<template>
  <div class="">
    <div class="row row-height" v-if="!isChildren">
      <v-sheet
        class="overflow-hidden col-md-12"
        style="position: relative; border-radius: 5px"
      >
        <div class="">
          <v-row align="center" justify="center">
            <div
              :class="
                overlay
                  ? 'hideOnMoblie background-opacity col-md-9'
                  : 'col-md-12 navigationWidth no-padding'
              "
            >
              <v-row class="blue-underline mb-1">
                <v-col class="col-xl-2 col-md-2 cols-4 pt-2 pb-0" cols="4">
                  <div class="col-md-12">
                    <h3 class="form-title font col-md-12">Users</h3>
                  </div>
                </v-col>

                <v-col class="col-xl-3 col-md-5 cols-8 pb-0 mobile-display" cols="8">
                  <div class="col-md-12 d-flex no-padding mt-4">
                    <v-text-field
                      :disabled="overlay"
                      class="input-control mr-5"
                      prepend-inner-icon="mdi-magnify"
                      @input="searchUsers"
                      light
                      dense
                      outlined
                      placeholder="Search Users"
                    ></v-text-field>
                    <v-btn
                      :disabled="overlay"
                      class="text-gray filter-btn"
                      @click.stop="drawer = !drawer"
                    >
                      <svg
                        width="17"
                        class="mr-2"
                        height="14"
                        viewBox="0 0 17 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 1H16"
                          stroke="#424242"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M2.59766 5H14.403"
                          stroke="#424242"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M6.3125 13H10.6858"
                          stroke="#424242"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M4.19531 9H12.8046"
                          stroke="#424242"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Filters
                    </v-btn>
                  </div>
                </v-col>

                <v-col
                  class="col-xl-7 col-md-5 cols-12 text-end pt-7 pb-0 mobile-display"
                  cols="12"
                  align="end"
                >
                  <v-btn
                    class="my-1 mr-5 gray-border-buttons"
                    @click="exportData"
                  >
                    <svg
                      class="mr-2"
                      width="15"
                      height="16"
                      viewBox="0 0 15 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.2361 10.7639C14.0329 10.7639 13.838 10.8434 13.6937 10.985C13.5495 11.1267 13.4676 11.319 13.4659 11.5202V12.6764C13.4659 12.9367 13.3615 13.1862 13.1756 13.3703C12.9897 13.5543 12.7376 13.6577 12.4747 13.6577H2.52525C2.26238 13.6577 2.01027 13.5543 1.8244 13.3703C1.63852 13.1862 1.53409 12.9367 1.53409 12.6764V11.5202C1.51671 11.3307 1.42838 11.1544 1.28645 11.0261C1.14452 10.8978 0.959257 10.8266 0.767046 10.8266C0.574834 10.8266 0.389569 10.8978 0.247642 11.0261C0.105715 11.1544 0.0173841 11.3307 0 11.5202V12.6764C0 13.3395 0.266053 13.9753 0.739629 14.4442C1.21321 14.913 1.85551 15.1764 2.52525 15.1764H12.4747C13.1445 15.1764 13.7868 14.913 14.2604 14.4442C14.7339 13.9753 15 13.3395 15 12.6764V11.5202C15 11.3196 14.9195 11.1272 14.7763 10.9854C14.633 10.8436 14.4387 10.7639 14.2361 10.7639Z"
                        fill="#707070"
                      />
                      <path
                        d="M7.49971 0.176392C7.17488 0.176392 6.86336 0.304137 6.63368 0.531525C6.404 0.758913 6.27496 1.06732 6.27496 1.38889V7.05139C6.27496 7.09283 6.25833 7.13257 6.22873 7.16188C6.19913 7.19118 6.15899 7.20764 6.11713 7.20764H4.43784C4.32437 7.21533 4.2151 7.25322 4.12158 7.31731C4.02807 7.3814 3.95378 7.46931 3.90657 7.57175C3.85935 7.6742 3.84097 7.78736 3.85337 7.89929C3.86576 8.01122 3.90847 8.11776 3.97698 8.20764L7.03885 11.6951C7.0979 11.7576 7.16927 11.8074 7.24855 11.8414C7.32782 11.8754 7.4133 11.893 7.49971 11.893C7.58611 11.893 7.6716 11.8754 7.75087 11.8414C7.83014 11.8074 7.90151 11.7576 7.96057 11.6951L11.0224 8.20764C11.0909 8.11776 11.1337 8.01122 11.146 7.89929C11.1584 7.78736 11.1401 7.6742 11.0928 7.57175C11.0456 7.46931 10.9713 7.3814 10.8778 7.31731C10.7843 7.25322 10.675 7.21533 10.5616 7.20764H8.88228C8.84042 7.20764 8.80028 7.19118 8.77068 7.16188C8.74108 7.13257 8.72445 7.09283 8.72445 7.05139V1.38889C8.72445 1.06732 8.59542 0.758913 8.36573 0.531525C8.13605 0.304137 7.82453 0.176392 7.49971 0.176392V0.176392Z"
                        fill="#707070"
                      />
                    </svg>

                    Export
                  </v-btn>

                  <v-btn
                    class="my-1 mr-2 gray-border-buttons"
                    @click="$router.push('/vendors/account/users/add-new')"
                  >
                    <svg
                      class="mr-2"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 7.5H15"
                        stroke="#707070"
                        stroke-width="2"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7.5 0V15"
                        stroke="#707070"
                        stroke-width="2"
                        stroke-linejoin="round"
                      />
                    </svg>

                    New
                  </v-btn>
                </v-col>

                <v-col
                    class="col-xl-10 col-md-10 cols-8 text-end" cols="8"  max-width="350px"
                    align="end"
                >
                  <v-btn class="gray-border-buttons mobile-only ml-auto mr-5 mt-3" @click="filterModal = true">
                    <i
                        class="fas fa-bars"
                        style="cursor: pointer; color: gray"
                    ></i>
                  </v-btn>
                  <v-menu offset-y>
                    <template v-slot:activator="{}">
                      <v-dialog v-model="filterModal" persistent max-width="350px">
                        <v-card>
                          <v-container>
                            <v-col md="12" cols="12" class="justify-center align-center pl-0">
                              <div class="row col-md-12 font">
                              </div>
                            </v-col>
                            <v-text-field
                                :disabled="overlay"
                                class="input-control mr-5"
                                prepend-inner-icon="mdi-magnify"
                                @input="searchUsers"
                                light
                                dense
                                outlined
                                placeholder="Search Users"
                            ></v-text-field>
<!--                            <v-btn-->
<!--                                :disabled="overlay"-->
<!--                                class="text-gray filter-btn"-->
<!--                                @click.stop="drawer = !drawer"-->
<!--                            >-->
<!--                              <svg-->
<!--                                  width="17"-->
<!--                                  class="mr-2"-->
<!--                                  height="14"-->
<!--                                  viewBox="0 0 17 14"-->
<!--                                  fill="none"-->
<!--                                  xmlns="http://www.w3.org/2000/svg"-->
<!--                              >-->
<!--                                <path-->
<!--                                    d="M1 1H16"-->
<!--                                    stroke="#424242"-->
<!--                                    stroke-width="1.5"-->
<!--                                    stroke-linecap="round"-->
<!--                                    stroke-linejoin="round"-->
<!--                                />-->
<!--                                <path-->
<!--                                    d="M2.59766 5H14.403"-->
<!--                                    stroke="#424242"-->
<!--                                    stroke-width="1.5"-->
<!--                                    stroke-linecap="round"-->
<!--                                    stroke-linejoin="round"-->
<!--                                />-->
<!--                                <path-->
<!--                                    d="M6.3125 13H10.6858"-->
<!--                                    stroke="#424242"-->
<!--                                    stroke-width="1.5"-->
<!--                                    stroke-linecap="round"-->
<!--                                    stroke-linejoin="round"-->
<!--                                />-->
<!--                                <path-->
<!--                                    d="M4.19531 9H12.8046"-->
<!--                                    stroke="#424242"-->
<!--                                    stroke-width="1.5"-->
<!--                                    stroke-linecap="round"-->
<!--                                    stroke-linejoin="round"-->
<!--                                />-->
<!--                              </svg>-->
<!--                              Filters-->
<!--                            </v-btn>-->
                            <v-btn
                                class="my-1 mr-5 gray-border-buttons exportButton"
                                @click="exportData"
                            >
                              <svg
                                  class="mr-2"
                                  width="15"
                                  height="16"
                                  viewBox="0 0 15 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                    d="M14.2361 10.7639C14.0329 10.7639 13.838 10.8434 13.6937 10.985C13.5495 11.1267 13.4676 11.319 13.4659 11.5202V12.6764C13.4659 12.9367 13.3615 13.1862 13.1756 13.3703C12.9897 13.5543 12.7376 13.6577 12.4747 13.6577H2.52525C2.26238 13.6577 2.01027 13.5543 1.8244 13.3703C1.63852 13.1862 1.53409 12.9367 1.53409 12.6764V11.5202C1.51671 11.3307 1.42838 11.1544 1.28645 11.0261C1.14452 10.8978 0.959257 10.8266 0.767046 10.8266C0.574834 10.8266 0.389569 10.8978 0.247642 11.0261C0.105715 11.1544 0.0173841 11.3307 0 11.5202V12.6764C0 13.3395 0.266053 13.9753 0.739629 14.4442C1.21321 14.913 1.85551 15.1764 2.52525 15.1764H12.4747C13.1445 15.1764 13.7868 14.913 14.2604 14.4442C14.7339 13.9753 15 13.3395 15 12.6764V11.5202C15 11.3196 14.9195 11.1272 14.7763 10.9854C14.633 10.8436 14.4387 10.7639 14.2361 10.7639Z"
                                    fill="#707070"
                                />
                                <path
                                    d="M7.49971 0.176392C7.17488 0.176392 6.86336 0.304137 6.63368 0.531525C6.404 0.758913 6.27496 1.06732 6.27496 1.38889V7.05139C6.27496 7.09283 6.25833 7.13257 6.22873 7.16188C6.19913 7.19118 6.15899 7.20764 6.11713 7.20764H4.43784C4.32437 7.21533 4.2151 7.25322 4.12158 7.31731C4.02807 7.3814 3.95378 7.46931 3.90657 7.57175C3.85935 7.6742 3.84097 7.78736 3.85337 7.89929C3.86576 8.01122 3.90847 8.11776 3.97698 8.20764L7.03885 11.6951C7.0979 11.7576 7.16927 11.8074 7.24855 11.8414C7.32782 11.8754 7.4133 11.893 7.49971 11.893C7.58611 11.893 7.6716 11.8754 7.75087 11.8414C7.83014 11.8074 7.90151 11.7576 7.96057 11.6951L11.0224 8.20764C11.0909 8.11776 11.1337 8.01122 11.146 7.89929C11.1584 7.78736 11.1401 7.6742 11.0928 7.57175C11.0456 7.46931 10.9713 7.3814 10.8778 7.31731C10.7843 7.25322 10.675 7.21533 10.5616 7.20764H8.88228C8.84042 7.20764 8.80028 7.19118 8.77068 7.16188C8.74108 7.13257 8.72445 7.09283 8.72445 7.05139V1.38889C8.72445 1.06732 8.59542 0.758913 8.36573 0.531525C8.13605 0.304137 7.82453 0.176392 7.49971 0.176392V0.176392Z"
                                    fill="#707070"
                                />
                              </svg>

                              Export
                            </v-btn>

                            <v-btn
                                class="my-1 mr-2 gray-border-buttons"
                                @click="$router.push('/vendors/account/users/add-new')"
                            >
                              <svg
                                  class="mr-2"
                                  width="15"
                                  height="15"
                                  viewBox="0 0 15 15"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                    d="M0 7.5H15"
                                    stroke="#707070"
                                    stroke-width="2"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M7.5 0V15"
                                    stroke="#707070"
                                    stroke-width="2"
                                    stroke-linejoin="round"
                                />
                              </svg>

                              New
                            </v-btn>
                            <v-card-actions>
                              <div class="col-md-12 mb-4 d-flex" align="end">
                <span
                    class="text-underline text-gray text-start mt-4  pointer col-md-6"
                    @click="filterModal = false"
                >Reset</span
                >
                                <span
                                    class="text-underline blue--text pointer mt-4 col-md-6"
                                    @click="filterModal = false"
                                >Apply Filter</span
                                >
                              </div>
                            </v-card-actions>
                          </v-container>
                        </v-card>
                      </v-dialog>
                    </template>

                  </v-menu>
                </v-col>
              </v-row>

              <v-simple-table fixed-header height="630px" id="tblData">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">First Name</th>
                      <th class="text-left">Last Name</th>
                      <th class="text-left">Email</th>

                      <th class="text-left">Title</th>
                      <th class="text-left">City</th>
                      <th class="text-left">State</th>
                    </tr>
                  </thead>
                  <tbody v-if="allUsers.length > 0">
                    <tr
                      v-for="item in allUsers"
                      :key="item.name"
                      class="text-gray"
                    >
                      <td v-if="item.firstName">
                        <a @click="singleUser(item)">{{ item.firstName }} </a>
                      </td>
                      <td v-else><a @click="singleUser(item)">/ </a></td>
                      <td>
                        <a @click="singleUser(item)">{{ item.lastName }} </a>
                      </td>
                      <td v-html="item.email ? item.email : '/'"></td>

                      <td v-if="item.title">
                        {{
                          item.title.charAt(0).toUpperCase() +
                          item.title.slice(1)
                        }}
                      </td>
                      <td v-else>/</td>
                      <td>{{ item.city }}</td>
                      <td>{{ item.state }}</td>
                    </tr>
                  </tbody>
                  <tbody v-else-if="filterItems">
                    <v-col md="12" cols="12">
                      <v-alert
                        border="bottom"
                        colored-border
                        type="warning"
                        elevation="2"
                        class="text-gray"
                      >
                        Sorry! No results found
                      </v-alert>
                    </v-col>
                  </tbody>
                  <tbody v-else>
                    <v-col md="12">
                      <a
                        @click="
                          $router.push(
                            '/employer-advisor/settings/users/add-new'
                          )
                        "
                        >You Don't have Users Yet. Click Here To Add New
                        User.</a
                      >
                    </v-col>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-row>
        </div>
        <v-navigation-drawer
          class="userFilterPosition filterMobile"
          width="350"
          v-model.trim="drawer"
          absolute
          temporary
        >
          <div class="filter-sideBar filterMobile">
            <div>
              <div class="row no-padding col-md-12 justify-space-between">
                <h3 class="font pl-3 mt-1">User Filters</h3>
                <h1 class="pointer mr-3" @click.stop="drawer = !drawer">x</h1>
              </div>
              <v-divider></v-divider>
              <div class="col-md-12">
                <div
                  class="
                    row
                    col-md-12
                    justify-space-between
                    no-padding no-margin
                  "
                >
                  <div
                    class="no-padding font filter-items-font font-weight-bold"
                  >
                    Selected Filter
                  </div>
                  <div
                    class="underline-text pointer text-gray filter-items-font"
                    @click="clearAll"
                  >
                    Clear All
                  </div>
                </div>
              </div>
              <div class="overflow-fitlers-buttons">
                <div class="row mb-1 col-md-12">
                  <v-btn
                    v-for="(item, index) in selectedArray.concat(
                      selectedTypeArray
                    )"
                    :key="index"
                    class="text-center mb-1 ml-2 mr-2 filter-btn"
                    small
                  >
                    <span v-html="item.text"></span>
                    <h5 class="ml-3" @click="removeFilter(item)">x</h5>
                  </v-btn>
                </div>
                <v-divider></v-divider>
                <v-expansion-panels
                  accordion
                  multiple
                  v-model.trim="panel"
                  class="no-shadow no-padding user-expansion-panels"
                >
                  <v-expansion-panel class="no-shadow no-padding">
                    <v-expansion-panel-header
                      class="
                        font-weight-black
                        change-padding-filter
                        filter-items-font
                      "
                      >Status</v-expansion-panel-header
                    >
                    <v-expansion-panel-content
                      class="no-padding content-filter"
                    >
                      <v-text-field
                        solo
                        class="filter-serach-input mb-4"
                        v-model.trim="status"
                        @input="searchStatus()"
                        dense
                        :hide-details="true"
                        placeholder="Search"
                        append-icon="fa fa-search"
                      ></v-text-field>
                      <v-checkbox
                        label="All"
                        color="primary"
                        v-if="showStatus.all"
                        v-model.trim="filter.status.all"
                        class="no-padding small-text radio-buttons-filters"
                        hide-details
                        :on-icon="'mdi-square'"
                        @click="
                          pushFilter(
                            { text: 'All', value: 'all' },
                            filter.status.all
                          )
                        "
                      >
                      </v-checkbox>
                      <v-checkbox
                        label="Join Request"
                        color="primary"
                        v-if="showStatus['join request']"
                        v-model.trim="filter.status.join"
                        class="no-padding radio-buttons-filters"
                        hide-details
                        :on-icon="'mdi-square'"
                        @click="
                          pushFilter(
                            { text: 'Join Request', value: 'join' },
                            filter.status.join
                          )
                        "
                      >
                      </v-checkbox>
                      <v-checkbox
                        label="Active"
                        color="primary"
                        v-if="showStatus.active"
                        :on-icon="'mdi-square'"
                        v-model.trim="filter.status.active"
                        class="no-padding radio-buttons-filters"
                        hide-details
                        @click="
                          pushFilter(
                            { text: 'Active', value: 'active' },
                            filter.status.active
                          )
                        "
                      >
                      </v-checkbox>
                      <v-checkbox
                        label="In Active"
                        color="primary"
                        v-if="showStatus['in active']"
                        v-model.trim="filter.status.inactive"
                        :on-icon="'mdi-square'"
                        class="no-padding radio-buttons-filters"
                        hide-details
                        @click="
                          pushFilter(
                            { text: 'In Active', value: 'inactive' },
                            filter.status.inactive
                          )
                        "
                      >
                      </v-checkbox>
                      <v-checkbox
                        label="Invited"
                        color="primary"
                        v-if="showStatus.invited"
                        :on-icon="'mdi-square'"
                        v-model.trim="filter.status.invited"
                        class="no-padding radio-buttons-filters mb-2"
                        hide-details
                        @click="
                          pushFilter(
                            { text: 'Invited', value: 'invited' },
                            filter.status.invited
                          )
                        "
                      >
                      </v-checkbox>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel class="no-shadow no-padding">
                    <v-expansion-panel-header
                      class="
                        font-weight-black
                        change-padding-filter
                        filter-items-font
                        mb-0
                        pb-0
                      "
                      >Type</v-expansion-panel-header
                    >
                    <v-expansion-panel-content
                      class="no-padding content-filter"
                    >
                      <v-text-field
                        solo
                        class="filter-serach-input no-padding mb-4"
                        v-model.trim="type"
                        dense
                        :hide-details="true"
                        @input="searchType()"
                        placeholder="Search"
                        append-icon="fa fa-search"
                      ></v-text-field>
                      <v-checkbox
                        label="All"
                        color="primary"
                        v-if="showType.all"
                        :on-icon="'mdi-square'"
                        v-model.trim="filter.type.all"
                        class="no-padding radio-buttons-filters"
                        hide-details
                        @click="
                          pushtoTypeFilter(
                            { text: 'All', value: 'allTypes' },
                            filter.type.all
                          )
                        "
                      >
                      </v-checkbox>
                      <v-checkbox
                        label="Admins"
                        color="primary"
                        v-if="showType.admin"
                        v-model.trim="filter.type.admin"
                        :on-icon="'mdi-square'"
                        class="no-padding radio-buttons-filters"
                        hide-details
                        @click="
                          pushtoTypeFilter(
                            { text: 'Admins', value: 'admin' },
                            filter.type.admin
                          )
                        "
                      >
                      </v-checkbox>
                      <v-checkbox
                        label="General User"
                        color="primary"
                        :on-icon="'mdi-square'"
                        v-if="showType['general user']"
                        v-model.trim="filter.type.user"
                        class="no-padding radio-buttons-filters"
                        hide-details
                        @click="
                          pushtoTypeFilter(
                            { text: 'General User', value: 'user' },
                            filter.type.user
                          )
                        "
                      >
                      </v-checkbox>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </div>
            <div>
              <v-divider></v-divider>
              <v-btn
                class="blue-buttons col-md-12 mt-3 mb-md-3 mb-xl-0"
                @click="filterUsers"
                >View Result</v-btn
              >
            </div>
          </div>
        </v-navigation-drawer>
      </v-sheet>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import api from "../../../../../services/api";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      filterModal: false,
      items: [],
      allUsers: [
        {
          firstName: "Bill",
          lastName: "Smith",
          email: "bill.smith@smartlight.cm",
          title: "Regional VP",
          city: "Detorit",
          state: "Michigan",
          status: "Active",
          role: "Admin",
        },
      ],
      overlay: false,
      display: true,
      filterArray: [],
      windowWidth: window.innerWidth,
      noResultMessage: false,
      drawer: null,
      showStatus: {
        all: true,
        active: true,
        "in active": true,
        invited: true,
        "join request": true,
      },
      showType: {
        all: true,
        "general user": true,
        admin: true,
      },
      userStatus: [
        {
          text: "ALL",
          value: "all",
        },

        {
          text: "Join Request",
          value: "join",
        },
        {
          text: "Active",
          value: "active",
        },
        {
          text: "In Active",
          value: "inactive",
        },
        {
          text: "Invited",
          value: "invited",
        },
      ],
      userType: [
        {
          text: "ALL",
          value: "all",
        },
        {
          text: "Admins",
          value: "admins",
        },
        {
          text: "General User",
          value: "users",
        },
      ],
      selectedArray: [],
      selectedTypeArray: [],
      getUsers: [],
      panel: [0, 1],
      filterUse: false,
      filterItems: false,
      type: "",
      status: "",
      statusArray: [],
      typeArray: [],
      filter: {
        status: {
          all: false,
          active: false,
          inactive: false,
          invited: false,
          join: false,
        },
        type: {
          all: false,
          user: false,
          admin: false,
        },
      },
    };
  },
  watch: {
    showRoutePathForFilter: {
      handler(newValue, oldValue) {
        this.drawer = this.showRoutePathForFilter;
      },
      deep: true,
    },
    drawer: {
      handler(newValue, oldValue) {
        this.$store.commit("setNavItemFilter", this.drawer);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["showRoutePathForFilter"]),
    routeParams() {
      return this.$route.params.name;
    },
    singleEmp() {
      let emp = "";
      if (localStorage.getItem("employer")) {
        emp = JSON.parse(localStorage.getItem("employer"));
      }
      return emp;
    },
    arrayInFilters() {
      return this.allUsers;
    },
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
  },
  created() {
    this.getAllUsers("all");
    this.statusArray = this.userStatus;
    this.typeArray = this.userType;
    this.selectedArray = [];
    this.filterArray = this.allUsers;
  },
  methods: {
    exportData() {
      var elt = document.getElementById("tblData");
      var downloadLink;
      var dataType = "application/vnd.ms-excel";
      var tableSelect = elt;
      var tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

      // Specify file name
      let filename = "excel_data.xls";

      // Create download link element
      downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        var blob = new Blob(["\ufeff", tableHTML], {
          type: dataType,
        });
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = "data:" + dataType + ", " + tableHTML;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
    },
    pushtoTypeFilter(item, checked) {
      if (checked) {
        if (item.value == "allTypes") {
          this.selectedTypeArray = [];
          this.filter.type = {
            all: true,
            user: true,
            admin: true,
          };
          this.selectedTypeArray.push({ text: "All", value: "allTypes" });
        } else {
          this.selectedTypeArray.push(item);
        }
      } else {
        if (item.value == "allTypes" || this.filter.type.all) {
          this.filter.type = {
            all: false,
            user: false,
            admin: false,
          };
          this.selectedTypeArray = [];
        } else {
          this.selectedTypeArray.forEach((el, index) => {
            if (el.value == item.value) {
              this.selectedTypeArray.splice(index, 1);
            }
          });
        }
      }
    },
    pushFilter(item, checked) {
      if (checked) {
        if (item.value == "all") {
          this.selectedArray = [];
          this.filter.status = {
            all: true,
            active: true,
            inactive: true,
            invited: true,
            join: true,
          };
          this.selectedArray.push({ text: "All", value: "all" });
        } else {
          this.selectedArray.push(item);
        }
      } else {
        if (item.value == "all" || this.filter.status.all) {
          this.filter.status = {
            all: false,
            active: false,
            inactive: false,
            invited: false,
            join: false,
          };
          this.selectedArray = [];
        } else {
          this.selectedArray.forEach((el, index) => {
            if (el.value == item.value) {
              this.selectedArray.splice(index, 1);
            }
          });
        }
      }
    },

    searchStatus() {
      for (let key in this.showStatus) {
        if (key.toLowerCase().includes(this.status.toLowerCase())) {
          this.showStatus[key] = true;
        } else {
          this.showStatus[key] = false;
        }
      }
    },
    searchType() {
      for (let key in this.showType) {
        if (key.toLowerCase().includes(this.type.toLowerCase())) {
          this.showType[key] = true;
        } else {
          this.showType[key] = false;
        }
      }
    },
    filterUsers() {
      api()
        .get("/vendors/users")
        .then((res) => {
          this.allUsers = res.data;
          this.filterArray = res.data;

          if (!this.filter.status && !this.filter.type) {
            this.getAllUsers();
          } else {
            let params = "";
            let role = "";
            for (let key in this.filter.status) {
              if (this.filter.status[key]) {
                params += `${key},`;
              }
            }

            for (let key in this.filter.type) {
              if (this.filter.type[key]) {
                role += `${key},`;
              }
            }

            if (
              (params && !this.filter.status.all) ||
              (role && !this.filter.type.all)
            ) {
              this.allUsers = this.filterArray.filter(function (el) {
                if (
                  el &&
                  el.groupPortal &&
                  el.groupPortal[0] &&
                  el.groupPortal[0].role &&
                  el.groupPortal[0].role.name
                ) {
                  return (
                    params.toLowerCase().includes(el.status.toLowerCase()) ||
                    role
                      .toLowerCase()
                      .includes(el.groupPortal[0].role.name.toLowerCase())
                  );
                } else {
                  return params.toLowerCase().includes(el.status.toLowerCase());
                }
              });
            }
          }
          if (this.allUsers.length < 1) {
            this.filterItems = true;
          } else {
            this.filterItems = false;
          }

          this.drawer = false;
        });
    },
    clearAll() {
      this.filter.status = {
        all: false,
        active: false,
        inactive: false,
        invited: false,
        join: false,
      };
      this.filter.type = {
        all: false,
        user: false,
        admin: false,
      };

      this.selectedArray = [];
      this.selectedTypeArray = [];
      this.getAllUsers("all");
    },

    removeFilter(item) {
      if (item.value == "allTypes") {
        this.selectedTypeArray = [];
        this.filter.type = {
          all: false,
          user: false,
          admin: false,
        };
      } else if (this.filter.status[item.value]) {
        this.filter.status[item.value] = false;
        this.selectedArray.splice(this.selectedArray.indexOf(item), 1);
      }

      if (item.value == "all") {
        this.selectedArray = [];
        this.filter.status = {
          all: false,
          active: false,
          inactive: false,
          invited: false,
          join: false,
        };
      } else if (this.filter.type[item.value]) {
        this.filter.type[item.value] = false;
        this.selectedTypeArray.splice(this.selectedTypeArray.indexOf(item), 1);
      }
    },

    getAllUsers(type) {
      api()
        .get(`/vendors/users`)
        .then((res) => {
          this.allUsers = res.data;
          this.filterArray = res.data;
          this.getUsers = res.data;
          if (res.data.length < 1) {
            this.noResultMessage = true;
          } else {
            this.noResultMessage = false;
          }
        });
    },

    singleUser(item) {
      this.$store.commit("setSingleUserInEmployerAdvisorGlobal", item);
      this.$router.push(`/vendors/account/users/user`);
      // if (!this.overlay) {
      //   let user = JSON.parse(localStorage.getItem("user"));
      //   let advisorId = user.groupPortal[0].organization.employerAdvisor;
      //   api()
      //     .get(`/advisors/${advisorId}/users/${item._id}`)
      //     .then((res) => {
      //       this.$store.commit(
      //         "setSingleUserInEmployerAdvisorGlobal",
      //         res.data.user
      //       );
      //       this.$router.push(`/employer-advisor/settings/users/user`);
      //     });
      // }
    },
    searchUsers(val) {
      this.allUsers = this.filterArray.filter((el) => {
        if (el.firstName.toLowerCase().includes(val.toLowerCase())) {
          return el.firstName.toLowerCase().includes(val.toLowerCase());
        } else if (el.lastName.toLowerCase().includes(val.toLowerCase())) {
          return el.lastName.toLowerCase().includes(val.toLowerCase());
        }
      });
      if (this.allUsers.length < 1) {
        this.filterItems = true;
      }
    },
  },
};
</script>
<style scoped>
.mobile-only{
  display: none;
}
.display{
  display: grid;
}
@media screen and (max-width: 768px) {
  .mobile-display{
    display: none;
  }
  .mobile-only{
    width: 10px;
    display: flex;
  }
  .v-input__control {
    margin-top: 30px;
    margin-bottom: 40px;
  }
  .exportButton{
    margin-left: 30px;
  }
  .filterMobile{
    height: 150%;
  }

}

</style>
