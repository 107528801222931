<template>
  <div class="page-background">
    <main-header :title="'Contact Us'" :color="'white'" ></main-header>
    <div class="mobile-edit-form">
      <form >
        <div>
          <h3 class="from-headers">Send a message to support
            <br>
            <span class="from-sub-headers">Contact us by completing this form. You can alternatively email contact@apaly.com.</span>
          </h3>
          <v-text-field type="email" outlined label="Email" color="white"></v-text-field>
          <v-textarea
              outlined
              label="Message"
          ></v-textarea>
        </div>
      </form>
    </div>
    <div class="d-flex footer"  style="">
      <div class="col-6">
        <v-btn class="footer-btn-cancel" >Cancel</v-btn>
      </div>
      <div class="col-6">
        <v-btn color="white" class="footer-btn" >Save</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import mainHeader from "@/components/Sections/DPCMember/Layout/mainHeader";
export default {
  data() {
    return {
      show1:false,
      show2:false,
      show3:false,

    };
  },
  components: {
    mainHeader,
  },
  methods: {},
  created() {},
};
</script>
<style scoped src='./../../../../assets/mobileStyle.css'></style>
<style scoped lang="scss">


.mobile-edit-form {
  background-color: #16407a;
  height: 100vh;
  padding: 20px;
  overflow-y: auto;
  padding-bottom: 150px !important;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;
}

.mobile-content {
  padding: 16px 24px 0 24px;
}

.page-background {

  background-color: #001e46;
}

.list-item-title {
  color: #0573f3;
  font-family: "Quicksand";
  font-weight: 700;
}

.list-item-title {
  color: #0573f3;
  font-family: "Quicksand";
  font-weight: 700;
}

.list-item-subtitle {
  font-family: "Quicksand";
  font-weight: 600;
}

.second-subtitle {
  color: #001e46;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.mobile-edit-form v-text-field {
  color: white;
}

.from-headers {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  padding-bottom: 15px;
  color: white;
}

.from-sub-headers {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.footer{
  background-color: #16407a;
  height:80px;
  position:absolute;
  bottom:0;
  width:100%;
  align-items: flex-start;
  border-top: 1px solid #C9C9C9
}
.footer-btn{
  border-radius: 23px;
  width: 134px;
}

.footer-btn-cancel{
  background-color:#16407a !important;
  border-radius: 23px;
  width: 134px;
  border: 1px solid white !important;
  color: white;
  float:right
}

.btn-settings{
  background-color:#16407a !important;
  border-radius: 23px;
  width: 155px;
  border: 1px solid white !important;
  color: white;
  float:right
}

.btn-settings-white{
  background-color:white !important;
  border-radius: 23px;
  width: 155px;
  border: 1px solid white !important;
  color: #16407a;
  float:right
}

.v-input__slot > label {
  color: white !important;
}

</style>
