
const state ={
    employerSingleRule:null
}
const getters = {
    getEmployerSingleRule(){
        return state.employerSingleRule
    }
}
const mutations = {
    setEmployerSingleRule(state,rule){
        state.employerSingleRule = rule
    },
    
}

export default{
    state,
    getters,
    mutations,
}