
const state ={
    singleSoulution:null,
    singleItem:null,
    solutionDetailsFromTable:false
}
const getters = {
  getSingleItem(state){
    return state.singleItem
  },
  checkIfIsFromTable(state){
     return state.solutionDetailsFromTable
  }
}
const mutations = {
    setSingleSolution(state,solution){
        state.singleSoulution = solution
    },
    setSingleItem(state, item){
      state.singleItem = item
    },
    solutionDetailsFromTable(state, details){

      state.solutionDetailsFromTable = details
    }
   
}

export default{
    state,
    getters,
    mutations,
}