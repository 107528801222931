<template>
    <div class="no-padding no-margin">
        <Users v-if="path == `/employer-advisor/employers/${routeParams}/settings/users`"></Users>
        <AddUser v-if="path == `/employer-advisor/employers/${routeParams}/settings/users/add-new`"></AddUser>
        <InviteUser v-if="path ==  `/employer-advisor/employers/${routeParams}/settings/users/invite-user`"></InviteUser>
        <User v-if="path == `/employer-advisor/employers/${routeParams}/settings/users/user`"></User>
        <Account v-if="path == `/employer-advisor/employers/${routeParams}/settings/account`"></Account>
        <Settings v-if="path == `/employer-advisor/employers/${routeParams}/settings`"></Settings>
    </div>
</template>
<script>
import AddUser from './AddUser.vue'
import InviteUser from './InviteUser.vue'
import User from './UsersCrud.vue'
import Users from './UsersTable.vue'
import Account from './AccountForm.vue'
import Settings from './Settings.vue'
export default{
    components:{
        AddUser,
        InviteUser,
        User,
        Users,
        Account,
        Settings
    },
    computed:{
        path(){
            return this.$route.path.replace(/%20/g,' ')
        },
        routeParams(){
            return this.$route.params.name
        }
      
    },
   
}
</script>