<template>
 <v-row class="add-client-form no-padding">
   <successModal  ref="success_modal" v-on:confirm="closeSuccessModal"></successModal>
   
  <div class="col-md-12 form-box">
    <div class="row">
        <div class="col-lg-8 col-md-9 col-sm-12">
            <h3 class="form-title" >
                <span class="form-title  text-underline" > Invite Members </span>
            </h3>
        </div>
          <!-- <div class="col-md-4 col-sm-12 text-end" align="end">
          <v-btn class="blue-buttons">Copy and Email Invite Link</v-btn>
        </div> -->
    </div>
      <v-form ref="form" v-model.trim="valid">
        <div v-for="(n,index) in inviteArray" :key="index">
           <div  class="row">
            <div class="col-md-4">
               <label class="font text-gray" for="">Email (required) </label>
                <v-text-field
                  required
                  :rules="n.email != '' ? emailRules : []"
                  v-model.trim="n.email"
                  solo
                ></v-text-field>
           </div>
            <div class="col-md-4">
               <label class="font text-gray" for="">First Name (optional)</label>
                <v-text-field
                  required
                  v-model.trim="n.firstName"
                  solo
                ></v-text-field>
           </div>
            <div class="col-md-4">
               <label class="font text-gray" for="">Last Name (optional)</label>
                <v-text-field
                  required
                  v-model.trim="n.lastName"
                  solo
                ></v-text-field>
           </div>
         </div>
         <v-divider class=" mb-2"></v-divider>
       </div>
       <div class="row mt-2">
           <div class="col-md-12 row justify-space-between">
            <a  @click="addAnotherRow" class="col-lg-10 col-md-10">Add Another Row</a>
             <v-btn class="blue-buttons  "  @click="showTable()">
               Invite Members
            </v-btn>
           </div> 
       </div>
      </v-form>
    </div>
    <errorModal  ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
 </v-row>
</template>
<script>
import successModal from '../../../../TablesTemplate/SuccessDialog.vue'
import api from '../../../../../services/api'
import errorModal from '../../../../TablesTemplate/ErrorDialog.vue'
 export default{
   data(){
        return{
          itemsLength:3,
          emailRules: [
            v => !!v || 'This field is required',
            v => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || 'E-mail must be valid',
          ],
          valid:false,
          inviteArray:[
            {
              'email':"",
              'lastName':'',
              'firstName':''
            },
               {
              'email':"",
              'lastName':'',
              'firstName':''
            },
               {
              'email':"",
              'lastName':'',
              'firstName':''
            }
          ]
        }
   },
   components:{
          successModal,errorModal
   },
    computed:{
        singleMember(){
          return this.$store.getters.getMemberGetter;
        },
         singleClient(){
          return this.$store.getters.getClientGetter;
        },
        routeParams(){
             return this.$route.params.name
        }
        
    },
    methods:{
      closeErrorModal(){
         this.$refs.error_modal.closePopUp();
      },
      showTable(){
        this.$refs.form.validate();
          if(this.valid){
           let user = JSON.parse(localStorage.getItem('user'));
           let advisorId = user.groupPortal[0].organization.employerAdvisor
           let employer = JSON.parse(localStorage.getItem('employer'))        
           let data = {
             'members':[]
           }
              for(let i = 0; i < this.inviteArray.length; i++){
                if(this.inviteArray[i].email != ''){
                  data.members.push(this.inviteArray[i])
                }
              }
           
          api().post(`/advisors/${advisorId}/employers/${employer._id}/inviteMembers`,data).catch((err)=>{
              if(err){
                this.$refs.error_modal.openPopUp(err.response.data.message)
              }
          }).then((res)=>{
            if(res){
               let text = 'Email sucessfully sent'
               this.$refs.success_modal.openPopUp(text);
              
             
            }
          });
       }
      },
      closeSuccessModal(){
         this.$router.push(`/employer-advisor/employers/${this.routeParams}/members`)
          // let data = this.inviteArray
      
      },
    addAnotherRow(){
           this.inviteArray.push({
              'email':"",
              'lastName':'',
              'firstName':''
            })

      }
   }
 }
</script>