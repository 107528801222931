<template>
  <div>
    <div class="row col-md-12 font-weight-bold light-blue-text">
      General Information
      <v-divider class="mt-3 ml-3"></v-divider>
    </div>
    <div class="row mt-10">
      <div class="col-md-12 ml-3">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4">
                <span class="text-gray font-weight-bold">Name:</span>
              </div>
              <div class="col-md-8">
                <span class="text-gray font-weight-bold ml-4">{{ item.companyName }}</span>
              </div>
            </div>
          </div>
          <br>
          
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4">
                <span class="text-gray font-weight-bold">Type:</span>
              </div>
              <div class="col-md-8">
                <span class="text-gray font-weight-bold ml-4">Point Solution Vendor</span>
              </div>
            </div>
          </div>
           <br>
           <!-- <div class="col-md-12">
            <div class="row">
              <div class="col-md-4">
                <span class="text-gray font-weight-bold">Category:</span>
              </div>
              <div class="col-md-8">
                <span class="text-gray font-weight-bold ml-4">{{item.category }}</span>
              </div>
            </div>
          </div> <br> -->
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4">
                <span class="text-gray font-weight-bold">Specialty:</span>
              </div>
              <div class="col-md-8">
                <span class="text-gray font-weight-bold ml-4">{{item.specialty }}</span>
              </div>
            </div>
          </div> <br>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4">
                <span class="text-gray font-weight-bold">Website:</span>
              </div>
              <div class="col-md-8">
                <a class="font-weight-bold ml-4" :href="item.url">{{ item.details.website }}</a>
              </div>
            </div>
          </div> <br>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4">
                <span class="text-gray font-weight-bold">Description:</span>
              </div>
              <div class="col-md-8">
                <span class="text-gray font-weight-bold ml-4">{{ item.details.description }}</span>
              </div>
            </div>
          </div> <br>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4">
                <span class="text-gray font-weight-bold">Activation:</span>
              </div>
              <div class="col-md-8">
                <span class="text-gray font-weight-bold ml-4">
                  {{ item.participation == "Requires Approval" ? 'Requires Approval from Vendor' : 'Open' }}
                </span>
              </div>
            </div>
          </div> <br>
          
        </div>
      </div>
    </div>
    <div class="row col-md-12 font-weight-bold light-blue-text mt-8">
      Contact Information
      <v-divider class="mt-3 ml-3"></v-divider>
    </div>
    <div class="row mt-3 mb-5">
      <div class="col-md-12 ml-3">
        <div class="row">
          <br>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4">
                <span class="text-gray font-weight-bold">Point of Contact:</span>
              </div>
              <div class="col-md-8" v-if="item.contact">
                <span class="text-gray font-weight-bold ml-4">{{item.contact.firstName +' '+item.contact.lastName }}</span>
              </div>
            </div>
          </div> <br>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4">
                <span class="text-gray font-weight-bold">Email:</span>
              </div>
              <div class="col-md-8"  v-if="item.contact">
                <a :href="'mailto:'+item.contact.email" class="text-gray font-weight-bold ml-4">{{ item.contact.email }}</a>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4">
                <span class="text-gray font-weight-bold">Phone Number:</span>
              </div>
              <div class="col-md-8"  v-if="item.contact">
                <span class="text-gray font-weight-bold ml-4"> {{ item.contact.phone }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GeneralInformation",
  props:['item'],
}
</script>

<style scoped>

</style>