<template>
    <div>
      <main-header :title="'Find Care'" :color="'white'"></main-header>
      <v-select class="searchBar" color="white" :items="providers" value="z2b1123123ub1" item-text="client" item-value="_id">
        <template v-slot:item="slotItem">
          <div class="d-flex justify-space-between info-box-list-select width-100">
            <div>
              {{ slotItem.item.client }}
            </div>
            <div >
              {{ slotItem.item.provider }}
            </div>
          </div>
        </template>
      </v-select>

      <div class="d-flex flex-column filters">
        <div class="navy-title">
          Selected Filters:
        </div>
        <div class="d-flex justify-space-between">
          <div>

          </div>
          <div>
            <v-btn @click="$router.push({name:'FindCareSearchFilters'})" elevation="1" outlined rounded text class="width-100 blue-background text--white">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 15V1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M4 21C5.65685 21 7 19.6569 7 18C7 16.3431 5.65685 15 4 15C2.34315 15 1 16.3431 1 18C1 19.6569 2.34315 21 4 21Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 23L12 10" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 10C13.6569 10 15 8.65685 15 7C15 5.34315 13.6569 4 12 4C10.3431 4 9 5.34315 9 7C9 8.65685 10.3431 10 12 10Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M20 17V1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M20 23C21.6569 23 23 21.6569 23 20C23 18.3431 21.6569 17 20 17C18.3431 17 17 18.3431 17 20C17 21.6569 18.3431 23 20 23Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </v-btn>
          </div>
        </div>
      </div>

      <div class="mobile-content-background scrollable">
        <v-radio-group class="mobile-content" v-model="selectedCare">
          <div class="d-flex flex-column provider-list" v-for="(item,key) in items" :key="key">
            <div class="d-flex justify-space-between">
              <div class="blue-header">
                {{ item.title }}
              </div>
              <div>1.2m</div>
            </div>
            <div class="d-flex justify-space-between align-center">
              <div>
                Main Street West <br>
                1313 Main Street West <br>
                Tampa, FL 33602 <br>
                <span class="blue-title">
                  p: 813-555-1212
                </span>
              </div>
              <div>
                <v-radio :value="item._id"></v-radio>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column p-5">
            <div class="info-box-list-single text-center">
              <div class="gray-title">End of Provider List</div>
            </div>
          </div>
        </v-radio-group>
      </div>

      <div class="footer">
        <div class="d-flex justify-space-between">
          <v-btn elevation="1" outlined rounded x-large text class="white-background blue--text mr-2" style="border-color: #16407A;width:50%;">
            Cancel
          </v-btn>
          <v-btn elevation="1" outlined rounded x-large text class="blue-background white--text" style="width:50%;">
            Save
          </v-btn>
        </div>
      </div>

    </div>
</template>

<script>
import mainHeader from "@/components/Sections/DPCMember/Layout/mainHeader";
export default {
  data() {
    return {
      selectedCare:null,
      providers:[
          {
            _id:'z2b1123123ub1',
            client:'William2 Smith',
            provider:'Everside Health'
          },
          {
            _id:'z2b1uhb2u1hb2ub1',
            client:'William Smith',
            provider:'Everside Health'
          },
      ],
      items:[
        {
          _id:'z2b1uhb2u1hb2ub1',
          title:'Everside Health',
          desc:'Main Street West 1313 Main Street West Tampa, FL 33602',
          p:'p: 813-555-1212',
        },
        {
          _id:'z2b1uhb21212b1',
          title:'Everside Health',
          desc:'Main Street West 1313 Main Street West Tampa, FL 33602',
          p:'p: 813-555-1212',
        },
        {
          _id:'z2b1u131332u1hb2ub1',
          title:'Everside Health',
          desc:'Main Street West 1313 Main Street West Tampa, FL 33602',
          p:'p: 813-555-1212',
        },
        {
          _id:'z2asfgasb2u1hb2ub1',
          title:'Everside Health',
          desc:'Main Street West 1313 Main Street West Tampa, FL 33602',
          p:'p: 813-555-1212',
        },
        {
          _id:'z2basfu1hb2ub1',
          title:'Everside Health',
          desc:'Main Street West 1313 Main Street West Tampa, FL 33602',
          p:'p: 813-555-1212',
        },
        {
          _id:'z2b1123b2u1hb2ub1',
          title:'Everside Health',
          desc:'Main Street West 1313 Main Street West Tampa, FL 33602',
          p:'p: 813-555-1212',
        },
        {
          _id:'z2b1asffgu1hb2ub1',
          title:'Everside Health',
          desc:'Main Street West 1313 Main Street West Tampa, FL 33602',
          p:'p: 813-555-1212',
        },
        {
          _id:'z2b1aasuhfshb2ub1',
          title:'Everside Health',
          desc:'Main Street West 1313 Main Street West Tampa, FL 33602',
          p:'p: 813-555-1212',
        }
      ]
    };
  },
  components:{
    mainHeader
  },
  methods: {},
  created() {},
};
</script>
<style scoped src='./../../../../assets/mobileStyle.css'></style>
<style scoped lang="scss">


.searchBar{
  background-color: #16407A;
  padding:5px 25px 5px 25px;
}
.mobile-content-background{
  background-color:#FFF;
  height:100vh;
}
.mobile-content{
  padding:16px 24px 0 24px;
}
.info-box{
  background-color: white;
  border-radius: 5px;
  border: 1px solid #C9C9C9;
}
.info-box-list-single{
  margin: 18px;
}
.info-box-list-select{
  margin: 12px;
  border-bottom: 1px solid #C9C9C9;
}
.info-box-list-select > div{
  padding: 10px 0;
}
.filters{
  padding:5px 25px 5px 25px;
  background-color:#FFF;
  border-bottom:1px solid #C9C9C9;
}
.provider-list{
  padding: 12px 0;
  border-bottom: 1px solid #C9C9C9;
}
.footer{
  background-color: white;
  border-top:1px solid #C9C9C9;
  padding:15px 15px 0 15px;
  height:80px;
  position:absolute;
  bottom:0;
  width:100%;
  align-items: flex-start;
  border-top: 1px solid #C9C9C9
}
.footer > button {
  background-color: #16407A;
  color:white;
}
.scrollable{
  height: 66vh;
}
</style>