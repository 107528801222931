<template>
    <div class="form-div mt-100">
        <v-form class="col-md-12 col-xl-12 col-lg-12 text-center mt-10" ref="form">
            <div class="mt-10"> 
                <div class="col-md-12 mb-20 mt-10" v-if="verification">
                    <div class="mt-10">
                        <h1 class="login">Account Verified Successfully
                     <br> 
                   </h1>
                    </div>
                    <div class="mt-5">
                <h2>
                 <a @click="$router.push('/new-login')" class="text-underline mt-10">
                     Go to Login</a>
            </h2>
              </div>
            
                </div>
                
                <div class="text-left col-md-12" v-else>
                <h1 class="">We're sorry your account is not verified!
                 </h1>
                  </div>
               </div>
          </v-form>
    </div>
  </template>
  <script>
  import api from "../../services/api";
  import Vue from "vue";
  import { mapActions } from "vuex";

  export default {
    data() {
      return {
      verification: false,
      token: "",
      
      };
    },
    created() {
        var url = window.location;
        var token = new URLSearchParams(url.search).get('token');
        api().post("/auth/simple/verify/account", {'token' : token })
                  .catch((err) => {
                    if (err) {
                     console.log('err',err)
                    }
                  })
                  .then((res) => {
                    if (res) {
                      if(res.data.verified == true)
                      {
                        this.verification = true;
                        if(res.data.orgExist == true)
                        {
                            let org = {
                                organizationId: res.data.organizationId,
                                orgName: res.data.orgName,
                                accessToken: res.data.accessToken,
                                email: res.data.email,
                            }
                            this.$store.commit("setOrg", org);
                            this.$router.push(`/auth/organization/${res.data.organizationId}/membership`);
                        }
                      }
                      else{
                        this.verification = false;
                      }
                    }
                  });

    },
    methods: {
      ...mapActions(["loginUser"]),
      eventHandling(event) {
        if (event.keyCode == 13) {
          this.submit();
        }
      },
   
    },
  };
  </script>
  <style scoped>


</style>
  