<template>
  <div class="row-height">
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <errorDialog ref="error_modal" v-on:confirm="closeErrorModal"></errorDialog>
    <exitsDialog
      ref="exists_modal"
      v-on:confirm="closeExistsModal"
    ></exitsDialog>
    <v-row class="mb-4 mt-3">
      <v-col class="col-md-4 col-lg-4 col-sm-5" cols="12">
        <h3>
          <span class=""> Add Providers - Search Results</span>
        </h3>
      </v-col>
      <v-col class="col-lg-4 col-md-6" cols="12">
        <v-text-field
          class="input-control"
          prepend-inner-icon="mdi-magnify"
          @input="searchProviders"
          light
          dense
          outlined
          placeholder="Search Providers"
        >
        </v-text-field>
      </v-col>
      <v-col
        class="
          col-md-5 col-lg-4 col-sm-7
          row
          justify-md-end justify-sm-center
          buttons-left
          ml-2
        "
        cols="12"
      >
        <v-btn class="blue-buttons mr-10" @click="goBack()"> Cancel </v-btn>
        <v-btn
          class="blue-buttons"
          v-if="items.length > 0"
          @click="addProviders()"
        >
          Add Selected
        </v-btn>
      </v-col>
    </v-row>
    <v-simple-table fixed-header height="630px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Provider Name</th>
            <th class="text-left">Type</th>
            <th class="text-left">Quality Rating</th>
            <th class="text-left">City</th>
            <th class="text-left">State</th>
            <th class="no-padding text-end justify-end">
              <div class="col-md-12 justify-end d-flex text-end">
                <v-checkbox
                  v-model="allRows"
                  class="no-padding mt-3"
                  @click="selectAllRows()"
                ></v-checkbox>
                <label for="" style="padding-top: 15px">Select all</label>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in items"
            :key="index"
            :class="selectAll ? 'activeItem' : ''"
            :id="'id' + index"
          >
            <td
              class="text-gray"
              v-if="item['Provider Organization Name (Legal Business Name)']"
            >
              <a @click="singleProvider(item)">{{
                item["Provider Organization Name (Legal Business Name)"]
              }}</a>
            </td>
            <td
              class="text-gray"
              v-else-if="
                item['Provider Last Name (Legal Name)'] ||
                item['Provider First Name']
              "
            >
              <a @click="singleProvider(item)">
                {{ item["Provider First Name"] }}
                {{ item["Provider Last Name (Legal Name)"] }}
              </a>
            </td>

            <td class="text-gray">NPIs Type{{ item["Entity Type Code"] }}</td>
            <td class="text-gray">
              <v-rating
                :value="item.qualityRating"
                color="yellow darken-3"
                background-color="grey darken-1"
                empty-icon="$ratingFull"
                half-increments
              ></v-rating>
            </td>
            <td
              class="text-gray"
              v-html="
                item['Provider Business Practice Location Address City Name']
                  ? item[
                      'Provider Business Practice Location Address City Name'
                    ]
                  : '/'
              "
            ></td>
            <td class="text-gray">
              {{ item["Provider Business Mailing Address State Name"] }}
            </td>
            <td
              class="text-center mt-3 row no-padding justify-end"
              style="padding-right: 80px"
            >
              <v-checkbox
                class="stay-blue no-padding"
                :value="selectAll"
                @click="activeRow(index, item)"
              ></v-checkbox>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-row class="text-end justify-end mt-5 mr-3">
      <v-progress-linear
        v-if="loadData"
        color="blue"
        indeterminate
        reverse
      ></v-progress-linear>
      <v-btn
        v-if="showButton"
        :disabled="loadData"
        class="blue-buttons mt-5 mb-2"
        @click="loadMoreData()"
      >
        Load More Data
      </v-btn>
    </v-row>
  </div>
</template>
<script>
import api from "../../../../services/api";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import errorDialog from "../../../TablesTemplate/ErrorDialog.vue";
import exitsDialog from "../../../TablesTemplate/ExistDialog.vue";
export default {
  components: {
    successModal,
    errorDialog,
    exitsDialog,
  },
  data() {
    return {
      loadData: false,
      selected: [],
      items: [],
      selectAll: false,
      filteredArray: [],
      selectedRows: [],
      showButton: false,
      filterArray: [],
      allRows: false,
    };
  },
  created() {
    this.items = this.getProviders;
    this.filterArray = this.getProviders;
    if (this.items.length % 100 == 0) {
      this.showButton = true;
    } else {
      this.showButton = false;
    }
  },
  computed: {
    getProviders() {
      return this.$store.getters.getSearchProviders;
    },
    urlData() {
      return this.$store.getters.getSearchUrl;
    },
    route() {
      return this.$route.path;
    },
    routeParams() {
      return this.$route.params.name;
    },
    providerType() {
      return this.$store.getters.getProviderType;
    },
  },
  methods: {
    closeExistsModal() {
      this.$refs.exists_modal.closePopUp();
    },
    goBack() {
      this.$router.push(`/provider-advisor/providers`);
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
      this.$router.push(`/provider-advisor/providers`);
    },
    singleProvider(item) {
      this.$store.commit("setSingleProviderInEmployerAdvisor", item);
      this.$router.push(`/provider-advisor/providers/result/npi`);
    },
    searchProviders(val) {
      this.items = this.filterArray.filter((el) => {
        if (
          el["Provider Last Name (Legal Name)"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el["Provider Last Name (Legal Name)"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el["Authorized Official Last Name"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el["Authorized Official Last Name"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el["Authorized Official First Name"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el["Authorized Official First Name"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el["Provider First Name"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el["Provider First Name"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el["NPI"].toString().toLowerCase().includes(val.toLowerCase())
        ) {
          return el["NPI"].toString().toLowerCase().includes(val.toLowerCase());
        } else if (
          el["Healthcare Provider Taxonomy Code_1"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el["Healthcare Provider Taxonomy Code_1"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el["Provider Business Practice Location Address Telephone Number"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el[
            "Provider Business Practice Location Address Telephone Number"
          ]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el["Provider First Line Business Mailing Address"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el["Provider First Line Business Mailing Address"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el["Provider Organization Name (Legal Business Name)"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el["Provider Organization Name (Legal Business Name)"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el["NPI"].toString().toLowerCase().includes(val.toLowerCase())
        ) {
          return el["NPI"].toString().toLowerCase().includes(val.toLowerCase());
        } else if (
          el["Healthcare Provider Taxonomy Code_1"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el["Healthcare Provider Taxonomy Code_1"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el["Provider First Line Business Mailing Address"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el["Provider First Line Business Mailing Address"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        }
      });
    },
    loadMoreData() {
      this.loadData = true;
      api()
        .get(`/api/npidata/search?${this.urlData}`)
        .then((res) => {
          if (res) {
            if (res.data.items.length < 100) {
              this.showButton = false;
            }
            res.data.items.forEach((el) => {
              this.items.push(el);
            });
            this.allRows = false;
            this.filteredArray = this.items;
            this.loadData = false;
          }
        });
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    addProviders() {
      let data = {
        search: "npidata",
        ids: this.selectedRows,
      };
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.providerAdvisor;
      let employer = JSON.parse(localStorage.getItem("employer"));
      let existAray = [];
      let exists = false;
      api()
        .get(`/advisors/${advisorId}/providers`)
        .then((res) => {
          if (res) {
            for (let i = 0; i < res.data.providers.length; i++) {
              for (let j = 0; j < this.selectedRows.length; j++) {
                if (res.data.providers[i]._id === this.selectedRows[j]) {
                  existAray.push(this.selectedRows[j]);
                  exists = true;
                }
              }
            }
          }
        });

      if (!exists) {
        api()
          .post(`/advisors/${advisorId}/providers/npidata`, data)
          .then((res) => {
            let text = "Providers added successfully to this Employer!";
            this.$refs.success_modal.openPopUp(text);
          });
      } else {
       
        let text = "Providers already exist ";
        this.$refs.exists_modal.openPopUp(text, existAray);
      }
    },

    selectAllRows() {
      this.selectedRows = [];
      this.selectAll = !this.selectAll;

      if (!this.selectAll) {
        this.selectedRows = [];
      } else {
        this.selectedRows = this.items;
      }
    },
    activeRow(index, item) {
      let itemById = document.getElementById("id" + index);
      itemById.classList.toggle("activeItem");

      if (itemById.classList.contains("activeItem")) {
        this.selectedRows.push(item);
      } else {
        let removeItem = this.selectedRows.find((x) => x._id === item._id);
        this.selectedRows.splice(this.selectedRows.indexOf(removeItem), 1);
      }
    },
  },
};
</script>
<style>
.buttons-left {
  justify-content: left;
  align-content: left !important;
  align-items: left !important;
}
</style>