<template>
  <div>
    <div class="row" v-if="statistics">
      <div v-for="item in items" :key="item.title"
        class="text-center justify-center align-center col-md-4 col-xl-3 col-lg-3"
        @click="clientsTable">
        <v-card style="height: 13rem; cursor: pointer" @click="redirectToAddEmployer">
          <img :src="item.img" height="67" width="75" class="client-home-img-cards mt-xl-4"/>
          <h3 class="text-center justify-center blue-text font">
            {{ item.title }}
          </h3>
          <v-card-text>
            <v-row align="center" class="mx-md-1 mx-lg-4 justify-center">
              <h4 class="text-center card-desc-responsive font-quicksand-medium-18" style="font-weight: normal">
                {{ item.desc }}
              </h4>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
      <div
        class="
          text-center
          justify-center
          align-center
          col-md-8 col-xl-6 col-lg-6
        "
      >
        <v-card
          class="space-around-content"
          style="height: 13rem; cursor: pointer"
          @click="
            $role != 'user' ? $router.push('/employer-advisor/wizard-form') : ''
          "
        >
          <v-row align="center" class="mx-0 justify-center">
            <h5
              class="text-center text-underline mt-4 font-quicksand-medium-18"
            >
              Easy Setup
            </h5>
          </v-row>
          <v-row class="text-center justify-center">
            <img src="/img2.png" id="setup-img-emp" alt="" />
          </v-row>
          <v-row align="center" class="mx-5 justify-center my-0">
            <h5
              class="
                red-desc
                card-desc-responsive
                text-center
                font-quicksand-medium-18
                mb-5
              "
            >
              Instantly view your employer client’s estimated savings and
              provider coverage.
            </h5>
          </v-row>
        </v-card>
      </div>
      <div v-for="(item, index) in precentageCards" :key="index" class="col-md-4 col-xl-3 col-lg-3 text-center justify-center aling-center">
        <v-card style="height: 13rem; cursor: pointer" v-if="statistics" class="space-around-content">
          <div class="space-around-content row" style="height: 10rem">
            <v-row align="center" class="mx-0 justify-center">
              <h5
                class="text-center text-underline font-quicksand-medium-18"
                :class="
                  item.title != 'Employer Clients and Members' &&
                  item.title != 'Providers'
                    ? 'mt-5'
                    : ''
                "
              >
                {{ item.title }}
              </h5>
            </v-row>
            <v-card-title class="text-center justify-center text-gray font percentage-num">
              <div v-if="item.title == 'Employer Clients and Members'" class="row justify-space-around blue-text" style="width: 300px">
                <div class="font-weight-bold">
                  <h1 class="blue-text mb-5">
                    {{ statistics.noEmployers }} <br />
                  </h1>
                  Employers
                </div>
                <div class="font-weight-bold">
                  <h1 class="blue-text mb-5">
                    {{ statistics.noPlanMembers }} <br />
                  </h1>
                  Members
                </div>
              </div>
              <div v-else-if="item.title == 'Current Contract Rates'" class="row justify-space-around blue-text">
                <div class="font-weight-bold">
                  <h1 class="blue-text mb-2">
                    <span v-if="!statistics.rates.inpatient">0</span>
                    <span v-else>{{ statistics.rates.inpatient }}</span
                    >% <br />
                  </h1>

                  Inpatient
                </div>
                <div class="ml-15 font-weight-bold">
                  <h1 class="blue-text mb-2">
                    <span v-if="!statistics.rates.outpatient">0</span>
                    <span v-else>{{ statistics.rates.outpatient }}</span
                    >% <br />
                  </h1>
                  Outpatient
                </div>
              </div>

              <div v-else-if="item.title == 'Providers'" class="blue-text">
                <h1>
                  {{ statistics.providers }}
                </h1>
              </div>
              <div
                v-else-if="item.title == 'Projected Savings'"
                class="blue-text"
              >
                <h1>{{ statistics.projectedSavings }}%</h1>
              </div>
              <div
                v-else-if="item.title == 'Provider Coverage'"
                class="blue-text"
              >
                <h1>{{ statistics.providerCoverage }}%</h1>
              </div>
              <div
                v-else-if="item.title == 'Contract Renewals'"
                class="blue-text"
              >
                <h1>{{ statistics.contractRenewals }}</h1>
              </div>
            </v-card-title>
            <v-row align="center" class="mx-10 justify-center">
              <h5
                class="
                  blue-text
                  text-center
                  card-desc-responsive
                  font-quicksand-medium-18
                  pb-6
                "
                style="line-height: 20px"
                v-if="item.desc"
              >
                {{ item.desc }}
              </h5>
            </v-row>
          </div>
        </v-card>
      </div>
    </div>
    <div class="row" v-if="statistics">
      <div
        v-for="(item, index) in lastTwo"
        :key="index"
        class="col-md-3 text-center justify-center aling-center"
      >
        <v-card
          style="height: 13rem; cursor: pointer"
          class="space-around-content"
        >
          <v-row align="center" class="mx-0 justify-center">
            <h5 class="ms-4 text-center text-underline mt-2">
              {{ item.title }}
            </h5>
          </v-row>
          <v-card-title
            class="text-center justify-center text-gray font percentage-num"
          >
            <div v-if="item.title == 'Providers'" class="blue-text">
              <h1>
                {{ statistics.providers }}
              </h1>
            </div>
            <div
              v-else-if="item.title == 'Provider Coverage'"
              class="blue-text"
            >
              <h1>{{ statistics.providerCoverage }}%</h1>
            </div>
          </v-card-title>
          <v-row
            align="center"
            class="justify-center mt-2"
            :class="item.desc.length > 50 ? 'mx-7 mb-1' : 'mx-15 mb-2'"
          >
            <h5
              class="
                blue-text
                text-center
                card-desc-responsive
                font-quicksand-medium-18
              "
              v-if="item.desc"
            >
              {{ item.desc }}
            </h5>
          </v-row>
        </v-card>
      </div>
    </div>
    <div v-if="!statistics">
      <v-row v-if="loader" class="justify-center col-md-12 mt-20">
        <v-progress-circular
          :size="100"
          class="text-center mt-10"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-row>
    </div>
  </div>
</template>
<script>
import api from "../../../services/api";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      loader: true,
      items: [
        {
          img: "/cards/woman.png",
          title: "Add an Employer",
          desc: "First create an employer profile, and then be able to explore savings and provider coverage.",
        },
      ],
      lastTwo: [
        //    {
        //     title: 'Provider Coverage',
        //     percentage:'94%',
        //     desc:'percentage of members with providers within a 10 mile radius of their zip code'
        // },
        // {
        //     title: 'Providers',
        //     percentage:'48,000',
        //     desc:'based on your client’s current provider lists'
        // },
      ],
      precentageCards: [
        {
          title: "Employer Clients and Members",
          percentage: "74",
          percentage1: "1,450",
          clients: true,
        },
        {
          title: "Contract Renewals",
          percentage: "5",
          desc: "number of employer contracts up for renewal within the next 90 days",
        },
        {
          title: "Current Contract Rates",
          percentage: "150%",
          percentage1: "180%",
          desc: "of Medicare benchmark rates, as an averages accross all clients",
          inpatient: true,
        },
        {
          title: "Providers",
          percentage: "48,000",
          desc: "based on your client’s current provider lists",
        },
      ],
      dialog: false,
      allEmployers: [],
      searchedEmployers: [],
      statistics: null,
    };
  },
  computed: {
    ...mapGetters(["getStatistcis"]),
  },
  methods: {
    redirectToAddEmployer() {
      if (this.$role != "user") {
        this.$store.commit("routeFromHomeCard", true);
        this.$router.push("/employer-advisor/employers/add-new");
      }
    },
    parsedDate(date) {
      let returnData = "/";
      if (date) {
        let newDate = new Date(date);
        returnData =
          newDate.getMonth() +
          "/" +
          newDate.getDay() +
          "/" +
          newDate.getFullYear();
      }
      return returnData;
    },
    goToAccess(item) {
      this.$router.push("/employer-advisor/employers/employer");
      localStorage.setItem("employer", JSON.stringify(item));
      // this.$router.push({path:'/employer-advisor/employers/'+ item.companyName, params:{name:item.companyName}})
    },
    singleEmployer(item) {
      this.$router.push(`/employer-advisor/employers/${item.companyName}`);
      localStorage.setItem("employer", JSON.stringify(item));
    },
    clientsTable() {
      this.dialog = !this.dialog;
      this.dialog1 = !this.dialog1;
      this.$emit("showClient");
    },
    getInfo() {
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.employerAdvisor;
      api()
        .get(`/advisors/${advisorId}/statistics`)
        .then((res) => {
          this.statistics = res.data.statistics;
          this.loader = false;
          this.$store.commit("setStatisticsInfo", res.data.statistics);
          localStorage.setItem(
            "statisticsEa",
            JSON.stringify(res.data.statistics)
          );
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
  },
  created() {
    this.getInfo();
  },
};
</script>
<style>
.v-stepper {
  width: 100% !important;
}
</style>
