<template>
    <div>
        <Bundles v-if="path == `/provider/bundles`"></Bundles>        
        <Builder v-if="path == `/provider/bundles/builder`"></Builder>
        <SingleBundle  v-if="path == `/provider/bundles/bundle`"></SingleBundle>

    </div>
</template>
<script>
import Bundles from './Bundles.vue'
import Builder from './Builder.vue'
import SingleBundle from './SingleBundle.vue'

export default {
    components:{
        Bundles,
        Builder,
        SingleBundle
    },
    computed:{
        path(){
            return this.$route.path
        },
        routeParams(){
        let parsed =''
            if(this.$route.params.name){
            parsed = this.$route.params.name.replace(/ /g, '%20')
            }
            return parsed
        }
    }
}
</script>