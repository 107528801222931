<template>
  <div :class="windowWidth ? 'mainSideBarDiv' : 'mainSideBarMobile'">
    <v-navigation-drawer
      v-if="windowWidth"
      :width="!mini ? '800' : '69'"
      permanent
      class="white-sidebar"
    >
      <div>
        <v-divider></v-divider>
        <v-list-item
          two-line
          class="mt-3"
          :class="
            watchActiveItem == 'home' ? 'pointer active' : 'pointer inactive'
          "
          @click="activeItem('home')"
        >
          <v-list-item-icon
            class="list-item-icon no-padding"
            :class="!mini ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-3' : 'ml-4 mt-3'"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.7319 6.49L9.00356 0.659668L1.26809 6.49C1.10817 6.63755 1.01201 6.84157 1 7.05881V15.9622C1.00056 16.1464 1.07402 16.323 1.20432 16.4532C1.33462 16.5835 1.51119 16.657 1.69547 16.6575H6.33333V12.9268C6.33333 12.2197 6.61429 11.5415 7.11438 11.0415C7.61448 10.5414 8.29276 10.2605 9 10.2605C9.70724 10.2605 10.3855 10.5414 10.8856 11.0415C11.3857 11.5415 11.6667 12.2197 11.6667 12.9268V16.6597H16.3038C16.4882 16.6591 16.6648 16.5857 16.7952 16.4554C16.9256 16.3252 16.9993 16.1486 17 15.9643V7.05881C16.9877 6.84165 16.8916 6.63773 16.7319 6.49Z"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title
              class="
                d-flex
                align-center
                font-quicksand-medium-12
                text-gray-darker
               
               
              "
            >
              Home
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          two-line
          :class="
            watchActiveItem == 'family' ? 'pointer active' : 'pointer inactive'
          "
          @click="activeItem('family')"
        >
          <v-list-item-icon
            class="list-item-icon no-padding"
            :class="
              !mini ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-xl-4 mt-lg-4 ' : 'ml-4 mt-4'
            "
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.6322 12.7686L12.2002 14.2685"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.34375 12.7824H5.30375L8.11175 18.1551C8.21361 18.3458 8.33692 18.4996 8.47436 18.6073C8.61179 18.7151 8.76054 18.7746 8.91175 18.7824C10.5465 16.7768 12.0823 14.5466 13.5037 12.1142C13.5917 11.6915 13.4238 11.2688 13.2718 10.9143L11.2637 6.49609"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.86439 3.0732L8.58439 2.66411C8.29678 2.31524 7.95338 2.12489 7.60039 2.11866C7.38661 2.11788 7.17475 2.18733 6.97639 2.3232L3.40039 4.75046"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1 2.41846H2.6C2.80154 2.41736 2.99589 2.54596 3.14421 2.77855C3.29253 3.01114 3.38388 3.33058 3.4 3.673V12.4275C3.38198 12.7675 3.2898 13.0836 3.14168 13.3133C2.99356 13.5431 2.80027 13.6698 2.6 13.6684H1"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.9996 13.6684H17.3996C17.1993 13.6698 17.0061 13.5431 16.8579 13.3133C16.7098 13.0836 16.6176 12.7675 16.5996 12.4275V3.673C16.6157 3.33058 16.7071 3.01114 16.8554 2.77855C17.0037 2.54596 17.1981 2.41736 17.3996 2.41846H18.9996"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12.2001 5.96411L9.44014 7.51864C9.13574 7.70007 8.80248 7.68026 8.50623 7.46312C8.20998 7.24598 7.97229 6.84732 7.84014 6.34592C7.69777 5.82356 7.67992 5.22738 7.79035 4.68302C7.90078 4.13866 8.13088 3.68863 8.43214 3.42775L11.1201 1.12322C11.3827 0.895505 11.6735 0.778592 11.9681 0.782313C12.1862 0.784815 12.4025 0.849337 12.6081 0.973221L16.6081 3.45503"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title
              class="
                d-flex
                align-center
                font-quicksand-medium-12
                text-gray-darker
              "
            >
              Family Members
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          two-line
          :class="
            watchActiveItem == 'visit-passes'
              ? 'pointer active'
              : 'pointer inactive'
          "
          @click="activeItem('visit-passes')"
        >
          <v-list-item-icon
            class="list-item-icon no-padding"
            :class="
              !mini ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-3 ' : 'ml-4 mt-3'
            "
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.2002 11.8C15.6217 12.3923 16.836 13.392 17.6902 14.6733C18.5444 15.9546 19.0002 17.4601 19.0002 19"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.40039 18.9999C3.40331 17.953 3.61736 16.9175 4.02974 15.9553C4.44211 14.9931 5.04434 14.124 5.80039 13.3999"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14.2002 10.6V12.4C14.2002 13.1956 13.8841 13.9587 13.3215 14.5213C12.7589 15.0839 11.9958 15.4 11.2002 15.4C10.4045 15.4 9.64148 15.0839 9.07887 14.5213C8.51627 13.9587 8.2002 13.1956 8.2002 12.4V10.6"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M2.6 9.80009C3.48366 9.80009 4.2 9.08374 4.2 8.20009C4.2 7.31643 3.48366 6.60009 2.6 6.60009C1.71634 6.60009 1 7.31643 1 8.20009C1 9.08374 1.71634 9.80009 2.6 9.80009Z"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M4.19238 8.33593C4.66051 8.50144 5.06578 8.80806 5.35234 9.21354C5.6389 9.61903 5.79264 10.1034 5.79238 10.5999V17.1999C5.79238 17.437 5.8392 17.6717 5.93016 17.8906C6.02112 18.1095 6.15442 18.3083 6.32242 18.4755C6.49041 18.6428 6.68979 18.7752 6.9091 18.8652C7.12841 18.9552 7.36333 19.001 7.60038 18.9999H9.40038C9.87777 18.9999 10.3356 18.8103 10.6732 18.4727C11.0107 18.1351 11.2004 17.6773 11.2004 17.1999V15.3999"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.2 9.39999C13.5196 9.39999 15.4 7.51959 15.4 5.2C15.4 2.8804 13.5196 1 11.2 1C8.8804 1 7 2.8804 7 5.2C7 7.51959 8.8804 9.39999 11.2 9.39999Z"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.59961 3.06396C8.24732 3.73694 9.02373 4.27283 9.88267 4.63977C10.7416 5.00671 11.6656 5.19722 12.5996 5.19996C13.5489 5.20262 14.4885 5.00925 15.3596 4.63196"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title
              class="
                d-flex
                align-center
                font-quicksand-medium-12
                text-gray-darker
              "
            >
              Visit Passes
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          two-line
          :class="
            watchActiveItem == 'care' ? 'pointer active' : 'pointer inactive'
          "
          @click="activeItem('care')"
        >
          <v-list-item-icon
            class="list-item-icon no-padding"
            :class="
              !mini ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-3 ' : 'ml-4 mt-3'
            "
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 2.6738L2.2 4.18767V7.7619C2.168 9.11254 3.7336 10.3224 5.1992 11.5642"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M4.58509 6.78734L6.00029 8.2372C6.08959 8.32888 6.19606 8.40207 6.31364 8.45259C6.43122 8.50311 6.5576 8.52997 6.68556 8.53164C6.81352 8.53331 6.94055 8.50976 7.05941 8.46233C7.17827 8.4149 7.28662 8.34452 7.37829 8.2552C7.46995 8.16589 7.54313 8.05939 7.59364 7.94179C7.64415 7.82419 7.67101 7.69779 7.67268 7.56981C7.67435 7.44182 7.6508 7.31477 7.60338 7.19589C7.55596 7.07701 7.48558 6.96863 7.39629 6.87696L5.19549 4.63096V3.46035C5.07625 2.7589 4.80958 2.09074 4.41309 1.5"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.2002 7.41145V17.1028C5.2002 17.4211 5.32662 17.7264 5.55167 17.9515C5.77671 18.1765 6.08194 18.303 6.4002 18.303H12.4002"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.9996 18.3286L17.7996 16.8147V13.2413C17.8276 12.0739 16.6628 11.0121 15.3996 9.94472V14.1999L13.9996 12.766C13.8193 12.5809 13.5728 12.4749 13.3144 12.4716C13.0559 12.4682 12.8067 12.5676 12.6216 12.748C12.4365 12.9284 12.3306 13.1749 12.3272 13.4334C12.3239 13.6919 12.4233 13.9411 12.6036 14.1263L14.8076 16.3731V17.5429C14.9275 18.2432 15.1941 18.9101 15.59 19.5"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.782 15.3025H7.59961"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.79961 12.3019H7.59961"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.6004 5.10064C16.6004 5.81278 16.3893 6.50893 15.9937 7.10105C15.5981 7.69317 15.0359 8.15467 14.3781 8.4272C13.7202 8.69972 12.9964 8.77103 12.2981 8.6321C11.5997 8.49316 10.9583 8.15024 10.4548 7.64668C9.95134 7.14312 9.60847 6.50155 9.46957 5.80309C9.33066 5.10463 9.40195 4.38067 9.67443 3.72273C9.9469 3.0648 10.4083 2.50246 11.0003 2.10682C11.5924 1.71117 12.2884 1.5 13.0004 1.5"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.5996 2.39938L13.3196 5.67997C13.2777 5.72216 13.2278 5.75564 13.1729 5.77849C13.118 5.80134 13.0591 5.8131 12.9996 5.8131C12.9402 5.8131 12.8813 5.80134 12.8263 5.77849C12.7714 5.75564 12.7216 5.72216 12.6796 5.67997L11.6484 4.64778"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title
              class="
                d-flex
                align-center
                font-quicksand-medium-12
                text-gray-darker
              "
            >
              Find Care
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          two-line
          :class="
            watchActiveItem == 'contact' ? 'pointer active' : 'pointer inactive'
          "
          @click="activeItem('contact')"
        >
          <v-list-item-icon
            class="list-item-icon no-padding"
            :class="
              !mini ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-3 ' : 'ml-4 mt-3'
            "
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 2.6738L2.2 4.18767V7.7619C2.168 9.11254 3.7336 10.3224 5.1992 11.5642"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M4.58509 6.78734L6.00029 8.2372C6.08959 8.32888 6.19606 8.40207 6.31364 8.45259C6.43122 8.50311 6.5576 8.52997 6.68556 8.53164C6.81352 8.53331 6.94055 8.50976 7.05941 8.46233C7.17827 8.4149 7.28662 8.34452 7.37829 8.2552C7.46995 8.16589 7.54313 8.05939 7.59364 7.94179C7.64415 7.82419 7.67101 7.69779 7.67268 7.56981C7.67435 7.44182 7.6508 7.31477 7.60338 7.19589C7.55596 7.07701 7.48558 6.96863 7.39629 6.87696L5.19549 4.63096V3.46035C5.07625 2.7589 4.80958 2.09074 4.41309 1.5"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.2002 7.41145V17.1028C5.2002 17.4211 5.32662 17.7264 5.55167 17.9515C5.77671 18.1765 6.08194 18.303 6.4002 18.303H12.4002"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.9996 18.3286L17.7996 16.8147V13.2413C17.8276 12.0739 16.6628 11.0121 15.3996 9.94472V14.1999L13.9996 12.766C13.8193 12.5809 13.5728 12.4749 13.3144 12.4716C13.0559 12.4682 12.8067 12.5676 12.6216 12.748C12.4365 12.9284 12.3306 13.1749 12.3272 13.4334C12.3239 13.6919 12.4233 13.9411 12.6036 14.1263L14.8076 16.3731V17.5429C14.9275 18.2432 15.1941 18.9101 15.59 19.5"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.782 15.3025H7.59961"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.79961 12.3019H7.59961"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.6004 5.10064C16.6004 5.81278 16.3893 6.50893 15.9937 7.10105C15.5981 7.69317 15.0359 8.15467 14.3781 8.4272C13.7202 8.69972 12.9964 8.77103 12.2981 8.6321C11.5997 8.49316 10.9583 8.15024 10.4548 7.64668C9.95134 7.14312 9.60847 6.50155 9.46957 5.80309C9.33066 5.10463 9.40195 4.38067 9.67443 3.72273C9.9469 3.0648 10.4083 2.50246 11.0003 2.10682C11.5924 1.71117 12.2884 1.5 13.0004 1.5"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.5996 2.39938L13.3196 5.67997C13.2777 5.72216 13.2278 5.75564 13.1729 5.77849C13.118 5.80134 13.0591 5.8131 12.9996 5.8131C12.9402 5.8131 12.8813 5.80134 12.8263 5.77849C12.7714 5.75564 12.7216 5.72216 12.6796 5.67997L11.6484 4.64778"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title
              class="
                d-flex
                align-center
                font-quicksand-medium-12
                text-gray-darker
              "
            >
              Contact us
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
        <v-list-group
          :value="false"
          :class="
            watchActiveItem == 'account' ||
            watchActiveItem == 'account/info' ||
            watchActiveItem == 'account/payment' ||
            watchActiveItem == 'account/settings'
              ? 'pointer active-settings'
              : 'pointer  inactive'
          "
        >
          <template v-slot:activator>
            <v-list-item-icon
              @click="activeItem('accoount')"
              class="list-item-icon no-padding"
              :class="!mini ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-3 ' : 'ml-4 mt-3'"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.84668 3.1472C7.84668 3.71667 8.07356 4.26282 8.47741 4.6655C8.88126 5.06818 9.429 5.2944 10.0001 5.2944C10.5713 5.2944 11.119 5.06818 11.5229 4.6655C11.9267 4.26282 12.1536 3.71667 12.1536 3.1472C12.1536 2.57773 11.9267 2.03158 11.5229 1.6289C11.119 1.22622 10.5713 1 10.0001 1C9.429 1 8.88126 1.22622 8.47741 1.6289C8.07356 2.03158 7.84668 2.57773 7.84668 3.1472V3.1472Z"
                  stroke="#707070"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.5654 10.5C12.8102 10.4961 13.0523 10.5513 13.2709 10.6611C13.4896 10.7709 13.6782 10.932 13.8208 11.1304C13.9633 11.3288 14.0555 11.5588 14.0893 11.8005C14.1232 12.0423 14.0978 12.2886 14.0152 12.5184"
                  stroke="#707070"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10 5.2944V19"
                  stroke="#707070"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.9746 4H18.3042C20.452 4 17.3414 9.1624 12.8708 7.44"
                  stroke="#707070"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.0233 4H1.69532C-0.451716 4 2.65812 9.1624 7.1287 7.44"
                  stroke="#707070"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.4342 10.5C7.03072 10.4959 6.64208 10.6516 6.35347 10.9327C6.06486 11.2139 5.89982 11.5977 5.89453 12C5.89666 12.4099 6.05844 12.8029 6.34572 13.096C6.633 13.3892 7.0233 13.5596 7.4342 13.5712H11.5389C11.9473 13.5712 12.3389 13.7329 12.6276 14.0209C12.9164 14.3088 13.0786 14.6992 13.0786 15.1064C13.0786 15.5136 12.9164 15.904 12.6276 16.192C12.3389 16.4799 11.9473 16.6416 11.5389 16.6416H8.71713C8.36869 16.6496 8.03769 16.7952 7.79695 17.0465C7.55621 17.2978 7.42544 17.6342 7.4334 17.9816C7.43239 18.1681 7.46966 18.3528 7.54293 18.5243C7.6162 18.6959 7.72391 18.8507 7.85944 18.9792"
                  stroke="#707070"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </v-list-item-icon>
            <v-list-item-title
              :class="
                watchActiveItem == 'account' ||
                watchActiveItem == 'account/info' ||
                watchActiveItem == 'account/payment' ||
                watchActiveItem == 'account/settings'
                  ? 'pointer active-settings font-quicksand-medium-12 '
                  : 'pointer inactive font-quicksand-medium-12'
              "
              @click="activeItem('account')"
              class="text-gray-darker"
              >Account
            </v-list-item-title>
          </template>
          <div class="ml-16 text-gray-darker font-quicksand-medium-12">
            <div
              :class="
                watchActiveItem == 'account/info'
                  ? 'pointer active-settings'
                  : 'pointer inactive'
              "
              class="pointer ml-2 mt-1"
              @click="activeItem('account/info')"
            >
              Personal Informaton
            </div>
            <div
              :class="
                watchActiveItem == 'account/payment'
                  ? 'pointer active-settings'
                  : 'pointer inactive'
              "
              class="pointer ml-2 mt-3"
              @click="activeItem('account/payment')"
            >
              Billing & Payment
            </div>
            <div
              :class="
                watchActiveItem == 'account/settings'
                  ? 'pointer active-settings'
                  : 'pointer inactive'
              "
              class="pointer mt-3 ml-2"
              @click="activeItem('account/settings')"
            >
              Settings
            </div>
          </div>
        </v-list-group>
      </div>
      <div>
        <v-divider></v-divider>
        <v-list-item two-line v-if="!mini" class="mb-2">
          <span class="orange--text mt-1 ml-1" style="font-size: 9px">
            Powered by:
          </span>

          <v-list-item-content
            class="no-padding pointer justify-center mt-2 mb-2"
          >
            <v-list-item-title
              class="d-flex mb-3 align-center text-center text-gray-darker"
            >
              <img src="/apalyhealthlogo.png" alt="" class="mt-1 ml-1" />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line v-else class="mt-4">
          <v-list-item-content class="no-padding pointer">
            <v-list-item-title
              class="d-flex mb-3 align-center text-center text-gray-darker"
            >
              <img
                src="/apaly1.png"
                class="ml-1"
                width="95"
                height="45"
                alt=""
              />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-navigation-drawer>
    <!-- Mobile -->
    <v-navigation-drawer v-else permanent class="white-sidebar-onmobile mt-3">
      <div>
        <v-divider></v-divider>
        <v-list-item
          two-line
          class="mt-3"
          :class="
            watchActiveItem == 'home' ? 'pointer active' : 'pointer inactive'
          "
          @click="activeItem('home')"
        >
          <v-list-item-icon
            class="list-item-icon no-padding"
            :class="!mini ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-3' : 'ml-4 mt-3'"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.7319 6.49L9.00356 0.659668L1.26809 6.49C1.10817 6.63755 1.01201 6.84157 1 7.05881V15.9622C1.00056 16.1464 1.07402 16.323 1.20432 16.4532C1.33462 16.5835 1.51119 16.657 1.69547 16.6575H6.33333V12.9268C6.33333 12.2197 6.61429 11.5415 7.11438 11.0415C7.61448 10.5414 8.29276 10.2605 9 10.2605C9.70724 10.2605 10.3855 10.5414 10.8856 11.0415C11.3857 11.5415 11.6667 12.2197 11.6667 12.9268V16.6597H16.3038C16.4882 16.6591 16.6648 16.5857 16.7952 16.4554C16.9256 16.3252 16.9993 16.1486 17 15.9643V7.05881C16.9877 6.84165 16.8916 6.63773 16.7319 6.49Z"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title
              class="
                d-flex
                align-center
                font-quicksand-medium-12
                text-gray-darker
               
               
              "
            >
              Home
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          two-line
          :class="
            watchActiveItem == 'family' ? 'pointer active' : 'pointer inactive'
          "
          @click="activeItem('family')"
        >
          <v-list-item-icon
            class="list-item-icon no-padding"
            :class="
              !mini ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-xl-4 mt-lg-4 ' : 'ml-4 mt-4'
            "
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.6322 12.7686L12.2002 14.2685"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.34375 12.7824H5.30375L8.11175 18.1551C8.21361 18.3458 8.33692 18.4996 8.47436 18.6073C8.61179 18.7151 8.76054 18.7746 8.91175 18.7824C10.5465 16.7768 12.0823 14.5466 13.5037 12.1142C13.5917 11.6915 13.4238 11.2688 13.2718 10.9143L11.2637 6.49609"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.86439 3.0732L8.58439 2.66411C8.29678 2.31524 7.95338 2.12489 7.60039 2.11866C7.38661 2.11788 7.17475 2.18733 6.97639 2.3232L3.40039 4.75046"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1 2.41846H2.6C2.80154 2.41736 2.99589 2.54596 3.14421 2.77855C3.29253 3.01114 3.38388 3.33058 3.4 3.673V12.4275C3.38198 12.7675 3.2898 13.0836 3.14168 13.3133C2.99356 13.5431 2.80027 13.6698 2.6 13.6684H1"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.9996 13.6684H17.3996C17.1993 13.6698 17.0061 13.5431 16.8579 13.3133C16.7098 13.0836 16.6176 12.7675 16.5996 12.4275V3.673C16.6157 3.33058 16.7071 3.01114 16.8554 2.77855C17.0037 2.54596 17.1981 2.41736 17.3996 2.41846H18.9996"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12.2001 5.96411L9.44014 7.51864C9.13574 7.70007 8.80248 7.68026 8.50623 7.46312C8.20998 7.24598 7.97229 6.84732 7.84014 6.34592C7.69777 5.82356 7.67992 5.22738 7.79035 4.68302C7.90078 4.13866 8.13088 3.68863 8.43214 3.42775L11.1201 1.12322C11.3827 0.895505 11.6735 0.778592 11.9681 0.782313C12.1862 0.784815 12.4025 0.849337 12.6081 0.973221L16.6081 3.45503"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title
              class="
                d-flex
                align-center
                font-quicksand-medium-12
                text-gray-darker
              "
            >
              Family Members
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          two-line
          :class="
            watchActiveItem == 'visit-passes'
              ? 'pointer active'
              : 'pointer inactive'
          "
          @click="activeItem('visit-passes')"
        >
          <v-list-item-icon
            class="list-item-icon no-padding"
            :class="
              !mini ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-3 ' : 'ml-4 mt-3'
            "
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.2002 11.8C15.6217 12.3923 16.836 13.392 17.6902 14.6733C18.5444 15.9546 19.0002 17.4601 19.0002 19"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.40039 18.9999C3.40331 17.953 3.61736 16.9175 4.02974 15.9553C4.44211 14.9931 5.04434 14.124 5.80039 13.3999"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14.2002 10.6V12.4C14.2002 13.1956 13.8841 13.9587 13.3215 14.5213C12.7589 15.0839 11.9958 15.4 11.2002 15.4C10.4045 15.4 9.64148 15.0839 9.07887 14.5213C8.51627 13.9587 8.2002 13.1956 8.2002 12.4V10.6"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M2.6 9.80009C3.48366 9.80009 4.2 9.08374 4.2 8.20009C4.2 7.31643 3.48366 6.60009 2.6 6.60009C1.71634 6.60009 1 7.31643 1 8.20009C1 9.08374 1.71634 9.80009 2.6 9.80009Z"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M4.19238 8.33593C4.66051 8.50144 5.06578 8.80806 5.35234 9.21354C5.6389 9.61903 5.79264 10.1034 5.79238 10.5999V17.1999C5.79238 17.437 5.8392 17.6717 5.93016 17.8906C6.02112 18.1095 6.15442 18.3083 6.32242 18.4755C6.49041 18.6428 6.68979 18.7752 6.9091 18.8652C7.12841 18.9552 7.36333 19.001 7.60038 18.9999H9.40038C9.87777 18.9999 10.3356 18.8103 10.6732 18.4727C11.0107 18.1351 11.2004 17.6773 11.2004 17.1999V15.3999"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.2 9.39999C13.5196 9.39999 15.4 7.51959 15.4 5.2C15.4 2.8804 13.5196 1 11.2 1C8.8804 1 7 2.8804 7 5.2C7 7.51959 8.8804 9.39999 11.2 9.39999Z"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.59961 3.06396C8.24732 3.73694 9.02373 4.27283 9.88267 4.63977C10.7416 5.00671 11.6656 5.19722 12.5996 5.19996C13.5489 5.20262 14.4885 5.00925 15.3596 4.63196"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title
              class="
                d-flex
                align-center
                font-quicksand-medium-12
                text-gray-darker
              "
            >
              Visit Passes
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          two-line
          :class="
            watchActiveItem == 'care' ? 'pointer active' : 'pointer inactive'
          "
          @click="activeItem('care')"
        >
          <v-list-item-icon
            class="list-item-icon no-padding"
            :class="
              !mini ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-3 ' : 'ml-4 mt-3'
            "
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 2.6738L2.2 4.18767V7.7619C2.168 9.11254 3.7336 10.3224 5.1992 11.5642"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M4.58509 6.78734L6.00029 8.2372C6.08959 8.32888 6.19606 8.40207 6.31364 8.45259C6.43122 8.50311 6.5576 8.52997 6.68556 8.53164C6.81352 8.53331 6.94055 8.50976 7.05941 8.46233C7.17827 8.4149 7.28662 8.34452 7.37829 8.2552C7.46995 8.16589 7.54313 8.05939 7.59364 7.94179C7.64415 7.82419 7.67101 7.69779 7.67268 7.56981C7.67435 7.44182 7.6508 7.31477 7.60338 7.19589C7.55596 7.07701 7.48558 6.96863 7.39629 6.87696L5.19549 4.63096V3.46035C5.07625 2.7589 4.80958 2.09074 4.41309 1.5"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.2002 7.41145V17.1028C5.2002 17.4211 5.32662 17.7264 5.55167 17.9515C5.77671 18.1765 6.08194 18.303 6.4002 18.303H12.4002"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.9996 18.3286L17.7996 16.8147V13.2413C17.8276 12.0739 16.6628 11.0121 15.3996 9.94472V14.1999L13.9996 12.766C13.8193 12.5809 13.5728 12.4749 13.3144 12.4716C13.0559 12.4682 12.8067 12.5676 12.6216 12.748C12.4365 12.9284 12.3306 13.1749 12.3272 13.4334C12.3239 13.6919 12.4233 13.9411 12.6036 14.1263L14.8076 16.3731V17.5429C14.9275 18.2432 15.1941 18.9101 15.59 19.5"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.782 15.3025H7.59961"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.79961 12.3019H7.59961"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.6004 5.10064C16.6004 5.81278 16.3893 6.50893 15.9937 7.10105C15.5981 7.69317 15.0359 8.15467 14.3781 8.4272C13.7202 8.69972 12.9964 8.77103 12.2981 8.6321C11.5997 8.49316 10.9583 8.15024 10.4548 7.64668C9.95134 7.14312 9.60847 6.50155 9.46957 5.80309C9.33066 5.10463 9.40195 4.38067 9.67443 3.72273C9.9469 3.0648 10.4083 2.50246 11.0003 2.10682C11.5924 1.71117 12.2884 1.5 13.0004 1.5"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.5996 2.39938L13.3196 5.67997C13.2777 5.72216 13.2278 5.75564 13.1729 5.77849C13.118 5.80134 13.0591 5.8131 12.9996 5.8131C12.9402 5.8131 12.8813 5.80134 12.8263 5.77849C12.7714 5.75564 12.7216 5.72216 12.6796 5.67997L11.6484 4.64778"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title
              class="
                d-flex
                align-center
                font-quicksand-medium-12
                text-gray-darker
              "
            >
              Find Care
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          two-line
          :class="
            watchActiveItem == 'contact' ? 'pointer active' : 'pointer inactive'
          "
          @click="activeItem('contact')"
        >
          <v-list-item-icon
            class="list-item-icon no-padding"
            :class="
              !mini ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-3 ' : 'ml-4 mt-3'
            "
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 2.6738L2.2 4.18767V7.7619C2.168 9.11254 3.7336 10.3224 5.1992 11.5642"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M4.58509 6.78734L6.00029 8.2372C6.08959 8.32888 6.19606 8.40207 6.31364 8.45259C6.43122 8.50311 6.5576 8.52997 6.68556 8.53164C6.81352 8.53331 6.94055 8.50976 7.05941 8.46233C7.17827 8.4149 7.28662 8.34452 7.37829 8.2552C7.46995 8.16589 7.54313 8.05939 7.59364 7.94179C7.64415 7.82419 7.67101 7.69779 7.67268 7.56981C7.67435 7.44182 7.6508 7.31477 7.60338 7.19589C7.55596 7.07701 7.48558 6.96863 7.39629 6.87696L5.19549 4.63096V3.46035C5.07625 2.7589 4.80958 2.09074 4.41309 1.5"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.2002 7.41145V17.1028C5.2002 17.4211 5.32662 17.7264 5.55167 17.9515C5.77671 18.1765 6.08194 18.303 6.4002 18.303H12.4002"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.9996 18.3286L17.7996 16.8147V13.2413C17.8276 12.0739 16.6628 11.0121 15.3996 9.94472V14.1999L13.9996 12.766C13.8193 12.5809 13.5728 12.4749 13.3144 12.4716C13.0559 12.4682 12.8067 12.5676 12.6216 12.748C12.4365 12.9284 12.3306 13.1749 12.3272 13.4334C12.3239 13.6919 12.4233 13.9411 12.6036 14.1263L14.8076 16.3731V17.5429C14.9275 18.2432 15.1941 18.9101 15.59 19.5"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.782 15.3025H7.59961"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.79961 12.3019H7.59961"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.6004 5.10064C16.6004 5.81278 16.3893 6.50893 15.9937 7.10105C15.5981 7.69317 15.0359 8.15467 14.3781 8.4272C13.7202 8.69972 12.9964 8.77103 12.2981 8.6321C11.5997 8.49316 10.9583 8.15024 10.4548 7.64668C9.95134 7.14312 9.60847 6.50155 9.46957 5.80309C9.33066 5.10463 9.40195 4.38067 9.67443 3.72273C9.9469 3.0648 10.4083 2.50246 11.0003 2.10682C11.5924 1.71117 12.2884 1.5 13.0004 1.5"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.5996 2.39938L13.3196 5.67997C13.2777 5.72216 13.2278 5.75564 13.1729 5.77849C13.118 5.80134 13.0591 5.8131 12.9996 5.8131C12.9402 5.8131 12.8813 5.80134 12.8263 5.77849C12.7714 5.75564 12.7216 5.72216 12.6796 5.67997L11.6484 4.64778"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title
              class="
                d-flex
                align-center
                font-quicksand-medium-12
                text-gray-darker
              "
            >
              Contact us
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
        <v-list-group
          :value="false"
          :class="
            watchActiveItem == 'account' ||
            watchActiveItem == 'account/info' ||
            watchActiveItem == 'account/payment' ||
            watchActiveItem == 'account/settings'
              ? 'pointer active-settings'
              : 'pointer  inactive'
          "
        >
          <template v-slot:activator>
            <v-list-item-icon
              @click="activeItem('accoount')"
              class="list-item-icon no-padding"
              :class="!mini ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-3 ' : 'ml-4 mt-3'"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.84668 3.1472C7.84668 3.71667 8.07356 4.26282 8.47741 4.6655C8.88126 5.06818 9.429 5.2944 10.0001 5.2944C10.5713 5.2944 11.119 5.06818 11.5229 4.6655C11.9267 4.26282 12.1536 3.71667 12.1536 3.1472C12.1536 2.57773 11.9267 2.03158 11.5229 1.6289C11.119 1.22622 10.5713 1 10.0001 1C9.429 1 8.88126 1.22622 8.47741 1.6289C8.07356 2.03158 7.84668 2.57773 7.84668 3.1472V3.1472Z"
                  stroke="#707070"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.5654 10.5C12.8102 10.4961 13.0523 10.5513 13.2709 10.6611C13.4896 10.7709 13.6782 10.932 13.8208 11.1304C13.9633 11.3288 14.0555 11.5588 14.0893 11.8005C14.1232 12.0423 14.0978 12.2886 14.0152 12.5184"
                  stroke="#707070"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10 5.2944V19"
                  stroke="#707070"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.9746 4H18.3042C20.452 4 17.3414 9.1624 12.8708 7.44"
                  stroke="#707070"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.0233 4H1.69532C-0.451716 4 2.65812 9.1624 7.1287 7.44"
                  stroke="#707070"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.4342 10.5C7.03072 10.4959 6.64208 10.6516 6.35347 10.9327C6.06486 11.2139 5.89982 11.5977 5.89453 12C5.89666 12.4099 6.05844 12.8029 6.34572 13.096C6.633 13.3892 7.0233 13.5596 7.4342 13.5712H11.5389C11.9473 13.5712 12.3389 13.7329 12.6276 14.0209C12.9164 14.3088 13.0786 14.6992 13.0786 15.1064C13.0786 15.5136 12.9164 15.904 12.6276 16.192C12.3389 16.4799 11.9473 16.6416 11.5389 16.6416H8.71713C8.36869 16.6496 8.03769 16.7952 7.79695 17.0465C7.55621 17.2978 7.42544 17.6342 7.4334 17.9816C7.43239 18.1681 7.46966 18.3528 7.54293 18.5243C7.6162 18.6959 7.72391 18.8507 7.85944 18.9792"
                  stroke="#707070"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </v-list-item-icon>
            <v-list-item-title
              :class="
                watchActiveItem == 'account' ||
                watchActiveItem == 'account/info' ||
                watchActiveItem == 'account/payment' ||
                watchActiveItem == 'account/settings'
                  ? 'pointer active-settings font-quicksand-medium-12 '
                  : 'pointer inactive font-quicksand-medium-12'
              "
              @click="activeItem('account')"
              class="text-gray-darker"
              >Account
            </v-list-item-title>
          </template>
          <div class="ml-16 text-gray-darker font-quicksand-medium-12">
            <div
              :class="
                watchActiveItem == 'account/info'
                  ? 'pointer active-settings'
                  : 'pointer inactive'
              "
              class="pointer ml-2 mt-1"
              @click="activeItem('account/info')"
            >
              Personal Informaton
            </div>
            <div
              :class="
                watchActiveItem == 'account/payment'
                  ? 'pointer active-settings'
                  : 'pointer inactive'
              "
              class="pointer ml-2 mt-3"
              @click="activeItem('account/payment')"
            >
              Billing & Payment
            </div>
            <div
              :class="
                watchActiveItem == 'account/settings'
                  ? 'pointer active-settings'
                  : 'pointer inactive'
              "
              class="pointer mt-3 ml-2"
              @click="activeItem('account/settings')"
            >
              Settings
            </div>
          </div>
        </v-list-group>
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script>
export default {
  name: "Tables",
  data() {
    return {
      miniVersion: true,
      item: "home",
      items: [
        {
          icon: "fas fa-home",
          title: "Home",
        },
        {
          icon: "fas fa-users",
          title: "Clients",
        },
        {
          icon: "fas fa-cog",
          title: "Settings",
        },
      ],
      route: this.$route.path,
    };
  },
  props: {
    windowWidth: {
      type: Boolean,
    },
    mini: {
      type: Boolean,
    },
  },

  computed: {
    routeParams() {
      return this.$route.params.name;
    },
    path() {
      return this.$route.path.replace(/%20/g, " ");
    },
    watchActiveItem() {
      if (
        this.$route.path.includes("/family-members/account/settings")
      ) {
        this.item = "account/settings";
      } else if (
        this.$route.path.includes("/family-members/account/payment")
      ) {
        this.item = "account/payment";
      } else if (this.$route.path.includes("/family-members/account/info")) {
        this.item = "account/info";
      } else if (this.$route.path.includes(this.$route.path.split("/")[2])) {
        this.item = this.$route.path.split("/")[2];
      }

      return this.item;
    },
  },
  created() {},
  methods: {
    activeItem(tab) {
      if (this.$route.path != `/family-members/${tab}`) {
        this.$router.push(`/family-members/${tab}`);
      }
      if (!this.windowWidth) {
        this.$emit("closeSideBar");
      }
    },
  },
};
</script>

