const state ={
    searchResultProvidersInEmployer:null,
    urlDataInEmployer:'',
    singleProviderInEmployer:null,
    zipSearchResult:[],
    fromMember:false,
    zipList:[]

}
const getters = {
    getSearchResultProvidersInEmployer(state){
        return state.searchResultProvidersInEmployer
    },
    getUrlDataInEmployer(state){
         return state.urlDataInEmployer
    },
    getSingleProviderInEmployer(state){
        return state.singleProviderInEmployer
    },
    getZipSearchResult(state){
        return state.zipSearchResult
    },
    checkIfSearchIsFromMember(){
        return state.fromMember
    },
    getZipList : () => state.zipList
}
const mutations = {
    zipListFromMembers(state,zips){
      state.zipList = zips
    },
    isSearchIsFromMember(state,fromMember){
        state.fromMember = fromMember
    },
    setSearchResultProvidersInEmployer(state,result){
        state.searchResultProvidersInEmployer = result
    },
    setUrlDataInEmployer(state,url){
        state.urlDataInEmployer = url
    },
    setSingleProviderInEmployer(state,provider){
        state.singleProviderInEmployer = provider
    },
    setZipSearchResult(state,result){
        state.zipSearchResult = result
    }
}

export default{
    state,
    getters,
    mutations,
}