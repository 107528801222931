const state ={
    filters:{},
}

const getters = {
  getFilters(state){
    return state.filters
  },
}

const mutations = {
    setSinglePackage(state,data){
        let tmpData = {
            _id:0,
            name:null,
            description:null,
            status:'active',
            solutions:[],
        }
        if(data != null){
            tmpData = data
        }
        state.singlePackage = tmpData
    },
    setFilters(state,data){
        state.filters[data.title] = data.value
    },
    clearFilters(state){
        state.filters = {}
    },
    clearFilter(state,data){
        delete state.filters[data]
    },
}

export default{
    state,
    getters,
    mutations,
}