<template>
  <v-row class="add-client-form no-padding row-height">
    <div class="col-md-12">
      <successModal
        ref="success_modal"
        v-on:confirm="closeSuccessModal"
      ></successModal>
      <div class="row">
        <div class="col-md-12">
          <h3 class="form-title font">Account</h3>
        </div>
      </div>
      <div class="col-md-12 text-center justify-center" v-if="readyToShow">
        <v-progress-circular
          indeterminate
          :size="100"
          color="primary"
          class="text-center"
        ></v-progress-circular>
      </div>
      <v-form v-model.trim="valid" ref="form" class="row" v-else>
        <div class="col-md-6">
          <div class="col-md-12 pb-0">
            <label class="font text-gray" for="">Company Legal Name</label>
            <v-text-field
              required
              :rules="requiredRules"
              v-model.trim="companyName"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">Company Display Name</label>
            <v-text-field
              required
              :rules="requiredRules"
              v-model.trim="displayName"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">Tax ID</label>
            <v-text-field
              required
              @keyup="showSlash($event)"
              v-model.trim="taxId"
              solo
              maxlength="10"
              placeholder="00-0000000"
              :rules="taxIdRules"
              counter="10"
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">Website</label>
            <v-text-field required v-model.trim="website" solo></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for=""
              >Company Logo (Image Size - Width = 100px Height = 50px)</label
            >
            <div class="row">
              <div class="col-xl-10 col-md-9 col-xs-12 col-sm-12" cols="12">
                <v-file-input
                  @change="Preview_image"
                  v-model.trim="dataComponents.image"
                  required
                  solo
                  ref="fileInput"
                ></v-file-input>
              </div>
              <div
                class="col-xl-2 col-md-3 col-xs-12 col-sm-12"
                v-if="$role != 'user'"
                cols="12"
              >
                <v-btn
                  class="blue-buttons col-sm-12"
                  @click="activateFileInput"
                  style="padding: 23px"
                >
                  Browse
                </v-btn>
              </div>
            </div>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for=""
              >Profile Picture (Image Size - Width = 100px Height = 50px)</label
            >
            <div class="row">
              <div class="col-xl-10 col-md-9 col-xs-12 col-sm-12" cols="12">
                <v-file-input
                  @change="Preview_profileImage"
                  v-model.trim="dataComponents.profileImage"
                  required
                  solo
                  ref="profileInput"
                ></v-file-input>
              </div>
              <div
                class="col-xl-2 col-md-3 col-xs-12 col-sm-12"
                v-if="$role != 'user'"
                cols="12"
              >
                <v-btn
                  class="blue-buttons col-sm-12"
                  @click="activateProfileInput"
                  style="padding: 23px"
                >
                  Browse
                </v-btn>
              </div>
            </div>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">HQ Address Line 1</label>
            <v-text-field required v-model.trim="address1" solo></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">HQ Address Line 2</label>
            <v-text-field required v-model.trim="address2" solo></v-text-field>
          </div>
          <v-row class="no-padding">
            <div class="col-md-6 pt-0 pb-0">
              <label class="font text-gray" for="">City</label>
              <v-text-field required v-model.trim="city" solo></v-text-field>
            </div>
            <div class="col-md-3 pt-0 pb-0">
              <label class="font text-gray" for="">State </label>
              <v-select
                :items="states"
                item-text="name"
                item-value="name"
                required
                v-model.trim="state"
                solo
              ></v-select>
            </div>
            <div class="col-md-3 zip-field-height pt-0 pb-0">
              <label class="font text-gray" for="">Zip</label>
              <v-text-field
                required
                type="number"
                v-model.trim="zip"
                :rules="zip.length > 0 ? zipRules : []"
                solo
              ></v-text-field>
            </div>
          </v-row>
        </div>
        <div class="col-md-6">
          <div class="col-md-12 pb-0">
            <label class="font text-gray" for=""
              >Approximate Number of Employees</label
            >
            <v-text-field
              type="number"
              required
              v-model.trim="numberOfEmp"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for=""
              >Approximate Number of Plan Members</label
            >
            <v-text-field
              required
              type="number"
              v-model.trim="numberOfMembers"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">Current Network Vendor </label>
            <v-text-field required v-model.trim="vendor" solo></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for=""
              >Current Network Discount Rate (i.e Precent Discount off Billed
              Charges)
            </label>
            <v-text-field
              required
              v-model.trim="discountRate"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 mt-3">
            <v-btn
              @click="generatePdf()"
              large
              class="blue-buttons col-xl-7 col-md-11"
              >Access the Sample Plan Addendum</v-btn
            >
          </div>
          <div class="col-md-12 mt-8">
            <v-btn
              @click="generatePdf()"
              large
              class="blue-buttons col-xl-7 col-md-11 blue-buttons-font-size"
              >Access the Apaly Health Terms of Use Agreement</v-btn
            >
          </div>
        </div>
        <div class="col-md-12 d-flex align-end flex-column">
          <v-btn class="blue-buttons" @click="update" v-if="$role != 'user'">
            Update & Save
          </v-btn>
        </div>
      </v-form>
    </div>
  </v-row>
</template>
<script>
import api from "../../../../../services/api";
import successModal from "../../../../TablesTemplate/SuccessDialog.vue";
export default {
  components: { successModal },
  data() {
    return {
      companyName: "",
      taxId: "",
      website: "",
      logo: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      readyToShow: false,
      zipRules: [
        (v) => (v && v.length == 5) || " Zip Code should be five digits long",
      ],
      companyLogo: {
        name: "",
      },
      profileChange: false,
      logoChange: false,
      displayName: "",
      imageProfleEvent: null,
      dataComponents: {
        image: {
          name: "",
        },
        profileImage: {
          name: "",
        },
      },
      numberOfEmp: "",
      numberOfMembers: "",
      vendor: "",
      states: [],
      discountRate: "",
      valid: false,
      requiredRules: [(v) => !!v || "This field is required"],
      taxIdRules: [
        (v) => !!v || "This field is required",
        (v) =>
          !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
          "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
      ],
      imageEvent: null,
    };
  },
  computed: {
    singleEmp() {
      let provider = "";
      if (localStorage.getItem("employer")) {
        provider = JSON.parse(localStorage.getItem("employer"));
      }

      return provider;
    },
  },

  methods: {
    showSlash(event) {
      if (this.taxId.length == 2 && event.keyCode != 8) {
        this.taxId += "-";
      }
      if (this.taxId.length == 10) {
        api()
          .get("auth/isValidTaxId?taxId=" + this.taxId)
          .catch((res) => {
            if (res) {
              this.taxIdRules = [res.response.data.message];
            }
          });
      } else {
        this.taxIdRules = [
          (v) => !!v || "This field is required",
          (v) =>
            !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
            "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
        ];
      }
    },
    activateFileInput() {
      this.$refs.fileInput.$refs.input.click();
    },
    activateProfileInput() {
      this.$refs.profileInput.$refs.input.click();
    },
    getStates() {
      api()
        .get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
    },
    getInfo() {
      let user = JSON.parse(localStorage.getItem("user"));
      let employerId = user.groupPortal[0].organization.employer;
      this.readyToShow = true;
      api()
        .get(`/employers/${employerId}`)
        .then((res) => {
          if (res.data.employer) {
            this.companyName = res.data.employer.companyName;
            this.taxId = res.data.employer.taxId ? res.data.employer.taxId : "";
            this.vendor = res.data.employer.networkVendor
              ? res.data.employer.networkVendor
              : "";
            this.numberOfEmp = res.data.employer.noEmployees
              ? res.data.employer.noEmployees
              : "";
            this.numberOfMembers = res.data.employer.noPlanMembers
              ? res.data.employer.noPlanMembers
              : "";
            this.city = res.data.employer.city ? res.data.employer.city : "";
            this.state = res.data.employer.state ? res.data.employer.state : "";
            this.zip = res.data.employer.zipCode
              ? res.data.employer.zipCode
              : "";
            this.website = res.data.employer.website
              ? res.data.employer.website
              : "";
            this.address1 = res.data.employer.address1
              ? res.data.employer.address1
              : "";
            this.address2 = res.data.employer.address2
              ? res.data.employer.address2
              : "";
            this.displayName = res.data.employer.displayName
              ? res.data.employer.displayName
              : "";
            this.discountRate = res.data.employer.networkDiscountRate
              ? res.data.employer.networkDiscountRate
              : "";
            this.dataComponents.image.name = res.data.employer.logo
              ? res.data.employer.logo
              : "";
            this.dataComponents.profileImage.name = res.data.employer
              .primaryContact.profile
              ? res.data.employer.primaryContact.profile
              : "";
          }
          this.readyToShow = false;
        });
    },
    generatePdf() {
      window.open("https://api.apaly.net/terms");
    },
    update() {
      this.$refs.form.validate();
      if (this.valid) {
        let data = {
          networkVendor: this.vendor,
          companyName: this.companyName,
          displayName: this.displayName,
          taxId: this.taxId,
          website: this.website,
          address1: this.address1,
          address2: this.address2,
          city: this.city,
          state: this.state,
          zipCode: this.zip,
          noEmployees: this.numberOfEmp,
          noPlanMembers: this.numberOfMembers,
          networkDiscountRate: this.discountRate,
          companyLogo: this.imageEvent,
        };
        let user = JSON.parse(localStorage.getItem("user"));
        let employerId = user.groupPortal[0].organization.employer;
        let formData = new FormData();
        formData.append("_method", "PUT");
        formData.append("image", this.imageEvent);

        api()
          .put(`/employers/${employerId}`, data)
          .then((res) => {
            if (res) {
              if (this.profileChange) {
                if (this.imageProfleEvent) {
                  let formData = new FormData();
                  formData.append("_method", "PUT");
                  formData.append("image", this.imageProfleEvent);
                  api()
                    .put(`/employers/${employerId}/profile`, formData)
                    .then((res) => {
                      document.getElementById("profile-img").click();
                    });
                } else {
                  api()
                    .delete(`/employers/${employerId}/profile`)
                    .then((res) => {
                      document.getElementById("profile-img").click();
                    });
                }
              }
              if (this.logoChange) {
                if (this.imageEvent) {
                  let formData = new FormData();
                  formData.append("_method", "PUT");
                  formData.append("image", this.imageEvent);
                  api()
                    .put(`/employers/${employerId}/logo`, formData)
                    .then((res) => {
                      document.getElementById("logo-img").click();
                    });
                } else {
                  api()
                    .delete(`/employers/${employerId}/logo`)
                    .then((res) => {
                      document.getElementById("logo-img").click();
                    });
                }
              }

              this.$refs.success_modal.openPopUp(
                "Account updated successfully"
              );
              this.logoChange = false;
              this.profileChange = false;
              document.getElementById("changeEmpDisplayName").click();
            }
          });
      }
    },
    Preview_image(event) {
      this.logoChange = true;
      this.imageEvent = event;
    },
    Preview_profileImage(event) {
      this.profileChange = true;
      this.imageProfleEvent = event;
    },
  },
  created() {
    this.getStates();
    this.getInfo();
  },
};
</script>