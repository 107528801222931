<template>
  <div class="row-height">
    <div v-if="!isChildren">
      <div class="row pa-2">
        <!-- <div class="col-xl-2 col-lg-3 col-md-3 col-sm-12">
          <v-text-field @input="searchInput" class="input-control mr-5 col-md-3" prepend-inner-icon="mdi-magnify"  light dense outlined placeholder="Search Solution Packages"></v-text-field>
        </div> -->
        <!-- <div class="col-xl-1 col-lg-1 col-md-12 col-sm-12" align="end">
          <v-btn class="my-1 gray-border-buttons" @click="openPackage(null)">
            <svg class="mr-2" width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.741211 7.5H15.7774" stroke="#707070" stroke-width="2" stroke-linejoin="round"/>
              <path d="M8.25977 0V15" stroke="#707070" stroke-width="2" stroke-linejoin="round"/>
            </svg>
            New
          </v-btn>
        </div> -->
      </div>
      <div class="mt-3">
        <v-simple-table fixed-header height="70vh" class="no-padding tr-custom-height">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">SOLUTION PACKAGE NAME</th>
                <th class="text-left">DESCRIPTION</th>
                <th class="text-left">SOLUTIONS</th>
                <th class="text-left">STATUS</th>
              </tr>
            </thead>
            <tbody v-if="items.length > 0 && !filterItems" class="text-gray tr-custom-height" :key="initTable">
              <tr v-for="(item, index) in items" :key="index+'_'+item.rating">
                <td @click="openPackage(item)">
                  <a>{{ item.name }}</a>
                </td>
                <td v-html="item.description ? item.description : '/'"></td>
                <td>{{ item.solutions.length }}</td>
                <td>
                  {{ item.status }}
<!--                  <div @click="fillArray(item, index)">-->
<!--                    <v-rating :value="item.rating" length="1" background-color="orange lighten-3" color="orange" large></v-rating>-->
<!--                  </div>-->
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="items.length < 1 && filterItems">
              <v-col md="12" cols="12">
                <v-alert border="bottom" colored-border type="warning" elevation="2" class="text-gray">
                  Sorry! No results found
                </v-alert>
              </v-col>
            </tbody>

            <tbody v-else>
              <v-col md="12"> There are no solution packages currenlty. </v-col>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <successModal ref="success_modal" v-on:confirm="closeSuccessModal"></successModal>

      <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import api from "../../../../services/api";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../TablesTemplate/ErrorDialog.vue";

export default {
  components: { successModal, errorModal },
  data() {
    return {
      tab: "solution",
      initTable: 'init',
      dialog: false,
      items: [],
      singleItem: null,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
      valid: false,
      companyName: "",
      firstName: "",
      lastName: "",
      email: "",
      filterArray: [],
      filterItems: false,
    };
  },
  computed: {
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
  },
  mounted() {
    this.getPackages();
  },
  methods: {
    filterItemsByType(type) {
      if (type == "sort") {
        if (this.sortBy == "Oldest") {
          this.items = this.filterArray.sort(
            (a, b) =>
              Number(new Date(a.createdAt)) - Number(new Date(b.createdAt))
          );
        } else {
          this.items = this.filterArray.sort(
            (a, b) =>
              Number(new Date(b.createdAt)) - Number(new Date(a.createdAt))
          );
        }
      } else {
        if (this.typeBy != "All Categories") {
          this.items = this.filterArray.filter((el) => {
            return el.category == this.typeBy;
          });
        } else {
          this.items = this.filterArray;
        }
      }
    },
    closeErrorModal() {
      this.dialog = false;
      this.companyName = "";
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.$refs.success_modal.closePopUp();
      this.$refs.error_modal.closePopUp();
    },
    closeSuccessModal() {
      this.dialog = false;
      this.companyName = "";
      this.firstName = "";
      this.lastName = "";
      this.email = "";

      this.$refs.success_modal.closePopUp();
    },
    openPackage(item){
      this.$store.commit('setSinglePackage',item)
      this.$router.push('/tpa/solutions/package')
    },
    getPackages() {
      const self = this;
      this.tab = 'solutions'
      api()
        .get("/tpas/solutions")
        .then((res) => {
          this.items = res.data.packages;
          this.filterArray = this.items;
        });
    },
    showDetails(item) {
      this.singleItem = item;
      this.$store.commit("setTpaSingleSolution", item);
      this.$router.push("/tpa/solutions/details");
    },
    fillArray(item, index) {
      const self = this
      item.rating = item.rating ? 0 : 1;
      this.items[index].rating = item.rating
      if(item.rating == 1){
        this.favorites.push(item._id)
      }else{
        this.favorites.splice(this.favorites.indexOf(item._id),1)
      }
      api().put("/tpas/solutions/favorite", {solutions: this.favorites}).then((res) => {
          this.favorites = res.data
        });
    },
    searchInput(val) {
      this.items = this.filterArray.filter((el) => {
        if (el.vendor && el.vendor.companyName && el.vendor.companyName.toString().toLowerCase().includes(val.toLowerCase())) {
          return el.vendor.companyName.toString().toLowerCase().includes(val.toLowerCase());
        } else if (el.category && el.category.toString().toLowerCase().includes(val.toLowerCase())) {
          return el.category.toString().toLowerCase().includes(val.toLowerCase());
        }else if (el.description && el.description.toString().toLowerCase().includes(val.toLowerCase())) {
          return el.description.toString().toLowerCase().includes(val.toLowerCase());
        }
      });
      this.filterItems  =  (this.items.length < 1);
    },
  },
};
</script>
<style>
</style>