<template>
  <div class="page-background">
    <main-header :title="'Register'" :color="'white'" ></main-header>
    <div class="mobile-edit-form">
      <form >
        <div>
          <h3 class="form-title">Get Started</h3>
          <h3 class="from-headers">Personal Information </h3>
          <v-text-field outlined label="First Name" color="white" class="input-text"></v-text-field>
          <v-text-field outlined label="Last Name" color="white" class="input-text"></v-text-field>
        </div>

        <div>
          <h3 class="from-headers">Login Information</h3>
          <v-text-field outlined label="Email (login)" type="email" color="white" class="input-text"></v-text-field>

          <v-text-field outlined label="Password" color="white" class="input-text" :type="show1 ? 'text' : 'password'"
                        @click:append="show1 = !show1" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          ></v-text-field>
          <v-text-field outlined label="Confirm password" color="white" class="input-text" :type="show2 ? 'text' : 'password'"
                        @click:append="show2 = !show2" :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
          ></v-text-field>
        </div>

      </form>

      <div class="above-footer-text" >
        Already have an account? <span style="color:white" @click="$router.push({name:'MemberLogin'})">Login ></span>
      </div>
    </div>

    <div class="d-flex footer"  style="">
        <div class="col-6">
          <v-btn class="footer-btn-cancel" >Cancel</v-btn>
        </div>
        <div class="col-6">
          <v-btn color="white" class="footer-btn" >Register</v-btn></div>
      </div>
  </div>
</template>
<script>
import mainHeader from "@/components/Sections/DPCMember/Layout/mainHeader";
export default {
  data() {
    return {
      show1:false,
      show2:false,
    };
  },
  components: {
    mainHeader,
  },
  methods: {

    navigateTo(link){
      this.$router.push({name:link})

    }
  },
  created() {},
};
</script>
<style scoped src='./../../../../assets/mobileStyle.css'></style>
<style scoped lang="scss">


.mobile-edit-form {
  background-color: #16407a;
  height: 100vh;
  padding: 20px;
  overflow-y: auto;
  padding-bottom: 150px !important;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;
}

.mobile-content {
  padding: 16px 24px 0 24px;
}

.page-background {

  background-color: #001e46;
}

.list-item-title {
  color: #0573f3;
  font-family: "Quicksand";
  font-weight: 700;
}

.list-item-title {
  color: #0573f3;
  font-family: "Quicksand";
  font-weight: 700;
}

.list-item-subtitle {
  font-family: "Quicksand";
  font-weight: 600;
}

.second-subtitle {
  color: #001e46;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.mobile-edit-form v-text-field {
  color: white;
}

.from-headers {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  padding-bottom: 15px;
  color: white;
}

.from-sub-headers {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.footer{
  background-color: #16407a;
  height:80px;
  position:absolute;
  bottom:0;
  width:100%;
  align-items: flex-start;
  border-top: 1px solid #C9C9C9
}
.footer-btn{
  border-radius: 23px;
  width: 100%;
}

.footer-btn-cancel{
  background-color:#16407a !important;
  border-radius: 23px;
  width: 100%;
  border: 1px solid white !important;
  color: white;
  float:right
}

.btn-settings{
  background-color:#16407a !important;
  border-radius: 23px;
  width: 155px;
  border: 1px solid white !important;
  color: white;
  float:right
}

.btn-settings-white{
  background-color:white !important;
  border-radius: 23px;
  width: 155px;
  border: 1px solid white !important;
  color: #16407a;
  float:right
}

.v-input__slot > label {
  color: white !important;
}

.form-title{
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: white;
}

.above-footer-text{
  margin-top: 75px;
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #C9C9C9
}

</style>
