<template>
    <div>
        <EmployerMembers v-if="route=='/employer/members'"></EmployerMembers>
        <AddZipCodes v-if="route=='/employer/members/zip-codes'"></AddZipCodes>
        <EmployerUpload v-if="route=='/employer/members/upload'"></EmployerUpload>
        <EmployerAddMember v-if="route=='/employer/members/add-new'"></EmployerAddMember>
        <Result v-if="route=='/employer/members/result'" ></Result>
        <EmployerMemberDetail v-if="route=='/employer/members/member-detail'" ></EmployerMemberDetail>
        <EmployerInviteMembers v-if="route=='/employer/members/invite-members'"></EmployerInviteMembers>
        <AddDependent v-if=" route == `/employer/members/member-detail/dependents`"></AddDependent>
        <EmployerDependentDetail v-if="route=='/employer/members/member-detail/dependent-detail'"></EmployerDependentDetail>
        
    </div>
</template>

<script>
    import EmployerMembers from "./EmployerMembers.vue";
    import EmployerUpload from "./Upload/EmployerUpload.vue";
    import EmployerAddMember from "./AddMember/EmployerAddMember.vue";
    import EmployerMemberDetail from "./MemberDetail/EmployerMemberDetail.vue";
    import EmployerDependentDetail from "./MemberDetail/DependentDetail/EmployerDependentDetail.vue";
    import EmployerInviteMembers from "./InviteMembers/EmployerInviteMembers.vue";
    import AddDependent from './MemberDetail/AddDependent.vue'
    import AddZipCodes from '../Members/AddZipCodes.vue'
    import Result from '../Members/ResultTable.vue'

    export default {
    name:'EmployerMembersTemplate',
        components: {
        EmployerMemberDetail,
        EmployerMembers,
        EmployerDependentDetail,
        EmployerInviteMembers,
        EmployerUpload,
        EmployerAddMember,
        AddDependent,
        AddZipCodes,
        Result
    },
        computed:{
            route(){
                return this.$route.path
            }
        }
    }
</script>