<template>
  <div>
    <div class="row col-md-12 font-weight-bold light-blue-text">
      Services & Fees
      <v-divider class="mt-3 ml-3"></v-divider>
    </div>
    <div class="row mt-10">
      <div class="col-12">
        <div class="row">
          <div class="col-md-12">
            <div class="row ml-10">
              <div class="col-md-3">
                <span><a class="font-weight-bold" href="#"> Definition of Services</a></span>
              </div>
              <div class="col-md-8">
                <span class="text-gray font-weight-bold">
                  <v-textarea
                  v-model="definitionOfService"
                  @blur="sendDefinition()"
                  hide-details
                  required
                  rows="4"
                  solo
                  class="mb-0 pb-0"
                ></v-textarea>
                <br/>
                <p class="text-gray font-weight-bold pt-0 mt-0">Please describe in detail the services and/or goods  agreed upon with the vendor </p>
                </span>
              </div>
            </div>
            <div class="row ml-10">
              <div class="col-md-3">
                <span><a class="font-weight-bold" href="#"> Vendor Services Rate</a></span>
              </div>
              <div class="col-md-6">
                <span class="text-gray font-weight-bold">
                  <v-textarea
                  v-model="vendorRate"
                  @blur="sendRate()"
                  hide-details
                  required
                  rows="4"
                  solo
                ></v-textarea>
                <br/>
                <p class="text-gray font-weight-bold pt-0 mt-0">Please describe in detail the services and/or goods  agreed upon with the vendor  </p>
                </span>
              </div>
            </div>
            <div class="row ml-10">
              <div class="col-md-3">
                <span><a class="font-weight-bold" href="#">TPA Admin Fee</a></span>
              </div>
            </div>
            <div class="row ml-10 mb-10" style="align-items: baseline;">
              <div class="col-md-3">
                <span class="text-gray font-weight-bold">TPA fee:</span>
              </div>
              <div class="col-md-9">
                <div class="row" style="align-items: baseline">
                  <div class="col-md-2">
                    
                    <v-slider
                     class="input-control"
                     @input="sendTpaFee()"
                     v-model="tpaFee"
                      light
                      type="number"
                      :min="0"
                       :max="2"
                       :step="0.1"
                       :thumb-size="24"
                      thumb-label="always"
                       dense
                        outlined ></v-slider>
                      
                  </div>
                  <div class="col-md-9">
                    <span class="text-gray font-weight-bold ml-4">TPA will receive <strong>{{tpaFee}}% </strong> of every invoice paid. </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
 import { mapGetters } from "vuex";
export default {
  name: "Rates",
  props:['item'],
  data(){
    return {
      definitionOfService: "",
      vendorRate: "",
      tpaFee: "",

    }
  },
  computed: {
      ...mapGetters(["getDefinitionOfService", "getVendorRate", "getTpaFee"]),
    },
  methods: {
    sendDefinition(){
      this.$store.commit("setDefinitionOfService", this.definitionOfService);
      this.$emit('getDefService',this.definitionOfService);
    },
    sendRate(){
      this.$store.commit("setVendorRate", this.vendorRate);
      this.$emit('getVendorRate',this.vendorRate);
    },
    sendTpaFee(){
      this.$store.commit("setTpaFee", this.tpaFee);
      this.$emit('getTpaFee',this.tpaFee);
      console.log('set',this.tpaFee)
    },
  },
  created(){
   this.definitionOfService = this.getDefinitionOfService ? this.getDefinitionOfService : '';
   this.vendorRate = this.getVendorRate ? this.getVendorRate : '';
   this.tpaFee = this.getTpaFee ? this.getTpaFee : '';
   console.log('tpafee',this.getTpaFee)
  },

}
</script>

<style scoped>

</style>