<template>
  <div>
    <div
      v-if="path.includes(`/employer-advisor/employers/${routeParams}`)"
      class="blue-toolbar row no-padding"
      style="margin-top: 0px"
    >
      <div
        cols="2"
        :class="
          activeTab == 'home'
            ? ' col-lg-1 col-md-2 col-sm-2 font ml-1  white--text text-center orange-underline sm-buttons-width'
            : ' col-lg-1 col-md-2 col-sm-2 font  ml-1 white--text text-center sm-buttons-width'
        "
        align="center"
      >
        <a class="white--text" @click="changeActiveTab('home')"> Home </a>
      </div>
      <!-- <div
        cols="2"
        :class="
          activeTab == 'members'
            ? ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center orange-underline sm-buttons-width'
            : ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center sm-buttons-width'
        "
        align="center"
      >
        <a class="white--text" @click="changeActiveTab('members')"> Members </a>
      </div> -->
      <div
        cols="2"
        :class="
          activeTab == 'providers'
            ? ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center orange-underline sm-buttons-width'
            : ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center sm-buttons-width'
        "
        align="center"
      >
        <a class="white--text" @click="changeActiveTab('providers')">
          Providers
        </a>
      </div>
      <!-- <div
        cols="2"
        :class="
          activeTab == 'networks'
            ? ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center orange-underline sm-buttons-width'
            : ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center sm-buttons-width'
        "
        align="center"
      >
        <a class="white--text" @click="changeActiveTab('networks')"> VNets </a>
      </div> -->
      <!-- <div
        cols="2"
        :class="
          activeTab == 'utilization'
            ? ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center orange-underline sm-buttons-width'
            : ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center sm-buttons-width'
        "
        align="center"
      >
        <a class="white--text" @click="changeActiveTab('utilization')">
          Utilization
        </a>
      </div> -->
      <div
        cols="2"
        :class="
          activeTab == 'contracts'
            ? ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center orange-underline sm-buttons-width'
            : ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center sm-buttons-width'
        "
        align="center"
      >
        <a class="white--text" @click="changeActiveTab('contracts')">
          Contract
        </a>
      </div>
      <div
        cols="2"
        :class="
          activeTab == 'advisors'
            ? ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center orange-underline sm-buttons-width'
            : ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center sm-buttons-width'
        "
        align="center"
      >
        <a class="white--text" @click="changeActiveTab('advisors')">
          Partners
        </a>
      </div>
      <v-menu bottom offset-y id="blue-nav">
        <template v-slot:activator="{ on }">
          <div
            cols="2"
            :class="
              activeTab == 'provider-settings'
                ? 'col-lg-1 col-md-2 col-sm-2 col-sm-2 font  white--text text-center orange-underline sm-buttons-width'
                : ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center blue-underline  sm-buttons-width'
            "
            align="center"
          >
            <a
              v-on="on"
              class="white--text"
              @click="changeActiveTab('provider-settings')"
            >
              Settings
            </a>
          </div>
        </template>
        <v-list>
          <v-list-item style="disply: block">
            <a
              class="a-tag underline-text"
              @click="
                $router.push(
                  `/employer-advisor/employers/${routeParams}/settings/account`
                )
              "
              >Account</a
            >
          </v-list-item>
          <v-list-item>
            <a
              class="a-tag underline-text"
              @click="
                $router.push(
                  `/employer-advisor/employers/${routeParams}/settings/users`
                )
              "
              >Users</a
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div
      v-else
      class="blue-toolbar row"
      style="margin-top: 0px; padding: 1.05rem; margin-left: 0px"
    >
      {{ routeParams }}
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeTab: "",
      activeTab1: "",
    };
  },
  props: {
    clientInfo: {
      type: Boolean,
    },
  },
  watch: {
    $route(to, from) {
      this.onCreatedFunction();
    },
  },

  methods: {
    changeActiveTab(tab) {
      if (tab == "home") {
        if (this.path != "/employer-advisor/employers/" + this.routeParams)
          this.$router.push("/employer-advisor/employers/" + this.routeParams);
      }
      else if(tab == "provider-settings"){
        if (this.path != `/employer-advisor/employers/${this.routeParams}/settings`)
        this.$router.push(`/employer-advisor/employers/${this.routeParams}/settings`);
    
      }else{
          if(this.path != `/employer-advisor/employers/${this.routeParams}/${tab}`)
           this.$router.push(`/employer-advisor/employers/${this.routeParams}/${tab}`);
      }
      this.activeTab = tab;

    },
    onCreatedFunction() {
      this.activeTab = "home";
      if (
        this.path.includes(`/employer-advisor/employers/${this.routeParams}`)
      ) {
        this.activeTab = "home";
      }

      if (this.path.includes("members")) {
        this.activeTab = "members";
      }
      if (this.path.includes("prior-auth")) {
        this.activeTab = "prior-auths";
      }
      if (this.path.includes("advisors")) {
        this.activeTab = "advisors";
      }
      if (this.path.includes("networks")) {
        this.activeTab = "networks";
      }
      if (this.path.includes("providers")) {
        this.activeTab = "providers";
      }
      if (this.path.includes("bundles")) {
        this.activeTab = "bundles";
      }

      if (
        this.path.includes("settings") &&
        this.path.includes("/employer-advisor/employers")
      ) {
        this.activeTab = "provider-settings";
      }

      if (this.$route.path.includes("contracts")) {
        this.activeTab = "contracts";
      }
      if (this.$route.path.includes("utilization")) {
        this.activeTab = "utilization";
      }

      if (this.path == `/employer-advisor/employers/${this.routeParams}`) {
        this.activeTab = "home";
      }
      if (
        this.path.includes(
          `/employer-advisor/employers/${this.routeParams}/employers`
        )
      ) {
        this.activeTab = "employers";
      }
    },
  },
  computed: {
    routeParams() {
      return this.$route.params.name;
    },
    path() {
      return this.$route.path.replace(/%20/g, " ");
    },
  },
  created() {
    this.onCreatedFunction();
  },
};
</script>
<style scoped>
.no-margin {
  margin: 0px !important;
}
</style>