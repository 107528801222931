<template>
  <div class="row row-height" v-if="!isChildren">
    <!-- <v-overlay v-if="loader">
            <v-progress-circular indeterminate :size="100" color="#326ab3" class="text-center mt-10 mb-5">
                <img
                  src="/newapalylogoonlyblue.png"
                  height="70"
                  class="img-mobile"
                />
            </v-progress-circular>

        </v-overlay> -->
    <!-- Fullscreen Loader -->
    <FullscreenLoader v-if="loader" />

    <v-sheet v-else
      class="overflow-hidden col-md-12"
      style="position: relative; border-radius: 8px"
    >
      <div>
        <v-row align="center" justify="center">
          <div
            :class="
              overlay
                ? 'hideOnMoblie background-opacity col-md-9'
                : 'col-md-12 navigationWidth no-padding '
            "
          >
            <v-row class="mb-1 blue-underline">
              <v-col class="col-xl-2 col-md-2 cols-4 pt-2 pb-0" cols="4">
                <div class="col-md-12">
                  <h3 class="form-title font col-md-12 font-18">Invoices</h3>
                </div>
              </v-col>
              <v-col class="col-xl-3 col-md-5 cols-8 pt-3 pb-1" cols="8">
                <div class="col-md-12 d-flex mt-4 no-padding">
                  <v-text-field
                    :disabled="overlay"
                    class="input-control mr-5"
                    prepend-inner-icon="mdi-magnify"
                    v-model="searchQuery"
                    @input="fetchInvoicesWithPagination"
                    light
                    dense
                    outlined
                    placeholder="Search Invoices"
                  ></v-text-field>
                  <!-- DPC-652 | Remove filtering capability from invoices tab -->
                  <!-- <v-btn
                    :disabled="overlay"
                    class="text-gray filter-btn"
                    @click.stop="showFilterSideBar"
                  >
                    <svg
                      width="17"
                      class="mr-2"
                      height="14"
                      viewBox="0 0 17 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1H16"
                        stroke="#424242"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M2.59766 5H14.403"
                        stroke="#424242"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6.3125 13H10.6858"
                        stroke="#424242"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M4.19531 9H12.8046"
                        stroke="#424242"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Filters
                  </v-btn> -->
                </div>
              </v-col>
              <v-col
                class="col-xl-7 col-md-5 pr-6 pt-6 text-end pt-3 pb-1"
                cols="12"
                align="end"
              >
                <v-btn
                  class="my-1 gray-border-buttons"
                  @click="exportData('invoiceData')">
                  <svg
                    class="mr-2"
                    width="15"
                    height="16"
                    viewBox="0 0 15 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.2361 10.7639C14.0329 10.7639 13.838 10.8434 13.6937 10.985C13.5495 11.1267 13.4676 11.319 13.4659 11.5202V12.6764C13.4659 12.9367 13.3615 13.1862 13.1756 13.3703C12.9897 13.5543 12.7376 13.6577 12.4747 13.6577H2.52525C2.26238 13.6577 2.01027 13.5543 1.8244 13.3703C1.63852 13.1862 1.53409 12.9367 1.53409 12.6764V11.5202C1.51671 11.3307 1.42838 11.1544 1.28645 11.0261C1.14452 10.8978 0.959257 10.8266 0.767046 10.8266C0.574834 10.8266 0.389569 10.8978 0.247642 11.0261C0.105715 11.1544 0.0173841 11.3307 0 11.5202V12.6764C0 13.3395 0.266053 13.9753 0.739629 14.4442C1.21321 14.913 1.85551 15.1764 2.52525 15.1764H12.4747C13.1445 15.1764 13.7868 14.913 14.2604 14.4442C14.7339 13.9753 15 13.3395 15 12.6764V11.5202C15 11.3196 14.9195 11.1272 14.7763 10.9854C14.633 10.8436 14.4387 10.7639 14.2361 10.7639Z"
                      fill="#707070"
                    />
                    <path
                      d="M7.49971 0.176392C7.17488 0.176392 6.86336 0.304137 6.63368 0.531525C6.404 0.758913 6.27496 1.06732 6.27496 1.38889V7.05139C6.27496 7.09283 6.25833 7.13257 6.22873 7.16188C6.19913 7.19118 6.15899 7.20764 6.11713 7.20764H4.43784C4.32437 7.21533 4.2151 7.25322 4.12158 7.31731C4.02807 7.3814 3.95378 7.46931 3.90657 7.57175C3.85935 7.6742 3.84097 7.78736 3.85337 7.89929C3.86576 8.01122 3.90847 8.11776 3.97698 8.20764L7.03885 11.6951C7.0979 11.7576 7.16927 11.8074 7.24855 11.8414C7.32782 11.8754 7.4133 11.893 7.49971 11.893C7.58611 11.893 7.6716 11.8754 7.75087 11.8414C7.83014 11.8074 7.90151 11.7576 7.96057 11.6951L11.0224 8.20764C11.0909 8.11776 11.1337 8.01122 11.146 7.89929C11.1584 7.78736 11.1401 7.6742 11.0928 7.57175C11.0456 7.46931 10.9713 7.3814 10.8778 7.31731C10.7843 7.25322 10.675 7.21533 10.5616 7.20764H8.88228C8.84042 7.20764 8.80028 7.19118 8.77068 7.16188C8.74108 7.13257 8.72445 7.09283 8.72445 7.05139V1.38889C8.72445 1.06732 8.59542 0.758913 8.36573 0.531525C8.13605 0.304137 7.82453 0.176392 7.49971 0.176392V0.176392Z"
                      fill="#707070"
                    />
                  </svg>
                  Export
                </v-btn>
              </v-col>
            </v-row>
            <!-- <div v-if="loader">
              <v-progress-linear
              class="text-center justify-center mt-0 "
              color="primary"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </div> -->
          <v-row class="col-md-12" v-if="allInvoices.length < 1">
            <v-col md="12" class="pt-0">
              No invoices to show </v-col>
          </v-row>
            <v-data-table v-else
                    :headers="tableHeaders" 
                    :items="allInvoices"
                    :search="searchValue"
                    :hide-default-header="false"
                    :mobile-breakpoint="0"
                    class="elevation-1 pa-2"
                    :hide-default-footer="true"
                    :items-per-page="itemsPerPage"
                    :page="page"
                    :footer-props="{
                        'items-per-page-options': itemsPerPageOptions,
                    }"
                    :loading="tableLoading"
                >
                  <template v-slot:body="{ items }" >
                    <tr v-for="item in items" :key="item.id" class="pa-1">
                     <td  class="pa-1 text-gray text-start font-14">
                      <span class="pl-3"> {{ item.ref.number }} </span>
                            </td>
                             <td  class="pa-1 text-gray text-start font-14">
                              <a class="pl-3" @click="goToMemberTab(item)"> {{ item.member.memberId }}</a> 
                            </td>
                            <td class="pa-1 text-gray text-start font-14">
                             <span class="pl-3">{{ item.member.firstName }}       {{  item.member.lastName }}</span> 
                            </td>
                            <td class="pa-1 text-gray text-start font-14">
                              <span class="pl-3">{{  item.client.companyName }}      </span>                  
                                </td>
                            <td  class="pa-1 text-gray text-start font-14">
                              <span class="pl-3">{{ item.date | moment('MM/DD/YYYY') }}   </span>                     
                                </td>

                              <td  class="pa-1 text-gray text-start font-14">
                                <span class="pl-3">$ {{ item.amount.toFixed(2) }}</span>
                            </td>
      
                            <td class="text-start text-gray text-center pa-1  font-14">
                              <!-- <v-chip :color="returnStatusColor(item.status)"> -->
                                <span class="pl-3">{{ item.status.charAt(0).toUpperCase() +  item.status.slice(1)}}</span>
                              <!-- </v-chip> -->
                            </td>
                    </tr>
                  </template>
                  <template v-slot:footer>
                            <v-divider class="mt-5 mb-3"></v-divider>
                            <v-row justify="end" align="center" no-gutters>
                                <v-col cols="auto" class="d-flex align-center mr-7">
                                    <span class="rows-per-page">Rows per page:</span>
                                </v-col>

                                <v-col cols="auto" class="d-flex align-center mr-5">
                                    <v-select
                                        class="pagination-items-per-page"
                                        v-model="itemsPerPage"
                                        :items="itemsPerPageOptions"
                                        hide-details
                                        @change="updateItemsPerPage"
                                    ></v-select>
                                </v-col>

                                <v-col cols="auto" class="d-flex align-center mr-2">
                                    <span class="page-number">{{ pageStart }} - {{ pageEnd }} of {{ totalInvoices }}</span>
                                </v-col>

                                <v-col cols="auto mr-13">
                                    <v-pagination
                                        density="compact"
                                        v-model="page"
                                        @input="updatePage"
                                        class="ml-4"
                                        :length="totalPages"
                                        :total-visible="5"
                                    >
                                    </v-pagination>
                                </v-col>
                            </v-row>
                        </template>
                </v-data-table>
          </div>
        </v-row>
      </div>
      <v-simple-table id="invoiceData" style="display: none">
                <thead>
                  <tr>
                    <th class="text-left">Reference number</th>
                    <th class="text-left">Member ID</th>
                    <th class="text-left">Member</th>
                    <th class="text-left">Client</th>
                    <th class="text-left">Date</th>
                    <th class="text-left">Amount</th>
                    <th class="text-left">Status</th>
                  </tr>
                </thead>
                <tbody id="remove-hover">
                  <tr
                    v-for="(item, index) in allInvoices"
                    :key="index"
                    class="text-gray"
                  >
                  <td  class="pa-1 text-gray text-start font-14">
                      <span class="pl-3"> {{ item.ref.number }} </span>
                            </td>
                             <td  class="pa-1 text-gray text-start font-14">
                              <a class="pl-3" @click="goToMemberTab(item)"> {{ item.member.memberId }}</a> 
                            </td>
                            <td class="pa-1 text-gray text-start font-14">
                             <span class="pl-3">{{ item.member.firstName }}       {{  item.member.lastName }}</span> 
                            </td>
                            <td class="pa-1 text-gray text-start font-14">
                              <span class="pl-3">{{  item.client.companyName }}      </span>                  
                                </td>
                            <td  class="pa-1 text-gray text-start font-14">
                              <span class="pl-3">{{ item.date | moment('MM/DD/YYYY') }}   </span>                     
                                </td>

                              <td  class="pa-1 text-gray text-start font-14">
                                <span class="pl-3">$ {{ item.amount.toFixed(2) }}</span>
                            </td>
      
                            <td class="text-start text-gray text-center pa-1  font-14">
                              <!-- <v-chip :color="returnStatusColor(item.status)"> -->
                                <span class="pl-3">{{ item.status.charAt(0).toUpperCase() +  item.status.slice(1)}}</span>
                              <!-- </v-chip> -->
                            </td>
                  </tr>
                </tbody>
              </v-simple-table>
      <span v-for="(item, index) in list" :key="index">
  {{ item }}
      </span>
      <v-navigation-drawer
        class="userFilterPosition"
        width="350"
        v-model.trim="drawer"
        absolute
        temporary
      >
        <div class="filter-sideBar">
          <div>
            <div class="row no-padding col-md-12 justify-space-between">
              <h3 class="font pl-3 mt-1">Invoices Filters</h3>
              <h1 class="pointer mr-3" @click.stop="drawer = !drawer">x</h1>
            </div>
            <v-divider></v-divider>
            <div class="col-md-12">
              <div
                class="row col-md-12 justify-space-between no-padding no-margin"
              >
                <div class="no-padding font filter-items-font font-weight-bold">
                  Selected Filter
                </div>
                <div
                  class="underline-text pointer text-gray filter-items-font"
                  @click="clearAll"
                >
                  Clear All
                </div>
              </div>
            </div>
            <div class="overflow-fitlers-buttons">
              <div class="row mb-1 col-md-12">
                <v-btn
                  v-for="(item, index) in selectedArray.concat(
                    selectedTypeArray,
                    selectedLastVisitArray,
                    selectedStatusArray
                  )"
                  :key="index"
                  class="text-center mb-1 ml-2 mr-2 filter-btn"
                  small
                >
                  <span v-html="item.text"></span>
                  <h5 class="ml-3" @click="removeFilter(item)">x</h5>
                </v-btn>
              </div>
              <v-divider></v-divider>
              <v-expansion-panels
                accordion
                multiple
                v-model.trim="panel"
                class="no-shadow no-padding user-expansion-panels"
              >
                <!-- <v-expansion-panel class="no-shadow no-padding">
                  <v-expansion-panel-header
                    class="
                      font-weight-black
                      change-padding-filter
                      filter-items-font
                      mb-0
                      pb-0
                    "
                    >Client</v-expansion-panel-header
                  >
                  <v-expansion-panel-content
                    class="no-shadow no-padding mb-3 content-filter"
                  >
                    <v-text-field
                      solo
                      class="filter-serach-input no-padding mb-4"
                      v-model.trim="type"
                      dense
                      :hide-details="true"
                      @input="searchType()"
                      placeholder="Search"
                      append-icon="fa fa-search"
                    ></v-text-field>
                    <v-checkbox
                      label="All"
                      color="primary"
                      v-if="showType.all"
                      :on-icon="'mdi-square'"
                      v-model.trim="filter.type.all"
                      class="no-padding radio-buttons-filters"
                      hide-details
                      @click="
                        pushtoTypeFilter(
                          { text: 'All', value: 'allTypes' },
                          filter.type.all
                        )
                      "
                    >
                    </v-checkbox>
                    <v-checkbox
                      label="7-Eleven"
                      color="primary"
                      v-if="showType.eleven"
                      v-model.trim="filter.type.eleven"
                      :on-icon="'mdi-square'"
                      class="no-padding radio-buttons-filters"
                      hide-details
                      @click="
                        pushtoTypeFilter(
                          { text: '7-Eleven', value: 'eleven' },
                          filter.type.eleven
                        )
                      "
                    >
                    </v-checkbox>
                    <v-checkbox
                      label="Boeing"
                      color="primary"
                      :on-icon="'mdi-square'"
                      v-if="showType['boeing']"
                      v-model.trim="filter.type.boeing"
                      class="no-padding radio-buttons-filters"
                      hide-details
                      @click="
                        pushtoTypeFilter(
                          { text: 'Boeing', value: 'boeing' },
                          filter.type.boeing
                        )
                      "
                    >
                    </v-checkbox>
                    <v-checkbox
                      label="Catepillar"
                      color="primary"
                      :on-icon="'mdi-square'"
                      v-if="showType['catepillar']"
                      v-model.trim="filter.type.catepillar"
                      class="no-padding radio-buttons-filters"
                      hide-details
                      @click="
                        pushtoTypeFilter(
                          { text: 'Catepillar', value: 'catepillar' },
                          filter.type.catepillar
                        )
                      "
                    >
                    </v-checkbox>
                  </v-expansion-panel-content>
                </v-expansion-panel> -->
                <v-expansion-panel class="no-padding no-shadow pb-2">
                  <v-expansion-panel-header
                    class="
                      font-weight-black
                      change-padding-filter
                      filter-items-font
                    "
                    >Status</v-expansion-panel-header
                  >
                  <v-expansion-panel-content class="no-padding content-filter">
                    <v-text-field
                    solo
                    class="filter-serach-input no-padding mb-4"
                    v-model.trim="status"
                    dense
                    :hide-details="true"
                    @input="searchStatus()"
                    placeholder="Search"
                    append-icon="fa fa-search"
                  ></v-text-field>
                  <v-checkbox
                    label="All"
                    color="primary"
                    v-if="showStatuses['all']"
                    :on-icon="'mdi-square'"
                    v-model.trim="filter.stat['all']"
                    class="no-padding radio-buttons-filters"
                    hide-details
                    @click="
                      pushtoStatusFilter(
                        { text: 'All', value: 'allStatuses' },
                        filter.stat['all']
                      )
                    "
                  >
                  </v-checkbox>
              {{ status }}
                  <v-checkbox v-for="(item,index) in statuses" :key="index"
                    :label="item"
                    color="primary"
                    v-if="showStatuses[item]"
                    v-model.trim="filter.stat[item?.toLowerCase()]"
                    :on-icon="'mdi-square'"
                    class="no-padding radio-buttons-filters"
                    hide-details
                    @click="
                      pushtoStatusFilter(
                        { text: item, value:item?.toLowerCase() },
                        filter.stat[item?.toLowerCase()]
                      )
                    "
                  >
                  </v-checkbox>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel class="no-shadow no-padding mt-3 mb-3">
                  <v-expansion-panel-header
                      class="
                      font-weight-black
                      change-padding-filter
                      filter-items-font
                    "
                  >Date
                  </v-expansion-panel-header
                  >
                  <v-expansion-panel-content class="no-padding content-filter">
                    <v-chip-group
                        multiple
                        id="months-div"
                        active-class="primary--text"
                        class="no-padding"
                    >
                      <v-chip
                          v-for="tag in months"
                          :key="tag"
                          @click="lastVisitFilter(tag)"
                      >
                        {{ tag }}
                      </v-chip>
                    </v-chip-group>
                  </v-expansion-panel-content>
                </v-expansion-panel>
           
              </v-expansion-panels>
            </div>
          </div>
          <div>
            <v-divider></v-divider>
            <v-btn class="blue-buttons col-md-12 mt-4 mb-1" @click="filterUsers"
              >View Result</v-btn
            >
          </div>
        </div>
      </v-navigation-drawer>
    </v-sheet>
  </div>
  
  <div v-else>
    <router-view></router-view>
        <successModal
        ref="success_modal"
        v-on:confirm="closeSuccessModal"
    ></successModal>
    
    <errorModal 
    ref="error_modal"
     v-on:confirm="closeErrorModal">
    </errorModal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import api from "../../../../services/api";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../TablesTemplate/ErrorDialog.vue";
import FullscreenLoader from "../../../Common/FullscreenLoader.vue";
import XLSX from "xlsx";

export default {
  components: {successModal, errorModal, FullscreenLoader},
  data() {
    return {
      searchValue: '',
      pagination: {
      rowsPerPage: 30
    },
      tableHeaders: [
        {text: 'Reference Number ', value:'transactionId',  sortable: false},
        {text: 'Member ID', value:'memberID',sortable: false},
        {text: 'Member', value:'member',  sortable: false},
        {text: 'Client', value:'client',  sortable: false},
        {text: 'Date', value:'date', sortable: false},
        {text: 'Amount', value:'amount', sortable: false},
        {text: 'Status', value:'status', sortable: false},
      ],
      loader: false,
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      items: [],
      list: [],
      statuses: [],
      allInvoices: [],
      filterItems: false,
      overlay: false,
      display: true,
      filterArray: [],
      selectedStatusArray: [],
      windowWidth: window.innerWidth,
      noResultMessage: false,
      drawer: null,
      showAging: {
        all: true,
        current: true,
        numDays: true,
        greaterThen: true,
      },
      showStatus: {
        all: true,
        pending: true,
        processing: true,
        paid: true,
      },
      showType: {
        all: true,
        eleven: true,
        boeing: true,
        catepillar: true,
      },
      userStatus: [
        {
          text: "ALL",
          value: "all",
        },

        {
          text: "Pending",
          value: "pending",
        },
        {
          text: "Active",
          value: "active",
        },
        {
          text: "Expired",
          value: "expired",
        },
      ],
      userType: [
        {
          text: "ALL",
          value: "all",
        },
        {
          text: "7-Eleven",
          value: "7-Eleven",
        },
        {
          text: "Boeing",
          value: "boeing",
        },
        {
          text: "Catepillar",
          value: "Catepillar",
        },
      ],
      userAging: [
        {
          text: "ALL",
          value: "allAging",
        },
        {
          text: "Current",
          value: "current",
        },
        {
          text: "30 - 60 days",
          value: "numDays",
        },
        {
          text: "Grater than 60 daya",
          value: "greaterThen",
        },
      ],
      selectedArray: [],
      selectedTypeArray: [],
      showStatuses: {},
      getUsers: [],
      panel: [0, 1],
      filterUse: false,
      type: "",
      status: "",
      aging: "",
      statusArray: [],
      agingArray: [],
      selectedAgingArray: [],
      selectedLastVisitArray: [],

      typeArray: [],
      filter: {
        status: {
          all: false,
          pending: false,
          inactive: false,
          processing: false,
          paid: false,
        },
        type: {
          all: false,
          user: false,
          admin: false,
        },
        aging: {
          all: false,
          current: false,
          greaterThen: false,
          numDays: false,
        },
        stat: {},
      },
        totalInvoices: 0,
        tableLoading: false,
        page: 1,
        itemsPerPage: 10,
        itemsPerPageOptions: [5, 10, 20, 50],
        searchQuery: "",
        debounceTimer: null
    };
  },
  watch: {
    showRoutePathForFilter: {
      handler(newValue, oldValue) {
        this.drawer = this.showRoutePathForFilter;
      },
      deep: true,
    },
    drawer: {
      handler(newValue, oldValue) {
        this.$store.commit("setNavItemFilter", this.drawer);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["showRoutePathForFilter"]),
    routeParams() {
      return this.$route.params.name;
    },
    singleEmp() {
      let emp = "";
      if (localStorage.getItem("employer")) {
        emp = JSON.parse(localStorage.getItem("employer"));
      }
      return emp;
    },
    arrayInFilters() {
      return this.allInvoices;
    },
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
    filteredPayments() {
        return this.allInvoices.filter(item => {
        let search = this.searchValue.toLowerCase()
        let match = false
        if(item.invoiceNo)match = match || item.invoiceNo.toLowerCase().includes(search)
        if(item.member.id)match = match || item.member.id.toLowerCase().includes(search)
        if(item.member?.firstName)match = match || item.member.firstName.toLowerCase().includes(search)
        if(item.member?.lastName)match = match || item.member.lastName.toLowerCase().includes(search)
        if(item.client)match = match || item.client.toLowerCase().includes(search)
        if(item.status)match = match || item.status.toLowerCase().includes(search)
        return match
        })
    },
    pageStart() {
      return (this.page - 1) * this.itemsPerPage + 1;
    },
    pageEnd() {
      return Math.min(this.page * this.itemsPerPage, this.totalInvoices);
    },
    totalPages() {
      return Math.ceil(this.totalInvoices / this.itemsPerPage);
    },
  },
  created() {
    this.fetchInvoicesWithPagination(true);
  },
  methods: {
    updateItemsPerPage(newVal) {
        this.itemsPerPage = newVal;
        this.page = 1;

        this.fetchInvoicesWithPagination();
    },
    updatePage(newVal) {
        this.page = newVal;
        this.fetchInvoicesWithPagination();
    },
    searchInvoices() {
        this.page = 1;

        // Clear the previous timeout if the user is still typing
        clearTimeout(this.debounceTimer);
        
        // Set a new timeout to call the search after a delay
        this.debounceTimer = setTimeout(() => {
            this.fetchInvoicesWithPagination();
        }, 500); 
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    returnStatusColor(status){
      if(status == 'pending'){
        return 'default'
      }else if(status == 'processing'){
        return 'primary'
      }else if(status == 'partially paid'){
        return 'warning'
      }else if(status == 'paid'){
        return 'success'
      }else if(status == 'rejected'){
        return 'error'
      }
    },
    goToMemberTab(item){
      this.$router.push(`/dpc-providers/members/member/` + item.member.id);
    },
   
    exportData(tableID, filename = "") {
      var elt = document.getElementById(tableID);
      var downloadLink;
      var dataType = "application/vnd.ms-excel";
      var tableSelect = elt;
      var tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

      // Specify file name
      filename = filename ? filename + ".xls" : "excel_data.xls";

      // Create download link element
      downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        var blob = new Blob(["\ufeff", tableHTML], {
          type: dataType,
        });
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = "data:" + dataType + ", " + tableHTML;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
    },
    lastVisitFilter(tag) {
      if (this.selectedLastVisitArray.length > 0) {
        let index = this.selectedLastVisitArray.findIndex((c) => c.text == tag);
        if (index == -1) {
          this.selectedLastVisitArray.push({text: tag, value: tag});
        } else {
          this.selectedLastVisitArray.splice(index, 1);
        }
      } else {
        this.selectedLastVisitArray.push({text: tag, value: tag});
      }
    },
    pushFilterAging(item, checked) {
      if (checked) {
        if (item.value == "allAging") {
          this.selectedAgingArray = [];
          this.filter.aging = {
            all: true,
            current: true,
            greaterThen: true,
            numDays: true,
          };
          this.selectedAgingArray.push({ text: "All", value: "allAging" });
        } else {
          this.selectedAgingArray.push(item);
        }
      } else {
        if (item.value == "allAging" || this.filter.aging.all) {
          this.filter.aging = {
            all: false,
            current: false,
            greaterThen: false,
            numDays: false,
          };
          this.selectedAgingArray = [];
        } else {
          this.selectedAgingArray.forEach((el, index) => {
            if (el.value == item.value) {
              this.selectedAgingArray.splice(index, 1);
            }
          });
        }
      }
    },
    showFilterSideBar() {
      this.drawer = !this.drawer;
      if (this.drawer) {
        this.$store.commit("setNavItemFilter", this.drawer);
      } else {
        this.$store.commit("setNavItemFilter", false);
      }
    },
    commitFilterNav() {
      if (this.drawer) {
        this.$store.commit("setNavItemFilter", false);
      }
    },
    pushtoTypeFilter(item, checked) {
      if (checked) {
        if (item.value == "allTypes") {
          this.selectedTypeArray = [];
          this.filter.type = {
            all: true,
            eleven: true,
            boeing: true,
            catepillar: true,
          };
          this.selectedTypeArray.push({ text: "All", value: "allTypes" });
        } else {
          this.selectedTypeArray.push(item);
        }
      } else {
        if (item.value == "allTypes" || this.filter.type.all) {
          this.filter.type = {
            all: false,
            eleven: false,
            boeing: false,
            catepillar: false,
          };
          this.selectedTypeArray = [];
        } else {
          this.selectedTypeArray.forEach((el, index) => {
            if (el.value == item.value) {
              this.selectedTypeArray.splice(index, 1);
            }
          });
        }
      }
    },
    pushFilter(item, checked) {
      if (checked) {
        if (item.value == "all") {
          this.selectedArray = [];
          this.filter.status = {
            all: true,
            pending: true,
            inactive: true,
            processing: true,
            paid: true,
          };
          this.selectedArray.push({ text: "All", value: "all" });
        } else {
          this.selectedArray.push(item);
        }
      } else {
        if (item.value == "all" || this.filter.status.all) {
          this.filter.status = {
            all: false,
            pending: false,
            inactive: false,
            processing: false,
            paid: false,
          };
          this.selectedArray = [];
        } else {
          this.selectedArray.forEach((el, index) => {
            if (el.value == item.value) {
              this.selectedArray.splice(index, 1);
            }
          });
        }
      }
    },

    searchStatus() {
      for (let key in this.showStatus) {
        if (key.toLowerCase().includes(this.statuses.toLowerCase())) {
          this.showStatus[key] = true;
        } else {
          this.showStatus[key] = false;
        }
      }
    },
    searchType() {
      for (let key in this.showType) {
        if (key.toLowerCase().includes(this.type.toLowerCase())) {
          this.showType[key] = true;
        } else {
          this.showType[key] = false;
        }
      }
    },
    pushtoStatusFilter(item, checked) {
      if (checked) {
        if (item.value == "allStatuses") {
          this.selectedStatusArray = [];

          Object.keys(this.filter.stat).forEach(key => {
            this.filter.stat[key]=true;
          });
          this.selectedStatusArray.push({ text: "All", value: "allStatuses" });

        } else {
          this.selectedStatusArray.push(item);
        }
      } else {
        if (item.value == "allStatuses" || this.filter.stat['all']) {
          Object.keys(this.filter.stat).forEach(key => {
            this.filter.stat[key]=false;
          });
          this.selectedStatusArray = [];
        } else {
          this.selectedStatusArray.forEach((el, index) => {
            if (el.value == item.value) {
              this.selectedStatusArray.splice(index, 1);
            }
          });
        }
      }
    },

    filterUsers(title) {
      api()
        .get("/dpcproviders/invoices")
        .then((res) => {
          this.allInvoices = res.data;
          this.filterArray = res.data;

          if (!this.filter.status.all) {
            let params = "";
            let aging = "";
            let visit = "";

            for (let key in this.filter.status) {
              if (this.filter.status[key]) {
                params += `${key},`;
              }
            }

            this.selectedLastVisitArray.forEach((el) => {
                visit += el.text + `,`;
            });

            for (let key in this.filter.aging) {
              if (this.filter.aging[key]) {
                aging += `${key},`;
              }
            }
            console.log('Visit filter:',visit);
            if ((params && !this.filter.status.all) || visit) {
              this.allInvoices = this.filterArray.filter(function (el) {
                console.log('aGING',new Date(el.date)
                        .toLocaleString("default", { month: "long" })
                        .toString()
                        .toLowerCase())
                return (
                  params.toLowerCase().includes(el.status.toLowerCase()) ||
                  visit
                    .toString()
                    .toLowerCase()
                    .includes(
                      new Date(el.date)
                        .toLocaleString("default", { month: "long" })
                        .toString()
                        .toLowerCase()
                    )
                );
              });
            }
          }

          if (this.allInvoices.length < 1) {
            this.filterItems = true;
          } else {
            this.filterItems = false;
          }
          this.filterUse = true;
          this.drawer = false;
        });
    },
    clearAll() {
      this.filter.stat= {},
      this.filter.status = {
        all: false,
        pending: false,
        inactive: false,
        processing: false,
        paid: false,
      };
      this.filter.type = {
        all: false,
        eleven: false,
        boeing: false,
        catepillar: false,
      };
      this.filter.aging = {
        all: false,
        current: false,
        greaterThen: false,
        numDays: false,
      };
      this.selectedStatusArray=[];
      this.selectedArray = [];
      this.selectedTypeArray = [];
      this.getItems("all");
    },

    removeFilter(item) {
      // if (item.value == "allTypes") {
      //   this.selectedTypeArray = [];
      //   this.filter.type = {
      //     all: false,
      //     eleven: false,
      //     boeing: false,
      //     catepillar: false,
      //   };
      // } else if (this.filter.status[item.value]) {
      //   this.filter.status[item.value] = false;
      //   this.selectedArray.splice(this.selectedArray.indexOf(item), 1);
      // }

      if (item.value == "all") {
        this.selectedArray = [];
        this.filter.stat = {
          all: false,
          pending: false,
          inactive: false,
          processing: false,
          paid: false,
        };
      } else if (this.filter.stat[item.value]) {
        this.filter.stat[item.value] = false;
        this.selectedStatusArray.splice(this.selectedStatusArray.indexOf(item), 1);
      }
      // else{
      //   let index = this.selectedLastVisitArray.findIndex(
      //       (c) => c.text == item
      //   );
      //   this.selectedLastVisitArray.splice(index, 1);
      // }

      // if (item.value == "allAging") {
      //   this.selectedArray = [];
      //   this.filter.aging = {
      //     all: false,
      //     current: false,
      //     greaterThen: false,
      //     numDays: false,
      //   };
      // } else if (this.filter.aging[item.value]) {
      //   this.filter.aging[item.value] = false;
      //   this.selectedTypeArray.splice(this.selectedTypeArray.indexOf(item), 1);
      // }
    },

    fetchInvoicesWithPagination(useLoader) {
        this.tableLoading = true;
        if (useLoader === true) {
            this.loader = true;
        }

        const queryParams = new URLSearchParams();

        queryParams.append('pagination', 'true');
        queryParams.append('limit', this.itemsPerPage);
        queryParams.append('page', this.page);

        let url = '/dpcproviders/payments'
        
        if (this.searchQuery && this.searchQuery !== '') {
            const searchQuery = this.searchQuery.trim().toLowerCase();
            queryParams.append('search', searchQuery);
            url = '/dpcproviders/payments/paginatedsearch'
        }

        api()
            .get(`${url}?${queryParams.toString()}`)
            .then((res) => {
                if (res && res.data) {
                    this.loader = false;
                    this.allInvoices = res.data.transactions;
                    this.totalInvoices = res.data.totalTransactions
                    this.statusArray = this.userStatus;
                    this.typeArray = this.userType;
                    this.agingArray = this.userAging;
                    this.selectedArray = [];
                    this.filterArray = this.allInvoices;
                    this.populateStatus();

                }
            })
            .finally(() => {
                this.loader = false
                this.tableLoading = false;
            });
    },
    getItems() {
      this.loader = true;
      api()
        .get("/dpcproviders/payments")
        .then((res) => {
          if (res) {
            this.loader = false;
            this.allInvoices = res.data;
            this.statusArray = this.userStatus;
            this.typeArray = this.userType;
            this.agingArray = this.userAging;
            this.selectedArray = [];
            this.filterArray = this.allInvoices;
            this.populateStatus();

          }
          // this.allInvoices.forEach((el) => {
          //       this.statuses.push(el.satus);
          //       console.log('all',this.statuses)
          // });
          
        })
        .finally(() => {
                this.loader = false
              });
    },
    populateStatus(){
      this.allInvoices.forEach(element => {
          this.statuses.push(element.satus);
           this.showStatuses[element.satus] = true
          this.filter.stat[element.satus?.toLowerCase()] = false;
          });

         this.filter.stat['all'] = false;
        this.showStatuses['all'] = true;
        const unique = (value, index, self) => {
        return self.indexOf(value) === index
           }
       this.list = this.list.filter(unique)

    },

    singleItem(item) {
      api()
        .get("/dpcproviders/invoice/" + item.id)
        .then((res) => {
          if (res) {
            //this.$store.commit("setSingleItem", item);
            this.$router.push(`/dpc-providers/invoices/details/`+item.id);
          }
        });
    },
    search(val) {
      // val = val.trim();
      this.allInvoices = this.filterArray.filter((el) => {
        if (el.client && el.client.toLowerCase().includes(val.toLowerCase())) {
          return el.client?.toLowerCase().includes(val.toLowerCase());
        }
        else if (el.amount && el.amount.toString().toLowerCase().includes(val.toLowerCase())) {
          return el.amount.toString().toLowerCase().includes(val.toLowerCase());
        }
        else if (el.invoiceNo && el.invoiceNo.toString().toLowerCase().includes(val.toLowerCase())) {
          return el.invoiceNo.toString().toLowerCase().includes(val.toLowerCase());
        }
        else if (el.member.firstName && el.member.firstName.toString().toLowerCase().includes(val.toLowerCase())) {
          return el.member.firstName.toString().toLowerCase().includes(val.toLowerCase());
        }
        else if (el.member.lastName && el.member.lastName.toString().toLowerCase().includes(val.toLowerCase())) {
          return el.member.lastName.toString().toLowerCase().includes(val.toLowerCase());
        }
        else if (el.member.id && el.member.id.toString().toLowerCase().includes(val.toLowerCase())) {
          return el.member.id.toString().toLowerCase().includes(val.toLowerCase());
        }

      });
      if (this.allInvoices.length < 1) {
        this.filterItems = true;
      }
      else{
        this.filterItems = false;
      }
    },
  },
};
</script>
<style>
.v-data-table__wrapper > table > thead > tr > th {
  height: 40px !important;
  padding-bottom: 4px !important;
}
</style>
