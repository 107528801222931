var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.readyToShow)?_c('div',{staticClass:"col-md-12 text-center justify-center"},[_c('v-progress-circular',{staticClass:"text-center",attrs:{"indeterminate":"","size":100,"color":"primary"}})],1):_c('div',{staticClass:"row"},[_vm._l((_vm.items),function(item){return _c('div',{key:item.title,staticClass:"col-md-4 col-lg-3 col-lg-3 text-center justify-center align-center"},[_c('v-card',{staticClass:"centered-card space-around-content",staticStyle:{"height":"100%","cursor":"pointer"},on:{"click":function($event){item.url ? _vm.$router.push(item.url) : ''}}},[_c('img',{staticClass:"img-media",staticStyle:{"object-fit":"cover"},attrs:{"src":item.img,"height":"70","width":"70"}}),_c('h4',{staticClass:"text-center justify-center blue-text font mx-2 font-quicksand-medium-18"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('v-card-text',[_c('v-row',{staticClass:"mx-3 justify-center",attrs:{"align":"center"}},[(_vm.statistics && item.procentage)?_c('h1',{staticClass:"blue-text mb-4",staticStyle:{"font-size":"2rem"},domProps:{"innerHTML":_vm._s(
                _vm.statistics.projectedSavings
                  ? _vm.statistics.projectedSavings + '%'
                  : '0%'
              )}},[_vm._v(" "+_vm._s(item.procentage)+" ")]):_vm._e(),_c('h4',{staticClass:"text-center text-center card-desc-responsive font-quicksand-medium-18 mb-5",staticStyle:{"font-weight":"normal","line-height":"16px"}},[_vm._v(" "+_vm._s(item.desc)+" ")])])],1)],1)],1)}),_vm._l((_vm.precentageCards),function(item){return _c('div',{key:item.title,staticClass:"col-md-4 col-lg-3 col-lg-3 text-center justify-center aling-center"},[_c('v-card',{class:_vm.showWizard && item.title == 'Easy Setup'
            ? 'space-around-content orange-bg'
            : 'space-around-content',staticStyle:{"height":"13rem","cursor":"pointer"},on:{"click":function($event){item.img && _vm.showTheForm && item.title == 'Easy Setup'
            ? _vm.$router.push('/employer/wizard-form')
            : item.title == 'Easy Setup'
            ? _vm.$router.push('/employer/new-wizard-form')
            : ''}}},[_c('v-row',{staticClass:"justify-center",attrs:{"align":"center"}},[_c('h5',{staticClass:"text-center text-underline mt-1 font-quicksand-medium-18"},[_vm._v(" "+_vm._s(item.title)+" ")])]),_c('v-card-title',{staticClass:"text-center justify-center text-gray font percentage-num"},[(item.img)?_c('v-row',{staticClass:"text-center justify-center align-center"},[_c('img',{staticClass:"align-center setup-small-img",attrs:{"src":"/employer-home.png","width":"250","height":"60","alt":""}})]):_vm._e(),(item.clients)?_c('div',{staticClass:"row justify-space-around"},[_c('h4',{staticClass:"blue-text font-quicksand-medium-18"},[_vm._v(" "+_vm._s(_vm.statistics.noEmployees)+" "),_c('br'),_vm._v(" Employees ")]),_c('h4',{staticClass:"blue-text ml-4 font-quicksand-medium-18"},[_vm._v(" "+_vm._s(_vm.statistics.noPlanMembers)+" "),_c('br'),_vm._v(" Members ")])]):(item.inpatient && _vm.statistics.rates)?_c('div',{staticClass:"row justify-space-around blue-text"},[_c('div',{staticClass:"font-weight-bold"},[_c('h1',{staticClass:"blue-text mb-3"},[(!_vm.statistics.rates.inpatient)?_c('span',[_vm._v("0")]):_c('span',[_vm._v(_vm._s(_vm.statistics.rates.inpatient))]),_vm._v("% "),_c('br')]),_vm._v(" Inpatient ")]),_c('div',{staticClass:"ml-6 font-weight-bold"},[_c('h1',{staticClass:"blue-text mb-3"},[(!_vm.statistics.rates.outpatient)?_c('span',[_vm._v("0")]):_c('span',[_vm._v(_vm._s(_vm.statistics.rates.outpatient))]),_vm._v("% "),_c('br')]),_vm._v(" Outpatient ")])]):(item.title == 'Providers on Your Provider List')?_c('div',{staticClass:"blue-text"},[_c('h1',{domProps:{"innerHTML":_vm._s(_vm.statistics.providers ? _vm.statistics.providers : '0')}})]):(item.title == 'Projected Savings')?_c('div',{staticClass:"blue-text"},[_c('h1',{domProps:{"innerHTML":_vm._s(
                _vm.statistics.projectedSavings
                  ? _vm.statistics.projectedSavings + '%'
                  : '0%'
              )}})]):(item.title == 'Provider Coverage')?_c('div',{staticClass:"blue-text"},[_c('h1',{domProps:{"innerHTML":_vm._s(
                _vm.statistics.providerCoverage
                  ? _vm.statistics.providerCoverage + '%'
                  : '0%'
              )}})]):(item.title == 'Contract Renewal')?_c('div',{staticClass:"blue-text"},[_c('h1',{domProps:{"innerHTML":_vm._s(
                _vm.statistics.contractRenewal
                  ? _vm.parsedDate(_vm.statistics.contractRenewal)
                  : '/'
              )}})]):_vm._e()],1),_c('v-row',{staticClass:"mx-10 justify-center",attrs:{"align":"center"}},[(item.desc)?_c('h5',{staticClass:"text-center font-quicksand-medium-18 pb-4",class:item.title == 'Easy Setup' ? 'red-desc' : 'blue-text',staticStyle:{"line-height":"20px"}},[_vm._v(" "+_vm._s(item.desc)+" ")]):_vm._e()])],1)],1)})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }