<template>
  <div class="row row-height">
    <v-sheet
      v-if="!isChildren"
      class="overflow-hidden col-md-12 mt-1"
      style="position: relative; border-radius: 8px"
    >
      <div class="">
        <v-row align="center" justify="center">
          <div
            :class="
              overlay
                ? 'hideOnMoblie background-opacity col-md-9'
                : 'col-md-12 navigationWidth no-padding'
            "
          >
            <v-row>
              <v-col class="col-xl-1 col-md-2 cols-4" cols="4">
                <div class="col-md-12">
                  <h3 class="form-title font col-md-12">Users </h3>
                </div>
              </v-col>
              <v-col class="col-xl-3 col-md-5 cols-8" cols="8">
                <div class="col-md-12 mt-4 d-flex no-padding">
                  <v-text-field
                    :disabled="overlay"
                    class="input-control mr-5"
                    prepend-inner-icon="mdi-magnify"
                    @input="searchUsers"
                    light
                    dense
                    outlined
                    placeholder="Search Users"
                  ></v-text-field>
                  <v-btn
                    :disabled="overlay"
                    class="text-gray filter-btn"
                    @click.stop="showFilterSideBar"
                  >
                    <svg
                      width="17"
                      class="mr-2"
                      height="14"
                      viewBox="0 0 17 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1H16"
                        stroke="#424242"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M2.59766 5H14.403"
                        stroke="#424242"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6.3125 13H10.6858"
                        stroke="#424242"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M4.19531 9H12.8046"
                        stroke="#424242"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Filters
                  </v-btn>
                </div>
              </v-col>
              <v-col
                class="col-xl-8 col-md-5 cols-12 pr-6 pt-6 text-end"
                cols="12"
                align="end"
              >
                <v-menu offset-y>
                  <template v-slot:activator="{}">
                    <v-btn
                      :disabled="overlay"
                      @click="
                        $router.push(`/employer-client/account/users/add-new`)
                      "
                      class="my-1 mx-2 pl-2 blue-buttons"
                    >
                      <span class="pl-5 pr-5">Add User</span>
                    </v-btn>
                  </template>
                </v-menu>
              </v-col>
            </v-row>
            <v-simple-table fixed-header height="630px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">First Name</th>
                    <th class="text-left">Last Name</th>
                    <th class="text-left">Email</th>
                    <th class="text-left">Title</th>
                    <th class="text-left">City</th>
                    <th class="text-left">State</th>
                  </tr>
                </thead>
                <tbody v-if="allUsers.length > 0">
                  <tr
                    v-for="item in allUsers"
                    :key="item.name"
                    class="text-gray"
                  >
                    <td v-if="item.firstName">
                      <a @click="singleUser(item)">{{ item.firstName }} </a>
                    </td>
                    <td v-else><a @click="singleUser(item)">/ </a></td>
                    <td v-html="item.lastName ? item.lastName : '/'"></td>
                    <td v-html="item.email ? item.email : '/'"></td>
                    <td
                      v-if="item.status"
                      v-html="
                        item.status && item.status == 'inactive'
                          ? 'In Active'
                          : item.status
                          ? item.status.charAt(0).toUpperCase() +
                            item.status.slice(1)
                          : '/'
                      "
                    ></td>
                    <td v-if="item.title">
                      {{
                        item.title.charAt(0).toUpperCase() + item.title.slice(1)
                      }}
                    </td>
                    <td v-else>/</td>
                    <td
                      v-if="item.groupPortal"
                      v-html="
                        item.groupPortal[0] &&
                        item.groupPortal[0].role &&
                        item.groupPortal[0].role.name
                          ? item.groupPortal[0].role.name
                              .charAt(0)
                              .toUpperCase() +
                            item.groupPortal[0].role.name.slice(1)
                          : '/'
                      "
                    ></td>
                    <td v-else>{{ item.role }}</td>
                  </tr>
                </tbody>
                <tbody v-else-if="filterItems">
                  <v-col md="12" cols="12">
                    <v-alert
                      border="bottom"
                      colored-border
                      type="warning"
                      elevation="2"
                      class="text-gray"
                    >
                      Sorry! No results found
                    </v-alert>
                  </v-col>
                </tbody>
                <tbody v-else>
                  <v-col md="12">
                    You Don't have Users Yet. Click Here To Add New User.
                  </v-col>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-row>
      </div>
      <v-navigation-drawer
        class="userFilterPosition"
        width="350"
        v-model.trim="drawer"
        absolute
        temporary
      >
        <div class="filter-sideBar">
          <div>
            <div class="row no-padding col-md-12 justify-space-between">
              <h3 class="font pl-3 mt-1">User Filters</h3>
              <h1 class="pointer mr-3" @click.stop="drawer = !drawer">x</h1>
            </div>
            <v-divider></v-divider>
            <div class="col-md-12">
              <div
                class="row col-md-12 justify-space-between no-padding no-margin"
              >
                <div class="no-padding font filter-items-font font-weight-bold">
                  Selected Filter
                </div>
                <div
                  class="underline-text pointer text-gray filter-items-font"
                  @click="clearAll"
                >
                  Clear All
                </div>
              </div>
            </div>
            <div class="overflow-fitlers-buttons">
              <div class="row mb-1 col-md-12">
                <v-btn
                  v-for="(item, index) in selectedArray.concat(
                    selectedTypeArray
                  )"
                  :key="index"
                  class="text-center mb-1 ml-2 mr-2 filter-btn"
                  small
                >
                  <span v-html="item.text"></span>
                  <h5 class="ml-3" @click="removeFilter(item)">x</h5>
                </v-btn>
              </div>
              <v-divider></v-divider>
              <v-expansion-panels
                accordion
                multiple
                v-model.trim="panel"
                class="no-shadow no-padding user-expansion-panels"
              >
                <v-expansion-panel class="no-shadow no-padding">
                  <v-expansion-panel-header
                    class="
                      font-weight-black
                      change-padding-filter
                      filter-items-font
                    "
                    >Status</v-expansion-panel-header
                  >
                  <v-expansion-panel-content class="no-padding content-filter mb-3">
                    <v-text-field
                      solo
                      class="filter-serach-input mb-4"
                      v-model.trim="status"
                      @input="searchStatus()"
                      dense
                      :hide-details="true"
                      placeholder="Search"
                      append-icon="fa fa-search"
                    ></v-text-field>
                    <v-checkbox
                      label="All"
                      color="primary"
                      v-if="showStatus.all"
                      v-model.trim="filter.status.all"
                      class="no-padding small-text radio-buttons-filters"
                      hide-details
                      :on-icon="'mdi-square'"
                      @click="
                        pushFilter(
                          { text: 'All', value: 'all' },
                          filter.status.all
                        )
                      "
                    >
                    </v-checkbox>
                    <v-checkbox
                      label="Join Request"
                      color="primary"
                      v-if="showStatus['join request']"
                      v-model.trim="filter.status.join"
                      class="no-padding radio-buttons-filters"
                      hide-details
                      :on-icon="'mdi-square'"
                      @click="
                        pushFilter(
                          { text: 'Join Request', value: 'join' },
                          filter.status.join
                        )
                      "
                    >
                    </v-checkbox>
                    <v-checkbox
                      label="Active"
                      color="primary"
                      v-if="showStatus.active"
                      :on-icon="'mdi-square'"
                      v-model.trim="filter.status.active"
                      class="no-padding radio-buttons-filters"
                      hide-details
                      @click="
                        pushFilter(
                          { text: 'Active', value: 'active' },
                          filter.status.active
                        )
                      "
                    >
                    </v-checkbox>

                     <v-checkbox
                      label="Inactive"
                      color="primary"
                      v-if="showStatus.inactive"
                      :on-icon="'mdi-square'"
                      v-model.trim="filter.status.inactive"
                      class="no-padding radio-buttons-filters mb-2"
                      hide-details
                      @click="
                        pushFilter(
                          { text: 'Inactive', value: 'inactive' },
                          filter.status.inactive
                        )
                      "
                    >
                    </v-checkbox> 
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel class="no-shadow no-padding">
                  <v-expansion-panel-header
                    class="
                      font-weight-black
                      change-padding-filter
                      filter-items-font
                      mb-0
                      pb-0
                    "
                    >Type</v-expansion-panel-header
                  >
                  <v-expansion-panel-content class="no-padding content-filter">
                    <v-text-field
                      solo
                      class="filter-serach-input no-padding mb-4"
                      v-model.trim="type"
                      dense
                      :hide-details="true"
                      @input="searchType()"
                      placeholder="Search"
                      append-icon="fa fa-search"
                    ></v-text-field>
                    <v-checkbox
                      label="All"
                      color="primary"
                      v-if="showType.all"
                      :on-icon="'mdi-square'"
                      v-model.trim="filter.type.all"
                      class="no-padding radio-buttons-filters"
                      hide-details
                      @click="
                        pushtoTypeFilter(
                          { text: 'All', value: 'allTypes' },
                          filter.type.all
                        )
                      "
                    >
                    </v-checkbox>
                    <v-checkbox
                      label="Admins"
                      color="primary"
                      v-if="showType.admin"
                      v-model.trim="filter.type.admin"
                      :on-icon="'mdi-square'"
                      class="no-padding radio-buttons-filters"
                      hide-details
                      @click="
                        pushtoTypeFilter(
                          { text: 'Admins', value: 'admin' },
                          filter.type.admin
                        )
                      "
                    >
                    </v-checkbox>
                    <v-checkbox
                      label="General User"
                      color="primary"
                      :on-icon="'mdi-square'"
                      v-if="showType['general user']"
                      v-model.trim="filter.type.user"
                      class="no-padding radio-buttons-filters"
                      hide-details
                      @click="
                        pushtoTypeFilter(
                          { text: 'General User', value: 'user' },
                          filter.type.user
                        )
                      "
                    >
                    </v-checkbox>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
          <div>
            <v-divider></v-divider>
            <v-btn
              class="blue-buttons col-md-12 mt-3 mb-md-3 mb-xl-0"
              @click="filterUsers"
              >View Result</v-btn
            >
          </div>
        </div>
      </v-navigation-drawer>
    </v-sheet>
    <router-view></router-view>
  </div>
</template>
<script>
import api from "../../../../../services/api";
export default {
  data() {
    return {
      items: [],
      allUsers: [
        {
          firstName: "Bill",
          lastName: "Smith",
          email: "bill.smith@smartlight.cm",
          title: "Regional VP",
          city: "Detorit",
          state: "Michigan",
          status: "Active",
          role: "Admin",
        },
      ],
      overlay: false,
      display: true,
      filterArray: [],
      windowWidth: window.innerWidth,
      noResultMessage: false,
      filterItems: false,
      drawer: null,
      showStatus: {
        all: true,
        active: true,
        inactive:true,
        "in active": true,
        invited: true,
        "join request": true,
      },
      showType: {
        all: true,
        "general user": true,
        admin: true,
      },
      userStatus: [
        {
          text: "ALL",
          value: "all",
        },

        {
          text: "Join Request",
          value: "join",
        },
        {
          text: "Active",
          value: "active",
        },
        {
          text: "In Active",
          value: "inactive",
        },
        {
          text: "Inactive",
          value: "inactive",
        },
      ],
      userType: [
        {
          text: "ALL",
          value: "all",
        },
        {
          text: "Admins",
          value: "admins",
        },
        {
          text: "General User",
          value: "users",
        },
      ],
      selectedArray: [],
      selectedTypeArray: [],
      getUsers: [],
      panel: [0, 1],
      filterUse: false,
      type: "",
      status: "",
      statusArray: [],
      typeArray: [],
      filter: {
        status: {
          all: false,
          active: false,
          inactive: false,
          invited: false,
          join: false,
        },
        type: {
          all: false,
          user: false,
          admin: false,
        },
      },
    };
  },
  computed: {
    routeParams() {
      return this.$route.params.name;
    },
    singleEmp() {
      let emp = "";
      if (localStorage.getItem("employer")) {
        emp = JSON.parse(localStorage.getItem("employer"));
      }
      return emp;
    },
    arrayInFilters() {
      return this.allUsers;
    },
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
  },
  created() {
    this.getAllUsers("all");
    this.statusArray = this.userStatus;
    this.typeArray = this.userType;
    this.selectedArray = [];
    this.filterArray = this.allUsers;
  },
  watch: {
    showRoutePathForFilter: {
      handler(newValue, oldValue) {
        this.drawer = this.showRoutePathForFilter;
      },
      deep: true,
    },
    drawer: {
      handler(newValue, oldValue) {
        this.$store.commit("setNavItemFilter", this.drawer);
      },
      deep: true,
    },
  },
  methods: {
    showFilterSideBar() {
      this.drawer = !this.drawer;
      if (this.drawer) {
        this.$store.commit("setNavItemFilter", this.drawer);
      } else {
        this.$store.commit("setNavItemFilter", false);
      }
    },
    pushtoTypeFilter(item, checked) {
      if (checked) {
        if (item.value == "allTypes") {
          this.selectedTypeArray = [];
          this.filter.type = {
            all: true,
            user: true,
            admin: true,
          };
          this.selectedTypeArray.push({ text: "All", value: "allTypes" });
        } else {
          this.selectedTypeArray.push(item);
        }
      } else {
        if (item.value == "allTypes" || this.filter.type.all) {
          this.filter.type = {
            all: false,
            user: false,
            admin: false,
          };
          this.selectedTypeArray = [];
        } else {
          this.selectedTypeArray.forEach((el, index) => {
            if (el.value == item.value) {
              this.selectedTypeArray.splice(index, 1);
            }
          });
        }
      }
    },
    pushFilter(item, checked) {
      if (checked) {
        if (item.value == "all") {
          this.selectedArray = [];
          this.filter.status = {
            all: true,
            active: true,
            inactive: true,
            invited: true,
            join: true,
          };
          this.selectedArray.push({ text: "All", value: "all" });
        } else {
          this.selectedArray.push(item);
        }
      } else {
        if (item.value == "all" || this.filter.status.all) {
          this.filter.status = {
            all: false,
            active: false,
            inactive: false,
            invited: false,
            join: false,
          };
          this.selectedArray = [];
        } else {
          this.selectedArray.forEach((el, index) => {
            if (el.value == item.value) {
              this.selectedArray.splice(index, 1);
            }
          });
        }
      }
    },

    searchStatus() {
      for (let key in this.showStatus) {
        if (key.toLowerCase().includes(this.status.toLowerCase())) {
          this.showStatus[key] = true;
        } else {
          this.showStatus[key] = false;
        }
      }
    },
    searchType() {
      for (let key in this.showType) {
        if (key.toLowerCase().includes(this.type.toLowerCase())) {
          this.showType[key] = true;
        } else {
          this.showType[key] = false;
        }
      }
    },
    filterUsers() {
      if (!this.filter.status && !this.filter.type) {
        this.getAllUsers("all");
      } else {
        this.getAllUsers(this.filter.status);
      }
      this.filterUse = true;
      this.drawer = false;
    },
    clearAll() {
      this.filter.status = {
        all: false,
        active: false,
        inactive: false,
        invited: false,
        join: false,
      };
      this.filter.type = {
        all: false,
        user: false,
        admin: false,
      };

      this.selectedArray = [];
      this.selectedTypeArray = [];
      this.getAllUsers("all");
    },

    removeFilter(item) {
      if (item.value == "allTypes") {
        this.selectedTypeArray = [];
        this.filter.type = {
          all: false,
          user: false,
          admin: false,
        };
      } else if (this.filter.status[item.value]) {
        this.filter.status[item.value] = false;
        this.selectedArray.splice(this.selectedArray.indexOf(item), 1);
      }

      if (item.value == "all") {
        this.selectedArray = [];
        this.filter.status = {
          all: false,
          active: false,
          inactive: false,
          invited: false,
          join: false,
        };
      } else if (this.filter.type[item.value]) {
        this.filter.type[item.value] = false;
        this.selectedTypeArray.splice(this.selectedTypeArray.indexOf(item), 1);
      }
    },

    getAllUsers(type) {
      // let params = "";
      // if (type != "all") {
      //   for (let key in type) {
      //     if (type[key]) {
      //       params += `${key}=${type[key]}&`;
      //     }
      //   }
      // }
      // let user = JSON.parse(localStorage.getItem("user"));
      // let advisorId = user.groupPortal[0].organization.employerAdvisor;
      // api()
      //   .get(`/advisors/${advisorId}/users?${params}`)
      //   .then((res) => {
      //     this.allUsers = res.data.users;
      //     this.filterArray = res.data.users;
      //     this.getUsers = res.data.users;
      //     if (res.data.users.length < 1) {
      //       this.noResultMessage = true;
      //     } else {
      //       this.noResultMessage = false;
      //     }
      //   });
    },

    singleUser(item) {
      this.$store.commit("setSingleUserInEmployerAdvisorGlobal", item);
      this.$router.push(`/employer-client/account/users/user`);
      // if (!this.overlay) {
      //   let user = JSON.parse(localStorage.getItem("user"));
      //   let advisorId = user.groupPortal[0].organization.employerAdvisor;
      //   api()
      //     .get(`/advisors/${advisorId}/users/${item._id}`)
      //     .then((res) => {
      //       this.$store.commit(
      //         "setSingleUserInEmployerAdvisorGlobal",
      //         res.data.user
      //       );
      //       this.$router.push(`/employer-advisor/settings/users/user`);
      //     });
      // }
    },
    searchUsers(val) {
      this.allUsers = this.filterArray.filter((el) => {
        if (
          el.firstName &&
          el.firstName.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.firstName.toLowerCase().includes(val.toLowerCase());
        } else if (
          el.lastName &&
          el.lastName.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.lastName.toLowerCase().includes(val.toLowerCase());
        } else if (
          el.email &&
          el.email.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.email.toLowerCase().includes(val.toLowerCase());
        } else if (
          el.status &&
          el.status.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.status.toLowerCase().includes(val.toLowerCase());
        } else if (
          el.title &&
          el.title.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.title.toLowerCase().includes(val.toLowerCase());
        } else if (
          el.role &&
          el.role.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.role.toLowerCase().includes(val.toLowerCase());
        }
      });
      if (this.allUsers.length < 1) {
        this.filterItems = true;
      }
    },
  },
};
</script>
<style >
</style>