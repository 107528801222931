<template>
    <div class="no-padding no-margin">
        <AdvisorsTable v-if="path == `/provider/advisors`"></AdvisorsTable>
        <AddAdvisor v-if="path == `/provider/advisors/add-new`"></AddAdvisor>
        <InviteAdvisor v-if="path == `/provider/advisors/invite-advisor`"></InviteAdvisor>
        <Advisor v-if="path == `/provider/advisors/advisor`"></Advisor>
        <Result v-if="path == `/provider/advisors/add-new/result`"></Result>


    </div>
    
</template>
<script>
import AddAdvisor from './AddAdvisor.vue'
import InviteAdvisor from './InviteAdvisor.vue'
import Advisor from './Advisor.vue'
import AdvisorsTable from './AdvisorsTable.vue'
import Result from './Result.vue'

export default {
    components:{
    AdvisorsTable,
    AddAdvisor,
    InviteAdvisor,
    Advisor,
    Result
    },
    data(){
        return{
        }
    },
    computed:{
        path(){
            return this.$route.path
        },
        routeParams(){
            let parsed =''
            if(this.$route.params.name){
            parsed = this.$route.params.name.replace(/ /g, '%20')
            }
            return parsed
       }
    }
   
}
</script>