<template>
  <v-row class="add-client-form no-padding account">
  
    <v-row class="blue-underline mb-1 margin-l-11">
      <v-col class="col-xl-6 col-md-6 cols-6 pt-2 pb-0" cols="12">
        <div class="pt-1 pb-1">
          <h3 class="form-title font col-md-12">
            Settings -
            <span v-if="itemtab == 'tab0'">Account &amp; Bank Information</span>
            <span v-if="itemtab == 'tab1'">Notifications</span>
          </h3>
        </div>
      </v-col>
    </v-row>
    <div class="col-md-12 pl-0 pr-0">
      <v-tabs dark show-arrows style="background: white" v-model.trim="tab" class="">
        <v-tabs-slider style="background: orange"></v-tabs-slider>
        <v-tab
          style="background: #4072b7; text-transform: none"
          v-for="(item, i) in items"
          :key="i"
          class="col-md-3 display-mobile  pl-1 pr-1"
          :href="'#tab' + i"
          :style="i == 1 ? { 'margin-right': '5px', 'margin-left': '5px' } : ''"
        >
          <div class="mr-1 ml-1" :class="itemtab== 'tab1' ? 'margin-tel' : ''">
            {{ item.text }}
          </div>
        </v-tab>
      </v-tabs>
      <div class="col-md-12 contract-form" v-if="itemtab == 'tab0'">
        <Account />
      </div>
      <div class="col-md-12 contract-form no-padding" v-if="itemtab == 'tab1'">
        <Notifications />
      </div>

    </div>
  </v-row>
</template>
<script>
import api from "../../../../services/api";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import Account from "./Account.vue";
import Bank from "./BankInfo/BankInfo.vue";
import Notifications from "./Settings/Settings.vue";

export default {
  components: { successModal, Account, Bank, Notifications },
  data() {
    return {
      tab: null,
      items: [
        {
            text: "Account & Bank Information",
            value: 0,
          },
          {
            text: "Notifications",
            value: 1,
          },
      ],
    };
  },
  computed: {
    itemtab() {
      return this.tab;
    },
  },
  created() {},
};
</script>