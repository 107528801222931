
<template>
  <div class="no-padding row row-height">
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <errorDialog ref="error_modal" v-on:confirm="closeErrorModal"></errorDialog>

    <div class="col-md-12">
      <div>
        <v-row>
          <v-col class="col-xl-1 col-lg-4 col-md-4" cols="12">
            <h3>
              <span class="font">Providers</span>
            </h3>
          </v-col>
          <!-- <v-col class="col-lg-3 col-md-4"  cols="12">
          <v-btn class="blue-buttons" :disabled="overlay" @click="overlay = !overlay">Group NPI Filters</v-btn>
        </v-col> -->
          <v-col class="col-xl-2 col-lg-3 col-md-6" cols="12">
            <v-text-field
              class="input-control"
              v-if="filterArray.length > 0"
              prepend-inner-icon="mdi-magnify"
              @input="searchProviders"
              light
              dense
              outlined
              placeholder="Search Providers "
            >
            </v-text-field>
          </v-col>
          <v-col
            class="col-xl-9 col-lg-5 col-md-12 text-end"
            cols="12"
            align="end"
          >
            <v-btn
              class="my-1 blue-buttons"
              v-if="$role == 'user'"
              @click="addProviderView"
              :disabled="overlay"
            >
              Search Providers
            </v-btn>
            <v-menu offset-y v-else>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" class="my-1 blue-buttons" :disabled="overlay">
                  Add / Invite Providers
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <a class="a-tag underline-text" @click="addProviderView"
                    >Add Providers</a
                  >
                </v-list-item>
                <v-list-item>
                  <a class="a-tag underline-text" @click="inviteProvidersView"
                    >Invite Providers</a
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </div>
      <v-row v-if="loader" class="justify-center col-md-12">
        <v-progress-circular
          :size="100"
          class="text-center"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-row>
      <v-row
        class="col-md-12"
        v-else-if="allProviders.length < 1 && !filterItems"
      >
        <v-col v-if="noDataMessage != ''">
          <v-col>
            <a @click="getAllProviders"> {{ noDataMessage }} </a>
          </v-col>
        </v-col>
        <v-col md="12" v-else>
          You Have Not Yet Added any Providers to your Account 
          <a @click="addProviderView" v-if="$role != 'user'"
            >- Click Here to Add Providers</a
          >
        </v-col>
      </v-row>
      <v-simple-table v-else fixed-header height="630px">
        <template v-slot:default>
          <thead :class="overlay ? 'disabled-table' : ''">
            <tr>
              <th class="text-left">Provider Name</th>
              <th class="text-left">NPI Type</th>
              <th class="text-left">Specialty / Taxonomy</th>
              <th class="text-left">City</th>
              <th class="text-left">Relationship</th>
              <th
                class="no-padding text-end justify-end"
                v-if="$role != 'user'"
              >
                <div class="col-md-12 justify-end d-flex text-end">
                  <v-checkbox
                    v-model="allRows"
                    class="no-padding mt-3 stay-blue"
                    @click="selectAllRows1()"
                  ></v-checkbox>
                  <label for="" style="padding-top: 11px">Select all </label>
                </div>
              </th>
            </tr>
          </thead>
          <tbody v-if="allProviders.length > 0">
            <tr
              v-for="(item, index) in allProviders"
              :key="index"
              :id="'id' + index"
            >
              <td v-if="item.displayName">
                <a @click="singleProvider(item)">{{ item.displayName }}</a>
              </td>
              <td v-else-if="item.companyName">
                <a @click="singleProvider(item)">{{ item.companyName }}</a>
              </td>
              <td v-else-if="item.providerName">
                <a @click="singleProvider(item)">{{ item.providerName }}</a>
              </td>
              <td v-else><a @click="singleProvider(item)">/</a></td>

              <td class="text-gray">Type {{ item.type }}</td>

              <td class="text-gray" v-html="item.city ? item.city : '/'"></td>
              <td
                :class="
                  item.status == 'contracted' ? 'green--text' : 'gray--text'
                "
                v-html="
                  item.status == 'contracted'
                    ? 'Contracted '
                    : item.status == 'pending'
                    ? 'Pending'
                    : '/'
                "
              ></td>
              <td
                :class="
                  item.rate == 'notMatched'
                    ? 'red--text'
                    : item.rate == 'matched'
                    ? 'green--text'
                    : 'gray--text'
                "
                v-html="
                  item.rate == 'matched'
                    ? 'Matched'
                    : item.rate == 'notMatched'
                    ? 'Not Matched'
                    : item.rate == 'NA'
                    ? 'NA'
                    : '/'
                "
              ></td>

              <td
                v-if="$role != 'user'"
                class="text-center mt-3 row no-padding justify-end"
                style="padding-right: 60px"
              >
                <v-checkbox
                  class="stay-blue no-padding stay-blue"
                  v-model="item.checkbox"
                  @click="activeRow1(index, item)"
                ></v-checkbox>
                <svg
                  class="pointer"
                  @click="singleProvider(item)"
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.9955 19.5396L6.87109 20.1296L7.45998 16.004L15.8611 7.6029C16.3299 7.13406 16.9658 6.87067 17.6289 6.87067C18.2919 6.87067 18.9278 7.13406 19.3966 7.6029C19.8655 8.07175 20.1289 8.70764 20.1289 9.37068C20.1289 10.0337 19.8655 10.6696 19.3966 11.1385L10.9955 19.5396Z"
                    stroke="#4072B7"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M1 13.5C1 16.8152 2.31696 19.9946 4.66117 22.3388C7.00537 24.683 10.1848 26 13.5 26C16.8152 26 19.9946 24.683 22.3388 22.3388C24.683 19.9946 26 16.8152 26 13.5C26 10.1848 24.683 7.00537 22.3388 4.66117C19.9946 2.31696 16.8152 1 13.5 1C10.1848 1 7.00537 2.31696 4.66117 4.66117C2.31696 7.00537 1 10.1848 1 13.5V13.5Z"
                    stroke="#4072B7"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </td>
            </tr>
          </tbody>
          <div v-if="allProviders.length < 1 && filterItems">
            <v-col md="12" cols="12">
              <v-alert
                border="bottom"
                colored-border
                type="warning"
                elevation="2"
                class="text-gray"
              >
                Sorry! No results found
              </v-alert>
            </v-col>
          </div>
        </template>
      </v-simple-table>
      <v-row
        v-if="allProviders.length > 0 && !loader && $role != 'user'"
        class="col-md-12 pr-0 justify-end mt-6 mb-2"
      >
        <v-btn class="blue-buttons" @click="removeSelected()"
          >Remove Selected</v-btn
        >
      </v-row>
      <v-row class="justify-end mt-3" v-if="!noDataMessage && showSaveButton">
        <v-btn class="blue-buttons" @click="addProviders()">Save & Apply</v-btn>
      </v-row>
    </div>

    <v-dialog v-model.trim="dialog" max-width="600px" persistent>
      <v-card>
        <v-col>
          <h3>Remove Provider</h3>
        </v-col>

        <v-col>
          <span v-if="selectedRows.length == 1"
            >Are you sure you want to delete this Provider?
          </span>
          <span v-else
            >Are you sure you want to delete selected Providers?</span
          >
        </v-col>

        <v-col>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              class="blue-buttons float-class"
              @click="removeListOfProvider"
              >Confirm</v-btn
            >
            <v-btn class="button-style float-class" @click="closePopUp"
              >Close</v-btn
            >
          </v-card-actions>
        </v-col>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import api from "../../../../../services/api";
import successModal from "../../../../TablesTemplate/SuccessDialog.vue";
import errorDialog from "../../../../TablesTemplate/ErrorDialog.vue";

export default {
  components: { successModal, errorDialog },
  data() {
    return {
      ratingValue: 2,
      items: [],
      manualOverrideProviders: [
        {
          text: "Manual Override",
          value: true,
        },
      ],
      prefferedProviderItems: [
        {
          text: "Preferred Provider",
          value: true,
        },
      ],
      filter: {
        type: "",
        speciality: "",
        rating: 0,
        distance: "",
        status: "",
        preferredProvider: false,
        manualOverride: false,
        npiType: "",
        hospitals: "",
      },
      showSaveButton: false,
      filterMatchingStatus: [
        {
          text: "Matched",
          value: "matched",
        },
        {
          text: "Not Matched",
          value: "notMatched",
        },
      ],
      noDataMessage: "",
      selectedArray: [],
      panel: [0, 1, 2, 3, 4, 5],
      specialities: [
        {
          text: "Anesthesia",
          value: "anesthesia",
        },
        {
          text: "Cardiology",
          value: "cardiology",
        },
        {
          text: "Colorectal Surgery",
          value: "colorectalSurgery",
        },
        {
          text: "Dermatology",
          value: "dermatology",
        },
        {
          text: "Emergency Medicine",
          value: "emergencyMedicine",
        },
      ],
      hospitals: [
        {
          text: "Endocrinology",
          value: "endocrinology",
        },
        {
          text: "Family Medicine",
          value: "family medicine",
        },
        {
          text: "Gastroenterology",
          value: "gastroenterology",
        },
        {
          text: "General Surgery",
          value: "genrelaSurgery",
        },
        {
          text: "Geriatrics",
          value: "geriatrics",
        },
        {
          text: "Gynecology",
          value: "gynecology",
        },
      ],
      npiType: [
        {
          text: "Type 2",
          value: "2",
        },
        {
          text: "Type 1",
          value: "1",
        },
      ],
      providerType: [
        {
          text: "Hospital",
          value: "hospital",
        },
        {
          text: "Doctor Office",
          value: "doctorOffice",
        },
        {
          text: "Imagining",
          value: "imaging",
        },
        {
          text: "Laboratory",
          value: "laboratory",
        },
        {
          text: "Other",
          value: "other",
        },
      ],
      selectedRows: [],
      allProviders: [],
      display: true,
      overlay: false,
      windowWidth: window.innerWidth,
      dialog: false,
      filterArray: [],
      itemToRemove: null,
      showCheckbox: false,
      loader: false,
      filterItems: false,
      allRows: false,
      selectAll: false,
      selectedRows: [],
      loader: true,
      checkIfSelect: false,
      checkIfClicked: false,
      items: [],
    };
  },

  computed: {
    path() {
      return this.$route.path;
    },
    getClient() {
      return this.$store.getters.getClientGetter;
    },
    routeParams() {
      return this.$route.params.name;
    },

    overlayClass() {
      let str = "";
      if (this.display) {
        str = "col-md-12";
        if (document.getElementById("hideOnMobile")) {
          document.getElementById("hideOnMobile").style.display =
            "none !important";
        }
      } else if (this.overlay) {
        str = "col-md-9 disabled-table";
      } else if (!this.display) {
        str = "col-md-12";
      } else {
        str = "col-md-12";
      }
      return str;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  created() {
    this.getAllProviders();
    if (this.windowWidth < 600) {
      this.display = false;
    } else {
      this.display = true;
    }
  },
  watch: {
    $route: function (to, from) {
      this.getAllProviders();
    },
  },

  methods: {
    selectAllRows1() {
      this.selectedRows = [];

      if (!this.checkIfSelect) {
        this.selectedRows = [];
        this.checkIfClicked = false;
        this.allProviders.forEach((el) => {
          el.checkbox = false;
        });
      }
      if (this.checkIfSelect) {
        this.allProviders = [];
        this.loader = true;
        this.allProviders = this.items;
        let tmp = JSON.stringify(this.allProviders);
        this.selectedRows = JSON.parse(tmp);
        this.checkIfSelect = false;

        setTimeout(() => {
          this.allProviders.forEach((el) => {
            el.checkbox = true;
          });
          this.loader = false;
        }, 0);
        //  this.$forceUpdate();
      } else if (this.selectAll && !this.checkIfSelect) {
        this.selectAll = false;
      } else {
        this.selectAll = !this.selectAll;
        if (!this.selectAll) {
          this.selectedRows = [];
          this.checkIfClicked = false;
          this.allProviders.forEach((el) => {
            el.checkbox = false;
          });
        } else {
          this.allProviders.forEach((el) => {
            el.checkbox = true;
          });
          this.selectedRows = [];
          let tmp = JSON.stringify(this.allProviders);
          this.selectedRows = JSON.parse(tmp);
        }
      }
    },
    activeRow1(index, item) {
      let itemById = document.getElementById("id" + index);
      if (this.allRows) {
        this.checkIfSelect = true;
        let removeItem = this.selectedRows.find((x) => x._id === item._id);
        this.selectedRows.splice(this.selectedRows.indexOf(removeItem), 1);
        itemById.classList.remove("activeItem");
        this.allRows = false;
        this.checkIfClicked = true;
      } else {
        itemById.classList.toggle("activeItem");
        if (this.checkIfClicked) {
          itemById.classList.remove("activeItem");
        }

        if (item.checkbox) {
          this.selectedRows.push(item);
        } else {
          let removeItem = this.selectedRows.find((x) => x._id === item._id);
          this.selectedRows.splice(this.selectedRows.indexOf(removeItem), 1);
        }
      }
      // this.allRows = true;
      // this.allProviders.forEach((el) => {
      //   if (!el.checkbox) {
      //     this.allRows = false;
      //   }
      // });
      // if (this.allRows) {
      //   this.checkIfSelect = false;
      // }
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    removeSelected() {
      if (this.selectedRows.length > 0) {
        this.dialog = true;
      } else if (this.selectedRows.length < 1) {
        let text = "You must select at least one Provider";
        this.$refs.error_modal.openPopUp(text);
        return;
      }
    },
    selectAllRows() {
      this.selectedRows = [];
      this.selectAll = !this.selectAll;

      if (!this.selectAll) {
        this.selectedRows = [];
      } else {
        this.selectedRows = [];
        let tmp = JSON.stringify(this.allProviders);
        this.selectedRows = JSON.parse(tmp);
      }
    },

    addProviderView() {
      if (this.path.includes("affiliates")) {
        this.$router.push(
          `/provider-advisor/providers/${this.routeParams}/affiliates/add-providers`
        );
      } else if (this.path.includes("hospital-based")) {
        this.$router.push(
          `/provider-advisor/providers/${this.routeParams}/hospital-based/add-providers`
        );
      } else {
        this.$router.push(
          `/provider-advisor/providers/${this.routeParams}/providers/add-providers`
        );
      }
    },
    removeListOfProvider() {
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.providerAdvisor;
      let provider = JSON.parse(localStorage.getItem("provider"));
      if (this.selectedRows.length < 1) {
        let text = "You must select at least one Provider";
        this.$refs.error_modal.openPopUp(text);
        return;
      }
      let arr = [];

      this.selectedRows.forEach((el) => {
        if (el._id) {
          arr.push(el._id);
        } else {
          arr.push(el);
        }
      });

      let data = {
        ids: arr,
      };

      api()
        .put(
          `/advisors/${advisorId}/providers/${provider._id}/groupProviders/providersDelete`,
          data
        )
        .then((res) => {
          this.loader = true;
          if (res) {
            api()
              .get(
                `/advisors/${advisorId}/providers/${provider._id}/groupProviders`
              )
              .then((res) => {
                if (res) {
                  this.allProviders = res.data.groupProviders;
                  this.filterArray = res.data.groupProviders;
                  this.items = res.data.groupProviders;
                  this.selectAll = false;
                  this.selectedRows = [];
                  this.dialog = false;
                  this.loader = false;
                  this.allRows = false;
                  this.checkIfClicked = false;
                  this.selectAll = false;
                }
              });
          }
        })
        .catch((err) => {
          this.dialog = false;
          if (err.response.data.message) {
            this.$refs.error_modal.openPopUp(err.response.data.message);
          }
          if (err.response.data.error) {
            this.$refs.error_modal.openPopUp(err.response.data.error);
          }
        });
    },
    inviteProvidersView() {
      if (this.path.includes("affiliates")) {
        this.$router.push(
          `/provider-advisor/providers/${this.routeParams}/affiliates/invite-providers`
        );
      } else if (this.path.includes("hospital-based")) {
        this.$router.push(
          `/provider-advisor/providers/${this.routeParams}/hospital-based/invite-providers`
        );
      } else {
        this.$router.push(
          `/provider-advisor/providers/${this.routeParams}/providers/invite-providers`
        );
      }
    },
    removeItem(item) {
      this.dialog = true;
      this.itemToRemove = item;
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
      this.getAllProviders();
      this.overlay = false;
      this.showSaveButton = false;
    },
    clearAll() {
      this.filter.type = "";
      this.filter.distance = "";
      this.filter.rating = 0;
      this.filter.status = "";
      this.filter.preferredProvider = false;
      this.filter.manualOverride = false;
      (this.filter.speciality = ""),
        (this.filter.npiType = ""),
        (this.filter.hospitals = "");
    },
    removeFilter(type) {
      if (type == "type") {
        this.filter.type = "";
      } else if (type == "distance") {
        this.filter.distance = "";
      } else if (type == "rating") {
        this.filter.rating = 0;
      } else if (type == "status") {
        this.filter.status = "";
      } else if (type == "preferred") {
        this.filter.preferredProvider = false;
      } else if (type == "manual") {
        this.filter.manualOverride = false;
      } else if (type == "npiType") {
        this.filter.npiType = "";
      } else if (type == "hospitals") {
        this.filter.hospitals = "";
      } else if (type == "speciality") {
        this.filter.speciality = "";
      }
    },
    activeRow(index, item) {
      this.index = index;
      this.itemById = document.getElementById("id" + index);
      this.itemById.classList.toggle("activeItem");
      if (this.allRows) {
        let removeItem = this.selectedRows.find((x) => x._id === item._id);
        this.selectedRows.splice(this.selectedRows.indexOf(removeItem), 1);
      } else {
        if (this.itemById.classList.contains("activeItem")) {
          this.selectedRows.push(item._id);
        } else {
          let removeItem = this.selectedRows.find((x) => x._id === item._id);
          this.selectedRows.splice(this.selectedRows.indexOf(removeItem), 1);
        }
      }
    },
    addProviders() {
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.providerAdvisor;
      let provider = JSON.parse(localStorage.getItem("provider"));
      if (this.path.includes("affiliates")) {
        let data = {
          search: "affiliateProvider",
          ids: this.selectedRows,
        };
        api()
          .post(
            `/advisors/${advisorId}/providers/${provider._id}/affiliateProviders`,
            data
          )
          .then((res) => {
            let text = "Affiliates added successfully!";
            this.$refs.success_modal.openPopUp(text);
          });
      } else if (this.path.includes("hospital-based")) {
        let data = {
          search: "hospitalProvider",
          ids: this.selectedRows,
        };
        api()
          .post(
            `/advisors/${advisorId}/providers/${provider._id}/hospitalProviders`,
            data
          )
          .then((res) => {
            let text = "Hospital Based Providers added successfully!";
            this.$refs.success_modal.openPopUp(text);
          });
      } else {
        let data = {
          search: "groupProvider",
          ids: this.selectedRows,
        };
        api()
          .post(
            `/advisors/${advisorId}/providers/${provider._id}/groupProviders`,
            data
          )
          .then((res) => {
            let text = "Group Providers added successfully!";
            this.$refs.success_modal.openPopUp(text);
          });
      }
    },
    filterProviders() {
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.providerAdvisor;
      let provider = JSON.parse(localStorage.getItem("provider"));
      let data = {
        type: this.filter.npiType.value,
        specialties: this.filter.speciality
          ? [this.filter.speciality.value]
          : [],
      };
      this.showCheckbox = true;
      if (this.path.includes("affiliates")) {
        api()
          .post(
            `/advisors/${advisorId}/providers/${provider._id}/searchAffiliateProviders`,
            data
          )
          .then((res) => {
            if (res) {
              if (res.data.providers.length < 1) {
                this.noDataMessage = "No Result Found. Try Again";
                this.allProviders = [];
                this.showSaveButton = false;
              } else {
                this.allProviders = res.data.providers;
                this.showSaveButton = true;
              }
              this.overlay = false;
            }
          });
      } else if (this.path.includes("hospital-based")) {
        let data = {
          type: this.filter.npiType.value,
          specialties: this.filter.speciality
            ? [this.filter.speciality.value]
            : [],
        };
        api()
          .post(
            `/advisors/${advisorId}/providers/${provider._id}/searchHospitalProviders`,
            data
          )
          .then((res) => {
            if (res) {
              if (res.data.providers.length < 1) {
                this.noDataMessage = "No Result Found. Try Again";
                this.allProviders = [];
                this.showSaveButton = false;
              } else {
                this.allProviders = res.data.providers;
                this.showSaveButton = true;
              }
              this.overlay = false;
            }
          });
      } else {
        let data = {
          type: this.filter.npiType.value,
          specialties: this.filter.speciality
            ? [this.filter.speciality.value]
            : [],
        };
        api()
          .post(
            `/advisors/${advisorId}/providers/${provider._id}/searchGroupProviders`,
            data
          )
          .then((res) => {
            if (res) {
              if (res.data.providers.length < 1) {
                this.noDataMessage = "No Result Found. Try Again";
                this.allProviders = [];
                this.showSaveButton = false;
              } else {
                this.allProviders = res.data.groupProviders;
                this.showSaveButton = true;
              }

              this.overlay = false;
            }
          });
      }
    },
    getAllProviders() {
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.providerAdvisor;
      let provider = JSON.parse(localStorage.getItem("provider"));
      this.showCheckbox = false;
      if (this.path.includes("affiliates")) {
        api()
          .get(
            `/advisors/${advisorId}/providers/${provider._id}/affiliateProviders`
          )
          .then((res) => {
            if (res) {
              this.allProviders = res.data.affiliateProviders;
              this.filterArray = res.data.affiliateProviders;
            }
          });
      } else if (this.path.includes("hospital-based")) {
        api()
          .get(
            `/advisors/${advisorId}/providers/${provider._id}/hospitalProviders`
          )
          .then((res) => {
            if (res) {
              this.allProviders = res.data.hospitalBasedProviders;
              this.filterArray = res.data.hospitalBasedProviders;
            }
          });
      } else {
        api()
          .get(
            `/advisors/${advisorId}/providers/${provider._id}/groupProviders`
          )
          .then((res) => {
            if (res) {
              this.allProviders = res.data.groupProviders;
              this.filterArray = res.data.groupProviders;
              this.loader = false;
              this.items = res.data.groupProviders;
              this.allRows = false;
            }
          });
      }
    },
    searchProviders(val) {
      this.allProviders = this.filterArray.filter((el) => {
        if (
          el.displayName &&
          el.displayName.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.displayName.toLowerCase().includes(val.toLowerCase());
        } else if (
          el.companyName &&
          el.companyName.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.companyName.toLowerCase().includes(val.toLowerCase());
        } else if (
          el.providerName &&
          el.providerName.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.providerName.toLowerCase().includes(val.toLowerCase());
        } else if (
          el["Provider First Name"] &&
          el["Provider First Name"].toLowerCase().includes(val.toLowerCase())
        ) {
          return el["Provider First Name"]
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el["Provider Last Name (Legal Name)"] &&
          el["Provider Last Name (Legal Name)"]
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el["Provider Last Name (Legal Name)"]
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el.city &&
          el.city.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.city.toLowerCase().includes(val.toLowerCase());
        } else if (
          el.state &&
          el.state.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.state.toLowerCase().includes(val.toLowerCase());
        } else if (
          el.taxonomy &&
          el.taxonomy.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.taxonomy.toLowerCase().includes(val.toLowerCase());
        }
      });
      if (this.allProviders.length < 1) {
        this.filterItems = true;
      }
    },

    singleProvider(item) {
      if (!this.overlay && !this.showSaveButton) {
        let user = JSON.parse(localStorage.getItem("user"));
        let advisorId = user.groupPortal[0].organization.providerAdvisor;
        let provider = JSON.parse(localStorage.getItem("provider"));
        if (this.$route.path.includes("hospital-based")) {
          api()
            .get(
              `/advisors/${advisorId}/providers/${provider._id}/hospitalProviders/${item._id}`
            )
            .then((res) => {
              if (res) {
                this.$store.commit(
                  "setSingleProviderInEmployerAdvisor",
                  res.data.provider
                );
                this.$router.push(
                  `/provider-advisor/providers/${this.routeParams}/hospital-based/provider`
                );
              }
            });
        } else if (this.$route.path.includes("affiliates")) {
          api()
            .get(
              `/advisors/${advisorId}/providers/${provider._id}/affiliateProviders/${item._id}`
            )
            .then((res) => {
              if (res) {
                this.$store.commit(
                  "setSingleProviderInEmployerAdvisor",
                  res.data.provider
                );
                this.$router.push(
                  `/provider-advisor/providers/${this.routeParams}/affiliates/provider`
                );
              }
            });
        } else {
          api()
            .get(
              `/advisors/${advisorId}/providers/${provider._id}/groupProviders/${item._id}`
            )
            .then((res) => {
              if (res) {
                this.$store.commit(
                  "setSingleProviderInEmployerAdvisor",
                  res.data.provider
                );
                this.$router.push(
                  `/provider-advisor/providers/${this.routeParams}/providers/provider`
                );
              }
            });
        }
      }
    },
    confirmRemove() {
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.providerAdvisor;
      let provider = JSON.parse(localStorage.getItem("provider"));
      if (this.path.includes("affiliates")) {
        api()
          .delete(
            `/advisors/${advisorId}/providers/${provider._id}/affiliateProviders/${this.itemToRemove._id}`
          )
          .then((res) => {
            if (res) {
              api()
                .get(
                  `/advisors/${advisorId}/providers/${provider._id}/affiliateProviders`
                )
                .then((res) => {
                  if (res) {
                    this.allProviders = res.data.affiliateProviders;
                    this.filterArray = res.data.affiliateProviders;
                    this.dialog = false;
                  }
                });
            }
          });
      } else if (this.path.includes("hospital-based")) {
        api()
          .delete(
            `/advisors/${advisorId}/providers/${provider._id}/hospitalProviders/${this.itemToRemove._id}`
          )
          .then((res) => {
            if (res) {
              api()
                .get(
                  `/advisors/${advisorId}/providers/${provider._id}/hospitalProviders`
                )
                .then((res) => {
                  if (res) {
                    this.allProviders = res.data.hospitalBasedProviders;
                    this.filterArray = res.data.hospitalBasedProviders;
                    this.dialog = false;
                  }
                });
            }
          });
      } else {
        api()
          .delete(
            `/advisors/${advisorId}/providers/${provider._id}/groupProviders/${this.itemToRemove._id}`
          )
          .then((res) => {
            if (res) {
              api()
                .get(
                  `/advisors/${advisorId}/providers/${provider._id}/groupProviders`
                )
                .then((res) => {
                  if (res) {
                    this.allProviders = res.data.groupProviders;
                    this.filterArray = res.data.groupProviders;
                    this.dialog = false;
                  }
                });
            }
          });
      }
    },
    closePopUp() {
      this.dialog = false;
    },
    openFilterMenu() {
      this.overlay = !this.overlay;
      this.$store.commit("showFilterMenu", this.overlay);
    },
    changeColor(status) {
      let myClass = "";
      if (status == "Matched") {
        myClass = "red--text";
      } else if (status == "Not matched") {
        myClass = "green--text";
      } else {
        myClass = "gray--text";
      }
      return myClass;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 600) {
        this.display = false;
      } else {
        this.display = true;
      }
    },
  },
};
</script>
<style>
</style>