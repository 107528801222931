const state ={
    singleUserEmpInEmployer:null
}
const getters = {
    getSingleUserEmpInEmployer(){
        return state.singleUserEmpInEmployer
    }
}
const mutations = {
    setSingleUserInEmployer(state,user){
        state.singleUserEmpInEmployer = user
    },
    
}

export default{
    state,
    getters,
    mutations,
}