<template>
  <div class="row justify-center mt-4">
    <div class="col-md-6 mt-4">
      <v-card class="mx-auto" max-width="1200" tile>
        <BlueHeader></BlueHeader>
        <div class="mt-3">
          <div class="row border-blue-bottom">
            <div class="row" :class="tableClients ? 'col-md-4' : 'col-md-6'">
              <v-icon
                large
                v-text="'mdi-menu-left'"
                class="pointer"
                @click="
                  tableClients
                    ? $router.push('/clearing-house')
                    : ((addNew = false),
                      (clientDetails = false),
                      (tableClients = true),
                      getData())
                "
              ></v-icon>

              <h3 class="mb-4 mt-5 ml-3 font-weight-bold warning--text">
                <span v-if="tableClients">Members </span>
                <span v-else-if="addNew">Add New Member</span>
                <span v-else-if="clientDetails">Member Details</span>
              </h3>
            </div>
            <div class="col-md-4" v-if="tableClients">
              <v-text-field
                class="input-control mr-5"
                prepend-inner-icon="mdi-magnify"
                @input="searchInput"
                light
                dense
                outlined
                placeholder="Search Members"
              ></v-text-field>
            </div>

            <div :class="tableClients ? 'col-md-4' : 'col-md-6'" align="end">
              <div
                class="pointer"
                v-if="showDetails || !tableClients || !addNewTPA"
              >
                <svg
                  @click="dialog = true"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.25 7.51196H27.75"
                    stroke="#326AB3"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21.75 28.5H8.25C7.45435 28.5 6.69129 28.1839 6.12868 27.6213C5.56607 27.0587 5.25 26.2956 5.25 25.5V7.5H24.75V25.5C24.75 26.2956 24.4339 27.0587 23.8713 27.6213C23.3087 28.1839 22.5456 28.5 21.75 28.5Z"
                    stroke="#326AB3"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.62402 7.5V6.813C9.62402 5.4039 10.1838 4.05252 11.1802 3.05614C12.1765 2.05976 13.5279 1.5 14.937 1.5C16.3461 1.5 17.6975 2.05976 18.6939 3.05614C19.6903 4.05252 20.25 5.4039 20.25 6.813V7.5"
                    stroke="#326AB3"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.967 13.5V22.5"
                    stroke="#326AB3"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18.0449 13.5V22.5"
                    stroke="#326AB3"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <v-btn
                v-if="tableClients"
                class="my-1 gray-border-buttons ml-6"
                @click="
                  (addNew = true),
                    (showDetails = false),
                    (tableClients = false),
                    (clientDetails = false),
                    (firstname = ''),
                    (lastname = ''),
                    (dob = ''),
                    (gender = ''),
                    (deductible = ''),
                    (payerid = ''),
                    (planname = ''),
                    (status = ''),
                    (selectedEmployers = null),
                    (selectedPlans = null),
                    (selectedTpas = null),
                    (memberid = '')
                "
              >
                <svg
                  class="mr-2"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 7.5H15"
                    stroke="#707070"
                    stroke-width="2"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.5 0V15"
                    stroke="#707070"
                    stroke-width="2"
                    stroke-linejoin="round"
                  />
                </svg>

                New
              </v-btn>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <v-simple-table
            v-if="tableClients"
            fixed-header
            height="70vh"
            class="no-padding tr-custom-height"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">MEMBER NAME</th>
                  <th class="text-left">DOB</th>

                  <th class="text-left">STATUS</th>
                </tr>
              </thead>

              <tbody
                v-if="items.length > 0 && !filterItems"
                class="text-gray tr-custom-height"
              >
                <tr
                  class="text-gray"
                  id="remove-hover"
                  v-for="(item, index) in items"
                  :key="index"
                >
                  <td>
                    <a
                      v-if="item && item.firstname"
                      class="text-underline"
                      @click="showClient(item)"
                      >{{ item.firstname }}
                      <span v-if="item.lastname">{{ item.lastname }}</span>
                    </a>
                    <a v-else @click="showClient(item)">/</a>
                  </td>
                  <td v-if="item.dob">{{ item.dob | formatDate }}</td>
                  <td v-else>/</td>

                  <td>{{ item.status }}</td>
                </tr>
              </tbody>
              <tbody v-else-if="items.length < 1 && filterItems">
                <v-col md="12" cols="12">
                  <v-alert
                    border="bottom"
                    colored-border
                    type="warning"
                    elevation="2"
                    class="text-gray"
                  >
                    Sorry! No results found
                  </v-alert>
                </v-col>
              </tbody>

              <tbody v-else>
                <v-col md="12" v-if="!loader">
                  There are no members currently.
                  <a
                    @click="
                      (addNew = true),
                        (tableClients = false),
                        (clientDetails = false)
                    "
                  >
                    Click to add your members</a
                  >
                </v-col>
                <v-row v-else class="justify-center col-md-12 mt-5">
                  <v-progress-circular
                    :size="100"
                    class="text-center"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-row>
              </tbody>
            </template>
          </v-simple-table>
          <div v-else-if="addNew" class="row justify-center mt-5">
            <div class="col-md-8">
              <v-text-field
                outlined
                label="First Name"
                v-model="firstname"
                hide-details=""
              ></v-text-field>
            </div>
            <div class="col-md-8">
              <v-text-field
                outlined
                hide-details=""
                label="Last Name"
                v-model="lastname"
              ></v-text-field>
            </div>
            <div class="col-md-8">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dob"
                    outlined
                    label="DOB"
                    hide-details
                    append-icon="mdi-calendar"
                    readonly
                    persistent-hint
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dob" no-title @input="menu = false">
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-menu>
            </div>
            <div class="col-md-8">
              <div class="row col-md-12">
                Gender:

                <span class="ml-4 mr-1">Male</span>

                <div
                  class="text-end"
                  :class="
                    gender == 'm' ? 'fill-blue-checkbox' : 'blue-checkbox'
                  "
                  @click="gender = 'm'"
                ></div>
                <span class="ml-2 mr-1"> Female</span>
                <div
                  class="text-end"
                  :class="
                    gender == 'f' ? 'fill-blue-checkbox' : 'blue-checkbox'
                  "
                  @click="gender = 'f'"
                ></div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="row col-md-12">
                Status:

                <span class="ml-4 mr-1">Active</span>

                <div
                  class="text-end"
                  :class="
                    status == 'Active' ? 'fill-blue-checkbox' : 'blue-checkbox'
                  "
                  @click="status = 'Active'"
                ></div>
                <span class="ml-2 mr-1">Inactive</span>
                <div
                  class="text-end"
                  :class="
                    status == 'Inactive'
                      ? 'fill-blue-checkbox'
                      : 'blue-checkbox'
                  "
                  @click="status = 'Inactive'"
                ></div>
              </div>
            </div>
            <div class="col-md-8">
              <v-text-field
                outlined
                hide-details=""
                type="number"
                label="Deductible Balance"
                v-model="deductible"
              ></v-text-field>
            </div>
            <div class="col-md-8">
              <v-text-field
                outlined
                hide-details=""
                label="Member ID"
                v-model="memberid"
              ></v-text-field>
            </div>
            <div class="col-md-8">
              <v-select
                outlined
                :items="employers"
                item-text="employername"
                item-value="_id"
                hide-details=""
                label="Employer Client"
                v-model="selectedEmployers"
                @change="fillTpas"
              >
               <template slot="selection" slot-scope="data">
                  {{ data.item.employername }} - {{ data.item.groupid }}
                </template>
                <template slot="item" slot-scope="data">
                  {{ data.item.employername }}
                </template>
                <template v-slot:prepend-item>
                  <div class="fixed-list-item">
                    <v-list-item>
                      <v-list-item-content class="p-2">
                        <v-text-field
                          dense
                          placeholder="Search"
                          @input="searchEmployers"
                        ></v-text-field>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </div>
                </template>
              </v-select>
            </div>

            <div class="col-md-8">
              <v-select
                outlined
                :items="tpas"
                item-text="tpaname"
                item-value="_id"
                hide-details=""
                label="Payer Name - ID"
                v-model="selectedTpas"
                @change="fillPlans"
              >
               <template slot="selection" slot-scope="data">
                  {{ data.item.tpaname }} - {{ data.item.payerid }}
                </template>
              </v-select>
            </div>
            <div class="col-md-8">
              <v-select
                outlined
                :items="plans"
                item-text="planname"
                item-value="_id"
                hide-details=""
                label="Plan Name - Plan ID"
                v-model="selectedPlans"
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.planname }} - {{ data.item.planid }}
                </template>
              </v-select>
            </div>
            <div class="col-md-8 mb-10">
              <v-btn class="blue-buttons" block @click="addNewTPA"
                >Add New</v-btn
              >
            </div>
          </div>
          <div v-else-if="clientDetails" class="row justify-center mt-5">
            <div class="col-md-8">
              <v-text-field
                outlined
                label="First Name"
                v-model="firstname"
                hide-details=""
              ></v-text-field>
            </div>
            <div class="col-md-8">
              <v-text-field
                outlined
                hide-details=""
                label="Last Name"
                v-model="lastname"
              ></v-text-field>
            </div>
            <div class="col-md-8">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dob"
                    outlined
                    label="DOB"
                    hide-details
                    append-icon="mdi-calendar"
                    readonly
                    persistent-hint
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dob" no-title @input="menu = false">
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-menu>
            </div>
            <div class="col-md-8">
              <div class="row col-md-12">
                Gender:

                <span class="ml-4 mr-1">Male</span>

                <div
                  class="text-end"
                  :class="
                    gender == 'm' ? 'fill-blue-checkbox' : 'blue-checkbox'
                  "
                  @click="gender = 'm'"
                ></div>
                <span class="ml-2 mr-1"> Female</span>
                <div
                  class="text-end"
                  :class="
                    gender == 'f' ? 'fill-blue-checkbox' : 'blue-checkbox'
                  "
                  @click="gender = 'f'"
                ></div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="row col-md-12">
                Status:

                <span class="ml-4 mr-1">Active</span>

                <div
                  class="text-end"
                  :class="
                    status == 'Active' ? 'fill-blue-checkbox' : 'blue-checkbox'
                  "
                  @click="status = 'Active'"
                ></div>
                <span class="ml-2 mr-1">Inactive</span>
                <div
                  class="text-end"
                  :class="
                    status == 'Inactive'
                      ? 'fill-blue-checkbox'
                      : 'blue-checkbox'
                  "
                  @click="status = 'Inactive'"
                ></div>
              </div>
            </div>
            <div class="col-md-8">
              <v-text-field
                outlined
                hide-details=""
                type="number"
                label="Deductible Balance"
                v-model="deductible"
              ></v-text-field>
            </div>
            <div class="col-md-8">
              <v-text-field
                outlined
                hide-details=""
                label="Member ID"
                v-model="memberid"
              ></v-text-field>
            </div>
            <div class="col-md-8">
              <v-select
                outlined
                :items="employers"
                item-text="employername"
                item-value="_id"
                hide-details=""
                label="Employer Client"
                v-model="selectedEmployers"
                @change="fillTpas"
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.employername }} - {{ data.item.groupid }}
                </template>
                <template slot="item" slot-scope="data">
                  {{ data.item.employername }}
                </template>
                <template v-slot:prepend-item>
                  <div class="fixed-list-item">
                    <v-list-item>
                      <v-list-item-content class="p-2">
                        <v-text-field
                          dense
                          placeholder="Search"
                          @input="searchEmployers"
                        ></v-text-field>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </div>
                </template>
              </v-select>
              <label for="">
                <small
                  class="light-blue-text pointer"
                  @click="showClientDetails('client')"
                  >Employer Client Details</small
                ></label
              >
            </div>

            <div class="col-md-8">
              <v-select
                :disabled="changeEmployer"
                outlined
                :items="tpas"
                item-text="tpaname"
                item-value="_id"
                hide-details=""
                label="Payer Name - ID"
                v-model="selectedTpas"
                @change="fillPlans"
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.tpaname }} - {{ data.item.payerid }}
                </template>
              </v-select>
              <label for="">
                <small
                  class="light-blue-text pointer"
                  @click="showClientDetails('payer')"
                  >Payer Details</small
                ></label
              >
            </div>
            <div class="col-md-8">
              <v-select
                :disabled="changeTpas"
                outlined
                :items="plans"
                item-text="planname"
                item-value="_id"
                hide-details=""
                label="Plan Name - Plan ID"
                v-model="selectedPlans"
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.planname }} - {{ data.item.planid }}
                </template>
              </v-select>
              <label for="">
                <small
                  class="light-blue-text pointer"
                  @click="showClientDetails('plan')"
                  >Plan Details</small
                ></label
              >
            </div>
            <div class="col-md-8 mb-10">
              <v-btn class="blue-buttons" block @click="updateItem"
                >Update</v-btn
              >
            </div>
          </div>
        </div>
      </v-card>

      <v-dialog v-model="dialog" width="500">
        <v-card>
          <v-card-title class="">
            Are you sure you want to delete?
          </v-card-title>

          <v-card-text class="mt-5 mb-5">
            <v-btn class="blue-buttons" block @click="removeItem">
              YES - DELETE</v-btn
            >
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <a class="text-underline mt-3 mb-3" @click="dialog = false">
              Cancel
            </a>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <router-view></router-view>
    </div>
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
  </div>
</template>
<script>
import Header from "../../../DigitalVendorInvoices/Layout/HeaderPage.vue";
import api from "../../../../../services/api";
import tpas from "../../../../../store/JSONS/members.json";
import successModal from "../../../../TablesTemplate/SuccessDialog.vue";
import BlueHeader from "../BlueHeader.vue";
import firebase from "../../../../../firebase.js";
import moment from "moment";
const db = firebase.firestore();
export default {
  data() {
    return {
      showDetails: false,
      dialog: false,
      items: [],
      singleItem: null,
      sortBy: "",
      type: "",
      solutions: [],
      favorites: [],
      filterItems: false,
      filteredArray: [],
      selectedItem: 1,
      tableClients: true,
      addNew: false,
      clientDetails: false,
      dialog: false,
      firstName: "",
      lastName: "",
      dob: "",
      memberid: "",
      gender: "",
      status: "",
      balance: "",
      groupid: "",
      payername: "",
      planname: "",
      payerid: "",
      planname: "",
      deductible: "",
      tpaId: "",
      menu: "",
      employers: [],
      filterEmp: [],
      plans: [],
      selectedPlans: [],
      tpas: [],
      selectedTpas: [],
      selectedEmployers: null,
      allTpas: [],
      allPlans: [],
      filterTpas: [],
      filterPlans: [],
      filterArray: [],
      changeEmployer: true,
      changeTpas: true,
      loader: true,
    };
  },
  components: {
    Header,
    successModal,
    BlueHeader,
  },
  computed: {
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
  },
  created() {
    this.getData();
    this.getEmployers();
    this.getTpas();
    this.getPlans();
  },
  methods: {
    showClientDetails(type) {
      if (type == "client") {
        this.$store.commit("setClientDetails", this.selectedEmployers);
        this.$router.push("/clearing-house/clients");
      }

      if (type == "payer") {
        this.$store.commit("setClientDetails", this.selectedTpas);
        this.$router.push("/clearing-house/tpa");
      }
      if (type == "plan") {
        this.$store.commit("setClientDetails", this.selectedPlans);
        this.$router.push("/clearing-house/plans");
      }
    },
    searchEmployers(val) {
      console.log('hi',this.filterEmp)
      this.employers = this.filterEmp.filter((el) => {
        if (
          el["employername"] &&
          el["employername"]
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el["employername"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        }
      });
    },

    searchPlans(val) {
      this.plans = this.filterPlans.filter((el) => {
        if (
          el["planname"] &&
          el["planname"].toString().toLowerCase().includes(val.toLowerCase())
        ) {
          return el["planname"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        }
      });
    },
    searchTpas(val) {
      this.tpas = this.filterTpas.filter((el) => {
        if (
          el["tpaname"] &&
          el["tpaname"].toString().toLowerCase().includes(val.toLowerCase())
        ) {
          return el["tpaname"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        }
      });
    },
    getTpas(item) {
      api()
        .get("/clearinghouse/tpas")
        .then((res) => {
          this.allTpas = res.data;

          this.filterTpas = res.data;
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    },
    fillPlans(item) {
      this.changeTpas = true;
      let sel = null;
      this.plans = [];
      this.tpas.forEach((el) => {
        if (el._id == item) {
          sel = el;
        }
      });

      if (sel && sel.plans.length > 0) {
        this.allPlans.forEach((el) => {
          sel.plans.forEach((el1) => {
            if (el._id == el1) {
              this.plans.push(el);
            }
          });
        });
      } else {
        this.plans = [];
      }

      this.changeTpas = false;
    },
    fillTpas(item) {
      this.changeEmployer = true;

      let sel = null;
      this.tpas = [];
      this.selectedPlans = null;
      this.employers.forEach((el) => {
        if (el._id == item) {
          sel = el;
        }
      });

      if (sel && sel.tpas.length > 0) {
        this.allTpas.forEach((el) => {
          sel.tpas.forEach((el1) => {
            if (el._id == el1) {
              this.tpas.push(el);
            }
          });
        });
      } else {
        this.tpas = [];
      }
      this.changeEmployer = false;
    },
    getPlans() {
      api()
        .get("/clearinghouse/plans")
        .then((res) => {
          if (res) {
            this.allPlans = res.data;
            this.filterPlans = res.data;
          }
        });
    },
    getData() {
      this.loader = true;
      api()
        .get("/clearinghouse/members")
        .then((res) => {
          if (res) {
            this.items = res.data;
            this.filteredArray = res.data;
            this.filterArray = res.data;
            this.loader = false;
            console.log(this.filterArray);
          }
        });
    },
    getEmployers() {
      api()
        .get("/clearinghouse/employers")
        .then((res) => {
          if (res) {
            this.employers = res.data;
            this.filterEmp = res.data;

          }
        });
    },
    removeItem() {
      api()
        .delete("/clearinghouse/members/" + this.tpaId)
        .then((res) => {
          if (res) {
            this.dialog = false;
            this.$refs.success_modal.openPopUp("Member removed successfully");
          }
        });
    },
    updateItem() {
      api()
        .put("/clearinghouse/members/" + this.tpaId, {
          firstname: this.firstname ? this.firstname : "/",
          lastname: this.lastname ? this.lastname : "/",
          dob: this.dob ? this.dob : "/",
          gender: this.gender ? this.gender : "/",
          status: this.status ? this.status : "/",
          deductible: this.deductible ? this.deductible : "/",
          employer: this.selectedEmployers ? this.selectedEmployers : null,
          tpa: this.selectedTpas ? this.selectedTpas : null,
          plan: this.selectedPlans ? this.selectedPlans : null,
          memberid: this.memberid ? this.memberid : null,
        })
        .then((res) => {
          if (res) {
            this.$refs.success_modal.openPopUp("Member updated successfully");
          }
        });
    },
    closeSuccessModal() {
      this.getData();
      this.$refs.success_modal.closePopUp();

      this.addNew = false;
      this.showDetails = false;
      this.tableClients = true;
    },
    addNewTPA() {
      if (this.firstname && this.lastname) {
        let data = {
          firstname: this.firstname ? this.firstname : "/",
          lastname: this.lastname ? this.lastname : "/",
          dob: this.dob ? this.dob : "/",
          gender: this.gender ? this.gender : "/",
          status: this.status ? this.status : "/",
          deductible: this.deductible ? this.deductible : "/",
          employer: this.selectedEmployers ? this.selectedEmployers : null,
          tpa: this.selectedTpas ? this.selectedTpas : null,
          plan: this.selectedPlans ? this.selectedPlans : null,
          memberid: this.memberid ? this.memberid : null,
        };
        console.log(data)

        api()
          .post("/clearinghouse/members", data)
          .then((res) => {
            if (res) {
              this.$refs.success_modal.openPopUp("Member added successfully");
            }
          });
      }
    },
    formatDate(value) {
      // value.split("T")[0]
      if (value) {
        let date = new Date(value);
        return date.toISOString().substring(0, 10);
      }
    },
    showClient(item) {
      this.tpaId = "";
      this.firstname = "";
      this.lastname = "";
      this.dob = "";
      this.memberid = "";
      this.gender = "";
      this.status = "";
      this.deductible = "";
      this.groupid = "";
      this.payername = "";
      this.planname = "";
      this.payerid = "";

      this.addNew = false;
      this.tableClients = false;
      this.clientDetails = true;
      this.tpaId = item._id;
      this.firstname = item.firstname;
      this.lastname = item.lastname;
      this.dob = item.dob ? this.formatDate(item.dob) : "";
      this.memberid = item.memberid;
      this.gender = item.gender;
      this.status = item.status;
      this.deductible = item.deductible;
      this.groupid = item.groupid;
      this.payername = item.payername;
      this.planname = item.planname;
      this.payerid = item.payerid;
      this.changeEmployer = true;
      this.changeTpas = true;

      this.selectedEmployers = null;
      this.selectedTpas = null;
      this.selectedPlans = null;

      if (item.employer) {
        this.selectedEmployers = item.employer;
      }

      if (item.plan) {
        this.selectedPlans = item.plan;
      }

      if (item.tpa) {
        this.selectedTpas = item.tpa;
      }
      this.plans = this.allPlans;
      this.tpas = this.allTpas;

    },

    invite() {},
    add() {},
    exportFun() {
      this.exportData("tblData");
    },

    searchInput(val) {
      let value = val.replace(' ','').toLowerCase();
      this.items = this.filterArray.filter((el) => {
        return((el["firstname"]+el["lastname"]).toLowerCase().includes(value))
      });
      if (this.items.length < 1) {
        this.filterItems = true;
      } else {
        this.filterItems = false;
      }
    },
  },
};
</script>
<style>
.div-shadow {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
</style>
