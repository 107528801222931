<template>
 <v-row class="add-client-form no-padding">
   <div class="row mb-4">
        <div class="col-md-12">
            <h3 class="form-title font">Build a Network</h3>
        </div>
    </div>
    <v-tabs
      dark
      show-arrows
      style="background:#4072B7"
      v-model.trim="tab"
    >
    <v-tabs-slider style="background:orange"></v-tabs-slider>
     <v-tab
        style="background:#4072B7"
        v-for="(item, i) in items"
        :key="i"
        class="col-md-4"
        :href="'#tab'+ i"
        >
       
       {{item.text}}
    </v-tab>
 </v-tabs>
   <div class="col-md-12"  v-if="itemtab == 'tab0'">
    <div class="col-md-12 padding">
    <form class="row">
      <div class="col-md-6 ">
        <div class="col-md-12">
          <label class="text-gray font" for="">Network Name</label>
          <v-text-field solo></v-text-field>
        </div>
        <div class="col-md-12">
          <label  class="text-gray font" for="">Network Type</label>
          <v-select solo></v-select>
        </div>
           <div class="col-md-12">
          <label class="text-gray font" for="">Network Profile URL</label>
          <v-text-field solo></v-text-field>
        </div>
        <div class="row no-padding">
         <div class="col-md-10">
          <label class="text-gray font" for="">Network Profile Image (Max Image Size 100 x 100 px)</label>
          <v-text-field solo></v-text-field>
        </div>
        <div class="col-md-2">
          <v-btn class="blue-buttons mt-7">Browse</v-btn>
        </div>
        </div>
        <div class="col-md-12">
          <label class="text-gray font" for="">Network Description</label>
          <v-textarea solo></v-textarea>
        </div>
      </div>
       <div class="col-md-6 ">
        <div class="col-md-12">
          <label class="text-gray font" for="">Company Name <i class="fas fa-question-circle  yellow-icons"></i></label>
          <v-text-field solo></v-text-field>
        </div>
        <div class="col-md-12">
          <label class="text-gray font" for="">First Type</label>
          <v-select solo></v-select>
        </div>
        <div class="col-md-12">
          <label class="text-gray font" for="">Last Name</label>
          <v-text-field solo></v-text-field>
        </div>
         <div class="col-md-12">
          <label class="text-gray font" for="">Title</label>
          <v-text-field solo></v-text-field>
        </div>
      </div>
      </form>
    </div>
   </div>
    <div class="col-md-12" v-if="itemtab == 'tab1'">
    <v-row>
      <v-col class="col-md-8" md="4" cols="12">
          <h3>
            <span class="font"> </span> Provider List
          </h3>
      </v-col>
      <v-col class="col-md-4 text-end" cols="12"   align="end">
        <v-btn  class="my-1 blue-buttons" @click="dialog = true">Add Providers</v-btn>
    </v-col>
    </v-row>      
    <v-simple-table fixed-header>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left text-gray font">
            Provider List
            </th>
            <th class="text-left text-gray font">
            Contact
            </th>
            <th class="text-left text-gray font">
            # of Providers
            </th>
            <th class="text-left text-gray font">
            Network Type
            </th>
            <th class="text-left text-gray font">
              Quality Rating
            </th>
            <th class="text-center text-gray font">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <a  @click="model=true">Add Providers</a>
            </td>
          
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div class="text-center">
      <v-dialog
        v-model.trim="dialog"
        width="1500"
      >
    <v-card>
      <v-card-title class="text-h5 ">
        Add Providers
      </v-card-title>
      <v-card-text>
        <v-simple-table fixed-header >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                Provider Name
                </th>
                <th class="text-left">
                Type
                </th>
                <th class="text-left">
                Speciality / Taxonomy
                </th>
                <th class="text-left">
                City
                </th>
                <th class="text-center">
                <v-checkbox></v-checkbox>
                Select All
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in providers"
                :key="item.name"
              >
                <td><a    @click="singleProvider(item)">{{ item.name }}</a> </td>
                
                <td class="text-gray">{{ item.type }}</td>
                <td class="text-gray">
                  
                  <v-rating
                    v-model.trim="item.rating"
                    color="yellow darken-3"
                    background-color="grey darken-1"
                    empty-icon="$ratingFull"
                    half-increments
                  
                ></v-rating>
                </td>
                <td class="text-gray">{{ item.city }}</td>
    
                <td  class="text-center">
                  <v-checkbox></v-checkbox>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
          </v-card-text>

          <v-divider></v-divider>
        </v-card>
      </v-dialog>
    </div>
   </div>
    <div class="col-md-12" v-if="itemtab == 'tab2'">
    <v-row>
      <v-col class="col-md-8" md="4" cols="12">
          <h3>
            <span class="font"> </span> Bundles
          </h3>
      </v-col>
      <v-col class="col-md-4 text-end" cols="12"   align="end">
        <v-btn  class="my-1 blue-buttons" @click="dialog1 = true">Add Bundles</v-btn>
    </v-col>
    </v-row>      
    <v-simple-table fixed-header>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left text-gray font">
              Bundle Name 
            </th>
            <th class="text-left text-gray font">
              Contact
            </th>
            <th class="text-left text-gray font">
             # of Providers
            </th>
            <th class="text-left text-gray font">
              Rate
            </th>
            <th class="text-left text-gray font">
              # of Cases (YTD)
            </th>
            <th class="text-center text-gray font">
             <v-checkbox></v-checkbox>
             Select All
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <a  @click="dialog1=true">Add Bundles</a>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div class="text-center">
      <v-dialog
        v-model.trim="dialog1"
        width="1500"
      >
    <v-card>
      <v-card-title class="text-h5 ">
        Add Bundles
      </v-card-title>
      <v-card-text>
        <v-simple-table fixed-header >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Bundle Name
                </th>
                <th class="text-left">
                Type
                </th>
                <th class="text-left">
                Speciality / Taxonomy
                </th>
                <th class="text-left">
                City
                </th>
                <th class="text-center">
                <v-checkbox></v-checkbox>
                <span>
                      Select All
                </span>
              
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in providers"
                :key="item.name"
              >
                <td><a  @click="singleProvider(item)">{{ item.name }}</a> </td>
                
                <td class="text-gray">{{ item.type }}</td>
                <td class="text-gray">
                  
                  <v-rating
                    v-model.trim="item.rating"
                    color="yellow darken-3"
                    background-color="grey darken-1"
                    empty-icon="$ratingFull"
                    half-increments
                  
                ></v-rating>
                </td>
                <td class="text-gray">{{ item.city }}</td>
    
                <td  class="text-center">
                  <v-checkbox></v-checkbox>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
          </v-card-text>

          <v-divider></v-divider>
        </v-card>
      </v-dialog>
    </div>
   </div>
    <div class="col-md-12" v-if="itemtab == 'tab3'">
     <v-row>
      <v-col class="col-md-4 mt-3" md="4" cols="12">
          <h3>
            <span class="font"> </span> Link Providers & Bundles
          </h3>
      </v-col>
      <v-col class="row">
        <span class="mt-8 mr-4 font text-blue">Select Bundle:</span>
        <v-select></v-select>
      </v-col>
      <v-col class="col-md-4 text-end" cols="12"   align="end">
        <v-btn  class="my-1 blue-buttons" >Select Provider First</v-btn>
    </v-col>
    </v-row>      
    <v-divider></v-divider>
    <v-row class="mt-5">
      <h3 class="col-md-6">Select Participating Providers to Link to Bundle</h3>
      <div class="col-md-3"> 
       <v-text-field   solo placeholder="Search Providers"></v-text-field>
      </div>
    </v-row>
      <v-simple-table fixed-header >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                Provider Name
                </th>
                <th class="text-left">
                Type
                </th>
                <th class="text-left">
                Speciality / Taxonomy
                </th>
                <th class="text-left">
                City
                </th>
                <th class="text-center">
                <v-checkbox></v-checkbox>
                Select All
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in providers"
                :key="item.name"
              >
                <td><a    @click="singleProvider(item)">{{ item.name }}</a> </td>
                <td class="text-gray">{{ item.type }}</td>
                <td class="text-gray">
                  <v-rating
                    v-model.trim="item.rating"
                    color="yellow darken-3"
                    background-color="grey darken-1"
                    empty-icon="$ratingFull"
                    half-increments 
                ></v-rating>
                </td>
                <td class="text-gray">{{ item.city }}</td>
                <td  class="text-center">
                  <v-checkbox></v-checkbox>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
     </div>
     <div class="col-md-12 d-flex align-end flex-column">
              <div>
                <v-btn class="blue-buttons mr-6">
                  Cancel
                </v-btn>
                <v-btn class="blue-buttons" @click="nextTab()">
                 Next
                </v-btn>
            </div>
        </div>
 </v-row>
 
</template>
<script>
 export default{
   data(){
       return{
          items:[
            {
              text:'Networks Information',
              value:1,
            },
            {
              text:'Participating Providers',
              value:1,
            },  
            {
              text:'Associated Bundles',
              value:1,
            },  {
              text:'Link Providers & Bundles',
              value:1,
            }
          ],
          tableItems:[
           

          ],
          tab: null,
          href:'#tab-0',
          providers: [
          {
            name: 'Tampa General Hospital',
            npi: '123456789',
            type: 'Type 1',
            city:'Tampa',
            state:'Florida',
            averageCharges: '280%',
            averageSavings:'35%',
            action:'',
            rating:5,
            status:'Matched',
            speciality:'Cardiology'
          },
          {
            name: 'USF Health',
            npi: '123456789',
            type: 'Type 1',
            city:'Tampa',
            state:'Florida',
            averageCharges: '280%',
            averageSavings:'35%',
            action:'',
            rating:4,
            status:'Not matched',
            speciality:'Family Medicine'
          },
          {
            name: 'Florida Medical Clinic',
            npi: '123456789',
            type: 'Type 2',
            city:'Tampa',
            state:'Florida',
            averageCharges: '280%',
            averageSavings:'35%',
            action:'',
            rating:4,
            status:'Pending',
            speciality:'Medicine'
          },
          {
            name: 'Boyfront Health St Pete',
            npi: '123456789',
            type: 'Type 1',
            city:'St.Petersburgh',
            state:'Florida',
            averageCharges: '280%',
            averageSavings:'35%',
            action:'',
            rating:4,
            status:'Matched',
            speciality:'Cardiology'

          },
          {
            name: 'Morton Plant Mease',
            npi: '123456789',
            type: 'Type 2',
            city:'Tampa',
            state:'Florida',
            averageCharges: '280%',
            averageSavings:'35%',
            action:'',
            rating:4,
            status:'Pending',
            speciality:'General Surgery'
          },
          {
            name: 'St.Anthony\'s Hospital',
            npi: '123456789',
            type: 'Type 2',
            city:'Tampa',
            state:'Florida',
            averageCharges: '280%',
            averageSavings:'35%',
            action:'',
            rating:4,
            status:'Pending',
             speciality:'Urgent Care'
          },
          {
            name: 'Midway Clinic',
            npi: '123456789',
            type: 'Type 2',
            city:'Tampa',
            state:'Florida',
            averageCharges: '280%',
            averageSavings:'35%',
            action:'',
            rating:2,
            status:'Matched',
            speciality:'Urgent Care'

          },
        ],
        dialog:false,
        dialog1:false,
        dialog2:false,
       }
   },
   computed:{
       itemtab(){
         return this.tab
       }
   },
    methods:{
      nextTab(){
         this.tab = '#tab-2'
      },
       changeTab(){
       }
    }
 }
</script>
<style >
.theme--dark.v-tabs > .v-tabs-bar {
     background-color: transparent !important;
}
</style>