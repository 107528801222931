import AdvisorsSection from '../views/ProviderAdvisor.vue'
import Home from '../components/Sections/ProviderAdvisor/Home/Home.vue'


// PROVIDERS

import Providers from '../components/Sections/ProviderAdvisor/Providers/ProvidersTable.vue'
import InviteProviders from '../components/Sections/ProviderAdvisor/Providers/InviteProvider.vue'
import AddProvider from '../components/Sections/ProviderAdvisor/Providers/AddProviders.vue'
import Provider from '../components/Sections/ProviderAdvisor/Providers/SingleNpi.vue'
import ProvidersResult from '../components/Sections/ProviderAdvisor/Providers/ResultTable.vue'
import AddProviderManually from '../components/Sections/ProviderAdvisor/Providers/AddManually.vue'



import ProvidersEmployers from '../components/Sections/ProviderAdvisor/Providers/Employers/EmployersTable.vue'
import ProvidersAddEmployer from '../components/Sections/ProviderAdvisor/Providers/Employers/AddEmployer.vue'
import ProvidersInviteEmployer from '../components/Sections/ProviderAdvisor/Providers/Employers/InviteEmployers.vue'
import ProvidersSingleEmployer from '../components/Sections/ProviderAdvisor/Providers/Employers/SingleEmployer.vue'

import ProvidersPrior from '../components/Sections/ProviderAdvisor//Providers/PriorAuths/PriorTable.vue'
import ProvidersPriorRequest from '../components/Sections/ProviderAdvisor/Providers/PriorAuths/PriorRequest.vue'
import ProvidersPriorDetermination from '../components/Sections/ProviderAdvisor/Providers/PriorAuths/PriorDetermination.vue'
import ProvidersPriorDetails from '../components/Sections/ProviderAdvisor/Providers/PriorAuths/Details.vue'
import ProvidersPriorAuth from '../components/Sections/ProviderAdvisor/Providers/PriorAuths/PriorAuthorization.vue'

import ProvidersMembers from '../components/Sections/ProviderAdvisor/Providers/Members/Members.vue'
import ProvidersAdvancedSearch from '../components/Sections/ProviderAdvisor/Providers/Members/AdvancedSearch.vue'
import ProvidersMember from '../components/Sections/ProviderAdvisor/Providers/Members/Member.vue'
import ProvidersMemberPrior from '../components/Sections/ProviderAdvisor/Providers/Members/Members.vue'

//  Providers - Provider
import ProvidersProviders from '../components/Sections/ProviderAdvisor/Providers/Providers/ProvidersTable.vue'
import ProvidersAddProviders from '../components/Sections/ProviderAdvisor/Providers/Providers/AddProviders.vue'
import ProvidersInviteProviders from '../components/Sections/ProviderAdvisor/Providers/Providers/InviteProvider.vue'
import ProvidersProvider from '../components/Sections/ProviderAdvisor/Providers/Providers/ProviderCrudForm.vue'
import ProvidersSingleNpi from '../components/Sections/ProviderAdvisor/Providers/Providers/SingleNpi.vue'
import ProvidersProviderResult from '../components/Sections/ProviderAdvisor/Providers/Providers/ResultTable.vue'


import ProvidersAdvisors from '../components/Sections/ProviderAdvisor/Providers/Advisors/AdvisorsTable.vue'
import ProvidersAddAdvisors from '../components/Sections/ProviderAdvisor/Providers/Advisors/AddAdvisor.vue'
import ProvidersAdvisor from '../components/Sections/ProviderAdvisor/Providers/Advisors/Advisor.vue'
import ProvidersAdvisorSearchResult from '../components/Sections/ProviderAdvisor/Providers/Advisors/AdvisorSearchResult.vue'
import ProvidersInviteAdvisor from '../components/Sections/ProviderAdvisor/Providers/Advisors/InviteAdvisor.vue'
import ProvidersAdvisorResults from '../components/Sections/ProviderAdvisor/Providers/Advisors/Result.vue'

import ProvidersBundle from '../components/Sections/ProviderAdvisor/Providers/Bundles/Bundles.vue'
import ProvidersBuilder from '../components/Sections/ProviderAdvisor/Providers/Bundles/Builder.vue'
import ProvidersSingleBundle from '../components/Sections/ProviderAdvisor/Providers/Bundles/SingleBundle.vue'

import ProvidersNetworks from '../components/Sections/ProviderAdvisor/Providers/Networks/NetworksTable.vue'
import ProvidersNetwork from '../components/Sections/ProviderAdvisor/Providers/Networks/SingleNetwork.vue'
import ProvidersNetworkBuilder from '../components/Sections/ProviderAdvisor/Providers/Networks/NetworkBuilder.vue'

import ProvidersRates from '../components/Sections/ProviderAdvisor/Providers/Rates/Rates.vue'
import ProvidersCreateRate from '../components/Sections/ProviderAdvisor/Providers/Rates/CreateRate.vue'



import ProvidersContracts from '../components/Sections/ProviderAdvisor/Providers/Contract/ContractsTable.vue'
import ProvidersContract from '../components/Sections/ProviderAdvisor/Providers/Contract/Contract.vue'
import ProvidersAddContract from '../components/Sections/ProviderAdvisor/Providers/Contract/AddContract.vue'
import ProvidersContractResult from '../components/Sections/ProviderAdvisor/Providers/Contract/ContractResult.vue'
import ProvidersRequestContract from '../components/Sections/ProviderAdvisor/Providers/Contract/ContractsTable.vue'
import ProviderDetailedContract from '../components/Sections/ProviderAdvisor/Providers/Contract/DetailedContract/DetailedContract.vue'
import ProviderDetail from '../components/Sections/ProviderAdvisor/Providers/Contract/Detail/Detail.vue'
import ProviderContractType from '../components/Sections/ProviderAdvisor/Providers/Contract/SelectType.vue'

import ProvidersSettings from '../components/Sections/ProviderAdvisor/Providers/Settings/Settings.vue'
import ProvidersAccount from '../components/Sections/ProviderAdvisor/Providers/Settings/AccountForm.vue'
import ProvidersAddUser from '../components/Sections/ProviderAdvisor/Providers/Settings/AddUser.vue'
import ProvidersUser from '../components/Sections/ProviderAdvisor/Providers/Settings/UsersCrud.vue'
import ProvidersInviteUser from '../components/Sections/ProviderAdvisor/Providers/Settings/InviteUser'
import ProvidersSingleUser from '../components/Sections/ProviderAdvisor/Providers/Settings/UsersCrud'


import ProvidersDetails from '../components/Sections/ProviderAdvisor/Providers/Tabs.vue'



//END

import Networks from '../components/Sections/ProviderAdvisor/Networks/NetworksTable.vue'
import NetworkBuilder from '../components/Sections/ProviderAdvisor/Networks/NetworkBuilder.vue'
import Network from '../components/Sections/ProviderAdvisor/Networks/SingleNetwork.vue'

import Partners from '../components/Sections/ProviderAdvisor/Partners/PartnersTable.vue'
import AddPartner from '../components/Sections/ProviderAdvisor/Partners/AddPartner.vue'
import Partner from '../components/Sections/ProviderAdvisor/Partners/Partner.vue'
import InvitePartner from '../components/Sections/ProviderAdvisor/Partners/InvitePartner.vue'
import PartnerResult from '../components/Sections/ProviderAdvisor/Partners/Result.vue'

import Bundles from '../components/Sections/ProviderAdvisor/Bundles/Bundles.vue'
import BundleBuilder from '../components/Sections/ProviderAdvisor/Bundles/Builder.vue'
import SingleBundle from '../components/Sections/ProviderAdvisor/Bundles/SingleBundle.vue'

import Settings from '../components/Sections/ProviderAdvisor/Settings/Settings.vue'
import Account from '../components/Sections/ProviderAdvisor/Settings/AccountForm.vue'
import AddUser from '../components/Sections/ProviderAdvisor/Settings/AddUser.vue'
import User from '../components/Sections/ProviderAdvisor/Settings/UsersCrud.vue'
import InviteUser from '../components/Sections/ProviderAdvisor/Settings/InviteUser'
import MyProfile from "../components/TablesTemplate/MyProfile.vue"

function routeGuard(to, from, next) {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user.type != 'providerAdvisor' || !$cookies.get('token')){
      next('/login');
    }
      next();
}
let role = "user"
if(localStorage.getItem('user')){
    let user = JSON.parse(localStorage.getItem('user'));
    role = user.role;
  }else{
   role = "no-login"
  }
function routeGuardRole(to, from, next){
    if(role == 'user'){
     next('/login');
    }
    next();
 }
const router = [
  
    {
        path:'/provider-advisor/home',
        name:'Home',
        component:AdvisorsSection,
        beforeEnter: routeGuard,

    },
    {
        path:'/provider-advisor',
        name:'Home',
        component:AdvisorsSection,
        beforeEnter: routeGuard,
        children:[
            {
                path:'profile',
                name:'My Profile',
                component:MyProfile
            },
         
            {
                path:'providers',
                component:Providers,
                name: 'Providers',
                children:
                [
                    {
                        path:'add-provider',
                        component:AddProvider,
                        name: 'Add New',
                        beforeEnter:routeGuardRole
                    },
                    {
                        path:'add-manually',
                        component:AddProviderManually,
                        name: 'Add New',
                        beforeEnter:routeGuardRole
                    },
                    {
                        path:'invite-provider',
                        component:InviteProviders,
                        name: 'Invite Providers',
                        beforeEnter:routeGuardRole
                    },
                    {
                            path:':name',
                            component:ProvidersDetails,
                            name: 'Provider Home',
                            children:
                            [
                               
                           
                                {
                                    path:'employers',
                                    component:ProvidersEmployers,
                                    name: 'Employers',
                                    children:[
                                        
                                        {
                                            path:'add-new',
                                            name:'Add New Employer',
                                            component:ProvidersAddEmployer,
                                            beforeEnter:routeGuardRole
                                        },
                                        {
                                            path:'invite-employer',
                                            name:'Invite New Employer',
                                            component:ProvidersInviteEmployer,
                                            beforeEnter:routeGuardRole
                                        },
                                        {
                                            path:'employer',
                                            name:'Employer',
                                            component:ProvidersSingleEmployer
                                        }
    
                                    ]
                                },
                                // {
                                // path:'members',
                                // component:ProvidersMembers,
                                // name:'Members',
                                // children:[
                                //     {
                                //         path:'member',
                                //         component:ProvidersMember,
                                //         name:'Member'
                                //     },
                                //     {
                                //         path : 'advanced-search',
                                //         component:ProvidersAdvancedSearch,
                                //         name:'Search Result'
                                //     }
                                //   ]
                                // },
                                // {
                                //     path:'prior-auth',
                                //     component:ProvidersPrior,
                                //     name:'Prior Authorizations',
                                //     children:[
                                //         {
                                //         path:'determination',
                                //         component:ProvidersPriorDetermination,
                                //         name:'New Prior Authorization Determination'
                                //         },
                                //         {
                                //             path:'details',
                                //             component:ProvidersPriorDetails,
                                //             name:'Prior Authorization Details'
                                //         },
                        
                                //         {
                                //             path:'new-request',
                                //             component:ProvidersPriorRequest,
                                //             name:'New Prior Authorization Request'
                                //         },
                                //         {
                                //             path:'auth',
                                //             component: ProvidersPriorAuth,
                                //             name:'New Prior Authorization Request'
                                //         },
                                       
                                        
                                //         ]
                                // },
                                {
                                    path:'providers',
                                    name:'Providers',
                                    component:ProvidersProviders,
                                    children:[
                                        
                                        {
                                            path:'invite-providers',
                                            name:"Invite Providers",
                                            component:ProvidersInviteProviders,
                                            beforeEnter:routeGuardRole
                                        },
                                        {
                                            path:'add-manually',
                                            name:"Add New Provider",
                                            component:AddProviderManually,
                                            beforeEnter:routeGuardRole
                                        },
                                        {
                                            path:'provider',
                                            name:"Provider",
                                            component:ProvidersProvider
                                        },
                                        {
                                            path:'invite-providers',
                                            name:"Invite Providers",
                                            component:ProvidersInviteProviders,
                                            beforeEnter:routeGuardRole
                                        },
                                        {
                                            path:'add-providers',
                                            name:"Add New Provider",
                                            component:ProvidersAddProviders
                                        },
                                        {
                                            path:'result',
                                            name:"Search Result",
                                            component:ProvidersProviderResult,
                                            children:[
                                                {
                                                    path:'npi',
                                                    name:'Npi Detail',
                                                    component:ProvidersSingleNpi

                                                }
                                            ]
    
                                        }
                                    ]
                                },
                             
                                {
                                    path:'advisors',
                                    name:'Partners',
                                    component:ProvidersAdvisors,
                                    children:[
                                        {
                                            path:'add-new',
                                            name:'Add New Partner',
                                            component:ProvidersAddAdvisors
                                        },
                                        {
                                            path:'result',
                                            name:'Search Result',
                                            component:ProvidersAdvisorSearchResult
                                        },
                                        {
                                            path:'invite-advisor',
                                            name:'Invite Partner',
                                            component:ProvidersInviteAdvisor,
                                            beforeEnter:routeGuardRole
                                        },
                                        {
                                            path:'advisor',
                                            name:'Partner Detail',
                                            component:ProvidersAdvisor
                                        },
                                        {
                                            path:'result',
                                            name:'Invite Partner',
                                            component:ProvidersAdvisorResults
                                            
                                        }
                                      
                                    ]
                                },
                                // {
                                //    path:'bundles',
                                //    component:ProvidersBundle,
                                //    name:'Bundle',
                                //    children:[
                                //        {
                                //            path:'builder',
                                //            name:'Bundle Builder',
                                //            component:ProvidersBuilder
                                //        },
                                //        {
                                //         path:'bundle',
                                //         name:'Details',
                                //         component: ProvidersSingleBundle
                                       
                                //        }
                                //    ]
                                // },
                                // {
                                //     path:'networks',
                                //     component:ProvidersNetworks,
                                //     name:'VNets',
                                //     children:[
                                //         {
                                //             path:'builder',
                                //             name:'Network Builder',
                                //             component:ProvidersNetworkBuilder
                                //         },
                                //         {
                                //             path:'network',
                                //             name:'Bundle Builder',
                                //             component:ProvidersNetwork
                                //         }
                                //     ]
                                //   },
                                //   {
                                //       path:'rates',
                                //       name:'Rates',
                                //       component:ProvidersRates,
                                //       children:[
                                //          {
                                //              path:'create-rate',
                                //              name:'New Rate',
                                //              component:ProvidersCreateRate
                                //          }
                                //       ]
                                //   },
                                 {
                                    path:'contracts',
                                    component:ProvidersContracts,
                                    name:'Contracts',
                                    children:[
                                      
                                        {
                                            path:'contract',
                                            component:ProvidersContract,
                                            name:'Contract',
                                        },
                                        {
                                            path:':type',
                                            component:ProviderContractType,
                                            name:'Contract Type',
                                            beforeEnter:routeGuardRole,
                                            children:[
                                                {
                                                    path:'add-new',
                                                    component:ProvidersAddContract,
                                                    name:'Add New Contract',
                                                    beforeEnter:routeGuardRole
                                                },
                                            ]
                                        },
                                        {
                                            path:'request',
                                            component:ProvidersRequestContract,
                                            name:'Request Contract',
                                            beforeEnter:routeGuardRole
                                        },
                                        {
                                            path:'detailed-contract',
                                            component:ProviderDetailedContract,
                                            name:'Detailed Contract'
                                            
                                        },
                                        
                                        {
                                            path:'detail',
                                            component:ProviderDetail,
                                            name:'Detail',
                                            
                                        }
                                    ]
    
                                 },
                                 {
                                    path: "settings",
                                    component: ProvidersSettings,
                                    name:'Settings',
                                    children:
                                    [
                                        {
                                            path:'account',
                                            name:'Account',
                                            component:ProvidersAccount
                                        },
                                        {
                                            path: "users",
                                            name:'Users',
                                            component: ProvidersSettings,
                                            children:[
                                                    {
                                                        path:'user',
                                                        name:'User',
                                                        conponent:ProvidersUser,
                                                    },
                                                    {
                                                        path:'add-new',
                                                        name:'Add New User',
                                                        component:ProvidersAddUser,
                                                        beforeEnter:routeGuardRole
                                                    },
                                                    {
                                                        path:'invite-user',
                                                        name:'Invite User',
                                                        component:ProvidersInviteUser,
                                                        beforeEnter:routeGuardRole
                                                    },
                                                    {
                                                        path:'user',
                                                        name:'User',
                                                        component:ProvidersSingleUser
                                                    },
                                                    
                                            ]
                                        },
                                    ]     
                                },
                            ]
                         
                        
                    },
                    {
                        path:'result',
                        component:ProvidersResult,
                        name: 'Search Result',
                        children:[
                            {
                                path:'npi',
                                component:Provider,
                                name: 'Npi Detail',
                                
                            }, 
                            
                        ]
                    },   
                  
                ]
            },
            {
                path:'partners',
                name:'Partners',
                component:Partners,
                children:[
                    {
                        path:'add-new',
                        name:'Add New Partner',
                        component:AddPartner,
                        
                        children:[
                            {
                                path:'result',
                                name:'Search Result',
                                component:PartnerResult,
                            }
                        ]
                    },
                    {
                        path:'invite-partner',
                        name:'Invite Partner',
                        component:InvitePartner,
                        beforeEnter:routeGuardRole
                    },
                    {
                        path:'partner',
                        name:'Partner Detail',
                        component:Partner,
                    }
                ]
            },
            // {
            //     path:'networks',
            //     name:'VNets',
            //     component:Networks,
            //     children:[
            //         {
            //             path:'builder',
            //             name:'Network Builder',
            //             component:NetworkBuilder,
            //         },
            //         {
            //             path:'network',
            //             name:'Network Detail',
            //             component:Network,
            //         }
            //     ]
            // },
            // {
            //     path:'bundles',
            //     name:'Bundles',
            //     component:Bundles,
            //     children:[
            //         {
            //             path:'builder',
            //             name: 'Bundle Builder',
            //             component:BundleBuilder
            //         },
            //         {
            //             path:'bundle',
            //             name: 'Details',
            //             component:SingleBundle
                        
            //         }
            //     ]
            // },
            {
                path:'settings',
                name:'Settings',
                component:Settings,
                children:[
                    {
                        path:'account',
                        name:'Account',
                        component:Account
                    },
                    {
                        path: "users",
                        name:'Users',
                        component: Settings,
                        children:[
                                {
                                    path:'user',
                                    name:'User',
                                    conponent:User,
                                },
                                {
                                    path:'add-new',
                                    name:'Add New User',
                                    component:AddUser,
                                    beforeEnter:routeGuardRole
                                },
                                {
                                    path:'invite-user',
                                    name:'Invite User',
                                    component:InviteUser,
                                    beforeEnter:routeGuardRole
                                },
                        ]
                    },
                ]
            },
           
        ]

    }
]

export default router;