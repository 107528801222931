<template>
    <div>
        <Bundles v-if="path == `/employer-advisor/employers/${routeParams}/bundles`"></Bundles>        
        <Builder v-if="path == `/employer-advisor/employers/${routeParams}/bundles/builder`"></Builder>
    </div>
</template>
<script>
import Bundles from './Bundles.vue'
import Builder from './Builder.vue'

export default {
    components:{
        Bundles,
        Builder
    },
    computed:{
       path(){
            return this.$route.path.replace(/%20/g,' ')
        },
        routeParams(){
         return this.$route.params.name
        }
    }
}
</script>