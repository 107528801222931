<template>
  <v-row class="padding">
    <div class="col-md-12">
      <h3 class="font">Advisor</h3>
    </div>
    <form class="row">
      <div class="col-md-6">
        <div class="col-md-12">
          <label class="font text-gray" for="">First Name</label>
          <v-text-field required solo v-model.trim="firstName"></v-text-field>
        </div>
        <div class="col-md-12">
          <label class="font text-gray" for="">Last Name</label>
          <v-text-field required solo v-model.trim="lastName"></v-text-field>
        </div>
        <div class="col-md-12">
          <label class="font text-gray" for="">Email</label>
          <v-text-field required v-model.trim="email" solo></v-text-field>
        </div>
        <div class="col-md-12">
          <label class="font text-gray" for="">Phone</label>
          <v-text-field required solo v-model.trim="phone"></v-text-field>
        </div>
      </div>
      <div class="col-md-6">
        <div class="col-md-12">
          <label class="font text-gray" for="">Company Name</label>
          <v-text-field required solo v-model.trim="company"></v-text-field>
        </div>
        <div class="col-md-12">
          <label class="font text-gray" for="">Title</label>
          <v-text-field required solo v-model.trim="title"></v-text-field>
        </div>
        <div class="row no-padding">
          <div class="col-md-6">
            <label class="font text-gray" for="">City</label>
            <v-text-field required v-model.trim="city" solo></v-text-field>
          </div>
          <div class="col-md-3">
            <label class="font text-gray" for="">State</label>
            <v-select
              class="text-gray"
              v-model.trim="state"
              item-text="name"
              item-value="name"
              :items="states"
              label=""
              solo
            ></v-select>
          </div>
          <div class="col-md-3">
            <label class="font text-gray" for="">Zip</label>
            <v-text-field
              required
              solo
              type="number"
              v-model.trim="zip"
            ></v-text-field>
          </div>
        </div>
      </div>
      <div class="col-md-12 d-flex align-end flex-column">
        <div>
          <v-btn
            class="blue-buttons mr-6"
            @click="$router.push(`/employer/advisors`)"
          >
            Close
          </v-btn>
          <v-btn class="blue-buttons mr-6" @click="removeItem">
            Remove Partner
          </v-btn>
        </div>
      </div>
    </form>
    <v-dialog v-model.trim="dialog" max-width="600px" persistent>
      <v-card>
        <v-col>
          <h3>Remove Advisor</h3>
        </v-col>
        <v-col>
          <span>Are you sure you want to delete this Advisor?</span>
        </v-col>
        <v-col>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="blue-buttons float-class" @click="confirmRemove"
              >Confirm</v-btn
            >
            <v-btn class="button-style float-class" @click="closePopUp"
              >Close</v-btn
            >
          </v-card-actions>
        </v-col>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import api from "../../../../../services/api";
export default {
  data() {
    return {
      states: [],
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      company: "",
      title: "",
      state: "",
      zip: "",
      city: "",
      dialog: false,
    };
  },
  computed: {
    getClient() {
      return this.$store.getters.getClientGetter;
    },
    routeParams() {
      return this.$route.params.name;
    },
    singleAdvisor() {
      return this.$store.getters.getSingleAdvisorinEmployer;
    },
  },
  created() {
    this.getStates();
    if (this.singleAdvisor) {
      this.firstName = this.singleAdvisor.primaryContact.firstName;
      this.lastName = this.singleAdvisor.primaryContact.lastName;
      this.company = this.singleAdvisor.companyName;
      this.email = this.singleAdvisor.primaryContact.email;
    }
  },
  methods: {
    closePopUp() {
      this.dialog = false;
    },
    confirmRemove() {
      let user = JSON.parse(localStorage.getItem("user"));
      let employerId = user.groupPortal[0].organization.employer;

      api()
        .delete(`/employers/${employerId}/advisors/${this.singleAdvisor._id}`)
        .then((res) => {
          if (res) {
            api()
              .get(`/employers/${employerId}/advisors`)
              .then((res) => {
                if (res) {
          
                  this.$store.commit("setAllPartners", res.data.advisors);
                  this.dialog = false;
                  this.$router.push(`/employer/advisors`);
                  
                }
              });
          }
        });
    },
    getStates() {
      api()
        .get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },
  },
};
</script>
 

