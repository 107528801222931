<template>
  <div>
    <div :class="showOverlay ? ' postion-relative' : ''">
      <div
        v-if="showOverlay"
        class="wizard-absolute"
        style="border-radius: 7px"
      >
        <div class="col-md-12">
          <div class="white-background" style="border-radius: 7px">
            <Header
              :title="'TPAs'"
              :searchInput="true"
              :inviteButton="true"
              :placeholder="'Search TPAs'"
              :newButton="false"
              @invite="invite"
              @add="add"
              @searchInput="searchInput"
            ></Header>

            <v-simple-table
              fixed-header
              height="870"
              class="no-padding tr-custom-height mt-3"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">TPA NAME</th>
                    <th class="text-center">SELECT</th>
                  </tr>
                </thead>

                <tbody
                  v-if="tpas.length > 0"
                  class="text-gray tr-custom-height"
                >
                  <tr v-for="(item, index) in tpas" :key="index">
                    <td v-if="item.companyName">
                      <a>{{ item.companyName }}</a>
                    </td>
                    <td v-else><a>/</a></td>

                    <td
                      class="
                        text-right
                        row
                        justify-center
                        no-padding
                        text-center
                        align-center
                      "
                    >
                      <div
                        class="text-end"
                        :id="'selected_item' + index"
                        :class="
                          item.select ? 'fill-blue-checkbox' : 'blue-checkbox'
                        "
                        @click="fillArray(item, index)"
                      ></div>
                    </td>
                  </tr>
                </tbody>

                <tbody v-else>
                  <v-col md="12">
                    There are no clients currently added to your account.
                    <a> Click to add your clients</a>
                  </v-col>
                </tbody>
              </template>
            </v-simple-table>
            <div class="gray-top-border">
              <div class="col-md-12" align="end">
                <span
                  class="
                    text-gray text-underline
                    mr-10
                    font-weight-bold
                    pointer
                  "
                  @click="closeTpaDialog"
                  >Cancel</span
                >
                <span
                  class="
                    text-gray text-underline
                    mr-10
                    font-weight-bold
                    pointer
                  "
                  @click="closeTpaDialog"
                  >Add Selected</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <Header :title="'Client Detail'"></Header>
      <div class="col-md-12">
        <div class="col-md-12">
          <div class="row gray-border-form">
            <div class="col-xl-4 col-lg-4 col-md-6">
              <div class="col-md-12">
                <h4 class="text-gray">Company Information</h4>
              </div>
              <v-form ref="form" v-model="valid" class="col-md-10">
                <div class="col-md-12 pb-0">
                  <label for="">Company Name</label>
                  <v-text-field
                    v-model="companyName"
                    :rules="requiredRules"
                    solo
                  ></v-text-field>
                </div>
                <div class="col-md-12 pt-0 pb-0">
                  <label for="">Tax ID / EIN</label>
                  <v-text-field
                    solo
                    type="text"
                    @keyup="showSlash($event)"
                    v-model.trim="taxId"
                    required
                    placeholder="00-0000000"
                    :rules="taxIdRules"
                    counter="10"
                    maxlength="10"
                  ></v-text-field>
                </div>
                <div class="col-md-12 pt-0 pb-0">
                  <label for="">Status</label>
                  <v-text-field
                    v-model="status"
                    :rules="requiredRules"
                    solo
                  ></v-text-field>
                </div>
                <div class="col-md-12 pt-0 pb-0">
                  <label for="">Contact First Name </label>
                  <v-text-field
                    v-model="firstName"
                    :rules="requiredRules"
                    solo
                  ></v-text-field>
                </div>
                 <div class="col-md-12 pt-0 pb-0">
                  <label for="">Contact Lat Name </label>
                  <v-text-field
                    v-model="lastName"
                    :rules="requiredRules"
                    solo
                  ></v-text-field>
                </div>
                <div class="col-md-12 pt-0 pb-0">
                  <label for="">Email</label>
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    solo
                  ></v-text-field>
                </div>
              </v-form>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6">
              <div class="col-md-10 row justify-space-between">
                <h4 class="text-gray text-underline pl-3">
                  TPA / Carrier Information
                </h4>
                <!-- <h4 class="light-blue-text text-underline">
                    Search for and add a TPA
                  </h4> -->
              </div>
              <div class="mt-5 col-md-10">
                <v-simple-table
                  fixed-header
                  height="700"
                  class="no-padding tr-custom-height"
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">TPA NAME</th>
                        <!-- <th class="text-right">ACTION</th> -->
                      </tr>
                    </thead>

                    <tbody
                      v-if="tpas.length > 0"
                      class="text-gray tr-custom-height"
                    >
                      <tr v-for="(item, index) in tpas" :key="index">
                        <td v-if="item && item.name">
                          {{ item.name }}
                        </td>
                        <td v-else><a>/</a></td>
                        <!-- <td class="text-right">
                          <a class="text-underline">Remove</a>
                        </td> -->
                      </tr>
                    </tbody>

                    <tbody v-else>
                      <v-col md="12"> No results </v-col>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12">
              <div class="col-md-12 row">
                <h4 class="text-gray text-underline">Actions and Functions</h4>
              </div>
              <br /><br />
              <div class="">
                <div class="row col-md-12">
                  <div class="col-md-12">
                    <h3 class="text-gray text-underline">Submit Charges</h3>
                  </div>
                  <br /><br /><br />
                  <div class="col-md-12">
                    <h3 class="text-gray text-underline">
                      View Prior Invoices
                    </h3>
                  </div>
                  <br /><br /><br />
                  <div class="col-md-12">
                    <div class="col-md-10 font text-gray">
                      <label for="">Client Relationship Status</label>
                      <div class="d-flex mt-2 font-weight-bold">
                        InActive
                        <v-switch
                          solo
                          v-model="status"
                          class="no-padding ml-2 mr-2 vendors-switch"
                        ></v-switch>
                        Active
                      </div>
                    </div>
                  </div>
                  <br /><br /><br />
                  <div class="mt-5 col-md-12">
                    <h3
                      @click="removeClient"
                      class="
                        text-underline
                        col-md-12
                        text-gray
                        pointer
                        font-weight-bold font-18
                      "
                    >
                      Remove Client
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="col-md-12 mb-4" align="end">
          <span
            class="
              text-gray text-underline
              pointer
              font-weight-bold
              mr-10
              font-18
            "
            @click="$router.go(-1)"
            >Cancel</span
          >
          <span
            @click="update"
            class="
              text-gray text-underline
              pointer
              font-weight-bold
              mr-5
              font-18
            "
            >Save</span
          >
        </div>
      </div>
      <removeDialog
        ref="dialog_delete"
        v-on:confirm="confirmRemove"
      ></removeDialog>
      <successModal
        ref="success_modal"
        v-on:confirm="closeSuccessModal"
      ></successModal>
    </div>
  </div>
</template>
<script>
import Header from "../../DigitalVendorInvoices/Layout/HeaderPage.vue";
import api from "../../../../services/api";
import { mapGetters } from "vuex";
import removeDialog from "../../../TablesTemplate/RemoveDialog.vue";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";

export default {
  components: { Header, removeDialog, successModal },
  data() {
    return {
      taxIdRules: [
        (v) => !!v || "This field is required",
        (v) =>
          !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
          "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
      ],
      zipRules: [
        (v) => (v && v.length == 5) || " Zip Code should be five digits long",
      ],
      states: [],
      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
      requiredRules: [(v) => !!v || "This field is required"],

      emailRules: [
        (v) => !!v || "This field is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      state: "",
      companyName: "",
      zip: "",
      city: "",
      firstName: "",
      phone: "",
      lastName: "",
      title: "",
      email: "",
      status: "",
      taxId: "",
      tpas: [
        { name: "Cigna", action: "" },
        { name: "WebTPA", action: "" },
      ],
      showOverlay: false,
      valid: false,
    };
  },
  created() {
    // this.getStates();
    this.getInfo();
  },
  computed: {
    ...mapGetters(["getSingleItem"]),
  },
  methods: {
    closeSuccessModal() {
      this.$router.push("/solution-vendor/clients");
    },
    confirmRemove() {
      api()
        .delete(`/vendors/clients/${this.getSingleItem._id}`)
        .then((res) => {
          if (res) {
            this.$parent.getData();
            this.$router.push("/solution-vendor/clients");
          }
        });
    },
    removeClient() {
      this.$refs.dialog_delete.openPopUp(
        "Are you sure that you want to delete this client?"
      );
    },
    showSlash(event) {
      if (this.taxId.length == 2 && event.keyCode != 8) {
        this.taxId += "-";
      }
      if (this.taxId.length == 10) {
        api()
          .get("auth/isValidTaxId?taxId=" + this.taxId)
          .catch((res) => {
            if (res) {
              this.taxIdRules = [res.response.data.message];
            }
          });
      } else {
        this.taxIdRules = [
          (v) => !!v || "This field is required",
          (v) =>
            !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
            "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
        ];
      }
    },
    addTpa() {
      this.$store.commit("setNavItemFilter", "TPA");
      this.showOverlay = true;
    },
    fillArray(item, index) {
      let doc = document.getElementById("selected_item" + index);
      item.select = !item.select;
      if (!item.select) {
        this.selected.push(item);
        doc.className = "fill-blue-checkbox";
      } else {
        this.selected.splice(this.selected.indexOf(item), 1);
        doc.className = "blue-checkbox";
      }
    },
    getInfo() {
      if (this.getSingleItem) {
        this.state = this.getSingleItem.state;
        this.zip = this.getSingleItem.zipCode;
        this.companyName = this.getSingleItem.companyName;
        this.taxId = this.getSingleItem.taxId;
        if (this.getSingleItem.primaryContact) {
          this.firstName = this.getSingleItem.primaryContact.firstName;
          this.phone = this.getSingleItem.primaryContact.phone;
          this.lastName = this.getSingleItem.primaryContact.lastName;
          this.title = this.getSingleItem.primaryContact.title;
          this.email = this.getSingleItem.primaryContact.email;
        }
        this.status = this.getSingleItem.status;
        this.tpas = this.getSingleItem.tpas;
      }
    },
    update() {
   
      this.$refs.form.validate();
      if (this.valid) {
     
        let data = {
          companyName: this.companyName,
          taxId: this.taxId,
          // status: this.status,
          firstName: this.firstName,
          lastName:this.lastName,
          email: this.email,
          tpas: this.tpas,
        };

        api()
          .put(`/vendors/clients/` + this.getSingleItem._id, data)
          .then((res) => {
            if (res) {
              this.$refs.success_modal.openPopUp("Client updated successfully");
            }
          });
      }
    },
    getStates() {
      api()
        .get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },
  },
};
</script>
<style>
</style>