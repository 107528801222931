<template>
  <div class="white-background border-radius">
    <div v-if="!isChildren">
      <div class="row border-blue-bottom">
        <div class="col-md-4 row">
          <div
            class="col-md-4 pt-5 text-center pointer font-weight-bold"
            @click="getAllSolutions('solution')"
            :class="tab == 'solution' ? 'light-blue-text' : 'text-gray'"
          >
            Solutions
          </div>
          <v-divider vertical class="dividers-margin"></v-divider>
          <div
            class="col-md-4 pt-5 text-center pointer font-weight-bold"
            @click="getAllSolutions('favorites')"
            :class="tab == 'favorites' ? 'light-blue-text' : 'text-gray'"
          >
            Favorites
          </div>
          <v-divider vertical class="dividers-margin"></v-divider>
        </div>
        <div class="col-md-2">
          <v-text-field
            class="input-control mr-5"
            prepend-inner-icon="mdi-magnify"
            @input="searchMembers"
            light
            dense
            outlined
            placeholder="Search Solutions"
          ></v-text-field>
        </div>
        <!-- <div class="col-md-4 row">
          <v-col class="d-flex aling-items-center col-md-6" cols="12">
            Type:
            <v-select
              :items="typeItems"
              solo
              dense
              v-model="type"
              hide-details
              class="ml-3"
            ></v-select>
          </v-col>

          <v-col class="d-flex aling-items-center col-md-6" cols="12">
            Sort By:
            <div>
              <v-select
                :items="sortItems"
                solo
                dense
                v-model="sortBy"
                hide-details
                class="ml-3"
              ></v-select>
            </div>
          </v-col>
        </div> -->
        <div class="col-md-6" align="end">
          <v-btn class="my-1 gray-border-buttons" @click="dialog = true">
            <svg
            class="mr-2"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 7.5H15"
              stroke="#707070"
              stroke-width="2"
              stroke-linejoin="round"
            />
            <path
              d="M7.5 0V15"
              stroke="#707070"
              stroke-width="2"
              stroke-linejoin="round"
            />
          </svg>
            Invite
          </v-btn>
        </div>
      </div>
      <div class="mt-3">
        <v-simple-table
          fixed-header
          height="830"
          class="no-padding tr-custom-height"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">SOLUTION / PACKAGE NAME</th>
                <th class="text-left">CATEGORY</th>
                <th class="text-left">PLANS FOR SOLUTION</th>
                <th class="text-left">ACTION</th>
              </tr>
            </thead>
            <tbody
              v-if="mergedData.length > 0"
              class="text-gray tr-custom-height"
            >
              <tr v-for="(item, index) in mergedData" :key="index">
                <td>
                  <a class="text-underline" >{{ item.companyName ? item.companyName : item.name}}</a>
                </td>
                <td>{{item.category ? item.category : "/"}}</td>
                <td>
                  <v-select
                    style="width: 300px !important"
                    solo
                    hide-details
                    multiple
                    item-value="_id"
                    item-text="name"
                    v-model="item.plan"
                    :items="plans"
                  ></v-select>
                </td>

                <td v-if="item.type == 'non-provider'">
                  <v-btn class="activate"
                  @click="activateVendor(item)"
                  
                   > Activate Vendor </v-btn>
                </td>
                <td v-else>
                  <v-btn class="activate"
                  @click="activateSolution(item)"
                   > Activate Solution </v-btn>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <v-col md="12">
                <v-alert border="bottom" colored-border color="primary" elevation="2" class="text-gray">
                  There are no solutions available currenlty.
                </v-alert> </v-col>
            </tbody>
          </template>
        </v-simple-table>
        <v-dialog v-model="selectPlan" persistent max-width="600px">
          <v-card>
          
            <v-card-text>
              <v-container>
                <span class="text-h5 mt-5 text-underline justify-center d-flex col-md-12 font-weight-bolder"> 
                     Select A Plan First!
                </span>
           
              </v-container>
            </v-card-text>
            <v-card-actions>
              <div class="col-md-12" align="end">
                <span
                  class=" mr-10 pointer font-weight-bold"
                  @click="selectPlan = false"
                  >Okay</span
                >
              </div>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialog" persistent max-width="800px">
          <v-card>
            <v-card-title>
              <span class="text-h5 text-underline col-md-12 font-weight-bolder"
                >Invite a Solution Facilitator or a  Vendor</span
              >
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form class="row" ref="form" v-model="valid">
                  <v-col cols="12" sm="12" md="12">
                    <label for="">Company Name</label>
                    <v-text-field
                      hide-details
                      :rules="requiredRules"
                      v-model="companyName"
                      solo
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <label for="">Contact First Name</label>
                    <v-text-field
                      hide-details
                      solo
                      :rules="requiredRules"
                      required
                      v-model="firstName"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <label for="">Contact Last Name</label>
                    <v-text-field
                      hide-details
                      :rules="requiredRules"
                      solo
                      required
                      v-model="lastName"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <label for="">Email</label>
                    <v-text-field
                      hide-details
                      solo
                      :rules="emailRules"
                      required
                      v-model="email"
                    ></v-text-field>
                  </v-col>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <div class="col-md-12" align="end">
                <span
                  class="text-underline text-gray mr-10 pointer"
                  @click="cancelInvite"
                  >Cancel</span
                >
                <span
                  class="text-underline text-gray pointer"
                  @click="inviteSolutions"
                  >Invite</span
                >
              </div>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <div class="col-md-12">
          <div class="col-md-12 mb-4" align="end">
            <span
              class="light-blue-text text-underline pointer font-weight-bold mr-10 font-18"
              @click="$emit('closeDialog')"
              >Cancel</span
            >
            <!-- <span
              class="light-blue-text text-underline pointer font-weight-bold mr-5 font-18"
              @click="addSelected"
              >Add Selected</span -->
            
          </div>
        </div>
      </div>
      <successModal
        ref="success_modal"
        v-on:confirm="closeSuccessModal"
      ></successModal>
      <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import api from "../../../../../../services/api";
import successModal from "../../../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../../../TablesTemplate/ErrorDialog.vue";
export default {
  components: { successModal, errorModal },
  props: ["plans", "currentSolutions"],
  data() {
    return {
      planIds:[],
      completeList: [],
      newSolutions: [],
      availableSolutions: [],
      allSolutions: [],
      tab: "solution",
      dialog: false,
      items: [],
      singleItem: null,
      typeItems: [{ text: "Categories", value: "Categories" }],
      sortItems: [{ text: "Newest", value: "Newest" }],
      sortBy: "",
      type: "",
      solutions: [],
      favorites: [],
      selectedItems: [],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
      valid: false,
      companyName: "",
      firstName: "",
      lastName: "",
      email: "",
      vendorList: [],
      plan:[],
      selectPlan: false,
      list: [],
    };
  },
  computed: {
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
    mergedData() {
     if(this.$route.query.filterBy == 'vendor'){
      console.log(this.$route.query.filterBy == 'vendor')
      let complete = [...this.newSolutions, ...this.vendorList];
      let displayList = [];
     complete.forEach((response) => {
      console.log('response.id',response.id, response._id,this.$route.query.vendorId)
                if(response.id == this.$route.query.vendorId || response._id == this.$route.query.vendorId)
                  displayList.push(response);
                  // console.log('response.id',displayList,this.$route.query.vendorId)
              });
              return displayList;

     }else{
         return [...this.newSolutions, ...this.vendorList];
     }
   
    }
  },

  methods: {
    getVendors() {
        api()
          .get("/vendors/market/vendors")
          .then((res) => {
            this.vendorList = res.data;
            this.completeList.push(this.vendorList);
          });
      },
    closeErrorModal() {
      this.$emit("closeDialog");
      this.dialog = false;
      this.companyName = "";
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.$refs.success_modal.closePopUp();
      this.$refs.error_modal.closePopUp();
    },
    closeSuccessModal() {
      setTimeout(() => {
        this.$emit("getSolutions");
        this.dialog = false;
        this.companyName = "";
        this.firstName = "";
        this.lastName = "";
        this.email = "";
        this.$refs.success_modal.closePopUp();
        this.$emit("closeDialog");
      }, "3000");
    },
    deselectItem(item, index) {
      let el = document.getElementById("selected-" + index);
      el.classList.remove("fill-blue-checkbox");
      el.classList.add("blue-checkbox");
      this.selectedItems.splice(
        this.selectedItems.findIndex((i) => i.id == item._id),
        1
      );
    },
    getAllSolutions(tab) {
      this.tab = tab;
      api()
        .get("/tpas/solutions")
        .then((res) => {
          this.items = [];
          if (this.tab == "favorites") {
            res.data.solutions.forEach((el) => {
              res.data.favorites.forEach((response) => {
                if (el._id == response) {
                  this.items.push(el);
                }
              });
            });
          } else {
            this.items = res.data.solutions;

            this.currentSolutions.forEach((el) => {
              this.allSolutions.push(el.solution);
            });
            let ids = this.allSolutions.map((item) => item._id);
            this.newSolutions = this.items.filter(
              (item) => !ids.includes(item._id)
            );
            this.completeList.push(this.newSolutions);
          }
        });
    },
    showDetails(item) {
      if(item.plans.length > 0){
              this.singleItem = item;
      this.$store.commit("setTpaSingleSolution", item);
      this.$router.push(`/tpa/clients/solutions/${this.$route.params.clientId}/activate-solution/${item._id}`);
      }
      else{
        this.selectPlan = true;
      }
    },
    activateSolution(item){
      this.singleItem = item;
      this.planIds = this.plans.map(obj => obj._id);
      this.singleItem.plans = item.plan;
      if(this.singleItem.plans.length> 0){
      this.$store.commit("setTpaSingleSolution", item);
      this.$router.push(`/tpa/clients/solutions/${this.$route.params.clientId}/activate-solution/${item._id}`);
      }
      else{
        this.selectPlan = true;
      }
   
    },
    activateVendor(item){
    console.log('item',item)
         this.singleItem = item;
      this.singleItem.plans = item.plan;
      if(this.singleItem.plans.length> 0){
             this.$store.commit("setTpaSingleSolution", this.singleItem);
             if(item.id){
               this.$router.push(`/tpa/client/${this.$route.params.clientId}/activate-vendor/${item.id}`); 
             }
             if(item._id){
               this.$router.push(`/tpa/client/${this.$route.params.clientId}/activate-vendor/${item._id}`); 
             }
     
      }
      else{
        this.selectPlan = true;
      }


   
     
    },
    fillArray(item, index) {
      if (!item.rating) {
        this.favorites.push(item);
      } else {
        this.favorites.splice(this.favorites.indexOf(item), 1);
      }
    },
    searchMembers() {},
    cancelInvite(){
      this.firstName = "";
      this.lastName = "";
      this.companyName = "";
      this.email = "";
      this.dialog = false;
    },
    inviteSolutions() {
      this.$refs.form.validate();
      if (this.valid) {
        let data = {
          companyName: this.companyName,
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
        };
        api()
          .post("/tpas/solutions/invite", data)
          .catch((res) => {
            if (res) {
              this.$refs.error_modal.openPopUp(res.response.data);
            }
          })
          .then((res) => {
            if (res) {
              this.$refs.success_modal.openPopUp("Invited Successfully");
            }
            //ratof41600@sopulit.com
          });
      }
    },
  },
  created() {
    this.getAllSolutions();
    this.getVendors();
  },
};
</script>
<style>
.blue-checkbox {
  width: 25px;
  height: 25px;
  border: 2px solid gray;
  border-radius: 3px;
}
.fill-blue-checkbox {
  width: 25px;
  height: 25px;
  border: 2px solid gray;
  border-radius: 3px;
  background: #4072b7;
}
.activate {
  width: 160px;
}
</style>
