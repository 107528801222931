<template>
  <div class="no-padding row-height">
    <div class="col-md-12">
      <v-row>
        <v-col class="col-xl-1 col-lg-4 col-md-4 font" md="4" cols="12">
          <h3>
            <span class="font"> Partners </span>
          </h3>
        </v-col>
        <v-col class="col-xl-2 col-lg-3 col-md-3" md="3" cols="12">
          <v-text-field
            class="input-control"
            v-if="filteredArray.length > 0"
            @input="searchAdvisor"
            prepend-inner-icon="mdi-magnify"
            light
            dense
            outlined
            placeholder="Search Partners"
          >
          </v-text-field>
        </v-col>
        <v-col
          class="col-xl-9 col-lg-5 col-md-5 text-end"
          cols="12"
          align="end"
        >
          <v-btn
            class="my-1 blue-buttons"
            v-if="$role == 'user'"
            @click="
              $router.push(
                `/employer-advisor/employers/${routeParams}/advisors/add-new`
              )
            "
          >
            Search Partners</v-btn
          >
          <v-menu offset-y v-if="$role != 'user'">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" class="my-1 blue-buttons"> Add Partners</v-btn>
            </template>
            <v-list>
              <v-list-item>
                <a
                  class="a-tag underline-text"
                  @click="
                    $router.push(
                      `/employer-advisor/employers/${routeParams}/advisors/add-new`
                    )
                  "
                  >Add Partners</a
                >
              </v-list-item>
              <v-list-item>
                <a
                  class="a-tag underline-text"
                  @click="
                    $router.push(
                      `/employer-advisor/employers/${routeParams}/advisors/invite-advisor`
                    )
                  "
                  >Invite Partners</a
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </div>
    <div class="col-md-12 text-center justify-center" v-if="readyToShow">
      <v-progress-circular
        indeterminate
        :size="100"
        color="primary"
        class="text-center"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-row class="col-md-12" v-if="allAdvisors.length < 1 && !emptySearch">
        <v-col md="12" class="font-bold">
          You have not yet added any Partners to your account -
          <a
            v-if="$role != 'user'"
            @click="
              $router.push(
                `/employer-advisor/employers/${routeParams}/advisors/add-new`
              )
            "
            >Click Here to Add Partners.</a
          >
        </v-col>
      </v-row>

      <v-simple-table v-else fixed-header height="630px">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Partner Name</th>
              <th class="text-left">Type</th>
              <th class="text-left">Primary Contact</th>
              <th class="text-left">State</th>
              <th class="text-end" v-if="$role != 'user'">Actions</th>
            </tr>
          </thead>
          <tbody v-if="allAdvisors.length > 0">
            <tr
              v-for="(item, index) in allAdvisors"
              :key="index"
              class="text-gray"
            >
              <td>
                <a @click="singleAdvisor(item)">{{ item.companyName }}</a>
              </td>
              <td
                v-html="
                  item.advisorType == 'brokerConsultant'
                    ? 'Broker Consultant'
                    : item.advisorType == 'analyticsConsultant'
                    ? 'Analytics Consultant'
                    : item.advisorType
                "
              >
                {{ item.advisorType }}
              </td>
              <td v-if="item.primaryContact">
                {{ item.primaryContact.firstName }}
                {{ item.primaryContact.lastName }}
              </td>
              <td v-else>/</td>
              <td v-html="item.state ? item.state : '/'"></td>
              <td class="text-end" v-if="$role != 'user'">
                <!-- <svg class="pointer"  @click="singleAdvisor(item)"  width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.9955 19.5396L6.87109 20.1296L7.45998 16.004L15.8611 7.6029C16.3299 7.13406 16.9658 6.87067 17.6289 6.87067C18.2919 6.87067 18.9278 7.13406 19.3966 7.6029C19.8655 8.07175 20.1289 8.70764 20.1289 9.37068C20.1289 10.0337 19.8655 10.6696 19.3966 11.1385L10.9955 19.5396Z" stroke="#4072B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M1 13.5C1 16.8152 2.31696 19.9946 4.66117 22.3388C7.00537 24.683 10.1848 26 13.5 26C16.8152 26 19.9946 24.683 22.3388 22.3388C24.683 19.9946 26 16.8152 26 13.5C26 10.1848 24.683 7.00537 22.3388 4.66117C19.9946 2.31696 16.8152 1 13.5 1C10.1848 1 7.00537 2.31696 4.66117 4.66117C2.31696 7.00537 1 10.1848 1 13.5V13.5Z" stroke="#4072B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                 </svg> -->
                <svg
                  class="ml-2 pointer mt-2"
                  @click="removeItem(item)"
                  width="28"
                  height="26"
                  viewBox="0 0 28 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.35547 13C1.35547 16.1826 2.65524 19.2348 4.96884 21.4853C7.28244 23.7357 10.4204 25 13.6923 25C16.9642 25 20.1021 23.7357 22.4157 21.4853C24.7293 19.2348 26.0291 16.1826 26.0291 13C26.0291 9.8174 24.7293 6.76516 22.4157 4.51472C20.1021 2.26428 16.9642 1 13.6923 1C10.4204 1 7.28244 2.26428 4.96884 4.51472C2.65524 6.76516 1.35547 9.8174 1.35547 13V13Z"
                    stroke="#D00000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.75781 17.8002L18.6273 8.2002"
                    stroke="#D00000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18.6273 17.8002L8.75781 8.2002"
                    stroke="#D00000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </td>
            </tr>
          </tbody>
          <div v-if="allAdvisors.length < 1 && emptySearch">
            <v-col md="12" cols="12">
              <v-alert
                border="bottom"
                colored-border
                type="warning"
                elevation="2"
                class="text-gray"
              >
                Sorry! No results found
              </v-alert>
            </v-col>
          </div>
        </template>
      </v-simple-table>
    </div>

    <v-dialog v-model.trim="dialog" max-width="600px" persistent>
      <v-card>
        <v-col>
          <h3>Remove Advisor</h3>
        </v-col>

        <v-col>
          <span>Are you sure you want to delete this Partner?</span>
        </v-col>

        <v-col>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="blue-buttons float-class" @click="confirmRemove"
              >Confirm</v-btn
            >
            <v-btn class="button-style float-class" @click="closePopUp"
              >Close</v-btn
            >
          </v-card-actions>
        </v-col>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import api from "../../../../../services/api";
export default {
  data() {
    return {
      items: [],
      filteredArray: [],
      dialog: false,
      allAdvisors: [],
      singleItem: null,
      emptySearch: false,
      readyToShow: false,
    };
  },
  computed: {
    getClient() {
      return this.$store.getters.getClientGetter;
    },
    routeParams() {
      return this.$route.params.name;
    },
    filterArray() {
      return this.items;
    },
  },
  created() {
    this.getAllAdvisors();
  },
  methods: {
    formatType(type) {
      return type;
    },
    removeItem(item) {
      this.dialog = true;
      this.singleItem = item;
    },
    getAllAdvisors() {
      this.readyToShow = true;
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.employerAdvisor;
      let employer = JSON.parse(localStorage.getItem("employer"));
      api()
        .get(`/advisors/${advisorId}/employers/${employer._id}/advisors`)
        .then((res) => {
          if (res) {
            this.filteredArray = res.data.advisors;
            this.allAdvisors = res.data.advisors;
            this.$store.commit("setAllPartners", res.data.advisors);
            this.readyToShow = false;
          }
        });
    },
    searchAdvisor(val) {
      this.allAdvisors = this.filteredArray.filter((el) => {
        if (el.companyName.toLowerCase().includes(val.toLowerCase())) {
          return el.companyName.toLowerCase().includes(val.toLowerCase());
        }
      });
      if (this.allAdvisors.length < 1) {
        this.emptySearch = true;
      }
    },
    singleAdvisor(item) {
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.employerAdvisor;
      let employer = JSON.parse(localStorage.getItem("employer"));

      api()
        .get(
          `/advisors/${advisorId}/employers/${employer._id}/advisors/${item._id}`
        )
        .then((res) => {
          if (res) {
            this.$store.commit("singlePartnerDeleteButton", true);
            this.$store.commit(
              "setSingleAdvisorinEmployerAdvisor",
              res.data.advisor
            );
            this.$router.push(
              `/employer-advisor/employers/${this.routeParams}/advisors/advisor`
            );
          }
        });
    },
    closePopUp() {
      this.dialog = false;
    },
    confirmRemove() {
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.employerAdvisor;
      let employer = JSON.parse(localStorage.getItem("employer"));
      this.dialog = false;
      api()
        .delete(
          `/advisors/${advisorId}/employers/${employer._id}/advisors/${this.singleItem._id}`
        )
        .then((res) => {
          if (res) {
            api()
              .get(`/advisors/${advisorId}/employers/${employer._id}/advisors`)
              .then((res) => {
                if (res) {
                  this.allAdvisors = res.data.advisors;
                  this.filteredArray = res.data.advisors;
                  this.$store.commit("setAllPartners", res.data.advisors);
                  this.dialog = false;
                }
              });
          }
        });
    },
  },
};
</script>
<style>
.green-text {
  color: #0fa64b !important;
}
</style>