<template>
  <div class="form-div">
    <v-form class="col-md-8 col-xl-6 col-lg-8" ref="form" v-model.trim="valid">
      <v-col md="12" sm="12" class="text-center col-md-12">
        <h1 class="apaly-color mobile-font">
          Welcome {{ $route.query.firstName }} {{ $route.query.lastName }}
        </h1>
      </v-col>
      <v-col md="12" sm="12" class="text-center col-md-12">
        <h2 class="mobile-font">You can Continue to Log in</h2>
      </v-col>

      <v-col md="12" sm="12" align="start" class="pb-0">
        <label for="" class="tiny-font text-start mobile-14">First Name</label>
        <v-text-field
          solo
          v-model.trim="firstname"
          :rules="requiredRules"
          @keyup="eventHandling($event)"
        ></v-text-field>
      </v-col>
      <v-col md="12" sm="12" align="start" class="pb-0">
        <label for="" class="tiny-font mobile-14">Last Name</label>
        <v-text-field
          solo
          v-model.trim="lastname"
          type="text"
          :rules="requiredRules"
          @keyup="eventHandling($event)"
        ></v-text-field>
      </v-col>
      <v-col md="12" sm="12" align="start" class="pb-0">
        <label for="" class="tiny-font mobile-14"> Email</label>
        <v-text-field
          solo
          v-model.trim="email"
          :rules="emailRules"
          type="email"
          @keyup="eventHandling($event)"
        ></v-text-field>
      </v-col>
      <v-col md="12" sm="12" align="start" class="pb-0">
        <label for="" class="tiny-font mobile-14">Password</label>
        <v-text-field
          solo
          v-model.trim="password"
          required
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show ? 'text' : 'password'"
          @click:append="show = !show"
          :rules="passRules"
          @input="checkPassword"
          @keyup="eventHandling($event)"
        ></v-text-field>
      </v-col>
      <v-col md="12" sm="12" align="start" class="display pt-0 pb-2">
        <span
          v-if="check1"
          for=""
          class="tiny-font d-flex valid-text mobile-14"
        >
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 16.9L6 12.9L7.4 11.5L10 14.1L16.6 7.5L18 8.9L10 16.9Z"
              fill="#238912"
            />
          </svg>
          At least 8 characters
        </span>
        <span v-else for="" class="tiny-font d-flex text-gray mobile-14">
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.2 18.5L6 17.3L10.8 12.5L6 7.7L7.2 6.5L12 11.3L16.8 6.5L18 7.7L13.2 12.5L18 17.3L16.8 18.5L12 13.7L7.2 18.5Z"
              fill="#837C7C"
            />
          </svg>
          At least 8 characters
        </span>
        <span
          v-if="check2"
          for=""
          class="tiny-font d-flex valid-text mobile-14"
        >
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 16.9L6 12.9L7.4 11.5L10 14.1L16.6 7.5L18 8.9L10 16.9Z"
              fill="#238912"
            />
          </svg>
          Contain at least one uppercase letter
        </span>
        <span v-else for="" class="tiny-font d-flex text-gray mobile-14">
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.2 18.5L6 17.3L10.8 12.5L6 7.7L7.2 6.5L12 11.3L16.8 6.5L18 7.7L13.2 12.5L18 17.3L16.8 18.5L12 13.7L7.2 18.5Z"
              fill="#837C7C"
            />
          </svg>
          Contain at least one uppercase letter
        </span>
        <span
          v-if="check3"
          for=""
          class="tiny-font d-flex valid-text mobile-14"
        >
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 16.9L6 12.9L7.4 11.5L10 14.1L16.6 7.5L18 8.9L10 16.9Z"
              fill="#238912"
            />
          </svg>
          Contain at least one special character (example: $, #, !, @, etc.)
        </span>
        <span v-else for="" class="tiny-font d-flex text-gray mobile-14">
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.2 18.5L6 17.3L10.8 12.5L6 7.7L7.2 6.5L12 11.3L16.8 6.5L18 7.7L13.2 12.5L18 17.3L16.8 18.5L12 13.7L7.2 18.5Z"
              fill="#837C7C"
            />
          </svg>
          Contain at least one special character (example: $, #, !, @, etc.)
        </span>
      </v-col>
      <v-col md="12" sm="12" align="start" class="pb-0">
        <label for="" class="tiny-font mobile-14">Confirm Password</label>
        <v-text-field
          solo
          v-model.trim="confirmpassword"
          required
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show ? 'text' : 'password'"
          @click:append="show = !show"
          :rules="passRules"
          @input="confirmPass"
          @keyup="eventHandling($event)"
        ></v-text-field>
      </v-col>
      <v-col v-if="error.length > 0" class="mt-3 mb-3 col-md-12">
        <v-alert dense outlined type="error">
          {{ error }}
        </v-alert>
      </v-col>
      <div>
        <div class="font text-gray mt-0 d-flex">
          <v-checkbox v-model="checkbox"></v-checkbox>
          <span class="mt-5 font-14 mobile-14" align="start">
            I accept the terms of
            <a class="text-underline" @click="openPdfModal()"
              >Apaly Health Platforms Access Agreement
            </a>
          </span>
        </div>
      </div>

      <v-col align="end">
        <v-btn
          depressed
          class="button-blue login col-md-4 col-lg-4 col-xl-3"
          @click="submit"
        >
          Login
        </v-btn>
      </v-col>
    </v-form>

    <v-dialog v-model.trim="dialog" width="500">
      <v-card>
        <v-card-title>
          <span class="">Change Password</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="changePassForm" v-model.trim="validPass" class="row">
            <v-col cols="12" sm="12" md="12">
              Please insert your email in order to receive the link to reset the
              password
              <v-text-field
                solo
                :rules="emailRules"
                v-model.trim="changePassEmail"
                label="Email"
                required
                class="mt-2"
              ></v-text-field>
            </v-col>
            <v-col v-if="passsError.length > 0" class="col-md-12">
              <v-alert dense outlined type="error">
                {{ passsError }}
              </v-alert>
            </v-col>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="changePass"> Submit </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <errorModal 
    ref="error_modal"
     v-on:confirm="closeErrorModal">
    </errorModal>
  </div>
</template>
  <script>
import api from "../../services/api";
import Vue from "vue";
  import errorModal from "../TablesTemplate/ErrorDialog.vue";
import { mapActions } from "vuex";
export default {
    components : { errorModal },
  data() {
    return {
      checkbox: false,
      requiredRules: [(v) => !!v || "This field is required"],
      firstname: "",
      lastname: "",
      confirmpassword: "",
      confirmed: false,
      check1: false,
      check2: false,
      check3: false,
      contains_uppercase: false,
      contains_special_character: false,
      email: "",
      changePassEmail: "",
      password: "",
      valid: false,
      validPass: "",
      show: false,
      error: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
      passRules: [(v) => !!v || "Password is required"],
      dialog: false,
      dialog1: false,
      passsError: "",
      error: "",
      advisorDialog: false,
      // rules: [v => v.length <= 5 || 'Max 5 characters'],
    };
  },
  created() {
    console.log("route", this.$route.query);
    this.firstname = this.$route.query.firstName;
    this.lastname = this.$route.query.lastName;
    this.email = this.$route.query.email;
  },
  methods: {
    ...mapActions(["loginUser"]),
    eventHandling(event) {
      if (event.keyCode == 13) {
        this.submit();
      }
    },
    checkPassword() {
      if (this.password.length >= 8) {
        this.check1 = true;
      } else {
        this.check1 = false;
      }
      this.contains_uppercase = /[A-Z]/.test(this.password);

      if (this.contains_uppercase == true) {
        this.check2 = true;
      } else {
        this.check2 = false;
      }
      const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
      this.contains_special_character = format.test(this.password);
      if (this.contains_special_character == true) {
        this.check3 = true;
      } else {
        this.check3 = false;
      }
    },
    confirmPass() {
      if (this.confirmpassword == this.password) {
        this.confirmed = true;
      } else {
        this.confirmed = false;
      }
    },
    submit() {
      let userInvited = {
        firstName: this.firstname,
        lastName: this.lastname,
        password: this.password,
        token: this.$route.query.token,
      };
      api()
        .post("/auth/confirm/invite", userInvited)
        .catch((err) => {
          if (err) {
            this.$refs.error_modal.openPopUp(err.response.data.message);
          }
        })
        .then((res) => {
          if (res) {
            console.log('resdataa',res.data)
            this.$refs.form.validate();
            if (this.valid) {
                let user ={
                    email: res.data.email,
                    password: this.password
                }
              api()
              .post("/auth/login", user)
                .catch((err) => {
                  if (err) {
                    this.$refs.error_modal.openPopUp(err.response.data.message);
                  }
                })
                .then((res) => {
                  if (res) {
                    console.log("res",res.data);
                    this.$cookies.set("token", res.data.accessToken);
                   localStorage.setItem("user", JSON.stringify(res.data));
                    if(res.data.superAdmin == true){
                this.$router.replace("/superadmin")
              }
              else  if (res.data.type == "employerAdvisor") {
                  this.$router.replace("/employer-advisor/home");
                } else if (res.data.type == "providerAdvisor") {
                  this.$router.replace("/provider-advisor/home");
                } else if (res.data.type == "employer") {
                  this.$router.replace("/employer/home");
                } else if (res.data.type == "provider") {
                  
                  api()
                    .get(`/providers/${res.data.groupPortal[0].organization.provider}`)
                    .then((res) => {
                      if(res.data.provider && res.data.provider.providerCategory && res.data.provider.providerCategory == 'DPC'){
                        localStorage.setItem("type",'DPC');
                        this.$router.replace("/dpc-providers/home");
                      }else{
                        this.$router.replace("/provider/home");
                      }
                    });
                } else if (res.data.type == "member") {
                  this.$router.replace("/member/home");
                } else if (res.data.type == "vendor") {
                    console.log('here to vendor')
                   this.$router.push("/vendors/home");
                } else if (res.data.type == "tpa") {
                  //cojita8699@hostovz.com
                  this.$router.replace("/tpa/home");
                }
                  }
                });
            }
          }
        });
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    changePass() {
      this.passsError = "";

      this.error = "";
      this.$refs.changePassForm.validate();

      if (this.validPass) {
        let email = {
          email: this.changePassEmail,
        };
        api()
          .get(
            `https://validate.consislab.net/check/v4/${this.changePassEmail}`
          )
          .catch((err) => {
            this.passsError = "Please Try Again";
          })
          .then((res) => {
            this.dialog = false;
            if (res.data.Response.result == "VALID") {
              api()
                .post(`/auth/forgotPassword`, email)
                .catch((err) => {
                  this.passsError = "Please Try Again";
                })
                .then((res) => {
                  if (res) {
                    this.dialog1 = true;
                  } else {
                    this.passsError = "Please Try Again";
                  }
                  this.$refs.changePassForm.reset();
                });
            }

            this.$refs.form.reset();
          });
      }
    },
  },
};
</script>
  <style scoped>
.apaly-color {
  color: #16407a;
}
@media only screen and (max-width: 1264px) {
  .mobile-font {
    font-size: 20px !important;
  }
}
</style>
  