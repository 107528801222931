<template>
  <v-dialog v-model.trim="dialog" max-width="850" class="success-modal">
    <v-card class="d-none d-md-block d-lg-block d-xl-block">
      <v-card-text id="element">
        <div
          style="
            position: fixed;
            margin-bottom: 20px;
            background-color: white;
            width: 800px;
            text-align: end;
            text-align: justify;
            color: black !important;
          "
        >
          <span style="float: right">
            <i @click="dialog = false" class="fa fa-times pointer"></i>
          </span>
        </div>
        <div
          style="
            padding-left: 100px;
            padding-right: 100px;
            text-align: justify;
            color: black !important;
          "
        >
          <div style="page-break-after: always">
            <br />
            <h3
              class="text-center font-weight-bold"
              style="text-align: center; justify-content: center"
            >
              DEFINITIVE DIGITAL DIRECT
            </h3>
            <h3
              class="text-center font-weight-bold"
              style="text-align: center; justify-content: center"
            >
              HEALTHCARE SERVICES AGREEMENT
            </h3>
            <br />
            <p>
              This Definitive Digital Direct Healthcare Services Agreement (the
              “Agreement”) serves as the standardized, accepted agreement
              between those parties who deliver or access products and services
              through a digital direct healthcare technology environment, upon
              which the Agreement is executed. Participation within a Digital
              Direct Healthcare technology environment, requires and is
              predicated upon acceptance of this Agreement. Therefore, the
              parties who intend to execute this Agreement are encouraged to
              fully review it in its entirety to determine any implications that
              it may have on the party both personally and from a business
              perspective, and thus make the determination if participation in
              the Digital Direct Healthcare technology environment is right for
              them.
            </p>
          </div>

          <div class="html2pdf__page-break" />
          <br /><br /><br />
          <div>
            <h4
              class="text-center font-weight-bold"
              style="
                text-align: center;
                justify-content: center;
                text-decoration: underline;
              "
            >
              DEFINITIVE DIGITAL DIRECT HEALTHCARE SERVICES AGREEMENT
            </h4>
            <br />
            <p>
              <b class="ml-5" style="margin-left: 30px"
                >THIS DEFINITIVE DIRECT HEALTHCARE SERVICES AGREEMENT (the
                “Agreement”)
              </b>
              is a binding legal contract by and between the Provider of
              healthcare products and services named below that has submitted
              rates on the Apaly Health Inc. ( <b>“Apaly”</b> ) Platform (the
              <b>“Platform”</b>) and clicked <b>“ACCEPT & CONTRACT”</b> to
              deliver Covered Services to Participants (“Provider”), and the
              consumer of healthcare products and services named below that
              submitted rates on the Platform to pay for Covered Services that
              are delivered to Participants and clicked
              <b>“ACCEPT & CONTRACT”</b> ( <b>“Payer”</b>), and is effective as
              of the date that such Provider and Payer have both clicked
              <b></b>“ACCEPT AND CONTRACT” (the <b>“Effective Date”</b>).
              Provider and Payer are sometimes collectively referred to herein
              as the “Parties” and individually as a “Party.”
              <b>Capitalized terms</b> herein shall have the meanings specified
              in Article 1 – Definitions of this Agreement.
            </p>
            <h4
              style="
                font-weight: bold;
                text-decoration: underline;
                margin: 10px 0px 10px 0px;
              "
              class="text-decoration"
            >
              RECITALS:
            </h4>
            <p>
              <b class="ml-5" style="margin-left: 30px">WHEREAS,</b> the
              Platform is a software solution used by Provider and Payer to
              facilitate: (a) direct contractual relationships; (b) medical
              Claim repricing and transmission between Provider and Payer; (c)
              Participant’s access to Provider’s services; (d) the reimbursement
              for Covered Services; and, (e) other functions to support the
              delivery of products and services directly between the Parties.
            </p>
            <p>
              <b class="ml-5" style="margin-left: 30px">WHEREAS,</b> Payer
              desires for Provider to become a Participating Provider, and
              Provider desires to participate with Payer on the Platform, on a
              nonexclusive basis, in accordance with the terms of this
              Agreement; and,
            </p>
            <p>
              <b class="ml-5" style="margin-left: 30px">WHEREAS,</b> The Parties
              acknowledge that Apaly is not a payer of any reimbursement under
              this Agreement;
            </p>

            <h4
              style="
                font-weight: bold;
                text-decoration: underline;
                margin: 10px 0px 10px 0px;
              "
            >
              TERMS:
            </h4>
            <p>
              <b class="ml-5" style="margin-left: 30px">NOW THEREFORE,</b> in
              consideration of the mutual covenants set forth below and valid
              consideration, the receipt and sufficiency of which is hereby
              acknowledged, the Parties, intending to be legally bound by this
              Agreement, hereby agree as follows.
            </p>
            <br />
          </div>
          <div class="html2pdf__page-break" />
          <br /><br /><br /><br /><br /><br /><br />
          <div>
            <h4
              class="text-center mt-2 font-weight-bold"
              style="text-align: center; justify-content: center"
            >
              ARTICLE 1
            </h4>
            <h4
              class="text-center mt-2 font-weight-bold"
              style="text-align: center; justify-content: center"
            >
              DEFINITIONS
            </h4>
            <br />
            <p>
              The following terms shall have the meanings as ascribed to them or
              referenced below (such terms shall be equally applicable to both
              the singular and plural forms of the terms defined):
            </p>
            <p>
              <b class="ml-5" style="margin-left: 30px">“Agreement”</b> shall
              mean this Definitive Digital Direct Healthcare Services Agreement,
              including any Compensation or Service addenda and any other
              addenda, amendments, exhibits, supplements and modifications
              hereto or thereto from time to time that are mutually agreed to
              and accepted by the Parties.
            </p>
            <p>
              <b class="ml-5" style="margin-left: 30px">“Applicable Law”</b>
              shall mean all applicable federal, state and local laws, rules and
              regulations, now or hereafter in force and effect, governing the
              provision of Provider Services or otherwise relating to the
              Agreement or governing Payer or Provider in connection with the
              Agreement including, without limitation, HIPAA.
            </p>
            <p>
              <b class="ml-5" style="margin-left: 30px"
                >“Benefit Program” or “Benefit Plan” or “Plan”</b
              >
              means the benefits underwritten by Payer under a federal ERISA
              employer health plan.
            </p>
            <p>
              <b class="ml-5" style="margin-left: 30px">“Claim”</b> means a
              request for reimbursement for Covered Services rendered to an
              eligible Participant, at a Provider location and submitted by
              Provider using one or more of the Tax Identification Numbers
              listed in Exhibit A of this Agreement and submitted to Payer with
              a prior authorization number issued on the Visit Pass.
            </p>
            <p>
              <b class="ml-5" style="margin-left: 30px">“Clinic(s)” </b>
              means Provider’s owned or leased location(s) where Participating
              Provider will deliver in-person Covered Services.
            </p>
            <p>
              <b class="ml-5" style="margin-left: 30px">“Covered Services”</b>
              means those healthcare products and services rendered to a
              Participant for which Payer has agreed to pay as evidenced by a
              Visit Pass with a prior authorization number that was issued
              through the Platform and as may be further defined within any
              addendum or exhibit to this Agreement.
            </p>
            <p>
              <b class="ml-5" style="margin-left: 30px">“ERISA”</b> means the
              Employee Retirement Income Security Act of 1974, as amended, and
              its implementing regulations.
            </p>
            <p>
              <b class="ml-5" style="margin-left: 30px"
                >“Facility-Based Providers”
              </b>
              means those Provider organizations, individual physicians, or
              allied health professionals, whether directly employed or
              contracted, who primarily deliver healthcare services within a
              facility. This includes but is not limited to: anesthesia,
              emergency, pathology, radiology, hospitalist, or any specialty
              whereby the facility has engaged the Provider under an exclusive
              arrangement.
            </p>
            <p>
              <b class="ml-5" style="margin-left: 30px">“HIPAA”</b> means the
              Health Insurance Portability and Accountability Act of 1996 and
              its implementing regulations
            </p>
            <p>
              <b class="ml-5" style="margin-left: 30px"
                >"Non-Covered Services”</b
              >
              means those medical and hospital services that do not qualify as
              Covered Services.
            </p>
            <p>
              <b class="ml-5" style="margin-left: 30px"
                >“Non-Participating Provider”</b
              >
              means a Provider that has not entered into this Agreement or
              entered rates on the Platform for the purpose of providing Covered
              Services to Participants.
            </p>
            <p>
              <b class="ml-5" style="margin-left: 30px">“Participant”</b>” means
              an eligible participant or beneficiary (as those terms are defined
              by ERISA) in a Benefit Program who has selected a Participating
              Provider to provide the Covered Services.
            </p>
            <p>
              <b class="ml-5" style="margin-left: 30px"
                >“Participating Provider”</b
              >means a Provider or employee or independent contractor of
              Provider that has entered into this Agreement and entered rates on
              the Platform for the purpose of providing Covered Services to
              Participants.
            </p>
            <p>
              <b class="ml-5" style="margin-left: 30px">“Payer”</b>
              shall have the meaning set forth in the introductory paragraph,
              which is expressly incorporated herein.
            </p>
            <p>
              <b class="ml-5" style="margin-left: 30px">“Provider”</b>
              shall have the meaning set forth in the introductory paragraph,
              which is expressly incorporated herein.
            </p>
            <p>
              <b class="ml-5" style="margin-left: 30px"
                >“Provider Affiliates”</b
              >
              means a duly licensed medical professional that Provider employs
              or contracts to deliver care to Participants.
            </p>
            <p>
              <b class="ml-5" style="margin-left: 30px">“Provider Services”</b>
              means those medical services customarily performed by Provider and
              within the scope of Provider’s license and accreditation.
            </p>
            <p>
              <b class="ml-5" style="margin-left: 30px">“Visit Pass”</b> means a
              digitally generated verification on the Platform that an
              individual is a Participant and that the services being requested
              are Covered Services. The Visit Pass shall include such
              information as: (a) Payer name and identifier (b) relevant phone
              numbers for customer or Provider service; (c) relevant identifying
              numbers such as Participant number, employer group number and
              Benefit Program number; (d) a prior authorization number that
              shall be submitted with Participating Providers Claim; and, (e)
              claims address and TPA information.
            </p>
            <br /><br />
          </div>
          <br />
          <div>
            <h4
              class="text-center mt-2 font-weight-bold"
              style="text-align: center; justify-content: center"
            >
              ARTICLE 2
            </h4>
            <h4
              class="text-center mt-2 font-weight-bold"
              style="text-align: center; justify-content: center"
            >
              OBLIGATIONS OF THE PARTIES
            </h4>
            <br /><br /><br />
            <p style="margin: 10px 0px 10px 0px" class="">
              <b style="text-decoration: underline">Obligations of Payer :</b>
              Payer shall comply with the following conditions and obligations:
            </p>
            <p>
              <span style="text-decoration: underline"
                >Participant Identification.</span
              >
              Through the Platform, Payer will furnish a Visit Pass to
              Participant and to Provider as a means to identify Participant and
              support Provider in creating and submitting Claims for
              reimbursement from Payer.
            </p>
            <p></p>
            <p>
              <span style="text-decoration: underline"
                >Provider Identification.</span
              >
              Payer will reference the Platform for listings of Participating
              Providers and may provide financial incentives to Participants to
              utilize Participating Providers through Benefit Program design
              subject to and in accordance with Applicable Law and other
              contractual obligations if applicable.
            </p>
            <p>
              <span style="text-decoration: underline"
                >Payment for Covered Services.</span
              >
              Subject to the terms of Payer’s health plan requirements, Payer
              will reimburse Provider for Covered Services rendered to eligible
              Participants pursuant to the Compensation Addendum attached as
              Exhibit A hereto (the <b>“Compensation Addendum”</b> ) in effect
              between the Parties at the time that services were rendered. For
              hospital inpatient services, the Compensation Addendum in effect
              at the time of admission shall govern compensation for all
              services provided until discharge.
            </p>
            <p>
              <span style="text-decoration: underline">Legal Obligations.</span>
              Provider acknowledges that Payer cannot enter into any transaction
              pursuant to this Agreement that is a prohibited transaction under
              ERISA or Applicable Law.
            </p>
            <p style="margin: 10px 0px 10px 0px" class="">
              <b style="text-decoration: underline">Obligations of Provider:</b>
              Provider shall comply with the following conditions and
              obligations
            </p>
            <p>
              <span style="text-decoration: underline">Services Provided.</span>
              As a Participating Provider on the Platform, Provider agrees to
              provide to Participants, those Covered Services that Provider is
              qualified to provide and customarily provides within the scope of
              Provider’s qualifications, license and certification, which
              include the services provided by physicians and allied health
              professionals that are contracted or employed by Provider and
              billed under Provider’s TIN at those locations that are identified
              by Provider on the Platform, and as may be more specifically
              identified in any addendum or exhibit to this Agreement as
              applicable
            </p>

            <p>
              <span style="text-decoration: underline"
                >Provider as a Group Practice.</span
              >
              Where Participating Provider is, owns, or operates a group
              physician practice, Participating Provider shall use commercially
              reasonable efforts to maintain on the Platform a true, complete,
              and accurate roster of Participating Provider’s physicians, allied
              health professionals, and locations where Covered Services are
              rendered under this Agreement.
            </p>

            <p>
              <span style="text-decoration: underline">
                Health Care Delivery.</span
              >
              Provider acknowledges that this Agreement, Payer, and the Benefits
              Program do not dictate the health care services that are provided
              by Provider or govern a physician’s or hospital’s determination of
              what care to provide its patients, even if those patients are
              Participants. The decision regarding what care is to be provided
              remains with Participants and their Providers, and not with Payer.
            </p>
            <p>
              <span style="text-decoration: underline"
                >Facility-Based Providers.</span
              >
              Provider will use commercially reasonable efforts to compel all
              Facility-Based Providers, to be Participating Providers on the
              Platform. In the event a Facility-Based Provider is a
              Non-Participating Provider on the Platform, then Provider shall
              identify these Facility-Based Non-Participating Providers on the
              Platform.
            </p>
            <p>
              <span style="text-decoration: underline">
                Discrimination Prohibited.</span
              >
              Participating Provider shall provide Provider Services in the same
              manner and in accordance with the same standards offered to all
              other patients with the same medical diagnosis or condition,
              regardless of source of payment or coverage. Participating
              Provider, in accordance with the provisions, spirit and intent of
              this Agreement, shall not differentiate or discriminate in the
              treatment of Participants or in the quality of services rendered
              to Participants based on race, creed, color, national origin, sex,
              age, religion, sexual orientation, gender identity, veteran
              status, handicap, place of residence, health status, source of
              payment, credit history, or any other ground prohibited by
              Applicable Law.
            </p>

            <p>
              <span style="text-decoration: underline">
                On-Platform Referrals.</span
              >
              To the extent applicable, Participating Provider shall use
              commercially reasonable efforts to facilitate the referral of
              Participants to and/or use Participating Providers for the
              provision of Covered Services to Participants, except: (a) if no
              Participating Provider is available to provide the Covered
              Services within a reasonable time and distance, or (b) the
              Participant intentionally chooses to use an alternate Provider
              whom the Participant knows is not a Participating Provider.
            </p>
            <p>
              <span style="text-decoration: underline">
                Licenses, Certifications, Hospital Privileges.</span
              >
              Participating Provider will ensure that all of Provider’s employed
              or contracted physicians or allied healthcare professionals who
              are utilized by Participating Provider to deliver Covered Services
              to Participants under this Agreement have, and shall maintain,
              throughout the term of this Agreement, all appropriate and
              unrestricted medical licenses, certifications and hospital
              privileges (if applicable), as appropriate for his or her
              professional discipline. In the event that any of Participating
              Provider’s physicians or allied healthcare professionals who are
              utilized by Provider to deliver Covered Services to Participants
              loses the unrestricted medical license, required certification, or
              hospital privilege (if applicable), then Participating Provider
              will immediately remove the physician or allied healthcare
              professional from the Platform. Apaly and the Platform do not
              provide credentialing services and make no representation or
              warrant regarding the qualifications of any Participating Provider
              and any of Participating Provider’s physicians or allied
              healthcare professionals, and has no obligation to verify the
              qualifications or credentialing status of any Participating
              Provider (including any material uploaded or presented by any
              Provider to the Platform).
            </p>
            <p>
              <span style="text-decoration: underline">Quality Assurance.</span>
              The Plan, in consultation with and upon joint approval from the
              Participating Provider, may implement a program of utilization,
              quality assurance, and peer review based upon standards
              established by Federal and State law or other plan administration
              best practices, the purpose of which is to promote adherence to
              accepted medical treatment standards. Participating Provider
              agrees to participate in, cooperate with, and comply with the
              decisions, rules and regulations established by the Plan’s
              utilization management program, including but not limited to, the
              use of the Visit Pass with a valid prior-authorization number as
              precertification for elective admissions and procedures, on
              Platform referral processes, and reporting of clinical encounter
              data.
            </p>
            <p>
              <span style="text-decoration: underline"
                >Medical Records, Retention, Access to Records, and Copies.
              </span>
              To the extent applicable, Participating Provider shall maintain a
              complete and accurate permanent medical record for each
              Participant to whom Participating Provider renders Covered
              Services under this Agreement in accordance with usual and
              customary practices and as required by Applicable Law.
            </p>
            <p>
              <span style="text-decoration: underline"
                >Use of Names for Platform.</span
              >
              Participating Provider agrees to allow Payer to list on the
              Platform, Participating Provider’s name, logo, business address,
              business phone number(s), available services, an indication of
              Participating Provider’s willingness to accept additional
              Participants, and other pertinent Participating Provider
              information, among Payer’s listing of Participating Providers, for
              the sole purpose of having beneficiaries be able to locate a
              provider inside the Platform. Platform will seek approval from
              Participating Provider and/or Payer before using name and logo for
              any marketing related purposes. Provider agrees to provide updated
              information to Payer regarding the above as may from time to time
              be necessary, and Participating Provider shall promptly notify
              Payer of any change in the information pertinent to this section.
              Provider may disclose the fact of its participation status with
              Payer in its communications with third parties and in its
              participating payer listings.
            </p>
            <p>
              <span style="text-decoration: underline"
                >Acceptance of Participants.</span
              >
              If applicable, Participating Provider shall notify Payer in
              writing through the Platform at least sixty (60) days prior to the
              date on which Participating Provider will not accept new
              Participants. Notwithstanding the foregoing, Participating
              Provider agrees not to close its practice to new Participants,
              unless Participating Provider has closed its practice to all new
              patients
            </p>
            <p>
              <span style="text-decoration: underline"
                >Provider Insurance.</span
              >
              Throughout the term of this Agreement, Participating Provider
              shall maintain professional and general liability insurance
              coverage to protect against all allegations arising out of the
              rendering of professional services or the alleged failure to
              render professional services by Participating Provider and its
              employees. For purposes of this Section, “professional services”
              shall include but is not limited to Covered Services. Such
              liability coverage shall be in the amounts consistent with
              Participating Provider type, industry standard and as may be
              required by Applicable Law.
            </p>
          </div>
          <br /><br />
          <div>
            <h4
              class="text-center mt-2 font-weight-bold"
              style="text-align: center; justify-content: center"
            >
              ARTICLE 3
            </h4>
            <h4
              class="text-center mt-2 font-weight-bold"
              style="text-align: center; justify-content: center"
            >
              COMPENSATION, CLAIMS SUBMISSION, AND PAYMENT
            </h4>
            <br /><br />
            <p>
              <span style="text-decoration: underline">Compensation.</span>
              Participating Provider shall accept, as payment in full for
              Covered Services rendered to a Participant, the amount determined
              in accordance with terms and conditions set forth herein as
              defined in the Compensation Addendum. Participating Provider
              acknowledges and agrees to look solely to Payer for payment of
              Covered Services provided to Participants.
            </p>
            <p>
              <span style="text-decoration: underline"
                >Eligibility and Coverage Determinations.</span
              >
              Provider shall verify through the Platform if an individual is a
              Participant and if the service being requested is a Covered
              Service that is reimbursable by Payer.
            </p>
            <p>
              <span style="text-decoration: underline">Claim Submission. </span>
              Participating Provider shall submit Claims for all Covered
              Services rendered to a Participant by submitting an appropriate
              Claim within one hundred twenty (120) days from the date the
              services are rendered for outpatient services or date of discharge
              for inpatient services, or as described otherwise in an addendum
              to this Agreement.
            </p>
            <p>
              <span style="text-decoration: underline">Claim Payment.</span>
              Payer and Participating Provider agree that Payer will use
              reasonable efforts to process all Claims for Covered Services
              within thirty (30) days from the date of receipt. However, a
              failure to do so shall not constitute a material breach of this
              Agreement provided that Payer processes such Claim within
              timeframes required by Applicable Law.
            </p>
            <p>
              <span style="text-decoration: underline"
                >Participant Hold Harmless.</span
              >
              Participants shall not be liable for payments due to Participating
              Provider’s failure to submit Claims within the time specified
              above or for any Claims denied by Payer for any reason other than
              Participant’s eligibility or that the services were not Covered
              Services under the Benefit Program.
            </p>
            <p>
              <span style="text-decoration: underline"
                >Referral Disclaimer.</span
              >
              Payments made pursuant to this Agreement shall not be determined
              in any manner by the volume or value of any potential referrals
              between the Parties. No amount paid pursuant to this Agreement is
              intended to be, nor shall it be construed to be, an inducement or
              payment for referral of patients by Payer to Participating
              Provider or by Participating Provider to Payer. Both Parties shall
              comply with all Applicable Laws regarding fraud and abuse.
            </p>
            <p>
              <span style="text-decoration: underline"
                >No Federal Health Care Programs.</span
              >
              It is expressly understood and acknowledged that no Medicare,
              Medicaid or other Federal or State Health Care Program funds will
              be utilized by the Parties under this Agreement.
            </p>
            <p>
              <span style="text-decoration: underline"
                >Limitation of Liability.</span
              >
              Regardless of whether there is a total and fundamental breach of
              this Agreement, or whether any remedy provided in this Agreement
              fails of its essential purpose, in no event shall either of the
              Parties hereto be liable for any amounts representing loss of
              revenues, loss of profits, loss of business, the multiple portion
              of any multiplied damage award, or incidental, indirect,
              consequential, special or punitive damages, whether arising in
              contract, tort (including negligence), or otherwise; regardless of
              whether the Parties have been advised of the possibility of such
              damages arising in any way out of or relating to this Agreement.
              The foregoing limitations do not affect any liability arising from
              willful misconduct, gross negligence, violation of Applicable Law
              or breaches of an obligation of non-disclosure or confidentiality
              as well as any liability which cannot be excluded or limited under
              Applicable Law.
            </p>
          </div>
          <br /><br />
          <div>
            <h4
              class="text-center mt-2 font-weight-bold"
              style="text-align: center; justify-content: center"
            >
              ARTICLE 4
            </h4>
            <h4
              class="text-center mt-2 font-weight-bold"
              style="text-align: center; justify-content: center"
            >
              DISPUTE RESOLUTION
            </h4>
            <br /><br />
            <p>
              If any dispute or controversy arises among the Parties with
              respect to the terms or interpretation of this Agreement, or the
              rights of any Party hereunder, or with respect to any transaction
              involved hereunder, <b>the Parties shall</b> attempt in good faith
              to negotiate a settlement, within thirty (30) working days of
              either Party notifying the other. If negotiation between the
              Parties is unable to resolve such dispute, and if any Party wishes
              to pursue the dispute, such dispute shall be submitted to binding
              arbitration in accordance with the commercial rules of the
              American Health Lawyers Association, the American Arbitration
              Association, or any other comparable nationally recognized
              alternative dispute resolution association acceptable to the
              Parties. Such arbitration proceeding shall be conducted in the
              location of the non-aggrieved Party’s choice. The arbitrators
              shall have no authority to award any punitive or exemplary
              damages, or to vary or ignore the terms of the Agreement and shall
              be bound by controlling law. The Parties agree that the
              arbitrator’s award shall be final, binding, non-appealable, and
              may be confirmed and entered as a judgment in any court of
              competent jurisdiction. This Section shall survive the termination
              of this Agreement.
            </p>
          </div>
          <br /><br />
          <div>
            <h4
              class="text-center mt-2 font-weight-bold"
              style="text-align: center; justify-content: center"
            >
              ARTICLE 5
            </h4>
            <h4
              class="text-center mt-2 font-weight-bold"
              style="text-align: center; justify-content: center"
            >
              TERM AND TERMINATION
            </h4>
            <br /><br />
            <p>
              <span style="text-decoration: underline">Term.</span>
              The initial term of this agreement shall be twelve (12) months
              (the “Initial Term”). At the end of the Initial Term, this
              Agreement shall automatically renew for successive periods of
              twelve (12) months (each a “Renewal Term”). During the Initial
              Term and each subsequent Renewal Term, this Agreement shall remain
              in effect between Payer and Provider with each other as long as:
              (a) Payer and Provider both participate on the Platform; (b)
              Provider’s reimbursement rate terms on the Platform remain
              consistent with those rates contained herein; (c) either party has
              not specifically excluded the other Party from this Agreement on
              the Platform, or (d) this Agreement is not specifically terminated
              as to a Payer or a Provider as set forth below. Both parties agree
              to maintain their respective reimbursement rate terms on the
              Platform under this Agreement and the Compensation Addendum for
              the duration of the twelve (12) month Initial Term and the
              duration of each subsequent twelve (12) month Renewal Term unless
              an Amendment is created as provided for in this Article 5.
            </p>
            <div>
              <span style="text-decoration: underline">Termination.</span> This
              Agreement may be terminated under any of the following
              circumstances: <br /><br />
              <p>
                i) by mutual written agreement of the parties;<br /><br />
                ii) by Payer, upon 90 days’ prior written notice, in the event
                that Provider fails to meet Payer’s minimum care standards as
                determined by Payer in its sole discretion and communicated to
                Provider; however, the termination will not take effect if
                within 30 days of Payer’s notice, Provider develops a plan that
                is presented to and accepted by Payer to resolve the minimum
                care standard issue and the implemented plan actually resolves
                the issue.<br /><br />
                iii) by either party, upon at least 90 days’ prior written
                notice;<br /><br />
                iv) by either party, upon 60 days’ prior written notice, in the
                event of a material breach of this Agreement by the other party,
                which notice will include a specific description of the alleged
                breach; however, the termination will not take effect if the
                breach is cured within 60 days after notice of the
                termination;<br /><br />
                v) by either party, upon 10 days’ prior written notice, in the
                event the other party loses licensure or other governmental
                authorization necessary to perform this Agreement, or fails to
                have insurance as required under Article 2 of this Agreement;<br /><br />
                vi) Payer determines in good faith that Provider’s continued
                provision of services to Participants may result in, or is
                resulting in, danger to the health, safety, or welfare of
                Participants. Where the danger results from the actions of
                Provider’s staff, contractors, or subcontractors, then Provider
                shall suspend the relationship of such staff, contractors,
                subcontractors upon immediate notice from Payer, at least with
                respect to Participants, and if Provider fails to take such
                action, Payer may terminate the Agreement upon ten (10) days’
                written to Provider;<br /><br />
                vii) by Payer, upon 10 days’ prior written notice, in the event
                Provider loses accreditation to provide services to patients:
                or<br /><br />
              </p>
              <p>
                <span style="text-decoration: underline"
                  >Termination for Insolvency.</span
                >
                Provider or Payer may terminate this Agreement immediately upon
                notice to the other Party in the event of: (a) the filing of a
                petition for relief under bankruptcy law by or against the other
                Party; or (b) any liquidation, rehabilitation, conservation, or
                similar proceeding, or upon any other fiscal insolvency of
                Provider or Payer.
              </p>
              <p>
                <span style="text-decoration: underline"
                  >Post-Termination Obligations.</span
                >
                Following any termination of this Agreement, other than a
                termination under Article 5: Immediate Termination or
                Termination for Insolvency, Provider and Payer shall continue to
                abide by the terms of this Agreement in effect as of the day
                before the effective date of termination until the earlier of:
                (a) the Participant’s transition of care to a new Provider; (b)
                the completion of Participant’s course of treatment. For
                pregnant women, course of treatment ends six weeks postpartum.
                For hospital admissions other than obstetrics, the course of
                treatment ends upon discharge; or (c) one hundred and twenty
                (120) days after the termination effective date.
              </p>
              <p>
                <span style="text-decoration: underline"
                  >Participant Notification.</span
                >
                To extent applicable, upon the termination of this Agreement,
                Provider shall cooperate with Payer regarding Participant
                notification of such termination.
              </p>
              <p>
                <span style="text-decoration: underline"
                  >Effect of Termination.
                </span>
                Termination of this Agreement shall have no effect upon the
                rights and obligations of the Parties arising out of any
                transaction occurring prior to the effective date of such
                termination.
              </p>
            </div>
          </div>
          <br /><br />
          <div>
            <h4
              class="text-center mt-2 font-weight-bold"
              style="text-align: center; justify-content: center"
            >
              ARTICLE 6
            </h4>
            <h4
              class="text-center mt-2 font-weight-bold"
              style="text-align: center; justify-content: center"
            >
              MISCELLANEOUS
            </h4>
            <br /><br />
            <p>
              <span style="text-decoration: underline">Notices</span> Any notice
              required to be given pursuant to the terms and provisions of this
              Agreement shall be in writing and may be sent via electronic mail,
              by certified or registered mail return receipt requested, or by
              personal hand delivery, at the addresses set forth on the
              Platform.
              <br />
            </p>
            <p>
              <span style="text-decoration: underline"
                >Independent Contractors.</span
              >
              For purposes of this Agreement, Payer and Provider are independent
              contractors. No agency, joint venture, partnership or
              representation agreement is created by this contract.
            </p>
            <p>
              <span style="text-decoration: underline">Unforeseen Events.</span>
              If either Party’s ability to perform that Party’s obligations
              under this Agreement is substantially interrupted by war, fire,
              pandemics or public health emergencies, insurrections, riots, the
              elements, earthquake, acts of God, or, without limiting the
              foregoing, any other similar cause beyond the control of either
              Party, the Party shall be relieved of the Party’s obligations only
              to those affected portions of this Agreement for the duration of
              such interruption. If such interruption of performance by one
              Party is likely to continue for a substantial period of time, the
              other Party shall have the right to terminate this Agreement upon
              twenty (20) days’ prior written notice.
            </p>
            <p>
              <span style="text-decoration: underline">Non-Exclusivity.</span>
              Nothing in this Agreement shall be construed to be an exclusive
              arrangement, nor shall this Agreement prevent either party from
              entering into similar Agreements or contracts with other entities
              on the Platform or through other mean or prevent Participants from
              choosing other Providers, Plan options, Benefit Programs,
              Providers, or programs.
            </p>
            <p>
              <span style="text-decoration: underline">Assignment.</span>
              This Agreement can only be amended when electronically accepted
              and acknowledged on the Platform by both Parties.
            </p>
            <p>
              <span style="text-decoration: underline">Amendments.</span>
              This Agreement can only be amended when electronically accepted
              and acknowledged on the Platform by both Parties
            </p>
            <p>
              <span style="text-decoration: underline"
                >Invalidity or Unenforceability.</span
              >
              The invalidity or unenforceability of any terms or provisions of
              this Agreement shall in no way affect the validity or
              enforceability of any other term or provision.
            </p>
            <p>
              <span style="text-decoration: underline">Entire Agreement.</span>
              To the extent not preempted by federal law, and without regard to
              principles of conflict of laws, this Agreement will be governed by
              and construed in accordance with the laws of the state in which
              the Provider’s primary business office is located.
            </p>
            <p>
              <span style="text-decoration: underline">Applicable Law.</span>
              This Agreement and all attachments, schedules, exhibits, addenda,
              hereto shall constitute the entire agreement between the Parties
              regarding the subject matter hereof and thereof.
            </p>
            <p>
              <span style="text-decoration: underline"
                >Third Party Beneficiaries.</span
              >
              There are no third-party beneficiaries of this Agreement except
              for Apaly, which is specifically intended to be a third-party
              beneficiary of all terms and conditions of this Agreement that
              could impact the Platform, or the ownership thereof, in any manner
              whatsoever.
            </p>
            <p>
              <span style="text-decoration: underline"
                >Proprietary Information.</span
              >
              The terms of this Agreement and any information and material
              provided by one Party to the other Party in contemplation of or in
              connection with this Agreement, remains proprietary to the
              disclosing party. Neither Payer nor Provider shall disclose any
              information proprietary to the other or use such information or
              material except if permitted or required under Applicable Law or
              with the express written approval of the disclosing party.
            </p>
            <p>
              <span style="text-decoration: underline">Data.</span>
              Payer and Provider agree that managing and transmitting data is
              critical to their ability to perform effectively under this
              Agreement. Provider agrees to provide, including via the Platform,
              all patient, practice, operational, and financial data reasonably
              requested by Payer to administer the Benefit Program. Payer and
              Provider agree to comply with HIPAA standards in relation to the
              transmission and storage of Participant personal and health
              related data.
            </p>
            <p>
              <span style="text-decoration: underline">Acceptance.</span> By
              clicking <b>“ACCEPT & CONTRACT”</b>” on the Platform (a) Payer and
              Provider have agreed to enter into, and be legally bound by, this
              Agreement along with any of the Agreement’s exhibits or addenda
              and that the relationship will be governed by the terms and
              conditions contained in this Agreement and any addenda; (b) the
              individual accepting this Agreement confirms his/her legal
              authority to enter into this Agreement on behalf of his/her
              respective party; and (c) all information provided by the
              individual accepting this Agreement related to Payer and Provider
              in connection with this Agreement is accurate, current and
              complete to the best of the individual’s knowledge.
            </p>
          </div>
          <br /><br /><br />
          <h4 style="justify-content: center; text-align: center !important">
            [ELECTRONIC ACCEPTANCE]
          </h4>
          <div
            style="
              display: flex;
              flex-direction: row;
              margin-top: 30px;
              page-break-after: always;
            "
          >
            <div style="display: flex; flex-direction: column; flex: 1">
              <h3 style="">PAYER</h3>
              <div style="">By:</div>
              <div style="">Name:</div>
              <div style="">Title:</div>
              <div style="">Organization:</div>
            </div>
            <div style="display: flex; flex-direction: column; flex: 1">
              <h3 style="">PROVIDER</h3>
              <div style="">By:</div>
              <div style="">Name:</div>
              <div style="">Title:</div>
              <div style="">Organization:</div>
            </div>
          </div>
          <div>
            <h4
              class="text-center font-weight-bold mt-2"
              style="text-align: center; justify-content: center"
            >
              EXHIBIT A
            </h4>
            <h4
              class="text-center font-weight-bold mt-2"
              style="text-align: center; justify-content: center"
            >
              COMPENSATION ADDENDUM
            </h4>
            <p>
              This is an addendum to the PROVIDER AGREEMENT (<b>“Agreement”</b>
              )) entered into by and between Provider and Payer. Capitalized
              terms used in this Exhibit but not defined herein will carry the
              meanings ascribed to them in the Agreement. This COMPENSATION
              ADDENDUM will become effective as of the Effective Date.
            </p>
            <p>
              <span class="ml-5" style="margin-left: 30px"
                ><b> Payments for Covered Services.</b></span
              >
              Except as otherwise provided herein, Payer shall reimburse
              Provider for Covered Services based on the rates shown below,
              which reference generally accepted terms, services, and published
              rates used by the Centers for Medicare and Medicaid Services
              (<b>“CMS”</b>).
            </p>
          </div>
          <br /><br />
          <div style="margin-top: 30px">
            <h4>Rate Table:</h4>
            <table
              class="pdf-table"
              style="
                border: 1px solid;
                width: 100%;
                border-collapse: collapse;
                padding: 4px;
              "
            >
              <tr style="border: 1px solid">
                <th
                  style="
                    text-align: left;
                    padding: 5px;
                    color: black !important;
                  "
                >
                  Rate Information
                </th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <th
                  style="
                    color: black !important;
                    border: 1px solid;
                    text-align: left;
                    padding: 5px;
                  "
                >
                  Rate Type
                </th>
                <th
                  style="
                    color: black !important;
                    border: 1px solid;
                    text-align: left;
                    padding: 5px;
                  "
                >
                  Description
                </th>
                <th
                  style="
                    color: black !important;
                    border: 1px solid;
                    text-align: left;
                    padding: 5px;
                  "
                >
                  Amount
                </th>
                <th
                  style="
                    color: black !important;
                    border: 1px solid;
                    text-align: left;
                    padding: 5px;
                  "
                >
                  Type
                </th>
                <th
                  style="
                    color: black !important;
                    border: 1px solid;
                    text-align: left;
                    padding: 5px;
                  "
                >
                  Freq
                </th>
              </tr>
              <tr>
                <td
                  style="
                    border: 1px solid;
                    color: black !important;
                    padding: 10px;
                  "
                >
                  Outpatient
                </td>
                <td
                  style="
                    border: 1px solid;
                    color: black !important;
                    padding: 10px;
                  "
                >
                  Outpatient services as defined and widely accepted by CMS,
                  generally characterized by same-day care episodes, except for
                  patient observation.
                </td>
                <td
                  style="
                    border: 1px solid;
                    text-align: center;
                    color: black !important;
                    padding: 10px;
                  "
                >
                  <span v-if="outpatient">{{ outpatient }}</span>
                </td>
                <td
                  style="
                    border: 1px solid;
                    color: black !important;
                    text-align: center;
                    padding: 10px;
                  "
                >
                  % of Medicare
                </td>
                <td
                  style="
                    border: 1px solid;
                    color: black !important;
                    text-align: center;
                    padding: 10px;
                  "
                >
                  Episodic
                </td>
              </tr>
            </table>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="$emit('confirm')">
          Cancel
        </v-btn>

        <v-btn color="green darken-1" text @click="convertContract">
          Download
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card
      class="d-xs-block d-sm-block d-md-block d-md-none d-lg-none d-xl-none"
    >
      <v-card-text>
        <div style="text-align: justify; color: black !important">
          <div style="page-break-after: always">
            <br />
            <h3
              class="text-center font-weight-bold"
              style="text-align: center; justify-content: center"
            >
              DEFINITIVE DIGITAL DIRECT
            </h3>
            <h3
              class="text-center font-weight-bold"
              style="text-align: center; justify-content: center"
            >
              HEALTHCARE SERVICES AGREEMENT
            </h3>
            <br />
            <p>
              This Definitive Digital Direct Healthcare Services Agreement (the
              “Agreement”) serves as the standardized, accepted agreement
              between those parties who deliver or access products and services
              through a digital direct healthcare technology environment, upon
              which the Agreement is executed. Participation within a Digital
              Direct Healthcare technology environment, requires and is
              predicated upon acceptance of this Agreement. Therefore, the
              parties who intend to execute this Agreement are encouraged to
              fully review it in its entirety to determine any implications that
              it may have on the party both personally and from a business
              perspective, and thus make the determination if participation in
              the Digital Direct Healthcare technology environment is right for
              them.
            </p>
          </div>

          <div class="html2pdf__page-break" />
          <br /><br /><br />
          <div>
            <h4
              class="text-center font-weight-bold"
              style="
                text-align: center;
                justify-content: center;
                text-decoration: underline;
              "
            >
              DEFINITIVE DIGITAL DIRECT HEALTHCARE SERVICES AGREEMENT
            </h4>
            <br />
            <p>
              <b class="ml-5" style="margin-left: 30px"
                >THIS DEFINITIVE DIRECT HEALTHCARE SERVICES AGREEMENT (the
                “Agreement”)
              </b>
              is a binding legal contract by and between the Provider of
              healthcare products and services named below that has submitted
              rates on the Apaly Health Inc. ( <b>“Apaly”</b> ) Platform (the
              <b>“Platform”</b>) and clicked <b>“ACCEPT & CONTRACT”</b> to
              deliver Covered Services to Participants (“Provider”), and the
              consumer of healthcare products and services named below that
              submitted rates on the Platform to pay for Covered Services that
              are delivered to Participants and clicked
              <b>“ACCEPT & CONTRACT”</b> ( <b>“Payer”</b>), and is effective as
              of the date that such Provider and Payer have both clicked
              <b></b>“ACCEPT AND CONTRACT” (the <b>“Effective Date”</b>).
              Provider and Payer are sometimes collectively referred to herein
              as the “Parties” and individually as a “Party.”
              <b>Capitalized terms</b> herein shall have the meanings specified
              in Article 1 – Definitions of this Agreement.
            </p>
            <h4
              style="
                font-weight: bold;
                text-decoration: underline;
                margin: 10px 0px 10px 0px;
              "
              class="text-decoration"
            >
              RECITALS:
            </h4>
            <p>
              <b class="ml-5" style="margin-left: 30px">WHEREAS,</b> the
              Platform is a software solution used by Provider and Payer to
              facilitate: (a) direct contractual relationships; (b) medical
              Claim repricing and transmission between Provider and Payer; (c)
              Participant’s access to Provider’s services; (d) the reimbursement
              for Covered Services; and, (e) other functions to support the
              delivery of products and services directly between the Parties.
            </p>
            <p>
              <b class="ml-5" style="margin-left: 30px">WHEREAS,</b> Payer
              desires for Provider to become a Participating Provider, and
              Provider desires to participate with Payer on the Platform, on a
              nonexclusive basis, in accordance with the terms of this
              Agreement; and,
            </p>
            <p>
              <b class="ml-5" style="margin-left: 30px">WHEREAS,</b> The Parties
              acknowledge that Apaly is not a payer of any reimbursement under
              this Agreement;
            </p>

            <h4
              style="
                font-weight: bold;
                text-decoration: underline;
                margin: 10px 0px 10px 0px;
              "
            >
              TERMS:
            </h4>
            <p>
              <b class="ml-5" style="margin-left: 30px">NOW THEREFORE,</b> in
              consideration of the mutual covenants set forth below and valid
              consideration, the receipt and sufficiency of which is hereby
              acknowledged, the Parties, intending to be legally bound by this
              Agreement, hereby agree as follows.
            </p>
            <br />
          </div>
          <div class="html2pdf__page-break" />
          <br /><br /><br /><br /><br /><br /><br />
          <div>
            <h4
              class="text-center mt-2 font-weight-bold"
              style="text-align: center; justify-content: center"
            >
              ARTICLE 1
            </h4>
            <h4
              class="text-center mt-2 font-weight-bold"
              style="text-align: center; justify-content: center"
            >
              DEFINITIONS
            </h4>
            <br />
            <p>
              The following terms shall have the meanings as ascribed to them or
              referenced below (such terms shall be equally applicable to both
              the singular and plural forms of the terms defined):
            </p>
            <p>
              <b class="ml-5" style="margin-left: 30px">“Agreement”</b> shall
              mean this Definitive Digital Direct Healthcare Services Agreement,
              including any Compensation or Service addenda and any other
              addenda, amendments, exhibits, supplements and modifications
              hereto or thereto from time to time that are mutually agreed to
              and accepted by the Parties.
            </p>
            <p>
              <b class="ml-5" style="margin-left: 30px">“Applicable Law”</b>
              shall mean all applicable federal, state and local laws, rules and
              regulations, now or hereafter in force and effect, governing the
              provision of Provider Services or otherwise relating to the
              Agreement or governing Payer or Provider in connection with the
              Agreement including, without limitation, HIPAA.
            </p>
            <p>
              <b class="ml-5" style="margin-left: 30px"
                >“Benefit Program” or “Benefit Plan” or “Plan”</b
              >
              means the benefits underwritten by Payer under a federal ERISA
              employer health plan.
            </p>
            <p>
              <b class="ml-5" style="margin-left: 30px">“Claim”</b> means a
              request for reimbursement for Covered Services rendered to an
              eligible Participant, at a Provider location and submitted by
              Provider using one or more of the Tax Identification Numbers
              listed in Exhibit A of this Agreement and submitted to Payer with
              a prior authorization number issued on the Visit Pass.
            </p>
            <p>
              <b class="ml-5" style="margin-left: 30px">“Clinic(s)” </b>
              means Provider’s owned or leased location(s) where Participating
              Provider will deliver in-person Covered Services.
            </p>
            <p>
              <b class="ml-5" style="margin-left: 30px">“Covered Services”</b>
              means those healthcare products and services rendered to a
              Participant for which Payer has agreed to pay as evidenced by a
              Visit Pass with a prior authorization number that was issued
              through the Platform and as may be further defined within any
              addendum or exhibit to this Agreement.
            </p>
            <p>
              <b class="ml-5" style="margin-left: 30px">“ERISA”</b> means the
              Employee Retirement Income Security Act of 1974, as amended, and
              its implementing regulations.
            </p>
            <p>
              <b class="ml-5" style="margin-left: 30px"
                >“Facility-Based Providers”
              </b>
              means those Provider organizations, individual physicians, or
              allied health professionals, whether directly employed or
              contracted, who primarily deliver healthcare services within a
              facility. This includes but is not limited to: anesthesia,
              emergency, pathology, radiology, hospitalist, or any specialty
              whereby the facility has engaged the Provider under an exclusive
              arrangement.
            </p>
            <p>
              <b class="ml-5" style="margin-left: 30px">“HIPAA”</b> means the
              Health Insurance Portability and Accountability Act of 1996 and
              its implementing regulations
            </p>
            <p>
              <b class="ml-5" style="margin-left: 30px"
                >"Non-Covered Services”</b
              >
              means those medical and hospital services that do not qualify as
              Covered Services.
            </p>
            <p>
              <b class="ml-5" style="margin-left: 30px"
                >“Non-Participating Provider”</b
              >
              means a Provider that has not entered into this Agreement or
              entered rates on the Platform for the purpose of providing Covered
              Services to Participants.
            </p>
            <p>
              <b class="ml-5" style="margin-left: 30px">“Participant”</b>” means
              an eligible participant or beneficiary (as those terms are defined
              by ERISA) in a Benefit Program who has selected a Participating
              Provider to provide the Covered Services.
            </p>
            <p>
              <b class="ml-5" style="margin-left: 30px"
                >“Participating Provider”</b
              >means a Provider or employee or independent contractor of
              Provider that has entered into this Agreement and entered rates on
              the Platform for the purpose of providing Covered Services to
              Participants.
            </p>
            <p>
              <b class="ml-5" style="margin-left: 30px">“Payer”</b>
              shall have the meaning set forth in the introductory paragraph,
              which is expressly incorporated herein.
            </p>
            <p>
              <b class="ml-5" style="margin-left: 30px">“Provider”</b>
              shall have the meaning set forth in the introductory paragraph,
              which is expressly incorporated herein.
            </p>
            <p>
              <b class="ml-5" style="margin-left: 30px"
                >“Provider Affiliates”</b
              >
              means a duly licensed medical professional that Provider employs
              or contracts to deliver care to Participants.
            </p>
            <p>
              <b class="ml-5" style="margin-left: 30px">“Provider Services”</b>
              means those medical services customarily performed by Provider and
              within the scope of Provider’s license and accreditation.
            </p>
            <p>
              <b class="ml-5" style="margin-left: 30px">“Visit Pass”</b> means a
              digitally generated verification on the Platform that an
              individual is a Participant and that the services being requested
              are Covered Services. The Visit Pass shall include such
              information as: (a) Payer name and identifier (b) relevant phone
              numbers for customer or Provider service; (c) relevant identifying
              numbers such as Participant number, employer group number and
              Benefit Program number; (d) a prior authorization number that
              shall be submitted with Participating Providers Claim; and, (e)
              claims address and TPA information.
            </p>
            <br /><br />
          </div>
          <br />
          <div>
            <h4
              class="text-center mt-2 font-weight-bold"
              style="text-align: center; justify-content: center"
            >
              ARTICLE 2
            </h4>
            <h4
              class="text-center mt-2 font-weight-bold"
              style="text-align: center; justify-content: center"
            >
              OBLIGATIONS OF THE PARTIES
            </h4>
            <br /><br /><br />
            <p style="margin: 10px 0px 10px 0px" class="">
              <b style="text-decoration: underline">Obligations of Payer :</b>
              Payer shall comply with the following conditions and obligations:
            </p>
            <p>
              <span style="text-decoration: underline"
                >Participant Identification.</span
              >
              Through the Platform, Payer will furnish a Visit Pass to
              Participant and to Provider as a means to identify Participant and
              support Provider in creating and submitting Claims for
              reimbursement from Payer.
            </p>
            <p></p>
            <p>
              <span style="text-decoration: underline"
                >Provider Identification.</span
              >
              Payer will reference the Platform for listings of Participating
              Providers and may provide financial incentives to Participants to
              utilize Participating Providers through Benefit Program design
              subject to and in accordance with Applicable Law and other
              contractual obligations if applicable.
            </p>
            <p>
              <span style="text-decoration: underline"
                >Payment for Covered Services.</span
              >
              Subject to the terms of Payer’s health plan requirements, Payer
              will reimburse Provider for Covered Services rendered to eligible
              Participants pursuant to the Compensation Addendum attached as
              Exhibit A hereto (the <b>“Compensation Addendum”</b> ) in effect
              between the Parties at the time that services were rendered. For
              hospital inpatient services, the Compensation Addendum in effect
              at the time of admission shall govern compensation for all
              services provided until discharge.
            </p>
            <p>
              <span style="text-decoration: underline">Legal Obligations.</span>
              Provider acknowledges that Payer cannot enter into any transaction
              pursuant to this Agreement that is a prohibited transaction under
              ERISA or Applicable Law.
            </p>
            <p style="margin: 10px 0px 10px 0px" class="">
              <b style="text-decoration: underline">Obligations of Provider:</b>
              Provider shall comply with the following conditions and
              obligations
            </p>
            <p>
              <span style="text-decoration: underline">Services Provided.</span>
              As a Participating Provider on the Platform, Provider agrees to
              provide to Participants, those Covered Services that Provider is
              qualified to provide and customarily provides within the scope of
              Provider’s qualifications, license and certification, which
              include the services provided by physicians and allied health
              professionals that are contracted or employed by Provider and
              billed under Provider’s TIN at those locations that are identified
              by Provider on the Platform, and as may be more specifically
              identified in any addendum or exhibit to this Agreement as
              applicable
            </p>

            <p>
              <span style="text-decoration: underline"
                >Provider as a Group Practice.</span
              >
              Where Participating Provider is, owns, or operates a group
              physician practice, Participating Provider shall use commercially
              reasonable efforts to maintain on the Platform a true, complete,
              and accurate roster of Participating Provider’s physicians, allied
              health professionals, and locations where Covered Services are
              rendered under this Agreement.
            </p>

            <p>
              <span style="text-decoration: underline">
                Health Care Delivery.</span
              >
              Provider acknowledges that this Agreement, Payer, and the Benefits
              Program do not dictate the health care services that are provided
              by Provider or govern a physician’s or hospital’s determination of
              what care to provide its patients, even if those patients are
              Participants. The decision regarding what care is to be provided
              remains with Participants and their Providers, and not with Payer.
            </p>
            <p>
              <span style="text-decoration: underline"
                >Facility-Based Providers.</span
              >
              Provider will use commercially reasonable efforts to compel all
              Facility-Based Providers, to be Participating Providers on the
              Platform. In the event a Facility-Based Provider is a
              Non-Participating Provider on the Platform, then Provider shall
              identify these Facility-Based Non-Participating Providers on the
              Platform.
            </p>
            <p>
              <span style="text-decoration: underline">
                Discrimination Prohibited.</span
              >
              Participating Provider shall provide Provider Services in the same
              manner and in accordance with the same standards offered to all
              other patients with the same medical diagnosis or condition,
              regardless of source of payment or coverage. Participating
              Provider, in accordance with the provisions, spirit and intent of
              this Agreement, shall not differentiate or discriminate in the
              treatment of Participants or in the quality of services rendered
              to Participants based on race, creed, color, national origin, sex,
              age, religion, sexual orientation, gender identity, veteran
              status, handicap, place of residence, health status, source of
              payment, credit history, or any other ground prohibited by
              Applicable Law.
            </p>

            <p>
              <span style="text-decoration: underline">
                On-Platform Referrals.</span
              >
              To the extent applicable, Participating Provider shall use
              commercially reasonable efforts to facilitate the referral of
              Participants to and/or use Participating Providers for the
              provision of Covered Services to Participants, except: (a) if no
              Participating Provider is available to provide the Covered
              Services within a reasonable time and distance, or (b) the
              Participant intentionally chooses to use an alternate Provider
              whom the Participant knows is not a Participating Provider.
            </p>
            <p>
              <span style="text-decoration: underline">
                Licenses, Certifications, Hospital Privileges.</span
              >
              Participating Provider will ensure that all of Provider’s employed
              or contracted physicians or allied healthcare professionals who
              are utilized by Participating Provider to deliver Covered Services
              to Participants under this Agreement have, and shall maintain,
              throughout the term of this Agreement, all appropriate and
              unrestricted medical licenses, certifications and hospital
              privileges (if applicable), as appropriate for his or her
              professional discipline. In the event that any of Participating
              Provider’s physicians or allied healthcare professionals who are
              utilized by Provider to deliver Covered Services to Participants
              loses the unrestricted medical license, required certification, or
              hospital privilege (if applicable), then Participating Provider
              will immediately remove the physician or allied healthcare
              professional from the Platform. Apaly and the Platform do not
              provide credentialing services and make no representation or
              warrant regarding the qualifications of any Participating Provider
              and any of Participating Provider’s physicians or allied
              healthcare professionals, and has no obligation to verify the
              qualifications or credentialing status of any Participating
              Provider (including any material uploaded or presented by any
              Provider to the Platform).
            </p>
            <p>
              <span style="text-decoration: underline">Quality Assurance.</span>
              The Plan, in consultation with and upon joint approval from the
              Participating Provider, may implement a program of utilization,
              quality assurance, and peer review based upon standards
              established by Federal and State law or other plan administration
              best practices, the purpose of which is to promote adherence to
              accepted medical treatment standards. Participating Provider
              agrees to participate in, cooperate with, and comply with the
              decisions, rules and regulations established by the Plan’s
              utilization management program, including but not limited to, the
              use of the Visit Pass with a valid prior-authorization number as
              precertification for elective admissions and procedures, on
              Platform referral processes, and reporting of clinical encounter
              data.
            </p>
            <p>
              <span style="text-decoration: underline"
                >Medical Records, Retention, Access to Records, and Copies.
              </span>
              To the extent applicable, Participating Provider shall maintain a
              complete and accurate permanent medical record for each
              Participant to whom Participating Provider renders Covered
              Services under this Agreement in accordance with usual and
              customary practices and as required by Applicable Law.
            </p>
            <p>
              <span style="text-decoration: underline"
                >Use of Names for Platform.</span
              >
              Participating Provider agrees to allow Payer to list on the
              Platform, Participating Provider’s name, logo, business address,
              business phone number(s), available services, an indication of
              Participating Provider’s willingness to accept additional
              Participants, and other pertinent Participating Provider
              information, among Payer’s listing of Participating Providers, for
              the sole purpose of having beneficiaries be able to locate a
              provider inside the Platform. Platform will seek approval from
              Participating Provider and/or Payer before using name and logo for
              any marketing related purposes. Provider agrees to provide updated
              information to Payer regarding the above as may from time to time
              be necessary, and Participating Provider shall promptly notify
              Payer of any change in the information pertinent to this section.
              Provider may disclose the fact of its participation status with
              Payer in its communications with third parties and in its
              participating payer listings.
            </p>
            <p>
              <span style="text-decoration: underline"
                >Acceptance of Participants.</span
              >
              If applicable, Participating Provider shall notify Payer in
              writing through the Platform at least sixty (60) days prior to the
              date on which Participating Provider will not accept new
              Participants. Notwithstanding the foregoing, Participating
              Provider agrees not to close its practice to new Participants,
              unless Participating Provider has closed its practice to all new
              patients
            </p>
            <p>
              <span style="text-decoration: underline"
                >Provider Insurance.</span
              >
              Throughout the term of this Agreement, Participating Provider
              shall maintain professional and general liability insurance
              coverage to protect against all allegations arising out of the
              rendering of professional services or the alleged failure to
              render professional services by Participating Provider and its
              employees. For purposes of this Section, “professional services”
              shall include but is not limited to Covered Services. Such
              liability coverage shall be in the amounts consistent with
              Participating Provider type, industry standard and as may be
              required by Applicable Law.
            </p>
          </div>
          <br /><br />
          <div>
            <h4
              class="text-center mt-2 font-weight-bold"
              style="text-align: center; justify-content: center"
            >
              ARTICLE 3
            </h4>
            <h4
              class="text-center mt-2 font-weight-bold"
              style="text-align: center; justify-content: center"
            >
              COMPENSATION, CLAIMS SUBMISSION, AND PAYMENT
            </h4>
            <br /><br />
            <p>
              <span style="text-decoration: underline">Compensation.</span>
              Participating Provider shall accept, as payment in full for
              Covered Services rendered to a Participant, the amount determined
              in accordance with terms and conditions set forth herein as
              defined in the Compensation Addendum. Participating Provider
              acknowledges and agrees to look solely to Payer for payment of
              Covered Services provided to Participants.
            </p>
            <p>
              <span style="text-decoration: underline"
                >Eligibility and Coverage Determinations.</span
              >
              Provider shall verify through the Platform if an individual is a
              Participant and if the service being requested is a Covered
              Service that is reimbursable by Payer.
            </p>
            <p>
              <span style="text-decoration: underline">Claim Submission. </span>
              Participating Provider shall submit Claims for all Covered
              Services rendered to a Participant by submitting an appropriate
              Claim within one hundred twenty (120) days from the date the
              services are rendered for outpatient services or date of discharge
              for inpatient services, or as described otherwise in an addendum
              to this Agreement.
            </p>
            <p>
              <span style="text-decoration: underline">Claim Payment.</span>
              Payer and Participating Provider agree that Payer will use
              reasonable efforts to process all Claims for Covered Services
              within thirty (30) days from the date of receipt. However, a
              failure to do so shall not constitute a material breach of this
              Agreement provided that Payer processes such Claim within
              timeframes required by Applicable Law.
            </p>
            <p>
              <span style="text-decoration: underline"
                >Participant Hold Harmless.</span
              >
              Participants shall not be liable for payments due to Participating
              Provider’s failure to submit Claims within the time specified
              above or for any Claims denied by Payer for any reason other than
              Participant’s eligibility or that the services were not Covered
              Services under the Benefit Program.
            </p>
            <p>
              <span style="text-decoration: underline"
                >Referral Disclaimer.</span
              >
              Payments made pursuant to this Agreement shall not be determined
              in any manner by the volume or value of any potential referrals
              between the Parties. No amount paid pursuant to this Agreement is
              intended to be, nor shall it be construed to be, an inducement or
              payment for referral of patients by Payer to Participating
              Provider or by Participating Provider to Payer. Both Parties shall
              comply with all Applicable Laws regarding fraud and abuse.
            </p>
            <p>
              <span style="text-decoration: underline"
                >No Federal Health Care Programs.</span
              >
              It is expressly understood and acknowledged that no Medicare,
              Medicaid or other Federal or State Health Care Program funds will
              be utilized by the Parties under this Agreement.
            </p>
            <p>
              <span style="text-decoration: underline"
                >Limitation of Liability.</span
              >
              Regardless of whether there is a total and fundamental breach of
              this Agreement, or whether any remedy provided in this Agreement
              fails of its essential purpose, in no event shall either of the
              Parties hereto be liable for any amounts representing loss of
              revenues, loss of profits, loss of business, the multiple portion
              of any multiplied damage award, or incidental, indirect,
              consequential, special or punitive damages, whether arising in
              contract, tort (including negligence), or otherwise; regardless of
              whether the Parties have been advised of the possibility of such
              damages arising in any way out of or relating to this Agreement.
              The foregoing limitations do not affect any liability arising from
              willful misconduct, gross negligence, violation of Applicable Law
              or breaches of an obligation of non-disclosure or confidentiality
              as well as any liability which cannot be excluded or limited under
              Applicable Law.
            </p>
          </div>
          <br /><br />
          <div>
            <h4
              class="text-center mt-2 font-weight-bold"
              style="text-align: center; justify-content: center"
            >
              ARTICLE 4
            </h4>
            <h4
              class="text-center mt-2 font-weight-bold"
              style="text-align: center; justify-content: center"
            >
              DISPUTE RESOLUTION
            </h4>
            <br /><br />
            <p>
              If any dispute or controversy arises among the Parties with
              respect to the terms or interpretation of this Agreement, or the
              rights of any Party hereunder, or with respect to any transaction
              involved hereunder, <b>the Parties shall</b> attempt in good faith
              to negotiate a settlement, within thirty (30) working days of
              either Party notifying the other. If negotiation between the
              Parties is unable to resolve such dispute, and if any Party wishes
              to pursue the dispute, such dispute shall be submitted to binding
              arbitration in accordance with the commercial rules of the
              American Health Lawyers Association, the American Arbitration
              Association, or any other comparable nationally recognized
              alternative dispute resolution association acceptable to the
              Parties. Such arbitration proceeding shall be conducted in the
              location of the non-aggrieved Party’s choice. The arbitrators
              shall have no authority to award any punitive or exemplary
              damages, or to vary or ignore the terms of the Agreement and shall
              be bound by controlling law. The Parties agree that the
              arbitrator’s award shall be final, binding, non-appealable, and
              may be confirmed and entered as a judgment in any court of
              competent jurisdiction. This Section shall survive the termination
              of this Agreement.
            </p>
          </div>
          <br /><br />
          <div>
            <h4
              class="text-center mt-2 font-weight-bold"
              style="text-align: center; justify-content: center"
            >
              ARTICLE 5
            </h4>
            <h4
              class="text-center mt-2 font-weight-bold"
              style="text-align: center; justify-content: center"
            >
              TERM AND TERMINATION
            </h4>
            <br /><br />
            <p>
              <span style="text-decoration: underline">Term.</span>
              The initial term of this agreement shall be twelve (12) months
              (the “Initial Term”). At the end of the Initial Term, this
              Agreement shall automatically renew for successive periods of
              twelve (12) months (each a “Renewal Term”). During the Initial
              Term and each subsequent Renewal Term, this Agreement shall remain
              in effect between Payer and Provider with each other as long as:
              (a) Payer and Provider both participate on the Platform; (b)
              Provider’s reimbursement rate terms on the Platform remain
              consistent with those rates contained herein; (c) either party has
              not specifically excluded the other Party from this Agreement on
              the Platform, or (d) this Agreement is not specifically terminated
              as to a Payer or a Provider as set forth below. Both parties agree
              to maintain their respective reimbursement rate terms on the
              Platform under this Agreement and the Compensation Addendum for
              the duration of the twelve (12) month Initial Term and the
              duration of each subsequent twelve (12) month Renewal Term unless
              an Amendment is created as provided for in this Article 5.
            </p>
            <div>
              <span style="text-decoration: underline">Termination.</span> This
              Agreement may be terminated under any of the following
              circumstances: <br /><br />
              <p>
                i) by mutual written agreement of the parties;<br /><br />
                ii) by Payer, upon 90 days’ prior written notice, in the event
                that Provider fails to meet Payer’s minimum care standards as
                determined by Payer in its sole discretion and communicated to
                Provider; however, the termination will not take effect if
                within 30 days of Payer’s notice, Provider develops a plan that
                is presented to and accepted by Payer to resolve the minimum
                care standard issue and the implemented plan actually resolves
                the issue.<br /><br />
                iii) by either party, upon at least 90 days’ prior written
                notice;<br /><br />
                iv) by either party, upon 60 days’ prior written notice, in the
                event of a material breach of this Agreement by the other party,
                which notice will include a specific description of the alleged
                breach; however, the termination will not take effect if the
                breach is cured within 60 days after notice of the
                termination;<br /><br />
                v) by either party, upon 10 days’ prior written notice, in the
                event the other party loses licensure or other governmental
                authorization necessary to perform this Agreement, or fails to
                have insurance as required under Article 2 of this Agreement;<br /><br />
                vi) Payer determines in good faith that Provider’s continued
                provision of services to Participants may result in, or is
                resulting in, danger to the health, safety, or welfare of
                Participants. Where the danger results from the actions of
                Provider’s staff, contractors, or subcontractors, then Provider
                shall suspend the relationship of such staff, contractors,
                subcontractors upon immediate notice from Payer, at least with
                respect to Participants, and if Provider fails to take such
                action, Payer may terminate the Agreement upon ten (10) days’
                written to Provider;<br /><br />
                vii) by Payer, upon 10 days’ prior written notice, in the event
                Provider loses accreditation to provide services to patients:
                or<br /><br />
              </p>
              <p>
                <span style="text-decoration: underline"
                  >Termination for Insolvency.</span
                >
                Provider or Payer may terminate this Agreement immediately upon
                notice to the other Party in the event of: (a) the filing of a
                petition for relief under bankruptcy law by or against the other
                Party; or (b) any liquidation, rehabilitation, conservation, or
                similar proceeding, or upon any other fiscal insolvency of
                Provider or Payer.
              </p>
              <p>
                <span style="text-decoration: underline"
                  >Post-Termination Obligations.</span
                >
                Following any termination of this Agreement, other than a
                termination under Article 5: Immediate Termination or
                Termination for Insolvency, Provider and Payer shall continue to
                abide by the terms of this Agreement in effect as of the day
                before the effective date of termination until the earlier of:
                (a) the Participant’s transition of care to a new Provider; (b)
                the completion of Participant’s course of treatment. For
                pregnant women, course of treatment ends six weeks postpartum.
                For hospital admissions other than obstetrics, the course of
                treatment ends upon discharge; or (c) one hundred and twenty
                (120) days after the termination effective date.
              </p>
              <p>
                <span style="text-decoration: underline"
                  >Participant Notification.</span
                >
                To extent applicable, upon the termination of this Agreement,
                Provider shall cooperate with Payer regarding Participant
                notification of such termination.
              </p>
              <p>
                <span style="text-decoration: underline"
                  >Effect of Termination.
                </span>
                Termination of this Agreement shall have no effect upon the
                rights and obligations of the Parties arising out of any
                transaction occurring prior to the effective date of such
                termination.
              </p>
            </div>
          </div>
          <br /><br />
          <div>
            <h4
              class="text-center mt-2 font-weight-bold"
              style="text-align: center; justify-content: center"
            >
              ARTICLE 6
            </h4>
            <h4
              class="text-center mt-2 font-weight-bold"
              style="text-align: center; justify-content: center"
            >
              MISCELLANEOUS
            </h4>
            <br /><br />
            <p>
              <span style="text-decoration: underline">Notices</span> Any notice
              required to be given pursuant to the terms and provisions of this
              Agreement shall be in writing and may be sent via electronic mail,
              by certified or registered mail return receipt requested, or by
              personal hand delivery, at the addresses set forth on the
              Platform.
              <br />
            </p>
            <p>
              <span style="text-decoration: underline"
                >Independent Contractors.</span
              >
              For purposes of this Agreement, Payer and Provider are independent
              contractors. No agency, joint venture, partnership or
              representation agreement is created by this contract.
            </p>
            <p>
              <span style="text-decoration: underline">Unforeseen Events.</span>
              If either Party’s ability to perform that Party’s obligations
              under this Agreement is substantially interrupted by war, fire,
              pandemics or public health emergencies, insurrections, riots, the
              elements, earthquake, acts of God, or, without limiting the
              foregoing, any other similar cause beyond the control of either
              Party, the Party shall be relieved of the Party’s obligations only
              to those affected portions of this Agreement for the duration of
              such interruption. If such interruption of performance by one
              Party is likely to continue for a substantial period of time, the
              other Party shall have the right to terminate this Agreement upon
              twenty (20) days’ prior written notice.
            </p>
            <p>
              <span style="text-decoration: underline">Non-Exclusivity.</span>
              Nothing in this Agreement shall be construed to be an exclusive
              arrangement, nor shall this Agreement prevent either party from
              entering into similar Agreements or contracts with other entities
              on the Platform or through other mean or prevent Participants from
              choosing other Providers, Plan options, Benefit Programs,
              Providers, or programs.
            </p>
            <p>
              <span style="text-decoration: underline">Assignment.</span>
              This Agreement can only be amended when electronically accepted
              and acknowledged on the Platform by both Parties.
            </p>
            <p>
              <span style="text-decoration: underline">Amendments.</span>
              This Agreement can only be amended when electronically accepted
              and acknowledged on the Platform by both Parties
            </p>
            <p>
              <span style="text-decoration: underline"
                >Invalidity or Unenforceability.</span
              >
              The invalidity or unenforceability of any terms or provisions of
              this Agreement shall in no way affect the validity or
              enforceability of any other term or provision.
            </p>
            <p>
              <span style="text-decoration: underline">Entire Agreement.</span>
              To the extent not preempted by federal law, and without regard to
              principles of conflict of laws, this Agreement will be governed by
              and construed in accordance with the laws of the state in which
              the Provider’s primary business office is located.
            </p>
            <p>
              <span style="text-decoration: underline">Applicable Law.</span>
              This Agreement and all attachments, schedules, exhibits, addenda,
              hereto shall constitute the entire agreement between the Parties
              regarding the subject matter hereof and thereof.
            </p>
            <p>
              <span style="text-decoration: underline"
                >Third Party Beneficiaries.</span
              >
              There are no third-party beneficiaries of this Agreement except
              for Apaly, which is specifically intended to be a third-party
              beneficiary of all terms and conditions of this Agreement that
              could impact the Platform, or the ownership thereof, in any manner
              whatsoever.
            </p>
            <p>
              <span style="text-decoration: underline"
                >Proprietary Information.</span
              >
              The terms of this Agreement and any information and material
              provided by one Party to the other Party in contemplation of or in
              connection with this Agreement, remains proprietary to the
              disclosing party. Neither Payer nor Provider shall disclose any
              information proprietary to the other or use such information or
              material except if permitted or required under Applicable Law or
              with the express written approval of the disclosing party.
            </p>
            <p>
              <span style="text-decoration: underline">Data.</span>
              Payer and Provider agree that managing and transmitting data is
              critical to their ability to perform effectively under this
              Agreement. Provider agrees to provide, including via the Platform,
              all patient, practice, operational, and financial data reasonably
              requested by Payer to administer the Benefit Program. Payer and
              Provider agree to comply with HIPAA standards in relation to the
              transmission and storage of Participant personal and health
              related data.
            </p>
            <p>
              <span style="text-decoration: underline">Acceptance.</span> By
              clicking <b>“ACCEPT & CONTRACT”</b>” on the Platform (a) Payer and
              Provider have agreed to enter into, and be legally bound by, this
              Agreement along with any of the Agreement’s exhibits or addenda
              and that the relationship will be governed by the terms and
              conditions contained in this Agreement and any addenda; (b) the
              individual accepting this Agreement confirms his/her legal
              authority to enter into this Agreement on behalf of his/her
              respective party; and (c) all information provided by the
              individual accepting this Agreement related to Payer and Provider
              in connection with this Agreement is accurate, current and
              complete to the best of the individual’s knowledge.
            </p>
          </div>
          <br /><br /><br />
          <h4 style="justify-content: center; text-align: center !important">
            [ELECTRONIC ACCEPTANCE]
          </h4>
          <div
            style="
              display: flex;
              flex-direction: row;
              margin-top: 30px;
              page-break-after: always;
            "
          >
            <div style="display: flex; flex-direction: column; flex: 1">
              <h3 style="">PAYER</h3>
              <div style="">By:</div>
              <div style="">Name:</div>
              <div style="">Title:</div>
              <div style="">Organization:</div>
            </div>
            <div style="display: flex; flex-direction: column; flex: 1">
              <h3 style="">PROVIDER</h3>
              <div style="">By:</div>
              <div style="">Name:</div>
              <div style="">Title:</div>
              <div style="">Organization:</div>
            </div>
          </div>
          <div>
            <h4
              class="text-center font-weight-bold mt-2"
              style="text-align: center; justify-content: center"
            >
              EXHIBIT A
            </h4>
            <h4
              class="text-center font-weight-bold mt-2"
              style="text-align: center; justify-content: center"
            >
              COMPENSATION ADDENDUM
            </h4>
            <p>
              This is an addendum to the PROVIDER AGREEMENT (<b>“Agreement”</b>
              )) entered into by and between Provider and Payer. Capitalized
              terms used in this Exhibit but not defined herein will carry the
              meanings ascribed to them in the Agreement. This COMPENSATION
              ADDENDUM will become effective as of the Effective Date.
            </p>
            <p>
              <span class="ml-5" style="margin-left: 30px"
                ><b> Payments for Covered Services.</b></span
              >
              Except as otherwise provided herein, Payer shall reimburse
              Provider for Covered Services based on the rates shown below,
              which reference generally accepted terms, services, and published
              rates used by the Centers for Medicare and Medicaid Services
              (<b>“CMS”</b>).
            </p>
          </div>
          <br /><br />
          <div
            style="margin-top: 30px; font-weight: bold; color: black !important"
          >
            <h4>Rate Table:</h4>
            <hr />
            <div>Rate Information</div>
            <hr />
            <div>Rate Type: Outpatient</div>
            <hr />
            <div>
              Description: Outpatient services as defined and widely accepted by
              CMS, generally characterized by same-day care episodes, except for
              patient observation.
            </div>
            <hr />
            <div>
              Amount: <span v-if="outpatient">{{ outpatient }}</span>
            </div>
            <hr />
            <div>Type: % of Medicare</div>
            <hr />
            <div>Freq: Episodic</div>
            <hr />
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="$emit('confirm')">
          Cancel
        </v-btn>

        <v-btn color="green darken-1" text @click="convertContract">
          Download
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script src="js/jsPDF/dist/jspdf.umd.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.3.4/jspdf.debug.js" ></script>
<script>
import api from "../../services/api";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
export default {
  data() {
    return {
      dialog: false,
      windowWidth: window.innerWidth,
      display: false,
    };
  },
  props: {
    name: String,
    inpatient: Number,
    outpatient: Number,
    id: String,
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  created() {
    if (this.windowWidth < 600) {
      this.display = false;
    } else {
      this.display = true;
    }
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 959) {
        this.display = false;
      } else {
        this.display = true;
      }
    },
    convertContract() {
    
      //vue-html2pdf - only for this browser versions
      // Package has been tested in these browsers:
      // Chrome Version 85.0.4183.121 (Official Build) (64-bit)
      // Mozilla Firefox Version 80.0.1 (64-bit)
      // Microsoft Edge Version 85.0.564.63 (Official build) (64-bit)
      // Brave Version 1.14.84 Chromium: 85.0.4183.121 (Official Build) (64-bit)

      // HTML Canvas
      // html2canvas(document.getElementById("element")).then(canvas => {
      //     document.body.appendChild(canvas)
      // });

      // JSPDF doesnt take the style , i should write style element by element exc p.setFont('Lato-Regular', 'normal');
      // var pdf = new jsPDF('p', 'pt', 'a4');
      // var options = {
      //          pagesplit: true
      //     };

      // pdf.html(document.getElementById("element"), options, function()
      // {
      //     pdf.save("apaly.pdf");
      // });

      //Window.print - is showing the pop up before downloading the file
      let doc = document.getElementById("element").innerHTML;
      let winPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=600,toolbar=0,scrollbars=0,status=0"
      );
      winPrint.document.write(doc);
      winPrint.document.close();
      winPrint.focus();
      winPrint.print();
      this.$emit("confirm");
      winPrint.close();
    },
    closePopUp() {
      this.dialog = false;
    },
    openPopUp() {
      this.dialog = true;
    },
  },
};
</script>
<style>
.pdf-table {
  width: 100%;
  border-collapse: collapse;
}
</style>
