<template>
 <v-row class="add-client-form no-padding">
  <div class="col-md-12 form-box">
     <removeDialog  ref="dialog_delete" v-on:confirm="confirmRemove"></removeDialog>
     <successModal  ref="success_modal" v-on:confirm="updateModal"></successModal>
    <div class="row">
        <div class="col-md-12">
            <h3 class="form-title  text-underline" v-if="singleProvider">{{singleProvider.companyName}}</h3>
         
        </div>
    </div>
    <form class="row">
        <div class="col-md-6">
             <div class="col-md-12">
               <label class="font text-gray" v-if="singleProvider && singleProvider.type && singleProvider.type == '1'" for="">Provider Name</label>
                <label class="font text-gray" v-else for="">Organization Name</label>
                <v-text-field
                required
                v-model.trim="providerName"
                solo
                ></v-text-field>
           </div>
            <div class="col-md-12">
               <label class="font text-gray" for="">Address 1</label>
                <v-text-field
                required
                v-model.trim="address1"
                solo
                ></v-text-field>
           </div>
            <div class="col-md-12">
               <label class="font text-gray" for="">Address 2</label>
                <v-text-field
                required
                v-model.trim="address2"
                solo
                ></v-text-field>
           </div>
           <div class="row no-padding">
             <div class="col-md-6">
                   <label class="font text-gray" for="">City</label>
                    <v-text-field
                    required
                    v-model.trim="city"
                    solo
                    ></v-text-field>
             </div>
             <div class="col-md-3">
                   <label class="font text-gray" for="">State</label>
                    <v-select
                    required
                    v-model.trim="state"
                    solo

                    ></v-select>
             </div>
             <div class="col-md-3">
                   <label class="font text-gray" for="">Zip</label>
                    <v-text-field
                    required
                    type="number"
                    v-model.trim="zip"
                    solo
                    :rules="zipRules"
                    ></v-text-field>
             </div>
           </div>
            <div class="col-md-12">
              <label class="font text-gray" for="">Tax ID</label>
                <v-text-field
                required
                @keyup="showSlash($event)"
                v-model.trim="taxId"
                solo
                placeholder="00-0000000"
                :rules="taxIdRules"
                counter="10"
                maxlength="10" 
                ></v-text-field>
           </div>
            <div class="col-md-12">
              <label class="font text-gray" for="">Provider Type</label>
             <v-select :items="providerTypeItems" solo v-model.trim="providerType"></v-select>
           </div>
           <div class="col-md-12">
              <label class="font text-gray" for="">Taxonomy</label>
                <v-text-field
                required
                v-model.trim="taxonomy"
                solo
                ></v-text-field>
           </div>
           <div class="col-md-12">
               <label class="font text-gray" for="">Preferred Provider Participation </label>
                <v-text-field
                required
                v-model.trim="participation"
                solo
                ></v-text-field>
           </div>
        </div>
        <div class="col-md-6">
            <div class="col-md-12">
               <label class="font text-gray" for="">Prmary Contact First Name</label>
                <v-text-field
                required
                v-model.trim="firstName"
                solo
                ></v-text-field>
           </div>
           <div class="col-md-12">
               <label class="font text-gray" for="">Prmary Contact Last Name</label>
                <v-text-field
                required
                v-model.trim="lastName"
                solo
                ></v-text-field>
           </div>
           <div class="col-md-12">
               <label class="font text-gray" for="">Prmary Contact Title</label>
                <v-text-field
                required
                v-model.trim="title"
                solo
                ></v-text-field>
           </div>
           <div class="col-md-12">
               <label class="font text-gray" for="">Prmary Contact Email</label>
                <v-text-field
                required
                v-model.trim="email"
                :rules="emailRules"
                solo
                ></v-text-field>
           </div>
             <div class="col-md-12">
               <label class="font text-gray" for="">Prmary Contact Phone</label>
                <v-text-field
                required
                v-model.trim="phone"
                solo
                :rules="phoneRules"
                ></v-text-field>
           </div>
        </div>

        <div class="col-md-12 d-flex align-end flex-column">
            <div>
                <v-btn class="blue-buttons mr-6 ml-6 mt-2"  @click="goBack">
                Cancel
                </v-btn>
            </div>
        </div>
     </form>
    </div>
 </v-row>
</template>
<script>
import successModal from '../../../TablesTemplate/SuccessDialog.vue'
import removeDialog from '../../../TablesTemplate/RemoveDialog.vue'
import api from '../../../../services/api'
export default{
   components:{
        successModal,
        removeDialog
   },
    data(){
     return{
         rating:4,
         switch1:true,
         providerName:'',
         address1:'',
         address2:'',
         city:'',
         state:'',
         zip:'',
         taxId:'',
         providerType:'',
         taxonomy:'',
         participation:'',
         firstName:'',
         lastName:'',
         title:'',
         email:'',
         phone:'',
         zipRules:[
              v => ( v && v.length == 5 ) || " Zip Code should be five digits long",
         ],
         phoneRules:[
        
          (v) => /^[0-9\ \+\-\/]+$/.test(v) || "Phone number must be in a valid format (XXX XXX XXXX)",
         ],
         emailRules: [
              v => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || 'E-mail must be valid',
         ],
         taxIdRules: [
                    v => !!v || 'This field is required',
                    v => !!/[0-9]{2}\-[0-9]{7}/.test(v) || "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
         ],
           providerTypeItems:[
                {
                    text:'Group',
                    value:'group'
                },
                {
                    text:'Hospital',
                    value:'hospital'
                },
                {
                    text:'Affiliate',
                    value:'affiliate'
                },
               
         ],
      
      }
    },
    created(){
          if(this.singleProvider){
            this.providerNpi = this.singleProvider['NPI'] ?   this.singleProvider['NPI'] : '';
            this.firstName = this.singleProvider.primaryContact && this.singleProvider.primaryContact.firstName ? this.singleProvider.primaryContact.firstName : this.singleProvider['Authorized Official First Name'] ? this.singleProvider['Authorized Official First Name']  :'';
            this.participation = this.singleProvider.settings && this.singleProvider.settings.preferredProviderParticipation ? this.singleProvider.settings.preferredProviderParticipation : '';
            this.taxonomy = this.singleProvider.specialty ? this.singleProvider.specialty : this.singleProvider['Healthcare Provider Taxonomy Code_1'] ? this.singleProvider['Healthcare Provider Taxonomy Code_1'] : '';
            this.address1 = this.singleProvider.address1 ? this.singleProvider.address1 : this.singleProvider['Provider First Line Business Mailing Address'] ? this.singleProvider['Provider First Line Business Mailing Address'] : '' ;
            this.address2 = this.singleProvider.address2 ? this.singleProvider.address2 :  this.singleProvider['Provider First Line Business Practice Location Address'] ? this.singleProvider['Provider First Line Business Practice Location Address'] : '';
            this.city = this.singleProvider.city ? this.singleProvider.city : this.singleProvider['Provider Business Practice Location Address City Name'] ? this.singleProvider['Provider Business Practice Location Address City Name'] : '';
            this.state = this.singleProvider.state ? this.singleProvider.state :  this.singleProvider['Provider Business Mailing Address State Name'] ?  this.singleProvider['Provider Business Mailing Address State Name'] : '';
            this.zip = this.singleProvider.zipCode ? this.singleProvider.zipCode : this.singleProvider['Provider Business Mailing Address Postal Code'] ? this.singleProvider['Provider Business Mailing Address Postal Code'] : this.singleProvider['Provider Business Practice Location Address State Name'] ? this.singleProvider['Provider Business Practice Location Address State Name'] : '';
            this.lastName = this.singleProvider.primaryContact && this.singleProvider.primaryContact.lastName ? this.singleProvider.primaryContact.lastName : this.singleProvider['Authorized Official Last Name'] ? this.singleProvider['Authorized Official Last Name'] : '';
            this.title = this.singleProvider.primaryContact &&  this.singleProvider.primaryContact.title ? this.singleProvider.primaryContact.title : this.singleProvider['Authorized Official Title or Position'] ?  this.singleProvider['Authorized Official Title or Position'] : '';
            this.email = this.singleProvider.primaryContact && this.singleProvider.primaryContact.email ? this.singleProvider.primaryContact.email : '';
            this.phone =this.singleProvider.primaryContact &&  this.singleProvider.primaryContact.phone ? this.singleProvider.primaryContact.phone : this.singleProvider['Authorized Official Telephone Number'] ? this.singleProvider['Authorized Official Telephone Number'] : '';
            // this.title =this.singleProvider.primaryContact &&  this.singleProvider.primaryContact.title ? this.singleProvider.primaryContact.title : this.singleProvider['Authorized Official Telephone Number'] ? this.singleProvider['Authorized Official Telephone Number'] : '';
           this.providerType = this.singleProvider.providerType;
           this.taxId = this.singleProvider.taxId ? this.singleProvider.taxId : this.singleProvider['Healthcare Provider Taxonomy Code_1'] ?  this.singleProvider['Healthcare Provider Taxonomy Code_1'] : '' ;
            this.npiType = this.singleProvider.type ? this.singleProvider.type : this.singleProvider['Entity Type Code'] ? this.singleProvider['Entity Type Code'] : '';
            if(this.singleProvider.providerName){
              this.providerName = this.singleProvider.providerName ? this.singleProvider.providerName : '';
             
            }else if(this.singleProvider.companyName){
              
                this.providerName = this.singleProvider.companyName ? this.singleProvider.companyName : '';
            }else if(this.singleProvider['Provider First Name']){
              
                  this.providerName = this.singleProvider['Provider First Name'] + ' ' + this.singleProvider['Provider Last Name (Legal Name)']
            } 
            else{
                this.providerName = this.singleProvider['Provider Organization Name (Legal Business Name)']
            }
         

          }
    },
    computed:{
      route(){
          return  this.$route.path
      },
      singleProvider(){
        
          return this.$store.getters.getSingleProviderInemployerAdvisor
      },
          routeParams(){
            return this.$route.params.name
      }
    },
    methods:{
       showSlash(event){
      if (this.taxId.length == 2 && event.keyCode != 8) {
        this.taxId += "-";
      }
      if (this.taxId.length == 2 && event.keyCode != 8) {
        this.taxId += "-";
      }
      if (this.taxId.length == 10) {
        api()
          .get("auth/isValidTaxId?taxId=" + this.taxId)
          .catch((res) => {
            if (res) {
              this.taxIdRules = [res.response.data.message];
            }
          });
      } else {
        this.taxIdRules = [
          (v) => !!v || "This field is required",
          (v) =>
            !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
            "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
        ];
      }
    },
     updateModal(){
          if(this.$route.path.includes('affiliates')){
             this.$router.push(`/provider/affiliates`);
          }else if(this.$route.path.includes('hospital-based')){
             this.$router.push(`/provider/hospital-based`);
          }else{
             this.$router.push(`/provider/providers`);
          }
           this.$refs.success_modal.closePopUp()
        },
     updateProvider(){
            let data = {
                'companyName' : this.providerName,
                'providerName': this.providerName,
                'address1': this.address1,
                'address2': this.address2,
                'city': this.city,
                'state': this.state,
                'zipCode': this.zip,
                'taxId': this.taxId,
                'providerCategory':this.providerType,
                'taxonomy': this.taxonomy,
                'preferredParticipation': this.participation,
                'firstName': this.firstName,
                'lastName': this.lastName,
                'title': this.title,
                'email': this.email,
                'phone': this.phone,
            }
           
           let user = JSON.parse(localStorage.getItem('user'));
           let provider = user.entity._id
          
           if(this.$route.path.includes('hospital-based')){
                api().put(`/providers/${provider}/hospitalProviders/${this.singleProvider._id}`, data).then((res)=>{
                this.$refs.success_modal.openPopUp('Hospital Based Provider successfully updated');
                 this.$store.commit('setSingleProviderInEmployerAdvisor', res.data.provider)
                
            })
           }else if(this.$route.path.includes('affiliates')){
                api().put(`/providers/${provider}/affiliateProviders/${this.singleProvider._id}`, data).then((res)=>{
                this.$refs.success_modal.openPopUp('Affiliate successfully updated');
                this.$store.commit('setSingleProviderInEmployerAdvisor', res.data.provider)
               
            })
           }else{
              api().put(`/providers/${provider}/groupProviders/${this.singleProvider._id}`, data).then((res)=>{
                this.$refs.success_modal.openPopUp('Provider successfully updated');
                this.$store.commit('setSingleProviderInEmployerAdvisor', res.data.provider)
               
              })
           }
          
            
        },
        goBack(){
            if(this.$route.path.includes('affiliates')){
              this.$router.push(`/provider/affiliates/result`);
              }
              else if(this.$route.path.includes('hospital-based')){
                  this.$router.push(`/provider/hospital-based/result`);
              }
              else {
                  this.$router.push(`/provider/providers/result`);
              }
          },

       
        removeProvider(){
            this.$refs.dialog_delete.openPopUp('Are you sure that you want to delete this provider?');
        },
        confirmRemove(){
           let user = JSON.parse(localStorage.getItem('user'));
           let provider = user.entity._id
     
           if(this.$route.path.includes('hospital-based')){
             api().delete(`/providers/${provider}/hospitalProviders/${this.singleProvider._id}`).then((res)=>{
                if(res){
                 this.$router.push(`/provider/hospital-based`);
                }
            })
           }else if(this.$route.path.includes('affiliates')){
             api().delete(`/providers/${provider}/affiliateProviders/${this.singleProvider._id}`).then((res)=>{
                this.$router.push(`/provider/affiliates`);
            })
           }else{
               api().delete(`/providers/${provider}/groupProviders/${this.singleProvider._id}`).then((res)=>{
                if(res){
                  this.$router.push(`/provider/providers`);
                }
            })
           }
       
        },

        closePopUp(){
            this.dialog = false
        }
      },
    }

</script>
