<template>
    <div class="add-client-form no-padding">
        <div class="form-box row ">
        
            <v-col class="no-padding" md="10" lg="9" xl="10" cols="12">
                <h3 class="font"> Prior Authorization Request Detail</h3>
            </v-col>
            <v-col md="2" lg="3" xl="2" class="text-end" >
                <v-row class="text-end justify-end">
                        <v-btn class="blue-buttons mr-3" @click="denyModal = true">Deny</v-btn>
                        <v-btn class="blue-buttons" @click="approveModal = true">Approve</v-btn>
                </v-row>
            </v-col>
      
            <v-row class="mt-10 scroll-row" >
                <v-col md="12">
                        <h5 class="text-gray font underline-text">Member Information</h5>
                        <v-simple-table dense >
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th class="text-left text-gray font">
                                        First Name
                                    </th>
                                    <th class="text-left text-gray font">
                                    Last Name
                                    </th>
                                    <th class="text-left text-gray font">
                                        Sex
                                    </th>
                                    <th class="text-left text-gray font">
                                        Status on Plan
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="font">
                                            <a >Valarie</a> <!-- {{singlePrior.firstName}} -->
                                        </td>
                                        <td class="font">
                                            <a >Vaughn</a> <!-- {{singlePrior.lastName}} -->
                                        </td>
                                        <td>
                                            Female <!-- {{singlePrior.gender}} -->
                                        </td>
                                        <td class="font">
                                            Active <!-- {{singlePrior.status}} -->
                                        </td>

                                    
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>

                </v-col>
                    <v-col md="12">
                        <h5 class="text-gray font underline-text">Provider Information</h5>
                        <v-simple-table dense>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left text-gray font">
                                        Organization Name
                                    </th>
                                    <th class="text-left text-gray font">
                                        Type
                                    </th>
                                    <th class="text-left text-gray font">
                                        Quality Rating
                                    </th>
                                    <th class="text-left text-gray font">
                                        City
                                    </th>
                                    <th class="text-left text-gray font">
                                        Status
                                    </th>
                                
                                </tr>
                            </thead>
                            <tbody>
                                    <tr>
                                        <td class="font">
                                            <a >Manatee Memorial Hospital</a> <!-- {{singlePrior.organization}} -->
                                        </td>
                                        <td class="font">
                                            Hospital <!-- {{singlePrior.type}} -->
                                        </td>
                                        <td>
                                            <!-- {{singlePrior.rating}} -->
                                            <v-rating
                                                v-model.trim="rating"
                                                color="yellow darken-3"
                                                background-color="grey darken-1"
                                                empty-icon="$ratingFull"
                                                half-increments
                                                
                                            
                                            ></v-rating>
                                        </td>
                                        <td class="font">
                                            Tampa <!-- {{singlePrior.city}} -->
                                        </td>
                                        <td class="font green-text">
                                            Matched <!-- {{singlePrior.status}} -->
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>

                </v-col> 
                    <v-col md="12">
                        <h5 class="text-gray font underline-text">Procedure Information</h5>
                        <v-simple-table dense>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left text-gray font">
                                        Procedure Code Type
                                    </th>
                                    <th class="text-left text-gray font">
                                        Procedure Code
                                    </th>
                                    <th class="text-left text-gray font">
                                        Procedure Description
                                    </th>
                                    <th class="text-left text-gray font">
                                        
                                    </th>
                                    <th class="text-left text-gray font">
                                    Estimated Procedure Cost (USD)
                                    </th>
                                
                                </tr>
                            </thead>
                            <tbody>
                                    <tr>
                                        <td class="font">
                                            BUNDLE <!-- {{singlePrior.comments}} -->
                                        </td>
                                        <td class="font">
                                            NA <!-- {{singlePrior.comments}} -->
                                        </td>
                                        <td>
                                        Total Knee Arthoscopy <!-- {{singlePrior.comments}} -->
                                        </td>
                                        <td class="font">
                                            
                                        </td>
                                        <td class="font">
                                        $20,000 <!-- {{singlePrior.comments}} -->
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>

                </v-col>
                    <v-col md="12">
                        <h5 class="text-gray font underline-text">Procedure Prerequisite Requirements</h5>
                        <v-simple-table dense>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left text-gray font">
                                        Name
                                    </th>
                                    <th class="text-left text-gray font">
                                        Category
                                    </th>
                                    <th class="text-left text-gray font">
                                        Code
                                    </th>
                                    <th class="text-left text-gray font">
                                        Description
                                    </th>
                                    <th class="text-left text-gray font text-center">
                                       Prerequisite Requirements
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in reqItems" :key="item.code">
                                        <td class="font">
                                           {{item.name}}
                                        </td>
                                        <td class="font">
                                           {{item.category}}
                                        </td>
                                        <td class="font">
                                           {{item.code}}
                                        </td>
                                        <td class="font">
                                           {{item.description}}
                                        </td>
                                        <td class="font text-center">
                                            <svg v-if="item.actions == 'true'" width="20" height="20" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.59961 14.3057L9.21294 18.0145C9.31207 18.1626 9.44513 18.2848 9.60105 18.3711C9.75696 18.4574 9.93123 18.5052 10.1093 18.5105C10.2874 18.5158 10.4643 18.4785 10.625 18.4017C10.7858 18.3249 10.926 18.2108 11.0337 18.0689L19.3996 7.48438" stroke="#069B0C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M1 13C1 16.1826 2.26428 19.2348 4.51472 21.4853C6.76516 23.7357 9.8174 25 13 25C16.1826 25 19.2348 23.7357 21.4853 21.4853C23.7357 19.2348 25 16.1826 25 13C25 9.8174 23.7357 6.76516 21.4853 4.51472C19.2348 2.26428 16.1826 1 13 1C9.8174 1 6.76516 2.26428 4.51472 4.51472C2.26428 6.76516 1 9.8174 1 13V13Z" stroke="#069B0C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            <svg v-if="item.actions == 'false'" width="20" height="20" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 13C1 16.1826 2.26428 19.2348 4.51472 21.4853C6.76516 23.7357 9.8174 25 13 25C16.1826 25 19.2348 23.7357 21.4853 21.4853C23.7357 19.2348 25 16.1826 25 13C25 9.8174 23.7357 6.76516 21.4853 4.51472C19.2348 2.26428 16.1826 1 13 1C9.8174 1 6.76516 2.26428 4.51472 4.51472C2.26428 6.76516 1 9.8174 1 13V13Z" stroke="#D00000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M8.19922 17.8002L17.7992 8.2002" stroke="#D00000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M17.7992 17.8002L8.19922 8.2002" stroke="#D00000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </td>
                                    </tr>
                                   
                                </tbody>
                            </template>
                        </v-simple-table>

                    </v-col>
                    
                    <v-col md="12">
                        <h5 class="text-gray font underline-text">Comments</h5>
                        <h5 class="font" cols="12">
                            <!-- {{singlePrior.comments}} -->
                            This is a comment entered by the provider requesting the Prior Authorization.
                        </h5>
                    </v-col>
                    <v-col md="12" cols="12" align="end">
                        <v-btn class="blue-buttons" @click="goToTheTable">
                            Cancel
                        </v-btn>
                    </v-col>
            </v-row>

        </div>
           <v-dialog
            v-model.trim="approveModal"
            width="550"
            class="success-modal"
            >
            <v-card>
             <v-card-text class="success-modal" >
                <div>
                    <h3 class="text-underline font">Prior Authorization Request Approval</h3>
                </div>
                <div class="mt-4">
                    <div class="font">
                        You have indicated that you want to approve this prior authorization request. <br><br>
                        When you click "Confirm Approval" bellow, the provider's request for prior authorization will be approved,
                        and a prior authorization number will be generated.
                     </div>  
                     <br><br>
                     <div class="text-center text-underline font">
                         Prior Authorization Number 
                        <!-- {{priorNum}} -->
                     </div>   
                     <br><br>
                     <div class="text-center font">
                         A Prior authorizaton number will be presented here after you confirm your approval.
                     </div>   
                      <br><br><br><br>
                     <div class="text-center font">
                         We will save this informatin on your Apaly health portal, in the Member's account.
                     </div>  
                     <br>
                     <div class="row justify-space-between">
                         <v-btn class="blue-buttons"  @click="approveModal = false">Cancel</v-btn>
                         <v-btn class="blue-buttons"  @click="confirmApproval">Confirm Approval</v-btn>
                     </div> 
                </div>
            
                </v-card-text>
            </v-card>
           </v-dialog>
             <v-dialog
            v-model.trim="denyModal"
            width="550"
            class="success-modal"
            >
            <v-card>
             <v-card-text class="success-modal" >
                <div>
                    <h3 class="text-underline font">Prior Authorization Request Denial</h3>
                </div>
                <div class="mt-4">
                    <div class="font">
                        You have indicated that you want to diny this prior authorization request. <br><br>
                        Please provide a reason for the denial below that will shared with the requesting provider.
                     </div>  
                     <br>
                     <div class="  font">
                      <label for="">Reason for Denial</label>
                       <v-select solo v-model.trim="denialReason"></v-select>
                     </div>   
                      <div class="  font">
                       <label for="">Comments</label>
                       <v-textarea solo v-model.trim="comments"></v-textarea>
                     </div>
                     <div class=" font">
                        We will save this information on your Apaly Health portal, in the Member's account.
                     </div>   
                      
                     <br>
                     <div class="row justify-space-between">
                         <v-btn class="blue-buttons" @click="denyModal = false">Cancel</v-btn>
                         <v-btn class="blue-buttons" @click="confirmDeny">Confirm Denial</v-btn>
                     </div> 
                </div>
            
                </v-card-text>
            </v-card>
           </v-dialog>
      
    </div>
</template>

<script>
import api from '../../../../../services/api'
export default {
    data(){
        return {
            rating:3,
            denyModal:false,
            approveModal:false,
            denialReason:'',
            comments:"",
            reqItems:[
                {
                    name:'MRI Knee',
                    category:'CPT',
                    code:'G187',
                    description:'CT Imaging of Knee',
                    actions:'false'
                },
                {
                    name:'14 Days of NSAIDs',
                    category:'Medication',
                    code:'None',
                    description:'Pretreatment with anti-inflammatory',
                    actions:'true'
                },
            ]

        }
    },
    computed:{
        routeParams(){
            return this.$route.params.name
        },
        singleMember(){
          
          return this.$store.getters.geteEmployerSingleMember
        }

    },
    methods:{
        goToTheTable(){
             this.$router.push(`/employer-advisor/employers/${this.routeParams}/prior-auth`)
        }, 
        confirmDeny(){
            // api().post(``).then((res)=>{
            //     console.log(res)
            // this.denyModal = false
            // })
        },
        confirmApproval(){
            // let data = {
            //     'reasons':this.denialReason,
            //     'comments' : this.comments
            // }
           // api().post(`../${this.singleMember._id}`, data).then((res)=>{
            //     console.log(res)
            // this.denyModal = false
            // })
        }
    }
}
</script>
