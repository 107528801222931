<template>
  <div class="container-fluid">
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <v-row class="main-row">
      <!-- <v-col lg="4" md="12" sm="12">
        <BlueSideBar :tab="tab"></BlueSideBar>
      </v-col> -->
      <v-col lg="12" md="12" sm="12">
        <!-- <div class="end-div">
          <h3 v-if="this.$route.path == '/login' || this.$route.path == '/new-login'" class="tiny-font text-gray mobile-font">
            Are you a plan Member? 
            <a @click="goToMarketplace()">Access Here</a>
          </h3>
          <h3 v-else class="tiny-font mobile-font">
            Already have an Apaly account?
            <a @click="$router.push('/login')">Sign in</a>
          </h3>
        </div> -->

        <div
          v-if="
            this.$route.path == '/login' ||
            (this.$route.path.includes('deny') &&
              this.$route.path.includes('visitPassRequests')) ||
            (this.$route.path.includes('approve') &&
              this.$route.path.includes('visitPassRequests'))
          "
        >
          <New></New>
        </div>
        <div v-else-if="this.$route.path == '/register'">
          <NewRegister></NewRegister>
        </div>

        <div v-else-if="this.$route.path == '/change-pass'">
          <ChangePass></ChangePass>
        </div>
        <div v-else-if="this.$route.path == '/set-pass'">
          <SetPass></SetPass>
        </div>
        <div
          v-else-if="
            this.$route.query &&
            typeof this.$route.query.resetPassword != 'undefined' &&
            this.$route.query.resetPassword == null
          "
        >
          <ResetPass></ResetPass>
        </div>
        <div v-else class="stepper">
          <RegisterComp></RegisterComp>
        </div>
      </v-col>
    </v-row>
    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
  </div>
</template>

<script>
import api from "../services/api";
import successModal from "../components/TablesTemplate/SuccessDialog.vue";
import errorModal from "../components/TablesTemplate/ErrorDialog.vue";

import BlueSideBar from "../components/LoginRegister/BlueSideBar.vue";
import Login from "../components/LoginRegister/Login.vue";
import New from "../components/LoginRegister/NewLoginPage.vue";
import NewRegister from "../components/LoginRegister/NewRegister.vue";
import Invited from "../components/LoginRegister/InviteUser.vue";
import ChangePass from "../components/LoginRegister/ChangePass.vue";
import SetPass from "../components/LoginRegister/SetPass.vue";
import ResetPass from "../components/LoginRegister/ResetPassword.vue";
import RegisterComp from "../components/LoginRegister/Register.vue";
export default {
  components: {
    Login,
    New,
    NewRegister,
    ChangePass,
    SetPass,
    BlueSideBar,
    RegisterComp,
    ResetPass,
    successModal,
    errorModal,
    Invited,
  },
  data() {
    return {
      login: true,
      valid: false,
      loginOrRegister: false,
      firstname: "",
      lastname: "",
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length <= 10 || "Name must be less than 10 characters",
      ],
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      tab: "firstTab",
      desc: "",
      title: "",
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    goToMarketplace() {
      window.location = "https://member.apaly.net/marketplace";
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
      this.$router.replace("/login");
    },
    changeTab(tab) {
      this.tab = tab;
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
      this.$router.replace("/login");
    },
  },
  created() {
    if (
      this.$route.path.includes("/register/reasons") ||
      this.$route.path.includes("/register/check-domain") ||
      this.$route.path.includes("/register/organization-not-found") ||
      this.$route.path.includes("/register/create-organization") ||
      this.$route.path.includes("/register/domain-list") ||
      this.$route.path.includes("/register/confirm-org") ||
      this.$route.path.includes("/register/organization-found") ||
      this.$route.path.includes("/register/why-not")
    ) {
      this.$router.replace("/register");
    }
    if (this.$route.path.includes("/auth/verifyMembership/")) {
      api()
        .get(`/auth/verifyMembership/${this.$route.params.id}`)
        .then((res) => {
          if (res) {
            this.$refs.success_modal.openPopUp(
              "Membership granted successfully!"
            );
          }
        });
    }
    let splitet = this.$route.path.split("/");
    if (this.$route.path.includes("/unmaskRequest/deny")) {
      api()
        .get(
          `/advisors/${splitet[2]}/employers/${splitet[4]}/providers/${splitet[6]}/unmaskRequest/accept?token=${this.$route.query.token}`
        )
        .then((res) => {
          if (res) {
            this.$refs.success_modal.openPopUp("Access Grant successfully!");
          }
        });
    } else if (this.$route.path.includes("/unmaskRequest/accept")) {
      api()
        .get(
          `/advisors/${splitet[2]}/employers/${splitet[4]}/providers/${splitet[6]}/unmaskRequest/deny?token=${this.$route.query.token}`
        )
        .then((res) => {
          if (res) {
            this.$refs.success_modal.openPopUp("Access Denied successfully!");
          }
        });
    }

    if (
      this.$route.path.includes(
        `/auth/confirmOrganizationRequest/${this.$route.params.id}/accept`
      )
    ) {
      api()
        .get(
          `/auth/confirmOrganizationRequest/${this.$route.params.id}/accept?token=${this.$route.query.token}`
        )
        .then((res) => {
          if (res) {
            this.$refs.success_modal.openPopUp(res.data.message);
          }
        });
    } else if (
      this.$route.path.includes(
        `/auth/confirmOrganizationRequest/${this.$route.params.id}/deny`
      )
    ) {
      api()
        .get(
          `/auth/confirmOrganizationRequest/${this.$route.params.id}/deny?token=${this.$route.query.token}`
        )
        .then((res) => {
          if (res) {
            this.$router.replace("/register/reasons");
            // this.$refs.success_modal.openPopUp(res.data.message);
          }
        });
    }

    if (
      this.$route.path.includes("deny") &&
      this.$route.path.includes("visitPassRequests")
    ) {
      let spliced = this.$route.path.split("/");
      let memberId = spliced[2];
      let passId = spliced[4];
      api()
        .get(`/members/${memberId}/visitPassRequests/${passId}/deny`)
        .then((res) => {
          if (res) {
            this.$refs.success_modal.openPopUp(
              "Visit Pass Denied Successfully!"
            );
          }
        });
    } else if (
      this.$route.path.includes("approve") &&
      this.$route.path.includes("visitPassRequests")
    ) {
      let spliced = this.$route.path.split("/");
      let memberId = spliced[2];
      let passId = spliced[4];
      api()
        .get(`/members/${memberId}/visitPassRequests/${passId}/approve`)
        .then((res) => {
          if (res) {
            this.$refs.success_modal.openPopUp(
              "Visit Pass Approved Successfully!"
            );
          }
        });
    }

    if (this.$route.path.includes("acceptEmployerAccess")) {
      let spliced = this.$route.path.split("/");
      let providerID = spliced[2];
      let emloyerId = spliced[4];
      let addedProviderId = spliced[6];

      api()
        .get(
          `/providers/${providerID}/employers/${emloyerId}/addedProvider/${addedProviderId}/acceptEmployerAccess?token=${this.$route.query.token}`
        )
        .then((res) => {
          if (res) {
            this.$refs.success_modal.openPopUp(
              "Employer Access has been granted successfully!"
            );
          }
        });
    } else if (this.$route.path.includes("denyEmployerAccess")) {
      let spliced = this.$route.path.split("/");
      let providerID = spliced[2];
      let emloyerId = spliced[4];
      let addedProviderId = spliced[6];

      api()
        .get(
          `/providers/${providerID}/employers/${emloyerId}/addedProvider/${addedProviderId}/denyEmployerAccess?token=${this.$route.query.token}`
        )
        .then((res) => {
          if (res) {
            this.$refs.success_modal.openPopUp(
              "Employer Access has been denied by the Provider!"
            );
          }
        });
    }

    if (this.$route.path.includes("/solutions/approve/dpc/provider")) {
      api()
        .get(`/solutions/approve/dpc/provider?token=${this.$route.query.token}`)
        .catch((err) => {
          if (err) {
            this.$refs.error_modal.openPopUp(err.response.data);
          }
        })
        .then((res) => {
          if (res) {
            this.$refs.success_modal.openPopUp(
              "Solution is activated for this provider"
            );
          }
        });
    }
  },
};
</script>
<style scoped>
@media only screen and (max-width: 1264px) {
  .mobile-font {
    font-size: 16px !important;
  }
}
</style>
