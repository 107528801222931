const state = {
    solution:null,
    solutionsTab:'all'
 }  
  
  const getters = {
      getSingleSolution(state){
          return state.solution
      },
      getSolutionTab(state){
          return state.solutionsTab
      }
  }
  
  const mutations = {
      singleSolution(state, solution){
          state.solution = solution
       },
       changeTabMarketPlace(state,tab){
           state.solutionsTab = tab
       }
  }
  
  const actions = {
  }
  
  export default {
      state,
      getters,
      mutations,
      actions
  }
