<template>
  <div :class="windowWidth ? 'mainSideBarDiv' : 'mainSideBarMobile'">
    <v-navigation-drawer
      v-if="windowWidth"
      :width="!mini ? '800' : '69'"
      permanent
      class="white-sidebar"
    >
      <div>
        <v-divider></v-divider>
        <v-list-item
          two-line
          class="mt-3"
          :class="
            watchActiveItem == 'clients' ? 'pointer active' : 'pointer inactive'
          "
          @click="activeItem('clients')"
        >
          <v-list-item-icon
            class="list-item-icon no-padding"
            :class="
              !mini
                ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-xl-4 mt-lg-2 mt-md-1'
                : 'ml-4 mt-xl-4 mt-lg-2 mt-md-1'
            "
          >
            <svg
              width="17"
              height="18"
              viewBox="0 0 17 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.8 12.7334H1.96C1.70539 12.7334 1.46121 12.8458 1.28118 13.0458C1.10114 13.2459 1 13.5172 1 13.8001V17.0001H5.8V12.7334Z"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14.4396 12.7334H10.5996V17.0001H15.3996V13.8001C15.3996 13.5172 15.2985 13.2459 15.1184 13.0458C14.9384 12.8458 14.6942 12.7334 14.4396 12.7334Z"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.64078 8.4668H6.76078C6.50617 8.4668 6.26199 8.57918 6.08196 8.77922C5.90192 8.97925 5.80078 9.25057 5.80078 9.53346V17.0001H10.6008V9.53346C10.6008 9.25057 10.4996 8.97925 10.3196 8.77922C10.1396 8.57918 9.89539 8.4668 9.64078 8.4668Z"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6.51953 2.86667C6.51953 3.36174 6.69653 3.83653 7.01159 4.1866C7.32665 4.53667 7.75397 4.73333 8.19953 4.73333C8.64509 4.73333 9.07241 4.53667 9.38747 4.1866C9.70253 3.83653 9.87953 3.36174 9.87953 2.86667C9.87953 2.3716 9.70253 1.8968 9.38747 1.54673C9.07241 1.19667 8.64509 1 8.19953 1C7.75397 1 7.32665 1.19667 7.01159 1.54673C6.69653 1.8968 6.51953 2.3716 6.51953 2.86667V2.86667Z"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.2791 6.33349C10.0227 5.99887 9.70382 5.73013 9.3447 5.54584C8.98557 5.36156 8.59472 5.26611 8.19914 5.26611C7.80356 5.26611 7.41271 5.36156 7.05358 5.54584C6.69446 5.73013 6.37563 5.99887 6.11914 6.33349"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.6406 7.66647C11.6406 8.16154 11.8176 8.63634 12.1327 8.9864C12.4477 9.33647 12.8751 9.53314 13.3206 9.53314C13.7662 9.53314 14.1935 9.33647 14.5086 8.9864C14.8236 8.63634 15.0006 8.16154 15.0006 7.66647C15.0006 7.1714 14.8236 6.69661 14.5086 6.34654C14.1935 5.99647 13.7662 5.7998 13.3206 5.7998C12.8751 5.7998 12.4477 5.99647 12.1327 6.34654C11.8176 6.69661 11.6406 7.1714 11.6406 7.66647V7.66647Z"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15.3995 11.1331C15.0541 10.6818 14.5972 10.353 14.0853 10.1873C13.5734 10.0216 13.029 10.0263 12.5195 10.2008"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1.40039 7.66647C1.40039 7.91161 1.44385 8.15434 1.52827 8.38081C1.6127 8.60729 1.73645 8.81307 1.89245 8.9864C2.04845 9.15974 2.23366 9.29724 2.43748 9.39105C2.64131 9.48486 2.85977 9.53314 3.08039 9.53314C3.30101 9.53314 3.51947 9.48486 3.7233 9.39105C3.92713 9.29724 4.11233 9.15974 4.26833 8.9864C4.42433 8.81307 4.54808 8.60729 4.63251 8.38081C4.71694 8.15434 4.76039 7.91161 4.76039 7.66647C4.76039 7.42134 4.71694 7.1786 4.63251 6.95213C4.54808 6.72565 4.42433 6.51987 4.26833 6.34654C4.11233 6.1732 3.92713 6.03571 3.7233 5.9419C3.51947 5.84809 3.30101 5.7998 3.08039 5.7998C2.85977 5.7998 2.64131 5.84809 2.43748 5.9419C2.23366 6.03571 2.04845 6.1732 1.89245 6.34654C1.73645 6.51987 1.6127 6.72565 1.52827 6.95213C1.44385 7.1786 1.40039 7.42134 1.40039 7.66647V7.66647Z"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1 11.1336C1.25702 10.7997 1.57597 10.5315 1.93498 10.3474C2.294 10.1633 2.68459 10.0676 3.08 10.0669C3.35011 10.0671 3.61867 10.1122 3.8768 10.2006"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title
              class="
                d-flex
                align-center
                font-quicksand-medium-12
                text-gray-darker
                mt-1
              "
            >
              CLIENTS
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          two-line
          :class="
            watchActiveItem == 'solutions'
              ? 'pointer active'
              : 'pointer inactive'
          "
          @click="activeItem('solutions')"
        >
          <v-list-item-icon
            class="list-item-icon no-padding"
            :class="
              !mini
                ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-xl-3 mt-lg-2 mt-md-1 '
                : 'ml-4 mt-xl-3 mt-lg-2 mt-md-1'
            "
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 2.17383L2.2 3.6877V7.26193C2.168 8.61257 3.7336 9.82239 5.1992 11.0642"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M4.58411 6.28734L5.99931 7.7372C6.08861 7.82888 6.19509 7.90207 6.31267 7.95259C6.43025 8.00311 6.55662 8.02997 6.68458 8.03164C6.81254 8.03331 6.93958 8.00976 7.05843 7.96233C7.17729 7.9149 7.28565 7.84452 7.37731 7.7552C7.46897 7.66589 7.54215 7.55939 7.59266 7.44179C7.64317 7.32419 7.67003 7.19779 7.6717 7.06981C7.67337 6.94182 7.64983 6.81477 7.6024 6.69589C7.55498 6.57701 7.48461 6.46863 7.39531 6.37696L5.19451 4.13096V2.96035C5.07528 2.2589 4.80861 1.59074 4.41211 1"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.19922 6.91162V16.6029C5.19922 16.9213 5.32565 17.2265 5.55069 17.4516C5.77573 17.6767 6.08096 17.8032 6.39922 17.8032H12.3992"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M19.0006 17.8287L17.8006 16.3148V12.7414C17.8286 11.574 16.6638 10.5122 15.4006 9.44482V13.7L14.0006 12.2661C13.8203 12.081 13.5738 11.9751 13.3153 11.9717C13.0569 11.9683 12.8077 12.0677 12.6226 12.2481C12.4375 12.4285 12.3316 12.675 12.3282 12.9335C12.3248 13.192 12.4243 13.4412 12.6046 13.6264L14.8086 15.8732V17.043C14.9284 17.7433 15.1951 18.4103 15.591 19.0001"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.782 14.8022H7.59961"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.79961 11.8018H7.59961"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.6004 4.60064C16.6004 5.31278 16.3893 6.00893 15.9937 6.60105C15.5981 7.19317 15.0359 7.65467 14.3781 7.9272C13.7202 8.19972 12.9964 8.27103 12.2981 8.1321C11.5997 7.99316 10.9583 7.65024 10.4548 7.14668C9.95134 6.64312 9.60847 6.00155 9.46957 5.30309C9.33066 4.60463 9.40195 3.88067 9.67443 3.22273C9.9469 2.5648 10.4083 2.00246 11.0003 1.60682C11.5924 1.21117 12.2884 1 13.0004 1"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.5996 1.89941L13.3196 5.18C13.2777 5.22219 13.2278 5.25567 13.1729 5.27852C13.118 5.30137 13.0591 5.31313 12.9996 5.31313C12.9402 5.31313 12.8813 5.30137 12.8263 5.27852C12.7714 5.25567 12.7216 5.22219 12.6796 5.18L11.6484 4.14781"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title
              class="
                d-flex
                align-center
                font-quicksand-medium-12
                text-gray-darker
              "
            >
              SOLUTIONS
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          two-line
          :class="
            watchActiveItem == 'invoices'
              ? 'pointer active'
              : 'pointer inactive'
          "
          @click="activeItem('invoices')"
        >
          <v-list-item-icon
            class="list-item-icon no-padding"
            :class="
              !mini
                ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-xl-3 mt-lg-2 mt-md-1 '
                : 'ml-4 mt-xl-3 mt-lg-2 mt-md-1'
            "
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 2.17383L2.2 3.6877V7.26193C2.168 8.61257 3.7336 9.82239 5.1992 11.0642"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M4.58411 6.28734L5.99931 7.7372C6.08861 7.82888 6.19509 7.90207 6.31267 7.95259C6.43025 8.00311 6.55662 8.02997 6.68458 8.03164C6.81254 8.03331 6.93958 8.00976 7.05843 7.96233C7.17729 7.9149 7.28565 7.84452 7.37731 7.7552C7.46897 7.66589 7.54215 7.55939 7.59266 7.44179C7.64317 7.32419 7.67003 7.19779 7.6717 7.06981C7.67337 6.94182 7.64983 6.81477 7.6024 6.69589C7.55498 6.57701 7.48461 6.46863 7.39531 6.37696L5.19451 4.13096V2.96035C5.07528 2.2589 4.80861 1.59074 4.41211 1"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.19922 6.91162V16.6029C5.19922 16.9213 5.32565 17.2265 5.55069 17.4516C5.77573 17.6767 6.08096 17.8032 6.39922 17.8032H12.3992"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M19.0006 17.8287L17.8006 16.3148V12.7414C17.8286 11.574 16.6638 10.5122 15.4006 9.44482V13.7L14.0006 12.2661C13.8203 12.081 13.5738 11.9751 13.3153 11.9717C13.0569 11.9683 12.8077 12.0677 12.6226 12.2481C12.4375 12.4285 12.3316 12.675 12.3282 12.9335C12.3248 13.192 12.4243 13.4412 12.6046 13.6264L14.8086 15.8732V17.043C14.9284 17.7433 15.1951 18.4103 15.591 19.0001"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.782 14.8022H7.59961"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.79961 11.8018H7.59961"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.6004 4.60064C16.6004 5.31278 16.3893 6.00893 15.9937 6.60105C15.5981 7.19317 15.0359 7.65467 14.3781 7.9272C13.7202 8.19972 12.9964 8.27103 12.2981 8.1321C11.5997 7.99316 10.9583 7.65024 10.4548 7.14668C9.95134 6.64312 9.60847 6.00155 9.46957 5.30309C9.33066 4.60463 9.40195 3.88067 9.67443 3.22273C9.9469 2.5648 10.4083 2.00246 11.0003 1.60682C11.5924 1.21117 12.2884 1 13.0004 1"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.5996 1.89941L13.3196 5.18C13.2777 5.22219 13.2278 5.25567 13.1729 5.27852C13.118 5.30137 13.0591 5.31313 12.9996 5.31313C12.9402 5.31313 12.8813 5.30137 12.8263 5.27852C12.7714 5.25567 12.7216 5.22219 12.6796 5.18L11.6484 4.14781"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title
              class="
                d-flex
                align-center
                font-quicksand-medium-12
                text-gray-darker
              "
            >
              INVOICES
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-navigation-drawer>
    <!-- Mobile -->
    <v-navigation-drawer v-else permanent class="white-sidebar-onmobile mt-3">
      <div class="ml-3">
        <v-divider></v-divider>
        <v-list-item
          two-line
          class="mt-3"
          :class="
            watchActiveItem == 'clients' ? 'pointer active' : 'pointer inactive'
          "
          @click="activeItem('clients')"
        >
          <v-list-item-icon
            class="list-item-icon no-padding"
            :class="
              !mini
                ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-xl-4 mt-lg-2 mt-md-1'
                : 'ml-4 mt-xl-4 mt-lg-2 mt-md-1'
            "
          >
            <svg
              width="17"
              height="18"
              viewBox="0 0 17 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.8 12.7334H1.96C1.70539 12.7334 1.46121 12.8458 1.28118 13.0458C1.10114 13.2459 1 13.5172 1 13.8001V17.0001H5.8V12.7334Z"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14.4396 12.7334H10.5996V17.0001H15.3996V13.8001C15.3996 13.5172 15.2985 13.2459 15.1184 13.0458C14.9384 12.8458 14.6942 12.7334 14.4396 12.7334Z"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.64078 8.4668H6.76078C6.50617 8.4668 6.26199 8.57918 6.08196 8.77922C5.90192 8.97925 5.80078 9.25057 5.80078 9.53346V17.0001H10.6008V9.53346C10.6008 9.25057 10.4996 8.97925 10.3196 8.77922C10.1396 8.57918 9.89539 8.4668 9.64078 8.4668Z"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6.51953 2.86667C6.51953 3.36174 6.69653 3.83653 7.01159 4.1866C7.32665 4.53667 7.75397 4.73333 8.19953 4.73333C8.64509 4.73333 9.07241 4.53667 9.38747 4.1866C9.70253 3.83653 9.87953 3.36174 9.87953 2.86667C9.87953 2.3716 9.70253 1.8968 9.38747 1.54673C9.07241 1.19667 8.64509 1 8.19953 1C7.75397 1 7.32665 1.19667 7.01159 1.54673C6.69653 1.8968 6.51953 2.3716 6.51953 2.86667V2.86667Z"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.2791 6.33349C10.0227 5.99887 9.70382 5.73013 9.3447 5.54584C8.98557 5.36156 8.59472 5.26611 8.19914 5.26611C7.80356 5.26611 7.41271 5.36156 7.05358 5.54584C6.69446 5.73013 6.37563 5.99887 6.11914 6.33349"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.6406 7.66647C11.6406 8.16154 11.8176 8.63634 12.1327 8.9864C12.4477 9.33647 12.8751 9.53314 13.3206 9.53314C13.7662 9.53314 14.1935 9.33647 14.5086 8.9864C14.8236 8.63634 15.0006 8.16154 15.0006 7.66647C15.0006 7.1714 14.8236 6.69661 14.5086 6.34654C14.1935 5.99647 13.7662 5.7998 13.3206 5.7998C12.8751 5.7998 12.4477 5.99647 12.1327 6.34654C11.8176 6.69661 11.6406 7.1714 11.6406 7.66647V7.66647Z"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15.3995 11.1331C15.0541 10.6818 14.5972 10.353 14.0853 10.1873C13.5734 10.0216 13.029 10.0263 12.5195 10.2008"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1.40039 7.66647C1.40039 7.91161 1.44385 8.15434 1.52827 8.38081C1.6127 8.60729 1.73645 8.81307 1.89245 8.9864C2.04845 9.15974 2.23366 9.29724 2.43748 9.39105C2.64131 9.48486 2.85977 9.53314 3.08039 9.53314C3.30101 9.53314 3.51947 9.48486 3.7233 9.39105C3.92713 9.29724 4.11233 9.15974 4.26833 8.9864C4.42433 8.81307 4.54808 8.60729 4.63251 8.38081C4.71694 8.15434 4.76039 7.91161 4.76039 7.66647C4.76039 7.42134 4.71694 7.1786 4.63251 6.95213C4.54808 6.72565 4.42433 6.51987 4.26833 6.34654C4.11233 6.1732 3.92713 6.03571 3.7233 5.9419C3.51947 5.84809 3.30101 5.7998 3.08039 5.7998C2.85977 5.7998 2.64131 5.84809 2.43748 5.9419C2.23366 6.03571 2.04845 6.1732 1.89245 6.34654C1.73645 6.51987 1.6127 6.72565 1.52827 6.95213C1.44385 7.1786 1.40039 7.42134 1.40039 7.66647V7.66647Z"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1 11.1336C1.25702 10.7997 1.57597 10.5315 1.93498 10.3474C2.294 10.1633 2.68459 10.0676 3.08 10.0669C3.35011 10.0671 3.61867 10.1122 3.8768 10.2006"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title
              class="
                d-flex
                align-center
                font-quicksand-medium-12
                text-gray-darker
                mt-1
              "
            >
              CLIENTS
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          two-line
          :class="
            watchActiveItem == 'invoices'
              ? 'pointer active'
              : 'pointer inactive'
          "
          @click="activeItem('invoices')"
        >
          <v-list-item-icon
            class="list-item-icon no-padding"
            :class="
              !mini
                ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-xl-3 mt-lg-2 mt-md-1 '
                : 'ml-4 mt-xl-3 mt-lg-2 mt-md-1'
            "
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 2.17383L2.2 3.6877V7.26193C2.168 8.61257 3.7336 9.82239 5.1992 11.0642"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M4.58411 6.28734L5.99931 7.7372C6.08861 7.82888 6.19509 7.90207 6.31267 7.95259C6.43025 8.00311 6.55662 8.02997 6.68458 8.03164C6.81254 8.03331 6.93958 8.00976 7.05843 7.96233C7.17729 7.9149 7.28565 7.84452 7.37731 7.7552C7.46897 7.66589 7.54215 7.55939 7.59266 7.44179C7.64317 7.32419 7.67003 7.19779 7.6717 7.06981C7.67337 6.94182 7.64983 6.81477 7.6024 6.69589C7.55498 6.57701 7.48461 6.46863 7.39531 6.37696L5.19451 4.13096V2.96035C5.07528 2.2589 4.80861 1.59074 4.41211 1"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.19922 6.91162V16.6029C5.19922 16.9213 5.32565 17.2265 5.55069 17.4516C5.77573 17.6767 6.08096 17.8032 6.39922 17.8032H12.3992"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M19.0006 17.8287L17.8006 16.3148V12.7414C17.8286 11.574 16.6638 10.5122 15.4006 9.44482V13.7L14.0006 12.2661C13.8203 12.081 13.5738 11.9751 13.3153 11.9717C13.0569 11.9683 12.8077 12.0677 12.6226 12.2481C12.4375 12.4285 12.3316 12.675 12.3282 12.9335C12.3248 13.192 12.4243 13.4412 12.6046 13.6264L14.8086 15.8732V17.043C14.9284 17.7433 15.1951 18.4103 15.591 19.0001"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.782 14.8022H7.59961"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.79961 11.8018H7.59961"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.6004 4.60064C16.6004 5.31278 16.3893 6.00893 15.9937 6.60105C15.5981 7.19317 15.0359 7.65467 14.3781 7.9272C13.7202 8.19972 12.9964 8.27103 12.2981 8.1321C11.5997 7.99316 10.9583 7.65024 10.4548 7.14668C9.95134 6.64312 9.60847 6.00155 9.46957 5.30309C9.33066 4.60463 9.40195 3.88067 9.67443 3.22273C9.9469 2.5648 10.4083 2.00246 11.0003 1.60682C11.5924 1.21117 12.2884 1 13.0004 1"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.5996 1.89941L13.3196 5.18C13.2777 5.22219 13.2278 5.25567 13.1729 5.27852C13.118 5.30137 13.0591 5.31313 12.9996 5.31313C12.9402 5.31313 12.8813 5.30137 12.8263 5.27852C12.7714 5.25567 12.7216 5.22219 12.6796 5.18L11.6484 4.14781"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title
              class="
                d-flex
                align-center
                font-quicksand-medium-12
                text-gray-darker
              "
            >
              INVOICES
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script>
export default {
  name: "Tables",
  data() {
    return {
      miniVersion: true,
      item: "home",
      items: [
        {
          icon: "fas fa-home",
          title: "Home",
        },
        {
          icon: "fas fa-users",
          title: "Clients",
        },
        {
          icon: "fas fa-cog",
          title: "Settings",
        },
      ],
      route: this.$route.path,
    };
  },
  props: {
    windowWidth: {
      type: Boolean,
    },
    mini: {
      type: Boolean,
    },
  },

  computed: {
    routeParams() {
      return this.$route.params.name;
    },
    path() {
      return this.$route.path.replace(/%20/g, " ");
    },
    watchActiveItem() {
      if (this.$route.path.includes(this.$route.path.split("/")[2])) {
        this.item = this.$route.path.split("/")[2];
      }

      return this.item;
    },
  },
  created() {},
  methods: {
    activeItem(tab) {
      this.$store.commit("setNavItemFilter", false);
      if (this.$route.path != `/solution-vendor/${tab}`) {
        this.$router.push(`/solution-vendor/${tab}`);
      }
      if (!this.windowWidth) {
        this.$emit("closeSideBar");
      }
    },
  },
};
</script>

