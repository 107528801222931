
const state ={
    searchMarket:'',
    singleItemDPC:null,
    locationID:'',
    loc: '',
    tableArray:[ {
        member: {
          firstName: "Jackie",
          middleName: "/",
          lastName: " Jones",
          phone: "077123312",
          client: "Prudential",
          email: "jackie@htca.com",
          status: "active",
          zipCode: "1233",
        },
        employer: {
          companyName: "My Company",
          payerID: "32",
          groupID: "12",
          planID: "3",
          memberID: "4",
        },
        eligibilityStatus: "Eligibility Confirmed on 02/01/2022",
        lastVisit: "1 months",
        nextVisit: "04/21/2022",
        deductibleBalance: "1200",
        subscription: "Pending",
      },

      {
        member: {
          firstName: "John",
          middleName: "/",
          lastName: " Doe",
          phone: "077123312",
          client: "Prudential",
          email: "john@htca.com",
          status: "inactive",
          zipCode: "1233",
        },
        employer: {
          companyName: "Company Name",
          payerID: "33",
          groupID: "2",
          planID: "31",
          memberID: "43",
        },
        eligibilityStatus: "Eligibility Confirmed on 12/06/2022",
        lastVisit: "5 months",
        nextVisit: "Not Set",
        deductibleBalance: "1200",
        subscription: "Pending",
      },],
      providers: [],
      users: [],
      personnel: [],
      pendingRegisteredMember:{},
      logo:'',
      editTemplate: null,
}
const getters = {
    getMarketSearchVal(state){
        return state.searchMarket
    }, 
    getSingleItemDPC(){
     return state.singleItemDPC
    },
    getTableArray(){
        return state.tableArray
    },
    getLocationId(){
      return state.locationID
    },
    getPendingRegisteredMember(){
      return state.pendingRegisteredMember
    },
    getLogo(){
      return state.logo
    },
    getLoc(){
      return state.loc
    },
    getEditTemplate(){
      return state.editTemplate
    },
}
const mutations = {
    searchMarketplace(state, val){
        state.searchMarket = val
      },
      storeSingleItemDPC(state,item){
        state.singleItemDPC = item
      },
      setVendorsArray(state, array){
        state.tableArray.push(array); 
      },
      setLocationID(state,id){
        state.locationID = id
      },
      setAllProviders(state, data){
        state.providers = data
      },
      setAllUsers(state, data){
        state.users = data
      },
      setPersonnel(state, data){
        state.personnel = data
      },
      setPendingRegisteredMember(state,data){
        state.pendingRegisteredMember = data
      },
      setLogo(state,data){
        state.logo = data
      },
      setLoc(state,data){
        state.loc = data
      },
      setEditTemplate(state,data){
        state.editTemplate = data
      }
}

export default{
    state,
    getters,
    mutations,
}