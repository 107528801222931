const state ={
    memberProvider : null,
    templateForEdit:null,
}
const getters = {
    getSingleProviderMember(state){
        return state.memberProvider
      },
      getTemplateForEdit(state){
        return state.templateForEdit
      },
      getFilterMember(state){
        return state.title
      },
    
    
}
const mutations = {
      setSingleMemberProvider(state,member){
          state.memberProvider = member
      },
      setTemplateForEdit(state,template){
        state.templateForEdit = template
    },
    setFilterMember(state,title){
        state.title = title
    }
}

export default{
    state,
    getters,
    mutations,
}