import axios from 'axios'

export default function () {
    return axios.create({
        // baseURL: 'https://api.apaly.net',
        // baseURL: 'https://apalyprovidersapi.tdb.al',
        baseURL: process.env.VUE_APP_API,

        headers: {
            // 'content-type':'multipart/form-data',
            //eslint-disable-next-line
            'x-access-token': $cookies.get('token'),
            withCredentials: false
        }
    })
}
//https://api.apaly.net
//https://apalyprovidersapi.tdb.al
//sanoda8660@giftcv.com