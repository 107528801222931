<template>
  <div>
    <v-row class="mb-4 mt-3">
      <v-col class="col-md-4 col-lg-4 col-sm-5" cols="12">
        <h3>
          <span class="underline-text ml-2">Search Results</span>
        </h3>
      </v-col>
      <v-col class="col-lg-3 col-md-3" cols="12">
        <v-text-field
          class="input-control"
          prepend-inner-icon="mdi-magnify"
          @input="searchProviders"
          light
          dense
          outlined
          placeholder="Search Providers"
        >
        </v-text-field>
      </v-col>
      <v-col class="col-xl-5 col-md-5 text-end">
        <span class="mr-3 text-gray">
          Number of Searched Providers: {{ items.length }}
        </span>
      </v-col>
    </v-row>
    <v-simple-table fixed-header height="630px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Provider Name</th>
            <th class="text-left">NPI Type</th>
            <th class="text-left">Speciality</th>
            <th class="text-left">City</th>
            <th class="text-left">State</th>
          </tr>
        </thead>
        <tbody v-if="items.length > 0">
          <tr
            v-for="(item, index) in items"
            :key="index"
            :class="selectAll ? 'activeItem' : ''"
            :id="'id' + index"
          >
            <td
              class="text-gray"
              v-if="item['Provider Organization Name (Legal Business Name)']"
            >
              {{ item["Provider Organization Name (Legal Business Name)"] }}
            </td>
            <td
              class="text-gray"
              v-else-if="
                item['Provider Last Name (Legal Name)'] ||
                item['Provider First Name']
              "
            >
              {{ item["Provider First Name"] }}
              {{ item["Provider Last Name (Legal Name)"] }}
            </td>
            <td v-else>/</td>
            <td
              class="text-gray"
              v-html="
                item['Entity Type Code']
                  ? 'Type ' + item['Entity Type Code']
                  : '/'
              "
            >
              {{ item["Entity Type Code"] }}
            </td>
            <td
              v-html="
                item['Healthcare Provider Taxonomy Code_1']
                  ? item['Healthcare Provider Taxonomy Code_1']
                  : '/'
              "
            >
              {{ item["Healthcare Provider Taxonomy Code_1"] }}
            </td>
            <td
              class="text-gray"
              v-html="
                item['Provider Business Practice Location Address City Name']
                  ? item[
                      'Provider Business Practice Location Address City Name'
                    ]
                  : '/'
              "
            ></td>
            <td
              class="text-gray"
              v-html="
                item['Provider Business Mailing Address State Name']
                  ? item['Provider Business Mailing Address State Name']
                  : '/'
              "
            ></td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td>No Results Found. Please Try Again.</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-row class="text-end justify-end mt-5 mr-3">
      <v-btn class="blue-buttons mt-5 mb-5" @click="goBack()"> Cancel </v-btn>
    </v-row>
  </div>
</template>
<script>
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import errorDialog from "../../../TablesTemplate/ErrorDialog.vue";
export default {
  components: {
    successModal,
    errorDialog,
  },
  data() {
    return {
      loadData: false,
      selected: [],
      items: [],
      selectAll: false,
      filteredArray: [],
      selectedRows: [],
      showButton: false,
      filterArray: [],
    };
  },
  created() {
    this.items = this.getProviders;
    this.filterArray = this.getProviders;
    if (this.items.length % 100 == 0) {
      this.showButton = true;
    } else {
      this.showButton = false;
    }
  },
  computed: {
    getProviders() {
      return this.$store.getters.getSearchResultProvidersInEmployer;
    },
    urlData() {
      return this.$store.getters.getSearchUrl;
    },
    route() {
      return this.$route.path;
    },
    routeParams() {
      return this.$route.params.name;
    },
  },
  methods: {
    goBack() {
      this.$router.push(`/employer/members`);
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
      this.$router.push(`/employer/members`);
    },
    searchProviders(val) {
      this.items = this.filterArray.filter((el) => {
        if (
          el["Provider Last Name (Legal Name)"] &&
          el["Provider First Name"]
        ) {
          let fullName =
            el["Provider First Name"] +
            " " +
            el["Provider Last Name (Legal Name)"];
          let reversefullName =
            el["Provider Last Name (Legal Name)"] +
            " " +
            el["Provider First Name"];
          if (fullName.toString().toLowerCase().includes(val.toLowerCase())) {
            return el;
          } else if (
            reversefullName.toString().toLowerCase().includes(val.toLowerCase())
          ) {
            return el;
          }
        } else if (
          el["Provider Last Name (Legal Name)"] &&
          el["Provider Last Name (Legal Name)"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el["Provider Last Name (Legal Name)"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el["Provider First Name"] &&
          el["Provider First Name"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el["Provider First Name"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el["NPI"] &&
          el["NPI"].toString().toLowerCase().includes(val.toLowerCase())
        ) {
          return el["NPI"].toString().toLowerCase().includes(val.toLowerCase());
        } else if (
          el["Healthcare Provider Taxonomy Code_1"] &&
          el["Healthcare Provider Taxonomy Code_1"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el["Healthcare Provider Taxonomy Code_1"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el["Provider Business Practice Location Address Telephone Number"] &&
          el["Provider Business Practice Location Address Telephone Number"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el[
            "Provider Business Practice Location Address Telephone Number"
          ]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el["Provider First Line Business Mailing Address"] &&
          el["Provider First Line Business Mailing Address"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el["Provider First Line Business Mailing Address"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el["Provider Organization Name (Legal Business Name)"] &&
          el["Provider Organization Name (Legal Business Name)"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el["Provider Organization Name (Legal Business Name)"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el["NPI"] &&
          el["NPI"].toString().toLowerCase().includes(val.toLowerCase())
        ) {
          return el["NPI"].toString().toLowerCase().includes(val.toLowerCase());
        } else if (
          el["Healthcare Provider Taxonomy Code_1"] &&
          el["Healthcare Provider Taxonomy Code_1"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el["Healthcare Provider Taxonomy Code_1"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el["Provider First Line Business Mailing Address"] &&
          el["Provider First Line Business Mailing Address"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el["Provider First Line Business Mailing Address"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        }
      });
    },

    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
  },
};
</script>
<style>
.buttons-left {
  justify-content: left;
  align-content: left !important;
  align-items: left !important;
}
.v-data-table--fixed-header > .v-data-table--fixed-header {
  overflow-y: none !important;
}
</style>
<style lang="scss" scoped>
</style>