<template>
  <v-row v-if="singleEmployer" class="add-client-form no-padding">
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <removeDialog
      ref="dialog_delete"
      v-on:confirm="confirmRemove"
    ></removeDialog>
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12">
          <h3 class="form-title font col-md-12">
            {{ singleEmployer.companyName }}
          </h3>
        </div>
      </div>

      <div class="form-row-height">
        <v-form class="row" ref="form" v-model="valid">
          <div class="col-md-6">
            <div class="col-md-12 pb-0">
              <label class="font text-gray" for="">Company Name</label>
              <v-text-field
                class="emp-detail-input"
                tabindex="1"
                required
                solo
                v-model.trim="companyName"
              ></v-text-field>
            </div>

            <div class="col-md-12 pt-0 pb-0">
              <label class="font text-gray" for="">Tax ID {{ taxId }}</label>
              <v-text-field
                class="emp-detail-input"
                tabindex="2"
                required
                @keyup="showSlash($event)"
                solo
                v-if="!viewContract"
                v-model.trim="taxId"
                placeholder="00-0000000"
                :rules="taxIdRules"
                counter="10"
                maxlength="10"
              ></v-text-field>
              <v-text-field
                :value="'Contract to View'"
                v-else
                required
                @click="goToContracts"
                solo
                class="contract-anchor-inputs pointer"
              >
                <template #label>
                  <a class="pointer">Contract to View</a>
                </template>
              </v-text-field>
            </div>
            <div class="col-md-12 pt-0 pb-0">
              <label class="font text-gray" for=""
                >Approximate Number of Employees</label
              >
              <v-text-field
                tabindex="3"
                class="emp-detail-input"
                required
                v-if="!viewContract"
                v-model.trim="noEmployees"
                solo
              ></v-text-field>
              <v-text-field
                v-else
                :value="'Contract to View'"
                required
                @click="goToContracts"
                solo
                class="contract-anchor-inputs"
              >
                <template #label>
                  <a class="pointer">Contract to View</a>
                </template>
              </v-text-field>
            </div>
            <div class="col-md-12 pt-0 pb-0">
              <label class="font text-gray" for=""
                >Approximate Number of Plan Members</label
              >
              <v-text-field
                tabindex="3"
                class="emp-detail-input"
                v-model.trim="noPlanMembers"
                required
                solo
                v-if="!viewContract"
              ></v-text-field>
              <v-text-field
                v-else
                :value="'Contract to View'"
                @click="goToContracts"
                required
                solo
                class="contract-anchor-inputs"
              >
                <template #label>
                  <a class="pointer">Contract to View</a>
                </template>
              </v-text-field>
            </div>
          </div>
        </v-form>
      </div>
    </div>
    <div class="row justify-md-end mt-2 mb-2" v-if="$role != 'user'">
      <div>
        <v-btn
          tabindex="16"
          class="blue-buttons mr-6 mb-2"
          @click="removeEmployer()"
        >
          Remove Employer
        </v-btn>
      </div>
      <div>
        <v-btn
          tabindex="16"
          class="blue-buttons mr-6 mb-2"
          @click="updateEmployer()"
        >
          Save & Update
        </v-btn>
      </div>
    </div>

    <v-dialog v-model.trim="dialog" width="600">
      <v-card>
        <v-card-title class="text-h5"> PDF CONTRACT </v-card-title>
        <v-card-text>
          When user clicks “View Contract” on the Employer list from prior
          screen, then a PDF version of the contract is presented for the
          Provider user to view. This signature page of this PDF contract is
          populated with the Employer’s information (i.e. the Employer that they
          selected on the prior screen), and the compensation addendum is
          populated with the Provider’s contract rates. The user can view,
          print, and save this PDF.
        </v-card-text>
        <v-card-actions>
          <div class="col-md-12 text-end">
            <v-btn class="blue-buttons text-end" @click="dialog = false"
              >Close</v-btn
            >
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import api from "../../../../services/api";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import removeDialog from "../../../TablesTemplate/RemoveDialog.vue";

export default {
  components: { removeDialog, successModal },
  data() {
    return {
      dialog: false,
      companyName: "",
      active: true,
      taxId: "",
      noEmployees: 0,
      noPlanMembers: 0,
      networkVendor: "",
      viewContract: false,
      dialog: false,
      networkDiscountRate: "",
      taxIdRules: [
        (v) => !!v || "This field is required",
        (v) =>
          !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
          "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
      ],
    };
  },
  computed: {
    singleEmployer() {
      return this.$store.getters.getSingleEmployer;
    },
    getStatusContract() {
      return this.$store.getters.getEmpContractStatus;
    },
  },
  created() {
    if (this.getStatusContract) {
      this.viewContract = false;
      this.companyName = this.singleEmployer.companyName;
      this.taxId = this.singleEmployer.taxId;
      this.noEmployees = this.singleEmployer.noEmployees
        ? this.singleEmployer.noEmployees
        : 0;
      this.noPlanMembers = this.singleEmployer.noPlanMembers
        ? this.singleEmployer.noPlanMembers
        : 0;
      this.networkVendor = this.singleEmployer.networkVendor;
      this.networkDiscountRate = this.singleEmployer.networkDiscountRate
        ? this.singleEmployer.networkDiscountRate
        : 0;
    } else {
      this.companyName = this.singleEmployer.companyName;
      this.viewContract = true;
    }
  },
  methods: {
    goToContracts() {
      this.$router.push("/provider/contracts");
    },
    showSlash(event) {
      if (this.taxId.length == 2 && event.keyCode != 8) {
        this.taxId += "-";
      }
      if (this.taxId.length == 10) {
        api()
          .get("auth/isValidTaxId?taxId=" + this.taxId)
          .catch((res) => {
            if (res) {
              this.taxIdRules = [res.response.data.message];
            }
          });
      } else {
        this.taxIdRules = [
          (v) => !!v || "This field is required",
          (v) =>
            !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
            "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
        ];
      }
    },
    confirmRemove() {
      let user = JSON.parse(localStorage.getItem("user"));
      let provider = user.entity._id;
      api()
        .delete(`/providers/${provider}/employers/${this.singleEmployer._id}`)
        .then((res) => {
          if (res) {
            this.$router.push("/provider/employers");
          }
        });
    },
    removeEmployer() {
      this.$refs.dialog_delete.openPopUp(
        "Are you sure that you want to remove this Employer?"
      );
    },
    updateEmployer() {
      this.$refs.form.validate();
      if (this.valid) {
        let user = JSON.parse(localStorage.getItem("user"));
        let provider = user.entity._id;
        let data = {
          companyName: this.companyName,
          taxId: this.taxId,
          noPlanMembers: this.noPlanMembers,
          networkVendor: this.networkVendor,
          noEmployees: this.noEmployees,
          networkDiscountRate: this.networkDiscountRate,
        };
        api()
          .put(
            `/providers/${provider}/employers/${this.singleEmployer._id}`,
            data
          )
          .then((res) => {
            if (res) {
              this.$refs.success_modal.openPopUp(
                "Employer updated successfully"
              );
            }
          });
      }
    },

    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
    },
    done() {
      this.dialog = false;
      this.$router.push("/provider/employers");
    },
  },
};
</script>
<style >
.v-text-field .v-label a {
  pointer-events: all;
}
.contract-anchor-inputs
  .v-input__control
  .v-input__slot
  .v-text-field__slot
  input {
  color: #1976d2 !important;
  cursor: pointer;
  text-decoration: underline;
}
.emp-detail-input .v-input__control .v-input__slot .v-text-field__slot input {
  font-weight: bolder !important;
}
</style>