<template>
  <div class="">
    <div v-if="!isChildren" class="row-height">
      <Header
        :title="'Invoices'"
        :searchInput="true"
        :inviteButton="false"
        :exportButton="true"
        @invite="invite"
        @add="add"
        @searchInput="searchInput"
        :placeholder="'Search Billing'"
        :sort="true"
        :type="false"
      ></Header>
      <div class="mt-3">
        <v-simple-table
          fixed-header
          height="70vh"
          class="no-padding tr-custom-height"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">INVOICE NUMBER</th>
                <th class="text-left">VENDOR NAME</th>
                <th class="text-left">DATE</th>
                <th class="text-left">AMOUNT</th>
               
                <th class="text-left">PAYMENTS</th>
              </tr>
            </thead>
            <tbody
              v-if="filterItems && items.length > 0"
              class="text-gray tr-custom-height"
            >
              <tr v-for="(item, index) in items" :key="index">
                <td v-if="item.invoiceNo" @click="showClient(item)">
                  <a>{{ item.invoiceNo }}</a>
                </td>
                <td v-else><a>/</a></td>
                <td v-html="item.companyName ? item.companyName : '/'"></td>

                <td v-html="item.date ? item.date : '/'"></td>
                <td v-html="item.total ? '$' + item.total : '/'"></td>
              

                <td>
                  {{ item.payment }}
                </td>
              </tr>
            </tbody>

            <tbody v-else-if="items.length < 1 && filterItems">
              <v-col md="12" cols="12">
                <v-alert
                  border="bottom"
                  colored-border
                  type="warning"
                  elevation="2"
                  class="text-gray"
                >
                  Sorry! No results found
                </v-alert>
              </v-col>
            </tbody>

            <tbody v-else>
              <v-col md="12">
                There are no invoices that have been received for any of your
                clients.
              </v-col>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import api from "../../../../../../services/api";

import Header from "../../../Layout/HeaderPage.vue";
export default {
  data() {
    return {
      showDetails: false,
      tab: "solution",
      dialog: false,
      items: [],
      filterItems: false,
      singleItem: null,
      typeItems: [{ text: "Categories", value: "Categories" }],
      sortItems: [{ text: "Newest", value: "Newest" }],
      sortBy: "",
      type: "",
      solutions: [],
      favorites: [],
      filterArray: [],
    };
  },
  components: {
    Header,
  },
  computed: {
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      api()
        .get("/tpas/clients/" + this.$route.params.id)
        .then((res) => {
          if (res) {
            this.items = res.data.invoices;
            this.filterArray = this.items;
          }
        });
    },
    invite() {},
    add() {
      // this.$router.push("/digital-invoicing/clients/billing/" + item.id + "/");
    },

    showClient(item) {
      this.singleItem = item;
      this.$store.commit("setSingleSolution", item);

      this.$router.push(
        "/digital-invoicing/clients/billing/" + item.id + "/details"
      );
    },

    searchInput(val) {
      this.items = this.filterArray.filter((el) => {
        if (
          el.companyName.toString().toLowerCase().includes(val.toLowerCase())
        ) {
          return el.companyName
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el.invoiceNo.toString().toLowerCase().includes(val.toLowerCase())
        ) {
          return el.invoiceNo
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        }
      });
      if (this.items.length < 1) {
        this.filterItems = true;
      } else {
        this.filterItems = false;
      }
    },
  },
};
</script>
<style>
</style>