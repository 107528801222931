import { render, staticRenderFns } from "./WizardWithoutInfo.vue?vue&type=template&id=4ded47ce&scoped=true&"
import script from "./WizardWithoutInfo.vue?vue&type=script&lang=js&"
export * from "./WizardWithoutInfo.vue?vue&type=script&lang=js&"
import style0 from "./WizardWithoutInfo.vue?vue&type=style&index=0&id=4ded47ce&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ded47ce",
  null
  
)

export default component.exports