<template>
  <div class="row-height">
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <exitsDialog
      ref="exists_modal"
      v-on:confirm="closeExistsModal"
    ></exitsDialog>
    <errorDialog ref="error_modal" v-on:confirm="closeErrorModal"></errorDialog>
    <v-row class="mb-4 mt-3">
      <v-col class="col-xl-3 col-lg-4 col-sm-5" cols="12">
        <h3>
          <span class="underline-text ml-2">
            Add Providers - Search Results
          </span>
        </h3>
      </v-col>
      <v-col class="col-xl-3 col-lg-3 col-md-3" cols="12">
        <v-text-field
          class="input-control"
          prepend-inner-icon="mdi-magnify"
          @input="searchProviders"
          light
          dense
          outlined
          placeholder="Search Providers"
        >
        </v-text-field>
      </v-col>
      <v-col class="col-xl-6 col-lg-5 col-md-3 text-gray text-end" cols="12">
        <span class="mr-3">
          Number of Searched Providers:
          <span class="green--text font-weight-bold">{{ items.length }}</span>
        </span>
        <br />
        <span
          class="mr-3"
          v-if="showButton && items.length > 0 && !showLoadMoreDataButton"
        >
          Click the
          <span class="blue--text"> Load More Data </span>
          button to get more results
        </span>
      </v-col>
    </v-row>
    <v-row v-if="loader" class="justify-center col-md-12">
      <v-progress-circular
        :size="200"
        class="text-center"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-row>
    <v-simple-table v-else fixed-header height="630px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Provider Name</th>
            <th class="text-left">NPI Type</th>
            <th class="text-left">Speciality</th>
            <th class="text-left">City</th>
            <th class="text-left">State</th>
            <th class="no-padding text-end justify-end" v-if="$role != 'user'">
              <div class="col-md-12 justify-end d-flex text-end">
                <v-checkbox
                  v-model="allRows"
                  class="no-padding mt-3 stay-blue"
                  @click="selectAllRows1()"
                ></v-checkbox>
                <label for="" style="padding-top: 11px">Select all </label>
              </div>
            </th>
          </tr>
        </thead>
        <tbody v-if="items.length > 0">
          <tr
            v-for="(item, index) in items"
            :key="index"
            :class="selectAll ? 'activeItem' : ''"
            :id="'id' + index"
          >
            <td
              class="text-gray"
              v-if="item['Provider Organization Name (Legal Business Name)']"
            >
              {{ item["Provider Organization Name (Legal Business Name)"] }}
            </td>
            <td
              class="text-gray"
              v-else-if="
                item['Provider Last Name (Legal Name)'] ||
                item['Provider First Name']
              "
            >
              {{ item["Provider First Name"] }}
              {{ item["Provider Last Name (Legal Name)"] }}
            </td>
            <td v-else>/</td>
            <td
              class="text-gray"
              v-html="
                item['Entity Type Code']
                  ? 'Type ' + item['Entity Type Code']
                  : '/'
              "
            ></td>
            <td
              class="text-gray"
              v-html="
                item['Healthcare Provider Taxonomy Code_1']
                  ? item['Healthcare Provider Taxonomy Code_1']
                  : '/'
              "
            ></td>
            <td
              class="text-gray"
              v-html="
                item['Provider Business Practice Location Address City Name']
                  ? item[
                      'Provider Business Practice Location Address City Name'
                    ]
                  : '/'
              "
            ></td>
            <td
              class="text-gray"
              v-html="
                item['Provider Business Mailing Address State Name']
                  ? item['Provider Business Mailing Address State Name']
                  : '/'
              "
            ></td>
            <td
              v-if="$role != 'user'"
              class="text-center mt-3 row no-padding justify-end"
              style="padding-right: 90px"
            >
              <v-checkbox
                class="stay-blue no-padding stay-blue"
                v-model="item.checkbox"
                @click="activeRow1(index, item)"
              ></v-checkbox>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td>No Results Found. Please Try Again.</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-row class="text-end justify-end mt-5 mr-3" v-if="items.length > 0">
      <v-progress-linear
        class="ml-3"
        v-if="loadData"
        color="blue"
        indeterminate
        reverse
      ></v-progress-linear>
      <v-btn
        v-if="$role != 'user'"
        :disabled="loadData"
        class="blue-buttons mt-5 mb-2 mr-5"
        @click="dialog = true"
      >
        Add All
      </v-btn>
      <v-btn
        v-if="showButton && items.length > 0"
        :disabled="loadData"
        class="blue-buttons mt-5 mb-2"
        @click="loadMoreData()"
      >
        Load More Data
      </v-btn>
      <v-btn
        v-if="items.length > 0 && $role != 'user'"
        :disabled="loadData"
        class="blue-buttons mt-5 mb-5 mr-1 ml-5"
        @click="addProviders()"
      >
        Add Selected
      </v-btn>
      <v-btn
        :disabled="loadData"
        class="blue-buttons ml-5 mt-5 mb-5"
        @click="goBack()"
      >
        Cancel
      </v-btn>
    </v-row>
    <v-dialog v-model="dialog" persistent max-width="350">
      <v-card class="text-center">
        <div class="col-md-12 font-weight-bold text-left">
          Number of Searched Providers:
        </div>

        <v-avatar color="primary" size="62">
          <span class="white--text text-h5"> {{ items.length }}</span>
        </v-avatar>
        <br />
        <v-card-text class="text-left mt-4">
          Add All or click the
          <span class="light-blue-text">Load More Data </span>button for more
          result</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="addAll()"> Add All </v-btn>
          <v-btn color="green darken-1" text @click="dialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import api from "../../../../../services/api";
import successModal from "../../../../TablesTemplate/SuccessDialog.vue";
import errorDialog from "../../../../TablesTemplate/ErrorDialog.vue";
import exitsDialog from "../../../../TablesTemplate/ExistDialog.vue";
export default {
  components: {
    successModal,
    errorDialog,
    exitsDialog,
  },
  data() {
    return {
      loadData: false,
      selected: [],
      items: [],
      selectAll: false,
      filteredArray: [],
      selectedRows: [],
      showButton: false,
      filterArray: [],
      page: 1,
      allRows: false,
      dialog: false,
      load: false,
      allProviders: [],
      checkIfSelect: false,
      checkIfClicked: false,
      loader: false,
    };
  },
  created() {
    this.items = this.getProviders;
    this.filterArray = this.getProviders;
    this.allProviders = this.getProviders;
    if (this.$role != "user" && this.items.length > 0) {
      this.dialog = true;
    }

    if (this.items.length % 100 == 0) {
      this.showButton = true;
    } else if (this.items.length < 100) {
      this.showButton = false;
    } else {
      this.showButton = true;
    }
  },
  computed: {
    getProviders() {
      return this.$store.getters.getSearchProviders;
    },
    urlData() {
      return this.$store.getters.getSearchUrl;
    },
    route() {
      return this.$route.path;
    },
    routeParams() {
      return this.$route.params.name;
    },
    getAllItems() {
      return this.items;
    },
    showLoadMoreDataButton() {
      return this.$store.getters.checkIfSearchIsFromMember;
    },
  },
  methods: {
    closeExistsModal() {
      this.$refs.exists_modal.closePopUp();
    },
    goBack() {
      this.$router.push(
        `/employer-advisor/employers/${this.routeParams}/providers`
      );
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
      this.$router.push(
        `/employer-advisor/employers/${this.routeParams}/providers`
      );
    },
    searchProviders(val) {
      this.items = this.filterArray.filter((el) => {
        if (
          el["Provider Last Name (Legal Name)"] &&
          el["Provider Last Name (Legal Name)"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el["Provider Last Name (Legal Name)"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el["Provider First Name"] &&
          el["Provider First Name"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el["Provider First Name"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el["Provider Business Practice Location Address Telephone Number"] &&
          el["Provider Business Practice Location Address Telephone Number"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el[
            "Provider Business Practice Location Address Telephone Number"
          ]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el["Provider Organization Name (Legal Business Name)"] &&
          el["Provider Organization Name (Legal Business Name)"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el["Provider Organization Name (Legal Business Name)"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el["NPI"] &&
          el["NPI"].toString().toLowerCase().includes(val.toLowerCase())
        ) {
          return el["NPI"].toString().toLowerCase().includes(val.toLowerCase());
        } else if (
          el["Healthcare Provider Taxonomy Code_1"] &&
          el["Healthcare Provider Taxonomy Code_1"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el["Healthcare Provider Taxonomy Code_1"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el["Provider First Line Business Mailing Address"] &&
          el["Provider First Line Business Mailing Address"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el["Provider First Line Business Mailing Address"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el["Provider Business Mailing Address Postal Code"] &&
          el["Provider Business Mailing Address Postal Code"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el["Provider Business Mailing Address Postal Code"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el["Provider Business Mailing Address State Name"] &&
          el["Provider Business Mailing Address State Name"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el["Provider Business Mailing Address State Name"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el["Provider Business Practice Location Address City Name"] &&
          el["Provider Business Practice Location Address City Name"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el["Provider Business Practice Location Address City Name"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el["Provider Last Name (Legal Name)"] &&
          el["Provider First Name"]
        ) {
          let fullName =
            el["Provider First Name"] +
            " " +
            el["Provider Last Name (Legal Name)"];
          let reversefullName =
            el["Provider Last Name (Legal Name)"] +
            " " +
            el["Provider First Name"];
          if (fullName.toString().toLowerCase().includes(val.toLowerCase())) {
            return el;
          } else if (
            reversefullName.toString().toLowerCase().includes(val.toLowerCase())
          ) {
            return el;
          }
        }
      });
    },
    loadMoreData() {
      this.loadData = true;
      this.page++;
      if (this.$store.getters.checkIfSearchIsFromMember) {
        api()
          .post(
            `/locations/npi?page=${this.page}&limit=100`,
            this.$store.getters.getZipList
          )
          .then((res) => {
            if (res) {
              if (res.data.items.length < 100) {
                this.showButton = false;
              }
              res.data.items.forEach((el) => {
                this.items.push(el);
              });

              this.allRows = false;
              this.filteredArray = this.items;
              this.loadData = false;
              this.allProviders = this.items;
              this.checkIfSelect = true;
            }
          });
      } else {
        api()
          .get(`/api/npidata/search?${this.urlData}page=${this.page}&limit=100`)
          .then((res) => {
            if (res) {
              if (res.data.items.length < 100) {
                this.showButton = false;
              }
              res.data.items.forEach((el) => {
                this.items.push(el);
              });
              this.filteredArray = this.items;
              this.loadData = false;
              this.allRows = false;
              this.checkIfSelect = true;
              this.allProviders = this.items;
            }
          });
      }
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    addAll() {
      this.load = true;
      this.selectedRows = this.items;
      this.addProviders();
    },
    addProviders() {
      if (this.selectedRows.length < 1) {
        let text = "You must select at least one Provider";
        this.$refs.error_modal.openPopUp(text);
        return;
      }
      this.loadData = true;
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.employerAdvisor;
      let employer = JSON.parse(localStorage.getItem("employer"));
      let exists = false;
      let existAray = [];
      api()
        .get(`/advisors/${advisorId}/employers/${employer._id}/providers`)
        .then((res) => {
          for (let i = 0; i < res.data.providers.length; i++) {
            for (let j = 0; j < this.selectedRows.length; j++) {
              if (res.data.providers[i].NPI === this.selectedRows[j].NPI) {
                existAray.push(this.selectedRows[j].NPI);
                exists = true;
              }
            }
          }
        })
        .then((res) => {
          let arr = [];
          this.selectedRows.forEach((el) => {
            if (el._id) {
              arr.push(el._id);
            } else {
              arr.push(el);
            }
          });
          let data = {
            search: "npidata",
            ids: arr,
          };

          if (exists) {
            this.dialog = false;
            this.load = false;
            let text = "Providers already exist ";
            this.$refs.exists_modal.openPopUp(text, existAray);
            this.loadData = false;
             return;
          } else {
             api()
              .post(
                `/advisors/${advisorId}/employers/${employer._id}/providers`,
                data
              )
              .then((res) => {
                this.loadData = false;
                if (res) {
                  let text = "Providers added successfully to this Employer!";
                  this.$refs.success_modal.openPopUp(text);
                }
              });
          }
        });
    },
    selectAllRows1() {
      this.selectedRows = [];

      if (!this.checkIfSelect) {
        this.selectedRows = [];
        this.checkIfClicked = false;
        this.items.forEach((el) => {
          el.checkbox = false;
        });
      }
      if (this.checkIfSelect) {
        this.items = [];
        this.loader = true;
        this.items = this.allProviders;
        let tmp = JSON.stringify(this.items);
        this.selectedRows = JSON.parse(tmp);
        this.checkIfSelect = false;

        setTimeout(() => {
          this.items.forEach((el) => {
            el.checkbox = true;
          });
          this.loader = false;
        }, 0);

        //  this.$forceUpdate();
      } else if (this.selectAll && !this.checkIfSelect) {
        this.selectAll = false;
      } else {
        this.selectAll = !this.selectAll;
        if (!this.selectAll) {
          this.selectedRows = [];
          this.checkIfClicked = false;
          this.items.forEach((el) => {
            el.checkbox = false;
          });
        } else {
          this.items.forEach((el) => {
            el.checkbox = true;
          });
          this.selectedRows = [];
          let tmp = JSON.stringify(this.items);
          this.selectedRows = JSON.parse(tmp);
        }
      }
    },
    activeRow1(index, item) {
      let itemById = document.getElementById("id" + index);
      if (this.allRows) {
        this.checkIfSelect = true;
        let removeItem = this.selectedRows.find((x) => x._id === item._id);
        this.selectedRows.splice(this.selectedRows.indexOf(removeItem), 1);
        itemById.classList.remove("activeItem");
        this.allRows = false;
        this.checkIfClicked = true;
      } else {
        itemById.classList.toggle("activeItem");
        if (this.checkIfClicked) {
          itemById.classList.remove("activeItem");
        }
        if (item.checkbox) {
          this.selectedRows.push(item);
        } else {
          let removeItem = this.selectedRows.find((x) => x._id === item._id);
          this.selectedRows.splice(this.selectedRows.indexOf(removeItem), 1);
        }
      }
    },
    selectAllRows() {
      this.selectedRows = [];
      this.selectAll = !this.selectAll;

      if (!this.selectAll) {
        this.selectedRows = [];
      } else {
        this.selectedRows = [];
        let tmp = JSON.stringify(this.getProviders);
        this.selectedRows = JSON.parse(tmp);
      }
    },
    activeRow(index, item) {
      let itemById = document.getElementById("id" + index);
      itemById.classList.toggle("activeItem");
      if (this.allRows) {
        let removeItem = this.selectedRows.find((x) => x._id === item._id);
        this.selectedRows.splice(this.selectedRows.indexOf(removeItem), 1);
      } else {
        if (itemById.classList.contains("activeItem")) {
          this.selectedRows.push(item);
        } else {
          let removeItem = this.selectedRows.find((x) => x._id === item._id);
          this.selectedRows.splice(this.selectedRows.indexOf(removeItem), 1);
        }
      }
    },
  },
};
</script>
<style>
.buttons-left {
  justify-content: left;
  align-content: left !important;
  align-items: left !important;
}
.v-data-table--fixed-header > .v-data-table--fixed-header {
  overflow-y: none !important;
}
</style>
<style lang="scss" scoped>
</style>