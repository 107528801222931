<template>
  <div class="form-div d-flex flex-column">
    <FullscreenLoader v-if="loader" />
    <v-flex xs12 sm12 md6 lg6 class="register-page-right">
      <div class="register-content-right">
        <div class="register-form-container">
          <v-form
            class="col-12 info-form"
            ref="form"
            v-model.trim="valid"
            @submit.prevent=""
          >
            <div class="center-content mb-8">
              <img class="apaly-logo-2" src="/svg/apalyBlue.svg" />
              <span class="register-header-text"> Reset Password </span>
            </div>

            <v-row class="register-row">
              <v-col
                md="12"
                sm="12"
                align="start"
                class="pb-0 position-relative"
              >
                <label for="" class="register-label">New Password</label>
                <v-text-field
                  dense
                  id="password"
                  placeholder="Enter your new password here"
                  name="accountPassword"
                  v-model.trim="password"
                  required
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  @click:append="show1 = !show1"
                  :rules="passRules"
                  class="apaly_text register-text-field"
                  tabindex="1"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="register-row">
              <v-col md="12" sm="12" align="start" class="pb-0">
                <label for="" class="register-label">Confirm Password</label>
                <v-text-field
                  dense
                  id="confirmPassword"
                  placeholder="Re-enter new password"
                  name="confirmPassword"
                  v-model.trim="confirmpassword"
                  required
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show2 ? 'text' : 'password'"
                  @click:append="show2 = !show2"
                  :rules="confirmPassRules"
                  class="apaly_text register-text-field"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- <div class="error-message">
                <span
                    style="color: #ff5252; font-size: 14px; width: 80%;"
                    v-if="errorMessage">{{ errorMessage }}
                </span>
             </div> -->

            <v-row class="mt-3">
              <v-col md="12" sm="12" align="start">
                <span class="password-requirements-text"
                  >Password Requirements</span
                >
                <ul>
                  <li
                    v-for="(requirement, index) in passwordRequirements()"
                    :key="index"
                    :class="
                      requirement.satisfied
                        ? 'req-satisfied'
                        : 'req-unsatisfied'
                    "
                  >
                    {{ requirement.text }}
                  </li>
                </ul>
              </v-col>
            </v-row>

            <v-row class="register-row">
              <v-col md="12" sm="12" class="pb-0" v-if="showContract">
                <div class="mb-0">
                    <v-checkbox
                        dense
                        v-model="checkbox"
                    >
                    <div slot="label" class="accept-aggrement-text">
                        I accept the terms of
                        <a
                        class="text-underline"
                        @click="openPdfModal()"
                        >Apaly Health Platforms Access Agreement
                        </a>
                    </div>
                    </v-checkbox>
                </div>
              </v-col>
            </v-row>

            <v-row class="register-row mt-0">
              <v-col md="12" sm="12" class="pb-0">
                <v-btn
                  text
                  :class="
                    passwordsValid() ? 'primary-button no-transform' : 'disabled-button no-transform'
                  "
                  style="width: 100%"
                  @click="send()"
                  :disabled="!valid"
                >
                  Reset password
                </v-btn>
              </v-col>
              <v-col md="12" sm="12" class="pb-0">
                  <v-btn
                    text
                    outlined
                    class="secondary-button"
                    style="width: 100%"
                    @click="$router.push('/login')"
                  >
                    Cancel
                  </v-btn>
                </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
    </v-flex>

    <!-- <v-form class="col-md-8 col-xl-6 col-lg-8" ref="form" v-model.trim="valid">
      <div>
        <div class="col-12">
          <span class="mt-5">
            <p style="font-size: 32px">Reset Password</p>
          </span>
        </div>
        <div class="col-12">
          Requirments:
          <ul>
            <li>At least 8 characters</li>
            <li>At least 1 capital letter</li>
            <li>At least 1 lowercase letter</li>
            <li>At least 1 special character - ! @ # $ % etc</li>
          </ul>
        </div>

        <div class="col-12">
          <label for="" class="tiny-font">New Password</label>
          <v-text-field
            outlined
            v-model.trim="password"
            required
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            @click:append="show = !show"
            :rules="passRules"
          ></v-text-field>
        </div>

        <div class="col-12 mb-0 pb-0">
          <label for="" class="tiny-font"> Confirm New Password</label>
          <v-text-field
            outlined
            v-model.trim="confirmpassword"
            required
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            @click:append="show = !show"
            :rules="confirmPassRules"
          ></v-text-field>
        </div>

        <div class="col-md-9 col-xl-9 col-sm-9 col-lg-9 pt-0" v-if="showContract">
          <div class="font text-gray mt-0 d-flex">
            <v-checkbox v-model="checkbox"></v-checkbox>
            <span class="mt-5 font-14 mobile-14" align="start">
              I accept the terms of
              <a class="text-underline" @click="openPdfModal()"
                >Apaly Health Platforms Access Agreement
              </a>
            </span>
          </div>
        </div>
        <v-col v-if="error.length > 0" class="mt-3 mb-3 col-md-12">
          <v-alert dense outlined type="error">
            {{ error }}
          </v-alert>
        </v-col>
        <div class="col-12 d-flex" style="justify-content:space-between">
          <a @click="$router.push('/login')">Cancel</a>
          <v-btn color="primary" :disabled="!valid" @click="send()">
            Reset Password
          </v-btn>
        </div>
      </div>
    </v-form> -->

    <v-dialog v-model.trim="rightPass" width="520" persistent>
      <v-card>
        <v-card-text class="pt-5">
          <span role="button" align="end" class="pt-0 pr-0">
            <p class="font-weight-bold" @click="backToLogin()">X</p>
          </span>
          <div class="display">
            <div class="text-center">
              <img class="check-icon" src="/svg/blueCheck.svg" />
            </div>
            <div class="password-changed-message">
              <span class="password-changed-title">
                Password Saved Successfully!
              </span>
              <span class="password-changed-info">
                Please continue to login to your account
              </span>
            </div>
          </div>

          <div class="mt-9 mb-3 align-right row-buttons">
            <v-btn
              text
              style="text-transform: none !important;"
              class="primary-b3-button no-transform"
              @click="backToLogin()"
            >
              Go to login page
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import api from "../../services/api";
import Vue from "vue";
import { mapActions } from "vuex";
import FullscreenLoader from "../Common/FullscreenLoader.vue";

export default {
  components: { FullscreenLoader },
  data() {
    return {
      error: "",
      loader: false,
      show1: false,
      show2: false,
      checkbox: false,
      pdfDialog: false,
      showContract: false,
      tokenAcc: "",
      confirmed: false,
      wrongPass: false,
      rightPass: false,
      email: "",
      changePassEmail: "",
      check1: false,
      check2: false,
      check3: false,
      contains_uppercase: false,
      contains_special_character: false,
      password: "",
      confirmpassword: "",
      valid: false,
      show: false,
      error: "",
      passRules: [
        (v) => !!v || "Password is required",
        (v) => !!v || "Please type password.",
        (v) => (v && v.length > 8) || "Minimum 8 characters",
        (v) => (v && /[A-Z]{1}/.test(v)) || "At least one capital latter",
        (v) =>
          (v.length && /[a-z]{1}/.test(v)) ||
          "Password should contain at least one lowercase letter",
        (v) => (v && /[^A-Za-z0-9]/.test(v)) || "At least one special character",
      ],
      confirmPassRules: [
        (v) => !!v || "Password is required",
        (v) => !!v || "Please type password.",
        (v) => (v && v.length > 8) || "Minimum 8 characters",
        (v) => (v && /[A-Z]{1}/.test(v)) || "At least one capital latter",
        (v) => (v && /[^A-Za-z0-9]/.test(v)) || "At least one special character",
        (v) => v == this.password || "Password must match",
      ],
      passsError: "",
      error: "",
    };
  },
  computed: {
    token() {
      return this.$route.params;
    },
  },
  created() {
    this.tokenAcc = this.token;
    if (this.$route.query.token) {
      this.showContract = true;
    }
  },
  methods: {
    ...mapActions(["loginUser"]),
    eventHandling(event) {
      if (event.keyCode == 13) {
        this.submit();
      }
    },
    reason() {
      this.reasonDialog = true;
    },
    openPdfModal() {
      // const url = "https://apalycdn.s3.us-west-1.amazonaws.com/public/uploads//admin/contracts/terms.pdf";
      const url = "https://apalycdn.s3.us-west-1.amazonaws.com/public/uploads/admin/contracts/ApalyTermsandConditionsMember01042024.pdf";
      this.pdfUrl = url;
      this.pdfDialog = true;
    },
    send() {
        this.loader = true;
      if (this.$route.query.token) {
            api()
              .post(`auth/simple/setPassword`, {
                password: this.password,
                token: this.$route.query.token,
              })
              .then((res) => {
                this.rightPass = true;
              }).finally(()=> {
                this.loader = false;
              });
          }


    },
    backToLogin() {
      this.$router.push("/login");
    },
    checkPassword() {
      if (this.password.length >= 8) {
        this.check1 = true;
      } else {
        this.check1 = false;
      }
      this.contains_uppercase = /[A-Z]/.test(this.password);

      if (this.contains_uppercase == true) {
        this.check2 = true;
      } else {
        this.check2 = false;
      }
      const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
      this.contains_special_character = format.test(this.password);
      if (this.contains_special_character == true) {
        this.check3 = true;
      } else {
        this.check3 = false;
      }
    },
    confirmPass() {
      if (this.confirmpassword == this.password) {
        this.confirmed = true;
      } else {
        this.confirmed = false;
      }
    },
    
    passwordRequirements() {
      const password = this.password || "";

      const passwordRequirements = {
        minLength: {
          text: "At least 8 characters",
          satisfied: password.length >= 8,
        },
        capitalLetter: {
          text: "At least 1 capital letter",
          satisfied: /[A-Z]/.test(password),
        },
        lowercaseLetter: {
          text: "At least 1 lowercase letter",
          satisfied: /[a-z]/.test(password),
        },
        specialCharacter: {
          text: "At least 1 special character",
          satisfied: /[!@#$%^&*(),.?":{}|<>]/.test(password),
        },
      };

      return passwordRequirements;
    },

    passwordsValid() {
      return (
        this.password.length >= 8 &&
        /[A-Z]/.test(this.password) &&
        /[a-z]/.test(this.password) &&
        /[!@#$%^&*(),.?":{}|<>]/.test(this.password) &&
        this.password === this.confirmpassword &&
        this.checkbox
      );
    },
  },
};
</script>
<style scoped>
.apaly-color {
  color: #16407a;
}
.display {
  display: grid;
}
.yes-btn {
  color: white;
}
.submit-btn {
  color: white;
}
.valid-text {
  color: green;
}

.confirm-btn {
  color: white;
  width: inherit;
}
.done-btn {
  color: white;
  width: 170px;
}
.org {
  margin-top: 20vh;
}
.no-btn {
  width: 170px;
  border: 1px solid #16407a;
}
.color-black {
  color: black;
}
.desc {
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.register-row {
    margin-top: 2vh;
}

.no-transform {
  text-transform: none !important;
}

.password-changed-message {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--primary-color);
  padding-left: 1.5vh;
  padding-right: 1.5vh;
}

.password-changed-title {
  font-size: 1.3vw;
  font-weight: bold;
}

.password-changed-info {
  width: 16vw;
  margin-top: 2.5vh;
  font-size: 0.8vw;
  line-height: 2.2vh;
  font-weight: bold;
  text-align: center;
}

</style>
