<template>
    <div class="row add-client-form no-padding row-height">
       
        <div class="col-md-12 ">
            <div class="font row col-md-12 justify-space-between">
                <div>
                    <h3>
                        Members 
                    </h3>
                </div>
                <!-- <v-menu offset-y >
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="my-1 blue-buttons"> Add / Invite Members</v-btn>
                    </template>
                    <v-list>
                        <v-list-item >
                            <a class="a-tag underline-text" @click="$router.push(`/employer/members/upload`)">Upload Census</a>
                        </v-list-item>
                        <v-list-item>
                            <a class="a-tag underline-text"  @click="$router.push(`/employer/members/add-new`)">Add Member</a>
                        </v-list-item>
                        <v-list-item>
                            <a class="a-tag underline-text"  @click="$router.push(`/employer/members/invite-members`)">Invite Members</a>
                        </v-list-item>
                        <v-list-item>
                            <a class="a-tag underline-text"  @click="$router.push(`/employer/partners/invite-partner`)">Copy Invite Link</a>
                        </v-list-item>
                    </v-list>

                </v-menu> -->
            </div>
            <v-row v-if="getProviders && getProviders.length < 1" class="mt-4">
                <v-col md="12" class="font-bold">
                    To create a targeted provider list, 
                    <a  @click="$router.push(`/employer-advisor/employers/${routeParams}/members/add-zip-codes`)">Add your plan member home zip codes.</a>
                </v-col>
            </v-row>
            <v-simple-table fixed-header  height="630px"  class="mt-5" v-else>
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-left">
                            Member Name
                        </th>
                        <th class="text-left">
                            Member ID
                        </th>
                        <th class="text-left">
                            Type
                        </th>
                       
                    </tr>
                    </thead>
                    <tbody v-if="getProviders && getProviders.length > 0">
                    <tr
                            v-for="item in getProviders"
                            :key="item.id"
                    >
                        <td v-if="item.firstName"><a  @click="singleMember(item)">{{ item.firstName }} {{item.lastName}}</a> </td>
                        <td v-else><a  @click="singleMember(item)">/</a> </td>
                        <td class="text-gray">123456</td>
                        <td class="text-gray" v-if="item.memberType">{{item.memberType.charAt(0).toUpperCase() + item.memberType.slice(1)}} </td>
                        <td v-else>/</td>
                        
                    </tr>
                    </tbody>

                </template>
            </v-simple-table>
        </div>
        <v-dialog v-model.trim="dialog" max-width="600px" persistent>
            <v-card>
                <v-col>
                    <h3>Remove Member</h3>
                </v-col>


                <v-col>
                    <span>Are you sure you want to deactivate this Member?</span>
                </v-col>

                <v-col>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="blue-buttons float-class" @click="confirmRemove">Confirm</v-btn>
                        <v-btn class="button-style float-class" @click="closePopUp">Close</v-btn>
                    </v-card-actions>
                </v-col>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import api from "../../../../../services/api";
  

    export default {
        name:'EmployerMembers',
        components:{},
        data(){
            return{
                allEmployers:[],
                dialog:false,
                itemToRemove:null,
                
                items: [
                    {
                        name: 'Tampa General Hospital',
                        npi: '123456789',
                        type: 'Hospital',
                    },
                    {
                        name: 'USF Health',
                        npi: '123456789',
                        type: 'Multi-Speciality',
                    },
                    {
                        name: 'Florida Medical Clinic',
                        npi: '123456789',
                        type: 'Multi-Speciality',
                    },
                    {
                        name: 'Boyfront Health St Pete',
                        npi: '123456789',
                        type: 'Multi-Speciality',
                    },
                    {
                        name: 'Morton Plant Mease',
                        npi: '123456789',
                        type: 'Hospital',
                    },
                    {
                        name: 'St.Anthony\'s Hospital',
                        npi: '123456789',
                        type: 'Hospital',
                    },
                    {
                        name: 'Midway Clinic',
                        npi: '123456789',
                        type: 'Hospital',
                    },
                ],
            }

        },
      
        computed:{
          routeParams(){
                return this.$route.params.name
           },
           getProviders(){
             let array = []
            //  if(this.$store.getters.getSearchResultProvidersInEmployer){
            //     array = this.$store.getters.getSearchResultProvidersInEmployer
            //  }else{
            //    array = []
            //  }
            return array
           },
        },
        methods:{
         singleMember(item){
      
           let user = JSON.parse(localStorage.getItem('user'));
           let advisorId = user.groupPortal[0].organization.employerAdvisor
           let employer = JSON.parse(localStorage.getItem('employer'))
            
           api().get(`/advisors/${advisorId}/employers/${employer._id}/members/${item._id}`).then((res)=>{
             if(res){
                this.$store.commit('setSingleEmployerMember', res.data.member);
               
             }
           });
           api().get(`/advisors/${advisorId}/employers/${employer._id}/members/${item._id}/associated`).then((res)=>{
          
             if(res){
                this.$store.commit('setSingleEmployerMemberDependent', res.data.associatedDependents);
                this.$store.commit('setSingleEmployerMemberEmployee', res.data.associatedEmployee);
                this.$router.push(`/employer-advisor/employers/${this.routeParams}/members/member`);
             }
           });

      },
     
      removeDialog(item){
      
        this.dialog = true
      },
      confirmRemove(){
         let user = JSON.parse(localStorage.getItem('user'));
         let advisorId = user.groupPortal[0].organization.employerAdvisor
         let employer = JSON.parse(localStorage.getItem('employer'))
        api().delete(`/advisors/${advisorId}/employers/${employer._id}/members/${item._id}`).then((res)=>{
             if(res){
                   this.$router.push(`/employer-advisor/employers/${this.routeParams}/members/member`);
             }
           })
        this.dialog = false
      },
      closePopUp(){
        this.dialog = false
      },
      searchMembers(val){
        this.allMembers = this.filteredArray.filter((el)=>{
          if(el.firstName.toLowerCase().includes(val.toLowerCase())){
            return el.firstName.toLowerCase().includes(val.toLowerCase())
          } else if(el.lastName.toLowerCase().includes(val.toLowerCase())){
            return el.lastName.toLowerCase().includes(val.toLowerCase())
          }
        })
      },
       getAllMembers(){
           let user = JSON.parse(localStorage.getItem('user'));
           let advisorId = user.groupPortal[0].organization.employerAdvisor
           let employer = JSON.parse(localStorage.getItem('employer'))
           api().get(`/advisors/${advisorId}/employers/${employer._id}/members`).then((res)=>{
              if(res)
              {
                this.filteredArray = res.data.members
                this.allMembers = res.data.members
               }
           })
        },
        
        created() {
            this.getEmployerMembers()
        }
    }}
</script>


