<template>
  <div class="row justify-center mt-4">
    <div class="col-md-6 mt-4">
      <v-card class="mx-auto row-height" max-width="1200" tile>
        <BlueHeader :title="'People Health - Members'"
                    @goBack="goBack"
                    @addNewPage="addNewMember"
                    :back="true"
                    :settings="false"
                    :add="true">
        </BlueHeader>
        <div class="clearing-house-search">
          <v-text-field
              class="mr-5 filter-serach-input"
              @input="searchInput"
              solo
              append-icon="fa fa-search"
              placeholder="Search Members"
          ></v-text-field>
        </div>

        <div class="mt-5" v-if="tableMembers">
          <v-list>
            <v-list-item-group color="primary" v-if="!filterItems">
              <v-list-item
                  id="clearing-house-list"
                  v-for="(item, i) in items"
                  :key="i"
                  @click="item.link? $router.push(item.link) : '' "
              >

                <v-list-item-content>
                  <v-list-item-title v-text="item.text" class="ml-5"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon color="warning"  v-text="item.icon"></v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-item-group>
            <v-col md="12" cols="12" v-else>
              <v-alert
                  border="bottom"
                  colored-border
                  type="warning"
                  elevation="2"
                  class="text-gray"
              >
                Sorry! No results found
              </v-alert>
            </v-col>
          </v-list>
        </div>

        <div v-if="addMember"
            class="row justify-center mt-5">
          <div class="col-md-8">
            <v-text-field
                outlined
                label="First Name"
                v-model="firstName"
                hide-details=""
            ></v-text-field>
          </div>
          <div class="col-md-8">
            <v-text-field
                outlined
                hide-details=""
                label="Last Name"
                v-model="lastName"
            ></v-text-field>
          </div>
          <div class="col-md-8">
            <v-text-field
                outlined
                hide-details=""
                label="DOP"
                v-model="DOB"
            ></v-text-field>
          </div>
          <div class="col-md-8">
            <div class="row col-md-12">
              Gender:
              <span class="ml-4 mr-3">Male</span>
              <div
                  class="text-end"
                  :class="
                    gender == 'm' ? 'fill-blue-checkbox' : 'blue-checkbox'
                  "
                  @click="gender = 'm'"
              ></div>
              <span class="ml-2 mr-3"> Female</span>
              <div
                  class="text-end"
                  :class="
                    gender == 'f' ? 'fill-blue-checkbox' : 'blue-checkbox'
                  "
                  @click="gender = 'f'"
              ></div>
            </div>
          </div>
          <div class="col-md-8">
            <v-text-field
                outlined
                hide-details=""
                label="Member ID"
                v-model="memberId"
            ></v-text-field>
          </div>
          <div class="col-md-8">
            <v-text-field
                outlined
                hide-details=""
                label="Group ID"
                v-model="groupId"
            ></v-text-field>
          </div>
          <div class="col-md-8">
            <v-text-field
                outlined
                hide-details=""
                label="Plan ID"
                v-model="planId"
            ></v-text-field>
          </div>
          <div class="col-md-8">
            <v-text-field
                outlined
                hide-details=""
                label="Payer ID"
                v-model="payerId"
            ></v-text-field>
          </div>
          <div class="col-md-8 mb-10">



            <template>
              <div class="text-center">
                <v-dialog
                    v-model="dialog"
                    width="500"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="blue-buttons"
                        block @click="checkEligibility"
                    >FIRST > CHECK ELIGIBILITY
                    </v-btn>
                 </template>

                  <v-card>
                    <v-card-title class="text-h6 ml-5 lighten-2 text-decoration-underline">
                      Running Member Eligibility Check
                    </v-card-title>

                    <v-card-text>

                      <div class="col-md-8">

                        <h4 v-show="visibleTPA"
                            style="color: red"
                         >
                          <v-icon color="red"  v-text="iconVerify"></v-icon> Verifying TPA
                       </h4>

                        <h4 v-show="checkedTPA"
                            style="color: green"
                        >
                          <v-icon color="green"  v-text="iconCheck"></v-icon>   TPA Verified
                        </h4>
                      </div>

                      <div class="col-md-8">
                        <h4
                            v-show="visibleEmployer"
                            style="color: red"
                        >
                          <v-icon color="red"  v-text="iconVerify"></v-icon> Verifying Employer
                        </h4>
                        <h4 v-show="checkedEmployer"
                            style="color: green"
                        >
                          <v-icon color="green"  v-text="iconCheck"></v-icon>   Employer Verified
                        </h4>
                      </div>
                      <div class="col-md-8">
                        <h4
                         v-show="visibleHealthPlan"
                         style="color: red"
                        >
                          <v-icon color="red"  v-text="iconVerify"></v-icon> Verifying Health Plan
                        </h4>

                        <h4 v-show="checkedHealthPlan"
                            style="color: green"
                        >
                          <v-icon color="green"  v-text="iconCheck"></v-icon>  Health Plan Verified
                        </h4>
                      </div>
                      <div class="col-md-8">
                        <h4
                        v-show="visibleMemberStatus"
                        style="color: red"
                        >
                          <v-icon color="red"  v-text="iconVerify"></v-icon> Verifying Member Status with TPA
                        </h4>
                        <h4 v-show="checkedMemberStatus"
                            style="color: green"
                        >
                          <v-icon color="green"  v-text="iconCheck"></v-icon>  Member Status Verified with TPA
                        </h4>
                      </div>
                      <div class="col-md-8">
                        <h4
                        v-show="visibleMemberBalance"
                        style="color: red"
                        >
                          <v-icon color="red"  v-text="iconVerify"></v-icon> Checking Member Deductible Balance</h4>

                        <h4 v-show="checkedMemberBalance"
                            style="color: green"
                        >
                          <v-icon color="green"  v-text="iconCheck"></v-icon>  Member Deductible Balance Confirmed
                        </h4>
                      </div>

                    </v-card-text>
                    <v-divider></v-divider>

                    <v-card-actions v-show="checkingBox">
                      <div  style="border: 3px solid red; padding: 5px; text-align: center; margin:auto;">
                      <v-row v-if="loader" class="text-center col-md-2" style="margin: auto;">
                        <v-progress-circular
                            :size="20"
                            indeterminate
                            color="red"
                        >  </v-progress-circular>
                      </v-row>
                      <v-row class="text-center">
                      <v-btn
                          color="red"
                          text
                          style="margin-bottom: 7px;"
                      >
                        Eligibility Checking Process
                      </v-btn>
                      </v-row>
                      </div>
                    </v-card-actions>
                    <v-card-actions v-show="allChecked">
                      <div  style="border: 3px solid green; padding: 5px; text-align: center; margin:auto;"  @click="registerPatient">
                        <v-row class="text-center col-md-2" style="margin: auto;">
                          <v-icon color="green"  v-text="iconCheck"></v-icon>
                        </v-row>
                        <v-row  class="text-center">
                          <v-btn
                              color="green"
                              text
                              style="margin-bottom: 10px; margin-top: -5px;"
                          >
                            Eligibility Confirmed
                            <br>
                            Click to Register Patient
                          </v-btn>
                        </v-row>

                      </div>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </template>
          </div>
        </div>
      </v-card>

      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import api from "@/services/api";
// import tpas from "../../../../store/JSONS/tpa.json";
import BlueHeader from '../../../BlueHeader'
export default {
  props: [
    'addNew',
      'table'
  ],
  data() {
    return {
      iconVerify: "mdi-close-circle-outline",
      iconCheck: "mdi-check-circle-outline",
      checkingBox: true,
      allChecked: false,
      visibleEmployer: true,
      checkedEmployer: false,
      visibleHealthPlan: true,
      checkedHealthPlan: false,
      visibleMemberStatus: true,
      checkedMemberStatus: false,
      visibleMemberBalance: true,
      checkedMemberBalance: false,
      checkedTPA: false,
      visibleTPA: true,
      loader: false,
      tableMembers:true,
      addMember: false,
      firstName: "",
      lastName: "",
      DOB: "",
      gender: "",
      memberId: "",
      groupId: "",
      planId: "",
      payerId: "",
      showDetails: false,
      dialog: false,
      singleItem: null,
      sortBy: "",
      type: "",
      solutions: [],
      favorites: [],
      filterItems: false,
      filteredArray: [],
      selectedItem: 1,
      items: [
        {
          text: "Test1 Test",
          icon: "mdi-arrow-right",
          link: "",
        },
        {
          text: "Test2 Test",
          icon: "mdi-arrow-right",
          link: "",
        },
        {
          text: "Test3 Test",
          icon: "mdi-arrow-right",
          link: "",
        },
      ],
    };
  },
  components: {
    BlueHeader,

  },
  computed: {
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
  },

  methods: {
    registerPatient(){
      this.dialog=false;
      this.$router.push('/dpc-organizations/people-health/member')
    },
    goBack(){
       this.$router.push('/dpc-organizations/people-health');
    },
    addNewMember(){

     this.addMember=true
     this.tableMembers=false

    },
    checkEligibility(){
       console.log('check');
       this.loader = true;
       this.visibleEmployer=false;
      this.visibleHealthPlan=false;
      this.visibleMemberStatus=false;
      this.visibleMemberBalance=false;
      setTimeout(() => {this.visibleTPA = false, this.checkedTPA=true}, 2000);
      setTimeout(() => {this.visibleEmployer = true}, 3000)
      setTimeout(() => {this.visibleEmployer = false, this.checkedEmployer=true}, 5000);
      setTimeout(() => {this.visibleHealthPlan = true}, 6000)
      setTimeout(() => {this.visibleHealthPlan = false, this.checkedHealthPlan=true}, 8000);
      setTimeout(() => {this.visibleMemberStatus = true}, 9000)
      setTimeout(() => {this.visibleMemberStatus = false, this.checkedMemberStatus=true}, 11000);
      setTimeout(() => {this.visibleMemberBalance = true}, 12000)
      setTimeout(() => {this.visibleMemberBalance = false, this.checkedMemberBalance=true}, 14000);
      setTimeout(() => {this.checkingBox = false, this.allChecked=true}, 15000)






    },
    getData() {
      api()
          .get("/tpas/invoices")
          .then((res) => {
            if (res) {
              // this.items = res.data;
              // this.filteredArray = res.data;
            }
          });
    },
    invite() {},
    add() {},
    exportFun() {
      this.exportData("tblData");
    },

    searchInput(val) {
      this.items = this.filteredArray.filter((el) => {
        if (el.text && el.text.toLowerCase().includes(val.toLowerCase())) {
          return el.text.toLowerCase().includes(val.toLowerCase());
        }
      });
      if (this.items.length < 1) {
        this.filterItems = true;
      } else {
        this.filterItems = false;
      }
    },

    searchMembers() {},
  },
  created() {

    this.filteredArray = this.items;
  },
};
</script>
