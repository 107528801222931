<template>
  <div class="row row-height">
    <v-sheet class="overflow-hidden col-md-12 border-r-8">
      <div class="">
        <v-row align="center" justify="center">
          <div
            class="
             col-md-12 navigationWidth no-padding row-height
            "
          >
            <v-row class="blue-underline mb-1">
              <v-col class="col-xl-6 col-md-6 cols-6 pt-2 pb-0" cols="6">
                <div class="col-md-12">
                  <h3 class="form-title font col-md-12 font-18">Available Contracts </h3>
                </div>
              </v-col>
              <v-col
                class="col-xl-6 pr-6 pt-6 col-md-6 cols-12 text-end"
                cols="6"
                align="end"
              >
                <v-btn class="my-1 gray-border-buttons">
                  <svg
                    class="mr-2"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 7.5H15"
                      stroke="#707070"
                      stroke-width="2"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.5 0V15"
                      stroke="#707070"
                      stroke-width="2"
                      stroke-linejoin="round"
                    />
                  </svg>

                  New</v-btn
                >
              </v-col>
            </v-row>
            <v-simple-table fixed-header height="800px" class="col-md-12 no-padding">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">CONTRACT NAME</th>
                    <th class="text-left">CLIENT(S)</th>
                    <th class="text-left">RATE STRUCTURE</th>
                    <th class="text-left">RATE AMOUNT</th>
                    <th class="text-left">RATE TYPE</th>
                    <th class="no-padding text-end justify-end">
                      <div class="col-md-12 pt-1 pb-0 justify-end d-flex text-end">
                        <v-checkbox class="no-padding "></v-checkbox>
                        <label for="" style="padding-top: 1px"
                          >SELECT ALL</label
                        >
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in allEmployers" :key="index">
                    <td v-if="item.name">
                      <a>{{ item.name }}</a>
                    </td>
                    <td v-else><a>/</a></td>
                    <td v-html="item.clients ? item.clients : '/'"></td>
                    <td
                      v-html="item.rateStructure ? item.rateStructure : '/'"
                    ></td>
                    <td v-html="item.rateAmount ? item.rateAmount : '/'"></td>
                    <td v-html="item.rateType ? item.rateType : '/'"></td>
                    <td
                      class="text-center mt-3 row no-padding justify-end"
                      style="padding-right: 110px"
                    >
                      <v-checkbox class="no-padding stay-blue"></v-checkbox>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
           
          </div>
           <v-row class="gray-underline-wizard margin-l-11">
              <div
                class="col-md-12 row justify-end text-end text-gray pt-10 pb-10"
              >
                <div class="mr-10 text-underline pointer pb-6 pt-4" @click="$router.go(-1)">Cancel</div>
                <div class="text-underline mr-10 pointer pb-6 pt-4" @click="addContract">Add Selected</div>
              </div>
            </v-row>
        </v-row>
      </div>
    </v-sheet>
  

    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
    <v-dialog v-model.trim="dialog1" width="600">
      <v-card>
        <v-card-title class="text-h5"> CONGRATULATIONS...! </v-card-title>
        <v-card-text>
          You have just contracted on the Apaly Health Platform. <br />
          You will be receiving a welcome package with educational materials
          that are designed to help your plan members become familiar with the
          Apaly Health solution.<br />
          In addition, we will be reaching out to gather some information about
          your TPA, so we can coordinate our provider medical claims process
          with them.
        </v-card-text>
        <v-card-actions>
          <div class="col-md-12 text-end">
            <v-btn
              class="blue-buttons text-end"
              @click="$router.push(`/dpc-providers/account/contract`)"
              >Close</v-btn
            >
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <contractPdf
      ref="contract_pdf"
      :name="''"
      :inpatient="0"
      :outpatient="0"
    ></contractPdf>
  </div>
</template>
<script>
import api from "../../../../../services/api";
import successModal from "../../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../../TablesTemplate/ErrorDialog.vue";
import ContractPdf from "../../../../TablesTemplate/ContractPDF.vue";
export default {
  components: {
    successModal,
    errorModal,
    ContractPdf,
  },
  data() {
    return {
      display: true,
      dialog1: false,
      windowWidth: window.innerWidth,
      inpatient: "",
      outpatient: "",
      priceMatch: "",
      contractName: "",
      checkboxError: "",
      checkbox: "",
      outpatientPriceMatch: "",
      inpatientPriceMatch: "",
      singleConDialog: false,
      customName: "",
      tab: null,
      href: "#tab-0",
      serviceAgreement: "",
      select: {
        text: "Fee for Service - Provider Services Agreement",
        value: "providerService",
      },
      allEmployers: [
        {
          name: "Digital Direct Healthcare - Provider Services Agreement",
          clients: "Multiple",
          rateStructure: "Open",
          rateAmount: "Open",
          rateType: "PMPM*",
        },
        {
          name: "Digital Direct Healthcare - Provider Services Agreement",
          clients: "Multiple",
          rateStructure: "Open",
          rateAmount: "Open",
          rateType: "PMPM*",
        },
        {
          name: "Digital Direct Healthcare - Provider Services Agreement",
          clients: "Multiple",
          rateStructure: "Open",
          rateAmount: "Open",
          rateType: "PMPM*",
        },
        {
          name: "Digital Direct Healthcare - Provider Services Agreement",
          clients: "Multiple",
          rateStructure: "Open",
          rateAmount: "Open",
          rateType: "PMPM*",
        },
      ],
      items: [
        {
          text: "Form and Custom Name",
          value: 0,
        },
        {
          text: "Rates",
          value: 1,
        },
        {
          text: "Acceptance",
          value: 2,
        },
      ],
    };
  },
  computed: {
    getClient() {
      return this.$store.getters.getClientGetter;
    },
    routeParams() {
      return this.$route.params.name;
    },
    itemtab() {
      return this.tab;
    },
  },
  created() {
    if (this.windowWidth < 900) {
      this.display = false;
    } else {
      this.display = true;
    }
    //   this.getOutpatientPriceMatch();
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    viewContract(name) {
      this.singleConDialog = true;
      this.$refs.contract_pdf.openPopUp();
    },
    clearContract() {
      this.customName = "";
      this.inpatient = "";
      this.outpatient = "";
      this.tab = "tab0";
    },
    getOutpatientPriceMatch() {
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.employerAdvisor;
      let employer = JSON.parse(localStorage.getItem("employer"));
      api()
        .get(
          `/advisors/${advisorId}/employers/${
            employer._id
          }/providersPriceMatch?inpatient=${Number(
            this.inpatient
          )}&outpatient=${Number(this.outpatient)}`
        )
        .then((res) => {
          this.outpatientPriceMatch = res.data.priceMatch.outpatient;
          this.inpatientPriceMatch = res.data.priceMatch.inpatient;
        });
    },
    getInpatientPriceMatch() {
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.employerAdvisor;
      let employer = JSON.parse(localStorage.getItem("employer"));
      api()
        .get(
          `/advisors/${advisorId}/employers/${
            employer._id
          }/providersPriceMatch?inpatient=${Number(
            this.inpatient
          )}&outpatient=${Number(this.outpatient)}`
        )
        .then((res) => {
          this.inpatientPriceMatch = res.data.priceMatch.inpatient;
        });
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
    },

    backTab(tab) {
      if (tab == "tab1") {
        this.tab = "tab0";
      } else if (tab == "tab2") {
        this.tab = "tab1";
      }
    },
    nextTab(tab) {
      if (tab == "tab0") {
        this.tab = "tab1";
      } else if (tab == "tab1") {
        this.tab = "tab2";
      }
    },
    createModal() {
      this.$router.push(`/dpc-providers/account/contract`);
    },
    addContract() {
      this.dialog1 = true;
      // if (this.customName && this.outpatient) {
      //   if (this.checkbox) {
      //     let user = JSON.parse(localStorage.getItem("user"));
      //     let advisorId = user.groupPortal[0].organization.employerAdvisor;
      //     let employer = JSON.parse(localStorage.getItem("employer"));
      //     let data = {
      //       form: this.serviceAgreement,
      //       name: this.customName,
      //       employerRates: {
      //         inpatient: this.inpatient,
      //         outpatient: this.outpatient,
      //       },
      //     };
      //     api()
      //       .post(
      //         `/advisors/${advisorId}/employers/${employer._id}/contract`,
      //         data
      //       )
      //       .then((res) => {
      //         if (res) {
      //           this.dialog1 = true;
      //         }
      //       });
      //   } else {
      //     this.checkboxError = "Please check the box first ";
      //   }
      // } else {
      //   this.$refs.error_modal.openPopUp(
      //     "Contract Name and Contract Rates Are Required"
      //   );
      // }
    },
    singleContract(item) {
      this.$store.commit("setSingleContract", item);
      this.$router.push("/contracts");
    },
    onResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 900) {
        this.display = false;
      } else {
        this.display = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.v-select__selection--comma {
  color: #1976d2;
}
</style>






 