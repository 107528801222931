<template>
   <div>
       <div class="row justify-center aling-center">
        <v-card class="justify-center aling-center col-md-12 " >
            <v-card-title class="justify-center">Search for Members</v-card-title>
            <v-card-text class="justify-center col-md-5" style="margin:0 auto">
                  <v-text-field  prepend-inner-icon="mdi-magnify" @input="searchMembers" class="justify-center aling-center text-center" placeholder="Search by Last Name or Member ID"></v-text-field>
                   <div class="blue-text   text-center">
                   <v-btn class="blue-buttons" small>Search for Memeber</v-btn>
                  </div>
                  <div class="blue-text text-underline  text-center mt-2">
                     <a @click="$router.push(`/provider-advisor/providers/${routeParams}/members/advanced-search`)">Advanced Search</a>
                  </div>
            </v-card-text>
        </v-card>

      </div>
      <div class="row justify-center aling-center mt-10">
       <v-card class="col-md-12 ">
        <div class="font row col-md-12 justify-space-between">
            <div>Recent Member Activity</div>
            <v-btn class="blue-buttons">Refresh</v-btn>
        </div>
        <v-row v-if="allMembers.length < 1 ">
          <v-col md="12">
              <a @click="$router.push(`/provider-advisor/providers/${routeParams}/members/advanced-search`)">You Don't have Members Yet. Search new Members.</a>
          </v-col>
      </v-row>
        <v-simple-table v-else fixed-header  class="mt-5" >
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                 Member Name
              </th>
              <th class="text-left">
                Member ID
              </th>
              <th class="text-left">
                Eligibility
              </th>
              <th class="text-left">
                Prior Auth Status
              </th>
              <th class="text-left">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item,index) in allMembers"
              :key="index"
            >
              <td><a    @click="singleMember(item)">{{ item.firstName }} {{item.lastName}} </a></td>
              <td class="text-gray">{{ item._id }} </td>
              <td v-html="item.status.charAt(0).toUpperCase() + item.status.slice(1)" :style="item.status == 'inactive' ? {'color':'red'} : {'color':'green'}">{{ item.status }}</td>
              <td >Pending</td>
              <td>
                  <a >New Prior Auth</a>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      </v-card>

      </div>
   
     
</div>
</template>
<script>
import api from '../../../../../services/api'
export default{
    data(){
        return{
         items: [],
         allMembers:[],
         filterArray:[]

        }
    },
    computed:{
       routeParams(){
        return this.$route.params.name
      }
    },
    methods:{
        getAllMembers(){
           let user = JSON.parse(localStorage.getItem('user'));
           let advisorId = user.groupPortal[0].organization.providerAdvisor
           let provider = JSON.parse(localStorage.getItem('provider'))
             api().get(`/advisors/${advisorId}/providers/${provider._id}/members`).then((res)=>{
               if(res){
                  this.allMembers = res.data.members
                  this.filterArray = res.data.members
               }
          })
     },
     searchMembers(val){
      this.allMembers = this.filterArray.filter((el)=>{
         if(el.firstName.toLowerCase().includes(val.toLowerCase())){
           return el.firstName.toLowerCase().includes(val.toLowerCase())
         }else if(el.lastName.toLowerCase().includes(val.toLowerCase())){
             return el.lastName.toLowerCase().includes(val.toLowerCase())
         }
       })
     },
     singleMember(item){
        if(!this.overlay && !this.showSaveButton){
           let user = JSON.parse(localStorage.getItem('user'));
           let advisorId = user.groupPortal[0].organization.providerAdvisor
           let provider = JSON.parse(localStorage.getItem('provider'))
             api().get(`/advisors/${advisorId}/providers/${provider._id}/members/${item._id}`).then((res)=>{
               if(res){
                 this.$store.commit('setSingleMember', res.data.member)
                  this.$router.push(`/provider-advisor/providers/${this.routeParams}/members/member`)
               }
          })
        }  
     }
    },
    created(){
      this.getAllMembers()
    }
}
</script>