<template>
  <v-row class=" row-height add-client-form">
    <div v-if="!isChildren" class="row col-xl-12" >
      <v-row class="no-padding">
        <h3 class="col-md-12">Account</h3>
      </v-row>
      <v-row class="col-md-12 text-center justify-center">
        <div class="col-md-12 col-xl-6 text-left">
          <v-card class="pointer" @click="$router.push('/family-members/account/info')">
            <v-card-title>Personal Information</v-card-title>
            <v-card-text> Name, phone, email </v-card-text>
          </v-card>
          <v-card class="mt-3 mb-3 pointer"  @click="$router.push('/family-members/account/payment')">
            <v-card-title>Billing and payment</v-card-title>
            <v-card-text>
              Prior monthly charges and payment information
            </v-card-text>
          </v-card>
          <v-card class="pointer" @click="$router.push('/family-members/account/settings')">
            <v-card-title>Settings</v-card-title>
            <v-card-text> Login, password, notifications </v-card-text>
          </v-card>
        </div>
      </v-row>
    </div>
    <v-col>
      <router-view></router-view>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
  },
};
</script>
