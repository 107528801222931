<template>
 <v-row class="add-client-form no-padding">
   <div class="row mb-4">
        <div class="col-md-12">
            <h3 class="form-title font">Add a Contract</h3>
        </div>
    </div>
    <v-tabs
      dark
      show-arrows
      style="background:#4072B7"
      v-model.trim="tab"
    >
    <v-tabs-slider style="background:orange"></v-tabs-slider>
     <v-tab
        style="background:#4072B7"
        v-for="(item, i) in items"
        :key="i"
        class="col-md-4"
        :href="'#tab'+ i"
        >
       
       {{item.text}}
    </v-tab>
 </v-tabs>
   <div class="col-md-12 form-box"  v-if="itemtab == 'tab0'">
    <div class="">
        <h3>Contract Name And Form</h3>
        <h5 class="text-gray mt-3">Contract Information</h5>
        
        <v-divider></v-divider>
        <br>
         <form class="row">
            <div class="col-md-6">
                <label class=" text-gray" for="">Name</label>
                <v-text-field value="Henry Ford Hospital North" solo></v-text-field>
            </div>
            <div class="col-md-6">
                <label  class="text-gray" for="">Type</label>
                <v-select solo></v-select>
            </div>
         </form>
         <br>
         <div>
             <div>
                  <h5 class="text-gray">Contract Form</h5>
             </div>
              <br> <v-divider></v-divider> <br>
             <v-row class="justify-space-between no-padding">
                 <a  @click="$router.push(`/provider-advisor/providers/${routeParams}/contracts/request`)">Provider Services Agreement</a>
                 <v-btn class="blue-buttons">Request a New Form</v-btn>
             </v-row>
         </div>
    </div>
   </div>
    <div class="col-md-12 form-box" v-if="itemtab == 'tab1'">
        <v-row>
        <v-col class="col-md-4" md="4" cols="12">
            <h3>
                <span class="font"> </span> Rate Schedule
            </h3>
        </v-col>
        <v-col class="col-md-4">
            <v-text-field solo placeholder="Search Contracts"></v-text-field>
        </v-col>
        <v-col class="col-md-4 text-end" cols="12"   align="end">
            <v-btn  class="my-1 blue-buttons" @click="dialog = true">Add Providers</v-btn>
        </v-col>
        </v-row>     
        <br>
        <v-simple-table fixed-header class="col-md-12">
        <template v-slot:default>
            <thead>
            <tr>
                <th class="text-left text-gray font">
                    Rate Schedule Name
                </th>
                <th class="text-left text-gray font">
                    Base Rate
                </th>
                <th class="text-left text-gray font">
                    Preferred Rate
                </th>
                <th class="text-left text-gray font">
                    Carve-Outs
                </th>
                <th class="text-left text-gray font">
                Exclusions
                </th>
                <th class="text-center text-gray font">
                Contracts
                </th>
                <th class="text-center text-gray font">
                Actions
                </th>
            </tr>
            </thead>
        </template>
        </v-simple-table>
        <v-row class="no-padding col-md-12">
            <a href="" @click="dialog = true">Add a Rate Schedule to this Contract</a>
        </v-row>
        <div class="text-center">
        <v-dialog
            v-model.trim="dialog"
            width="1500"
        >
        <v-card>
        <v-card-title class="text-h5 ">
            Add Providers
        </v-card-title>
        <v-card-text>
            <v-simple-table fixed-header >
            <template v-slot:default>
                <thead>
                <tr>
                    <th class="text-left">
                    Rate Schedule Name
                    </th>
                    <th class="text-left">
                    Base Rate
                    </th>
                    <th class="text-left">
                    Preferred Rate
                    </th>
                    <th class="text-left">
                    Carve-Outs
                    </th>
                    <th class="text-left">
                    Exclusions
                    </th>
                    <th class="text-left">
                    Contracts
                    </th>
                    <th class="text-center row no-padding">
                    <v-checkbox></v-checkbox>
                    <span class="mt-6">
                        Select All
                        </span>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="item in searchedItems"
                    :key="item.name"
                >
                    <td><a  @click="singleContract(item)">{{ item.name }}</a> </td>
                    <td>{{ item.date }}</td>
                    <td>{{ item.baseRate }}</td>
                    <td>{{ item.preferredRate }}</td>
                    <td>{{item.contract}}</td>
                    <td>{{ item.averageSavings }}</td>
                    <td>
                        <v-checkbox></v-checkbox>
                    </td>
                </tr>
                </tbody>
            </template>
            </v-simple-table>
            </v-card-text>

            <v-divider></v-divider>
            </v-card>
        </v-dialog>
        </div>
   </div>
    <div class="col-md-12 form-box" v-if="itemtab == 'tab2'">
       <v-row>
        <v-col class="col-md-4" md="4" cols="12">
            <h3>
                <span class="font"> </span> Location(s)
            </h3>
        </v-col>
        <v-col class="col-md-4">
            <v-text-field solo placeholder="Search Contracts by Name or NPI"></v-text-field>
        </v-col>
        <v-col class="col-md-4 text-end" cols="12"   align="end">
            <v-btn  class="my-1 blue-buttons" @click="dialog1 = true">Add Locations</v-btn>
        </v-col>
        </v-row>     
        <br>
        <v-simple-table fixed-header class="col-md-12">
        <template v-slot:default>
            <thead>
            <tr>
                <th class="text-left text-gray font">
                    Location Name
                </th>
                <th class="text-left text-gray font">
                   NPI2
                </th>
                <th class="text-left text-gray font">
                   Address 1
                </th>
                <th class="text-left text-gray font">
                    City
                </th>
                <th class="text-left text-gray font">
                   State
                </th>
                
                <th class="text-center text-gray font">
                Actions
                </th>
            </tr>
            </thead>
        </template>
        </v-simple-table>
        <v-row class="no-padding col-md-12">
            <a href="" @click="dialog1 = true">Assign Locations to this Contract</a>
        </v-row>
        <div class="text-center">
        <v-dialog
            v-model.trim="dialog1"
            width="1500"
        >
        <v-card>
        <v-card-title class="text-h5 ">
           Locations
        </v-card-title>
        <v-card-text>
            <v-simple-table fixed-header >
            <template v-slot:default>
                <thead>
                <tr>
                    <th class="text-left">
                   Location Name
                    </th>
                    <th class="text-left">
                    NPI 2
                    </th>
                    <th class="text-left">
                      Address 1
                    </th>
                    <th class="text-left">
                      City
                    </th>
                    <th class="text-left">
                      State
                    </th>
                    <th class="text-center row no-padding">
                    <v-checkbox></v-checkbox>
                    <span class="mt-6">
                        Select All
                        </span>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="item in locationItems"
                    :key="item.name"
                >
                    <td><a  @click="singleContract(item)">{{ item.name }}</a> </td>
                    <td>{{ item.date }}</td>
                    <td>{{ item.baseRate }}</td>
                    <td>{{ item.preferredRate }}</td>
                 
                    <td>{{ item.averageSavings }}</td>
                    <td>
                        <v-checkbox></v-checkbox>
                    </td>
                </tr>
                </tbody>
            </template>
            </v-simple-table>
            </v-card-text>

            <v-divider></v-divider>
            </v-card>
        </v-dialog>
        </div>
   </div>
    <div class="col-md-12 form-box" v-if="itemtab == 'tab3'">
     <v-row>
      <v-col class="col-md-4 mt-3" md="4" cols="12">
          <h3>
            <span class="font"> </span> Review and Accept
          </h3>
      </v-col>
    </v-row>      
      <v-simple-table fixed-header >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Contract Name
                </th>
                <th class="text-left">
                  Min Rates
                </th>
                <th class="text-left">
                Locations
                </th>
                <th class="text-left">
                  Carve-Outs & Exclusions
                </th>
                <th class="text-left">
                   Actions
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><a > Henry Ford North Provider Services Agreement</a> </td>
                <td class="text-gray">160% / 180%</td>
                <td class="text-gray">
                  All
                </td>
                <td class="text-gray">5</td>
                <td  class="text-center">
                  <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.5 19.5H26.5" stroke="#E40A0A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M32.3333 2H6.66667C4.08934 2 2 4.08934 2 6.66667V32.3333C2 34.9107 4.08934 37 6.66667 37H32.3333C34.9107 37 37 34.9107 37 32.3333V6.66667C37 4.08934 34.9107 2 32.3333 2Z" stroke="#E40A0A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-row cols="12" class="text-end justify-end mt-4">
            <div class="jumbotron col-md-4 text-left font">
               <b> Note:</b> When you proceed to contract, you will be committed to those rates for 12 months. You can lower your rates during that time, but not increase them.
            </div>
        </v-row>
     </div>
     <div class="col-md-12 d-flex align-end flex-column">
              <div>
                <v-btn class="blue-buttons mr-6">
                  Cancel
                </v-btn>
                <v-btn class="blue-buttons" @click="nextTab()">
                 Next
                </v-btn>
            </div>
        </div>
 </v-row>
 
</template>
<script>
 export default{
   data(){
       return{
          items:[
            {
              text:'Name and Form',
              value:1,
            },
            {
              text:'Rate Schedule',
              value:1,
            },  
            {
              text:'Locations',
              value:1,
            },  {
              text:'Acceptance',
              value:1,
            }
          ],
          tableItems:[
           

          ],
          tab: null,
          href:'#tab-0',
          locationItems:[
            {
                name: 'Henry Ford North',
                date: '12344456',
                baseRate: '123444 Lane',
                preferredRate:'Detorit',
                averageSavings:'MD',
                
            },
          ],
          searchedItems: [
          {
            name: 'Valarie Vaughn',
            date: '10/01/2021',
            baseRate: '200% / 160%',
            preferredRate:'185% / 145%',
            averageSavings:'35%',
            contract:'1'
          },
        ],
        dialog:false,
        dialog1:false,
        dialog2:false,
       }
   },
   computed:{
       itemtab(){
         return this.tab
       },
       routeParams(){
           return this.$route.params.name
       }
   },
    methods:{
      nextTab(){
         this.tab = '#tab-2'
      },
       changeTab(){
       }
    }
 }
</script>
<style >
.theme--dark.v-tabs > .v-tabs-bar {
     background-color: transparent !important;
}
</style>