const state ={
    singlePartnerInEmployerAdvisor:null,
    partnersSearchResultInEmployerAdvisor:[],
    deletePartnerButton:true,

}
const getters = {
    getPartnersSearchResultInEmployerAdvisor(state){
        return state.partnersSearchResultInEmployerAdvisor
    },
    getSinglePartnerInEmployerAdvisor(state){
        return state.singlePartnerInEmployerAdvisor
    },
    showDeletePartnerButton(state){
        return state.deletePartnerButton
    }
}
const mutations = {
    setSinglePartnerInEmployerAdvisor(state,partner){
        state.singlePartnerInEmployerAdvisor = partner
    },
    setPartnersSearchResultInEmployerAdvisor(state,partner){
        state.partnersSearchResultInEmployerAdvisor = partner
    },
    singlePartnerDeleteButton(state,show){
        state.deletePartnerButton = show
    }
}

export default{
    state,
    getters,
    mutations,
}