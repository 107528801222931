import SolutionVendor from '../views/SolutionVendor.vue'
import RouterView from '../components/TablesTemplate/RouterView.vue'
import Home from '../components/Sections/SolutionVendor/Home.vue'
import Clients from '../components/Sections/SolutionVendor/Clients/Clients.vue'
import ClientDetails from '../components/Sections/SolutionVendor/Clients/Details.vue'
import NewClient from '../components/Sections/SolutionVendor/Clients/NewClient.vue'

import Invoices from '../components/Sections/SolutionVendor/Invoices/Invoices.vue'
import InvoiceDetails from '../components/Sections/SolutionVendor/Invoices/Details.vue'
import InvoiceNew from '../components/Sections/SolutionVendor/Invoices/NewInvoice.vue'

import Account from '../components/Sections/SolutionVendor/Settings/Account'
import Settings from '../components/Sections/SolutionVendor/Settings/Settings'
import Users from '../components/Sections/SolutionVendor/Settings/Users/Users'
import UserDetails from '../components/Sections/SolutionVendor/Settings/Users/SingleUser'
import AddUser from '../components/Sections/SolutionVendor/Settings/Users/AddUser'


function routeGuard(to, from, next) {
    let user = JSON.parse(localStorage.getItem('user'));
    if(!user){
        next('/login');
    }
    else if ( user.type != 'vendor' || !$cookies.get('token')){
      next('/login');
    }else{
        next();
    }
      
}
const router = [
    {
       path:'/solution-vendor',
       name:'',
       beforeEnter: routeGuard,
       redirect: () => {
           return { path: '/solution-vendor/home'} 
       },
    },
   {
       component:RouterView,
       path:'/solution-vendor',
       name:'',
       beforeEnter: routeGuard,
       redirect: () => {
           return { path: '/solution-vendor'} 
       },
       children:[
           {
               path:'/solution-vendor',
               name:'',
               beforeEnter: routeGuard,
               component:SolutionVendor,
               children:[
                {
                    path:'home',
                    name:'Home',
                    component:Home,
                },
                {
                    path:'home',
                    name:'Home',
                    component:RouterView,
                    redirect: () => {
                        return { path: '/solution-vendor/home'} 
                    },
                    children:[
                        {
                            path:'/solution-vendor/solutions',
                            name:'Solutions',
                            component:RouterView,
                          
                        },
                        {
                            path:'clients',
                            name:'',
                            component:RouterView,
                            redirect: () => {
                                return { path: '/solution-vendor/clients'} 
                            },
                            children:[                 
                                {  
                                    path:'/solution-vendor/clients',
                                    name:'Clients',
                                    component:Clients,
                                    meta:{
                                        type:'clients'
                                    },
                                    children:[
                                        {
                                            path:'/solution-vendor/clients/details/:id',
                                            name:'Client Detail',
                                            component:ClientDetails,
                                            meta:{
                                                isChildren:true,
                                                
                                            },
                                        },
                                        {
                                            path:'/solution-vendor/clients/add-client',
                                            name:'New Client',
                                            component:NewClient,
                                            meta:{
                                                isChildren:true,
                                                
                                            },
                                        }
                                    ]
                                },
                                {  
                                    path:'/solution-vendor/invoices',
                                    name:'Invoices',
                                    component:Invoices,
                                    meta:{
                                        type:'invoices'
                                    },
                                    children:[
                                        {
                                            path:'/solution-vendor/invoices/details/:id',
                                            name:'Invoice Detail',
                                            component:InvoiceDetails,
                                            meta:{
                                                isChildren:true,
                                                
                                            },
                                        },
                                        {
                                            path:'/solution-vendor/invoices/new',
                                            name:'New Invoice',
                                            component:InvoiceNew,
                                            meta:{
                                                isChildren:true,
                                                
                                            },
                                        },
                                       
                                    ]
                                },
                            ]
                        },
                        {
                            path:'account',
                            name:'',
                            component:RouterView,
                            redirect: () => {
                                return { path: '/solution-vendor/account'} 
                            },
                            children:[                 
                                {  
                                    path:'/solution-vendor/account',
                                    name:'Account',
                                    component:Account,
                               
                                },
                            ]
                        },   {
                            path:'users',
                            name:'',
                            component:RouterView,
                            redirect: () => {
                                return { path: '/solution-vendor/users'} 
                            },
                            children:[                 
                                {  
                                    path:'/solution-vendor/users',
                                    name:'Users',
                                    component:Users,
                                    children:[
                                        {
                                            path:'/solution-vendor/users/details',
                                            name:'User Detail',
                                            component:UserDetails,
                                            meta:{
                                                isChildren:true
                                            },
                                        },
                                        {
                                            path:'/solution-vendor/users/add-new',
                                            name:'Add New User',
                                            component:AddUser,
                                            meta:{
                                                isChildren:true
                                            },
                                        }
                                    ]
                                },
                            ]
                        },
                        {
                            path:'settings',
                            name:'',
                            component:RouterView,
                            redirect: () => {
                                return { path: '/solution-vendor/settings'} 
                            },
                            children:[                 
                                {  
                                    path:'/solution-vendor/settings',
                                    name:'Settings',
                                    component:Settings,
                               
                                },
                            ]
                        },
                        {
                            path:'/solution-vendor/profile',
                            name:'Profile',
                            component:RouterView,
                          
                        },
                    
                    ]
                },
                
               ]
           }
       ]
   },

]

export default router;