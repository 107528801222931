<template>
    <div>
        <EmpContract v-if="route=='/employer/contract'"></EmpContract>
        <EmpAddContract v-if="route=='/employer/contract/add-new'"></EmpAddContract>
        <EmpNewRequest v-if="route=='/employer/contract/request'"></EmpNewRequest>
        <EmpSingleContract v-if="route=='/employer/contract/details'"></EmpSingleContract>
     
    </div>
</template>

<script>
import EmpContract from "./EmpContract.vue";
import EmpAddContract from "./AddContract/EmpAddContract.vue";
import EmpNewRequest from "./NewRequest/EmpNewRequest.vue";
import EmpSingleContract from './SingleContract/Contract.vue'

    export default {
        name:'EmpContactTemplate',
        components: {
            EmpContract,
            EmpAddContract,
            EmpNewRequest,
            EmpContract,
            EmpSingleContract
        },
        computed:{
            route(){
                return this.$route.path
            }
        }
    }
</script>