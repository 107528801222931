<template>
  <div class="">
    <div v-if="!isChildren" class="row-height">
 <Header
        :title="'Invoices'"
        :searchInput="true"
        :inviteButton="false"
        :exportButton="true"
        :newButton="false"
        :addButton="true"
        @export="exportFun"
        @invite="invite"
        @add="add"
        @searchInput="searchInput"
        :placeholder="'Search Invoices'"
        :sort="false"
      ></Header>
    </div>
  </div>
</template>
<script>
import Header from "../Layout/HeaderPage.vue";
import api from "../../../../services/api";

export default {
  components: {
    Header,
  },
  computed: {
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
  },
  data() {
  return {

  }
  },
  methods: {
     invite() {},
    add() {
         this.$router.push("/digital-invoicing/invoices/add-new");
    },
    exportFun() {
      this.exportData("tblData");
    },

    searchInput(val) {
      this.items = this.filteredArray.filter((el) => {
        if (
          el.invoiceNo &&
          el.invoiceNo.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.invoiceNo.toLowerCase().includes(val.toLowerCase());
        } else if (
          el.companyName &&
          el.companyName.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.companyName.toLowerCase().includes(val.toLowerCase());
        }
      });
      if (this.items.length < 1) {
        this.filterItems = true;
      } else {
        this.filterItems = false;
      }
    },
  }
}
</script>