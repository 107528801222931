<template>
  <div class="container search-emp-card row-height-custom">
    <div class="col-md-6 col-lg-6 col-xl-4">
      <v-card class="pl-0 pr-0">
        <div class="orange-underline-wizard text-center">
          <h3
            class="text-underline col-md-12 pt-3 light-blue-text"
            @click="firstForm = true"
          >
            Providers
          </h3>
          <div
            v-if="$route.path.includes('search-members')"
            class="text-gray pb-3"
          >
            Are there plan members in your market?
          </div>
          <div v-else class="text-gray pb-3">
            Have employers requested to connect with you?
          </div>
        </div>
        <v-form v-model="valid" ref="form" class="justify-center">
          <div class="col-md-12 justify-center row">
            <div class="col-md-12 no-padding justify-center row">
              <div class="col-md-10 justify-center">
                <div class="col-md-12">
                  <label for="" style="font-weight: normal"
                    >Pull up your profile and find out.</label
                  >
                  <v-text-field
                    class="border-r-8 input-background"
                    prepend-inner-icon="mdi-magnify"
                    light
                    dense
                    filled
                    type="number"
                    outlined
                    :disabled="disabledNpi"
                    hide-details
                    @input="searchOnInput"
                    v-model="npi"
                    placeholder="Enter NPI Number"
                    persistent-hint
                  ></v-text-field>
                </div>
              </div>
            </div>
            <v-row
              wrap
              no-gutters
              class="col-md-12 text-center justify-center mt-0 pt-0 pb-0 mb-0"
            >
              <v-col cols="3" class="text-center mt-3">
                <v-divider />
              </v-col>
              <v-col cols="1" class="text-center text-gray ml-6 mr-3">
                OR
              </v-col>
              <v-col cols="4" class="text-center mt-3">
                <v-divider />
              </v-col>
            </v-row>
            <div
              class="mx-0 text-left col-md-12 justify-center row mt-0 pt-1"
              align="center"
            >
              <div
                class="
                  col-md-12
                  text-center
                  justify-center
                  text-gray
                  d-flex
                  aling-center
                  pb-0
                "
              >
                Individual

                <v-switch
                  solo
                  v-model="switchInfo"
                  class="no-padding ml-2 mr-1 primary-switch"
                ></v-switch>
                Organization
              </div>
              <div class="col-md-10 no-padding">
                <div class="col-md-12">
                  <v-select
                    hide-details
                    class="border-r-8"
                    light
                    dense
                    outlined
                    v-model="state"
                    item-text="name"
                    item-value="value"
                    :items="states"
                    @change="searchOnInput"
                    filled
                    :disabled="disabledFileds"
                    placeholder="Select a state"
                  ></v-select>
                </div>
                <div class="col-md-12" v-if="!switchInfo">
                  <v-text-field
                    hide-details
                    class="border-r-8"
                    light
                    @input="searchOnInput"
                    dense
                    :disabled="disabledFileds"
                    v-model="lastName"
                    outlined
                    filled
                    placeholder="Enter Individual Provider’s Last Name"
                  ></v-text-field>
                </div>
                <div v-else class="col-md-12">
                  <v-text-field
                    hide-details
                    class="border-r-8"
                    light
                    dense
                    @input="searchOnInput"
                    :disabled="disabledFileds"
                    outlined
                    filled
                    v-model="organizationName"
                    persistent-hint
                    placeholder="Enter Organization Name"
                  ></v-text-field>
                </div>
              </div>
              <div class="col-md-10">
                <v-alert
                  dense
                  outlined
                  :type="errorMessage.length > 0 ? 'error' : 'info'"
                  class="small-text"
                >
                  Enter either NPI number OR State and Organization
                  Name/Provider's Last Name.
                </v-alert>
              </div>
            </div>

            <div class="col-md-12 pt-0 row justify-center text-center mb-2">
              <div class="col-md-10 row justify-space-between">
                <a class="text-underline" @click="clearForm">Clear Form</a>
                <v-btn class="blue-buttons mt-0 ml-2" @click="showResults"
                  >Search</v-btn
                >
              </div>
            </div>
          </div>
        </v-form>
      </v-card>
      <v-dialog v-model.trim="selectProviderDialog" width="500">
        <v-card>
          <v-card-title class="orange-underline-wizard">
            <div class="col-md-3">
              <svg
                @click="selectProviderDialog = false"
                width="15"
                height="26"
                class="cursor-pointer"
                viewBox="0 0 15 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.9398 24.5L2.21975 13.78C2.15009 13.7104 2.09482 13.6278 2.05712 13.5369C2.01941 13.4459 2 13.3484 2 13.25C2 13.1516 2.01941 13.0541 2.05712 12.9631C2.09482 12.8722 2.15009 12.7896 2.21975 12.72L12.9398 2"
                  stroke="#4072B7"
                  stroke-width="2.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>

            <a
              class="
                text-center text-underline
                justify-center
                col-md-6
                font-weight-bold
              "
              >Select a Provider</a
            >
          </v-card-title>
          <v-card-text class="text-center mt-6 pl-0 pr-0">
            <div class="row col-md-12 justify-center align-center pr-0">
              <v-simple-table
                fixed-header
                height="410px"
                class="col-md-12 pl-0 pr-0"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Providers Search Result</th>
                    </tr>
                  </thead>

                  <tbody class="text-center" v-if="load">
                    <v-progress-circular
                      indeterminate
                      size="16"
                      class="text-center"
                      color="primary"
                    ></v-progress-circular>
                  </tbody>
                  <tbody v-else-if="array.length > 0">
                    <tr v-for="(item, i) in array" :key="i">
                      <td
                        class="text-gray text-left"
                        v-if="
                          item &&
                          item['Entity Type Code'] == 2 &&
                          item[
                            'Provider Organization Name (Legal Business Name)'
                          ]
                        "
                      >
                        <a @click="showDetails(item)">
                          {{
                            item[
                              "Provider Organization Name (Legal Business Name)"
                            ]
                          }}</a
                        >
                      </td>
                      <td
                        class="text-gray text-left"
                        v-else-if="
                          item &&
                          item['Entity Type Code'] == 1 &&
                          item['Provider First Name']
                        "
                      >
                        <a @click="showDetails(item)">
                          {{ item["Provider First Name"] }}
                          {{ item["Provider Last Name (Legal Name)"] }}
                        </a>
                      </td>
                      <td class="text-left text-gray" v-else>
                        <a class="text-underline" @click="showDetails(item)"
                          >/</a
                        >
                      </td>
                    </tr>
                  </tbody>

                  <tbody v-else-if="array.length < 1">
                    <v-col md="12" cols="12">
                      <v-alert
                        border="bottom"
                        colored-border
                        type="warning"
                        elevation="2"
                        class="text-gray text-left"
                      >
                        <span v-if="errMessage"> {{ errMessage }}</span>
                        <span v-else> No results.</span>
                      </v-alert>
                    </v-col>
                  </tbody>
                </template>
              </v-simple-table>
              <div class="col-md-12"></div>
              <div class="col-md-12 text-end">
                <v-btn
                  v-if="array.length > 0 && showButton"
                  @click="showResults('loadMore')"
                  :disabled="loadData"
                  class="blue-buttons"
                  >Load More Data</v-btn
                >
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model.trim="emloyersDetailsDialog" width="500">
        <v-card v-if="singleItem">
          <v-card-title class="orange-underline-wizard">
            <div class="col-md-1">
              <svg
                @click="
                  (emloyersDetailsDialog = false), (selectProviderDialog = true)
                "
                width="12"
                height="23"
                class="cursor-pointer"
                viewBox="0 0 15 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.9398 24.5L2.21975 13.78C2.15009 13.7104 2.09482 13.6278 2.05712 13.5369C2.01941 13.4459 2 13.3484 2 13.25C2 13.1516 2.01941 13.0541 2.05712 12.9631C2.09482 12.8722 2.15009 12.7896 2.21975 12.72L12.9398 2"
                  stroke="#4072B7"
                  stroke-width="2.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <h3 class="text-underline col-md-11 text-center font-weight-bold">
              <a style="font-size: 16px" class="mr-10">{{ singleItem.name }}</a>
            </h3>
          </v-card-title>
          <v-card-text class="text-center mt-6 pl-0 pr-0">
            <br />
            <div v-if="singleItem.result == null">
              <svg
                width="70"
                height="70"
                viewBox="0 0 60 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M30 60C46.5686 60 60 46.5686 60 30C60 13.4315 46.5686 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5686 13.4315 60 30 60ZM45.1706 18.4935C45.9163 17.2158 45.4851 15.5756 44.2071 14.8299C42.9296 14.0842 41.2894 14.5155 40.5438 15.7931L27.1024 38.8235L18.8163 32.1945C17.6611 31.2704 15.9755 31.4577 15.0513 32.6129C14.1272 33.768 14.3145 35.4536 15.4697 36.3777L24.2436 43.3967C24.7828 43.8369 25.4125 44.1523 26.0877 44.3211C26.7646 44.4904 27.4704 44.508 28.1546 44.3726C28.8286 44.2436 29.4675 43.9719 30.0282 43.5763C30.5934 43.1773 31.0672 42.6594 31.4153 42.0617L45.1706 18.4935Z"
                  fill="#20A914"
                />
              </svg>
              <br /><br />
              <h3>{{ singleItem.employersMessage }}</h3>
              <br />
              <br />
            </div>
            <div v-else-if="singleItem.result == 0">
              <br />
              <br />
              <br />
              <br />
              <h3>{{ singleItem.employersMessage }}</h3>
              <br />
              <br />
              <br />
              <br />
            </div>
            <div v-else>
              <br />
              <br />
              <h1>{{ singleItem.result }}</h1>
              <br />

              <h3>{{ singleItem.employersMessage }}</h3>
              <br />
              <br />
            </div>

            <br />
            <v-divider></v-divider>
            <br />

            <h3>
              <a @click="$router.push('/register')" class="text-underline"
                >CREATE A FREE ACCOUNT</a
              >
            </h3>
            <br />

            <h4 v-if="singleItem.result != 0">to see who they are</h4>
            <h4 v-else>to be notified when they do</h4>
            <br />
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model.trim="organizationDialog" width="500">
        <v-card v-if="singleItem">
          <v-card-title class="orange-underline-wizard">
            <div class="col-md-1">
              <svg
                @click="
                  (organizationDialog = false), (selectProviderDialog = true)
                "
                width="12"
                height="23"
                class="cursor-pointer"
                viewBox="0 0 15 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.9398 24.5L2.21975 13.78C2.15009 13.7104 2.09482 13.6278 2.05712 13.5369C2.01941 13.4459 2 13.3484 2 13.25C2 13.1516 2.01941 13.0541 2.05712 12.9631C2.09482 12.8722 2.15009 12.7896 2.21975 12.72L12.9398 2"
                  stroke="#4072B7"
                  stroke-width="2.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <h3 class="text-underline col-md-11 text-center font-weight-bold">
              <a style="font-size: 16px" class="mr-10">{{ singleItem.name }}</a>
            </h3>
          </v-card-title>
          <v-card-text class="text-center mt-6 pl-0 pr-0">
            <br />
            <div v-if="singleItem.members">
              <svg
                width="70"
                height="70"
                viewBox="0 0 60 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M30 60C46.5686 60 60 46.5686 60 30C60 13.4315 46.5686 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5686 13.4315 60 30 60ZM45.1706 18.4935C45.9163 17.2158 45.4851 15.5756 44.2071 14.8299C42.9296 14.0842 41.2894 14.5155 40.5438 15.7931L27.1024 38.8235L18.8163 32.1945C17.6611 31.2704 15.9755 31.4577 15.0513 32.6129C14.1272 33.768 14.3145 35.4536 15.4697 36.3777L24.2436 43.3967C24.7828 43.8369 25.4125 44.1523 26.0877 44.3211C26.7646 44.4904 27.4704 44.508 28.1546 44.3726C28.8286 44.2436 29.4675 43.9719 30.0282 43.5763C30.5934 43.1773 31.0672 42.6594 31.4153 42.0617L45.1706 18.4935Z"
                  fill="#20A914"
                />
              </svg>
              <br /><br />
              <h3>{{ singleItem.membersMessage }}</h3>
              <br />
              <br />
            </div>
            <div v-else>
              <br />
              <br />
              <br />
              <br />
              <h3>{{ singleItem.membersMessage }}</h3>
              <br />
              <br />
              <br />
              <br />
            </div>

            <br />
            <v-divider></v-divider>
            <br />

            <h3>
              <a @click="$router.push('/register')" class="text-underline"
                >CREATE A FREE ACCOUNT</a
              >
            </h3>
            <br />

            <h4 v-if="singleItem.members">to see how many</h4>
            <h4 v-else>we’ll notify you as we expand</h4>
            <br />
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import api from "../services/api";

export default {
  data() {
    return {
      array: [],
      search: "",
      firstForm: true,
      showModal: false,
      showForm: false,
      advancedForm: false,
      searchCity: "",
      searchState: "",
      errMessage: "",
      singleItem: null,
      load: false,
      afterSearch: false,
      loadData: false,
      showButton: false,
      page: 1,
      switchInfo: "",
      state: "",
      states: [],
      disabledFileds: false,
      errorMessage: "",
      requiredRules: [(v) => !!v || "This field is required"],
      npi: "",
      disabledNpi: false,
      organizationName: "",
      lastName: "",
      valid: false,
      selectProviderDialog: false,
      organizationDialog: false,
      emloyersDetailsDialog: false,
    };
  },
  created() {
    this.getStates();
  },
  methods: {
    clearForm() {
      this.npi = "";
      this.organizationName = "";
      this.lastName = "";
      this.state = "";
      this.disabledFileds = false;
      this.disabledNpi = false;
      this.page = 1;
    },
    showDetails(item) {
      api()
        .get("/api/npi/search/" + item._id)
        .then((res) => {
          if (res) {
            this.singleItem = res.data;
            this.selectProviderDialog = false;
            if (!this.$route.path.includes("search-employers")) {
              this.organizationDialog = true;
            } else {
              this.emloyersDetailsDialog = true;
            }
          }
        })
        .catch((err) => {
          this.errMessage = err.response.data.message;
        });
    },
    showResults(type) {
      if (
        this.npi ||
        (this.state && this.organizationName) ||
        (this.state && this.lastName)
      ) {
        this.errorMessage = "";
        this.$refs.form.validate();
        if (this.valid) {
          this.selectProviderDialog = true;
          this.afterSearch = true;
          this.load = true;
          this.loadData = true;

          let data = null;
          if (this.npi) {
            data = {
              NPI: this.npi,
            };
          } else if (this.organizationName && this.switchInfo) {
            data = {
              type: 2,
              state: this.state,
              keyword: this.organizationName,
            };
          } else {
            data = {
              type: 1,
              state: this.state,
              keyword: this.lastName,
            };
          }

          if (type == "loadMore") {
            this.page++;
          } else {
            this.array = [];
            this.page = 1;
          }
          api()
            .post("/api/npi/searchNPI?page=" + this.page, data)
            .then((res) => {
              if (res) {
                this.load = false;
                this.loadData = false;

                if (res.data.providers.length <= 9) {
                  this.showButton = false;
                } else {
                  this.showButton = true;
                }

                res.data.providers.forEach((el) => {
                  this.array.push(el);
                });
              }
            })
            .catch((err) => {
              this.load = false;
              this.loadData = false;
              this.selectProviderDialog = false;
              this.errMessage = err.response.data.message;
            });
        }
      } else {
        this.array = [];
        this.errorMessage =
          "Enter either NPI number OR your First Name AND Last Name. If you enter NPI only, the First Name and Last Name are not mandatory.";
      }
    },
    getStates() {
      api()
        .get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },
    eventHandling(event) {
      if (event.keyCode == 13) {
        this.showResults();
      }
    },
    searchOnInput() {
      if (this.npi) {
        this.organizationName = "";
        this.lastName = "";
        this.state = "";
        this.disabledFileds = true;
        this.disabledNpi = false;
      } else if (this.organizationName || this.lastName || this.state) {
        this.npi = "";
        this.disabledNpi = true;
        this.disabledFileds = false;
      } else {
        this.clearForm();
      }
      this.page = 1;
    },
    showDialog(item) {
      api()
        .get("/api/npi/search/" + item._id)
        .then((res) => {
          if (res) {
            this.singleItem = res.data;
            this.showModal = true;
          }
        })
        .catch((err) => {
          this.errMessage = err.response.data.message;
        });
    },
    // showResults(data) {
    //   if (this.search) {
    //     this.afterSearch = true;
    //     this.load = true;
    //     if (data == "loadMore") {
    //       this.loadData = true;
    //       this.page++;
    //     } else {
    //       this.array = [];
    //     }
    //     let data = {
    //       keyword: this.search,
    //     };
    //     api()
    //       .post("/api/npi/search?page=" + this.page, data)
    //       .then((res) => {
    //         if (res) {
    //           this.load = false;
    //           this.loadData = false;
    //           if (res.data.providers.length < 100) {
    //             this.showButton = false;
    //           } else {
    //             this.showButton = true;
    //           }

    //           res.data.providers.forEach((el) => {
    //             this.array.push(el);
    //           });
    //         }
    //       })
    //       .catch((err) => {
    //         this.errMessage = err.response.data.message;
    //       });
    //   } else if (this.searchCity || this.searchState) {
    //     this.array = [
    //       {
    //         name: "Tampa General Hospital",
    //       },
    //       {
    //         name: "Tampa General Hospital",
    //       },
    //       {
    //         name: "Tampa General Hospital",
    //       },
    //       {
    //         name: "Tampa General Hospital",
    //       },
    //       {
    //         name: "Tampa General Hospital",
    //       },
    //       {
    //         name: "Tampa General Hospital",
    //       },
    //     ];
    //   } else {
    //     this.array = [];
    //   }
    // },
  },
};
</script>
<style>
.small-cards {
  height: 200px;
  background: #ffffff !important;
  border: 1px solid rgba(112, 112, 112, 0.5) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 8px !important;
}
.text-bottom-center {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.search-emp-card {
  display: flex;
  align-items: center;
  justify-content: center;
}
.row-height-custom {
  height: 100vh;
}
.theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
  background: #f9f9fa !important;
}
</style>