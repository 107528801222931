<template>
  <div class="page-background">
    <main-header :title="'Personal'" :color="'white'" ></main-header>
    <div class="mobile-edit-form">
      <form class="forma">
        <div>
          <h3 class="from-headers">Personal Information</h3>
          <v-text-field outlined label="First Name" color="white" class="input-text"></v-text-field>
          <v-text-field outlined label="Last Name" color="white"></v-text-field>
          <v-text-field color="white" type="date" outlined label="Date of Birth"></v-text-field>
          <v-select color="white" :items="genders" label="Select" outlined></v-select>
        </div>

        <div>
          <h3 class="from-headers">Contact</h3>
          <v-text-field type="email" outlined label="Email" color="white"></v-text-field>
          <v-text-field type="tel" outlined label="Phone" placeholder="000-000-0000 " color="white"></v-text-field>
          <v-text-field color="white" type="number" placeholder="00000" outlined label="Home Zip Code"></v-text-field>
        </div>

        <div>
          <h3 class="from-headers">
            Insurance Information <br />
            <span class="from-sub-headers">(from your insurance card)</span>
          </h3>
          <v-text-field outlined label="Member ID" color="white" placeholder="000-000000"></v-text-field>
          <v-text-field outlined label="Group ID" placeholder="000-000000" color="white"></v-text-field>
          <v-text-field outlined label="Payer ID" placeholder="000-000000" color="white"></v-text-field>
          <v-select color="white" :items="relationships" label="Select" outlined></v-select>
        </div>
      </form>

    </div>
    <div class="d-flex footer"  style="">
        <div class="col-6">
            <v-btn class="footer-btn-cancel" >Cancel</v-btn>
        </div>
        <div class="col-6">
            <v-btn color="white" class="footer-btn" >Save</v-btn>
        </div>
    </div>
  </div>
</template>

<script>
import mainHeader from "@/components/Sections/DPCMember/Layout/mainHeader";
export default {
  data() {
    return {
      relationships: ["Spouse"],
      genders: ["male", "female"],
    };
  },
  components: {
    mainHeader,
  },
  methods: {},
  created() {},
};
</script>
<style scoped src='./../../../../assets/mobileStyle.css'></style>
<style scoped lang="scss">


.mobile-edit-form {
  background-color: #16407a;
  height: 100vh;
  padding: 20px;
  overflow-y: auto;
  padding-bottom: 150px !important;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  
}

.mobile-content {
  padding: 16px 24px 0 24px;
}

.page-background {

  background-color: #001e46;
}

.list-item-title {
  color: #0573f3;
  font-family: "Quicksand";
  font-weight: 700;
}

.list-item-title {
  color: #0573f3;
  font-family: "Quicksand";
  font-weight: 700;
}

.list-item-subtitle {
  font-family: "Quicksand";
  font-weight: 600;
}

.second-subtitle {
  color: #001e46;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.mobile-edit-form v-text-field {
  color: white;
}

.from-headers {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  padding-bottom: 15px;
  color: white;
}

.from-sub-headers {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.footer{
    background-color: #16407a;
    height:80px;
    position:absolute;
    bottom:0;
    width:100%;
    align-items: flex-start;
    border-top: 1px solid #C9C9C9
}
.footer-btn{
    border-radius: 23px;
    width: 100%;
}

.footer-btn-cancel{
    background-color:#16407a !important;
    border-radius: 23px;
    width: 100%;
    border: 1px solid white !important;
    color: white;
    float:right
}



// .input-text {
//         ::v-deep {
//             .v-text-field {
//                 input {
//                     color: red;
//                 }
//             }
//         }
//     }
.forma v-text-field{
    color: red;
}
</style>
