<template>
  <v-row class="add-client-form no-padding">
    <div class="col-md-12 form-box">
      <div class="row">
        <div class="col-md-12">
          <div class="col-md-12 row justify-space-between">
            <h3
              class="form-title font"
              v-html="
                $store.getters.getSinglePartner
                  ? $store.getters.getSinglePartner.name
                  : 'Partner Detail'
              "
            ></h3>
          </div>
        </div>
      </div>
      <div class="form-row-height ">
        <form class="row">
          <div class="col-md-6 no-padding">
            <div class="col-md-12 pb-0">
              <label class="font text-gray" for="">First Name</label>
              <v-text-field
                required
                solo
                v-model.trim="firstName"
                disabled
              ></v-text-field>
            </div>
            <div class="col-md-12 pt-0 pb-0">
              <label class="font text-gray" for="">Last Name</label>
              <v-text-field
                required
                v-model.trim="lastName"
                solo
                disabled
              ></v-text-field>
            </div>
            <div class="col-md-12 pt-0 pb-0">
              <label class="font text-gray" for="">Email</label>
              <v-text-field
                required
                v-model.trim="email"
                :rules="emailRules"
                disabled
                solo
              ></v-text-field>
            </div>
            <div class="col-md-12 pt-0 pb-0">
              <label class="font text-gray" for="">Phone</label>
              <v-text-field
                required
                v-model.trim="phone"
                :rules="phoneRules"
                disabled
                solo
              ></v-text-field>
            </div>
          </div>
          <div class="col-md-6 no-padding">
            <div class="col-md-12 pb-0">
              <label class="font text-gray" for="">Company Name</label>
              <v-text-field
                required
                solo
                v-model.trim="companyName"
                disabled
              ></v-text-field>
            </div>
            <div class="col-md-12 pt-0 pb-0">
              <label class="font text-gray" for="">Title</label>
              <v-text-field
                required
                v-model.trim="title"
                disabled
                solo
              ></v-text-field>
            </div>
            <v-row class="no-padding">
              <div class="col-md-6 pt-0 pb-0">
                <label class="font text-gray" for="">City</label>
                <v-text-field
                  required
                  v-model.trim="city"
                  disabled
                  solo
                ></v-text-field>
              </div>
              <div class="col-md-3 pt-0 pb-0">
                <label class="font text-gray" for="">State</label>
                <v-text-field solo v-model.trim="state" disabled></v-text-field>
              </div>
              <div class="col-md-3 pt-0 pb-0">
                <label class="font text-gray" for="">Zip</label>
                <v-text-field
                  required
                  solo
                  disabled
                  type="number"
                  v-model.trim="zip"
                ></v-text-field>
              </div>
              <div class="col-md-12 pt-0 pb-0">
                <label class="font text-gray" for="">Type</label>
                <v-text-field
                  disabled
                  required
                  v-model.trim="type"
                  solo
                ></v-text-field>
              </div>
              <div class="col-md-12 pt-0 pb-0" v-if="showDeleteBtn">
                <div class="d-flex no-padding" v-if="showCheckboxes">
                  <v-checkbox
                    @change="selectCheckbox"
                    class="mr-3"
                    v-model.trim="jointlyManage"
                  ></v-checkbox>
                  <span class="mt-5"
                    >Allow this partner to access and jointly manage this
                    employer portal.</span
                  >
                </div>
                <div class="d-flex no-padding" v-if="showCheckboxes">
                  <v-checkbox
                    @change="selectCheckbox"
                    class="mr-3"
                    v-model.trim="administratorPartner"
                  ></v-checkbox>
                  <span class="mt-5"
                    >This partner is a designated plan administrator.</span
                  >
                </div>
              </div>
            </v-row>
          </div>
        </form>
      </div>
      <div
        class="col-md-12 d-flex align-end flex-column"
        v-if="$role != 'user'"
      >
        <div>
          <v-btn
            tabindex="16"
            class="blue-buttons mr-6 mb-2"
            @click="$router.go(-1)"
          >
            Close
          </v-btn>
          <v-btn
            class="blue-buttons mb-2"
            v-if="showDeleteBtn"
            @click="removeItem"
          >
            Remove Partner
          </v-btn>
        </div>
      </div>
    </div>
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <v-dialog v-model.trim="dialog" max-width="600px" persistent>
      <v-card>
        <v-col>
          <h3>Remove Advisor</h3>
        </v-col>
        <v-col>
          <span>Are you sure you want to delete this Advisor?</span>
        </v-col>
        <v-col>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="blue-buttons float-class" @click="confirmRemove"
              >Confirm</v-btn
            >
            <v-btn class="button-style float-class" @click="closePopUp"
              >Close</v-btn
            >
          </v-card-actions>
        </v-col>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import api from "../../../../services/api";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
export default {
  components: { successModal },
  data() {
    return {
      show1: false,
      dialog: false,
      dialog1: false,
      firstName: "",
      lastName: "",
      type: "",
      email: "",
      phone: "",
      companyName: "",
      title: "",
      city: "",
      state: "",
      zip: "",
      states: [],
      administratorPartner: "",
      jointlyManage: "",
      showCheckboxes: false,
      emailRules: [
        (v) => !!v || "This field is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
    };
  },
  computed: {
    singlePartner() {
      return this.$store.getters.getSinglePartnerInEmployerAdvisor;
    },
    singleClient() {
      return this.$store.getters.getClientGetter;
    },
    routeParams() {
      return this.$route.params.name;
    },
    showDeleteBtn() {
      return this.$store.getters.showDeletePartnerButton;
    },
  },
  methods: {
    closePopUp() {
      this.dialog = false;
    },
    confirmRemove() {
      let user = JSON.parse(localStorage.getItem("user"));
      let employerId = user.groupPortal[0].organization.employer;

      api()
        .delete(`/employers/${employerId}/advisors/${this.singlePartner._id}`)
        .then((res) => {
          if (res) {
            api()
              .get(`/employers/${employerId}/advisors`)
              .then((res) => {
                if (res) {
                  this.$store.commit("setAllPartners", res.data.advisors);
                  this.dialog = false;
                  this.$router.push(`/employer/partners`);
                }
              });
          }
        });
    },
    removeItem(item) {
      this.dialog = true;
      this.singleItem = item;
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
    },
    selectCheckbox(item) {
      let user = JSON.parse(localStorage.getItem("user"));
      let empId = user.groupPortal[0].organization.employer;
      let data = {
        jointlyManage: this.jointlyManage,
        administratorPartner: this.administratorPartner,
      };
      api()
        .put(`/employers/${empId}/advisors/${this.singlePartner._id}`, data)
        .then((res) => {
          if (res) {
            this.$refs.success_modal.openPopUp(res.data.success);
          }
        });
    },
    getStates() {
      api()
        .get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },
  },
  created() {
    this.getStates();
    if (this.singlePartner) {
      this.administratorPartner = this.singlePartner.administratorPartner
        ? this.singlePartner.administratorPartner
        : "";
      this.jointlyManage = this.singlePartner.jointlyManage
        ? this.singlePartner.jointlyManage
        : "";
      this.firstName = this.singlePartner.primaryContact.firstName;
      this.lastName = this.singlePartner.primaryContact.lastName;
      this.companyName = this.singlePartner.companyName;
      this.email = this.singlePartner.primaryContact.email;
      this.phone = this.singlePartner.primaryContact.phone;
      this.title = this.singlePartner.title;
      this.city = this.singlePartner.city;
      this.state = this.singlePartner.state;
      this.zip = this.singlePartner.zipCode;
      this.type =
        this.singlePartner.advisorType == "brokerConsultant"
          ? "Broker Consultant"
          : this.singlePartner.advisorType == "analyticsConsultant"
          ? "Analytics Consultant"
          : this.singlePartner.advisorType;

      if (this.type == "TPA") {
        this.showCheckboxes = true;
      }
    }
  },
};
</script>
