<template>
  <div>
    <div class="col-md-12 text-center justify-center" v-if="readyToShow">
      <v-progress-circular
        indeterminate
        :size="100"
        color="primary"
        class="text-center"
      ></v-progress-circular>
    </div>
    <div class="row" v-else>
      <div
        v-for="item in items"
        :key="item.title"
        class="
          col-md-4 col-lg-3 col-lg-3
          text-center
          justify-center
          align-center
        "
      >
        <v-card
          class="centered-card space-around-content"
          @click="item.url ? $router.push(item.url) : ''"
          style="height: 100%; cursor: pointer"
        >
          <img
            :src="item.img"
            height="70"
            width="70"
            style="object-fit: cover"
            class="img-media"
          />
          <h4
            class="
              text-center
              justify-center
              blue-text
              font
              mx-2
              font-quicksand-medium-18
            "
          >
            {{ item.title }}
          </h4>
          <v-card-text>
            <v-row align="center" class="mx-3 justify-center">
              <h1
                class="blue-text mb-4"
                style="font-size: 2rem"
                v-if="statistics && item.procentage"
                v-html="
                  statistics.projectedSavings
                    ? statistics.projectedSavings + '%'
                    : '0%'
                "
              >
                {{ item.procentage }}
              </h1>
              <h4
                class="
                  text-center text-center
                  card-desc-responsive
                  font-quicksand-medium-18
                  mb-5
                "
                style="font-weight: normal; line-height: 16px"
              >
                {{ item.desc }}
              </h4>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
      <!-- <div class="col-md-3">
        <v-card class="col-md-12 space-around-content" style="height:100%;cursor:pointer">
            <v-row
          v-if="title == 'Easy Setup'"
              class="mx-0 justify-center "
            >
              <h5 class=" ms-4  text-underline ">
                Easy Setup 
              </h5>
            </v-row>
            <v-row class=" text-center justify-center align-center" >
                 <img src="/employer-home.png"   class="align-center setup-small-img" width="250" height="60" alt="">
            </v-row>
            <v-row
              align="center"
              class="mx-0 justify-center mt-6 "
            >
              <h5 class="red-desc text-center font card-desc-responsive" >
               Start saving by directly contracting
              </h5>
            </v-row> 
        </v-card>
    </div> -->

      <div
        v-for="item in precentageCards"
        :key="item.title"
        class="
          col-md-4 col-lg-3 col-lg-3
          text-center
          justify-center
          aling-center
        "
      >
        <v-card
          style="height: 13rem; cursor: pointer"
          :class="
            showWizard && item.title == 'Easy Setup'
              ? 'space-around-content orange-bg'
              : 'space-around-content'
          "
          @click="
            item.img && showTheForm && item.title == 'Easy Setup'
              ? $router.push('/employer/wizard-form')
              : item.title == 'Easy Setup'
              ? $router.push('/employer/new-wizard-form')
              : ''
          "
        >
          <!-- $router.push('/employer/new-wizard-form') -->
          <v-row align="center" class="justify-center">
            <h5
              class="text-center text-underline mt-1 font-quicksand-medium-18"
            >
              {{ item.title }}
            </h5>
          </v-row>
          <v-card-title
            class="text-center justify-center text-gray font percentage-num"
          >
            <v-row
              v-if="item.img"
              class="text-center justify-center align-center"
            >
              <img
                src="/employer-home.png"
                class="align-center setup-small-img"
                width="250"
                height="60"
                alt=""
              />
            </v-row>
            <div v-if="item.clients" class="row justify-space-around">
              <h4 class="blue-text font-quicksand-medium-18">
                {{ statistics.noEmployees }} <br />
                Employees
              </h4>
              <h4 class="blue-text ml-4 font-quicksand-medium-18">
                {{ statistics.noPlanMembers }} <br />
                Members
              </h4>
            </div>

            <div
              v-else-if="item.inpatient && statistics.rates"
              class="row justify-space-around blue-text"
            >
              <div class="font-weight-bold">
                <h1 class="blue-text mb-3">
                  <span v-if="!statistics.rates.inpatient">0</span>
                  <span v-else>{{ statistics.rates.inpatient }}</span
                  >% <br />
                </h1>
                Inpatient
              </div>
              <div class="ml-6 font-weight-bold">
                <h1 class="blue-text mb-3">
                  <span v-if="!statistics.rates.outpatient">0</span>
                  <span v-else>{{ statistics.rates.outpatient }}</span
                  >% <br />
                </h1>
                Outpatient
              </div>
            </div>
            <div
              v-else-if="item.title == 'Providers on Your Provider List'"
              class="blue-text"
            >
              <h1
                v-html="statistics.providers ? statistics.providers : '0'"
              ></h1>
            </div>
            <div
              v-else-if="item.title == 'Projected Savings'"
              class="blue-text"
            >
              <h1
                v-html="
                  statistics.projectedSavings
                    ? statistics.projectedSavings + '%'
                    : '0%'
                "
              ></h1>
            </div>
            <div
              v-else-if="item.title == 'Provider Coverage'"
              class="blue-text"
            >
              <h1
                v-html="
                  statistics.providerCoverage
                    ? statistics.providerCoverage + '%'
                    : '0%'
                "
              ></h1>
            </div>
            <div v-else-if="item.title == 'Contract Renewal'" class="blue-text">
              <h1
                v-html="
                  statistics.contractRenewal
                    ? parsedDate(statistics.contractRenewal)
                    : '/'
                "
              ></h1>
            </div>
          </v-card-title>
          <v-row align="center" class="mx-10 justify-center">
            <h5
              :class="item.title == 'Easy Setup' ? 'red-desc' : 'blue-text'"
              class="text-center font-quicksand-medium-18 pb-4"
              style="line-height: 20px"
              v-if="item.desc"
            >
              {{ item.desc }}
            </h5>
          </v-row>
        </v-card>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../../../../services/api";
export default {
  data() {
    return {
      items: [
        {
          url: "/employer/providers/zip-codes",
          img: "/cards/woman.png",
          title: "Add Plan Member Zip Codes",
          desc: "This will create a targeted provider list to show your average potential savings.",
        },
        // {
        //   img: "/cards/money.png",
        //   title: "Your Average Projected Savings",
        //   procentage: "23%",
        // },
        {
          url: "/employer/providers",
          img: "/cards/doctor.png",
          title: "Your Provider List",
          desc: "Access and manage your provider list.",
        },
        //  {
        //      img:'/cards/hospital.png',
        //      title:'Define Network',
        //      desc:'Create a custom provider network based on many factors.'
        //  },
        //  {
        //      img:'/cards/contract.png',
        //      title:'Contract',
        //      desc:'Direct contract with providers.'
        //  },
        //  {
        //      img:'/cards/letter.png',
        //      title:'Invite Providers',
        //      desc:'Invite providers to directly contract throught Apaly.'
        //  },
        //  {
        //      img:'/cards/desktop.png',
        //      title:'Invite Colleagues',
        //      desc:'Invite other HR or Executive staff to participate on your Apaly portal.'
        //  },
      ],
      precentageCards: [
        //  {
        //      title: 'Employees / Members',
        //      percentage:'74',
        //      percentage1:'1,450',
        //      clients:true
        //  },

        // {
        //   title: "Provider Coverage",
        //   percentage: "94%",
        //   desc: "percentage of members with providers within a 30 mile radius of their zip code",
        // },
        {
          img: "/employer-home.png",
          title: "Easy Setup",
          desc: "Start saving by directly contracting.",
        },
        {
          title: "Providers on Your Provider List",
          percentage: "48,000",
          desc: "based on current provider list filters",
        },
        {
          title: "Contract Renewal",
          percentage: "5",
          desc: "Contract renewal date",
        },
        //  {
        //      title: 'Projected Savings',
        //      percentage:'28%',
        //      desc:'accross all clients, compared to current average network lease costs'
        //  },

        {
          title: "Current Contract Rates",
          percentage: "150%",
          percentage1: "180%",
          desc: "of Medicare benchmark rates",
          inpatient: true,
        },
      ],
      showTheForm: "",
      showWizard: "",
      dialogm1: "",
      dialog: false,
      dialog1: false,
      statistics: {},
      readyToShow: false,
    };
  },
  computed: {},
  methods: {
    wizardForm() {
      let user = JSON.parse(localStorage.getItem("user"));
      api()
        .get(`/onboard/account/${user.id}?type=employer`)
        .catch((err) => {
          this.showTheForm = false;
        })
        .then((res) => {
          if (res) {
            this.showTheForm = true;
          }
        });
    },
    parsedDate(date) {
      let returnData = "/";
      if (date) {
        let newDate = new Date(date);
        returnData =
          newDate.getMonth() +
          "/" +
          newDate.getDay() +
          "/" +
          newDate.getFullYear();
      }
      return returnData;
    },
    getEmployersStatistics() {
      this.readyToShow = true;
      let user = JSON.parse(localStorage.getItem("user"));
      let employerId = user.groupPortal[0].organization.employer;
      api()
        .get(`/employers/${employerId}/statistics`)
        .then((res) => {
          this.statistics = res.data.statistics;
          this.readyToShow = false;
        })
        .catch((err) => {
          this.readyToShow = false;
        });
    },
  },
  created() {
    if (localStorage.getItem("firstTime")) {
      this.showWizard = JSON.parse(localStorage.getItem("firstTime"));
    }
    this.wizardForm();
    this.getEmployersStatistics();
  },
};
</script>
<style>
.v-stepper {
  width: 100% !important;
}
</style>
<style scoped>
.v-stepper--alt-labels .v-stepper__step {
  flex-basis: 0px !important;
}
.orange-bg {
  border: 3px solid #4072b7;
}
@-webkit-keyframes pulseGlow {
  0% {
    box-shadow: none;
  }
  2% {
    box-shadow: 0 0 0.5em #4072b7, 0 0 0.5em #4072b7 inset;
    border-color: #4072b7;
  }
}

.orange-bg {
  -webkit-animation: pulseGlow 1s ease-in-out 1s infinite;
}
</style>
