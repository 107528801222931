<template>
  <div class="no-padding row-height">
    <removeDialog
      ref="dialog_delete"
      v-on:confirm="confirmRemove"
    ></removeDialog>
    <div class="col-md-12">
      <v-row>
        <v-col class="col-md-10" md="10" cols="12">
          <h3>
            <span class="font"> Contracts</span>
          </h3>
        </v-col>
        <!-- <v-col class="col-md-4" md="4" cols="12">
            <v-text-field class="input-control" prepend-inner-icon="mdi-magnify" @input="searchContracts" light dense outlined placeholder="Search Contracts by Contract Name">
            </v-text-field>
        </v-col> -->
        <v-col
          class="col-lg-2 col-md-2 text-end"
          cols="12"
          v-if="$role != 'user' && allContracts.length < 1"
        >
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" class="my-1 blue-buttons">Add Contract </v-btn>
            </template>
            <v-list style="max-width: 140px !important;margin-right: 0px" class="contract-dropdown">
              <v-list-item style="disply: block">
                <a
                  class="a-tag underline-text"
                  @click="
                    $router.push(
                      `/employer-advisor/employers/${routeParams}/contracts/add-new`
                    )
                  "
                  >New Contract</a
                >
              </v-list-item>
              <v-list-item style="disply: block">
                <a
                  class="a-tag underline-text"
                  @click="
                    $router.push(
                      `/employer-advisor/employers/${routeParams}/contracts/request`
                    )
                  "
                  >Request New Type of Contract</a
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </div>
    <div class="col-md-12 text-center justify-center" v-if="readyToShow">
      <v-progress-circular
        indeterminate
        :size="100"
        color="primary"
        class="text-center"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-row class="col-md-12" v-if="allContracts.length < 1">
        <v-col md="12" class="font-bold">
          You have not yet Contracted on the Platform
          <a
            v-if="$role != 'user'"
            @click="
              $router.push(
                `/employer-advisor/employers/${routeParams}/contracts/add-new`
              )
            "
            >- Click Here to Add Your Rates and Contract</a
          >
        </v-col>
      </v-row>
      <v-simple-table v-else fixed-header>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Final Contract</th>
              <th class="text-left">Contract Date</th>
              <th class="text-left">Outpatient Rate</th>
              <th class="text-left">Inpatient Rate</th>
              <th class="text-left" v-if="$role != 'user'">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in allContracts" :key="item.name">
              <td v-if="item.name">
                <a @click="singleContract(item, 'form')">{{ item.name }}</a>
              </td>
              <td v-else><a @click="singleContract(item, 'form')">/</a></td>

              <td
                v-html="
                  item.effectiveDate ? parsedDate(item.effectiveDate) : '/'
                "
              ></td>
              <td v-if="item.employerRates">
                {{ item.employerRates.inpatient }}%
              </td>
              <td v-if="item.employerRates">
                {{ item.employerRates.outpatient }}%
              </td>
              <td class="mt-2" v-if="$role != 'user'">
                <a @click="singleContract(item, 'rates')">Increase Rates</a>
                <br />
                <a @click="removeItem(item)">Cancel Contract</a>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div class="justify-end row mt-10" v-if="allContracts.length > 0">
        <div class="jumbotron font col-md-3 mr-3 ml-3" v-if="$role != 'user'">
          You have committed to the contract rate shown here for 12 months from
          your contract date. You can increase your rates during that time, but
          not lower them.
        </div>
      </div>
    </div>

    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
  </div>
</template>
<script>
import api from "../../../../../services/api";
import removeDialog from "../../../../TablesTemplate/RemoveDialog.vue";
import errorModal from "../../../../TablesTemplate/ErrorDialog.vue";
export default {
  components: { removeDialog, errorModal },
  data() {
    return {
      items: [],
      allContracts: [],
      contractsArray: [],
      readyToShow: false,
    };
  },
  created() {
    this.getAllContracts();
  },
  computed: {
    getClient() {
      return this.$store.getters.getClientGetter;
    },
    routeParams() {
      return this.$route.params.name;
    },
    filterContracts() {
      return this.contractsArray;
    },
  },
  methods: {
    parsedDate(date) {
      let newDate = new Date(date);
      return newDate.toDateString();
    },
    removeItem() {
      let text = "Are you sure you want to cancel the Contract?";
      this.$refs.dialog_delete.openPopUp(text);
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    confirmRemove() {
      this.$refs.dialog_delete.closePopUp();
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.employerAdvisor;
      let employer = JSON.parse(localStorage.getItem("employer"));
      api()
        .delete(`/advisors/${advisorId}/employers/${employer._id}/contract`)
        .catch((err) => {
          if (err) {
            this.$refs.error_modal.openPopUp(err.response.data.message);
          }
        })
        .then((res) => {
          if (res) {
            api()
              .get(`/advisors/${advisorId}/employers/${employer._id}/contract`)
              .then((res) => {
                if (res) {
                  this.allContracts.push(res.data.contract);
                  this.allContracts.push(res.data.contract);
                  this.$refs.dialog_delete.closePopUp();
                } else {
                  this.$refs.dialog_delete.closePopUp();
                }
              });
          }
        });
    },
    getAllContracts() {
      this.readyToShow = true;
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.employerAdvisor;
      let employer = JSON.parse(localStorage.getItem("employer"));
      api()
        .get(`/advisors/${advisorId}/employers/${employer._id}/contract`)
        .catch((err) => {
          if (err) {
            this.allContracts = [];
            this.readyToShow = false;
          }
        })
        .then((res) => {
          if (res) {
            this.allContracts.push(res.data.contract);
            this.contractsArray.push(res.data.contract);
            this.readyToShow = false;
          }
        });
    },
    singleContract(item, type) {
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.employerAdvisor;
      let employer = JSON.parse(localStorage.getItem("employer"));
      api()
        .get(`/advisors/${advisorId}/employers/${employer._id}/contract`)
        .then((res) => {
          if (res) {
            this.$store.commit(
              "setSingleContractinEmployerAdvisor",
              res.data.contract
            );
            this.$router.push(
              `/employer-advisor/employers/${this.routeParams}/contracts/contract`
            );
            this.$store.commit("singleContractView", type);
          }
        });
    },
    searchContracts(val) {
      this.allContracts = this.filterContracts.filter((el) => {
        if (el.name.toLowerCase().includes(val.toLowerCase())) {
          return el.name.toLowerCase().includes(val.toLowerCase());
        }
      });
    },
  },
};
</script>
<style>
.green-text {
  color: #0fa64b !important;
}
</style>