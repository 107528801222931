<template>
  <div class="row">
    <v-sheet class="overflow-hidden col-md-12 border-r-8">
      <div class="">
        <v-row align="center" justify="center">
          <div
            :class="
              overlay
                ? 'hideOnMoblie background-opacity col-md-9'
                : 'col-md-12 navigationWidth no-padding form-row-height '
            "
          >
            <v-row class="blue-underline mb-1">
              <v-col class="col-xl-6 col-md-6 cols-6 pt-2 pb-0" cols="6">
                <div class="col-md-12">
                  <h3 class="form-title font col-md-12 font-18">Contracts</h3>
                </div>
              </v-col>
              <v-col
                class="col-xl-6 pr-6 pt-6 col-md-6 cols-12 text-end"
                cols="6"
                align="end"
              >
                <v-btn class="my-1 gray-border-buttons">
                  <svg
                    class="mr-2"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 7.5H15"
                      stroke="#707070"
                      stroke-width="2"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.5 0V15"
                      stroke="#707070"
                      stroke-width="2"
                      stroke-linejoin="round"
                    />
                  </svg>

                  New</v-btn
                >
              </v-col>
            </v-row>

            <v-simple-table fixed-header height="630px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">CONTRACT NAME</th>
                    <th class="text-left">RATE GROUP</th>
                    <th class="no-padding text-end justify-end">
                      <div class="col-md-12 justify-end d-flex text-end">
                        <v-checkbox
                          v-model="allRows"
                          class="no-padding mt-3"
                          @click="selectAllRows()"
                        ></v-checkbox>
                        <label
                          for=""
                          style="padding-top: 11px; padding-right: 145px"
                          >SELECT ALL</label
                        >
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody v-if="allContracts.length > 0">
                  <tr
                    v-for="(item, index) in allContracts"
                    :key="index"
                    :class="selectAll ? 'activeItem' : ''"
                    :id="'id' + index"
                  >
                    <td class="col-md-5" v-if="item.contract">
                      <a
                        @click="
                          $router.push(
                            '/dpc-providers/account/contract/details'
                          )
                        "
                        >{{ item.contract }}</a
                      >
                    </td>
                    <td v-else><a>/</a></td>
                    <td class="col-md-2">
                      <v-select
                        dense
                        solo
                        class="no-padding"
                        :items="item.select"
                        hide-details
                      ></v-select>
                    </td>

                    <td
                      :class="
                        !display
                          ? 'text-end mt-3 justify-end  text-gray'
                          : 'text-end mt-3  row justify-end text-gray no-padding'
                      "
                      style="padding-right: 110px"
                    >
                      <v-checkbox
                        class="no-padding stay-blue"
                        :value="selectAll"
                        @click="activeRow(index, item)"
                      ></v-checkbox>
                      ACCEPT & CONTRACT
                    </td>
                  </tr>
                </tbody>
                <tbody v-else-if="filterItems">
                  <v-col md="12" cols="12">
                    <v-alert
                      border="bottom"
                      colored-border
                      type="warning"
                      elevation="2"
                      class="text-gray"
                    >
                      Sorry! No results found
                    </v-alert>
                  </v-col>
                </tbody>
                <tbody v-else>
                  <v-col md="12"> You have not yet contracted. </v-col>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-row>
        <div class="row col-md-12 mt-3 text-gray">
          <div class="col-md-12">
            <v-btn class="blue-buttons">Accept & Contract</v-btn>
            <div class="font-14 mt-2">
              By checking the box(es) and clicking "ACCEPT & CONTRACT," you
              represent that: (1) you have read and agree with the contract(s)
              listed above, and (2) you have the right, authority and capacity
              to enter into this agreement and commit to the contract rate(s)
              that you have entered, on your own behalf and on behalf of any
              organization(s) for which the agreement(s) applies.
            </div>
          </div>
        </div>
      </div>
    </v-sheet>

    <router-view></router-view>
  </div>
</template>

<script>
import api from "../../../../../services/api";
export default {
  data() {
    return {
      allContracts: [
        {
          contract: "Digital Direct Healthcare - Provider Services Agreement",
          _id: 1,
          select: [
            {
              text: "General Rate Group",
              value: "General Rate Group",
            },
            {
              text: "$60 PMPM* - Fixed",
              value: "$60 PMPM* - Fixed",
            },
            {
              text: "Prudential Rate Group",
              value: "Prudential Rate Group",
            },
          ],
        },
        {
          contract: "Digital Direct Healthcare - Provider Services Agreement",
          _id: 2,
          select: [
            {
              text: "General Rate Group",
              value: "General Rate Group",
            },
            {
              text: "$60 PMPM* - Fixed",
              value: "$60 PMPM* - Fixed",
            },
            {
              text: "Prudential Rate Group",
              value: "Prudential Rate Group",
            },
          ],
        },
        {
          contract: "Digital Direct Healthcare - Provider Services Agreement",
          _id: 3,
          select: [
            {
              text: "General Rate Group",
              value: "General Rate Group",
            },
            {
              text: "$60 PMPM* - Fixed",
              value: "$60 PMPM* - Fixed",
            },
            {
              text: "Prudential Rate Group",
              value: "Prudential Rate Group",
            },
          ],
        },
        {
          contract: "Digital Direct Healthcare - Provider Services Agreement",
          _id: 4,
          select: [
            {
              text: "General Rate Group",
              value: "General Rate Group",
            },
            {
              text: "$60 PMPM* - Fixed",
              value: "$60 PMPM* - Fixed",
            },
            {
              text: "Prudential Rate Group",
              value: "Prudential Rate Group",
            },
          ],
        },
        {
          contract: "HTA - Verizon - DPC Services Agreement",
          _id: 5,
          select: [
            {
              text: "General Rate Group",
              value: "General Rate Group",
            },
            {
              text: "$60 PMPM* - Fixed",
              value: "$60 PMPM* - Fixed",
            },
            {
              text: "Prudential Rate Group",
              value: "Prudential Rate Group",
            },
          ],
        },
        {
          contract: "HTA - JP Morgan - DPC Services Agreement",
          _id: 6,
          select: [
            {
              text: "General Rate Group",
              value: "General Rate Group",
            },
            {
              text: "$60 PMPM* - Fixed",
              value: "$60 PMPM* - Fixed",
            },
            {
              text: "Prudential Rate Group",
              value: "Prudential Rate Group",
            },
          ],
        },
        {
          contract: "HTA - Verizon - DPC Services Agreement",
          _id: 7,
          select: [
            {
              text: "General Rate Group",
              value: "General Rate Group",
            },
            {
              text: "$60 PMPM* - Fixed",
              value: "$60 PMPM* - Fixed",
            },
            {
              text: "Prudential Rate Group",
              value: "Prudential Rate Group",
            },
          ],
        },
        {
          contract: "Digital Direct Healthcare - Provider Services Agreement",
          _id: 8,
          select: [
            {
              text: "General Rate Group",
              value: "General Rate Group",
            },
            {
              text: "$60 PMPM* - Fixed",
              value: "$60 PMPM* - Fixed",
            },
            {
              text: "Prudential Rate Group",
              value: "Prudential Rate Group",
            },
          ],
        },
        {
          contract: "Digital Direct Healthcare - Provider Services Agreement",
          _id: 9,
          select: [
            {
              text: "General Rate Group",
              value: "General Rate Group",
            },
            {
              text: "$60 PMPM* - Fixed",
              value: "$60 PMPM* - Fixed",
            },
            {
              text: "Prudential Rate Group",
              value: "Prudential Rate Group",
            },
          ],
        },
        {
          contract: "Digital Direct Healthcare - Provider Services Agreement",
          _id: 10,
          select: [
            {
              text: "General Rate Group",
              value: "General Rate Group",
            },
            {
              text: "$60 PMPM* - Fixed",
              value: "$60 PMPM* - Fixed",
            },
            {
              text: "Prudential Rate Group",
              value: "Prudential Rate Group",
            },
          ],
        },
        {
          contract: "Digital Direct Healthcare - Provider Services Agreement",
          _id: 11,
          select: [
            {
              text: "General Rate Group",
              value: "General Rate Group",
            },
            {
              text: "$60 PMPM* - Fixed",
              value: "$60 PMPM* - Fixed",
            },
            {
              text: "Prudential Rate Group",
              value: "Prudential Rate Group",
            },
            {
              contract:
                "Digital Direct Healthcare - Provider Services Agreement",
              _id: 12,
              select: [
                {
                  text: "General Rate Group",
                  value: "General Rate Group",
                },
                {
                  text: "$60 PMPM* - Fixed",
                  value: "$60 PMPM* - Fixed",
                },
                {
                  text: "Prudential Rate Group",
                  value: "Prudential Rate Group",
                },
              ],
            },
            {
              contract:
                "Digital Direct Healthcare - Provider Services Agreement",
              _id: 13,
              select: [
                {
                  text: "General Rate Group",
                  value: "General Rate Group",
                },
                {
                  text: "$60 PMPM* - Fixed",
                  value: "$60 PMPM* - Fixed",
                },
                {
                  text: "Prudential Rate Group",
                  value: "Prudential Rate Group",
                },
              ],
            },
          ],
        },
      ],
      display: false,
      windowWidth: window.innerWidth,
      response: [],
      allRows: false,
      panel: [0, 1],
      selectAll: false,
      selectedRows: [],
      filterItems: false,
    };
  },
  created() {
    this.response = this.allContracts;
    if (this.windowWidth < 900) {
      this.display = false;
    } else {
      this.display = true;
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    filteredArray() {
      return this.response;
    },
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 900) {
        this.display = false;
      } else {
        this.display = true;
      }
    },
    activeRow(index, item) {

      let itemById = document.getElementById("id" + index);
      itemById.classList.toggle("activeItem");

      if (itemById.classList.contains("activeItem")) {
        this.selectedRows.push(item);
      } else {
        let removeItem = this.selectedRows.find((x) => x._id === item._id);
        this.selectedRows.splice(this.selectedRows.indexOf(removeItem), 1);
      }
    },
    selectAllRows() {
      this.selectedRows = [];
      this.selectAll = !this.selectAll;
      if (this.selectAll) {
        let tmp = JSON.stringify(this.allContracts);
        this.selectedRows = JSON.parse(tmp);
      }
      if (!this.selectAll) {
        this.selectedRows = [];
      }
    },

    search(val) {
      this.allContracts = this.filteredArray.filter((el) => {
        if (el.contract.toLowerCase().includes(val.toLowerCase())) {
          return el.contract.toLowerCase().includes(val.toLowerCase());
        }
      });
      if (this.allContracts.length < 1) {
        this.filterItems = true;
      }
    },
  },
};
</script>
<style>
.select-all-mobile-table {
  width: 400px;
  overflow-x: scroll;
}
</style>