var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"no-padding row-height"},[_c('removeDialog',{ref:"dialog_delete",on:{"confirm":_vm.confirmRemove}}),_c('div',{staticClass:"col-md-12"},[_c('v-row',[_c('v-col',{staticClass:"col-md-10",attrs:{"md":"10","cols":"12"}},[_c('h3',[_c('span',{staticClass:"font"},[_vm._v(" Contracts")])])]),(_vm.$role != 'user' && _vm.allContracts.length < 1)?_c('v-col',{staticClass:"col-lg-2 col-md-2 text-end",attrs:{"cols":"12"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"my-1 blue-buttons"},on),[_vm._v("Add Contract ")])]}}],null,false,506126076)},[_c('v-list',{staticClass:"contract-dropdown",staticStyle:{"max-width":"140px !important","margin-right":"0px"}},[_c('v-list-item',{staticStyle:{"disply":"block"}},[_c('a',{staticClass:"a-tag underline-text",on:{"click":function($event){return _vm.$router.push(
                    `/employer-advisor/employers/${_vm.routeParams}/contracts/add-new`
                  )}}},[_vm._v("New Contract")])]),_c('v-list-item',{staticStyle:{"disply":"block"}},[_c('a',{staticClass:"a-tag underline-text",on:{"click":function($event){return _vm.$router.push(
                    `/employer-advisor/employers/${_vm.routeParams}/contracts/request`
                  )}}},[_vm._v("Request New Type of Contract")])])],1)],1)],1):_vm._e()],1)],1),(_vm.readyToShow)?_c('div',{staticClass:"col-md-12 text-center justify-center"},[_c('v-progress-circular',{staticClass:"text-center",attrs:{"indeterminate":"","size":100,"color":"primary"}})],1):_c('div',[(_vm.allContracts.length < 1)?_c('v-row',{staticClass:"col-md-12"},[_c('v-col',{staticClass:"font-bold",attrs:{"md":"12"}},[_vm._v(" You have not yet Contracted on the Platform "),(_vm.$role != 'user')?_c('a',{on:{"click":function($event){return _vm.$router.push(
              `/employer-advisor/employers/${_vm.routeParams}/contracts/add-new`
            )}}},[_vm._v("- Click Here to Add Your Rates and Contract")]):_vm._e()])],1):_c('v-simple-table',{attrs:{"fixed-header":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Final Contract")]),_c('th',{staticClass:"text-left"},[_vm._v("Contract Date")]),_c('th',{staticClass:"text-left"},[_vm._v("Outpatient Rate")]),_c('th',{staticClass:"text-left"},[_vm._v("Inpatient Rate")]),(_vm.$role != 'user')?_c('th',{staticClass:"text-left"},[_vm._v("Actions")]):_vm._e()])]),_c('tbody',_vm._l((_vm.allContracts),function(item){return _c('tr',{key:item.name},[(item.name)?_c('td',[_c('a',{on:{"click":function($event){return _vm.singleContract(item, 'form')}}},[_vm._v(_vm._s(item.name))])]):_c('td',[_c('a',{on:{"click":function($event){return _vm.singleContract(item, 'form')}}},[_vm._v("/")])]),_c('td',{domProps:{"innerHTML":_vm._s(
                item.effectiveDate ? _vm.parsedDate(item.effectiveDate) : '/'
              )}}),(item.employerRates)?_c('td',[_vm._v(" "+_vm._s(item.employerRates.inpatient)+"% ")]):_vm._e(),(item.employerRates)?_c('td',[_vm._v(" "+_vm._s(item.employerRates.outpatient)+"% ")]):_vm._e(),(_vm.$role != 'user')?_c('td',{staticClass:"mt-2"},[_c('a',{on:{"click":function($event){return _vm.singleContract(item, 'rates')}}},[_vm._v("Increase Rates")]),_c('br'),_c('a',{on:{"click":function($event){return _vm.removeItem(item)}}},[_vm._v("Cancel Contract")])]):_vm._e()])}),0)]},proxy:true}])}),(_vm.allContracts.length > 0)?_c('div',{staticClass:"justify-end row mt-10"},[(_vm.$role != 'user')?_c('div',{staticClass:"jumbotron font col-md-3 mr-3 ml-3"},[_vm._v(" You have committed to the contract rate shown here for 12 months from your contract date. You can increase your rates during that time, but not lower them. ")]):_vm._e()]):_vm._e()],1),_c('errorModal',{ref:"error_modal",on:{"confirm":_vm.closeErrorModal}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }