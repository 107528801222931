<template>
    <div class="no-padding no-margin">
        <MemberServices v-if="path == '/member/services'"></MemberServices>
        <AddService v-if="path == '/member/services/add-services'"></AddService>
        <ServiceDetail v-if="path == '/member/services/service'"></ServiceDetail>
    </div>
</template>
<script>
  import MemberServices from "./MemberServices";
  import AddService from "./AddService";
  import ServiceDetail from "./ServiceDetail";

    export default {
        components:{
            MemberServices,
            AddService,
            ServiceDetail,

        },
        data(){
            return{

            }
        },
        computed:{
            path(){
                return this.$route.path
            },
            routeName(){
                return this.$route.params.name
            }
        },

        created(){

        },
        methods:{

        }

    }
</script>
<style>
    .background-opacity{
        opacity: 0.46;
        background-color: rgb(33, 33, 33);
        border-color: rgb(33, 33, 33);
    }
</style>