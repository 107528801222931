<template>
 <v-row class="add-client-form no-padding">
   <div class="row mb-4">
        <div class="col-md-12">
            <h3 class="form-title font">Create a Rate Schedule</h3>
        </div>
    </div>
    <v-tabs
      dark
      show-arrows
      style="background:#4072B7"
      v-model.trim="tab"
    >
    <v-tabs-slider style="background:orange"></v-tabs-slider>
     <v-tab
        style="background:#4072B7"
        v-for="(item, i) in items"
        :key="i"
        class="col-md-4"
        :href="'#tab'+ i"
        >
       
       {{item.text}}
    </v-tab>
 </v-tabs>
 
   <div class="col-md-12 form-box" v-if="itemtab == 'tab0'">
    <v-row>
      <v-col  cols="12">
          <h3>
            <span class="font"> </span> Ceneral Information
          </h3>
      </v-col>
    </v-row>      
     <div class="add-client-form col-md-6" cols="12">
         <div class="col-md-12">
             <label for="">Name</label>
             <v-text-field solo></v-text-field>
         </div>
          <div class="col-md-12">
             <label for="">Type</label>
             <v-select solo></v-select>
         </div>
          <div class="col-md-12">
             <label for="">Description</label>
             <v-textarea solo></v-textarea>
         </div>

     </div>
   </div>
    <div class="col-md-12 form-box" v-if="itemtab == 'tab1'">
        <div>
            <h3>
                  Base and Preferred Rates - Hospital
            </h3>
        </div>
        <div class="add-client-form">
           <v-row md="12">
            <v-col  cols="12" :class="display ? 'font text-gray col-md-7' : 'text-gray col-md-12'">Hospital Base Contract Rates (Required) <i class="fas fa-question-circle  yellow-icons"></i></v-col>
            <v-col md="4" v-if="display" class="font text-gray text-center">Employer Price Match <i class="fas fa-question-circle  yellow-icons"></i></v-col>
            <v-divider v-if="!display"></v-divider>
            </v-row>
            <v-divider class="mt-4 mb-4"></v-divider>
            <v-row class="col-md-12">
                <v-col md="2">
                    <div class="text-gray text-underline">Inpatient</div>
                    <v-label >Rate Amount</v-label>
                    <div class="row  mt-2">
                            <input type="text" class="inputs-width" />
                            <h2>%</h2>
                    </div>
                </v-col>
                <v-col  md="2">
                    <div class="text-gray white--text">Outpatient</div>
                    <label for="" >Rate Type</label>
                    <div class="row mt-2">
                            <input type="text" class="inputs-width"/>
                            <h2>%</h2>
                    </div>
                </v-col>
                <v-col  md="2">
                    <div class="text-gray text-underline">Outpatient</div>
                    <v-label >Rate Amount</v-label>
                    <div class="row mt-2">
                            <input type="text" class="inputs-width"/>
                            <h2>%</h2>
                    </div>
                </v-col>
                 <v-col md="4" cols="12" v-if="!display" class="font text-gray text-center">Employer Price Match <i class="fas fa-question-circle  yellow-icons"></i></v-col>
                <v-col md="6" class="text-center ">
                    <h2>__ __ %</h2>
                    <v-btn class="blue-buttons">Check Price Match</v-btn>
                </v-col>
            </v-row>
            <v-row md="12">
               <v-col md="7" class="font text-gray">Hospital Base Contract Rates (Required) <i class="fas fa-question-circle  yellow-icons"></i></v-col>
            </v-row>
            <v-divider class="mt-4 mb-4"></v-divider>
            <v-row class="col-md-12">
                <v-col md="2">
                    <div class="text-gray text-underline">Inpatient</div>
                    <v-label >Rate Amount</v-label>
                    <div class="row  mt-2">
                            <input type="text" class="inputs-width" />
                            <h2>%</h2>
                    </div>
                </v-col>
                <v-col  md="2">
                    <div class="text-gray white--text">Outpatient</div>
                    <label for="" >Rate Type</label>
                    <div class="row mt-2">
                            <input type="text" class="inputs-width"/>
                            <h2>%</h2>
                    </div>
                </v-col>
                
                <v-col  md="2">
                    <div class="text-gray text-underline">Outpatient</div>
                    <v-label >Rate Amount</v-label>
                    <div class="row mt-2">
                            <input type="text" class="inputs-width"/>
                            <h2>%</h2>
                    </div>
                </v-col>
            </v-row>
        </div>
     </div>
     <div class="col-md-12 form-box" v-if="itemtab == 'tab2'">
      <v-row class="justify-space-around">
        <v-col class="col-md-9" cols="12">
            <h3>
                 Carve-Outs and Exclusions
            </h3>
        </v-col>
        <v-col class="col-md-3">
            <v-btn class="blue-buttons" @click="dialog=true">Add a Carve-Out or Exclusions</v-btn>
        </v-col>
        </v-row> 
        <v-row>
          <v-simple-table class="col-md-12">
                <template v-slot:default>
                <thead>
                    <tr>
                    <th class="text-left">
                        Name
                    </th>
                    <th class="text-left">
                        Type
                    </th>
                    <th class="text-left">
                        Category
                    </th>
                    <th class="text-left">
                        Item
                    </th>
                    <th class="text-left">
                        Description
                    </th>
                     <th class="text-left">
                        Rate Type
                    </th>
                     <th class="text-left">
                        Amount
                    </th>
                     <th class="text-left">
                        Actions
                    </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                    v-for="item in carveOuts"
                    :key="item.name"
                    >
                    <td>{{ item.name }}</td>
                    <td>{{ item.type }}</td>
                    <td>{{ item.category }}</td>
                    <td>{{ item.item }}</td>
                    <td>{{ item.description }}</td>
                    <td>{{ item.rateType }}</td>
                    <td>{{ item.rateAmount }}</td>
                    <td></td>

                    </tr>
                </tbody>
                </template>
           </v-simple-table>
        </v-row>
        <v-dialog
            v-model.trim="dialog"
            persistent
            max-width="600px"
            >
  
      <v-card>
        <v-card-title>
          <span class="text-h5 text-underline">Add Carve Outs and Exclusions</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
              <label for="">Name</label>
                <v-text-field
                  solo
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
               <label for="">Type</label>
              <v-select
                  solo
                  required
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                  <label for="">Category</label>
                  <v-select
                  solo
                  required
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
               <label for="">Item</label>
                <v-select
                  solo
                  required
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
              <label for="">Description</label>
                <v-text-field
                  solo
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
              <label for="">Rate Type</label>
                <v-select
                  solo
                  required
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <label for="">Rate Amount (USD)</label>
                <v-text-field
                  solo
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Save & Add
          </v-btn>
        </v-card-actions>
      </v-card>
        </v-dialog>
     </div>
     <div class="col-md-12 form-box" v-if="itemtab == 'tab3'">
      <v-row class="justify-space-around">
        <v-col class="col-md-9" cols="12">
            <h3>
                Outliers & Formulas
            </h3>
        </v-col>
        <v-col class="col-md-3 text-end" cols="12">
            <v-btn class="blue-buttons" @click="dialog1 = true">Add an Outlier or Formula</v-btn>
        </v-col>
        </v-row> 
        <v-row>
          <v-simple-table class="col-md-12">
                <template v-slot:default>
                <thead>
                    <tr>
                    <th class="text-left">
                        Name
                    </th>
                    <th class="text-left">
                        Type
                    </th>
                    <th class="text-left">
                        Category
                    </th>
                    <th class="text-left">
                        Item
                    </th>
                    <th class="text-left">
                        Description
                    </th>
                     <th class="text-left">
                        Rate Type
                    </th>
                     <th class="text-left">
                        Amount
                    </th>
                     <th class="text-left">
                        Actions
                    </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                    v-for="item in carveOuts"
                    :key="item.name"
                    >
                    <td>{{ item.name }}</td>
                    <td>{{ item.type }}</td>
                    <td>{{ item.category }}</td>
                    <td>{{ item.item }}</td>
                    <td>{{ item.description }}</td>
                    <td>{{ item.rateType }}</td>
                    <td>{{ item.rateAmount }}</td>
                    <td></td>

                    </tr>
                </tbody>
                </template>
           </v-simple-table>
        </v-row>
        <v-dialog
            v-model.trim="dialog1"
            persistent
            max-width="600px"
            >
      <v-card>
        <v-card-title>
          <span class="text-h5 text-underline">Request to Add an Outlier or Formula</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
              <label for="">Name</label>
                <v-text-field
                  solo
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
              <label for="">Type</label>
              <v-select
                  solo
                  required
                ></v-select>
              </v-col>
            
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
              <label for="">Description</label>
                <v-textarea
                  solo
                  required
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog1 = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog1 = false"
          >
            Save & Add
          </v-btn>
        </v-card-actions>
      </v-card>
        </v-dialog>
     </div>
     <div class="col-md-12 d-flex align-end flex-column">
              <div>
                <v-btn class="blue-buttons mr-6" @click="cancel(tab)">
                  Cancel
                </v-btn>
                <v-btn class="blue-buttons" @click="nextTab(tab)">
                 Next
                </v-btn>
            </div>
        </div>
 </v-row>
 
</template>
<script>
 export default{
   data(){
       return{
        carveOuts:[
             {
                 name:'Rate Name',
                 type:'Rate Type',
                 category : ' Rate Category',
                 item:'Rate Item',
                 description:'Rate Description',
                 rateType:'Rate Type',
                 rateAmount:'RateAmount',
                 actions:''
                 
             }
         ],
          items:[
            {
              text:'General Information',
              value:1,
            },
            {
              text:'Base & Preferred Rates',
              value:1,
            },  
            {
              text:'Carve-Outs & Exclusions',
              value:1,
            },  
            {
              text:'Outliers & Formulas',
              value:1,
            }
          ],
          tableItems:[
           

          ],
          tab: null,
          href:'#tab-0',
       
        dialog:false,
        dialog1:false,
        dialog2:false,
        display:false,
        windowWidth:window.innerWidth
       }
   },
   computed:{
       itemtab(){
         return this.tab
       }
   },
    methods:{
      cancel(tab){
          if(tab == 'tab4'){
            this.tab = 'tab3'
          }else if(tab == 'tab3'){
              this.tab = 'tab2'
          }else if(tab == 'tab2'){
              this.tab = 'tab1'
          }
          else if(tab == 'tab1'){
              this.tab = 'tab0'
          }

      },
      nextTab(tab){
          if(tab == 'tab0'){
            this.tab = 'tab1'
          }else if(tab == 'tab1'){
              this.tab = 'tab2'
          }else if(tab == 'tab2'){
              this.tab = 'tab3'
          }
          else if(tab == 'tab3'){
              this.tab = 'tab4'
          }

      },
      onResize() {
        this.windowWidth = window.innerWidth
        if (this.windowWidth < 900) {
            this.display = false;
        } else {
            this.display = true;
        }
    },
       
    },
     created(){
            if (this.windowWidth < 900) {
                this.display = false;
            } else {
                this.display = true;
            }
        },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
   
 }
</script>
<style >
.theme--dark.v-tabs > .v-tabs-bar {
     background-color: transparent !important;
}
</style>