var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app-bar',{attrs:{"app":"","clipped-left":"","clipped-right":""}},[_c('v-toolbar-title',{staticClass:"no-padding",staticStyle:{"overflow":"initial","width":"173px"},attrs:{"align":"center"}},[_c('img',{staticClass:"apaly-pic pointer",attrs:{"id":"logo-img","src":_vm.logo ? _vm.logo : '/apaly1.png'}})]),_c('v-divider',{staticClass:"header-divider-hide",attrs:{"vertical":""}}),_c('div',{staticClass:"col-md-6 col-sm-6 header-hamb-icon mt-md-3 no-padding row"},[_c('i',{staticClass:"fas fa-bars top-2px",staticStyle:{"cursor":"pointer","color":"gray"},on:{"click":_vm.hideSideBar}}),_c('h2',{staticClass:"text-gray font-quicksand-semibold mb-4 ml-2 group-name",domProps:{"innerHTML":_vm._s(
        _vm.user &&
        _vm.user.groupPortal &&
        _vm.user.groupPortal[0] &&
        _vm.user.companyName
          ? _vm.user.companyName
          : 'Employers Providers'
      )}})]),_c('v-spacer'),_c('div',{staticClass:"user-settings"},[_c('div',{staticClass:"font text-right font-quicksand-medium-12 pointer",on:{"click":function($event){return _vm.$router.push('/login')}}},[_vm._v(" Login ")])]),_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({  }){return [_c('v-btn',{staticClass:"mr-1",attrs:{"dark":"","icon":""},on:{"click":function($event){return _vm.$router.push('/login')}}},[_c('v-avatar',{attrs:{"size":"40"}},[_c('img',{staticStyle:{"object-fit":"cover"},attrs:{"id":"profile-img","src":_vm.profileImg
                ? _vm.profileImg
                : 'https://www.pngkit.com/png/full/128-1280585_user-icon-fa-fa-user-circle.png',"alt":"John"}})])],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }