var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row row-height justify-center col-12"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.activeEntity),expression:"!activeEntity"}],staticClass:"organization-pending-info"},[_c('img',{staticClass:"org-pending-icon",attrs:{"src":"/svg/organizationPending.svg"}}),_c('span',{staticClass:"org-pending-title"},[_vm._v("Your organization is pending")]),_c('span',{staticClass:"org-pending-info"},[_vm._v(" Thank you for registering with Apaly. ")]),_c('span',{staticClass:"org-pending-info"},[_vm._v(" Your account is being reviewed and you will be notified once your account is approved. ")])]),(_vm.activeEntity)?_c('div',{staticClass:"col-md-8 col-12"},[(_vm.companyName == '/'
  || _vm.taxId == '/' 
  || _vm.image == '/'
  || _vm.desc == '/' 
  || _vm.bankName == '/'
  || _vm.roundNo == '/'
  || _vm.accountNo == '/' )?_c('v-card',{staticClass:"text-center"},[_c('v-card-text',{staticClass:"success-modal pt-5"},[_c('div',{staticClass:"display mt-5"},[_c('span',{staticClass:"text-center font-18 mt-5 font-weight-bold color-black mt-5"},[_vm._v(" Complete your account information if you want your Vendor to be in the marketplace! ")])]),_c('div',{staticClass:"text-center text--white mt-10"},[_c('v-btn',{staticClass:"submit-btn",attrs:{"color":"#16407A"},on:{"click":function($event){return _vm.$router.push('/vendors/account')}}},[_vm._v(" Complete account ")])],1)])],1):_c('v-card',{staticClass:"text-center"},[_c('v-card-text',{staticClass:"success-modal pt-5"},[_c('div',{staticClass:"display mt-5"},[_c('span',{staticClass:"text-center font-18 mt-5 font-weight-bold color-black mt-5"},[_vm._v(" Your account is completed! Now you can see your vendor in the marketplace. ")])]),_c('div',{staticClass:"text-center text--white mt-10"},[_c('v-btn',{staticClass:"submit-btn",attrs:{"color":"#16407A"},on:{"click":function($event){return _vm.$router.push('/vendors/solutions')}}},[_vm._v(" View Vendor ")])],1)])],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }