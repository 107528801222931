<template>
  <v-row class="add-client-form">
    <successModal ref="success_modal" v-on:confirm="sendMail"></successModal>
    <errorDialog ref="error_modal" v-on:confirm="closeErrorModal"></errorDialog>
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12">
          <h3 class="form-title font">Add & Invite a User</h3>
        </div>
      </div>
      <div class="form-row-height">
        <v-form v-model.trim="valid" ref="form" class="row">
          <div class="col-md-6 pb-0">
            <label class="font text-gray" for="">First Name</label>
            <v-text-field
              required
              v-model.trim="firstName"
              solo
              :rules="requiredRules"
              tabindex="1"
            ></v-text-field>
          </div>
          <div class="col-md-6 pb-0">
            <label class="font text-gray" for="">Role </label>
            <v-select
              required
              tabindex="5"
              solo
              :items="[
                { text: 'Admin', value: 'admin' },
                { text: 'User', value: 'user' },
              ]"
              v-model.trim="role"
            ></v-select>
          </div>
          <div class="col-md-6 pb-0 pt-0">
            <label class="font text-gray" for="">Last Name</label>
            <v-text-field
              required
              tabindex="2"
              :rules="requiredRules"
              v-model.trim="lastName"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-6 pb-0 pt-0">
            <label class="font text-gray" for="">Office Name</label>
            <v-text-field
              required
              tabindex="6"
              v-model.trim="officeName"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-6 pb-0 pt-0">
            <label class="font text-gray" for="">Email</label>
            <v-text-field
              required
              :rules="emailRules"
              v-model.trim="email"
              solo
              tabindex="3"
            ></v-text-field>
          </div>
          <div class="col-md-6 pb-0 pt-0">
            <label class="font text-gray" for="">Address</label>
            <v-text-field
              required
              tabindex="7"
              v-model.trim="address"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-6 pb-0 pt-0">
            <label class="font text-gray" for="">Title</label>
            <v-text-field
              required
              tabindex="4"
              v-model.trim="title"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-6 row no-padding">
            <div class="col-md-6 pb-0 pt-0">
              <label class="font text-gray" for="">City</label>
              <v-text-field
                required
                v-model.trim="city"
                solo
                tabindex="8"
              ></v-text-field>
            </div>
            <div class="col-md-3 pb-0 pt-0">
              <label class="font text-gray" for="">State</label>
              <v-select
                required
                :items="states"
                v-model.trim="state"
                solo
                tabindex="9"
                item-text="name"
                item-value="name"
              ></v-select>
            </div>
            <div class="col-md-3 zip-field-height pb-0 pt-0">
              <label class="font text-gray" for="">Zip</label>
              <v-text-field
                required
                type="number"
                v-model.trim="zip"
                :rules="zip.length > 0 ? zipRules : []"
                solo
                tabindex="10"
              ></v-text-field>
            </div>
          </div>
        </v-form>
      </div>
      <div class="row py-2">
        <div class="col-md-12 row text-end justify-end">
          <v-btn
            class="blue-buttons"
            :disabled="loadData"
            @click="$router.push(`/provider-advisor/settings/users`)"
          >
            Cancel
          </v-btn>
          <v-btn class="blue-buttons ml-2" :disabled="loadData" @click="save()">
            Save & Invite User
          </v-btn>
        </div>
      </div>
    </div>
  </v-row>
</template>
<script>
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import errorDialog from "../../../TablesTemplate/ErrorDialog.vue";
import api from "../../../../services/api";
export default {
  components: { successModal, errorDialog },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      title: "",
      role: "",
      officeName: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      loadData: false,
      zipRules: [
        (v) => (v && v.length == 5) || " Zip Code should be five digits long",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
      states: [],
      valid: false,
    };
  },
  computed: {
    routeParams() {
      return this.$route.params.name;
    },
  },
  created() {
    this.getStates();
  },
  methods: {
    addUser() {
      this.$router.push(`/provider-advisor/settings/users`);
    },
    getStates() {
      api()
        .get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },
    sendMail() {
      this.$refs.success_modal.closePopUp();
      this.$router.push(`/provider-advisor/settings/users`);
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    save() {
      this.$refs.form.validate();
      if (this.valid) {
        this.loadData = true;
        let data = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          title: this.title,
          role: this.role,
          officeName: this.officeName,
          address1: this.address,
          city: this.city,
          state: this.state,
          zipCode: this.zip,
        };
        let user = JSON.parse(localStorage.getItem("user"));
        let advisorId = user.groupPortal[0].organization.providerAdvisor;
        let employer = JSON.parse(localStorage.getItem("employer"));
        api()
          .post(`/advisors/${advisorId}/PA/users`, data)
          .catch((err) => {
            let text = err.response.data.message;
            this.$refs.error_modal.openPopUp(text);
            this.loadData = false;
          })
          .then((res) => {
            if (res) {
              let text = "Email sucessfully sent";
              this.$refs.success_modal.openPopUp(text);
              this.loadData = false;
            }
          });
      }
    },
  },
};
</script>
