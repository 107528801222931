<template>
  <div>
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <v-row class="mb-4 mt-3">
      <v-col class="col-md-7 col-lg-8 col-sm-5" cols="12">
        <h3 class="font ml-3" v-if="$role != 'user'">
          Add New Partner - Search Results
        </h3>
        <h3 class="font ml-3" v-else>Search Results</h3>
      </v-col>
    </v-row>

    <v-row v-if="loader" class="justify-center col-md-12">
      <v-progress-circular
        :size="200"
        class="text-center"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-row>
    <v-simple-table v-else fixed-header height="630px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Partner Name</th>
            <th class="text-left">Individual Name</th>
            <th class="text-left">Type</th>
            <th class="text-left">City</th>
            <th class="text-left">State</th>
            <th class="no-padding text-end justify-end" v-if="$role != 'user'">
              <div class="col-md-12 justify-end d-flex text-end">
                <v-checkbox
                  class="no-padding mt-3"
                  v-model="allRows"
                  @click="selectAllRows1()"
                ></v-checkbox>
                <label for="" style="padding-top: 11px">Select all</label>
              </div>
            </th>
          </tr>
        </thead>
        <tbody v-if="items && items.length > 0">
          <tr
            v-for="(item, index) in items"
            :key="index"
            :class="selectAll ? 'activeItem' : ''"
            :id="'id' + index"
          >
            <td>
              <a @click="singleItem(item)">{{ item.companyName }}</a>
            </td>
            <td v-if="item.primaryContact">
              {{ item.primaryContact.firstName }}
              {{ item.primaryContact.lastName }}
            </td>
            <td v-else>/</td>
            <td
              v-html="
                item.advisorType == 'brokerConsultant'
                  ? 'Broker Consultant'
                  : item.advisorType == 'analyticsConsultant'
                  ? 'Analytics Consultant'
                  : item.advisorType
              "
            >
              {{ item.advisorType }}
            </td>
            <td v-html="item.city ? item.city : '/'"></td>
            <td v-html="item.state ? item.state : '/'"></td>
            <td
              v-if="$role != 'user'"
              class="text-center mt-3 row no-padding justify-end"
              style="padding-right: 90px"
            >
              <v-checkbox
                class="no-padding stay-blue"
                v-model="item.checkbox"
                @click="activeRow1(index, item)"
              ></v-checkbox>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <td>
            <a
              class="ml-3"
              @click="
                $router.push(
                  `/provider-advisor/providers/${routeParams}/advisors/invite-advisor`
                )
              "
              >No Results Found - Click Here to Invite a Partner</a
            >
          </td>
        </tbody>
      </template>
    </v-simple-table>
    <div class="row col-md-12 mt-5 justify-end no-padding">
      <v-btn
        class="blue-buttons"
        :class="getAdvisors.length > 0 ? 'mr-5' : ''"
        @click="
          $router.push(`/provider-advisor/providers/${routeParams}/advisors`)
        "
      >
        Cancel
      </v-btn>
      <v-btn
        class="blue-buttons mr-2 mb-3"
        v-if="getAdvisors.length > 0 && $role != 'user'"
        @click="addAdvisor()"
      >
        Add Selected
      </v-btn>
    </div>
    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
  </div>
</template>
<script>
import api from "../../../../../services/api";
import successModal from "../../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../../TablesTemplate/ErrorDialog.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    successModal,
    errorModal,
  },
  data() {
    return {
      selected: [],
      items: [],
      selectAll: false,
      selectedRows: [],
      checkIfSelect: false,
      allRows: false,
      allPartners: [],
      checkIfClicked: false,
      loader: false,
    };
  },
  computed: {
    getClient() {
      return this.$store.getters.getClientGetter;
    },
    routeParams() {
      return this.$route.params.name;
    },
    getAdvisors() {
      return this.$store.getters.getSearchAdvisorsResultInEmployerAdvisor;
    },
    ...mapGetters(["getAllPartners"]),
  },
  created() {
    this.allPartners = this.getAdvisors;
    this.items = this.getAdvisors;
  },
  methods: {
    selectAllRows1() {
      this.selectedRows = [];

      if (!this.checkIfSelect) {
        this.selectedRows = [];
        this.checkIfClicked = false;
        this.items.forEach((el) => {
          el.checkbox = false;
        });
      }
      if (this.checkIfSelect) {
        this.items = [];
        this.loader = true;
        this.items = this.allPartners;
        let tmp = JSON.stringify(this.items);
        this.selectedRows = JSON.parse(tmp);
        this.checkIfSelect = false;

        setTimeout(() => {
          this.items.forEach((el) => {
            el.checkbox = true;
          });
          this.loader = false;
        }, 0);

        //  this.$forceUpdate();
      } else if (this.selectAll && !this.checkIfSelect) {
        this.selectAll = false;
      } else {
        this.selectAll = !this.selectAll;
        if (!this.selectAll) {
          this.selectedRows = [];
          this.checkIfClicked = false;
          this.items.forEach((el) => {
            el.checkbox = false;
          });
        } else {
          this.items.forEach((el) => {
            el.checkbox = true;
          });
          this.selectedRows = [];
          let tmp = JSON.stringify(this.items);
          this.selectedRows = JSON.parse(tmp);
        }
      }
    },
    activeRow1(index, item) {
      let itemById = document.getElementById("id" + index);
      if (this.allRows) {
        this.checkIfSelect = true;
        let removeItem = this.selectedRows.find((x) => x._id === item._id);
        this.selectedRows.splice(this.selectedRows.indexOf(removeItem), 1);
        itemById.classList.remove("activeItem");
        this.allRows = false;
        this.checkIfClicked = true;
      } else {
        itemById.classList.toggle("activeItem");
        if (this.checkIfClicked) {
          itemById.classList.remove("activeItem");
        }
        if (item.checkbox) {
          this.selectedRows.push(item);
        } else {
          let removeItem = this.selectedRows.find((x) => x._id === item._id);
          this.selectedRows.splice(this.selectedRows.indexOf(removeItem), 1);
        }
      }
    },
    singleItem(item) {
      this.$store.commit("singlePartnerDeleteButton", false);
      this.$store.commit("setSingleAdvisorinEmployerAdvisor", item);
      this.$router.push(
        `/provider-advisor/providers/${this.routeParams}/advisors/advisor`
      );
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    addAdvisor() {
      if (this.selectedRows.length < 1) {
        let text = "You must  select at least one Partner";
        this.$refs.error_modal.openPopUp(text);
        return;
      }

      let exist = false;

      this.getAllPartners.forEach((el) => {
        if (this.selectedRows.some((t) => t._id == el._id) || this.selectedRows.some((t) => t._id == el._id)) {
          this.$refs.error_modal.openPopUp("Partner Already Exists");
          exist = true;
          return;
        }
      });
      if (!exist) {
        let user = JSON.parse(localStorage.getItem("user"));
        let advisorId = user.groupPortal[0].organization.providerAdvisor;
        let provider = JSON.parse(localStorage.getItem("provider"));
        let data = {
          ids: this.selectedRows,
        };
        api()
          .post(
            `/advisors/${advisorId}/providers/${provider._id}/advisors`,
            data
          )
          .catch((err) => {
            if (err) {
              this.$refs.error_modal.openPopUp(err.response.data.error);
            }
          })
          .then((res) => {
            if (res) {
              let text = "Partners added successfully to this Provider!";
              this.$refs.success_modal.openPopUp(text);
            }
          });
      }
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
      this.$router.push(
        `/provider-advisor/providers/${this.routeParams}/advisors`
      );
    },
    selectAllRows() {
      this.selectedRows = [];
      this.selectAll = !this.selectAll;
      this.getAdvisors.forEach((el) => {
        this.selectedRows.push(el._id);
      });
      if (!this.selectAll) {
        this.selectedRows = [];
      }
    },

    activeRow(index, item) {
      let itemById = document.getElementById("id" + index);
      itemById.classList.toggle("activeItem");
      if (itemById.classList.contains("activeItem")) {
        this.selectedRows.push(item._id);
      } else {
        let removeItem = this.selectedRows.find((x) => x === item._id);
        this.selectedRows.splice(this.selectedRows.indexOf(removeItem), 1);
      }
    },
  },
};
</script>
