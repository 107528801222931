import { render, staticRenderFns } from "./Add.vue?vue&type=template&id=2ab91deb&scoped=true&"
import script from "./Add.vue?vue&type=script&lang=js&"
export * from "./Add.vue?vue&type=script&lang=js&"
import style0 from "./../../../../../assets/mobileStyle.css?vue&type=style&index=0&id=2ab91deb&prod&scoped=true&lang=css&"
import style1 from "./Add.vue?vue&type=style&index=1&id=2ab91deb&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ab91deb",
  null
  
)

export default component.exports