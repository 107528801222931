<template>
    <div class="no-padding no-margin">
        <Users v-if="path == `/provider/settings/users`"></Users>
        <AddUser v-if="path == `/provider/settings/users/add-new`"></AddUser>
        <InviteUser v-if="path ==  `/provider/settings/users/invite-user`"></InviteUser>
        <User v-if="path == `/provider/settings/users/user`"></User>
        <Account v-if="path == `/provider/settings/account`"></Account>
        <Settings v-if="path == `/provider/settings`"></Settings>
    </div>
</template>
<script>
import AddUser from './AddUser.vue'
import InviteUser from './InviteUser.vue'
import User from './UsersCrud.vue'
import Users from './UsersTable.vue'
import Account from './AccountForm.vue'
import Settings from './Settings.vue'
export default{
    components:{
        AddUser,
        InviteUser,
        User,
        Users,
        Account,
        Settings
    },
    computed:{
        path(){
            return this.$route.path
        },
        routeParams(){
            let parsed = ''
            if(this.$route.params.name){
               parsed = this.$route.params.name.replace(/ /g, '%20')
            }
           
            return parsed
        }
      
    },
   
}
</script>