<template>
    <v-row class="add-client-form no-padding">

      <div class="row blue-underline margin-l-11">
        <div class="col-md-12 d-flex mt-3 p-0">
            <img
            id="logo-img"
            :src="'/apaly1.png'"
            class="apaly-pic pointer ml-3 mr-3"
          />
          <h3 class=" col-7 form-title title-color font ml-2 mt-2 font-18">
           Apaly Website - DPC Provider Members Search</h3>


        </div>

      </div>
    <div class="col-12 d-flex pt-14 pl-5 pr-5">
  <div class="col-6">
    <v-card class="mx-auto row-height" max-width="1200" tile>
      
        <div class="blue-underline pt-3 text-center pb-3 font-weight-bold font-18">
             Search by zip code
            </div>
        <div class="col-12 d-flex justify-center mb-5 mt-5">
            <span class="col-7">
                <v-text-field
                @keypress.enter="searchZip(zipCode)"
                class="input-control mr-5"
                prepend-inner-icon="mdi-magnify"
                light
                dense
                v-model="zipCode"
                outlined
                placeholder="Search for Zip Code"
            ></v-text-field>
            </span>

            <span class="col-2">
                <v-btn @click="searchZip(zipCode)"> Search</v-btn>
            </span>
        </div>
        </v-card>
</div>
  <div class="col-6">
    <v-card class="mx-auto row-height overflow-y-auto " max-width="1200" height="70vh" tile>
        <div class="blue-underline pt-3 text-center pb-3 font-weight-bold font-18">
            Search results
           </div>
           <div v-if="searched" class="pl-5 pt-3">
                There are in total: <strong> {{ totalCount }} </strong> plan member that live within a 30 mile radius of this zip code.
           </div>
           <div v-if="searched"  class="pl-5 pt-3 mb-3 font-weight-bold text-gray d-flex">
         <p class="mt-2"> Here is a list of nearby zip Codes  </p>  <v-btn class="ml-2 font-weight-bold" @click="showList=true">SHOW</v-btn>
       </div>
           <v-simple-table fixed-header v-if="showList">
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">Zip Code</th>
                <th class="text-left">Member Count</th>
                <th class="text-left">Company</th>
              </tr>
              </thead>
              <tbody>
              <tr
                 v-for="(item,index) in list" 
                  :key="index"
                  class="text-gray"
              >
              <td>
                {{ item.Zip }}
              </td>
               <td v-if="item.Members.length>0" >
               <a v-for="(member,index) in item.Members"> {{ member.Count}} </a>  
               </td> 
               <td v-else>/</td>
               <td  v-if="item.Members.length>0">
                <a v-for="(member,index) in item.Members">{{member.Company}}</a>
              </td>
              <td v-else>/</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
          
    </v-card>
    <successModal ref="success_modal" v-on:confirm="closeSuccessModal"></successModal>
   <errorModal 
    ref="error_modal"
     v-on:confirm="closeErrorModal">
    </errorModal>
</div>
    </div>
      
    </v-row>
  </template>
  <script>
    import successModal from "../../TablesTemplate/SuccessDialog.vue";
  import errorModal from '../../TablesTemplate/ErrorDialog.vue';
  import api from "../../../services/api.js";
  import XLSX from "xlsx";
  export default {
  components: { errorModal, successModal },
    data() {
      return {
        showList: false,
        searched: false,
        zipCode: "",
        list: [],
        items: [],
        names: [],
        totalCount: [],
      jsondata: [
        {
          zip:"",
        }
      ],
      };
    },
    created(){

    },
    methods: {
      closeSuccessModal() {
        this.$refs.success_modal.closePopUp();
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
      this.zipCode = "";
    },
      searchZip(zip){
        api()
          .get(`/dpcmembers?zipCode=` + zip)
          .catch((err) => {
                    this.$refs.error_modal.openPopUp(err.response.data)
                })
          .then((res) => {
            if (res) {
              this.list = res.data.nearbyZipCodes;
              this.totalCount = res.data.totalCount;
              let test= res.data.nearbyZipCodes.Zip;
              this.searched=true;
             
            }
          });
      },
      addItemExcel() {
      this.$refs.fileInput.click();
    },
    uploadExcel(e) {
      this.excelError = false;
      const files = e.target.files;
      if (!files.length) {
        return;
      } else if (!/\.(xls|xlsx|csv)$/.test(files[0].name.toLowerCase())) {
        return alert(
            "The upload format is incorrect. Please upload xls, xlsx or csv format"
        );
      }
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const workbook = XLSX.read(data, {
            type: "binary",
          });
          const wsname = workbook.SheetNames[0];
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]);
          for (var i = 0; i < ws.length; i++) {
            if (ws[i].zipCode) {
              this.excelError = false;
              let tmpObj = {
                zipCode: ws[i].zipCode ?? "",
              }
              this.items.push(tmpObj);
              this.arrayLength = true;
            } else {
              this.excelError = true;
              this.arrayLength = false;
            }
          }
          if (this.items.length < 1) {
            this.arrayLength = false;
            this.items = [];
            this.$refs.error_modal.openPopUp(
                "Something Went Wrong, Please Download The File Template First"
            );
          }
        } catch (e) {
          console.log(e)
          this.$refs.error_modal.openPopUp(
                "Read failure! Please Download the File Template."
            );
        }
      };

      fileReader.readAsBinaryString(files[0]);
    },
    downloadExcel() {
      const data = XLSX.utils.json_to_sheet(this.jsondata);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "zipCodes.xlsx");
    },
    }
  
  };
  </script>
  <style scoped>
.justify-space{
    justify-content: space-between;
}
.title-color{
    color:#334D6E;
}
</style>