<template>
  <v-row class="padding">
    <div class="col-md-12">
      <h3 class="font">Partner Detail</h3>
    </div>
    <form class="row form-row-height">
      <div class="col-md-6">
        <div class="col-md-12 pb-0">
          <label class="font text-gray" for="">First Name</label>
          <v-text-field
            required
            solo
            v-model.trim="firstName"
            disabled
          ></v-text-field>
        </div>
        <div class="col-md-12 pb-0 pt-0">
          <label class="font text-gray" for="">Last Name</label>
          <v-text-field
            required
            solo
            v-model.trim="lastName"
            disabled
          ></v-text-field>
        </div>
        <div class="col-md-12 pb-0 pt-0">
          <label class="font text-gray" for="">Email</label>
          <v-text-field
            required
            v-model.trim="email"
            solo
            disabled
          ></v-text-field>
        </div>
        <div class="col-md-12 pb-0 pt-0">
          <label class="font text-gray" for="">Phone</label>
          <v-text-field
            required
            solo
            disabled
            v-model.trim="phone"
            :rules="phoneRules"
          ></v-text-field>
        </div>
      </div>
      <div class="col-md-6">
        <div class="col-md-12 pb-0">
          <label class="font text-gray" for="">Company Name</label>
          <v-text-field
            required
            solo
            v-model.trim="company"
            disabled
          ></v-text-field>
        </div>
        <div class="col-md-12 pb-0 pt-0">
          <label class="font text-gray" for="">Title</label>
          <v-text-field
            required
            solo
            v-model.trim="title"
            disabled
          ></v-text-field>
        </div>
        <div class="row no-padding">
          <div class="col-md-6 pb-0 pt-0">
            <label class="font text-gray" for="">City</label>
            <v-text-field
              required
              v-model.trim="city"
              solo
              disabled
            ></v-text-field>
          </div>
          <div class="col-md-3 pb-0 pt-0">
            <label class="font text-gray" for="">State</label>
            <v-text-field v-model.trim="state" solo disabled> </v-text-field>
          </div>
          <div class="col-md-3 pb-0 pt-0">
            <label class="font text-gray" for="">Zip</label>
            <v-text-field
              required
              solo
              disabled
              type="number"
              v-model.trim="zip"
            ></v-text-field>
          </div>
        </div>
        <div class="col-md-12 pb-0 pt-0">
          <label class="font text-gray" for="">Type</label>
          <v-text-field
            required
            solo
            v-model.trim="type"
            disabled
          ></v-text-field>
        </div>
        <div class="col-md-12" v-if="showDeleteBtn && $role != 'user'">
          <div class="row text-gray">
            <v-checkbox
              @change="selectCheckbox"
              class="mr-3 ml-3"
              v-model.trim="administratorPartner"
            ></v-checkbox>
            <span class="mt-5 font">
              This partner is our designated plan administrator.
            </span>
          </div>
        </div>
      </div>
    </form>
    <div class="col-md-12 row justify-end text-end">
      <v-btn class="blue-buttons mr-6" @click="$router.go(-1)"> Close </v-btn>
      <v-btn
        v-if="showDeleteBtn && $role != 'user'"
        class="blue-buttons"
        @click="removePartner"
      >
        Remove Partner
      </v-btn>
    </div>
    <v-dialog v-model.trim="dialog" max-width="600px" persistent>
      <v-card>
        <v-col>
          <h3>Remove Advisor</h3>
        </v-col>

        <v-col>
          <span>Are you sure you want to delete this Partner?</span>
        </v-col>

        <v-col>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="blue-buttons float-class" @click="confirmRemove"
              >Confirm</v-btn
            >
            <v-btn class="button-style float-class" @click="closePopUp"
              >Close</v-btn
            >
          </v-card-actions>
        </v-col>
      </v-card>
    </v-dialog>
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
  </v-row>
</template>
<script>
import api from "../../../../../services/api";
import successModal from "../../../../TablesTemplate/SuccessDialog.vue";
export default {
  components: { successModal },
  data() {
    return {
      states: [],
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      company: "",
      title: "",
      state: "",
      zip: "",
      city: "",
      planAdministrator: "",
      administratorPartner: "",
      type: "",
      dialog: false,
      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
    };
  },
  computed: {
    getClient() {
      return this.$store.getters.getClientGetter;
    },
    routeParams() {
      return this.$route.params.name;
    },
    singleAdvisor() {
      return this.$store.getters.getSingleAdvisorinEmployerAdvisor;
    },
    showDeleteBtn() {
      return this.$store.getters.showDeletePartnerButton;
    },
  },
  created() {
    this.getStates();

    if (this.singleAdvisor) {
      this.firstName = this.singleAdvisor.primaryContact.firstName;
      this.lastName = this.singleAdvisor.primaryContact.lastName;
      this.city = this.singleAdvisor.city;
      this.company = this.singleAdvisor.companyName;
      this.email = this.singleAdvisor.primaryContact.email;
      this.type =
        this.singleAdvisor.advisorType == "brokerConsultant"
          ? "Broker Consultant"
          : this.singleAdvisor.advisorType == "analyticsConsultant"
          ? "Analytics Consultant"
          : this.singleAdvisor.advisorType;
      this.phone = this.singleAdvisor.primaryContact.phone
        ? this.singleAdvisor.primaryContact.phone
        : "";
      this.phone = this.singleAdvisor.title ? this.singleAdvisor.title : "";
      this.state = this.singleAdvisor.state;
      this.zip = this.singleAdvisor.zipCode;
      this.administratorPartner = this.singleAdvisor.administratorPartner;
    }
  },
  methods: {
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
    },
    selectCheckbox(item) {
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.employerAdvisor;
      let employer = JSON.parse(localStorage.getItem("employer"));
      let data = {
        administratorPartner: this.administratorPartner,
      };
      api()
        .put(
          `/advisors/${advisorId}/employers/${employer._id}/advisors/${this.singleAdvisor._id}`,
          data
        )
        .then((res) => {
          if (res) {
            this.$refs.success_modal.openPopUp(res.data.success);
          }
        });
    },
    removeItem() {
      this.dialog = true;
    },
    closePopUp() {
      this.dialog = false;
    },
    confirmRemove() {
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.employerAdvisor;
      let employer = JSON.parse(localStorage.getItem("employer"));
      api()
        .delete(
          `/advisors/${advisorId}/employers/${employer._id}/advisors/${this.singleAdvisor._id}`
        )
        .then((res) => {
          if (res) {
            this.dialog = false;
            this.$router.push(
              `/employer-advisor/employers/${this.routeParams}/advisors`
            );
          }
        });
    },
    removePartner() {
      this.dialog = true;
    },
    getStates() {
      api()
        .get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },
  },
};
</script>
 

