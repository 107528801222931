<template>
  <div class="overlay-backgorund">
    <div class="col-md-12" v-if="dialog">
      <div class="white-background border-r-8">
        <v-card>
          <div class="col-md-12 row blue-underline mb-1 no-padding">
            <div class="col-xl-5 row col-lg-5 col-md-3 mt-1">
              <h3
                class="form-title font ml-4 mr-2 mt-1 font-18"
                style="font-size: 18px"
              >
                Add Personnel to this Location: 
              </h3>
              <!-- <v-select
                class="input-control mr-5"
                prepend-inner-icon="mdi-magnify"
                light
                @input="searchPersonel"
                dense
                hide-details
                outlined
                placeholder="All Providers"
              ></v-select> -->
            </div>

            <div class="mr-auto col-xl-3 col-lg-3 col-md-3 row mt-1">
              <v-text-field
                class="input-control mr-5"
                prepend-inner-icon="mdi-magnify"
                light
                dense
                @input="searchPersonel"
                hide-details
                outlined
                placeholder="Search Personnel"
              ></v-text-field>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-2 text-end">
              <v-btn
                class="my-1 mr-2 gray-border-buttons"
                @click="addNewPersonnel"
              >
              <img class="pointer mr-1"  src="/svg/add.svg"/>


                New</v-btn
              >
            </div>
          </div>
          <div class="col-md-12">
            <v-simple-table fixed-header height="600px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">USER NAME</th>
                    <th class="text-left">ROLE</th>
                    <th class="text-left">LOCATION(S)</th>

                    <th v-if="display">
                      <div
                        :class="
                          display
                            ? 'col-md-12 justify-end d-flex text-end aling-center'
                            : ''
                        "
                        align="center"
                        style="padding-right: 12px; padding-top: 0px"
                      >
                  
                        <label class="d-flex aling-center" for="" style=""
                          >SELECT ALL
                        </label>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <!-- {{ availableUsers }} -->
                  <tr
                    v-for="(item, index) in availableUsers"
                    :key="index"
                    :class="selectAll ? 'activeItem' : ''"
                    :id="'id' + index"
                  >
                    <td>
          
                      <a style="color: #0573F3; text-decoration: underline;"
                          v-if="item"
                        >{{ item.firstName }}
                        {{ item.lastName }}
                      </a>
                    </td>
                    <td>
                      {{
                        item.groupPortal &&
                        item.groupPortal[0] &&
                        item.groupPortal[0].role
                          ? item.groupPortal[0].role.name
                              .charAt(0)
                              .toUpperCase() +
                            item.groupPortal[0].role.name.slice(1)
                          : "/"
                      }}
                    </td>
                    <td>{{ item.locations ? item.locations.length : "/" }}</td>
                    <td
                      v-if="display"
                      :class="
                        display
                          ? 'text-center mt-3 row no-padding justify-end'
                          : ''
                      "
                      style="padding-right: 110px"
                    >
                      <v-checkbox
                        :value="selectAll"
                        @click="activeRow(index, item)"
                        hide-details
                        class="no-padding stay-blue"
                      ></v-checkbox>
                    </td>
                    <td v-else>
                      <input type="checkbox" />
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          <div class="gray-underline-wizard col-md-12">
            <div class="mt-1 row text-end justify-end col-md-12 mb-1">
              <div
                class="pointer mr-10 text-underline mb-3"
                style="color:#0572F3;"
                @click="goBack(-1)"
              >
                Cancel
              </div>
              <div
                class="pointer text-underline mb-3" 
                style="color:#0572F3;"
                @click="addData('')"
              >
                Add Selected
              </div>
            </div>
          </div>
        </v-card>
      </div>
    </div>
    <div class="col-md-12" v-if="addNewModal">
      <div class="white-background border-r-8">
        <v-card>
          <div class="col-md-12 row blue-underline no-padding">
            <div class="col-xl-3 col-lg-3 col-md-3 mt-2">
              <h3 class="form-title font ml-2 font-18">Add New Providers</h3>
            </div>
          </div>
          <div class="col-md-12 row no-padding mt-0 mb-0">
            <v-col cols="12" md="6" class="border-right">
              <div class="col-md-12">
                <div class="row col-md-12">
                  <h3 class="font">Search & Add Providers</h3>
                </div>
                <form class="row">
                  <div class="col-md-12 no-padding">
                    <div class="col-md-6 pb-0">
                      <label class="font text-gray" for="">NPI Number</label>
                      <v-text-field
                        required
                        v-model.trim="npiNumber"
                        solo
                      ></v-text-field>
                    </div>
                  </div>

                  <div class="col-md-6 pt-0 pb-0">
                    <label class="font text-gray" for="">First Name</label>
                    <v-text-field
                      required
                      v-model.trim="firstName"
                      solo
                    ></v-text-field>
                  </div>
                  <div class="col-md-6 pt-0 pb-0">
                    <label class="font text-gray" for="">Last Name</label>
                    <v-text-field
                      required
                      solo
                      v-model.trim="lastName"
                    ></v-text-field>
                  </div>

                  <div class="col-md-12"><h4>Narrow your Search</h4></div>
                  <div class="col-md-12 pt-0 pb-0">
                    <label class="font text-gray" for="">Specialties</label>
                    <v-text-field required solo v-model.trim="speciality">
                    </v-text-field>
                    <!-- <v-select
                      multiple
                      :items="taxonomies"
                      required
                      solo
                      filled
                      chips
                      v-model.trim="speciality"
                      :menu-props="{
                        bottom: true,
                        offsetY: true,
                        closeOnClick: true,
                        maxHeight: 200,
                      }"
                    >
                      <template #selection="selection">
                        <v-chip
                          @click="deleteItem(selection.item)"
                          v-text="selection.item"
                        ></v-chip>
                      </template>
                    </v-select> -->
                  </div>
                  <div class="col-md-5 pt-0 pb-0">
                    <label class="font text-gray" for="">City</label>
                    <v-text-field
                      required
                      v-model.trim="city"
                      solo
                    ></v-text-field>
                  </div>
                  <div class="col-md-5 pt-0 pb-0">
                    <label class="font text-gray" for="">State</label>
                    <v-select
                      required
                      item-text="name"
                      item-value="value"
                      :items="states"
                      v-model.trim="state"
                      solo
                    ></v-select>
                  </div>
                  <div class="col-md-2 pt-0 pb-0">
                    <label class="font text-gray" for="">Zip</label>
                    <v-text-field
                      required
                      solo
                      v-model.trim="zip"
                      :rules="zipRules"
                    ></v-text-field>
                  </div>
                  <div class="col-md-12 aling-end" align="end">
                    <span
                      class="text-underline text-blue pointer"
                      style="color: "
                      @click="searchResultModal"
                      >Search</span
                    >
                  </div>
                </form>
              </div>
            </v-col>
            <v-col md="6" cols="12" class="justify-center align-center">
              <div class="row col-md-12 font">
                <h3 class="ml-xl-10 col-md-12">Upload a List of Providers</h3>
              </div>
              <div class="row text-center justify-center upload-box">
                <div
                  class="col-md-9 upload-provider-box pointer"
                  @click="addItemExcel"
                >
                  <input
                    ref="fileInput"
                    type="file"
                    style="display: none"
                    @change="uploadExcel"
                  />
                  
                  <svg
                    width="100"
                    height="100"
                    viewBox="0 0 100 100"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M90.2135 56.7407C86.921 54.5225 83.1435 53.1493 79.208 52.7403C75.2726 52.3313 71.2979 52.8986 67.6281 54.3933C63.9583 55.8879 60.704 58.2648 58.147 61.3181C55.5901 64.3713 53.8075 68.009 52.9537 71.916C51.4417 76.8915 51.7658 82.2529 53.8659 87.005C55.9661 91.7571 59.6995 95.5771 64.3734 97.7561C66.9118 98.6879 69.5174 99.4207 72.1671 99.9481C72.3424 99.9983 72.5259 100.012 72.7065 99.9875C72.8871 99.9632 73.0608 99.9018 73.217 99.807C73.3732 99.7122 73.5086 99.586 73.6147 99.4363C73.7209 99.2866 73.7956 99.1164 73.8342 98.9364C74.4177 96.618 70.8751 97.3346 65.4987 94.9318C61.6722 92.939 58.6871 89.614 57.094 85.5701C55.5008 81.5262 55.4069 77.0361 56.8297 72.9277C57.619 69.7482 59.1452 66.8043 61.2821 64.3395C63.419 61.8746 66.1053 59.9597 69.1189 58.7528C72.1325 57.546 75.3868 57.082 78.6128 57.3991C81.8388 57.7163 84.9438 58.8055 87.6712 60.5767C91.3984 63.1517 94.1367 66.9448 95.4268 71.3196C96.7168 75.6945 96.4801 80.3849 94.7564 84.6042C92.5892 89.9577 87.046 96.5758 81.1695 96.8287C79.169 96.8287 78.9606 101.339 86.0458 98.2619C91.2017 95.6067 95.3368 91.2909 97.7989 85.9953C100.112 80.9907 100.62 75.325 99.2345 69.9813C97.8489 64.6375 94.6573 59.953 90.2135 56.7407V56.7407Z"
                      fill="#4072B7"
                    />
                    <path
                      d="M79.1678 70.3161C81.2871 72.781 82.902 75.6465 83.919 78.7468C83.919 79.1158 84.0639 79.4696 84.3219 79.7305C84.5798 79.9913 84.9296 80.1379 85.2944 80.1379C85.6592 80.1379 86.009 79.9913 86.2669 79.7305C86.5249 79.4696 86.6698 79.1158 86.6698 78.7468C86.3859 76.2974 85.725 73.9079 84.7109 71.665C84.2108 70.6112 83.7106 69.726 83.0021 68.2928C82.4951 67.1647 81.7563 66.1588 80.8349 65.342C78.5843 63.7823 75.2501 65.342 73.2912 67.1125C70.7302 69.9395 68.7974 73.2885 67.6231 76.9342C67.543 77.0833 67.4946 77.2475 67.4807 77.4165C67.4668 77.5855 67.4879 77.7556 67.5425 77.916C67.5972 78.0763 67.6842 78.2234 67.7981 78.3479C67.912 78.4723 68.0502 78.5715 68.2041 78.639C68.3579 78.7065 68.5239 78.7409 68.6916 78.74C68.8593 78.7391 69.0249 78.703 69.178 78.6338C69.3311 78.5647 69.4683 78.464 69.5809 78.3384C69.6935 78.2127 69.779 78.0647 69.832 77.9038C71.1592 75.4585 72.9097 73.274 75 71.4543C75 72.5081 75 73.857 75 74.1521C74.3604 77.4976 74.192 80.9179 74.4999 84.3111C74.4375 86.1337 74.5773 87.9577 74.9167 89.7489C74.9774 90.0675 75.1609 90.3487 75.4266 90.5305C75.6924 90.7123 76.0187 90.7799 76.3337 90.7184C76.6487 90.6569 76.9267 90.4714 77.1065 90.2026C77.2862 89.9338 77.3531 89.6038 77.2923 89.2852C77.4945 87.7059 77.8576 86.152 78.3759 84.6483C79.1199 81.2736 79.289 77.7952 78.876 74.3629C78.4593 67.4919 78.2925 69.0515 79.1678 70.3161Z"
                      fill="#4072B7"
                    />
                    <path
                      d="M20.1537 21.7534C22.1876 22.4505 24.2802 22.9584 26.4053 23.2709C30.5503 23.8942 34.7637 23.8942 38.9086 23.2709C39.2734 23.2709 39.6232 23.1243 39.8812 22.8635C40.1391 22.6026 40.284 22.2488 40.284 21.8798C40.284 21.5109 40.1391 21.1571 39.8812 20.8962C39.6232 20.6353 39.2734 20.4888 38.9086 20.4888C34.8425 19.3343 30.627 18.8085 26.4053 18.9291C24.138 18.6979 21.848 18.898 19.6535 19.5192C19.1951 19.8565 18.9867 21.0368 20.1537 21.7534Z"
                      fill="#4072B7"
                    />
                    <path
                      d="M18.8224 38.1526C18.7239 38.4172 18.7159 38.7074 18.7996 38.9771C18.8833 39.2467 19.0539 39.4803 19.2842 39.6404C19.5144 39.8005 19.7911 39.878 20.07 39.8605C20.3489 39.8429 20.614 39.7314 20.8229 39.5437C24.7935 39.4649 28.7642 39.6904 32.7011 40.2182C42.5787 40.4711 49.3721 39.1643 56.7074 38.785C57.0364 38.7273 57.3346 38.554 57.5495 38.2956C57.7645 38.0373 57.8823 37.7105 57.8823 37.3728C57.8823 37.0352 57.7645 36.7084 57.5495 36.45C57.3346 36.1917 57.0364 36.0184 56.7074 35.9607C47.2075 35.1589 37.6581 35.1589 28.1582 35.9607C26.4077 36.2557 20.1561 35.9185 18.8224 38.1526Z"
                      fill="#4072B7"
                    />
                    <path
                      d="M43.9089 54.7602C37.0909 53.4732 30.0964 53.4732 23.2784 54.7602C19.9442 55.3082 19.4857 55.266 19.1106 55.6876C18.9082 55.837 18.7549 56.0446 18.6706 56.2833C18.5862 56.522 18.5748 56.7808 18.6377 57.0262C18.7006 57.2716 18.8349 57.4922 19.0233 57.6594C19.2117 57.8266 19.4454 57.9327 19.6941 57.9638C20.2352 58.0036 20.7785 58.0036 21.3195 57.9638C28.8509 59.0779 36.5025 59.0779 44.0339 57.9638C44.2419 57.9555 44.4462 57.9059 44.6352 57.8177C44.8242 57.7295 44.9942 57.6046 45.1355 57.45C45.2767 57.2954 45.3865 57.1141 45.4585 56.9166C45.5305 56.7191 45.5633 56.5091 45.5551 56.2988C45.5469 56.0884 45.4978 55.8818 45.4106 55.6906C45.3235 55.4994 45.1999 55.3275 45.0471 55.1846C44.8942 55.0418 44.715 54.9307 44.5197 54.8579C44.3244 54.7851 44.1168 54.7519 43.9089 54.7602V54.7602Z"
                      fill="#4072B7"
                    />
                    <path
                      d="M41.2847 72.8444C29.5732 72.8444 16.8199 71.2847 5.10843 69.9358C11.1934 54.6341 10.5682 32.7985 12.4854 8.39167C12.4854 7.97014 13.3189 6.62123 13.694 6.53692C17.8618 5.56739 37.4503 2.82741 39.9927 2.70095C41.8441 2.55245 43.7069 2.738 45.4941 3.24895C47.0362 3.62833 49.9953 -0.123332 39.7843 0.0031284C37.492 0.0452819 15.5278 1.98434 12.0686 2.91172C8.60935 3.8391 8.526 6.19969 7.48406 15.5578C5.5252 43.6741 5.35849 50.9667 3.31629 60.9149C1.27408 70.8632 -0.684772 71.0739 0.232138 72.6336C0.388726 72.9446 0.619216 73.2114 0.902917 73.41C1.18662 73.6087 1.51465 73.733 1.85757 73.7717C14.1525 74.7413 28.9064 75.9216 41.2847 75.6686C41.6136 75.611 41.9118 75.4377 42.1268 75.1793C42.3417 74.921 42.4596 74.5942 42.4596 74.2565C42.4596 73.9189 42.3417 73.5921 42.1268 73.3337C41.9118 73.0754 41.6136 72.9021 41.2847 72.8444V72.8444Z"
                      fill="#4072B7"
                    />
                    <path
                      d="M61.7465 13.1549C63.2469 14.5881 65.9143 16.4007 66.6228 17.9604L66.206 44.2642C66.2591 44.5548 66.4112 44.8173 66.6358 45.0064C66.8604 45.1954 67.1433 45.2989 67.4355 45.2989C67.7277 45.2989 68.0106 45.1954 68.2352 45.0064C68.4598 44.8173 68.6119 44.5548 68.665 44.2642C68.665 41.9879 72.8328 20.1524 70.7906 15.8527C69.3318 12.9863 69.0401 13.9558 54.703 1.77345C54.4073 1.58391 54.0527 1.51177 53.7076 1.57094C53.3625 1.6301 53.0512 1.81637 52.8338 2.09386C52.6164 2.37135 52.5082 2.72041 52.5301 3.07376C52.5521 3.42712 52.7025 3.75977 52.9525 4.00759L61.7465 13.1549Z"
                      fill="#4072B7"
                    />
                    <path
                      d="M47.0348 21.3321C49.202 25.5474 56.704 23.777 61.4553 22.4702C69.7908 20.1939 65.623 16.0629 63.9559 18.2549C59.8192 19.6492 55.394 19.9253 51.1192 19.0558C50.7664 16.162 50.879 13.2296 51.4526 10.3722C51.7861 5.77745 51.8277 5.86176 51.0775 5.60884C49.4938 4.93439 49.077 7.4636 48.4518 9.82419C47.6183 12.9435 45.1176 17.4961 47.0348 21.3321Z"
                      fill="#4072B7"
                    />
                    <path
                      d="M73.4197 8.47638C75.5477 10.1898 77.4772 12.1413 79.1712 14.2936L76.7123 45.1499C76.7123 46.7939 77.8376 47.4684 78.7128 46.1194C79.9631 42.3678 85.0061 15.1366 82.2971 11.2585C78.8507 7.41849 74.8788 4.09688 70.5023 1.3946C70.367 1.25149 70.2031 1.13924 70.0215 1.06535C69.8399 0.991459 69.6447 0.95763 69.4492 0.966125C69.2536 0.974619 69.0621 1.02524 68.8874 1.11461C68.7127 1.20397 68.5589 1.33002 68.4363 1.48433C68.3136 1.63864 68.2249 1.81765 68.1762 2.00939C68.1274 2.20114 68.1197 2.40121 68.1535 2.59622C68.1873 2.79123 68.2619 2.9767 68.3723 3.14022C68.4826 3.30374 68.6262 3.44154 68.7935 3.54442L73.4197 8.47638Z"
                      fill="#F6A405"
                    />
                  </svg>
                  <h3 class="text-gray font">Drag & Drop</h3>
                  <h5 class="text-gray font">
                    Your files here or
                    <a>Browse</a>
                    to upload
                  </h5>
                  <h5 class="text-gray font">
                    <a>Excel or CSV files Only</a> <br />
                    <img
                      v-if="arrayLength"
                      src="/success.png"
                      width="30"
                      height="30"
                      alt=""
                    />
                  </h5>
                </div>
                <div class="btn-box col-md-10">
                  <div class="downoald-file-btn-box">
                    <h3>
                      <a class="underline-text" @click="downloadExcel"
                        >Download File Template</a
                      >
                    </h3>
                  </div>
                </div>
              </div>
            </v-col>
            <div
              class="
                row
                gray-underline-wizard
                text-gray
                col-md-12
                text-end
                mt-0
                justify-end
              "
            >
              <div
                class="pointer mb-4 mr-7 pt-2 text-underline"
                style="color:#0572F3;"
                @click="$emit('closeDialog'), closeDialog()"
              >
                Cancel
              </div>
              <div
                class="ml-6 pointer mb-4 mb-7 pt-2 text-underline" 
                style="color:#0572F3;"
                @click="searchResultModal"
              >
                Proccess
              </div>
            </div>
          </div>
        </v-card>
      </div>
    </div>
      <div class="col-md-12" v-if="addNewPersonnalModal">
      <div class="white-background">
        <v-card>
          <div class="col-md-12 row blue-underline no-padding">
            <div class="col-xl-3 col-lg-3 col-md-3 mt-2">
              <h3 class="form-title font ml-2 font-18">Add & Invite a New User</h3>
            </div>
          </div>
          
        
          <div class="col-md-12 row no-padding mt-0 mb-0">
            <v-form  v-model.trim="valid" ref="form"  class="row">
            <v-col  class=" col-lg-6 col-xl-6 col-md-12 mb-10">
              <div class="col-md-12">
                   <div class="col-md-12 no-padding">
                    <div class="col-md-12 pb-0">
                      <label class="font text-gray" for="">First Name</label>
                      <v-text-field
                        required
                        :rules="requiredRules"
                        v-model.trim="firstName"
                        solo
                      ></v-text-field>
                    </div>
                  </div>
                     <div class="col-md-12 no-padding">
                    <div class="col-md-12 pb-0">
                      <label class="font text-gray" for="">Last Name</label>
                      <v-text-field
                        required
                        :rules="requiredRules"
                         v-model.trim="lastName"
                        solo
                      ></v-text-field>
                    </div>
                  </div>
                 <div class="col-md-12 no-padding">
                    <div class="col-md-12 pb-0">
                      <label class="font text-gray" for="">Email</label>
                      <v-text-field
                        required
                        v-model.trim="email"
                        solo
                        :rules="emailRules"
                      ></v-text-field>
                    </div>
                  </div>
                     <div class="col-md-12 no-padding">
                    <div class="col-md-12 pb-0">
                      <label class="font text-gray" for="">Phone</label>
                      <v-text-field
                        required
                         v-model.trim="phone"
                        solo
                        :rules="phoneRules"
                      ></v-text-field>
                    </div>
                  </div>
              </div>
            </v-col>
            <v-col  class="col-lg-6 col-xl-6 col-md-12 justify-center align-center">
             <div class="col-md-12">
                  <div class="col-md-12 mt-0 mb-0">
                    <label class="font text-gray" for="">Location(s)
                      <span class="small-text font-italic ml-3"
                      >(select one or more locations)</span
                    >
                    </label>
                    <v-select
                      multiple
                      :items="locs"
                      required
                      solo
                      filled
                      chips
                      v-model.trim="location"
                      item-text="name"
                      item-value="id"
                     
                    >
                    
                    </v-select>
                  </div>
                  <div class="col-md-12 pt-0 pb-0">
                    <label class="font text-gray" for="">Role</label>
                    <v-select
                    required
                     tabindex="5"
                     solo
                     :rules="requiredRules"
                     :items="roles"
                     v-model.trim="role"
                    >
                      <!-- <template #selection="selection">
                        <v-chip
                          @click="deleteItem(selection.item)"
                          v-text="selection.item"
                        ></v-chip>
                      </template> -->
                    </v-select>
                  </div>
                  <div class="col-md-12 pt-1 pb-0">
                    <label class="font text-gray" for="">Address</label>
                      <v-text-field
                        required
                        :rules="requiredRules"
                        v-model.trim="address"
                        solo
                      ></v-text-field>
                  </div>
                  <div class="row no-padding justify-space-between mt-3">
                  <div class="col-md-4 pt-0 pb-0">
                    <label class="font text-gray" for="">City</label>
                    <v-text-field
                      required
                      :rules="requiredRules"
                      v-model.trim="city"
                      solo
                    ></v-text-field>
                  </div>
                  <div class="col-md-4 pt-0 pb-0">
                    <label class="font text-gray" for="">State</label>
                    <v-select
                      required
                      :rules="requiredRules"
                      item-text="name"
                      item-value="value"
                      :items="states"
                      v-model.trim="state"
                      solo
                    ></v-select>
                  </div>
                  <div class="col-md-2 pt-0 pb-0">
                    <label class="font text-gray" for="">Zip</label>
                    <v-text-field
                      required
                      solo
                      v-model.trim="zip"
                      :rules="zipRules"
                    ></v-text-field>
                  </div>
              </div>
              </div>
            </v-col>
          </v-form>
            <div
              class="
                row
                gray-underline-wizard
                text-gray
                col-md-12
                text-end
                mt-0
                justify-end
              "
            >
              <div
                class="pointer mb-4 mr-7 pt-2 text-underline"
                style="color:#0572F3;"
                @click="$emit('closeDialog'), closeDialog()"
              >
                Cancel
              </div>
              <div
                class="ml-6 pointer mb-4 mb-7 pt-2 text-underline"
                style="color:#0572F3;"
                @click="saveAndInvite"
              >
                Save & Invite User
              </div>
            </div>
          </div>
        </v-card>
      </div>
    </div>
    <div class="col-md-12" v-if="searchModal">
      <div class="white-background border-r-8 mb-5">
        <v-card>
          <div class="col-md-12 row blue-underline mb-3 no-padding">
            <div class="col-xl-3 col-lg-3 col-md-3 mt-2">
              <h3 class="form-title font ml-2 font-18">
                Add & Invite a New User
              </h3>
            </div>
          </div>
        </v-card>
      </div>
    </div>
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <errorModal 
    ref="error_modal"
     v-on:confirm="closeErrorModal">
    </errorModal>

  </div>
</template>
<script>
import api from "../../../../services/api";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../TablesTemplate/ErrorDialog.vue";
import XLSX from "xlsx";
import { mapGetters } from "vuex";
import { mapState } from 'vuex';

export default {
  components: { successModal, errorModal},
  props: {
    id: {
      type: String,
    },
    locationPersonnel: {
      type: Array
    }
  },
  data() {
    return {
      valid: false,
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      locs: [],
      location: [],
      role: "",
      roles: [],
      address: "",
      city: "",
      states: [],
      state: "",
      zip: "",
      dialog: true,
      display: false,
      windowWidth: window.innerWidth,
      arrayLength: false,
      personnel: [],
      selectAll: false,
      selectedRows: [],
      personnelForAdding: [],
      filterItems: false,
      allRows: false,
      filterArray: [],
      searchModal: false,
      addNewModal: false,
      new: [],
      addNewPersonnalModal: false,
      zipRules: [
        (v) => (v && v.length == 5) || "Zip Code should be five digits long",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
      emailRules: [
        (v) => !!v || "This field is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
    };
  },
  computed: {
    ...mapGetters(["getLocationId"]),
    ...mapState({
      Personnel: state => state.DPCProviders.personnel
    }),
    ...mapState({
      Users: state => state.DPCProviders.users
    }),
    availableUsers(){
      let ids = this.locationPersonnel.map(item => item.id)
      return this.Users.filter(item => !ids.includes(item.id));
    }
  },
  created() {
    if (this.windowWidth < 1000) {
      this.display = false;
    } else {
      this.display = true;
    }

    this.getData();
    this.getStates();
    this.getLocations();
    this.getRoles();
    if(this.Users.length<=0){
      this.getUsers();
    }
    
  },
  methods: {
    getUsers(){
      api()
        .get(`/dpcproviders/users`)
        .then((res) => {
          this.$store.commit('setAllUsers', res.data)
          this.Users = res.data;
        });
    },
    getRoles() {
      api()
        .get("/roles")
        .then((res) => {
          res.data.forEach((el) => {
                  this.roles.push(el.name);
              });
        });
    },
    getLocations() {
      api()
        .get("/dpcproviders/locations")
        .then((res) => {
          res.data.forEach((el) => {
              this.locs.push(el);
          });
        });
    },
    getStates() {
      api()
        .get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },
    saveAndInvite(){
      this.$refs.form.validate();
      if (this.valid) {
        let data = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          role: this.role,
          address: this.address,
          city: this.city,
          state: this.state,
          zipCode: this.zip,
          locations: this.location,
        };
        api()
          .post(`/dpcproviders/users`, data)
          .catch((err) => {
                  if (err.response.status == 500) {
                    this.$refs.error_modal.openPopUp(
                      "There was an error, system admin is notified. Please try again later."
                    );}
                   else{
                    this.$refs.error_modal.openPopUp(err.response.data)
                   }
                  
                })
          .then((res) => {
            if (res) {
              let text = "Email sucessfully sent";
              this.$refs.success_modal.openPopUp(text);
              this.$emit("selectedPersonel", data);
            }
          });
      }
    },
    searchPersonel(val) {
      this.personnelForAdding = this.filterArray.filter((el) => {
        if (el && el.firstName && el.lastName) {
          let fullName = el.firstName + " " + el.lastName;
          let reversefullName = el.lastName + " " + el.firstName;
          if (fullName.toString().toLowerCase().includes(val.toLowerCase())) {
            return el;
          } else if (
            reversefullName.toString().toLowerCase().includes(val.toLowerCase())
          ) {
            return el;
          }
        } else if (
          el.firstName &&
          el.firstName.toString().toLowerCase().includes(val.toLowerCase())
        ) {
          return el.firstName
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el.lastName &&
          el.lastName.toString().toLowerCase().includes(val.toLowerCase())
        ) {
          return el.lastName
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        }
      });

      if (this.personnelForAdding.length < 1) {
        this.filterItems = true;
      } else {
        this.filterItems = false;
      }
    },
    goBack() {
      this.dialog = false;
      this.addNewModal = false;
      this.searchModal = false;
      this.$emit("hideOverlay");
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    closeSuccessModal() {
      this.dialog = false;
      this.addNewModal = false;
      this.addNewPersonnalModal = false;
      this.searchModal = false;
      this.$emit("hideOverlay");
      this.$refs.success_modal.closePopUp();
    },
    addData(type) {
      if (this.$route.params.id) {
        if (this.selectedRows.length > 0) {
          api()
            .post(`/dpcproviders/location/${this.$route.params.id}/personnel`, {"users":this.selectedRows.map((i)  => { return i.id})})
             .catch((err) => {
                  if (err.response.status == 500) {
                    this.$refs.error_modal.openPopUp(
                      "There was an error, system admin is notified. Please try again later."
                    );}
                   else{
                    this.$refs.error_modal.openPopUp(err.response.data)
                   }
                  
                })
            .then((res) => {
              if (res) {
                this.$refs.success_modal.openPopUp("Personnel Added successfully");
               this.$emit("selectedPersonel", this.selectedRows);
                  this.dialog = false;
                   this.addNewModal = false;
                  this.searchModal = false;
              }
            });
        }
      } else {
        this.$emit("selectedPersonel", this.selectedRows);
        this.dialog = false;
        this.addNewModal = false;
        this.searchModal = false;
      }
    },
    selectAllRows(type) {
      this.selectedRows = [];
      this.selectAll = !this.selectAll;
      if (this.selectAll) {
        if (type == "adding") {
          let tmp = JSON.stringify(this.personnelForAdding);
          this.selectedRows = JSON.parse(tmp);
        } else {
          let tmp = JSON.stringify(this.personnel);
          this.selectedRows = JSON.parse(tmp);
        }
      }
      if (!this.selectAll) {
        this.selectedRows = [];
      }
    },
    activeRow(index, item) {
      let itemById = document.getElementById("id" + index);
      itemById.classList.toggle("activeItem");

      if (itemById.classList.contains("activeItem")) {
        this.selectedRows.push(item);
      } else {
        let removeItem = this.selectedRows.find((x) => x._id === item._id);
        this.selectedRows.splice(this.selectedRows.indexOf(removeItem), 1);
      }
    },
    getData() {
      let url = "";
      if (this.getLocationId) {
        url = `/dpcproviders/users?locations=${this.getLocationId}`;
      } else {
        url = "/dpcproviders/users";
      }
      api()
        .get(url)
        .then((res) => {
          
          
          if (res.data.length > 0) {
            let user = localStorage.getItem("user");
            let role = "";
            if (user) {
              role = JSON.parse(user).groupPortal[0].role.name;
              if (role === "moderator") {
                this.personnel = res.data.filter((el) => {
                  return el.groupPortal[0].role.name == "admin";
                });

                this.personnelForAdding = this.personnel;
                this.filterArray = this.personnel;
              } else {
                this.personnel = res.data;
                this.personnelForAdding = res.data;
                this.filterArray = res.data;
              }
            }
          }
        });
    },
    deleteItem(item) {
      this.speciality = this.speciality.filter((find) => find !== item);
    },
    addItemExcel() {
      this.$refs.fileInput.click();
    },
    uploadExcel(e) {
      this.excelError = false;
      const files = e.target.files;
      if (!files.length) {
        return;
      } else if (!/\.(xls|xlsx|csv)$/.test(files[0].name.toLowerCase())) {
        return alert(
          "The upload format is incorrect. Please upload xls, xlsx or csv format"
        );
      }
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const workbook = XLSX.read(data, {
            type: "binary",
          });
          const wsname = workbook.SheetNames[0];
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]);
          const excellist = [];

          for (var i = 0; i < ws.length; i++) {
            if (ws[i].NPI) {
              excellist.push(ws[i].NPI);
              this.excelError = false;
              this.arrayExcel.push(ws[i].NPI);
              this.arrayLength = true;
            } else {
              this.excelError = true;
              this.arrayLength = false;
            }
          }
          if (this.arrayExcel.length < 1) {
            this.arrayLength = false;
            this.$refs.error_modal.openPopUp(
              "Something Went Wrong, Please Download The File Template First"
            );
          }
        } catch (e) {
          return alert("Read failure! Please Download the File Template.");
        }
      };

      fileReader.readAsBinaryString(files[0]);
    },
    downloadExcel() {
      const data = XLSX.utils.json_to_sheet(this.jsondata);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "providerList.xlsx");
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.searchModal = false;
      this.addNewModal = false;
      this.$emit("hideOverlay");
    },
    addSelected() {
      this.dialog = false;
    },

    addNewPersonnel() {
      this.dialog = false;
      this.searchModal = false;
      this.addNewPersonnalModal = true;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 1000) {
        this.display = false;
      } else {
        this.display = true;
      }
    },
  },
  
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>
<style scoped>
.wizard-locations-providers-absolute {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 8;
  width: 99%;
  margin-left: 0.5% !important;
  margin-top: 1%;
}
</style>
