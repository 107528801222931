<template>
 <div class="add-client-form">
    <h2>Prior Authorization Request New</h2>
    <div>
        <div class="row  mt-3">
            <div class="col-md-12 font text-gray font text-underline">
               Member Information
            </div>
            <div class="col-md-2 ">
                <label class="text-gray font" for="">First Name</label>
                <v-text-field solo></v-text-field>
            </div>
            <div class="col-md-2">
                <label class="text-gray font" for="">Last Name</label>
                <v-text-field solo></v-text-field>
            </div>
            <div class="col-md-2">
                <label class="text-gray font" for="">Member ID</label>
                <v-text-field solo></v-text-field>
            </div>
            <div class="col-md-1">
                <label class="text-gray font" for="">Sex</label>
                <v-text-field solo></v-text-field>
            </div>
            <div class="col-md-1">
                <label class="text-gray font" for="">Birth Month </label>
                <v-text-field solo></v-text-field>
            </div>
            <div class="col-md-1">
                <label class="text-gray font" for="">Day</label>
                <v-text-field solo></v-text-field>
            </div>
            <div   class="col-md-1">
                <label class="text-gray font" for="">Year</label>
                <v-text-field solo></v-text-field>
            </div>
            <div class="col-md-2">
                <label class="text-gray font" for="">Status On Plan</label>
                <v-text-field solo></v-text-field>
            </div>
        </div>
        <div class="row  mt-3">
            <div class="col-md-12 font text-gray font text-underline">
                Provider Information
            </div>
            <div class="col-md-3 ">
                <label class="text-gray font" for="">Organization / Provider Name</label>
                <v-select solo></v-select>
            </div>
            <div class="col-md-3">
                <label class="text-gray font" for="">Quality Rating</label>
                <v-rating
                    :value="rating"
                    color="yellow darken-3"
                    background-color="grey darken-1"
                    empty-icon="$ratingFull"
                    half-increments
                    hover
                ></v-rating>
            </div>
            <div class="col-md-3 font text-gray">
                City
                <br>
                Tampa
            </div>
            <div class="col-md-3  font text-gray">
                Status
                <br>
                Matched
            </div>
        </div>
        <div class="row  mt-3 ">
            <div class="col-md-12 font text-gray font text-underline">
               Procedure Information
            </div>
            <div class="col-md-2">
                <label class="text-gray font" for="">Procedure Code Type <i class="fas fa-question-circle  yellow-icons"></i></label>
                <v-select solo></v-select>
            </div>
            <div class="col-md-2">
                <label class="text-gray font" for="">Procedure Code <i class="fas fa-question-circle  yellow-icons"></i></label>
                <v-select  solo></v-select>
            </div>
            <div class="col-md-6">
                <label class="text-gray font" for="">Procedure Description <i class="fas fa-question-circle  yellow-icons"></i></label>
                <v-select solo></v-select>
            </div>
            <div class="col-md-2">
                <label class="text-gray font" for="">Est Procedure Cost (USD) <i class="fas fa-question-circle  yellow-icons"></i></label>
                <v-text-field solo></v-text-field>
            </div>
            <div class="col-md-12">
                <label class="text-gray font" for="">Comments</label>
                <v-text-field solo></v-text-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 font text-gray font text-underline">
               Procedure Prerequisite Requirements
            </div>
             <v-simple-table class="col-md-12">
                 <template v-slot:default>
                    <thead>
                        <tr>
                        <th class="text-left text-gray">
                            Name
                        </th>
                        <th class="text-left text-gray">
                            Category
                        </th>
                          <th class="text-left text-gray">
                            Code
                        </th>
                          <th class="text-left text-gray">
                            Description
                        </th>
                          <th class="text-left text-gray">
                            Prerequisite Met?
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="font">MRI Knee</td>
                            <td class="font">CPT</td>
                            <td class="font">G187</td>
                            <td class="font">CT Imaging of Knee</td>
                            <td class="font">
                               <input type="checkbox">
                               <span class="ml-2">Prerequisit Requirement Met</span>
                            </td>
                        </tr>
                         <tr>
                            <td class="font">14 Days of NSAIDs/td>
                            <td class="font">Medication</td>
                            <td class="font">None</td>
                            <td class="font">Pretreatment with anti-inflammatory</td>
                            <td class="font">
                                <input type="checkbox">
                                <span class="ml-2">Prerequisit Requirement Met</span>
                            </td>
                        </tr>
                    </tbody>
                 </template>
            </v-simple-table>
        </div>
        <div class="row justify-end">
            <v-btn class="blue-buttons" @click="$router.push(`/provider-advisor/providers/${routeParams}/prior-auth`)">Cancel</v-btn>
            <v-btn class="blue-buttons ml-5"  @click="$router.push(`/provider-advisor/providers/${routeParams}/prior-auth`)">Submit Request</v-btn>

        </div>
    </div>
 </div>
</template>
<script>
export default{
    data(){
        return{
            rating:3.5
        }
    },
    computed:{
      routeParams(){
          let parsed = this.$route.params.name.replace(/ /g, '%20')
          return parsed
      }
    }
}
</script>
