
<template>
  <div class="no-padding row-height font-bold">
    <v-row class="col-md-12">
      <v-col>
        <h3>
          <span class="font"> VNets</span>
        </h3>
      </v-col>
    </v-row>
    <div class="col-md-12">
      <div class="mt-7 text-underline">PENDING FEATURE</div>
      <div class="mt-3">
        <br />
        VNets (short for Virtual Networks), will enable you participate in VNets
        that have been created to more easily serve employer client’s on the
        platform.
        <br /><br />
        You could even use this VNet function as a way to expand your geographic
        coverage and service offering to employers with whom you have a direct
        relationship, by partnering with other providers outside your coverage
        area.
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      items: [
        {
          name: "Tampa General Hospital",
          contact: "James Reilly",
          type: "Multi Speciality",
          providers: "123",
          rating: 3,
          action: "",
        },
        {
          name: "National Ortha HVN",
          contact: "James Reilly",
          type: "Single Speciality",
          providers: "223",
          rating: 5,
          action: "",
        },
        {
          name: "National Cardiac Network",
          contact: "Jeff Hayes",
          type: "Single Speciality",
          providers: "212",
          rating: 3,
          action: "",
        },
        {
          name: "Florida HVN",
          contact: "Scot Pruzan",
          type: "Multi Speciality",
          providers: "88",
          rating: 4,
          action: "",
        },
        {
          name: "Northeast HVN",
          contact: "Beth Schultz",
          type: "Multi Speciality",
          providers: "195",
          rating: 5,
          action: "",
        },
      ],
    };
  },
  computed: {
    getClient() {
      return this.$store.getters.getClientGetter;
    },
    routeParams() {
      let parsed = "";
      if (this.$route.params.name) {
        parsed = this.$route.params.name.replace(/ /g, "%20");
      }
      return parsed;
    },
  },
  methods: {
    singleProvider(item) {
      this.$store.commit("setSingleNetwork", item);
      this.$router.push("/provider/networks/builder");
    },
  },
};
</script>