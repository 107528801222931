<template>
  <div class="form-div d-flex flex-column">
    <FullscreenLoader v-if="loader" />
    <v-flex xs12 sm12 md6 lg6 class="register-page-right">
      <div class="register-content-right">
        <div class="register-form-container">
          <v-form
            class="col-12 info-form"
            ref="form"
            v-model.trim="valid"
            @submit.prevent=""
          >
            <div class="center-content mb-8">
              <img class="apaly-logo-2" src="/svg/apalyBlue.svg" />
              <span class="register-header-text"> Change Password </span>
            </div>

            <v-row class="register-row">
              <v-col md="12" sm="12" class="pb-0" style="position: relative">
                <label for="userEmail" class="register-label">E-mail</label>
                <v-text-field
                  dense
                  placeholder="Enter company email"
                  type="email"
                  ref="emailField"
                  v-model.trim="email"
                  :rules="emailRules"
                  @blur="emailValidation"
                  class="apaly_text register-text-field"
                ></v-text-field>
                <div class="mb-2" style="position: absolute; bottom: 0">
                  <span
                    style="color: #ff5252; font-size: 12px"
                    v-if="emailErrorFromAPI"
                    >{{ emailErrorFromAPI }}</span
                  >
                </div>
              </v-col>
            </v-row>

            <div class="error-message">
                <span
                    style="color: #ff5252; font-size: 14px; width: 80%;"
                    v-if="reasonMessage">If an account with this e-mail exists, <br>you will receive a link to reset your password
                </span>
             </div>

            <v-row class="register-row">
              <v-col md="12" sm="12" class="pb-0">
                <v-btn
                  text
                  :class="!valid ? 'disabled-button no-transform' : 'primary-button no-transform'"
                  style="width: 100%"
                  @click="send()"
                  :disabled="!valid"
                >
                  Send password reset link
                </v-btn>
              </v-col>
              <v-col md="12" sm="12" class="pb-0">
                  <v-btn
                    text
                    outlined
                    class="secondary-button no-transform"
                    style="width: 100%"
                    @click="$router.push('/login')"
                  >
                    Go back to login
                  </v-btn>
                </v-col>
            </v-row>

            <div class="register-footer-left">
              <h3 class="tiny-font text-gray mobile-font" style="left: 0">
                Already have an account?
                <a @click="$router.push('/login')"><b>Log In</b></a>
              </h3>
            </div>
            <div class="register-footer-right">
              <h3 class="tiny-font text-gray mobile-font" style="left: 0">
                Are you a Plan Member?
                <a @click="goToMarketplace()"><b>Access Here!</b></a>
              </h3>
            </div>
          </v-form>
        </div>
      </div>
    </v-flex>

    <v-dialog v-model.trim="requestDialog" width="unset" class="success-modal" persistent>
       <v-card>
        <v-card-text class="pt-5">
          <span role="button" align="end" class="pt-0 pr-0">
            <p class="font-weight-bold" @click="$router.push('/login')">X</p>
          </span>
          <div class="display">
            <div class="text-center">
              <img class="check-icon" src="/svg/blueCheck.svg" />
            </div>
            <div class="password-changed-message">
              <span class="password-changed-title">
                Check your e-mail
              </span>
              <span class="password-changed-info">
                If an account with this e-mail exists, you will receive a link to reset your password.
              </span>
            </div>
          </div>

          <div class="mt-9 mb-3 d-flex align-right row-buttons">
            <v-btn
              text
              class="primary-b3-button no-transform"
              @click="$router.push('/login')"
            >
              Go to login page
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import api from "../../services/api";
import Vue from "vue";
import { mapActions } from "vuex";
import FullscreenLoader from "../Common/FullscreenLoader.vue";

export default {
  components: { FullscreenLoader },
  data() {
    return {
      requestDialog: false,
      reasonDialog: false,
      reasonMessage: "",
      email: "",
      changePassEmail: "",
      password: "",
      valid: false,
      validPass: "",
      show: false,
      error: "",
      icons: ["mdi-home", "mdi-email", "mdi-calendar", "mdi-delete"],
      padless: false,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
      passRules: [(v) => !!v || "Password is required"],
      passsError: "",
      error: "",
      loader: false,
      // rules: [v => v.length <= 5 || 'Max 5 characters'],
    };
  },
  computed: {
    domain() {
      return this.email.split("@").pop();
    },
  },
  created() {},
  methods: {
    ...mapActions(["loginUser"]),
    eventHandling(event) {
      if (event.keyCode == 13) {
        // this.submit();
        console.log("onEnter", event.keyCode);
        // this.send()
      }
    },
    lowerCaseEmail() {
      this.email = this.email.toLowerCase();
    },
    reason() {
      this.reasonDialog = true;
    },
    send() {
      // this.reasonDialog = true; this.reasonMessage = 'test'; return;
      this.reasonDialog = false;
      this.loader = true;
      api()
        .get(
          `https://validate.consislab.net/check/v4/${this.email.toLowerCase()}`
        )
        .catch((err) => {
          this.reasonDialog = true;
          this.reasonMessage = `This email is not reachable or does not exist`;
        })
        .then((res) => {
          // console.log('consislab', res)
          // this.reasonDialog = true; this.reasonMessage = 'test'; return;
          this.requestDialog = false;
          if (
            res &&
            res.data &&
            res.data.Response &&
            res.data.Response.result == "VALID"
          ) {
            api()
              .post(`auth/forgotPassword`, { email: this.email.toLowerCase() })
              .catch((err) => {
                this.reasonDialog = true;
                this.reasonMessage = `This email is not reachable or does not exist`;
              })
              .then((res) => {
                console.log(res);
                this.requestDialog = true;
              });
          } else {
            this.reasonDialog = true;
            this.reasonMessage = `This email is not reachable or does not exist`;
          }
        })
        .finally(() => {
            this.loader = false
        })
    },
    backToLogin() {
      this.$router.push("/login");
    },
  },
};
</script>
<style scoped>
.apaly-color {
  color: #16407a;
}
.display {
  display: grid;
}
.yes-btn {
  color: white;
}
.submit-btn {
  color: white;
  width: 150px;
}
.reset-btn {
  color: white;
}
.org {
  margin-top: 20vh;
}
.no-btn {
  width: 170px;
  border: 1px solid #16407a;
}
.color-black {
  color: black;
}
.desc {
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.register-page-right {
  height: 100vh;
  margin-top: -17vh;
}

.register-content-right {
  width: 55vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.register-form-container {
  width: 30vw;
}

.apaly-logo-2 {
  width: 13vh;
  margin-bottom: 1vh;
}

.register-header-text {
  font-size: 4.2vh;
  font-weight: bold;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.register-form-title {
  font-size: 5.5vh;
  padding-left: 7vw;
  padding-right: 7vw;
  padding-top: 1vh;
  line-height: 6vh;
  margin-bottom: 2.8vh;
  display: block;
  font-weight: bold;
  text-align: center;
}

.register-form-title-2 {
  font-size: 5.5vh;
  padding-left: 3vh;
  padding-right: 3vh;
  line-height: 6vh;
  margin-bottom: 6vh;
  display: block;
  font-weight: bold;
}

.register-form-subtitle {
  font-size: 2.2vh;
  margin-bottom: 5vh;
  display: block;
  color: var(--inactive-color);
  text-align: center;
}

.register-label {
  font-size: 2vh;
}

.register-row {
  height: 80px;
}

.login-line-break {
  margin-top: 2.5vh;
  margin-bottom: 4vh;
  background-color: var(--gray-color);
}

.login-footer-text {
  font-size: 2vh;
}

.error-message {
    margin-top: 2vh;
    margin-bottom: 2vh;
    text-align: center;
}

.password-changed-message {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--primary-color);
  padding-left: 1vh;
  padding-right: 1vh;
}

.password-changed-title {
  font-size: 2.5vh;
  font-weight: bold;
}

.password-changed-info {
  width: 45vh;
  margin-top: 2.5vh;
  font-size: 1.8vh;
  line-height: 2.2vh;
  font-weight: bold;
  text-align: center;
}

.check-icon {
  width: 3vw;
  margin-bottom: 2vh;
}

.no-transform {
  text-transform: none !important;
}

.primary-button {
  font-weight: bold;
}

.secondary-button {
  font-weight: bold;
}

.disabled-button {
  font-weight: bold;
}
</style>
