  const state = {
      clientUser:null,
      client:null
   }  
    
    const getters = {
        getClientUserGetter(state){
            return state.clientUser
        },
        getSingleClient(state){
            return state.client
        }
    }
    
    const mutations = {
        setSingleClientUser(state, clientUser){
            state.clientUser = clientUser
         },
         setSingleClient(state,client){
            state.client = client
         }
    }
    
    const actions = {
    }
    
    export default {
        state,
        getters,
        mutations,
        actions
    }
