<template>
<div>
  <!-- Fullscreen Loader -->
  <FullscreenLoader v-if="loader" />
  
  <v-dialog v-model="selectClinic"  v-else-if="!loader && !hideModal" persistent max-width="600">
    <v-card class="dialog_container_2">
      <!-- Close Button -->
        <v-card-title class="d-flex justify-end dialog_x">
          <v-icon style="float: right" role="button" @click="closeSelectClinic()" v-text="'mdi-close'"></v-icon>
        </v-card-title>

        <div class="dialog_content">
          <v-card-title class="dialog_title mb-5">Select Clinic Location</v-card-title>
          <v-card-subtitle class="dialog_sub" >Which clinic would you like to attribute this patient to?</v-card-subtitle >
          <div class="ce_checkName">
            <div class="dialog_label">Clinic Location</div>

            <!-- NEW SELECt -->
            <v-select
            ref="mySelect"
            v-model="selectedProvider"
            :items="employersList"
            placeholder="Type to search"
            item-value="id"
            item-text="companyName"
            return-object
            class="custom-v-select"
            outlined dense
            no-data-text="No clinic found"
            @click="focusSearchInput()"
            @change="selectedPatient()"
            v-click-outside="onClickOutside"
          >

            <template v-slot:prepend-item>
              <div class="custom_select_input_container">
                <input id="focusSearch" type="text" class="custom_select_input" v-model="searchTerm" placeholder="Search patient here" @input="searchingEmployers" @keyup.enter="noMember()"/>
              </div>
            </template>

            <!-- Show Selected Employer -->
            <template #selection="{ item }">
              {{ item.name }}
            </template>

            <!-- List of Employers -->
            <template #item="{ item }">
              <div class="ce_select_cont">
                <div class="ce_select_div">
                  {{ item.name }}
                </div>
                <div class="ce_gapLine"> - </div>
                <div class="ce_select_div ce_select_gray">
                  <!-- {{ item.address1 }}&nbsp;{{ item.address2 }}&nbsp;{{ item.city }}&nbsp;{{ item.state }}&nbsp;{{ item.zip }} -->
                  <!-- {{ item.address1 }}&nbsp;{{ item.address2 }}&nbsp;{{ item.city }}&nbsp;{{ item.state }}&nbsp;{{ item.zip }} -->
                  {{ item.city }},&nbsp;{{ item.state }}
                </div>
              </div>
            </template>

            <template #item-disabled="{ item }">
              <div class="ce_select_cont" >
                <div class="ce_select_div">
                  {{ item.name }}
                </div>
                <div class="ce_gapLine"> - </div>
                <div class="ce_select_div ce_select_gray">
                  <!-- {{ item.address1 }}&nbsp;{{ item.address2 }}&nbsp;{{ item.city }}&nbsp;{{ item.state }}&nbsp;{{ item.zip }} -->
                  <!-- {{ item.address1 }}&nbsp;{{ item.address2 }}&nbsp;{{ item.city }}&nbsp;{{ item.state }}&nbsp;{{ item.zip }} -->
                  {{ item.city }},&nbsp;{{ item.state }}
                </div>
              </div>
            </template>
            
          </v-select>

          <div class="ce_employersList_con" v-if="patientFound">
            <div class="ce_results_title">Results</div>
            <div class="ce_select_cont" v-for="(item, i) in employersListListResults" :key="`i`+i">
              <div class="ce_select_div ce_select_gray">
                {{ item.name }}
              </div>
              <div class="ce_gapLine"> - </div>
              <div class="ce_select_div ce_select_gray">
                {{ item.location }}
              </div>
            </div>
            <!-- {{ employersListListResults }} -->
          </div>

          </div>

          <!-- Button -->
          <div class="dialog_buttons">
            <v-btn class="dialog_single_button dialog_btn_primary" @click="registerPatient()" :disabled="!selectedProvider">
              continue
            </v-btn>
          </div>

        </div>

    </v-card>
  </v-dialog>

  <!-- Eligibility Successful -->
  <v-dialog v-model="successAttribution" width="500px" persistent>
    <v-card class="dialog_layout">
      <v-card-title class="d-flex justify-end">
        <v-icon role="button" @click="closeModals()" v-text="'mdi-close'" slot="end"></v-icon>
      </v-card-title>
      <v-card-text class="dialog_content">
        <div class="dialog_title dialog_primary">
          Success! Patient successfully attributed to your organization. You can add additional contact information to their profile 
        </div>
        <!-- <div class="dialog_subtext dialog_second">
          Sub text here 
        </div> -->
        <div class="dialog_buttons">
          <button class="dialog_single_button dialog_btn_primary" @click="openProfile()">
            View patient profile
          </button>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>

  <!-- Provider is not offering pediatric services -->
  <v-dialog v-model="notOfferingPedia" width="500px" persistent>
    <v-card class="dialog_layout">
      <v-card-title class="d-flex justify-end">
        <v-icon role="button" @click="closeModals()" v-text="'mdi-close'" slot="end"></v-icon>
      </v-card-title>
      <v-card-text class="dialog_content">
        <div class="dialog_title dialog_error">
          Pediatric services not offered at this location
        </div>
        <div class="dialog_subtext dialog_second mt-3">
          The selected clinic does not offer pediatric services. Please select another clinic location or enable the pediatrics services under the locations tab to attribute this patient.
        </div>
        <div class="dialog_buttons">
          <button class="dialog_single_button dialog_btn_primary" @click="reOpenClinicList()">
            Okay
          </button>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>

  <!-- Generic Error Modal -->
  <v-dialog v-model="genError" width="500px" persistent>
    <v-card class="dialog_layout">
      <v-card-title class="d-flex justify-end">
        <v-icon role="button" @click="genError = false" v-text="'mdi-close'" slot="end"></v-icon>
      </v-card-title>
      <v-card-text class="dialog_content">
        <div class="dialog_title dialog_error text-center">
          {{ genErrorMess ? genErrorMess:'Oops. Something went wrong' }}
        </div>
        <div class="dialog_buttons">
          <button class="dialog_single_button dialog_btn_primary" @click="closeModals()" >
            Okay
          </button>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>

</div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import FullscreenLoader from "../../../Common/FullscreenLoader.vue";
import api from "../../../../services/api";
// import api from "../../../../services/api";

export default {
  components: {
    FullscreenLoader,
  },
  props: {
    selectClinic: {
      type: Boolean,
    },
    patientInfo: {
      type: Object,
    }
  },
  data() {
    return {
      notOfferingPedia: false,
      successAttribution: false,
      genError: false,
      genErrorMess: "",
      loader: false,
      memberId: "",
      hideModal: false,
      noMemberFound: false,
      searchTerm: '',
      selectedProvider: null, // selectedEmployerInfo
      employersList: [], // employers
      searchEmp: "",
      employersCopy: [], // employersCopy
      allEmployers: [], // allEmployers
      employersListListResults: [],
      patientFound: false,
    }
  },
  computed: {
    returnFormatedDate() {
      return moment(this.newMember.dob).format("MM/DD/YYYY");
    },
    ...mapState({
      checkEligibilityPatientInfo: (state) => state.patientInfo,
      setAvailbaleLocations: (state) => state.setAvailbaleLocations,
    }),
  },
  created() {
    // var testData = [];
    // // Test location
    // // for(var i=0; i <= 5; i++) {
    // //   testData.push(
    // //     {
    // //       id: `clinic_${i}`,
    // //       name: `Test Clinic No.${i}`,
    // //       location: `location_${i}`
    // //     }
    // //   );
    // // }

    // this.employersList = testData;
    // this.employersCopy = testData;
    // this.allEmployers = testData;

    // Get Locations
    this.getLocations();
  },
  methods: {
  reOpenClinicList() {
    this.hideModal = false;
    this.notOfferingPedia = false;
  },
    closeModals() {
      this.hideModal = false;
      this.loader = false;
      this.genError = false;
      this.successAttribution = false;
      this.notOfferingPedia = false;

      // Self Modal
      this.closeSelectClinic();
    },
    openProfile(memberID) {
      // console.log("Open Member Profile");
      if(memberID) {
        // console.log("Member ID 1: ", memberID);
        this.$router.push("/dpc-providers/members/member/"+memberID).then(() => {
          window.location.reload();
        });
      }
      else if(this.memberId) {
        // console.log("Member ID 2: ", this.memberId);
        this.$router.push("/dpc-providers/members/member/"+this.memberId).then(() => {
          window.location.reload();
        });
      }
      else {
        // console.log("No member with ID: " + memberID);
      }
    },
    registerPatient() {
      // console.log("Register Patient");
      // Loader
      this.loader = true;

      // get eligibility id from store
      if(this.checkEligibilityPatientInfo?.eligibility) {
        // subscribers.memberId = this.checkEligibilityPatientInfo.subscriberMemberId;
        // console.log("Eligibility ID: ", this.checkEligibilityPatientInfo.eligibility);

        // check if selectedProvider is not empty
        if(this.selectedProvider && this.selectedProvider.id) {
          // console.log("Selected Provider: ", this.selectedProvider);

          let params = {
            location: this.selectedProvider.id, // Selected provider id
            eligibility: this.checkEligibilityPatientInfo.eligibility // From store
          }

          // console.log("Patient info: ", this.checkEligibilityPatientInfo.eligibility);
          // console.log("Params: ", params);

          api()
          .post("/dpcproviders/register/member", params)
          .catch((err) => {
            this.hideModal = true;
            this.loader = false;
            if (err) {

              if(err.response.status == 403) {
                this.notOfferingPedia = true; // Not offering pediatric services
              }
              else {
                this.hideModal = true; // Hide main modal (select clinic)
                this.loader = false; // Hide Loader
                this.genError = true; // Show Generic Error Modal
                this.genErrorMess = err.response.data ? err.response.data.messsage ? err.response.data.messsage  : err.response : err.response;
                // console.log("registerPatient() Error: ", this.genErrorMess);
              }
            }
          })
          .then((res) => {
            this.hideModal = true;
            this.loader = false;

            // console.log("res.data", res?.data);
            if(res.data) {
              // Success modal
              this.successAttribution = true;

              // Just incase
              this.memberId = res?.data?.member ? res?.data?.member : "";

              // console.log("Message: ", res?.data?.messsage);
              // console.log("Member ID: ", res?.data?.member);

              // Open member data
              // this.openProfile(res.data.member);

              // Clear patient info frmo store
              this.$store.commit("setPatientInfo", "");
            }
          });
        }
        else {
          // console.log("No selected provider: ", this.selectedProvider);
        }
      }
      else {
        // console.log("Missing eligibility object ID");
        this.hideModal = true; // Hide main modal (select clinic)
        this.genError = true; // Show Generic Error Modal
        this.genErrorMess = "Oops! Something went wrong...";
      }
      this.loader = false; // Hide Loader
    },
    noMember() {
      // Close select
      this.$refs.mySelect.isMenuActive = false;
      // If no members found
      if(this.employersList.length < 1) {
        // this.searchTerm = "";
        this.patientFound = false;
        // console.log("No member");
        this.closeSelectClinic();
        this.noMemberFound = true;
      }
      else {
        this.patientFound = true;
      }

      if(this.searchTerm == "") {
        this.patientFound = false;
      }
      // console.log("patientFound", this.patientFound);
    },
    onClickOutside (event) {
      // console.log('Clicked outside. Event: ', event)
      // this.searchTerm = "";
      // if(this.employers.length < 1) {
      //   // this.searchTerm = "";
      //   this.patientFound = false;
      //   console.log("No member");
      //   this.closeCheckPatientModal();
      // }
    },
    focusSearchInput() {
      setTimeout(() => {
        document.getElementById("focusSearch").focus();
      }, 100);
    },
    searchingEmployers(e) { // searchingEmployers
      // console.log("searchingEmployers", this.searchTerm);
      if (!this.searchTerm) {
        this.employersCopy = this.employersList;
      }

      this.employersList = this.employersCopy.filter((data) => {
        // console.log(data.firstName.toLowerCase().includes(this.searchTerm.toLowerCase()));
        return data.name.toLowerCase().includes(this.searchTerm.toLowerCase());
      });

      // Store search results
      this.employersListListResults = this.employersList;

      if(this.searchTerm == '') {
        this.patientFound = false;
        this.employersList = this.allEmployers;
        this.employersCopy = this.allEmployers;
      }
    },
    selectedPatient() { // selectedEmployer
      // Selecting through list
      // alert();
      // console.log("Selected Provider: ", this.selectedProvider);
      this.employersList = this.allEmployers;
      this.employersCopy = this.allEmployers;
    },
    getLocations() {
      this.loader = true;
      
      api()
        .get("/dpcproviders/locations")
        .then((res) => {
          this.loader = false;
          // console.log("res.data: ", res.data);

          // this.employersList = res.data;
          // this.employersCopy = res.data;
          // this.allEmployers = res.data;

          // console.log("Get available locations from store: ", this.setAvailbaleLocations);
          if(this.setAvailbaleLocations) {
            const locations = this.setAvailbaleLocations;
            let newLocations = [];
            let places = [];

            // push to places
            for(var i=0; i < locations.length; i++) {
              places.push({
                id: locations[i]
              });
              ;
            }

            // Find the matching available location id from the res.data
            for (const place of places) {
                const matchedData = res.data.find(data => data.id === place.id);
                if (matchedData) {
                    // If matching, then push to newLocations
                    newLocations.push(matchedData);
                }
            }

            // console.log("locations", locations);
            // console.log("places", places);
            // console.log("newLocations", newLocations);

            this.employersList = newLocations;
            this.employersCopy = newLocations;
            this.allEmployers = newLocations;
          }
          else {
            console.log("No available locations for this patient");
          }
        });

      
    },
    // getEmployers() {
    //   console.log("Get Employers");
    //   api()
    //     .get(`/dpcproviders/employers/active`)
    //     .then((res) => {
    //       if (res?.data) {
    //         console.log("getEmployers: ", res.data);
    //         this.employersList = res.data;
    //         this.employersCopy = res.data;
    //         this.allEmployers = res.data;
            
    //         let count = this.employersList.filter(employer => {
    //           return employer.payers && employer.payers.length > 1;
    //         }).length;

    //         let emp = this.employersList.filter(employer => {
    //           return employer.payers && employer.payers.length > 1;
    //         });

    //         console.log("Count of employers with 2 or more payers: ", count);
    //         console.log("Employers with 2 or more payers: ", emp);
    //       }
    //       this.loader = false;
    //     })
    //     .catch((err) => {
    //       this.loader = false;
    //       console.log("Error - getEmployers:", err);
    //     });
      
    // },
    closeSelectClinic() {
      // Unhide the hideCheckEligibility
      this.$store.commit("closeTheCheckEligibility", false);

      // Unhide the Subscriber's Info modal
      this.$store.commit("closeSubscriberInfoModal", false);

      this.$emit("closeSelectClinic");
    },
  }
}
</script>

<style>

</style>