<template>
    <div>
        <!-- <v-overlay v-if="loader">
            <v-progress-circular indeterminate :size="100" color="#326ab3" class="text-center mt-10 mb-5">
                <img
                  src="/newapalylogoonlyblue.png"
                  height="70"
                  class="img-mobile"
                />
            </v-progress-circular>

        </v-overlay> -->
        <!-- Fullscreen Loader -->
        <FullscreenLoader v-if="loader" />

        <div v-else>
            <div class="col-12 mr-1 blue-bg ">
                <div class="text--white font-12 font-weight-400 pl-8 col-12 mb-0 pb-0" style="opacity: 60%">
                    Home / Marketplace
                </div>
                <div class="col-12">
                    <div class="pa-md-4 pa-0 pt-0  text-mobile-p">
                        <!-- <span class="font-36 font-weight-500" style="color: white" >Find your personal doctor &nbsp; {{ didFilter }}</span> -->
                        <span class="font-36 font-weight-500" style="color: white">Apaly Marketplace</span>
                        <br />
                        <span class="font-20 font-weight-500" style="color: white">
                          Participate in solutions to start providing care to thousands of plan members 
                        </span>
                    </div>
                    <div class="col-12 pt-0 search-inputs emp-style">
                        <span class="col-12 col-lg-12 col-xl-12 col-md-12 d-md-flex pa-0 pt-0 mt-0">
                            <span class="mask pa-1 col-md-3">
                                <v-text-field
                    prepend-inner-icon="mdi-magnify"
                    v-model="searchQuery"
                    class="mr-3"
                    light
                    dense
                    outlined
                    hide-details
                    placeholder="Search by Name or Specialty"
                    style="
                      border-radius: 8px;
                      font-size: 14px;
                      background-color: white;"
                  ></v-text-field>
                            </span>
                            <!-- <span class="pa-1 col-md-3 col-12 d-flex emp-style">
                                <v-text-field
                    prepend-inner-icon="mdi-map-marker"
                    v-model="zipOrState"
                    @keyup.enter="searchByZip"
                    class="mr-3"
                    light
                    dense
                    outlined
                    hide-details
                    placeholder="Search by Zip Code"
                    style="
                      border-radius: 8px;
                      font-size: 14px;
                      background-color: white;"
                  ></v-text-field>
                                <v-btn @click="searchByZip()" color="#0069F3" size="x-small" class="text-white"
                                    style="border-radius: 8px; width: 30px">
                                    <v-icon dark color="white"> mdi-magnify </v-icon>
                                </v-btn>
                            </span> -->
                        </span>

                    </div>
                </div>
            </div>
            <div>
              <v-card height="auto" class=" row-height mt-2">
                  <div class="col-12 font-36 font-weight-400 mb-0 pb-0 mt-2">
                    Virtual Networks
                  </div>
                    <!-- <div class="col-md-12 col-lg-12 col-xl-12 col-8 pb-0 mb-0 pt-0 mt-0 d-flex">
          <span class="col-8 text-start">
            <v-sheet max-width="500">
              <v-slide-group show-arrows>
                <v-slide-item
                  v-for="(category, categoryIndex) in selectedCategories"
                  :key="'category-' + categoryIndex"
                >
                  <v-chip closable @click="removeChip(category, selectedCategories)" class="mr-1">
                    {{ category }}
                    <v-icon right> close </v-icon>
                  </v-chip>
                </v-slide-item>
                <v-slide-item
                v-for="(crendential, credentialIndex) in selectedCredentials"
                :key="'crendential-' + credentialIndex"
              >
                <v-chip closable @click="removeChip(crendential,selectedCredentials)" class="mr-1">
                  {{ crendential }}
                  <v-icon right> close </v-icon>
                </v-chip>
              </v-slide-item>
              <v-slide-item
              v-for="(gender, genderIndex) in selectedGenders"
              :key="'gender-' + genderIndex"
            >
              <v-chip closable @click="removeChip(gender,selectedGenders)" class="mr-1">
                {{ gender == 'F' ? 'Female' : 'Male' }}
                <v-icon right> close </v-icon>
              </v-chip>
            </v-slide-item>
            <v-slide-item
            v-for="(group, groupIndex) in selectedGroupAndOrganizations"
            :key="'group-' + groupIndex"
          >
            <v-chip closable @click="removeChip(group,selectedGroupAndOrganizations)" class="mr-1">
              {{ group }}
              <v-icon right> close </v-icon>
            </v-chip>
          </v-slide-item>
          <v-slide-item
            v-for="(speciality, specialityIndex) in selectedSpecialities"
            :key="'speciality-' + specialityIndex"
          >
            <v-chip closable @click="removeChip(speciality,selectedSpecialities)" class="mr-1">
              {{ speciality }}
              <v-icon right> close </v-icon>
            </v-chip>
          </v-slide-item>
              </v-slide-group>
            </v-sheet>
          </span>

          <span class="col-4 text-end">
            <v-btn
              @click.stop="drawer = !drawer"
              color="#F26621"
              elevation="3"
              outlined
              class="radius-8"
            >
              Filter
              <img class="ml-1" src='/svg/filter.svg' />
            </v-btn>
          </span>
        </div> -->
                  <v-navigation-drawer
                    class="mobile-drawer"
                    :value="drawer"
                    temporary
                    touchless
                    :right="!$vuetify.rtl"
                    width="350"
                    app
                  >
                  <v-card height="100%" class="mt-12">
                    <div class="drawer-header d-flex align-center mb-0 mt-0">
                      <span class="font-20">Filter solutions</span>
                      <v-btn icon small @click="drawer = !drawer">
                        <v-icon right color="white" class="mr-1 ml-0"> mdi-close </v-icon>
                      </v-btn>
                    </div>
                    <v-card-text class="pt-5 content-height" style="overflow:scroll">
                      <v-expansion-panels
                      accordion
                      multiple
                      v-model.trim="panel"
                      class="no-shadow no-padding user-expansion-panels"
                    >
                      <v-expansion-panel class="no-shadow no-padding">
                        <v-expansion-panel-header
                          class="font-18 font-weight-black change-padding-filter filter-items-font"
                          >Type</v-expansion-panel-header
                        >
                        <v-expansion-panel-content class="no-padding content-filter">
                          <v-checkbox
                          class="checkbox-label-size pa-0"
                          hide-details
                            v-for="category in categories"
                            :key="category.value"
                            v-model="selectedCategories"
                            :label="category.label"
                            :value="category.value"
                          >
                          </v-checkbox>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel class="no-shadow no-padding">
                        <v-expansion-panel-header
                          class="font-18 font-weight-black change-padding-filter filter-items-font"
                          >Provider</v-expansion-panel-header
                        >
                        <v-expansion-panel-content class="no-padding content-filter">
                          <h4 class="text-start mb-2">Credentials</h4>
                          <div class="pa-0 ma-0 mb-3 scrollable-creds">
                            <v-checkbox
                            class="checkbox-label-size pa-0"
                            hide-details
                              v-for="(credentials, i) in providerCredentials"
                              :key="i"
                              v-model="selectedCredentials"
                              :label="credentials"
                              :value="credentials"
                            >
                            </v-checkbox>
                          </div>
                          <v-divider horizontal> </v-divider>
                          <h4 class="text-start mb-2">Gender</h4>
                          <div class="d-flex justify-space-between pb-0 mb-0">
                            <p class="mb-0">Female</p>
                            <v-switch  lass="mb-0" v-model="selectedGenders" value="F"> </v-switch>
                          </div>
                          <div class="d-flex justify-space-between">
                            <p>Male</p>
                            <v-switch  v-model="selectedGenders" value="M"> </v-switch>
                          </div>
                          <h4 class="text-start mb-2">Specialty</h4>
                          <div class="pa-0 ma-0 mb-3 scrollable-creds">
                            <v-checkbox
                            class="checkbox-label-size pa-0"
                            hide-details
                              v-for="(speciality, ind) in specialities"
                              :key="ind"
                              v-model="selectedSpecialities"
                              :label="speciality"
                              :value="speciality"
                            >
                            </v-checkbox>
                          </div>
                          <v-divider horizontal> </v-divider>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel class="no-shadow no-padding">
                        <v-expansion-panel-header
                          class="font-18 font-weight-black change-padding-filter filter-items-font"
                          >Group or Organization</v-expansion-panel-header
                        >
                        <v-expansion-panel-content class="no-padding content-filter">
                          <div class="pa-0 ma-0 mb-3 scrollable-creds">
                            <v-checkbox
                            class="checkbox-label-size pa-0"
                            hide-details
                              v-for="(grAndOrg, indx) in groupAndOrganizations"
                              :key="indx"
                              v-model="selectedGroupAndOrganizations"
                              :label="grAndOrg"
                              :value="grAndOrg"
                            >
                            </v-checkbox>
                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                    </v-card-text>  
                      <v-divider horizontal class="mt-2"></v-divider>
                    <v-list-item>
                    
                      <v-list-item-content class="justify-center">
                        <v-list-item-title class="d-flex justify-center">
                          <a class="mr-5 mt-3 font-14 font-weight-500" color="#0069F3" @click="clearSearch()"> Clear All </a>
                          <v-btn class="font-14" style="color:white;" color="#0069F3" @click="searchResults()"> View results </v-btn>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                  </v-navigation-drawer>
                  <div v-if="!noResultsFromSearch">
                      <div v-for="(item, categoryI) in filteredCategories" :key="categoryI" class="mt-0" v-show="item.category === 'Direct Primary Care'">

                        <!-- TITLE -->
                          <v-card-title class="font-24 font-weight-400 mb-2 mt-2">
                              <!-- {{ item.category }} -->
                              Advanced Primary Care
                              <span class="pl-3 subtitle-link">
                                  <small>
                                    <a class="font-14 font-weight-600 text-underline" @click="$router.push(`/dpc-providers/solutions/all/${item.category}`)">
                                        View all
                                    </a>
                                  </small>
                              </span>
                          </v-card-title>

                          <!-- CARD CONTENTS: SLIDE -->
                          <!-- FOR NOW< DO NOT SHOW APC -->
                          <v-slide-group class="pa-4 pt-0 pb-0" active-class="success" show-arrows>
                              <v-slide-item v-for="(card, itemIndex) in item.items" :key="itemIndex">
                                  <v-card height="180px" width="316px" :style="resolveMarketplaceBackgroundColor(card)"
                                      class="mr-3 pa-2 mb-4 mt-4 cursor-pointer box-hover radius-8">
                                      <div class="pl-1 pr-1 pb-0" @click="goToDetails(card, card.entity)">
                                          <div class="d-flex">
                                              <div v-if="card.logo" class="text-start justify-start mb-1 mt-0 card-logo"
                                                  :style="`background-image:url(${card.logo})`"></div>
                                              <div v-else class="text-start justify-start mb-1 mt-0 card-logo"
                                                  :style="`background-image:url(${returnCardImage(card.entity)})`"></div>
                                              <div class="text-start mt-0 ml-2 text-ellipsis-card-name">
                                                  <p class="font-20 font-weight-600 mb-1 mt-2" style="color: #333333">
                                                      {{ card.name }}
                                                  </p>
                                              </div>
                                          </div>

                                          <div class="text-start d-flex card_icon_text">
                                              <img class="mr-2 card_icon" src="/svg/advancedPrimaryCare.svg" />
                                              <!-- <p class="ma-0 font-14"> {{ card.category }}</p> -->
                                              <p class="ma-0 font-14"> Advanced Primary Care </p>
                                          </div>
                                          <div>
                                            {{ card.participation }}
                                          </div>
                                          <!-- <div class="text-start d-flex card_icon_text">
                                              <img class="mr-2 card_icon" src="/svg/activeMembers.svg" />
                                              <p class="ma-0 font-14"> 0 active members</p>
                                          </div> -->
                                          <div class="">
                                              <div class="d-flex justify-space-between col-12 pl-0 pr-0">

                                                  <!-- <v-btn v-if="card.contracted && card.participation.status == 'Active' && card.type != 'Organization' " class="radius-8" height="32" disabled outlined color="#999999">
                                                      Participating
                                                  </v-btn>
                                                  <v-btn v-if="card.contracted && card.participation.status == 'Pending Approval' && card.type != 'Organization' " class="radius-8" height="32" disabled outlined color="#999999">
                                                      Pending Approval
                                                  </v-btn> -->
                                                  <v-btn v-if="card.contracted && card.status === 'Pending Approval' || card.status === 'Pending'" class="radius-8" height="32" disabled outlined color="#999999" style="width: 48%;">
                                                      Pending 
                                                  </v-btn>
                                                  <v-btn v-else-if="card.contracted && card.type != 'Organization' && card.status !== 'Denied'" class="radius-8" height="32" disabled outlined color="#999999" style="width: 48%;">
                                                      Active
                                                  </v-btn>
                                                  <v-btn v-else-if="(!card.contracted && card.type != 'Organization') || card.status === 'Denied'" class="radius-8 font-14" height="32" outlined color="#1E7BF4" style="width: 48%;" @click="goToDetails(card, card.entity)">
                                                      Participate
                                                  </v-btn>
                                                  <v-btn class="radius-8" :class="card.type != 'Organization' ? 'ml-auto' : ''" height="32" outlined color="#1E7BF4" @click="goToDetails(card, card.entity)">
                                                      View details
                                                  </v-btn>

                                              </div>
                                          </div>
                                      </div>
                                  </v-card>
                              </v-slide-item>
                          </v-slide-group>
                          <v-divider horizontal class="mb-2"></v-divider>
                      </div>
                  </div>
                  <div v-else>
                      <v-alert border="bottom" colored-border color="primary" elevation="2"
                          class="col-2 text-gray mt-2 ml-2">
                          No results found! <a @click="clearSearch()" class="text-underline">Reset</a>
                      </v-alert>
                  </div>
                </v-card>
            </div>

            <router-view></router-view>
        </div>



    </div>
</template>

<script>
var _paq = window._paq || [];
_paq.push(["trackPageView"]);
import api from "../../../../services/api";
//   import AllMarketplaceItems from "./AllMarketplaceItems.vue";
import axios from "axios";
import { mapState } from "vuex";
import FullscreenLoader from "../../../Common/FullscreenLoader.vue";


export default {
    components: {
      FullscreenLoader
        //   AllMarketplaceItems,
        //   MySolutions,
        //   errorModal,
    },
    data() {
        return {
            searchQuery: "",
            zipOrState: "",
            marketplace: [],
            didFilter: false,
            validEmail: false,
            emailRules: [
                (v) => !!v || "This field is required",
                (v) =>
                    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) ||
                    "Please enter a valid e-mail",
            ],
            tickLabels: [
                "0",
                10,
                15,
                20,
                25,
                30,
                35,
                40,
                45,
                50,
            ],
            noEmail: false,
            searchByName: "",
            searchDistance: 0,
            notifyMe: false,
            addedToWaitlist: false,
            addToNotifyNearClinic: false,
            allowApalyToContact: false,
            selectedLocationForWaitlist: "",
            unregisteredEmail: "",
            provider: [],
            somethingWrong: false,
            infoOptions: {
                disableAutoPan: true,
            },
            // alreadyHaveProvider: false,
            notifyMe: false,
            errorMessage: "",
            selectingProvider: false,
            dependnetErrorModal: false,
            cannotEnrollModal: false,
            dependnetAllSetModal: false,
            autocomplete: null,
            showMap: true,
            urlLocation: null,
            urlCompany: null,
            solutions: "all_solutions",
            tab: "clinics",
            tab: "clinics",
            searchQuery: "",
            zipOrState: "",
            employer: "",
            loader: false,
            noResultsFromSearch: false,
            // marketplace: [],
            // clinicians: [],
            // clinics: [],
            items: [],
            tabTitle: "Direct Primary Care",
            showAllCredentials: false,
            showAllSpecialities: false,
            showAllGroups: false,
            panel: [0, 2],
            drawer: false,
            selectedCategories: [],
            selectedCredentials: [],
            selectedSpecialities: [],
            selectedGroupAndOrganizations: [],
            selectedGenders: [],
            searchByCategory: [],
            providerCredentials: [],
            slicedProviderCredentials: [],
            specialities: [],
            slicedSpecialities: [],
            groupAndOrganizations: [],
            slicedGroupAndOrganizations: [],
            categories: [
        { label: "Virtual Networks", value: "virtualNetwork" },
        { label: " Point Solution Vendors", value: "vendors" },
        { label: " Clinics & Organizations", value: "clinics" },
        { label: "Clinicians ", value: "clinicians" },
      ],
            newPatients: false,
            pediatrics: false,
            findSpeciality: "",
            searchCredentials: "",
            searchGroupOrOrganization: "",
            serviceTypeCodes: [],
            selectedServiceTypeCodes: [],
            changeFilterHeightFor700: false,
            changeFilterHeightFor800: false,
            itemPerPage: 6,
            pageNumber: 1,
            Ip: null,
            hasNextPage: false,
            currentPage: 1,
            pagesToShow: 4,

            infoWindowOpened: false,
            infoWindowPosition: null,
            infoWindowContent: {
                name: "",
                description: "",
            },
            selectedPlace: null,
            centerData: {
                name: "Struga",
                description: "your address ",
            },
            card: null,
            card2: null,
            showUser: true,
            fromSearch: false,
            // searchBy: 'normal', // T.N. used in pagination to determine which method to use. enum: ['normal', 'address', 'filters']
            employers: [],
            address: this.$store.state.searchedAddress, //Whatever value state.searchedAddress has, will be the default value for addrss
            aliasUrl: null,
            isCustomURL: false,
            mapLoaded: false,
            userInfo: null,
            all: [],
            maxDistance: 100,
            slider: 1,
            cardLoaded: false,
        };
    },
    mounted() {

    },
    created() {
        this.getMarketplace();
        this.getProviderCredentials();
    this.getSpecialities();
    this.getGroupAndOrganizations();
    },
    methods: {
        getProviderCredentials() {
      api()
        .get("/market/credentials/provider")
        .then((res) => {
          this.providerCredentials = res.data;
        });
    },
    getSpecialities() {
      api()
        .get("/market/specialities")
        .then((res) => {
          this.specialities = res.data;
        });
    },
    getGroupAndOrganizations() {
      api()
        .get("/market/organization/company/name")
        .then((res) => {
          this.groupAndOrganizations = res.data;
        });
    },
        searchAddress() { },
        getMarketplace() {
            this.loader = true;
            this.noResultsFromSearch = false;
            this.zipOrState = '';
            api()
                .get("/dpcproviders/market")
                .catch((err) => {
          if (err) {
            this.loader = false;
          }
        })
                .then((res) => {
                    this.marketplace = res.data;
                    this.loader = false;
                });
        },
        returnCardImage(entitiy) {
            if (entitiy == "solution") {
                return "/tpa/virtualNetwork.svg";
            } else if (entitiy == "location") {
                return "/tpa/clinicsAndOrganizations.svg";
            } else if (entitiy == "vendor") {
                return "/tpa/pointSolutionVendor.svg";
            } else if (entitiy == "provider") {
                return "/tpa/clinicians.svg";
            }
        },
        resolveMarketplaceBackgroundColor(card) {
            if(card.type == 'Virtual Network'){
             return "border-left: 15px solid #F26621; border-radius: 8px 0 0 8px"; 
            }
            else if(card.type == 'Organization'){
             return "border-left: 15px solid #249999; border-radius: 8px 0 0 8px"; 
            }
            

        },
        goToDetails(item, entity) {
      if (entity == "solution") {
        this.$router.push(`/dpc-providers/solutions/details/${item.id}`);
      } else if (entity == "location") {
        this.$router.push({
          path: `/dpc-providers/solutions/provider/details/${item.id}`,
          query: { location: item.locationId },
        });
      } else if (entity == "vendor") {
        this.$router.push(`/dpc-providers/solutions/vendor/details/${item.id}`);
      } else if (entity == "provider") {
        this.$router.push(
          `/dpc-providers/solutions/clinician/details/${item.id}`
        );
      }
    },
    selectGender(){
      if(this.male){
        this.selectedGenders.push('M');
      }else{
        let index = this.selectedGenders.indexOf('M');
        this.selectedGenders.splice(index,1);
      }
      if(this.female){
        this.selectedGenders.push('F');
      }else{
        let index = this.selectedGenders.indexOf('F');
        this.selectedGenders.splice(index,1);
      }
    },
    searchResults() {
      this.searchLoader = true;
     
      this.data = {
        search: {
          types: this.selectedCategories,
          organizations: this.selectedGroupAndOrganizations,
          specialities: this.selectedSpecialities,
          credentials: this.selectedCredentials,
          gender: this.selectedGenders,
        },
      };
    
      api()
        .post("/dpcproviders/market/search", this.data)
        .catch((err) => {
          if (err) {
            this.$refs.error_modal.openPopUp(err);
          }
        })
        .then((res) => {
          this.marketplace = res.data;
          this.searchLoader = false;
          if (res.data.length < 1) {
            this.noResultsFromSearch = true;
          }
          else{
            this.noResultsFromSearch = false;
          }
          this.drawer = false;
        });
    },
    searchByZip() {
      this.searchLoader = true;
      const data = {
        search: {
          zipCode: this.zipOrState,
        },
      };
      api()
        .post("/dpcproviders/market/search", data)
        .catch((err) => {
          if (err) {
            this.$refs.error_modal.openPopUp(err);
          }
        })
        .then((res) => {
          this.marketplace = res.data;
          this.searchLoader = false;
          if (res.data.length < 1) {
            this.noResultsFromSearch = true;
          }
          else{
            this.noResultsFromSearch = false;
          }
        });
    },
    removeChip(chip,selectedArray) {
      const filterArray = selectedArray;
      const chipIndex = filterArray.indexOf(chip);
      if (chipIndex !== -1) {
        filterArray.splice(chipIndex, 1);
      }
      this.searchResults();
      this.drawer = false;
    },
    clearSearch() {
      this.loader = false;
      this.selectedCategories = [];
      this.selectedCredentials = [];
      this.selectedGenders = [];
      this.selectedGroupAndOrganizations = [];
      this.selectedSpecialities = [];
      this.getMarketplace();
    },
        searchByZip() {
      this.searchLoader = true;
      const data = {
        search: {
          zipCode: this.zipOrState,
        },
      };
      api()
        .post("/dpcproviders/market/search", data)
        .catch((err) => {
          if (err) {
            this.$refs.error_modal.openPopUp(err);
          }
        })
        .then((res) => {
          this.marketplace = res.data;
          this.searchLoader = false;
          if (res.data.length < 1) {
            this.noResultsFromSearch = true;
          }
          else{
            this.noResultsFromSearch = false;
          }
        });
    },
    },
    computed: {
        filteredSearch() {
      return (items) => {
        const query = this.searchQuery.toLowerCase().trim();
        if (query === "") {
          return items;
        }
        return items.filter(
          (item) =>
            item.name.toLowerCase().includes(query) ||
            item.speciality?.toLowerCase().includes(query)
        );
      };
    },
        filteredCategories() {
            const query = this.searchQuery.toLowerCase().trim();
            if (query === "") {
                return this.marketplace;
            }
            return this.marketplace.reduce((filtered, category) => {
                const filteredItems = category.items.filter(
                    (item) =>
                        item.name.toLowerCase().includes(query) ||
                        category.category.toLowerCase().includes(query)
                );
                if (
                    category.category.toLowerCase().includes(query) ||
                    (filteredItems.length > 0 &&
                        !category.category.toLowerCase().includes(query))
                ) {
                    filtered.push({ category: category.category, items: filteredItems });
                }

                return filtered;
            }, []);
        },

    }





};
</script>
<style scoped lang="scss">
.active {
    color: #333333 !important;
    background-color: transparent !important;
    border-bottom: 1px solid #333333;
}

</style>
