<template>
    <div class="no-padding no-margin">
        <ProvidersTable v-if="path == `/employer-advisor/employers/${routeParams}/providers`"></ProvidersTable>
        <ResultTable v-if="path == `/employer-advisor/employers/${routeParams}/providers/result`"></ResultTable>
        <AddProviders v-if="path == `/employer-advisor/employers/${routeParams}/providers/add-providers` "></AddProviders>
        <InviteProvider v-if="path == `/employer-advisor/employers/${routeParams}/providers/invite-providers`"></InviteProvider>
        <ProviderCrudForm v-if="path == `/employer-advisor/employers/${routeParams}/providers/provider`"></ProviderCrudForm>
        <SingleNpi v-if="path == `/employer-advisor/employers/${routeParams}/providers/result/npi` "></SingleNpi>
        <AddByMember v-if="path == `/employer-advisor/employers/${routeParams}/providers/add-by-member` " />
    </div>
</template>
<script>
import AddProviders from './AddProviders.vue'
import InviteProvider from './InviteProvider.vue'
import ProviderCrudForm from './ProviderCrudForm.vue'
import ResultTable from './ResultTable.vue'
import ProvidersTable from './ProvidersTable.vue'
import SingleNpi from './SingleNpi.vue'
import AddByMember from './AddByMemberZip.vue'



export default {
    components:{
    ResultTable,
    AddProviders,
    InviteProvider,
    ProviderCrudForm,
    ProvidersTable,
    SingleNpi,
    AddByMember


    },
    data(){
        return{
            
        }
    },
    computed:{
        path(){
             return this.$route.path.replace(/%20/g,' ')
        },
        routeName(){
            return this.$route.params.name
        },
         routeParams(){
            return this.$route.params.name
      }
    },
   
    created(){
      
    },
    methods:{
     
    }
   
}
</script>
<style>
.background-opacity{
    opacity: 0.46;
    background-color: rgb(33, 33, 33);
    border-color: rgb(33, 33, 33);
}
</style>