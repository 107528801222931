<template>
  <div class="">
    <div class="row border-blue-bottom mb-3">
      <div class="col-md-12 mb-4 mt-4 ml-4 font-weight-bold text-gray">
        Solution Detail
      </div>
    </div>
    <div v-if="item">
      <div class="row form-row-height pt-2 pb-2">
        <div
          class="col-md-2 ml-4 font-weight-bold"
          style="border-right: 1px solid #eeeded"
        >
          <div
            class="pointer"
            :class="tab == 'general' ? ' light-blue-text' : 'text-gray'"
            @click="tab = 'general'"
          >
            General Information
          </div>
          <br />
          <div
            class="pointer"
            :class="tab == 'rates' ? ' light-blue-text' : 'text-gray'"
            @click="tab = 'rates'"
          >
            Rates & Contract
          </div>
        </div>
        <div
          class="col-md-8"
          v-if="item && item.solution && item.solution.vendor"
        >
          <div class="row col-md-12 font-weight-bold">
            <span
              class="light-blue-text"
              v-html="
                tab != 'general' ? '  Rates & Contract' : 'General Information'
              "
            ></span>

            <v-divider class="mt-3 ml-3"></v-divider>
          </div>
          <div class="row col-md-12" v-if="tab == 'general'">
            <div class="col-md-6">
              <div class="col-md-12">
                <span class="text-gray font-weight-bold">Solution Name:</span>
                <span class="text-gray font-weight-bold ml-4">{{
                  item.solution ? item.solution.vendor.companyName : "/"
                }}</span>
              </div>
              <div class="col-md-12">
                <span class="text-gray font-weight-bold"
                  >Solution Category:</span
                >
                <span class="text-gray font-weight-bold ml-4">{{
                  item.solution.category
                }}</span>
              </div>
              <div class="col-md-12">
                <span class="text-gray font-weight-bold">Solution URL:</span>
                <span class="text-gray font-weight-bold ml-4">{{
                  item.solution.url
                }}</span>
              </div>
              <div class="col-md-12">
                <span class="text-gray font-weight-bold"
                  >Solution Contact:</span
                >
                <span class="text-gray font-weight-bold ml-4">{{
                  item.solution.contact
                }}</span>
              </div>
              <div class="col-md-12">
                <span class="text-gray font-weight-bold">Solution Email:</span>
                <span class="text-gray font-weight-bold ml-4">{{
                  item.solution.email
                }}</span>
              </div>
              <div class="col-md-12">
                <span class="text-gray font-weight-bold"
                  >Solution Definition:</span
                >
                <span class="text-gray font-weight-bold ml-4">{{
                  item.solution.definition
                }}</span>
              </div>
              <div class="col-md-12">
                <span class="text-gray font-weight-bold">Solution Status:</span>
                <span class="light-blue-text font-weight-bold ml-4" @click="dialog = true">{{
                  item.status
                    ? item.status.charAt(0).toUpperCase() + item.status.slice(1)
                    : "/"
                }}</span>
              </div>

              <div class="col-md-12">
                <div class="row col-md-12">
                  <span class="text-gray font-weight-bold">Auto Pay:</span>
                  <div class="d-flex ml-3 text-gray">
                    ON
                    <v-switch
                      v-model="item.solution.autoplay"
                      solo
                      class="no-padding ml-2 mr-2 vendors-switch"
                    ></v-switch>
                    OFF
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="col-md-12">
                <span class="text-gray font-weight-bold">Vendor Name:</span>
                <span class="text-gray font-weight-bold ml-4">{{
                  item.solution.vendor.companyName
                }}</span>
              </div>
              <div class="col-md-12">
                <span class="text-gray font-weight-bold">Vendor Address:</span>
                <span class="text-gray font-weight-bold ml-4">{{
                  item.solution.vendor.address1
                }}</span>
              </div>
              <div class="col-md-12">
                <span class="text-gray font-weight-bold">Vendor Website:</span>
                <span class="text-gray font-weight-bold ml-4">{{
                  item.solution.vendor.website
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="gray-top-border">
      <div class="col-md-12" align="end">
        <span
          class="light-blue-text text-underline mr-10 font-weight-bold pointer"
          @click="$router.go(-1)"
          >Cancel</span
        >
        <span
          class="light-blue-text text-underline mr-10 font-weight-bold pointer"
          @click="$router.go(-1)"
          >Save & Close</span
        >
      </div>
    </div>

    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span class="text-h5 text-underline col-md-12 font-weight-bolder" style="font-weight:bold !important"
            >Send Reminder Email to Client for Approval</span
          >
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form class="row" ref="form" v-model="valid">
              <v-col cols="12" sm="12" md="12">
                <label for="">Company Name</label>
                <v-text-field
                
                  :rules="requiredRules"
                  v-model="companyName"
                  solo
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <label for="">Contact First Name</label>
                <v-text-field
                 
                  solo
                  :rules="requiredRules"
                  required
                  v-model="firstName"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <label for="">Contact Last Name</label>
                <v-text-field
                  
                  :rules="requiredRules"
                  solo
                  required
                  v-model="lastName"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <label for="">Email</label>
                <v-text-field
                
                  solo
                  :rules="emailRules"
                  required
                  v-model="email"
                ></v-text-field>
              </v-col>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <div class="col-md-12 mb-3" align="end">
            <span
              class="text-underline text-gray mr-10 pointer"
              @click="dialog = false"
              >Cancel</span
            >
            <span
              class="text-underline text-gray pointer"
              @click="inviteSolutions"
              >Invite</span
            >
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      item: null,
      tab: "general",
      dialog: false,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
      valid: false,
      companyName: "",
      firstName: "",
      lastName: "",
      email: "",
    };
  },

  computed: {},
  created() {
    this.item = this.$store.state.Invoicing.singleSoulution;
  },
  methods: {
    inviteSolutions() {
      this.$refs.form.validate();
      if (this.valid) {
        this.dialog = false
      }
    },
  },
};
</script>
