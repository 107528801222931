<template>
  <div>
    <div class="col-md-12 text-center justify-center" v-if="readyToShow">
      <v-progress-circular
        indeterminate
        :size="100"
        color="primary"
        class="text-center"
      ></v-progress-circular>
    </div>

    <div class="row" v-else>
      <div
        v-for="item in items"
        :key="item.title"
        class="
          text-center
          justify-center
          align-center
          col-md-4 col-xl-3 col-lg-3
        "
      >
        <v-card
          class="centered-card space-around-content"
          style="height: 13rem; cursor: pointer"
          @click="item.url ? $router.push(item.url) : ''"
        >
          <img :src="item.img" height="64" width="70" class="img-media" />
          <h4
            class="
              text-center
              justify-center
              blue-text
              font font-quicksand-medium-18
            "
          >
            {{ item.title }}
          </h4>
          <v-card-text>
            <v-row align="center" class="mx-0 justify-center">
              <h6
                class="text-center font-quicksand-medium-18"
                style="font-weight: normal; line-height: 16px"
              >
                {{ item.desc }}
              </h6>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
      <div
        class="
          col-md-8 col-xl-6 col-lg-6
          text-center
          justify-center
          aling-center
        "
      >
        <v-card
          style="height: 13rem; cursor: pointer"
          class="space-around-content"
        >
          <v-row align="center" class="mx-0 justify-center">
            <h5
              class="text-center text-underline mt-5 font-quicksand-medium-18"
            >
              Easy Setup
            </h5>
          </v-row>
          <v-row class="text-center justify-center">
            <img
              src="/provider-advisor-home.png"
              id="setup-img-emp"
              height="100"
              alt=""
            />
          </v-row>
          <v-row align="center" class="mx-15 mb-2 justify-center">
            <h5 class="red-desc text-center font font-quicksand-medium-18 mb-4">
              Instantly see those employers who are requesting to contract with
              you, and then contract to capture their business and sieze market
              share.
            </h5>
          </v-row>
        </v-card>
      </div>
      <div
        v-for="item in precentageCards"
        :key="item.title"
        class="
          col-md-6 col-xl-3 col-lg-3
          text-center
          justify-center
          aling-center
        "
      >
        <v-card
          style="height: 13rem; cursor: pointer"
          class="space-around-content"
        >
          <div align="center" class="mx-0 justify-center">
            <h5
              class="text-center text-underline mt-2 font-quicksand-medium-18"
            >
              {{ item.title }}
            </h5>
          </div>
          <v-card-title
            class="text-center justify-center text-gray font percentage-num"
            :class="item.title == 'Current Contract Rates' ? 'pt-0' : ''"
            style="height: 5rem; cursor: pointer"
          >
            <div
              v-if="item.title == 'Patient / Member Encounters'"
              class="row justify-space-around mt-1"
            >
              <h3 class="blue-text no-padding">
                {{ statistics.noMembersEncounter }} <br />
                This Month
              </h3>
              <h3 class="blue-text no-padding ml-5">
                {{ statistics.noPatientsEncounter }} <br />
                YTD
              </h3>
            </div>
            <div
              v-else-if="
                item.title == 'Current Contract Rates' && statistics.rates
              "
              class="row justify-space-around mt-1 blue-text"
            >
              <div class="blue-text font-weight-bold">
                <h1 class="blue-text mb-2">
                  <span v-if="!statistics.rates.inpatient">0</span>
                  <span v-else>{{ statistics.rates.inpatient }}</span
                  >% <br />
                </h1>
                Inpatient
              </div>
              <div class="ml-10 font-weight-bold">
                <h1 class="blue-text mb-2">
                  <span v-if="!statistics.rates.outpatient">0</span>
                  <span v-else>{{ statistics.rates.outpatient }}</span
                  >% <br />
                </h1>
                Outpatient
              </div>
            </div>
            <div v-else-if="item.title == 'Providers'" class="blue-text">
              <h1 v-html="statistics.noProviders ? statistics.noProviders : 0">
                {{ statistics.noProviders }}
              </h1>
            </div>
            <div
              v-else-if="item.title == 'Projected Savings'"
              class="blue-text"
            >
              <h1>{{ statistics.projectedSavings }}%</h1>
            </div>
            <div
              v-else-if="item.title == 'Members within Coverage Area'"
              class="blue-text"
            >
              <h1 v-if="statistics.memberCoverage">
                {{ statistics.memberCoverage }}%
              </h1>
              <h1 v-else>0%</h1>
            </div>
            <div v-else-if="item.title == 'Contract Renewal'" class="blue-text">
              <h1
                v-html="
                  statistics.contractRenewals
                    ? parsedDate(statistics.contractRenewals)
                    : ''
                "
              ></h1>
            </div>
            <div
              v-else-if="item.title == 'Employers Interested in Contracting'"
              class="blue-text"
            >
              <h1
                v-html="
                  statistics.noEmployersInterested
                    ? statistics.noEmployersInterested
                    : 0
                "
              ></h1>
            </div>
          </v-card-title>
          <div align="center" class="mx-10 justify-center">
            <h5
              class="blue-text ms-2 text-center font-quicksand-medium-18 pb-4"
              v-if="item.desc"
              style="line-height: 20px"
            >
              {{ item.desc }}
            </h5>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../../../../../services/api";
export default {
  data() {
    return {
      items: [
        {
          url: `/provider-advisor/providers/${this.$route.params.name}/employers`,
          img: "/cards/woman.png",
          title: "Employer Contract Requests ",
          desc: "See the employers who are requesting to contract with you.",
        },
        //  {
        //      img:'/cards/money.png',
        //      title:'Prior Authorization',
        //      desc:'Recieve approval for certain procedures.'
        //  },
        //  {
        //      img:'/cards/doctor.png',
        //      title:'Make a Referral',
        //      desc:'Lookup participating providers to keep member within the network.'
        //  },
        //  {
        //      img:'/cards/hospital.png',
        //      title:'Build a Network',
        //      desc:'Create a virtual network of providers to deliver an expanded coverage of services'
        //  },
        //  {
        //      img:'/cards/contract.png',
        //      title:'Invite Employers',
        //      desc:'Expand your buisiness and capture market share using the Apaly Health platform..'
        //  },
        //  {
        //      img:'/cards/letter.png',
        //      title:'Invite Providers',
        //      desc:'Help expand your business through relattionships with health benefits advisors.'
        //  },
        //  {
        //      img:'/cards/desktop.png',
        //      title:'Invite Colleagues',
        //      desc:'Invite other staff within your organization to praticipate on the Apaly Healt platform..'
        //  },
      ],
      precentageCards: [
        {
          title: "Employers Interested in Contracting",
          percentage: "28%",
          desc: "number of self-funded employers who have added you to their provider list, and are requesting to contract",
        },
        // {
        //   title: "Members within Coverage Area",
        //   percentage: "94%",
        //   desc: "number of self-funded employer plan members residing within a 30 mile radius of your facilities",
        // },
        {
          title: "Providers",
          percentage: "48,000",
          desc: "total number of providers that are currently entered into your Apaly Health account",
        },
        {
          title: "Current Contract Rates",
          percentage: "150%",
          percentage1: "180%",
          desc: "of Medicare benchmark rates",
          inpatient: true,
        },
      ],
      dialogm1: "",
      dialog: false,
      dialog1: false,
      allEmployers: "",
      statistics: {},
      readyToShow: false,
    };
  },
  methods: {
    parsedDate(date) {
      let returnData = "/";
      if (date) {
        let newDate = new Date(date);
        returnData =
          newDate.getMonth() +
          "/" +
          newDate.getDay() +
          "/" +
          newDate.getFullYear();
      }
      return returnData;
    },
    singleCompany() {
      this.$router.push({
        path: "/provider-advisor/providers/" + item.companyName,
        params: { name: item.companyName },
      });
    },
    clientsTable() {
      this.dialog = !this.dialog;
      this.dialog1 = !this.dialog1;
      this.$emit("showClient");
    },
    getEmployersStatistics() {
      this.readyToShow = true;
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.providerAdvisor;
      let provider = JSON.parse(localStorage.getItem("provider"));
      api()
        .get(`/advisors/${advisorId}/providers/${provider._id}/statistics`)
        .then((res) => {
          this.statistics = res.data.statistics;
          this.readyToShow = false;
        })
        .catch((err) => {
          console.log(err.response);
          this.readyToShow = false;
        });
    },
    searchEmp(value) {
      api()
        .get(`/advisors/providers/searchPA?=${value}`)
        .then((res) => {
          this.allEmployers = res.data.providers;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
  },
  created() {
    this.getEmployersStatistics();
    //   this.$store.commit('setBreadcrumbs','home')
  },
};
</script>
<style>
