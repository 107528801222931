
    const state = {
        provider: null,
    }
    
    const getters = {
      getSingleProvider(state){
        return state.provider
      },
    }
    
    const mutations = {
        setProvider(state, provider){
            state.provider = provider
        },
    }
    
    const actions = {
    }
    
    export default {
        state,
        getters,
        mutations,
        actions
    }
  