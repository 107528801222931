// Members
import Employer from "../views/Employer.vue";
import MemberZipCodes from '../components/Sections/Employer/Members/AddZipCodes.vue'
import EmployerUpload from "../components/Sections/Employer/Members/Upload/EmployerUpload.vue";
import EmployerMembers from "../components/Sections/Employer/Members/EmployerMembers";
import EmployerAddMember from "../components/Sections/Employer/Members/AddMember/EmployerAddMember.vue";
import EmployerMemberDetail from "../components/Sections/Employer/Members/MemberDetail/EmployerMemberDetail.vue";
import EmployerDependentDetail from "../components/Sections/Employer/Members/MemberDetail/DependentDetail/EmployerDependentDetail.vue";
import EmployerInviteMembers from "../components/Sections/Employer/Members/InviteMembers/EmployerInviteMembers.vue";
import EmployerAddDependent from '../components/Sections/Employer/Members/MemberDetail/AddDependent.vue'
import MemberResult from '../components/Sections/Employer/Members/ResultTable.vue'

// Prior Auths
import EmpPriorAuth from "../components/Sections/Employer/PriorAuth/EmpPriorAuth.vue";
import EmpPriorAuthDetail from "../components/Sections/Employer/PriorAuth/PriorAuthDetail/EmpPriorAuthDetail.vue";
import EmpPriorAuthRules from "../components/Sections/Employer/PriorAuth/PriorAuthRules/EmpPriorAuthRules.vue";
import EmpPriorAuthAddRule from "../components/Sections/Employer/PriorAuth/PriorAuthAddRule/EmpPriorAuthAddRule.vue";
import EmpPriorAuthRuleDetail
    from "../components/Sections/Employer/PriorAuth/PriorAuthRuleDetail/EmpPriorAuthRuleDetail.vue";

// Providers
import EmpProviders from "../components/Sections/Employer/Providers/EmpProviders.vue";
import EmpAddProvider from "../components/Sections/Employer/Providers/AddProvider/EmpAddProvider.vue";
import EmpProviderSearchResult  from "../components/Sections/Employer/Providers/AddProvider/SearchResult/EmpProviderSearchResult.vue";
import EmpInviteProvider from "../components/Sections/Employer/Providers/InviteProviders/EmpInviteProvider.vue";
import EmpProviderDetail from "../components/Sections/Employer/Providers/ProviderDetail/EmpProviderDetail.vue";
import EmpAddMemberInProvider from '../components/Sections/Employer/Providers/AddMember/AddMember.vue'
import AddProviderMemberZipCodes from '../components/Sections/Employer/Providers/AddZipCodes.vue'
//Bundles
import EmpBundles from '../components/Sections/Employer/Bundles/Bundles.vue'
import EmpAddNew from '../components/Sections/Employer/Bundles/AddNew.vue'

// Advisors
import EmpAdvisors from "../components/Sections/Employer/Advisors/EmpAdvisors.vue";
import EmpAddAdvisor from "../components/Sections/Employer/Advisors/AddAdvisor/EmpAddAdvisor.vue";
import EmpAddAdvisorResult from "../components/Sections/Employer/Advisors/AddAdvisor/SearchResult/EmpAddAdvisorResult.vue";
import EmpAdvisorInvite from "../components/Sections/Employer/Advisors/InviteAdvisor/EmpAdvisorInvite.vue";
import EmpAdvisorDetail from "../components/Sections/Employer/Advisors/AdvisorDetail/EmpAdvisorDetail.vue";


// Contract
import EmpContract from "../components/Sections/Employer/Contract/EmpContract.vue";
import EmpAddContract from "../components/Sections/Employer/Contract/AddContract/EmpAddContract.vue";
import EmpNewRequest from "../components/Sections/Employer/Contract/NewRequest/EmpNewRequest.vue";
import EmpSingleContract from '../components/Sections/Employer/Contract/SingleContract/Contract.vue'

//Partners
import Partners from '../components/Sections/Employer/Partners/PartnersTable.vue'
import AddPartner from '../components/Sections/Employer/Partners/AddPartner.vue'
import Partner from '../components/Sections/Employer/Partners/Partner.vue'
import InvitePartner from '../components/Sections/Employer/Partners/InvitePartner.vue'
import PartnerResult from '../components/Sections/Employer/Partners/Result.vue'

// Settings
import EmpSettings from "../components/Sections/Employer/Settings/EmpSettings.vue";
import EmpSettingsAccount from "../components/Sections/Employer/Settings/Account/EmpSettingsAccount.vue";
import EmpSettingsUsers from "../components/Sections/Employer/Settings/Users/EmpSettingsUsers.vue";
import EmpSettingsAddUser from "../components/Sections/Employer/Settings/AddUser/EmpSettingsAddUser.vue";
import EmpSettingsInvite from "../components/Sections/Employer/Settings/Invite/EmpSettingsInvite.vue";
import EmpSettingDetail from "../components/Sections/Employer/Settings/Detail/EmpSettingDetail.vue";

//Utilization
import Utilization from '../components/Sections/Employer/Utilization/Utilization.vue'

import Networks from '../components/Sections/Employer/Networks/Networks.vue'
import WizzardForm from '../components/Sections/Employer/WizzardFormEmployer.vue'
import NewWizzardForm from '../components/Sections/Employer/WizardWithoutInfo.vue'
import MyProfile from "../components/TablesTemplate/MyProfile.vue"



function routeGuard(to, from, next) {
    let user = JSON.parse(localStorage.getItem('user'));

    if (user.type != 'employer' || !$cookies.get('token')){
      next('/login');
    }else{
        next();
    }
      
}
const router = [
 
    {
        path: '/employer/home',
        name: 'Home',
        component: Employer,
        beforeEnter: routeGuard,

    },
    {
        path: '/employer',
        name: 'Home',
        component: Employer,
        beforeEnter: routeGuard,
      
        children: [
            {
                path: 'providers',
                name: 'Providers',
                component: EmpProviders,
                children: [
                    {  
                        path: 'zip-codes',
                        name: 'Add by Member Zips',
                        component:AddProviderMemberZipCodes,
                    },
                    {
                        path: 'add-provider',
                        name: 'Add New',
                        component: EmpAddProvider,
                        children: [
                            {
                                path: 'search-result',
                                name: 'Search result',
                                component: EmpProviderSearchResult
                            }
                        ]
                    },
                    {
                        path: 'invite',
                        name: 'Invite',
                        component: EmpInviteProvider
                    },
                    {
                        path: 'detail',
                        name: 'Detail',
                        component: EmpProviderDetail
                    },
                    {
                        path: 'member',
                        name: 'Add Member',
                        component: EmpAddMemberInProvider
                    },
                    
                ]
            },
            { 
                path:'bundles',
                name:'Bundles',
                component:EmpBundles,
                children:[
                    {
                        path:'add-new',
                        name:'Create Bundle List',
                        component: EmpAddNew
                    }
                ]

            },
            {
              path: 'advisors',
              name: 'Advisors',
              component: EmpAdvisors,
                children: [
                    {
                        path: 'add-new',
                        name: 'Add New',
                        component: EmpAddAdvisor,
                        children: [
                            {
                                path: 'result',
                                name: 'Search Result',
                                component: EmpAddAdvisorResult
                            }
                        ]
                    },
                    {
                        path: 'invite',
                        name: 'Invite',
                        component: EmpAdvisorInvite
                    },
                    {
                        path: 'detail',
                        name:'Advisor Detail',
                        component: EmpAdvisorDetail
                    }
                ]
            },
            {
              path: 'settings',
              name: 'Settings',
              component: EmpSettings,
                children: [
                    {
                        path: 'account',
                        name: 'Account',
                        component: EmpSettingsAccount
                    },
                    {
                        path: 'users',
                        name: 'Users',
                        component: EmpSettingsUsers,
                        children: [
                            {
                                path: 'add-new',
                                name: 'Add New',
                                component: EmpSettingsAddUser
                            },
                            {
                                path: 'invite',
                                name: 'Invite',
                                component: EmpSettingsInvite
                            },
                            {
                                path: 'detail',
                                name: 'Detail',
                                component: EmpSettingDetail
                            }
                        ]
                    }
                ]
            },
            {
              path: 'contract',
              name: 'Contract',
              component: EmpContract,
                children: [
                    {
                        path: 'add-new',
                        name: 'New',
                        component: EmpAddContract
                    },
                    {
                        path: 'request',
                        name: 'Request New Type',
                        component: EmpNewRequest
                    },
                    {
                        path: 'details',
                        name: 'Details',
                        component: EmpSingleContract
                        
                    }
                ]
            },
            {
                path: 'prior-auth',
                name: 'Prior Auths',
                component: EmpPriorAuth,
                children: [
                    {
                        path: 'detail',
                        name: 'Detail',
                        component: EmpPriorAuthDetail

                    },
                    {
                        path: 'rules',
                        name: 'Prior Authorization Rules',
                        component: EmpPriorAuthRules,
                    },
                    {
                        path: 'add-rule',
                        name: 'New Prior Authorization Rules',
                        component: EmpPriorAuthAddRule
                    },
                    {
                        path: 'rule-detail',
                        name: 'Prior Authorization Request Detail',
                        component: EmpPriorAuthRuleDetail
                    }
                ]
            },
            {
                path: 'members',
                name: 'Members',
                component: EmployerMembers,
                children: [
                    {  path: 'zip-codes',
                        name: 'Add by Member Zips',
                        component:MemberZipCodes,
                    },
                    {
                        path: 'upload',
                        name: 'Upload',
                        component: EmployerUpload,
                    },
                    {
                        path: 'add-new',
                        name: 'Add New',
                        component: EmployerAddMember,
                    },
                    {
                        path: 'result',
                        name: 'Search Result',
                        component: MemberResult,
                    },
                    {
                        path: 'invite-members',
                        name: 'Invite Members',
                        component: EmployerInviteMembers,
                    },
                    {
                        path: 'member-detail',
                        name: 'Member Detail',
                        component: EmployerMemberDetail,
                        children: [
                            {
                                path: 'dependent-detail',
                                name: 'Dependent Detail',
                                component: EmployerDependentDetail
                            },
                            {
                                path: 'dependents',
                                name: 'Add Dependent',
                                component: EmployerAddDependent
                                
                            }

                        ]
                    },
                ]
            },
            {
                path:'utilization',
                name:'Utilization',
                component:Utilization
            },
            {
                path:'networks',
                name:'VNets',
                component:Networks
            },
            {
                path:'wizard-form',
                name:'Wizard Form Employer',
                component:WizzardForm
            },
            {
                path:'profile',
                name:'My Profile',
                component:MyProfile
            },
            {
                path:'new-wizard-form',
                name:'Wizard Form Employer',
                component:NewWizzardForm
            },
            
            {
                path:'partners',
                name:'Partners',
                component:Partners,
                children:[
                    {
                        path:'add-new',
                        name:'Add New Partner',
                        component:AddPartner,
                        children:[
                            {
                                path:'result',
                                name:'Search Result',
                                component:PartnerResult,
                            }
                        ]
                    },
                    {
                        path:'invite-partner',
                        name:'Invite Partner',
                        component:InvitePartner,
                    },
                    {
                        path:'partner',
                        name:'Partner Detail',
                        component:Partner,
                    }
                ]
            },

        ]
    }
]


export default router