<template>
  <div class="row justify-center mt-4">
    <div class="col-md-6 mt-4">
      <v-card class="mx-auto" max-width="1200" tile>
        <blue-header></blue-header>
        <div class="mt-3">
          <div class="row border-blue-bottom">
            <div class="col-md-5 row">
              <v-icon
                large
                v-text="'mdi-menu-left'"
                class="pointer"
                @click="
                  tableClients
                    ? $router.push('/clearing-house')
                    : ((addNew = false),
                      (clientDetails = false),
                      (tableClients = true),
                      getData())
                "
              ></v-icon>

              <h3 class="mb-4 mt-5 ml-3 font-weight-bold warning--text">
                <span v-if="tableClients"> TPAs / Carrier Admins </span>
                <span v-else-if="addNew">Add New TPA / Carrier</span>
                <span v-else-if="clientDetails">TPA / Carrier Details</span>
              </h3>
            </div>
            <div class="col-md-4" v-if="tableClients">
              <v-text-field
                class="input-control mr-5"
                prepend-inner-icon="mdi-magnify"
                @input="searchInput"
                light
                dense
                outlined
                placeholder="Search TPAs / Carrier Admins"
              ></v-text-field>
            </div>

            <div :class="tableClients ? 'col-md-3' : 'col-md-7'" align="end">
              <div
                class="pointer"
                v-if="showDetails || !tableClients || !addNewTPA"
              >
                <svg
                  @click="dialog = true"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.25 7.51196H27.75"
                    stroke="#326AB3"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21.75 28.5H8.25C7.45435 28.5 6.69129 28.1839 6.12868 27.6213C5.56607 27.0587 5.25 26.2956 5.25 25.5V7.5H24.75V25.5C24.75 26.2956 24.4339 27.0587 23.8713 27.6213C23.3087 28.1839 22.5456 28.5 21.75 28.5Z"
                    stroke="#326AB3"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.62402 7.5V6.813C9.62402 5.4039 10.1838 4.05252 11.1802 3.05614C12.1765 2.05976 13.5279 1.5 14.937 1.5C16.3461 1.5 17.6975 2.05976 18.6939 3.05614C19.6903 4.05252 20.25 5.4039 20.25 6.813V7.5"
                    stroke="#326AB3"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.967 13.5V22.5"
                    stroke="#326AB3"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18.0449 13.5V22.5"
                    stroke="#326AB3"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <v-btn
                v-if="tableClients"
                class="my-1 gray-border-buttons ml-6"
                @click="
                  (addNew = true),
                    (tableClients = false),
                    (clientDetails = false),
                    (payerid = ''),
                    (tpaname = ''),
                    (selectedEmployers = []),
                    (selectedPlans = [])
                "
              >
                <svg
                  class="mr-2"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 7.5H15"
                    stroke="#707070"
                    stroke-width="2"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.5 0V15"
                    stroke="#707070"
                    stroke-width="2"
                    stroke-linejoin="round"
                  />
                </svg>

                New
              </v-btn>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <v-simple-table
            v-if="tableClients"
            fixed-header
            height="70vh"
            class="no-padding tr-custom-height"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Payer ID</th>
                  <th class="text-left">TPA Name</th>
                </tr>
              </thead>

              <tbody
                v-if="items.length > 0 && !filterItems"
                class="text-gray tr-custom-height"
              >
                <tr
                  class="text-gray"
                  id="remove-hover"
                  v-for="(item, index) in items"
                  :key="index"
                >
                  <td>
                    <a
                      v-if="item && item['payerid']"
                      class="text-underline"
                      @click="showClient(item, index)"
                      >{{ item["payerid"] }}
                    </a>
                    <a v-else @click="showClient(item, index)">/</a>
                  </td>
                  <td>{{ item["tpaname"] }}</td>
                </tr>
              </tbody>
              <tbody v-else-if="items.length < 1 && filterItems">
                <v-col md="12" cols="12">
                  <v-alert
                    border="bottom"
                    colored-border
                    type="warning"
                    elevation="2"
                    class="text-gray"
                  >
                    Sorry! No results found
                  </v-alert>
                </v-col>
              </tbody>

              <tbody v-else>
                <v-col md="12" v-if="!loader">
                  There are no clients currently added to your account.
                  <a
                    @click="
                      (addNew = true),
                        (tableClients = false),
                        (clientDetails = false)
                    "
                  >
                    Click to add your clients</a
                  >
                </v-col>
                <v-row v-else class="justify-center col-md-12 mt-5">
                  <v-progress-circular
                    :size="100"
                    class="text-center"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-row>
              </tbody>
            </template>
          </v-simple-table>
          <div v-else-if="addNew" class="row justify-center mt-5">
            <div class="col-md-8">
              <v-text-field
                outlined
                label="TPA Name"
                v-model="tpaname"
                hide-details=""
              ></v-text-field>
            </div>
            <div class="col-md-8">
              <v-text-field
                outlined
                hide-details=""
                label="Payer Id"
                v-model="payerid"
              ></v-text-field>
            </div>
            <div class="col-md-8">
              <v-select
                :items="employers"
                outlined
                item-text="employername"
                item-value="_id"
                hide-details=""
                label="Employer Client"
                v-model="selectedEmployers"
                multiple
              >
                <template v-slot:prepend-item>
                  <div class="fixed-list-item">
                    <v-list-item>
                      <v-list-item-content class="p-2">
                        <v-text-field
                          dense
                          placeholder="Search"
                          @input="searchEmployers"
                        ></v-text-field>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </div>
                </template>
              </v-select>
            </div>
            <div class="col-md-8">
              <v-select
                outlined
                hide-details=""
                item-text="planname"
                item-value="_id"
                multiple
                label="Health Plans"
                :items="plans"
                v-model="selectedPlans"
              >
                <template v-slot:prepend-item>
                  <div class="fixed-list-item">
                    <v-list-item>
                      <v-list-item-content class="p-2">
                        <v-text-field
                          dense
                          placeholder="Search"
                          @input="searchPlans"
                        ></v-text-field>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </div>
                </template>
              </v-select>
            </div>
            <div class="col-md-8 mb-10">
              <v-btn class="blue-buttons" block @click="addNewTPA"
                >Add New</v-btn
              >
            </div>
          </div>
          <div v-else-if="clientDetails" class="row justify-center mt-5">
            <div class="col-md-8">
              <v-text-field
                outlined
                label="TPA Name"
                v-model="tpaname"
                hide-details=""
              ></v-text-field>
            </div>
            <div class="col-md-8">
              <v-text-field
                outlined
                hide-details=""
                label="Payer Id"
                v-model="payerid"
              ></v-text-field>
            </div>
            <div class="col-md-8">
              <v-select
                :items="employers"
                outlined
                item-text="employername"
                item-value="_id"
                hide-details=""
                label="Employer Client"
                v-model="selectedEmployers"
                multiple
              >
                <template v-slot:prepend-item>
                  <div class="fixed-list-item">
                    <v-list-item>
                      <v-list-item-content class="p-2">
                        <v-text-field
                          dense
                          placeholder="Search"
                          @input="searchEmployers"
                        ></v-text-field>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </div>
                </template>
              </v-select>
            </div>
            <div class="col-md-8">
              <v-select
                outlined
                item-text="planname"
                item-value="_id"
                hide-details=""
                label="Health Plans"
                :items="plans"
                multiple
                v-model="selectedPlans"
              >
                <template v-slot:prepend-item>
                  <div class="fixed-list-item">
                    <v-list-item>
                      <v-list-item-content class="p-2">
                        <v-text-field
                          dense
                          placeholder="Search"
                          @input="searchPlans"
                        ></v-text-field>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </div>
                </template>
              </v-select>
            </div>
            <div class="col-md-8"></div>
            <div class="col-md-8 mb-10">
              <v-btn class="blue-buttons" block @click="updateItem"
                >Update</v-btn
              >
            </div>
          </div>
        </div>
      </v-card>

      <v-dialog v-model="dialog" width="500">
        <v-card>
          <v-card-title class="">
            Are you sure you want to delete?
          </v-card-title>

          <v-card-text class="mt-5 mb-5">
            <v-btn class="blue-buttons" block @click="removeItem">
              YES - DELETE</v-btn
            >
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <a class="text-underline mt-3 mb-3" @click="dialog = false">
              Cancel
            </a>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <router-view></router-view>
      <successModal
        ref="success_modal"
        v-on:confirm="closeSuccessModal"
      ></successModal>
    </div>
  </div>
</template>
<script>
import Header from "../../../DigitalVendorInvoices/Layout/HeaderPage.vue";
import api from "../../../../../services/api";
import tpas from "../../../../../store/JSONS/tpa.json";
import BlueHeader from "../BlueHeader.vue";
import successModal from "../../../../TablesTemplate/SuccessDialog.vue";
import { mapGetters } from "vuex";
import firebase from "../../../../../firebase.js";

const db = firebase.firestore();
export default {
  data() {
    return {
      showDetails: false,
      dialog: false,
      items: [],
      singleItem: null,
      sortBy: "",
      type: "",
      solutions: [],
      favorites: [],
      filterItems: false,
      filteredArray: [],
      selectedItem: 1,
      tableClients: true,
      addNew: false,
      clientDetails: false,
      dialog: false,
      tpaname: "",
      payerid: "",
      filterArray: [],
      tpaIndex: "",
      tpaId: "",
      employers: [],
      plans: [],
      filterEmployers: [],
      filterPlans: [],
      selectedEmployers: [],
      selectedPlans: [],
      loader: true,
    };
  },
  components: {
    Header,
    successModal,
    BlueHeader,
  },
  computed: {
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
    ...mapGetters(["getClearingHouseDetails"]),
  },
  created() {
    this.getData();
    this.getPlans();
    this.getEmployers();
  },
  methods: {
    searchEmployers(val) {
      this.employers = this.filterEmployers.filter((el) => {
        if (
          el["employername"] &&
          el["employername"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el["employername"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        }
      });
    },
    searchPlans(val) {
      this.plans = this.filterPlans.filter((el) => {
        if (
          el["planname"] &&
          el["planname"].toString().toLowerCase().includes(val.toLowerCase())
        ) {
          return el["planname"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        }
      });
    },
    removeItem() {
      api()
        .delete("/clearinghouse/tpas/" + this.tpaId)
        .then((res) => {
          if (res) {
            this.$refs.success_modal.openPopUp("TPA removed successfully");
          }
        });
    },
    updateItem() {
      api()
        .put("/clearinghouse/tpas/" + this.tpaId, {
          payerid: this.payerid,
          tpaname: this.tpaname,
          employers: this.selectedEmployers,
          plans: this.selectedPlans,
        })
        .then((res) => {
          if (res) {
            this.$refs.success_modal.openPopUp("TPA updated successfully");
          }
        });
    },
    closeSuccessModal() {
      this.getData();
      this.$refs.success_modal.closePopUp();
      this.addNew = false;
      this.showDetails = false;
      this.tableClients = true;
    },
    addNewTPA() {
      if (this.tpaname && this.payerid) {
        api()
          .post("/clearinghouse/tpas/" + this.tpaId, {
            payerid: this.payerid,
            tpaname: this.tpaname,
            employers: this.selectedEmployers,
            plans: this.selectedPlans,
          })
          .then((res) => {
            if (res) {
              this.$refs.success_modal.openPopUp("TPA added successfully");
            }
          });
      }
    },
    showClient(item, index) {
      this.payerid = "";
      this.tpaname = "";
      this.tpaId = "";
      this.selectedEmployers = [];
      this.selectedPlans = [];

      this.payerid = item["payerid"];
      this.tpaname = item["tpaname"];
      this.tpaIndex = index;
      this.tpaId = item._id;

      this.selectedEmployers = item.employers;
      if (item.plans.length > 0) {
        this.plans.forEach((el) => {
          item.plans.forEach((el1) => {
            if (el._id == el1) {
              this.selectedPlans.push(el);
            }
          });
        });
      }

      this.addNew = false;
      this.tableClients = false;
      this.clientDetails = true;
    },
    getEmployers(item) {
      this.employers = [];
      this.filterEmployers = [];
      api()
        .get("/clearinghouse/employers")
        .then((res) => {
          this.employers = res.data;
          this.filterEmployers = res.data;
          if (this.getClearingHouseDetails) {
            this.showClient(this.getClearingHouseDetails);
            this.$store.commit("setClientDetails", null);
          }
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    },
    getPlans(item) {
      api()
        .get("/clearinghouse/plans")
        .then((res) => {
          this.plans = res.data.filter((el) => {
            if (el.tpa == "" || el.tpa == null || !el.tpa == null) {
              return el;
            }
          });

          this.filterPlans = this.plans;
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    },
    getData() {
      this.loader = true;
      api()
        .get("/clearinghouse/tpas")
        .then((res) => {
          this.items = [];
          this.items = res.data;
          this.dialog = false;
          this.filterArray = this.items;
          this.loader = false;
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    },
    invite() {},
    add() {},
    exportFun() {
      this.exportData("tblData");
    },

    searchInput(val) {
      this.items = this.filterArray.filter((el) => {
        if (
          el["payerid"] &&
          el["payerid"].toString().toLowerCase().includes(val.toLowerCase())
        ) {
          return el["payerid"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el["tpaname"] &&
          el["tpaname"].toString().toLowerCase().includes(val.toLowerCase())
        ) {
          return el["tpaname"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        }
      });
      if (this.items.length < 1) {
        this.filterItems = true;
      } else {
        this.filterItems = false;
      }
    },

    searchMembers() {},
  },
};
</script>
<style>
.div-shadow {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.fixed-list-item {
  position: sticky;
  top: 0px;
  background: white;
  z-index: 999;
}
</style>