<template>
  <div class="row col-md-12 no-padding">
    <div class="col-md-12 padding-wizard">
      <div class="orange-underline-wizard row justify-space-between">
        <v-col class="col-xl-4 col-lg-4 col-md-2 mt-2 mb-3" cols="12">
          <h3 class="col-md-12 pt-0 pb-0">
            NEW MEMBER REGISTRATION - CONFIRM INFORMATION
          </h3>
        </v-col>
        <v-col
          class="col-xl-2 col-lg-4 col-md-4 mt-2 mb-3 text-center"
          cols="3"
        >
          <h3>1<span class="text-gray">/4</span></h3>
        </v-col>
        <v-col class="col-xl-6 col-lg-4 col-md-4 text-end mt-2 mb-3" cols="3">
          <i
            class="fa fa-times pointer mr-2 text-gray fa-lg"
            @click="$router.go(-1)"
          ></i>
        </v-col>
      </div>
      <div
        class="row form-row-height"
        :class="showOverlay ? ' postion-relative' : ''"
      >
        <div v-if="showOverlay" class="wizard-absolute"></div>
        <div class="col-md-6">
          <h4 class="text-underline col-md-12">Member Information</h4>
          <div class="col-md-12 pt-2 pb-0">
            <v-label class="text-gray">First Name</v-label>
            <v-text-field solo v-model="item.member.firstName"></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <v-label class="text-gray">Middle Name</v-label>
            <v-text-field solo v-model="item.member.middleName"></v-text-field>
          </div>
          <div class="col-md-12">
            <v-label class="text-gray">Last Name</v-label>
            <v-text-field solo v-model="item.member.lastName"></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <v-label class="text-gray">Phone</v-label>
            <v-text-field
              v-model="item.member.phone"
              :rules="item.member.phone.length > 0 ? phoneRules : []"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <v-label class="text-gray">Email</v-label>
            <v-text-field solo v-model="item.member.email"></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <v-label class="text-gray">Home Zip Code</v-label>
            <v-text-field
              v-model="item.member.zipCode"
              :rules="item.member.zipCode.length > 0 ? zipRules : []"
              solo
            ></v-text-field>
          </div>
        </div>
        <div class="col-md-6">
          <h4 class="text-underline col-md-12">
            Employer and Plan Information
          </h4>
          <div class="col-md-12 pt-2 pb-0">
            <v-label class="text-gray">Company Name</v-label>
            <v-text-field
              solo
              v-model="item.employer.companyName"
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <v-label class="text-gray">Group ID</v-label>
            <v-text-field solo v-model="item.employer.groupID"></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <v-label class="text-gray">Plan ID</v-label>
            <v-text-field solo v-model="item.employer.planID"> </v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <v-label class="text-gray">Member ID</v-label>
            <v-text-field solo v-model="item.employer.memberID"></v-text-field>
          </div>

          <div class="col-md-12 row pt-0 pb-2">
            <label for="" class="col-md-4 col-lg-4 col-xl-3 pt-0">
              Eligibility Status:</label
            >

            <span class="font-weight-bold col-md-5 col-lg-8 col-xl-5 pt-0"
              >Eligibility Confirmed on 06/01/2022</span
            >
          </div>
          <div class="col-md-12 row pt-0 pb-2">
            <label for="" class="col-md-4 col-lg-4 col-xl-3 pt-0">
              Deductible Balance:</label
            >
            <span class="font-weight-bold col-md-4 col-lg-4 col-xl-3 pt-0"
              ><v-text-field
                solo
                hide-details=""
                v-model="deductible"
              ></v-text-field
            ></span>
          </div>
          <div class="col-md-12 pt-0 pb-2 row">
            <label for="" class="col-md-4 col-lg-4 col-xl-3 pt-0">
              Next Visit Date:
            </label>
            <span class="font-weight-bold col-md-4 col-lg-4 col-xl-3 pt-0"
              >Not Set</span
            >
          </div>
          <div class="col-md-12 row pt-0 pb-1">
            <label class="col-md-4 col-lg-4 col-xl-3 pt-0 pb-1" for=""
              >Subscription:</label
            >
            <span class="font-weight-bold col-md-4 col-lg-4 col-xl-3 pt-0 pb-1"
              >Pending</span
            >
          </div>
        </div>
        <!-- Modals -->
      </div>

      <div class="row text-end mt-3 gray-underline-wizard">
        <div class="col-md-12 justify-end row mb-4 mt-4 mr-1">
          <div class="text-gray mr-10 text-underline pointer" @click="saveItem">
            Save
          </div>
          <div
            class="text-gray mr-5 ml-10 pointer text-underline"
            @click="$router.go(-1)"
          >
            Cancel
          </div>
        </div>
      </div>
      <successModal
        ref="success_modal"
        v-on:confirm="closeSuccessModal"
      ></successModal>
      <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
    </div>
  </div>
</template>
<script>
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../TablesTemplate/ErrorDialog.vue";

import api from "../../../../services/api";

export default {
  components: { successModal, errorModal },
  data() {
    return {
      item: {
        member: {
          firstName: "",
          middleName: "",
          lastName: " ",
          phone: "",
          client: "",
          email: "",
          status: "",
          zipCode: "",
        },
        employer: {
          companyName: "",
          payerID: "",
          groupID: "",
          planID: "",
          memberID: "",
        },
        eligibilityStatus: "Eligibility Confirmed on 06/01/2022",
        lastVisit: "Not Set",
        nextVisit: "Not Set",
        deductibleBalance: "$1,200",
        subscription: "Pending",
      },
      showOverlay: false,
      deductible: "",
      zipRules: [
        (v) => (v && v.length == 5) || " Zip Code should be five digits long",
      ],
      clientId: "",
      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
    };
  },
  created() {
    this.getClients();
  },
  methods: {
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    getClients() {
      api()
        .get(`/vendors/clients`)
        .catch((err) => {
          this.load = false;
        })
        .then((res) => {
          if (res && res.data) {
            this.clientId = res.data[0].id;
          }
        });
    },
    saveItem() {
      let data = {
        firstName: this.item.member.firstName,
        middleName: this.item.member.middleName,
        lastName: this.item.member.lastName,
        phone: this.item.member.phone,
        email: this.item.member.email,
        zipCode: this.item.member.zipCode,
        companyName: this.item.employer.companyName,
        payerId: this.item.employer.payerID,
        groupId: this.item.employer.groupID,
        planId: this.item.employer.planID,
        memberId: this.item.employer.memberID,
        nextVisit: "2022-10-14",
        lastVisit: "2022-10-14",
        clientId: this.clientId,
        deductible: this.deductible,
      };

      api()
        .post("/dpc/eligibility", data)
        .then((res) => {
          api()
            .post("/vendors/members", data)
            .catch((err) => {
              if (err) {
                this.$refs.error_modal.openPopUp(err.response.data.message);
              }
            })
            .then((res) => {
              if (res) {
                this.$parent.getData();
                this.$refs.success_modal.openPopUp("Member added successfully");
              }
            });
        });
    },
    closeSuccessModal() {
      this.$router.push("/vendors/members");
    },
    closeModal() {
      this.wizardData.steps = 1;
      this.showOverlay = false;
      this.paymentDialog = false;
      this.proccessPaymentDialog = false;
      this.sendPaymentLinkDialog = false;
      this.checkNowDialog = false;
      this.dialog = false;
    },
  },
};
</script>
<style>
</style>
