import DPCMemberIndex from '../views/DPCMember.vue'
import Home from '../components/Sections/DPCMember/Home/Home.vue'
import Marketplace from '../components/Sections/DPCMember/Marketplace/Index.vue'
import Account from '../components/Sections/DPCMember/Account/Index.vue'
import AccountMenu from '../components/Sections/DPCMember/Account/Menu.vue'
import FamilyMembersMenu from '../components/Sections/DPCMember/Account/FamilyMembers/Menu.vue'
import FamilyMember from '../components/Sections/DPCMember/Account/FamilyMembers/FamilyMember.vue'
import FamilyMembersIndex from '../components/Sections/DPCMember/Account/FamilyMembers/Index.vue'
import Personal from '../components/Sections/DPCMember/Account/Personal.vue'
import PersonalEdit from '../components/Sections/DPCMember/Account/PersonalEdit.vue'
import FindCare from '../components/Sections/DPCMember/FindCare/Home.vue'
import FindCareSearch from '../components/Sections/DPCMember/FindCare/Search.vue'
import FindCareSearchFilters from '../components/Sections/DPCMember/FindCare/Filters.vue'
import FamilyMemberAdd from '../components/Sections/DPCMember/Account/FamilyMembers/Add.vue'
import FamilyMemberEdit from '../components/Sections/DPCMember/Account/FamilyMembers/Edit.vue'
import AlertPage from '../components/Sections/DPCMember/Account/AlertPage.vue'
import Settings from '../components/Sections/DPCMember/Account/Settings.vue'
import SettingsChangePassword from "@/components/Sections/DPCMember/Account/SettingsChangePassword";
import ContactUsIndex from "../components/Sections/DPCMember/ContactUs/Index.vue";
import ContactUs from "../components/Sections/DPCMember/ContactUs/ContactUs.vue";
import AuthIndex from "../components/Sections/DPCMember/Auth/Index.vue";
import Entry from "../components/Sections/DPCMember/Auth/Entry.vue";
import Register from "../components/Sections/DPCMember/Auth/Register.vue";
import Alert from '../components/Sections/DPCMember/Auth/Alert.vue'
import Login from '../components/Sections/DPCMember/Auth/Login.vue'
import ConfirmEmail from '../components/Sections/DPCMember/Auth/ConfirmEmail.vue'
import MemberChangePassword from '../components/Sections/DPCMember/Auth/MemberChangePassword.vue'

function routeGuard(to, from, next) {
    let user = JSON.parse(localStorage.getItem('user'));
    if(!user){
        next('/dpc-member/login');
    }
    else if ( user.type != 'tpa' || !$cookies.get('token')){
        next('/dpc-member/login');
    }else{
        next();
    }
}
const router = [
    {
        path:'/dpc-member/login',
        name:'MemberLogin',
        component:Login,
    },
   {
       component:DPCMemberIndex,
       path:'/dpc-member',
       name:'dpcIndex',
       beforeEnter: routeGuard,
       redirect: () => {
           return { path: '/dpc-member/home'}
       },
       children:[
           {
               path:'home',
               name:'Home',
               component:Home,
           },
           {
               path:'find-care',
               name:'FindCare',
               component:FindCare,
           },
           {
               path:'find-care/search',
               name:'FindCareSearch',
               component:FindCareSearch,
           },
           {
               path:'find-care/search/filters',
               name:'FindCareSearchFilters',
               component:FindCareSearchFilters,
           },
           {
               path:'marketplace',
               name:'marketplace',
               component:Marketplace,
           },
           {
            path:'account',
            name:'account',
            component:Account,
            redirect: () => {
                return { path: '/dpc-member/account/index'}
            },
            children:[
                {
                    path:'index',
                    name:'accountIndex',
                    component:AccountMenu,
                },
                {
                    path:'alert',
                    name:'alertPage',
                    component:AlertPage,
                },
                {
                    path:'personal',
                    name:'accountFamilyMembers',
                    component:Personal,
                },
                {
                    path:'personal/edit/:id',
                    name:'personalEdit',
                    component:PersonalEdit,
                },
                {
                    path:'family-members',
                    name:'familyMembersIndex',
                    component:FamilyMembersIndex,
                    redirect: () => {
                        return { path: '/dpc-member/account/family-members/menu'}
                    },
                    children:[
                        {
                            path:'menu',
                            name:'familyMembersMenu',
                            component:FamilyMembersMenu,
                        },
                        {
                            path:'family-member/:id',
                            name:'familyMember',
                            component:FamilyMember,
                        },
                        {
                            path:'add',
                            name:'addFamilyMember',
                            component:FamilyMemberAdd,
                        },
                        {
                            path:'edit/:id',
                            name:'editFamilyMember',
                            component:FamilyMemberEdit,
                        },
                    ]
                },
                {
                    path:'settings',
                    name:'accountSettings',
                    component:Settings,
                },
                {
                    path:'settings/change-password',
                    name:'accountSettingsChangePassword',
                    component:SettingsChangePassword,
                }
            ]
        },
           {
               path:'contact-us',
               name:'contactUsIndex',
               component:ContactUsIndex,
               redirect: () => {
                   return { path: '/dpc-member/contact-us/index'}
               },
               children:[
                   {
                       path:'index',
                       name:'contactUs',
                       component:ContactUs,
                   },
               ]
           },
           {
               path:'auth',
               name:'authIndex',
               component:AuthIndex,
               redirect: () => {
                   return { path: '/dpc-member/auth/index'}
               },
               children:[
                   {
                       path:'index',
                       name:'Entry',
                       component:Entry,
                   },
                   {
                       path:'register',
                       name:'MemberRegister',
                       component:Register,
                   },
                   {
                       path:'alert',
                       name:'Alert',
                       component:Alert,
                   },
                   {
                       path:'confirm-email',
                       name:'ConfirmEmail',
                       component:ConfirmEmail,
                   },
                   {
                       path:'change-password',
                       name:'MemberChangePassword',
                       component:MemberChangePassword,
                   },
               ]
           },
       ]
   },

]

export default router;