
import DPCsearch from '../components/Sections/Website/DPCsearch.vue'
import TPAsearch from '../components/Sections/Website/TPAsearch.vue'
import WebsiteTool from '../components/Sections/Website/WebsiteTools.vue'
import WebsiteToolP from '../components/Sections/Website/WebsiteToolsPercentage.vue'
import MemberCountTool from '../components/Sections/Website/MemberCountTool.vue'

const router = [
    {
       path:'/tools/dpcezipsearch',
    name:'DPC search',
    component:DPCsearch,  
    },
    {
        path:'/tools/tpaezipsearch',
     name:'TPA search',
     component:TPAsearch,  
     },
   {
      path:'/membercount',
      name:'MemberCountTool',
      component:MemberCountTool,  
   },
     {
      path:'/website-tools',
   name:'Website Tools',
   component:WebsiteTool,  
   },
   
   {
   path:'/website-toolsp',
   name:'Website Tools Percentage',
   component:WebsiteToolP,  
   },
   
     
]
export default router