var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_vm._m(0),(_vm.solution)?_c('div',[_c('div',{staticClass:"row form-row-height pt-2 pb-2"},[_c('div',{staticClass:"col-md-2",staticStyle:{"border-right":"1px solid #eeeded"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-gray"},[_c('span',{class:_vm.tab == 'general'
                  ? 'ml-4 font-weight-bold light-blue-text'
                  : 'ml-4 font-weight-bold'},[_vm._v("General Information")])]),_c('div',{staticClass:"col-12 mt-6 text-gray"},[_c('span',{class:_vm.tab == 'rates'
                  ? 'ml-4 font-weight-bold light-blue-text'
                  : 'ml-4 font-weight-bold'},[_vm._v("Services & Fees")])]),_c('div',{staticClass:"col-12 mt-6 text-gray"},[_c('span',{class:_vm.tab == 'contracts'
                  ? 'ml-4 font-weight-bold light-blue-text'
                  : 'ml-4 font-weight-bold '},[_vm._v("Contract(s)")])]),_c('div',{staticClass:"col-12 mt-6 text-gray"},[_c('span',{class:_vm.tab == 'acceptance'
                  ? 'ml-4 font-weight-bold  light-blue-text'
                  : 'ml-4 font-weight-bold'},[_vm._v("Acceptance")])])])]),_c('div',{staticClass:"col-md-8"},[(_vm.solution && _vm.tab == 'general')?_c('GeneralInformation',{attrs:{"item":_vm.solution}}):_vm._e(),(_vm.solution && _vm.tab == 'rates')?_c('Rates',{attrs:{"item":_vm.solution},on:{"getDefService":_vm.getDefService,"getVendorRate":_vm.getVendorRate,"getTpaFee":_vm.getTpaFee}}):_vm._e(),(_vm.solution && _vm.tab == 'contracts')?_c('Contracts',{attrs:{"item":_vm.solution},on:{"getContract":_vm.getContract,"base64file":_vm.base64file}}):_vm._e(),(_vm.solution && _vm.tab == 'acceptance')?_c('Acceptance',{attrs:{"item":_vm.solution},on:{"getAcceptedByTpa":_vm.getAcceptedByTpa,"getClient":_vm.getClient}}):_vm._e()],1)])]):_vm._e(),_c('div',{staticClass:"gray-top-border"},[_c('div',{staticClass:"col-md-12 f-lex",attrs:{"align":"end"}},[_c('span',{staticClass:"light-blue-text text-underline mr-10 font-weight-bold pointer",on:{"click":function($event){return _vm.backTab(_vm.tab)}}},[_vm._v(" Back ")]),(_vm.tab != 'acceptance')?_c('span',{staticClass:"light-blue-text text-underline mr-10 font-weight-bold pointer",on:{"click":function($event){return _vm.nextTab(_vm.tab)}}},[_vm._v(" Next ")]):_c('span',{staticClass:"light-blue-text text-underline mr-10 font-weight-bold pointer",on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Submit ")])])]),_c('successModal',{ref:"success_modal",on:{"confirm":_vm.closeSuccessModal}}),_c('errorModal',{ref:"error_modal",on:{"confirm":_vm.closeErrorModal}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row border-blue-bottom mb-3"},[_c('div',{staticClass:"col-md-12 mb-4 mt-4 ml-4 font-weight-bold text-gray"},[_vm._v(" Activate Vendor ")])])
}]

export { render, staticRenderFns }