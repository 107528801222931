<template>
    <div>
       <PriorTable v-if="route == `/employer-advisor/employers/${routeParams}/prior-auth`"></PriorTable>
       <SinglePrior  v-if="route == `/employer-advisor/employers/${routeParams}/prior-auth/details`"></SinglePrior>
       <Rules v-if="route == `/employer-advisor/employers/${routeParams}/prior-auth/rules`"></Rules>
       <AddRules v-if="route == `/employer-advisor/employers/${routeParams}/prior-auth/add-rule`"></AddRules>
       <RuleDetail v-if="route == `/employer-advisor/employers/${routeParams}/prior-auth/rule-detail`"></RuleDetail>

    </div>
</template>
<script>
import PriorTable from './PriorTable.vue'
import PriorRequest from './PriorRequest.vue'
import PriorDetermination from './PriorDetermination.vue'
import SinglePrior from './SinglePrior.vue'
import Rules from './PriorRules.vue'
import AddRules from './AddRules.vue'
import RuleDetail from './RuleDetail.vue'
export default{
    components:{
        PriorTable,
        PriorRequest,
        PriorDetermination,
        SinglePrior,
        Rules,
        AddRules,
        RuleDetail
    },
    computed:{
        route(){
             return this.$route.path.replace(/%20/g,' ')
        },
        routeParams(){
            return this.$route.params.name
        }
    },

    data(){
        return{

        }
    },
    
}
</script>