<template>
  <div class="col-md-12">
    <div class="row" v-if="!isChildren">
      <div v-for="(item, index) in myTab" class="col-md-3" :key="index">
        <v-card class="text-center font-weight-bold pt-4 cursor-pointer">
          <div class="row text-center justify-center">
            <div class="height-items-card-img col-xl-9 col-lg-8">
              <img
                :src="item.img"
                alt=""
                class="ml-xl-10 mr-lg-0"
                :width="item.width"
                :height="item.height"
                style="object-fit: contain"
              />
            </div>

            <div
              class="text-end col-md-1"
              @click="fillArray(item, index), (item.rating = !item.rating)"
            >
              <v-rating
                v-if="item.rating"
                :value="1"
                length="1"
                background-color="orange lighten-3"
                color="orange"
                large
              ></v-rating>
              <v-rating
                v-else
                length="1"
                background-color="orange lighten-3"
                color="orange"
                large
              ></v-rating>
            </div>
          </div>

          <div class="height-items-card mt-10">{{ item.desc1 }}</div>
          <div class="height-items-card">{{ item.desc2 }}</div>
          <div
            class="pointer text-underline light-blue-text pb-4"
            @click="showSingleSolution(item)"
          >
            Details
          </div>
        </v-card>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      selectRate: false,
      mySolutions: [],
      singleSolution: null,
      filterArray: [],
      cards: [
        {
          img: "/cards/lyra2 1.png",
          desc1: "A Smart Approach to Emotional Health",
          desc2: "Mental Health Services",
          width: "159",
          height: "65",
          rating: false,
        },
        {
          img: "/cards/smartlight 1.png",
          desc1: "Reducing Employer Healthcare Costs through Data",
          desc2: "Fraud Waste & Abuse, Analytics",
          width: "238",
          height: "65",
          rating: false,
        },
        {
          img: "/cards/vizient 1.png",
          desc1: "Nationwide High Value Network",
          desc2: "Value Based Care, Bundled Services",
          width: "210",
          height: "65",
          rating: false,
        },
        {
          img: "/cards/ehir 1.png",
          desc1: "Digital Direct Vendor Management",
          desc2: "Health Plan Operations Support",
          width: "196",
          height: "99",
          rating: false,
        },
        {
          img: "/cards/htalogo 1.png",
          desc1: "National Network DPC",
          desc2: "Direct Primary Care",
          width: "217",
          height: "98",
          rating: false,
        },
        {
          img: "/apaly1.png",
          desc1: "National Network DPC",
          desc2: "Direct Primary Care",
          width: "222",
          height: "98",
          rating: false,
        },
      ],
    };
  },
  props: {
    tab: {
      type: String,
    },
  },
  computed: {
    ...mapGetters(["getSolutionTab", "getMarketSearchVal"]),
    myTab() {
      let arr = this.cards;

      if (this.getSolutionTab && this.getSolutionTab != "all") {
        arr = this.mySolutions;
        if (this.getMarketSearchVal) {
          arr = this.filterArray.filter((el) => {
            if (
              el.desc1 &&
              el.desc1
                .toString()
                .toLowerCase()
                .includes(this.getMarketSearchVal.toLowerCase())
            ) {
              return el.desc1
                .toString()
                .toLowerCase()
                .includes(this.getMarketSearchVal.toLowerCase());
            } else if (
              el.desc2 &&
              el.desc2
                .toString()
                .toLowerCase()
                .includes(this.getMarketSearchVal.toLowerCase())
            ) {
              return el.desc2
                .toString()
                .toLowerCase()
                .includes(this.getMarketSearchVal.toLowerCase());
            }
          });
        }
      }
      if (this.getMarketSearchVal) {
        arr = this.filterArray.filter((el) => {
          if (
            el.desc1 &&
            el.desc1
              .toString()
              .toLowerCase()
              .includes(this.getMarketSearchVal.toLowerCase())
          ) {
            return el.desc1
              .toString()
              .toLowerCase()
              .includes(this.getMarketSearchVal.toLowerCase());
          } else if (
            el.desc2 &&
            el.desc2
              .toString()
              .toLowerCase()
              .includes(this.getMarketSearchVal.toLowerCase())
          ) {
            return el.desc2
              .toString()
              .toLowerCase()
              .includes(this.getMarketSearchVal.toLowerCase());
          }
        });
      }
      return arr;
    },
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
  },
  created() {
    this.filterArray = this.cards;
  },
  methods: {
    fillArray(item, index) {
      if (!item.rating) {
        this.mySolutions.push(item);
      } else {
        this.mySolutions.splice(this.mySolutions.indexOf(item), 1);
      }
    },
    showSingleSolution(item) {
      //

      this.$store.commit("singleSolution", item);
      this.$router.push("/marketplace/home/details");
    },
  },
};
</script>
<style>
.height-items-card {
  height: 4rem;
}
.height-items-card-img {
  height: 5rem;
}
</style>
