<template>
  <div class="no-padding row-height">
    <v-row class="col-md-12">
      <v-col class="col-xl-1 col-lg-4 col-md-4" md="4" cols="12">
        <h3>
          <span class="font">Employers </span>
        </h3>
      </v-col>
      <v-col class="col-xl-2 col-lg-3 col-md-3" md="3" cols="12">
        <v-text-field
          class="input-control"
          v-if="filteredArray.length > 0"
          @input="searchEmp"
          prepend-inner-icon="mdi-magnify"
          light
          dense
          outlined
          placeholder="Search Employers"
        >
        </v-text-field>
      </v-col>
      <v-col
        class="col-xl-9 col-lg-5 col-md-5 text-end pr-0"
        v-if="$role != 'user'"
        cols="12"
        align="end"
      >
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" class="my-1 blue-buttons">
              Add / Invite Employer</v-btn
            >
          </template>
          <v-list>
            <v-list-item>
              <a
                class="a-tag underline-text"
                @click="$router.push('/provider/employers/add-new')"
                >Add Employer</a
              >
            </v-list-item>
            <v-list-item>
              <a
                class="a-tag underline-text"
                @click="$router.push('/provider/employers/invite-employer')"
                >Invite Employers</a
              >
            </v-list-item>
            <!-- <v-list-item>
                <a class="a-tag underline-text" >Copy Invite Link</a>
            </v-list-item> -->
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <div class="col-md-12 text-center justify-center" v-if="readyToShow">
      <v-progress-circular
        indeterminate
        :size="100"
        color="primary"
        class="text-center"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-row class="col-md-12" v-if="allEmployers.length < 1">
        <v-col md="12">
          You Have Not Yet Added any Employer to your Account
          <a
            v-if="$role != 'user'"
            @click="$router.push('/provider/employers/add-new')"
            >- Click Here to Add Employer.</a
          >
        </v-col>
      </v-row>
      <v-simple-table fixed-header height="630px" class="no-padding" v-else>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Company Name</th>
              <th class="text-left"># of Employees</th>
              <th class="text-left"># of Members</th>
              <th class="text-left">Contract</th>
            </tr>
          </thead>
          <tbody v-if="allEmployers.length > 0">
            <tr
              v-for="(item, index) in allEmployers"
              :key="index"
              v-if="contractToView"
            >
              <td v-if="item.displayName">
                <a @click="singleEmp(item)">{{ item.displayName }}</a>
              </td>
              <td v-else>
                <a @click="singleEmp(item)">{{ item.companyName }}</a>
              </td>
              <td class="text-gray">
                <span v-html="item.noEmployees ? item.noEmployees : '/'"></span>
              </td>
              <td class="text-gray">
                <span
                  v-html="item.noPlanMembers ? item.noPlanMembers : '/'"
                ></span>
              </td>
              <td
                :class="
                  item.status == 'contracted' ? 'green--text' : 'text-gray'
                "
                v-html="
                  item.status == 'contracted'
                    ? 'Contracted '
                    : item.status == 'pending'
                    ? 'Pending'
                    : '/'
                "
              ></td>
            </tr>
            <tr v-else>
              <td v-if="item.displayName">
                <a @click="singleEmp(item)">{{ item.displayName }}</a>
              </td>
              <td v-else>
                <a @click="singleEmp(item)">{{ item.companyName }}</a>
              </td>
              <td><a @click="showContract">Contract to View </a></td>
              <td><a @click="showContract">Contract to View </a></td>
              <td><a @click="showContract">Contract to View </a></td>
            </tr>
          </tbody>
          <div v-if="allEmployers.length < 1 && filterItems">
            <v-col md="12" cols="12">
              <v-alert
                border="bottom"
                colored-border
                type="warning"
                elevation="2"
                class="text-gray"
              >
                Sorry! No results found
              </v-alert>
            </v-col>
          </div>
        </template>
      </v-simple-table>
      <v-dialog v-model.trim="dialog" width="600">
        <v-card>
          <v-card-title class="text-h5"> PDF CONTRACT </v-card-title>
          <v-card-text>
            When user clicks “View Contract” on the Employer list from prior
            screen, then a PDF version of the contract is presented for the
            Provider user to view. This signature page of this PDF contract is
            populated with the Employer’s information (i.e. the Employer that
            they selected on the prior screen), and the compensation addendum is
            populated with the Provider’s contract rates. The user can view,
            print, and save this PDF.
          </v-card-text>
          <v-card-actions>
            <div class="col-md-12 text-end">
              <v-btn class="blue-buttons text-end" @click="dialog = false"
                >Close</v-btn
              >
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import api from "../../../../services/api";
export default {
  data() {
    return {
      items: [],
      allEmployers: [],
      response: [],
      contractToView: false,
      dialog: false,
      filterItems: false,
      readyToShow: false,
    };
  },
  created() {
    this.getAllEmployers();
    this.getAllContracts();
  },
  computed: {
    filteredArray() {
      return this.response;
    },
  },
  methods: {
    searchEmp(val) {
      this.allEmployers = this.filteredArray.filter((el) => {
        if (
          el.displayName &&
          el.displayName.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.displayName.toLowerCase().includes(val.toLowerCase());
        } else if (
          el.companyName &&
          el.companyName.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.companyName.toLowerCase().includes(val.toLowerCase());
        }
      });
      if (this.allEmployers.length < 1) {
        this.filterItems = true;
      }
    },
    getAllContracts() {
      this.readyToShow = true;
      let user = JSON.parse(localStorage.getItem("user"));
      let provider = user.entity._id;

      api()
        .get(`providers/${provider}/contract`)
        .catch((err) => {
          if (err) {
            this.contractToView = false;
            this.$store.commit("setEmpContractStatus", this.contractToView);
            this.readyToShow = false;
          }
        })
        .then((res) => {
          if (res) {
            this.contractToView = true;
            this.$store.commit("setEmpContractStatus", this.contractToView);
            this.readyToShow = false;
          }
        });
    },
    singleEmp(item) {
      let user = JSON.parse(localStorage.getItem("user"));
      let provider = user.entity._id;
      api()
        .get(`/providers/${provider}/employers/${item._id}`)
        .then((res) => {
          if (res) {
            this.$router.push(`/provider/employers/employer`);
            this.$store.commit("setSingleEmployer", res.data.employer);
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    showContract() {
      this.$router.push("/provider/contracts");
    },
    getAllEmployers() {
      this.readyToShow = true;

      let user = JSON.parse(localStorage.getItem("user"));
      let provider = user.entity._id;
      api()
        .get(`/providers/${provider}/employers`)
        .then((res) => {
          this.allEmployers = res.data.employers;
          this.response = res.data.employers;
          this.readyToShow = false;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
  },
};
</script>
<style>
</style>