<template>
  <v-dialog v-model.trim="heatMapModal" width="100%" class="success-modal">
    <v-card>
      <span align="end" class="pt-2 pr-0">
        <p class="font-weight-bold text-gray pr-3 pt-2" @click="heatMapModal = false">
          X
        </p>
      </span>
      <v-card-text class="success-modal pt-5">
        <iframe :src="mapLink"></iframe>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import api from "../../../../services/api";
export default {
  props: {
    mapLink: {
      type: String,
    },
    heatMapModal: {
      type: Boolean,
    },
  },

  data() {
    return {};
  },
  computed: {},
  created() {
    console.log('asd')
  },
  watch: {},
  methods: {},
};
</script>
