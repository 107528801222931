<template>
    <div class="no-padding no-margin"> 
        <ContractsTable v-if="route == `/provider-advisor/providers/${routeParams}/contracts`"></ContractsTable>
        <AddContract v-if="route == `/provider-advisor/providers/${routeParams}/contracts/${routeParamsType}/add-new`"></AddContract>
        <RequestNewContract  v-if="route == `/provider-advisor/providers/${routeParams}/contracts/request`"></RequestNewContract>
        <Contract  v-if="route ==  `/provider-advisor/providers/${routeParams}/contracts/contract`"></Contract>
        <DetailedContract  v-if="route ==  `/provider-advisor/providers/${routeParams}/contracts/detailed-contract`"></DetailedContract>
        <Detail v-if="route ==  `/provider-advisor/providers/${routeParams}/contracts/detail`"></Detail>
        <SelectType v-if="route ==  `/provider-advisor/providers/${routeParams}/contracts/contract-type`"> </SelectType>
    </div>
</template>
<script>
import AddContract from './AddContract.vue'
import Contract from './Contract.vue'
import ContractResult from './ContractResult.vue'
import ContractsTable from './ContractsTable.vue'
import RequestNewContract from './RequestNewContract.vue'
import DetailedContract from './DetailedContract/DetailedContract.vue'
import Detail from './Detail/Detail.vue'
import SelectType from './SelectType.vue'

export default {
    components:{
        AddContract,
        Contract,
        ContractResult,
        RequestNewContract,
        ContractsTable,
        DetailedContract,
        Detail,
        SelectType
    },
    data(){
        return{
         
        }
    },
    computed:{
        route(){
            return this.$route.path.replace(/%20/g, ' ')
        },
        routeParams(){
          return this.$route.params.name
        },
        routeParamsType(){
             return this.$route.params.type
        }
    }

}
</script>