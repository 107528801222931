<template>
  <div class="container-fluid background-gray">
    <Header @expantSideBarOnHover="expantSideBarOnHover" @hideSideBar="hideSideBar"/>
    <v-row class="background-gray">
      <div class="pb-0 vh-height sidebar-wrapper" v-if="showSideBar && !display">
        <SideBar :mini="miniClass" @closeSideBar="closeSideBar" :windowWidth="display" :showSideBar="showSideBar"></SideBar>
      </div>
      <div :class=" showSideBar ? 'pb-0  vh-height sidebar-wrapper' : 'pb-0 vh-height sidebar-wrapper-mini'" v-else-if="display">
        <SideBar :mini="miniClass" @closeSideBar="closeSideBar" :windowWidth="display" :showSideBar="showSideBar"></SideBar>
      </div>
      <div v-if="mobileSideBar" :class="!showSideBar ? 'main-wrapper' : 'main-wrapper-mini'">
        <div class="context no-padding-left">
          <div class="row no-padding">
            <RoutePath :path="path"></RoutePath>
          </div>
          <BlueNavbar :tab="tab"></BlueNavbar>
          <div :class="hideWhiteBack ? 'home-dpc-tables' : 'white-background digital-tables'">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </v-row>
  </div>
</template>
<script>
import Header from "../components/Sections/Tpa/Layout/Header.vue";
import SideBar from "../components/Sections/Tpa/Layout/SideBar.vue";
import RoutePath from "../components/Sections/Tpa/Layout/RoutePath.vue";
import BlueNavbar from "../components/Sections/Tpa/Layout/BlueNavbar.vue";

export default {
  components: {
    Header,
    SideBar,
    RoutePath,
    BlueNavbar,
  },
  data() {
    return {
      sideBar: true,
      showSideBar: false,
      display: true,
      windowWidth: window.innerWidth,
      page: "",
      clientName: "",
      miniClass: false,
      clientItem: null,
      path: "",
      tab: "",
    };
  },
  created() {
    this.display = (this.windowWidth >= 959)
  },
  computed: {
    hideWhiteBack() {
      return ["/tpa", "/tpa/home", "/tpa/profile", "/tpa/invoices/details/payment"].includes(this.$route.path);
    },

    mobileSideBar() {
      let show;
      if (this.display) {
        show = true;
      } else if (!this.showSideBar && !this.display) {
        show = true;
      }
      return show;
    },
    route() {
      return this.$route.path;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    getProvidersByClient(item) {
      if (item) {
        this.clientItem = item;
        this.clientName = item.name;
      }
    },
    closeSideBar() {
      if (!this.display) {
        this.showSideBar = false;
      } else {
        this.showSideBar = true;
      }
      this.display = (this.windowWidth >= 959)
    },
    getClientName(name) {
      this.clientName = name;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
      this.display = (this.windowWidth >= 959)
    },
    hideSideBar() {
      this.showSideBar = !this.showSideBar;
    },
    expantSideBarOnHover(mini) {
      this.showSideBar = !this.showSideBar;
      this.miniClass = !this.miniClass;
    },
  },
};
</script>
