<template>
  <div class="col-md-12 no-padding row-height">
    <v-row class="col-md-12 no-padding">
      <v-col class="col-md-8" md="8" cols="12">
        <h3>
          <span class="font">Employers</span>
        </h3>
      </v-col>

      <v-col
        class="col-md-4 text-end"
        cols="12"
        align="end"
        v-if="$role != 'user'"
      >
        <v-btn
          class="my-1 blue-buttons"
          @click="
            $store.commit('routeFromHomeCard', false),
              $router.push('/employer-advisor/employers/add-new')
          "
        >
          Add Employer
        </v-btn>
      </v-col>
    </v-row>
    <div class="col-md-12 text-center justify-center" v-if="!resultsReady">
      <v-progress-circular
        indeterminate
        :size="100"
        color="primary"
        class="text-center"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-row v-if="allEmployers.length < 1">
        <v-col md="12" class="ml-3">
          You Have Not Yet Added any Employers to your Account
          <a
            v-if="$role != 'user'"
            @click="$router.push('/employer-advisor/employers/add-new')"
            >- Click Here to Add An Employer</a
          >
        </v-col>
      </v-row>
      <v-simple-table fixed-header height="630px" class="no-padding" v-else>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Company Name</th>
              <th class="text-left"># of Employees</th>
              <th class="text-left"># of Members</th>
              <th class="text-left">Providers Coverage</th>
              <th class="text-left">Est.Avg. Charges</th>
              <th class="text-left">Est.Avg. Savings</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in allEmployers" :key="index">
              <td v-if="item.displayName">
                <a @click="singleEmp(item)">{{ item.displayName }}</a>
              </td>
              <td v-else><a @click="singleEmp(item)">/</a></td>
              <td v-html="item.noEmployees ? item.noEmployees : '/'"></td>
              <td v-html="item.noPlanMembers ? item.noPlanMembers : '/'"></td>
              <td
                v-html="item.providerCoverage ? item.providerCoverage : '/'"
              ></td>
              <td
                v-html="item.projectedCharges ? item.projectedCharges : '/'"
              ></td>
              <td
                v-html="item.projectedSavings ? item.projectedSavings : '/'"
              ></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import api from "../../../../services/api";
export default {
  data() {
    return {
      items: [],
      allEmployers: [],
      response: [],
      resultsReady: false,
    };
  },
  created() {
    this.getAllEmployers();
  },
  computed: {
    filteredArray() {
      return this.response;
    },
  },
  methods: {
    searchEmp(val) {
      this.allEmployers = this.filteredArray.filter((el) => {
        if (el.companyName.toLowerCase().includes(val.toLowerCase())) {
          return el.companyName.toLowerCase().includes(val.toLowerCase());
        }
      });
    },
    singleEmp(item) {
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.employerAdvisor;

      api()
        .get(`/advisors/${advisorId}/employers/${item._id}`)
        .then((res) => {
          if (res) {
            api()
              .get(`/advisors/${advisorId}/employers/${item._id}/statistics`)
              .then((res) => {
                this.$store.commit(
                  "setStatisticsInfoHorizontalMenu",
                  res.data.statistics
                );
              });
            this.$store.commit("setSingleEmployer", res.data);
            localStorage.setItem("employer", JSON.stringify(item));
            this.$router.push("/employer-advisor/employers/employer");
            // this.$router.push({path:'/employer-advisor/employers/'+ item.companyName, params:{name:item.companyName}})
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    getAllEmployers() {
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.employerAdvisor;
      api()
        .get(`/advisors/${advisorId}/employers`)
        .then((res) => {
          this.allEmployers = res.data.employers;
          this.response = res.data.employers;
          this.resultsReady = true;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
  },
};
</script>
<style>
</style>