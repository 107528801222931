<template>
    <div class="no-padding no-margin">
        <ContractsTable v-if="route == `/employer-advisor/employers/${routeParams}/contracts`"></ContractsTable>
        <AddContract v-if="route == `/employer-advisor/employers/${routeParams}/contracts/add-new`"></AddContract>
        <RequestNewContract  v-if="route == `/employer-advisor/employers/${routeParams}/contracts/request`"></RequestNewContract>
        <Contract  v-if="route ==  `/employer-advisor/employers/${routeParams}/contracts/contract`"></Contract>
    </div>
</template>
<script>
import AddContract from './AddContract.vue'
import Contract from './Contract.vue'
import ContractResult from './ContractResult.vue'
import ContractsTable from './ContractsTable.vue'
import RequestNewContract from './RequestNewContract.vue'

export default {
   components:{
    AddContract,
    Contract,
    ContractResult,
    RequestNewContract,
    ContractsTable
    },
    data(){
        return{
         
        }
    },
    computed:{
        route(){
            return this.$route.path.replace(/%20/g,' ')
        },
        routeParams(){
          return this.$route.params.name
        }
    }

}
</script>