
import DPCOrganizations from '../components/Sections/Tests/DPC/DPCOrganizations.vue'
import BillingDPC from '../components/Sections/Tests/DPC/Organizations/PeopleOneHealth/Billing/Billing.vue'
import MembersDPC from '../components/Sections/Tests/DPC/Organizations/PeopleOneHealth/Members/Members.vue'
import MemberDPC from '../components/Sections/Tests/DPC/Organizations/PeopleOneHealth/Members/Member.vue'
import ClientsDPC from '../components/Sections/Tests/DPC/Organizations/PeopleOneHealth/Clients/Clients.vue'
import PeopleHealth from '../components/Sections/Tests/DPC/Organizations/PeopleOneHealth/PeopleOneHelath.vue'
import SettingsDPC from '../components/Sections/Tests/DPC/Organizations/PeopleOneHealth/Settings/Settings.vue'
import LocationsDPC from '../components/Sections/Tests/DPC/Organizations/PeopleOneHealth/Settings/Locations/Locations.vue'
import ProvidersDPC from '../components/Sections/Tests/DPC/Organizations/PeopleOneHealth/Settings/Providers/Providers.vue'
import RatesDPC from '../components/Sections/Tests/DPC/Organizations/PeopleOneHealth/Settings/Rates/Rates.vue'



const router = [

    {
        path:'/dpc-organizations',
        name:'DPC',
        component:DPCOrganizations,
    },
    {
        path:'/dpc-organizations/people-health',
        name:'People One Health',
        component:PeopleHealth,
    },
    {
        path:'/dpc-organizations/people-health/billing',
        name:'People One Health',
        component:BillingDPC,
    },
    {
        path:'/dpc-organizations/people-health/clients',
        name:'Clients',
        component:ClientsDPC,
    },
    {
        path:'/dpc-organizations/people-health/members',
        name:'Members',
        component:MembersDPC,
    },
    {
        path:'/dpc-organizations/people-health/settings',
        name:'Settings',
        component:SettingsDPC,
    },
    {
        path:'/dpc-organizations/people-health/settings/locations',
        name:'Locations',
        component:LocationsDPC,
    },
    {
        path:'/dpc-organizations/people-health/settings/providers',
        name:'Providers',
        component:ProvidersDPC,
    },
    {
        path:'/dpc-organizations/people-health/settings/rates',
        name:'Rates',
        component:RatesDPC,
    },
    {
        path:'/dpc-organizations/people-health/member',
        name:'Member',
        component:MemberDPC,
    },
]




export default router;