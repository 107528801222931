
const state ={
    searchResultAdvisorsInEmployer:null,
    singleAdvisorinEmployer:null

}
const getters = {
    getAdvisorSearchResultInEmployer(state){
        return state.searchResultAdvisorsInEmployer
    },
    getSingleAdvisorinEmployer(state){
        return state.singleAdvisorinEmployer
    }

}
const mutations = {
    setAdvisorSearchResultInEmployer(state,result){
        state.searchResultAdvisorsInEmployer = result
    },
    setSingleAdvisorinEmployer(state,employer){
        state.singleAdvisorinEmployer = employer
    }
   
}

export default{
    state,
    getters,
    mutations,
}