<template>
  <div class=" row-height position-relative pa-3">
   <div v-if="!loader">

    <div class="col-12 mr-1 blue-bg ">
                <div class="text--white font-12 font-weight-400 pl-8 col-12 mb-0 pb-0" style="opacity: 60%">
                    Home / Marketplace / {{ category }}
                </div>
                <div class="col-12">
                    <div class="pa-md-4 pa-0 pt-0  text-mobile-p">
                        <!-- <span class="font-36 font-weight-500" style="color: white" >Find your personal doctor &nbsp; {{ didFilter }}</span> -->
                        <span class="font-36 font-weight-500" style="color: white">Apaly Marketplace</span>
                        <br />
                        <span class="font-20 font-weight-500" style="color: white">
                          Participate in solutions to start providing care to thousands of plan members 
                        </span>
                    </div>
                    <div class="col-12 pt-0 search-inputs emp-style">
                        <span class="col-12 col-lg-12 col-xl-12 col-md-12 d-md-flex pa-0 pt-0 mt-0">
                            <span class="mask pa-1 col-md-3">
                                <v-text-field
                    prepend-inner-icon="mdi-magnify"
                    v-model="searchQuery"
                    class="mr-3"
                    light
                    dense
                    outlined
                    hide-details
                    placeholder="Search by Name"
                    style="
                      border-radius: 8px;
                      font-size: 14px;
                      background-color: white;"
                  ></v-text-field>
                            </span>
                            <!-- <span class="pa-1 col-md-3 col-12 d-flex emp-style">
                                <v-text-field
                    prepend-inner-icon="mdi-map-marker"
                    v-model="zipOrState"
                    @keyup.enter="searchByZip"
                    class="mr-3"
                    light
                    dense
                    outlined
                    hide-details
                    placeholder="Search by Zip Code"
                    style="
                      border-radius: 8px;
                      font-size: 14px;
                      background-color: white;"
                  ></v-text-field>
                                <v-btn @click="searchByZip()" color="#0069F3" size="x-small" class="text-white"
                                    style="border-radius: 8px; width: 30px">
                                    <v-icon dark color="white"> mdi-magnify </v-icon>
                                </v-btn>
                            </span> -->
                        </span>

                    </div>
                </div>
            </div>
            <div  @click="$router.push('/dpc-providers/solutions')" role="button" class=" pa-0 col-md-12 mb-0 ml-4 mt-4  d-flex">
              <img class="mr-2" src="/svg/backArrow.svg"  @click="$router.go(-1)" />
      <p  @click="$router.go(-1)" class="mb-0 pb-0">Back</p>
    </div>
        <!-- <div class="col-md-12 col-lg-12 col-xl-12 col-8 pb-0 mb-0 d-flex">
          <span class="col-8 text-start">
            <v-sheet max-width="500">
              <v-slide-group show-arrows>
                <v-slide-item
                  v-for="(category, categoryIndex) in selectedCategories"
                  :key="'category-' + categoryIndex"
                >
                  <v-chip closable @click="removeChip(category, selectedCategories)" class="mr-1">
                    {{ category }}
                    <v-icon right> close </v-icon>
                  </v-chip>
                </v-slide-item>
                <v-slide-item
                v-for="(crendential, credentialIndex) in selectedCredentials"
                :key="'crendential-' + credentialIndex"
              >
                <v-chip closable @click="removeChip(crendential,selectedCredentials)" class="mr-1">
                  {{ crendential }}
                  <v-icon right> close </v-icon>
                </v-chip>
              </v-slide-item>
              <v-slide-item
              v-for="(gender, genderIndex) in selectedGenders"
              :key="'gender-' + genderIndex"
            >
              <v-chip closable @click="removeChip(gender,selectedGenders)" class="mr-1">
                {{ gender == 'F' ? 'Female' : 'Male' }}
                <v-icon right> close </v-icon>
              </v-chip>
            </v-slide-item>
            <v-slide-item
            v-for="(group, groupIndex) in selectedGroupAndOrganizations"
            :key="'group-' + groupIndex"
          >
            <v-chip closable @click="removeChip(group,selectedGroupAndOrganizations)" class="mr-1">
              {{ group }}
              <v-icon right> close </v-icon>
            </v-chip>
          </v-slide-item>
          <v-slide-item
            v-for="(speciality, specialityIndex) in selectedSpecialities"
            :key="'speciality-' + specialityIndex"
          >
            <v-chip closable @click="removeChip(speciality,selectedSpecialities)" class="mr-1">
              {{ speciality }}
              <v-icon right> close </v-icon>
            </v-chip>
          </v-slide-item>
              </v-slide-group>
            </v-sheet>
          </span>

          <span class="col-4 text-end">
            <v-btn
              @click.stop="drawer = !drawer"
              color="primary"
              elevation="3"
              outlined
            >
              <v-icon right dark class="mr-1 ml-0"> mdi-filter </v-icon>
              Filter solutions
            </v-btn>
          </span>
        </div> -->
        <v-navigation-drawer
          v-model.trim="drawer"
          width="350"
          absolute
          temporary
          right
          style="overflow: hidden"
        >
          <div class="scrollable-drawer">
            <div>
              <div class="drawer-header">
                <v-list-item class="apaly-bg">
                  <v-list-item-title class="d-flex justify-space-between">
                    <h4 style="color: white">Filter Solutions</h4>
                    <h3
                      role="button"
                      class="cursor-pointer"
                      @click.stop="drawer = !drawer"
                      style="color: white"
                    >
                      <v-icon right dark class="mr-1 ml-0"> mdi-close </v-icon>
                    </h3>
                  </v-list-item-title>
                </v-list-item>
              </div>
              <div
                class="drawer-content drawer-mobile-height"
                style="height: 75vh; overflow: scroll"
              >
                <v-expansion-panels
                  accordion
                  multiple
                  v-model.trim="panel"
                  class="no-shadow no-padding user-expansion-panels"
                >
                  <v-expansion-panel class="no-shadow no-padding">
                    <v-expansion-panel-header
                      class="font-18 font-weight-black change-padding-filter filter-items-font"
                      >Type</v-expansion-panel-header
                    >
                    <v-expansion-panel-content
                      class="no-padding content-filter"
                    >
                      <v-checkbox
                      class="pa-0 checkbox-label-size"
                        v-for="category in categories"
                        :key="category.value"
                        v-model="selectedCategories"
                        :label="category.label"
                        :value="category.value"
                      >
                      </v-checkbox>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel class="no-shadow no-padding">
                    <v-expansion-panel-header
                      class="font-18 font-weight-black change-padding-filter filter-items-font"
                      >Provider</v-expansion-panel-header
                    >
                    <v-expansion-panel-content
                      class="no-padding content-filter"
                    >
                      <h4 class="text-start mb-2">Credentials</h4>
                      <div class="pa-0 ma-0 mb-3 scrollable-creds">
                        <v-checkbox
                        class="pa-0 checkbox-label-size"
                          v-for="(credentials, i) in providerCredentials"
                          :key="i"
                          v-model="selectedCredentials"
                          :label="credentials"
                          :value="credentials"
                        >
                        </v-checkbox>
                      </div>
                      <v-divider horizontal> </v-divider>
                      <h4 class="text-start mb-2">Gender</h4>
                      <div class="d-flex justify-space-between pb-0 mb-0">
                        <p class="mb-0">Female</p>
                        <v-switch  lass="mb-0" v-model="selectedGenders" value="F"> </v-switch>
                      </div>
                      <div class="d-flex justify-space-between">
                        <p>Male</p>
                        <v-switch  v-model="selectedGenders" value="M"> </v-switch>
                      </div>
                      <h4 class="text-start mb-2">Specialty</h4>
                      <div class="pa-0 ma-0 mb-3 scrollable-creds">
                        <v-checkbox
                        class="pa-0 checkbox-label-size"
                          v-for="(speciality, ind) in specialities"
                          :key="ind"
                          v-model="selectedSpecialities"
                          :label="speciality"
                          :value="speciality"
                        >
                        </v-checkbox>
                      </div>
                      <v-divider horizontal> </v-divider>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel class="no-shadow no-padding">
                    <v-expansion-panel-header
                      class="font-18 font-weight-black change-padding-filter filter-items-font"
                      >Group or Organization</v-expansion-panel-header
                    >
                    <v-expansion-panel-content
                      class="no-padding content-filter"
                    >
                      <div class="pa-0 ma-0 mb-3 scrollable-creds">
                        <v-checkbox
                        class="pa-0 checkbox-label-size"
                          v-for="(grAndOrg, indx) in groupAndOrganizations"
                          :key="indx"
                          v-model="selectedGroupAndOrganizations"
                          :label="grAndOrg"
                          :value="grAndOrg"
                        >
                        </v-checkbox>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </div>
            <div class="drawer-footer">
              <v-list-item>
                <v-list-item-content class="justify-center">
                  <v-list-item-title class="d-flex justify-center">
                    <v-btn class="mr-5" @click="clearSearch()">
                      Clear All
                    </v-btn>
                    <v-btn color="primary" @click="searchResults()">
                      View results
                    </v-btn>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </div>
        </v-navigation-drawer>
   
    <v-card-title class="ma-2">
      <span class="font-weight-500 font-36" style="color: #333333">{{category}} </span>
    </v-card-title>
    <div class="row-height row col-12 ml-4 pa-0">
      <div v-if="getCategoryItems(category).length < 1" class="col-12"> 
        <v-alert border="bottom" colored-border color="primary" elevation="2" class="col-5 text-gray mt-2 ml-2">
          No results found! <a @click="clearSearch()" class="text-underline">Reset</a>
        </v-alert>
    </div>
      <div v-else class="col-12 row pa-0  mb-10" style="width: 100%">                 
        <v-card  v-for="(card,index) in getCategoryItems(category)" :key="index" height="180px" width="310px" :style="resolveMarketplaceBackgroundColor(card)"
                 class="mr-3 pa-2 mb-4 mt-4 cursor-pointer box-hover radius-8" >
        <div class="pl-1 pr-1 pb-0" @click="goToDetails(card, card.entity)">
                                            <div class="d-flex">
                                                <div v-if="card.logo" class="text-start justify-start mb-1 mt-0 card-logo"
                                                    :style="`background-image:url(${card.logo})`"></div>
                                                <div v-else class="text-start justify-start mb-1 mt-0 card-logo"
                                                    :style="`background-image:url(${returnCardImage(card.entity)})`"></div>
                                                <div class="text-start mt-0 ml-2 text-ellipsis-card-name">
                                                    <p class="font-20 font-weight-600 mb-1 mt-2" style="color: #333333">
                                                        {{ card.name }}
                                                    </p>
                                                </div>
                                            </div>

                                            <div class="text-start d-flex card_icon_text">
                                                <img class="mr-2 card_icon" src="/svg/advancedPrimaryCare.svg" />
                                                <p class="ma-0 font-14"> {{ card.category }}</p>
                                            </div>
                                            <!-- <div class="text-start d-flex card_icon_text">
                                                <img class="mr-2 card_icon" src="/svg/activeMembers.svg" />
                                                <p class="ma-0 font-14"> 0 active members</p>
                                            </div> -->
                                            <div class="">
                                                <div class="d-flex justify-space-between col-12 pl-0 pr-0">

                                                    <v-btn v-if="card.contracted" class="radius-8" height="32" disabled outlined color="#999999">
                                                        Participating
                                                    </v-btn>
                                                    <v-btn v-else class="radius-8 font-14" height="32" outlined color="#1E7BF4" @click="goToDetails(card, card.entity)">
                                                        Participate
                                                    </v-btn>
                                                    <v-btn class="radius-8 ml-auto" height="32" outlined color="#1E7BF4" @click="goToDetails(card, card.entity)">
                                                        View details
                                                    </v-btn>

                                                </div>
                                            </div>
                                        </div>
      </v-card> 
     </div>
    </div>
     </div>
     <div v-else>
      <v-overlay>
            <v-progress-circular indeterminate :size="100" color="#326ab3" class="text-center mt-10 mb-5">
                <img
                  src="/newapalylogoonlyblue.png"
                  height="70"
                  class="img-mobile"
                />
            </v-progress-circular>

        </v-overlay>
    </div>
  <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <removeDialog
      ref="dialog_delete"
      v-on:confirm="confirmRemove"
    ></removeDialog>

    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
   
     
    <router-view></router-view>
  </div>
</template>

<script>
import api from "../../../../services/api";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../TablesTemplate/ErrorDialog.vue";
import removeDialog from "../../../TablesTemplate/RemoveDialog.vue";

export default {
  components: { successModal, errorModal, removeDialog },
  data() {
    return {
      marketplace: [],
      searchQuery: "",
      loader: false,
      model: null,
      items:[],
      zipOrState: '',
      filteredItems:[],
      showOverlay: false,
      category: "",
      panel: [0, 2],
      drawer: null,
      selectedCategories: [],
      selectedCredentials: [],
      selectedSpecialities: [],
      selectedGroupAndOrganizations: [],
      female: false,
      male: false,
      selectedGenders: [],
      genders: [],
      categories: [
        { label: "Virtual Networks", value: "virtualNetwork" },
        { label: " Point Solution Vendors", value: "vendors" },
        { label: " Clinics & Organizations", value: "clinics" },
        { label: "Clinicians ", value: "clinicians" },
      ],
      tab: "all_solutions",
      searchByCategory: [],
      providerCredentials: [],
      specialities: [],
      groupAndOrganizations: [],
      searchLabels: [],
      data: {},
    };
  },
  watch: {
  },
  computed: {
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
  },
  created() {
    this.category = this.$route.params.category;
    this.getMarketplace();
  
  this.getProviderCredentials();
    this.getSpecialities();
    this.getGroupAndOrganizations();
  },
  methods: {
    removeChip(chip,selectedArray) {
      const filterArray = selectedArray;
      const chipIndex = filterArray.indexOf(chip);
      if (chipIndex !== -1) {
        filterArray.splice(chipIndex, 1);
      }
      this.searchResults();
      this.drawer = false;
    },
    getProviderCredentials() {
      api()
        .get("/market/credentials/provider")
        .then((res) => {
          this.providerCredentials = res.data;
        });
    },
    getSpecialities() {
      api()
        .get("/market/specialities")
        .then((res) => {
          this.specialities = res.data;
        });
    },
    getGroupAndOrganizations() {
      api()
        .get("/market/organization/company/name")
        .then((res) => {
          this.groupAndOrganizations = res.data;
        });
    },
    getCategoryItems(category) {
  const categoryDataArray = this.marketplace.filter(
    (item) => item.category === category
  );
  const allCategoryItems = categoryDataArray.reduce(
    (allItems, categoryData) => allItems.concat(categoryData.items),
    []
  );

  if (this.searchQuery) {
    const query = this.searchQuery.toLowerCase();
    return allCategoryItems.filter((item) => {
      const nameMatch = item.name.toLowerCase().includes(query);
      return nameMatch;
    });
  } else {
    return allCategoryItems;
  }
},
    getMarketplace() {
      this.loader=true
      api()
        .get("/dpcproviders/market")
        .then((res) => {
          this.marketplace = res.data;
          this.loader = false;
        });
    },
    goToDetails(item, entity) {
      if (entity == "solution") {
        this.$router.push(`/dpc-providers/solutions/details/${item.id}`);
      } else if (entity == "location") {
        this.$router.push({
          path: `/dpc-providers/solutions/provider/details/${item.id}`,
          query: { location: item.locationId },
        });
      } else if (entity == "vendor") {
        this.$router.push(`/dpc-providers/solutions/vendor/details/${item.id}`);
      } else if (entity == "provider") {
        this.$router.push(
          `/dpc-providers/solutions/clinician/details/${item.id}`
        );
      }
      this.showOverlay = false;
    },
    resolveMarketplaceBackgroundColor(card) {

return "border-left: 15px solid #F26621; border-radius: 8px 0 0 8px";

},
    searchResults() {
      this.searchLoader = true;
      this.data = {
        search: {
          types: this.selectedCategories,
          organizations: this.selectedGroupAndOrganizations,
          specialities: this.selectedSpecialities,
          credentials: this.selectedCredentials,
          gender: this.selectedGenders,
        },
      };
      api()
        .post("/dpcproviders/market/search", this.data)
        .catch((err) => {
          if (err) {
            this.$refs.error_modal.openPopUp(err);
          }
        })
        .then((res) => {
          this.marketplace = res.data;
          this.searchLoader = false;
          if (res.data.length < 1) {
            this.noResultsFromSearch = true;
          }
          else{
            this.noResultsFromSearch = false;
          }
          this.drawer = false;
        });
    },
    searchByZip() {
      this.searchLoader = true;
      const data = {
        search: {
          zipCode: this.zipOrState,
        },
      };
      api()
        .post("/dpcproviders/market/search", data)
        .catch((err) => {
          if (err) {
            this.$refs.error_modal.openPopUp(err);
          }
        })
        .then((res) => {
          this.marketplace = res.data;
          this.searchLoader = false;
          if (this.marketplace.length < 1) {
            this.noResultsFromSearch = true;
          }
        });
    },
    returnCardImage(entitiy) {
            if (entitiy == "solution") {
                return "/tpa/virtualNetwork.svg";
            } else if (entitiy == "location") {
                return "/tpa/clinicsAndOrganizations.svg";
            } else if (entitiy == "vendor") {
                return "/tpa/pointSolutionVendor.svg";
            } else if (entitiy == "provider") {
                return "/tpa/clinicians.svg";
            }
        },
    clearSearch() {
      this.loader = false;
      this.selectedCategories = [];
      this.selectedCredentials = [];
      this.selectedGenders = [];
      this.selectedGroupAndOrganizations = [];
      this.selectedSpecialities = [];
      this.getMarketplace();
    },
    changeTab(tab) {
      this.tab = tab;
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
    },
    confirmRemove() {},
   
  },
};
</script>
<style scoped>
.card_icon_text_inline_block {
    display: inline-block;
    width: 50%;
}

.card_icon_text {
    display: flex;
    align-items: center;
}

.card_text {
    font-size: 14px;
}

.card_icon {
    width: 16px !important;
    height: 12px !important;
}
.card_buttons {
    border: 1px solid #1E7BF4 !important;
    color: #1E7BF4;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 20px;
    border-radius: 7px;
    margin-top: 8px;
    width: 170px;
    height:32px;
}
.card_button_disabled {
    color: gray !important;
    border: 1px solid gray !important;
}
.card_buttons-pending {
    border: 1px solid gray !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 20px;
    border-radius: 7px;
    margin-top: 15px;
    cursor: not-allowed;
}
.card-logo {
    min-width: 80px;
    max-width: 80px;
    min-height: 80px;
    max-height: 80px;
    overflow: hidden;
    background-size: contain;
    background-position: center;
    border-radius: 100px;
    background-repeat: no-repeat;
    border: 0.2px solid #CCCCCC;
    background-position: center;
    background-color: white;
}
.text-ellipsis {
    height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.text-ellipsis-card-name {
    height: 65px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.active {
  color: #333333 !important;
  background-color: transparent !important;
  text-decoration: underline !important;
}
.active:hover {
  color: #333333 !important;
}
.text-ellipsis {
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.subtitle-link{
  color:#366CB3 !important
}
.box-hover:hover {
  box-shadow: 0px 4px 8px 0px rgba(51, 51, 51, 0.16);
}

</style>

