<template>
   <div>
       <div class="row">
        <v-card class="col-md-12" >
            <v-card-title>Member Search</v-card-title>
            <v-card-text class="row justify-space-between">
                 <div class="col-md-3">
                    <label for="" class="font text-gray">Member ID</label>
                    <v-text-field solo></v-text-field>
                </div>
                 <div class="col-md-3">
                    <label for="" class="font text-gray">Last Name</label>
                    <v-text-field solo></v-text-field>
                </div>
                 <div class="col-md-3">
                    <label for="" class="font text-gray">First Name</label>
                    <v-text-field solo></v-text-field>
                </div>
                 <div class="col-md-1">
                    <label for="" class="font text-gray">DOB</label>
                    <v-text-field solo></v-text-field>
                </div>
                 <div class="col-md-2">
                    <label for="" class="font text-gray">Last 4 of Social</label>
                    <v-text-field solo></v-text-field>
                </div>
                 <div class="col-md-4">
                    <label for="" class="font text-gray">City</label>
                    <v-text-field solo></v-text-field>
                </div>
                  <div class="col-md-2">
                    <label for="" class="font text-gray">State</label>
                    <v-text-field solo> </v-text-field>
                </div>
               <div class="col-md-2">
                  <label for="" class="font text-gray">Zip</label>
                  <v-text-field solo></v-text-field>
                </div>
                <div class="com-md-6 d-flex align-center text-center justify-center">
                  <v-btn class="blue-buttons mr-2">Cancel</v-btn>
                  <v-btn class="blue-buttons">Search for Member</v-btn>
                </div>
            </v-card-text>
        </v-card>
      </div>
      <div class="row justify-center aling-center mt-10">
       <v-card class="col-md-12 ">
        <div class="font row col-md-12 justify-space-between">
            <div class="col-md-10" cols="10">Recent Member Activity</div>
            <div class="col-md-1" cols="2">
                <v-btn class="blue-buttons " >Refresh</v-btn>
                   
            </div>
        </div>
        <v-simple-table fixed-header  height="630px"  class="mt-5" >
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                 Member Name
              </th>
              <th class="text-left">
                Member ID
              </th>
              <th class="text-left">
                Eligibility
              </th>
              <th class="text-left">
                Prior Auth Status
              </th>
              <th class="text-left">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in items"
              :key="item.name"
            >
              <td><a    @click="singleMember(item)">{{ item.name }}</a></td>
              <td>{{ item.npi }}</td>
              <td :style="item.status == 'Denied' ? {'color':'red'} : {'color':'green'}">{{ item.status }}</td>
              <td :style="item.priorStatus == 'Denied' ? {'color':'red'} : {'color':'green'}">{{item.priorStatus}}</td>
              <td>
                  <a > {{item.actions}}</a>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      </v-card>

      </div>
   
     
</div>
</template>
<script>
export default{
    data(){
        return{
         items: [
          {
            name: 'Tampa General Hospital',
            npi: '123456789',
            type: 'Hospital',
            city:'Tampa',
            state:'Florida',
            averageCharges: '280%',
            averageSavings:'35%',
            action:'',
            rating:5,
            priorStatus:'Approved',
            actions:'Prior Auth',
            status:'Matched'
          },
          {
            name: 'USF Health',
            npi: '123456789',
            type: 'Multi-Speciality',
            city:'Tampa',
            state:'Florida',
            averageCharges: '280%',
            averageSavings:'35%',
            action:'',
            rating:4,
            priorStatus:'Denied',
            actions:'Prior Auth',
            status:'Not matched'
          },
          {
            name: 'Florida Medical Clinic',
            npi: '123456789',
            type: 'Multi-Speciality',
            city:'Tampa',
            state:'Florida',
            averageCharges: '280%',
            averageSavings:'35%',
            action:'',
            rating:4,
            priorStatus:'NA',
            actions:'Prior Auth',
            status:'Pending'
          },
          {
            name: 'Boyfront Health St Pete',
            npi: '123456789',
            type: 'Multi-Speciality',
            city:'St.Petersburgh',
            state:'Florida',
            averageCharges: '280%',
            averageSavings:'35%',
            action:'',
             priorStatus:'NA',
            actions:'Prior Auth',
            rating:4,
            status:'Matched'

          },
          {
            name: 'Morton Plant Mease',
            npi: '123456789',
            type: 'Hospital',
            city:'Tampa',
            state:'Florida',
            averageCharges: '280%',
            averageSavings:'35%',
            action:'',
            priorStatus:'NA',
            actions:'Pending',
            rating:4,
            status:'Pending'
          },
          {
            name: 'St.Anthony\'s Hospital',
            npi: '123456789',
            type: 'Hospital',
            city:'Tampa',
            state:'Florida',
            averageCharges: '280%',
            averageSavings:'35%',
            action:'',
            priorStatus:'NA',
            actions:'Approved',
            rating:4,
            status:'Pending'
          },
          {
            name: 'Midway Clinic',
            npi: '123456789',
            type: 'Hospital',
            city:'Tampa',
            state:'Florida',
            averageCharges: '280%',
            averageSavings:'35%',
            action:'',
            priorStatus:'NA',
            actions:'Approved',
            rating:2,
            status:'Matched'
          },
        ],
        }
    },
    methods:{
        singleMember(item){
            this.$store.commit('singleMember', item);
            this.$router.push(`/employer-advisor/employers/${routeParams}/members/member`)
        }
    }
}
</script>