<template>
  <v-row class="add-client-form row no-padding">
    <successModal ref="success_modal" v-on:confirm="sendMail"></successModal>
    <removeDialog
      ref="dialog_delete"
      v-on:confirm="confirmRemove"
    ></removeDialog>
    <errorModal 
    ref="error_modal"
     v-on:confirm="closeErrorModal">
    </errorModal>
    <div class="col-md-12 form-row-60-height">
      <div class="row">
        <div class="col-md-12">
          <h3 class="form-title font">User Detail</h3>
        </div>
      </div>
      <v-form v-model.trim="valid" ref="form" class="row">
        <div class="col-lg-6 col-xl-6 col-md-12 pb-0">
          <label class="font text-gray" for="">First Name</label>
          <v-text-field
            required
            v-model.trim="user.firstName"
            solo
            :rules="requiredRules"
            tabindex="1"
          ></v-text-field>
        </div>
        <div class="col-lg-6 col-xl-6 col-md-12 pb-0">
          <label class="font text-gray" for=""
            >Locations
            <span class="small-text font-italic ml-3"
              >(hold ctrl key to select multiple)</span
            >
          </label>

          <v-select
            v-model="user.locations"
            multiple
            item-text="name"
            item-value="id"
            :items="allLocations"
            required
            tabindex="6"
            solo
          ></v-select>
        </div>

        <div class="col-lg-6 col-xl-6 col-md-12 pb-0 pt-0">
          <label class="font text-gray" for="">Last Name</label>
          <v-text-field
            required
            tabindex="2"
            :rules="requiredRules"
            v-model.trim="user.lastName"
            solo
          ></v-text-field>
        </div>
        <div class="col-lg-6 col-xl-6 col-md-12 pb-0 pt-0">
          <label class="font text-gray" for="">Role </label>
          <v-text-field
            required
            v-model.trim="user.role"
            solo
            disabled
            tabindex="3"
          ></v-text-field>
        </div>
        <div class="col-lg-6 col-xl-6 col-md-12 pb-0 pt-0">
          <label class="font text-gray" for="">Email</label>
          <v-text-field
            required
            :rules="emailRules"
            v-model.trim="user.email"
            solo
            disabled
            tabindex="3"
          ></v-text-field>
        </div>
        <!-- <div class="col-md-6 pb-0 pt-0">
          <label class="font text-gray" for="">Address</label>
          <v-text-field
            required
            tabindex="7"
            :rules="requiredRules"
            v-model.trim="user.address"
            solo
          ></v-text-field>
        </div> -->
        <div class="col-md-6 pb-0 pt-0">
          <label class="font text-gray" for="">Title</label>
          <v-text-field
            required
            tabindex="4"
            :rules="requiredRules"
            v-model.trim="user.title"
            solo
          ></v-text-field>
        </div>
        <!-- <div class="col-lg-6 col-xl-6 col-md-12 row no-padding">
          <div class="col-md-6 pb-0 pt-0">
            <label class="font text-gray" for="">City</label>
            <v-text-field
              required
              :rules="requiredRules"
              v-model.trim="user.city"
              solo
              tabindex="8"
            ></v-text-field>
          </div>
          <div class="col-md-3 pb-0 pt-0">
            <label class="font text-gray" for="">State</label>

            <v-select
              required
              :rules="requiredRules"
              :items="states"
              v-model.trim="user.state"
              solo
              tabindex="9"
              item-text="name"
              item-value="name"
            ></v-select>
          </div>
          <div class="col-md-3 pb-0 pt-0">
            <label class="font text-gray" for="">Zip</label>
            <v-text-field
              required
              type="number"
              v-model.trim="user.zipCode"
              :rules="zipRules"
              solo
              tabindex="10"
            ></v-text-field>
          </div>
        </div> -->
        <div
          class="row col-md-6 pt-0 pb-0"
          v-if="!deactivateDelete && $role != 'user'"
        >
          <div class="col-md-8 font text-gray pt-0">
            <label for="" class="small-text"> User Status</label>
            <div class="d-flex mt-2">
              Inactive
              <v-switch
                solo
                v-model="user.status"
                class="no-padding ml-2 mr-2 vendors-switch"
              ></v-switch>
              Active
            </div>
          </div>
          <div
            class="col-md-4 mt-5 text-end"
            v-if="!deactivateDelete && $role != 'user'"
          >
            <a class="text-underline" @click="deleteUser()">Remove User</a>
          </div>
        </div>
      </v-form>
    </div>
    <div class="text-end justify-end gray-underline-wizard row no-padding pr-10">
      <div class="col-md-12 mt-5 mb-5">
        <span
          class="text-underline mr-10 col-md-12 pointer"
          style="color:#0572F3;"
          @click="goBack"
          >Cancel</span
        >
        <span
          class="text-underline mr-10 col-md-12 pointer"
          style="color:#0572F3;"
          @click="updateUser"
          >Save
        </span>
      </div>
    </div>
  </v-row>
</template>
<script>
import successModal from "../../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../../TablesTemplate/ErrorDialog.vue";
import removeDialog from "../../../../TablesTemplate/RemoveDialog.vue";
import api from "../../../../../services/api";
export default {
  components: { successModal, removeDialog, errorModal },
  data() {
    return {
      user:{},
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
      zipRules: [
        (v) => !!v || "This field is required",
        (v) => (v && v.length == 5) || " Zip Code should be five digits long",
      ],
      states: [],
      valid: false,
      deactivateDelete: false,
      removUserStatus: false,
      locations: [],
      allLocations: [],
    };
  },
  computed: {
    routeParams() {
      return this.$route.params.name;
    },
  },
  created() {
    this.getStates();
    this.getLocations();
    this.getRoles();
    this.getUser();
    if(localStorage.getItem('user')){
      let loggedInUser = JSON.parse(localStorage.getItem("user"));
      if(loggedInUser.email == this.user.email){
        this.deactivateDelete = true
      }
    }
    if(this.user.status == 'invited'){
      this.removUserStatus = true
    }
  },
  methods: {
    async getUser(){
      api()
        .get("/dpcproviders/user/"+this.$route.params.id)
        .then((res) => {
          this.user = res.data;
          this.user.locations = this.user.locations.map(a => a.id)
          this.user.roles 
        });
    },
    getRoles() {
      api()
        .get("/roles")
        .then((res) => {
          res.data.forEach((el) => {
                  this.roles.push(el.name);
              });
              console.log('roles', this.roles)
        });
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    deleteUser() {
      this.$refs.dialog_delete.openPopUp(
        "Are you sure that you want to delete this user?"
      );
    },
    getLocations() {
      api()
        .get("/dpcproviders/locations")
        .then((res) => {
          this.allLocations = res.data;
        });
    },
    confirmRemove() {
      api()
        .delete(`/dpcproviders/users/${this.$route.params.id}`)
        .catch((err) => {
                  if (err.response.status == 500) {
                    this.$refs.error_modal.openPopUp(
                      "There was an error, system admin is notified. Please try again later."
                    );}
                   else{
                    this.$refs.error_modal.openPopUp(err.response.data)
                   }
                  
                })
        .then((res) => {
          if (res) {
            let text = "User sucessfully removed";
            this.$refs.success_modal.openPopUp(text);
          }
        });
    },
    goBack() {
      this.$router.push(`/dpc-providers/users`);
    },
    getStates() {
      api()
        .get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },
    sendMail() {
      this.$refs.success_modal.closePopUp();
      this.$parent.getAllUsers("all");
      this.$router.push(`/dpc-providers/users`);
    },
    updateUser() {
      this.$refs.form.validate();
      if (this.valid) {

      
         let data = this.user
         delete data.email
         delete data.role
         delete data.status
        api()
          .put(`/dpcproviders/users/` + this.user.id, data)
          .catch((err) => {
                  if (err.response.status == 500) {
                    this.$refs.error_modal.openPopUp(
                      "There was an error, system admin is notified. Please try again later."
                    );}
                   else{
                    this.$refs.error_modal.openPopUp(err.response.data)
                   }
                  
                })
          .then((res) => {
            if (res) {
              let text = "User sucessfully updated";
              this.$refs.success_modal.openPopUp(text);
            }
          });
      }
    },
  },
};
</script>
