
import EmailDomain from '../views/EmailDomain.vue'
import notFound from '../components/TablesTemplate/NotFound.vue'
import NewLogin from '../views/NewLogin.vue'
import OrgExists from '../views/OrgExists.vue'
import NewRegister from '../views/NewRegister.vue'
import VerifyAccount from '../views/NewRegister.vue'
import MessageFromEmail from '../components/LoginRegister/MessageFromEmail.vue'
const router = [
  // {
  //   path:'/',
  //   redirect:'/login'
  // },
  
  {
    path:'/checkdomain',
    name:'Check Email Domain',
    component:EmailDomain
  },
  {
    path:'/login',
    name:'Login',
    component:NewLogin
  },
  {
    path:'/new-login',
    name:'Login',
    component:NewLogin
  },
  {
    path:'/new-register',
    name:'Register',
    component:NewRegister
  },
  {
    path:'/register',
    name:'Register',
    component:NewRegister,
    props: (route) => ({
      action: route.query.action || '',
      token: route.query.token || '',
      firstName: route.query.firstName || '',
      lastName: route.query.lastName || '',
      email: route.query.email || '',
      companyName: route.query.companyName || '',
      type: route.query.type || '',
    }),
  },
  {
    path:'/confirm',
    name:'Register',
    component:NewRegister,
    props: (route) => ({
      action: route.query.action || '',
      token: route.query.token || '',
      firstName: route.query.firstName || '',
      lastName: route.query.lastName || '',
      email: route.query.email || '',
      companyName: route.query.companyName || '',
      type: route.query.type || '',
    }),
  },
  {
    path:'/auth/organization/:orgId/membership',
    name:'Organization Exists',
    component:OrgExists
  },
  {
    path:'/change-pass',
    name:'Change Password',
    component:NewLogin
  },
  {
    path:'/set-pass:token?',
    name:'Set Password ',
    component:NewLogin
  },
  {
    path:'/message:message?',
    name:'Message From Apaly',
    component:MessageFromEmail
  },
  {
    path:'/auth/simple/verify/account',
    name:'Verify Account',
    component:VerifyAccount
  },
  { path: '/404', component: notFound },  
  { path: '*', redirect: '/404' },  
]
export default router