const state ={
  
    bundle : null,

}
const getters = {
   getSingleBundle(state){
        return state.bundle
      },
     
}
const mutations = {
    setSingleBundle(state, bundle){
        state.bundle = bundle
      },
    
}

export default{
    state,
    getters,
    mutations,
}