<template>
  <div class="container-fluid background-gray">
    <Header
      @expantSideBarOnHover="expantSideBarOnHover"
      @hideSideBar="hideSideBar"
    />
    <v-row class="background-gray">
      <div
        class="pb-0 vh-height sidebar-wrapper"
        v-if="showSideBar && !display"
      >
        <SideBar
          :mini="miniClass"
          @closeSideBar="closeSideBar"
          :windowWidth="display"
          :showSideBar="showSideBar"
        ></SideBar>
      </div>
      <div
        :class="
          showSideBar
            ? 'pb-0  vh-height sidebar-wrapper'
            : 'pb-0 vh-height sidebar-wrapper-mini'
        "
        v-else-if="display"
      >
        <SideBar
          :mini="miniClass"
          @closeSideBar="closeSideBar"
          :windowWidth="display"
          :showSideBar="showSideBar"
        ></SideBar>
      </div>
      <div
        v-if="mobileSideBar"
         :class="!showSideBar ? 'main-wrapper' : 'main-wrapper-mini'"
      >
        <div class="context no-padding-left">
          <div>
            <div class="row no-padding">
              <RoutePath :path="path"></RoutePath>
            </div>
            <BlueNavbar :tab="tab"></BlueNavbar>
            <div
              :class="hideWhiteBack ? 'home-tables' : 'white-background tables'"
            >
              <Home
                v-if="
                  route == '/provider-advisor' ||
                  route == '/provider-advisor/home'
                "
              ></Home>
              <Providers></Providers>
              <Partners></Partners>
              <Networks></Networks>
              <Bundles></Bundles>
              <Settings></Settings>
              <Profile v-if="$route.path.includes('profile')" />
            </div>
          </div>
        </div>
      </div>
    </v-row>
  </div>
</template>
<script>
import Header from "../components/Sections/ProviderAdvisor/Header.vue";
import SideBar from "../components/Sections/ProviderAdvisor/Layout/AdvisorSideBar.vue";
import RoutePath from "../components/TablesTemplate/RoutePath.vue";
import BlueNavbar from "../components/Sections/ProviderAdvisor/Layout/BlueNavbar.vue";
import Home from "../components/Sections/ProviderAdvisor/Home.vue";
import Providers from "../components/Sections/ProviderAdvisor/Providers/ProvidersTemplate.vue";
import Partners from "../components/Sections/ProviderAdvisor/Partners/PartnerTemplate.vue";
import Networks from "../components/Sections/ProviderAdvisor/Networks/NetworksTemplate.vue";
import Bundles from "../components/Sections/ProviderAdvisor/Bundles/BundlesTemplate.vue";
import Settings from "../components/Sections/ProviderAdvisor/Settings/SettingsTemplate.vue";
import Profile from '../components/TablesTemplate/MyProfile.vue'

export default {
  components: {
    Header,
    SideBar,
    RoutePath,
    BlueNavbar,
    Home,
    Providers,
    Partners,
    Networks,
    Bundles,
    Settings,
    Profile
  },
  data() {
    return {
      sideBar: true,
      showSideBar: false,
      display: true,
      windowWidth: window.innerWidth,
      page: "",
      clientName: "",
      miniClass: false,
      clientItem: null,
      path: "",
      tab: "",
    };
  },
  created() {
    if (this.windowWidth < 959) {
      this.display = false;
    } else {
      this.display = true;
    }
    if (this.route.includes("provider-advisor/providers/provider")) {
      this.$router.replace("/provider-advisor/providers");
    } else if (
      this.route.includes("provider-advisor/partners/add-new/result") ||
      this.route.includes("provider-advisor/partners/partner")
    ) {
      this.$router.replace("/provider-advisor/partners");
    } else if (this.route.includes("provider-advisor/users/user")) {
      this.$router.replace("/provider-advisor/users");
    }
  },
  computed: {
    hideWhiteBack() {
      let hide = false;
      let params = "";
      if (this.$route.params.name) {
        params = this.$route.params.name.replace(/ /g, "%20");
      }
      if (
        this.$route.path == "/provider-advisor" ||
        this.$route.path == "/provider-advisor/home" ||
        this.$route.path ==
          "/provider-advisor/providers/" + this.$route.params.name ||
        this.$route.path == "/provider-advisor/providers/" + params
      ) {
        hide = true;
      }

      if (this.$route.path == "/provider-advisor/providers/provider") {
        hide = false;
      }

      return hide;
    },
    mobileSideBar() {
      let show;
      if (this.display) {
        show = true;
      } else if (!this.showSideBar && !this.display) {
        show = true;
      }
      return show;
    },
    route() {
      return this.$route.path;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    getProvidersByClient(item) {
      if (item) {
        this.clientItem = item;
        this.clientName = item.name;
      }
    },
    closeSideBar() {
      if (!this.display) {
        this.showSideBar = false;
      } else {
        this.showSideBar = true;
      }
    },
    getClientName(name) {
      this.clientName = name;
    },

    onResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 959) {
        this.display = false;
      } else {
        this.display = true;
      }
    },
    hideSideBar() {
      this.showSideBar = !this.showSideBar;
    },
    expantSideBarOnHover(mini) {
      this.showSideBar = !this.showSideBar;
      this.miniClass = !this.miniClass;
    },
  },
};
</script>
<style scoped>
.tables-height{
  min-height: 2000px !important;
}
</style>
