<template>
    <v-row class="padding">
        <div class=" col-md-12">
           <h3  class="font">
             Add New Advisor
          </h3>
           <h4 class="font text-gray mt-2">Search and Add Advisors</h4>
        </div>
        <form class="row">
           <div class="col-md-6 ">
                <div class="col-md-12">
                    <label class="font text-gray" for="">Advisor Type</label>
                    <v-select
                        required
                        solo
                        :items="typeOptions"
                        v-model.trim="advisorType"
                     ></v-select>
                </div>
                <div class="col-md-12">
                    <label class="font text-gray" for="">Advisor Organization Name</label>
                        <v-text-field
                        required
                        solo
                        v-model.trim="advisorOrganizationName"
                        ></v-text-field>
                </div>
              <div class="row no-padding">
                <div class="col-md-6">
                   <label class="font text-gray" for="">City</label>
                        <v-text-field
                        required
                        v-model.trim="advisorCity"
                        solo
                        ></v-text-field>
                </div>
                <div class="col-md-3">
                    <label class="font text-gray" for="">State</label>
                    <v-select
                            class="text-gray"
                            :items="states"
                            label=""
                            solo
                            item-text="name" 
                            item-value="name"
                            v-model.trim="advisorState"
                            ></v-select>
                </div>
                <div class="col-md-3">
                    <label class="font text-gray" for="">Zip</label>
                        <v-text-field
                        required
                        type="number"
                        v-model.trim="advisorZip"
                        solo
                        ></v-text-field>
                </div>
               </div>
           </div>
            <div class="col-md-6  ">
                <div class="col-md-12">
                    <label class="font text-gray" for="">Individual Advisor First Name</label>
                    <v-text-field
                        required
                        v-model.trim="advisorFirstName"
                        solo
                        ></v-text-field>
                </div>
                <div class="col-md-12">
                    <label class="font text-gray" for="">Last Name</label>
                        <v-text-field
                        required
                        solo
                        v-model.trim="advisorLastName"
                        ></v-text-field>
                </div>
           </div>
            <div class="col-md-12 d-flex align-end flex-column">
                <div>
                    <v-btn class="blue-buttons mr-6" @click="searchAdvisors">
                       Search
                    </v-btn>
                 </div>
            </div>
        </form>
 </v-row>
</template>
<script>
import api from '../../../../../services/api'
export default{
    data(){
        return{
           advisorType:'',
           advisorOrganizationName:'',
           advisorCity:'',
           advisorState:'',
           advisorZip:'',
           advisorFirstName:'',
           advisorLastName:'',
           states:[],
           typeOptions:[
               {
                   text:'Third Party Administrator (TPA)',
                   value:'TPA'
               },
               {
                   text:'Broker Consultant',
                   value:'brokerConsultant'
               },
               {
                   text:'Analytics Consultant',
                   value:'analyticsConsultant'
               },
           ]
        }
    },
    computed:{
        getClient(){
            return this.$store.getters.getClientGetter
        },
        routeParams(){
           return this.$route.params.name
       }
    },
    created(){
       this.getStates();
    },
    methods:{
      getStates(){
          api().get('/api/states').then((res)=>{
              res.data.forEach((el)=>{
                  if(el.active){
                      this.states.push(el);
                  }
              })
          })
        },
        searchAdvisors(){
            let urlData = ''
            urlData += this.advisorType ? `advisorType=${this.advisorType}&` : ''
            urlData += this.advisorOrganizationName ? `companyName=${this.advisorOrganizationName}&` : ''
            urlData += this.city ? `city=${this.city}&` : ''
            urlData += this.state  ? `state=${this.state}&` : ''
            urlData += this.zip ? `zipCode =${this.zip}&` : ''
            urlData += this.firstName  ? `firstName=${this.firstName}&` : ''
            urlData += this.lastName  ? `lastName=${this.lastName}&` : ''
        
            api().get('/advisors/search?' + urlData ).then((res)=>{
              
                if(res){
                    this.$router.push(`/employer/advisors/add-new/result`);
                    this.$store.commit('setAdvisorSearchResultInEmployer', res.data.advisors)
                }
            })
            
        }
    }
}
</script>
 