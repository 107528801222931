<template>

  <div class="col-md-12 form-box ">
    <div class="row">
        <div class="col-md-12">
            <h3 class="form-title  text-underline">Npi Detail</h3>
        </div>
    </div>
    <form class="row">
        <div class="col-md-6">
            <div class="col-md-12">
               <label class="font text-gray" for="">Provider Name</label>
                <v-text-field
                required
                solo
                tabindex="1"
                ></v-text-field>
           </div>
           <div class="col-md-12">
              <label class="font text-gray" for="">Provider City</label>
                <v-text-field
                required
                tabindex="2"
                solo
                ></v-text-field>
           </div>
           <div class="col-md-12">
               <label class="font text-gray" for="">Preferred Provider Participan <i class="fas fa-question-circle  yellow-icons"></i></label>
                <v-text-field
                required
                tabindex="3"
                solo
                ></v-text-field>
           </div>
           <div class="col-md-12">
            <label class="font text-gray" for="">Provider Type <i class="fas fa-question-circle  yellow-icons"></i></label>
                <v-text-field
                required
                tabindex="4"
                solo
                ></v-text-field>
          </div>
           <div class="col-md-12 row">
             <input type="checkbox" class="check ml-3">
             <span class="font ml-3">
                Select as Preferred Provider <i class="fas fa-question-circle  yellow-icons"></i>
             </span>
          </div>
          <div class="col-md-12 mt-2">
             <v-btn class="blue-buttons">View Contract</v-btn>
          </div>

        </div>
         <v-divider></v-divider>
         <div class="col-md-6">
           <div class="col-md-12   mb-3">
             <div class="col-md-12 text-underline font no-padding">
                 Match Information and Contorls
             </div>
             <div class="col-md-12 row justify-space-between mt-1">
               <div class="font text-gray">Your Entries</div>
               <div class="font text-gray">Provider Data</div>
               <div class="font text-gray">Status</div>
             </div>
             <v-divider></v-divider>
              <div class="col-md-12  justify-space-between mt-2 no-padding">
                  <div class="no-padding font text-gray">
                      Rates <i class="fas fa-question-circle  yellow-icons"></i>
                   </div> 
                   <div class="row no-padding mt-5">
                       <div class="no-padding col-md-2">
                           <label class="font text-gray">Inpatient</label>
                            <v-text-field solo class="mr-3"></v-text-field>
                            
                       </div>
                       <div class="no-padding col-md-2">
                            <label class="font text-gray">Outpatient</label>
                          <v-text-field solo></v-text-field>
                         
                       </div>
                       <div class="col-md-4  text-end no-padding mt-6">
                            <a  class="font">Request to View Rates</a>
                        </div>
                          
                       <div class="col-md-4 font text-right red--text mt-6">Not Matched</div>
                   </div>      
               
             </div>
              <v-divider></v-divider>
             <div class="col-md-12 row justify-space-between mt-2 no-padding">
               <div class="font text-gray col-md-4 no-padding">
                  <div class="no-padding">
                    Quality  <i class="fas fa-question-circle  yellow-icons"></i>
                   </div> 
                        <v-rating    
                        :value="rating"
                        background-color="orange lighten-3"
                        color="orange">
                        </v-rating>
                </div>
               <div class="font text-gray col-md-4 no-padding mt-5 ">
                        <v-rating    
                        :value="rating"
                        background-color="orange lighten-3"
                        color="orange">
                       </v-rating>
                   
                </div>
               <div class="font  red--text aling-center justify-center mt-5">Not Matched</div>
             </div>
               <v-divider></v-divider>
             <div class="col-md-12 row justify-space-between mt-2 no-padding">
                 <div class="no-padding col-md-4 font text-gray">
                    Distance <i class="fas fa-question-circle  yellow-icons"></i>
                   </div> 
               <div class="font text-gray row col-md-12 no-padding">
                   <div class="col-md-4 d-flex no-padding mt-5">
                     <v-text-field solo ></v-text-field>
                      <span class="ml-2">10 Miles</span>
                   </div>     
                     <div class="col-md-4 d-flex no-padding mt-5">
                      <v-text-field solo></v-text-field>
                      <span class="ml-2">20 Miles</span>
                   </div>  
                   <div class="col-md-4 font  red--text mt-5" align="end">Not Matched</div>
                </div>
             </div>
           </div>
         </div>
        <div class="col-md-12 d-flex align-end flex-column">
            <div>
                <v-btn class="blue-buttons mt-2 mr-6"  @click=" $router.push(`/employer-advisor/employers/${routeParams}/providers`)">
                  Remove Provider
                </v-btn>
                <v-btn class="blue-buttons mr-6 mt-2"   @click=" $router.push(`/employer-advisor/employers/${routeParams}/providers`)">
                   Cancel
                </v-btn>
                <v-btn class="blue-buttons mt-2"   @click=" $router.push(`/employer-advisor/employers/${routeParams}/providers`)">
                  Save 
                </v-btn>
            </div>
        </div>
     </form>
  </div>

</template>
<script>
export default{
    data(){
     return{
         rating:4,
         switch1:true,
     }
    },
    computed:{
      route(){
           return this.$route.path
      },
      getSingleProvider(){
          return this.$store.getters.getProviderGetter
      },
      getAffilate(){
          return this.$store.getters.getSingleAffilate
      },
        routeParams(){
         return this.$route.params.name
      }
    }
}
</script>

        
        
   