<template>
  <v-row class="add-client-form no-padding">
    <div class="col-md-12 form-box">
      <div class="row">
        <div class="col-md-12">
          <div class="col-md-12 row justify-space-between">
            <h3 class="form-title font" v-if="$role != 'user'">
              Add New Partner
            </h3>
            <h3 class="form-title font" v-else>Search Partners</h3>
          </div>
        </div>
      </div>
      <div class="font mt-5">
        <h3 class="col-md-12" v-if="$role != 'user'">
          Search and Add a Partner
        </h3>
      </div>
      <form class="row form-row-height">
        <div class="col-md-6">
          <div class="col-md-12 pb-0">
            <label class="font text-gray" for="">Partner Type</label>
            <v-select
              :items="typeOptions"
              v-model.trim="partnerType"
              required
              solo
            ></v-select>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for=""
              >Partner Organization Name</label
            >
            <v-text-field
              required
              solo
              v-model.trim="organization"
            ></v-text-field>
          </div>
          <v-row class="no-padding">
            <div class="col-md-6 pb-0 pt-0">
              <label class="font text-gray" for="">City</label>
              <v-text-field required solo v-model.trim="city"></v-text-field>
            </div>
            <div class="col-md-3 pb-0 pt-0">
              <label class="font text-gray" for="">State</label>
              <v-select
                required
                item-text="name"
                item-value="name"
                solo
                :items="states"
                v-model.trim="state"
              ></v-select>
            </div>
            <div class="col-md-3 pb-0 pt-0">
              <label class="font text-gray" for="">Zip</label>
              <v-text-field
                required
                solo
                type="number"
                v-model.trim="zip"
              ></v-text-field>
            </div>
          </v-row>
        </div>
        <div class="col-md-6">
          <div class="col-md-12 pb-0">
            <label class="font text-gray" for=""
              >Individual Partner First Name</label
            >
            <v-text-field required v-model.trim="firstName" solo></v-text-field>
          </div>
          <div class="col-md-12  pt-0">
            <label class="font text-gray" for="">Last Name</label>
            <v-text-field required solo v-model.trim="lastName"></v-text-field>
          </div>
          <div class="col-md-12 pb-0  d-flex align-end flex-column">
            <div>
              <v-btn
                tabindex="16"
                class="blue-buttons  mb-2"
                @click="searchPartners"
              >
                Search
              </v-btn>
            </div>
          </div>
        </div>
      </form>
    </div>
  </v-row>
</template>
<script>
import api from "../../../../services/api";
export default {
  data() {
    return {
      show1: false,
      dialog: false,
      dialog1: false,
      partnerType: "",
      city: "",
      state: "",
      zip: "",
      firstName: "",
      lastName: "",
      organization: "",
      states: [],
      typeOptions: [
        {
          text: "Third Party Administrator (TPA)",
          value: "TPA",
        },
        {
          text: "Broker Consultant",
          value: "brokerConsultant",
        },
        {
          text: "Analytics Consultant",
          value: "analyticsConsultant",
        },
      ],
    };
  },
  computed: {
    singleMember() {
      return this.$store.getters.getMemberGetter;
    },
    singleClient() {
      return this.$store.getters.getClientGetter;
    },
    routeParams() {
      return this.$route.params.name;
    },
  },
  created() {
    this.getStates();
  },
  methods: {
    getStates() {
      api()
        .get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },
    searchPartners() {
      let urlData = "";
      urlData += this.partnerType ? `advisorType=${this.partnerType}&` : "";
      urlData += this.organization ? `companyName=${this.organization}&` : "";
      urlData += this.city ? `city=${this.city}&` : "";
      urlData += this.state ? `state=${this.state}&` : "";
      urlData += this.zip ? `zipCode =${this.zip}&` : "";
      urlData += this.firstName ? `firstName=${this.firstName}&` : "";
      urlData += this.lastName ? `lastName=${this.lastName}&` : "";
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.employerAdvisor;
      api()
        .get(`/advisors/${advisorId}/search?` + urlData)
        .then((res) => {
          if (res) {
            this.$router.replace(`/employer-advisor/partners/add-new/result`);
            this.$store.commit(
              "setPartnersSearchResultInEmployerAdvisor",
              res.data.advisors
            );
          }
        });
    },
  },
};
</script>
