<template>
<div class="no-padding">
  <div class="col-md-12">
  <v-row>
    <v-col class="col-md-4" md="4" cols="12">
        <h3>
          <span class="font">Prior Authorization Request </span>
        </h3>
    </v-col>
    <v-col class="col-md-4" md="4" cols="12">
        <v-text-field class="input-control" prepend-inner-icon="mdi-magnify"  light dense outlined placeholder="Search Prior Authorization">
        </v-text-field>
    </v-col>

     <v-col class="col-md-4 text-end" cols="12"   align="end">
          <v-btn @click="$router.push(`/provider-advisor/providers/${routeParams}/prior-auth/determination`)" class="my-1 blue-buttons">New Prior Auth Request</v-btn>
   </v-col>
  </v-row>    
 </div>
 <v-simple-table fixed-header>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
          Prior Authorization
          </th>
          <th class="text-left">
           Date
          </th>
          <th class="text-left">
          Status
          </th>
          <th class="text-left">
           Prior Uth Number
          </th>
          <th class="text-left">
            Member Name
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in items"
          :key="item.name"
        >
      
          <td><a  @click="$router.push(`/provider-advisor/providers/${routeParams}/prior-auth/details`)">{{ item.auth }}</a> </td>
          <td class="text-gray">{{ item.date }}</td>
          <td :class="item.status == 'Approved' ? 'green-text' : item.status == 'Denied' ? 'red--text' : 'text-gray'">{{ item.status }}</td>
          <td class=" text-gray">{{item.authNo}}</td>
          <td class=" text-gray"><a>{{ item.memberName }}</a></td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</div>
</template>
<script>
  export default {
    data () {
      return {
        items: [
          {
            auth: 'MRI L-Spine',
            date: '10/01/2021',
            status: 'Pending',
            authNo:'NA',
            memberName:'Valarie Vaughn',
           
          }, {
            auth: 'Knee Arthroscopy',
            date: '10/01/2021',
            status: 'Approved',
            authNo:'AP1ZX523473',
            memberName:'Theodore Ramos',
           
          }, 
          {
            auth: 'Cochlear Implant',
            date: '10/01/2021',
            status: 'Approved',
            authNo:'AP1ZX523473',
            memberName:'Gary Gullen',
           
          }, 
          {
            auth: 'Implanted Defibriliator',
            date: '10/01/2021',
            status: 'Pending',
            authNo:'NA',
            memberName:'Joseph Rimkus',
           
          }, 
          {
            auth: 'Diskectomy',
            date: '10/01/2021',
            status: 'Denied',
            authNo:'NA',
            memberName:'Don Adams',
           
          }, 
          {
            auth: 'Spinal Fusion',
            date: '10/01/2021',
            status: 'Denied',
            authNo:'NA',
            memberName:'Donald Bass',

          },
        ],
      }
    },
    computed:{
      getClient(){
          return this.$store.getters.getClientGetter
       },
       routeParams(){
          let parsed = this.$route.params.name.replace(/ /g, '%20')
          return parsed
      }
    },
    methods:{
 
     
    }
  }
</script>
<style>
.green-text{
  color: #0FA64B !important;
}
</style>