import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=f31a8e0e&scoped=true&"
import script from "./Index.vue?vue&type=script&lang=js&"
export * from "./Index.vue?vue&type=script&lang=js&"
import style0 from "./../../../../assets/mobileStyle.css?vue&type=style&index=0&id=f31a8e0e&prod&scoped=true&lang=css&"
import style1 from "./Index.vue?vue&type=style&index=1&id=f31a8e0e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f31a8e0e",
  null
  
)

export default component.exports