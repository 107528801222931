const state ={
    employerSingleMember:null,
    employerSingleMemberDependent:null,
    employerSingleMemberEmployee:null,
    employerSingleMemberSingleDependent:null
}
const getters = {
    getEmployerSingleMember(state){
        return state.employerSingleMember
    },
    getEmployerSingleMemberDependent(state){
        return state.employerSingleMemberDependent
    },
    getEmployerSingleMemberEmployee(state){
        return state.employerSingleMemberEmployee
    },
    getEmployerSingleMemberSingleDependent(state){
        return state.employerSingleMemberSingleDependent
    }


}
const mutations = {
    setSingleEmployerMember(state,member){
        state.employerSingleMember = member
    },
    setSingleEmployerMemberDependent(state,dependent){
        state.employerSingleMemberDependent = dependent
    },
    setSingleEmployerMemberEmployee(state,employee){
        state.employerSingleMemberEmployee = employee
    },
    setSingleEmployerMemberSingleDependent(state, dep){
        state.employerSingleMemberSingleDependent = dep
    }
}

export default{
    state,
    getters,
    mutations,
}