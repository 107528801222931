<template>
  <div>
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <v-row class="mb-4 mt-3">
      <v-col class="col-md-7 col-lg-8 col-sm-5" cols="12">
        <h3 class="font">Add New Advisor- Search Results</h3>
      </v-col>
    </v-row>
    <v-simple-table fixed-header height="630px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Advisor Name</th>
            <th class="text-left">Individual Name</th>
            <th class="text-left">Type</th>
            <th class="text-left">City</th>
            <th class="text-left">State</th>
            <th class="no-padding text-end justify-end">
              <div class="col-md-12 justify-end d-flex text-end">
                <v-checkbox
                  class="no-padding mt-3"
                  @click="selectAllRows()"
                ></v-checkbox>
                <label for="" style="padding-top: 15px">Select all</label>
              </div>
            </th>
          </tr>
        </thead>
        <tbody v-if="getAdvisors && getAdvisors.length > 0">
          <tr
            v-for="(item, index) in getAdvisors"
            :key="item.companyName"
            :class="selectAll ? 'activeItem' : ''"
            :id="'id' + index"
          >
            <td>
              <a >{{ item.companyName }}</a>
            </td>
            <td v-if="item.primaryContact">
              {{ item.primaryContact.firstName }}
              {{ item.primaryContact.lastName }}
            </td>
            <td v-else>/</td>
            <td
              v-html="
                item.advisorType == 'brokerConsultant'
                  ? 'Broker Consultant'
                  : item.advisorType == 'analyticsConsultant'
                  ?  'Analytics Consultant'
                  : item.advisorType
              "
            >
              {{ item.advisorType }}
            </td>
            <td v-html="item.city ? item.city : '/'"></td>
            <td v-html="item.state ? item.state : '/'"></td>
            <td
              class="text-center mt-3 row no-padding justify-end"
              style="padding-right: 80px"
            >
              <v-checkbox
                class="stay-blue no-padding"
                :value="selectAll"
                @click="activeRow(index, item)"
              ></v-checkbox>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <td>
            <a class="ml-3" @click="$router.push(`/employer/advisors/invite`)"
              >No Results Found - Click Here to Invite a Partner</a
            >
          </td>
        </tbody>
      </template>
    </v-simple-table>
    <div class="row text-end col-md-12">
      <v-btn
        class="blue-buttons mr-10"
        @click="$router.push(`/employer/advisors`)"
      >
        Cancel
      </v-btn>
      <v-btn
        class="blue-buttons"
        v-if="getAdvisors.length > 0"
        @click="addAdvisor()"
      >
        Add Selected
      </v-btn>
    </div>
    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
  </div>
</template>
<script>
import api from "../../../../../../services/api";
import successModal from "../../../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../../../TablesTemplate/ErrorDialog.vue";
import { mapGetters } from 'vuex'

export default {
  components: {
    successModal,
    errorModal,
  },
  data() {
    return {
      selected: [],
      items: [],
      selectAll: false,
      selectedRows: [],
    };
  },
  computed: {
    getClient() {
      return this.$store.getters.getClientGetter;
    },
    routeParams() {
      return this.$route.params.name;
    },
    getAdvisors() {
      return this.$store.getters.getAdvisorSearchResultInEmployer;
    },
     ...mapGetters(['getAllPartners']),

  },
  methods: {
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    addAdvisor() {
      if(this.selectedRows.length < 1) {
        let text = "You must  select at least one Partner";
        this.$refs.error_modal.openPopUp(text);
        return;
      }
      let exist = false;
      this.getAllPartners.forEach((el) => {
        if (this.selectedRows.some((t) => t._id == el._id)) {
          this.$refs.error_modal.openPopUp("Partner Already Exists");
          exist = true;
          return;
        }
      });
      if (!exist) {
        let user = JSON.parse(localStorage.getItem("user"));
        let employerId = user.groupPortal[0].organization.employer;

        let data = {
            'ids' : this.selectedRows
        };
        api()
          .post(`/employers/${employerId}/advisors`, data)
          .catch((err) => {
            if (err) {
              this.$refs.error_modal.openPopUp(err.response.data.error);
            }
          })
          .then((res) => {
            if (res) {
              let text = "Advisors added successfully to this Employer!";
              this.$refs.success_modal.openPopUp(text);
            }
          });
      }
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
      this.$router.push(`/employer/advisors`);
    },
    selectAllRows() {
      this.selectedRows = [];
      this.selectAll = !this.selectAll;
      this.getAdvisors.forEach((el) => {
        this.selectedRows.push(el._id);
      });
      if (!this.selectAll) {
        this.selectedRows = [];
      }
      
    },
    activeRow(index, item) {
      let itemById = document.getElementById("id" + index);
      itemById.classList.toggle("activeItem");
      if (itemById.classList.contains("activeItem")) {
        this.selectedRows.push(item._id);
      } else {
        let removeItem = this.selectedRows.find((x) => x === item._id);
        this.selectedRows.splice(this.selectedRows.indexOf(removeItem), 1);
      }
    },
  },
};
</script>
<style>
.buttons-left {
  justify-content: left;
  align-content: left !important;
  align-items: left !important;
}
</style>