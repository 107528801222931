<template>
 <v-row class="add-client-form no-padding">
  <div class="col-md-12 form-box">
    <div class="row">
        <div class="col-lg-8 col-md-9 col-sm-12">
            <h3 class="form-title" >
                <span class="form-title font">Invite Employers</span> 
            </h3>
        </div>
          <!-- <div class="col-md-4 text-end col-sm-12" align="end">
          <v-btn class="blue-buttons">Copy and Email Invite Link</v-btn>
        </div> -->
    </div>
    <form>
        <div v-for="n in 3" :key="n" class="row">
            <div class="col-md-4">
               <label class="font text-gray" for="">Email (required)</label>
                <v-text-field
                required
                solo
                ></v-text-field>
           </div>
            <div class="col-md-4">
               <label class="font text-gray" for="">First Name (optional)</label>
                <v-text-field
                required
                solo
                ></v-text-field>
           </div>
            <div class="col-md-4">
               <label class="font text-gray" for="">Last Name (optional)</label>
                <v-text-field
                required
                solo
                ></v-text-field>
           </div>
              <v-divider></v-divider>
        </div>

       <div class="row mt-2">
           <div class="col-md-12 row justify-space-between">
            <a  class="col-lg-10 col-md-10" @click="addAnotherRow">Add Another Row</a>
            <v-btn class="blue-buttons" @click="$router.push(`/employer-advisor/employers/${routeParams}/employers`)">
               Invite Employers
            </v-btn>
           </div> 
       </div>
      </form>
    </div>
 </v-row>
</template>
<script>
 export default{
    computed:{
        singleClient(){
          return this.$store.getters.getClientGetter;
        },
         routeParams(){
            return this.$route.params.name
      }
    },
    created(){
       
    },
    addAnotherRow(){

    }
 }
</script>