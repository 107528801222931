<template>
    <div>
      <Bundles v-if="route=='/employer/bundles'"></Bundles>
      <AddNew v-if="route=='/employer/bundles/add-new'"></AddNew>
    </div>
</template>

<script>
    import AddNew from "./AddNew.vue";
    import Bundles from "./Bundles.vue";
    export default {
        name:'EmpProvidersTemplate',
        components: {
            Bundles,
            AddNew,
        },
        computed:{
            route(){
                return this.$route.path
            }
        }
    }
</script>