<template>
  <div class="">
    <div v-if="!isChildren && $route.meta.type == 'clients'" class="">
      <Header
        :title="'Clients'"
        :searchInput="true"
        :exportButton="true"
        :placeholder="'Search Clients'"
        :addButton="true"
        @invite="invite"
        @add="add"
        @export="exportData"
        @searchInput="searchInput1"
      ></Header>
      <div class="mt-3">
        <v-simple-table
          fixed-header
          height="1000"
          class="no-padding tr-custom-height"
          id="tblData"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">CLIENT NAME</th>
                <th class="text-left">TPA</th>
                <th class="text-left">STATUS</th>
                <th class="text-left">ACTION</th>
              </tr>
            </thead>
            <tbody v-if="clients.length > 0 && !filterItems" class="text-gray tr-custom-height">
              <tr
                class="text-gray"
                id="remove-hover"
                v-for="(item, index) in clients"
                :key="index"
              >
                <td>
                  <a
                    v-if="item && item.primaryContact"
                    class="text-underline"
                    @click="showClient(item)"
                    >{{ item.primaryContact.firstName }}
                    {{ item.primaryContact.lastName }}</a
                  >
                  <a v-else @click="showClient(item)">/</a>
                </td>
                <td>TPA</td>
                <td>{{ item.status }}</td>
                <td>
                  <a>Submit Charges</a>
                </td>
              </tr>
            </tbody>
               <tbody v-else-if="items.length < 1 && filterItems">
                  <v-col md="12" cols="12">
                    <v-alert
                      border="bottom"
                      colored-border
                      type="warning"
                      elevation="2"
                      class="text-gray"
                    >
                      Sorry! No results found
                    </v-alert>
                  </v-col>
                </tbody>

            <tbody v-else>
              <v-col md="12">
                There are no clients currently added to your account.
                <a @click="$router.push('/solution-vendor/clients/add-client')">
                  Click to add your clients</a
                >
              </v-col>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>
    <router-view></router-view>

    <v-dialog v-model="inviteDialog" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span class="text-h5 text-underline col-md-12 font-weight-bolder"
            >Invite a TPA</span
          >
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <label for="">Company Name</label>
                <v-text-field solo required></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <label for="">Contact First Name</label>
                <v-text-field solo required></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <label for="">Contact Last Name</label>
                <v-text-field solo required></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <label for="">Email</label>
                <v-text-field solo required></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <div class="col-md-12" align="end">
            <span
              class="text-underline text-gray mr-10 pointer"
              @click="inviteDialog = false"
              >Cancel</span
            >
            <span
              class="text-underline text-gray pointer"
              @click="inviteDialog = false"
              >Invite</span
            >
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Header from "../../DigitalVendorInvoices/Layout/HeaderPage.vue";
import api from "../../../../services/api";

export default {
  data() {
    return {
      showDetails: false,
      tab: "solution",
      items: [],
      singleItem: null,
      typeItems: [{ text: "Categories", value: "Categories" }],
      sortItems: [{ text: "Newest", value: "Newest" }],
      sortBy: "",
      type: "",
      solutions: [],
      favorites: [],
      selected: [],
      inviteDialog: false,
      searchInput: [],
      clients: [],
      filterItems:false,
    };
  },
  components: {
    Header,
  },
  created() {
    this.getData();
  },
  computed: {
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
  },
  methods: {
     exportData() {
      var elt = document.getElementById("tblData");
      var downloadLink;
      var dataType = "application/vnd.ms-excel";
      var tableSelect = elt;
      var tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

      // Specify file name
      let filename = "excel_data.xls";

      // Create download link element
      downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        var blob = new Blob(["\ufeff", tableHTML], {
          type: dataType,
        });
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = "data:" + dataType + ", " + tableHTML;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
    },
    invite() {
      this.inviteDialog = true;
    },
    add() {
      this.$router.push("/solution-vendor/clients/add-client");
    },
    showClient(item) {
      this.singleItem = item;
      this.$store.commit("setSingleItem", item);
      this.$router.push("/solution-vendor/clients/details/" + item.id);
      //  api()
      //   .get(`/vendors/clients/` + item._id).then((res)=>{
      //     console.log(res)

      //   })
    },
    getData() {
      this.load = true;
      api()
        .get(`/vendors/clients`)
        .catch((err) => {
          this.load = false;
        })
        .then((res) => {
          if (res && res.data) {
            this.clients = res.data;

            this.filterArray = this.clients;
            this.load = false;
          }
        });
    },
    searchInput1(val) {
      this.clients = this.filterArray.filter((el) => {
        if (
          el.primaryContact &&
          el.primaryContact.firstName &&
          el.primaryContact.firstName.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.primaryContact.firstName
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el.pmntStatus &&
          el.pmntStatus.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.pmntStatus.toLowerCase().includes(val.toLowerCase());
        } else if (
          el.memberNumber &&
          el.memberNumber.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.memberNumber.toLowerCase().includes(val.toLowerCase());
        } else if (
          el.lastPmnt &&
          el.lastPmnt.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.lastPmnt.toLowerCase().includes(val.toLowerCase());
        }
      });
      if (this.clients.length < 1) {
        this.filterItems = true;
      }else{
        this.filterItems = false
      }
    },
  },
};
</script>
<style>
.border-blue-bottom {
  border-bottom: 6px solid #4072b7;
  margin-left: -1px;
  margin-right: -2px;
  margin-top: -1px;
}

.tr-custom-height > .v-data-table__wrapper > table > tbody > tr > td {
  height: 75px;
}
</style>