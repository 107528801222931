const state = {
   email : '',
   verify:false,
   account:null,
   organization:null,
   accountId:'',
   organizationId:'',
   type:'',
   user:null,
   companyName:'',
   org:null,
   emailWhiteList:[],
   addDomainArray:[],
   removeDomainArray:[]
}

const getters = {
    getUserEmail(){
        return state.email
    },
    verifyUser(){
        return state.verify
    },
    getAccountId(){
        return state.accountId
    },
    getOrganizationId(){
        return state.organizationId
    },
    getUserType(){
        return state.type
    },
    getUser(){
        return state.user
    },
    getCompanyName(){
        return state.companyName
    },
    getOrganizationInfo(){
        return state.org
    },
    getEmailWhiteList(state){
        return  state.emailWhiteList
    },
    getAddDomainArray(state){
        return state.addDomainArray
    },
    getDeleteDomainArray(state){
        return state.removeDomainArray
    }

}

const mutations = {
    setAddDomainArray(state,array){
      state.addDomainArray = array
    },
    setDeleteDomainArray(state,array){
        state.removeDomainArray = array
    },
    setEmailWhiteList(state,list){
       state.emailWhiteList = list
    },
    setOrganizationInfo(state,org){
        state.org = org;
    },
   
    setUserEmail(state,email){
      state.email = email
    },
    setUserVerify(state,error){
        state.verify = error
    },
    setAccount(state, account){
        state.account = account
    },
    setOrganization(state,organization){
        state.organization = organization
    },
    setAccountId(state, id){
        state.accountId = id
    },
    setOrganizationId(state,id){
        state.organizationId = id
    },
    userType(state, type){
         state.type = type
    },
    setUser(state, user){
          state.user = user
    },
    
    setCompanyName(state,name){
            state.companyName = name
    }
}


export default{
    state,
    getters,
    mutations,
}