<template>
    <div>
        <Members v-if="route == `/provider/members`"></Members>
        <Member v-if="route == `/provider/members/member`"></Member>
        <Search v-if="route == `/provider/members/advanced-search`"></Search>
    </div>
</template>
<script>
import Members from './Members.vue'
import Member from './Member.vue'
import Search from './AdvancedSearch.vue'

export default{
    components:{
        Members,
        Member,
        Search,
       
    },
    computed:{
      route(){
          return this.$route.path
      },
      routeParams(){
        let parsed =''
        if(this.$route.params.name){
          parsed = this.$route.params.name.replace(/ /g, '%20')
        }
        return parsed
      }
      
    },
    data(){
        return{

        }
    },

}
</script>