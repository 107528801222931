<template>
<div>
    <div class="row" v-if="statistics">
    <div v-for="item in items" :key="item.title" class=" col-xl-3 col-lg-3 col-md-4 text-center justify-center align-center  " >
      <v-card class="centered-card space-around-content" style="height:13rem;cursor:pointer" @click="item.url ? $router.push(`/employer-advisor/employers/${routeParams}/${item.url}`) : ''">
        <img
            :src="item.img"
            height="65"
            width="70"
            style="object-fit:cover"
            class="img-media"
           >
        <h4 class="text-center justify-center blue-text font mx-1 font-quicksand-medium-18">{{item.title}}</h4>
        <v-card-text>
          <v-row
              align="center"
              class="mx-6 justify-center mb-4"
          >
         
            <h1 class="blue-text" style="font-size:2.3rem" v-if="item.procentage">{{statistics.projectedSavings}} %</h1>
            <h5 class=" text-center   font-quicksand-medium-18" style="font-weight:normal">
              {{item.desc}}
            </h5>
          </v-row>
        </v-card-text>
      </v-card>
    </div>  

    <div v-for="item in precentageCards" :key="item.title" class="col-xl-3 col-lg-3 col-md-4 text-center justify-center aling-center" >
        <v-card style="height:13rem;cursor:pointer" class=" space-around-content">
            <v-row
              align="center"
              class="mx-0 justify-center "
            >
              <h5 class=" text-center text-underline mt-1 font-quicksand-medium-18">
                {{item.title}}
              </h5>
            </v-row>
            <v-card-title class="text-center justify-center text-gray font percentage-num ">
            <v-row  v-if="item.img" class=" text-center justify-center align-center" >
                 <img src="/employer-home.png"   class="align-center setup-small-img" width="250" height="60" alt="">
            </v-row>
            <div v-if="item.clients" class="row justify-space-around ">
              <div>
                 <h1 class=" blue-text ">
                 {{statistics.noEmployers}} <br>
                  </h1>
                 Employers
              </div>
              <div class="ml-6">
                <h1 class=" blue-text"  >
                 {{statistics.noPlanMembers}} <br>
              </h1>
               Members
              </div>
            </div>
            
            <div v-else-if="item.inpatient && statistics.rates" class="row justify-space-around  blue-text">
              <div class="font-weight-bold">
                <h1 class=" blue-text mb-4">
                  <span v-if="!statistics.rates.inpatient">0</span>
                  <span v-else>{{statistics.rates.inpatient}}</span>% <br> 
                 </h1>
                 
                Inpatient
              </div>
              <div class="ml-8 font-weight-bold">
                <h1 class=" blue-text mb-4"  >
                  <span v-if="!statistics.rates.outpatient">0</span>
                  <span v-else>{{statistics.rates.outpatient}}</span>% <br>
              </h1>
               Outpatient
              </div>
            </div>
                <div v-else-if="item.title == 'Providers on Your Provider List'" class=" blue-text">
                  <h1>
                       {{statistics.providers}}
                  </h1>
                </div>
                <div v-else-if="item.title == 'Projected Savings'" class=" blue-text">
                  <h1>
                      {{statistics.projectedSavings}} %
                  </h1>
                </div>
                <div v-else-if="item.title == 'Provider Coverage'" class=" blue-text">
                   <h1> {{statistics.providerCoverage}}%</h1>
                </div>
                <div v-else-if="item.title == 'Contract Renewal'" class=" blue-text">
                  <h1 v-if="parsedDate(statistics.contractRenewal)" v-html="parsedDate(statistics.contractRenewal)"></h1>
                  <h1 v-else>0</h1>
                </div>
          </v-card-title>
            <v-row
              align="center"
              class="mx-3 justify-center "
            >
              <h5 :class="item.title == 'Easy Setup' ? 'red-desc' : 'blue-text'" class="  text-center  card-desc-responsive   font-quicksand-medium-18 pb-4" style="line-height:20px" v-if="item.desc">
                {{item.desc}}
              </h5>
            </v-row>
        </v-card>
      
    </div>
    </div>
    <div v-else>
      <v-row  class="justify-center col-md-12 mt-20">
        <v-progress-circular
          :size="100"
          class="text-center mt-10"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-row>
    </div>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import api from '../../../../../services/api'
 export default{
   data(){
     return{
       items:[
        //  {
        //      img:'/cards/woman.png',
        //      title:'Add Plan Member Zip Codes',
        //      url:'providers/add-by-member',
        //      desc:'This will create a targeted provider list to show your average potential savings.'
        //  },
        //  {
        //      img:'/cards/money.png',
        //      title:'Your Average Projected Savings',
        //      procentage:'23%',
        //      url:'providers',
        //  },
         {
             img:'/cards/doctor.png',
             title:'Your Provider List',
             desc:'Access and manage your provider list.',
             url:'providers',
         },         

       ],
       precentageCards:[

        //  {
        //      title: 'Provider Coverage',
        //      percentage:'94%',
        //      desc:'percentage of members with providers within a 30 mile radius of their zip code'
        //  },
         {
             img:"/employer-home.png",
             title:'Easy Setup',
             desc:'Start saving by directly contracting.'
         },
         
         {
             title: 'Providers on Your Provider List',
             percentage:'48,000',
             desc:'based on current provider list filters'
         },
            {
             title: 'Contract Renewal',
             percentage:'5',
             desc:'contract renewal date'
         },
        //  {
        //      title: 'Projected Savings',
        //      percentage:'28%',
        //      desc:'accross all clients, compared to current average network lease costs'
        //  },
     
         {
             title: 'Current Contract Rates',
             percentage:'150%',
             percentage1:'180%',
             desc:'of Medicare benchmark rates',
             inpatient:true
         },

       ],
       contractParsed:'',
       dialogm1: '',
       dialog: false,
       dialog1:false,
       statistics:'',
       showCards:false
    
     }
   },
   computed:{
     routeParams(){
        return this.$route.params.name
      },
      ...mapGetters(['getStatistcisInHorizontalMenu'])
   },
   methods:{
      parsedDate(date){
        let returnData = '/'
        if(date){
         let newDate = new Date(date);
         returnData = newDate.getMonth() + '/' + newDate.getDay() + '/' + newDate.getFullYear();
        }
         return  returnData;
      },
     clientsTable(){
       this.dialog = !this.dialog
       this.dialog = !this.dialog1
       this.$emit('showClient')
     },
     getStatistics(){
         let user = JSON.parse(localStorage.getItem('user'));
         let advisorId = user.groupPortal[0].organization.employerAdvisor
         let employer = JSON.parse(localStorage.getItem('employer'));
        api().get(`/advisors/${advisorId}/employers/${employer._id}/statistics`).then((res)=>{
          this.statistics = res.data.statistics;

          this.$store.commit('setStatisticsInfoHorizontalMenu', res.data.statistics)
        }).catch((err)=>{
          console.log(err.response);
        })
       }
   },
   created(){
       this.getStatistics()
      
   }
 }
</script>
<style>
.border-input-img{
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 8px;
  text-align: left;

}
.send-email-input{
  border-left: none;
}
.send-email-input::placeholder{
   color:gray;
   font-family: 'Mulish', sans-serif;
}
.send-email-input:focus{
   outline: none !important;
}
.email-img-padding{
  display: flex;
  align-items: center;
}
.small-card-text{
  font-size:9px
}

@media only screen and (max-width: 600px) {
  .mobile-padding {
    padding: 20px !important;
  }
  .card-images-mobile{
    margin-left: 150px!important;
  }
}
</style>
<style scoped>
.v-stepper--alt-labels .v-stepper__step{
  flex-basis: 0px !important;
}
</style>
