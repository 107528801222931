<template>
  <v-row class="add-client-form">
    <removeDialog
      ref="dialog_delete"
      v-on:confirm="confirmRemove"
    ></removeDialog>
    <div class="col-md-12 no-padding">
      <div class="row">
        <div class="col-md-12">
          <h3
            class="form-title col-md-12"
            v-if="singleProvider && singleProvider.type == 2"
          >
            Provider Oganization
          </h3>
          <h3 class="form-title col-md-12" v-else>Provider Individual</h3>
        </div>
      </div>
      <v-form v-model.trim="valid" ref="form" class="row pt-0 mt-0">
        <div class="col-md-6">
          <div class="col-md-12 pb-0 pt-0">
            <label
              class="font text-gray"
              v-if="
                singleProvider &&
                singleProvider.type &&
                singleProvider.type == '1'
              "
              for=""
              >Provider Name</label
            >
            <label class="font text-gray" v-else for=""
              >Organization Name</label
            >
            <v-text-field
              required
              :rules="requiredRules"
              v-model.trim="companyName"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Company Display Name</label>
            <v-text-field
              required
              v-model.trim="displayName"
              :rules="requiredRules"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Address 1</label>
            <v-text-field
              required
              v-model.trim="address1"
              :rules="requiredRules"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Address 2</label>
            <v-text-field required v-model.trim="address2" solo></v-text-field>
          </div>
          <v-row class="no-padding">
            <div class="col-md-6 pb-0 pt-0">
              <label class="font text-gray" for="">City</label>
              <v-text-field
                required
                v-model.trim="city"
                solo
                :rules="requiredRules"
              ></v-text-field>
            </div>
            <div class="col-md-3 pb-0 pt-0">
              <label class="font text-gray" for="">State</label>
              <!-- <v-text-field solo v-model.trim="state"> </v-text-field> -->
              <v-select
                :items="states"
                item-text="name"
                item-value="name"
                required
                v-model.trim="state"
                solo
                :rules="requiredRules"
              ></v-select>
            </div>
            <div class="col-md-3 zip-field-height pb-0 pt-0">
              <label class="font text-gray" for="">Zip</label>
              <v-text-field
                required
                type="number"
                v-model.trim="zip"
                solo
                :rules="zipRules"
              ></v-text-field>
            </div>
          </v-row>
          <!-- <div class="col-md-12"  v-if="singleProvider['NPI']">
               <label class="font text-gray" for="">Provider Npi</label>
                <v-text-field
                    required
                    type="number"
                   
                    :rules="requiredRules"
                    v-model.trim="providerNpi"
                    solo
                ></v-text-field>
           </div> -->
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Tax ID</label>
            <v-text-field
              required
              @keyup="showSlash($event)"
              v-model.trim="taxId"
              solo
              placeholder="00-0000000"
              :rules="taxIdRules"
              counter="10"
              maxlength="10"
            ></v-text-field>
          </div>

          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Provider Type</label>
            <v-select
              required
              :rules="requiredRules"
              v-model.trim="providerType"
              solo
              :items="providerTypeItems"
            ></v-select>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Taxonomy</label>
            <v-text-field
              required
              :rules="requiredRules"
              v-model.trim="taxonomy"
              solo
            ></v-text-field>
          </div>
          <!-- <div class="col-md-12">
               <label class="font text-gray" for="">Preferred Provider Participation</label>
                <v-text-field
                    required
                    :rules="requiredRules"
                    v-model.trim="participation"
                    solo
                    :placeholder="participation ? 'Yes' : 'No'"
                ></v-text-field>
           </div> -->
        </div>
        <div class="col-md-6">
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for=""
              >Primary Contact First Name
            </label>
            <v-text-field
              required
              v-model.trim="contactFirstName"
              solo
              :rules="requiredRules"
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for=""
              >Primary Contact Last Name</label
            >
            <v-text-field
              required
              v-model.trim="contactLastName"
              solo
              :rules="requiredRules"
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Primary Contact Title</label>
            <v-text-field
              required
              v-model.trim="contactTitle"
              solo
              :rules="requiredRules"
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Primary Contact Email</label>
            <v-text-field
              required
              disabled
              :rules="emailRules"
              v-model.trim="contactEmail"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Primary Contact Phone</label>
            <v-text-field
              required
              v-model.trim="contactPhone"
              solo
              :rules="phoneRules"
            ></v-text-field>
          </div>
          <div class="col-md-12 mt-3">
            <v-btn large class="blue-buttons" @click="goToHorizontalMenu"
              >Access Provider Portal</v-btn
            >
          </div>
        </div>
        <div class="col-md-12 row text-end justify-end" v-if="$role != 'user'">
          <v-btn class="blue-buttons mb-2" @click="removeProvider">
            Remove Provider
          </v-btn>
          <v-btn class="blue-buttons ml-2" @click="updateProvider">
            Update & Save
          </v-btn>
          <v-btn
            class="blue-buttons ml-2"
            @click="$router.replace('/provider-advisor/providers')"
          >
            Close
          </v-btn>
        </div>
      </v-form>
      <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
      <successModal
        ref="success_modal"
        v-on:confirm="closeSuccessModal"
      ></successModal>
    </div>
  </v-row>
</template>
<script>
import api from "../../../../services/api";
import removeDialog from "../../../TablesTemplate/RemoveDialog.vue";
import errorModal from "../../../TablesTemplate/ErrorDialog.vue";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";

export default {
  components: { removeDialog, errorModal, successModal },
  data() {
    return {
      companyName: "",
      taxId: "",
      contactFirstName: "",
      providerNpi: "",
      logo: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      companyLogo: {
        name: "",
      },
      providerTypeItems: [
        {
          text: "Group",
          value: "group",
        },
        {
          text: "Hospital",
          value: "hospital",
        },
        {
          text: "Affiliate",
          value: "affiliate",
        },
      ],
      contactLastName: "",
      displayName: "",
      contactTitle: "",
      contactEmail: "",
      participation: "",
      providerType: "",
      taxonomy: "",
      states: [],
      contactPhone: "",
      valid: false,
      requiredRules: [(v) => !!v || "This field is required"],
      imageEvent: null,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      taxIdRules: [
        (v) => !!v || "This field is required",
        (v) =>
          !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
          "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
      ],
      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
      zipRules: [
        (v) => (v && v.length == 5) || " Zip Code should be five digits long",
      ],
    };
  },
  computed: {
    singleProvider() {
      return this.$store.getters.getSingleProviderInemployerAdvisor;
    },
  },
  created() {
    this.getStates();

    if (this.singleProvider) {
      this.providerNpi = this.singleProvider["NPI"]
        ? this.singleProvider["NPI"]
        : "";
      this.providerType = this.singleProvider.providerType
        ? this.singleProvider.providerType.charAt(0).toUpperCase() +
          this.singleProvider.providerType.slice(1)
        : "";
      this.contactFirstName =
        this.singleProvider.primaryContact &&
        this.singleProvider.primaryContact.firstName
          ? this.singleProvider.primaryContact.firstName
          : this.singleProvider["Authorized Official First Name"]
          ? this.singleProvider["Authorized Official First Name"]
          : "";
      this.participation =
        this.singleProvider.settings &&
        this.singleProvider.settings.preferredProviderParticipation
          ? this.singleProvider.settings.preferredProviderParticipation
          : "";
      this.taxonomy = this.singleProvider.taxonomy
        ? this.singleProvider.taxonomy
        : this.singleProvider["Healthcare Provider Taxonomy Code_1"]
        ? this.singleProvider["Healthcare Provider Taxonomy Code_1"]
        : "";
      this.address1 = this.singleProvider.address1
        ? this.singleProvider.address1
        : this.singleProvider["Provider First Line Business Mailing Address"]
        ? this.singleProvider["Provider First Line Business Mailing Address"]
        : "";
      this.address2 = this.singleProvider.address2
        ? this.singleProvider.address2
        : this.singleProvider[
            "Provider First Line Business Practice Location Address"
          ]
        ? this.singleProvider[
            "Provider First Line Business Practice Location Address"
          ]
        : "";

      this.city = this.singleProvider.city
        ? this.singleProvider.city
        : this.singleProvider[
            "Provider Business Practice Location Address City Name"
          ]
        ? this.singleProvider[
            "Provider Business Practice Location Address City Name"
          ]
        : "";
      this.state = this.singleProvider.state
        ? this.singleProvider.state
        : this.singleProvider["Provider Business Mailing Address State Name"]
        ? this.singleProvider["Provider Business Mailing Address State Name"]
        : "";

      this.zip = this.singleProvider.zipCode
        ? this.singleProvider.zipCode
        : this.singleProvider["Provider Business Mailing Address Postal Code"]
        ? this.singleProvider["Provider Business Mailing Address Postal Code"]
        : "";
      this.contactLastName =
        this.singleProvider.primaryContact &&
        this.singleProvider.primaryContact.lastName
          ? this.singleProvider.primaryContact.lastName
          : this.singleProvider["Authorized Official Last Name"]
          ? this.singleProvider["Authorized Official Last Name"]
          : "";
      this.contactTitle =
        this.singleProvider.primaryContact &&
        this.singleProvider.primaryContact.title
          ? this.singleProvider.primaryContact.title
          : this.singleProvider["Authorized Official Title or Position"]
          ? this.singleProvider["Authorized Official Title or Position"]
          : "";
      this.contactEmail =
        this.singleProvider.primaryContact &&
        this.singleProvider.primaryContact.email
          ? this.singleProvider.primaryContact.email
          : "";
      this.contactPhone =
        this.singleProvider.primaryContact &&
        this.singleProvider.primaryContact.phone
          ? this.singleProvider.primaryContact.phone
          : this.singleProvider["Authorized Official Telephone Number"]
          ? this.singleProvider["Authorized Official Telephone Number"]
          : "";
      this.taxId = this.singleProvider.taxId
        ? this.singleProvider.taxId
        : this.singleProvider["Healthcare Provider Taxonomy Code_1"]
        ? this.singleProvider["Healthcare Provider Taxonomy Code_1"]
        : "";
      this.providerType = this.singleProvider.providerCategory;
      if (this.singleProvider.providerName) {
        this.companyName = this.singleProvider.providerName
          ? this.singleProvider.providerName
          : "";
      } else if (this.singleProvider.companyName) {
        this.companyName = this.singleProvider.companyName
          ? this.singleProvider.companyName
          : "";
      } else if (this.singleProvider["Provider First Name"]) {
        this.companyName =
          this.singleProvider["Provider First Name"] +
          " " +
          this.singleProvider["Provider Last Name (Legal Name)"];
      } else {
        this.companyName =
          this.singleProvider[
            "Provider Organization Name (Legal Business Name)"
          ];
      }
      this.displayName = this.singleProvider.displayName
        ? this.singleProvider.displayName
        : "";
      localStorage.setItem("providerName", JSON.stringify(this.displayName));
      document.getElementById("changeProviderName").click();
    }
  },
  methods: {
    updateProvider() {
      this.$refs.form.validate();
      if (this.valid) {
        let user = JSON.parse(localStorage.getItem("user"));
        let advisorId = user.groupPortal[0].organization.providerAdvisor;
        let data = null;

        if (this.singleProvider.type == "1") {
          data = {
            providerName: this.companyName,
            displayName: this.displayName,
            address1: this.address1,
            address2: this.address2,
            city: this.city,
            state: this.state,
            zipCode: this.zip,
            taxId: this.taxId,
            providerCategory: this.providerType,
            taxonomy: this.taxonomy,
            preferredParticipation: this.participation,
            firstName: this.contactFirstName,
            lastName: this.contactLastName,
            title: this.contactTitle,
            email: this.contactEmail,
            phone: this.contactPhone,
          };
        } else if (this.singleProvider.type == "2") {
          data = {
            companyName: this.companyName,
            displayName: this.displayName,
            address1: this.address1,
            address2: this.address2,
            city: this.city,
            state: this.state,
            zipCode: this.zip,
            taxId: this.taxId,
            providerCategory: this.providerType,
            taxonomy: this.taxonomy,
            preferredParticipation: this.participation,
            firstName: this.contactFirstName,
            lastName: this.contactLastName,
            title: this.contactTitle,
            email: this.contactEmail,
            phone: this.contactPhone,
          };
        }

        api()
          .put(
            `/advisors/${advisorId}/providers/${this.singleProvider._id}`,
            data
          )
          .then((res) => {
            if (res) {
              this.$refs.success_modal.openPopUp(
                "Provider updated successfully"
              );
            }
          });
      }
    },
    showSlash(event){
      if (this.taxId.length == 2 && event.keyCode != 8) {
        this.taxId += "-";
      }
      if (this.taxId.length == 10) {
        api()
          .get("auth/isValidTaxId?taxId=" + this.taxId)
          .catch((res) => {
            if (res) {
              this.taxIdRules = [res.response.data.message];
            }
          });
      } else {
        this.taxIdRules = [
          (v) => !!v || "This field is required",
          (v) =>
            !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
            "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
        ];
      }
    },
    goToHorizontalMenu() {
      localStorage.setItem("provider", JSON.stringify(this.singleProvider));

      if (this.singleProvider.displayName) {
        let parsed = this.singleProvider.displayName.split(" ");
        let str = "";
        parsed.forEach((el) => {
          str += el.charAt(0).toUpperCase() + el.slice(1);
        });
         localStorage.setItem("providerName", JSON.stringify(str));
        this.$router.push({
          path: "/provider-advisor/providers/" + str,
          params: { name: str },
        });
      }
    },
    activateFileInput() {
      this.$refs.fileInput.$refs.input.click();
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    getStates() {
      api()
        .get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
      this.$router.push("/provider-advisor/providers");
    },
    confirmRemove() {
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.providerAdvisor;
      api()
        .delete(`/advisors/${advisorId}/providers/${this.singleProvider._id}`)
        .catch((err) => {
          if (err) {
            this.$refs.error_modal.openPopUp(err.response.data.message);
            this.$refs.dialog_delete.closePopUp();
          }
        })
        .then((res) => {
          if (res) {
            this.$router.push("/provider-advisor/providers");
          }
        });
    },
    removeProvider() {
      let text = "Are you sure that you want to delete this Provider?";
      this.$refs.dialog_delete.openPopUp(text);
    },
    Preview_image(event) {
      this.url = URL.createObjectURL(this.companyLogo);
      this.imageEvent = event;
    },
  },
};
</script>