<template>
  <div class="">
    <div v-if="!isChildren" class="row-height">
      <Header
        :title="'Billing'"
        :searchInput="true"
        :inviteButton="false"
        :exportButton="true"
        :newButton="false"
        :addButton="true"
        @export="exportFun"
        @invite="invite"
        @add="add"
        @searchInput="searchInput"
        :placeholder="'Search Billing'"
        :sort="false"
      ></Header>
      <div class="mt-3">
        <v-simple-table
          fixed-header
          id="tblData"
          height="70vh"
          class="no-padding tr-custom-height"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">INVOICE NUMBER</th>
                <th class="text-left">VENDOR NAME</th>
                <th class="text-left">DATE</th>
                <th class="text-left">AMOUNT</th>
                <th class="text-left">STATUS</th>
                <th class="text-left">PAYMENTS</th>
              </tr>
            </thead>
            <tbody
              v-if="items.length > 0 && !filterItems"
              class="text-gray tr-custom-height"
            >
              <tr v-for="(item, index) in items" :key="index">
                <td v-if="item.invoiceNo" @click="showClient(item)">
                  <a>{{ item.invoiceNo }}</a>
                </td>
                <td v-else><a>/</a></td>

                <td v-html="item.companyName ? item.companyName : '/'"></td>

                <td>
                  <span v-if="item.date">{{ item.date | formatDate }} </span>
                  <span v-else>/</span>
                </td>
                <td v-html="item.total ? '$' + item.total : '/'"></td>
                <td
                  v-html="
                    item.status
                      ? item.status.charAt(0).toUpperCase() +
                        item.status.slice(1)
                      : '/'
                  "
                ></td>

                <td>
                  {{ item.payment }}
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="filterItems">
              <v-col md="12" cols="12">
                <v-alert
                  border="bottom"
                  colored-border
                  type="warning"
                  elevation="2"
                  class="text-gray"
                >
                  Sorry! No results found
                </v-alert>
              </v-col>
            </tbody>

            <tbody v-else>
              <v-col md="12">
                There are no invoices that have been received for any of your
                clients.
              </v-col>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import Header from "../Layout/HeaderPage.vue";
import api from "../../../../services/api";

export default {
  data() {
    return {
      showDetails: false,
      tab: "solution",
      dialog: false,
      items: [],

      singleItem: null,
      typeItems: [{ text: "Categories", value: "Categories" }],
      sortItems: [{ text: "Newest", value: "Newest" }],
      sortBy: "",
      type: "",
      solutions: [],
      favorites: [],
      filterItems: false,
      filteredArray: [],
    };
  },
  components: {
    Header,
  },
  computed: {
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
  },
  created() {
    this.getData();
  },
  methods: {
    exportData(tableID, filename = "") {
      var elt = document.getElementById(tableID);
      var downloadLink;
      var dataType = "application/vnd.ms-excel";
      var tableSelect = elt;
      var tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

      // Specify file name
      filename = filename ? filename + ".xls" : "excel_data.xls";

      // Create download link element
      downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        var blob = new Blob(["\ufeff", tableHTML], {
          type: dataType,
        });
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = "data:" + dataType + ", " + tableHTML;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
    },
    getData() {
      api()
        .get("/tpas/invoices")
        .then((res) => {
          if (res) {
            this.items = res.data;
            this.filteredArray = res.data;
          }
        });
    },
    invite() {},
    add() {},
    exportFun() {
      this.exportData("tblData");
    },

    searchInput(val) {
      this.items = this.filteredArray.filter((el) => {
        if (
          el.invoiceNo &&
          el.invoiceNo.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.invoiceNo.toLowerCase().includes(val.toLowerCase());
        } else if (
          el.companyName &&
          el.companyName.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.companyName.toLowerCase().includes(val.toLowerCase());
        }
      });
      if (this.items.length < 1) {
        this.filterItems = true;
      } else {
        this.filterItems = false;
      }
    },
    showClient(item) {
      this.singleItem = item;
      this.$store.commit("setSingleItem", item);
      this.$router.push("/digital-invoicing/billing/details");
    },

    searchMembers() {},
  },
};
</script>
<style>
</style>