<template>
  <div class="">
    <div v-if="!isChildren" class="row-height">
      <Header
        :title="'Users'"
        :searchInput="true"
        :placeholder="'Search Users'"
        :newButton="true"
        :exportButton="true"
        @invite="invite"
        @add="add"
        @searchInput="searchInput"
      ></Header>
      <div class="mt-3">
        <v-simple-table fixed-header height="630px">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">First Name</th>
                <th class="text-left">Last Name</th>
                <th class="text-left">Email</th>
                <th class="text-left">Title</th>
                <th class="text-left">City</th>
                <th class="text-left">State</th>
              </tr>
            </thead>
            <tbody v-if="allUsers.length > 0">
              <tr v-for="item in allUsers" :key="item.name" class="text-gray">
                <td v-if="item.firstName">
                  <a @click="singleUser(item)">{{ item.firstName }} </a>
                </td>
                <td v-else><a @click="singleUser(item)">/ </a></td>
                <td v-if="item.lastName">
                  <a @click="singleUser(item)">{{ item.lastName }} </a>
                </td>
                <td v-else>/</td>
                <td v-html="item.email ? item.email : '/'"></td>
                <td v-if="item.title">
                  {{ item.title.charAt(0).toUpperCase() + item.title.slice(1) }}
                </td>
                <td v-else>/</td>
                <td v-html="item.city ? item.city : '/'"></td>
                <td v-html="item.state ? item.state : '/'"></td>
              </tr>
            </tbody>

            <tbody v-else>
              <v-col md="12">
                You Don't have Users Yet. Click Here To Add New User.
              </v-col>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import Header from "../Layout/HeaderPage.vue";
import api from "../../../../services/api";
export default {
  data() {
    return {
      showDetails: false,
      tab: "solution",
      dialog: false,
      allUsers: [],
      singleItem: null,
      typeItems: [{ text: "Categories", value: "Categories" }],
      sortItems: [{ text: "Newest", value: "Newest" }],
      sortBy: "",
      type: "",
      solutions: [],
      favorites: [],
      filterArray: [],
      noResultMessage: false,
    };
  },
  components: {
    Header,
  },
  computed: {
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
  },
  created() {
    this.getAllUsers();
  },
  methods: {
    invite() {},
    add() {
      this.$router.push("/digital-invoicing/users/add-new");
    },
    getAllUsers() {
      api()
        .get(`/tpas/users`)
        .then((res) => {
          this.allUsers = res.data;
          this.filterArray = res.data;

          if (res.data.length < 1) {
            this.noResultMessage = true;
          } else {
            this.noResultMessage = false;
          }
        });
    },
    searchInput() {},
    singleUser(item) {
      this.$store.commit("setSingleItem", item);
      this.$router.push("/digital-invoicing/users/details");
      // api()
      //   .get(`/tpas/users/` + item._id)
      //   .then((res) => {
      //     this.singleItem = res.data.value;

      //   });
    },

    searchMembers() {},
  },
};
</script>
<style>
.border-blue-bottom {
  border-bottom: 6px solid #4072b7;
  margin-left: -1px;
  margin-right: -2px;
  margin-top: -1px;
}

.tr-custom-height > .v-data-table__wrapper > table > tbody > tr > td {
  height: 75px;
}
</style>