var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row row-height"},[_c('v-sheet',{staticClass:"overflow-hidden col-md-12 border-r-8",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"container-fluid"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('div',{class:_vm.overlay
              ? 'hideOnMoblie background-opacity col-md-9'
              : 'col-md-12 navigationWidth '},[_c('v-row',[_c('v-col',{staticClass:"col-xl-1 col-md-2 cols-4",attrs:{"cols":"4"}},[_c('div',{staticClass:"col-md-12"},[_c('h3',{staticClass:"form-title font"},[_vm._v("Users")])])]),_c('v-col',{staticClass:"col-xl-3 col-md-6 cols-8",attrs:{"cols":"8"}},[_c('div',{staticClass:"col-md-12 d-flex no-padding"},[_c('v-text-field',{staticClass:"input-control mr-5",attrs:{"disabled":_vm.overlay,"prepend-inner-icon":"mdi-magnify","light":"","dense":"","outlined":"","placeholder":"Search Users"},on:{"input":_vm.searchUsers}}),_c('v-btn',{staticClass:"text-gray filter-btn",attrs:{"disabled":_vm.overlay},on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}},[_c('svg',{staticClass:"mr-2",attrs:{"width":"17","height":"14","viewBox":"0 0 17 14","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1 1H16","stroke":"#424242","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M2.59766 5H14.403","stroke":"#424242","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M6.3125 13H10.6858","stroke":"#424242","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M4.19531 9H12.8046","stroke":"#424242","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}})]),_vm._v(" Filters ")])],1)]),(_vm.$role != 'user')?_c('v-col',{staticClass:"col-xl-8 col-md-4 cols-12 text-end",attrs:{"cols":"12","align":"end"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"my-1 blue-buttons",attrs:{"disabled":_vm.overlay}},on),[_vm._v("Add / Invite Users")])]}}],null,false,1245295530)},[_c('v-list',[_c('v-list-item',{staticStyle:{"disply":"block"}},[_c('a',{staticClass:"a-tag underline-text",on:{"click":function($event){return _vm.$router.push(
                          `/provider-advisor/providers/${_vm.routeParams}/settings/users/add-new`
                        )}}},[_vm._v("Add User")])]),_c('v-list-item',[_c('a',{staticClass:"a-tag underline-text",on:{"click":function($event){return _vm.$router.push(
                          `/provider-advisor/providers/${_vm.routeParams}/settings/users/invite-user`
                        )}}},[_vm._v("Invite Users")])])],1)],1)],1):_vm._e()],1),(_vm.readyToShow)?_c('div',{staticClass:"col-md-12 text-center justify-center"},[_c('v-progress-circular',{staticClass:"text-center",attrs:{"indeterminate":"","size":100,"color":"primary"}})],1):_c('div',[(_vm.allUsers.length < 1 && !_vm.filterItems)?_c('v-row',[_c('v-col',{staticClass:"ml-2",attrs:{"md":"12"}},[(_vm.$role != 'user')?_c('a',{on:{"click":function($event){return _vm.$router.push(
                      `/provider-advisor/providers/${_vm.routeParams}/settings/users/add-new`
                    )}}},[_vm._v("You Don't have Users Yet. Click Here To Add New User.")]):_c('span',[_vm._v("You Don't have Users Yet.")])])],1):_c('v-simple-table',{attrs:{"fixed-header":"","height":"630px"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("First Name")]),_c('th',{staticClass:"text-left"},[_vm._v("Last Name")]),_c('th',{staticClass:"text-left"},[_vm._v("Email")]),_c('th',{staticClass:"text-left"},[_vm._v("Status")]),_c('th',{staticClass:"text-left"},[_vm._v("Title")]),_c('th',{staticClass:"text-left"},[_vm._v("Role")])])]),(_vm.allUsers.length > 0)?_c('tbody',_vm._l((_vm.allUsers),function(item){return _c('tr',{key:item.name,staticClass:"text-gray"},[(item.firstName)?_c('td',[_c('a',{on:{"click":function($event){return _vm.singleUser(item)}}},[_vm._v(_vm._s(item.firstName)+" ")])]):_c('td',[_c('a',{on:{"click":function($event){return _vm.singleUser(item)}}},[_vm._v("/ ")])]),_c('td',{domProps:{"innerHTML":_vm._s(item.lastName ? item.lastName : '/')}}),_c('td',{domProps:{"innerHTML":_vm._s(item.email ? item.email : '/')}}),_c('td',{domProps:{"innerHTML":_vm._s(
                        item.status && item.status == 'inactive'
                          ? 'In Active'
                          : item.status
                          ? item.status.charAt(0).toUpperCase() +
                            item.status.slice(1)
                          : '/'
                      )}},[_vm._v(" "+_vm._s(item.status.charAt(0).toUpperCase() + item.status.slice(1))+" ")]),(item.title)?_c('td',[_vm._v(" "+_vm._s(item.title.charAt(0).toUpperCase() + item.title.slice(1))+" ")]):_c('td',[_vm._v("/")]),_c('td',{domProps:{"innerHTML":_vm._s(
                        item.groupPortal[0] &&
                        item.groupPortal[0].role &&
                        item.groupPortal[0].role.name
                          ? item.groupPortal[0].role.name
                              .charAt(0)
                              .toUpperCase() +
                            item.groupPortal[0].role.name.slice(1)
                          : '/'
                      )}})])}),0):_vm._e(),(_vm.allUsers.length < 1 && _vm.filterItems)?_c('div',[_c('v-col',{attrs:{"md":"12","cols":"12"}},[_c('v-alert',{staticClass:"text-gray",attrs:{"border":"bottom","colored-border":"","type":"warning","elevation":"2"}},[_vm._v(" Sorry! No results found ")])],1)],1):_vm._e()]},proxy:true}])})],1)],1)])],1),_c('v-navigation-drawer',{staticClass:"userFilterPosition",attrs:{"width":"350","absolute":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"drawer"}},[_c('div',{staticClass:"filter-sideBar"},[_c('div',[_c('div',{staticClass:"row no-padding col-md-12 justify-space-between"},[_c('h3',{staticClass:"font pl-3 mt-1"},[_vm._v("User Filters")]),_c('h1',{staticClass:"pointer mr-3",on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}},[_vm._v("x")])]),_c('v-divider'),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row col-md-12 justify-space-between no-padding no-margin"},[_c('div',{staticClass:"no-padding font filter-items-font font-weight-bold"},[_vm._v(" Selected Filter ")]),_c('div',{staticClass:"underline-text pointer text-gray filter-items-font",on:{"click":_vm.clearAll}},[_vm._v(" Clear All ")])])]),_c('div',{staticClass:"overflow-fitlers-buttons"},[_c('div',{staticClass:"row mb-1 col-md-12"},_vm._l((_vm.selectedArray.concat(
                  _vm.selectedTypeArray
                )),function(item,index){return _c('v-btn',{key:index,staticClass:"text-center mb-1 ml-2 mr-2 filter-btn",attrs:{"small":""}},[_c('span',{domProps:{"innerHTML":_vm._s(item.text)}}),_c('h5',{staticClass:"ml-3",on:{"click":function($event){return _vm.removeFilter(item)}}},[_vm._v("x")])])}),1),_c('v-divider'),_c('v-expansion-panels',{staticClass:"no-shadow no-padding user-expansion-panels",attrs:{"accordion":"","multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"panel"}},[_c('v-expansion-panel',{staticClass:"no-shadow no-padding"},[_c('v-expansion-panel-header',{staticClass:"font-weight-black change-padding-filter filter-items-font"},[_vm._v("Status")]),_c('v-expansion-panel-content',{staticClass:"no-padding content-filter"},[_c('v-text-field',{staticClass:"filter-serach-input mb-4",attrs:{"solo":"","dense":"","hide-details":true,"placeholder":"Search","append-icon":"fa fa-search"},on:{"input":function($event){return _vm.searchStatus()}},model:{value:(_vm.status),callback:function ($$v) {_vm.status=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"status"}}),(_vm.showStatus.all)?_c('v-checkbox',{staticClass:"no-padding small-text radio-buttons-filters",attrs:{"label":"All","color":"primary","hide-details":"","on-icon":'mdi-square'},on:{"click":function($event){return _vm.pushFilter(
                        { text: 'All', value: 'all' },
                        _vm.filter.status.all
                      )}},model:{value:(_vm.filter.status.all),callback:function ($$v) {_vm.$set(_vm.filter.status, "all", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"filter.status.all"}}):_vm._e(),(_vm.showStatus['join request'])?_c('v-checkbox',{staticClass:"no-padding radio-buttons-filters",attrs:{"label":"Join Request","color":"primary","hide-details":"","on-icon":'mdi-square'},on:{"click":function($event){return _vm.pushFilter(
                        { text: 'Join Request', value: 'join' },
                        _vm.filter.status.join
                      )}},model:{value:(_vm.filter.status.join),callback:function ($$v) {_vm.$set(_vm.filter.status, "join", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"filter.status.join"}}):_vm._e(),(_vm.showStatus.active)?_c('v-checkbox',{staticClass:"no-padding radio-buttons-filters",attrs:{"label":"Active","color":"primary","on-icon":'mdi-square',"hide-details":""},on:{"click":function($event){return _vm.pushFilter(
                        { text: 'Active', value: 'active' },
                        _vm.filter.status.active
                      )}},model:{value:(_vm.filter.status.active),callback:function ($$v) {_vm.$set(_vm.filter.status, "active", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"filter.status.active"}}):_vm._e(),(_vm.showStatus['in active'])?_c('v-checkbox',{staticClass:"no-padding radio-buttons-filters",attrs:{"label":"In Active","color":"primary","on-icon":'mdi-square',"hide-details":""},on:{"click":function($event){return _vm.pushFilter(
                        { text: 'In Active', value: 'inactive' },
                        _vm.filter.status.inactive
                      )}},model:{value:(_vm.filter.status.inactive),callback:function ($$v) {_vm.$set(_vm.filter.status, "inactive", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"filter.status.inactive"}}):_vm._e(),(_vm.showStatus.invited)?_c('v-checkbox',{staticClass:"no-padding radio-buttons-filters mb-2",attrs:{"label":"Invited","color":"primary","on-icon":'mdi-square',"hide-details":""},on:{"click":function($event){return _vm.pushFilter(
                        { text: 'Invited', value: 'invited' },
                        _vm.filter.status.invited
                      )}},model:{value:(_vm.filter.status.invited),callback:function ($$v) {_vm.$set(_vm.filter.status, "invited", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"filter.status.invited"}}):_vm._e()],1)],1),_c('v-expansion-panel',{staticClass:"no-shadow no-padding"},[_c('v-expansion-panel-header',{staticClass:"font-weight-black change-padding-filter filter-items-font mb-0 pb-0"},[_vm._v("Type")]),_c('v-expansion-panel-content',{staticClass:"no-padding content-filter"},[_c('v-text-field',{staticClass:"filter-serach-input no-padding mb-4",attrs:{"solo":"","dense":"","hide-details":true,"placeholder":"Search","append-icon":"fa fa-search"},on:{"input":function($event){return _vm.searchType()}},model:{value:(_vm.type),callback:function ($$v) {_vm.type=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"type"}}),(_vm.showType.all)?_c('v-checkbox',{staticClass:"no-padding radio-buttons-filters",attrs:{"label":"All","color":"primary","on-icon":'mdi-square',"hide-details":""},on:{"click":function($event){return _vm.pushtoTypeFilter(
                        { text: 'All', value: 'allTypes' },
                        _vm.filter.type.all
                      )}},model:{value:(_vm.filter.type.all),callback:function ($$v) {_vm.$set(_vm.filter.type, "all", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"filter.type.all"}}):_vm._e(),(_vm.showType.admin)?_c('v-checkbox',{staticClass:"no-padding radio-buttons-filters",attrs:{"label":"Admins","color":"primary","on-icon":'mdi-square',"hide-details":""},on:{"click":function($event){return _vm.pushtoTypeFilter(
                        { text: 'Admins', value: 'admin' },
                        _vm.filter.type.admin
                      )}},model:{value:(_vm.filter.type.admin),callback:function ($$v) {_vm.$set(_vm.filter.type, "admin", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"filter.type.admin"}}):_vm._e(),(_vm.showType['general user'])?_c('v-checkbox',{staticClass:"no-padding radio-buttons-filters",attrs:{"label":"General User","color":"primary","on-icon":'mdi-square',"hide-details":""},on:{"click":function($event){return _vm.pushtoTypeFilter(
                        { text: 'General User', value: 'user' },
                        _vm.filter.type.user
                      )}},model:{value:(_vm.filter.type.user),callback:function ($$v) {_vm.$set(_vm.filter.type, "user", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"filter.type.user"}}):_vm._e()],1)],1)],1)],1)],1),_c('div',[_c('v-divider'),_c('v-btn',{staticClass:"blue-buttons col-md-12 mt-3",on:{"click":_vm.filterUsers}},[_vm._v("View Result")])],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }