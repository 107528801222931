<template>
  <div class="background-gray">
    <div class="mobile-background">
      <router-view></router-view>
    </div>
  </div>

</template>
<script>
export default {
  components: {},
  data() {
    return {

    };
  },
  created() {},
  computed: {},
  methods: {},
};
</script>
<style scoped src='./../../../../assets/mobileStyle.css'></style>
<style scoped lang="scss">


.mobile-background{
  max-width: 420px;
  max-height: 920px;
  margin: auto;
  height: 100vh; width: 100vw;
  overflow: hidden;
}
</style>
