<template>
    <div class="no-padding">
        <div class="col-md-12">
            <v-row style="justify-content: space-between">
                <v-col class="col-md-4" md="4" cols="12">
                    <h3>
                        <span class="font">Prior Authorization Request Detail</span>
                    </h3>
                </v-col>

                <v-col class="col-md-4 text-end" cols="12"   align="end">
                    <v-btn @click="$router.push('/employer/prior-auth/rules')" class="my-1 blue-buttons">Prior Auth Rules</v-btn>
                </v-col>
            </v-row>
        </div>
        <v-simple-table fixed-header  height="630px">
            <template v-slot:default>
                <thead>
                <tr>
                    <th class="text-left">
                        Procedure Description
                    </th>
                    <th class="text-left">
                        Code Type
                    </th>
                    <th class="text-left">
                        Provider
                    </th>
                    <th class="text-left">
                        Est.Cost
                    </th>
                    <th class="text-left">
                        Local Avg.Cost
                    </th>
                    <th class="text-left">
                        Prereqs
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr
                        v-for="item in items"
                        :key="item.name"
                >
                    <td><a  @click="$router.push('/employer/prior-auth/detail')">{{ item.auth }}</a> </td>
                    <td class="text-gray font">{{item.code}}</td>
                    <td class="text-gray font">{{item.provider}}</td>
                    <td class="text-gray font">{{item.cost}}</td>
                    <td class="text-gray font">{{item.avgCost}}</td>
                    <td v-if="item.prereqs == 'yes'">
                        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.59961 14.3057L9.21294 18.0145C9.31207 18.1626 9.44513 18.2848 9.60105 18.3711C9.75696 18.4574 9.93123 18.5052 10.1093 18.5105C10.2874 18.5158 10.4643 18.4785 10.625 18.4017C10.7858 18.3249 10.926 18.2108 11.0337 18.0689L19.3996 7.48438" stroke="#069B0C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M1 13C1 16.1826 2.26428 19.2348 4.51472 21.4853C6.76516 23.7357 9.8174 25 13 25C16.1826 25 19.2348 23.7357 21.4853 21.4853C23.7357 19.2348 25 16.1826 25 13C25 9.8174 23.7357 6.76516 21.4853 4.51472C19.2348 2.26428 16.1826 1 13 1C9.8174 1 6.76516 2.26428 4.51472 4.51472C2.26428 6.76516 1 9.8174 1 13V13Z" stroke="#069B0C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </td>
                    <td v-else>
                        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 13C1 16.1826 2.26428 19.2348 4.51472 21.4853C6.76516 23.7357 9.8174 25 13 25C16.1826 25 19.2348 23.7357 21.4853 21.4853C23.7357 19.2348 25 16.1826 25 13C25 9.8174 23.7357 6.76516 21.4853 4.51472C19.2348 2.26428 16.1826 1 13 1C9.8174 1 6.76516 2.26428 4.51472 4.51472C2.26428 6.76516 1 9.8174 1 13V13Z" stroke="#D00000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M8.19922 17.8002L17.7992 8.2002" stroke="#D00000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M17.7992 17.8002L8.19922 8.2002" stroke="#D00000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </td>

                </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>
<script>
    export default {
        name:'EmpPriorAuth',
        data () {
            return {
                items: [
                    {
                        auth: 'MRI L-Spine',
                        code:'CPT',
                        provider:'Tampa Hospital',
                        cost:'$ 5,000',
                        avgCost : '$ 1,500',
                        prereqs:'yes',
                        date: '10/01/2021',
                        status: 'Pending',
                        authNo:'NA',
                        memberName:'Valarie Vaughn',

                    },
                    {
                        auth: 'Diskectomy',
                        code:'CPT',
                        provider:'Tampa Hospital',
                        cost:'$ 5,000',
                        avgCost : '$ 1,500',
                        prereqs:'no',
                        date: '10/01/2021',
                        status: 'Pending',
                        authNo:'NA',
                        memberName:'Valarie Vaughn',

                    },
                    {
                        auth: 'Total Knee Arthoplasty',
                        code:'CPT',
                        provider:'Tampa Hospital',
                        cost:'$ 5,000',
                        avgCost : '$ 1,500',
                        prereqs:'yes',
                        date: '10/01/2021',
                        status: 'Pending',
                        authNo:'NA',
                        memberName:'Valarie Vaughn',

                    },
                    {
                        auth: 'Cochelear Implant',
                        code:'CPT',
                        provider:'Tampa Hospital',
                        cost:'$ 5,000',
                        avgCost : '$ 1,500',
                        prereqs:'no',
                        date: '10/01/2021',
                        status: 'Pending',
                        authNo:'NA',
                        memberName:'Valarie Vaughn',

                    },
                    {
                        auth: 'MRI L-Spine',
                        code:'CPT',
                        provider:'Tampa Hospital',
                        cost:'$ 5,000',
                        avgCost : '$ 1,500',
                        prereqs:'yes',
                        date: '10/01/2021',
                        status: 'Pending',
                        authNo:'NA',
                        memberName:'Valarie Vaughn',

                    },
                    {
                        auth: 'Spinal Fusion',
                        code:'CPT',
                        provider:'Tampa Hospital',
                        cost:'$ 5,000',
                        avgCost : '$ 1,500',
                        prereqs:'no',
                        date: '10/01/2021',
                        status: 'Pending',
                        authNo:'NA',
                        memberName:'Valarie Vaughn',

                    },

                ],
            }
        },

    }
</script>
