<template>
  <div class="container-fluid">
    <Header
      @expantSideBarOnHover="expantSideBarOnHover"
      @hideSideBar="hideSideBar"
    />
    <v-row class="background-gray">
      <div
        class="pb-0 vh-height sidebar-wrapper"
        v-if="showSideBar && !display"
      >
        <SideBar
          :mini="miniClass"
          @closeSideBar="closeSideBar"
          :windowWidth="display"
          :showSideBar="showSideBar"
        ></SideBar>
      </div>
      <div
        :class="
          showSideBar
            ? 'pb-0  vh-height sidebar-wrapper'
            : 'pb-0 vh-height sidebar-wrapper-mini'
        "
        v-else-if="display"
      >
        <SideBar
          :mini="miniClass"
          @closeSideBar="closeSideBar"
          :windowWidth="display"
          :showSideBar="showSideBar"
        ></SideBar>
      </div>
      <div
        v-if="mobileSideBar"
        :class="!showSideBar ? 'main-wrapper' : 'main-wrapper-mini'"
      >
        <div class="context no-padding-left">
          <div class="row no-padding" v-if="display">
            <RoutePath :path="path"></RoutePath>
          </div>
          <BlueNavbar :tab="tab" v-if="display"></BlueNavbar>
          <div class="home-tables" v-if="!showInfo">
            <div class="mt-10">
              <v-col class="col-xl-3 col-lg-4 col-md-12 no-padding" cols="12">
                <v-card
                  class="justify-center text-center"
                  style="height: 14rem; cursor: pointer"
                  @click="checkInPage"
                >
                  <v-row class="mx-0 justify-center mt-1">
                    <h5
                      class="
                        text-center text-underline
                        mt-4
                        font-quicksand-medium-18
                      "
                    >
                      Check In
                    </h5>
                  </v-row>
                  <v-row class="text-center justify-center mb-1">
                    <v-card-title>
                      <h1 class="dpc-cards" v-html="members.length"></h1>
                    </v-card-title>
                  </v-row>
                  <v-row class="mx-5 justify-center my-0">
                    <h5
                      class="
                        blue-text
                        card-desc-responsive
                        text-center
                        font-quicksand-medium-18
                      "
                    >
                      Find the employers that are waiting to connect with you
                    </h5>
                  </v-row>
                </v-card>
                <br />
              </v-col>
            </div>
          </div>
          <div v-else class="white-background tables">
            <v-row class="col-md-12">
              <v-form class="col-6 no-padding" ref="form" v-model="valid">
                <v-col class="col-md-12">
                  <h3>Welcome</h3>
                  <div>
                    Find the employers that are waiting to connect with you
                  </div>
                </v-col>
                <div class="col-md-9">
                  <v-alert
                    dense
                    outlined
                    :type="errorMessage.length > 0 ? 'error' : 'info'"
                    class="small-text"
                  >
                    Enter either NPI number OR your First Name AND Last Name. If
                    you enter NPI only, the First Name and Last Name are not
                    mandatory.
                  </v-alert>
                </div>
                <v-col class="col-9 pt-0 pb-0">
                  <v-text-field
                    label="First Name"
                    v-model="firstName"
                    @input="firstUpercase"
                    :rules="firstName.length > 0 ? lengthNameRules : []"
                    outlined
                  >
                  </v-text-field>
                </v-col>
                <v-col class="col-9 pt-0 pb-0">
                  <v-text-field
                    v-model="lastName"
                    outlined
                    label="Last Name"
                    @input="firstUpercase"
                    :rules="lastName.length > 0 ? lengthLastNameRules : []"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="col-9 pt-0 font-weight-bold"> OR </v-col>
                <v-col class="col-9">
                  <v-text-field
                    outlined
                    label="NPI"
                    type="number"
                    v-model="npi"
                  >
                  </v-text-field>
                </v-col>
                <div class="row">
                  <v-col
                    class="text-end col-md-7 text-underline text-gray pointer"
                    @click="resetForm()"
                  >
                    Clear
                  </v-col>
                  <v-col
                    class="text-end col-md-2 text-underline text-gray pointer"
                    @click="fillMembersArray"
                    >Search</v-col
                  >
                </div>
              </v-form>
              <v-col class="md-6 no-padding">
                <v-col class="col-md-12">
                  <h3>Members List</h3>
                  <div>
                    Find the employers that are waiting to connect with you
                  </div>
                </v-col>
                <v-simple-table height="630" fixed-header>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Member Name</th>
                        <th class="text-left">Member Id</th>
                        <th class="text-left">Eligibility</th>
                        <th class="text-left">Visit Pass Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in members" :key="index">
                        <td>
                          <a>{{ item.name }}</a>
                        </td>
                        <td>{{ item.id }}</td>
                        <td class="text-underline">
                          <a>{{ item.eli }}</a>
                        </td>
                        <td
                          :class="
                            item.status == 'Active'
                              ? 'green--text'
                              : item.status == 'Pending'
                              ? 'grey--text'
                              : 'red--text'
                          "
                        >
                          {{ item.status }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
            <v-row class="col-md-12 text-end">
              <v-col
                class="text-gray text-underline pointer"
                @click="showInfo = false"
                >Back</v-col
              >
            </v-row>
          </div>
        </div>
      </div>
    </v-row>
  </div>
</template>
<script>
import Header from "../components/Sections/FamilyMembers/Header.vue";
import SideBar from "../components/TablesTemplate/MembersProvidersSideBar.vue";
import RoutePath from "../components/TablesTemplate/RoutePath.vue";
import BlueNavbar from "../components/Sections/FamilyMembers/Layout/BlueNavbar.vue";

export default {
  components: {
    Header,
    SideBar,
    RoutePath,
    BlueNavbar,
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      npi: "",
      sideBar: true,
      showSideBar: false,
      display: true,
      windowWidth: window.innerWidth,
      page: "",
      clientName: "",
      miniClass: false,
      clientItem: null,
      path: "",
      tab: "",
      showInfo: false,
      valid: false,
      members: [],
      errorMessage: "",
      lengthNameRules: [
        (v) =>
          (v && v.length > 3) ||
          "First Name must be at least three digits long",
        (v) =>
          /^[A-Z][a-z0-9_-]{3,19}$/.test(v) ||
          "Only the first letter should be in uppercase",
      ],
      lengthLastNameRules: [
        (v) =>
          (v && v.length > 3) || "Last Name must be at least three digits long",
        (v) =>
          /^[A-Z][a-z0-9_-]{3,19}$/.test(v) ||
          "Only the first letter should be in uppercase",
      ],
    };
  },
  created() {
    if (this.windowWidth < 959) {
      this.display = false;
    } else {
      this.display = true;
    }
  },
  computed: {
    hideWhiteBack() {
      let hide = false;
      let routeParamsName =
        this.$route.params && this.$route.params.name
          ? this.$route.params.name.replace(/ /g, "%20")
          : "";
      let routeParamsSpaceName =
        this.$route.params && this.$route.params.name
          ? this.$route.params.name
          : "";

      if (
        this.$route.path == "/employer-advisor" ||
        this.$route.path == "/employer-advisor/home" ||
        this.$route.path == "/employer-advisor/employers/" + routeParamsName ||
        this.$route.path ==
          "/employer-advisor/employers/" + routeParamsSpaceName
      ) {
        hide = true;
      }
      return hide;
    },

    mobileSideBar() {
      let show;
      if (this.display) {
        show = true;
      } else if (!this.showSideBar && !this.display) {
        show = true;
      }
      return show;
    },
    route() {
      return this.$route.path;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    resetForm() {
      this.firstName = "";
      this.lastName = "";
      this.npi = "";
      this.errorMessage = "";
    },
    firstUpercase() {
      if (this.firstName.length == 1) {
        this.firstName = this.firstName.toUpperCase();
      }
      if (this.lastName.length == 1) {
        this.lastName = this.lastName.toUpperCase();
      }
    },
    fillMembersArray() {
      if (this.npi || (this.firstName && this.lastName)) {
        this.errorMessage = "";
        this.$refs.form.validate();
        if (this.valid) {
          this.members = [
            {
              name: "Valerie Vaughn",
              id: "123456789",
              eli: "Inactive",
              status: "Active",
            },
            {
              name: "Jeremy Johnson ",
              id: "123456789",
              eli: "Active",
              status: "Inactive",
            },
            {
              name: "Gary Guilen",
              id: "123456789",
              eli: "Active",
              status: "Active",
            },
            {
              name: "Theodore Romos",
              id: "123456789",
              eli: "Active",
              status: "Pending",
            },
            {
              name: "William Stacks ",
              id: "123456789",
              eli: "Inactive",
              status: "Inactive",
            },
            {
              name: "Jenifer Swift",
              id: "123456789",
              eli: "Active",
              status: "Active",
            },
            {
              name: "Jerome Bevington",
              id: "123456789",
              eli: "Active",
              status: "Pending",
            },
          ];
        }
      } else {
        this.members = [];
        this.errorMessage =
          "Enter either NPI number OR your First Name AND Last Name. If you enter NPI only, the First Name and Last Name are not mandatory.";
      }
    },
    checkInPage() {
      this.showInfo = true;
    },
    closeSideBar() {
      if (!this.display) {
        this.showSideBar = false;
      } else {
        this.showSideBar = true;
      }
    },

    onResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 959) {
        this.display = false;
      } else {
        this.display = true;
      }
    },
    hideSideBar() {
      this.showSideBar = !this.showSideBar;
    },
    expantSideBarOnHover(mini) {
      this.showSideBar = !this.showSideBar;
      this.miniClass = !this.miniClass;
    },
  },
};
</script>
