<template>
  <v-row class="add-client-form no-padding">
    <successModal ref="success_modal" v-on:confirm="closeSuccessModal"></successModal>
    <div class="row blue-underline margin-l-11">
      <div class="d-flex col-md-12">
        <h3 class="form-title font ml-2 mt-3 mb-2 font-18 cursor-pointer">
          Member Detail
        </h3>
      </div>
    </div>
    <div v-if="tab == 'details'" class="member-details width-100">
      <div class="col-12 col-md-12 mt-2">
        <v-form v-model.trim="valid" ref="form" class="row mt-2">
          <div class="col-12 col-md-9">
            <div class="row">
              <div class="col-12 col-md-4">
                <h3>Member Information</h3>
                <p>Enter details for member information</p>
              </div>
              <div class="col-12 col-md-8">
                <div class="row">
                  <div class="col-12">
                    <label class="font" for="">First Name</label>
                    <v-text-field
                      required
                      disabled
                      :rules="requiredRules"
                      v-model.trim="newMember.firstName"
                      solo
                    ></v-text-field>
                  </div>
                  <div class="col-12">
                    <label class="font text-gray" for="">Middle Name</label>
                    <v-text-field v-model.trim="newMember.middleName" solo></v-text-field>
                  </div>
                  <div class="col-12">
                    <label class="font text-gray" for="">Last Name</label>
                    <v-text-field
                      required
                      disabled
                      :rules="requiredRules"
                      v-model.trim="newMember.lastName"
                      solo
                    ></v-text-field>
                  </div>
                  <div class="col-12">
                    <label class="font text-gray" for="">Gender</label>
                    <v-text-field
                      required
                      disabled
                      :rules="requiredRules"
                      v-model="returnFullGender"
                      solo
                    ></v-text-field>
                  </div>
                  <div class="col-12 col-md-12">
                    <label class="font text-gray" for="">Date Of Birth</label>
                    <v-text-field
                      required
                      disabled
                      :rules="requiredRules"
                      v-model="returnFormattedDate"
                      solo
                    ></v-text-field>
                  </div>
                  <div class="col-12 col-md-12">
                    <label class="font text-gray" for="">Email</label>
                    <v-text-field
                      :rules="newMember.email ? emailRules:[]"
                      v-model.trim="newMember.email"
                      solo
                    ></v-text-field>
                  </div>
                  <!-- <div class="col-12 col-md-6">
                    <label class="font text-gray" for="">Phone</label>
                    <v-text-field
                      required
                      hide-details
                      :rules="phoneRules"
                      v-model.trim="newMember.phone"
                      solo
                    ></v-text-field>
                  </div> -->
                  <!-- <div class="col-12 col-md-6">
                    <label class="font text-gray" for="">Home Zip Code</label>
                    <v-text-field
                      hide-details
                      required
                      v-model.trim="newMember.zipCode"
                      solo
                    ></v-text-field>
                  </div> -->
                </div>
              </div>
            </div>
            <v-divider class="mt-5 mb-5"></v-divider>
            <div class="row">
              <div class="col-12 col-md-4">
                <h3>Employer and Plan Information</h3>
                <p>Enter details for member information</p>
              </div>
              <div class="col-12 col-md-8">
                <div class="row">
                  <div class="col-12">
                    <label class="font text-gray" for="">Company Name </label>
                    <v-text-field
                      disabled
                      v-model.trim="newMember.companyName"
                      solo
                      :rules="requiredRules"
                    ></v-text-field>
                  </div>
                  <div class="col-12">
                    <label class="font text-gray" for="">Member ID</label>
                    <v-text-field
                      required
                      disabled
                      v-model.trim="newMember.memberId"
                      solo
                    ></v-text-field>
                  </div>
                  <div class="col-12 col-md-12">
                    <label class="font text-gray" for=""
                      >Relationship to the Subscriber
                    </label>
                    <v-text-field
                      required
                      disabled
                      v-model.trim="newMember.subscriberRelation"
                      solo
                      :rules="requiredRules"
                    ></v-text-field>
                  </div>

                  <div class="col-12">
                    <label class="font text-gray" for="">Plan ID</label>
                    <v-text-field
                      required
                      disabled
                      v-model.trim="newMember.planId"
                      solo
                    ></v-text-field>
                  </div>
                  <div class="col-12">
                    <label class="font text-gray" for="">Group ID</label>
                    <v-text-field
                      required
                      disabled
                      v-model.trim="newMember.groupId"
                      solo
                      :rules="requiredRules"
                    ></v-text-field>
                  </div>
                  <div class="col-12">
                    <label class="font text-gray" for="">Payer ID</label>
                    <v-text-field
                      required
                      disabled
                      v-model.trim="newMember.payerId"
                      solo
                      :rules="requiredRules"
                    ></v-text-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-form>
      </div>

      <div class="col-md-12 mt-5 no-padding">
        <div class="row margin-l-11 pr-10">
          <div class="text-end justify-end col-md-12 mt-5 mb-5">
            <span class="mr-10 pointer" style="color: #0572f3" @click="cancel()"
              >Cancel</span
            >
            <v-btn
              class="mr-3 pointer primary pl-10 pr-10"
              @click="save()"
              >Register
            </v-btn>
          </div>
        </div>
      </div>
    </div>

    <!-- <removeDialog ref="dialog_delete" v-on:confirm="confirmRemove"></removeDialog> -->
    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"> </errorModal>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
import api from "../../../../services/api";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import removeDialog from "../../../TablesTemplate/RemoveDialog.vue";
import errorModal from "../../../TablesTemplate/ErrorDialog.vue";
import Subscription from "./Subscription.vue";

import Dependents from "./Dependents.vue";
import MemberHistory from "./MemberHistory.vue";
import MemberActions from "./MemberActions.vue";

import moment from "moment";
export default {
  components: {
    successModal,
    removeDialog,
    errorModal,
    Subscription,
    MemberHistory,
    MemberActions,
    Dependents,
  },
  data() {
    return {
      tab: "details",
      newMember: {
        firstName: "",
        lastName: "",
        middleName: "",
        gender: "",
        dob: "",
        email: "",
        companyName: "",
        memberId: "",
        subscriberRelation: "",
        planId: "",
        groupId: "",
        payerId: "",
        location: "",
        eligibility: "",
      },
      emailStatus: false,
      dialog: false,
      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
      states: [],
      discountRate: "",
      valid: false,
      zipRules: [(v) => (v && v.length == 5) || " Zip Code should be five digits long"],
      requiredRules: [(v) => !!v || "This field is required"],
      emailRules: [
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      taxIdRules: [
        (v) => !!v || "This field is required",
        (v) =>
          !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
          "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
      ],
    };
  },
  created() {
    // this.getMember()
    this.newMember.firstName = this.getPendingRegisteredMember.firstName;
    this.newMember.lastName = this.getPendingRegisteredMember.lastName;
    this.newMember.middleName = this.getPendingRegisteredMember.middleName;
    this.newMember.gender = this.getPendingRegisteredMember.gender;
    this.newMember.dob = this.getPendingRegisteredMember.dob;
    this.newMember.email = this.getPendingRegisteredMember.email
    this.newMember.companyName = this.getPendingRegisteredMember.employer?.companyName;
    this.newMember.memberId = this.getPendingRegisteredMember.memberId;
    this.newMember.subscriberRelation = this.getPendingRegisteredMember.subscriberRelation;
    this.newMember.planId = this.getPendingRegisteredMember.planId;
    this.newMember.groupId = this.getPendingRegisteredMember.groupId;
    this.newMember.payerId = this.getPendingRegisteredMember.payerId;
    this.newMember.eligibility = this.getPendingRegisteredMember.eligibilityId;
    this.newMember.location = this.getPendingRegisteredMember.location;
  },
  computed: {
    returnFullGender() {
      if (this.newMember.gender == "f") {
        return "Female";
      } else {
        return "Male";
      }
    },
    returnFormattedDate() {
      return moment(this.newMember.dob).format("MM/DD/YYYY");
    },
    ...mapGetters(["getPendingRegisteredMember"]),
  },
  methods: {
    cancel(){
      this.$router.push('/dpc-providers/members')
    },
    getMember() {
      api()
        .get(`/dpcproviders/member/63f36b11c763134e3e1a469c`)
        .catch((err) => {
          if (err) {
            if (err.response.status == 500) {
              this.$refs.error_modal.openPopUp(
                "There was an error, system admin is notified. Please try again later."
              );
            }
          }
        })
        .then((res) => {
          // this.memberData = res.data;
        });
    },

    showSlash(event) {
      if (this.taxId.length == 2 && event.keyCode != 8) {
        this.taxId += "-";
      }
    },
    activateFileInput() {
      this.$refs.fileInput.$refs.input.click();
    },

    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
      this.$router.push("/dpc-providers/members/");
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    emailValidation() {
      let data = {
        email: this.newMember.email,
        employer: this.getPendingRegisteredMember.employer?.id,
        member:
          this.getPendingRegisteredMember.member ??
          this.getPendingRegisteredMember.member,
      };
      if (this.newMember.email || this.newMember.email != "") {
        api()
          .post(`/dpcproviders/member/validate/email`, data)
          .catch((error) => {
            this.$refs.error_modal.openPopUp(error.response.data.message);
          })
          .then((res) => {
            if (res.data.valid) {
              this.emailStatus = true;
            }else{

            this.$refs.error_modal.openPopUp(res.data.message)
            }
          });
      }
    },
    save() {
      this.$refs.form.validate()
      if(this.valid){
        api()
        .post(`/dpcproviders/member/register`,this.newMember)
        .catch((error)=>{
            this.$refs.error_modal.openPopUp(error.response.data.message)
        })
        .then((res) => {
          if (res) {
            this.$router.push('/dpc-providers/members/member/'+res.data._id)
          }
        });
      }
    },
  },
};
</script>
<style scoped></style>
