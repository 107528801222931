<template>
    <div class="pa-3">
      <div class="row blue-underline mb-3">
        <div  @click="$router.go(-1)" role="button" class="col-md-12 mb-0 ml-4 mt-2 pb-0 font-weight-500 font-14 d-flex">
          <svg
          @click="$router.go(-1)"
            class="mr-2 mt-1"
            width="14"
            height="16"
            viewBox="0 0 14 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.6269 23.764L1.99634 12.9562C1.92725 12.8861 1.87267 12.803 1.83571 12.7118C1.79875 12.6205 1.78014 12.5229 1.78095 12.4245C1.78176 12.326 1.80197 12.2287 1.84042 12.1381C1.87888 12.0474 1.93482 11.9653 2.00505 11.8963L12.8118 1.26475"
              stroke="gray"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p  @click="$router.go(-1)">Back</p>
        </div>
      </div>
      <div>
        <div class="row form-row-height d-flex pt-2 pb-2 light-blue-text">
          <div  class="col-md-4 ml-auto d-flex font-weight-bold">
            <div class=" mb-1 mt-1">
                <v-card
                outlined color="transparent"
                height="fit-content"
                width="270px"
                class=" mb-5 mt-2"
                :class="{ 'no-pointer': disablePointer }"
              >
                <div class="display pl-1 pr-1 pb-4">
                  
                  <div class="text-start justify-start mb-1 mt-0">
                    <img
                      :src="clinician.logo ? clinician.logo :'/tpa/clinicians.svg'"
                      class="mt-0 mb-0"
                      height="60"
                    />
                  </div>
                  <span class="text-start mt-2 mb-0 text-ellipsis">
                    <p class="font-20 font-weight-500 mb-0">
                      {{ clinician.credentials ? clinician.providerName + ', ' + clinician.credentials : clinician.providerName }}
                    </p>
                  </span>
                  <span class="text-start mb-0 mt-0 text-ellipsis">
                    <p class="font-16 font-weight-400">
                      {{ clinician.category}}
                        </p>
                  </span>
                  <div class="justify-start d-flex mb-0 mt-0">
                    <v-chip
                    class=""
                    color="#CDFFCC"
                    text-color="#333333"
                  >
                  Clinician
                  </v-chip>
                    
                  </div>
                </div>
              </v-card>
             
         
        </div>
              
            <div align="end" class="mb-0">
              <img v-if="clinician.favorite" @click="favorite()" src="/svg/filled-heart.svg"/>
              <img v-else  @click="favorite()" src="/svg/empty-heart.svg"/>
            </div>
          </div>
          <div class="col-md-7 col-11 ml-2 mr-auto">
           <v-expansion-panels  multiple class="col-5" v-model.trim="panel">
                <v-expansion-panel class="mb-3" >
                  <v-expansion-panel-header>
                  <div class="col-12 expansion-header pt-0 pb-0"> 
                     Details 
                  </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="col-12 pl-0">
                    <div class="col-12 expansion-title mt-2 pb-0"> 
                      Gender: 
                    </div>
                    <div class="col-12 expansion-content" v-if="clinician.details.gender == 'F' || clinician.details.gender == 'Female'"> 
                      Female
                    </div>
                    <div class="col-12 expansion-content" v-else-if="clinician.details.gender == 'M' || clinician.details.gender == 'Male'"> 
                        Male
                      </div>
                      <div class="col-12 expansion-content" v-else> 
                        /
                      </div>
                    <div class="col-12 expansion-title mt-2 pb-0"> 
                        NPI:
                      </div>
                      <div class="col-12 expansion-content"> 
                        {{clinician.details.NPI ? clinician.details.NPI : ''}}
                      </div>
                      <!-- <div class="col-12 font-weight-bold mt-2 pb-0"> 
                        License Number:
                      </div>
                      <div class="col-12 text-gray"> 
                        {{clinician.details.license?.number}}
                      </div>
                      <div class="col-12 font-weight-bold mt-2 pb-0"> 
                        State:
                      </div>
                      <div class="col-12 text-gray"> 
                        {{clinician.details.license?.state}}
                      </div>
                      <div class="col-12 font-weight-bold mt-2 pb-0"> 
                        Certification Date:
                      </div>
                      <div class="col-12 text-gray"> 
                        {{clinician.details.license?.date}}
                      </div> -->
                  
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <!-- <v-expansion-panel class="mb-3" >
                    <v-expansion-panel-header>
                      <div class="col-12 expansion-header pt-0 pb-0"> 
                        Contact Information
                     </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="pl-0">
                        <div class="col-12 expansion-title mt-0 pb-0"> 
                            Address: 
                          </div>
                          <div class="col-12 expansion-content mt-0"> 
                            {{ clinician.contact?.address1 }}
                        </div> 
                        <div class="col-12 expansion-title mt-0 pb-0"> 
                          Phone Number: 
                        </div>
                        <div class="col-12 expansion-content mt-0" > 
                          {{ clinician.contact?.phone }}                      
                        </div>   
                        <div class="col-12 expansion-title mt-0 pb-0"> 
                          Email: 
                        </div>
                        <div class="col-12 expansion-content mt-0"> 
                          {{ clinician.contact?.email }}
                        </div>    
                      
                               
                        </v-expansion-panel-content>
                    </v-expansion-panel> -->
                    <v-expansion-panel class="mb-3" >
                        <v-expansion-panel-header>
                          <div class="col-12 expansion-header pt-0 pb-0"> 
                           Clinic or Organization
                         </div>
                           
                          </v-expansion-panel-header>
                          <v-expansion-panel-content class="col-12 pl-0">
                            <div class="col-12 row d-flex">                        
                              <v-card
                              height="210px"
                              class=" mb-5 mt-1 mr-2 col-12 col-md-3"
                              v-for="(item,index1) in clinician.clinics" :key="index1"
                              @click="goToDetails(item, item.type)"
                            >
                            <div class="display pl-1 pr-1 pb-1">
                              <div
                              v-if="item.logo"
                              class="text-start justify-start mb-1 mt-0"
                              style="height: 60px; overflow: hidden; background-size: contain;margin:10px"
                              :style="`background-image:url(${item.logo})`"
                            ></div>
                            <div
                              v-else
                              class="text-start justify-start mb-1 mt-0"
                              style="height: 80px; overflow: hidden; background-size: contain;margin:10px;background-image:url('/tpa/clinicians.svg')"
                            ></div>
                              <!-- <div class="text-start justify-start mb-1 mt-0">
                                <img
                                  :src="  item.logo ? item.logo : '/tpa/clinicians.svg'"
                                  class="pointer mt-2 mb-0"
                                  width="80"
                                   height="45"
                                />
                              </div> -->
                              <span class="text-start mt-0 font-weight-bold text-ellipsis-clinician">
                                <p class="font-14 mb-0">
                               {{item.providerName ? item.providerName : 'Provider'}}
                                </p>
                              </span>
                              <span class="text-start text-ellipsis-clinician">
                                <p class="font-14 mb-0"> {{item.category}}</p>
                              </span>
                              <div class="justify-start d-flex">
                                <v-chip
                                class=""
                                :color="item.type == 'Clinician' ? '#CDFFCC' : '#FFF4BF'"
                                text-color="#333333"
                                small
                              >
                              {{item.type}}
                              </v-chip>
                              </div>
                            </div>
                            </v-card> 
                           </div>
                          
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                     
              </v-expansion-panels>
          </div>
        </div>
      </div>
      <div class="">
        <div class="col-md-12" align="end">
          <!-- <span
            class="light-blue-text text-underline mr-10 font-weight-bold pointer"
            @click="addSolution"
            >Update</span
          > -->
          <!-- <span
            class="light-blue-text text-underline mr-10 ml-10 font-weight-bold pointer"
            @click="$router.go(-1)"
            >Close</span> -->
        </div>
      </div>
      <successModal ref="success_modal" v-on:confirm="closeSuccessModal"></successModal>
    </div>
  </template>
  
  <script>
  import successModal from "../../../TablesTemplate/SuccessDialog.vue";
  import api from "../../../../services/api";
  
  
  export default {
    components: { successModal },
    data() {
      return {
        disablePointer: true,
        clinician: null,
        panel: [0],
      };
    },
    created() {
      this.getProvider();
    },
    methods: {
      closeSuccessModal() {
        this.$parent.getSolutions();
        this.$router.push("/dpcproviders/solutions");
      },
      getProvider() {
        api()
          .get("/dpcproviders/market/doctor/" + this.$route.params.id)
          .then((res) => {
            if (res) {
              this.clinician = res.data;
              this.clinician.logo = res.data.logo ? res.data.logo : '';
            }
          });
      },
      favorite() {
      let data = {
        entity: 'provider',
        provider: this.$route.params.id,
        favorite: !this.clinician.favorite,
      };
      api()
        .post("/dpcproviders/market/favorite", data)
        .then((res) => {
          if (res) {
            this.clinician.favorite = !this.clinician.favorite;
          }
        });
    },
    goToDetails(item, type) {
     if (type == "Clinician") {
        this.$router.replace("/dpc-providers/solutions/clinician/details/" + item.id);
        window.location.reload();
      }
      else if (type == "Clinic & Organization") {
        this.$router.push({
          path: `/dpc-providers/solutions/provider/details/${item.id}`,
          query: { location: item.locationId },
        });
      }
      this.showOverlay = false;
    },
    
    },
  };
  </script>
  <style scoped>
  .participate {
    width: 150px;
    color: white;
  }
  .next {
    width: 650px;
    color: white;
  }
  .okay {
    width: 250px;
    color: white;
  }
  .no-pointer {
    cursor: default;
  }
  .text-ellipsis{
    height: 58px;
    overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  }
  .text-ellipsis-clinician{
    height: 40px;
    overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  }
  </style>
  