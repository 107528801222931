<template>
  <div class="no-padding row-height">
    <removeDialog
      ref="dialog_delete"
      v-on:confirm="confirmRemove"
    ></removeDialog>
    <div class="col-md-12">
      <v-row>
        <v-col class="col-md-6" md="4" cols="12">
          <h3>
            <span class="font"> Contracts</span>
          </h3>
        </v-col>

        <!-- <v-col  class="col-md-6" cols="12"   align="end">
        <v-btn  @click="$router.push(`/provider-advisor/providers/${routeParams}/contracts/request`)" class="my-1 blue-buttons">Request New Type of Contract</v-btn> -->

        <!-- <v-menu class="offset-y">
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" class="my-1 blue-buttons"> Add a Contract</v-btn>
            </template>
        
            <v-list>
              <v-list-item-title><span style="font-size:30px;"> &#183;</span> Hospital</v-list-item-title>
                <v-list-item style="disply:block">
                    <a class="a-tag underline-text" @click="$router.push(`/provider-advisor/providers/${routeParams}/contracts/add-new`)">Simple Contract</a>                 
                </v-list-item>
                <v-list-item>
                    <a class="a-tag underline-text"  @click="$router.push(`/provider-advisor/providers/${routeParams}/contracts/detailed-contract`)">Detailed Contract</a>
                </v-list-item>
                 <v-list-item-title ><span style="font-size:30px;"> &#183;</span> Other Providers</v-list-item-title>
                 <v-list-item style="disply:block">
                    <a class="a-tag underline-text" @click="$router.push(`/provider-advisor/providers/${routeParams}/contracts/add-new`)">Simple Contract</a>                 
                </v-list-item>
                <v-list-item>
                    <a class="a-tag underline-text"  @click="$router.push(`/provider-advisor/providers/${routeParams}/contracts/detailed-contract`)">Detailed  Contract</a>
                </v-list-item>
             
            </v-list>
        </v-menu>  -->
        <!-- </v-col> -->
      </v-row>
    </div>
    <div class="col-md-12 text-center justify-center" v-if="readyToShow">
      <v-progress-circular
        indeterminate
        :size="100"
        color="primary"
        class="text-center"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-row class="col-md-12" v-if="items.length < 1">
        <v-col md="12" class="font-bold">
          You have not yet Contracted -
          <a
            v-if="$role != 'user'"
            @click="
              $router.push(
                `/provider-advisor/providers/${routeParams}/contracts/contract-type`
              )
            "
            >Click Here to Review the Contract</a
          >
        </v-col>
        <v-col class="mt-5 font-bold">
          Don’t worry... You’ll be able print, save, and send for review before
          accepting and electronically signing the contract.
        </v-col>
      </v-row>
      <v-simple-table v-else fixed-header>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Final Contract</th>
              <th class="text-left">Contract Date</th>
              <th class="text-left">Outpatient Rate</th>
              <th class="text-left">Inpatient Rate</th>
              <th v-if="$role != 'user'" class="text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in allContracts" :key="item.name">
              <td v-if="item.name">
                <a @click="singleContract(item, 'form')">{{ item.name }}</a>
              </td>
              <td v-else><a @click="singleContract(item)">/</a></td>
              <td
                v-html="
                  item.effectiveDate ? parsedDate(item.effectiveDate) : '/'
                "
              ></td>
              <td v-if="item.providerRates && item.providerRates.inpatient">
                {{ item.providerRates.inpatient }}%
              </td>
              <td v-else>/</td>
              <td v-if="item.providerRates && item.providerRates.outpatient">
                {{ item.providerRates.outpatient }}%
              </td>
              <td v-else>/</td>

              <td class="mt-2" v-if="$role != 'user'">
                <a @click="singleContract(item, 'rates')">Decrease Rates</a>
                <br />
                <a @click="removeItem(item)">Cancel Contract</a>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
    </div>
  </div>
</template>
<script>
import api from "../../../../../services/api";
import removeDialog from "../../../../TablesTemplate/RemoveDialog.vue";
import errorModal from "../../../../TablesTemplate/ErrorDialog.vue";
export default {
  components: {
    removeDialog,
    errorModal,
  },
  data() {
    return {
      errorMessage: "",
      allContracts: [],
      items: [],
      item: null,
      readyToShow:false,
    };
  },
  computed: {
    getClient() {
      return this.$store.getters.getClientGetter;
    },
    routeParams() {
      return this.$route.params.name;
    },
    filterContracts() {
      return this.items;
    },
  },
  created() {
    this.getAllContracts();
  },
  methods: {
    parsedDate(date) {
      let newDate = new Date(date);
      return newDate.toDateString();
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    getAllContracts() {
      this.readyToShow = true;
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.providerAdvisor;
      let provider = JSON.parse(localStorage.getItem("provider"));
      api()
        .get(`/advisors/${advisorId}/providers/${provider._id}/contract`)
        .catch((err) => {
          this.readyToShow = false;
          //  this.$refs.error_modal.openPopUp(err.response.data.message);
        })
        .then((res) => {
          if (res) {
            this.allContracts.push(res.data.contract);
            this.items.push(res.data.contract);
            this.dialog = false;
          } else {
            this.allContracts = [];
            this.items = [];
            this.dialog = false;
          }
          this.readyToShow = false;
        });
    },
    confirmRemove() {
      this.$refs.dialog_delete.closePopUp();
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.providerAdvisor;
      let provider = JSON.parse(localStorage.getItem("provider"));
      api()
        .delete(`/advisors/${advisorId}/providers/${provider._id}/contract`)
        .catch((err) => {
          this.$refs.error_modal.openPopUp(err.response.data.message);

          return;
        })
        .then((res) => {
          if (res) {
            this.getAllContracts();
          }
        });
    },
    removeItem(item) {
      this.itemToDelete = item;
      this.$refs.dialog_delete.openPopUp(
        "Are you sure that you want to delete the Contract?"
      );
    },
    searchContracts(val) {
      this.allContracts = this.filterContracts.filter((el) => {
        if (el.name.toLowerCase().includes(val.toLowerCase())) {
          return el.name.toLowerCase().includes(val.toLowerCase());
        }
      });
    },
    singleContract(item, type) {
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.providerAdvisor;
      let provider = JSON.parse(localStorage.getItem("provider"));
      api()
        .get(`/advisors/${advisorId}/providers/${provider._id}/contract`)
        .then((res) => {
          if (res) {
            this.$store.commit(
              "setSingleContractinEmployerAdvisor",
              res.data.contract
            );
            this.$router.push(
              `/provider-advisor/providers/${this.routeParams}/contracts/contract`
            );
            this.$store.commit("singleContractView", type);
          }
        });
    },
  },
};
</script>
<style>
.green-text {
  color: #0fa64b !important;
}
</style>