<template>
  <v-row class="add-client-form no-padding">
    <successModal ref="success_modal" v-on:confirm="sendMail"></successModal>
    <errorDialog ref="error_modal" v-on:confirm="closeErrorModal"></errorDialog>
    <div class="col-md-12 form-row-60-height">
      <div class="row">
        <div class="col-md-12">
          <h3 class="form-title font col-md-12">Add & Invite a User</h3>
        </div>
      </div>
      <v-form v-model.trim="valid" ref="form" class="row col-md-12">
        <div class="col-md-6 pb-0">
          <label class="font text-gray" for="">First Name</label>
          <v-text-field
            required
            v-model.trim="firstName"
            solo
            :rules="requiredRules"
            tabindex="1"
          ></v-text-field>
        </div>
        <div class="col-md-6 pb-0">
          <label class="font text-gray" for=""
            >Location(s)
            <span class="small-text font-italic ml-3"
              >(hold ctrl key to select multiple)</span
            >
          </label>
          <v-select
            required
            tabindex="5"
            solo
            :items="[{ text: 'Choose One', value: 'choose' }]"
          ></v-select>
        </div>
        <div class="col-md-6 pb-0 pt-0">
          <label class="font text-gray" for="">Last Name</label>
          <v-text-field
            required
            tabindex="2"
            :rules="requiredRules"
            v-model.trim="lastName"
            solo
          ></v-text-field>
        </div>
        <div class="col-md-6 pb-0 pt-0">
          <label class="font text-gray" for="">Role </label>
          <v-select
            required
            tabindex="5"
            solo
            :rules="requiredRules"
            :items="[
              { text: 'Admin', value: 'admin' },
              { text: 'User', value: 'user' },
            ]"
            v-model.trim="role"
          ></v-select>
        </div>
        <div class="col-md-6 pb-0 pt-0">
          <label class="font text-gray" for="">Email</label>
          <v-text-field
            required
            :rules="emailRules"
            v-model.trim="email"
            solo
            tabindex="3"
          ></v-text-field>
        </div>
        <div class="col-md-6 pb-0 pt-0">
          <label class="font text-gray" for="">Address</label>
          <v-text-field
            required
            tabindex="7"
            :rules="requiredRules"
            v-model.trim="address"
            solo
          ></v-text-field>
        </div>
        <div class="col-md-6 pb-0 pt-0">
          <label class="font text-gray" for="">Title</label>
          <v-text-field
            required
            tabindex="4"
            :rules="requiredRules"
            v-model.trim="title"
            solo
          ></v-text-field>
        </div>
        <div class="col-md-6 row no-padding">
          <div class="col-md-6 pb-0 pt-0">
            <label class="font text-gray" for="">City</label>
            <v-text-field
              required
              :rules="requiredRules"
              v-model.trim="city"
              solo
              tabindex="8"
            ></v-text-field>
          </div>
          <div class="col-md-3 pb-0 pt-0">
            <label class="font text-gray" for="">State</label>
            <v-select
              required
              :rules="requiredRules"
              :items="states"
              v-model.trim="state"
              solo
              tabindex="9"
              item-text="name"
              item-value="name"
            ></v-select>
          </div>
          <div class="col-md-3 pb-0 pt-0">
            <label class="font text-gray" for="">Zip</label>
            <v-text-field
              required
              type="number"
              v-model.trim="zip"
              :rules="zipRules"
              solo
              tabindex="10"
            ></v-text-field>
          </div>
        </div>
      </v-form>
    </div>
    <div class="row text-end justify-end no-padding" >
      <div class="col-md-12 mt-7 mb-7 pt-8 gray-underline-wizard margin-l-11">
        <span
          class="text-underline text-gray col-md-12 mr-4 pointer"
          @click="$router.push(`/solution-vendor/users`)"
          >Cancel</span
        >
        <span
          class="text-underline text-gray ml-10 mr-2 col-md-12 pointer"
          @click="save"
          >Save & Invite User</span
        >
      </div>
    </div>
  </v-row>
</template>
<script>
import successModal from "../../../../TablesTemplate/SuccessDialog.vue";
import errorDialog from "../../../../TablesTemplate/ErrorDialog.vue";
import api from "../../../../../services/api";
export default {
  components: { successModal, errorDialog },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      title: "",
      role: "",
      officeName: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
      zipRules: [
        (v) => (v && v.length == 5) || " Zip Code should be five digits long",
      ],
      states: [],
      valid: false,
    };
  },
  computed: {
    routeParams() {
      return this.$route.params.name;
    },
  },
  created() {
    this.getStates();
  },
  methods: {
    getStates() {
      api()
        .get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },
    sendMail() {
      this.$refs.success_modal.closePopUp();
      this.$parent.getAllUsers();
      this.$router.push(`/solution-vendor/users`);
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    save() {
      this.$refs.form.validate();
      if (this.valid) {
        let data = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          title: this.title,
          role: this.role,
          officeName: this.officeName,
          address1: this.address,
          city: this.city,
          state: this.state,
          zipCode: this.zip,
        };

        api()
          .post(`/vendors/users`, data)
          .catch((err) => {
            console.log(err);
            let text = err.response.data.message;
            this.$refs.error_modal.openPopUp(err.response.data);
          })
          .then((res) => {
            if (res) {
              let text = "Email sucessfully sent";
              this.$refs.success_modal.openPopUp(text);
            }
          });
      }
    },
  },
};
</script>
<style>
.form-row-60-height {
  min-height: 70vh;
}
</style>