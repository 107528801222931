import AdvisorsSection from '../views/EmployerAdvisor.vue'

// EMPLOYERS
import Employers from '../components/Sections/EmployerAdvisor/Employers/EmployersTable.vue'
import AddEmployer from '../components/Sections/EmployerAdvisor/Employers/AddEmployer.vue'
import InviteEmployer from '../components/Sections/EmployerAdvisor/Employers/InviteEmployers.vue'
import SingleEmployer from '../components/Sections/EmployerAdvisor/Employers/SingleEmployer.vue'

import EmpEmployers from '../components/Sections/EmployerAdvisor/Employers/Employers/EmployersTable.vue'
import EmpAddEmployer from '../components/Sections/EmployerAdvisor/Employers/Employers/AddEmployer.vue'
import EmpInviteEmployer from '../components/Sections/EmployerAdvisor/Employers/Employers/InviteEmployers.vue'
import EmpSingleEmployer from '../components/Sections/EmployerAdvisor/Employers/Employers/SingleEmployer.vue'


import EmpPriorAuth from '../components/Sections/EmployerAdvisor/Employers/PriorAuths/PriorTable.vue'
import EmpPriorDetermination from '../components/Sections/EmployerAdvisor/Employers/PriorAuths/PriorDetermination.vue'
import EmpPriorRequest from '../components/Sections/EmployerAdvisor/Employers/PriorAuths/PriorRequest.vue'
import EmpSinglePrior from '../components/Sections/EmployerAdvisor/Employers/PriorAuths/SinglePrior.vue'
import EmpPriorRules from '../components/Sections/EmployerAdvisor/Employers/PriorAuths/PriorRules.vue'
import EmpAddRule from '../components/Sections/EmployerAdvisor/Employers/PriorAuths/AddRules.vue'
import EmpRuleDetail from '../components/Sections/EmployerAdvisor/Employers/PriorAuths/RuleDetail.vue'

import EmpMemberResult from '../components/Sections/EmployerAdvisor/Employers/Members/ResultTable.vue'
import EmpMembers from '../components/Sections/EmployerAdvisor/Employers/Members/Members.vue'
import EmpAdvancedSearch from '../components/Sections/EmployerAdvisor/Employers/Members/AdvancedSearch.vue'
import EmpMember from '../components/Sections/EmployerAdvisor/Employers/Members/Member.vue'
import EmpAddByZip from '../components/Sections/EmployerAdvisor/Employers/Members/AddZipCodes.vue'
import EmpAddMember from '../components/Sections/EmployerAdvisor/Employers/Members/AddMember.vue'
import EmpInviteMember from '../components/Sections/EmployerAdvisor/Employers/Members/InviteMembers.vue'
import EmpUploadMember from '../components/Sections/EmployerAdvisor/Employers/Members/UploadCensus.vue'
import EmpAddDependent from '../components/Sections/EmployerAdvisor/Employers/Members/AddDependent.vue'
import EmpSingleDependent from '../components/Sections/EmployerAdvisor/Employers/Members/SingleDependent.vue'




import EmpProviders from '../components/Sections/EmployerAdvisor/Employers/Providers/ProvidersTable.vue'
import EmpAddProviderByMember from '../components/Sections/EmployerAdvisor/Employers/Providers/AddByMemberZip.vue'
import EmpAddProviders from '../components/Sections/EmployerAdvisor/Employers/Providers/AddProviders.vue'
import EmpInviteProviders from '../components/Sections/EmployerAdvisor/Employers/Providers/InviteProvider.vue'
import EmpProvider from '../components/Sections/EmployerAdvisor/Employers/Providers/ProviderCrudForm.vue'
import EmpProviderResult from '../components/Sections/EmployerAdvisor/Employers/Providers/ResultTable.vue'

import EmpAdvisors from '../components/Sections/EmployerAdvisor/Employers/Advisors/AdvisorsTable.vue'
import EmpAddAdvisors from '../components/Sections/EmployerAdvisor/Employers/Advisors/AddAdvisor.vue'
import EmpAdvisor from '../components/Sections/EmployerAdvisor/Employers/Advisors/Advisor.vue'
import EmpAdvisorSearchResult from '../components/Sections/EmployerAdvisor/Employers/Advisors/AdvisorSearchResult.vue'
import EmpInviteAdvisor from '../components/Sections/EmployerAdvisor/Employers/Advisors/InviteAdvisor.vue'



import EmpNetworks from '../components/Sections/EmployerAdvisor/Employers/Networks/NetworksTable.vue'
import EmpNetwork from '../components/Sections/EmployerAdvisor/Employers/Networks/SingleNetwork.vue'
import EmpNetworkBuilder from '../components/Sections/EmployerAdvisor/Employers/Networks/NetworkBuilder.vue'

import EmpContracts from '../components/Sections/EmployerAdvisor/Employers/Contract/ContractsTable.vue'
import EmpContract from '../components/Sections/EmployerAdvisor/Employers/Contract/Contract.vue'
import EmpAddContract from '../components/Sections/EmployerAdvisor/Employers/Contract/AddContract.vue'
import EmpContractResult from '../components/Sections/EmployerAdvisor/Employers/Contract/ContractResult.vue'
import EmpRequestContract from '../components/Sections/EmployerAdvisor/Employers/Contract/ContractsTable.vue'

import EmpSettings from '../components/Sections/EmployerAdvisor/Employers/Settings/Settings.vue'
import EmpAccount from '../components/Sections/EmployerAdvisor/Employers/Settings/AccountForm.vue'
import EmpAddUser from '../components/Sections/EmployerAdvisor/Employers/Settings/AddUser.vue'
import EmpUser from '../components/Sections/EmployerAdvisor/Employers/Settings/UsersCrud.vue'
import EmpInviteUser from '../components/Sections/EmployerAdvisor/Employers/Settings/InviteUser'

import EmpDetails from '../components/Sections/EmployerAdvisor/Employers/Tabs.vue'
// END

import Utilization from '../components/Sections/EmployerAdvisor/Utilization/Utilization.vue'



//END

import Networks from '../components/Sections/EmployerAdvisor/Networks/NetworksTable.vue'
import NetworkBuilder from '../components/Sections/EmployerAdvisor/Networks/NetworkBuilder.vue'
import Network from '../components/Sections/EmployerAdvisor/Networks/SingleNetwork.vue'

import Partners from '../components/Sections/EmployerAdvisor/Partners/PartnersTable.vue'
import AddPartner from '../components/Sections/EmployerAdvisor/Partners/AddPartner.vue'
import Partner from '../components/Sections/EmployerAdvisor/Partners/Partner.vue'
import InvitePartner from '../components/Sections/EmployerAdvisor/Partners/InvitePartner.vue'
import PartnerResult from '../components/Sections/EmployerAdvisor/Partners/Result.vue'

import SettingsTemplate from '../components/Sections/EmployerAdvisor/Settings/SettingsTemplate.vue'
import Settings from '../components/Sections/EmployerAdvisor/Settings/Settings.vue'
import Account from '../components/Sections/EmployerAdvisor/Settings/AccountForm.vue'
import AddUser from '../components/Sections/EmployerAdvisor/Settings/AddUser.vue'
import User from '../components/Sections/EmployerAdvisor/Settings/UsersCrud.vue'
import InviteUser from '../components/Sections/EmployerAdvisor/Settings/InviteUser'
import WizzardForm from "../components/Sections/EmployerAdvisor/WizardForm";
import MyProfile from "../components/TablesTemplate/MyProfile.vue"

let role = "user"
if(localStorage.getItem('user')){
    let user = JSON.parse(localStorage.getItem('user'));
    role = user.role;
  }else{
   role = "no-login"
  }

function routeGuard(to, from, next) {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user.type != 'employerAdvisor' || !$cookies.get('token')){
      next('/login');
    }
      next();
}

function routeGuardRole(to, from, next){
   if(role == 'user'){
    next('/login');
   }
   next();
}
const router = [
    {
        path:'/advisors/verifyEmployer/:id',
        name:'Employers',
        component:AdvisorsSection,
       
    },
    {
        path:'/employer-advisor/home',
        name:'Home',
        beforeEnter: routeGuard,
        component:AdvisorsSection,

    },
    {
        path:'/employer-advisor',
        name:'Home',
        beforeEnter: routeGuard,
        component:AdvisorsSection,
        children:[
            {
                path:'wizard-form',
                name:'Wizard Form Employer Advisor',
                component:WizzardForm,
                beforeEnter:routeGuardRole
            },
        
            {
                path:'employers',
                name:'Employers',
                component:Employers,
                children:[
                    {
                        path:'add-new',
                        name:'Add New Employer',
                        component:AddEmployer,
                        beforeEnter:routeGuardRole
                    },
                    {
                        path:'invite-employer',
                        name:'Invite Employer',
                        component:InviteEmployer,
                        beforeEnter:routeGuardRole
                    },
                    
                    {
                        path:'employer',
                        name:'Employer',
                        component:SingleEmployer
                    },
                    {
                        path:':name',
                        component:EmpDetails,
                        name: 'Employer Home',
                        children:
                        [
                            {
                                path:'employers',
                                component:Employers,
                                name: 'Employers',
                            },
                            {
                                path:'employers',
                                component:EmpEmployers,
                                name: 'Employers',
                                children:[
                                    {
                                        path:'add-new',
                                        name:'Add New Employer',
                                        component:EmpAddEmployer,
                                        beforeEnter:routeGuardRole
                                    },
                                    {
                                        path:'invite-employer',
                                        name:'Invite New Employer',
                                        component:EmpInviteEmployer,
                                        beforeEnter:routeGuardRole
                                    },
                                    {
                                        path:'employer',
                                        name:'Employer',
                                        component:EmpSingleEmployer
                                    }

                                ]
                            },
                            // {
                            // path:'members',
                            // component:EmpMembers,
                            // name:'Members',
                            // beforeEnter:routeGuardRole,
                            // children:[
                            //     {
                            //         path:'result',
                            //         component:EmpMemberResult,
                            //         name:'Result'
                                    
                            //     },
                            //     {
                            //         path:'member',
                            //         component:EmpMember,
                            //         name:'Member'
                            //     },
                            //     { 
                            //         path:'dependents',
                            //         component:EmpAddDependent,
                            //         name:'Dependents',
                            //         children:[
                            //             {
                            //                 path:'dependent',
                            //                 component:EmpSingleDependent,
                            //                 name:'Dependent'
                            //             }
                            //         ]
                            //     },
                            //     {
                            //         path:'add-zip-codes',
                            //         component:EmpAddMember,
                            //         name:'Member Zip Codes'
                            //     },
                            //     {
                            //         path:'add-new',
                            //         component:EmpAddByZip,
                            //         name:'Add New Member'
                            //     },
                                
                            //     {
                            //         path:'invite-members',
                            //         component:EmpInviteMember,
                            //         name:'Invite Members'
                            //     },
                            //     {
                            //         path:'upload',
                            //         component:EmpUploadMember,
                            //         name:'Upload'
                            //     },
                            //     {
                            //         path : 'advanced-search',
                            //         component:EmpAdvancedSearch,
                            //         name:'Search Result'
                            //     }
                            //   ]
                            // },
                            // {
                            //     path:'prior-auth',
                            //     name:'Prior Authorizations',
                            //     component:EmpPriorAuth,
                            //     beforeEnter:routeGuardRole,
                            //     children:[
                            //         {
                            //             path:'new-request',
                            //             name:'New Prior Authorization Request',
                            //             component:EmpPriorRequest,
                            //         },
                            //         {
                            //             path:'determination',
                            //             name:'Prior Authorization Rule Detail',
                            //             component:EmpPriorDetermination,
                            //         },
                            //         {
                            //             path:'details',
                            //             component:EmpSinglePrior,
                            //             name:'Prior Authorization Request Detail'
                                        
                            //         },
                            //         {
                            //             path:'rules',
                            //             component:EmpPriorRules,
                            //             name:'Prior Authorization Rules'
                                        
                            //         },
                            //         {
                            //             path:'add-rule',
                            //             component:EmpAddRule,
                            //             name:'New Prior Authorization Rule'
                            //         },
                            //         {
                            //             path:'rule-detail',
                            //             component:EmpRuleDetail,
                            //             name:'Prior Authorizaton Rule Detail'
                            //         },
                                   
                            //     ]
                            // },
                            {
                                path:'providers',
                                name:'Providers',
                                component:EmpProviders,
                                children:[
                                  
                                    {
                                        path:'provider',
                                        name:"Provider",
                                        component:EmpProvider
                                    },
                                    {
                                        path:'invite-providers',
                                        name:"Invite Providers",
                                        component:EmpInviteProviders,
                                        beforeEnter:routeGuardRole
                                    },
                                    {
                                        path:'add-providers',
                                        name:"Add New Provider",
                                        component:EmpAddProviders,
                                     
                                    },
                                    {
                                        path:'add-by-member',
                                        name:"Add by Member Zip",
                                        component:EmpAddProviderByMember
                                    },
                                    {
                                        path:'result',
                                        name:"Search Result",
                                        component:EmpProviderResult

                                    }
                                ]
                            },
                            {
                                path:'advisors',
                                name:'Partners',
                                component:EmpAdvisors,
                                children:[
                                    {
                                        path:'add-new',
                                        name: 'Add New Partner',
                                        component:EmpAddAdvisors,
                                      
                                    },
                                    {
                                        path:'result',
                                        name: 'Add New Partner',
                                        component:EmpAdvisorSearchResult
                                    },
                                    {
                                        path:'invite-advisor',
                                        name:'Invite',
                                        component:EmpInviteAdvisor,
                                        beforeEnter:routeGuardRole
                                    },
                                    {
                                        path:'advisor',
                                        name:'Detail',
                                        component:EmpAdvisor
                                    },
                                  
                                ]
                            },
                          
                            // {
                            //     path:'utilization',
                            //     component:Utilization,
                            //     name:'Utilization Review Triggers',
                            // },
                      
                            // {
                            //     path:'networks',
                            //     component:EmpNetworks,
                            //     name:'Networks',
                            //     children:[
                            //         {
                            //             path:'builder',
                            //             name:'Network Builder',
                            //             component:EmpNetworkBuilder
                            //         },
                            //         {
                            //             path:'network',
                            //             name:'Bundle Builder',
                            //             component:EmpNetwork
                            //         }
                            //     ]
                            // },
                             {
                                path:'contracts',
                                component:EmpContracts,
                                name:'Contracts',
                                children:[
                                    {
                                        path:'add-new',
                                        component:EmpAddContract,
                                        name:'Add New Contract',
                                        beforeEnter:routeGuardRole
                                    },
                                    {
                                        path:'contract',
                                        component:EmpContract,
                                        name:'Contract',
                                    },
                                    {
                                        path:'request',
                                        component:EmpRequestContract,
                                        name:'Request Contract',
                                        beforeEnter:routeGuardRole
                                    }
                                ]

                             },
                             {
                                path: "settings",
                                component: EmpSettings,
                                name:'Settings',
                                children:
                                [
                                    {
                                        path:'account',
                                        name:'Account',
                                        component:EmpAccount
                                    },
                                    {
                                        path: "users",
                                        name:'Users',
                                        component: EmpSettings,
                                        children:[
                                                {
                                                    path:'user',
                                                    name:'User',
                                                    conponent:EmpUser,
                                                },
                                                {
                                                    path:'add-new',
                                                    name:'Add New User',
                                                    component:EmpAddUser,
                                                    beforeEnter:routeGuardRole
                                                },
                                                {
                                                    path:'invite-user',
                                                    name:'Invite User',
                                                    component:EmpInviteUser,
                                                    beforeEnter:routeGuardRole
                                                },
                                        ]
                                    },
                                ]     
                            },
                        ]
                    }, 
                ]
            },
        
         
            {
                path:'partners',
                name:'Partners',
                component:Partners,
                children:[
                    {
                        path:'add-new',
                        name: role == 'user' ? 'Search Partners' : 'Add New Partner',
                        component:AddPartner,
                        children:[
                            {
                                path:'result',
                                name:'Search Result',
                                component:PartnerResult,
                            }
                        ]
                    },
                    {
                        path:'invite-partner',
                        name:'Invite Partner',
                        component:InvitePartner,
                        beforeEnter:routeGuardRole,
                    },
                    {
                        path:'partner',
                        name:'Partner Detail',
                        component:Partner,
                    }
                ]
            },
            // {
            //     path:'networks',
            //     name:'Networks',
            //     component:Networks,
            //     children:[
            //         {
            //             path:'builder',
            //             name:'Network Builder',
            //             component:NetworkBuilder,
            //         },
            //         {
            //             path:'network',
            //             name:'Network Detail',
            //             component:Network,
            //         }
            //     ]
            // },
            {
                path:'/settings-template',
                name:'Settings',
                component:SettingsTemplate,
                meta:{
                    layout:'content'
                },
                redirect: to => {
                    return { path: '/employer-advisor/settings' }
                  }, 
            },
            {
                path:'settings',
                name:'Settings',
                component:Settings,
                children:[
                    {
                        path:'account',
                        name:'Account',
                        component:Account
                    },
                    {
                        path: "users",
                        name:'Users',
                        component: Settings,
                        children:[
                                {
                                    path:'user',
                                    name:'User Detail',
                                    conponent:User,
                                },
                                {
                                    path:'add-new',
                                    name:'Add New User',
                                    component:AddUser,
                                    beforeEnter:routeGuardRole,
                                },
                                {
                                    path:'invite-user',
                                    name:'Invite User',
                                    component:InviteUser,
                                    beforeEnter:routeGuardRole,
                                },
                        ]
                    },
                ]
            },
            {
                path:'profile',
                name:'My Profile',
                component:MyProfile
            },
            // {
            //     path:'utilization',
            //     component:Utilization,
            //     name:'Utilization Review Triggers'
            // },
        ]     
        

    }
]

export default router;