<template>
  <div class="col-md-12 row-height">
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <errorDialog ref="error_modal" v-on:confirm="closeErrorModal"></errorDialog>
    <v-row class="mb-1 mt-1 blue-underline margin-l-11">
      <v-col class="col-xl-3 col-lg-4 col-sm-5 mb-2">
        <h3>
          <span class="ml-2"> Add Providers - Search Results</span>
        </h3>
      </v-col>
      <v-col class="col-lg-3 col-md-3 pt-1">
        <v-text-field
          class="input-control"
          prepend-inner-icon="mdi-magnify"
          @input="searchProviders"
          light
          dense
          hide-details=""
          outlined
          placeholder="Search Providers"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-simple-table fixed-header height="60vh">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Provider Name</th>
            <th class="text-left">NPI Numbers</th>
            <th class="text-left">Speciality</th>
            <th class="text-left">City</th>
            <th class="text-left">State</th>
            <th class="no-padding text-end justify-end">
              <div class="col-md-12 justify-end d-flex text-end">
                <v-checkbox
                  v-model="allRows"
                  class="no-padding mt-3"
                  @click="selectAllRows()"
                ></v-checkbox>
                <label for="" style="padding-top: 15px">Select all</label>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in availableProviders"
            :key="index"
            :class="selectAll ? 'activeItem' : ''"
            :id="'id' + index"
          >
            <td class="text-gray" v-if="item.basic.first_name">
              {{ item.basic.first_name }}
              {{ item.basic.last_name }}
            </td>
            <td class="text-gray" v-else-if="item.basic.authorized_official_first_name && item.basic.authorized_official_last_name">
              {{ item.basic.authorized_official_first_name}} {{ item.basic.authorized_official_last_name }}
              </td>
            <td class="text-gray" v-else>/</td>
            <td class="text-gray">
              {{ item.number }}
            </td>

            <td
              class="text-gray"
              v-html="
                item.taxonomies && item.taxonomies[0]
                  ? item.taxonomies[0].desc
                  : '/'
              "
            ></td>
            <td
              class="text-gray"
              v-html="
                item.addresses && item.addresses[0]
                  ? item.addresses[0].city
                  : '/'
              "
            ></td>
            <td
              class="text-gray"
              v-html="
                item.addresses && item.addresses[0]
                  ? item.addresses[0].state
                  : '/'
              "
            ></td>
            <td
              class="text-center mt-3 row no-padding justify-end"
              style="padding-right: 90px"
            >
              <v-checkbox
                class="stay-blue no-padding stay-blue"
                :value="selectAll"
                @click="activeRow(index, item)"
              ></v-checkbox>
            </td>
          </tr>
        </tbody>
        <!-- <tbody v-else-if="filterItems">
          <v-col md="12" cols="12">
            <v-alert
              border="bottom"
              colored-border
              type="warning"
              elevation="2"
              class="text-gray"
            >
              Sorry! No results found
            </v-alert>
          </v-col>
        </tbody>
        <tbody v-else>
          <tr>
            <td>No Results Found. Please Try Again.</td>
          </tr>
        </tbody> -->
      </template>
    </v-simple-table>
    <v-row class="text-end justify-end mt-5 mr-3">
      <v-progress-linear
        v-if="loadData"
        color="blue"
        indeterminate
        reverse
      ></v-progress-linear>
      <span
        v-if="showButton && items.length > 0 && !showLoadMoreDataButton"
        :disabled="loadData"
        class="text-gray underline-text mt-5 mb-2 pointer"
        @click="loadMoreData()"
      >
        Load More Data
      </span>
      <span
        :disabled="loadData"
        class="text-gray underline-text ml-10 mr-10 mt-5 mb-5 pointer"
        @click="goBack()"
      >
        Cancel
      </span>
      <span
        :disabled="loadData"
        v-if="items.length > 0"
        class="text-gray underline-text mt-5 mb-5 pointer"
        @click="addProviders()"
      >
        Add Selected
      </span>
    </v-row>

    <exitsDialog
      ref="exists_modal"
      v-on:confirm="closeExistsModal"
    ></exitsDialog>

    
  </div>
</template>
<script>
import api from "../../../../../services/api";
import successModal from "../../../../TablesTemplate/SuccessDialog.vue";
import errorDialog from "../../../../TablesTemplate/ErrorDialog.vue";
import exitsDialog from "../../../../TablesTemplate/ExistDialog.vue";
import { mapState } from 'vuex'

export default {
  components: {
    successModal,
    errorDialog,
    exitsDialog,
  },
  data() {
    return {
      allProviders: [],
      loadData: false,
      selected: [],
      items: [],
      selectAll: false,
      filteredArray: [],
      selectedRows: [],
      showButton: false,
      filterArray: [],
      allRows: false,
      page: 1,
      filterItems: false,
    };
  },
  created() {
    this.getAllProviders();
    this.items = this.getProviders;

    this.filterArray = this.getProviders;
    if (this.items.length % 100 == 0) {
      this.showButton = true;
    } else {
      this.showButton = false;
    }

    if (this.items.length < 100) {
      this.showButton = false;
    } else {
      this.showButton = true;
    }
  },
  computed: {
    // ...mapState({
    //   allProviders: state => state.DPCProviders.providers
    // }),
    availableProviders(){
      let ids = this.allProviders.map(item => item.id)
      return this.items.filter(item => !ids.includes(item.id))
      // return npis
    },
    getProviders() {
      return this.$store.getters.getSearchProviders;
    },
    urlData() {
      return this.$store.getters.getSearchUrl;
    },
    route() {
      return this.$route.path;
    },
    routeParams() {
      return this.$route.params.name;
    },
    showLoadMoreDataButton() {
      return this.$store.getters.checkIfSearchIsFromMember;
    },
    isFromExcel() {
      return this.$store.getters.getExcelType;
    },
  },
  methods: {
    closeExistsModal() {
      this.$refs.exists_modal.closePopUp();
    },
    goBack() {
      this.$router.push(`/dpc-providers/providers`);
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
      this.$parent.getAllProviders();
      this.$router.push(`/dpc-providers/providers`);
    },
    searchProviders(val) {
  this.items = this.filterArray.filter((el) => {
    const valueToLowerCase = val.toLowerCase();

    switch (true) {
      case (
        el.basic &&
        el.basic.first_name &&
        el.basic.last_name &&
        (
          el.basic.first_name.toLowerCase() + ' ' + el.basic.last_name.toLowerCase()
        ).includes(valueToLowerCase)
      ):
      case (
        el.basic &&
        el.basic.organization_name &&
        el.basic.organization_name.toLowerCase().includes(valueToLowerCase)
      ):
      case (
        el.number &&
        el.number.toString().toLowerCase().includes(valueToLowerCase)
      ):
      case (
        el.specialty &&
        el.specialty.toString().toLowerCase().includes(valueToLowerCase)
      ):
      case (
        el.city &&
        el.city.toString().toLowerCase().includes(valueToLowerCase)
      ):
      case (
        el.state &&
        el.state.toString().toLowerCase().includes(valueToLowerCase)
      ):
      case (
        el.taxonomies &&
        el.taxonomies[0] &&
        el.taxonomies[0].desc.toLowerCase().includes(valueToLowerCase)
      ):
      case (
        el.addresses &&
        el.addresses[0] &&
        el.addresses[0].city.toLowerCase().includes(valueToLowerCase)
      ):
        return true;
      default:
        return false;
    }
  });

  this.filterItems = this.items.length < 1;
},

    loadMoreData() {
      this.loadData = true;
      this.page++;

      api()
        .post(
          `/dpcproviders/providers/search?page=${this.page}&limit=100`,
          this.urlData
        )
        .catch((err) => {
                  if (err.response.status == 500) {
                    this.$refs.error_modal.openPopUp(
                      "There was an error, system admin is notified. Please try again later."
                    );}
                   else{
                    this.$refs.error_modal.openPopUp(err.response.data)
                   }
                  
                })
        .then((res) => {
          if (res) {
            console.log('items',res.data)
            if (res.data.items.length < 100) {
              this.showButton = false;
            }
            res.data.items.forEach((el) => {
              this.items.push(el);
            });
            this.allRows = false;
            this.filteredArray = this.items;
            this.loadData = false;
          }
        });
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    addProviders() {
      if (this.selectedRows.length < 1) {
        let text = "You must  select at least one Provider";
        this.$refs.error_modal.openPopUp(text);
        return;
      }
    else {
     let exists = false;

     api()
     .get(`/dpcproviders/providers`)
     .catch((err) => {
            if (err.response.status == 500) {
              this.$refs.error_modal.openPopUp(
                "There was an error, system admin is notified. Please try again later."
              );}
             else{
              this.$refs.error_modal.openPopUp(err.response.data)
             }
            
          })
  .then((res) => {
    console.log('this.selectedRows',this.selectedRows)
    let existAray1 = [];
    for (let i = 0; i < res.data.length; i++) {
      for (let j = 0; j < this.selectedRows.length; j++) {
        if (
          res.data[i].id ===
          this.selectedRows[j].id
        ) {
     existAray1.push(
      this.selectedRows[j] &&  this.selectedRows[j].basic
          ?  this.selectedRows[j].basic.first_name + ' ' +  this.selectedRows[j].basic.last_name
          :  this.selectedRows[j].number
      );                exists = true;
        }
    
      }
    }
    console.log('exists',exists)

    if (!exists) {
      console.log('ktu')
      let data = [];
    data = this.selectedRows;
      api()
        .post(`/dpcproviders/providers`, this.selectedRows)
        .then((res) => {
          if (res) {
            console.log('data',res)
            let text = "Providers added successfully";
            this.$refs.success_modal.openPopUp(text);
          }
        });
    } else {
      let text = "Provider already exist";
      this.$refs.exists_modal.openPopUp(text, existAray1);
    }
  })
}
    },

    selectAllRows() {
      this.selectedRows = [];
      this.selectAll = !this.selectAll;
      this.selectedRows = this.items;
      // this.items.forEach((el) => {
      //   this.selectedRows.push(el);
      // });

      if (!this.selectAll) {
        this.selectedRows = [];
      }
    },
    activeRow(index, item) {
      let itemById = document.getElementById("id" + index);
      itemById.classList.toggle("activeItem");
      if (itemById.classList.contains("activeItem")) {
        this.selectedRows.push(item);
      } else {
        let removeItem = this.selectedRows.find(
          (x) => x.number === item.number
        );
        this.selectedRows.splice(this.selectedRows.indexOf(removeItem), 1);
      }
    },
    getAllProviders() {
      api()
        .get(`/dpcproviders/providers`)
        .then((res) => {
          if (res) {
          this.allProviders = res.data;
       
          }
        })
        
    },
  },
};
</script>
<style>
.buttons-left {
  justify-content: left;
  align-content: left !important;
  align-items: left !important;
}
.v-data-table--fixed-header > .v-data-table--fixed-header {
  overflow-y: none !important;
}
</style>
<style lang="scss" scoped>
</style>