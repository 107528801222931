<template>
  <div class="no-padding row row-height">
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <errorDialog ref="error_modal" v-on:confirm="closeErrorModal"></errorDialog>

    <v-navigation-drawer
      v-if="overlay"
      permanent
      cols="12"
      :class="display ? 'col-md-3 onMobile' : 'onMobile'"
    >
      <div class="filter-sideBar">
        <div>
          <div class="row no-padding justify-space-between">
            <h3 class="font">Group NPI Filters</h3>
            <h6 class="pointer mr-2" @click="overlay = false">x</h6>
          </div>
          <div class="col-md-12 font">
            <div
              class="row col-md-11 justify-space-between no-padding no-margin"
            >
              <div class="no-padding text-gray">Selected Filter</div>
              <div class="underline-text text-gray pointer" @click="clearAll">
                Clear All
              </div>
            </div>
            <div class="row">
              <v-btn
                v-if="filter.type"
                class="text-center mb-2 ml-2 mr-2 col-md-4"
                small
                color="light"
              >
                {{ filter.type.value }}
                <v-icon right dark small @click="removeFilter('type')">
                  mdi-delete
                </v-icon>
              </v-btn>
              <v-btn
                v-if="filter.distance"
                class="text-center mb-2 ml-2 mr-2 col-md-6"
                small
                color="light"
              >
                Distance: {{ filter.distance }}
                <v-icon right dark small @click="removeFilter('distance')">
                  mdi-delete
                </v-icon>
              </v-btn>
              <v-btn
                v-if="filter.rating"
                class="text-center mb-2 ml-2 mr-2 col-md-4"
                small
                color="light"
              >
                Rating: {{ filter.rating }}
                <v-icon right dark small @click="removeFilter('rating')">
                  mdi-delete
                </v-icon>
              </v-btn>
              <v-btn
                v-if="filter.status"
                class="text-center mb-2 ml-2 mr-2 col-md-6"
                small
                color="light"
              >
                {{ filter.status.text }}
                <v-icon right @click="removeFilter('status')" dark small>
                  mdi-delete
                </v-icon>
              </v-btn>

              <v-btn
                v-if="filter.preferredProvider"
                class="text-center mb-2 ml-2 mr-2 col-md-9"
                small
                color="light"
              >
                <span>
                  {{ filter.preferredProvider.text }}
                </span>
                <v-icon right dark small @click="removeFilter('preferred')">
                  mdi-delete
                </v-icon>
              </v-btn>
              <v-btn
                v-if="filter.manualOverride"
                class="text-center mb-2 ml-2 mr-2 col-md-8"
                small
                color="light"
              >
                <span>
                  {{ filter.manualOverride.text }}
                </span>
                <v-icon right @click="removeFilter('manual')" dark small>
                  mdi-delete
                </v-icon>
              </v-btn>
            </div>
          </div>

          <v-expansion-panels accordion multiple v-model.trim="panel">
            <v-expansion-panel>
              <v-expansion-panel-header class="text-gray"
                >NPI Type</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-radio-group v-model.trim="filter.type">
                  <v-radio
                    @click="selectedArray.push(filter.type.text)"
                    v-for="n in providerType"
                    :key="n.value"
                    :label="n.text"
                    :value="n"
                  ></v-radio>
                </v-radio-group>
                <span class="blue--text pointer">Show More</span>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header class="text-gray">
                Provider Radius Distance</v-expansion-panel-header
              >
              <v-expansion-panel-content class="">
                <div class="row">
                  <v-col class="row ml-2 col-md-9 no-padding">
                    <div class="col-md-3 no-padding">
                      <v-text-field
                        solo
                        value="30"
                        v-model.trim="filter.distance"
                      ></v-text-field>
                    </div>
                    <div class="col-md-8 no-padding mt-3 ml-2">
                      <span class="text-gray small-text"
                        >Distance in Miles</span
                      >
                    </div>
                  </v-col>
                  <v-col class="col-md-2 text-end">
                    <span
                      class="blue--text pointer small-text"
                      @click="selectedArray.push(filter.distance)"
                      >Apply</span
                    >
                  </v-col>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header class="text-gray">
                Quality Rating
              </v-expansion-panel-header>
              <v-expansion-panel-content class="no-padding">
                <div>
                  <v-rating
                    @click="selectedArray.push(filter.rating)"
                    v-model.trim="filter.rating"
                    background-color="orange lighten-3"
                    color="orange"
                  ></v-rating>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header class="text-gray">
                Matching Status
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-radio-group v-model.trim="filter.status">
                  <v-radio
                    @click="selectedArray.push(n.text)"
                    v-for="n in filterMatchingStatus"
                    :key="n.value"
                    :label="n.text"
                    :value="n"
                  ></v-radio>
                </v-radio-group>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header class="text-gray">
                Preferred Provider</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-radio-group v-model.trim="filter.preferredProvider">
                  <v-radio
                    @click="selectedArray.push(filter.preferredProvider)"
                    v-for="n in prefferedProviderItems"
                    :key="n.value"
                    :label="n.text"
                    :value="n"
                  ></v-radio>
                </v-radio-group>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header class="text-gray">
                Manual Override</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-radio-group v-model.trim="filter.manualOverride">
                  <v-radio
                    @click="selectedArray.push(filter.manualOverride)"
                    v-for="n in manualOverrideProviders"
                    :key="n.value"
                    :label="n.text"
                    :value="n"
                  ></v-radio>
                </v-radio-group>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <div class="mb-5 mt-5">
          <v-btn class="blue-buttons col-md-12" @click="filterProviders"
            >View Result</v-btn
          >
        </div>
      </div>
    </v-navigation-drawer>

    <div
      :class="
        overlay
          ? 'hideOnMoblie background-opacity col-md-9'
          : 'col-md-12 navigationWidth '
      "
    >
      <div>
        <v-row>
          <v-col class="col-xl-1 col-lg-3 col-md-3" cols="12">
            <h3>
              <span class="font">Providers</span>
            </h3>
          </v-col>
          <!-- <v-col class="col-lg-3 col-md-4"  cols="12">
          <v-btn class="blue-buttons" :disabled="overlay" @click="overlay = !overlay">Group NPI Filters</v-btn>
        </v-col> -->
          <v-col class="col-xl-2 col-lg-3 col-md-3" cols="12">
            <v-text-field
              class="input-control col-md-3"
              prepend-inner-icon="mdi-magnify"
              @input="searchProviders"
              light
              dense
              outlined
              placeholder="Search Providers "
            >
            </v-text-field>
          </v-col>
          <v-col
            class="col-xl-9 col-lg-6 col-md-6 text-end"
            cols="12"
            align="end"
          >
            <v-menu offset-y v-if="$role == 'user'">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" class="my-1 blue-buttons" :disabled="overlay"
                  >Search Providers
                </v-btn>
              </template>
              <v-list>
                <v-list-item style="disply: block">
                  <a
                    class="a-tag underline-text"
                    @click="
                      $router.push(
                        `/employer-advisor/employers/${routeParams}/providers/add-by-member`
                      )
                    "
                    >By Member Zip Code</a
                  >
                </v-list-item>
                <v-list-item style="disply: block">
                  <a
                    class="a-tag underline-text"
                    @click="
                      $router.push(
                        `/employer-advisor/employers/${routeParams}/providers/add-providers`
                      )
                    "
                    >Search & Add
                  </a>
                </v-list-item>

                <!-- <v-list-item>
                    <a class="a-tag underline-text" >Copy Invite Link</a>
                </v-list-item> -->
              </v-list>
            </v-menu>
            <v-menu offset-y v-else>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" class="my-1 blue-buttons" :disabled="overlay"
                  >Add / Invite Providers
                </v-btn>
              </template>
              <v-list>
                <v-list-item style="disply: block">
                  <a
                    class="a-tag underline-text"
                    @click="
                      $router.push(
                        `/employer-advisor/employers/${routeParams}/providers/add-by-member`
                      )
                    "
                    >Add by Member Zip Code</a
                  >
                </v-list-item>
                <v-list-item style="disply: block">
                  <a
                    class="a-tag underline-text"
                    @click="
                      $router.push(
                        `/employer-advisor/employers/${routeParams}/providers/add-providers`
                      )
                    "
                    >Search & Add
                  </a>
                </v-list-item>
                <v-list-item>
                  <a
                    class="a-tag underline-text"
                    @click="
                      $router.push(
                        `/employer-advisor/employers/${routeParams}/providers/add-providers`
                      )
                    "
                    >Upload a NPI List</a
                  >
                </v-list-item>
                <v-list-item>
                  <a
                    class="a-tag underline-text"
                    @click="
                      $router.push(
                        `/employer-advisor/employers/${routeParams}/providers/invite-providers`
                      )
                    "
                    >Invite Providers</a
                  >
                </v-list-item>
                <!-- <v-list-item>
                    <a class="a-tag underline-text" >Copy Invite Link</a>
                </v-list-item> -->
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </div>
      <v-row v-if="loader" class="justify-center col-md-12">
        <v-progress-circular
          :size="100"
          class="text-center"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-row>
      <v-row v-else-if="allProviders.length < 1 && !emptySearch">
        <v-col v-if="noDataMessage != ''">
          <v-col>
            <a @click="getAllProviders"> {{ noDataMessage }} </a>
          </v-col>
        </v-col>
        <v-col md="12" v-else-if="$role == 'user'" class="font-bold mt-4">
           No Results
        </v-col>
        <v-col md="12" v-else class="font-bold mt-4">
          To create a targeted provider list,
          <a
            @click="
              $router.push(
                `/employer-advisor/employers/${routeParams}/providers/add-by-member`
              )
            "
            >Add your plan member home zip codes</a
          >
        </v-col>
      </v-row>

      <v-simple-table
        ref="table"
        v-else
        fixed-header
        height="630px"
        class="mt-4"
      >
        <template v-slot:default>
          <thead :class="overlay ? 'disabled-table' : ''">
            <tr>
              <th class="text-left">Provider Name</th>
              <th class="text-left">Type</th>
              <th class="text-left">City</th>
              <th class="text-left">Status</th>
              <th class="text-left">Rate</th>

              <th
                class="no-padding text-end justify-end"
                v-if="$role != 'user'"
              >
                <div class="col-md-12 justify-end d-flex text-end">
                  <v-checkbox
                    v-model="allRows"
                    class="no-padding mt-3 stay-blue"
                    @click="selectAllRows1()"
                  ></v-checkbox>
                  <label for="" style="padding-top: 11px">Select all </label>
                </div>
              </th>

              <!-- <th class="text-center">Actions</th> -->
            </tr>
          </thead>
          <tbody v-if="allProviders.length > 0">
            <tr
              v-for="(item, index) in allProviders"
              :key="index"
              :id="'id' + index"
            >
              <td v-if="item.displayName">
                <a @click="singleProvider(item)">{{ item.displayName }}</a>
              </td>
              <td v-else-if="item.companyName">
                <a @click="singleProvider(item)">{{ item.companyName }}</a>
              </td>
              <td v-else-if="item.providerName">
                <a @click="singleProvider(item)">{{ item.providerName }}</a>
              </td>
              <td v-else><a @click="singleProvider(item)">/</a></td>

              <td class="text-gray">Type {{ item.type }}</td>

              <td class="text-gray" v-html="item.city ? item.city : '/'"></td>
              <td
                :class="
                  item.status == 'contracted' ? 'green--text' : 'gray--text'
                "
                v-html="
                  item.status == 'contracted'
                    ? 'Contracted '
                    : item.status == 'pending'
                    ? 'Pending'
                    : '/'
                "
              ></td>
              <td
                :class="
                  item.rate == 'notMatched'
                    ? 'red--text'
                    : item.rate == 'matched'
                    ? 'green--text'
                    : 'gray--text'
                "
                v-html="
                  item.rate == 'matched'
                    ? 'Matched'
                    : item.rate == 'notMatched'
                    ? 'Not Matched'
                    : item.rate == 'NA'
                    ? 'NA'
                    : '/'
                "
              ></td>

              <td
                v-if="$role != 'user'"
                class="text-center mt-3 d-flex no-padding justify-end"
                style="padding-right: 60px"
              >
                <v-checkbox
                  class="stay-blue no-padding stay-blue "
                  v-model="item.checkbox"
                  @click="activeRow1(index, item)"
                ></v-checkbox>
                <!-- v-model="item.checkbox" -->
                <svg
                  class="pointer"
                  @click="singleProvider(item)"
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.9955 19.5396L6.87109 20.1296L7.45998 16.004L15.8611 7.6029C16.3299 7.13406 16.9658 6.87067 17.6289 6.87067C18.2919 6.87067 18.9278 7.13406 19.3966 7.6029C19.8655 8.07175 20.1289 8.70764 20.1289 9.37068C20.1289 10.0337 19.8655 10.6696 19.3966 11.1385L10.9955 19.5396Z"
                    stroke="#4072B7"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M1 13.5C1 16.8152 2.31696 19.9946 4.66117 22.3388C7.00537 24.683 10.1848 26 13.5 26C16.8152 26 19.9946 24.683 22.3388 22.3388C24.683 19.9946 26 16.8152 26 13.5C26 10.1848 24.683 7.00537 22.3388 4.66117C19.9946 2.31696 16.8152 1 13.5 1C10.1848 1 7.00537 2.31696 4.66117 4.66117C2.31696 7.00537 1 10.1848 1 13.5V13.5Z"
                    stroke="#4072B7"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </td>
            </tr>
          </tbody>
          <div v-if="allProviders.length < 1 && emptySearch">
            <v-col md="12" cols="12">
              <v-alert
                border="bottom"
                colored-border
                type="warning"
                elevation="2"
                class="text-gray"
              >
                Sorry! No results found
              </v-alert>
            </v-col>
          </div>
        </template>
      </v-simple-table>

      <v-row
        v-if="allProviders.length > 0 && !loader && $role != 'user'"
        class="col-md-12 pr-0 justify-end mt-6 mb-2"
      >
        <v-btn class="blue-buttons" @click="removeSelected()"
          >Remove Selected</v-btn
        >
      </v-row>
      <v-row
        :class="!overlay ? 'justify-end mt-3' : 'disabled-table'"
        v-if="!noDataMessage && showSaveButton"
      >
        <v-btn class="blue-buttons" @click="addProviders()">Save & Apply</v-btn>
      </v-row>
    </div>

    <v-dialog v-model.trim="dialog" max-width="600px" persistent>
      <v-card>
        <v-col>
          <h3>Remove Provider</h3>
        </v-col>

        <v-col>
          <span v-if="selectedRows.length == 1"
            >Are you sure you want to delete this Provider?
          </span>
          <span v-else
            >Are you sure you want to delete selected Providers?</span
          >
        </v-col>

        <v-col>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              class="blue-buttons float-class"
              @click="removeListOfProvider"
              >Confirm</v-btn
            >
            <v-btn class="button-style float-class" @click="closePopUp"
              >Close</v-btn
            >
          </v-card-actions>
        </v-col>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import api from "../../../../../services/api";
import successModal from "../../../../TablesTemplate/SuccessDialog.vue";
import axios from "axios";
import errorDialog from "../../../../TablesTemplate/ErrorDialog.vue";

export default {
  components: { successModal, errorDialog },
  data() {
    return {
      ratingValue: 2,
      items: [],
      manualOverrideProviders: [
        {
          text: "Manual Override",
          value: true,
        },
      ],
      filterItems: false,
      prefferedProviderItems: [
        {
          text: "Preferred Provider",
          value: true,
        },
      ],
      emptySearch: false,
      filter: {
        type: "",
        speciality: "",
        rating: 0,
        distance: "",
        status: "",
        preferredProvider: false,
        manualOverride: false,
      },
      showSaveButton: false,
      filterMatchingStatus: [
        {
          text: "Matched",
          value: "matched",
        },
        {
          text: "Not Matched",
          value: "notMatched",
        },
      ],
      noDataMessage: "",
      selectedArray: [],
      panel: [0, 1, 2, 3, 4, 5],
      providerType: [
        {
          text: "Hospital",
          value: "hospital",
        },
        {
          text: "Doctor Office",
          value: "doctorOffice",
        },
        {
          text: "Imagining",
          value: "imaging",
        },
        {
          text: "Laboratory",
          value: "laboratory",
        },
        {
          text: "Other",
          value: "other",
        },
      ],

      allProviders: [],
      display: true,
      overlay: false,
      windowWidth: window.innerWidth,
      dialog: false,
      filterArray: [],
      itemToRemove: null,
      itemById: {},
      index: 0,
      allRows: false,
      selectAll: false,
      selectedRows: [],
      loader: true,
      checkIfSelect: false,
      checkIfClicked: false,
    };
  },

  created() {
    if (this.windowWidth < 600) {
      this.display = false;
    } else {
      this.display = true;
    }
    this.overlay = false;
  },
  computed: {
    path() {
      return this.$route.path;
    },
    getClient() {
      return this.$store.getters.getClientGetter;
    },
    routeParams() {
      return this.$route.params.name;
    },
    myArray() {
      return this.filterArray;
    },

    overlayClass() {
      let str = "";
      if (this.display) {
        str = "col-md-12";
        if (document.getElementById("hideOnMobile")) {
          document.getElementById("hideOnMobile").style.display =
            "none !important";
        }
      } else if (this.overlay) {
        str = "col-md-9 disabled-table";
      } else if (!this.display) {
        str = "col-md-12";
      } else {
        str = "col-md-12";
      }
      return str;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  created() {
    this.getAllProviders();
  },

  methods: {
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    selectAllRows() {
      this.selectedRows = [];
      this.selectAll = !this.selectAll;

      if (!this.selectAll) {
        this.selectedRows = [];
      } else {
        this.selectedRows = [];
        let tmp = JSON.stringify(this.allProviders);
        this.selectedRows = JSON.parse(tmp);
      }
    },
    activeRow(index, item) {
      let itemById = document.getElementById("id" + index);
      itemById.classList.toggle("activeItem");
      if (this.allRows) {
        let removeItem = this.selectedRows.find((x) => x._id === item._id);
        this.selectedRows.splice(this.selectedRows.indexOf(removeItem), 1);
      } else {
        if (itemById.classList.contains("activeItem")) {
          this.selectedRows.push(item);
        } else {
          let removeItem = this.selectedRows.find((x) => x._id === item._id);
          this.selectedRows.splice(this.selectedRows.indexOf(removeItem), 1);
        }
      }
    },

    selectAllRows1() {
      this.selectedRows = [];

      if (!this.checkIfSelect) {
        this.selectedRows = [];
        this.checkIfClicked = false;
        this.allProviders.forEach((el) => {
          el.checkbox = false;
        });
      }
      if (this.checkIfSelect) {
        this.allProviders = [];
        this.loader = true;
        this.allProviders = this.items;
        let tmp = JSON.stringify(this.allProviders);
        this.selectedRows = JSON.parse(tmp);
        this.checkIfSelect = false;

        setTimeout(() => {
          this.allProviders.forEach((el) => {
            el.checkbox = true;
          });
          this.loader = false;
        }, 0);
        //  this.$forceUpdate();
      } else if (this.selectAll && !this.checkIfSelect) {
        this.selectAll = false;
      } else {
        this.selectAll = !this.selectAll;
        if (!this.selectAll) {
          this.selectedRows = [];
          this.checkIfClicked = false;
          this.allProviders.forEach((el) => {
            el.checkbox = false;
          });
        } else {
          this.allProviders.forEach((el) => {
            el.checkbox = true;
          });
          this.selectedRows = [];
          let tmp = JSON.stringify(this.allProviders);
          this.selectedRows = JSON.parse(tmp);
        }
      }
    },
    activeRow1(index, item) {
      let itemById = document.getElementById("id" + index);
      if (this.allRows) {
        this.checkIfSelect = true;
        let removeItem = this.selectedRows.find((x) => x._id === item._id);
        this.selectedRows.splice(this.selectedRows.indexOf(removeItem), 1);
        itemById.classList.remove("activeItem");
        this.allRows = false;
        this.checkIfClicked = true;
      } else {
        itemById.classList.toggle("activeItem");
        if (this.checkIfClicked) {
          itemById.classList.remove("activeItem");
        }

        if (item.checkbox) {
          this.selectedRows.push(item);
        } else {
          let removeItem = this.selectedRows.find((x) => x._id === item._id);
          this.selectedRows.splice(this.selectedRows.indexOf(removeItem), 1);
        }
      }
    },
    removeItem(item) {
      this.dialog = true;
      this.itemToRemove = item;
    },
    removeSelected() {
      if (this.selectedRows.length > 0) {
        this.dialog = true;
      } else if (this.selectedRows.length < 1) {
        let text = "You must select at least one Provider";
        this.$refs.error_modal.openPopUp(text);
        return;
      }
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
      this.getAllProviders();
      this.overlay = false;
      this.showSaveButton = false;
    },
    clearAll() {
      this.filter.type = "";
      this.filter.distance = "";
      this.filter.rating = 0;
      this.filter.status = "";
      this.filter.preferredProvider = false;
      this.filter.manualOverride = false;
    },
    removeFilter(type) {
      if (type == "type") {
        this.filter.type = "";
      } else if (type == "distance") {
        this.filter.distance = "";
      } else if (type == "rating") {
        this.filter.rating = 0;
      } else if (type == "status") {
        this.filter.status = "";
      } else if (type == "preferred") {
        this.filter.preferredProvider = false;
      } else if (type == "manual") {
        this.filter.manualOverride = false;
      }
    },

    addProviders() {
      this.itemById.classList.remove("activeItem");
      let data = {
        search: "provider",
        ids: this.selectedRows,
      };
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.employerAdvisor;
      let employer = JSON.parse(localStorage.getItem("employer"));
      api()
        .post(
          `/advisors/${advisorId}/employers/${employer._id}/providers`,
          data
        )
        .then((res) => {
          let text = "Providers added successfully to this Employer!";
          this.$refs.success_modal.openPopUp(text);
        });
    },
    filterProviders() {
      this.filterItems = true;
      let urlData = ``;
      urlData += this.filter.type
        ? `providerType=${this.filter.type.value}&`
        : "";
      urlData += this.filter.distance
        ? `distance=${this.filter.distance}&`
        : "";
      urlData += this.filter.rating
        ? `qualityRating=${this.filter.rating}&`
        : "";
      urlData += this.filter.status
        ? `status=${this.filter.status.value}&`
        : "";
      urlData += this.filter.preferredProvider
        ? `preferred=${this.filter.preferredProvider.value}&`
        : "";
      urlData += this.filter.manualOverride
        ? `manualOverride=${this.filter.manualOverride.value}&`
        : "";

      api()
        .get(`/advisors/providers/search?${urlData}`)
        .then((res) => {
          if (res) {
            if (res.data.providers.length < 1) {
              this.noDataMessage = "No Result Found. Try Again";
              this.allProviders = [];
              this.showSaveButton = false;
            } else {
              this.allProviders = res.data.providers;
              this.showSaveButton = true;
            }

            this.overlay = false;
          }
        });
    },
    getAllProviders() {
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.employerAdvisor;
      let employer = JSON.parse(localStorage.getItem("employer"));
      api()
        .get(`/advisors/${advisorId}/employers/${employer._id}/providers`)
        .then((res) => {
          if (res) {
            this.filterItems = false;
            this.allProviders = res.data.providers;
            this.items = res.data.providers;
            this.filterArray = res.data.providers;
            this.allRows = false;
            this.loader = false;
          }
        });
    },
    searchProviders(val) {
      this.allProviders = this.myArray.filter((el) => {
        if (
          el.displayName &&
          el.displayName.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.displayName.toLowerCase().includes(val.toLowerCase());
        } else if (
          el.companyName &&
          el.companyName.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.companyName.toLowerCase().includes(val.toLowerCase());
        } else if (
          el.providerName &&
          el.providerName.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.providerName.toLowerCase().includes(val.toLowerCase());
        } else if (
          el["Provider First Name"] &&
          el["Provider First Name"].toLowerCase().includes(val.toLowerCase())
        ) {
          return el["Provider First Name"]
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el["Provider Last Name (Legal Name)"] &&
          el["Provider Last Name (Legal Name)"]
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el["Provider Last Name (Legal Name)"]
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el.city &&
          el.city.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.city.toLowerCase().includes(val.toLowerCase());
        } else if (
          el.status &&
          el.status.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.status.toLowerCase().includes(val.toLowerCase());
        } else if (
          el.state &&
          el.state.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.state.toLowerCase().includes(val.toLowerCase());
        }
      });
      if (this.allProviders.length < 1) {
        this.emptySearch = true;
      }
    },

    singleProvider(item) {
      if (!this.overlay && !this.showSaveButton) {
        let user = JSON.parse(localStorage.getItem("user"));
        let advisorId = user.groupPortal[0].organization.employerAdvisor;
        let employer = JSON.parse(localStorage.getItem("employer"));
        api()
          .get(
            `/advisors/${advisorId}/employers/${employer._id}/providers/${item._id}`
          )
          .then((res) => {
            if (res) {
              this.$store.commit(
                "setSingleProviderInEmployerAdvisor",
                res.data.provider
              );
              this.$router.push(
                `/employer-advisor/employers/${this.routeParams}/providers/provider`
              );
            }
          });
      }
    },
    removeListOfProvider() {
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.employerAdvisor;
      let employer = JSON.parse(localStorage.getItem("employer"));
      if (this.selectedRows.length < 1) {
        let text = "You must select at least one Provider";
        this.$refs.error_modal.openPopUp(text);
        return;
      }
      let arr = [];

      this.selectedRows.forEach((el) => {
        if (el._id) {
          arr.push(el._id);
        } else {
          arr.push(el);
        }
      });

      let data = {
        ids: arr,
      };

      api()
        .put(
          `/advisors/${advisorId}/employers/${employer._id}/providersDelete`,
          data
        )
        .then((res) => {
          this.loader = true;
          if (res) {
            api()
              .get(`/advisors/${advisorId}/employers/${employer._id}/providers`)
              .then((res) => {
                if (res) {
                  this.allProviders = res.data.providers;
                  this.filterArray = res.data.providers;
                  this.items = res.data.providers;
                  this.selectedRows = [];
                  this.dialog = false;
                  this.loader = false;
                  this.allRows = false;
                  this.checkIfClicked = false;
                  this.selectAll = false;
                }
              });
          }
        })
        .catch((err) => {
          this.dialog = false;
          if (err.response.data.message) {
            this.$refs.error_modal.openPopUp(err.response.data.message);
          }
          if (err.response.data.error) {
            this.$refs.error_modal.openPopUp(err.response.data.error);
          }
        });
    },
    confirmRemove() {
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.employerAdvisor;
      let employer = JSON.parse(localStorage.getItem("employer"));
      api()
        .delete(
          `/advisors/${advisorId}/employers/${employer._id}/providers/${this.itemToRemove._id}`
        )
        .then((res) => {
          if (res) {
            api()
              .get(`/advisors/${advisorId}/employers/${employer._id}/providers`)
              .then((res) => {
                if (res) {
                  this.allProviders = res.data.providers;
                  this.items = res.data.providers;
                  this.filterArray = res.data.providers;
                  this.dialog = false;
                  this.selectedRows = [];
                }
              });
          }
        });
    },
    closePopUp() {
      this.dialog = false;
    },
    openFilterMenu() {
      this.overlay = !this.overlay;
      this.$store.commit("showFilterMenu", this.overlay);
    },
    changeColor(status) {
      let myClass = "";
      if (status == "Matched") {
        myClass = "red--text";
      } else if (status == "Not matched") {
        myClass = "green--text";
      } else {
        myClass = "gray--text";
      }
      return myClass;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 600) {
        this.display = false;
      } else {
        this.display = true;
      }
    },
  },
};
</script>
<style>
.noActiveItem {
  background-color: white !important;
}
</style>