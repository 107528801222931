<template>
  <div class="container-fluid background-gray">
    <v-app-bar app clipped-left clipped-right class="no-padding">
      <v-toolbar-title
        class="col-lg-1 col-md-1 col-sm-2 col-sm-1 no-padding"
        align="center"
      >
        <img id="logo-img" src="/apaly1.png" class="apaly-pic pointer" />
      </v-toolbar-title>
      <v-divider class="header-divider col-md-1" vertical></v-divider>

      <v-spacer />
      <div class="user-settings">
        <div
          class="font text-right text-bold pointer"
          @click="$router.push('/login')"
        >
          Login
        </div>
        <div class="tiny-font"></div>
      </div>
      <v-menu
        bottom
        left
        offset-y
        origin="top right"
        transition="scale-transition"
      >
        <template v-slot:activator="{}">
          <v-btn dark icon class="mr-1">
            <v-avatar size="40">
              <img
                style="object-fit: cover"
                src="https://www.pngkit.com/png/full/128-1280585_user-icon-fa-fa-user-circle.png"
                alt="John"
              />
            </v-avatar>
          </v-btn>
        </template>
        <v-list>
          <v-divider></v-divider>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-row
      class="background-gray text-center justify-center"
      style="justify-content: center"
    >
      <div class="mt-5 col-md-4">
        <div class="add-client-form row">
          <v-card class="col-md-12 no-padding">
            <v-form ref="form" v-model.trim="valid">
              <div class="col-md-12 orange-underline-wizard">
                <h3>Enter An Email Address</h3>
              </div>
              <div class="col-md-12 text-left">
                <v-text-field
                  label="Email"
                  placeholder="Email"
                  @change="emailValidationFunction"
                  outlined
                  v-model.trim="email"
                  :rules="emailRules"
                ></v-text-field>
              </div>
              <div class="col-md-12 text-end justify-end">
                <v-btn class="blue-buttons" @click="emailValidationFunction"
                  >Check</v-btn
                >
              </div>
            </v-form>
          </v-card>
        </div>
      </div>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      valid: false,
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
    };
  },
  computed: {
    ...mapGetters(["getEmailValidation"]),
  },
  methods: {
    ...mapActions(["registerUser", "emailValidation"]),
    submit() {
      this.$refs.form.validate();
      if (this.valid) {
      }
    },
    emailValidationFunction() {
      // <!-- @change="emailValidationFunction" -->
      const data = {
        email: this.email,
      };
      this.emailValidation(data).then((_) => {
        !this.getEmailValidation.data
          ? (this.emailRules = [this.getEmailValidation.message])
          : (this.emailRules = []);
      });
    },
  },
};
</script>
