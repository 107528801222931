import Invoicing from '../views/TPA.vue'
import RouterView from '../components/TablesTemplate/RouterView.vue'
import Home from '../components/Sections/Tpa/Home.vue'
import SolutionsIndex from '../components/Sections/Tpa/Solutions/Index.vue'
import Solutions from '../components/Sections/Tpa/Solutions/NewSolutions.vue'
import ActivateVendor from '../components/Sections/Tpa/Clients/HorizontalMenu/Solutions/VendorFlow/ActivateVendor'
import ClientSolutionDetails from '../components/Sections/Tpa/Clients/HorizontalMenu/Solutions/ClientSolutionDetails'
import ClosedSolutionDetails from '../components/Sections/Tpa/Clients/HorizontalMenu/Solutions/ClosedSolutionDetails'

import NewSolutionDetails from '../components/Sections/Tpa/Solutions/NewSolutionDetails.vue'
import AllSolutions from '../components/Sections/Tpa/Solutions/AllSolutions.vue'
import NewVendorDetails from '../components/Sections/Tpa/Solutions/NewVendorDetails.vue'
import VirtualNetworkDetails from '../components/Sections/Tpa/Solutions/VirtualNetworkDetails.vue'
import ClinicsAndOrganizationDetails from '../components/Sections/Tpa/Solutions/ClinicAndOrganizationDetails.vue'
import PointSolutionVendorDetails from '../components/Sections/Tpa/Solutions/PointSolutionVendorDetails.vue'
import CliniciansDetails from '../components/Sections/Tpa/Solutions/ClinicianDetails.vue'
import SolutionPackage from '../components/Sections/Tpa/Solutions/SolutionPackage'

import Clients from '../components/Sections/Tpa/Clients/Clients.vue'
import AddClient from '../components/Sections/Tpa/Clients/AddNewCilent.vue'

import ClientsPlans from '../components/Sections/Tpa/Clients/HorizontalMenu/Plans/Plans.vue'
import ClientsPlanDetails from '../components/Sections/Tpa/Clients/HorizontalMenu/Plans/Details.vue'
import ClinetsPlanAddNew from '../components/Sections/Tpa/Clients/HorizontalMenu/Plans/AddNew.vue'

import ClientMembers from '../components/Sections/Tpa/Clients/HorizontalMenu/Members/Members.vue'
import ClientMemberDetails from '../components/Sections/Tpa/Clients/HorizontalMenu/Members/Details.vue'
import ClientMemberAddNew from '../components/Sections/Tpa/Clients/HorizontalMenu/Members/AddNew.vue'

import ClientsBilling from '../components/Sections/Tpa/Clients/HorizontalMenu/Billing/Invoices.vue'
import ClientsVendorDetails from '../components/Sections/Tpa/Clients/HorizontalMenu/Billing/VendorDetails.vue'
import ClientsDpcDetails from '../components/Sections/Tpa/Clients/HorizontalMenu/Billing/DpcDetails.vue'
import ClientsAddBilling from '../components/Sections/Tpa/Clients/HorizontalMenu/Billing/AddNew.vue'

import ClientsSolutions from '../components/Sections/Tpa/Clients/HorizontalMenu/Solutions/Solutions.vue'
import ClientsSolutionsApprove from '../components/Sections/Tpa/Clients/HorizontalMenu/Solutions/Approve.vue'
import ActivateSolution from '../components/Sections/Tpa/Clients/HorizontalMenu/Solutions/SolutionFlow/ActivateSolution.vue'
import ClientsSettings from '../components/Sections/Tpa/Clients/HorizontalMenu/Settings/Settings.vue'


import Income from '../components/Sections/Tpa/Income/Incomes.vue'

import InvoicesIndex from "@/components/Sections/Tpa/Invoices/Index";
import Invoices from "@/components/Sections/Tpa/Invoices/Invoices";
import InvoiceDetails from "@/components/Sections/Tpa/Invoices/Details";
import VendorInvoiceDetails from "@/components/Sections/Tpa/Invoices/VendorInvoice.vue";
import DpcInvoiceDetails from "@/components/Sections/Tpa/Invoices/DpcInvoice.vue";

import Account from '../components/Sections/Tpa/Settings/Account'
import AccountLayout from '../components/Sections/Tpa/Settings/AccountLayout.vue'
import Settings from '../components/Sections/Tpa/Settings/Settings'
import Users from '../components/Sections/Tpa/Settings/Users'
import UserDetails from '../components/Sections/Tpa/Settings/UserDetails'
import AddNewUser from '../components/Sections/Tpa/Settings/AddUser'
import PleaseWait from '../components/LoginRegister/PleaseWait.vue'

function routeGuard(to, from, next) {
    let user = JSON.parse(localStorage.getItem('user'));
    if(!user){
        next('/login');
    }
    else if ( user.type != 'tpa' || !$cookies.get('token')){
      next('/login');
    }else{
        next();
    }
      
}
const router = [
    {
       path:'/tpa',
       name:'',
       beforeEnter: routeGuard,
       redirect: () => {
           return { path: '/tpa/home'}
       },
    },
    {
        path:'/redirect/tpa/clients/:clientId/solutions',
        name:'Client Solutions',
        component:PleaseWait,
    },
   {
       component:RouterView,
       path:'/tpa',
       name:'',
      beforeEnter: routeGuard,
       redirect: () => {
           return { path: '/tpa'}
       },
       children:[
           {
               path:'/solutions/approve',
               name:"approve",
               component: ClientsSolutionsApprove,
           },
           {
               path:'/tpa',
               name:'',
               component:Invoicing,
               children:[
               
                {
                    path:'home',
                    name:'Home',
                    component:Home,
                },
                {
                    path:'home',
                    name:'Home',
                    component:RouterView,
                    redirect: () => {
                        return { path: '/tpa/home'}
                    },
                    children:[
                        {
                            path:'/tpa/profile',
                            name:'Profile',
                            component:RouterView,
                        },
                        {
                            path:'/tpa/solutions',
                            name:'Solutions',
                            component:SolutionsIndex,
                            redirect: () => {
                                return { path: '/tpa/solutions'}
                            },
                            children:[                 
                                {  
                                    path:'/tpa/solutions',
                                    name:'',
                                    component:Solutions,
                                    children:[
                                       
                                        {
                                            path:'solution-details',
                                            name:'Solution Details',
                                            component:NewSolutionDetails,
                                            meta:{
                                                isChildren:true
                                            }
                                        },
                                        {
                                            path:'vendor-details',
                                            name:'Vendor Details',
                                            component:NewVendorDetails,
                                            meta:{
                                                isChildren:true
                                            }
                                        },
                                        
                                        {
                                            path:'/tpa/solutions/package',
                                            name:'Package',
                                            component:SolutionPackage,
                                            meta:{
                                                isChildren:true
                                            },
                                        },
                                        {
                                            path:'virtual-network/:solutionId',
                                            name:'Virtual Network Details',
                                            component:VirtualNetworkDetails,
                                            meta:{
                                                isChildren:true
                                            }
                                        },
                                        {
                                            path:'clinics-organization/:solutionId',
                                            name:'Clinics And Organization Details',
                                            component:ClinicsAndOrganizationDetails,
                                            meta:{
                                                isChildren:true
                                            },
                                            props: (route) => ({
                                                location: route.query.location || '',
                                             
                                              }),
                                        },
                                        {
                                            path:'point-solution-vendor/:solutionId',
                                            name:'Point Solution Vendor Details',
                                            component:PointSolutionVendorDetails,
                                            meta:{
                                                isChildren:true
                                            }
                                        },
                                        {
                                            path:'clinician/:solutionId',
                                            name:'Clinician Details',
                                            component:CliniciansDetails,
                                            meta:{
                                                isChildren:true
                                            }
                                        },
                                        {
                                            path: "all/:category",
                                            name: "All Soluttions",
                                            component: AllSolutions,
                                            meta: {
                                              isChildren: true,
                                            },
                                          },
                                    ]
                                },

                            ]
                        },
                        {
                            path:'clients',
                            name:'',
                            component:RouterView,
                            redirect: () => {
                                return { path: '/tpa/clients'}
                            },
                            children:[    
                                           
                                {  
                                    path:'/tpa/clients',
                                    name:'Clients',
                                    component:Clients,
                                    meta:{
                                        type:'clients'
                                    },
                                    children:[
                                        {
                                            path:'/tpa/clients/add-new',
                                            name:'Add Client',
                                            component:AddClient,
    
                                        }, 
                                        {
                                            path:'/tpa/clients/:clientId/details',
                                            name:'Client Home',
                                            component:AddClient,
                                            meta:{
                                                isChildren:true,
                                                type:'general'
                                            },
                                        },
                                        {
                                            path:'/tpa/clients/:clientId/plans',
                                            name:'Client Plans',
                                            component:ClientsPlans,
                                            meta:{
                                                type:'plans'
                                            },
                                        },
                                        {
                                            path:'/tpa/clients/:clientId/plans/:id/details',
                                            name:'Plan Detail',
                                            component:ClientsPlanDetails,
                                            meta:{
                                                isChildren:true,
                                                type:'plansDetails'
                                            },
                                        },
                                        {
                                            path:'/tpa/clients/plans/:clientId/add-new',
                                            name:'Add New Health Plan',
                                            component:ClinetsPlanAddNew,
                                            meta:{
                                                isChildren:true,
                                                type:'addNewPlan'
                                            },
                                        },
                                        {
                                            path:'/tpa/clients/:clientId/members',
                                            name:'Client Members',
                                            component:ClientMembers,
                                            meta:{
                                                type:'members'
                                            },
                                        },
                                        {
                                            path:'/tpa/clients/:clientId/members/:memberId/details',
                                            name:'Client Member Details',
                                            component:ClientMemberDetails,
                                            meta:{
                                                isChildren:true,
                                                type:'clientMemberDetails'
                                            },
                                        },
                                        {
                                            path:'/tpa/clients/:clientId/members/add-new',
                                            name:'Add New Client Member',
                                            component:ClientMemberAddNew,
                                            meta:{
                                                isChildren:true,
                                                type:'addNewClientMember'
                                            },
                                        },
                                        {
                                            path:'/tpa/clients/:clientId/invoices',
                                            name:'Invoices',
                                            component:ClientsBilling,
                                            meta:{
                                                type:'invoices'
                                            },
                                        },
                                        {
                                            path:'/tpa/clients/:clientId/invoices/vendor-invoice/:invoiceId',
                                            name:'Vendor Invoice Details',
                                            component:ClientsVendorDetails,
                                            meta:{
                                                isChildren:true,
                                                type:'invoiceDetails'
                                            },
                                        },
                                        {
                                            path:'/tpa/clients/:clientId/invoices/dpc-invoice/:invoiceId',
                                            name:'DPC Invoice Details',
                                            component:ClientsDpcDetails,
                                            meta:{
                                                isChildren:true,
                                                type:'invoiceDetails'
                                            },
                                        },
                                        {
                                            path:'/tpa/clients/invoices/:id/add-new',
                                            name:'Add invoice',
                                            component:ClientsAddBilling,
                                            meta:{
                                                isChildren:true,
                                                type:'addNewInvoice'
                                            },
                                        },
                                        
                                        {
                                            path:'/tpa/clients/:clientId/solutions',
                                            name:'Client Solutions',
                                            component:ClientsSolutions,
                                            meta:{
                                                type:'solutions'
                                            },
                                            
                                        },
                                        {
                                            path:'/tpa/clients/:clientId/solutions/:id:token?',
                                            name:'Client Solutions',
                                            component:ClientsSolutions,
                                            meta:{
                                                type:'solutions'
                                            },
                                            
                                        },
                                        {
                                            path:'/tpa/clients/:clientId/solutions/details/:solutionId',
                                            name:'Client Solution Details',
                                            component:ClientSolutionDetails,
                                            meta:{
                                                isChildren:true,
                                                type:'solutionDetails'
                                            },
                                        },
                                        {
                                            path:'/tpa/clients/:clientId/solutions/closed/details/:solutionId',
                                            name:'Client Closed Solution Details',
                                            component:ClosedSolutionDetails,
                                            meta:{
                                                isChildren:true,
                                                type:'solutionDetails'
                                            },
                                        },
                                        {
                                            path:'/tpa/clients/solutions/:clientId/activate-solution/:solutionId',
                                            name:'Activate Solution',
                                            component:ActivateSolution,
                                            meta:{
                                                isChildren:true,
                                                type:'solutionDetails'
                                            },
                                        },
                                        {
                                            path:'/tpa/client/:clientId/activate-vendor/:vendorId',
                                            name:'Activate Vendor',
                                            component:ActivateVendor,
                                            meta:{
                                                isChildren:true,
                                                type:'solutionDetails'
                                            },
                                        },
                                        {
                                            path:'/tpa/clients/settings/:id',
                                            name:'Client Settings',
                                            component:ClientsSettings,
                                            meta:{
                                              
                                                type:'settings'
                                            },
                                            
                                        },

                                    ]
                                },
                            ]
                        },
                        {
                            path:'invoices',
                            name:'',
                            component:InvoicesIndex,
                            redirect: () => {
                                return { path: '/tpa/invoices'}
                            },
                            children:[

                                {
                                    path:'/tpa/invoices',
                                    name:'Invoices',
                                    component:Invoices,
                                    meta:{
                                        type:'invoices'
                                    },
                                    children:[
                                        {
                                            path:'/tpa/invoices/details/:id',
                                            name:'Invoice Details',
                                            component:InvoiceDetails,
                                            meta:{
                                                isChildren:true
                                            },
                                        },
                                        {
                                            path:'/tpa/invoices/vendor-details/:invoiceId',
                                            name:'Vendor Invoice Details',
                                            component:VendorInvoiceDetails,
                                            meta:{
                                                isChildren:true
                                            },
                                        },
                                        {
                                            path:'/tpa/invoices/dpc-details/:invoiceId',
                                            name:'DPC Invoice Details',
                                            component:DpcInvoiceDetails,
                                            meta:{
                                                isChildren:true
                                            },
                                        },
                                    ]
                                },
                            ]
                        },
                        {
                            path:'income',
                            name:'',
                            component:RouterView,
                            redirect: () => {
                                return { path: '/tpa/income'}
                            },
                            children:[                 
                                {  
                                    path:'/tpa/income',
                                    name:'Income',
                                    component:Income,
                                },
                            ]
                        },
                        {
                            path:'account',
                            name:'',
                            component:RouterView,
                            redirect: () => {
                                return { path: '/tpa/account'}
                            },
                            children:[                 
                                {  
                                    path:'/tpa/account',
                                    name:'Account',
                                    component:AccountLayout,
                               
                                },
                            ]
                        },
                
                        {
                            path:'users',
                            name:'',
                            component:RouterView,
                            redirect: () => {
                                return { path: '/tpa/users'}
                            },
                            children:[                 
                                {  
                                    path:'/tpa/users',
                                    name:'Users',
                                    component:Users,
                                    children:[
                                        {
                                            path:'/tpa/users/details',
                                            name:'User Details',
                                            component:UserDetails,
                                            meta:{
                                                isChildren:true
                                            },
                                        },
                                        {
                                            path:'/tpa/users/add-new',
                                            name:'Add New',
                                            component:AddNewUser,
                                            meta:{
                                                isChildren:true
                                            },
                                            
                                        }
                                    ]
                                },
                            ]
                        },
                        {
                            path:'settings',
                            name:'',
                            component:RouterView,
                            redirect: () => {
                                return { path: '/tpa/settings'}
                            },
                            children:[                 
                                {  
                                    path:'/tpa/settings',
                                    name:'Settings',
                                    component:Settings,
                               
                                },
                            ]
                        },
                    ]
                },
                
               ]
           }
       ]
   },

]

export default router;