<template>
    <div class="no-padding no-margin">
        <ContractsTable v-if="route == `/provider/contracts`"></ContractsTable>
        <AddContract v-if="route == `/provider/contracts/${routeParamsType}/add-new`"></AddContract>
        <RequestNewContract  v-if="route == `/provider/contracts/request`"></RequestNewContract>
        <Contract  v-if="route ==  `/provider/contracts/contract`"></Contract>
        <ContractType v-if="route ==  `/provider/contracts/select-type`"></ContractType>   
    </div>
</template>
<script>
import AddContract from './AddContract.vue'
import Contract from './Contract.vue'
import ContractResult from './ContractResult.vue'
import ContractsTable from './ContractsTable.vue'
import RequestNewContract from './RequestNewContract.vue'
import ContractType from './ContractType.vue'

export default {
    components:{
    AddContract,
    Contract,
    ContractResult,
    RequestNewContract,
    ContractsTable,
    ContractType
    },
    data(){
        return{
         
        }
    },
    computed:{
        route(){
            return this.$route.path
        },
        routeParams(){
           let parsed =''
            if(this.$route.params.name){
               parsed = this.$route.params.name.replace(/ /g, '%20')
            }
            return parsed
        },
          routeParamsType(){
             
             return this.$route.params.type
        }
    }

}
</script>