<template>
    <div class="no-padding no-margin">
        <div v-if="path.includes(`/provider/providers`)" class="">
            <ProvidersTable v-if="path == `/provider/providers`"></ProvidersTable>
            <ResultTable v-if="path == `/provider/providers/result`"></ResultTable>
            <AddProviders v-if="path == `/provider/providers/add-providers` "></AddProviders>
            <InviteProvider v-if="path == `/provider/providers/invite-providers`"></InviteProvider>
            <ProviderCrudForm v-if="path == `/provider/providers/provider`"></ProviderCrudForm>
            <SingleNpi v-if="path == `/provider/providers/result/npi` "></SingleNpi>
        </div>
        <div v-else-if="path.includes(`/provider/affiliates`)">
            <ProvidersTable v-if="path == `/provider/affiliates`"></ProvidersTable>
            <ResultTable v-if="path == `/provider/affiliates/result`"></ResultTable>
            <AddProviders v-if="path == `/provider/affiliates/add-providers` "></AddProviders>
            <InviteProvider v-if="path == `/provider/affiliates/invite-providers`"></InviteProvider>
            <ProviderCrudForm v-if="path == `/provider/affiliates/provider`"></ProviderCrudForm>
            <SingleNpi v-if="path == `/provider/affiliates/result/npi` "></SingleNpi>
        </div>
         <div  v-else>
            <ProvidersTable v-if="path == `/provider/hospital-based`"></ProvidersTable>
            <ResultTable v-if="path == `/provider/hospital-based/result`"></ResultTable>
            <AddProviders v-if="path == `/provider/hospital-based/add-providers` "></AddProviders>
            <InviteProvider v-if="path == `/provider/hospital-based/invite-providers`"></InviteProvider>
            <ProviderCrudForm v-if="path == `/provider/hospital-based/provider`"></ProviderCrudForm>
            <SingleNpi v-if="path == `/provider/hospital-based/result/npi` "></SingleNpi>
        </div>

      
    </div>
</template>
<script>
import AddProviders from './AddProviders.vue'
import InviteProvider from './InviteProvider.vue'
import ProviderCrudForm from './ProviderCrudForm.vue'
import ResultTable from './ResultTable.vue'
import ProvidersTable from './ProvidersTable.vue'
import SingleNpi from './SingleNpi.vue'



export default {
    components:{
    ResultTable,
    AddProviders,
    InviteProvider,
    ProviderCrudForm,
    ProvidersTable,
    SingleNpi,


    },
    data(){
        return{
            
        }
    },
    computed:{
        path(){
             return this.$route.path.replace(/%20/g,' ')
        },
        routeName(){
            return this.$route.params.name
        },
         routeParams(){
            return this.$route.params.name
      }
    },
   
    created(){
      
    },
    methods:{
     
    }
   
}
</script>
<style>
.background-opacity{
    opacity: 0.46;
    background-color: rgb(33, 33, 33);
    border-color: rgb(33, 33, 33);
}
</style>