<template>
<div class="no-padding row-height font-bold">
  <div class="col-md-12">
      <v-row>
        <v-col class="col-md-8" md="4" cols="12">
          <h3>
            <span class="font"> Bundle Templates</span>
          </h3>
        </v-col>
      </v-row>      
      <div class="col-md-12">
           <div class="mt-7 text-underline"> COMING SOON</div> 
          <div class="mt-3"> Bundle templates will enable you to build standardized service templates and enable your provider clients to “pull them into their provider portal.”
            This will make it easy for your clients to implement bundled services, without all the work of putting them together, and each provider can define their own rates.
          </div>

      </div>
     
  </div>
 
</div>

<!-- <div class="no-padding row">
   <successModal  ref="success_modal" v-on:confirm="closeSuccessModal"></successModal>
     <div :class="overlay ? 'hideOnMoblie background-opacity col-md-9' : 'col-md-12 navigationWidth '">
        <v-row>
            <v-col class="col-md-4" md="4" cols="12">
                <h3>
                <span class="font"> Bundles</span>
              </h3>
            </v-col>
              <v-col class="col-md-4" md="4" cols="12">
                 <v-text-field class="input-control" @input="searchBundles" prepend-inner-icon="mdi-magnify" light dense outlined placeholder="Search Bundles by Name">
                </v-text-field>
            </v-col>
            <v-col class="col-md-4" cols="12"   align="end">
              <v-btn  class="my-1 blue-buttons" :disabled="overlay" @click="$router.push(`/provider-advisor/bundles/builder`)">Build a Bundle</v-btn>
          </v-col>
          </v-row>   
           <v-row v-if="items.length < 1">
            <v-col md="12">
                <a  @click="$router.push(`/provider-advisor/bundles/builder`)">You Don't have Bundles Yet. Click Here To Add Bundle.</a>
            </v-col>
          </v-row>
              <v-simple-table v-else  fixed-header :class="overlay ? 'disabled-table  ' : ''">
                <template v-slot:default>
                <thead :class="overlay ? 'disabled-table' : ''">
                  <tr>
                    <th class="text-left text-gray font">
                    Bundle Name
                    </th>
                    <th class="text-left text-gray font">
                    Source
                    </th>
                    <th class="text-left text-gray font">
                      Partner
                    </th>
                    <th class="text-left text-gray font">
                      Rate
                    </th>
                    <th class="text-left text-gray font">
                    Status
                    </th>
                    <th class="text-end text-gray font">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item,index) in items"
                    :key="index"
                    :id="'id' + index"
                    class="text-gray"
                  >
                    
                    <td v-if="item.information.name"><a  @click="singleBundle(item)">{{ item.information.name }}</a> </td>
                    <td v-else><a  @click="singleBundle(item)">/</a></td>
                    <td v-if="item.source"> {{ item.source.charAt(0).toUpperCase() + item.source.slice(1) }}</td>
                    <td>N/A </td>
                    <td>$20.000</td>
                    <td v-html="item.status ?  item.status.charAt(0).toUpperCase() + item.status.slice(1) : '/'">  </td>
          
                    <td v-if="!filterItems"   class="text-end">
                         <svg class="pointer"  @click="singleBundle(item)"  width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.9955 19.5396L6.87109 20.1296L7.45998 16.004L15.8611 7.6029C16.3299 7.13406 16.9658 6.87067 17.6289 6.87067C18.2919 6.87067 18.9278 7.13406 19.3966 7.6029C19.8655 8.07175 20.1289 8.70764 20.1289 9.37068C20.1289 10.0337 19.8655 10.6696 19.3966 11.1385L10.9955 19.5396Z" stroke="#4072B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M1 13.5C1 16.8152 2.31696 19.9946 4.66117 22.3388C7.00537 24.683 10.1848 26 13.5 26C16.8152 26 19.9946 24.683 22.3388 22.3388C24.683 19.9946 26 16.8152 26 13.5C26 10.1848 24.683 7.00537 22.3388 4.66117C19.9946 2.31696 16.8152 1 13.5 1C10.1848 1 7.00537 2.31696 4.66117 4.66117C2.31696 7.00537 1 10.1848 1 13.5V13.5Z" stroke="#4072B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <svg class="ml-2 pointer"  @click="deleteItem(item)" width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.35547 13C1.35547 16.1826 2.65524 19.2348 4.96884 21.4853C7.28244 23.7357 10.4204 25 13.6923 25C16.9642 25 20.1021 23.7357 22.4157 21.4853C24.7293 19.2348 26.0291 16.1826 26.0291 13C26.0291 9.8174 24.7293 6.76516 22.4157 4.51472C20.1021 2.26428 16.9642 1 13.6923 1C10.4204 1 7.28244 2.26428 4.96884 4.51472C2.65524 6.76516 1.35547 9.8174 1.35547 13V13Z" stroke="#D00000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M8.75781 17.8002L18.6273 8.2002" stroke="#D00000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M18.6273 17.8002L8.75781 8.2002" stroke="#D00000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                  
                    </td>
                     <td v-else class="text-end">
                        <v-checkbox class="text-end" solo  @click="activeRow(index,item)"> </v-checkbox>
                    </td>
                  </tr>
                </tbody>
            </template>
          </v-simple-table>
          <v-row  :class="!overlay ? 'justify-end mt-3' : 'disabled-table'" v-if="!noDataMessage && showSaveButton">
            <v-btn class="blue-buttons" @click="addBundle()">Save & Apply</v-btn>
        </v-row>
      <v-dialog v-model.trim="dialog" max-width="600px" persistent>
       <v-card>
            <v-col>
                <h3>Remove Bundle</h3>
            </v-col>
      
       
            <v-col>
                <span>Are you sure you want to delete this Bundle?</span>
            </v-col>
       
            <v-col>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="blue-buttons float-class" @click="confirmRemove">Confirm</v-btn>
                    <v-btn class="button-style float-class" @click="closePopUp">Close</v-btn>
                </v-card-actions>
            </v-col>
        </v-card>
    </v-dialog>
 </div>

</div> -->

    
</template>
<script>
import successModal from '../../../TablesTemplate/SuccessDialog.vue' 
import api from '../../../../services/api'
  export default {
    components:{
      successModal
    },
    data () {
      return {
        items: [],
        overlay:false,
        dialog:false,
        filter:{
          vendors:'',
          source:'',
          bundles:'',
          hospitals:''
        },
        filterItems:false,
        display:true,
        windowWidth:window.innerWidth,
        panel: [0, 1,2,3,4,5],
        vendors:[
          {
               text:'Bundles R Us',
               value:'bundlesRUs'
          },
          {
               text:'Vizient',
               value:'vizient'
          },
        ],
        source:[
           {
               text:'Template',
               value:'template'
          },
           {
               text:'Own',
               value:'own'
          },
        ],
        itemToDelete:{},
        bundles:[],
        selectedArray:[],
        hospitals:[
          {
             text:'Henry Ford Main',
             value:'henryFordMain'
          },
          {
             text:'Henry Ford South',
             value:'henryFordSouth'
          },
            {
             text:'Henry Ford West',
             value:'henryFordWest'
          },
        ],
        noDataMessage : '',
        showSaveButton:false,
        selectedRows:[],
        itemsArray: []
        
      }
    },
    computed:{
      filteredArray(){
           return this.itemsArray
      },
      getClient(){
          return this.$store.getters.getClientGetter
       },
       routeParams(){
            return this.$route.params.name
       }
    },
    mounted() {
        this.$nextTick(() => {
          window.addEventListener('resize', this.onResize);
       })
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.onResize);
    },
    created(){
         this.getAllBundles();
         if (this.windowWidth < 600) {
            this.display = false;
         } else {
            this.display = true;
        }
        this.overlay = false;
    },
    methods:{
      deleteItem(item){
        this.itemToDelete = item;
        this.dialog = true
      },
       closeSuccessModal(){
        this.$refs.success_modal.closePopUp();
        this.getAllBundles();
        this.overlay = false;
        this.showSaveButton = false;
        
      },
     removeItem(item){
        this.dialog = true;
        this.itemToRemove = item
     },
     onResize() {
        this.windowWidth = window.innerWidth
        if (this.windowWidth < 600) {
          this.display = false;
        } else {
          this.display = true;
        }
      },
    
    searchBundles(val){
      this.items = this.filteredArray.filter((el)=>{
            if(el.information.name.toLowerCase().includes(val.toLowerCase())){
              return el.information.name.toLowerCase().includes(val.toLowerCase())
            }
          })
    },
       
     confirmRemove(){
          let user = JSON.parse(localStorage.getItem('user'));
          let advisorId = user.groupPortal[0].organization.providerAdvisor;
          api().delete(`/advisors/${advisorId}/bundles/${this.itemToDelete._id}`).then((res)=>{
             this.getAllBundles() 
          })
         
      },
      closePopUp(){
        this.dialog = false
      },
   
      getAllBundles(){
        let user = JSON.parse(localStorage.getItem('user'));
        let advisorId = user.groupPortal[0].organization.providerAdvisor;
        api().get(`/advisors/${advisorId}/bundles`).then((res)=>{
          this.items = res.data.bundles;
          this.itemsArray = res.data.bundles;
          this.dialog = false
        })
      },
      singleBundle(item){
        let user = JSON.parse(localStorage.getItem('user'));
        let advisorId = user.groupPortal[0].organization.providerAdvisor;
         api().get(`/advisors/${advisorId}/bundles/${item._id}`).then((res)=>{
           
           this.$store.commit('setSingleBundle', res.data.bundle);
           this.$router.push(`/provider-advisor/bundles/bundle`)
        })
       
      }
    }
  }
</script>