import axios from 'axios'
import api from '../../services/api'
import emailValidation from "../../services/emailValidation";
  const state = {  
     testName:'test1',
     user:null,
     emailValidation: false,
     org: null,
    }
    const getters = {
        getTestName(state) {
            return  state.testName;
        },
        getOrg(state) {
            return  state.org;
        },
        getEmailValidation: (state) => state.emailValidation,
    }
    
    const mutations = {
        setClientName(state, testName){
           state.testName = testName 
        },
        setOrg(state, org){
            state.org = org 
         },
        setEmailValidation: (state, validation) => state.emailValidation = validation
    }
    const actions = {
      loginUser(state, user){
        api().post('/advisors/auth/login',user)
      },
      registerUser(state, user){
        api().post('/advisors/auth/signup',user);
      },
      emailValidation ({commit}, data) {
        return new Promise((resolve, reject) => {
            emailValidation.validateDomain(data).then((res) => {
            
                commit('setEmailValidation', res.data)
                resolve(res)
            }).catch((error) => {
               
                reject(error)
            })
        })
    },
    }
export default {
    state,
    getters,
    mutations,
    actions
}