<template>
    <div class="form-div">
      <v-form class="col-md-10 col-xl-10 col-lg-10" ref="form" v-model.trim="valid">
        <div class="org">
          <span class="text-center">
          <h2 class="apaly-color mb-10">
            Organization Exists
          </h2>
          </span>
        </div>
        <div class="display">
          <span class="text-center">
            <span class="mb-3">
              It looks like an account already exists for <strong>{{ orgName ? orgName : email }}.</strong> 
            </span>
            </span>
            <span class="text-center">
              <span class="mb-3">
                   Would you you like to request to join?
              </span>
              </span>
        </div>
        <div class="d-flex col-12 mt-10">
          <span class="col-6">
            <v-btn
            @click="openReasons()"
            class="no-btn"> No, I'm good</v-btn>
          </span>
          <span class="col-6" align="end">
            <v-btn
            @click="request()"
            color="#16407A"
            class="yes-btn"
            >
             Yes, make a request
            </v-btn>
          </span>
        </div>
      </v-form>
      <v-dialog v-model.trim="requestDialog" width="800" class="success-modal">
        <v-card>
          <v-card-text class="success-modal pt-5">
            <span align="end" class="pt-0 pr-0">
              <p class="font-weight-bold" @click="requestDialog=false">X</p>
            </span>
            <div class="text-center">
              <svg width="126" height="112" viewBox="0 0 126 112" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1017_28415)">
                <path d="M124.403 42.8311C124.358 42.8311 124.315 42.8174 124.278 42.7918L63.629 0.633045C63.4444 0.505346 63.2251 0.437101 63.0005 0.437483C62.776 0.437865 62.5569 0.506856 62.3727 0.635183L2.16072 42.7914C2.1131 42.8248 2.05416 42.8379 1.99686 42.8278C1.93956 42.8178 1.8886 42.7854 1.8552 42.7379C1.8218 42.6904 1.80868 42.6315 1.81873 42.5743C1.82879 42.5171 1.86119 42.4663 1.90882 42.4329L62.1209 0.27671C62.3787 0.0970783 62.6854 0.000517809 62.9998 2.07673e-06C63.3142 -0.000513655 63.6213 0.0950393 63.8796 0.273824L124.529 42.4325C124.567 42.4592 124.596 42.4973 124.611 42.5414C124.626 42.5855 124.626 42.6333 124.612 42.6778C124.598 42.7222 124.571 42.7611 124.533 42.7887C124.495 42.8162 124.45 42.8311 124.403 42.8311Z" fill="#3F3D56"/>
                <path d="M5.60156 44.3256L63.0603 1.82104L120.955 47.35L66.0209 79.855L36.1963 73.0694L5.60156 44.3256Z" fill="#E6E6E6"/>
                <path d="M38.7139 99.9318H10.3279C10.1536 99.9321 9.98095 99.898 9.81985 99.8316C9.65876 99.7651 9.51236 99.6677 9.38903 99.5447C9.2657 99.4218 9.16787 99.2758 9.10112 99.1151C9.03436 98.9544 9 98.7821 9 98.6081C9 98.4342 9.03436 98.2619 9.10112 98.1012C9.16787 97.9405 9.2657 97.7945 9.38903 97.6715C9.51236 97.5486 9.65876 97.4511 9.81985 97.3847C9.98095 97.3183 10.1536 97.2842 10.3279 97.2844H38.7139C38.8882 97.2842 39.0609 97.3183 39.2219 97.3847C39.383 97.4511 39.5294 97.5486 39.6528 97.6715C39.7761 97.7945 39.8739 97.9405 39.9407 98.1012C40.0074 98.2619 40.0418 98.4342 40.0418 98.6081C40.0418 98.7821 40.0074 98.9544 39.9407 99.1151C39.8739 99.2758 39.7761 99.4218 39.6528 99.5447C39.5294 99.6677 39.383 99.7651 39.2219 99.8316C39.0609 99.898 38.8882 99.9321 38.7139 99.9318Z" fill="#16407A"/>
                <path d="M20.5122 94.2407H10.3279C10.1536 94.2409 9.98095 94.2068 9.81985 94.1404C9.65876 94.074 9.51236 93.9765 9.38903 93.8536C9.2657 93.7306 9.16787 93.5846 9.10112 93.4239C9.03436 93.2632 9 93.0909 9 92.917C9 92.743 9.03436 92.5707 9.10112 92.41C9.16787 92.2493 9.2657 92.1033 9.38903 91.9804C9.51236 91.8574 9.65876 91.76 9.81985 91.6935C9.98095 91.6271 10.1536 91.593 10.3279 91.5933H20.5122C20.6865 91.593 20.8591 91.6271 21.0202 91.6935C21.1813 91.76 21.3277 91.8574 21.451 91.9804C21.5743 92.1033 21.6722 92.2493 21.7389 92.41C21.8057 92.5707 21.84 92.743 21.84 92.917C21.84 93.0909 21.8057 93.2632 21.7389 93.4239C21.6722 93.5846 21.5743 93.7306 21.451 93.8536C21.3277 93.9765 21.1813 94.074 21.0202 94.1404C20.8591 94.2068 20.6865 94.2409 20.5122 94.2407Z" fill="#16407A"/>
                <path d="M63.8685 67.9999C63.6528 68.0001 63.439 67.9583 63.2393 67.8768L27.8027 53.2006V7.26147C27.8032 6.85525 27.9651 6.46579 28.2529 6.17855C28.5407 5.8913 28.9308 5.72972 29.3378 5.72925H97.3203C97.7273 5.72972 98.1174 5.8913 98.4052 6.17855C98.693 6.46579 98.8549 6.85525 98.8554 7.26147V53.2327L98.7888 53.2611L64.5168 67.8697C64.3115 67.9557 64.0911 68 63.8685 67.9999Z" fill="white"/>
                <path d="M63.8688 68.1093C63.6387 68.1095 63.4108 68.065 63.1978 67.9782L27.6934 53.2738V7.26154C27.6939 6.82629 27.8673 6.40902 28.1756 6.10125C28.484 5.79349 28.902 5.62037 29.3381 5.61987H97.3205C97.7566 5.62037 98.1747 5.79349 98.483 6.10125C98.7914 6.40902 98.9648 6.82629 98.9653 7.26154V53.305L64.5601 67.9703C64.3413 68.0622 64.1062 68.1095 63.8688 68.1093ZM28.1319 52.9813L63.3651 67.5735C63.6937 67.7066 64.0618 67.7044 64.3888 67.5674L98.5267 53.016V7.26154C98.5263 6.94237 98.3991 6.63637 98.173 6.41068C97.9469 6.18499 97.6403 6.05803 97.3206 6.05765H29.3381C29.0183 6.05803 28.7118 6.18499 28.4857 6.41068C28.2595 6.63637 28.1323 6.94237 28.132 7.26154L28.1319 52.9813Z" fill="#3F3D56"/>
                <path d="M123.965 42.3933H123.921L98.7456 53.1233L64.3005 67.8041C64.1659 67.8607 64.0215 67.8901 63.8754 67.8909C63.7294 67.8916 63.5847 67.8636 63.4495 67.8085L27.9123 53.0926L2.1184 42.4108L2.07899 42.3933H2.03509C1.6281 42.3938 1.2379 42.5553 0.950114 42.8426C0.662327 43.1298 0.500452 43.5193 0.5 43.9255V110.468C0.500452 110.874 0.662327 111.263 0.950114 111.551C1.2379 111.838 1.6281 112 2.03509 112H123.965C124.372 112 124.762 111.838 125.05 111.551C125.338 111.263 125.5 110.874 125.5 110.468V43.9255C125.5 43.5193 125.338 43.1298 125.05 42.8426C124.762 42.5553 124.372 42.3938 123.965 42.3933ZM125.061 110.468C125.061 110.758 124.946 111.036 124.74 111.242C124.535 111.447 124.256 111.562 123.965 111.562H2.03509C1.74432 111.562 1.46549 111.447 1.25988 111.242C1.05427 111.036 0.938713 110.758 0.938596 110.468V43.9255C0.938994 43.6425 1.04898 43.3706 1.24557 43.1666C1.44215 42.9627 1.71011 42.8424 1.99343 42.8311L27.9123 53.5654L63.2808 68.2135C63.6636 68.3689 64.0926 68.3666 64.4737 68.2069L98.7456 53.5982L124.009 42.8311C124.292 42.8433 124.559 42.9638 124.755 43.1677C124.951 43.3715 125.061 43.643 125.061 43.9255V110.468Z" fill="#3F3D56"/>
                <path d="M63.514 54.9733C62.2058 54.9757 60.9322 54.5535 59.8855 53.7703L59.8206 53.7216L46.1559 43.279C45.5231 42.795 44.9919 42.1913 44.5928 41.5025C44.1937 40.8136 43.9345 40.053 43.8298 39.2642C43.7252 38.4753 43.7773 37.6736 43.9831 36.9048C44.1889 36.136 44.5444 35.4152 45.0293 34.7835C45.5141 34.1519 46.1189 33.6217 46.8091 33.2234C47.4992 32.825 48.2612 32.5662 49.0516 32.4618C49.8419 32.3574 50.6451 32.4093 51.4154 32.6148C52.1856 32.8202 52.9077 33.175 53.5406 33.659L62.3915 40.4331L83.3078 13.2062C83.7929 12.5747 84.3978 12.0448 85.0881 11.6466C85.7784 11.2485 86.5405 10.99 87.3309 10.8859C88.1213 10.7817 88.9245 10.834 89.6946 11.0397C90.4648 11.2454 91.1868 11.6005 91.8194 12.0847L91.6894 12.261L91.8228 12.0873C93.099 13.0663 93.934 14.5106 94.1446 16.1033C94.3553 17.696 93.9243 19.3071 92.9464 20.5831L68.3439 52.6085C67.7749 53.3465 67.0432 53.9437 66.2056 54.3538C65.368 54.7639 64.4469 54.9759 63.514 54.9733Z" fill="#16407A"/>
                </g>
                <defs>
                <clipPath id="clip0_1017_28415">
                <rect width="125" height="112" fill="white" transform="translate(0.5)"/>
                </clipPath>
                </defs>
                </svg>
            </div>
            <div class="display">
                <span class="text-center font-18 mt-2 font-weight-bold color-black mt-5">
              Request sent!
            </span>
            <span class="text-center font-18 mt-2 text-gray mt-5">
              We sent your request to the account administrator for approval.
            </span>
            <span class="text-center font-18 mt-2 text-gray mt-5 mb-2">
              We’ll email you with the response.
            </span>
            </div>
            <div class="text-center text--white mt-6">
              <v-btn
                     color="#16407A"
                     class="submit-btn"
                      @click="$router.push('/new-login')"
                    >
                     Done
                    </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model.trim="reasonDialog" width="800" class="success-modal">
        <v-card>
          <v-card-text class="success-modal pt-5">
            <span align="end" class="pt-0 pr-0">
              <p class="font-weight-bold" @click="reasonDialog=false">X</p>
            </span>
            <div class="text-center">
              <h2 class="mb-6 color-black">
                Okay. No Worries,
              </h2>
              <p class="font-18">
                Can you tell us what is scaring you away?
              </p>
            </div>
            <div class="display pl-40">
            <span class="font d-flex">
              <v-checkbox label="That's not my organization" 
                          value="That's not my organization" 
                          v-model="checked">
                        </v-checkbox>
            </span>
            <span class="font pt-0 d-flex">
              <v-checkbox  label="Reason 2 "
                           value="Reason 2 "
                           v-model="checked">
            </v-checkbox>
            </span>
            <span class="font d-flex">
              <v-checkbox  label="Reason 3"
                           value="Reason 3 "
                           v-model="checked">
               </v-checkbox>
            </span>
            <span class="mb-2 display">
              <p align="start" class="color-black">Share your own reasons</p>
              <textarea rows="4" class="desc" v-model="description"></textarea>
            </span>
            </div>
            <div class="text-center text--white mt-6">
              <v-btn
                     color="#16407A"
                     class="submit-btn"
                      @click="reason()"
                    >
                     Submit
                    </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
          <successModal ref="success_modal" 
          v-on:confirm="closeSuccessModal">
        </successModal>

      <errorModal 
        ref="error_modal"
         v-on:confirm="closeErrorModal">
        </errorModal>
    </div>
  </template>
  <script>
  import api from "../../services/api";
  import Vue from "vue";
  import { mapActions } from "vuex";
  import {mapGetters} from "vuex";
  import errorModal from "../TablesTemplate/ErrorDialog.vue";
  import successModal from "../TablesTemplate/SuccessDialog.vue";
  export default {
    components: { errorModal, successModal},
    data() {
      return {
        requestDialog: false,
        reasonDialog: false,
        valid: false,
        orgId: "",
        accessToken: "",
        email: "",
        orgName: "",
        multiple: "",
        description: "",
        checked: "",

      };
    },
    computed: {
      organization() {
      return this.$store.getters.getOrg;
    },
    },
    created() {
      this.orgId = this.organization.organizationId;
      this.orgName = this.organization.orgName;
      this.accessToken = this.organization.accessToken;
      this.email = this.organization.email;
      this.getInfo();
    },
    methods: {
      getInfo(){
        let headers = {'x-access-token':this.accessToken};
        api()
        .get(`/auth/organization/${this.orgId}/membership`, {'headers':  headers })
        .catch((err) => {
                    if (err) {
                      this.$refs.error_modal.openPopUp(err)
                    }
                  })
        .then((res) => {
          console.log('res',res)
        });
      },
      ...mapActions(["loginUser"]),
      eventHandling(event) {
        if (event.keyCode == 13) {
          this.submit();
        }
      },
      openReasons(){
        this.reasonDialog = true;
      },
      reason(){
        let feedback = {
          feedback : this.checked ? this.checked : this.description
        }
        let headers = {'x-access-token':this.accessToken};
        api()
        .post("/auth/failed/onboarding/feedback", feedback, {'headers': headers })
        .then((res) => {
          if(res){
            this.$refs.success_modal.openPopUp("Your feedback was successfully sent!");

          }
      
        })
        .catch((err) => {
                    if (err) {
                      this.$refs.error_modal.openPopUp(err)
                    }
                  });
        
        this.reasonDialog = false;
      },
      request(){
        
        this.requestDialog = true;
      },
      closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
      this.$router.push('/new-login')
    },
    },
  };
  </script>
  <style scoped>


.apaly-color{
  color: #16407A;
}
.display{
  display: grid;
}
.yes-btn{
  color: white;
}
.submit-btn{
  color: white;
  width: 150px;
}
.org{
  margin-top: 20vh;
}
.no-btn{
  width: 170px;
  border: 1px solid #16407A;
}
.color-black{
  color: black;
}
.desc{
  border: 1px solid rgba(0, 0, 0, 0.25);
}
</style>
  