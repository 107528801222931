
import RouterView from '../components/TablesTemplate/RouterView.vue'
import ClearingHouse from '../components/Sections/Tests/TPA/ClearingHouse.vue'
import Clients from '../components/Sections/Tests/TPA/Clients/Clients.vue'
import Members from '../components/Sections/Tests/TPA/Members/Members.vue'
import Admins from '../components/Sections/Tests/TPA/Admins/Admin.vue'
import Plans from '../components/Sections/Tests/TPA/HealthPlans/Plans.vue'


const router = [
    
            {
                path:'/clearing-house',
                name:'Home',
                component:ClearingHouse,
               
            },
            {
                path:'/clearing-house/clients',
                name:'Clients',
                component:Clients,
               
            },
            {
                path:'/clearing-house/members',
                name:'Members',
                component:Members,
               
            },
              {
                path:'/clearing-house/plans',
                name:'Plans',
                component:Plans,
               
            },
            {
                path:'/clearing-house/tpa',
                name:'Amdins',
                component:Admins,
               
            }

        ]




export default router;