<template>
  <div class="row-height">
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <exitsDialog
      ref="exists_modal"
      v-on:confirm="closeExistsModal"
    ></exitsDialog>
    <errorDialog ref="error_modal" v-on:confirm="closeErrorModal"></errorDialog>
    <v-row class="mb-4 mt-3">
      <v-col class="col-md-4 col-lg-4 col-sm-5" cols="12">
        <h3>
          <span v-if="$role != 'user'" class="ml-3">
            Add Providers - Search Results
          </span>
          <span v-else class="underline-text ml-3"> Search Results </span>
        </h3>
      </v-col>
      <v-col class="col-lg-3 col-md-6" cols="12">
        <v-text-field
          class="input-control"
          prepend-inner-icon="mdi-magnify"
          @input="searchProviders"
          light
          dense
          outlined
          placeholder="Search Providers"
        >
        </v-text-field>
      </v-col>
      <v-col class="col-xl-5 col-lg-5 col-md-3 text-gray text-end" cols="12">
        <span class="mr-3">
          Number of Searched Providers:
          <span class="green--text font-weight-bold">{{ items.length }}</span>
        </span>
        <br />
        <span class="mr-3" v-if="showButton && items.length > 1">
          Click the
          <span class="blue--text"> Load More Data </span>
          button to get more results
        </span>
      </v-col>
    </v-row>
    <v-row v-if="loader" class="justify-center col-md-12">
      <v-progress-circular
        :size="200"
        class="text-center"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-row>
    <v-simple-table v-else fixed-header height="630px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Group Name</th>
            <th class="text-left">Hospital</th>
            <th class="text-left">Specialty / Taxonomy</th>
            <th class="text-left">City</th>
            <th class="text-left">Relationship</th>
            <th class="no-padding text-end justify-end" v-if="$role != 'user'">
              <div class="col-md-12 justify-end d-flex text-end">
                <v-checkbox
                  v-model="allRows"
                  class="no-padding mt-3"
                  @click="selectAllRows1()"
                ></v-checkbox>
                <label for="" style="padding-top: 11px">Select all</label>
              </div>
            </th>
          </tr>
        </thead>
        <tbody v-if="items.length > 0">
          <tr
            v-for="(item, index) in items"
            :key="index"
            :class="selectAll ? 'activeItem' : ''"
            :id="'id' + index"
          >
            <td
              class="text-gray"
              v-if="item['Provider Organization Name (Legal Business Name)']"
            >
              <a @click="singleProvider(item)">{{
                item["Provider Organization Name (Legal Business Name)"]
              }}</a>
            </td>
            <td
              class="text-gray"
              v-else-if="
                item['Provider Last Name (Legal Name)'] ||
                item['Provider First Name']
              "
            >
              <a @click="singleProvider(item)">
                {{ item["Provider First Name"] }}
                {{ item["Provider Last Name (Legal Name)"] }}</a
              >
            </td>
            <td v-else>/</td>
            <td
              class="text-gray"
              v-html="
                item['Entity Type Code']
                  ? 'Type' + item['Entity Type Code']
                  : '/'
              "
            >
              Type {{ item["Entity Type Code"] }}
            </td>
            <td
              v-html="
                item['Healthcare Provider Taxonomy Code_1']
                  ? item['Healthcare Provider Taxonomy Code_1']
                  : '/'
              "
            >
              {{ item["Healthcare Provider Taxonomy Code_1"] }}
            </td>
            <td
              class="text-gray"
              v-html="
                item['Provider Business Practice Location Address City Name']
                  ? item[
                      'Provider Business Practice Location Address City Name'
                    ]
                  : '/'
              "
            ></td>
            <td
              class="text-gray"
              v-html="item.relationship ? item.relationship : 'Not Contracted'"
            ></td>
            <td
              v-if="$role != 'user'"
              class="text-center mt-3 row no-padding justify-end"
              style="padding-right: 90px"
            >
              <v-checkbox
                class="stay-blue no-padding"
                v-model="item.checkbox"
                @click="activeRow1(index, item)"
              ></v-checkbox>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr class="text-gray">
            <td>No Result Found. Please Try Again</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-row class="text-end justify-end mt-5 mr-3 mb-5" v-if="items.length > 0">
      <v-progress-linear
        v-if="loadData"
        color="blue"
        indeterminate
        reverse
      ></v-progress-linear>
      <v-btn
        v-if="$role != 'user'"
        :disabled="loadData"
        class="blue-buttons mt-5 mb-2 mr-5"
        @click="dialog = true"
      >
        Add All
      </v-btn>
      <v-btn
        v-if="showButton && items.length > 1"
        :disabled="loadData"
        class="blue-buttons mt-5 mb-2"
        @click="loadMoreData()"
      >
        Load More Data
      </v-btn>

      <v-btn
        class="blue-buttons mt-5 ml-5"
        v-if="items.length > 0 && $role != 'user'"
        :disabled="loadData"
        @click="addProviders()"
      >
        Add Selected
      </v-btn>
      <v-btn
        class="blue-buttons ml-5 mr-2 mt-5"
        :disabled="loadData"
        @click="goBack()"
      >
        Cancel
      </v-btn>
    </v-row>
    <v-dialog v-model="dialog" persistent max-width="350">
      <v-card class="text-center">
        <div class="col-md-12 font-weight-bold text-left">
          Number of Searched Providers:
        </div>

        <v-avatar color="primary" size="62">
          <span class="white--text text-h5"> {{ items.length }}</span>
        </v-avatar>
        <br />
        <v-card-text class="text-left mt-4">
          Add All or click the
          <span class="light-blue-text">Load More Data </span>button for more
          result</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="addAll()"> Add All </v-btn>
          <v-btn color="green darken-1" text @click="dialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import api from "../../../../services/api";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import errorDialog from "../../../TablesTemplate/ErrorDialog.vue";
import exitsDialog from "../../../TablesTemplate/ExistDialog.vue";
export default {
  components: {
    successModal,
    errorDialog,
    exitsDialog,
  },
  data() {
    return {
      loadData: false,
      selected: [],
      items: [],
      selectAll: false,
      filteredArray: [],
      selectedRows: [],
      showButton: false,
      filterArray: [],
      page: 1,
      allRows: false,
      dialog: false,
      load: false,
      allProviders: [],
      checkIfSelect: false,
      checkIfClicked: false,
      loader: false,
    };
  },
  created() {
    this.items = this.getProviders;
    this.allProviders = this.getProviders;
    if (this.$role != "user" && this.items.length > 0) {
      this.dialog = true;
    }
    this.filterArray = this.getProviders;
    if (this.items.length % 100 == 0) {
      this.showButton = true;
    } else {
      this.showButton = false;
    }
  },
  computed: {
    getProviders() {
      return this.$store.getters.getSearchProviders;
    },
    urlData() {
      return this.$store.getters.getSearchUrl;
    },
    route() {
      return this.$route.path;
    },
    routeParams() {
      return this.$route.params.name;
    },
    providerType() {
      return this.$store.getters.providerCategory;
    },
  },
  methods: {
    selectAllRows1() {
      this.selectedRows = [];

      if (!this.checkIfSelect) {
        this.selectedRows = [];
        this.checkIfClicked = false;
        this.items.forEach((el) => {
          el.checkbox = false;
        });
      }
      if (this.checkIfSelect) {
        this.items = [];
        this.loader = true;
        this.items = this.allProviders;
        let tmp = JSON.stringify(this.items);
        this.selectedRows = JSON.parse(tmp);
        this.checkIfSelect = false;

        setTimeout(() => {
          this.items.forEach((el) => {
            el.checkbox = true;
          });
          this.loader = false;
        }, 0);

        //  this.$forceUpdate();
      } else if (this.selectAll && !this.checkIfSelect) {
        this.selectAll = false;
      } else {
        this.selectAll = !this.selectAll;
        if (!this.selectAll) {
          this.selectedRows = [];
          this.checkIfClicked = false;
          this.items.forEach((el) => {
            el.checkbox = false;
          });
        } else {
          this.items.forEach((el) => {
            el.checkbox = true;
          });
          this.selectedRows = [];
          let tmp = JSON.stringify(this.items);
          this.selectedRows = JSON.parse(tmp);
        }
      }
    },
    activeRow1(index, item) {
      let itemById = document.getElementById("id" + index);
      if (this.allRows) {
        this.checkIfSelect = true;
        let removeItem = this.selectedRows.find((x) => x._id === item._id);
        this.selectedRows.splice(this.selectedRows.indexOf(removeItem), 1);
        itemById.classList.remove("activeItem");
        this.allRows = false;
        this.checkIfClicked = true;
      } else {
        itemById.classList.toggle("activeItem");
        if (this.checkIfClicked) {
          itemById.classList.remove("activeItem");
        }
        if (item.checkbox) {
          this.selectedRows.push(item);
        } else {
          let removeItem = this.selectedRows.find((x) => x._id === item._id);
          this.selectedRows.splice(this.selectedRows.indexOf(removeItem), 1);
        }
      }
    },
    closeExistsModal() {
      this.$refs.exists_modal.closePopUp();
    },
    goBack() {
      if (this.$route.path.includes("affiliates")) {
        this.$router.push(`/provider/affiliates`);
      } else if (this.$route.path.includes("hospital-based")) {
        this.$router.push(`/provider/hospital-based`);
      } else {
        this.$router.push(`/provider/providers`);
      }
    },
    closeSuccessModal() {
      if (this.$route.path.includes("affiliates")) {
        this.$router.push(`/provider/affiliates`);
      } else if (this.$route.path.includes("hospital-based")) {
        this.$router.push(`/provider/hospital-based`);
      } else {
        this.$router.push(`/provider/providers`);
      }
      this.$refs.success_modal.closePopUp();
    },
    singleProvider(item) {
      if (this.$route.path.includes("affiliates")) {
        this.$router.push(`/provider/affiliates/result/npi`);
      } else if (this.$route.path.includes("hospital-based")) {
        this.$router.push(`/provider/hospital-based/result/npi`);
      } else {
        this.$router.push(`/provider/providers/result/npi`);
      }
      this.$store.commit("setSingleProviderInEmployerAdvisor", item);
    },
    searchProviders(val) {
      this.items = this.filterArray.filter((el) => {
        if (
          el["Provider Last Name (Legal Name)"] &&
          el["Provider Last Name (Legal Name)"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el["Provider Last Name (Legal Name)"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el["Provider First Name"] &&
          el["Provider First Name"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el["Provider First Name"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el["Provider Business Practice Location Address Telephone Number"] &&
          el["Provider Business Practice Location Address Telephone Number"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el[
            "Provider Business Practice Location Address Telephone Number"
          ]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el["Provider Organization Name (Legal Business Name)"] &&
          el["Provider Organization Name (Legal Business Name)"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el["Provider Organization Name (Legal Business Name)"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el["NPI"] &&
          el["NPI"].toString().toLowerCase().includes(val.toLowerCase())
        ) {
          return el["NPI"].toString().toLowerCase().includes(val.toLowerCase());
        } else if (
          el["Healthcare Provider Taxonomy Code_1"] &&
          el["Healthcare Provider Taxonomy Code_1"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el["Healthcare Provider Taxonomy Code_1"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el["Provider First Line Business Mailing Address"] &&
          el["Provider First Line Business Mailing Address"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el["Provider First Line Business Mailing Address"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el["Provider Business Mailing Address Postal Code"] &&
          el["Provider Business Mailing Address Postal Code"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el["Provider Business Mailing Address Postal Code"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el["Provider Business Practice Location Address City Name"] &&
          el["Provider Business Practice Location Address City Name"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el["Provider Business Practice Location Address City Name"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el["Provider Last Name (Legal Name)"] &&
          el["Provider First Name"]
        ) {
          let fullName =
            el["Provider First Name"] +
            " " +
            el["Provider Last Name (Legal Name)"];
          let reversefullName =
            el["Provider Last Name (Legal Name)"] +
            " " +
            el["Provider First Name"];
          if (fullName.toString().toLowerCase().includes(val.toLowerCase())) {
            return el;
          } else if (
            reversefullName.toString().toLowerCase().includes(val.toLowerCase())
          ) {
            return el;
          }
        }
      });
    },
    loadMoreData() {
      this.loadData = true;
      this.page++;
      api()
        .get(`/api/npidata/search?${this.urlData}page=${this.page}&limit=100`)
        .then((res) => {
          if (res) {
            if (res.data.items.length < 100) {
              this.showButton = false;
            }
            res.data.items.forEach((el) => {
              this.items.push(el);
            });
            this.allRows = false;
            this.filteredArray = this.items;
            this.loadData = false;
            this.allProviders = this.items;
            this.checkIfSelect = true;
          }
        });
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    addAll() {
      this.load = true;
      this.selectedRows = this.items;
      this.addProviders();
    },
    addProviders() {
      if (this.selectedRows.length < 1) {
        let text = "You must  select at least one Provider";
        this.$refs.error_modal.openPopUp(text);
        return;
      }
      this.loadData = true;
      let existAray = [];
      let arr = [];
      this.selectedRows.forEach((el) => {
        if (el._id) {
          arr.push(el._id);
        } else {
          arr.push(el);
        }
      });
      let data = {
        search: "npidata",
        providerCategory: this.providerType,
        ids: arr,
      };
      let user = JSON.parse(localStorage.getItem("user"));
      let provider = user.entity._id;
      let exists = false;
      api()
        .get(`/providers/${provider}/groupProviders`)
        .then((res) => {
          if (res) {
            for (let i = 0; i < res.data.groupProviders.length; i++) {
              for (let j = 0; j < this.selectedRows.length; j++) {
                console.log(
                  res.data.groupProviders[i].NPI,
                  this.selectedRows[j].NPI
                );
                if (
                  res.data.groupProviders[i].NPI === this.selectedRows[j].NPI
                ) {
                  exists = true;
                  existAray.push(this.selectedRows[j].NPI);
                }
              }
            }
          }
        })
        .then((res) => {
          if (exists) {
            this.dialog = false;
            this.load = false;
            let text = "Providers already exist ";
            this.$refs.exists_modal.openPopUp(text, existAray);
            this.loadData = false;
            return;
          } else {
            api()
              .post(`/providers/${provider}/groupProviders`, data)
              .then((res) => {
                let text = "Providers added successfully";
                this.$refs.success_modal.openPopUp(text);
                this.loadData = false;
              });
          }
        });
    },
    selectAllRows() {
      this.selectedRows = [];
      this.selectAll = !this.selectAll;
      if (!this.selectAll) {
        this.selectedRows = [];
      } else {
        let tmp = JSON.stringify(this.items);
        this.selectedRows = JSON.parse(tmp);
      }
    },
    activeRow(index, item) {
      let itemById = document.getElementById("id" + index);
      itemById.classList.toggle("activeItem");
      if (itemById.classList.contains("activeItem")) {
        this.selectedRows.push(item);
      } else {
        let removeItem = this.selectedRows.find((x) => x._id === item._id);
        this.selectedRows.splice(this.selectedRows.indexOf(removeItem), 1);
      }
    },
  },
};
</script>
