<template>
  <div>
    <div class="row row-height" v-if="!isChildren">
      <v-sheet
        class="overflow-hidden col-md-12"
        style="position: relative; border-radius: 5px"
      >
        <div>
          <v-row align="center" justify="center">
            <div
              :class="
                overlay
                  ? 'hideOnMoblie background-opacity col-md-9'
                  : 'col-md-12 navigationWidth no-padding '
              "
            >
              <v-row>
                <v-col class="col-xl-1 col-md-2 cols-4" cols="4">
                  <div class="col-md-12">
                    <h3 class="form-title font col-md-12">Contracts</h3>
                  </div>
                </v-col>
                <v-col class="col-xl-4 col-md-6 cols-8" cols="8">
                  <div class="col-md-8 mt-4 d-flex no-padding">
                    <v-text-field
                      :disabled="overlay"
                      class="input-control mr-5"
                      prepend-inner-icon="mdi-magnify"
                      @input="searchEmp"
                      light
                      dense
                      outlined
                      placeholder="Search Contract"
                    ></v-text-field>
                    <v-btn
                      :disabled="overlay"
                      class="text-gray filter-btn"
                      @click.stop="showFilterSideBar"
                    >
                      <svg
                        width="17"
                        class="mr-2"
                        height="14"
                        viewBox="0 0 17 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 1H16"
                          stroke="#424242"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M2.59766 5H14.403"
                          stroke="#424242"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M6.3125 13H10.6858"
                          stroke="#424242"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M4.19531 9H12.8046"
                          stroke="#424242"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Filters
                    </v-btn>
                  </div>
                </v-col>
                <v-col
                  class="col-xl-7 pr-6 pt-6 col-md-4 cols-12 text-end"
                  cols="12"
                  align="end"
                >
                  <v-menu offset-y>
                    <template v-slot:activator="{}">
                      <v-btn
                        :disabled="overlay"
                        @click="
                          $router.push(
                            '/employer-client/account/contract/add-new'
                          )
                        "
                        class="my-1 blue-buttons"
                      >
                        New Contract</v-btn
                      >
                    </template>
                  </v-menu>
                </v-col>
              </v-row>
              <v-simple-table fixed-header>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Company Name</th>
                      <th class="text-left"># of Employees</th>
                      <th class="text-left"># of Members</th>
                      <th class="text-left">Contract</th>
                    </tr>
                  </thead>
                  <tbody v-if="contracts.length > 0">
                    <tr v-for="(item, index) in contracts" :key="index">
                      <td v-if="item.companyName">
                        <a>{{ item.companyName }}</a>
                      </td>
                      <td v-else><a>/</a></td>
                      <td
                        v-html="item.noEmployees ? item.noEmployees : '/'"
                      ></td>
                      <td
                        v-html="item.noPlanMembers ? item.noPlanMembers : '/'"
                      ></td>

                      <td>
                        <a class="text-underline">Contract to View</a>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else-if="filterItems">
                    <v-col md="12" cols="12">
                      <v-alert
                        border="bottom"
                        colored-border
                        type="warning"
                        elevation="2"
                        class="text-gray"
                      >
                        Sorry! No results found
                      </v-alert>
                    </v-col>
                  </tbody>
                  <tbody v-else>
                    <v-col md="12">
                      You Don't have Contract Yet. Click Here To Add New
                      Contract.
                    </v-col>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-row>
        </div>
        <v-navigation-drawer
          class="userFilterPosition"
          width="350"
          v-model.trim="drawer"
          absolute
          temporary
        >
          <div class="filter-sideBar">
            <div>
              <div class="row no-padding col-md-12 justify-space-between">
                <h3 class="font pl-3 mt-1">Contract Filters</h3>
                <h1 class="pointer mr-3" @click.stop="drawer = !drawer">x</h1>
              </div>
              <v-divider></v-divider>
              <div class="col-md-12">
                <div
                  class="
                    row
                    col-md-12
                    justify-space-between
                    no-padding no-margin
                  "
                >
                  <div
                    class="no-padding font filter-items-font font-weight-bold"
                  >
                    Selected Filter
                  </div>
                  <div
                    class="underline-text pointer text-gray filter-items-font"
                    @click="clearAll"
                  >
                    Clear All
                  </div>
                </div>
              </div>
              <div class="overflow-fitlers-buttons">
                <div class="row mb-1 col-md-12">
                  <v-btn
                    v-for="(item, index) in selectedArray.concat(
                      selectedTypeArray
                    )"
                    :key="index"
                    class="text-center mb-1 ml-2 mr-2 filter-btn"
                    small
                  >
                    <span v-html="item.text"></span>
                    <h5 class="ml-3" @click="removeFilter(item)">x</h5>
                  </v-btn>
                </div>
                <v-divider></v-divider>
                <v-expansion-panels
                  accordion
                  multiple
                  v-model.trim="panel"
                  class="no-shadow no-padding user-expansion-panels"
                >
                  <v-expansion-panel class="no-shadow no-padding">
                    <v-expansion-panel-header
                      class="
                        font-weight-black
                        change-padding-filter
                        filter-items-font
                      "
                      >Status</v-expansion-panel-header
                    >
                    <v-expansion-panel-content
                      class="no-padding content-filter"
                    >
                      <v-text-field
                        solo
                        class="filter-serach-input mb-4"
                        v-model.trim="status"
                        @input="searchStatus()"
                        dense
                        :hide-details="true"
                        placeholder="Search"
                        append-icon="fa fa-search"
                      ></v-text-field>
                      <v-checkbox
                        label="All"
                        color="primary"
                        v-if="showStatus.all"
                        v-model.trim="filter.status.all"
                        class="no-padding small-text radio-buttons-filters"
                        hide-details
                        :on-icon="'mdi-square'"
                        @click="
                          pushFilter(
                            { text: 'All', value: 'all' },
                            filter.status.all
                          )
                        "
                      >
                      </v-checkbox>
                      <v-checkbox
                        label="Join Request"
                        color="primary"
                        v-if="showStatus['join request']"
                        v-model.trim="filter.status.join"
                        class="no-padding radio-buttons-filters"
                        hide-details
                        :on-icon="'mdi-square'"
                        @click="
                          pushFilter(
                            { text: 'Join Request', value: 'join' },
                            filter.status.join
                          )
                        "
                      >
                      </v-checkbox>
                      <v-checkbox
                        label="Active"
                        color="primary"
                        v-if="showStatus.active"
                        :on-icon="'mdi-square'"
                        v-model.trim="filter.status.active"
                        class="no-padding radio-buttons-filters"
                        hide-details
                        @click="
                          pushFilter(
                            { text: 'Active', value: 'active' },
                            filter.status.active
                          )
                        "
                      >
                      </v-checkbox>
                      <v-checkbox
                        label="In Active"
                        color="primary"
                        v-if="showStatus['in active']"
                        v-model.trim="filter.status.inactive"
                        :on-icon="'mdi-square'"
                        class="no-padding radio-buttons-filters mb-2"
                        hide-details
                        @click="
                          pushFilter(
                            { text: 'In Active', value: 'inactive' },
                            filter.status.inactive
                          )
                        "
                      >
                      </v-checkbox>
                      <!-- <v-checkbox
                        label="Invited"
                        color="primary"
                        v-if="showStatus.invited"
                        :on-icon="'mdi-square'"
                        v-model.trim="filter.status.invited"
                        class="no-padding radio-buttons-filters mb-2"
                        hide-details
                        @click="
                          pushFilter(
                            { text: 'Invited', value: 'invited' },
                            filter.status.invited
                          )
                        "
                      >
                      </v-checkbox> -->
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel class="no-shadow no-padding">
                    <v-expansion-panel-header
                      class="
                        font-weight-black
                        change-padding-filter
                        filter-items-font
                        mb-0
                        pb-0
                      "
                      >Type</v-expansion-panel-header
                    >
                    <v-expansion-panel-content
                      class="no-padding content-filter"
                    >
                      <v-text-field
                        solo
                        class="filter-serach-input no-padding mb-4"
                        v-model.trim="type"
                        dense
                        :hide-details="true"
                        @input="searchType()"
                        placeholder="Search"
                        append-icon="fa fa-search"
                      ></v-text-field>
                      <v-checkbox
                        label="All"
                        color="primary"
                        v-if="showType.all"
                        :on-icon="'mdi-square'"
                        v-model.trim="filter.type.all"
                        class="no-padding radio-buttons-filters"
                        hide-details
                        @click="
                          pushtoTypeFilter(
                            { text: 'All', value: 'allTypes' },
                            filter.type.all
                          )
                        "
                      >
                      </v-checkbox>
                      <v-checkbox
                        label="Admins"
                        color="primary"
                        v-if="showType.admin"
                        v-model.trim="filter.type.admin"
                        :on-icon="'mdi-square'"
                        class="no-padding radio-buttons-filters"
                        hide-details
                        @click="
                          pushtoTypeFilter(
                            { text: 'Admins', value: 'admin' },
                            filter.type.admin
                          )
                        "
                      >
                      </v-checkbox>
                      <v-checkbox
                        label="General User"
                        color="primary"
                        :on-icon="'mdi-square'"
                        v-if="showType['general user']"
                        v-model.trim="filter.type.user"
                        class="no-padding radio-buttons-filters"
                        hide-details
                        @click="
                          pushtoTypeFilter(
                            { text: 'General User', value: 'user' },
                            filter.type.user
                          )
                        "
                      >
                      </v-checkbox>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </div>
            <div>
              <v-divider></v-divider>
              <v-btn class="blue-buttons col-md-12 mt-3" @click="filterUsers"
                >View Result</v-btn
              >
            </div>
          </div>
        </v-navigation-drawer>
      </v-sheet>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import api from "../../../../../services/api";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      items: [],
      contracts: [
        {
          companyName: "Catholic Charities - St Pete",
          noEmployees: 2,
          providerCoverage: 2,
          projectedCharges: 3,
        },
        {
          companyName: "Catholic Charities - St Pete",
          noEmployees: 2,
          providerCoverage: 2,
          projectedCharges: 3,
        },
        {
          companyName: "Catholic Charities - St Pete",
          noEmployees: 2,
          providerCoverage: 2,
          projectedCharges: 3,
        },
      ],
      response: [],
      resultsReady: true,
      drawer: null,
      showStatus: {
        all: true,
        active: true,
        "in active": true,
        invited: true,
        "join request": true,
      },
      showType: {
        all: true,
        "general user": true,
        admin: true,
      },
      type: "",
      status: "",
      panel: [0, 1],
      userStatus: [
        {
          text: "ALL",
          value: "all",
        },

        {
          text: "Join Request",
          value: "join",
        },
        {
          text: "Active",
          value: "active",
        },
        {
          text: "In Active",
          value: "inactive",
        },
        {
          text: "Invited",
          value: "invited",
        },
      ],
      userType: [
        {
          text: "ALL",
          value: "all",
        },
        {
          text: "Admins",
          value: "admins",
        },
        {
          text: "General User",
          value: "users",
        },
      ],
      overlay: false,
      selectedArray: [],
      selectedTypeArray: [],
      filter: {
        status: {
          all: false,
          active: false,
          inactive: false,
          invited: false,
          join: false,
        },
        type: {
          all: false,
          user: false,
          admin: false,
        },
      },
    };
  },
    watch: {
    showRoutePathForFilter: {
      handler(newValue, oldValue) {
        this.drawer = this.showRoutePathForFilter;
      },
      deep: true,
    },
    drawer: {
      handler(newValue, oldValue) {
        this.$store.commit("setNavItemFilter", this.drawer);
      },
      deep: true,
    },
  },
  created() {
    this.filteredArray = this.contracts;
  },
  //   created() {
  //     this.getAllEmployers();
  //   },
  computed: {
    ...mapGetters(["showRoutePathForFilter"]),
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
  },
  methods: {
    commitFilterNav() {
      if (this.drawer) {
        this.$store.commit("setNavItemFilter", false);
      }
    },
    showFilterSideBar() {
      this.drawer = !this.drawer;

      if (this.drawer) {
        this.$store.commit("setNavItemFilter", this.drawer);
      } else {
        this.$store.commit("setNavItemFilter", false);
      }
    },
    pushtoTypeFilter(item, checked) {
      if (checked) {
        if (item.value == "allTypes") {
          this.selectedTypeArray = [];
          this.filter.type = {
            all: true,
            user: true,
            admin: true,
          };
          this.selectedTypeArray.push({ text: "All", value: "allTypes" });
        } else {
          this.selectedTypeArray.push(item);
        }
      } else {
        if (item.value == "allTypes" || this.filter.type.all) {
          this.filter.type = {
            all: false,
            user: false,
            admin: false,
          };
          this.selectedTypeArray = [];
        } else {
          this.selectedTypeArray.forEach((el, index) => {
            if (el.value == item.value) {
              this.selectedTypeArray.splice(index, 1);
            }
          });
        }
      }
    },
    pushFilter(item, checked) {
      if (checked) {
        if (item.value == "all") {
          this.selectedArray = [];
          this.filter.status = {
            all: true,
            active: true,
            inactive: true,
            invited: true,
            join: true,
          };
          this.selectedArray.push({ text: "All", value: "all" });
        } else {
          this.selectedArray.push(item);
        }
      } else {
        if (item.value == "all" || this.filter.status.all) {
          this.filter.status = {
            all: false,
            active: false,
            inactive: false,
            invited: false,
            join: false,
          };
          this.selectedArray = [];
        } else {
          this.selectedArray.forEach((el, index) => {
            if (el.value == item.value) {
              this.selectedArray.splice(index, 1);
            }
          });
        }
      }
    },
    removeFilter(item) {
      if (item.value == "allTypes") {
        this.selectedTypeArray = [];
        this.filter.type = {
          all: false,
          user: false,
          admin: false,
        };
      } else if (this.filter.status[item.value]) {
        this.filter.status[item.value] = false;
        this.selectedArray.splice(this.selectedArray.indexOf(item), 1);
      }

      if (item.value == "all") {
        this.selectedArray = [];
        this.filter.status = {
          all: false,
          active: false,
          inactive: false,
          invited: false,
          join: false,
        };
      } else if (this.filter.type[item.value]) {
        this.filter.type[item.value] = false;
        this.selectedTypeArray.splice(this.selectedTypeArray.indexOf(item), 1);
      }
    },
    searchStatus() {
      for (let key in this.showStatus) {
        if (key.toLowerCase().includes(this.status.toLowerCase())) {
          this.showStatus[key] = true;
        } else {
          this.showStatus[key] = false;
        }
      }
    },
    searchType() {
      for (let key in this.showType) {
        if (key.toLowerCase().includes(this.type.toLowerCase())) {
          this.showType[key] = true;
        } else {
          this.showType[key] = false;
        }
      }
    },
    filterUsers() {
      if (!this.filter.status && !this.filter.type) {
        this.getAllInvoices("all");
      } else {
        this.getAllInvoices(this.filter.status);
      }
      this.filterUse = true;
      this.drawer = false;
    },
    clearAll() {
      this.filter.status = {
        all: false,
        active: false,
        inactive: false,
        invited: false,
        join: false,
      };
      this.filter.type = {
        all: false,
        user: false,
        admin: false,
      };

      this.selectedArray = [];
      this.selectedTypeArray = [];
      this.getAllInvoices("all");
    },
    searchEmp(val) {
      this.contracts = this.filteredArray.filter((el) => {
        if (el.companyName.toLowerCase().includes(val.toLowerCase())) {
          return el.companyName.toLowerCase().includes(val.toLowerCase());
        }
      });
      if (this.contracts.length < 1) {
        this.filterItems = true;
      }
    },
    singleEmp(item) {
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.employerAdvisor;

      api()
        .get(`/advisors/${advisorId}/employers/${item._id}`)
        .then((res) => {
          if (res) {
            api()
              .get(`/advisors/${advisorId}/employers/${item._id}/statistics`)
              .then((res) => {
                this.$store.commit(
                  "setStatisticsInfoHorizontalMenu",
                  res.data.statistics
                );
              });
            this.$store.commit("setSingleEmployer", res.data);
            localStorage.setItem("employer", JSON.stringify(item));
            this.$router.push("/employer-advisor/employers/employer");
            // this.$router.push({path:'/employer-advisor/employers/'+ item.companyName, params:{name:item.companyName}})
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    searchInvoices() {},
    getAllInvoices() {
      this.allEmployers = [];
      //   let user = JSON.parse(localStorage.getItem("user"));
      //   let advisorId = user.groupPortal[0].organization.employerAdvisor;
      //   api()
      //     .get(`/advisors/${advisorId}/employers`)
      //     .then((res) => {
      //       this.allEmployers = res.data.employers;
      //       this.response = res.data.employers;
      //       this.resultsReady = true;
      //     })
      //     .catch((err) => {
      //       console.log(err.response);
      //     });
    },
  },
};
</script>
<style>
.user-expansion-panels
  > .v-expansion-panel--active
  > .v-expansion-panel-header {
  min-height: 40px !important;
}
</style>