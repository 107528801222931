const state ={
    member:null
}
const getters = {
    getSingleMemberInEmp(){
        return state.member
    }
}
const mutations = {
    setSingleMemberEmployers(state,member){
        state.member = member
    }
}

export default{
    state,
    getters,
    mutations,
}