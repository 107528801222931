<template>
    <div class="no-padding no-margin">
        <AdvisorsTable v-if="path == `/provider-advisor/providers/${routeParams}/advisors`"></AdvisorsTable>
        <AddAdvisor v-if="path == `/provider-advisor/providers/${routeParams}/advisors/add-new`"></AddAdvisor>
        <InviteAdvisor v-if="path == `/provider-advisor/providers/${routeParams}/advisors/invite-advisor`"></InviteAdvisor>
        <Advisor v-if="path == `/provider-advisor/providers/${routeParams}/advisors/advisor`"></Advisor>
        <Result v-if="path == `/provider-advisor/providers/${routeParams}/advisors/result`"></Result>
    </div>
    
</template>
<script>
import AddAdvisor from './AddAdvisor.vue'
import InviteAdvisor from './InviteAdvisor.vue'
import Advisor from './Advisor.vue'
import AdvisorsTable from './AdvisorsTable.vue'
import Result from './Result.vue'

export default {
    components:{
    AdvisorsTable,
    AddAdvisor,
    InviteAdvisor,
    Advisor,
    Result
    },
    data(){
        return{
        }
    },
    computed:{
        path(){
              return this.$route.path.replace(/%20/g, ' ')
        },
        routeParams(){
           return this.$route.params.name
       }
    }
   
}
</script>