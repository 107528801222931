<template>
<div class="no-padding col-md-12 row-height font-bold">
  <v-row class=" col-md-12">
    <v-col class="col-md-8" md="4" cols="12">
      <h3>
         <span class="font"> VNets</span>
      </h3>
    </v-col>
  </v-row>      
  <div class="col-md-12">
    <div class="mt-7 text-underline  ml-3"> PENDING FEATURE</div> 
    <div class="mt-3  col-md-12"> 
      VNets (short for Virtual Networks), will enable you search a marketplace of VNets that are available on the platform, and access multiple providers through a single VNet relationship.
      <br><br>
      Instead of you having to individually determine which providers deliver the best value and quality, you can leave this to the experts who have created VNets for this purpose.
      <br><br>
      You’ll be able to access multiple VNets simultaneously, to access the best providers to deliver care to your plan members.
   </div>

  </div>
  
</div>

    
</template>
<script>

  export default {
    data () {
      return {
        items: [
          {
            name: 'Tampa General Hospital',
            contact: 'James Reilly',
            type: 'Multi Speciality',
            providers:'123',
            rating:3,
            action:''
          },
          {
            name: 'National Ortha HVN',
            contact: 'James Reilly',
            type: 'Single Speciality',
            providers:'223',
            rating:5,
            action:''
          },
          {
            name: 'National Cardiac Network',
            contact: 'Jeff Hayes',
            type: 'Single Speciality',
            providers:'212',
            rating:3,
            action:''
          },
          {
            name: 'Florida HVN',
            contact: 'Scot Pruzan',
            type: 'Multi Speciality',
            providers:'88',
            rating:4,
            action:''
          },
          {
            name: 'Northeast HVN',
            contact: 'Beth Schultz',
            type: 'Multi Speciality',
            providers:'195',
            rating:5,
            action:''
          },

     
        ],
      }
    },
    computed:{
      getClient(){
          return this.$store.getters.getClientGetter
       },
        routeParams(){
            return this.$route.params.name
        }
    },
    methods:{
      singleProvider(item){
        this.$store.commit('setSingleNetwork', item);
        this.$emit('singleNetworkTemp');
      }
    }
  }
</script>