<template>
<div>
  <v-row class="mb-4 mt-3">
    <v-col class="col-md-7 col-lg-8 col-sm-5" cols="12">
        <h3 class="font">
           <span class="underline-text" v-if="getClient">{{getClient.name}}:</span> Add New Advisor- Search Results 
        </h3>
    </v-col>
 
  </v-row>      
 <v-simple-table fixed-header  height="630px">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
           Partner Name
          </th>
          <th class="text-left">
           Individual Name
          </th>
          <th class="text-left">
           Type
          </th>
          <th class="text-left">
           City
          </th>
          <th class="text-left">
          State
          </th>
          <th class="row">
               <v-checkbox></v-checkbox>
              <span class="mt-6">
                  All
              </span>  
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in items"
          :key="item.name"
        >
          <td><a >{{ item.organization }}</a> </td>
          <td>{{item.name}}</td>
          <td>{{ item.type }}</td>
          <td>{{ item.city }}</td>
          <td>{{ item.state }}</td>
          <td>
            <v-checkbox  @click="selected.push(item)"></v-checkbox>
          </td>
        
        </tr>
      </tbody>
    </template>
  </v-simple-table>
  <div class="col-md-12 row mt-5 justify-end">
     <v-btn class="blue-buttons mr-10" @click="addAdvisor()" >
                Cancel
         </v-btn>
         <v-btn class="blue-buttons"  v-if="items.length > 0"  @click="addAdvisor()">
                 Add Selected
         </v-btn>
  </div>
</div>

    
</template>
<script>
  export default {
    data () {
      return {
        selected:[],
       items: [
          {
            name: 'Brian McNulty',
            npi: '123456789',
            type: 'Broker Cosultant',
            organization:'USI',
            city:'Tampa',
            state:'Florida',
            averageCharges: '280%',
            averageSavings:'35%',
            action:''
          },
          {
            name: 'Michelle Bounce',
            npi: '123456789',
            type: 'TPA',
            organization:'JP Farley',
            city:'Tampa',
            state:'Florida',
            averageCharges: '280%',
            averageSavings:'35%',
            action:''
          },
          {
            name: 'Don Ross',
            npi: '123456789',
            type: 'Analystics Cosultant',
            city:'Tampa',
            organization:'Med-Vision',
            state:'Florida',
            averageCharges: '280%',
            averageSavings:'35%',
            action:''
          },

        ],
      }
    },
   computed:{
        getClient(){
            return this.$store.getters.getClientGetter
        }
    },
    methods:{
       addAdvisor(){
       }
    }
  }
</script>
<style>
.buttons-left{
  justify-content: left;
  align-content: left !important;
  align-items: left !important;
}
</style>