import FamilyMembers from '../views/FamilyMembers'
import Home from '../components/Sections/FamilyMembers/Home'
import OtherFamily from '../components/Sections/FamilyMembers/OtherFamily/OtherFamily.vue'
import FindCare from '../components/Sections/FamilyMembers/FindCare/FindCare.vue'
import CareDetails from '../components/Sections/FamilyMembers/FindCare/Details.vue'
import Contact from '../components/Sections/FamilyMembers/ContactUs/Contact.vue'
import Family from '../components/Sections/FamilyMembers/Family/Members.vue'
import AddNewFamily from '../components/Sections/FamilyMembers/Family/AddNew.vue'
import FamilyDetails from '../components/Sections/FamilyMembers/Family/Detail.vue'
import VisitPasses from '../components/Sections/FamilyMembers/VisitPasses/VisitPasses.vue'
import Account from '../components/Sections/FamilyMembers/Account/Account.vue'
import AccountPersonal from '../components/Sections/FamilyMembers/Account/Personal.vue'
import AccountSettings from '../components/Sections/FamilyMembers/Account/Settings.vue'
import AccountPayment from '../components/Sections/FamilyMembers/Account/Payment.vue'
import RouterView from '../components/TablesTemplate/RouterView.vue'



const router = [
    {
        path:'/family-members',
        name:'',
        redirect: () => {
            return { path: '/family-members/home'} 
        },
    },
    {
        component:RouterView,
        path:'/family-members',
        name:'',
        redirect: () => {
            return { path: '/family-members'} 
        }, 
        children:[
             {
                    path:'/family-members',
                    name:'',
                    component:FamilyMembers,
                    children:[
                           {
                                path:'home',
                                name:'Home',
                                component:Home,
                            },
                            {
                                component:RouterView,
                                path:'/family-members/care',
                                name:'',
                                redirect: () => {
                                    return { path: '/family-members/care'} 
                                }, 
                                children:[
                                 {
                                    path:'/family-members/care',
                                    name:'Find Care',
                                    component:FindCare,
                                    children:[
                                        {
                                            path:'details',
                                            name:'Provider detail',
                                            component:CareDetails,
                                            meta:{
                                                isChildren:true
                                            }
                                        }
                                    ]
                                  }
                                ]
                        
                                
                            },
                            {
                                path:'contact',
                                name:'Contact us',
                                component:Contact,
                            },
                            {
                                component:RouterView,
                                path:'/family-members/family',
                                name:'',
                                redirect: () => {
                                    return { path: '/family-members/family'} 
                                }, 
                                children:[
                                    {
                                        path:'/family-members/family',
                                        name:'Family Members',
                                        component:Family,
                                        children:[
                                            {
                                                path:'add-new',
                                                name:'Family Member New',
                                                component:AddNewFamily,
                                                meta:{
                                                    isChildren:true
                                                }
                                            },
                                            {
                                                path:'details',
                                                name:'Family Member Detail',
                                                component:FamilyDetails,
                                                meta:{
                                                    isChildren:true
                                                }
                                            },

                                        ]
                                    }
                                ]
                            },
                            {
                                path:'visit-passes',
                                name:'Visit Passes',
                                component:VisitPasses,
                            },
                            {
                                component:RouterView,
                                path:'/family-members/account',
                                name:'',
                                redirect: () => {
                                    return { path: '/family-members/account'} 
                                }, 
                                children:[
                                    {
                                        path:'/family-members/account',
                                        name:'Account',
                                        component:Account,
                                        children:[
                                            {
                                                path:'info',
                                                name:'Personal Information',
                                                component:AccountPersonal,
                                                meta:{
                                                    isChildren:true
                                                }
                                               
                                            },
                                            {
                                                path:'payment',
                                                name:'Billing & payment',
                                                component:AccountPayment,
                                                meta:{
                                                    isChildren:true
                                                }
                                            },
                                            {
                                                path:'settings',
                                                name:'Settings',
                                                component:AccountSettings,
                                                meta:{
                                                    isChildren:true
                                                }
                                               
                                            }
                                        ]
                                    }
                                ]
                            },
                        ]
                     }
                
           ]    

    }

]

export default router;


    
