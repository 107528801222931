import PlanSponsor from '../views/PlanSponsor.vue' 

const router  = [
    {
        name:'Plan Sponsor',
        path:'/plan-sponsor',
        component:PlanSponsor

    }
]
export default router;