<template>
    <div class="container-fluid">
      <successModal
        ref="success_modal"
        v-on:confirm="closeSuccessModal"
      ></successModal>
      <v-row class="main-row">
        <v-col lg="4" md="12" sm="12">
          <BlueSideBar :tab="tab"></BlueSideBar>
        </v-col>
        <v-col lg="8" md="12" sm="12">
  
          <div
          v-if="this.$route.path == `/auth/organization/${orgId}/membership`"
        >
          <Org></Org>
        </div>
          <div
            v-else-if="
              this.$route.query &&
              typeof this.$route.query.resetPassword != 'undefined' &&
              this.$route.query.resetPassword == null
            "
          >
            <ResetPass></ResetPass>
          </div>
          <div v-else class="stepper">
            <RegisterComp></RegisterComp>
          </div>
        </v-col>
      </v-row>
      <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
    </div>
  </template>
  
  <script>
import api from "../services/api";
import successModal from "../components/TablesTemplate/SuccessDialog.vue";
import errorModal from "../components/TablesTemplate/ErrorDialog.vue";

import BlueSideBar from "../components/LoginRegister/BlueSideBar.vue";
import Login from "../components/LoginRegister/Login.vue";
import New from "../components/LoginRegister/NewLoginPage.vue";
import Org from "../components/LoginRegister/OrgExists.vue";
import NewRegister from "../components/LoginRegister/NewRegister.vue";
import ResetPass from "../components/LoginRegister/ResetPassword.vue";
import RegisterComp from "../components/LoginRegister/Register.vue";

  export default {
    components: {
        Org,
      Login,
      New,
      NewRegister,
      BlueSideBar,
      RegisterComp,
      ResetPass,
      successModal,
      errorModal,
    },
    data() {
      return {
        orgId: "",
        login: true,
        valid: false,
        loginOrRegister: false,
        firstname: "",
        lastname: "",
        nameRules: [
          (v) => !!v || "Name is required",
          (v) => v.length <= 10 || "Name must be less than 10 characters",
        ],
        email: "",
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
        ],
        tab: "firstTab",
        desc: "",
        title: "",
      };
    },
    computed: {
      id() {
        return this.$route.params.id;
      },
      organization() {
      return this.$store.getters.getOrg;
    },
    },
    methods: {
      closeErrorModal() {
        this.$refs.error_modal.closePopUp();
         this.$router.replace("/login");
      },
      changeTab(tab) {
        this.tab = tab;
      },
      closeSuccessModal() {
        this.$refs.success_modal.closePopUp();
        this.$router.replace("/login");
      },
    },
    created() {
       this.orgId = this.organization.organizationId;
    },
  };
  </script>
  