<template>
  <v-row class="container-fluid padding justify-center col-md-12">
    <v-app-bar app clipped-left clipped-right class="no-padding">
      <v-toolbar-title
        class="col-lg-1 col-md-1 col-sm-2 col-sm-1 no-padding"
        align="center"
      >
        <img id="logo-img" src="/apaly1.png" class="apaly-pic pointer" />
      </v-toolbar-title>
      <v-divider class="header-divider col-md-1" vertical></v-divider>

      <v-spacer />
      <div class="user-settings">
        <div
          class="font text-right text-bold pointer"
          @click="$router.push('/login')"
        >
          Login
        </div>
        <div class="tiny-font"></div>
      </div>
      <v-menu
        bottom
        left
        offset-y
        origin="top right"
        transition="scale-transition"
      >
        <template v-slot:activator="{}">
          <v-btn dark icon class="mr-1">
            <v-avatar size="40">
              <img
                style="object-fit: cover"
                src="https://www.pngkit.com/png/full/128-1280585_user-icon-fa-fa-user-circle.png"
                alt="John"
              />
            </v-avatar>
          </v-btn>
        </template>
        <v-list>
          <v-divider></v-divider>
        </v-list>
      </v-menu>
    </v-app-bar>
    <div class="col-md-11 row card white-background p-10 border-r-8">
      <div class="row col-md-12  mt-1 mb-3 orange-underline-wizard">
        <h2>
          <span class="text-center justify-center ml-2"> Search Providers</span>
        </h2>
      </div>

      <v-col cols="12" class="pl-4" md="6 border-right">
        <div>
          <div class="row col-md-12" v-if="route.includes('providers')">
            <h4 class="font">Search and Add Providers</h4>
          </div>
          <div class="row col-md-12" v-else>
            <h4 class="font">Search and Add Affilates</h4>
          </div>

          <form class="row">
            <div class="col-md-6 pt-0 pb-0">
              <label class="font text-gray" for="">NPI Number</label>
              <v-text-field
                required
                v-model.trim="npiNumber"
                solo
              ></v-text-field>
            </div>
            <div class="col-md-3  pt-0 pb-0">
              <label class="font text-gray" for="">NPI TYPE </label>
              <v-select
                class="text-gray"
                :items="[
                  { text: 'NPIs Type 1', value: '1' },
                  { text: 'NPIs Type 2', value: '2' },
                ]"
                solo
                v-model.trim="npiType"
              ></v-select>
            </div>
            <div class="col-md-12  pt-0 pb-0">
              <h4>Search for Individuals</h4>
            </div>
            <div class="col-md-6  pt-0 pb-0">
              <label class="font text-gray" for="">First Name</label>
              <v-text-field
                required
                v-model.trim="firstName"
                solo
              ></v-text-field>
            </div>
            <div class="col-md-6  pt-0 pb-0">
              <label class="font text-gray" for="">Last Name</label>
              <v-text-field
                required
                solo
                v-model.trim="lastName"
              ></v-text-field>
            </div>
            <div class="col-md-12  pt-0 pb-0"><h4>OR</h4></div>
            <div class="col-md-12  pt-0 pb-0"><h4>Search for Organization</h4></div>
            <div class="col-md-12  pt-0 pb-0">
              <label class="font text-gray" for="">Organization Name</label>
              <v-text-field
                required
                v-model.trim="organizationName"
                solo
              ></v-text-field>
            </div>
            <div class="col-md-12  pt-0 pb-0"><h4>Narrow your Search</h4></div>
            <div class="col-md-12  pt-0 pb-0">
              <label class="text-gray font" for=""
                >Select Specialties (hold ctrl to select multiple)</label
              >
              <v-select
                multiple
                :items="taxonomies"
                required
                solo
                filled
                chips
                v-model.trim="speciality"
                :menu-props="{
                  bottom: true,
                  offsetY: true,
                  closeOnClick: true,
                  maxHeight: 200,
                }"
              >
                <template #selection="selection">
                  <v-chip
                    @click="deleteItem(selection.item)"
                    v-text="selection.item"
                  ></v-chip>
                </template>
              </v-select>
            </div>

            <div class="col-md-5  pt-0 pb-0">
              <label class="font text-gray" for="">City</label>
              <v-text-field required v-model.trim="city" solo></v-text-field>
            </div>
            <div class="col-md-5  pt-0 pb-0">
              <label class="font text-gray" for="">State</label>
              <v-select
                required
                item-text="name"
                item-value="name"
                :items="states"
                v-model.trim="state"
                solo
              ></v-select>
            </div>
            <div class="col-md-2  pt-0 pb-0">
              <label class="font text-gray" for="">Zip</label>
              <v-text-field required solo v-model.trim="zip"></v-text-field>
            </div>
            <div class="col-md-12 text-end justify-end row">
              <v-progress-circular
                v-if="results"
                indeterminate
                color="primary mr-3"
              ></v-progress-circular>
              <div>
                <v-btn
                  :disabled="loadData"
                  class="blue-buttons"
                  @click="submit"
                > 
                  Search
                </v-btn>
              </div>
            </div>
          </form>
        </div>
      </v-col>
      <v-col md="6" cols="12" class="justify-center align-center">
        <h3>Search Result - Provider List</h3>
        <div>
          <v-simple-table fixed-header height="700px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Provider/Organization</th>
                  <th class="text-left">Type</th>
                  <th class="text-left">Taxonomy</th>
                  <th class="text-left">City</th>
                  <th class="text-left">State</th>
                </tr>
              </thead>
              <tbody v-if="items.length > 0">
                <tr
                  v-for="(item, index) in items"
                  :key="index"
                  :id="'id' + index"
                >
                  <td
                    class="text-gray"
                    v-if="
                      item['Provider Organization Name (Legal Business Name)']
                    "
                  >
                    <a @click="singleProvider(item)">{{
                      item["Provider Organization Name (Legal Business Name)"]
                    }}</a>
                  </td>
                  <td
                    class="text-gray"
                    v-else-if="
                      item['Provider Last Name (Legal Name)'] ||
                      item['Provider First Name']
                    "
                  >
                    <a>
                      {{ item["Provider First Name"] }}
                      {{ item["Provider Last Name (Legal Name)"] }}</a
                    >
                  </td>
                  <td v-else>/</td>
                  <td class="text-gray text-left">
                    Type {{ item["Entity Type Code"] }}
                  </td>
                  <td
                    class="text-left"
                    v-html="
                      item['Healthcare Provider Taxonomy Code_1']
                        ? item['Healthcare Provider Taxonomy Code_1']
                        : '/'
                    "
                  ></td>
                  <td
                    class="text-gray text-left"
                    v-html="
                      item[
                        'Provider Business Practice Location Address City Name'
                      ]
                        ? item[
                            'Provider Business Practice Location Address City Name'
                          ]
                        : '/'
                    "
                  ></td>
                  <td
                    class="text-gray"
                    v-html="
                      item['Provider Business Mailing Address State Name']
                        ? item['Provider Business Mailing Address State Name']
                        : '/'
                    "
                  ></td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td>No Result Found</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div class="col-md-12 text-end" style="height:50px">
            <v-btn
              v-if="showButton && items.length > 0"
              :disabled="loadData"
              class="blue-buttons"
              @click="loadMoreData()"
              >Load more data</v-btn
            >
          </div>
        </div>
      </v-col>
      <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
    </div>
  </v-row>
</template>
<script>
import api from "../services/api";
import errorModal from "./../components/TablesTemplate/ErrorDialog.vue";

export default {
  components: { errorModal },
  data() {
    return {
      npiNumber: "",
      npiType: "",
      firstName: "",
      lastName: "",
      organizationName: "",
      speciality: "",
      city: "",
      state: "",
      zip: "",
      states: [],
      showButton: false,
      excelError: false,
      results: false,
      arrayLength: false,
      urlData: "",
      taxonomies: [],
      taxonomiesValue: "",
      excelError: false,
      items: [],
      noResult: false,
      page: 1,
      loadData: false,
      jsondata: [
        {
          NPI: "",
     
        },
      ],
    };
  },
  computed: {
    route() {
      return this.$route.path;
    },
    getClient() {
      return this.$store.getters.getClientGetter;
    },
    routeParams() {
      return this.$route.params.name;
    },
  },
  created() {
    this.getStates();
    this.taxonomies = this.$store.getters.getTaxonomies;
    this.arrayExcel = [];
  },
  methods: {
    deleteItem(item) {
      this.speciality = this.speciality.filter((find) => find !== item);
    },
    loadMoreData() {
      this.loadData = true;
      this.page++;
      api()
        .get(`/api/npi/search?${this.urlData}page=${this.page}&limit=100`)
        .then((res) => {
          if (res) {
            if (res.data.items.length < 100) {
              this.showButton = false;
            }
            res.data.items.forEach((el) => {
              this.items.push(el);
            });
            this.filteredArray = this.items;
            this.loadData = false;
          }
        }).catch((err)=>{
          this.loadData = false;
        });;
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    getStates() {
      api()
        .get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },
    addItemExcel() {
      this.$refs.fileInput.click();
    },
    submit() {
      this.results = true;
      let urlData = ``;
      urlData += this.npiNumber ? `npi=${this.npiNumber}&` : "";
      urlData += this.npiType ? `type=${this.npiType}&` : "";
      urlData += this.firstName
        ? `firstname=${this.firstName.toUpperCase()}&`
        : "";
      urlData += this.lastName
        ? `lastname=${this.lastName.toUpperCase()}&`
        : "";
      urlData += this.organizationName
        ? `organization=${this.organizationName.toUpperCase()}&`
        : "";
      urlData += this.state ? `state=${this.state.toUpperCase()}&` : "";
      urlData += this.zip ? `zip=${this.zip.toUpperCase()}&` : "";
      urlData += this.city ? `city=${this.city.toUpperCase()}&` : "";
      urlData += this.speciality ? `speciality=${this.speciality}&` : "";
      urlData += this.speciality ? `taxonomy=${this.speciality}&` : "";
      this.urlData = urlData;
      api()
        .get(`/api/npi/search?${urlData}`)
        .then((res) => {
         
          if (res) {
            this.items = res.data.items;
            this.results = false;
            if (this.items.length % 100 == 0) {
              this.showButton = true;
            } else {
              this.showButton = false;
            }
          }
        }).catch((err)=>{
           this.results = false;
        })
    },
  },
};
</script>
