<template>
  <div>
    <div
      v-if="
        path.includes(`/provider-advisor/providers/${routeParams}`) &&
        path != '/provider-advisor/providers/provider' &&
        path != '/provider-advisor/providers/result' &&
        path != '/provider-advisor/providers/add-manually'
      "
      class="blue-toolbar row no-padding pl-2"
      style="margin-top: 0px"
    >
      <div
        cols="2"
        :class="
          activeTab == 'home'
            ? ' col-lg-1 ml-2 col-md-2 col-sm-2  font white--text text-center orange-underline sm-buttons-width'
            : 'col-lg-1 ml-2 col-md-2 col-sm-2 font  white--text text-center  sm-buttons-width'
        "
        align="center"
      >
        <a class="white--text" @click="changeActiveTab('home')"> Home </a>
      </div>
      <div
        cols="2"
        :class="
          activeTab == 'employers'
            ? ' col-lg-1 col-md-2 col-sm-2 font white--text text-center orange-underline sm-buttons-width'
            : 'col-lg-1 col-md-2 col-sm-2 font  white--text text-center  sm-buttons-width'
        "
        align="center"
      >
        <a class="white--text" @click="changeActiveTab('employers')">
          Employers
        </a>
      </div>
      <div
        cols="2"
        :class="
          activeTab == 'providers'
            ? ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center orange-underline sm-buttons-width'
            : ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center sm-buttons-width'
        "
        align="center"
      >
        <a class="white--text" @click="changeActiveTab('providers')">
          Providers
        </a>
      </div>
      <!-- <div
        cols="2"
        :class="
          activeTab == 'networks'
            ? 'col-lg-1 col-md-2 col-sm-2 font  white--text text-center orange-underline sm-buttons-width'
            : ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center sm-buttons-width'
        "
        align="center"
      >
        <a class="white--text" @click="changeActiveTab('networks')"> VNets </a>
      </div> -->
      <div
        cols="2"
        :class="
          activeTab == 'contracts'
            ? ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center orange-underline sm-buttons-width'
            : ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center sm-buttons-width'
        "
        align="center"
      >
        <a class="white--text" @click="changeActiveTab('contracts')">
          Contract
        </a>
      </div>
      <div
        cols="2"
        :class="
          activeTab == 'advisors'
            ? ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center orange-underline sm-buttons-width'
            : ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center sm-buttons-width'
        "
        align="center"
      >
        <a class="white--text" @click="changeActiveTab('advisors')">
          Partners
        </a>
      </div>

      <v-menu bottom offset-y id="blue-nav" >
        <template v-slot:activator="{ on }">
          <div
            cols="2"
            :class="
              activeTab == 'provider-settings'
                ? 'col-lg-1 col-md-2 col-sm-2 col-sm-2 font  white--text text-center orange-underline sm-buttons-width'
                : ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center blue-underline  sm-buttons-width'
            "
            align="center"
          >
            <a
              v-on="on"
              class="white--text"
              @click="changeActiveTab('provider-settings')"
            >
              Settings  <v-icon small color="white darken-2" :open-on-click="openOnClick = true"> mdi mdi-chevron-down </v-icon>
            </a>
          </div>
        </template>
        <v-list>
            <v-list-item style="disply: block">
            <a
              class="a-tag underline-text"
              @click="
               $route.path != `/provider-advisor/providers/${routeParams}/settings` ? 
                $router.push(
                  `/provider-advisor/providers/${routeParams}/settings`
                ) : ''
              "
              >Settings</a
            >
          </v-list-item>
          <v-list-item style="disply: block">
            <a
              class="a-tag underline-text"
              @click="
               $route.path != `/provider-advisor/providers/${routeParams}/settings/account` ? 
                $router.push(
                  `/provider-advisor/providers/${routeParams}/settings/account`
                ) : ''
              "
              >Account</a
            >
          </v-list-item>
          <v-list-item>
            <a
              class="a-tag underline-text"
              @click="
              $route.path != `/provider-advisor/providers/${routeParams}/settings/users` ? 
                $router.push(
                  `/provider-advisor/providers/${routeParams}/settings/users`
                ) : ''
              "
              >Users</a
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div
      v-else
      class="blue-toolbar row"
      style="margin-top: 0px; padding: 1.05rem; margin-left: 0px"
    ></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeTab: "",
      activeTab1: "",
      showOtherSettings: false,
      openOnClick:false,
    };
  },
  props: {
    clientInfo: {
      type: Boolean,
    },
  },
  watch: {
    $route(to, from) {
      this.onCreatedFunction();
    },
  },

  methods: {
    changeSettingsTimeOut() {
      this.showOtherSettings = true;
    },
    changeActiveTab(tab) {
      if (tab == "home") {
        if (this.path != "/provider-advisor/providers/" + this.routeParams)
          this.$router.push("/provider-advisor/providers/" + this.routeParams);
       } 
     else if (tab == "provider-settings") {
       this.openOnClick = true

      } else {
        if (
          this.path != `/provider-advisor/providers/${this.routeParams}/${tab}`
        )
          this.$router.push(
            `/provider-advisor/providers/${this.routeParams}/${tab}`
          );
      }

      this.activeTab = tab;
      this.activeTab1 = tab;
    },
    onCreatedFunction() {
      this.activeTab = "home";
      if (this.path.includes("providers")) {
        this.activeTab = "providers";
      }
      if (this.path.includes("account")) {
        this.activeTab1 = "account-settings";
      } else if (this.path.includes("users")) {
        this.activeTab1 = "users";
      } else {
        this.activeTab1 = "settings";
        this.activeTab1 = "settings";
      }

      if (
        this.path.includes(`/provider-advisor/providers/${this.routeParams}`)
      ) {
        this.activeTab = "home";
      }
      if (
        this.path.includes(
          `/provider-advisor/providers/${this.routeParams}/providers`
        )
      ) {
        this.activeTab = "providers";
      }

      if (this.path.includes("members")) {
        this.activeTab = "members";
      }
      if (this.path.includes("prior-auth")) {
        this.activeTab = "prior-auths";
      }
      if (this.path.includes("advisors")) {
        this.activeTab = "advisors";
      }
      if (this.path.includes("networks")) {
        this.activeTab = "networks";
      }

      if (this.path.includes("bundles")) {
        this.activeTab = "bundles";
      }
      if (this.path.includes("rates")) {
        this.activeTab = "rates";
      }

      if (
        this.path.includes("settings") ||
        this.path.includes("users") ||
        (this.path.includes("account") &&
          this.path.includes("/provider-advisor/providers"))
      ) {
        this.activeTab = "provider-settings";
      }

      if (this.$route.path.includes("contracts")) {
        this.activeTab = "contracts";
      }

      if (this.path == `/provider-advisor/providers/${this.routeParams}`) {
        this.activeTab = "home";
      }
      if (
        this.path.includes(
          `/provider-advisor/providers/${this.routeParams}/employers`
        )
      ) {
        this.activeTab = "employers";
      }
      if (
        this.path.includes(
          `/provider-advisor/providers/${this.routeParams}/providers`
        ) ||
        this.path.includes(
          `/provider-advisor/providers/${this.routeParams}/affiliates`
        ) ||
        this.path.includes(
          `/provider-advisor/providers/${this.routeParams}/hospital-based`
        )
      ) {
        this.activeTab = "providers";
      }
    },
  },
  computed: {
    routeParams() {
      return this.$route.params.name;
    },
    path() {
      return this.$route.path.replace(/%20/g, " ");
    },
  },
  created() {
    this.onCreatedFunction();
  },
};
</script>
<style>
.no-margin {
  margin: 0px !important;
}
</style>