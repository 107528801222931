<template>
    <v-row class="padding">
        <div class="row col-md-12">
           <h3>
             <span class="underline-text"> Create a Custom Bundle List Adding Member Zip Codes <i class="fas fa-question-circle  yellow-icons"></i></span>
          </h3>
        </div>
      <v-col md="6" cols="12" class="justify-center align-center">
         <div class="row col-md-12" > <h4 class="font">Add Zip Code Manually</h4></div>
         <div class="row">
             <div class="col-md-6">
                   <label for="" class="text-gray">Enter Zip Code</label>
                   <v-text-field solo></v-text-field>
             </div>
            <div class="col-md-6 mt-7 text-center">
                   <v-btn large class="blue-buttons rounded-btn">Add Zip Code to List</v-btn>
             </div>
            
         </div>
         <div class="row col-md-12"> <h4>OR</h4></div>
            <div class="row col-md-12 font"> 
                <h4 v-if="route.includes('providers')" >Upload a List of Providers</h4>
                <h4 v-else >Upload a List of Affilates</h4>
            </div>
            <div class="row text-center justify-center upload-box">
                <div class="col-md-9 upload-provider-box pointer" @click="addItemExcel">
                    <input ref="fileInput" type="file" style="display: none" @change="uploadExcel">
                    <img src="/filee.png" class="apaly-pic" alt="">
                    <h3 class="text-gray font">Drag & Drop</h3>
                    <h5 class="text-gray font">
                        Your files here or 
                        <a >Browse</a> 
                        to upload
                        </h5>
                    <h5 class="text-gray font">
                        <a >Excel or CSV files Only</a>
                    </h5>
                </div>
                <div class="btn-box col-md-10 ">
                    <div class="downoald-file-btn-box ">
                        <h3>
                            <a  class="underline-text" @click="downloadExcel">Download File Template</a>
                        </h3>
                    </div>
                </div>
            </div>
            <div class="col-md-11 justify-end text-end m">
                <v-btn class="blue-buttons" :disabled="results" @click="searchExcel">Upload Zip Code List</v-btn>
            </div>
        </v-col>
        <v-col cols="12" md="6 border-right">
            <h3>Zip Code List</h3>
            <div> 
                <v-simple-table >
                    <template v-slot:default>
                    <thead>
                        <tr>
                        <th class="text-left">
                            Zip Codes
                        </th>
                        <th class="text-end">
                           Actions
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                        v-for="item in 14"
                        :key="item"
                        >
                        <td>332525</td>
                        <td class="text-end">
                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 13.7206C1 17.0943 2.35661 20.3298 4.77138 22.7154C7.18615 25.101 10.4613 26.4412 13.8763 26.4412C17.2913 26.4412 20.5664 25.101 22.9812 22.7154C25.396 20.3298 26.7526 17.0943 26.7526 13.7206C26.7526 10.3469 25.396 7.11134 22.9812 4.72577C20.5664 2.3402 17.2913 1 13.8763 1C10.4613 1 7.18615 2.3402 4.77138 4.72577C2.35661 7.11134 1 10.3469 1 13.7206V13.7206Z" stroke="#D00000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M8.72607 18.8093L19.0271 8.63281" stroke="#D00000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M19.0271 18.8093L8.72607 8.63281" stroke="#D00000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>

                        </td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
          </div>
       </v-col>
       
 </v-row>
</template>
<script>
import api from '../../../../services/api'
import XLSX from 'xlsx'
export default{
    data(){
       return {
           npiNumber:'',
           npiType:'',
           firstName:'',
           lastName:'',
           organizationName:'',
           speciality:'',
           city:'',
           state:'',
           zip:'',
           states:[],
           excelError:false,
           results:false,
           urlData:'',
           jsondata:[{
                'NPI':'',
                'ORGANIZATON/PROVIDER NAME' : '',
                'PRIMARY TAXONOMY' : '',
                'ADDRESS' : ''
           }],
       }
    },
    computed:{
        route(){
             return this.$route.path
        },
        getClient(){
            return this.$store.getters.getClientGetter
        },
        routeParams(){
            return this.$route.params.name
         }
    },
    created(){
        this.getStates()
    },
    methods:{
        searchExcel(){ 
           this.results = true;
            let urlData = ``
            urlData += this.npiNumber ? `npi=${this.npiNumber}&` : ''
            urlData += this.npiType ? `type=${this.npiType}&` : '';
            urlData += this.firstName ? `firstname=${this.firstName.toUpperCase()}&` : ''
            urlData += this.lastName ? `lastname=${this.lastName.toUpperCase()}&` : ''
            urlData += this.taxonomy ? `taxonomy=${this.taxonomy}&` : ''
            urlData += this.organizationName ? `organization=${this.organizationName.toUpperCase()}&` : ''
            urlData += this.speciality ? `speciality=${this.speciality}&` : ''
            urlData += this.state ? `state=${this.state}&` : ''
            urlData += this.zip ? `zip=${this.zip}&` : ''
            urlData += this.city ? `city=${this.city}&` : '';
            
           
            if(this.arrayExcel.length != 0){
           
              let data = {
                urlData,
                 "array": {
                        "ids": this.arrayExcel
                    }
               }
          
          api().post(`/api/npidata/excel`, data.array).then((res) => {
          if(res){
                this.results = true;
                this.$router.replace(`/employer/providers/add-provider/search-result`);
                this.$store.commit('setSearchResultProvidersInEmployer', res.data.nipdatas);
                this.$store.commit('setUrlDataInEmployer', urlData);
          }else{
            this.results = false;
            this.arrayExcel = []
            this.$refs.error_modal.openPopUp('Attachment failed. Please follow the Provider List Template')
           }
         })
        }
        else{
            this.results = false;
            this.arrayExcel = []
            this.$refs.error_modal.openPopUp('Attachment failed. Please follow the Provider List Template')
          }
        },
         downloadExcel(){
            const data = XLSX.utils.json_to_sheet(this.jsondata)
            const wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, data, 'data')
            XLSX.writeFile(wb,'providerList.xlsx')
       },
         getStates(){
          api().get('/api/states').then((res)=>{
              res.data.forEach((el)=>{
                  if(el.active){
                      this.states.push(el);
                  }
              })
          })
        },
        addItemExcel() {
          this.$refs.fileInput.click()
         },
      uploadExcel(e){
         this.excelError = false;
         const files = e.target.files;
            if (!files.length) {
                return;
            } else if (!/\.(xls|xlsx|csv)$/.test(files[0].name.toLowerCase())) {
                return alert("The upload format is incorrect. Please upload xls, xlsx or csv format");
            }
            const fileReader = new FileReader();
            fileReader.onload = ev => {
                try {
                const data = ev.target.result;
                const workbook = XLSX.read(data, {
                    type: "binary"
                });
                const wsname = workbook.SheetNames[
                    0
                    ]; 
                const ws = XLSX.utils.sheet_to_json(workbook.Sheets[
                    wsname
                    ]); 
                const excellist = []; 
              
                for (var i = 0; i < ws.length; i++) {
                
                    if(ws[i].NPI){
                      excellist.push(ws[i].NPI);
                      this.excelError = false;
                      this.arrayExcel.push(ws[i].NPI);
                      this.arrayLength = true;
                    }else{
                      this.excelError = true;
                      this.arrayLength = false;
                    }
                }
                if(this.arrayExcel.length < 1){
                     this.arrayLength = false;
                    this.$refs.error_modal.openPopUp('Something Went Wrong, Please Download The File Template First')
                }
                } catch (e) {
                return alert("Read failure! Please Download the File Template.");
                }
                 
            };
           
           fileReader.readAsBinaryString(files[0]);
        },
       submit() {
            this.results= true;
            let urlData = ``
            urlData += this.npiNumber ? `npi=${this.npiNumber}&` : ''
            urlData += this.firstName ? `firstname=${this.firstName.toUpperCase()}&` : ''
            urlData += this.taxonomy ? `lastname=${this.taxonomy.toUpperCase()}&` : ''
            urlData += this.organizationName ? `organization=${this.organizationName.toUpperCase()}&` : ''
            urlData += this.speciality ? `speciality=${this.speciality}&` : ''
             urlData += this.speciality ? `taxonomy=${this.speciality}&` : ''
            urlData += this.state ? `state=${this.state.toUpperCase()}&` : ''
            urlData += this.zip ? `zip=${this.zip.toUpperCase()}&` : ''
            urlData += this.city ? `city=${this.city.toUpperCase()}&` : ''
            urlData += this.npiType
            
            api().get(`/api/npidata/search?${urlData}`).then((res)=>{
             
                if(res){
                    this.results = true;
                    this.$router.replace(`/employer/providers/add-provider/search-result`);
                    this.$store.commit('setSearchResultProvidersInEmployer', res.data.items);
                    this.$store.commit('setUrlDataInEmployer', urlData);

                    
                }
            })
           
       },
    }
}
</script>
