<template>
  <div>
    <header-menu></header-menu>
    <div class="mobile-content-background">
      <div class="mobile-content">
        <h2 class="white--text mt-10">Welcome</h2>
        <v-select color="white" :items="providers" value="z2b1123123ub1" item-text="client" item-value="_id">
          <template v-slot:item="slotItem">
            <div class="d-flex justify-space-between info-box-list-select width-100">
              <div>
                {{ slotItem.item.client }}
              </div>
              <div >
                {{ slotItem.item.provider }}
              </div>
            </div>
          </template>
        </v-select>

        <div class="d-flex flex-column info-box p-5">
          <div class="d-flex justify-space-between info-box-list align-baseline">
            <div class="d-flex flex-column mt-2">
              <span class="info-box-title">Insurance Information</span>
              <div class="d-flex justify-space-between">
                <div class="d-flex flex-column">
                  <span>Member ID</span>
                  <span class="blue-text font-bold">XXX-XXXXXX</span>
                </div>

                <div class="d-flex flex-column">
                  <span>Group ID</span>
                  <span class="blue-text font-bold">XXX-XXXXXX</span>
                </div>
              </div>
              <div class="d-flex flex-column">
                <span>Player ID</span>
                <span class="blue-text font-bold">XXX-XXXXXX</span>
              </div>
            </div>
            <div>
              <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22.4652 6.28894C23.4219 7.25287 23.4219 8.74713 22.4652 9.71106C20.4086 11.7834 16.4926 15 12 15C7.50741 15 3.59144 11.7834 1.53478 9.71106C0.578111 8.74713 0.57811 7.25287 1.53477 6.28894C3.59144 4.21664 7.50741 1 12 1C16.4926 1 20.4086 4.21664 22.4652 6.28894Z" stroke="#16407A" stroke-width="1.5"/>
                <circle cx="12" cy="8" r="4.25" stroke="#16407A" stroke-width="1.5"/>
              </svg>
            </div>
          </div>
        </div>

        <div class="d-flex flex-column info-box p-5 mt-5">
          <div class="d-flex justify-space-between info-box-list">
            <div class="info-box-title">Direct Primary Care</div>
          </div>
          <div class="d-flex justify-space-between info-box-list">
            <div>Provider</div>
            <div style="color:#16407A">Everside Health</div>
          </div>
          <div class="d-flex justify-space-between info-box-list">
            <div>Next visit</div>
            <div style="color:#001E46;font-weight: bold;">10 days •02/30/2023</div>
          </div>
          <div class="d-flex justify-space-between info-box-list">
            <div>Membership status</div>
            <div style="color:#001E46;font-weight: bold;">Active</div>
          </div>
        </div>
        <div class="d-flex flex-column info-box p-5 mt-5">
          <div class="info-box-list-single text-center">
            <div>Rotating Informational Messages</div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex footer">
        <v-btn @click="$router.push({name:'marketplace'})" elevation="1" outlined rounded x-large text class="width-100 blue-background white--text">
          Go to Marketplace
        </v-btn>
    </div>
  </div>
</template>

<script>
import headerMenu from "@/components/Sections/DPCMember/Layout/headerMenu";
export default {
  data() {
    return {
      providers:[
          {
            _id:'z2b1123123ub1',
            client:'William2 Smith',
            provider:'Everside Health'
          },
          {
            _id:'z2b1uhb2u1hb2ub1',
            client:'William Smith',
            provider:'Everside Health'
          },
      ],
    };
  },
  components:{
    headerMenu
  },
  methods: {},
  created() {},
};
</script>

<style scoped src='./../../../../assets/mobileStyle.css'></style>
<style scoped lang="scss">

.mobile-content-background{
  background-color:#F5F7FA;
  height:100vh;
  margin-top:150px;
}
.mobile-content{
  padding:16px 24px 0 24px;
  transform: translateY(-170px);
}
.info-box{
  background-color: white;
  border-radius: 5px;
  border: 1px solid #C9C9C9;
  padding:10px;
}
.info-box-list:first-child{
  border: none;
}
.info-box-title{
  color:#001E46;
  font-size: 20px;
  font-weight: bold;
}
.info-box-list-single{
  margin: 24px;
}
.info-box-list-select{
  margin: 12px;
  border-bottom: 1px solid #C9C9C9;
}
.info-box-list-select > div{
  padding: 10px 0;
}
.info-box-list{
  margin: 4px 12px;
  border-bottom: 1px solid #C9C9C9;
}
.info-box-list:last-child{
  margin-bottom: 0;
  border: none;
}
.info-box-list > div{
  padding-bottom: 10px;
}
.footer{
  border-top:1px solid #C9C9C9;
  padding:15px 15px 0 15px;
  height:80px;
  position:absolute;
  bottom:0;
  width:100%;
  align-items: flex-start;
  border-top: 1px solid #C9C9C9
}
</style>