<template>
  <div class="form-div d-flex flex-column">
    <FullscreenLoader v-if="loader" />
    <div class="form-pass-div" v-if="errorDiv">
      <v-alert dense outlined type="error" class="mt-15">
        This link has expired, please <span @click="goToForgotPassword()"> click here </span> to try again
      </v-alert>
    </div>

    <v-flex v-else xs12 sm12 md6 lg6 class="register-page-right">
      <div class="register-content-right">
        <div class="register-form-container">
          <v-form
            class="col-12 info-form"
            ref="form"
            v-model.trim="valid"
            @submit.prevent=""
          >
            <div class="center-content mb-8">
              <img class="apaly-logo-2" src="/svg/apalyBlue.svg" />
              <span class="register-header-text"> Change Passwords </span>
            </div>

            <v-row class="register-row">
              <v-col
                md="12"
                sm="12"
                align="start"
                class="pb-0 position-relative"
              >
                <label for="" class="register-label">New Password</label>
                <v-text-field
                  dense
                  id="password"
                  placeholder="Enter your new password here"
                  name="accountPassword"
                  v-model.trim="password"
                  required
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  @click:append="show1 = !show1"
                  :rules="passRules"
                  class="apaly_text register-text-field"
                  tabindex="1"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="register-row">
              <v-col md="12" sm="12" align="start" class="pb-0">
                <label for="" class="register-label">Confirm Password</label>
                <v-text-field
                  dense
                  id="confirmPassword"
                  placeholder="Re-enter new password"
                  name="confirmPassword"
                  v-model.trim="retypePassword"
                  required
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show2 ? 'text' : 'password'"
                  @click:append="show2 = !show2"
                  :rules="confirmPassRules"
                  class="apaly_text register-text-field"
                ></v-text-field>
              </v-col>
            </v-row>

            <div class="error-message">
                <span
                    style="color: #ff5252; font-size: 14px; width: 80%;"
                    v-if="errorMessage">{{ errorMessage }}
                </span>
             </div>

            <v-row class="mt-3">
              <v-col md="12" sm="12" align="start">
                <span class="password-requirements-text"
                  >Password Requirements</span
                >
                <ul>
                  <li
                    v-for="(requirement, index) in passwordRequirements()"
                    :key="index"
                    :class="
                      requirement.satisfied
                        ? 'req-satisfied'
                        : 'req-unsatisfied'
                    "
                  >
                    {{ requirement.text }}
                  </li>
                </ul>
              </v-col>
            </v-row>

            <v-row class="register-row">
              <v-col md="12" sm="12" class="pb-0">
                <v-btn
                  text
                  :class="
                    passwordsValid() ? 'primary-button' : 'disabled-button'
                  "
                  style="width: 100%"
                  @click="submit()"
                  :disabled="!passwordsValid()"
                >
                  Submit Changes
                </v-btn>
              </v-col>
            </v-row>

            <div class="register-footer-left">
              <h3 class="tiny-font text-gray mobile-font" style="left: 0">
                Already have an account?
                <a @click="$router.push('/login')"><b>Log In</b></a>
              </h3>
            </div>
            <div class="register-footer-right">
              <h3 class="tiny-font text-gray mobile-font" style="left: 0">
                Are you a Plan Member?
                <a @click="goToMarketplace()"><b>Access Here!</b></a>
              </h3>
            </div>
          </v-form>
        </div>
      </div>
    </v-flex>

    <v-dialog v-model.trim="dialog" width="520" persistent>
      <v-card>
        <v-card-text class="pt-5">
          <span role="button" align="end" class="pt-0 pr-0">
            <p class="font-weight-bold" @click="$router.push('/login')">X</p>
          </span>
          <div class="display">
            <div class="text-center">
              <img class="check-icon" src="/svg/blueCheck.svg" />
            </div>
            <div class="password-changed-message">
              <span class="password-changed-title">
                Password has been changed
              </span>
              <span class="password-changed-info">
                You may now use your new password to login
              </span>
            </div>
          </div>

          <div class="mt-9 mb-3 align-right row-buttons">
            <v-btn
              text
              style="text-transform: none !important;"
              class="primary-b3-button no-transform"
              @click="$router.push('/login')"
            >
              Go to login page
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import api from "../../services/api";
import FullscreenLoader from "../Common/FullscreenLoader.vue";

export default {
  components: { FullscreenLoader },
  data() {
    return {
      dialog: false,
      errorDiv: true,
      show1: false,
      show2: false,
      retypePassword: "",
      password: "",
      passRules: [
        (v) => !!v || "Password is required",
        (v) => v.length > 7 || "Password must be at least 8 characters",
        (v) =>
          (v.length && /[^A-Za-z0-9]/.test(v)) ||
          "Password should contain at least one special character (example @,#,$,etc.)",
        (v) =>
          (v.length && /[a-z]{1}/.test(v)) ||
          "Password should contain at least one lowercase letter",
        (v) =>
          (v.length && /[A-Z]{1}/.test(v)) ||
          "Password should contain at least one uppercase letter",
      ],
      confirmPassRules: [
        (v) => !!v || "ReType Password is required",
        (v) => v == this.password || "Password must match",
      ],
      valid: false,
      id: "",
      type: "",
      loader: false,
      errorMessage: null,
    };
  },
  computed: {},
  created() {
    this.getToken();
    
  },
  methods: {
    goToForgotPassword() {
      this.$router.push('/change-pass');
    },
    getToken() {
      api()
      .get(`/auth/verify/${this.$route.params.id}`)
      .then((res) => {
        if(res) {
          console.log("Sucess: ", res)
          this.errorDiv = false;
          localStorage.setItem("type", JSON.stringify(res.data.type));
          this.$store.commit("userType", res.data.type);
          this.type = res.data.type;
        }
      })
      .catch((error) => {
        if(error) {
          // UI
          console.log("Error: ", error)
          this.errorDiv = true;
          this.$store.commit("sideBarTitleAndDesc", "");
          this.$store.commit("sideBarDesc", "");
        }
        

        // console.log("reset password error: ", error);
        // if (error.response) {
        //   console.log("Response error data: ", error.response.data);
        //   console.log("Response error status: ", error.response.status);
        //   console.log("Response error headers: ", error.response.headers);
        // } else if (error.request) {
        //   console.log("Request error: ", error.request);
        // } else {
        //   console.log("Error message: ", error.message);
        // }
      });

      if (this.errorDiv) {
        this.$store.commit("sideBarTitleAndDesc", "");
      }

    },
    ...mapActions(["registerUser"]),
    submit() {
        this.errorMessage = null;
        this.loader = true;
        let user = {
            password: this.password,
        };
        this.$refs.form.validate();
        if (this.valid) {
            api()
            .post("/auth/setPassword/" + this.$route.params.id, user)
            .then((res) => {
                if (res.status == 200) {
                this.dialog = true;
                }
            })
            .catch((error) => {
                this.errorMessage = error.response.data.message;
            } )
            .finally(() => {
                this.loader = false;
            });
        }
    },

    passwordRequirements() {
      const password = this.password || "";

      const passwordRequirements = {
        minLength: {
          text: "At least 8 characters",
          satisfied: password.length >= 8,
        },
        capitalLetter: {
          text: "At least 1 capital letter",
          satisfied: /[A-Z]/.test(password),
        },
        lowercaseLetter: {
          text: "At least 1 lowercase letter",
          satisfied: /[a-z]/.test(password),
        },
        specialCharacter: {
          text: "At least 1 special character",
          satisfied: /[!@#$%^&*(),.?":{}|<>]/.test(password),
        },
      };

      return passwordRequirements;
    },

    passwordsValid() {
      return (
        this.password.length >= 8 &&
        /[A-Z]/.test(this.password) &&
        /[a-z]/.test(this.password) &&
        /[!@#$%^&*(),.?":{}|<>]/.test(this.password) &&
        this.password === this.retypePassword
      );
    },
  },
};
</script>
<style>
.check {
  width: 33px;
  height: 35px;
  padding: 0px;
}

.register-page-right {
  height: 100vh;
  margin-top: -15vh;
}

.register-content-right {
  width: 55vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.register-form-container {
  width: 30vw;
}

.apaly-logo-2 {
  width: 13vh;
  margin-bottom: 1vh;
}

.register-header-text {
  font-size: 4.2vh;
  font-weight: bold;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.register-form-title {
  font-size: 5.5vh;
  padding-left: 7vw;
  padding-right: 7vw;
  padding-top: 1vh;
  line-height: 6vh;
  margin-bottom: 2.8vh;
  display: block;
  font-weight: bold;
  text-align: center;
}

.register-form-title-2 {
  font-size: 5.5vh;
  padding-left: 3vh;
  padding-right: 3vh;
  line-height: 6vh;
  margin-bottom: 6vh;
  display: block;
  font-weight: bold;
}

.register-form-subtitle {
  font-size: 2.2vh;
  margin-bottom: 5vh;
  display: block;
  color: var(--inactive-color);
  text-align: center;
}

.register-label {
  font-size: 2vh;
}

.register-row {
  height: 80px;
}

.login-line-break {
  margin-top: 2.5vh;
  margin-bottom: 4vh;
  background-color: var(--gray-color);
}

.login-footer-text {
  font-size: 2vh;
}

.register-footer-left {
  position: absolute;
  margin-left: 3vw;
  margin-bottom: 2vh;
  bottom: 0;
  left: 0;
  font-size: 1.8vh;
}

.register-footer-right {
  position: absolute;
  margin-right: 3vw;
  margin-bottom: 2vh;
  bottom: 0;
  right: 0;
  font-size: 1.8vh;
}

.check-icon {
  width: 3vw;
  margin-bottom: 2vh;
}

.password-changed-message {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--primary-color);
  padding-left: 1.5vh;
  padding-right: 1.5vh;
}

.password-changed-title {
  font-size: 1.3vw;
  font-weight: bold;
}

.password-changed-info {
  width: 14vw;
  margin-top: 2.5vh;
  font-size: 0.8vw;
  line-height: 2.2vh;
  font-weight: bold;
  text-align: center;
}

.align-right {
  display: flex;
  justify-content: flex-end;
}

.row-buttons {
  align-items: end;
}

.password-requirements-text {
  font-weight: bold;
  font-size: 1.5vh;
}

.req-satisfied {
  font-size: 1.5vh;
  color: #4cb04f;
}

.req-unsatisfied {
  font-size: 1.5vh;
  color: red;
}

.error-message {
    margin-top: 2vh;
    margin-bottom: -2vh;
    text-align: center;
}

.no-transform {
    text-transform: none !important;
}

.primary-button {
  font-weight: bold;
}

.secondary-button {
  font-weight: bold;
}

.disabled-button {
  font-weight: bold;
}

.form-pass-div span {
  color: blue !important;
  cursor: pointer !important;
}

</style>
