const state = {
    client: null,
}

const getters = {
    getClientGetter(state){
        return state.client
      },
}

const mutations = {
    setClientName(state, clientName){
        state.clientName = clientName 
     },
     singleClient(state, client){
        state.client = client 
     },
}

const actions = {
}

export default {
    state,
    getters,
    mutations,
    actions
}
