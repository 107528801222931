<template>
  <div class="">
    <div  class="row-height">
<Header
        :title="'New Invoice'"
        :searchInput="false"
        :inviteButton="false"
        :exportButton="false"
        :newButton="false"
        :addButton="false"
        :placeholder="'Search Invoices'"
        :sort="false"
      ></Header>

    <div class="col-md-12">
      <div class="col-md-12">
        <v-form class="row gray-border-form" ref="form">
          <div class="col-md-6 border-right">
            <div class="col-md-12">
              <div class="col-md-12 pb-3" style="color: #707070"> 
               Enter or verify invoice information below
              </div>
              <div class="col-md-12 d-flex pb-0">
                  <div class="col-md-4 text-end mt-3 font-14">  <label for="">INVOICE NUMBER:</label></div>
                 <div class="col-md-6">
                <v-text-field
                  solo
                ></v-text-field>
                 </div>
                     <div class="col-md-2">
                <a class="text-underline" style="color: #0573F3"> Autogenerate</a>
                </div>
              </div>
              <div class="col-md-12 d-flex pt-0 pb-0">
                <div class="col-md-4 text-end mt-3 font-14">  <label for="">BILLING PERIOD:</label></div>
               <div class="col-md-8">
                <v-text-field
                  solo
                ></v-text-field>
                </div>
              </div>
              <div class="col-md-12 d-flex pt-0 pb-0">
                      <div class="col-md-4 text-end mt-3 font-14"> 
                <label for="">CLIENT:</label>
                      </div>
                            <div class="col-md-8"> 
                <v-text-field
                  solo
                ></v-text-field>
                </div>
              </div>
               <div class="col-md-12 d-flex pb-0">
                      <div class="col-md-4 text-end mt-3 font-14"> 
                <label class="font text-gray" for=""
                  > PLAN:
                </label>
                      </div>     
                       <div class="col-md-8"> 
                <v-text-field
                  solo
                ></v-text-field>
                       </div>
              </div>
              <div class="col-md-12 d-flex pt-0 pb-0">
                      <div class="col-md-4 text-end mt-3 font-14"> 
                <label class="font text-gray" for=""
                  > TOTAL CHARGE (USD):</label>
                      </div>
                            <div class="col-md-8"> 
                <v-text-field
                  solo
                ></v-text-field>
                      </div> 
              </div>
           
             
            </div>
          </div>
          <div class="col-md-6 d-flex justify-content-end">
            <div class="col-md-12 d-flex">
         
             <div class="col-md-12 pb-3"> 
                          <span class="text-gray mr-10">
                  Invoice </span>  <span class="mr-10" style="color:#D9D9D9;"> | </span>
                <span style="color: #0573F3;"
                 class="text-underline ml-auto"> Members</span> <span class="text-gray">(optional)</span>
              </div>
      
              

           <div>
        
             </div>
              </div>
            

    
          </div>
        </v-form>
      </div>
    </div>
    <div class="col-md-12">
      <div class="col-md-12 mb-4" align="end">
        <span
          class="
            light-blue-text
            text-underline
            pointer
            font-weight-bold
            mr-10
            font-18
          "
          @click="$router.go(-1)"
          >Cancel</span
        >
        <span
          @click="submit"
          class="
            light-blue-text
            text-underline
            pointer
            font-weight-bold
            mr-5
            font-18
          "
          >Submit Changes
        </span>
      </div>
      <successModal
        ref="success_modal"
      ></successModal>
      <errorModal ref="error_modal"></errorModal>
    </div>
  </div>
      </div>
</template>
<script>
import Header from "../Layout/HeaderPage.vue";
import api from "../../../../services/api";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../TablesTemplate/ErrorDialog.vue";
export default {
  components: {
    Header, successModal, errorModal,
  },
  computed: {
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
  },
  data() {
  return {

  }
  },
  methods: {
    submit(){},
     invite() {},
    add() {
         this.$router.push("/digital-invoicing/invoices/add-new");
    },
    exportFun() {
      this.exportData("tblData");
    },

    searchInput(val) {
      this.items = this.filteredArray.filter((el) => {
        if (
          el.invoiceNo &&
          el.invoiceNo.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.invoiceNo.toLowerCase().includes(val.toLowerCase());
        } else if (
          el.companyName &&
          el.companyName.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.companyName.toLowerCase().includes(val.toLowerCase());
        }
      });
      if (this.items.length < 1) {
        this.filterItems = true;
      } else {
        this.filterItems = false;
      }
    },
  }
}
</script>