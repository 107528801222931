<template>
  <div>

    <div v-if="devideRow == 'onlyTitle'" class="row border-blue-bottom">
      <h3 class="col-md-12 mb-4 mt-4 ml-4 font-weight-bold text-gray">
        {{ title }}
      </h3>
    </div>

    <div
      v-else-if="devideRow == 'titleSearch'"
      class="row border-blue-bottom"
    >
      <div class="col-md-3" style="margin-top:10px">
        {{ title }}
      </div>
      <div class="col-md-5 pt-1 " style="margin-top:10px;margin-bottom:20px">
        <v-text-field
          class="input-control mr-5"
          prepend-inner-icon="mdi-magnify"
          @input="$emit('searchInput', search)"
          light
          dense
          v-model="search"
          outlined
          :placeholder="placeholder"
        ></v-text-field>
      </div>
    </div>
    <div
      v-else-if="devideRow == 'titleSearchAndSort'"
      class="row border-blue-bottom"
    >
      <h3 class="col-md-3 mb-4 mt-2 ml-4">
        {{ title }}
      </h3>
      <div class="col-md-3">
        <v-text-field
          class="input-control mr-5"
          prepend-inner-icon="mdi-magnify"
          @input="$emit('searchInput', search)"
          light
          dense
          outlined
          v-model="search"
          :placeholder="placeholder"
        ></v-text-field>
      </div>
      <div class="col-md-4 row">
        <v-col class="d-flex aling-items-center col-md-6" v-if="type" cols="12">
          Type:

          <v-select
            @change="$emit('filterItemsByType', typeBy)"
            :items="typeItems"
            solo
            dense
            v-model="typeBy"
            hide-details
            class="ml-3"
          ></v-select>
        </v-col>

        <v-col class="d-flex aling-items-center col-md-6" v-if="sort" cols="12">
          Sort By:

          <v-select
            @change="$emit('sortItemsByDate', sortBy)"
            :items="sortItems"
            solo
            dense
            v-model="sortBy"
            hide-details
            class="ml-3"
          ></v-select>
        </v-col>
      </div>
    </div>
    <div v-else-if="devideRow == 'everything'" class="row border-blue-bottom">
      <h3 class="col-xl-3 col-lg-2 col-md-6 mb-2 mt-3 text-gray pt-4">
        <span class="ml-3 "> {{ title }}</span>
       
      </h3>
      <div class="col-xl-2 col-lg-2 col-md-6 mt-1">
        <v-text-field
          class="input-control mr-5"
          prepend-inner-icon="mdi-magnify"
          @input="$emit('searchInput', search)"
          light
          dense
          v-model="search"
          outlined
          :placeholder="placeholder"
        ></v-text-field>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-12 mt-1 
      ">
        <div class="d-flex pt-0">
          <div class="col-md-6 pt-0 d-flex justify-end" align="end">
            <span class="mt-2"> Type:</span>

            <v-select
              style="max-width: 250px"
              @change="$emit('filterItemsByType', typeBy)"
              :items="typeItems"
              solo
              dense
              v-model="typeBy"
              hide-details
              class="ml-3"
            ></v-select>
          </div>


          <div class="col-md-6 ml-9 pt-0 d-flex">
            <span class="mt-2"> Sort By:</span>
         
              <v-select
                style="max-width: 250px"
                @change="$emit('sortItemsByDate', sortBy)"
                :items="sortItems"
                solo
                dense
                v-model="sortBy"
                hide-details
                class="ml-3"
              ></v-select>
           
          </div>
        </div>
        
      </div>
      <div class="col-xl-1 col-lg-2 col-md-12 justify-end text-end" align="end">
        <v-btn
          class="my-1  gray-border-buttons"
          @click="$emit('invite')"
          v-if="inviteButton"
        >
          <svg
            class="mr-2"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 7.5H15"
              stroke="#707070"
              stroke-width="2"
              stroke-linejoin="round"
            />
            <path
              d="M7.5 0V15"
              stroke="#707070"
              stroke-width="2"
              stroke-linejoin="round"
            />
          </svg>

          Invite
        </v-btn>
        <v-btn
          class="my-1 gray-border-buttons ml-6"
          @click="$emit('add')"
          v-if="addButton"
        >
          <svg
            class="mr-2"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 7.5H15"
              stroke="#707070"
              stroke-width="2"
              stroke-linejoin="round"
            />
            <path
              d="M7.5 0V15"
              stroke="#707070"
              stroke-width="2"
              stroke-linejoin="round"
            />
          </svg>

          Add
        </v-btn>
        <v-btn
          class="my-1 gray-border-buttons "
          @click="$emit('export')"
          v-if="exportButton"
        >
          <svg
            class="mr-2 "
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.7705 10.7637C14.5668 10.7637 14.3714 10.8431 14.2268 10.9848C14.0822 11.1264 14.0001 11.3188 13.9984 11.5199V12.6762C13.9984 12.9364 13.8938 13.186 13.7074 13.37C13.5211 13.554 13.2684 13.6574 13.0049 13.6574H3.03135C2.76784 13.6574 2.51513 13.554 2.3288 13.37C2.14247 13.186 2.0378 12.9364 2.0378 12.6762V11.5199C2.02037 11.3304 1.93183 11.1542 1.78956 11.0259C1.64729 10.8975 1.46157 10.8264 1.2689 10.8264C1.07622 10.8264 0.89051 10.8975 0.74824 11.0259C0.605971 11.1542 0.517426 11.3304 0.5 11.5199V12.6762C0.5 13.3392 0.766695 13.9751 1.24142 14.4439C1.71614 14.9128 2.36 15.1762 3.03135 15.1762H13.0049C13.6762 15.1762 14.3201 14.9128 14.7948 14.4439C15.2695 13.9751 15.5362 13.3392 15.5362 12.6762V11.5199C15.5362 11.3194 15.4556 11.127 15.312 10.9852C15.1684 10.8433 14.9736 10.7637 14.7705 10.7637Z"
              fill="#707070"
            />
            <path
              d="M8.01829 0.17627C7.69268 0.17627 7.38041 0.304015 7.15017 0.531403C6.91993 0.75879 6.79058 1.06719 6.79058 1.38877V7.05127C6.79058 7.09271 6.77392 7.13245 6.74425 7.16176C6.71458 7.19106 6.67433 7.20752 6.63237 7.20752H4.94902C4.83528 7.2152 4.72575 7.25309 4.632 7.31719C4.53826 7.38128 4.46379 7.46919 4.41647 7.57163C4.36914 7.67407 4.35072 7.78724 4.36314 7.89917C4.37557 8.0111 4.41838 8.11764 4.48705 8.20752L7.55632 11.695C7.61551 11.7575 7.68706 11.8072 7.76652 11.8413C7.84599 11.8753 7.93168 11.8929 8.01829 11.8929C8.1049 11.8929 8.19059 11.8753 8.27006 11.8413C8.34952 11.8072 8.42107 11.7575 8.48026 11.695L11.5495 8.20752C11.6182 8.11764 11.661 8.0111 11.6734 7.89917C11.6859 7.78724 11.6674 7.67407 11.6201 7.57163C11.5728 7.46919 11.4983 7.38128 11.4046 7.31719C11.3108 7.25309 11.2013 7.2152 11.0876 7.20752H9.4042C9.36225 7.20752 9.322 7.19106 9.29233 7.16176C9.26266 7.13245 9.246 7.09271 9.246 7.05127V1.38877C9.246 1.06719 9.11665 0.75879 8.88641 0.531403C8.65617 0.304015 8.3439 0.17627 8.01829 0.17627V0.17627Z"
              fill="#707070"
            />
          </svg>

          Export
        </v-btn>
        <v-btn
          class="my-1 gray-border-buttons ml-6"
          @click="$emit('new')"
          v-if="newButton"
        >
          <svg
            class="mr-2"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 7.5H15"
              stroke="#707070"
              stroke-width="2"
              stroke-linejoin="round"
            />
            <path
              d="M7.5 0V15"
              stroke="#707070"
              stroke-width="2"
              stroke-linejoin="round"
            />
          </svg>

          New
        </v-btn>
      </div>
    </div>
    <div v-else-if="devideRow == 'withButtons'" class="row border-blue-bottom">
      <div class="col-md-3 row">
        <h3 class="mb-4 mt-4 ml-6 font-weight-bold text-gray">
          {{ title }}
        </h3>
      </div>
      <div class="col-xl-2 col-lg-4">
        <v-text-field
          class="input-control mr-5"
          prepend-inner-icon="mdi-magnify"
          @input="$emit('searchInput', search)"
          light
          dense
          outlined
          v-model="search"
          :placeholder="placeholder"
        ></v-text-field>
      </div>

      <div class="col-xl-7 col-lg-5" align="end">
        <v-btn
          class="my-1 gray-border-buttons"
          @click="$emit('invite')"
          v-if="inviteButton"
        >
          <svg
            class="mr-2"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 7.5H15"
              stroke="#707070"
              stroke-width="2"
              stroke-linejoin="round"
            />
            <path
              d="M7.5 0V15"
              stroke="#707070"
              stroke-width="2"
              stroke-linejoin="round"
            />
          </svg>

          Invite
        </v-btn>

        <v-btn
          class="my-1 gray-border-buttons ml-6"
          @click="$emit('export')"
          v-if="exportButton"
        >
          <svg
            class="mr-2"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.7705 10.7637C14.5668 10.7637 14.3714 10.8431 14.2268 10.9848C14.0822 11.1264 14.0001 11.3188 13.9984 11.5199V12.6762C13.9984 12.9364 13.8938 13.186 13.7074 13.37C13.5211 13.554 13.2684 13.6574 13.0049 13.6574H3.03135C2.76784 13.6574 2.51513 13.554 2.3288 13.37C2.14247 13.186 2.0378 12.9364 2.0378 12.6762V11.5199C2.02037 11.3304 1.93183 11.1542 1.78956 11.0259C1.64729 10.8975 1.46157 10.8264 1.2689 10.8264C1.07622 10.8264 0.89051 10.8975 0.74824 11.0259C0.605971 11.1542 0.517426 11.3304 0.5 11.5199V12.6762C0.5 13.3392 0.766695 13.9751 1.24142 14.4439C1.71614 14.9128 2.36 15.1762 3.03135 15.1762H13.0049C13.6762 15.1762 14.3201 14.9128 14.7948 14.4439C15.2695 13.9751 15.5362 13.3392 15.5362 12.6762V11.5199C15.5362 11.3194 15.4556 11.127 15.312 10.9852C15.1684 10.8433 14.9736 10.7637 14.7705 10.7637Z"
              fill="#707070"
            />
            <path
              d="M8.01829 0.17627C7.69268 0.17627 7.38041 0.304015 7.15017 0.531403C6.91993 0.75879 6.79058 1.06719 6.79058 1.38877V7.05127C6.79058 7.09271 6.77392 7.13245 6.74425 7.16176C6.71458 7.19106 6.67433 7.20752 6.63237 7.20752H4.94902C4.83528 7.2152 4.72575 7.25309 4.632 7.31719C4.53826 7.38128 4.46379 7.46919 4.41647 7.57163C4.36914 7.67407 4.35072 7.78724 4.36314 7.89917C4.37557 8.0111 4.41838 8.11764 4.48705 8.20752L7.55632 11.695C7.61551 11.7575 7.68706 11.8072 7.76652 11.8413C7.84599 11.8753 7.93168 11.8929 8.01829 11.8929C8.1049 11.8929 8.19059 11.8753 8.27006 11.8413C8.34952 11.8072 8.42107 11.7575 8.48026 11.695L11.5495 8.20752C11.6182 8.11764 11.661 8.0111 11.6734 7.89917C11.6859 7.78724 11.6674 7.67407 11.6201 7.57163C11.5728 7.46919 11.4983 7.38128 11.4046 7.31719C11.3108 7.25309 11.2013 7.2152 11.0876 7.20752H9.4042C9.36225 7.20752 9.322 7.19106 9.29233 7.16176C9.26266 7.13245 9.246 7.09271 9.246 7.05127V1.38877C9.246 1.06719 9.11665 0.75879 8.88641 0.531403C8.65617 0.304015 8.3439 0.17627 8.01829 0.17627V0.17627Z"
              fill="#707070"
            />
          </svg>

          Export
        </v-btn>
        <v-btn
          class="my-1 gray-border-buttons ml-6"
          @click="$emit('add')"
          v-if="newButton"
        >
          <svg
            class="mr-2"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 7.5H15"
              stroke="#707070"
              stroke-width="2"
              stroke-linejoin="round"
            />
            <path
              d="M7.5 0V15"
              stroke="#707070"
              stroke-width="2"
              stroke-linejoin="round"
            />
          </svg>

          New
        </v-btn>
        <v-btn
          class="my-1 gray-border-buttons ml-6"
          @click="$emit('add')"
          v-if="addButton"
        >
          <svg
            class="mr-2"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 7.5H15"
              stroke="#707070"
              stroke-width="2"
              stroke-linejoin="round"
            />
            <path
              d="M7.5 0V15"
              stroke="#707070"
              stroke-width="2"
              stroke-linejoin="round"
            />
          </svg>

          Add
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      typeItems: [],
      sortItems: [],
      sortBy: "",
      typeBy: "",
      solutions: [],
      favorites: [],
      search: "",
    };
  },

  props: [
    "title",
    "inviteButton",
    "searchInput",
    "newButton",
    "sort",
    "type",
    "placeholder",
    "addButton",
    "exportButton",
    "types",
    "sorts",
  ],
  created() {

    if (this.types) {
      this.typeItems = this.types;
    }

    if (this.sorts) {
      this.sortItems = this.sorts;
    }
  },
  computed: {
    devideRow() {
      let col = "";
      if (
        this.title &&
        !this.inviteButton &&
        !this.searchInput &&
        !this.newButton &&
        !this.sort
      ) {
        col = "onlyTitle";
      } else if (
        this.title &&
        !this.inviteButton &&
        this.searchInput &&
        !this.newButton &&
        !this.addButton &&
        !this.exportButton &&
        !this.sort
      ) {
        col = "titleSearch";
      } else if (
        this.title &&
        this.searchInput &&
        !this.sort &&
        (this.newButton ||
          this.inviteButton ||
          this.exportButton ||
          this.addButton)
      ) {
        col = "withButtons";
      } else if (
        this.title &&
        (this.newButton ||
          this.inviteButton ||
          this.exportButton ||
          this.addButton) &&
        this.searchInput &&
        this.sort
      ) {
        col = "everything";
      }
      return col;
    },
  },
};
</script>