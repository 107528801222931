<template>
  <div>
    <div class="row col-md-12 font-weight-bold light-blue-text">
      Solution Facilitator
      <v-divider class="mt-3 ml-3"></v-divider>
    </div>
    <div class="row mt-10">
      <div class="col-md-12 ml-3">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4">
                <span class="text-gray font-weight-bold">Name:</span>
              </div>
              <div class="col-md-8">
                <span class="text-gray font-weight-bold ml-4">{{item.facilitator.companyName}}</span>
              </div>
            </div>
          </div>
          <br>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4">
                <span class="text-gray font-weight-bold">Address:</span>
              </div>
              <div class="col-md-8">
                <span class="text-gray font-weight-bold ml-4">{{item.facilitator.address1 }}</span>
              </div>
            </div>
          </div> <br>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4">
                <span class="text-gray font-weight-bold">Website:</span>
              </div>
              <div class="col-md-8">
                <a :href="item.facilitator.website" class="text-gray font-weight-bold ml-4">{{item.facilitator.website }}</a>
              </div>
            </div>
          </div> <br>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4">
                <span class="text-gray font-weight-bold">Contact:</span>
              </div>
              <div class="col-md-8">
                <span class="text-gray font-weight-bold ml-4">{{item.facilitator.primaryContact.firstName +' '+item.facilitator.primaryContact.lastName }}</span>
              </div>
            </div>
          </div> <br>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4">
                <span class="text-gray font-weight-bold">Email:</span>
              </div>
              <div class="col-md-8">
                <a :href="'mailto:'+item.facilitator.primaryContact.email" class="text-gray font-weight-bold ml-4">{{ item.facilitator.primaryContact.email }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SolutionFacilitator",
  props:['item'],
}
</script>

<style scoped>

</style>