<template>
  <v-row class="add-client-form">
    <successModal ref="success_modal" v-on:confirm="sendMail"></successModal>
    <removeDialog
      ref="dialog_delete"
      v-on:confirm="confirmRemove"
    ></removeDialog>

    <div class="col-md-12 form-box">
      <div class="row">
        <div class="col-md-12">
          <h3 class="form-title font">User</h3>
        </div>
      </div>
      <div class="form-row-height">
        <v-form v-model.trim="valid" ref="form" class="row">
          <div class="col-md-6 pb-0">
            <label class="font text-gray" for="">First Name</label>
            <v-text-field
              required
              v-model.trim="firstName"
              solo
              :rules="requiredRules"
              tabindex="1"
            ></v-text-field>
          </div>
          <div class="col-md-6 pb-0">
            <label class="font text-gray" for="">Role </label>
            <v-select
              solo
              v-model.trim="role"
              :items="[
                { text: 'Admin', value: 'admin' },
                { text: 'User', value: 'user' },
              ]"
            ></v-select>
          </div>
          <div class="col-md-6 pb-0 pt-0">
            <label class="font text-gray" for="">Last Name</label>
            <v-text-field
              required
              tabindex="2"
              :rules="requiredRules"
              v-model.trim="lastName"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-6 pb-0 pt-0">
            <label class="font text-gray" for="">Office Name</label>
            <v-text-field
              required
              tabindex="6"
              v-model.trim="officeName"
              :rules="requiredRules"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-6 pb-0 pt-0">
            <label class="font text-gray" for="">Email</label>
            <v-text-field
              required
              disabled
              :rules="emailRules"
              v-model.trim="email"
              solo
              tabindex="3"
            ></v-text-field>
          </div>
          <div class="col-md-6 pb-0 pt-0">
            <label class="font text-gray" for="">Address</label>
            <v-text-field
              required
              tabindex="7"
              :rules="requiredRules"
              v-model.trim="address"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-6 pb-0 pt-0">
            <label class="font text-gray" for="">Title</label>
            <v-text-field
              required
              tabindex="4"
              :rules="requiredRules"
              v-model.trim="title"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-6 row pr-0">
            <div class="col-md-6 pb-0 pt-0">
              <label class="font text-gray" for="">City</label>
              <v-text-field
                required
                :rules="requiredRules"
                v-model.trim="city"
                solo
                tabindex="8"
              ></v-text-field>
            </div>
            <div class="col-md-3 pb-0 pt-0">
              <label class="font text-gray" for="">State</label>
              <v-select
                required
                :rules="requiredRules"
                :items="states"
                v-model.trim="state"
                solo
                tabindex="9"
                item-text="name"
                item-value="name"
              ></v-select>
            </div>
            <div class="col-md-3 zip-field-height pb-0 pt-0">
              <label class="font text-gray" for="">Zip</label>
              <v-text-field
                required
                type="number"
                v-model.trim="zip"
                :rules="zipRules"
                solo
                tabindex="10"
              ></v-text-field>
            </div>
          </div>
          <div
            class="col-md-6 font text-gray"
            v-if="!deactivateDelete && $role != 'user'"
          >
            <label for="">User Status</label>
            <div class="d-flex mt-2">
              In Active
              <v-switch
                v-model.trim="status"
                solo
                :disabled="removUserStatus"
                class="no-padding ml-2 mr-2 vendors-switch"
              ></v-switch>
              Active
            </div>
          </div>
          <div class="col-md-6" v-if="!deactivateDelete && $role != 'user'">
            <v-btn class="blue-buttons" @click="deleteUser()">
              Delete User
            </v-btn>
          </div>
        </v-form>
      </div>

      <div class="row py-2" v-if="$role != 'user'">
        <div class="col-md-12 row text-end justify-end">
          <v-btn class="blue-buttons mr-2" @click="goBack()"> Cancel </v-btn>
          <v-btn class="blue-buttons  ml-2" @click="updateUser()">
            Save/Update
          </v-btn>
        </div>
      </div>
    </div>
    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
  </v-row>
</template>
<script>
import successModal from "../../../../TablesTemplate/SuccessDialog.vue";
import removeDialog from "../../../../TablesTemplate/RemoveDialog.vue";
import errorModal from "../../../../TablesTemplate/ErrorDialog.vue";
import api from "../../../../../services/api";
export default {
  components: { successModal, removeDialog, errorModal },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      title: "",
      role: "",
      officeName: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      status: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
      zipRules: [
        (v) => (v && v.length == 5) || " Zip Code should be five digits long",
      ],
      states: [],
      valid: false,
      deactivateDelete: false,
      removUserStatus: false,
    };
  },
  computed: {
    routeParams() {
      return this.$route.params.name;
    },
    singleUser() {
      return this.$store.getters.getSingleUserEmpInEmployerAdvisor;
    },
  },
  created() {
    this.getStates();
    if (this.singleUser) {
      this.firstName = this.singleUser.firstName;
      this.lastName = this.singleUser.lastName;
      this.email = this.singleUser.email;
      this.title = this.singleUser.title ? this.singleUser.title : "";
      this.role =
        this.singleUser.groupPortal[0] && this.singleUser.groupPortal[0].role
          ? this.singleUser.groupPortal[0].role.name
          : "";

      this.officeName = this.singleUser.officeName
        ? this.singleUser.officeName
        : "";
      this.address = this.singleUser.address1 ? this.singleUser.address1 : "";
      this.city = this.singleUser.city ? this.singleUser.city : "";
      this.state = this.singleUser.state ? this.singleUser.state : "";
      this.zip = this.singleUser.zipCode ? this.singleUser.zipCode : "";
      this.status =
        this.singleUser.status && this.singleUser.status == "active"
          ? true
          : false;
    }
    if (localStorage.getItem("user")) {
      let user = JSON.parse(localStorage.getItem("user"));
      if (user.email == this.email) {
        this.deactivateDelete = true;
      }
    }
    if (this.singleUser.status == "invited") {
      this.removUserStatus = true;
    }
  },
  methods: {
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    goBack() {
      this.$router.push(
        `/employer-advisor/employers/${this.routeParams}/settings/users`
      );
    },
    deleteUser() {
      this.$refs.dialog_delete.openPopUp(
        "Are you sure that you want to delete this user?"
      );
    },
    confirmRemove() {
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.employerAdvisor;
      let employer = JSON.parse(localStorage.getItem("employer"));
      api()
        .delete(
          `/advisors/${advisorId}/employers/${employer._id}/users/${this.singleUser._id}`
        )
        .then((res) => {
          if (res) {
            this.$router.push(
              `/employer-advisor/employers/${this.routeParams}/settings/users`
            );
          }
        }).catch((err)=>{
          this.$refs.dialog_delete.closePopUp();
          this.$refs.error_modal.openPopUp(err.response.data.error)
        });
    },

    addUser() {
      this.$router.push(
        `/employer-advisor/employers/${this.routeParams}/settings/users`
      );
    },
    getStates() {
      api()
        .get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },
    sendMail() {
      this.$refs.success_modal.closePopUp();
      this.$router.push(
        `/employer-advisor/employers/${this.routeParams}/settings/users`
      );
    },
    updateUser() {
      this.$refs.form.validate();
      if (this.valid) {
        let data = {
          firstName: this.firstName,
          lastName: this.lastName,
          title: this.title,
          officeName: this.officeName,
          address1: this.address,
          city: this.city,
          state: this.state,
          zipCode: this.zip,
          role: this.role,
          status: this.status ? "active" : "inactive",
        };

        let user = JSON.parse(localStorage.getItem("user"));
        let advisorId = user.groupPortal[0].organization.employerAdvisor;
        let employer = JSON.parse(localStorage.getItem("employer"));
        api()
          .put(
            `/advisors/${advisorId}/employers/${employer._id}/users/${this.singleUser._id}`,
            data
          )
          .then((res) => {
            if (res) {
              this.firstName = res.data.user.firstName;
              this.lastName = res.data.user.lastName;
              this.title = res.data.user.title ? res.data.user.title : "";
              this.officeName = res.data.user.officeName
                ? res.data.user.officeName
                : "";
              this.address = res.data.user.address1
                ? res.data.user.address1
                : "";
              this.city = res.data.user.city ? res.data.user.city : "";
              this.state = res.data.user.state ? res.data.user.state : "";
              this.zip = res.data.user.zipCode ? res.data.user.zipCode : "";
              this.status =
                res.data.user.status && res.data.user.status == "active"
                  ? true
                  : false;

              let text = "User sucessfully updated";
              this.$refs.success_modal.openPopUp(text);
            }
          })
          .catch((err) => {
            if (err) {
              this.$refs.error_modal.openPopUp(err.response.data.error);
            }
          });
      }
    },
  },
};
</script>
