import Vue from "vue";
import VueRouter from "vue-router";


import EmployerAdvisor from './employer-advisors.js'
import ProviderAdvisor from './provider-advisor.js'
import Provider from './provider.js'
import Member from './members'
import Employer from './employer.js'
import EmployerClient from './employer-client.js'
import DPCProviders from './dpc-provider.js'
import Vendors from './vendors.js'
import FamilyMembers from './family-members.js'
import Marketplace from './marketplace.js'
import DigitalInvoicing from './digital-invoicing.js'
import PlanSponsor from './plan-sponsor.js'
import SolutionVendor from './solution-vendor.js'
import tpa from './tpa.js'
import DPCMmember from './dpc-member.js'
import testTPA from './testTpa.js'
import testDPC from './testDpc.js'
import Login from './login'
import Website from './website'
import NewLogin from './new-login'
import SuperAdmin from './superadmin'
import store from '../store/index.js';

Vue.use(VueRouter);


let routeArray = Login.concat(
  DPCMmember,ProviderAdvisor,Provider,Employer,Member,EmployerAdvisor,EmployerClient,
  DPCProviders,Vendors,FamilyMembers,Marketplace,DigitalInvoicing,PlanSponsor,SolutionVendor,
  tpa,testTPA, Website, NewLogin, SuperAdmin)

const routes = routeArray
const router = new VueRouter({
  mode: 'history',
  routes,
});
router.beforeEach((to, from, next) => {
  let pageTitle = to.name; // Extract page title from route parameters
  if(store.state.documentTitle != null){
    pageTitle = store.state.documentTitle
  }
  document.title = `Apaly Portal - ${pageTitle}`

  next();
});
router.afterEach((to, from) => {
  let pageTitle = to.name; // Extract page title from route parameters
  if(store.state.documentTitle != null){
    pageTitle = store.state.documentTitle
  }
  document.title = `Apaly Portal - ${pageTitle}`
 

});
export default router;
