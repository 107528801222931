<template>
  <div class="row-height  col-md-12">
    <v-row class="col-md-12">
      <v-col class="col-md-1">
        <h3>Providers</h3>
      </v-col>
      <v-col class="col-md-2">
        <v-select :items="items" item-text="providerName"></v-select>
      </v-col>
      <v-col class="col-md-2">
        <v-btn class="white-button-members col-md-12">
          <svg
            width="13"
            height="20"
            class="mr-3"
            viewBox="0 0 18 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.4286 8.71429C16.4286 12.9829 8.71429 23.2857 8.71429 23.2857C8.71429 23.2857 1 12.9829 1 8.71429C1 6.66833 1.81275 4.70617 3.25946 3.25946C4.70617 1.81275 6.66833 1 8.71429 1C10.7602 1 12.7224 1.81275 14.1691 3.25946C15.6158 4.70617 16.4286 6.66833 16.4286 8.71429V8.71429Z"
              stroke="#555555"
              stroke-width="1.71429"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8.71429 11.2854C10.1344 11.2854 11.2857 10.1342 11.2857 8.71401C11.2857 7.29385 10.1344 6.14258 8.71429 6.14258C7.29413 6.14258 6.14286 7.29385 6.14286 8.71401C6.14286 10.1342 7.29413 11.2854 8.71429 11.2854Z"
              stroke="#555555"
              stroke-width="1.71429"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Find Care
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="col-md-12">
        <!-- <div v-for="(item, index) in items" class="col-md-12" :key="index">
                  <v-card>Provider</v-card>
        </div> -->
     
      <v-simple-table fixed-header class="col-md-12">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Provider</th>
              <th class="text-left">Next Visit</th>
              <th class="text-left">Deductible</th>
              <th class="text-left">Monthly bill</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items" :key="index">
              <td>
                {{ item.providerName }}
              </td>
              <td>
                {{ item.deductible }}
              </td>
              <td>
                {{ item.visit }}
              </td>
              <td>
                {{ item.bill }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      items: [
        {
          providerName: "Eden Health",
          deductible: "$1,150 balance",
          visit: "10 Days",
          bill: "Autopay on",
        },
        {
          providerName: "William Smith",
          deductible: "$1,150 balance",
          visit: "10 Days",
          bill: "Autopay on",
        },
        {
          providerName: "Janice Smith",
          deductible: "$1,150 balance",
          visit: "10 Days",
          bill: "Autopay on",
        },
        {
          providerName: "John Smith",
          deductible: "$1,150 balance",
          visit: "10 Days",
          bill: "Autopay on",
        },
      ],
    };
  },
};
</script>
<style>
.white-button-members {
  background: white !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px !important;
  border-radius: 30px;
}
</style>