<template>
  <div class="page-background">
    <main-header :title="'Edit Family Member'" :color="'white'" ></main-header>
    <div class="mobile-edit-form">
      <form class="forma">
        <div>
          <h3 class="from-headers">Family Member’s Information</h3>
          <v-text-field outlined label="First Name" color="white" class="input-text"></v-text-field>
          <v-text-field outlined label="Last Name" color="white"></v-text-field>
          <v-text-field
              color="white"
              type="date"
              outlined
              label="Date of Birth"
          ></v-text-field>
          <v-select color="white" :items="genders" label="Select" outlined></v-select>
        </div>

        <div>
          <h3 class="from-headers">Contact</h3>
          <v-text-field type="email" outlined label="Email" color="white"></v-text-field>
          <v-text-field
              type="tel"
              outlined
              label="Phone"
              placeholder="000-000-0000 "
              color="white"
          ></v-text-field>
          <v-text-field
              color="white"
              type="number"
              placeholder="00000"
              outlined
              label="Home Zip Code"
          ></v-text-field>
        </div>

        <div>
          <h3 class="from-headers">
            Insurance Information <br />
            <span class="from-sub-headers">(from your insurance card)</span>
          </h3>

          <v-text-field
              outlined
              label="Member ID"
              color="white"
              placeholder="000-000000"
          ></v-text-field>

          <v-text-field
              outlined
              label="Group ID"
              placeholder="000-000000"
              color="white"
          ></v-text-field>

          <v-text-field
              outlined
              label="Payer ID"
              placeholder="000-000000"
              color="white"
          ></v-text-field>

          <v-select
              color="white"
              :items="relationships"
              label="Select"
              outlined
          ></v-select>
        </div>

        <div class="mb-6">
          <h3 class="from-headers">
           Provider <br />
          </h3>
          <div class="row">
            <div class="col-6 pt-0">
            <span class="provider-location" >Provider</span>  <br> <span class="from-sub-headers">Everside Health</span>
            </div>
            <div class="col-6 pt-0" >
              <span class="provider-location" >Location</span>  <br> <span class="from-sub-headers">Main Street West</span>

            </div>
          </div>
          <div class="row">
            <div class="col-12 pt-0">
              <v-btn class="btn-switch-providers" @click="saveDialog = true">Switch Providers</v-btn>
            </div>
          </div>
        </div>

        <div>
          <h3 class="from-headers">
            Family Member Management <br />
          </h3>
          <div class="row">
            <div class="col-12 pt-0">


              <v-btn class="btn-red-background mt-2" @click="removeDialog = true">Remove John Smith</v-btn>
            </div>
          </div>
        </div>
      </form>
      <v-dialog
          v-model="saveDialog"
          width="500"
      >

        <v-card>
          <v-icon class="icon-close-dialog" color="#16407A" v-text="'mdi-close'" @click="saveDialog = false" ></v-icon>

          <v-card-title class="dialog-title" >

            Would you like to save  your changes first?
          </v-card-title>

          <v-card-text>
            <v-btn class="btn-white-background" style="border: 1px solid white" >Leave without saving</v-btn>
            <v-btn class="btn-blue-background"  >Save and leave</v-btn>
          </v-card-text>

<div></div>
          <v-card-actions>
            <v-spacer></v-spacer>

          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="removeDialog"
          width="500"
      >

        <v-card>

          <v-card-title class="remove-dialog-title" >

            Are you sure you want to remove this family member from your account?
          </v-card-title>

          <v-card-text>
            <div class="row">
              <div class="col-6">
                <v-btn class="btn-white-background" style="border: 1px solid white" @click="removeDialog = false" >No, go back</v-btn>
              </div>

              <div class="col-6">
                <v-btn class="btn-red-background" style="border: 1px solid white" >Remove</v-btn>
              </div>
            </div>
          </v-card-text>

          <div></div>
          <v-card-actions>
            <v-spacer></v-spacer>

          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div class="d-flex footer"  style="">
      <div class="col-6">
        <v-btn class="footer-btn-cancel" @click="saveDialog = true" >Cancel</v-btn>
      </div>
      <div class="col-6">
        <v-btn color="white" class="footer-btn" >Save</v-btn>
      </div>
    </div>
  </div>


</template>

<script>
import mainHeader from "@/components/Sections/DPCMember/Layout/mainHeader";
export default {
  data() {
    return {
      saveDialog:false,
      removeDialog:false,
      relationships: ["Spouse"],
      genders: ["male", "female"],
      id:this.$route.params.id,

    };
  },
  components: {
    mainHeader,
  },
  methods: {},
  created() {},
};
</script>
<style scoped src='./../../../../../assets/mobileStyle.css'></style>
<style scoped lang="scss">
.mobile-edit-form {
  background-color: #16407a;
  height: 100vh;
  padding: 20px;
  overflow-y: auto;
  padding-bottom: 190px !important;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;
}

.mobile-content {
  padding: 16px 24px 0 24px;
}

.page-background{
  background-color: #001e46;
}

.from-headers {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  padding-bottom: 15px;
  color: white;
}

.from-sub-headers {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: white;
}

.footer{
  background-color: #16407a;
  height:80px;
  position:absolute;
  bottom:0;
  width:100%;
  align-items: flex-start;
  border-top: 1px solid #C9C9C9
}
.footer-btn{
  border-radius: 23px;
  width: 134px;
}

.footer-btn-cancel{
  background-color:#16407a !important;
  border-radius: 23px;
  width: 134px;
  border: 1px solid white !important;
  color: white;
  float:right
}
.btn-switch-providers {
  background-color:#16407a !important;
  border-radius: 23px;
  width: 100%;
  border: 1px solid white !important;
  color: white;
  float:right
}
::-webkit-scrollbar {
  display: none;
}

.btn-red-background{
  background-color: #C24141 !important;
  border-radius: 23px;
  width: 100%;
  border: 1px solid white !important;
  color: white;
  float:right
}
.provider-location{
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #C9C9C9;
}

.dialog-title{
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700 !important;
  font-size: 18px !important;
  color: #16407A;
  padding-right: 70px !important;
  padding-left: 70px !important;
  //padding-top: 30px !important;
  text-align: center;
}

.btn-white-background{
  background-color:white !important;
  border-radius: 23px;
  width: 100%;
  border: 1px solid  #16407A !important;
  color: #16407A;
  float:right;
  margin-bottom:10px;
}

.btn-blue-background {
  background-color:#16407a !important;
  border-radius: 23px;
  width: 100%;
  color: white;
  float:right
}

.icon-close-dialog::before{
  position: absolute;
  left: 300px;
  top: 0px;
}

.remove-dialog-title{
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700 !important;
  font-size: 18px !important;
  color: #16407A;
  //padding-right: 70px !important;
  //padding-left: 70px !important;
  //padding-top: 30px !important;
  text-align: center;
}

</style>
