import Template from '../views/SuperAdmin.vue'

import Home from '../components/Sections/SuperAdmin/Home/Home.vue'

import Providers from '../components/Sections/SuperAdmin/Providers/ProvidersTable.vue'
import AddProvider from '../components/Sections/SuperAdmin/Providers/AddProvider.vue'
import InviteProvider from '../components/Sections/SuperAdmin/Providers/InviteProviders.vue'
import SingleProvider from '../components/Sections/SuperAdmin/Providers/SingleProvider.vue'

import TPAs from '../components/Sections/SuperAdmin/TPAs/TPAsTable.vue'
import AddTPA from '../components/Sections/SuperAdmin/TPAs/AddTPA.vue'
import InviteTPA from '../components/Sections/SuperAdmin/TPAs/InviteTPA.vue'
import SingleTPA from '../components/Sections/SuperAdmin/TPAs/SingleTPA.vue'

import Vendors from '../components/Sections/SuperAdmin/Vendors/VendorsTable.vue'
import AddVendor from '../components/Sections/SuperAdmin/Vendors/AddVendor.vue'
import InviteVendor from '../components/Sections/SuperAdmin/Vendors/InviteVendors.vue'
import SingleVendor from '../components/Sections/SuperAdmin/Vendors/SingleVendor.vue'

import Settings from '../components/Sections/SuperAdmin/Settings/Settings.vue'
import Account from '../components/Sections/SuperAdmin/Settings/AccountForm.vue'
import AddUser from '../components/Sections/SuperAdmin/Settings/AddUser.vue'
import User from '../components/Sections/SuperAdmin/Settings/UsersCrud.vue'
import InviteUser from '../components/Sections/SuperAdmin/Settings/InviteUser'
import MyProfile from "../components/TablesTemplate/MyProfile.vue"
function routeGuard(to, from, next) {
    let user = JSON.parse(localStorage.getItem('user'));
    if (!user.superAdmin || !$cookies.get('token')){
      next('/login');
    }
      next();
}

let role = "user"
if(localStorage.getItem('user')){
    let user = JSON.parse(localStorage.getItem('user'));
    role = user.role;
  }else{
   role = "no-login"
  }
function routeGuardRole(to, from, next){
    if(role == 'user'){
     next('/login');
    }
    next();
 }
const router = [
 
    {
        path:'/superadmin/home',
        component:Template,
        name:'Home',
        beforeEnter: routeGuard,
    }, 

    {
        path:'/superadmin',
        component:Template,
        name:'Home',
        beforeEnter: routeGuard,
        children:
        [
            {
                path:'home',
                component:Home,
                name: 'Home',
            },
            {
                path:'providers',
                component:Providers,
                name: 'Providers',
                children:[
                    {
                        path:'add-new',
                        name:'Add New Provider',
                        component:AddProvider,
                        beforeEnter:routeGuardRole
                    },
                    {
                        path:'invite-provider',
                        name:'Invite New Provider',
                        component:InviteProvider,
                        beforeEnter:routeGuardRole
                    },
                    {
                        path:'provider',
                        name:'Provider',
                        component:SingleProvider
                    }

                ]
            },
            {
                path:'tpas',
                component:TPAs,
                name: 'TPAs',
                children:[
                    {
                        path:'add-new',
                        name:'Add New TPA',
                        component:AddTPA,
                    },
                    {
                        path:'invite-tpa',
                        name:'Invite New TPA',
                        component:InviteTPA,
                    },
                    {
                        path:'tpa',
                        name:'TPA',
                        component:SingleTPA
                    }

                ]
            },
            {
                path:'vendors',
                component:Vendors,
                name: 'Vendors',
                children:[
                    {
                        path:'add-new',
                        name:'Add New Vendor',
                        component:AddVendor
                    },
                    {
                        path:'invite-vendor',
                        name:'Invite New Vendor',
                        component:InviteVendor
                    },
                    {
                        path:'vendor',
                        name:'Vendor',
                        component:SingleVendor
                    }

                ]
            },
            {
                path:'profile',
                name:'My Profile',
                component:MyProfile
            },
            
                {
                path: "settings",
                component:Settings,
                name:'Settings',
                children:
                [
                    {
                        path:'account',
                        name:'Account',
                        component:Account
                    },
                    {
                        path: "users",
                        name:'Users',
                        component: Settings,
                        children:[
                                {
                                    path:'user',
                                    name:'User',
                                    conponent:User,
                                },
                                {
                                    path:'add-new',
                                    name:'Add New User',
                                    component: AddUser
                                },
                                {
                                    path:'invite-user',
                                    name:'Invite User',
                                    component: InviteUser
                                },
                        ]
                    },
                ]     
            },
        ]

    },

        
  
]

export default router;