<template>
    <div class="no-padding no-margin">
        <Networks v-if="route == `/provider-advisor/providers/${routeParams}/networks`"></Networks>
        <NetworkBuilder  v-if="route == `/provider-advisor/providers/${routeParams}/networks/builder`"></NetworkBuilder>
        <Network  v-if="route == `/provider-advisor/providers/${routeParams}/networks/network`"></Network>
    </div>
</template>
<script>

import NetworkBuilder from './NetworkBuilder.vue'
import Networks from './NetworksTable.vue'
import Network from './SingleNetwork.vue'
export default{
    data(){
        return{
        
        }
    },
    components:{
        Network,
        NetworkBuilder,
        Networks,
    },
    computed:{
        route(){
             return this.$route.path.replace(/%20/g,' ')
        },
        routeParams(){
            return this.$route.params.name
        }
    }
}
</script>