<template>
    <div>
       <PriorTable v-if="route == `/provider/prior-auth`"></PriorTable>
       <PriorRequest v-if="route == `/provider/prior-auth/new-request`"></PriorRequest>
       <PriorDetermination v-if="route == `/provider/prior-auth/determination`"></PriorDetermination>
       <Details v-if="route == `/provider/prior-auth/details`"></Details>
    </div>
</template>
<script>
import PriorTable from './PriorTable.vue'
import PriorRequest from './PriorRequest.vue'
import PriorDetermination from './PriorDetermination.vue'
import Details from './Details.vue'
export default{
    components:{
        PriorTable,
        PriorRequest,
        PriorDetermination,
        Details
    },
    computed:{
       route(){
          return this.$route.path
      },
      routeParams(){
             let parsed = ''
            if(this.$route.params.name){
               parsed = this.$route.params.name.replace(/ /g, '%20')
            }
           
            return parsed
      }
    },
    data(){
        return{

        }
    },
    
}
</script>