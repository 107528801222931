<template>
  <div class="form-div">
    <v-card class="col-lg-8 col-xl-6 col-md-6 blue-text">
      <h2 class="text-center blue-text">
        Great...! Tell us a bit about your organization.
      </h2>
      <v-form class="col-md-12 mt-2" ref="form" v-model.trim="valid">
        <v-row>
          <div class="col-md-12">
            <label for="" class="tiny-font text-gray">Company Legal Name</label>
            <v-text-field
              solo
              type="text"
              :rules="rules"
              v-model.trim="companyName"
              required
            ></v-text-field>
          </div>
          <div class="col-md-12">
            <label for="" class="tiny-font text-gray"
              >Company Display Name (the name that employers and members will
              see)</label
            >
            <v-text-field
              solo
              type="text"
              :rules="rules"
              v-model.trim="companyDisplayName"
              required
            ></v-text-field>
          </div>
          <div class="col-md-12">
            <label for="" class="tiny-font text-gray">Tax ID</label>
            <v-text-field
              solo
              type="text"
              @keyup="showSlash($event)"
              v-model.trim="taxId"
              required
              placeholder="00-0000000"
              :rules="taxIdRules"
              counter="10"
              maxlength="10"
            ></v-text-field>
          </div>
          <div class="col-md-12">
            <label for="" class="tiny-font text-gray"
              >Your Title with the Organization</label
            >
            <v-text-field
              solo
              type="text"
              :rules="rules"
              v-model.trim="title"
              required
            ></v-text-field>
          </div>

          <!-- <div class="col-md-12" v-if="showSwitch">
            <label for="" class="tiny-font text-gray">DPC Provider</label>
            <div class="d-flex mt-2 text-gray">
              NO
              <v-switch
                class="no-padding vendors-switch"
                v-model.trim="dpc"
                
              ></v-switch>
              YES
            </div>
          </div> -->
        </v-row>

        <v-col class="d-flex justify-end" align="end">
          <v-btn
            :disabled="taxId.length != 10"
            depressed
            class="select-btn mt-2 ml-3 col-md-3"
            @click="submit"
          >
            Next
          </v-btn>
        </v-col>
      </v-form>
    </v-card>
  </div>
</template>
<script>
import api from "../../../../services/api";
export default {
  data() {
    return {
      show1: false,
      companyName: "",
      companyDisplayName: "",
      title: "",
      taxId: "",
      rules: [(v) => !!v || "This field is required"],
      valid: false,
      dpc: false,
      showSwitch:false,
      taxIdRules: [
        (v) => !!v || "This field is required",
        (v) =>
          !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
          "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
      ],
    };
  },
  created() {
    this.showSwitch = false;
    let t = localStorage.getItem("type");
    if (t && JSON.parse(t) == "provider") {
      this.showSwitch = true;
    }
    this.$store.commit(
      "sideBarTitleAndDesc",
      "“Love the life you live. Live the life you love.”"
    );
    this.$store.commit("sideBarDesc", "Bob Marley");
    if (this.$store.getters.getOrganizationInfo) {
      this.companyName = this.$store.getters.getOrganizationInfo.companyName;
      this.companyDisplayName =
        this.$store.getters.getOrganizationInfo.companyDisplayName;
      this.title = this.$store.getters.getOrganizationInfo.title;
      this.taxId = this.$store.getters.getOrganizationInfo.taxId;
       this.dpc = this.$store.getters.getOrganizationInfo.dpc;
    } else if (localStorage.getItem("accountInfo")) {
      let info = JSON.parse(localStorage.getItem("accountInfo"));
      this.companyName = info.companyName;
      this.companyDisplayName = info.displayName ? info.displayName : "";
      this.taxId = info.taxId ? info.taxId : "";
      this.title = info.title ? info.title : "";
       this.dpc = info.dpc ? info.dpc : false;
    }
  },

  methods: {
    showSlash(event) {
      if (this.taxId.length == 2 && event.keyCode != 8) {
        this.taxId += "-";
      }
      if (this.taxId.length == 10) {
        api()
          .get("auth/isValidTaxId?taxId=" + this.taxId)
          .catch((res) => {
            if (res) {
              this.taxIdRules = [res.response.data.message];
            }
          });
      } else {
        this.taxIdRules = [
          (v) => !!v || "This field is required",
          (v) =>
            !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
            "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
        ];
      }
    },
    submit() {
      this.$refs.form.validate();
      if (this.valid) {
        let user = {
          companyName: this.companyName,
          companyDisplayName: this.companyDisplayName,
          title: this.title,
          taxId: this.taxId,
          // dpc:this.dpc,
          dpc: true
        };
         if(this.showSwitch){
          user.showSwitch = true
         }
        this.$store.commit("setOrganizationInfo", user);
        this.$router.push("/register/domain-list");
      }
    },
  },
};
</script>
