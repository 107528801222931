<template>
   <div>
       <div class="row">
        <v-card class="col-md-12" >
            <errorModal  ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
            <v-card-title>Member Search</v-card-title>
            <v-card-text class="row ">
                 <div class="col-md-3">
                    <label for="" class="font text-gray">Member ID</label>
                    <v-text-field solo v-model.trim="memberId"></v-text-field>
                </div>
                 <div class="col-md-3">
                    <label for="" class="font text-gray">Last Name</label>
                    <v-text-field solo v-model.trim="lastName"></v-text-field>
                </div>
                 <div class="col-md-3">
                    <label for="" class="font text-gray">First Name</label>
                    <v-text-field solo v-model.trim="firstName"></v-text-field>
                </div>
                 <div class="col-md-1">
                    <label for="" class="font text-gray">DOB</label>
                    <v-text-field solo v-model.trim="dob"></v-text-field>
                </div>
                 <div class="col-md-2">
                    <label for="" class="font text-gray">Last 4 of Social</label>
                    <v-text-field solo type="number" v-model.trim="social"></v-text-field>
                </div>
                 <div class="col-md-4">
                    <label for="" class="font text-gray">City</label>
                    <v-text-field solo v-model.trim="city"></v-text-field>
                </div>
                  <div class="col-md-2">
                    <label for="" class="font text-gray">State</label>
                    <v-text-field solo v-model.trim="state"> </v-text-field>
                </div>
               <div class="col-md-2">
                  <label for="" class="font text-gray">Zip</label>
                  <v-text-field solo type="number" v-model.trim="zip"></v-text-field>
                </div>
                <div class="col-md-4 text-end justify-center align-center mt-5">
                  <v-btn class="blue-buttons mr-2" @click="goBack">Cancel</v-btn>
                  <v-btn class="blue-buttons" @click="searchMembers">Search for Member</v-btn>
                </div>
            </v-card-text>
        </v-card>
      </div>
      <div class="row justify-center aling-center mt-10">
       <v-card class="col-md-12 ">
        <div class="font row col-md-12 justify-space-between">
            <div class="col-md-10" cols="10">Recent Member</div>
            <!-- <div class="col-md-1" cols="2">
                <v-btn class="blue-buttons " >Refresh</v-btn>

            </div> -->
        </div>
        <v-simple-table fixed-header  class="mt-5" >
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                 Member Name
              </th>
              <th class="text-left">
                Member ID
              </th>
              <th class="text-left">
                Eligibility
              </th>
              <th class="text-left">
                Prior Auth Status
              </th>
              <th class="text-left">
                Actions
              </th>
            </tr>
          </thead>
           <tbody v-if="items.length < 1">
             <tr>
               <td>
                   <a> No data found</a>
               </td>
             </tr>
          </tbody>
          <tbody>
            <tr
              v-for="item in items"
              :key="item.name"
            >
              <td><a    @click="singleMember(item)">{{ item.firstName }} {{item.lastName}}</a></td>
              <td class="text-gray">{{ item._id }} </td>
              <td v-html="item.status.charAt(0).toUpperCase() + item.status.slice(1)" :style="item.status == 'inactive' ? {'color':'red'} : {'color':'green'}">{{ item.status }}</td>
              <td >Pending</td>
              <td>
                  <a >New Prior Auth</a>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      </v-card>

      </div>
   
     
</div>
</template>
<script>
import api from '../../../../services/api'
import errorModal from '../../../TablesTemplate/ErrorDialog.vue'
export default{
  components:{
    errorModal
  },
    data(){
        return{
         items: [],
         memberId:'',
         lastName:'',
         firstName:'',
         dob:'',
         social:'',
         city:'',
         state:'',
         zip:''
        }
    },
    methods:{
        closeErrorModal(){
               this.$refs.error_modal.closePopUp();
        },
        singleMember(item){
            this.$store.commit('singleMember', item);
            this.$router.push(`/provider/members/member`)
        },
        searchMembers(){
            let urlData = ``
            urlData += this.firstName ? `firstName=${this.firstName}&` : ''
            urlData += this.lastName ? `lastName=${this.lastName}&` : ''
            urlData += this.memberId ? `memberId=${this.memberId}&` : ''
            urlData += this.dob ? `birthdate=${this.dob}&` : ''
            urlData += this.city ? `city=${this.city}&` : ''
            urlData += this.state ? `state=${this.state}&` : ''
            urlData += this.zip ? `zipCode=${this.zip}&` : ''
            urlData += this.social ? `ssn=${this.social}&` : ''
           let user = JSON.parse(localStorage.getItem('user'));
           let provider = user.entity._id
           let advisor = user.groupPortal[0].organization.advisor
        
     

            api().get(`/providers/${provider}/searchMembers?${urlData}`).then((res)=>{
                if(res){
                  if(res.data.members.length > 0){
                    this.items = res.data.members
                  }else{
                    this.$refs.error_modal.openPopUp('No Results Found. Please try again')
                  }
                   
                }
            })
           
        },
        goBack(){
 
        }
    }
}
</script>