<template>
  <div>
    <div v-if="!load" :class="showOverlay ? ' postion-relative' : ''">
      <div v-if="showOverlay" class="wizard-absolute" style="border-radius: 7px">
        <div class="col-md-12">
          <Dialog @closeDialog="closeDialog" :plans="plans" @getData="getSolutions" @refreshData="refreshData" :currentSolutions="currentSolutions"></Dialog>
        </div>
        <br />
        <br />
      </div>
      <div v-if="!isChildren">
        <Header
          :title="'Solutions'"
          :searchInput="true"
          :inviteButton="false"
          :newButton="true"
          @invite="invite"
          @add="add"
          @searchInput="searchInput"
          :placeholder="'Search Solutions'"
          :sort="false"
          :type="false"
          :types="types"
          :sorts="sortItems"
          @filterItemsByType="filterItemsByType"
          @sortItemsByDate="sortItemsByDate"
        ></Header>
        <div class="mt-3 row-height">
          <v-simple-table fixed-header height="900" class="no-padding tr-custom-height">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">SOLUTION / PACKAGE NAME</th>
                  <th class="text-left">TYPE</th>
                  <th class="text-left">CATEGORY</th>
                  <th class="text-left">PLANS FOR SOLUTION</th>
                  <th class="text-left">STATUS</th>
                </tr>
              </thead>
              
              <tbody v-if="items.length > 0 && !filterItems " class="text-gray tr-custom-height">
                <tr v-for="(item, index) in items" :key="index">
                  <!-- {{ item }} -->
                  <td>
                      <a class="text-underline" @click='solutionDetails(item)'>{{ item.solution }}</a>
                  </td>
                  <td>{{ item.type ? item.type : "/" }}</td>
                  <td>{{ item.category ? item.category : "/" }}</td>
                  <td v-if="item.plans">
                    <v-select style="width: 300px !important"
                    disabled
                      solo 
                      hide-details
                       multiple
                      item-value="_id"
                      item-text="name"
                      v-model="item.plans"
                      :items="plans"
                      @input="changePlans(index)"
                    ></v-select>
                  </td>
                  <td v-else>/</td>
                  <td>
                    <span>
                      {{ item.status ? item.status.charAt(0).toUpperCase() + item.status.slice(1) : "/" }}
                    </span>
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="items.length < 1 && filterItems">
                ////
                <v-col md="12" cols="12">
                  <v-alert border="bottom" colored-border type="warning" elevation="2" class="text-gray">
                    Sorry! No results found
                  </v-alert>
                </v-col>
              </tbody>

              <tbody v-else>
                <v-col md="12">There are no solutions currently activated for this client.
                  <a @click="showOverlay = true;">Click to activate solutions for this client</a></v-col>
              </tbody>
            </template>
          </v-simple-table>
          <br /><br />
        </div>
      </div>
      <successModal
        ref="success_modal"
        v-on:confirm="closeSuccessModal"
      ></successModal>

      <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
    </div>
    <div v-else>
      <div class="col-md-12 row-height d-flex align-center justify-center">
        <v-progress-circular class="text-center" size="90" indeterminate color="primary"></v-progress-circular>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import Header from "../../../Layout/HeaderPage.vue";
import Dialog from "./SolutionsDialog.vue";
import api from "../../../../../../services/api";
import successModal from "../../../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../../../TablesTemplate/ErrorDialog.vue";
export default {
  components: { Header, Dialog, successModal, errorModal },
  data() {
    return {
      currentSolutions: [],
      showDetails: false,
      tab: "solution",
      dialog: false,
      items: [],
      singleItem: null,
      types: [],
      typeItems: [
        { text: "All Categories" },
        { text: "DPC" },
        { text: "Others" },
      ],
      sortItems: [{ text: "Newest" }, { text: "Oldest" }],
      sortBy: "",
      type: "",
      solutions: [],
      favorites: [],
      filterArray: [],
      showOverlay: false,
      allSolutions: [],
      plans: [],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
      valid: false,
      companyName: "",
      firstName: "",
      lastName: "",
      email: "",
      filterItems: false,
      load: false,
    };
  },
  created() {
    this.getSolutions();
    this.getPlans();
  },
  computed: {
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
  },
  methods: {
    solutionDetails(item){
      this.singleItem = item;
      this.$store.commit("setTpaSingleSolution", item);
      if(item.type == 'Virtual Network')
      {
        this.$router.push(`/tpa/clients/${this.$route.params.clientId}/solutions/details/${item.id}`);
      }
      else{
        this.$router.push(`/tpa/clients/${this.$route.params.clientId}/solutions/closed/details/${item.id}`);
      }
    },
    sortItemsByDate(type) {
      if (type == "Oldest") {
        this.items = this.filterArray.sort(
          (a, b) =>
            Number(new Date(a.solution.createdAt)) -
            Number(new Date(b.solution.createdAt))
        );
      } else {
        this.items = this.filterArray.sort(
          (a, b) =>
            Number(new Date(b.solution.createdAt)) -
            Number(new Date(a.solution.createdAt))
        );
      }
    },
    filterItemsByType(type) {
      if (type != "All") {
        this.items = this.filterArray.filter((el) => {
          
          return el.solution.type == type;
        });
      } else {
        this.items = this.filterArray;
      }
    },
    refreshData() {
      this.getSolutions();
    },
    getPlans() {
      api().get(`/tpas/clients/${this.$route.params.clientId}/plans`)
        .then((res) => {
        let results = res.data;
          results.forEach(element => {
            if(element.status == 'active')
              this.plans.push(element);
          });

        });
    },
    add() {
      this.showOverlay = true;
    },
    invite() {},

    closeErrorModal() {
      this.dialog = false;
      this.companyName = "";
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.$refs.success_modal.closePopUp();
      this.$refs.error_modal.closePopUp();
    },
    closeSuccessModal() {
      this.dialog = false;
      this.companyName = "";
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.$refs.success_modal.closePopUp();
    },
    changePlans(index) {
  
    },
    getSolutions() {
      this.load = true;
      api()
        .get(`/tpas/clients/${this.$route.params.clientId}/solutions`)
        .catch((err) => {
          this.showOverlay = false;
          this.load = false;
        })
        .then((res) => {
          if(this.$route.query.action == 'activate'){
             this.showOverlay = true;
          }
         else{
          this.showOverlay = false;
         }
          this.items = res.data;
          this.currentSolutions = this.items;
          this.filterArray = res.data;
          this.$store.commit('setClientSolutions', res.data)
          this.load = false;
          this.types.push('All');
          this.items.forEach(element => {
            if(element.solution){
                this.types.push(element.solution.type);
            }
            
          });

          const unique = (value, index, self) => {
           return self.indexOf(value) === index
           }
          this.types = this.types.filter(unique)
        });
    },
    fillArray(item, index) {
      if (!item.rating) {
        this.favorites.push(item);
      } else {
        this.favorites.splice(this.favorites.indexOf(item), 1);
      }
    },
    closeDialog() {
      this.showOverlay = false;
    },
    searchInput(val) {
      this.items = this.filterArray.filter((el) => {
          return el.solution.name.toString().toLowerCase().includes(val.toLowerCase())
              || el.solution.category.toString().toLowerCase().includes(val.toLowerCase());
      });
      this.filterItems = (this.items.length < 1)
    },
  },
};
</script>
