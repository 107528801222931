<template>
    <v-row class="padding">
        <div class="row col-md-12">
           <h3>
             <span > Add Providers </span>
          </h3>
        </div>
        <v-form class="row col-md-12" ref="form" v-model.trim="valid">
            <div class="col-md-6">
                <label for="" class="text-gray">First Name</label>
                 <v-text-field v-model.trim="firstName" solo :rules="requiredRules"> </v-text-field>
            </div>
            <div class="col-md-6">
                <label for="" class="text-gray">Last Name</label>
                 <v-text-field  v-model.trim="lastName" solo :rules="requiredRules"> </v-text-field>
            </div>
            <div class="col-md-12">
                <label for="" class="text-gray">Organization Name</label>
                 <v-text-field :rules="requiredRules" v-model.trim="organizationName" solo></v-text-field>
            </div>
            <div class="col-md-6">
                <label for="" class="text-gray">City</label>
                 <v-text-field v-model.trim="city" :rules="requiredRules" solo></v-text-field>
            </div>
            <div class="col-md-3">
                <label for="" class="text-gray">State</label>
                 <v-select solo v-model.trim="state" :rules="requiredRules" :items="states" item-text="name"  item-value="name"></v-select>
            </div>
            <div class="col-md-3">
                <label for="" class="text-gray">Zip</label>
                 <v-text-field  v-model.trim="zip" solo :rules="requiredRules"></v-text-field>
            </div>
        </v-form >
        <div class="row col-md-12">
            <div class="col-md-12 justify-end text-end">
               <v-btn  class="blue-buttons" @click="addProvider">Submit</v-btn>
            </div>
           
        </div>
      <successModal  ref="success_modal" v-on:confirm="closeSuccessModal"></successModal>
 </v-row>
</template>
<script>
import api from '../../../../services/api'
import successModal from '../../../TablesTemplate/SuccessDialog.vue'
export default{
    components:{
      successModal
    },
    data(){
        return{
           states:[],
           state:'',
           firstName:'',
           lastName:'',
           organizationName:'',
           city:'',
           zip:'',
           requiredRules: [
                v => !!v || 'This field is required',
           ],
           valid:false,
             
        }
    },
    computed:{
        route(){
             return this.$route.path
        },
        getClient(){
            return this.$store.getters.getClientGetter
        }
    },
    created(){
        this.getStates();
    },
    methods:{
    closeSuccessModal(){
      this.$router.push('/member/providers');
    },
     getStates(){
        api().get('/api/states').then((res)=>{
            res.data.forEach((el)=>{
                if(el.active){
                    this.states.push(el);
                }
            })
        })
    },
      addProvider(){
          let data = {
                "firstName": this.firstName,
                "lastName": this.lastName,
                "organizationName": this.organizationName,
                "city": this.city,
                "state": this.state,
                "zipCode": this.zip

          }
          let user = JSON.parse(localStorage.getItem('user'));
          let member = user.member
         
          this.$refs.form.validate();
          if(this.valid){
               api().post(`/members/${member}/requestProvider`,data ).then((res)=>{
                   if(res){
                       this.$refs.success_modal.openPopUp('Request sent successfully!')
                   }
           
            })
            

          }
          
      }
    }
}
</script>
<style>
    .padding{
        padding:2rem ;
    }
    .border-right{
        border-right: 2px solid rgb(226, 221, 221);
    }
    .upload-box{
        padding-top: 3rem;
        align-items: center;
    }
    .upload-provider-box{
        border: 5px solid rgb(226, 221, 221);
        border-style: dotted;
        border-radius: 10px;
        padding: 4rem;
    }
    .downoald-file-btn-box{
        border: 5px solid gainsboro;
        padding: 1rem;
        border-radius: 10px;
    }
    .btn-box{
        padding: 1.5rem;
    }
   @media only screen and (max-width: 600px) {
    .padding {
       padding: 10px ;
    }
    .upload-box{
        padding: 0px;
    }
 }
</style>