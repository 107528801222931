<template>
  <div class="add-client-form">
    <div>
      <h3 class="font">Prior Authorization Request</h3>
    </div>
    <v-row>
      <v-col md="12">
        <h5 class="text-gray font underline-text">Member Information</h5>
      </v-col>
      <v-col md="10">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left text-gray font">First Name</th>
                <th class="text-left text-gray font">Last Name</th>
                <th class="text-left text-gray font">Sex</th>
                <th class="text-left text-gray font">Status on Plan</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="font">
                  <a>Valarie</a>
                </td>
                <td class="font">
                  <a>Vaughn</a>
                </td>
                <td>Female</td>
                <td class="font">Active</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col md="12">
        <h5 class="text-gray font underline-text">Provider Information</h5>
      </v-col>
      <v-col md="10">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left text-gray font">Organization Name</th>
                <th class="text-left text-gray font">Type</th>
                <th class="text-left text-gray font">Quality Rating</th>
                <th class="text-left text-gray font">City</th>
                <th class="text-left text-gray font">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="font">
                  <a>Manatee Memorial Hospital</a>
                </td>
                <td class="font">Hospital</td>
                <td>
                  <v-rating
                    v-model.trim="rating"
                    color="yellow darken-3"
                    background-color="grey darken-1"
                    empty-icon="$ratingFull"
                    half-increments
                  ></v-rating>
                </td>
                <td class="font">Tampa</td>
                <td class="font green-text">Matched</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-row class="mt-4">
        <div class="col-md-12">
          <h4 class="font text-gray">Procedure Information</h4>
        </div>
      </v-row>
      <v-row>
        <div class="col-md-2">
          <label class="font text-gray" for=""
            >Procedure Code Type
            <i class="fas fa-question-circle yellow-icons"></i
          ></label>
          <v-select solo></v-select>
        </div>
        <div class="col-md-2">
          <label class="font text-gray" for=""
            >Procedure Code <i class="fas fa-question-circle yellow-icons"></i
          ></label>
          <v-select solo></v-select>
        </div>
        <div class="col-md-5">
          <label class="font text-gray" for=""
            >Procedure Description
            <i class="fas fa-question-circle yellow-icons"></i
          ></label>
          <v-select solo></v-select>
        </div>
        <div class="col-md-3">
          <label class="font text-gray" for=""
            >East Procedure Cost (USD)
            <i class="fas fa-question-circle yellow-icons"></i
          ></label>
          <v-text-field solo></v-text-field>
        </div>
      </v-row>
      <v-col md="12" cols="12">
        <h5 class="text-gray font underline-text">Comments</h5>
      </v-col>
      <v-col md="12">
        <h5 class="font" cols="12">
          This is a comment entered by the provider requesting the Prior
          Authorization.
        </h5>
      </v-col>

      <v-col md="12">
        <h5 class="text-gray font underline-text">
          Procedure Prerequisite Requirements
        </h5>
      </v-col>
      <v-col md="10">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left text-gray font">Name</th>
                <th class="text-left text-gray font">Category</th>
                <th class="text-left text-gray font">Code</th>
                <th class="text-left text-gray font">Description</th>
                <th class="text-left text-gray font text-center">
                  Prerequisite Requirements
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="font">MRI Knee</td>
                <td class="font">CPT</td>
                <td>G187</td>
                <td class="font">CT Imaging of Knee</td>
                <td class="font text-center">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.59961 14.3057L9.21294 18.0145C9.31207 18.1626 9.44513 18.2848 9.60105 18.3711C9.75696 18.4574 9.93123 18.5052 10.1093 18.5105C10.2874 18.5158 10.4643 18.4785 10.625 18.4017C10.7858 18.3249 10.926 18.2108 11.0337 18.0689L19.3996 7.48438"
                      stroke="#069B0C"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M1 13C1 16.1826 2.26428 19.2348 4.51472 21.4853C6.76516 23.7357 9.8174 25 13 25C16.1826 25 19.2348 23.7357 21.4853 21.4853C23.7357 19.2348 25 16.1826 25 13C25 9.8174 23.7357 6.76516 21.4853 4.51472C19.2348 2.26428 16.1826 1 13 1C9.8174 1 6.76516 2.26428 4.51472 4.51472C2.26428 6.76516 1 9.8174 1 13V13Z"
                      stroke="#069B0C"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </td>
              </tr>
              <tr>
                <td class="font">14 Days of NSAIDs</td>
                <td class="font">Medication</td>
                <td>None</td>
                <td class="font">Pretreatment with anti-inflammatory</td>
                <td class="font text-center">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 13C1 16.1826 2.26428 19.2348 4.51472 21.4853C6.76516 23.7357 9.8174 25 13 25C16.1826 25 19.2348 23.7357 21.4853 21.4853C23.7357 19.2348 25 16.1826 25 13C25 9.8174 23.7357 6.76516 21.4853 4.51472C19.2348 2.26428 16.1826 1 13 1C9.8174 1 6.76516 2.26428 4.51472 4.51472C2.26428 6.76516 1 9.8174 1 13V13Z"
                      stroke="#D00000"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.19922 17.8002L17.7992 8.2002"
                      stroke="#D00000"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17.7992 17.8002L8.19922 8.2002"
                      stroke="#D00000"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <v-row justify="end">
      <v-btn
        class="blue-buttons mr-4"
        @click="$router.push(`/provider/prior-auth/`)"
        >Cancel</v-btn
      >
      <v-btn class="blue-buttons" @click="dialog = true">Submit</v-btn>
    </v-row>
    <div class="text-center">
      <v-dialog v-model.trim="dialog" width="700" class="success-modal">
        <v-card>
          <v-card-text class="success-modal">
            <div>
              <h1 class="text-underline font">Prior Authorization Request</h1>
            </div>
            <div class="font mt-5 mb-5">
              <h2>A prior authorization is not required for this procedure.</h2>
            </div>
            <div class="text-center">
              <svg
                width="210"
                height="210"
                viewBox="0 0 210 210"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M45 119.235L69.5 159.597C70.4293 161.209 71.6768 162.539 73.1385 163.478C74.6002 164.417 76.2339 164.937 77.9037 164.995C79.5735 165.052 81.231 164.647 82.7384 163.811C84.2458 162.975 85.5595 161.733 86.57 160.189L165 45"
                  stroke="#0E8F32"
                  stroke-width="5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <circle
                  cx="105"
                  cy="105"
                  r="102.5"
                  stroke="#0E8F32"
                  stroke-width="5"
                />
              </svg>
            </div>
            <h2 class="font mt-2 mb-5">
              We will save this response on your Apaly Healt portal, un the
              Member's account, and you can also print a copy if you wish.
            </h2>
            <div class="row justify-space-between mt-2">
              <v-btn class="blue-buttons" @click="dialog = false">Close</v-btn>
              <v-btn
                class="blue-buttons"
                @click="(dialog = false), (dialog1 = true)"
                >Print</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model.trim="dialog1" width="700" class="success-modal">
        <v-card>
          <v-card-text class="success-modal">
            <div>
              <h1 class="text-underline font">Prior Authorization Request</h1>
            </div>
            <div class="font mt-5 mb-5">
              <h2>A prior authorization is required for this procedure.</h2>
            </div>
            <div class="row text-center justify-center set-padding">
              <v-btn
                class="blue-buttons"
                @click="$router.push(`/provider/prior-auth/new-request`)"
                >Complete & Submit Prior Authorization Request</v-btn
              >
            </div>
            <h2 class="font mt-2 mb-5">
              Click the button above to complete the prior authorization request
              for this procedure.
            </h2>
            <div class="row justify-space-between mt-2">
              <v-btn
                class="blue-buttons"
                @click="(dialog = false), (dialog1 = false)"
                >Close</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
      dialog1: false,
    };
  },
  computed: {
    routeParams() {
      let parsed = this.$route.params.name.replace(/ /g, "%20");
      return parsed;
    },
  },
};
</script>
<style>
.set-padding {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}
</style>