<template>
  <div class="row justify-center mt-4">
    <div class="col-md-6 mt-4">
      <v-card class="mx-auto row-height" max-width="1200" tile>
        <BlueHeader :title="'People One Health'"
                    :back="true"
                    :settings="false"
                    :add="true">
        </BlueHeader>
        <div class="clearing-house-search">
          <v-text-field
              class="mr-5 filter-serach-input"
              @input="searchInput"
              solo
              placeholder="Member Name"
          ></v-text-field>
        </div>
        <div class="mt-8">
          <v-tabs
          v-model="tab"
          >
            <v-tab
            v-for="item in items"
            :key="item"
            >
              {{item}}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">

            <v-tab-item
                v-show="tab == 0"
            >
              <div class="mt-2 mb-10">
                <div class="row justify-md-space-between mt-3">
                  <div class="col-md-5">
                <h3 class="ml-10 row text-gray mt-2">
                     Member Information
                </h3>
                  </div>
                  <div class="col-md-2">
                      <svg
                        @click="dialog = true"
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                          d="M2.25 7.51196H27.75"
                          stroke="#326AB3"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                      />
                      <path
                          d="M21.75 28.5H8.25C7.45435 28.5 6.69129 28.1839 6.12868 27.6213C5.56607 27.0587 5.25 26.2956 5.25 25.5V7.5H24.75V25.5C24.75 26.2956 24.4339 27.0587 23.8713 27.6213C23.3087 28.1839 22.5456 28.5 21.75 28.5Z"
                          stroke="#326AB3"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                      />
                      <path
                          d="M9.62402 7.5V6.813C9.62402 5.4039 10.1838 4.05252 11.1802 3.05614C12.1765 2.05976 13.5279 1.5 14.937 1.5C16.3461 1.5 17.6975 2.05976 18.6939 3.05614C19.6903 4.05252 20.25 5.4039 20.25 6.813V7.5"
                          stroke="#326AB3"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                      />
                      <path
                          d="M11.967 13.5V22.5"
                          stroke="#326AB3"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                      />
                      <path
                          d="M18.0449 13.5V22.5"
                          stroke="#326AB3"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div class="row justify-center mt-5">
                  <div class="col-md-8">
                    <v-text-field
                        outlined
                        label="First Name"
                        v-model="firstName"
                        hide-details=""
                    ></v-text-field>
                  </div>
                  <div class="col-md-8">
                    <v-text-field
                        outlined
                        hide-details=""
                        label="Last Name"
                        v-model="lastName"
                    ></v-text-field>
                  </div>
                  <div class="col-md-8">
                    <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="DOB"
                            outlined
                            label="DOB"
                            hide-details
                            append-icon="mdi-calendar"
                            readonly
                            persistent-hint
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="DOB" no-title @input="menu = false">
                        <v-spacer></v-spacer>
                      </v-date-picker>
                    </v-menu>
                  </div>
                  <div class="col-md-8">
                    <div class="row col-md-12">
                      Gender:
                      <span class="ml-4 mr-3">Male</span>
                      <div
                          class="text-end"
                          :class="
                    gender == 'm' ? 'fill-blue-checkbox' : 'blue-checkbox'
                  "
                          @click="gender = 'm'"
                      ></div>
                      <span class="ml-2 mr-3"> Female</span>
                      <div
                          class="text-end"
                          :class="
                    gender == 'f' ? 'fill-blue-checkbox' : 'blue-checkbox'
                  "
                          @click="gender = 'f'"
                      ></div>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <v-text-field
                        outlined
                        hide-details=""
                        label="Member ID"
                        v-model="memberId"
                    ></v-text-field>
                  </div>
                </div>
              </div>
            </v-tab-item>

            <v-tab-item
                v-show="tab == 1"
            >
                <div class="mt-2 mb-10">
                  <div class="row justify-md-space-between mt-3">
                    <div class="col-md-5">
                      <h3 class="ml-10 row text-gray mt-2">
                         Plan Information
                      </h3>
                    </div>
                  </div>
                  <div class="row justify-center mt-5">
                    <div class="col-md-8">
                      <div class="row col-md-12">
                        Plan Status:
                        <span class="ml-4 mr-3">Active</span>
                        <div
                            class="text-end"
                            :class="
                    planStatus == 'a' ? 'fill-blue-checkbox' : 'blue-checkbox'
                  "
                            @click="planStatus = 'a'"
                        ></div>
                        <span class="ml-2 mr-3"> Inactive</span>
                        <div
                            class="text-end"
                            :class="
                    planStatus == 'i' ? 'fill-blue-checkbox' : 'blue-checkbox'
                  "
                            @click="planStatus = 'i'"
                        ></div>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <v-text-field
                          outlined
                          label="Deductible Balance"
                          v-model="deductibleBalance"
                          hide-details=""
                      ></v-text-field>
                    </div>
                    <div class="col-md-8">
                      <v-text-field
                          outlined
                          hide-details=""
                          label="Employer Client Name - Group ID"
                          v-model="ECNgroupId"
                      ></v-text-field>
                    </div>
                    <div class="col-md-8">
                      <v-text-field
                          outlined
                          hide-details=""
                          label="Plan Name - Plan ID"
                          v-model="planNamePlanId"
                      ></v-text-field>
                    </div>
                    <div class="col-md-8">
                      <v-text-field
                          outlined
                          hide-details=""
                          label="Payer Name - Payer ID"
                          v-model="payerNamePayerId"
                      ></v-text-field>
                    </div>
                    <div class="col-md-8">
                      <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              v-model="lastUpdated"
                              outlined
                              label="Last Updated"
                              hide-details
                              readonly
                              persistent-hint
                              v-bind="attrs"
                              v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="lastUpdated" no-title @input="menu = false">
                          <v-spacer></v-spacer>
                        </v-date-picker>
                      </v-menu>
                    </div>
                  </div>
                </div>
            </v-tab-item>

            <v-tab-item
                v-show="tab == 2"
            >
              <div class="mt-5 attrList" v-if="attrList">
                <div class="col-md-5">
                  <h3 class="ml-5 row text-gray mt-2"> Attributions</h3>
                </div>
                <v-list>
                  <v-list-item-group color="primary" v-if="!filterItems">
                    <v-list-item

                        id="clearing-house-list"
                        v-for="(item, i) in attributions"
                        :key="i"
                        @click="item.link? $router.push(item.link) : '' "
                    >

                      <v-list-item-content>
                        <v-list-item-title v-text="item.text" class="ml-5"></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon
                            @click="attrDetails=true
                                   attrList=false"
                            color="primary"  v-text="item.icon"></v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list-item-group>
                  <v-col md="12" cols="12" v-else>
                    <v-alert
                        border="bottom"
                        colored-border
                        type="warning"
                        elevation="2"
                        class="text-gray"
                    >
                      Sorry! No results found
                    </v-alert>
                  </v-col>
                </v-list>
              </div>
              <div v-if="attrDetails">

                <div class="mt-2 mb-10">
                  <div class="row mt-3 ml-5">
                    <div clas="col-md-3">
                      <v-icon
                          color="primary"
                          v-text="'mdi-arrow-left'"
                          class="pointer mr-3"
                          @click="attrDetails=false
                          attrList=true
                         "
                      >
                      </v-icon>
                    </div>
                    <div class="col-md-5">
                      <h3 class="row text-gray">
                        Attribution Details
                      </h3>
                    </div>
                  </div>
                  <div class="row justify-center mt-5">
                    <div class="col-md-8">
                      <v-text-field
                          outlined
                          label="Attribution Status"
                          v-model="attrStatus"
                          hide-details=""
                      ></v-text-field>
                    </div>
                    <div class="col-md-8">
                      <v-text-field
                          outlined
                          hide-details=""
                          label="DPC Organization"
                          v-model="DPCorganization"
                      ></v-text-field>
                    </div>
                    <div class="col-md-8">
                      <v-text-field
                          outlined
                          hide-details=""
                          label="DPC Location"
                          v-model="DPClocation"
                      ></v-text-field>
                    </div>
                    <div class="col-md-8">
                      <v-text-field
                          outlined
                          hide-details=""
                          label="PMPM - Annual Rate"
                          v-model="attrAnnualRate"
                      ></v-text-field>
                    </div>

                    <div class="col-md-8">
                      <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              v-model="attrStartDate"
                              outlined
                              label="Attribution Start Date"
                              hide-details
                              readonly
                              persistent-hint
                              v-bind="attrs"
                              v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="attrStartDate" no-title @input="menu = false">
                          <v-spacer></v-spacer>
                        </v-date-picker>
                      </v-menu>
                    </div>
                    <div class="col-md-8">
                      <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              v-model="attrEndDate"
                              outlined
                              label="Attribution End Date"
                              hide-details
                              readonly
                              persistent-hint
                              v-bind="attrs"
                              v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="attrEndDate" no-title @input="menu = false">
                          <v-spacer></v-spacer>
                        </v-date-picker>
                      </v-menu>
                    </div>
                  </div>
                </div>
              </div>
            </v-tab-item>

            <v-tab-item
                v-show="tab == 3"
            >
              <div class="mt-5" v-if="subscriptionsList">
                <div class="col-md-5">
                  <h3 class="ml-5 row text-gray mt-2">
                    Subscriptions
                  </h3>
                </div>
                <v-list>
                  <v-list-item-group color="primary" v-if="!filterItems">
                    <v-list-item
                        id="clearing-house-list"
                        v-for="(item, i) in subscriptions"
                        :key="i"
                        @click="item.link? $router.push(item.link) : '' "
                    >
                      <v-list-item-content>
                        <v-list-item-title v-text="item.text" class="ml-5"></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon
                            @click="subscriptionDetails=true
                                   subscriptionsList=false"
                            color="primary"  v-text="item.icon"></v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list-item-group>
                  <v-col md="12" cols="12" v-else>
                    <v-alert
                        border="bottom"
                        colored-border
                        type="warning"
                        elevation="2"
                        class="text-gray"
                    >
                      Sorry! No results found
                    </v-alert>
                  </v-col>
                </v-list>
              </div>
              <div v-if="subscriptionDetails">
                <div class="mt-2 mb-10">
                  <div class="row mt-3 ml-5">
                    <div clas="col-md-3">
                      <v-icon
                          color="primary"
                          v-text="'mdi-arrow-left'"
                          class="pointer mr-3"
                          @click="subscriptionDetails=false
                          subscriptionsList=true
                         "
                      >
                      </v-icon>
                    </div>
                    <div class="col-md-5">
                      <h3 class="row text-gray">
                        Subscirption Details
                      </h3>
                    </div>
                  </div>
                  <div class="row justify-center mt-5">
                    <div class="col-md-8">
                      <v-text-field
                          outlined
                          label="Subscription Status"
                          v-model="subStatus"
                          hide-details=""
                      ></v-text-field>
                    </div>
                    <div class="col-md-8">
                      <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              v-model="subStartDate"
                              outlined
                              label="Subscription Start Date"
                              hide-details
                              readonly
                              persistent-hint
                              v-bind="attrs"
                              v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="subStartDate" no-title @input="menu = false">
                          <v-spacer></v-spacer>
                        </v-date-picker>
                      </v-menu>
                    </div>
                    <div class="col-md-8">
                      <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              v-model="subEndDate"
                              outlined
                              label="Subscription End Date"
                              hide-details
                              readonly
                              persistent-hint
                              v-bind="attrs"
                              v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="subEndDate" no-title @input="menu = false">
                          <v-spacer></v-spacer>
                        </v-date-picker>
                      </v-menu>
                    </div>
                    <div class="col-md-8">
                      <v-text-field
                          outlined
                          hide-details=""
                          label="PMPM - Annual Rate"
                          v-model="attrAnnualRate"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
              </div>
            </v-tab-item>

            <v-tab-item
                v-show="tab == 4"
            >
              <div class="mt-5" v-if="encountersList">
                <div class="col-md-5">
                  <h3 class="ml-5 row text-gray mt-2">
                    Encounters
                  </h3>
                </div>
                <v-list>
                  <v-list-item-group color="primary" v-if="!filterItems">
                    <v-list-item
                        id="clearing-house-list"
                        v-for="(item, i) in encounters"
                        :key="i"
                        @click="item.link? $router.push(item.link) : '' "
                    >
                      <v-list-item-content>
                        <v-list-item-title v-text="item.text" class="ml-5"></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon
                            @click="encounterDetails=true
                                   encountersList=false"
                            color="primary"  v-text="item.icon"></v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list-item-group>
                  <v-col md="12" cols="12" v-else>
                    <v-alert
                        border="bottom"
                        colored-border
                        type="warning"
                        elevation="2"
                        class="text-gray"
                    >
                      Sorry! No results found
                    </v-alert>
                  </v-col>
                </v-list>
              </div>
              <div v-if="encounterDetails">
                <div class="mt-2 mb-10">
                  <div class="row mt-3 ml-5">
                    <div clas="col-md-3">
                      <v-icon
                          color="primary"
                          v-text="'mdi-arrow-left'"
                          class="pointer mr-3"
                          @click="encounterDetails=false
                          encountersList=true
                         "
                      >
                      </v-icon>
                    </div>
                    <div class="col-md-5">
                      <h3 class="row text-gray"> Encounter Details</h3>
                    </div>
                  </div>

                  <div class="row justify-center mt-5">
                    <div class="col-md-8">
                      <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              v-model="encounterDate"
                              outlined
                              label="Encounter Date"
                              hide-details
                              readonly
                              persistent-hint
                              v-bind="attrs"
                              v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="encounterDate" no-title @input="menu = false">
                          <v-spacer></v-spacer>
                        </v-date-picker>
                      </v-menu>
                    </div>
                    <div class="col-md-8">
                      <v-text-field
                          outlined
                          label="Encounter Type"
                          v-model="encounterType"
                          hide-details=""
                      ></v-text-field>
                    </div>

                    <div class="col-md-8">
                      <v-text-field
                          outlined
                          hide-details=""
                          label="Provider"
                          v-model="encounterProvider"
                      ></v-text-field>
                    </div>
                    <div class="col-md-8">
                      <v-text-field
                          outlined
                          hide-details=""
                          label="CPT Code(s)"
                          v-model="CPTcode"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
              </div>
            </v-tab-item>

            <v-tab-item
                v-show="tab == 5"
            >
              <div class="mt-5">
                <div class="col-md-5">
                  <h3 class="ml-5 row text-gray mt-2"> Accounting</h3>
                </div>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </v-card>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import api from "@/services/api";
// import tpas from "../../../../store/JSONS/tpa.json";
import BlueHeader from '../../../BlueHeader'
export default {
  props: [
    'addNew',
    'table'
  ],
  data() {
    return {
      firstName: "Trevor",
      lastName: "Spann",
      DOB: "07/25/1960",
      menu:"",
      gender: "m",
      memberId: "112233445",
      planStatus: "a",
      deductibleBalance: "$518.00",
      ECNgroupId: "Prudental - 5554332",
      planNamePlanId: "Prudential - Health Plan- 24332123",
      payerNamePayerId: "Anthem - 45332",
      lastUpdated: "12/07/2022",
      attributions: [
        {
          text: "01/01/2022 - Present",
          icon: "mdi-arrow-right",
          link: "",
        },
        {
          text: "08/01/2021 - 12/31/2021",
          icon: "mdi-arrow-right",
          link: "",
        },
        {
          text: "02/01/2021 - 07/30/2021",
          icon: "mdi-arrow-right",
          link: "",
        },
      ],
      attrList: true,
      attrDetails: false,
      attrStatus: "Active",
      DPCorganization: "Care ATC",
      DPClocation: "Houston",
      attrAnnualRate: "$60.00 - $720.00",
      attrStartDate: "01/01/2022",
      attrEndDate: "12/31/2022",
      subStatus: "Active",
      subStartDate: "01/01/2022",
      subEndDate: "12/31/2022",
      subAnnualRate: "$60.00 - $720.00",
      subscriptionsList: true,
      subscriptionDetails: false,
      subscriptions: [
        {
          text: "01/01/2022 - Present",
          icon: "mdi-arrow-right",
          link: "",
        },
        {
          text: "08/01/2021 - 12/31/2021",
          icon: "mdi-arrow-right",
          link: "",
        },
        {
          text: "02/01/2021 - 07/30/2021",
          icon: "mdi-arrow-right",
          link: "",
        },
      ],
      encountersList: true,
      encounterDetails: false,
      encounterDate: "12/07/2022",
      encounterType: "Wellness",
      encounterProvider:"Javier LeMalles MD",
      CPTcode: "99386; 99231",
      encounters: [
        {
          text: "12/07/2022",
          icon: "mdi-arrow-right",
          link: "",
        },
        {
          text: "08/01/2022",
          icon: "mdi-arrow-right",
          link: "",
        },
        {
          text: "04/01/2022",
          icon: "mdi-arrow-right",
          link: "",
        },
        {
          text: "04/01/2022",
          icon: "mdi-arrow-right",
          link: "",
        },
      ],
      showDetails: false,
      dialog: false,
      singleItem: null,
      sortBy: "",
      type: "",
      solutions: [],
      favorites: [],
      filterItems: false,
      filteredArray: [],
      selectedItem: 1,
      tab: "",
      items: ['MBR INFO', 'PLAN INFO', 'ATTRIBUTION', 'SUBSCRIPTION','ENCOUNTERS', 'ACCOUNTING'],
      text:'hiiii',
    };
  },
  components: {
    BlueHeader,

  },
  computed: {
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
  },

  methods: {
    getData() {
      api()
          .get("/tpas/invoices")
          .then((res) => {
            if (res) {
              // this.items = res.data;
              // this.filteredArray = res.data;
            }
          });
    },
    invite() {},
    add() {},
    exportFun() {
      this.exportData("tblData");
    },

    searchInput(val) {
      this.items = this.filteredArray.filter((el) => {
        if (el.text && el.text.toLowerCase().includes(val.toLowerCase())) {
          return el.text.toLowerCase().includes(val.toLowerCase());
        }
      });
      if (this.items.length < 1) {
        this.filterItems = true;
      } else {
        this.filterItems = false;
      }
    },

    searchMembers() {},
  },
  created() {

    this.filteredArray = this.items;
  },
};
</script>
