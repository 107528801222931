<template>
  <div class="col-md-12">
    <v-row class="add-client-form no-padding">
      <div class="row mb-4">
        <div class="col-md-12">
          <h3 class="form-title font">Contract New</h3>
        </div>
      </div>
      <v-tabs dark show-arrows style="background: white" v-model.trim="tab">
        <v-tabs-slider style="background: orange"></v-tabs-slider>
        <v-tab
          style="background: #4072b7; text-transform: none"
          v-for="(item, i) in items"
          :key="i"
          class="col-md-3 pl-1 pr-1"
          :href="'#tab' + i"
          :style="i == 1 ? { 'margin-right': '5px', 'margin-left': '5px' } : ''"
        >
          <div class="mr-1 ml-1">
            {{ item.text }}
          </div>
        </v-tab>
      </v-tabs>
      <div class="col-md-12 contract-form" v-if="itemtab == 'tab0'">
        <div class="col-md-12 row padding">
          <div class="col-md-12">
            <h3 class="mb-5">Contract</h3>
            <label for="" class="text-gray mt-10">Contract Information</label>
            <hr />
          </div>
          <div class="col-md-6 mt-5">
            <a>Digital Direct Healthcare - Provider Services Agreement</a>
            <!-- <v-select solo
          v-model.trim="select"
          :items="[{text:'Fee for Service - Provider Services Agreement', value:'providerService'}]"
        >
         <template slot="selection" >
              <a  style="text-decoration: underline !important">Fee for Service - Provider Services Agreement </a>
        </template>
         </v-select> -->
            <!-- <div class="row ml-1">
              <v-checkbox solo v-model.trim="serviceAgreement"></v-checkbox>
              <a class="mt-5"> Fee for Service - Provider Services Agreement</a>
            </div> -->
          </div>
          <!-- <div class="col-md-6">
            <label for="">Custom Name</label>
            <v-text-field
              v-model.trim="customName"
              solo
              value="Platform Contract"
            ></v-text-field>
          </div> -->
        </div>
      </div>
      <div class="col-md-12 contract-form" v-if="itemtab == 'tab1'">
        <div>
          <h3>Rates</h3>
        </div>
        <div class="row mt-5 col-md-12">
          <label for="" class="text-gray">Your Contract Rates </label>
        </div>
        <div class="row mt-5">
          <div class="col-md-3 mt-2">
            <v-label class="font">DPC </v-label>
            <v-text-field
              @input="getOutpatientPriceMatch"
              v-model.trim="outpatient"
              solo
              type="number"
            >
            </v-text-field>
          </div>
          <div class="col-md-3 mt-2">
            <v-label class="font">Rate Type </v-label>
            <v-text-field
              @input="getOutpatientPriceMatch"
              v-model.trim="outpatient"
              solo
              type="number"
            >
            </v-text-field>
          </div>
        </div>
      </div>
      <div class="col-md-12 contract-form" v-if="itemtab == 'tab2'">
        <div>
          <h3>Review and Accept</h3>
        </div>
        <div class="row mt-5" v-if="display">
          <label for="" class="text-gray col-xl-4 col-lg-6">Contract Name</label>
          <label for="" class="text-gray col-xl-3 col-lg-2">Rate</label>
          <label for="" class="text-gray col-xl-3 col-lg-2">Rate Type</label>
          <label for="" class="text-gray col-xl-2 col-lg-2 text-end">
            <span class="mr-6">Actions</span>  </label>
        </div>
        <hr />
        <div class="row mt-4">
          <label v-if="!display" for="" class="text-gray col-md-3" cols="3"
            >Contract Name</label
          >
          <a
            class="col-xl-4 col-lg-6 col-md-6"
            cols="3"
            @click="viewContract(customName)"
            v-html="customName ? customName : 'Digital Direct Healthcare - Provider Services Agreement'"
          ></a>
          <label v-if="!display" for="" class="text-gray col-md-3"
            >Min Rates</label
          >
          <label for="" class="col-xl-3 col-lg-2 col-md-2 "
            >{{ inpatient }} % / {{ outpatient }} %</label
          >
          <label for="" class="col-xl-3 col-lg-2 col-md-2">PMPM</label>
          <label v-if="!display" for="" class="text-gray col-xl-3 col-lg-2 text-end"
            >
            <span>Actions</span>
            </label
          >
          <label for="" class="col-xl-2 col-lg-2 text-end">
            <svg
              width="28"
              @click="clearContract"
              class="pointer mr-10"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 13.7206C1 17.0943 2.35661 20.3298 4.77138 22.7154C7.18615 25.101 10.4613 26.4412 13.8763 26.4412C17.2913 26.4412 20.5664 25.101 22.9812 22.7154C25.396 20.3298 26.7526 17.0943 26.7526 13.7206C26.7526 10.3469 25.396 7.11134 22.9812 4.72577C20.5664 2.3402 17.2913 1 13.8763 1C10.4613 1 7.18615 2.3402 4.77138 4.72577C2.35661 7.11134 1 10.3469 1 13.7206V13.7206Z"
                stroke="#D00000"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.72607 18.8088L19.0271 8.63232"
                stroke="#D00000"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M19.0271 18.8088L8.72607 8.63232"
                stroke="#D00000"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </label>
        </div>
        <div class="row mt-15 justify-end mt-10">
          <div class="col-md-4  mt-10 font" style="height:150px">
         
          </div>
        </div>
        <v-row v-if="checkboxError">
          <v-alert dense outlined type="error">
            {{ checkboxError }}
          </v-alert>
        </v-row>
        <div class="row col-md-12">
          <v-row class="col-md-12 pb-0">
            <v-checkbox v-model.trim="checkbox"></v-checkbox>
            <v-btn class="blue-buttons mt-3" @click="addContract"
              >Accept & Contract</v-btn
            >
          </v-row>
          <v-row class="font text-gray mt-0">
            <v-col>
              By checking the box and clicking "ACCEPT & CONTRACT," you
              represent that: (1) you have read and agree with the contract
              listed above, and (2) you have the right, authority and capacity
              to enter into this agreement and commit to the contract rates that
              you have entered, on your own behalf and on behalf of any
              organization(s) for which the agreement applies.
            </v-col>
          </v-row>
        </div>
      </div>

      <div class="col-md-12 row justify-space-between mt-2 no-padding">
        <div class="col-md-6 no-padding" v-if="itemtab != 'tab0'">
          <v-btn class="blue-buttons col-md-2" @click="backTab(itemtab)">
            Back
          </v-btn>
        </div>

        <div
          :class="
            itemtab != 'tab0'
              ? 'col-md-6 text-end no-padding'
              : 'col-md-12 text-end no-padding'
          "
        >
          <v-btn
            class="mr-2 mb-3 blue-buttons"
            :class="itemtab != 'tab0' ? 'col-md-2' : 'col-md-1'"
            @click="
              $router.push(
                `/employer-client/account/contract`
              )
            "
          >
            Cancel
          </v-btn>
          <v-btn
            v-if="itemtab != 'tab2'"
            :class="itemtab != 'tab0' ? 'col-md-2' : 'col-md-1'"
            class="blue-buttons mb-3"
            @click="nextTab(itemtab)"
          >
            Next
          </v-btn>
        </div>
      </div>
    
    </v-row>
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
    <v-dialog v-model.trim="dialog1" width="600">
      <v-card>
        <v-card-title class="text-h5"> CONGRATULATIONS...! </v-card-title>
        <v-card-text>
          You have just contracted on the Apaly Health Platform. <br />
          You will be receiving a welcome package with educational materials
          that are designed to help your plan members become familiar with the
          Apaly Health solution.<br />
          In addition, we will be reaching out to gather some information about
          your TPA, so we can coordinate our provider medical claims process
          with them.
        </v-card-text>
        <v-card-actions>
          <div class="col-md-12 text-end">
            <v-btn
              class="blue-buttons text-end"
              @click="$router.push(`/employer-client/account/contract`)"
              >Close</v-btn
            >
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <contractPdf
      ref="contract_pdf"
      :name="customName"
      :inpatient="inpatient"
      :outpatient="outpatient"
    ></contractPdf>
  </div>
</template>
<script>
import api from "../../../../../services/api";
import successModal from "../../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../../TablesTemplate/ErrorDialog.vue";
import ContractPdf from "../../../../TablesTemplate/ContractPDF.vue";
export default {
  components: {
    successModal,
    errorModal,
    ContractPdf,
  },
  data() {
    return {
      display: true,
      dialog1: false,
      windowWidth: window.innerWidth,
      inpatient: "",
      outpatient: "",
      priceMatch: "",
      contractName: "",
      checkboxError: "",
      checkbox: "",
      outpatientPriceMatch: "",
      inpatientPriceMatch: "",
      singleConDialog: false,
      customName: "",
      tab: null,
      href: "#tab-0",
      serviceAgreement: "",
      select: {
        text: "Fee for Service - Provider Services Agreement",
        value: "providerService",
      },
      items: [
        {
          text: "Contract Form",
          value: 0,
        },
        {
          text: "Rate",
          value: 1,
        },
        {
          text: "Acceptance",
          value: 2,
        },
      ],
    };
  },
  computed: {
    getClient() {
      return this.$store.getters.getClientGetter;
    },
    routeParams() {
      return this.$route.params.name;
    },
    itemtab() {
      return this.tab;
    },
  },
  created() {
    if (this.windowWidth < 900) {
      this.display = false;
    } else {
      this.display = true;
    }
    //   this.getOutpatientPriceMatch();
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    viewContract(name) {
      this.singleConDialog = true;
      this.$refs.contract_pdf.openPopUp();
    },
    clearContract() {
      this.customName = "";
      this.inpatient = "";
      this.outpatient = "";
      this.tab = "tab0";
    },
    getOutpatientPriceMatch() {
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.employerAdvisor;
      let employer = JSON.parse(localStorage.getItem("employer"));
      api()
        .get(
          `/advisors/${advisorId}/employers/${
            employer._id
          }/providersPriceMatch?inpatient=${Number(
            this.inpatient
          )}&outpatient=${Number(this.outpatient)}`
        )
        .then((res) => {
          this.outpatientPriceMatch = res.data.priceMatch.outpatient;
          this.inpatientPriceMatch = res.data.priceMatch.inpatient;
        });
    },
    getInpatientPriceMatch() {
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.employerAdvisor;
      let employer = JSON.parse(localStorage.getItem("employer"));
      api()
        .get(
          `/advisors/${advisorId}/employers/${
            employer._id
          }/providersPriceMatch?inpatient=${Number(
            this.inpatient
          )}&outpatient=${Number(this.outpatient)}`
        )
        .then((res) => {
          this.inpatientPriceMatch = res.data.priceMatch.inpatient;
        });
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
    },

    backTab(tab) {
      if (tab == "tab1") {
        this.tab = "tab0";
      } else if (tab == "tab2") {
        this.tab = "tab1";
      }
    },
    nextTab(tab) {
      if (tab == "tab0") {
        this.tab = "tab1";
      } else if (tab == "tab1") {
        this.tab = "tab2";
      }
    },
    createModal() {
      this.$router.push(`/employer-client/account/contract`);
    },
    addContract() {
      this.dialog1 = true;
      // if (this.customName && this.outpatient) {
      //   if (this.checkbox) {
      //     let user = JSON.parse(localStorage.getItem("user"));
      //     let advisorId = user.groupPortal[0].organization.employerAdvisor;
      //     let employer = JSON.parse(localStorage.getItem("employer"));
      //     let data = {
      //       form: this.serviceAgreement,
      //       name: this.customName,
      //       employerRates: {
      //         inpatient: this.inpatient,
      //         outpatient: this.outpatient,
      //       },
      //     };
      //     api()
      //       .post(
      //         `/advisors/${advisorId}/employers/${employer._id}/contract`,
      //         data
      //       )
      //       .then((res) => {
      //         if (res) {
      //           this.dialog1 = true;
      //         }
      //       });
      //   } else {
      //     this.checkboxError = "Please check the box first ";
      //   }
      // } else {
      //   this.$refs.error_modal.openPopUp(
      //     "Contract Name and Contract Rates Are Required"
      //   );
      // }
    },
    singleContract(item) {
      this.$store.commit("setSingleContract", item);
      this.$router.push("/contracts");
    },
    onResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 900) {
        this.display = false;
      } else {
        this.display = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.v-select__selection--comma {
  color: #1976d2;
}
</style>






 