<template>
  <div class="container-fluid background-gray">
    <Header
      @expantSideBarOnHover="expantSideBarOnHover"
      @hideSideBar="hideSideBar"
    />
    <v-row class="background-gray">
      <div class="col-md-12">
        <div class="col-md-12">
          <WhiteNavbar
            :tab="tab"
            @changeTab="changeTab"
        
          ></WhiteNavbar>
          <div class="home-tables">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </v-row>
  </div>
</template>
<script>
import Header from "../components/Sections/Marketplace/Layout/Header.vue";
import Home from "../components/Sections/Marketplace/Home.vue";
import WhiteNavbar from "../components/Sections/Marketplace/Layout/WhiteNavbar.vue";

export default {
  components: {
    Header,
    Home,
    WhiteNavbar,
  },
  data() {
    return {
      sideBar: true,
      showSideBar: false,
      display: true,
      windowWidth: window.innerWidth,
      page: "",
      clientName: "",
      miniClass: false,
      clientItem: null,
      path: "",
      tab: "all",
      searchVal:'',
    };
  },
  created() {
    if (this.windowWidth < 959) {
      this.display = false;
    } else {
      this.display = true;
    }
  },
  computed: {
    hideWhiteBack() {
      let hide = false;
      let routeParamsName =
        this.$route.params && this.$route.params.name
          ? this.$route.params.name.replace(/ /g, "%20")
          : "";
      let routeParamsSpaceName =
        this.$route.params && this.$route.params.name
          ? this.$route.params.name
          : "";

      if (
        this.$route.path == "/employer-client/providers" ||
        this.$route.path == "/employer-client/home" ||
        this.$route.path == "/employer-client/vendors"
      ) {
        hide = true;
      }
      return hide;
    },

    mobileSideBar() {
      let show;
      if (this.display) {
        show = true;
      } else if (!this.showSideBar && !this.display) {
        show = true;
      }
      return show;
    },
    route() {
      return this.$route.path;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
 
    changeTab(tab) {
      this.tab = tab;
    },
    getProvidersByClient(item) {
      if (item) {
        this.clientItem = item;
        this.clientName = item.name;
      }
    },
    closeSideBar() {
      if (!this.display) {
        this.showSideBar = false;
      } else {
        this.showSideBar = true;
      }
    },
    getClientName(name) {
      this.clientName = name;
    },

    onResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 959) {
        this.display = false;
      } else {
        this.display = true;
      }
    },
    hideSideBar() {
      this.showSideBar = !this.showSideBar;
    },
    expantSideBarOnHover(mini) {
      this.showSideBar = !this.showSideBar;
      this.miniClass = !this.miniClass;
    },
  },
};
</script>
