<template>
  <div class="col-md-12 row-height">
    <h3>
      <span class="font col-md-12">Settings</span>
    </h3>
    <div class="col-md-12 text-center justify-center" v-if="readyToShow">
      <v-progress-circular
        indeterminate
        :size="100"
        color="primary"
        class="text-center"
      ></v-progress-circular>
    </div>

    <div class="col-md-12 form-row-height"  v-if="!readyToShow">
      <div class="row">
        <v-form
          class="col-md-12 row"
          ref="form"
          v-model.trim="valid"
          lazy-validation
        >
          <div class="col-lg-12 col-md-12 mt-xl-2 row" cols="12">
            <h4 class="settings-text text-underline col-md-12">
              Group Account Notifications
            </h4>
            <label
              class="text-gray font mt-5 col-xl-4 col-lg-12 col-md-12"
              for=""
              >Send all group account notifications the email(s) listed
              here:</label
            >
            <div class="col-lg-12 col-xl-8 col-md-12 col-md-12 no-padding">
              <label class="text-gray font ml-3" for=""
                >Enter email(s) where notifications should be sent :</label
              >
              <v-row justify="space-around">
                <v-col cols="12">
                  <v-text-field
                    class="ml-3"
                    style="width: 30%; float: left"
                    v-model.trim="email"
                    :rules="email ? emailRules : []"
                    solo
                  >
                    <template v-slot:append>
                      <v-btn icon @click="pushToTags"
                        ><v-icon>mdi-plus</v-icon></v-btn
                      >
                    </template>
                  </v-text-field>
                  <v-chip-group
                    mandatory
                    class="primary--text"
                    style="height: 43px"
                  >
                    <v-chip
                      v-for="(tag, index) in tags"
                      :key="index"
                      class="primary--text v-chip--active ml-3"
                    >
                      {{ tag }}
                      <i
                        class="fa fa-times fa-2xs"
                        @click="removeFromTags(index)"
                      ></i>
                    </v-chip>
                  </v-chip-group>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-form>
        <div class="col-md-6 settings-text mb-3 text-underline">
          Email Domains Associated with this Account
        </div>
      </div>
      <v-form
        v-if="$role != 'user'"
        class="col-md-6 no-padding"
        v-model.trim="valid1"
        ref="form1"
      >
        <label class="text-gray font" for=""
          >Enter email domain here, and click Add</label
        >
        <div class="row col-md-11">
          <v-text-field
            @keyup="fillDomainRules"
            :rules="newEmail ? domainRules : []"
            v-model.trim="newEmail"
            solo
          ></v-text-field>
          <v-btn large class="ml-3 mt-1 blue-buttons" @click="pushToArray"
            >Add</v-btn
          >
        </div>
      </v-form>
      <div
        class="col-md-6 no-padding mb-10"
        :class="emails.length > 8 ? 'y-scroll' : ''"
        style="overflow-x: hidden"
      >
        <label for="" class="text-gray font">Email domain white list:</label>
        <div class="col-md-12">
          <v-card
            v-for="(email, index) in emails"
            :key="index"
            style="padding: 1rem"
            class="mt-2 mb-2"
          >
            <span v-if="email.domain">{{ email.domain }}</span>
            <span v-else>{{ email }}</span>
            <span class="float-right" v-if="index != 0">
              <svg
                @click="removeFromArray(email)"
                class="pointer"
                width="28"
                height="26"
                viewBox="0 0 28 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.35547 13C1.35547 16.1826 2.65524 19.2348 4.96884 21.4853C7.28244 23.7357 10.4204 25 13.6923 25C16.9642 25 20.1021 23.7357 22.4157 21.4853C24.7293 19.2348 26.0291 16.1826 26.0291 13C26.0291 9.8174 24.7293 6.76516 22.4157 4.51472C20.1021 2.26428 16.9642 1 13.6923 1C10.4204 1 7.28244 2.26428 4.96884 4.51472C2.65524 6.76516 1.35547 9.8174 1.35547 13V13Z"
                  stroke="#D00000"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.75781 17.7992L18.6273 8.19922"
                  stroke="#D00000"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M18.6273 17.7992L8.75781 8.19922"
                  stroke="#D00000"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </v-card>
        </div>
      </div>
    </div>
    <br /><br /><br />
    <div class="row col-md-12 justify-end" v-if="$role != 'user' && !readyToShow">
      <v-btn class="blue-buttons" @click="update">Update & Save</v-btn>
    </div>
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
    <v-snackbar v-model.trim="snackbar" :timeout="1000" color="red">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import api from "../../../../services/api";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../TablesTemplate/ErrorDialog.vue";

export default {
  components: { successModal, errorModal },
  data() {
    return {
      email: "",
      newEmail: "",
      snackbar: false,
      snackbarText: "",
      emails: [],
      emailsForDelete: [],
      domains: [],
      array: [],
      tags: [],
      schedule: "",
      costs: "",
      myDeleteArray: [],
      myAddArray: [],
      domainRules: [
        (v) => !!v || "This field is required",
        (v) =>
          /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/.test(v) ||
          "Email domain is not valid!",
      ],
      emailRules: [
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      valid: false,
      valid1: false,
      page: 1,
      total: 0,
      items: 100,
      readyToShow: false,
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    pushToTags() {
      if (this.email != null && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email)) {
        let index = this.tags.findIndex((c) => c == this.email);
        if (index == -1) {
          this.tags.push(this.email);
          this.email = "";
        } else {
          this.snackbarText = "Email already exists";
          this.snackbar = true;
        }
      } else {
        this.snackbarText = "Email is not valid";
        this.snackbar = true;
      }
    },
    removeFromTags(index) {
      this.tags.splice(index, 1);
    },

    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    getEmailDomains() {
      api()
        .get("/vdomains/distinct?page=" + this.page + "&limit=100")
        .catch((err) => {
          if (err) {
            this.$refs.error_modal.openPopUp(err.response.data.message);
          }
        })
        .then((res) => {
          this.domains = res.data.domains;
          this.emails = res.data.domains;
          this.items = res.data.totalRecordsInPage;
          this.total = Math.round(res.data.total / 250);
          if (localStorage.getItem("user")) {
            let mail = JSON.parse(localStorage.getItem("user")).email;
            this.emails.unshift(mail.split("@")[1]);
            this.$store.commit("setEmailWhiteList", this.emails);
          }
        });
    },
    fillDomainRules() {
      this.domainRules = [
        (v) => !!v || "This field is required",
        (v) =>
          /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/.test(v) ||
          "Email domain is not valid!",
      ];
    },
    pushToArray() {
      this.$refs.form1.validate();
      if (this.valid1) {
        let data = {
          domain: this.newEmail,
        };
        let exist = false;
        api()
          .post("/auth/validateEmail", data)
          .catch((err) => {
            if (err) {
              this.domainRules = ["Only company email domains are allowed"];
            }
          })
          .then((res) => {
            if (res) {
              api()
                .get("/auth/isValidDomain?domain=" + this.newEmail)
                .catch((err) => {
                  if (err) {
                    this.domainRules = [err.response.data.message];
                    exist = true;
                  }
                })
                .then((res) => {
                  let regex =
                    /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/;
                  let check = regex.test(this.newEmail);
                  if (this.newEmail && check && this.valid1) {
                    this.emails.push({ domain: this.newEmail });
                    this.myAddArray.push({ domain: this.newEmail });
                    this.$refs.success_modal.openPopUp(
                      "Domain added successfully"
                    );
                    this.$refs.form.resetValidation();
                    this.newEmail = "";
                  }
                });
            }
          });
      }
    },
    removeFromArray(email) {
      this.myDeleteArray.push(email);
      for (let i = 0; i < this.emails.length; i++) {
        if (this.emails[i] == email) {
          this.emails.splice(i, 1);
        }
      }

      this.$refs.success_modal.openPopUp("Domain was successfully deleted!");
    },
    getInfo() {
      this.readyToShow = true;
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.providerAdvisor;
      let self = this;
      api()
        .get(
          `/advisors/${advisorId}/PA/settings?page=` + this.page + "&limit=100"
        )
        .then((res) => {
          this.total = Math.round(res.data.settings.total / 100);

          this.emails = res.data.settings.domainWhiteList;
          let emailTags = res.data.settings.priorAuths.emails?.split(";") ?? [];
          self.tags = [];
          emailTags.forEach(function (item) {
            if (item != "") self.tags.push(item);
          });
          if (localStorage.getItem("user")) {
            let mail = JSON.parse(localStorage.getItem("user")).email;
            let parsedMail = mail.split("@")[1];

            if (!res.data.settings.domainWhiteList.includes(parsedMail)) {
              this.emails.unshift(parsedMail);
              this.$store.commit("setEmailWhiteList", this.emails);
            }
            this.readyToShow = false;
          }
        });
    },
    update() {
      let newAddArr = [];
      let newDeletArr = [];
      if (this.myAddArray.length > 0) {
        this.myAddArray.forEach((el) => {
          if (el.domain) {
            newAddArr.push(el.domain);
          } else {
            newAddArr.push(el);
          }
        });
      }
      if (this.myDeleteArray.length > 0) {
        this.myDeleteArray.forEach((el) => {
          if (el.domain) {
            newDeletArr.push(el.domain);
          } else {
            newDeletArr.push(el);
          }
        });
      }
      let tagEmails = "";
      this.tags.forEach(function (item) {
        if (item != "") tagEmails += item + ";";
      });
      let data = {
        priorAuths: {
          emails: tagEmails,
        },
        addedDomains: newAddArr,
        deletedDomains: newDeletArr,
      };

      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.providerAdvisor;
      api()
        .put(`/advisors/${advisorId}/PA/settings`, data)
        .then((res) => {
          if (res) {
            this.getInfo();
            this.$refs.success_modal.openPopUp("Settings updated successfully");
          }
        });
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
    },
  },
};
</script>
