<template>
  <div class="mb-5">
    <div class="row col-md-12 font-weight-bold light-blue-text">
      Rates & Fees
      <v-divider class="mt-3 ml-3"></v-divider>
    </div>
    <div class="row mt-10">
      <div class="col-12">
        <a class="font-weight-bold ml-4" href="#">Rates for Vendor Services</a>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-md-12">
            <div class="row ml-10">
              <div class="col-md-2">
                <span class="text-gray font-weight-bold">Structure:</span>
              </div>
              <div class="col-md-10">
                <span class="text-gray font-weight-bold ml-4">{{item.rate.type }}</span>
              </div>
            </div>
            <div class="row ml-10">
              <div class="col-md-2">
                <span class="text-gray font-weight-bold">Frequency:</span>
              </div>
              <div class="col-md-10">
                <span class="text-gray font-weight-bold ml-4">{{item.rate.frequency }}</span>
              </div>
            </div>
            <div class="row ml-10">
              <div class="col-md-2">
                <span class="text-gray font-weight-bold">Amount:</span>
              </div>
              <div class="col-md-10">
                <span class="text-gray font-weight-bold ml-4">
                  {{item.rate.structure == 'Provider Defined' ? 'Vendor / Provider Defined with Max Input for Employer' : item.rate.amount }}
                </span>
              </div>
            </div>
            <div class="row ml-10" style="align-items: baseline;">
              <div class="col-md-2">
                <span class="text-gray font-weight-bold">Max Rate:</span>
              </div>
              <div class="col-md-10">
                <div class="row" style="align-items: baseline">
                  <div class="col-md-3">
                    <v-text-field class="input-control" light dense outlined 
                    v-model="maxRate"
                    @input="sendRate()" ></v-text-field>
                  </div>
                  <div class="col-md-9">
                    <span class="text-gray font-weight-bold ml-4">Enter Max PMPM Acceptable Rate in USD</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-10">
      <div class="col-12">
        <a class="font-weight-bold ml-4" href="#">Solution Facilitator Fee</a>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-md-12">
            <div class="row ml-10" style="align-items: baseline;">
              <div class="col-md-2">
                <span class="text-gray font-weight-bold">Solution Facilitator Fee:</span>
              </div>
              <div class="col-md-10">
                <div class="row" style="align-items: baseline">
                  <div class="col-md-3">
                    <span class="text-gray font-weight-bold">$ </span>
                  </div>
                  <div class="col-md-9">
                    <span class="text-gray font-weight-bold ml-4">Per member per month</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-10">
      <div class="col-12">
        <a class="font-weight-bold ml-4" href="#">TPA Admin Fee</a>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-md-12">
            <div class="row ml-10" style="align-items: baseline;">
              <div class="col-md-2">
                <span class="text-gray font-weight-bold">TPA Fee:</span>
              </div>
              <div class="col-md-10">
                <div class="row" style="align-items: baseline">
                  <div class="col-md-3">
                    <v-text-field class="input-control" light dense outlined 
                    v-model="tpaFee"
                    @input="sendFee()" ></v-text-field>
                  </div>
                  <div class="col-md-9">
                    <span class="text-gray font-weight-bold ml-4">To be entered by you for each Client</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-10" v-for="(fee,key) in item.fees" :key="key">
      <div class="col-12">
        <a class="font-weight-bold ml-4" href="#">{{fee.entity.charAt(0).toUpperCase() + fee.entity.slice(1)}} Fee</a>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-md-12">
            <div  class="row ml-10">
              <div class="col-md-2">
                <span class="text-gray font-weight-bold">{{fee.entity.charAt(0).toUpperCase() + fee.entity.slice(1)}} Fee:</span>
              </div>
              <div class="col-md-10">
                <span class="text-gray font-weight-bold ml-4">
                  ${{ fee.amount.toFixed(2) }} USD <span class="ml-4">{{ fee.freq == 'annually' ? 'Per Annual Subscription' : fee.freq }}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { mapGetters } from "vuex";
export default {
  name: "Rates",
  props:['item'],
  data(){
    return{
      rate: {},
      maxRate: "",
      tpaFee: "",
      // data:this.$store.getters.getClientSolutionAcceptance
    }
  },
  methods: {
    sendRate(){
      this.$store.commit("setSolutionMaxRate", this.maxRate);
      this.$emit('getRate',this.maxRate);
    },
    sendFee(){
      this.$store.commit("setTpaAdminFee", this.tpaFee);
      this.$emit('getFee',this.tpaFee);
    },
  },
  computed: {
    ...mapState({
      data: state => state.Tpa.clientSolutionAcceptance
    }),
    ...mapGetters(["getTpaAdminFee", "getSolutionMaxRate"]),

  },
  created(){
    this.maxRate = this.getSolutionMaxRate ? this.getSolutionMaxRate : '';
    this.tpaFee = this.getTpaAdminFee ? this.getTpaAdminFee : '';
  },
}
</script>

<style scoped>

</style>