<template>
    <div>
        <Members v-if="route == `/provider-advisor/providers/${routeParams}/members`"></Members>
        <Member v-if="route == `/provider-advisor/providers/${routeParams}/members/member`"></Member>
        <Search v-if="route == `/provider-advisor/providers/${routeParams}/members/advanced-search`"></Search>
    </div>
</template>
<script>
import Members from './Members.vue'
import Member from './Member.vue'
import Search from './AdvancedSearch.vue'

export default{
    components:{
        Members,
        Member,
        Search,
       
    },
    computed:{
      route(){
           return this.$route.path.replace(/%20/g,' ')
      },
      routeParams(){
         return this.$route.params.name
      }
      
    },
    data(){
        return{

        }
    },

}
</script>