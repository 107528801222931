<template>
    <div>
      <div class="row col-md-12 font-weight-bold light-blue-text">
        Acceptance
        <v-divider class="mt-3 ml-3"></v-divider>
      </div>
      <div class="row mt-10">
        <div class="col-12">
          <span :style="tab != 'tpa' ? '' : 'color:#0573F3;cursor:pointer'" class="text-gray font-weight-bold ml-4 mr-4" @click="setTab('tpa')">TPA / Carrier Direct Acceptance</span>
          <span class="font-weight-bold text-gray">OR</span>
          <span :style="tab != 'employer' ? '' : 'color:#0573F3;cursor:pointer'" class="text-gray font-weight-bold ml-4" @click="setTab('employer')">Client / Employer Acceptance</span>
        </div>
        <div class="col-12">
  
          <div v-if="tab == 'tpa'">
            <div class="col-12">
              <a class="font-weight-bold">TPA / Carrier</a>
            </div>
            <div class="col-12">
              By checking the box below and clicking "AGREE & ACCEPT," you represent that:
              <ul style="list-style: none">
                <li>(1) You have read and agree with the contract(s) listed above (as applicable),</li>
                <li>(2) You have read and agree with the <a class="text-underline font-weight-bold" @click="termsAndconditions()">Apaly Platform Terms of Use Agreement</a>,</li>
                <li>(3) You have the right, authority and capacity to enter into this agreement and commit your client to participate</li>
                <li class="ml-5">
                  in this solution under the terms and details contained herein, on your own behalf and on behalf of any
                  organization for which this solution applies.
                </li>
              </ul>
            </div>
            <div class="col-12 d-flex align-center">
              <v-checkbox @click="acceptedByTpa()" v-model="acceptedTpa" :disabled="isCheckboxDisabled"></v-checkbox>
              <div class="font-weight-bold ml-8" >
                <a>
                  <u>Agree & Accept</u>
                </a>
                
              </div>
            </div>
          </div>
          <div v-else>
            <div class="col-12">
              <a class="font-weight-bold">Client Confirmation</a>
            </div>
            <div class="col-12">
              <div class="row">
                <v-col md="6" cols="12" >
                  <label for="">First Name</label>
                  <v-text-field solo v-model="data.firstName"
                  :disabled="acceptedTpa==true"
                   ></v-text-field>
                </v-col>
              </div>
              <div class="row">
                <v-col md="6" cols="12">
                  <label for="">Last Name</label>
                  <v-text-field solo v-model="data.lastName" 
                  :disabled="acceptedTpa==true"
                   ></v-text-field>
                </v-col>
                </div>
                <div class="row">
                <v-col md="6" cols="12">
                  <label for="">Email</label>
                  <v-text-field solo v-model="data.email"
                  :disabled="acceptedTpa==true"
                    ></v-text-field>
                </v-col>
              </div>
            </div>
            <div class="col-6 text-right">
              <div class="font-weight-bold ml-8"  v-if="isCheckboxDisabled">
                <a @click="acceptedByEmployer()">
                  <u>Click to Submit to Client for Acceptance</u>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <successModal ref="success_modal" v-on:confirm="closeSuccessModal"></successModal>
      <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
    </div>
  </template>
  
  <script>
  import api from "@/services/api";
  import { mapState } from 'vuex';
  import { mapGetters } from "vuex";
  import successModal from "../../../../../../../TablesTemplate/SuccessDialog.vue";
  import errorModal from "../../../../../../../TablesTemplate/ErrorDialog.vue";
  export default {
    name: "Acceptance",
    props:['item'],
    components:{
      successModal,
      errorModal,
    },
    data(){
      return{
        // tab:'tpa',
        accepted:false,
        acceptedTpa: false,
        data: {
          firstName: '', 
          lastName: '', 
          email: ''
        }
      }
    },
    computed: {
      ...mapState({
        solutionAcceptance: state => state.Tpa.clientSolutionAcceptance
      }),
      tab(){
        return this.solutionAcceptance.acceptedBy
      },
      ...mapGetters(["getAcceptByTpa", "getAcceptByClient"]),
      isCheckboxDisabled() {
      return this.data.firstName !== '' && this.data.lastName !== '' && this.data.email !== '';
    },
    },
    methods:{
      acceptedByTpa(){
        this.$store.commit("setAcceptByTpa", this.acceptedTpa);
        this.$emit('getAcceptedByTpa',this.acceptedTpa);
      },
      acceptedByEmployer(){
        this.$store.commit("setAcceptByClient", this.data);
        this.$emit('getClient',this.data);
      },
      closeErrorModal() {},
      closeSuccessModal() {
        this.$refs.success_modal.closePopUp()
        this.$router.push(`/tpa/clients/solutions/${this.$route.params.id}`)
      },
      termsAndconditions(){
        window.open( `https://apalycdn.s3.us-west-1.amazonaws.com/public/uploads/admin/contracts/TermsAndConditions.pdf`, "_blank");
      },
      accept(type){
        if(this.item.contracted) return;
        this.data.acceptedBy = type
        
        api().post(`/tpas/clients/${this.$route.params.id}/solutions/${this.$route.params.solutionId}/contract`, this.solutionAcceptance)
            .then((res) => {
              if (res) {
                this.$refs.success_modal.openPopUp(res.data);
              }
            });
      },
      setTab(val){
        if(this.item.contracted)return
        this.$store.commit('setClientSolutionAcceptance', {
          ...this.solutionAcceptance, acceptedBy: val
        })
      },
      setEmpAcceptance(key){
        let acceptance = {...this.solutionAcceptance}
        acceptance[key] = this.data[key]
        this.$store.commit('setClientSolutionAcceptance', acceptance)
      }
    },
    created() {
      this.isCheckboxDisabled();
      this.acceptedTpa = this.getAcceptByTpa == true ? this.acceptedTpa = true : false;
      this.data = this.getAcceptByClient ? this.getAcceptByClient : {};
    }
  }
  </script>
  
  <style scoped>
  
  </style>