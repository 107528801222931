<template>
  <v-row class="padding">
    <div class="row col-md-12">
      <h3>
        <span class="underline-text"> Add Providers </span>
      </h3>
    </div>
    <v-col cols="12" md="6 border-right">
      <div>
        <div class="row col-md-12" v-if="route.includes('providers')">
          <h4 class="font">Search and Add Providers</h4>
        </div>
        <div class="row col-md-12" v-else>
          <h4 class="font">Search and Add Affilates</h4>
        </div>

        <form class="row">
          <div class="col-md-6">
            <label class="font text-gray" for="">NPI Number</label>
            <v-text-field required v-model.trim="npiNumber" solo></v-text-field>
          </div>
          <div class="col-md-3">
            <label class="font text-gray" for="">NPI TYPE </label>
            <v-select
              class="text-gray"
              :items="[
                { text: 'NPIs Type 1', value: '1' },
                { text: 'NPIs Type 2', value: '2' },
              ]"
              solo
              v-model.trim="npiType"
            ></v-select>
          </div>
          <div class="col-md-12">
            <h4>Search for Individuals</h4>
          </div>
          <div class="col-md-6">
            <label class="font text-gray" for="">First Name</label>
            <v-text-field required v-model.trim="firstName" solo></v-text-field>
          </div>
          <div class="col-md-6">
            <label class="font text-gray" for="">Last Name</label>
            <v-text-field required solo v-model.trim="lastName"></v-text-field>
          </div>
          <div class="col-md-12"><h4>OR</h4></div>
          <div class="col-md-12"><h4>Search for Organization</h4></div>
          <div class="col-md-12">
            <label class="font text-gray" for="">Organization Name</label>
            <v-text-field
              required
              v-model.trim="organizationName"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12"><h4>Narrow your Search</h4></div>

          <div class="col-md-5">
            <label class="font text-gray" for="">City</label>
            <v-text-field required v-model.trim="city" solo></v-text-field>
          </div>
          <div class="col-md-5">
            <label class="font text-gray" for="">State</label>
            <v-select
              required
              item-text="name"
              item-value="name"
              :items="states"
              v-model.trim="state"
              solo
            ></v-select>
          </div>
          <div class="col-md-2">
            <label class="font text-gray" for="">Zip</label>
            <v-text-field
              required
              solo
              v-model.trim="zip"
              :rules="zipRules"
            ></v-text-field>
          </div>
          <div class="col-md-12 text-end justify-end row">
            <v-progress-circular
              v-if="results"
              indeterminate
              color="primary mr-3"
            ></v-progress-circular>
            <div>
              <v-btn :disabled="results" class="blue-buttons" @click="submit">
                Search
              </v-btn>
            </div>
          </div>
        </form>
      </div>
    </v-col>
    <v-col md="6" cols="12" class="justify-center align-center">
      <div class="row col-md-12 font">
        <h4 v-if="route.includes('providers')">Upload a List of Providers</h4>
        <h4 v-else>Upload a List of Affilates</h4>
      </div>
      <div class="row text-center justify-center upload-box">
        <div class="col-md-9 upload-provider-box pointer" @click="addItemExcel">
          <input
            ref="fileInput"
            type="file"
            style="display: none"
            @change="uploadExcel"
          />
          <img src="/filee.png" class="apaly-pic" alt="" />
          <h3 class="text-gray font">Drag & Drop</h3>
          <h5 class="text-gray font">
            Your files here or
            <a>Browse</a>
            to upload
          </h5>
          <h5 class="text-gray font">
            <a>Excel or CSV files Only</a>
            <img
              v-if="arrayLength"
              src="/success.png"
              width="30"
              height="30"
              alt=""
            />
          </h5>
        </div>
        <div class="btn-box col-md-10">
          <div class="downoald-file-btn-box">
            <h3>
              <a class="underline-text" @click="downloadExcel"
                >Download File Template</a
              >
            </h3>
          </div>
        </div>
      </div>
      <div class="col-md-11 justify-end text-end m">
        <v-btn class="blue-buttons" :disabled="results" @click="searchExcel"
          >Upload</v-btn
        >
      </div>
    </v-col>
  </v-row>
</template>
<script>
import api from "../../../../services/api";
import XLSX from "xlsx";
export default {
  data() {
    return {
      npiNumber: "",
      npiType: "",
      firstName: "",
      lastName: "",
      organizationName: "",
      speciality: "",
      city: "",
      state: "",
      zip: "",
      states: [],
      excelError: false,
      results: false,
      urlData: "",
      arrayLength: false,
      zipRules: [
        (v) => (v && v.length == 5) || " Zip Code should be five digits long",
      ],
      jsondata: [
        {
          NPI: "",
        },
      ],
    };
  },
  computed: {
    route() {
      return this.$route.path;
    },
    getClient() {
      return this.$store.getters.getClientGetter;
    },
    routeParams() {
      return this.$route.params.name;
    },
  },
  created() {
    this.getStates();
  },
  methods: {
    searchExcel() {
      this.results = true;
      let urlData = ``;
      urlData += this.npiNumber ? `npi=${this.npiNumber}&` : "";
      urlData += this.npiType ? `type=${this.npiType}&` : "";
      urlData += this.firstName
        ? `firstname=${this.firstName.toUpperCase()}&`
        : "";
      urlData += this.lastName
        ? `lastname=${this.lastName.toUpperCase()}&`
        : "";
      urlData += this.taxonomy
        ? `taxonomy=${this.taxonomy.toUpperCase()}&`
        : "";
      urlData += this.organizationName
        ? `organization=${this.organizationName.toUpperCase()}&`
        : "";
      urlData += this.speciality
        ? `speciality=${this.speciality}&`
        : "";
      urlData += this.state ? `state=${this.state.toUpperCase()}&` : "";
      urlData += this.zip ? `zip=${this.zip.toUpperCase()}&` : "";
      urlData += this.city ? `city=${this.city.toUpperCase()}&` : "";

      if (this.arrayExcel.length != 0) {
        let data = {
          urlData,
          array: {
            ids: this.arrayExcel,
          },
        };

        api()
          .post(`/api/npidata/excel`, data.array)
          .then((res) => {
            if (res) {
              this.results = true;
              this.$router.replace(`/provider-advisor/providers/result`);
              this.$store.commit("setSearchResultProviders", res.data.nipdatas);
            } else {
              this.results = false;
              this.arrayExcel = [];
              this.$refs.error_modal.openPopUp(
                "Attachment failed. Please follow the Provider List Template"
              );
            }
          });
      } else {
        this.results = false;
        this.arrayExcel = [];
        this.$refs.error_modal.openPopUp(
          "Attachment failed. Please follow the Provider List Template"
        );
      }
    },
    downloadExcel() {
      const data = XLSX.utils.json_to_sheet(this.jsondata);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "providerList.xlsx");
    },
    getStates() {
      api()
        .get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },
    addItemExcel() {
      this.$refs.fileInput.click();
    },
    uploadExcel(e) {
      this.excelError = false;
      const files = e.target.files;
      if (!files.length) {
        return;
      } else if (!/\.(xls|xlsx|csv)$/.test(files[0].name.toLowerCase())) {
        return alert(
          "The upload format is incorrect. Please upload xls, xlsx or csv format"
        );
      }
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const workbook = XLSX.read(data, {
            type: "binary",
          });
          const wsname = workbook.SheetNames[0];
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]);
          const excellist = [];

          for (var i = 0; i < ws.length; i++) {
            if (ws[i].NPI) {
              excellist.push(ws[i].NPI);
              this.excelError = false;
              this.arrayExcel.push(ws[i].NPI);
              this.arrayLength = true;
            } else {
              this.excelError = true;
              this.arrayLength = false;
            }
          }
          if (this.arrayExcel.length < 1) {
            this.arrayLength = false;
            this.$refs.error_modal.openPopUp(
              "Something Went Wrong, Please Download The File Template First"
            );
          }
        } catch (e) {
          return alert("Read failure! Please Download the File Template.");
        }
      };

      fileReader.readAsBinaryString(files[0]);
    },
    submit() {
      this.results = true;
      let urlData = ``;
      urlData += this.npiNumber ? `npi=${this.npiNumber}&` : "";
      urlData += this.npiType ? `type=${this.npiType}&` : "";
      urlData += this.firstName
        ? `firstname=${this.firstName.toUpperCase()}&`
        : "";
      urlData += this.lastName
        ? `lastname=${this.lastName.toUpperCase()}&`
        : "";
      urlData += this.organizationName
        ? `organization=${this.organizationName.toUpperCase()}&`
        : "";
      urlData += this.state ? `state=${this.state.toUpperCase()}&` : "";
      urlData += this.zip ? `zip=${this.zip.toUpperCase()}&` : "";
      urlData += this.city ? `city=${this.city.toUpperCase()}&` : "";
      urlData += this.speciality
        ? `speciality=${this.speciality}&`
        : "";
      urlData += this.speciality
        ? `taxonomy=${this.speciality}&`
        : "";

      api()
        .get(`/api/npidata/search?${urlData}`)
        .then((res) => {
          if (res) {
            this.results = true;
            this.$router.replace(`/provider-advisor/providers/result`);
            this.$store.commit("setSearchResultProviders", res.data.items);
            this.$store.commit("setUrlData", urlData);
          }
        });
    },
  },
};
</script>
