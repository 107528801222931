<template>
  <v-row class="padding">
    <div class="col-md-12 pt-0">
      <h3 class="font">Partner Detail</h3>
    </div>
    <div class="col-md-12 no-padding form-row-height ">
      <form class="row">
        <div class="col-md-6">
          <div class="col-md-12 pb-0">
            <label class="font text-gray" for="">First Name</label>
            <v-text-field
              required
              solo
              v-model.trim="firstName"
              disabled
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Last Name</label>
            <v-text-field
              required
              solo
              v-model.trim="lastName"
              disabled
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Email</label>
            <v-text-field
              required
              v-model.trim="email"
              solo
              disabled
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Phone</label>
            <v-text-field
              required
              solo
              disabled
              v-model.trim="phone"
              :rules="phoneRules"
            ></v-text-field>
          </div>
        </div>
        <div class="col-md-6">
          <div class="col-md-12 pb-0">
            <label class="font text-gray" for="">Company Name</label>
            <v-text-field
              required
              solo
              v-model.trim="company"
              disabled
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Title</label>
            <v-text-field
              required
              solo
              v-model.trim="title"
              disabled
            ></v-text-field>
          </div>
          <div class="row no-padding">
            <div class="col-md-6 pb-0 pt-0">
              <label class="font text-gray" for="">City</label>
              <v-text-field
                required
                v-model.trim="city"
                disabled
                solo
              ></v-text-field>
            </div>
            <div class="col-md-3 pb-0 pt-0">
              <label class="font text-gray" for="">State</label>
              <v-select
                class="text-gray"
                v-model.trim="state"
                disabled
                item-text="name"
                item-value="name"
                :items="states"
                label=""
                solo
              ></v-select>
            </div>
            <div class="col-md-3 pb-0 pt-0 zip-height-field">
              <label class="font text-gray" for="">Zip</label>
              <v-text-field
                required
                solo
                disabled
                type="number"
                v-model.trim="zip"
                :rules="zipRules"
              ></v-text-field>
            </div>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Type</label>
            <v-text-field
              required
              solo
              v-model.trim="type"
              disabled
            ></v-text-field>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-12 d-flex align-end flex-column pt-0" v-if="$role != 'user'">
      <div>
        <v-btn class="blue-buttons mr-6" @click="$router.go(-1)"> Close </v-btn>
        <v-btn
          class="blue-buttons mr-6"
          v-if="showDeleteBtn"
          @click="removePartner"
        >
          Remove Partner
        </v-btn>
      </div>
    </div>
    <v-dialog v-model.trim="dialog" max-width="600px" persistent>
      <v-card>
        <v-col>
          <h3>Remove Advisor</h3>
        </v-col>

        <v-col>
          <span>Are you sure you want to delete this Advisor?</span>
        </v-col>

        <v-col>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="blue-buttons float-class" @click="confirmRemove"
              >Confirm</v-btn
            >
            <v-btn class="button-style float-class" @click="closePopUp"
              >Close</v-btn
            >
          </v-card-actions>
        </v-col>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import api from "../../../../../services/api";
export default {
  data() {
    return {
      states: [],
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      company: "",
      title: "",
      state: "",
      zip: "",
      city: "",
      type: "",
      dialog: false,
      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
      zipRules: [
        (v) => (v && v.length == 5) || " Zip Code should be five digits long",
      ],
    };
  },
  computed: {
    getClient() {
      return this.$store.getters.getClientGetter;
    },
    routeParams() {
      return this.$route.params.name;
    },
    singleAdvisor() {
      return this.$store.getters.getSingleAdvisorinEmployerAdvisor;
    },
    showDeleteBtn() {
      return this.$store.getters.showDeletePartnerButton;
    },
  },
  created() {
    this.getStates();

    if (this.singleAdvisor) {
      this.firstName = this.singleAdvisor.primaryContact.firstName;
      this.lastName = this.singleAdvisor.primaryContact.lastName;
      this.company = this.singleAdvisor.companyName;
      this.email = this.singleAdvisor.primaryContact.email;
      this.phone = this.singleAdvisor.primaryContact.phone;
      this.title = this.singleAdvisor.title;
      this.city = this.singleAdvisor.city;
      this.state = this.singleAdvisor.state;
      this.zip = this.singleAdvisor.zipCode;
      this.type =
        this.singleAdvisor.advisorType == "brokerConsultant"
          ? "Broker Consultant"
          : this.singleAdvisor.advisorType == "analyticsConsultant"
          ? "Analytics Consultant"
          : this.singleAdvisor.advisorType;
    }
  },
  methods: {
    removePartner() {
      this.dialog = true;
    },
    closePopUp() {
      this.dialog = false;
    },
    confirmRemove() {
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.providerAdvisor;
      let provider = JSON.parse(localStorage.getItem("provider"));
      api()
        .delete(
          `/advisors/${advisorId}/providers/${provider._id}/advisors/${this.singleAdvisor._id}`
        )
        .then((res) => {
          if (res) {
            api()
              .get(`/advisors/${advisorId}/providers/${provider._id}/advisors`)
              .then((res) => {
                if (res) {
                  this.$store.commit("setAllPartners", res.data.advisors);
                  this.dialog = false;
                  this.$router.push(
                    `/provider-advisor/providers/${this.routeParams}/advisors`
                  );
                }
              });
          }
        });
    },
    getStates() {
      api()
        .get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },
  },
};
</script>
 

