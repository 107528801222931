<template>
    <div>
        <EmpAdvisors  v-if="route=='/employer/advisors'"></EmpAdvisors>
        <EmpAddAdvisor  v-if="route=='/employer/advisors/add-new'"></EmpAddAdvisor>
        <EmpAddAdvisorResult  v-if="route=='/employer/advisors/add-new/result'"></EmpAddAdvisorResult>
        <EmpAdvisorInvite  v-if="route=='/employer/advisors/invite'"></EmpAdvisorInvite>
        <EmpAdvisorDetail v-if="route=='/employer/advisors/detail'"></EmpAdvisorDetail>
    </div>
</template>

<script>
import EmpAdvisors from "./EmpAdvisors.vue";
import EmpAddAdvisor from "./AddAdvisor/EmpAddAdvisor.vue";
import EmpAddAdvisorResult from "./AddAdvisor/SearchResult/EmpAddAdvisorResult.vue";
import EmpAdvisorInvite from "./InviteAdvisor/EmpAdvisorInvite.vue";
import EmpAdvisorDetail from "./AdvisorDetail/EmpAdvisorDetail.vue";

    export default {
        name:'EmpProvidersTemplate',
        components: {
            EmpAdvisors,
            EmpAddAdvisor,
            EmpAddAdvisorResult,
            EmpAdvisorInvite,
            EmpAdvisorDetail
        },
        computed:{
            route(){
                return this.$route.path
            }
        }
    }
</script>