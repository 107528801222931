<template>
  <v-card class=" row-height col-12">
    <div>
      <div class="blue-underline">
        <div class="col-md-12">  
           <h3 class="text-gray"> New Invoice </h3>
          </div>
        <div class="col-md-3 col-lg-3 d-flex">
        <h4 class="mr-3 pointer"  :class="invoicesPart ? 'light-blue-text' : 'text-gray'" @click="invoiceTab()">Invoices</h4>
        <h4 class="text-gray ml-1 pointer" :class="membersPart ? 'light-blue-text' : 'text-gray'" @click="memberTab()">Members (optional)</h4>
      </div> 
    </div>
      <div id="display-pdf" style="display: none"></div>
      <div class="col-12 mt-3 pl-0 pr-0">
        <div class="row col-12 pr-0 pl-0">
             <div v-if="invoicesPart" class="col-12 d-md-flex"> 
              <div class=" col-md-6 col-12">
                <v-form
            ref="form"
            v-model="valid"
            class="col-xl-12 col-lg-12 col-md-12 col-sm-12 row justify-center">
            <span class="mr-10 text-gray mt-4 font-weight-bold col-md-12">
              Enter or verify invoice information below</span>
            <br />
            <div class="col-xl-8 col-lg-12 col-md-12 pb-0">
              <div class="row">
                <label
                  for=""
                  class="col-md-4 text-xl-right text-lg-right text-md-left"
                  >INVOICE NUMBER:</label>
                <div class="col-md-6 pt-0">
                  <v-text-field
                    solo
                    v-model="invoiceNo"
                    @blur="checkInvoiceNumber()"
                    required
                  ></v-text-field>
                </div>
              </div>
            </div>

            <div class="col-xl-8 col-lg-12 col-md-12 pb-0">
              <div class="row">
                <label for="" class="col-md-4 text-lg-right text-md-left"
                  >BILLING DATE:</label
                >
                <div class="col-md-6 pt-0">
                  <v-menu
                    ref="menu"
                    :close-on-content-click="true"
                    transition="scale-transition"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        id="calendar"
                        solo
                        v-model="startDate"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="startDate"></v-date-picker>
                  </v-menu>
                </div>
                <div class="col-md-1">
                  <svg
                    class="ml-2 d-xl-block d-lg-block d-md-none d-sm-none d-xs-none"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1_11277)">
                      <path
                        d="M21.75 3.75H2.25C1.42157 3.75 0.75 4.42157 0.75 5.25V21.75C0.75 22.5784 1.42157 23.25 2.25 23.25H21.75C22.5784 23.25 23.25 22.5784 23.25 21.75V5.25C23.25 4.42157 22.5784 3.75 21.75 3.75Z"
                        stroke="#707070"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M0.75 9.75H23.25"
                        stroke="#707070"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6.75 6V0.75"
                        stroke="#707070"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M17.25 6V0.75"
                        stroke="#707070"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5.625 14.25C5.41789 14.25 5.25 14.0821 5.25 13.875C5.25 13.6679 5.41789 13.5 5.625 13.5"
                        stroke="#707070"
                        stroke-width="1.5"
                      />
                      <path
                        d="M5.625 14.25C5.83211 14.25 6 14.0821 6 13.875C6 13.6679 5.83211 13.5 5.625 13.5"
                        stroke="#707070"
                        stroke-width="1.5"
                      />
                      <path
                        d="M5.625 19.5C5.41789 19.5 5.25 19.3321 5.25 19.125C5.25 18.9179 5.41789 18.75 5.625 18.75"
                        stroke="#707070"
                        stroke-width="1.5"
                      />
                      <path
                        d="M5.625 19.5C5.83211 19.5 6 19.3321 6 19.125C6 18.9179 5.83211 18.75 5.625 18.75"
                        stroke="#707070"
                        stroke-width="1.5"
                      />
                      <path
                        d="M12 14.25C11.7929 14.25 11.625 14.0821 11.625 13.875C11.625 13.6679 11.7929 13.5 12 13.5"
                        stroke="#707070"
                        stroke-width="1.5"
                      />
                      <path
                        d="M12 14.25C12.2071 14.25 12.375 14.0821 12.375 13.875C12.375 13.6679 12.2071 13.5 12 13.5"
                        stroke="#707070"
                        stroke-width="1.5"
                      />
                      <path
                        d="M12 19.5C11.7929 19.5 11.625 19.3321 11.625 19.125C11.625 18.9179 11.7929 18.75 12 18.75"
                        stroke="#707070"
                        stroke-width="1.5"
                      />
                      <path
                        d="M12 19.5C12.2071 19.5 12.375 19.3321 12.375 19.125C12.375 18.9179 12.2071 18.75 12 18.75"
                        stroke="#707070"
                        stroke-width="1.5"
                      />
                      <path
                        d="M18.375 14.25C18.1679 14.25 18 14.0821 18 13.875C18 13.6679 18.1679 13.5 18.375 13.5"
                        stroke="#707070"
                        stroke-width="1.5"
                      />
                      <path
                        d="M18.375 14.25C18.5821 14.25 18.75 14.0821 18.75 13.875C18.75 13.6679 18.5821 13.5 18.375 13.5"
                        stroke="#707070"
                        stroke-width="1.5"
                      />
                      <path
                        d="M18.375 19.5C18.1679 19.5 18 19.3321 18 19.125C18 18.9179 18.1679 18.75 18.375 18.75"
                        stroke="#707070"
                        stroke-width="1.5"
                      />
                      <path
                        d="M18.375 19.5C18.5821 19.5 18.75 19.3321 18.75 19.125C18.75 18.9179 18.5821 18.75 18.375 18.75"
                        stroke="#707070"
                        stroke-width="1.5"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_11277">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>
            <div class="col-xl-8 col-lg-12 col-md-12 pb-0">
              <div class="row">
                <label for="" class="col-md-4 text-lg-right text-md-left"
                  >CLIENT:</label
                >
                <div class="col-md-6 pt-0">
                  <v-select
                    solo
                    return-object
                    v-model="client"
                    :items="clients"
                    item-text="client.name"
                    item-value="client.id"
                  ></v-select>
                </div>
              </div>
            </div>
            <div class="col-xl-8 col-lg-12 col-md-12 pb-0">
              <div class="row">
                <label for="" class="col-md-4 text-lg-right text-md-left"
                  >TOTAL CHARGE (USD):</label
                >
                <div class="col-md-6 pt-0">
                  <v-text-field solo v-model="total"></v-text-field>
                </div>
              </div>
            </div>
                </v-form>
              </div>
           <div class="col-md-6 col-12"> 
            <div v-if="type == 'pdf'">
              <iframe :src="pdfUrl" :style="{ height: $vuetify.breakpoint.xsOnly ? '300px' : '1000px', width: $vuetify.breakpoint.xsOnly ? '100%' : '100%' }"></iframe>
            </div>
            <v-card  v-else class="pl-3 pr-3 pt-3">
            
              <div
              class="col-xl-12 col-lg-12 col-md-12 pb-0 pt-0 d-md-flex"
            >
            <div class="col-md-6 pt-2">
           
              <span>
                <img
                :src="user.logo"
                class="apaly-pic pointer mt-3 mb-2"
              />
               </span>
             
            </div>
            <div class="col-md-6 pt-2">
              <span>
                <h1 class="font-weight-bold">Invoice</h1>
              </span>
              <br/>
              <span>
              
                <h3 class="">
                  No: {{invoiceNo }}
                </h3>
              </span>
              <br />
              <span class="font-weight-bold"> Issued </span>
              <br />
              <span class="text-gray">
                {{ startDate }}
              </span>
            </div>
            
            </div>
              <div class="col-xl-12 col-lg-12 col-md-12 pb-0 pt-3 d-md-flex">
                <div class="col-md-6 pt-0">
                   <span class="font-weight-bold text-gray">
                    Vendor Details 
                   </span>
                   <br>
                   <span>
                    {{user.companyName}}
                   </span>
                   <br>
                   <span>
                    {{user.primaryContact.firstName}} {{user.primaryContact.lastName}}
                   </span>
                   <br>
                   <span>
                   {{user.primaryContact.email}}
                   </span>
                   <br>
                   <span>
                    {{user.primaryContact.phone}}
                   </span>
                </div>
                  <div class="col-md-6 pt-0">
                     <span class="font-weight-bold text-gray">
                      Client Details 
                     </span>
                     <br>
                     <span>
                      {{client.client ? client.client.name : 'Company Name'}}
                     </span>
                     <br>
                     <span>
                    Address
                     </span>
                     <br>
                     <span>
                      City, State
                     </span>
                </div>
              </div>
              
           
              <div class="col-md-12 col-12">
                <v-simple-table fixed-header height="150px">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-start">#</th>
                        <th class="text-start">Item</th>
                        <th class="text-end">Qty</th>
                        <th class="text-end">Amount</th>
                      </tr>
                    </thead>
                    <tbody class="mt-2">
                      <tr class="">
                        <td>1</td>
                        <td>
                          Vendor Services
                          
                        </td>
                        <td  class="text-end">
                          1
                        </td>
                        <td class="text-end">
                         $ {{ total }}
                        </td>
                      </tr>
                    
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
              <div class="col-xl-12 col-lg-12 col-md-12 pb-0 pt-3 d-flex">
                <div class="col-md-4 pt-0">
                </div>
                  <div class="col-md-8 pt-2 d-flex justify-end border-top">
                    <div class="col-md-5">
                     
                      <span class="font-weight-bold">
                       Total
                      </span>
                    </div>
                    <div class="col-md-3" align="end">
                      <span class="font-weight-bold">
                        ${{total}}
                      </span>
                    </div>
                </div>
              </div>
              <div class="col-md-12 d-md-flex pb-0 pt-0">
                <div  class="col-md-9 mobile-font"> <p class="mt-4">Thank you for doing business with us. Have a good day! </p></div>
                <div  class="col-md-3 justify-logo justify-end pt-0"> <img
                  id="logo-img"
                  :src="'/newapalylogoblue.png'"
                  class="apaly-pic pointer"
                /></div>
              </div>
              <v-divider horizontal></v-divider>
              <div class="col-md-12 d-md-flex pt-0">
                <div  class="col-md-9 mobile-font"> <p class=" text-gray">Proudly crafted in Apaly Health. </p></div>
                <div  class="col-md-3" align="end"> </div>
              </div>
            </v-card>
           </div>
             </div>
          <div v-if="membersPart" class="col-12 col-md-12 pr-0 pl-0 pt-0 ">
            <div>
              <div class="text-gray col-md-12 row justify-end pt-0">
                <span class="col-xl-4 col-lg-4 col-md-12">
                  <v-text-field
                    class="input-control mb-5"
                    prepend-inner-icon="mdi-magnify"
                    light
                    dense
                    outlined
                    placeholder="Search Members"
                  ></v-text-field>
                </span>
                <span
                  class="col-xl-4 col-lg-3 col-md-12  pr-0"
                  align="end"
                >
                  <a
                    class="
                      light-blue-text
                      text-underline
                      ml-10
                      mt-10
                      font-weight-bold
                    "
                    @click="memberDialog = true"
                  >
                    Upload Member List</a
                  >
                </span>
              </div>
       <v-divider horizontal thickness="2"></v-divider>
              <div class="col-md-12 pl-0 pr-0">
                <div class="pr-0 pl-0">
                  <div class="col-md-12 pl-0 pr-0 pt-0 text-gray">
                    <div class="col-md-12 pl-0 pr-0">
                      <v-simple-table fixed-header height="630px">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-center">MEMBER ID</th>
                              <th class="text-center">PLAN ID</th>
                              <th class="text-center">FIRST NAME</th>
                              <th class="text-center">LAST NAME</th>
                              <th class="text-center">GENDER</th>
                              <th class="text-center"> DATE OF BIRTH</th>
                              <th class="text-center">RELATIONSHIP</th>
                              <th class="text-center">SERVICE DATE</th>
                              <th class="text-center">AMOUNT(USD)</th>
                              <th class="text-end">ACTION</th>
                            </tr>
                          </thead>
                          <tbody class="mt-2">
                            <tr class="text-gray">
                              <td class="pr-0">
                                <v-text-field
                                 hide-details 
                                 v-model="member.id"
                                 :rules="memberIdRules"
                                 counter="9"
                                 maxlength="9"
                                 solo>
                                </v-text-field>
                              </td>
                              <td class="pr-0">
                                <v-text-field
                                 hide-details 
                                 v-model="member.planId"
                                 counter="9"
                                 maxlength="9"
                                 solo>
                                </v-text-field>
                              </td>
                              <td class="pr-0">
                                <v-text-field 
                                hide-details
                                v-model="member.firstName"
                                :rules="requiredRules"
                                 solo>
                                </v-text-field>
                              </td>
                              <td class="pr-0">
                                <v-text-field 
                                hide-details 
                                v-model="member.lastName"
                                :rules="requiredRules"
                                solo>
                              </v-text-field>
                              </td>
                              <td class="pr-0">
                                <v-select
                                hide-details
                                v-model="member.gender"
                                :items="genders"
                                 solo>
                                </v-select>
                              </td>
                              <td class="pr-0 pl-0">
                                <div class="col-md-12">
                                  <v-menu
                                    ref="menu"
                                    transition="scale-transition"
                                    :close-on-content-click="false"
                                    offset-y
                                    v-model="menu"
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        solo
                                        hide-details
                                        v-model="member.dob"
                                        append-icon="mdi-calendar"
                                        persistent-hint
                                        v-bind="attrs"
                                        placeholder="YYYY-MM-DD"
                                        v-on="on"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      no-title
                                      @input="menu = false"
                                      v-model="member.dob"
                                      >
                                      <v-spacer></v-spacer>
                                    </v-date-picker>
                                  </v-menu>
                                </div>
                              </td>
                              <td class="pr-0">
                                <v-select
                                 hide-details
                                  solo
                                  :items="relationships"
                                  v-model="member.relationship">
                                </v-select>
                              </td>
                              <td class="pr-0 pl-0">
                                <div class="col-md-12">
                                  <v-menu
                                    ref="menu1"
                                    transition="scale-transition"
                                    :close-on-content-click="false"
                                    offset-y
                                    v-model="menu1"
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        solo
                                        hide-details
                                        v-model="member.service"
                                        append-icon="mdi-calendar"
                                        persistent-hint
                                        v-bind="attrs"
                                        placeholder="YYYY-MM-DD"
                                        v-on="on"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                     no-title
                                      @input="menu1 = false"
                                      v-model="member.service">
                                      <v-spacer></v-spacer>
                                    </v-date-picker>
                                  </v-menu>
                                </div>
                              </td>
                              <td class="pr-0">
                                <v-text-field
                                 hide-details 
                                 solo
                                 v-model="member.amount"
                                 ></v-text-field>
                              </td>
                              <td class="pr-0 text-end">
                                <svg @click="addNewMember()"
                                  class="pointer"
                                  width="26"
                                  height="24"
                                  viewBox="0 0 26 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M13.0059 6.97266V16.1714"
                                    stroke="#09951F"
                                    stroke-width="2.4"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M8.11328 11.5723H17.8967"
                                    stroke="#09951F"
                                    stroke-width="2.4"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M19.1208 1.22363H6.89164C4.19006 1.22363 2 3.28284 2 5.82299V17.3214C2 19.8616 4.19006 21.9208 6.89164 21.9208H19.1208C21.8223 21.9208 24.0124 19.8616 24.0124 17.3214V5.82299C24.0124 3.28284 21.8223 1.22363 19.1208 1.22363Z"
                                    stroke="#09951F"
                                    stroke-width="2.4"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </td>
                            </tr>
                            <tr v-for="(item,index) in newMembers" :key="index">
                              <td class="text-center">{{item.id}}</td>
                              <td class="text-center">{{item.planId}}</td>
                              <td class="text-center">{{ item.firstName }}  </td>
                              <td class="text-center">{{ item.lastName }} </td>
                              <td  v-if="item.gender == 'm'"
                              class="text-center">Male </td>
                              <td v-if="item.gender == 'f'"
                              class="text-center">Female </td>
                              <td class="text-center">{{ item.dob }} </td>
                              <td class="text-center">{{ item.relationship }} </td>
                              <td class="text-center">{{ item.service }} </td>
                              <td class="text-center">{{ item.amount }} $ </td>
                              <td class="text-underline"> <a @click="removeMember(index)">Remove</a></td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </div>
                  </div>
  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="gray-top-border">
        <div class="col-md-12" align="end">
          <span
            class="blue--text text-underline mr-10 font-weight-bold pointer"
            @click="$router.push('/vendors/invoices')"
            >Cancel</span
          >
          <span
            class="blue--text text-underline ml-10 mr-10 font-weight-bold pointer"
            @click="submit()"
            >Submit Charges</span
          >
        </div>
      </div>

      <v-dialog v-model="memberDialog" persistent max-width="800px">
        <v-card>
          <v-card-text>
            <v-container>
              <v-col md="12" cols="12" class="justify-center align-center">
                <div class="row col-md-12 font">
                  <h3 class="ml-3">Upload a List of Plan Members</h3>
                </div>
                <div class="row text-center justify-center upload-box"  @click="addItemExcel">
                  <div
                    class="col-md-10 upload-provider-box pointer"
                    style="padding-bottom: 30px; padding-top: 50px"
                  >
                    <input
                      ref="fileInput"
                      type="file"
                      style="display: none"
                      @change="uploadExcel"
                    />
                    <svg
                      width="100"
                      height="100"
                      viewBox="0 0 100 100"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M90.2135 56.7417C86.921 54.5234 83.1435 53.1503 79.208 52.7413C75.2726 52.3322 71.2979 52.8996 67.6281 54.3942C63.9583 55.8889 60.704 58.2658 58.147 61.319C55.5901 64.3723 53.8075 68.01 52.9537 71.917C51.4417 76.8925 51.7658 82.2538 53.8659 87.0059C55.9661 91.758 59.6995 95.5781 64.3734 97.7571C66.9118 98.6888 69.5174 99.4217 72.1671 99.9491C72.3424 99.9993 72.5259 100.013 72.7065 99.9884C72.8871 99.9642 73.0608 99.9028 73.217 99.808C73.3732 99.7132 73.5086 99.587 73.6147 99.4373C73.7209 99.2876 73.7956 99.1174 73.8342 98.9374C74.4177 96.6189 70.8751 97.3355 65.4987 94.9328C61.6722 92.9399 58.6871 89.615 57.094 85.5711C55.5008 81.5272 55.4069 77.0371 56.8297 72.9287C57.619 69.7491 59.1452 66.8053 61.2821 64.3404C63.419 61.8756 66.1053 59.9606 69.1189 58.7538C72.1325 57.547 75.3868 57.0829 78.6128 57.4001C81.8388 57.7172 84.9438 58.8065 87.6712 60.5777C91.3984 63.1527 94.1367 66.9458 95.4268 71.3206C96.7168 75.6954 96.4801 80.3859 94.7564 84.6052C92.5892 89.9587 87.046 96.5768 81.1695 96.8297C79.169 96.8297 78.9606 101.34 86.0458 98.2629C91.2017 95.6077 95.3368 91.2918 97.7989 85.9963C100.112 80.9917 100.62 75.326 99.2345 69.9822C97.8489 64.6385 94.6573 59.954 90.2135 56.7417Z"
                        fill="#4072B7"
                      />
                      <path
                        d="M79.1678 70.3152C81.2871 72.78 82.902 75.6455 83.919 78.7459C83.919 79.1148 84.0639 79.4686 84.3219 79.7295C84.5798 79.9904 84.9296 80.1369 85.2944 80.1369C85.6592 80.1369 86.009 79.9904 86.2669 79.7295C86.5249 79.4686 86.6698 79.1148 86.6698 78.7459C86.3859 76.2964 85.725 73.9069 84.7109 71.6641C84.2108 70.6102 83.7106 69.725 83.0021 68.2918C82.4951 67.1637 81.7563 66.1578 80.8349 65.341C78.5843 63.7814 75.2501 65.341 73.2912 67.1115C70.7302 69.9385 68.7974 73.2876 67.6231 76.9333C67.543 77.0823 67.4946 77.2465 67.4807 77.4156C67.4668 77.5846 67.4879 77.7547 67.5425 77.915C67.5972 78.0753 67.6842 78.2224 67.7981 78.3469C67.912 78.4714 68.0502 78.5705 68.2041 78.638C68.3579 78.7055 68.5239 78.7399 68.6916 78.739C68.8593 78.7382 69.0249 78.702 69.178 78.6328C69.3311 78.5637 69.4683 78.4631 69.5809 78.3374C69.6935 78.2117 69.779 78.0637 69.832 77.9028C71.1592 75.4575 72.9097 73.2731 75 71.4533C75 72.5071 75 73.8561 75 74.1511C74.3604 77.4967 74.192 80.9169 74.4999 84.3101C74.4375 86.1328 74.5773 87.9568 74.9167 89.7479C74.9774 90.0665 75.1609 90.3477 75.4266 90.5295C75.6924 90.7113 76.0187 90.7789 76.3337 90.7174C76.6487 90.656 76.9267 90.4704 77.1065 90.2016C77.2862 89.9329 77.3531 89.6029 77.2923 89.2842C77.4945 87.705 77.8576 86.1511 78.3759 84.6473C79.1199 81.2726 79.289 77.7942 78.876 74.3619C78.4593 67.4909 78.2925 69.0506 79.1678 70.3152Z"
                        fill="#4072B7"
                      />
                      <path
                        d="M20.1537 21.7544C22.1876 22.4515 24.2802 22.9594 26.4053 23.2719C30.5503 23.8952 34.7637 23.8952 38.9086 23.2719C39.2734 23.2719 39.6232 23.1253 39.8812 22.8645C40.1391 22.6036 40.284 22.2498 40.284 21.8808C40.284 21.5119 40.1391 21.1581 39.8812 20.8972C39.6232 20.6363 39.2734 20.4898 38.9086 20.4898C34.8425 19.3353 30.627 18.8094 26.4053 18.9301C24.138 18.6988 21.848 18.899 19.6535 19.5202C19.1951 19.8575 18.9867 21.0377 20.1537 21.7544Z"
                        fill="#4072B7"
                      />
                      <path
                        d="M18.8204 38.1517C18.722 38.4162 18.7139 38.7064 18.7976 38.9761C18.8813 39.2458 19.0519 39.4793 19.2822 39.6394C19.5125 39.7995 19.7892 39.877 20.0681 39.8595C20.347 39.842 20.6121 39.7305 20.821 39.5427C24.7915 39.4639 28.7623 39.6894 32.6991 40.2172C42.5767 40.4701 49.3702 39.1634 56.7055 38.784C57.0344 38.7263 57.3326 38.553 57.5476 38.2946C57.7625 38.0363 57.8804 37.7095 57.8804 37.3718C57.8804 37.0342 57.7625 36.7074 57.5476 36.449C57.3326 36.1907 57.0344 36.0174 56.7055 35.9597C47.2056 35.158 37.6562 35.158 28.1562 35.9597C26.4058 36.2548 20.1541 35.9175 18.8204 38.1517Z"
                        fill="#4072B7"
                      />
                      <path
                        d="M43.9089 54.7602C37.0909 53.4732 30.0964 53.4732 23.2784 54.7602C19.9442 55.3082 19.4857 55.266 19.1106 55.6876C18.9082 55.837 18.7549 56.0446 18.6706 56.2833C18.5862 56.522 18.5748 56.7808 18.6377 57.0262C18.7006 57.2716 18.8349 57.4922 19.0233 57.6594C19.2117 57.8266 19.4454 57.9327 19.6941 57.9638C20.2352 58.0036 20.7785 58.0036 21.3195 57.9638C28.8509 59.0779 36.5025 59.0779 44.0339 57.9638C44.2419 57.9555 44.4462 57.9059 44.6352 57.8177C44.8242 57.7295 44.9942 57.6046 45.1355 57.45C45.2767 57.2954 45.3865 57.1141 45.4585 56.9166C45.5305 56.7191 45.5633 56.5091 45.5551 56.2988C45.5469 56.0884 45.4978 55.8818 45.4106 55.6906C45.3235 55.4994 45.1999 55.3275 45.0471 55.1846C44.8942 55.0418 44.715 54.9307 44.5197 54.8579C44.3244 54.7851 44.1168 54.7519 43.9089 54.7602Z"
                        fill="#4072B7"
                      />
                      <path
                        d="M41.2847 72.8444C29.5732 72.8444 16.8199 71.2847 5.10843 69.9358C11.1934 54.6341 10.5682 32.7985 12.4854 8.39167C12.4854 7.97014 13.3189 6.62123 13.694 6.53692C17.8618 5.56739 37.4503 2.82741 39.9927 2.70095C41.8441 2.55245 43.7069 2.738 45.4941 3.24895C47.0362 3.62833 49.9953 -0.123332 39.7843 0.0031284C37.492 0.0452819 15.5279 1.98434 12.0686 2.91172C8.60935 3.8391 8.526 6.19969 7.48406 15.5578C5.5252 43.6741 5.35849 50.9667 3.31629 60.9149C1.27408 70.8632 -0.684772 71.0739 0.232138 72.6336C0.388726 72.9446 0.619216 73.2114 0.902917 73.41C1.18662 73.6087 1.51465 73.733 1.85757 73.7717C14.1525 74.7413 28.9064 75.9216 41.2847 75.6686C41.6136 75.611 41.9118 75.4377 42.1268 75.1793C42.3417 74.921 42.4596 74.5942 42.4596 74.2565C42.4596 73.9189 42.3417 73.592 42.1268 73.3337C41.9118 73.0754 41.6136 72.9021 41.2847 72.8444Z"
                        fill="#4072B7"
                      />
                      <path
                        d="M61.7465 13.1549C63.2469 14.5881 65.9143 16.4007 66.6228 17.9604L66.206 44.2642C66.2591 44.5548 66.4112 44.8173 66.6358 45.0064C66.8604 45.1954 67.1433 45.2989 67.4355 45.2989C67.7277 45.2989 68.0106 45.1954 68.2352 45.0064C68.4598 44.8173 68.6119 44.5548 68.665 44.2642C68.665 41.9879 72.8328 20.1524 70.7906 15.8527C69.3319 12.9863 69.0401 13.9558 54.703 1.77345C54.4073 1.58391 54.0527 1.51177 53.7076 1.57094C53.3625 1.6301 53.0512 1.81637 52.8338 2.09386C52.6164 2.37135 52.5082 2.72041 52.5301 3.07376C52.5521 3.42712 52.7025 3.75977 52.9525 4.00759L61.7465 13.1549Z"
                        fill="#4072B7"
                      />
                      <path
                        d="M47.0367 21.3326C49.204 25.5479 56.706 23.7775 61.4572 22.4707C69.7928 20.1944 65.625 16.0634 63.9579 18.2554C59.8212 19.6497 55.3959 19.9258 51.1212 19.0563C50.7683 16.1625 50.8809 13.2301 51.4546 10.3727C51.788 5.77794 51.8297 5.86225 51.0795 5.60933C49.4957 4.93487 49.079 7.46408 48.4538 9.82468C47.6202 12.944 45.1196 17.4966 47.0367 21.3326Z"
                        fill="#4072B7"
                      />
                      <path
                        d="M73.4178 8.47589C75.5458 10.1893 77.4752 12.1408 79.1693 14.2931L76.7103 45.1494C76.7103 46.7934 77.8356 47.4679 78.7108 46.119C79.9612 42.3673 85.0042 15.1361 82.2951 11.258C78.8487 7.418 74.8769 4.09639 70.5003 1.39411C70.3651 1.251 70.2011 1.13875 70.0195 1.06486C69.8379 0.990971 69.6428 0.957142 69.4472 0.965636C69.2516 0.974131 69.0601 1.02475 68.8854 1.11412C68.7108 1.20348 68.557 1.32953 68.4343 1.48384C68.3116 1.63815 68.223 1.81716 68.1742 2.00891C68.1254 2.20065 68.1177 2.40072 68.1515 2.59573C68.1854 2.79075 68.2599 2.97621 68.3703 3.13973C68.4807 3.30325 68.6243 3.44105 68.7915 3.54394L73.4178 8.47589Z"
                        fill="#F6A405"
                      />
                    </svg>

                    <h2 class="text-gray font">Drag & Drop</h2>
                    <h5 class="text-gray font">
                      Your files here or
                      <a>Browse</a>
                      to upload
                    </h5>
                    <br />
                    <h5 class="text-gray font">
                      <a>CSV or Excel Files Only</a> <br />
                      <img
                        v-if="arrayLength"
                        src="/success.png"
                        width="30"
                        height="30"
                        alt=""
                      />
                    </h5>
                  </div>
                  <div
                    class="col-md-12 justify-center text-center row pb-0 mt-2"
                    v-if="arrayLength"
                  >
                    <div class="col-md-11 pr-6 pl-6 text-center pb-0">
                      <v-alert type="success" outlined>
                        File uploaded successfully</v-alert
                      >
                    </div>
                  </div>
                  <div class="btn-box col-md-11 pb-0">
                    <div class="downoald-file-btn-box">
                      <h3>
                        <a class="underline-text" @click="downloadExcel"
                          >Download File Template</a
                        >
                      </h3>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <div class="col-md-12 mb-4" align="end">
              <span
                class="text-underline text-gray mr-10 pointer"
                @click="memberDialog = false"
                >Cancel</span
              >
              <span
                class="text-underline ml-10 mr-10 text-gray pointer"
                @click="memberDialog = false"
                >Upload</span
              >
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="uploadDialog" persistent max-width="800px">
        <v-card>
          <v-card-text>
            <v-container>
              <v-col md="12" cols="12" class="justify-center align-center">
                <div class="row col-md-12 font">
                  <h3 class="ml-3">Upload an Invoice</h3>
                </div>
                <div class="row text-center justify-center upload-box">
                  <div
                    class="col-md-10 upload-provider-box pointer"
                    style="padding-bottom: 30px; padding-top: 50px"
                  >
                    <!-- <input
                      ref="fileInput"
                      type="file"
                      style="display: none"
                      @change="uploadExcel"
                    /> -->
                    <v-file-input
                      @change="Preview_pdf"
                      v-model.trim="dataComponents.pdf"
                      required
                      solo
                      truncate-length="15"
                      ref="profileInput"
                    ></v-file-input>
                    <svg
                      width="100"
                      height="100"
                      viewBox="0 0 100 100"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M90.2135 56.7417C86.921 54.5234 83.1435 53.1503 79.208 52.7413C75.2726 52.3322 71.2979 52.8996 67.6281 54.3942C63.9583 55.8889 60.704 58.2658 58.147 61.319C55.5901 64.3723 53.8075 68.01 52.9537 71.917C51.4417 76.8925 51.7658 82.2538 53.8659 87.0059C55.9661 91.758 59.6995 95.5781 64.3734 97.7571C66.9118 98.6888 69.5174 99.4217 72.1671 99.9491C72.3424 99.9993 72.5259 100.013 72.7065 99.9884C72.8871 99.9642 73.0608 99.9028 73.217 99.808C73.3732 99.7132 73.5086 99.587 73.6147 99.4373C73.7209 99.2876 73.7956 99.1174 73.8342 98.9374C74.4177 96.6189 70.8751 97.3355 65.4987 94.9328C61.6722 92.9399 58.6871 89.615 57.094 85.5711C55.5008 81.5272 55.4069 77.0371 56.8297 72.9287C57.619 69.7491 59.1452 66.8053 61.2821 64.3404C63.419 61.8756 66.1053 59.9606 69.1189 58.7538C72.1325 57.547 75.3868 57.0829 78.6128 57.4001C81.8388 57.7172 84.9438 58.8065 87.6712 60.5777C91.3984 63.1527 94.1367 66.9458 95.4268 71.3206C96.7168 75.6954 96.4801 80.3859 94.7564 84.6052C92.5892 89.9587 87.046 96.5768 81.1695 96.8297C79.169 96.8297 78.9606 101.34 86.0458 98.2629C91.2017 95.6077 95.3368 91.2918 97.7989 85.9963C100.112 80.9917 100.62 75.326 99.2345 69.9822C97.8489 64.6385 94.6573 59.954 90.2135 56.7417Z"
                        fill="#4072B7"
                      />
                      <path
                        d="M79.1678 70.3152C81.2871 72.78 82.902 75.6455 83.919 78.7459C83.919 79.1148 84.0639 79.4686 84.3219 79.7295C84.5798 79.9904 84.9296 80.1369 85.2944 80.1369C85.6592 80.1369 86.009 79.9904 86.2669 79.7295C86.5249 79.4686 86.6698 79.1148 86.6698 78.7459C86.3859 76.2964 85.725 73.9069 84.7109 71.6641C84.2108 70.6102 83.7106 69.725 83.0021 68.2918C82.4951 67.1637 81.7563 66.1578 80.8349 65.341C78.5843 63.7814 75.2501 65.341 73.2912 67.1115C70.7302 69.9385 68.7974 73.2876 67.6231 76.9333C67.543 77.0823 67.4946 77.2465 67.4807 77.4156C67.4668 77.5846 67.4879 77.7547 67.5425 77.915C67.5972 78.0753 67.6842 78.2224 67.7981 78.3469C67.912 78.4714 68.0502 78.5705 68.2041 78.638C68.3579 78.7055 68.5239 78.7399 68.6916 78.739C68.8593 78.7382 69.0249 78.702 69.178 78.6328C69.3311 78.5637 69.4683 78.4631 69.5809 78.3374C69.6935 78.2117 69.779 78.0637 69.832 77.9028C71.1592 75.4575 72.9097 73.2731 75 71.4533C75 72.5071 75 73.8561 75 74.1511C74.3604 77.4967 74.192 80.9169 74.4999 84.3101C74.4375 86.1328 74.5773 87.9568 74.9167 89.7479C74.9774 90.0665 75.1609 90.3477 75.4266 90.5295C75.6924 90.7113 76.0187 90.7789 76.3337 90.7174C76.6487 90.656 76.9267 90.4704 77.1065 90.2016C77.2862 89.9329 77.3531 89.6029 77.2923 89.2842C77.4945 87.705 77.8576 86.1511 78.3759 84.6473C79.1199 81.2726 79.289 77.7942 78.876 74.3619C78.4593 67.4909 78.2925 69.0506 79.1678 70.3152Z"
                        fill="#4072B7"
                      />
                      <path
                        d="M20.1537 21.7544C22.1876 22.4515 24.2802 22.9594 26.4053 23.2719C30.5503 23.8952 34.7637 23.8952 38.9086 23.2719C39.2734 23.2719 39.6232 23.1253 39.8812 22.8645C40.1391 22.6036 40.284 22.2498 40.284 21.8808C40.284 21.5119 40.1391 21.1581 39.8812 20.8972C39.6232 20.6363 39.2734 20.4898 38.9086 20.4898C34.8425 19.3353 30.627 18.8094 26.4053 18.9301C24.138 18.6988 21.848 18.899 19.6535 19.5202C19.1951 19.8575 18.9867 21.0377 20.1537 21.7544Z"
                        fill="#4072B7"
                      />
                      <path
                        d="M18.8204 38.1517C18.722 38.4162 18.7139 38.7064 18.7976 38.9761C18.8813 39.2458 19.0519 39.4793 19.2822 39.6394C19.5125 39.7995 19.7892 39.877 20.0681 39.8595C20.347 39.842 20.6121 39.7305 20.821 39.5427C24.7915 39.4639 28.7623 39.6894 32.6991 40.2172C42.5767 40.4701 49.3702 39.1634 56.7055 38.784C57.0344 38.7263 57.3326 38.553 57.5476 38.2946C57.7625 38.0363 57.8804 37.7095 57.8804 37.3718C57.8804 37.0342 57.7625 36.7074 57.5476 36.449C57.3326 36.1907 57.0344 36.0174 56.7055 35.9597C47.2056 35.158 37.6562 35.158 28.1562 35.9597C26.4058 36.2548 20.1541 35.9175 18.8204 38.1517Z"
                        fill="#4072B7"
                      />
                      <path
                        d="M43.9089 54.7602C37.0909 53.4732 30.0964 53.4732 23.2784 54.7602C19.9442 55.3082 19.4857 55.266 19.1106 55.6876C18.9082 55.837 18.7549 56.0446 18.6706 56.2833C18.5862 56.522 18.5748 56.7808 18.6377 57.0262C18.7006 57.2716 18.8349 57.4922 19.0233 57.6594C19.2117 57.8266 19.4454 57.9327 19.6941 57.9638C20.2352 58.0036 20.7785 58.0036 21.3195 57.9638C28.8509 59.0779 36.5025 59.0779 44.0339 57.9638C44.2419 57.9555 44.4462 57.9059 44.6352 57.8177C44.8242 57.7295 44.9942 57.6046 45.1355 57.45C45.2767 57.2954 45.3865 57.1141 45.4585 56.9166C45.5305 56.7191 45.5633 56.5091 45.5551 56.2988C45.5469 56.0884 45.4978 55.8818 45.4106 55.6906C45.3235 55.4994 45.1999 55.3275 45.0471 55.1846C44.8942 55.0418 44.715 54.9307 44.5197 54.8579C44.3244 54.7851 44.1168 54.7519 43.9089 54.7602Z"
                        fill="#4072B7"
                      />
                      <path
                        d="M41.2847 72.8444C29.5732 72.8444 16.8199 71.2847 5.10843 69.9358C11.1934 54.6341 10.5682 32.7985 12.4854 8.39167C12.4854 7.97014 13.3189 6.62123 13.694 6.53692C17.8618 5.56739 37.4503 2.82741 39.9927 2.70095C41.8441 2.55245 43.7069 2.738 45.4941 3.24895C47.0362 3.62833 49.9953 -0.123332 39.7843 0.0031284C37.492 0.0452819 15.5279 1.98434 12.0686 2.91172C8.60935 3.8391 8.526 6.19969 7.48406 15.5578C5.5252 43.6741 5.35849 50.9667 3.31629 60.9149C1.27408 70.8632 -0.684772 71.0739 0.232138 72.6336C0.388726 72.9446 0.619216 73.2114 0.902917 73.41C1.18662 73.6087 1.51465 73.733 1.85757 73.7717C14.1525 74.7413 28.9064 75.9216 41.2847 75.6686C41.6136 75.611 41.9118 75.4377 42.1268 75.1793C42.3417 74.921 42.4596 74.5942 42.4596 74.2565C42.4596 73.9189 42.3417 73.592 42.1268 73.3337C41.9118 73.0754 41.6136 72.9021 41.2847 72.8444Z"
                        fill="#4072B7"
                      />
                      <path
                        d="M61.7465 13.1549C63.2469 14.5881 65.9143 16.4007 66.6228 17.9604L66.206 44.2642C66.2591 44.5548 66.4112 44.8173 66.6358 45.0064C66.8604 45.1954 67.1433 45.2989 67.4355 45.2989C67.7277 45.2989 68.0106 45.1954 68.2352 45.0064C68.4598 44.8173 68.6119 44.5548 68.665 44.2642C68.665 41.9879 72.8328 20.1524 70.7906 15.8527C69.3319 12.9863 69.0401 13.9558 54.703 1.77345C54.4073 1.58391 54.0527 1.51177 53.7076 1.57094C53.3625 1.6301 53.0512 1.81637 52.8338 2.09386C52.6164 2.37135 52.5082 2.72041 52.5301 3.07376C52.5521 3.42712 52.7025 3.75977 52.9525 4.00759L61.7465 13.1549Z"
                        fill="#4072B7"
                      />
                      <path
                        d="M47.0367 21.3326C49.204 25.5479 56.706 23.7775 61.4572 22.4707C69.7928 20.1944 65.625 16.0634 63.9579 18.2554C59.8212 19.6497 55.3959 19.9258 51.1212 19.0563C50.7683 16.1625 50.8809 13.2301 51.4546 10.3727C51.788 5.77794 51.8297 5.86225 51.0795 5.60933C49.4957 4.93487 49.079 7.46408 48.4538 9.82468C47.6202 12.944 45.1196 17.4966 47.0367 21.3326Z"
                        fill="#4072B7"
                      />
                      <path
                        d="M73.4178 8.47589C75.5458 10.1893 77.4752 12.1408 79.1693 14.2931L76.7103 45.1494C76.7103 46.7934 77.8356 47.4679 78.7108 46.119C79.9612 42.3673 85.0042 15.1361 82.2951 11.258C78.8487 7.418 74.8769 4.09639 70.5003 1.39411C70.3651 1.251 70.2011 1.13875 70.0195 1.06486C69.8379 0.990971 69.6428 0.957142 69.4472 0.965636C69.2516 0.974131 69.0601 1.02475 68.8854 1.11412C68.7108 1.20348 68.557 1.32953 68.4343 1.48384C68.3116 1.63815 68.223 1.81716 68.1742 2.00891C68.1254 2.20065 68.1177 2.40072 68.1515 2.59573C68.1854 2.79075 68.2599 2.97621 68.3703 3.13973C68.4807 3.30325 68.6243 3.44105 68.7915 3.54394L73.4178 8.47589Z"
                        fill="#F6A405"
                      />
                    </svg>

                    <h2 class="text-gray font">Drag & Drop</h2>
                    <h5 class="text-gray font">
                      Your files here or
                      <a>Browse</a>
                      to upload
                    </h5>
                    <br />
                    <h5 class="text-gray font">
                      <a>PDF FIle Only</a> <br />
                      <img
                        v-if="arrayLength"
                        src="/success.png"
                        width="30"
                        height="30"
                        alt=""
                      />
                    </h5>
                  </div>
                  <div
                    class="col-md-12 justify-center text-center row pb-0 mt-2"
                    v-if="arrayLength"
                  >
                    <div class="col-md-11 pr-6 pl-6 text-center pb-0">
                      <v-alert type="success" outlined>
                        File uploaded successfully</v-alert
                      >
                    </div>
                  </div>
                </div>
              </v-col>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <div class="col-md-12 mb-4" align="end">
              <span
                class="text-underline text-gray mr-10 pointer"
                @click="uploadDialog = false"
                >Cancel</span
              >
              <span
                class="text-underline ml-10 mr-10 text-gray pointer"
                @click="uploadDialog = false"
                >Upload</span
              >
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </div>
    <successModal ref="success_modal" v-on:confirm="updateModal"></successModal>
    <errorModal
        ref="error_modal"
         v-on:confirm="closeErrorModal">
        </errorModal>
  </v-card>
</template>
<script>
import Header from "../../DigitalVendorInvoices/Layout/HeaderPage.vue";
import api from "../../../../services/api";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../TablesTemplate/ErrorDialog.vue";
import XLSX from "xlsx";
import pdf from "jspdf";
import { jsPDF } from "jspdf";
export default {
  components: {  Header, successModal, errorModal },
  data() {
    return {
      user: {},
      newMembers: [],
      invoiceItem: {},
      pdf: null,
      pdfUrl: null,
      menu: "",
      menu1: "",
      member: {
        planId: "",
        id: "",
        firstName: "",
        lastName: "",
        gender: "",
        dob: "",
        relationship: "",
        service: "",
        amount: "",
      },
      genders: [{ text: 'Male', value: 'm'},
                {text: 'Female', value: 'f'}
              ],
      relationships: [
                { text: 'Self', value: 'Self'},
                {text: 'Spouse', value: 'Spouse'},
                {text: 'Dependent', value: 'Dependent'}
              ],
      memberIdRules: [
        (v) => !!v || "This field is required",
        (v) => (v && v.length == 9) || "Member ID should contain 9 digits",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
      invoicesPart: true,
      membersPart: false,
      requestDialog: false,
      item: {},
      tab: "invoice",
      billingPeriod: "",
      uploadDialog: false,
      arrayLength: false,
      memberError: false,
      emailDialog: false,
      memberDialog: false,
      items: [],
      invoiceNo: "",
      startDate: "",
      endDate: "",
      clients: [],
      client: "",
      planId: "",
      total: "",
      valid: false,
      uploadedFile: null,
      pdfEvent: null,
      dataComponents: {
        pdf: {
          name: "",
        },
      },
      rules: [
        (v) =>
          (v && v.length > 4) ||
          "The invoice should contain more than 4 digits",
      ],
      jsondata: [
        {
          memberId: "",
          planId: "",
          memberFirstName: "",
          memberLastName: "",
          gender: "",
          dateOfBirth: "",
          subscriberRelation: "",
          serviceDate: "",
          amount: "",
        },
      ],
    };
  },
  computed: {
    object() {
      return this.$route.params.invoiceObject;
    },
    pdfFile() {
      return this.$route.params.pdf;
    },
    type() {
      return this.$route.params.type;
    },
    contact(){
      let contact = {
      };
      if (localStorage.getItem("user")) {
        contact = JSON.parse(localStorage.getItem("user"));
      }
      return contact;
    }
  },
  mounted() {
    this.createPdfUrl();
  },
  created() {
    this.item = this.$store.state.Invoicing.singleSoulution;
    this.getClients();
    this.getInfo();
    this.getiInvoiceObject();
  },
  methods: {
    base64ToBlob(base64, type) {
      const binary = atob(base64);
      const binaryLength = binary.length;
      const bytes = new Uint8Array(binaryLength);
      for (let i = 0; i < binaryLength; i++) {
        bytes[i] = binary.charCodeAt(i);
      }
      return new Blob([bytes], { type: type });
    },
    createPdfUrl() {
      const pdfBlob = this.base64ToBlob(this.pdf, 'application/pdf');
      this.pdfUrl = URL.createObjectURL(pdfBlob);
    },
    getiInvoiceObject(){
      if(this.type == 'pdf')
      {
        this.invoiceItem = this.object;
        this.invoiceNo = this.object.invoiceNo ? this.object.invoiceNo : '';
        this.startDate = this.object.date;
        this.startDate = this.startdate ? this.startDate.substring(0,10) : '';
        this.total = this.object.total ? this.object.total : '';
        this.client = this.object.client ? this.object.client.id : '';
        this.pdf = this.object.base64 ? this.object.base64 : '';
      }
    },
    addNewMember(){
      this.newMembers.push(this.member);
      this.member = {
        id: "",
        firstName: "",
        lastName: "",
        gender: "",
        dob: "",
        relationship: "",
        service: "",
        amount: "",
      };
    },
    removeMember(index){
      this.newMembers.splice(index,1);
    },
    invoiceTab(){
      this.invoicesPart = true;
      this.membersPart = false;
    },
    memberTab(){
      this.invoicesPart = false;
      this.membersPart = true;
    },
    Preview_pdf(event) {
      this.pdfEvent = event;
    },
    updateModal() {
      this.$router.push("/vendors/invoices");
    },
    getClients() {
      api()
        .get(`/vendors/clients`)
        .catch((err) => {
        })
        .then((res) => {
          if (res && res.data) {
            res.data.forEach((el) => {
           if(el.status == 'active'){
            this.clients.push(el);
           }
            });
          }
        });
    },
    uploadInvoice() {
      this.uploadDialog = true;
    },
    checkInvoiceNumber(){
      api()
      .get(`/vendors/invoices/check/` + this.invoiceNo)
        .then((res) => {
          if(res.data.exist == true){
          this.$refs.error_modal.openPopUp('This Invoice Already Exists!');
          }
        });
    },
    submit() {
      this.checkInvoiceNumber();
          let data = {
            invoiceNo : this.invoiceNo,
            date : this.startDate,
            total : Number(this.total),
            members: this.newMembers.map(item => ({
              memberId: item.id,
              planId: item.planId,
              firstName: item.firstName ,
              lastName: item.lastName,
              gender: item.gender,
              dob: item.dob,
              subscriberRelation: item.relationship ,
              serviceDate: item.service ,
              amount: Number(item.amount) ,
        }))
          }
          api()
        .post(`/vendors/invoices/client/`+ this.client.client.id, data)
        .then((res) => {
     
          const formData = new FormData();
          
          formData.append('invoice', this.pdfFile);
            api()
            .put(`/vendors/invoice/${res.data[0]._id}/attach`, formData)
            .then((result) => {
              if(result)
              console.log('res',result.data)
             });
             api()
            .put(`/vendors/invoice/${res.data[1]._id}/attach`, formData)
            .then((result) => {
              if(result)
              console.log('res',result.data)
             });

             this.$refs.success_modal.openPopUp('Successfully Submitted!');
        });
        
    },
    downloadExcel() {
      const data = XLSX.utils.json_to_sheet(this.jsondata);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "uploadMembers.xlsx");
    },
    addItemExcel() {
      this.excelKey = Math.random()
      this.$refs.fileInput.click();
    },
    uploadExcel(e) {
      console.log('e',e)
      this.excelError = false;
      const files = e.target.files;
      if (!files.length) {
        return;
      } else if (!/\.(xls|xlsx|csv)$/.test(files[0].name.toLowerCase())) {
        return alert(
            "The upload format is incorrect. Please upload xls, xlsx or csv format"
        );
      }
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const workbook = XLSX.read(data, {
            type: "binary",
          });
         
          const wsname = workbook.SheetNames[0];
          
          console.log('wsname',wsname)
          console.log('workbook',workbook)

          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname],{raw:false});
  
          
          console.log('wess',ws)
          for (var i = 0; i < ws.length; i++) {
            console.log('wa',ws[i])
            if (ws[i].memberId) {
              this.excelError = false;
              let tmpObj = {
                  id: ws[i].memberId,
                  planId: ws[i].planId,
                  dob: ws[i].dateOfBirth.w ?? "",
                  firstName: ws[i].memberFirstName ?? "",
                  lastName: ws[i].memberLastName ?? "",
                  gender: ws[i].gender ?? "",
                  service: ws[i].serviceDate ?? "",
                  relationship: ws[i].subscriberRelation ?? "",
                  amount: ws[i].amount ?? "",
              }
              console.log('tmp',tmpObj)
              let data =  {
                  id: ws[i].memberId,
                  planId: ws[i].planId,
                  dob: ws[i].dateOfBirth ?? "",
                  firstName: ws[i].memberFirstName ?? "",
                  lastName: ws[i].memberLastName ?? "",
                  gender: ws[i].gender ?? "",
                  service: ws[i].serviceDate ?? "",
                  relationship: ws[i].subscriberRelation ? ws[i].subscriberRelation.charAt(0).toUpperCase() + ws[i].subscriberRelation.slice(1) : "",
                  amount: ws[i].amount ?? "",
              }

              if(data.id.toString().length < 9)
              {
                this.memberError = true;
              }
              else{
                   this.newMembers.push(data);
                   console.log('items',this.newMembers);
                     this.arrayLength = true;
              }
            } else {
              this.excelError = true;
              this.arrayLength = false;
            }
          }
          if (this.newMembers.length < 1) {
            this.arrayLength = false;
            this.newMembers = [];
            if(this.memberError)
            {
              this.$refs.error_modal.openPopUp(
                "Member ID should be 9 digits long"
            );
            }
            else{
              this.$refs.error_modal.openPopUp(
                "Something Went Wrong, Please Download The File Template First"
            );
            }
            
          }
        } catch (e) {
          console.log(e)
          return alert("Read failure! Please Download the File Template.");
        }
      };

      fileReader.readAsBinaryString(files[0]);
    },
    getInfo() {
      api()
        .get(`/vendors`)
        .then((res) => {
          if (res) {
         this.user = res.data
          }
        });
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
      this.$router.push('/vendor/invoices')
    },
  },
};
</script>
<style scoped>
.submit-btn {
  color: white;
  width: 150px;
}
.display {
  display: grid;
}
@media screen and (max-width: 768px) {
  .justify-logo {
    justify-content: flex-start !important;
    
  }
  .mobile-font{
   font-size: 13px;
  }
  .apaly-pic{
    height: 40px;
    width: 85px;
  }
}
</style>
