import Invoicing from '../views/DigitalVendorInvoicing.vue'
import RouterView from '../components/TablesTemplate/RouterView.vue'
import Home from '../components/Sections/DigitalVendorInvoices/Home.vue'
import Solutions from '../components/Sections/DigitalVendorInvoices/Solutions/Solutions.vue'
import SolutionDetails from '../components/Sections/DigitalVendorInvoices/Solutions/Details'

import Clients from '../components/Sections/DigitalVendorInvoices/Clients/Clients.vue'
import AddClient from '../components/Sections/DigitalVendorInvoices/Clients/AddNewCilent.vue'


import ClientsDetails from '../components/Sections/DigitalVendorInvoices/Clients/HorizontalMenu/ClientsDetails'

import ClientsPlans from '../components/Sections/DigitalVendorInvoices/Clients/HorizontalMenu/Plans/Plans.vue'
import ClientsPlanDetails from '../components/Sections/DigitalVendorInvoices/Clients/HorizontalMenu/Plans/Details.vue'
import ClinetsPlanAddNew from '../components/Sections/DigitalVendorInvoices/Clients/HorizontalMenu/Plans/AddNew.vue'

import ClientsBilling from '../components/Sections/DigitalVendorInvoices/Clients/HorizontalMenu/Billing/Billing'
import ClientsBillingDetails from '../components/Sections/DigitalVendorInvoices/Clients/HorizontalMenu/Billing/Details.vue'
import ClientsAddBilling from '../components/Sections/DigitalVendorInvoices/Clients/HorizontalMenu/Billing/AddNew.vue'
import ClientsSolutions from '../components/Sections/DigitalVendorInvoices/Clients/HorizontalMenu/Solutions/Solutions.vue'
import ClientsSolutionDetails from '../components/Sections/DigitalVendorInvoices/Clients/HorizontalMenu/Solutions/Details.vue'
import ClientsSettings from '../components/Sections/DigitalVendorInvoices/Clients/HorizontalMenu/Settings/Settings.vue'




import Billing from '../components/Sections/DigitalVendorInvoices/Billing/Billing.vue'
import BillingDetails from '../components/Sections/DigitalVendorInvoices/Billing/Details.vue'

import Invoices from '../components/Sections/DigitalVendorInvoices/Invoices/Invoices.vue'
import InvoiceDetails from '../components/Sections/DigitalVendorInvoices/Invoices/Details.vue'
import AddInvoice from '../components/Sections/DigitalVendorInvoices/Invoices/AddInvoice.vue'

import Account from '../components/Sections/DigitalVendorInvoices/Settings/Account'
import Settings from '../components/Sections/DigitalVendorInvoices/Settings/Settings'
import Users from '../components/Sections/DigitalVendorInvoices/Settings/Users'
import UserDetails from '../components/Sections/DigitalVendorInvoices/Settings/UserDetails'
import AddNewUser from '../components/Sections/DigitalVendorInvoices/Settings/AddUser'


function routeGuard(to, from, next) {
    let user = JSON.parse(localStorage.getItem('user'));
    if(!user){
        next('/login');
    }
    else if ( user.type != 'tpa' || !$cookies.get('token')){
      next('/login');
    }else{
        next();
    }
      
}
const router = [
    {
       path:'/digital-invoicing',
       name:'',
       beforeEnter: routeGuard,
       redirect: () => {
           return { path: '/digital-invoicing/home'} 
       },
    },
   {
       component:RouterView,
       path:'/digital-invoicing',
       name:'',
      beforeEnter: routeGuard,
       redirect: () => {
           return { path: '/digital-invoicing'} 
       },
       children:[
           {
               path:'/digital-invoicing',
               name:'',
           
               component:Invoicing,
               children:[
               
                {
                    path:'home',
                    name:'Home',
                    component:Home,
                },
                {
                    path:'home',
                    name:'Home',
                    component:RouterView,
                    redirect: () => {
                        return { path: '/digital-invoicing/home'} 
                    },
                    children:[
                        {
                            path:'/digital-invoicing/profile',
                            name:'Profile',
                            component:RouterView,
                        },
                        {
                            path:'solutions',
                            name:'',
                            component:RouterView,
                            redirect: () => {
                                return { path: '/digital-invoicing/solutions'} 
                            },
                            children:[                 
                                {  
                                    path:'/digital-invoicing/solutions',
                                    name:'Solutions',
                                    component:Solutions,
                                    children:[
                                        {
                                            path:'/digital-invoicing/solutions/details',
                                            name:'Solution Detail',
                                            component:SolutionDetails,
                                            meta:{
                                                isChildren:true
                                            },
                                        }
                                    ]
                                },
                            ]
                        },
                        {
                            path:'clients',
                            name:'',
                            component:RouterView,
                            redirect: () => {
                                return { path: '/digital-invoicing/clients'} 
                            },
                            children:[    
                                           
                                {  
                                    path:'/digital-invoicing/clients',
                                    name:'Clients',
                                    component:Clients,
                                    meta:{
                                       
                                        type:'clients'
                                    },
                                    children:[
                                        {
                                            path:'/digital-invoicing/clients/add-new',
                                            name:'Add Client',
                                            component:AddClient,
    
                                        }, 
                                        {
                                            path:'/digital-invoicing/clients/details/:id',
                                            name:'Client General Information',
                                            component:ClientsDetails,
                                            meta:{
                                                isChildren:true,
                                                type:'general'
                                            },
                                        },
                                        {
                                            path:'/digital-invoicing/clients/plans/:id',
                                            name:'Client Plans',
                                            component:ClientsPlans,
                                            meta:{
                                              
                                                type:'plans'
                                            },
                                            
                                        },
                                        {
                                            path:'/digital-invoicing/clients/plans/:id/details',
                                            name:'Plan Detail',
                                            component:ClientsPlanDetails,
                                            meta:{
                                                isChildren:true,
                                                type:'plansDetails'
                                            },
                                        },
                                        {
                                            path:'/digital-invoicing/clients/plans/:id/add-new',
                                            name:'Add New Health Plan',
                                            component:ClinetsPlanAddNew,
                                            meta:{
                                                isChildren:true,
                                                type:'addNewPlan'
                                            },
                                        },
                                        
                                        {
                                            path:'/digital-invoicing/clients/billing/:id',
                                            name:'Client Billing',
                                            component:ClientsBilling,
                                            meta:{
                                              
                                                type:'billing'
                                            },
                                            
                                        },
                                        {
                                            path:'/digital-invoicing/clients/billing/:id/details',
                                            name:'Billing Detail',
                                            component:ClientsBillingDetails,
                                            meta:{
                                                isChildren:true,
                                                type:'billingDetails'
                                            },
                                        },
                                        {
                                            path:'/digital-invoicing/clients/billing/:id/add-new',
                                            name:'Add Billing',
                                            component:ClientsAddBilling,
                                            meta:{
                                                isChildren:true,
                                                type:'addNewBilling'
                                            },
                                        },
                                        
                                        {
                                            path:'/digital-invoicing/clients/solutions/:id',
                                            name:'Client Solutions',
                                            component:ClientsSolutions,
                                            meta:{
                                              
                                                type:'solutions'
                                            },
                                            
                                        },
                                        {
                                            path:'/digital-invoicing/clients/solutions/:id/details',
                                            name:'Solution Detail',
                                            component:ClientsSolutionDetails,
                                            meta:{
                                                isChildren:true,
                                                type:'solutionDetails'
                                            },
                                        },
                                        {
                                            path:'/digital-invoicing/clients/settings/:id',
                                            name:'Client Settings',
                                            component:ClientsSettings,
                                            meta:{
                                              
                                                type:'settings'
                                            },
                                            
                                        },

                                    ]
                                },
                            ]
                        },
                        {
                            path:'invoices',
                            name:'',
                            component:RouterView,
                            redirect: () => {
                                return { path: '/digital-invoicing/invoices'} 
                            },
                            
                            children:[                 
                                {  
                                    path:'/digital-invoicing/invoices',
                                    name:'Invoices',
                                    component:Invoices,
                                    children:[
                                        {
                                            path:'/digital-invoicing/invoices/details',
                                            name:'Invoice Detail',
                                            component: InvoiceDetails,
                                            meta:{
                                                isChildren:true
                                            },
                                        },
                                    ],
                                },
                                
                              
                            {
                                path:'/digital-invoicing/invoices/add-new',
                                name:'Add Invoice',
                                component: AddInvoice,
                                meta:{
                                    isChildren:true
                                },
                            }
                            ]
                        },     
                        {
                            path:'billing',
                            name:'',
                            component:RouterView,
                            redirect: () => {
                                return { path: '/digital-invoicing/billing'} 
                            },
                            children:[                 
                                {  
                                    path:'/digital-invoicing/billing',
                                    name:'Billing',
                                    component:Billing,
                                    children:[
                                        {
                                            path:'/digital-invoicing/billing/details',
                                            name:'Invoice Detail',
                                            component:BillingDetails,
                                            meta:{
                                                isChildren:true
                                            },
                                        }
                                    ]
                                },
                            ]
                        },
                        {
                            path:'account',
                            name:'',
                            component:RouterView,
                            redirect: () => {
                                return { path: '/digital-invoicing/account'} 
                            },
                            children:[                 
                                {  
                                    path:'/digital-invoicing/account',
                                    name:'Account',
                                    component:Account,
                               
                                },
                            ]
                        },
                        {
                            path:'users',
                            name:'',
                            component:RouterView,
                            redirect: () => {
                                return { path: '/digital-invoicing/users'} 
                            },
                            children:[                 
                                {  
                                    path:'/digital-invoicing/users',
                                    name:'Users',
                                    component:Users,
                                    children:[
                                        {
                                            path:'/digital-invoicing/users/details',
                                            name:'User Details',
                                            component:UserDetails,
                                            meta:{
                                                isChildren:true
                                            },
                                        },
                                        {
                                            path:'/digital-invoicing/users/add-new',
                                            name:'Add New',
                                            component:AddNewUser,
                                            meta:{
                                                isChildren:true
                                            },
                                            
                                        }
                                    ]
                                },
                            ]
                        },
                        {
                            path:'settings',
                            name:'',
                            component:RouterView,
                            redirect: () => {
                                return { path: '/digital-invoicing/settings'} 
                            },
                            children:[                 
                                {  
                                    path:'/digital-invoicing/settings',
                                    name:'Settings',
                                    component:Settings,
                               
                                },
                            ]
                        },
                    ]
                },
                
               ]
           }
       ]
   },

]

export default router;