<template>
  <div class="top-class">
    <FullscreenLoader v-if="loader" />
    <!-- Desktop -->
    <v-layout row wrap class="whole-page hide-on-mobile">
      <v-flex xs12 sm12 md5 lg5 class="register-page-left">
        <div class="register-content-left">
          <div>
            <img class="apaly-logo" src="/svg/apalyBlue.svg" />
          </div>

          <div class="register-left-welcome">
            <span style="margin-bottom: -2vh; display: block"> Welcome</span>
            <span
              >to
              <span style="color: var(--secondary-color)">Apaly Health</span>
            </span>
          </div>

          <hr class="register-line-break" />

          <div class="register-left-info">
            <span class="info-line-spacing">
              <b>Apaly Health</b> is a B2B healthcare marketplace that
              <b>streamlines the business of healthcare</b> by connecting
              providers and point solution vendors to third-party
              administrators, brokers, self-funded employers and their members
            </span>
          </div>
        </div>
      </v-flex>
      <div class="divider"></div>
      <v-flex xs12 sm12 md6 lg6 class="register-page-right">
        <div class="register-content-right">
          <v-stepper alt-labels v-model="registerStep">
            <v-stepper-items>
              <v-stepper-content step="1" class="mobile-content">
                <v-card>
                  <div class="register-form-container">
                    <div class="register-form-text">
                      <span class="register-form-title">
                        What entity type best describes you?</span
                      >
                      <span class="register-form-subtitle"
                        >We’ll streamline your experience</span
                      >
                    </div>
                    <div class="register-form-types">
                      <div
                        v-for="(type, index) in types"
                        :key="index"
                        @click="selectType(type)"
                        @mouseenter="hoveredType = type"
                        @mouseleave="hoveredType = null"
                        class="register-form-type"
                      >
                        <div class="register-form-type-box">
                          <img class="type-logo" :src="returnTypeIcon(type)" />
                          <span class="type-label">
                            {{
                              type.label === "TPA"
                                ? "Third Party Administrator"
                                : type.label
                            }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="register-form-button text-center">
                      <v-btn
                        @click="updateStep(2)"
                        :disabled="selectedType == null"
                        color="primary"
                        >Start</v-btn
                      >
                    </div> -->
                  </div>

                  <div class="register-footer-left">
                    <h3 class="tiny-font text-gray mobile-font" style="left: 0">
                      Already have an account?
                      <a @click="$router.push('/login')"><b>Log In</b></a>
                    </h3>
                  </div>
                  <div class="register-footer-right">
                    <h3 class="tiny-font text-gray mobile-font" style="left: 0">
                      Are you a Plan Member?
                      <a @click="goToMarketplace()"><b>Access Here!</b></a>
                    </h3>
                  </div>
                </v-card>
              </v-stepper-content>

              <v-stepper-content step="2" class="mobile-content ">
                <v-card >
                  <div class="register-form-container" v-if="selectedType">
                    <div class="register-form-text-2">
                      <span class="register-form-title-2 ">
                        Great! What is your
                        {{ selectedType ? selectedType.type : "" }} type?
                      </span>
                    </div>
                    <div class="register-form-types">
                      <div @click="updateStep(1)" class="register-form-type">
                        <div
                          class="register-form-type-back-box"
                          @mouseenter="backHovered = true"
                          @mouseleave="backHovered = false"
                        >
                          <img class="type-logo" :src="returnBackIcon()" />
                          <span class="type-label"> Go Back </span>
                          <span class="sub-label">
                            to change the previous choice
                          </span>
                        </div>
                      </div>
                      <div
                        v-for="(subType, index) in selectedType.subTypes"
                        :key="index"
                        @click="selectSubType(subType)"
                        @mouseenter="hoveredSubtype = subType.value"
                        @mouseleave="hoveredSubtype = null"
                        class="register-form-type"
                      >
                        <div class="register-form-type-box">
                          <img
                            class="type-logo"
                            :src="returnSubTypeIcon(subType.value)"
                          />
                          <span class="type-label">
                            {{ subType.label }}
                          </span>
                          <span class="sub-label">
                            {{ returnSublabel(subType.value) }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <!-- <div class="register-form-types">
                      <div
                        v-for="(subtype, index) in selectedType.subTypes"
                        :key="index"
                        @click="selectSubType(subtype)"
                        class="register-form-type text-center"
                      >
                        <div
                          class="register-form-subtype-box"
                          :class="{
                            selectedActiveSubType:
                              subtype.value == selectedSubtype?.value,
                          }"
                        >
                          {{ subtype.label }}
                        </div>
                      </div>
                    </div>
                    <div
                      class="register-form-button d-flex"
                      style="
                        justify-content: space-between;
                        align-items: center;
                        padding: 0 10px;
                      "
                    >
                      <span
                        role="button"
                        style="color: #0069f3"
                        @click="registerStep = 1"
                        >Back</span
                      >
                      <v-btn
                        :disabled="selectedSubtype == null"
                        @click="registerStep = 3"
                        color="primary"
                        >Continue</v-btn
                      >
                    </div> -->
                  </div>

                  <div class="register-footer-left">
                    <h3 class="tiny-font text-gray mobile-font" style="left: 0">
                      Already have an account?
                      <a @click="$router.push('/login')"><b>Log In</b></a>
                    </h3>
                  </div>
                  <div class="register-footer-right">
                    <h3 class="tiny-font text-gray mobile-font" style="left: 0">
                      Are you a Plan Member?
                      <a @click="goToMarketplace()"><b>Access Here!</b></a>
                    </h3>
                  </div>
                </v-card>
              </v-stepper-content>

              <v-stepper-content step="3">
                <v-container fluid>
                  <div class="register-form-container-2">
                    <div class="register-form">
                      <v-form
                        class="col-12 info-form"
                        ref="form"
                        v-model.trim="valid"
                      >
                      <!-- {{ valid }} -->
                        <v-row>
                          <v-col>
                            <span class="register-header-text">
                              Registration Form
                            </span>
                          </v-col>
                        </v-row>

                        <v-row class="register-row">
                          <v-col
                            md="6"
                            sm="12"
                            lg="6"
                            align="start"
                            class="pb-0"
                          >
                            <label for="firstNameField" class="register-label"
                              >First Name</label
                            >
                            <v-text-field
                              id="firstNameField"
                              dense
                              placeholder="Enter your first name here"
                              v-model.trim="user.firstName"
                              type="text"
                              :rules="requiredRules"
                              class="apaly_text register-text-field"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            md="6"
                            sm="12"
                            lg="6"
                            align="start"
                            class="pb-0"
                          >
                            <label for="lastNameField" class="register-label"
                              >Last Name</label
                            >
                            <v-text-field
                              id="lastNameField"
                              dense
                              placeholder="Enter your last name here"
                              v-model.trim="user.lastName"
                              type="text"
                              :rules="requiredRules"
                              class="apaly_text register-text-field"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row class="register-row">
                          <v-col
                            md="12"
                            sm="12"
                            align="start"
                            class="pb-0 position-relative"
                            style="position: relative"
                          >
                            <label for="userEmail" class="register-label"
                              >E-mail</label
                            >
                            <v-text-field
                              dense
                              id="registerEmail"
                              placeholder="Enter your company email here"
                              type="email"
                              ref="emailField"
                              v-model.trim="user.email"
                              :rules="emailRules"
                              @blur="emailValidation"
                              @input="emailErrorFromAPI = ''"
                              class="apaly_text register-text-field"
                            ></v-text-field>
                            <div
                              class="error-from-api"
                            >
                              <span
                                class="error-from-api-text"
                                v-if="emailErrorFromAPI && emailErrorFromAPI === 'Email already in use'"
                                >Email already in use. <a @click="$router.push(`/login?email=${user.email}`)">Log In instead?</a>
                              </span>
                              <span
                                class="error-from-api-text"
                                v-else-if="emailErrorFromAPI "
                                >{{ emailErrorFromAPI }}
                              </span>
                            </div>
                          </v-col>
                        </v-row>

                        <v-row class="register-row">
                          <v-col
                            md="12"
                            sm="12"
                            align="start"
                            class="pb-0 position-relative"
                          >
                            <label for="" class="register-label"
                              >Organization Name</label
                            >
                            <v-text-field
                              dense
                              placeholder="Enter organization name"
                              v-model.trim="user.companyName"
                              type="text"
                              :rules="requiredRules"
                              class="apaly_text register-text-field"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row class="register-row">
                          <v-col
                            md="12"
                            sm="12"
                            align="start"
                            class="pb-0 position-relative"
                          >
                            <label for="" class="register-label">Tax ID</label>
                            <v-text-field
                              dense
                              id="organizationName"
                              placeholder="Insert Tax ID"
                              v-model.trim="user.taxId"
                              type="text"
                              :rules="taxIdRules"
                              class="apaly_text register-text-field"
                              @input="formatTaxId"
                              counter="10"
                              maxLength="10"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row class="register-row">
                          <v-col
                            md="6"
                            sm="12"
                            align="start"
                            class="pb-0 position-relative"
                          >
                            <label for="" class="register-label"
                              >Password</label
                            >
                            <v-text-field
                              dense
                              id="password"
                              placeholder="Enter password"
                              name="accountPassword"
                              v-model.trim="user.password"
                              required
                              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="show ? 'text' : 'password'"
                              @click:append="show = !show"
                              :rules="passRules"
                              class="apaly_text register-text-field"
                            ></v-text-field>
                          </v-col>

                          <v-col md="6" sm="12" align="start" class="pb-0">
                            <label for="" class="register-label"
                              >Confirm Password</label
                            >
                            <v-text-field
                              dense
                              id="confirmPassword"
                              placeholder="Re-enter password"
                              name="confirmPassword"
                              v-model.trim="user.confirmPassword"
                              required
                              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="show2 ? 'text' : 'password'"
                              @click:append="show2 = !show2"
                              :rules="confirmPassRules"
                              class="apaly_text register-text-field"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row class="password-requirements">
                          <v-col md="12" sm="12" align="start">
                            <span class="password-requirements-text"
                              >Password Requirements</span
                            >
                            <ul>
                              <li
                                v-for="( requirement, index) in passwordRequirements()"
                                :key="index"
                                :class="requirement.satisfied ? 'req-satisfied' : 'req-unsatisfied'"
                              >
                                {{ requirement.text }}
                              </li>
                            </ul>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col md="12" sm="12" align="start" class="pb-0">
                            <div class="">
                                <v-checkbox
                                    dense
                                    v-model="checkbox"
                                    :rules="requiredRules"
                                    >
                                    <template #label>
                                        <div class="checkbox_label">
                                        I accept the terms of&nbsp;
                                        <a href="https://apaly.app/apaly_paa" target="_blank" @click.stop>
                                            Apaly Health Platform Access Agreement
                                        </a>
                                        </div>
                                    </template>
                                </v-checkbox>
                            </div>
                          </v-col>
                        </v-row>

                        <v-row class="row-buttons justify-end">
                            <div class="mr-1">
                                <v-btn
                                    outlined
                                    class="secondary-button"
                                    style="width: 100%"
                                    @click="back()"
                                >
                                Back
                                </v-btn>
                            </div>

                          <div class="mr-1">
                            <v-btn
                              v-show="showClearFields()"
                              outlined
                              class="secondary-button"
                              style="width: 100%"
                              @click="clearFields()"
                            >
                              Clear Fields
                            </v-btn>
                          </div>

                          <div>
                            <v-btn
                              text
                              class="primary-button"
                              style="width: 100%"
                              :disabled="!valid || registerApiQueue"
                              @click="submit()"
                            >
                              Register
                            </v-btn>
                          </div>
                        </v-row>
                      </v-form>
                    </div>
                  </div>
                </v-container>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </div>
      </v-flex>
    </v-layout>

    <!-- Mobile -->
    <div class="register-content-right hide-on-desktop">
      <v-stepper alt-labels v-model="registerStep">
        <v-stepper-items>
          <v-stepper-content step="1" class="mobile-content">
            <v-card>
              <div class="register-form-container">
                <div class="register-form-text">
                  <span class="register-form-title">
                    What entity type best describes you?</span
                  >
                  <span class="register-form-subtitle"
                    >We’ll streamline your experience</span
                  >
                </div>
                <div class="register-form-types">
                  <div
                    v-for="(type, index) in types"
                    :key="index"
                    @click="selectType(type)"
                    @mouseenter="hoveredType = type"
                    @mouseleave="hoveredType = null"
                    class="register-form-type"
                  >
                    <div class="register-form-type-box">
                      <img class="type-logo" :src="returnTypeIcon(type)" />
                      <span class="type-label">
                        {{
                          type.label === "TPA"
                            ? "Third Party Administrator"
                            : type.label
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="register-footer-left">
                <h3 class="tiny-font text-gray mobile-font" style="left: 0">
                  Already have an account?
                  <a @click="$router.push('/login')"><b>Log In</b></a>
                </h3>
              </div>
              <div class="register-footer-right">
                <h3 class="tiny-font text-gray mobile-font" style="left: 0">
                  Are you a Plan Member?
                  <a @click="goToMarketplace()"><b>Access Here!</b></a>
                </h3>
              </div>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="2" class="mobile-content ">
            <v-card >
              <div class="register-form-container" v-if="selectedType">
                <div class="register-form-text">
                  <span class="register-form-title">
                    Great! What is your
                    {{ selectedType ? selectedType.type : "" }} type?
                  </span>
                </div>
                <div class="register-form-types">
                  <div @click="updateStep(1)" class="register-form-type">
                    <div
                      class="register-form-type-back-box"
                      @mouseenter="backHovered = true"
                      @mouseleave="backHovered = false"
                    >
                      <img class="type-logo" :src="returnBackIcon()" />
                      <div class="choice_flex">
                        <span class="type-label"> Go Back </span>
                        <span class="sub-label">
                          to change the previous choice
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    v-for="(subType, index) in selectedType.subTypes"
                    :key="index"
                    @click="selectSubType(subType)"
                    @mouseenter="hoveredSubtype = subType.value"
                    @mouseleave="hoveredSubtype = null"
                    class="register-form-type"
                  >
                    <div class="register-form-type-box">
                      <img
                        class="type-logo"
                        :src="returnSubTypeIcon(subType.value)"
                      />
                      <div class="choice_flex">

                        <span class="type-label">
                          {{ subType.label }}
                        </span>
                        <span class="sub-label">
                          {{ returnSublabel(subType.value) }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="register-footer-left">
                <h3 class="tiny-font text-gray mobile-font" style="left: 0">
                  Already have an account?
                  <a @click="$router.push('/login')"><b>Log In</b></a>
                </h3>
              </div>
              <div class="register-footer-right">
                <h3 class="tiny-font text-gray mobile-font" style="left: 0">
                  Are you a Plan Member?
                  <a @click="goToMarketplace()"><b>Access Here!</b></a>
                </h3>
              </div>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-container fluid>
              <div class="register-form-container-2">
                <div class="register-form">
                  <v-form
                    class="col-12 info-form"
                    ref="form"
                    v-model.trim="valid"
                  >
                    <v-row>
                      <v-col>
                        <span class="register-header-text">
                          Registration Form
                        </span>
                      </v-col>
                    </v-row>

                    <v-row class="register-row">
                      <v-col
                        md="6"
                        sm="12"
                        lg="6"
                        align="start"
                        class="pb-0"
                      >
                        <label for="firstNameField" class="register-label"
                          >First Name</label
                        >
                        <v-text-field
                          id="firstNameField"
                          dense
                          placeholder="Enter your first name here"
                          v-model.trim="user.firstName"
                          type="text"
                          :rules="requiredRules"
                          class="apaly_text register-text-field"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row class="register-row">
                      <v-col
                        md="6"
                        sm="12"
                        lg="6"
                        align="start"
                        class="pb-0"
                      >
                        <label for="lastNameField" class="register-label"
                          >Last Name</label
                        >
                        <v-text-field
                          id="lastNameField"
                          dense
                          placeholder="Enter your last name here"
                          v-model.trim="user.lastName"
                          type="text"
                          :rules="requiredRules"
                          class="apaly_text register-text-field"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row class="register-row">
                      <v-col
                        md="12"
                        sm="12"
                        align="start"
                        class="pb-0 position-relative"
                        style="position: relative"
                      >
                        <label for="userEmail" class="register-label"
                          >E-mail</label
                        >
                        <v-text-field
                          dense
                          id="registerEmail"
                          placeholder="Enter your company email here"
                          type="email"
                          ref="emailField"
                          v-model.trim="user.email"
                          :rules="emailRules"
                          @blur="emailValidation"
                          @input="emailErrorFromAPI = ''"
                          class="apaly_text register-text-field"
                        ></v-text-field>
                        <div
                          class="error-from-api"
                        >
                          <span
                            class="error-from-api-text"
                            v-if="emailErrorFromAPI && emailErrorFromAPI === 'Email already in use'"
                            >Email already in use. <a @click="$router.push(`/login?email=${user.email}`)">Log In instead?</a>
                          </span>
                          <span
                            class="error-from-api-text"
                            v-else-if="emailErrorFromAPI "
                            >{{ emailErrorFromAPI }}
                          </span>
                        </div>
                      </v-col>
                    </v-row>

                    <v-row class="register-row">
                      <v-col
                        md="12"
                        sm="12"
                        align="start"
                        class="pb-0 position-relative"
                      >
                        <label for="" class="register-label"
                          >Organization Name</label
                        >
                        <v-text-field
                          dense
                          placeholder="Enter organization name"
                          v-model.trim="user.companyName"
                          type="text"
                          :rules="requiredRules"
                          class="apaly_text register-text-field"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row class="register-row">
                      <v-col
                        md="12"
                        sm="12"
                        align="start"
                        class="pb-0 position-relative"
                      >
                        <label for="" class="register-label">Tax ID</label>
                        <v-text-field
                          dense
                          id="organizationName"
                          placeholder="Insert Tax ID"
                          v-model.trim="user.taxId"
                          type="text"
                          :rules="taxIdRules"
                          class="apaly_text register-text-field"
                          @input="formatTaxId"
                          counter="10"
                          maxLength="10"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row class="register-row">
                      <v-col
                        md="6"
                        sm="12"
                        align="start"
                        class="pb-0 position-relative"
                      >
                        <label for="" class="register-label"
                          >Password</label
                        >
                        <v-text-field
                          dense
                          id="password"
                          placeholder="Enter password"
                          name="accountPassword"
                          v-model.trim="user.password"
                          required
                          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="show ? 'text' : 'password'"
                          @click:append="show = !show"
                          :rules="passRules"
                          class="apaly_text register-text-field"
                        ></v-text-field>
                      </v-col>

                    </v-row>
                    <v-row class="register-row">
                      <v-col md="6" sm="12" align="start" class="pb-0">
                        <label for="" class="register-label"
                          >Confirm Password</label
                        >
                        <v-text-field
                          dense
                          id="confirmPassword"
                          placeholder="Re-enter password"
                          name="confirmPassword"
                          v-model.trim="user.confirmPassword"
                          required
                          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="show2 ? 'text' : 'password'"
                          @click:append="show2 = !show2"
                          :rules="confirmPassRules"
                          class="apaly_text register-text-field"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row class="password-requirements">
                      <v-col md="12" sm="12" align="start">
                        <span class="password-requirements-text"
                          >Password Requirements</span
                        >
                        <ul>
                          <li
                            v-for="( requirement, index) in passwordRequirements()"
                            :key="index"
                            :class="requirement.satisfied ? 'req-satisfied' : 'req-unsatisfied'"
                          >
                            {{ requirement.text }}
                          </li>
                        </ul>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col md="12" sm="12" align="start" class="pb-0">
                        <div class="">
                          <v-checkbox
                            dense
                            v-model="checkbox"
                            :rules="requiredRules"
                          >
                            <div slot="label" class="accept-aggrement-text">
                              I accept the terms of
                              <a
                                class="text-underline"
                                @click="openPdfModal()"
                                >Apaly Health Platforms Access Agreement
                              </a>
                            </div>
                          </v-checkbox>
                        </div>
                      </v-col>
                    </v-row>

                    <v-row class="row-buttons justify-end">
                        <div class="mr-1">
                            <v-btn
                                outlined
                                class="secondary-button"
                                style="width: 100%"
                                @click="back()"
                            >
                            Back
                            </v-btn>
                        </div>

                      <div class="mr-1">
                        <v-btn
                          v-show="showClearFields()"
                          outlined
                          class="secondary-button"
                          style="width: 100%"
                          @click="clearFields()"
                        >
                          Clear Fields
                        </v-btn>
                      </div>

                      <div>
                        <v-btn
                          text
                          class="primary-button"
                          style="width: 100%"
                          :disabled="!valid || registerApiQueue"
                          @click="submit()"
                        >
                          Register
                        </v-btn>
                      </div>
                    </v-row>
                  </v-form>
                </div>
              </div>
            </v-container>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>

    <v-dialog v-model.trim="checkEmailDialog" width="460" persistent>
      <v-card>
        <v-card-text class="pt-5">
          <span role="button" align="end" class="pt-0 pr-0">
            <p class="font-weight-bold" @click="backToLogin()">X</p>
          </span>
          <div class="display">
            <div class="text-center">
              <img class="email-sent-logo" src="/svg/newEmailSent.svg" />
            </div>
            <div class="email-sent-message">
              <span class="email-sent-title"> Verify your E-mail Address </span>
              <span class="email-sent-info">
                To start using Apaly we need to verify your e-mail address. We
                have sent an e-mail confirmation link to
                <span class="user-email">{{ this.user.email }}</span>
              </span>
            </div>
          </div>

          <div class="mt-8 d-flex align-right row-buttons">
            <v-btn text class="primary-b3-button" @click="backToLogin()">
              Go to Login Page
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model.trim="organisationRegisteredModal" width="460" persistent>
      <v-card>
        <v-card-text class="pt-5">
          <span role="button" align="end" class="pt-0 pr-0">
            <a
              class="font-weight-bold"
              @click="backToLogin()"
            >
              X
          </a>
          </span>
          <div class="display">
            <div class="text-center">
              <img class="email-sent-logo" src="/svg/newEmailSent.svg" />
            </div>
            <div class="email-sent-message">
              <span class="email-sent-title"> Verify your E-mail Address </span>
              <span class="email-sent-info">
                To start using Apaly we need to verify your e-mail address.
                Please check it on
                <span class="user-email">{{ this.user.email }}</span>
              </span>
            </div>
          </div>

          <div class="mt-6 d-flex align-right row-buttons">
            <v-btn text class="primary-b3-button no-transform" @click="backToLogin()">
              Go to login page
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model.trim="requestJoinSentModal" width="510">
      <v-card>
        <v-card-text class="pt-5">
          <span role="button" align="end" class="pt-0 pr-0">
            <p
              class="font-weight-bold"
              style="margin-bottom: 0"
              @click="requestJoinSentModal = false"
            >
              X
            </p>
          </span>
          <div class="display">
            <div class="text-center">
              <img src="/svg/email_sent.svg" />
            </div>
            <div style="font-size: 24px; color: #333333; margin-top: 20px">
              Request Sent!
            </div>
            <div style="font-size: 16px; color: #808080; margin-top: 20px">
              We sent your request to the account administrator for approval.
            </div>
            <div style="font-size: 16px; color: #808080; margin-top: 20px">
              We’ll email you with the response.
            </div>
          </div>

          <div
            class="text-center text--white mt-6 d-flex"
            style="justify-content: center"
          >
            <v-btn color="primary" class="email-sent" @click="backToLogin()">
              Okay
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model.trim="notJoiningReasonModal" width="510">
      <v-card>
        <v-card-text class="pt-5">
          <span role="button" align="end" class="pt-0 pr-0">
            <p
              class="font-weight-bold"
              style="margin-bottom: 0"
              @click="notJoiningReasonModal = false"
            >
              X
            </p>
          </span>
          <div class="display">
            <div style="font-size: 24px; color: #333333; margin-top: 20px">
              Okay. No worries
            </div>
            <div style="font-size: 16px; color: #808080; margin-top: 20px">
              Unfortunately we can not proceed with your account. If there is
              any problem, contact us at support@apaly.net.
            </div>
            <div style="font-size: 16px; color: #808080; margin-top: 20px">
              Can you tell us what is scaring you away?
              <v-checkbox
                value="That's not my organization"
                v-model="checked"
                label="That's not my organization"
                hide-details
              ></v-checkbox>
              <v-checkbox
                label="Other Reason"
                v-model="checked"
                value="other"
              ></v-checkbox>
            </div>
            <div>
              <label>Share any comments you want to:</label>
              <v-textarea v-model="description"></v-textarea>
            </div>
          </div>

          <div
            class="text-center text--white mt-6 d-flex"
            style="justify-content: center"
          >
            <v-btn color="primary" class="email-sent" @click="submitReason()">
              Submit
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model.trim="pdfDialog">
      <v-card>
        <span role="button" align="end" class="pt-0 pr-0">
          <h1 class="font-weight-bold mr-4" @click="pdfDialog = false">X</h1>
        </span>
        <v-card-text class="success-modal pt-5">
          <object
            :data="pdfUrl"
            type="application/pdf"
            width="100%"
            height="1000"
          ></object>
        </v-card-text>
      </v-card>
    </v-dialog>

    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"> </errorModal>
  </div>
</template>
<script>
import api from "../../services/api";
import Vue from "vue";
import { mapActions } from "vuex";
import errorModal from "../TablesTemplate/ErrorDialog.vue";
import FullscreenLoader from "../Common/FullscreenLoader.vue";

export default {
  components: { errorModal, FullscreenLoader },
  data() {
    return {
      isMobile: false,
      types: [],
      selectedType: null,
      selectedSubtype: null,
      pdfUrl: "",
      pdfDialog: false,
      passwordMatch: false,
      registerStep: 1,
      user: {},
      checkbox: false,
      valid: false,
      show: false,
      show2: false,
      checkEmailDialog: false,
      organisationRegisteredModal: false,
      requestJoinSentModal: false,
      notJoiningReasonModal: false,
      requiredRules: [(v) => !!v || "This field is required"],
      checked: "",
      description: "",
      emailErrorFromAPI: null,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      passRules: [
        (v) => !!v || "Password is required",
        (v) => !!v || "Please type password.",
        (v) => (v && v.length > 8) || "Minimum 8 characters",
        (v) =>
          (v && v.length && /[a-z]{1}/.test(v)) ||
          "Password should contain at least one lowercase letter",
        (v) => (v && /[A-Z]{1}/.test(v)) || "At least one capital latter",
        (v) =>
          (v && /[^A-Za-z0-9]/.test(v)) || "At least one special character",
      ],
      confirmPassRules: [
        (v) => !!v || "Password is required",
        (v) => !!v || "Please type password.",
        (v) => (v && v.length > 8) || "Minimum 8 characters",
        (v) => (v && /[A-Z]{1}/.test(v)) || "At least one capital latter",
        (v) =>
          (v && /[^A-Za-z0-9]/.test(v)) || "At least one special character",
        (v) => v == this.user.password || "Password must match",
      ],
      taxIdRules: [
        (v) => !!v || "This field is required",
        (v) =>
          !!/[0-9]{2}\-[0-9]{7}/.test(v) || "Tax ID should contain 9 digits",
        (v) => (v && v.length <= 10) || "Tax ID should only contain 9 digits",
      ],
      passsError: "",
      error: "",
      disableSubmit: true,
      registerApiQueue: false, // set true prior to api call (will stop another call if previous one is not finished),
      hoveredType: null,
      hoveredSubtype: null,
      backHovered: false,
      loader: false,
    };
  },
  created() {
    this.getTypes();
    // this.organisationRegisteredModal = true
  },
  mounted() {
    this.checkScreenWidth();
    window.addEventListener('resize', this.checkScreenWidth);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkScreenWidth);
  },
  methods: {
    checkScreenWidth() { // Check if screen is mobile
      this.isMobile = window.innerWidth <= 964;
    },
    formatTaxId() {
        let taxId = this.user.taxId.replace(/[^0-9A-Za-z]/g, '');
        if (taxId.length > 2) {
            taxId = taxId.slice(0, 2) + '-' + taxId.slice(2, 9);
        }
        this.user.taxId = taxId;
    },
    updateStep(step) {
      this.registerStep = step;
      if (this.selectedType.type !== "provider") {
        this.registerStep = 3;
      }
    },
    back() {
        if (this.selectedSubtype) {
            this.registerStep = 2;
            this.selectedSubtype = null;
        } else {
            this.registerStep = 1;
        }
    },
    requestJoin() {
      this.organisationRegisteredModal = false;
      this.requestJoinSentModal = true;
    },
    dontJoin() {
      this.organisationRegisteredModal = false;
      this.notJoiningReasonModal = true;
    },
    submitReason() {},
    getTypes() {
      api()
        .get("/auth/account/types")
        .catch((err) => {
          if (err) {
            alert(err);
          }
        })
        .then((res) => {
          console.log("res", res);
          if (res) {
            this.types = res.data;
          }
        });
    },

    returnTypeIcon(type) {
      const typeMap = {
        provider: {
          false: "/svg/newDoctorGray.svg",
          true: "/svg/newDoctor.svg",
        },
        tpa: {
          false: "/svg/newTPAGray.svg",
          true: "/svg/newTPA.svg",
        },
        vendor: {
          false: "/svg/newVendorGray.svg",
          true: "/svg/newVendor.svg",
        },
      };

      if (typeMap.hasOwnProperty(type.type)) {
        const isHovered =
          (this.hoveredType && this.hoveredType.type === type.type) || false;

        return typeMap[type.type][isHovered];
      }
    },

    returnSubTypeIcon(subType) {
      const subTypeMap = {
        DPC: {
          false: "/svg/newDoctorGray.svg",
          true: "/svg/newDoctor.svg",
        },
        other: {
          false: "/svg/newHospitalGray.svg",
          true: "/svg/newHospital.svg",
        },
        default: {
          false: "/svg/newHospitalGray.svg",
          true: "/svg/newHospital.svg",
        },
      };

      const isHovered =
        (this.hoveredSubtype && this.hoveredSubtype === subType) || false;

      if (!subTypeMap[subType]) {
        return subTypeMap.default[isHovered];
      }

      return subTypeMap[subType][isHovered];
    },

    returnBackIcon() {
      const backIcons = {
        false: "/svg/back.svg",
        true: "/svg/backHovered.svg",
      };

      return backIcons[this.backHovered];
    },

    returnSublabel(value) {
      const subLabels = {
        DPC: "Subscription Model",
        other: "Fee for Service",
      };

      return subLabels[value] || null;
    },

    selectType(type) {
      this.selectedType = type;

      this.updateStep(2);
    },

    selectSubType(subtype) {
      this.selectedSubtype = subtype;

      this.updateStep(3);
    },

    passwordRequirements() {
      const password = this.user.password || "";

      const passwordRequirements = {
        minLength: {
          text: "At least 8 characters",
          satisfied: password.length >= 8,
        },
        capitalLetter: {
          text: "At least 1 capital letter",
          satisfied: /[A-Z]/.test(password),
        },
        lowercaseLetter: {
          text: "At least 1 lowercase letter",
          satisfied: /[a-z]/.test(password),
        },
        specialCharacter: {
          text: "At least 1 special character",
          satisfied: /[!@#$%^&*(),.?":{}|<>]/.test(password),
        },
      };

      return passwordRequirements;
    },

    clearFields() {
      this.user = {};
      this.emailErrorFromAPI = null
    },

    showClearFields() {
      return (
        this.user.firstName ||
        this.user.lastName ||
        this.user.email ||
        this.user.companyName ||
        this.user.taxId ||
        this.user.password ||
        this.user.confirmPassword
      );
    },

    openPdfModal() {
      if(this.isMobile) {
        const url = 'https://apalycdn.s3.us-west-1.amazonaws.com/public/uploads/admin/contracts/ApalyTermsandConditionsMember01042024.pdf';
        window.open(url, '_blank');
      }
      else {
        // const url = "https://apalycdn.s3.us-west-1.amazonaws.com/public/uploads/admin/contracts/PlatformAccessAgreement.pdf";
        const url = "https://apalycdn.s3.us-west-1.amazonaws.com/public/uploads/admin/contracts/ApalyTermsandConditionsMember01042024.pdf";
        this.pdfUrl = url;
        this.pdfDialog = true;
      }
    },
    backToLogin() {
      this.$router.push(`/login?email=${this.user.email}`);
    },

    ...mapActions(["loginUser"]),
    eventHandling(event) {
      if (event.keyCode == 13) {
        this.submit();
      }
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    emailValidation() {
      // this.user.email = this.user.email.toLowerCase();
      if (!this.user.email || this.user.email === "") {
        this.emailErrorFromAPI = null;
        return;
      }
      let email = {
        email: this.user.email.toLowerCase(),
      };
      api()
        .post("/auth/validate/email", email)
        .catch((err) => {
          if (err) {
            this.checkEmailDialog = false;
          }
        })
        .then((res) => {
          if (res) {
            if (!res.data.valid) {
              this.disableSubmit = true;
              this.emailErrorFromAPI = res.data.message;
            } else {
              this.disableSubmit = false;
              this.emailErrorFromAPI = null;
            }

            // this.$refs.emailField.validate();
          }
        });
    },
    submit() {
      this.loader = true;
      this.user.email = this.user.email.toLowerCase();
      this.$refs.form.validate();
      if (this.valid) {
        this.user.type = this.selectedType.type;
        this.user.subType = this.selectedSubtype?.value;
        if (this.registerApiQueue) return;
        this.registerApiQueue = true;
        api()
          .post("/auth/signup/new", this.user)
          .catch((err) => {
            if (err) {
              this.$refs.error_modal.openPopUp(err.response.data.message);
            }
          })
          .then((res) => {
            if (res) {
              this.checkEmailDialog = true;
            }
          })
          .finally(() => {
            this.registerApiQueue = false;
            this.loader = false;
          });
      }
    },
    verifyEmail() {
      this.$router.push("/login");
    },
    goToMarketplace() {
      window.location = "https://member.apaly.net/marketplace";
    },
  },
};
</script>

<style scoped lang="scss">
@import url("../../assets/colors.css");

// Left screen styles
.whole-page {
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  font-family: "Open Sans";
}

.apaly-logo {
  width: 17.8vh;
}

.register-page-left {
  background-color: #ffffff;
  width: 100%;
  height: 100vh;
  padding: 1.5vw;
}

.register-content-left {
  margin: 8vh;
}

.register-left-welcome {
  margin-top: 3.8vh;
  font-size: 6.2vh;
  color: var(--primary-color);
  font-weight: bold;
}

.register-line-break {
  margin-top: 4vh;
  margin-bottom: 5.3vh;
  width: 5vw;
  border: 0;
  height: 0.6vh;
  background-color: var(--secondary-color);
  border-radius: 5px;
}

.register-left-info {
  font-size: 3vh;
  padding-right: 2vw;
  color: var(--primary-color);
}

.info-line-spacing {
  line-height: 5.8vh;
}

.register-form-logo-2 {
  width: 200px;
  position: absolute;
  left: 0;
}

.register-form-text {
  width: 40vw;
  font-size: 6vh;
  color: var(--primary-color);
  display: inline-block;
  position: relative;
  text-align: center;
}

.register-form-text-2 {
  width: 33vw;
  font-size: 6vh;
  color: var(--primary-color);
  display: inline-block;
  position: relative;
  text-align: center;
}

.divider {
  width: 1px;
  margin-left: -2vw;
  background-color: black;
  height: 90vh;
}

// Right screen styles
.register-page-right {
  height: 100vh;
  margin-left: 2vw;
  padding-bottom: 2vh;
}

.register-content-right {
  margin-top: 5vh;
  width: 60vw;
}

.register-form-container {
  padding-top: 10vh;
  height: 98vh;
  width: 50vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.register-form-title {
  font-size: 5vh;
  padding-left: 7vw;
  padding-right: 7vw;
  padding-top: 1vh;
  line-height: 6vh;
  margin-bottom: 2.8vh;
  display: block;
  font-weight: bold;
  text-align: center;
}

.register-form-title-2 {
  font-size: 5vh;
  padding-top: 1vh;
  padding-left: 3vh;
  padding-right: 3vh;
  line-height: 6vh;
  margin-bottom: 6vh;
  display: block;
  font-weight: bold;
}

.register-form-subtitle {
  font-size: 2.2vh;
  margin-bottom: 5vh;
  display: block;
  color: var(--inactive-color);
  text-align: center;
}

.register-form-types {
  justify-content: left;
  display: flex;
  width: 38.6vw;
  flex-wrap: wrap;
  gap: 1.3vw;
}

.register-form-type {
  cursor: pointer;
}

.register-form-type-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 4vh;
  height: 27vh;
  width: 12vw;
  border: 1px solid var(--box-border-color);
  border-radius: 2.5vh;
  transition: box-shadow 0.3s, transform 0.3s;
}

.register-form-type-box:hover {
  background-color: var(--secondary-color);
  box-shadow: 0 15px 18px var(--box-border-shadow);
  border: 1px solid var(--secondary-color);
  transform: translateY(-2px);
}

.register-form-type-box:hover .type-label {
  color: #ffffff;
}

.register-form-type-box:hover .sub-label {
  color: #ffffff;
}

.register-form-type-back-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 4vh;
  height: 27vh;
  width: 12vw;
  border: 1px solid var(--box-border-color);
  border-radius: 2.5vh;
  transition: box-shadow 0.3s, transform 0.3s;
}

.register-form-type-back-box:hover {
  background-color: var(--gray-color);
  box-shadow: 0 10px 20px var(--gray-color);
  border: 1px solid var(--gray-color);
  transform: translateY(-2px);
}

.register-form-subtype-box {
  background-color: #f2f2f2;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 10px;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.type-logo {
  height: 5.2vh;
  align-self: flex-start;
}

.type-label {
  font-size: 2.3vh;
  font-weight: bold;
  line-height: 2.8vh;
  align-self: flex-start;
  color: var(--primary-color);
  margin-top: 4.5vh;
}

.sub-label {
  font-size: 1.8vh;
  font-weight: bold;
  line-height: 1.8vh;
  align-self: flex-start;
  color: var(--primary-color);
}

// Register
.register-header-text {
  color: var(--primary-color);
  font-size: 3.8vh;
  display: block;
  font-weight: bold;
  margin-top: 2vh;
}

.register-form-card {
  align-items: center;
}

.register-form-container-2 {
  padding-top: 4vh;
  height: 92vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.register-form {
  width: 30vw;
}

.register-text-field {
  border-radius: 12px;
  border: 1px;
}

.register-row {
    margin-top: 2vh;
}

.error-from-api {
    margin-bottom: -0.5vh;
    position: absolute; 
    bottom: 0;
}

.error-from-api-text {
    font-size: 1.3vh;
    color: #ff5252;
}

.password-requirements {
    margin-top: 2.5vh;
} 

.password-requirements-text {
  font-weight: bold;
  font-size: 1.8vh;
}

.req-satisfied {
  font-size: 1.7vh;
  color: #4cb04f;
}

.req-unsatisfied {
  font-size: 1.7vh;
  color: red;
}

.accept-aggrement-text {
  font-size: 1.7vh;
}

.row-buttons {
  align-items: end;
}

.primary-button.v-btn--disabled {
  color: var(--disabled-text-color) !important;
  background-color: var(--disabled-button-color) !important;
}

.register-footer-left {
  position: absolute;
  margin-left: 3vw;
  bottom: 0;
  left: 0;
  font-size: 1.6vh;
}

.register-footer-right {
  position: absolute;
  margin-right: 3vw;
  bottom: 0;
  right: 0;
  font-size: 1.6vh;
}

.email-sent-message {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--primary-color);
  // padding-left: 1.5vh;
  // padding-right: 1.5vh;
}

.email-sent-logo {
  width: 3.4vw;
  margin-bottom: 2vh;
}

.email-sent-title {
  font-size: 20px;
  font-weight: bold;
}

.email-sent-info {
  margin-top: 2vh;
  font-size: 18px;
  line-height: 2.2vh;
  // font-weight: bold;
  text-align: center;
}

.align-right {
  display: flex;
  justify-content: flex-end;
}

.user-email {
  color: var(--secondary-color);
}

.no-transform {
  text-transform: none !important;
}

.primary-b3-button {
  font-size: 14px;
  margin-bottom: 1.5vh;
}

.selectedActiveType {
  background-color: #16407a;
}
.selectedActiveSubType {
  background-color: #16407a;
  color: white;
}

.v-sheet.v-stepper:not(.v-sheet--outlined) {
  box-shadow: none;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
form {
  div {
    padding-top: 0 !important;
  }
}

// @media only screen and (max-height: 700px) {
//     .register-row {
//         margin-top: 0vh;
//     }

//     .error-from-api {
//         margin-bottom: 1vh;
//     }

//     .register-form-container-2 {
//         padding-top: 2.5vh;
//     }

//     .password-requirements {
//         margin-top: 1.3vh;
//     } 
// }

// @media only screen and (max-height: 800px) {
//     .register-form {
//         width: 32vw;
//     }
// }

// @media only screen and (max-width: 768px) {
//   .register-form-container {
//     width: 100%;
//   }
//   .register-form-title {
//     font-size: 24px;
//     width: 100%;
//   }
//   .register-form-button {
//     button {
//       width: 200px;
//     }
//   }
//   .register-form-types {
//     flex-direction: column;
//   }
//   .register-form-logo-2 {
//     width: 100px;
//     position: relative;
//   }
// }

// @media only screen and (max-height: 800px) {
//     .register-row {
//         margin-top: 1.2vh;
//     }

//   .register-header-text {
//     font-size: 3vh;
//     margin-top: 1vh;
//   }

//   .password-requirements-text {
//     font-weight: bold;
//     font-size: 1.5vh;
//   }

//   .req-satisfied {
//     font-size: 1.5vh;
//   }

//   .req-unsatisfied {
//     font-size: 1.5vh;
//   }

//   .register-label {
//     font-size: 1.5vh;
//   }

//   .accept-aggrement-text {
//     font-size: 1.5vh;
//   }

//     .secondary-button {
//         font-size: 1.5vh;
//         max-height: 4vh;
//     }

//     .primary-button {
//         font-size: 1.5vh;
//         max-height: 4vh;
//     }

// }

// @media only screen and (max-height: 650px) {
//     .register-row {
//         margin-top: -2.7vh;
//     }

//     .password-requirements {
//         margin-top: -3vh;
//     }

//     .secondary-button {
//         font-size: 2vh;
//         max-height: 4.5vh;
//     }

//     .primary-button {
//         font-size: 2vh;
//         max-height: 4.5vh;
//     }

// }

// @media only screen and (max-height: 580px) {
//     .register-row {
//         margin-top: -3.2vh;
//     }

//     .password-requirements {
//         margin-top: -3vh;
//     }

//     .secondary-button {
//         font-size: 2vh;
//         max-height: 4.5vh;
//     }

//     .primary-button {
//         font-size: 2vh;
//         max-height: 4.5vh;
//     }

// }

// Desktop
@media only screen and (min-width: 965px) {
  .hide-on-desktop {
    display: none;
  }
}

// Mobile
@media only screen and (max-width: 964px) {
  .hide-on-mobile {
    display: none;
  }
  .register-content-right {
    width: 100% !important;
    margin: 0;
  }
  .register-form-text {
    width: 100%;  
  }
  .register-form-title {
    line-height: normal;
  }
  .register-form-container {
    width: calc(100vw - 48px) !important;
    padding: 0;
  }

  // options
  .register-form-types, .register-form-type-box {
    width: 100%;
  }
  .register-form-types {
    display: flex;
    width: 80vw;
  }
  .register-form-type-box, .register-form-type-back-box{
    // border: 1px solid red;
    flex-direction: row;
    height: fit-content;
    align-items: center !important;
    width: 80vw;
    margin: auto;
  }
  .type-logo {
    width: auto;
    height: 30px;
  }
  .type-label {
    margin: 0;
    padding: 0;
    width: 50%;
    font-size: 16px;
  }
  .v-card {
    overflow-wrap: normal;
  }
  .choice_flex {
    width: 70%;
    span {
      display: block !important;
    }
    .sub-label {
      font-size: 12px;
    }
  }
  .container {
    padding: 0;
  }
  .register-form-container-2 {
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 48px);
  }
  .register-header-text {
    margin: 0;
    font-size: 20px;
  }
  .register-form {
    width: 100%;
  }
  .register-footer-left {
    position: absolute;
    margin-left: 3vw;
    bottom: 0;
    left: 0;
    font-size: 1.6vh;
  }
  .register-footer-right {
    position: absolute;
    margin-left: 3vw;
    bottom: 50px;
    left: 0;
    font-size: 1.6vh;
  }
}
</style>
