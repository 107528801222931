import Vue from 'vue'
import Vuex from 'vuex'
import clients from './Modules/clients.js'
import members from './Modules/members.js'
import auth from './Modules/auth.js'
import employers from './Modules/employers.js'
import advisors from './Modules/advisors.js'
import affilates from './Modules/affilates.js'
import contract from './Modules/contract.js'
import network from './Modules/network.js'
import providers from './Modules/providers.js'
import settings from './Modules/setings.js'
import advisorProviders from './Modules/Advisor/providers.js'
import bundles from './Modules/Provider/bundles.js'
import empMembers from  './Modules/Employer/Members.js'
import loginPassword from './Modules/Login/password.js'
import employerMembers from './Modules/EmployerAdvisor/employerMembers.js'
import employerPrior from './Modules/EmployerAdvisor/employerPrior.js'
import memberProvider from './Modules/Member/providers.js'
import employerAdvisorProviders from './Modules/EmployerAdvisor/providers.js'
import empoyerAdvisorAdvisors from './Modules/EmployerAdvisor/advisors.js'
import employerAdvisorContract from './Modules/EmployerAdvisor/contracts.js'
import employerAdvisorEmpSettings from './Modules/EmployerAdvisor/empSettings'
import employerAdvisorPartners from './Modules/EmployerAdvisor/partners'
import employerAdvisorSettings from './Modules/EmployerAdvisor/settings'
import employerProviders from './Modules/Employer/providers.js'
import employerAdvisors from './Modules/Employer/advisor.js'
import employerContract from './Modules/Employer/contracts.js'
import empSettings from './Modules/Employer/settings.js'
import wizzardData from './Modules/wizzardData'
import DPCProviders from './Modules/dpc-providers'
import singleSolution from './Modules/solution'
import Invoicing from './Modules/DigitalInvoicing/index.js'
import Tpa from './Modules/Tpa/index.js'
import DPCMember from './Modules/DPCMember/index.js'

import 'material-design-icons-iconfont/dist/material-design-icons.css'

import api from "../services/api.js"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    namespaced: true,
    clients,
    members,
    auth,
    employers,
    advisors,
    affilates,
    contract,
    network,
    providers,
    settings,
    advisorProviders,
    bundles,
    empMembers,
    loginPassword,
    employerMembers,
    employerPrior,
    memberProvider,
    employerAdvisorProviders,
    empoyerAdvisorAdvisors,
    employerAdvisorContract,
    employerAdvisorEmpSettings,
    employerAdvisorPartners,
    employerAdvisorSettings,
    employerProviders,
    employerAdvisors,
    employerContract,
    empSettings,
    wizzardData,
    singleSolution,
    DPCProviders,
    Invoicing,
    Tpa,
    DPCMember,
  },
  state: {
    patientInfo: null,
    breadcrumb:'',
    filterMenu:false,
    tab:true,
    path:'',
    sideBarTitle:'',
    sideBarDesc:'',
    showNavItem:'',
    showEmailOverlay:false,
    clearingHouseDetails:null,
    eligibilityMember:null,
    mobileMenuState:false,
    documentTitle: null,
    employersList: null,
    closeCheckEligibility: false,
    closeSubscriberInfoModal: false,
    setAvailbaleLocations: [],
  },
  getters:{
    getBreadCrumb(state){
      return state.breadcrumb
    },
    getSingleNetwork(state){
      return state.network
    },
    getFilterMenu(state){
      return state.filterMenu
    },
    getTab(state){
      return state.tab
    },
    getPath(state){
      return state.path
    },
    showRoutePathForFilter(state){
      return state.showNavItem
    },
    showRoutePathForEmailOverlay(state){
      return state.showEmailOverlay
    },
    getClearingHouseDetails(state){
      return state.clearingHouseDetails;
    },
    getEligibilityMember(state){
      return state.eligibilityMember
    },
    getMobileMenuState(state){
      return state.mobileMenuState
    }
  },
  mutations: {
    setAvailbaleLocations(state, data) {
      state.setAvailbaleLocations = data
    },
    closeSubscriberInfoModal(state, data) {
      state.closeSubscriberInfoModal = data
    },
    closeTheCheckEligibility(state, data) {
      state.closeCheckEligibility = data
    },
    setPatientInfo(state, data){
      state.patientInfo = data
    },
    setBreadcrumbs(state,path){
      localStorage.setItem('path', path)
      state.breadcrumb = path
    },
    changePath(state, path){
      state.path = path
    },
    setSingleNetwork(state,network){
      state.network = network
    },
    showFilterMenu(state,filterMenu){
      state.filterMenu = filterMenu
    },
    setTabs(state,tab){
      state.tab = tab
    },
    sideBarTitleAndDesc(state,title){
      state.sideBarTitle = title;
    },
    sideBarDesc(state, desc){

      state.sideBarDesc = desc;
    },
    setOverlayItemFilter(state,overlay){
      state.showEmailOverlay = overlay
    },
    setNavItemFilter(state,nav){
      state.showNavItem = nav
    },
    setClientDetails(state, details){
   
      state.clearingHouseDetails = details
    },
    storeMemberInfo(state,member){
      state.eligibilityMember = member
    },
    openMobileMenu(state,openMenu){
      state.mobileMenuState = openMenu
    },
    setEmployers(state, data) {
      state.employersList = data;
    }
  },
  actions: {
    getEmployers(context) { // K.N Not being used for now
      api()
        .get(`/dpcproviders/employers/active`)
        .then((res) => {
          if (res?.data) {
            context.commit("setEmployers", res.data);
            console.log("getEmployers: ", res.data);
          }
        })
        .catch((err) => {
          console.log("Error - getEmployers:", err);
        });
    },
  },
})




