<template>
    <v-row class="add-client-form no-padding">
    <div class="col-12 d-flex pt-14 pl-5 pr-5 justify-center">
  <div class="col-12">
    <v-card v-if="searchCard" class="mx-auto" max-width="550" height="400px" tile>
      
        <div class="pt-10 text-center pb-3 font-weight-bold font-18">
            <h2> Healthcare Providers</h2>
            </div>

            <div class="pt-3 text-center pb-3 font-weight-bold font-14">
              <h3>
                Enter your zip code to see how many potential 
              </h3>  
               </div>
               <div class="text-center pb-3 font-weight-bold font-14">
              <h3>
                members live within driving distance of your location!
              </h3>   
               </div>
        <div class="justify-center d-flex mb-10 mt-5">
            <div>
                <v-text-field
                class="input-control search-btn rounded-lg"
                light
                dense
                @keypress.enter="searchZip(zipCode)"
                v-model="zipCode"
                outlined
                placeholder="Search for Zip Code"
            ></v-text-field>
            </div>
        </div>
        <div class="text-center">
            
            <span>
                <v-btn @click="searchZip(zipCode)" 
                class="search-btn" 
                rounded
                color="#16407A"> Submit</v-btn>
            </span>
        </div>
        </v-card>

        <v-card v-if="resultCard" class="mx-auto" max-width="550" height="420px" tile>
      
            <div class="pt-3 text-center pb-3 font-weight-bold font-18">
               <h2 v-if="totalCount" class="pt-2">
                {{ totalCount }}
               </h2>  
               <h2 v-else>
                No members
               </h2> 
                </div>
    
                <div class="pt-3 text-center pb-3 font-weight-bold font-14">
                 <span>
                    <h3>
                       Members live within driving distance of your location
                    </h3>
                 </span>
             
                   </div>
                   <div  class="text-center">
                    <span>
                        <a class="text-underline"
                        @click="reset()">Reset</a>
                    </span>
                   </div>
                   <div class="justify-center text-center col-12 pr-3 pl-3">
                    <v-divider horizontal></v-divider>
                   </div>
                   <div class="pt-3 text-center pb-3 font-weight-bold font-14">
                    <span>
                       <h3>
                          Want to view a full dynamic member map?
                       </h3>
                    </span>
                    </div>
                      <div  class="text-center">
                       <span>
                        <h3>
                         <a class="text-underline">SIGN UP FOR FREE</a>
                       </h3>
                       </span>
                      </div>
                      <div class="justify-center text-center col-12 pr-3 pl-3">
                       <v-divider horizontal></v-divider>
                      </div>

                      <div class="pt-3 text-center pb-3 font-weight-bold font-14">
                        <span>
                           <h3>
                             Apaly makes it possible for providers to
                           </h3>
                        </span>
                        <span>
                          <h3>
                            tap into the large employer market.
                          </h3>
                       </span>
                        </div>
            </v-card>
</div>
    </div>
      
    </v-row>
  </template>
  <script>
  import api from "../../../services/api.js";
  import XLSX from "xlsx";
  export default {
  
    data() {
      return {
        resultCard: false,
        searchCard: true,
        showList: false,
        searched: false,
        zipCode: "",
        list: [],
        items: [],
        names: [],
        totalCount: [],
      jsondata: [
        {
          zip:"",
        }
      ],
      };
    },
    created(){

    },
    methods: {
        reset(){
          this.resultCard = false;
          this.searchCard = true;
          this.zipCode = "";
        },
      searchZip(zip){
        this.searchCard = false;
        this.resultCard = true;
        api()
          .get(`/dpcmembers?zipCode=` + zip)
          .then((res) => {
            if (res) {
              this.list = res.data.nearbyZipCodes;
              this.totalCount = res.data.totalCount;
              let test= res.data.nearbyZipCodes.Zip;
              this.searched=true;
             
            }
          });
      },
      addItemExcel() {
      this.$refs.fileInput.click();
    },
    uploadExcel(e) {
      this.excelError = false;
      const files = e.target.files;
      if (!files.length) {
        return;
      } else if (!/\.(xls|xlsx|csv)$/.test(files[0].name.toLowerCase())) {
        return alert(
            "The upload format is incorrect. Please upload xls, xlsx or csv format"
        );
      }
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const workbook = XLSX.read(data, {
            type: "binary",
          });
          const wsname = workbook.SheetNames[0];
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]);
          for (var i = 0; i < ws.length; i++) {
            if (ws[i].zipCode) {
              this.excelError = false;
              let tmpObj = {
                zipCode: ws[i].zipCode ?? "",
              }
              this.items.push(tmpObj);
              this.arrayLength = true;
            } else {
              this.excelError = true;
              this.arrayLength = false;
            }
          }
          if (this.items.length < 1) {
            this.arrayLength = false;
            this.items = [];
            this.$refs.error_modal.openPopUp(
                "Something Went Wrong, Please Download The File Template First"
            );
          }
        } catch (e) {
          console.log(e)
          this.$refs.error_modal.openPopUp(
                "Read failure! Please Download the File Template."
            );
        }
      };

      fileReader.readAsBinaryString(files[0]);
    },
    downloadExcel() {
      const data = XLSX.utils.json_to_sheet(this.jsondata);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "zipCodes.xlsx");
    },
    }
  
  };
  </script>
  <style scoped>
.justify-space{
    justify-content: space-between;
}
.title-color{
    color:#334D6E;
}
.apaly-color{
    color: #16407A;
}
.search-btn{
    color: white;
  width: 260px;

}
.search1{
    margin-left: 16vw;
}
</style>