import EmployerClientTemplate from '../views/EmployerClient.vue'
import RouterView from '../components/Sections/EmployerClient/RouterView.vue'
import Home from '../components/Sections/EmployerClient/Home.vue'
import Vendors from '../components/Sections/EmployerClient/Vendors/Vendors.vue'
import Providers from '../components/Sections/EmployerClient/Providers/Providers.vue'
import Invoices from '../components/Sections/EmployerClient/Invoices/Invoices.vue'
import Account from '../components/Sections/EmployerClient/Account/Account.vue'
import Contract from '../components/Sections/EmployerClient/Account/Contract/Contract.vue'
import AddNew from '../components/Sections/EmployerClient/Account/Contract/AddNew.vue'
import Bank from '../components/Sections/EmployerClient/Account/BankInfo/BankInfo.vue'
import Users from '../components/Sections/EmployerClient/Account/Users/Users.vue'
import AddUser from '../components/Sections/EmployerClient/Account/Users/AddUser.vue'
import SingleUser from '../components/Sections/EmployerClient/Account/Users/SingleUser.vue'
import Solutions from '../components/Sections/EmployerClient/Solutions/Solutions.vue'
import Settings from '../components/Sections/EmployerClient/Account/Settings/Settings.vue'
import TpaInfo from '../components/Sections/EmployerClient/Account/TPAInfo/TPAInfo.vue'
import BrokerInfo from '../components/Sections/EmployerClient/Account/BrokerInfo/BrokerInfo.vue'
import PlanInfo from '../components/Sections/EmployerClient/Account/PlanInfo/PlanInfo.vue'


const router = [
    {
        path:'/employer-client',
        name:'',
        redirect: () => {
            return { path: '/employer-client/home'} 
        },
     },
    {
        component:RouterView,
        path:'/employer-client',
        name:'',
        redirect: () => {
            return { path: '/employer-client'} 
        },
        children:[
            {
                path:'/employer-client',
                name:'',
                component:EmployerClientTemplate,
                children:[
                    {
                        path:'home',
                        name:'Home',
                        component:Home,
                    },
                    {
                        path:'vendors',
                        name:'Vendors',
                        component:Vendors,
                    },
                    {
                        path:'solutions',
                        name:'Solutions',
                        component:Solutions,
                    },
                    {
                        path:'providers',
                        name:'Providers',
                        component:Providers,
                    },
                    {
                        path:'invoices',
                        name:'Invoices',
                        component:Invoices,
                        children:[
                            {
                            path:'new',
                            name:'New Invoice',
                            component:RouterView,
                            meta:{
                                isChildren:true
                            }

                           }
                        ]
                    },  
                    
                    {
                        path:'account',
                        name:'Account',
                        component:RouterView,
                        redirect: () => {
                            return { path: '/employer-client/account'} 
                        },
                        children:[
                            {
                                path:'/employer-client/account',
                                name:'Account',
                                component:Account,
                            },
                            {
                                path:'bank',
                                name:'Bank Info',
                                component:Bank,
                            },
                            {
                                path:'plan-info',
                                name:'Plan Info',
                                component:PlanInfo,
                            },{
                                path:'tpa-info',
                                name:'TPA Info',
                                component:TpaInfo,
                            },{
                                path:'broker-info',
                                name:'Broker Info',
                                component:BrokerInfo,
                            },
                            {
                                path:'/employer-client/account/contract',
                                name:'',
                                component:RouterView,
                                redirect: () => {
                                    return { path: '/employer-client/account/contract'} 
                                },
                                children:[
                                    {
                                        path:'/employer-client/account/contract',
                                        name:'Contract',
                                        component:Contract,
                                        children:[
                                            {
                                                path:'add-new',
                                                name:'Add New',
                                                component:AddNew,
                                                meta:{
                                                    isChildren:true
                                                }
                                            },
                                          
                                        ]
                                    },
                                ]
                            },
                            {
                                path:'/employer-client/account/users',
                                name:'',
                                component:RouterView,
                                redirect: () => {
                                    return { path: '/employer-client/account/users'} 
                                },
                                children:[
                                    {
                                        path:'/employer-client/account/users',
                                        name:'Users',
                                        component:Users,
                                        children:[
                                            {
                                                path:'add-new',
                                                name:'New User',
                                                component:AddUser,
                                                meta:{
                                                    isChildren:true
                                                }
                                            },
                                            {
                                                path:'user',
                                                name:'User Detail',
                                                component:SingleUser,
                                                meta:{
                                                    isChildren:true
                                                }
                                            },
                                        ]
                                    },
                                ]
                            },
                            {
                                path:'settings',
                                name:'Settings',
                                component:Settings,
                            },
                        ]
                    },
                   
                ]
            }
        ]
    },

]

export default router;