var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('successModal',{ref:"success_modal",on:{"confirm":_vm.closeSuccessModal}}),_c('v-row',{staticClass:"main-row"},[_c('v-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[(
          this.$route.path == '/login' ||
          (this.$route.path.includes('deny') &&
            this.$route.path.includes('visitPassRequests')) ||
          (this.$route.path.includes('approve') &&
            this.$route.path.includes('visitPassRequests'))
        )?_c('div',[_c('New')],1):(this.$route.path == '/register')?_c('div',[_c('NewRegister')],1):(this.$route.path == '/change-pass')?_c('div',[_c('ChangePass')],1):(this.$route.path == '/set-pass')?_c('div',[_c('SetPass')],1):(
          this.$route.query &&
          typeof this.$route.query.resetPassword != 'undefined' &&
          this.$route.query.resetPassword == null
        )?_c('div',[_c('ResetPass')],1):_c('div',{staticClass:"stepper"},[_c('RegisterComp')],1)])],1),_c('errorModal',{ref:"error_modal",on:{"confirm":_vm.closeErrorModal}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }