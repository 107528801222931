<template>
    <div class="page-background">
      <main-header :title="'Account'" :color="'#001E46'" ></main-header>
      <div class="mobile-content-background">
          <v-list>
            <v-list-item-group color="primary" >
              <v-list-item v-for="(item, i) in items" :key="i" style="border-bottom:1px solid #00000029" >
                <v-list-item-content @click="navigateTo(item.link)">
                  <v-list-item-title v-text="item.text" class="list-item-title" ></v-list-item-title>
                  <v-list-item-subtitle v-text="item.subtitle"></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon color=""  v-text="item.icon" class="mr-3"></v-icon>
                </v-list-item-icon>

              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </div>
</template>

<script>
import mainHeader from "@/components/Sections/DPCMember/Layout/mainHeader";
export default {
  data() {
    return {
      items: [
        {
          text: "Personal",
          icon: "mdi-chevron-right",
          subtitle:"Personal, contact, and insurance info",
          link: "accountFamilyMembers",
        },
        {
          text: "Family Members",
          icon: "mdi-chevron-right",
          subtitle:"Manage family members",
          link: "familyMembersIndex",
        },
        {
          text: "Settings",
          icon: "mdi-chevron-right",
          subtitle:"Login, password, notifications",
          link: "accountSettings",
        },
      ],
    };
  },
  components:{
    mainHeader
  },
  methods: {
    navigateTo(link){
      console.log('link',link)
      this.$router.push({name:link})

    }


  },
  created() {},
};
</script>
<style scoped src='./../../../../assets/mobileStyle.css'></style>
<style scoped lang="scss">


  .mobile-content-background{
    background-color:white;
    height:100vh;
  }
  .mobile-content{
    padding:16px 24px 0 24px;
  }
  .page-background{
    background-color: #E8E8E8
;
  }
  .list-item-title{
    color:#0573F3;
    font-family: 'Quicksand';
    font-weight: 700;
  }

  .list-item-title{
    color:#0573F3;
    font-family: 'Quicksand';
    font-weight: 700;
  }

  .list-item-subtitle{
    font-family: 'Quicksand';
    font-weight: 600;
  }
</style>