<template>
    <div class="no-padding no-margin">
        <VisitPasses v-if="path == '/member/visit-passes'"></VisitPasses>
    </div>
</template>
<script>
import VisitPasses from "./VisitPasses";
    export default {
        components:{
            VisitPasses,
        },
        data(){
            return{

            }
        },
        computed:{
            path(){
                return this.$route.path
            },
            routeName(){
                return this.$route.params.name
            }
        },

        created(){

        },
        methods:{

        }

    }
</script>
<style>
    .background-opacity{
        opacity: 0.46;
        background-color: rgb(33, 33, 33);
        border-color: rgb(33, 33, 33);
    }
</style>