import Marketplace from '../views/Marketplace.vue'
import RouterView from '../components/Sections/EmployerClient/RouterView.vue'
import Home from '../components/Sections/Marketplace/Home.vue'
import Details from '../components/Sections/Marketplace/Details.vue'
const router = [
     {
        path:'/marketplace',
        name:'Marketplace',
        redirect: () => {
            return { path: '/marketplace/home'} 
        },
     },
     {
        component:RouterView,
        path:'/marketplace',
        name:'',
        redirect: () => {
            return { path: '/marketplace'} 
        },
        children:[
            {
                path:'/marketplace',
                name:'',
                component:Marketplace,
                children:[
                
                    {
                        path:'home',
                        name:'Home',
                        component:Home,
                        children:[
                            {
                                path:'details',
                                name:'Details',
                                component:Details,
                                meta:{
                                    isChildren:true,
                                }
                            }
                        ]
                    },
                ]
            }
        ]
     },
    
]

export default router;
