<template>
    <v-row class="padding">
        <div class="row col-md-12">
           <h3> 
             <span class="" v-if="$role == 'user'"> Search Providers Adding Member Zip Codes   <i class="fas fa-question-circle  yellow-icons"></i></span>
             <span v-else class="">Create a Custom Provider List Adding Member Zip Codes   <i class="fas fa-question-circle  yellow-icons"></i></span>
          </h3>
        </div>
      <v-col md="6" cols="12" class="justify-center align-center border-right">
         <div class="row col-md-12" > <h4 class="font">Add Zip Code Manually</h4></div>
         <v-form class="row" v-model.trim="valid" ref="form">
             <div class="col-md-6">
                   <label for="" class="text-gray">Enter Zip Code</label>
                   <v-text-field @input="changeZipRules" :rules="zip ? zipRules : []"  solo v-model.trim="zip" type="number"></v-text-field>
             </div>
            <div class="col-md-6 mt-7 text-center">
                   <v-btn large class="blue-buttons rounded-btn col-xl-6 col-lg-8 col-md-12" @click="pushToArray()">Add Zip Code to List</v-btn>
             </div>
         </v-form>
         <div class="row col-md-12"> <h4>OR</h4></div>
            <div class="row col-md-12 font"> 
                <h4 v-if="route.includes('providers')" >Upload a List of Providers</h4>
                <h4 v-else >Upload a List of Affilates</h4>
            </div>
            <div class="row text-center justify-center">
                <div class="col-md-9 upload-provider-box pointer">
                    <input ref="fileInput" type="file" style="display: none" >
                    <img src="/filee.png" class="apaly-pic" alt="">
                    <h3 class="text-gray font">Drag & Drop</h3>
                    <h5 class="text-gray font">
                        Your files here or 
                        <a >Browse</a> 
                        to upload
                        </h5>
                    <h5 class="text-gray font">
                        <a >Excel or CSV files Only</a>
                    </h5>
                </div>
                <div class="btn-box col-md-10 ">
                    <div class="downoald-file-btn-box ">
                        <h3>
                            <a  class="underline-text">Download File Template</a>
                        </h3>
                    </div>
                </div>
            </div>
            <div class="col-md-11 justify-end text-end m">
                <v-btn class="blue-buttons" :disabled="results" >Upload Zip Code List</v-btn>
            </div>
        </v-col>
        <v-col cols="12" md="6 ">
            <h3>Zip Code List</h3>
            <div> 
                <v-simple-table fixed-header  height="630px">
                    <template v-slot:default>
                    <thead>
                        <tr>
                        <th class="text-left">
                            Zip Codes
                        </th>
                        <th class="text-end">
                           Action
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                        v-for="item in zipList"
                        :key="item"
                        >
                        <td>{{item}}</td>
                        <td @click="removeFromArray(item)" class="text-end pointer">
                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 13.7206C1 17.0943 2.35661 20.3298 4.77138 22.7154C7.18615 25.101 10.4613 26.4412 13.8763 26.4412C17.2913 26.4412 20.5664 25.101 22.9812 22.7154C25.396 20.3298 26.7526 17.0943 26.7526 13.7206C26.7526 10.3469 25.396 7.11134 22.9812 4.72577C20.5664 2.3402 17.2913 1 13.8763 1C10.4613 1 7.18615 2.3402 4.77138 4.72577C2.35661 7.11134 1 10.3469 1 13.7206V13.7206Z" stroke="#D00000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M8.72607 18.8093L19.0271 8.63281" stroke="#D00000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M19.0271 18.8093L8.72607 8.63281" stroke="#D00000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>

                        </td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
          </div>
        
       </v-col>
       <v-row class="text-end justify-end">
            <v-progress-circular
                indeterminate
                v-if="load"
                color="primary"
             ></v-progress-circular>
           <v-btn class="blue-buttons mr-2" @click="$router.push('/employer/providers')">Cancel </v-btn>
           <v-btn class="blue-buttons mr-4" @click="addZip">Create / Update Provider List</v-btn>

       </v-row>
       <errorModal  ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
 </v-row>
</template>
<script>
import api from '../../../../../services/api'
import XLSX from 'xlsx'
import errorModal from '../../../../TablesTemplate/ErrorDialog.vue'

export default{
    components:{errorModal},
    data(){
       return {
           npiNumber:'',
           npiType:'',
           firstName:'',
           lastName:'',
           organizationName:'',
           speciality:'',
           city:'',
           state:'',
           zip:'',
           states:[],
           excelError:false,
           results:false,
           urlData:'',
           valid:false,
           load:false,
           zipList:[],
           requiredRules:[
                v => !!v || 'This field is required',
           ],
           jsondata:[{
                'NPI':'',
                'ORGANIZATON/PROVIDER NAME' : '',
                'PRIMARY TAXONOMY' : '',
                'ADDRESS' : ''
           }],
       }
    },
    computed:{
        route(){
             return this.$route.path
        },
        getClient(){
            return this.$store.getters.getClientGetter
        },
        routeParams(){
            return this.$route.params.name
         }
    },
    created(){
        
      
    },
    methods:{
        closeErrorModal(){
            this.zipList = [];
            this.$refs.error_modal.closePopUp();
        },
        downloadExcel(){
                const data = XLSX.utils.json_to_sheet(this.jsondata)
                const wb = XLSX.utils.book_new()
                XLSX.utils.book_append_sheet(wb, data, 'data')
                XLSX.writeFile(wb,'providerList.xlsx')
        },
        addItemExcel() {
          this.$refs.fileInput.click()
         },
        uploadExcel(e){
         const files = e.target.files;
            if (!files.length) {
                return;
            } else if (!/\.(xls|xlsx|csv)$/.test(files[0].name.toLowerCase())) {
                return alert("The upload format is incorrect. Please upload xls, xlsx or csv format");
            }
            const fileReader = new FileReader();
            fileReader.onload = ev => {
                try {
                const data = ev.target.result;
                const workbook = XLSX.read(data, {
                    type: "binary"
                });
                const wsname = workbook.SheetNames[
                    0
                    ]; 
                const ws = XLSX.utils.sheet_to_json(workbook.Sheets[
                    wsname
                    ]); 
                const excellist = []; 
               
                for (var i = 0; i < ws.length; i++) {
                    excellist.push(ws[i].ids);
                    if(ws[i].ids){
                      this.excelError = false
                    }else{
                      this.excelError = true
                    }
                }

                this.arrayExcel =
                    excellist 
                } catch (e) {
                return alert("Read failure!");
                }
                 
            };
           fileReader.readAsBinaryString(files[0]);
        },
         changeZipRules(){
            this.zipRules = [
                    v => ( v && v.length == 5 ) || " Zip Code should be five digits long",
            ]
        },
        pushToArray(){
          this.$refs.form.validate();
          if(this.zip !=  '' && this.valid){
              if(this.zipCodeList.length > 0){
                if(this.zipCodeList.includes(this.zip)){
                    this.zipRules = ['Zip Code Already Exists!']
                }else{
                 this.zipCodeList.push(this.zip);
                 this.zip = ''
               }
              }else{
                 this.zipCodeList.push(this.zip);
                 this.zip = ''
             }
          }
        },
        removeFromArray(item){
         
             let removeItem = this.zipList.find(x => x == item);
             this.zipList.splice(this.zipList.indexOf(removeItem),1)
        },
        createProviderList(){
        //  /api/npi/searchZip
        },
        addZip(){
          if(this.zipCodeList){
                api().get(`/locations/nearLocations?zipCode=` + this.zipCodeList).catch((err)=>{
                     if(err){
                           this.$refs.error_modal.openPopUp(err.response.data.error);
                    }else{
                        this.$refs.error_modal.openPopUp('Something went wrong, please try again');
                    }
                }).then((res)=>{
                  if(res){
                        if(res.data.length > 0 ){
                        this.load = true;
                        let arr =[]
                        res.data.forEach((el)=>{
                            if(el.Zip){
                            arr.push(Number(el.Zip))
                            }else{
                                arr.push(Number(el))
                            }
                        })
                        let data ={
                            zipCodes:arr
                        }
                        api().post(`/locations/npi`,data).then((res)=>{
                                if(res){
                                this.$store.commit('setZipSearchResult',res.data.items);
                                this.$router.push('/employer/providers');
                                this.load = false
                                  this.$store.commit('isSearchIsFromMember', true);
                                }
                        })
                        }else{
                            this.$refs.error_modal.openPopUp('First Add Zip Code To List')
                        }
                    }
                })
            }
        },
      
        
    }
}
</script>
