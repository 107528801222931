const state ={
    member : null,
    iconInFooter:'',
    filterMemberBy:'',
}
const getters = {
    getSingleMember(state){
        return state.member
    },
    getIconFromFooter(state){
         return  state.iconInFooter
    },
    getFilterBy(state){
        return state.filterMemberBy
    }

}
const mutations = {
    setSingleMember(state, member){
        state.member = member
      },
      setMemberFooterIcons(state, page){
          state.iconInFooter = page
      },
      setFilterMember(state,title){
        state.filterMemberBy = title
      }
}

export default{
    state,
    getters,
    mutations,
}