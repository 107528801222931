<template>
  <div>
    <Header :title="'Add New Health Plan'"></Header>
    <div class="col-md-12">
      <div class="col-md-12">
        <v-form v-model="valid" ref="form" class="row gray-border-form form-row-height">
          <div class="col-md-6">
            <div class="col-md-10">
              <div class="col-md-12 pb-0">
                <label for="">Plan Name</label>
                <v-text-field solo v-model="plan.name" :rules="requiredRules"></v-text-field>
              </div>
              <div class="col-md-12 pt-0 pb-0">
                <label for="">Plan ID</label>
                <v-text-field solo v-model="plan.planId" :rules="requiredRules"></v-text-field>
              </div>
              <div class="col-md-12 pt-0 pb-0">
                <label for="">Plan Type</label>
                <v-select solo v-model="plan.type" :items="typeItems" :rules="requiredRules"></v-select>
              </div>
            </div>
          </div>
        
        </v-form>
      </div>
    </div>
    <div class="col-md-12">
      <div class="col-md-12 mb-4" align="end">
        <span
          class="light-blue-text text-underline pointer font-weight-bold mr-10 font-18" @click="goBack">Cancel</span>
        <span class="light-blue-text text-underline pointer font-weight-bold mr-5 font-18 pointer" @click="submit">Submit</span>
      </div>
    </div>
    <successModal ref="success_modal" v-on:confirm="closeSuccessModal"></successModal>
    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
  </div>
</template>
<script>
import Header from "../../../Layout/HeaderPage.vue";
import api from "../../../../../../services/api";
import successModal from "../../../../../TablesTemplate/SuccessDialog.vue";
import removeDialog from "../../../../../TablesTemplate/RemoveDialog.vue";
import errorModal from "../../../../../TablesTemplate/ErrorDialog.vue";

export default {
  components: { Header, successModal, removeDialog, errorModal },
  data() {
    return {
      requiredRules: [(v) => !!v || "This field is required"],
      typeItems: ["HSA", "Non-HSA"],
      emailRules: [
        (v) => !!v || "This field is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      planIdRules: [
        (v) => !!v || "This field is required",
        (v) => (v && v.length == 7) || "Plan ID should contain 7 digits",
      ],
      valid: false,
      plan: {
        name: "",
        planId: "",
        type: "",
        status: "",
      },
    };
  },
  computed: {
    singleItem() {
      return this.$store.getters.getSingleItem;
    },
    getSingleClient() {
      return this.$store.getters.getSingleClient;
    },
  },
  created() {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user && user.groupPortal && user.groupPortal[0].organization) {
      this.plan.admin = user.companyName;
    }
    if (this.getSingleClient) {
      this.plan.groupId = this.getSingleClient.groupId;
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
      this.$router.push(`/tpa/clients/${this.$route.params.clientId}/plans`);
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    submit() {
      this.$refs.form.validate();
      if (this.valid) {
        this.plan.status = 'active';
        api()
          .post(`/tpas/clients/${this.$route.params.clientId}/plans`, this.plan)
          .catch((err) => {
              this.$refs.error_modal.openPopUp(err.response.data);
          })
          .then((res) => {
            if (res) {
              this.$refs.success_modal.openPopUp("Successfully added");
            }
          });
      }
    },
 
  },
};
</script>
<style>
.gray-border-form {
  border-right: 2px solid rgba(179, 177, 177, 0.5);
  border-left: 2px solid rgba(179, 177, 177, 0.5);
  border-bottom: 2px solid rgba(179, 177, 177, 0.5);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
</style>