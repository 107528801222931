<template>
  <div class="no-padding no-margin">
      <Table v-if="route=='/employer-advisor/employers'"></Table>
      <AddNewForm v-if="route=='/employer-advisor/employers/add-new'"></AddNewForm> 
      <SingleForm v-if="route=='/employer-advisor/employers/employer'"></SingleForm>
      <InviteForm  v-if="route=='/employer-advisor/employers/invite-employer'"></InviteForm>
      <Tabs></Tabs>
  </div>
    
</template>
<script>
import Table from './EmployersTable.vue'
import SingleForm from './SingleEmployer.vue'
import AddNewForm from './AddEmployer.vue'
import InviteForm from './InviteEmployers.vue'
import Tabs from './Tabs.vue'


export default{
  data(){
    return{
        
    }
  },
  created(){
  },
  computed:{
     route(){
        return this.$route.path.replace(/%20/g,' ')
      },
  },
  methods:{

  },
  components:{
        SingleForm,
        AddNewForm,
        Table,
        InviteForm,
        Tabs
 
  }
}

</script>
