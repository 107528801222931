<template>
  <v-row class="no-padding">
    <successModal ref="success_modal" v-on:confirm="sendMail"></successModal>
    <removeDialog
      ref="dialog_delete"
      v-on:confirm="confirmRemove"
    ></removeDialog>
    <div class="col-md-12">
      <div class="col-md-12 form-row-height">
        <div class="row">
          <div class="col-md-12">
            <h3 class="form-title font font-18 text-gray">User Detail</h3>
          </div>
        </div>
        <br />
        <v-form v-model.trim="valid" ref="form" class="row mt-4">
          <div class="col-md-6">
            <div class="col-md-11 pb-0">
              <label class="font text-gray" for="">First Name</label>
              <v-text-field
                required
                v-model.trim="firstName"
                solo
                :rules="requiredRules"
                tabindex="1"
              ></v-text-field>
            </div>
            <div class="col-md-11 pt-0 pb-0">
              <label class="font text-gray" for="">Last Name</label>
              <v-text-field
                required
                tabindex="2"
                :rules="requiredRules"
                v-model.trim="lastName"
                solo
              ></v-text-field>
            </div>
            <div class="col-md-11 pt-0 pb-0">
              <label class="font text-gray" for="">Email</label>
              <v-text-field
                required
                :rules="emailRules"
                v-model.trim="email"
                solo
                disabled
                tabindex="3"
              ></v-text-field>
            </div>
            <div class="col-md-11 pt-0 pb-0">
              <label class="font text-gray" for="">Title</label>
              <v-text-field
                required
                tabindex="4"
                :rules="requiredRules"
                v-model.trim="title"
                solo
              ></v-text-field>
            </div>
            <br /><br /><br /><br /><br />
            <div class="row col-md-11 no-padding">
              <div class="col-md-8 font">
                <label for="" class="text-center ml-12">User Status</label>
                <div class="d-flex mt-2 font-weight-bold text-gray">
                  InActive
                  <v-switch
                    solo
                    class="no-padding ml-2 mr-2 vendors-switch"
                  ></v-switch>
                  Active
                </div>
              </div>
              <div
                class="col-md-4 mt-5 text-end"
                v-if="!deactivateDelete && $role != 'user'"
              >
                <span
                  class="text-underline font-weight-bold text-gray pointer"
                  @click="deleteUser()"
                  >Remove User</span
                >
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="col-md-12 pt-0 pb-0">
              <label class="font text-gray" for="">Role </label>

              <v-select
                :items="[
                  { text: 'Admin', value: 'admin' },
                  { text: 'User', value: 'user' },
                ]"
                solo
                v-model.trim="role"
              ></v-select>
            </div>

            <div class="col-md-12 pt-0 pb-0">
              <label class="font text-gray" for="">Address</label>

              <v-text-field
                required
                tabindex="7"
                :rules="requiredRules"
                v-model.trim="address"
                solo
              ></v-text-field>
            </div>

            <div class="col-md-12 row no-padding">
              <div class="col-md-6 pt-0 pb-0">
                <label class="font text-gray" for="">City</label>
                <v-text-field
                  required
                  :rules="requiredRules"
                  v-model.trim="city"
                  solo
                  tabindex="8"
                ></v-text-field>
              </div>
              <div class="col-md-3 pt-0 pb-0">
                <label class="font text-gray" for="">State</label>
                <v-select
                  required
                  :rules="requiredRules"
                  :items="states"
                  v-model.trim="state"
                  solo
                  tabindex="9"
                  item-text="name"
                  item-value="name"
                ></v-select>
              </div>
              <div class="col-md-3 pt-0 pb-0">
                <label class="font text-gray" for="">Zip</label>
                <v-text-field
                  required
                  type="number"
                  v-model.trim="zip"
                  :rules="zipRules"
                  solo
                  tabindex="10"
                ></v-text-field>
              </div>
            </div>
          </div>
        </v-form>
      </div>
    </div>

    <div class="col-md-12 gray-top-border">
      <div class="col-md-12 mb-4" align="end">
        <span
            style="color: #0573F3;"
          class="
            text-underline
            pointer
            font-weight-bold
            mr-10
            font-18
          "
          @click="goBack()"
          >Cancel</span
        >
        <span
          @click="updateUser()"
          style="color: #0573F3;"
          class="
            text-underline
            pointer
            font-weight-bold
            mr-10
            font-18
          "
          >Save
        </span>
      </div>
    </div>
  </v-row>
</template>
<script>
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import removeDialog from "../../../TablesTemplate/RemoveDialog.vue";
import api from "../../../../services/api";
import { mapGetters } from "vuex";
export default {
  components: { successModal, removeDialog },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      title: "",
      role: "",
      officeName: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      status: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
      zipRules: [
        (v) => !!v || "This field is required",
        (v) => (v && v.length == 5) || " Zip Code should be five digits long",
      ],
      states: [],
      valid: false,
      deactivateDelete: false,
      removUserStatus: false,
    };
  },
  computed: {
    ...mapGetters(["getSingleItem"]),
    routeParams() {
      return this.$route.params.name;
    },
    singleUser() {
      return this.$store.getters.getSingleItem;
    },
  },
  created() {
    this.getStates();
    if (this.singleUser) {
      this.firstName = this.singleUser.firstName;
      this.lastName = this.singleUser.lastName;
      this.email = this.singleUser.email;
      this.title = this.singleUser.title ? this.singleUser.title : "";
      this.role =
        this.singleUser.groupPortal &&
        this.singleUser.groupPortal[0] &&
        this.singleUser.groupPortal[0].role &&
        this.singleUser.groupPortal[0].role.name
          ? this.singleUser.groupPortal[0].role.name
          : "";

      this.officeName = this.singleUser.officeName
        ? this.singleUser.officeName
        : "";
      this.address = this.singleUser.address1 ? this.singleUser.address1 : "";

      this.city = this.singleUser.city ? this.singleUser.city : "";
      this.state = this.singleUser.state ? this.singleUser.state : "";
      this.zip = this.singleUser.zipCode ? this.singleUser.zipCode : "";
      this.status =
        this.singleUser.status && this.singleUser.status == "active"
          ? true
          : false;
      if (localStorage.getItem("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        if (user.email == this.email) {
          this.deactivateDelete = true;
        }
      }
      if (this.singleUser.status == "invited") {
        this.removUserStatus = true;
      }
    }
  },
  methods: {
    deleteUser() {
      this.$refs.dialog_delete.openPopUp(
        "Are you sure that you want to delete this user?"
      );
    },

    confirmRemove() {
      api()
        .delete(`/tpas/users/${this.singleUser._id}`)
        .then((res) => {
          if (res) {
            this.$parent.getAllUsers();
            this.$router.push(`/digital-invoicing/users`);
          }
        });
    },
    goBack() {
      this.$router.push(`/digital-invoicing/users`);
    },
    getStates() {
      api()
        .get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },
    sendMail() {
      this.$refs.success_modal.closePopUp();
      this.$router.push(`/digital-invoicing/users`);
    },
    updateUser() {
      this.$refs.form.validate();
      if (this.valid) {
        let data = {
          firstName: this.firstName,
          lastName: this.lastName,
          title: this.title,
          email: this.email,
          role: this.role,
          address1: this.address,
          city: this.city,
          state: this.state,
          zipCode: this.zip,
          status: this.status ? "active" : "inactive",
        };

        api()
          .put(`/tpas/users/` + this.singleUser._id, data)
          .then((res) => {
            if (res) {
                this.$parent.getAllUsers();
              let text = "User sucessfully updated";
              this.$refs.success_modal.openPopUp(text);
            }
          });
      }
    },
  },
};
</script>
