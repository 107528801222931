<template>
  <v-row class="padding">
    <div class="col-md-12 pt-0">
      <h3 class="font">Partner Detail</h3>
    </div>
    <div class="form-row-height col-md-12 no-padding">
         <form class="row">
      <div class="col-md-6">
        <div class="col-md-12   pb-0">
          <label class="font text-gray " for="">First Name</label>
          <v-text-field required solo v-model.trim="firstName" disabled></v-text-field>
        </div>
        <div class="col-md-12  pt-0 pb-0">
          <label class="font text-gray" for="">Last Name</label>
          <v-text-field required solo v-model.trim="lastName" disabled></v-text-field>
        </div>
        <div class="col-md-12  pt-0 pb-0">
          <label class="font text-gray" for="">Email</label>
          <v-text-field required v-model.trim="email" disabled solo></v-text-field>
        </div>
        <div class="col-md-12  pt-0 pb-0">
          <label class="font text-gray" for="">Phone</label>
          <v-text-field
            required
            solo
            v-model.trim="phone"
            disabled
            :rules="phoneRules"
          ></v-text-field>
        </div>
      </div>
      <div class="col-md-6">
        <div class="col-md-12  pb-0">
          <label class="font text-gray" for="">Company Name</label>
          <v-text-field required solo v-model.trim="company" disabled></v-text-field>
        </div>
        <div class="col-md-12  pt-0 pb-0">
          <label class="font text-gray" for="">Title</label>
          <v-text-field required solo v-model.trim="title" disabled></v-text-field>
        </div>
        <div class="row no-padding">
          <div class="col-md-6  pt-0 pb-0">
            <label class="font text-gray" for="">City</label>
            <v-text-field required v-model.trim="city" disabled solo></v-text-field>
          </div>
          <div class="col-md-3  pt-0 pb-0">
            <label class="font text-gray" for="">State</label>
            <v-text-field v-model.trim="state" disabled solo> </v-text-field>
          </div>
          <div class="col-md-3  pt-0 pb-0">
            <label class="font text-gray" for="">Zip</label>
            <v-text-field
              required
              disabled
              solo
              type="number"
              v-model.trim="zip"
            ></v-text-field>
          </div>
        </div>
        <div class="col-md-12  pt-0 pb-0">
          <label class="font text-gray" for="">Type </label>
          <v-text-field required solo v-model.trim="type" disabled></v-text-field>
        </div>
      </div>

    </form>
    </div>
 
      <div class="col-md-12 d-flex align-end flex-column pt-0" v-if="$role != 'user'">
        <div>
          <v-btn
            class="blue-buttons mr-6"
            @click="$router.go(-1)"
          >
            Close
          </v-btn>
          <v-btn v-if="showDeleteBtn" class="blue-buttons" @click="removeItem">
            Remove Partner
          </v-btn>
        </div>
      </div>
    <v-dialog v-model.trim="dialog" max-width="600px" persistent>
      <v-card>
        <v-col>
          <h3>Remove Partner</h3>
        </v-col>

        <v-col>
          <span>Are you sure you want to delete this Partner?</span>
        </v-col>

        <v-col>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="blue-buttons float-class" @click="confirmRemove"
              >Confirm</v-btn
            >
            <v-btn class="button-style float-class" @click="closePopUp"
              >Close</v-btn
            >
          </v-card-actions>
        </v-col>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import api from "../../../../services/api";
export default {
  data() {
    return {
      states: [],
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      company: "",
      title: "",
      state: "",
      zip: "",
      type: "",
      dialog: false,
      city: "",
        phoneRules:[
      (v) => /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) || "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
    };
  },
  computed: {
    getClient() {
      return this.$store.getters.getClientGetter;
    },
    showDeleteBtn() {
      return this.$store.getters.showDeletePartnerButton;
    },
    routeParams() {
      return this.$route.params.name;
    },
    singleAdvisor() {
      return this.$store.getters.getSingleAdvisorinEmployerAdvisor;
    },
  },
  created() {
    this.getStates();
    if (this.singleAdvisor) {
      this.firstName = this.singleAdvisor.primaryContact.firstName;
      this.lastName = this.singleAdvisor.primaryContact.lastName;
      this.city = this.singleAdvisor.city;
      this.company = this.singleAdvisor.companyName;
      this.email = this.singleAdvisor.primaryContact.email;
      this.type =
        this.singleAdvisor.advisorType == "brokerConsultant"
          ? "Broker Consultant"
          : this.singleAdvisor.advisorType == "analyticsConsultant"
          ? "Analytics Consultant"
          : this.singleAdvisor.advisorType;
      this.phone = this.singleAdvisor.primaryContact.phone
        ? this.singleAdvisor.primaryContact.phone
        : "";
      this.phone = this.singleAdvisor.title ? this.singleAdvisor.title : "";
      this.state = this.singleAdvisor.state;
      this.zip = this.singleAdvisor.zipCode;
    }
  },
  methods: {
    closePopUp() {
      this.dialog = false;
    },
    confirmRemove() {
      let user = JSON.parse(localStorage.getItem("user"));
      let provider = user.entity._id;

      api()
        .delete(`/providers/${provider}/advisors/${this.singleAdvisor._id}`)
        .then((res) => {
          if (res) {
            api()
              .get(`/providers/${provider}/advisors`)
              .then((res) => {
                if (res) {
                  this.$store.commit("setAllPartners", res.data.advisors);
                  this.dialog = false;
                  this.$router.push(`/provider/advisors`);
                }
              });
          }
        });
    },
    removeItem() {
      this.dialog = true;
    },
    getStates() {
      api()
        .get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },
  },
};
</script>
 

