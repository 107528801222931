<template>
  <v-row class="add-client-form no-padding">
    <div class="row mb-4">
      <div class="col-md-12">
        <h3 class="form-title font">Contract Details</h3>
      </div>
    </div>
    <v-tabs dark show-arrows style="background: #4072b7" v-model.trim="tab">
      <v-tabs-slider style="background: orange"></v-tabs-slider>
      <v-tab
        style="background: #4072b7"
        v-for="(item, i) in items"
        :key="i"
        class="col-md-4"
        :href="'#tab' + i"
      >
        {{ item.text }}
      </v-tab>
    </v-tabs>

    <div class="col-md-12 contract-form" v-if="itemtab == 'tab0'">
      <div class="col-md-12 row padding">
        <div class="col-md-12 row justify-space-between">
          <h3 class="mb-5">Contract Form and Custom Name</h3>
          <v-btn
            v-if="$role != 'user'"
            class="blue-buttons"
            @click="
              $router.push(
                `/provider-advisor/providers/${routeParams}/contracts/request`
              )
            "
            >Request a New Form</v-btn
          >
        </div>
        <div class="col-md-12">
          <label for="" class="text-gray mt-10">Contract Information</label>
          <hr />
        </div>
        <div class="col-md-6">
          <label for="">Form</label>
          <div class="row ml-1">
            <v-checkbox solo v-model.trim="serviceAgreement"></v-checkbox>
            <a class="mt-5" @click="viewContract">
              Fee for Service - Provider Services Agreement</a
            >
          </div>
        </div>
        <div class="col-md-6">
          <label for="">Custom Name</label>
          <v-text-field
            v-model.trim="customName"
            solo
            value="Platform Contract"
            hint="(Optional)"
            persistent-hint
          ></v-text-field>
        </div>
      </div>
    </div>
    <div class="col-md-12 contract-form" v-if="itemtab == 'tab1'">
      <div class="col-md-12 p-4">
        <div class="col-md-12 row justify-space-between">
          <h3 class="mb-5">Rates</h3>
          <v-btn
            v-if="$role != 'user'"
            class="blue-buttons"
            @click="
              $router.push(
                `/provider-advisor/providers/${routeParams}/contracts/request`
              )
            "
            >Request Custom Rates</v-btn
          >
        </div>
        <div class="row mt-3">
          <label for="" class="col-md-6 text-gray"
            >Your Maximum Contract Rates
          </label>
          <label for="" class="col-md-6 text-gray" v-if="display"
            >Employer Price-Match</label
          >
        </div>
        <hr />
        <v-label class="font">Outpatient </v-label>
        <div class="row mb-10 ml-1">
          <div class="row mt-2 col-md-6" cols="8">
            <input
              style="height: 2.5rem"
              @input="getOutpatientPriceMatch"
              v-model.trim="outpatient"
              type="number"
              class="inputs-width"
            />
            <h5 class="mt-2 ml-3 mr-10">% of Base Medicare</h5>
            <h5 v-if="!display" class="mt-2">
              {{ outpatientPriceMatch }}
              <span v-if="outpatientPriceMatch != 'NA'">%</span> - Employer
              Price-Match <i class="fas fa-question-circle yellow-icons"></i>
            </h5>
          </div>

          <div v-if="display" class="col-md-2" cols="2">
            <h2 class="ml-10">
              {{ outpatientPriceMatch }}
              <span v-if="outpatientPriceMatch != 'NA'">%</span>
            </h2>
          </div>
        </div>
        <v-label class="font" v-if="getContract.providerType != 'other'"
          >Inpatient
        </v-label>
        <div class="row ml-1" v-if="getContract.providerType != 'other'">
          <div class="row mt-2 col-md-6">
            <input
              style="height: 2.5rem"
              @input="getOutpatientPriceMatch"
              v-model.trim="inpatient"
              type="number"
              class="inputs-width"
            />
            <h5 class="mt-2 ml-3 mr-10">% of Base Medicare DRG</h5>
            <h5 v-if="!display" class="mt-2">
              {{ inpatientPriceMatch }}
              <span v-if="inpatientPriceMatch != 'NA'">%</span> - Employer
              Price-Match <i class="fas fa-question-circle yellow-icons"></i>
            </h5>
          </div>
          <div v-if="display" class="col-md-2">
            <h2 class="ml-10">
              {{ inpatientPriceMatch }}
              <span v-if="inpatientPriceMatch != 'NA'">%</span>
            </h2>
          </div>
        </div>
      </div>
      <div class="row" v-if="$role != 'user'">
        <div class="col-md-6"></div>
        <div class="col-md-6">
          <v-btn class="blue-buttons col-md-2" @click="updateContractRates"
            >Refresh</v-btn
          >
        </div>
      </div>
    </div>
    <div class="col-md-12 contract-form" v-if="itemtab == 'tab2'">
      <div class="col-md-12 p-4">
        <div>
          <h3>Review and Accept</h3>
        </div>
        <div class="row mt-5" v-if="display">
          <label for="" class="text-gray col-md-4" cols="4"
            >Contract Name</label
          >
          <label
            for=""
            class="text-gray col-md-4"
            cols="4"
            v-html="
              getContract.providerType != 'other' ? 'Min Rates' : 'Outpatient'
            "
          ></label>
          <label
            for=""
            v-if="$role != 'user'"
            class="text-gray col-md-4"
            cols="4"
            >Actions</label
          >
        </div>
        <hr />
        <div class="row mt-4">
          <label v-if="!display" for="" class="text-gray col-md-4" cols="4"
            >Contract Name</label
          >
          <a
            class="col-md-4"
            cols="4"
            @click="viewContract(customName)"
            v-html="
              this.customName
                ? this.customName
                : 'Please Insert the Contract Name'
            "
          ></a>
          <label v-if="!display" for="" class="text-gray col-md-4"
            >Min Rates</label
          >
          <label
            for=""
            class="col-md-4"
            v-html="
              getContract.providerType != 'other'
                ? inpatient + '% /' + outpatient + '%'
                : outpatient + '%'
            "
            >{{ inpatient }} % / {{ outpatient }} %</label
          >
          <label
            v-if="!display && $role != 'user'"
            for=""
            class="text-gray col-md-4"
            >Actions</label
          >
          <label v-if="$role != 'user'" for="" class="col-md-4">
            <svg
              class="pointer"
              @click="clearContract"
              width="39"
              height="39"
              viewBox="0 0 39 39"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.5 19.5H26.5"
                stroke="#E40A0A"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M32.3333 2H6.66667C4.08934 2 2 4.08934 2 6.66667V32.3333C2 34.9107 4.08934 37 6.66667 37H32.3333C34.9107 37 37 34.9107 37 32.3333V6.66667C37 4.08934 34.9107 2 32.3333 2Z"
                stroke="#E40A0A"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </label>
        </div>
        <div class="row mt-15 justify-end">
          <div class="col-md-4 jumbotron mb-10 font" v-if="$role != 'user'">
            Note: When you proceed to contract, you will be committed to those
            rates for 12 months. You can lower your rates during that time, but
            not increase them.
          </div>
        </div>
        <v-row v-if="checkboxError">
          <v-alert dense outlined type="error">
            {{ checkboxError }}
          </v-alert>
        </v-row>
        <div class="row" v-if="$role != 'user'">
          <v-row class="ml-2">
            <v-checkbox v-model.trim="checkbox"></v-checkbox>
            <v-btn class="blue-buttons mt-3" @click="updateContract"
              >Update Contract
            </v-btn>
          </v-row>
          <v-row class="font text-gray">
            <v-col>
              By checking the box and clicking "ACCEPT & CONTRACT," you
              represent that: (1) you have read and agree with the contract
              listed above, and (2) you have the right, authority and capacity
              to enter into this agreement and commit to the contract rates that
              you have entered, on your own behalf and on behalf of any
              organization(s) for which the agreement applies.
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <div class="col-md-12 row justify-space-between mt-4 no-padding">
      <div class="col-md-6 no-padding" v-if="itemtab != 'tab0'">
        <v-btn class="blue-buttons col-md-2 mb-3" @click="backTab(itemtab)">
          Back
        </v-btn>
      </div>

      <div
        :class="
          itemtab != 'tab0'
            ? 'col-md-6 text-end no-padding'
            : ' no-padding col-md-12 text-end'
        "
      >
        <v-btn
          class="mr-2 mb-3 blue-buttons"
          :class="itemtab != 'tab0' ? 'col-md-2' : 'col-md-1'"
          @click="
            $router.push(`/provider-advisor/providers/${routeParams}/contracts`)
          "
        >
          Cancel
        </v-btn>
        <v-btn
          v-if="itemtab != 'tab2'"
          :class="itemtab != 'tab0' ? 'col-md-2' : 'col-md-1'"
          class="blue-buttons mb-3"
          @click="nextTab(itemtab)"
        >
          Next
        </v-btn>
      </div>
    </div>
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
    <contractPdf
      v-on:confirm="confirmClose"
      ref="contract_pdf"
      :id="getContract._id"
      :name="customName"
      :inpatient="inpatient"
      :outpatient="outpatient"
    ></contractPdf>
  </v-row>
</template>
<script>
import api from "../../../../../services/api.js";
import removeDialog from "../../../../TablesTemplate/RemoveDialog.vue";
import successModal from "../../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../../TablesTemplate/ErrorDialog.vue";
import contractPdf from "../../../../TablesTemplate/ContractPDF.vue";

export default {
  components: {
    successModal,
    removeDialog,
    errorModal,
    contractPdf,
  },
  data() {
    return {
      display: true,
      windowWidth: window.innerWidth,
      inpatient: "",
      outpatient: "",
      serviceAgreement: "",
      priceMatch: "",
      contractName: "",
      checkboxError: "",
      checkbox: "",
      customName: "",
      singleConDialog: false,
      outpatientPriceMatch: "",
      inpatientPriceMatch: "",
      tab: null,
      oldInpa: "",
      oldOut: "",
      href: "#tab-0",
      updateRates: false,
      select: {
        text: "Fee for Service - Provider Services Agreement",
        value: "providerService",
      },
      items: [
        {
          text: "Form and Custom Name",
          value: 0,
        },
        {
          text: "Rates",
          value: 1,
        },
        {
          text: "Acceptance",
          value: 2,
        },
      ],
    };
  },
  computed: {
    getClient() {
      return this.$store.getters.getClientGetter;
    },
    routeParams() {
      return this.$route.params.name;
    },
    getContract() {
      return this.$store.getters.getSingleContractInEmployerAdvisor;
    },
    itemtab() {
      return this.tab;
    },
    showRatesTab() {
      return this.$store.getters.showRatesView;
    },
  },
  created() {
    if (this.showRatesTab == "rates") {
      this.tab = "tab1";
    }
    if (this.windowWidth < 1000) {
      this.display = false;
    } else {
      this.display = true;
    }
    if (this.getContract) {
      this.inpatient = this.getContract.providerRates.inpatient;
      this.outpatient = this.getContract.providerRates.outpatient;
      this.priceMatch = this.getContract.priceMatch;
      this.customName = this.getContract.name;
      this.serviceAgreement = this.getContract.form;
      this.oldInpa = this.getContract.providerRates.inpatient;
      this.oldOut = this.getContract.providerRates.outpatient;
    }
    this.getOutpatientPriceMatch();
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    showCont() {
      this.singleConDialog = true;
      this.$refs.contract_pdf.openPopUp();
    },
    confirmClose() {
      this.singleConDialog = false;
      this.$refs.contract_pdf.closePopUp();
    },
    viewContract(name) {
      this.singleConDialog = true;
      this.$refs.contract_pdf.openPopUp();
    },
    clearContract() {
      this.customName = "";
      this.inpatient = "";
      this.outpatient = "";
      this.tab = "tab0";
    },
    getOutpatientPriceMatch() {
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.providerAdvisor;
      let provider = JSON.parse(localStorage.getItem("provider"));
      api()
        .get(
          `/advisors/${advisorId}/providers/${
            provider._id
          }/employersPriceMatch?inpatient=${Number(
            this.inpatient
          )}&outpatient=${Number(this.outpatient)}`
        )
        .then((res) => {
          this.outpatientPriceMatch = res.data.priceMatch.outpatient;
          this.inpatientPriceMatch = res.data.priceMatch.inpatient;
        });
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
      if (!this.updateRates) {
        this.$router.push(
          `/provider-advisor/providers/${this.routeParams}/contracts`
        );
      }
    },
    backTab(tab) {
      if (tab == "tab1") {
        this.tab = "tab0";
      } else if (tab == "tab2") {
        this.tab = "tab1";
      }
    },
    nextTab(tab) {
      if (tab == "tab0") {
        this.tab = "tab1";
      } else if (tab == "tab1") {
        this.tab = "tab2";
      }
    },
    createModal() {
      this.$router.push(
        `/provider-advisor/providers/${this.routeParams}/contracts`
      );
    },
    updateContractRates() {
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.providerAdvisor;
      let provider = JSON.parse(localStorage.getItem("provider"));
      let data = {
        providerRates: {
          inpatient: this.inpatient,
          outpatient: this.outpatient,
        },
      };
      api()
        .put(`/advisors/${advisorId}/providers/${provider._id}/contract`, data)
        .catch((err) => {
          if (err) {
            this.lowRatesError = true;
          }

          this.$refs.error_modal.openPopUp(err.response.data.message);
          this.inpatient = this.oldInpa;
          this.outpatient = this.oldOut;
        })
        .then((res) => {
          if (res) {
            this.updateRates = true;
            let text = "Contact rates updated successfully";
            this.$refs.success_modal.openPopUp(text);
          }
        });
    },
    updateContract() {
      this.updateRates = false;
      if (this.outpatient && this.serviceAgreement) {
        if (this.checkbox) {
          let user = JSON.parse(localStorage.getItem("user"));
          let advisorId = user.groupPortal[0].organization.providerAdvisor;
          let provider = JSON.parse(localStorage.getItem("provider"));
          let data = {
            name: this.customName,
            form: this.serviceAgreement,
            providerRates: {
              inpatient: this.inpatient,
              outpatient: this.outpatient,
            },
          };
          api()
            .put(
              `/advisors/${advisorId}/providers/${provider._id}/contract`,
              data
            )
            .catch((err) => {
              if (err) {
                this.lowRatesError = true;
              }
              this.$refs.error_modal.openPopUp(err.response.data.message);
              this.inpatient = this.oldInpa;
              this.outpatient = this.oldOut;
            })
            .then((res) => {
              if (res) {
                let text = "Contract updated successfully";
                this.$refs.success_modal.openPopUp(text);
                this.checkboxError = "";
              }
            });
        } else {
          this.checkboxError = "Please check the box first ";
        }
      } else {
        this.$refs.error_modal.openPopUp(
          "Contract From and Contract Rates Are Required"
        );
      }
    },
    confirmRemove() {
      this.$refs.error_modal.closePopUp();
    },
    onResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 1000) {
        this.display = false;
      } else {
        this.display = true;
      }
    },
  },
};
</script>

<style>
.contract-form {
  min-height: 35rem;
  padding: 20px;
  border-bottom: 1px solid rgb(231, 231, 231);
  border-right: 1px solid rgb(231, 231, 231);
  border-left: 1px solid rgb(231, 231, 231);
  border-radius: 6px;
}
</style>
