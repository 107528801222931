<template>
 <v-row class="add-client-form no-padding">
  <div class="col-md-12 form-box">
    <div class="row">
        <div class="col-md-12">
            <h3 class="form-title  text-underline" ></h3>
        </div>
    </div>
    <form class="row">
            <div class="col-md-6">
               <label class="font text-gray" for="">Provider Name</label>
                <v-text-field
                required
                solo
                ></v-text-field>
           </div>
           <div class="col-md-6">
               <label class="font text-gray" for="">Provider Type <i class="fas fa-question-circle  yellow-icons"></i></label>
                <v-text-field
                required
                solo
                ></v-text-field>
           </div>
          <div class="col-md-6">
              <label class="font text-gray" for="">NPI <i class="fas fa-question-circle  yellow-icons"></i></label>
                <v-text-field
                required
                solo
                ></v-text-field>
        </div>
        <div class="col-md-6">
            <label class="font text-gray" for="">Speciality<i class="fas fa-question-circle  yellow-icons"></i></label>
                <v-text-field
                required
                solo
                ></v-text-field>
        </div>
    
        <div class="col-md-6">
                <label class="font text-gray" for="">City</label>
            <v-text-field
            required
            solo
            ></v-text-field>
        </div>
        <div class="col-md-6 align-md-cente">
            <div class="row  justify-center">
                <div class="col-md-6 aling-center text-center justify-center">
                    <h2 class="text-gray">Avg.Est. Charges  <i class="fas fa-question-circle  yellow-icons"></i></h2>
                    <h1>280%</h1>
                </div>
                 <div class="col-md-6 aling-center text-center justify-center">
                    <h2 class="text-gray">Est. Savings  <i class="fas fa-question-circle  yellow-icons"></i></h2>
                    <h1>35%</h1>
                </div>
            </div>
        </div>
    
        <div class="col-md-12 no-padding">
            <div class="col-md-6">
                <label class="font text-gray" for="">State</label>
                <v-text-field
                required
                solo
                ></v-text-field>
            </div>
        </div>
        <div class="col-md-12 d-flex align-end flex-column">
            <div>
                <v-btn class="blue-buttons mr-6 mt-2" @click="$route.push(`/provider/networks`)">
                 Delete Provider
                </v-btn>
                <v-btn class="blue-buttons mt-2" @click="$route.push(`/provider/networks`)">
                  Save / Update
                </v-btn>
            </div>
        </div>
      </form>
    </div>
 </v-row>
</template>
<script>
export default{
    computed:{
      getSingleNetwork(){
          return this.$store.getters.getSingleNetwork
      },
        routeParams(){
            let parsed =''
            if(this.$route.params.name){
                parsed = this.$route.params.name.replace(/ /g, '%20')
            }
            return parsed
        }
    }
}
</script>
