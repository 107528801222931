import ProviderTemplate from '../views/Provider.vue'

import Home from '../components/Sections/Provider/Home/Home.vue'

import Employers from '../components/Sections/Provider/Employers/EmployersTable.vue'
import AddEmployer from '../components/Sections/Provider/Employers/AddEmployer.vue'
import InviteEmployer from '../components/Sections/Provider/Employers/InviteEmployers.vue'
import SingleEmployer from '../components/Sections/Provider/Employers/SingleEmployer.vue'

import Prior from '../components/Sections/Provider/PriorAuths/PriorTable.vue'
import PriorRequest from '../components/Sections/Provider/PriorAuths/PriorRequest.vue'
import PriorDetermination from '../components/Sections/Provider/PriorAuths/PriorDetermination.vue'
import PriorDetails from '../components/Sections/Provider/PriorAuths/Details.vue'

import Members from '../components/Sections/Provider/Members/Members.vue'
import AdvancedSearch from '../components/Sections/Provider/Members/AdvancedSearch.vue'
import Member from '../components/Sections/Provider/Members/Member.vue'
import MemberPrior from '../components/Sections/Provider/Members/Members.vue'

// PROVIDERS
import ProvidersProviders from '../components/Sections/Provider/Providers/ProvidersTable.vue'
import ProvidersAddProviders from '../components/Sections/Provider/Providers/AddProviders.vue'
import ProvidersInviteProviders from '../components/Sections/Provider/Providers/InviteProvider.vue'
import ProvidersProvider from '../components/Sections/Provider/Providers/ProviderCrudForm.vue'
import ProvidersProviderResult from '../components/Sections/Provider/Providers/ResultTable.vue'
import AddProviderManually from '../components/Sections/ProviderAdvisor/Providers/AddManually.vue'
import ProvidersSingleNpi from '../components/Sections/Provider/Providers/SingleNpi.vue'

// END

import Advisors from '../components/Sections/Provider/Advisors/AdvisorsTable.vue'
import AddAdvisors from '../components/Sections/Provider/Advisors/AddAdvisor.vue'
import Advisor from '../components/Sections/Provider/Advisors/Advisor.vue'
import AdvisorSearchResult from '../components/Sections/Provider/Advisors/AdvisorSearchResult.vue'
import InviteAdvisor from '../components/Sections/Provider/Advisors/InviteAdvisor.vue'

import Bundle from '../components/Sections/Provider/Bundles/Bundles.vue'
import Builder from '../components/Sections/Provider/Bundles/Builder.vue'
import SingleBundle from '../components/Sections/Provider/Bundles/SingleBundle.vue'


import Networks from '../components/Sections/Provider/Networks/NetworksTable.vue'
import Network from '../components/Sections/Provider/Networks/SingleNetwork.vue'
import NetworkBuilder from '../components/Sections/Provider/Networks/NetworkBuilder.vue'

import Contracts from '../components/Sections/Provider/Contract/ContractsTable.vue'
import Contract from '../components/Sections/Provider/Contract/Contract.vue'
import AddContract from '../components/Sections/Provider/Contract/AddContract.vue'
import ContractResult from '../components/Sections/Provider/Contract/ContractResult.vue'
import RequestContract from '../components/Sections/Provider/Contract/ContractsTable.vue'
import ContractType from '../components/Sections/Provider/Contract/ContractType.vue'

import Settings from '../components/Sections/Provider/Settings/Settings.vue'
import Account from '../components/Sections/Provider/Settings/AccountForm.vue'
import AddUser from '../components/Sections/Provider/Settings/AddUser.vue'
import User from '../components/Sections/Provider/Settings/UsersCrud.vue'
import InviteUser from '../components/Sections/Provider/Settings/InviteUser'
import MyProfile from "../components/TablesTemplate/MyProfile.vue"
function routeGuard(to, from, next) {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user.type != 'provider' || !$cookies.get('token')){
      next('/login');
    }
      next();
}

let role = "user"
if(localStorage.getItem('user')){
    let user = JSON.parse(localStorage.getItem('user'));
    role = user.role;
  }else{
   role = "no-login"
  }
function routeGuardRole(to, from, next){
    if(role == 'user'){
     next('/login');
    }
    next();
 }
const router = [
 
    {
        path:'/provider/home',
        component:ProviderTemplate,
        name:'Home',
        beforeEnter: routeGuard,
    },
    

    {
        path:'/provider',
        component:ProviderTemplate,
        name:'Home',
        beforeEnter: routeGuard,
        children:
        [
            {
                path:'home',
                component:Home,
                name: 'Home',
            },
            {
                path:'employers',
                component:Employers,
                name: 'Employers',
                children:[
                    {
                        path:'add-new',
                        name:'Add New Employer',
                        component:AddEmployer,
                        beforeEnter:routeGuardRole
                    },
                    {
                        path:'invite-employer',
                        name:'Invite New Employer',
                        component:InviteEmployer,
                        beforeEnter:routeGuardRole
                    },
                    {
                        path:'employer',
                        name:'Employer',
                        component:SingleEmployer
                    }

                ]
            },
            {
                path:'profile',
                name:'My Profile',
                component:MyProfile
            },
            // {
            // path:'members',
            // component:Members,
            // name:'Members',
            // children:[
            //     {
            //         path:'member',
            //         component:Member,
            //         name:'Member'
            //     },
            //     {
            //         path : 'advanced-search',
            //         component:AdvancedSearch,
            //         name:'Search Result'
            //     }
            //     ]
            // },
            // {
            // path:'prior-auth',
            // component:Prior,
            // name:'Prior Authorizations',
            // children:[
            //     {
            //     path:'determination',
            //     component:PriorDetermination,
            //     name:'Prior Authorization Determination'
            //     },
            //     {
            //         path:'details',
            //         component:PriorDetails,
            //         name:'Prior Authorization Details'
            //     },

            //     {
            //     path:'new-request',
            //     component:PriorRequest,
            //     name:'New Prior Authorization Request'
            //     },
                
            //     ]
            // },
            {
                path:'providers',
                name:'Providers',
                component:ProvidersProviders,
                children:[
                    
                    {
                        path:'invite-providers',
                        name:"Invite Providers",
                        component:ProvidersInviteProviders,
                        beforeEnter:routeGuardRole
                    },
                    {
                        path:'add-manually',
                        name:"Add New Provider",
                        component:AddProviderManually,
                        beforeEnter:routeGuardRole
                    },
                    {
                        path:'provider',
                        name:"Provider",
                        component:ProvidersProvider
                    },
                    {
                        path:'invite-providers',
                        name:"Invite Providers",
                        component:ProvidersInviteProviders,
                        beforeEnter:routeGuardRole
                    },
                    {
                        path:'add-providers',
                        name:"Add New Provider",
                        component:ProvidersAddProviders
                    },
                    {
                        path:'result',
                        name:"Search Result",
                        component:ProvidersProviderResult,
                        children:[
                            {
                                path:'npi',
                                name:'Npi Detail',
                                component:ProvidersSingleNpi

                            }
                        ]

                    }
                ]
            },
            // {
            //     path:'affiliates',
            //     name:'Affiliates',
            //     component:ProvidersProviders,
            //     children:[
                    
            //         {
            //             path:'invite-providers',
            //             name:"Invite Providers",
            //             component:ProvidersInviteProviders
            //         },
            //         {
            //             path:'add-manually',
            //             name:"Add New Provider",
            //             component:AddProviderManually
            //         },
            //         {
            //             path:'provider',
            //             name:"Provider",
            //             component:ProvidersProvider
            //         },
            //         {
            //             path:'invite-providers',
            //             name:"Invite Providers",
            //             component:ProvidersInviteProviders
            //         },
            //         {
            //             path:'add-providers',
            //             name:"Add New Provider",
            //             component:ProvidersAddProviders
            //         },
            //         {
            //             path:'result',
            //             name:"Search Result",
            //             component:ProvidersProviderResult,
            //             children:[
            //                 {
            //                     path:'npi',
            //                     name:'Npi Detail',
            //                     component:ProvidersSingleNpi

            //                 }
            //             ]

            //         }
            //     ]
            // },
            //  {
            //     path:'hospital-based',
            //     name:'Hospital Based Providers',
            //     component:ProvidersProviders,
            //     children:[
                    
            //         {
            //             path:'invite-providers',
            //             name:"Invite Providers",
            //             component:ProvidersInviteProviders
            //         },
            //         {
            //             path:'add-manually',
            //             name:"Add New Provider",
            //             component:AddProviderManually
            //         },
            //         {
            //             path:'provider',
            //             name:"Provider",
            //             component:ProvidersProvider
            //         },
            //         {
            //             path:'invite-providers',
            //             name:"Invite Providers",
            //             component:ProvidersInviteProviders
            //         },
            //         {
            //             path:'add-providers',
            //             name:"Add New Provider",
            //             component:ProvidersAddProviders
            //         },
            //         {
            //             path:'result',
            //             name:"Search Result",
            //             component:ProvidersProviderResult,
            //             children:[
            //                 {
            //                     path:'npi',
            //                     name:'Npi Detail',
            //                     component:ProvidersSingleNpi

            //                 }
            //             ]

            //         }
            //     ]
            // },
            {
                path:'advisors',
                name:'Partners',
                component:Advisors,
                children:[
                    {
                        path:'add-new',
                        name:'Add New Partner',
                        component:AddAdvisors,
                        children:[
                            {
                                path:'result',
                                name:'Search Result',
                                component:AdvisorSearchResult
                            },
                        ]
                    },
                   
                    {
                        path:'invite-advisor',
                        name:'Invite Partner',
                        beforeEnter:routeGuardRole,
                        component:InviteAdvisor
                    },
                    {
                        path:'advisor',
                        name:'Partner Detail',
                        component:Advisor
                    },
                    
                ]
            },
            // {
            //     path:'bundles',
            //     component:Bundle,
            //     name:'Bundle',
            //     children:[
            //         {
            //             path:'builder',
            //             name:'Bundle Builder',
            //             component:Builder
            //         },
            //         {
            //             path:'bundle',
            //             name:'Details',
            //             component: SingleBundle
            //         }
            //     ]
            // },
            //    {
            //     path:'networks',
            //     component:Networks,
            //     name:'Networks',
            //     children:[
            //         {
            //             path:'builder',
            //             name:'Network Builder',
            //             component:NetworkBuilder
            //         },
            //         {
            //             path:'network',
            //             name:'Bundle Builder',
            //             component:Network
            //         }
            //     ]
            //     },
                {
                path:'contracts',
                component:Contracts,
                name:'Contracts',
                children:[
                    {
                        path:'contract',
                        component:Contract,
                        name:'Contract',
                    },
                    {
                        path:':type',
                        component:ContractType,
                        name:'Contract Type',
                        children:[
                            {
                                path:'add-new',
                                beforeEnter:routeGuardRole,
                                component:AddContract,
                                name:'Add New Contract',
                            },
                        ]
                    },
                    
                    {
                        path:'request',
                        component:RequestContract,
                        name:'Request Contract',
                        beforeEnter:routeGuardRole
                    },
                   
                    
                ]

                },
                {
                path: "settings",
                component:Settings,
                name:'Settings',
                children:
                [
                    {
                        path:'account',
                        name:'Account',
                        component:Account
                    },
                    {
                        path: "users",
                        name:'Users',
                        component: Settings,
                        children:[
                                {
                                    path:'user',
                                    name:'User',
                                    conponent:User,
                                },
                                {
                                    path:'add-new',
                                    beforeEnter:routeGuardRole,
                                    name:'Add New User',
                                    component: AddUser
                                },
                                {
                                    path:'invite-user',
                                    beforeEnter:routeGuardRole,
                                    name:'Invite User',
                                    component: InviteUser
                                },
                        ]
                    },
                ]     
            },
        ]

    },

        
  
]

export default router;