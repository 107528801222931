<template>
  <div class="">
    <div class="row-height">
      <div class="blue-underline">
        <div class="col-md-12">
          <h3 class="text-gray">Vendor Invoice Details</h3>
        </div>
        <div class="col-md-3 col-lg-3 d-flex">
          <h4
            class="mr-3 pointer"
            :class="invoicesPart ? 'light-blue-text' : 'text-gray'"
            @click="invoiceTab()"
          >
            Inovices
          </h4>
          <h4
            class="text-gray ml-1 pointer"
            :class="membersPart ? 'light-blue-text' : 'text-gray'"
            @click="memberTab()"
          >
            Member Details
          </h4>
        </div>
      </div>
      <div v-if="invoicesPart" class="row mt-5">
        <div class="col-10 col-md-6">
          <div class="text-gray  mt-3" style="align-items: baseline;">
            <div class="d-md-flex">
              <div class="col-md-4 text-md-right">
                <span class="text-gray font-weight-bold ml-4">INVOICE NUMBER:</span>
              </div>
              <div class="col-md-6">
                <v-text-field class="input-control" :value="invoice.invoiceNo" disabled light dense outlined></v-text-field>
              </div>
            </div>
            <div class="d-md-flex">
              <div class="col-md-4 text-md-right">
                <span class="text-gray font-weight-bold ml-4">CLIENT:</span>
              </div>
              <div class="col-md-6">
                <v-text-field class="input-control" :value="invoice.client" disabled light dense outlined></v-text-field>
              </div>
            </div>
            <div class="d-md-flex">
              <div class="col-md-4 text-md-right">
                <span class="text-gray font-weight-bold ml-4">VENDOR:</span>
              </div>
              <div class="col-md-6">
                <v-text-field class="input-control" :value="invoice.vendor" disabled light dense outlined></v-text-field>
              </div>
            </div>
            <div class="d-md-flex">
              <div class="col-md-4 text-md-right">
                <span class="text-gray font-weight-bold ml-4">INVOICE DATE:</span>
              </div>
              <div class="col-md-6">
                <v-text-field class="input-control" :value="invoice.date" disabled light dense outlined ></v-text-field>
              </div>
            </div>
            <div class="d-md-flex">
              <div class="col-md-4 text-md-right">
                <span class="text-gray font-weight-bold ml-4">TOTAL CHARGE (USD):</span>
              </div>
              <div class="col-md-6">
                <v-text-field class="input-control" :value="invoice.total" disabled light dense outlined></v-text-field>
              </div>
            </div>
            <div class="d-md-flex">
              <div class="col-md-4 text-md-right">
                <span class="text-gray font-weight-bold ml-4">STATUS:</span>
              </div>
              <div class="col-md-6">
                <v-text-field class="input-control" :value="invoice.status" disabled light dense outlined></v-text-field>
              </div>
            </div>
          </div>

        </div>
        <div class="col-12 col-md-6">
          <div class="col-md-12 col-12 pt-3">
         
            <v-card class="pl-3 pr-3 pt-3" id="generate-pdf">
              <div
                class="col-xl-12 col-lg-12 col-md-12 pb-0 pt-3 d-md-flex"
                ref="htmlContent"
              >
                <div class="col-md-6 pt-2">
                  <span>
                    <h1 class="font-weight-bold">Invoice</h1>
                  </span>
                </div>
                <div class="col-md-6 pt-2">
                  <span>
                  
                    <h1 class="font-weight-bold">
                      No: {{ invoice.invoiceNo }}
                    </h1>
                  </span>
                  <br />
                  <span class="font-weight-bold"> Issued </span>
                  <br />
                  <span class="text-gray">
                    {{ invoice.date | moment('YYYY/MM/DD')}}
                  </span>
                </div>
              </div>
              <div class="col-xl-12 col-lg-12 col-md-12 pb-0 pt-3 d-md-flex">
                <div class="col-md-6 pt-2" >
                  <span class="font-weight-bold"> From </span>
                  <br />
                  <span>
                    {{ invoice.from.companyName }}
                  </span>
                  <br />
                  <span>
                    {{ invoice.from.address }}
                  </span>
                  <br />
                  <span>
                    {{ invoice.from.city }}, {{ invoice.from.state }}
                  </span>
                  <br />
                  <span>
                    {{ invoice.from.zipCode }}
                  </span>
                  <br />
                </div>
                <div class="col-md-6 pt-2">
                  <span class="font-weight-bold"> To </span>
                  <br />
                  <span> {{ invoice.to.companyName }} </span>
                  <br />
                  <span> {{ invoice.to.address }} </span>
                  <br />
                  <span> {{ invoice.to.city }},   {{ invoice.to.state }}
                  
                   </span>
                   <br/>
                   <span>
                    {{ invoice.to.zipCode }}
                   </span>
                </div>
              </div>

              <div class="col-md-12 col-12">
                <v-simple-table fixed-header height="150px">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-start">#</th>
                        <th class="text-start">Item</th>
                        <th class="text-end">Qty</th>
                        <th class="text-end">Amount</th>
                      </tr>
                    </thead>
                    <tbody class="mt-2">
                      <tr
                        class="border-top--2"
                        v-for="(item, index) in invoice.items"
                        :key="index"
                      >
                        <td class="border-top--2">
                          {{ index + 1 }}
                        </td>
                        <td  class="border-top--2">
                          {{ item.description }}
                        </td>
                        <td class="text-end border-top--2">1</td>
                        <td class="text-end border-top--2">$ {{ item.amount }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
              <div class="col-xl-12 col-lg-12 col-md-12 pb-0 pt-3 d-flex">
                <div class="col-md-4 pt-0"></div>
                <div class="col-md-8 pt-2 d-flex justify-end border-top border-top--2">
                  <div class="col-md-5">
                    <span class="font-weight-bold"> Total </span>
                  </div>
                  <div class="col-md-3" align="end">
    <span class="font-weight-bold">
      ${{ invoice.total }}
    </span>
                  </div>
                </div>
              </div>
              <div class="col-md-12 d-md-flex pb-0 pt-4">
                <div class="col-md-9 mobile-font">
                  <img
                    id="logo-img"
                    :src="'/newapalylogoblue.png'"
                    class="apaly-pic pointer"
                  />
                </div>
                <div class="col-md-3 justify-logo text-right pt-8">
                  <a class="text-underline" @click="generatePdf()">
                    Download</a
                  >
                </div>
              </div>

              <v-divider horizontal></v-divider>
              <div class="col-md-12 d-md-flex pt-0">
                <div class="col-md-9 mobile-font">
                  <p class="text-gray"></p>
                </div>
                <div class="col-md-3" align="end"></div>
              </div>
            </v-card>
          </div>
        </div>
      </div>
   <div v-if="membersPart">
   <VendorMembers/>  
  </div>
    </div>
  </div>
</template>
<script>
import Header from "../../../Layout/HeaderPage.vue";
import VendorMembers from '../Billing/VendorMembers.vue'
import api from "../../../../../../services/api";
export default {
  data() {
    return {
      showDetails: false,
      dialog: false,
      invoice: [],
      invoiceList: [],
      filterArray: [],
      singleItem: null,
      filterItems: false,
      load: false,
      invoicesPart: true,
      membersPart: false,
    };
  },
  components: {
    Header,
    VendorMembers
  },
  methods: {
    memberTab(){
            this.invoicesPart = false;
            this.membersPart = true;
        },
        invoiceTab(){
            this.invoicesPart = true;
            this.membersPart = false;
        },
  },
  created() {
    this.load = true;
    api().get(`/tpas/clients/${this.$route.params.clientId}/invoice/${this.$route.params.invoiceId}`)
        .then((res) => {
          if (res && res.data) {
            this.invoice = res.data
            this.load = false;
            this.invoice.date = res.data.date.substring(0,10)
            this.invoice.status = res.data.status.charAt(0).toUpperCase() + res.data.status.slice(1);
          }
        }).catch((err) => {
      this.load = false;
    });
  },
};
</script>
<style>
.border-blue-bottom {
  border-bottom: 6px solid #4072b7;
  margin-left: -1px;
  margin-right: -2px;
  margin-top: -1px;
}

.tr-custom-height > .v-data-table__wrapper > table > tbody > tr > td {
  height: 75px;
}
</style>