<template>
  <v-row class="add-client-form no-padding account">
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <errorModal ref="error_modal"
     v-on:confirm="closeErrorModal">
    </errorModal>

    <div class="col-md-12 pl-0 pr-0">
      <v-form v-model.trim="valid" ref="form" class="row" v-if="this.display">
        <div class="col-md-12 pb-0 pr-0 pl-0">
          <div class="col-md-12 d-md-flex pl-0 pr-0">
            <div class="col-md-3">
              <h3>Company Details</h3>
              <p>Enter some basic company information</p>
            </div>
            <div class="col-md-12">
              <div class="col-md-12 d-md-flex pt-0 pb-0">
                <div class="col-md-6  pt-0 pb-0">
                  <label class="font text-gray" for="">Company Name</label>
                  <v-text-field
                    required
                    :rules="requiredRules"
                    v-model.trim="account.companyName"
                    solo
                  ></v-text-field>
                </div>
                <div class="col-md-3  pt-0 pb-0">
                  <label class="font text-gray" for="">Tax ID</label>
                  <v-text-field
                    required
                    v-model.trim="account.taxId"
                    @keyup="showSlash($event)"
                    solo
                    placeholder="00-0000000"
                    :rules="taxIdRules"
                    counter="10"
                    maxlength="10"
                  ></v-text-field>
                </div>
              </div>
              <div class="col-md-12 d-md-flex pt-0 pb-0">
                <div class="col-md-6  pt-0 pb-0">
                  <label class="" for="">Website</label>
                  <v-text-field
                    :rules="websiteRules"
                    v-model.trim="account.website"
                    solo
                  ></v-text-field>
                </div>
                <!-- <div class="col-md-3  pt-0 pb-0">
                  <label class="font text-gray" for="">Vendor Type</label>
                  <v-select
                        :items="types"
                        item-value="value"
                        hide-details
                        v-model.trim="type"
                  solo>

                  </v-select>
                </div> -->
                <div class="col-md-3  pt-0 pb-0">
                  <label class="font text-gray" for="">Category</label>
                  <v-select
                  :items="categories"
                  item-text="name"
                  item-value="_id"
                   v-model.trim="account.category" 
                   solo>
                  </v-select>
                </div>
              </div>
              <div class="col-md-12 d-md-flex pt-0 pb-0">
                <div class="col-md-6  pt-0 pb-0">
                  <label class="" for="">Description - This will be your description in the marketplace</label>
                  <v-textarea
                    required
                    rows="3"
                    solo
                    v-model.trim="account.description"
                  ></v-textarea>
                </div>
                <!-- <div class="col-md-3  pt-0 pb-0">
                  <label class="font text-gray" for="">Category</label>
                  <v-select solo></v-select>
                </div> -->
              </div>
              <!-- <div class="col-md-12 d-md-flex pt-0 pb-0">
                <div class="col-md-6  pt-0 pb-0">
                  <label class="" for="">Definition of Service</label>
                  <v-text-field
                    required
                    solo
                    v-model.trim="definition"
                  ></v-text-field>
                </div>
              </div> -->
              <div class="col-md-12 d-md-flex pt-0 pb-0">
                <div>
                  <img
                  id="logo-img"
                  :src="image ? image : '/no_logo_uploaded.png'" />
                </div>

                <div class="col-md-4 pt-2 pb-0">
                  <label class="font text-gray" for=""></label>
                  <v-file-input
                  @change="Preview_image"
                  v-model.trim="dataComponents.image"
                  solo
                  truncate-length="38"
                  ref="fileInput"
                ></v-file-input>
                </div>
                <div class="col-md-1  pt-5 pb-0">
                    <a @click="activateFileInput" class="text-underline">Browse</a>
                  </div>

              </div>
            </div>
          </div>
   <v-divider horizontal class="mb-5"></v-divider>

   <div class="col-md-12 d-md-flex pr-0 pl-0">
    <div class="col-md-3">
      <h3>Address Details</h3>
      <p>Enter the address of your headquarters</p>
    </div>
    <div class="col-md-9">
        <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">HQ Address Line 1</label>
            <v-text-field
              required
              v-model.trim="account.address1"
              :rules="requiredRules"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">HQ Address Line 2</label>
            <v-text-field
            required
            v-model.trim="account.address2"
            solo></v-text-field>
          </div>
          <v-row class="no-padding">
            <div class="col-md-6 pt-0 pb-0">
              <label class="font text-gray" for="">City</label>
              <v-text-field
                required
                v-model.trim="account.city"
                solo
                :rules="requiredRules"
              ></v-text-field>
            </div>
            <div class="col-md-3 pt-0 pb-0">
              <label class="font text-gray" for="">State</label>
              <v-select
                :items="states"
                item-text="name"
                item-value="name"
                required
                v-model.trim="account.state"
                solo
                :rules="requiredRules"
              ></v-select>
            </div>
            <div class="col-md-3 pt-0 pb-0">
              <label class="font text-gray" for="">Zip</label>
              <v-text-field
                required
                type="number"
                v-model.trim="account.zipCode"
                solo
                :rules="zipRules"
              ></v-text-field>
            </div>
          </v-row>
    </div>
  </div>
          <v-divider horizontal class="mb-5"></v-divider>

          <div class="col-md-12 d-md-flex pl-0 pr-0">
            <div class="col-md-3">
              <h3>Contact Details</h3>
              <p>Enter details of your primary contact person.</p>
            </div>
            <div class="col-md-9">
              <div class="col-md-12 d-md-flex pt-0 pb-0">
                <div class="col-md-6 pt-0 pb-0">
                    <label class="font text-gray pb-0" for=""
                    >First Name
                  </label>
                  <v-text-field
                    required
                    v-model.trim="account.primaryContact.firstName"
                    solo
                    :rules="requiredRules"
                  ></v-text-field>
                </div>
                <div class="col-md-6 pt-0 pb-0">
                    <label class="font text-gray" for=""
                    >Primary Contact Last Name</label
                  >
                  <v-text-field
                    required
                    v-model.trim="account.primaryContact.lastName"
                    solo
                    :rules="requiredRules"
                  ></v-text-field>
                </div>
              </div>
              <div class="col-md-12 d-md-flex pt-0 pb-0">
                <div class="col-md-6 pt-0 pb-0">
                    <label class="font text-gray" for="">Title</label>
                    <v-text-field
                      v-model.trim="account.primaryContact.title"
                      solo
                    ></v-text-field>
                </div>
                <div class="col-md-6 pt-0 pb-0">
                    <label class="font text-gray" for=""> Email</label>
                    <v-text-field
                      required
                      :rules="emailRules"
                      v-model.trim="account.primaryContact.email"
                      solo
                    ></v-text-field>
                </div>
              </div>
              <div class="col-md-12 d-md-flex pt-0 pb-0">
                <div class="col-md-6 pt-0 pb-0">
                    <label class="font text-gray" for="">Phone</label>
                    <v-text-field
                      required
                      v-model.trim="formattedPhoneNumber"
                      maxlength="12"
                       @input="formatPhoneNumber"
                      :rules="phoneRules"
                      solo
                    ></v-text-field>
                </div>
              </div>

            </div>
          </div>
          <v-divider horizontal class="mb-5"></v-divider>

          <div class="col-md-12 d-md-flex pl-0 pr-0">
            <div class="col-md-3">
              <h3>Bank Details</h3>
              <p>Enter details of your bank account.</p>
            </div>
            <div class="col-md-9">
              <div class="col-md-12 d-md-flex pt-0 pb-0">
                <div class="col-md-6 pt-0 pb-0">
                    <label class="font text-gray pb-0" for=""
                    >Bank Name
                  </label>
                  <v-text-field
                    solo
                    v-model.trim="account.bank.name"
                  ></v-text-field>
                </div>
              </div>
              <div class="col-md-12 d-md-flex pt-0 pb-0">
                <div class="col-md-6 pt-0 pb-0">
                    <label class="font text-gray" for="">Inbound Routing Number</label>
                    <v-text-field
                      solo
                      v-model.trim="account.bank.roundNo"
                    ></v-text-field>
                </div>
                <div class="col-md-6 pt-0 pb-0">
                    <label class="font text-gray" for=""> Inbound Account Number</label>
                    <v-text-field
                      solo
                      v-model.trim="account.bank.accountNo"
                    ></v-text-field>
                </div>
              </div>
            </div>
          </div>
          <v-divider horizontal class="mb-5"></v-divider>
        </div>

      </v-form>
    </div>
    <div
      class="row text-end justify-end"
      style="margin-left: -11px"
    >
    <div class="col-md-8 col-12 d-md-flex pa-0 mt-6 justify-end">
      <a @click="generatePdf()" large class="text-underline mt-3 mr-14">
        View & Print Apaly Terms and Conditions
          </a>
        <v-btn class="cancel mt-2 mr-5 col-md-4"
         @click="$router.go(-1)"
         > Cancel </v-btn>

        <v-btn class="submit mt-2 col-md-4"
         color="#16407A"
         @click="update"
          > Save </v-btn>
      </div>
    </div>

  </v-row>
</template>
  <script>
import api from "../../../../services/api";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../TablesTemplate/ErrorDialog.vue";
import { mapActions } from 'vuex';
export default {
  components: { successModal, errorModal },
  data() {
    return {
      hasChanged: false,
      display:false,
      account: {},
      categories: [],
      requestDialog: false,
      preview: null,
      types: [
      {
        text: 'Vendor',
        value: 'vendor',
      },
      {
        text: 'DPC',
        value: 'dpc',
      },
      {
        text: 'TPA',
        value: 'tpa',
      },
      ],
      type: "",
      image: null,
      logo: "",
      definition: "",
      displayName: "",
      url: null,
      companyLogo: {
        name: "",
      },
      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
      states: [],
      discountRate: "",
      valid: false,
      zipRules: [
        (v) => (v && v.length == 5) || " Zip Code should be five digits long",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
      imageEvent: null,
      emailRules: [
        (v) => !!v || "This field is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      websiteRules: [
        (v) => !!v || "This field is required",
        (v) =>
        /^(https?:\/\/)?[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/.test(v) ||
          "Website must be valid",
      ],
      logoChange: false,
      profileChange: false,
      imageProfleEvent: null,
      formattedPhoneNumber:'',
      dataComponents: {
        image: {
          name: "",
        },
        profileImage: {
          name: "",
        },
      },
      taxIdRules: [
        (v) => !!v || "This field is required",
        (v) =>
          !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
          "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
      ],
    };
  },
  computed: {
    singleEmp() {
      let provider = "";
      if (localStorage.getItem("employer")) {
        provider = JSON.parse(localStorage.getItem("employer"));
      }

      return provider;
    },
    user() {
      let user = {
        firstName: "",
        lastName: "",
        email: "",
        id: "",
      };
      if (localStorage.getItem("user")) {
        user = JSON.parse(localStorage.getItem("user"));
      }
      return user;
    },
    customUserItems(){
      if(this.user.superAdmin){
        return [...this.userItems, {
          text: "Superadmin Dashboard", link: "/superadmin/vendors"
        }]
      }
      else return [...this.userItems]
    }
  },
  created() {
    this.getStates();
    this.getInfo();
    this.getCategories();
    setTimeout(() => {
                  this.hasChanged = false;
                }, 1000);
  },
  watch: {
    account:{
      handler: function(newValue) {
                this.hasChanged = true;
            },
            deep: true
    }
  },
  methods: {
    formatPhoneNumber(event) {
      this.account.primaryContact.phone = event
      this.formattedPhoneNumber = this.formatAsPhoneNumber(this.account.primaryContact.phone);
    },
    formatAsPhoneNumber(value) {
      value = value.replace(/\D/g, "");
      if (value.length > 10) {
        value = value.slice(0, 10);
      }
      value = value.slice(0, 3) + " " + value.slice(3, 6) + " " + value.slice(6);
      return value;
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
    },
    generatePdf() {
      window.open( `https://apalycdn.s3.us-west-1.amazonaws.com/public/uploads/admin/contracts/TermsAndConditions.pdf`, "_blank");
    },
    showSlash(event) {
      if ((event.target.value.length) == 2) {
        event.target.value += '-';
      }
      if (this.taxId.length == 10) {
        api()
          .get("auth/isValidTaxId?taxId=" + this.taxId)
          .catch((res) => {
            if (res) {
              this.taxIdRules = [res.response.data.message];
            }
          });
      } else {
        this.taxIdRules = [
          (v) => !!v || "This field is required",
          (v) =>
            !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
            "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
        ];
      }
    },
    activateFileInput() {
      this.$refs.fileInput.$refs.input.click();
    },
    getStates() {
      api()
        .get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },
    getCategories() {
      api()
        .get("/vendors/categories")
        .then((res) => {
          res.data.forEach((el) => {
              this.categories.push(el);
            
          });
        });
    },
    getInfo() {
      api()
        .get(`/vendors`)
        .then((res) => {
          if (res) {
            this.account = res.data;
            this.account.bank = res.data.bank ? res.data.bank : {};
            this.image=res.data.logo;
            this.dataComponents.image.name = res.data.logo ? res.data.logo : "";
            this.formattedPhoneNumber = res.data.primaryContact.phone ? res.data.primaryContact.phone : '';
            this.display = true
          }
        });
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
      this.$router.push('/vendors/home')
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    update() {
      this.$refs.form.validate();
      if (this.valid) {
       console.log('acc',this.account.bank)
        let data = {
          bank : {
             name: this.account.bank.name,
             accountNo: this.account.bank.accountNo,
             roundNo: this.account.bank.roundNo,
          },
          ...this.account,
          logo: this.dataComponents.image ? this.dataComponents.image.name : '',
          type: 'non-provider',
        };
        let formData = new FormData();
        formData.append("_method", "PUT");
        formData.append("image", this.imageEvent);
        console.log('data',data)
        api()
          .put(`/vendors`, data)
          .catch((err) => {
            if (err) {
                this.$refs.error_modal.openPopUp(err.response.data.message);
            }
          })
          .then((res) => {
            if (res) {
              if (this.logoChange) {
                if (this.imageEvent) {
                  let formData = new FormData();
                  formData.append("_method", "PUT");
                  formData.append("image", this.imageEvent);
                  api()
                    .put(`/vendors/logo`, formData)
                    .then((res) => {
                      document.getElementById("logo-img").click();
                      this.hasChanged = true
                    });
                } else {
                  api()
                    .delete(`/vendors/logo`)
                    .then((res) => {
                      document.getElementById("logo-img").click();
                    });
                }
              }
              if(this.hasChanged){
                this.$refs.success_modal.openPopUp("Account updated successfully");
              }
              else{
                this.$router.go(-1);
              }    
              this.logoChange = false;
              this.profileChange = false;
            }
          });
      }
    },
    activateProfileInput() {
      this.$refs.profileInput.$refs.input.click();
    },
    Preview_image(event) {
      this.logoChange = true;
      this.imageEvent = event;
    },
  },
};
</script>
<style scoped>
.submit {
    width: 130px;
    color: white;
  }
  .cancel {
    width: 130px;
  }
  #logo-img{
    max-width: 200px;
    height: 100px;
  }
@media screen and (max-width: 768px) {
  #logo-img{
    height: 65px;
  }
  fileInput{
   margin-top:40px ;
 }
}
</style>
