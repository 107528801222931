const state ={
    singleUserEmpInEmployerAdvisorGlobal:null
}
const getters = {
    getSingleUserEmpInEmployerAdvisorGlobal(){
        return state.singleUserEmpInEmployerAdvisorGlobal
    }
}
const mutations = {
    setSingleUserInEmployerAdvisorGlobal(state,user){
        state.singleUserEmpInEmployerAdvisorGlobal = user
    },
    
}

export default{
    state,
    getters,
    mutations,
}