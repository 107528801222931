<template>
  <div>
    <Header :title="'Add New Client'"></Header>
    <div class="col-md-12">
      <div class="col-md-12">
        <v-form class="row gray-border-form" ref="form" v-model="valid">
          <div class="col-md-4">
            <div class="col-md-12">
              <div class="col-md-12 pb-3 text-underline"> 
                Company Information
              </div>
              <div class="col-md-12 pb-0">
                <label for="">Company Name</label>
                <v-text-field
                  solo
                  v-model="client.companyName"
                  :rules="requiredRules"
                ></v-text-field>
              </div>
              <div class="col-md-12 pt-0 pb-0">
                <label for="">Tax ID / EIN</label>
                <v-text-field
                  solo
                  v-model="client.taxId"
                  @keyup="showSlash($event)"
                  placeholder="00-0000000"
                  :rules="taxIdRules"
                  counter="10"
                  maxlength="10"
                ></v-text-field>
              </div>
              <div class="col-md-12 pt-0 pb-0">
                <label for="">Group ID</label>
                <v-text-field
                  solo
                  v-model="client.groupId"
                  :rules="requiredRules"
                ></v-text-field>
              </div>
               <div class="col-md-12 pb-0">
                <label class="font text-gray" for=""
                  > Contact First Name
                </label>
                <v-text-field
                  required
                  v-model.trim="client.primaryContact.firstName"
                  solo
                  :rules="requiredRules"
                ></v-text-field>
              </div>
              <div class="col-md-12 pt-0 pb-0">
                <label class="font text-gray" for=""
                  > Contact Last Name</label
                >
                <v-text-field
                  required
                  v-model.trim="client.primaryContact.lastName"
                  solo
                  :rules="requiredRules"
                ></v-text-field>
              </div>
              <div class="col-md-12 pt-0 pb-0">
                <label class="font text-gray" for=""
                  >Primary Contact Email</label
                >
                <v-text-field
                  required
                  :rules="emailRules"
                  v-model.trim="client.primaryContact.email"
                  solo
                ></v-text-field>
              </div>
                 <div class="row col-md-12 justify-space-between no-padding">
                <div class="font text-gray">
                  <label for="">Client Relationship Status</label>
                  <div class="d-flex mt-2">
                    InActive
                    <v-switch
                      solo
                      v-model="client.status"
                      class="no-padding ml-2 mr-2 vendors-switch"
                    ></v-switch>
                    Active
                  </div>
                </div>
              </div>
              <!-- <div class="col-md-12 pt-0 pb-0">
                <label for="">HQ Address Line 1 </label>
                <v-text-field
                  solo
                  v-model="client.address1"
                  :rules="requiredRules"
                ></v-text-field>
              </div>
              <div class="col-md-12 pt-0 pb-0">
                <label for="">HQ Address Line 2</label>
                <v-text-field
                  solo
                  v-model="client.address2"
                  :rules="requiredRules"
                ></v-text-field>
              </div> -->
              <!-- <v-row class="no-padding">
                <div class="col-md-6 pt-0 pb-0">
                  <label class="font text-gray" for="">City</label>
                  <v-text-field
                    required
                    v-model.trim="city"
                    v-model="client.city"
                    solo
                    :rules="requiredRules"
                  ></v-text-field>
                </div>
                <div class="col-md-3 pt-0 pb-0">
                  <label class="font text-gray" for="">State</label>
                  <v-select
                    :items="states"
                    item-text="name"
                    item-value="name"
                    required
                    v-model.trim="client.state"
                    solo
                    :rules="requiredRules"
                  ></v-select>
                </div>
                <div class="col-md-3 pt-0 pb-0 zip-field-height">
                  <label class="font text-gray" for="">Zip</label>
                  <v-text-field
                    required
                    type="number"
                    v-model.trim="client.zipCode"
                    solo
                    :rules="zipRules"
                  ></v-text-field>
                </div>
              </v-row> -->
            </div>
          </div>
          <div class="col-md-8 d-flex justify-content-end">
            <div class="col-md-12 d-flex">
             <div class="col-md-6">
              <div class="d-flex">
                <span class="text-gray text-underline" ></span>
                  TPA / Carrier Information

                <span style="color: #0573F3;"
                 class="text-underline ml-auto"> Search for and add a TPA</span>
              </div>

             <div>
               <v-simple-table
          fixed-header
          height="70vh"
          class="no-padding tr-custom-height mt-8 border-top y-scroll"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">TPA NAME</th>
                <th class="text-end">ACTION</th>
              </tr>
            </thead>
            <tbody class="tr-custom-height"   >
              <tr id="remove-hover" >
                <td class="text-gray">
                 Name
                </td>
                <td class="text-end text-underline"
                   style="color: #0D6DEF;">Remove</td>
  
              </tr>
            </tbody>
          
          </template>
        </v-simple-table>
             </div>
              </div>
              
                 <div class="col-md-5 text-center">
                  <div class="text-underline" style="color: #707070">
                    Actions and Functions 
                  </div>
                  <div class="ml-16 d-inline-block">
            
                  <div class="text-start font-18 text-gray text-underline mt-15 mb-15"> Submit Charges</div>

                  <div class="text-start font-18 text-gray text-underline mb-10"> View Prior Invoices</div>

                    
                 <div class="text-start  col-md-12 justify-space-between no-padding">
                 <div>
                  <label>Client Relationship Status</label>
                  <div class="d-flex mt-2">
                    InActive
                    <v-switch
                      solo
                      v-model="client.status"
                      class="no-padding ml-2 mr-2 vendors-switch"
                    ></v-switch>
                    Active
                  </div>
                </div>
              </div>
              <div class="font-18 text-start text-underline  text-gray mt-5"> Remove Client</div>
                </div>
                
                 </div>
               
<!--              
              <div class="col-md-12 pt-0 pb-0">
                <label class="font text-gray" for=""
                  >Primary Contact Title</label
                >
                <v-text-field
                  required
                  v-model.trim="client.primaryContact.title"
                  solo
                  :rules="requiredRules"
                ></v-text-field>
              </div>
              
              <div class="col-md-12 pt-0 pb-0">
                <label class="font text-gray" for=""
                  >Primary Contact Phone</label
                >
                <v-text-field
                  required
                  v-model.trim="client.primaryContact.phone"
                  solo
                  :rules="phoneRules"
                ></v-text-field>
              </div>
      -->
            </div>
          </div>
        </v-form>
      </div>
    </div>
    <div class="col-md-12">
      <div class="col-md-12 mb-4" align="end">
        <span
          class="
            light-blue-text
            text-underline
            pointer
            font-weight-bold
            mr-10
            font-18
          "
          @click="$router.go(-1)"
          >Cancel</span
        >
        <span
          @click="submit"
          class="
            light-blue-text
            text-underline
            pointer
            font-weight-bold
            mr-5
            font-18
          "
          >Save
        </span>
      </div>
      <successModal
        ref="success_modal"
        v-on:confirm="closeSuccessModal"
      ></successModal>
      <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
    </div>
  </div>
</template>
<script>
import Header from "../Layout/HeaderPage.vue";
import api from "../../../../services/api";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../TablesTemplate/ErrorDialog.vue";
export default {
  components: { Header, successModal, errorModal },
  data() {
    return {
      taxIdRules: [
        (v) => !!v || "This field is required",
        (v) =>
          !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
          "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
      ],
      zipRules: [
        (v) => (v && v.length == 5) || " Zip Code should be five digits long",
      ],
      states: [],
      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
      requiredRules: [(v) => !!v || "This field is required"],

      emailRules: [
        (v) => !!v || "This field is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      valid: false,
      client: {
        companyName: "",
        taxId: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipCode: "",
        primaryContact: {
          firstName: "",
          lastName: "",
          title: "",
          email: "",
          phone: "",
        },
        groupId: "",
        status: "",
      },
    };
  },
  created() {
    this.getStates();
  },
  methods: {
    closeErrorModal() {},
    closeSuccessModal() {
      this.$parent.getData();
      this.$router.push("/digital-invoicing/clients");
    },
    showSlash(event) {
      if (this.client.taxId.length == 2 && event.keyCode != 8) {
        this.client.taxId += "-";
      }
      if (this.client.taxId.length == 10) {
        api()
          .get("auth/isValidTaxId?taxId=" + this.client.taxId)
          .catch((res) => {
            if (res) {
              this.taxIdRules = [res.response.data.message];
            }
          });
      } else {
        this.taxIdRules = [
          (v) => !!v || "This field is required",
          (v) =>
            !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
            "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
        ];
      }
    },
    getStates() {
      api()
        .get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },
    submit() {
      this.$refs.form.validate();
      if (this.valid) {
        if (this.client.status) {
          this.client.status = "active";
        } else {
          this.client.status = "inactive";
        }
        api()
          .post("/tpas/clients", this.client)
          .then((res) => {
            if (res) {
              this.$refs.success_modal.openPopUp("Client added successfully!");
            }
          });
      }
    },
  },
};
</script>
<style>
</style>