<template>
  <div>
    <div
      v-if="!isChildren"
      class="row row-height"
      :class="showOverlay ? ' postion-relative' : ''"
    >
      <div
        v-if="showOverlay"
        class="wizard-absolute border-r-8"
        @click="closeEmailOverlay"
      ></div>
      <v-sheet
        class="overflow-hidden col-md-12"
        style="position: relative; border-radius: 5px"
      >
        <div>
          <v-row align="center" justify="center">
            <div
              :class="
                overlay
                  ? 'hideOnMoblie background-opacity col-md-9'
                  : 'col-md-12 navigationWidth no-padding '
              "
            >
              <v-row class="blue-underline mb-1">
                <v-col class="col-xl-2 col-md-2 cols-4 pt-2 pb-0" cols="4">
                  <div class="col-md-12">
                    <h3 class="form-title font col-md-12">Invoices</h3>
                  </div>
                </v-col>
                <v-col class="col-xl-2 col-md-2 cols-4 pb-0 d-md-none">
                  <v-btn
                    class="gray-border-buttons mobile-only ml-auto mr-5 mt-3"
                    @click="filterModal = true"
                  >
                    <i class="fas fa-bars"  style="cursor: pointer; color: gray"></i>
                  </v-btn>
                </v-col>
                <v-col
                  class="mobile-display col-xl-3 col-md-5 col-lg-5 cols-8 pt-3 pb-0" cols="8" >
                  <div class="col-md-12 d-flex no-padding pt-4">
                    <v-text-field
                      :disabled="overlay"
                      class="input-control mr-5"
                      prepend-inner-icon="mdi-magnify"
                      @input="search"
                      light
                      dense
                      outlined
                      placeholder="Search Invoices"
                    ></v-text-field>
                    <v-btn
                      :disabled="overlay"
                      class="text-gray filter-btn"
                      @click.stop="showFilterSideBar"
                    >
                      <svg
                        width="17"
                        class="mr-2"
                        height="14"
                        viewBox="0 0 17 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 1H16"
                          stroke="#424242"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M2.59766 5H14.403"
                          stroke="#424242"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M6.3125 13H10.6858"
                          stroke="#424242"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M4.19531 9H12.8046"
                          stroke="#424242"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      FILTERS
                    </v-btn>
                  </div>
                </v-col>
                <v-col
                  class="mobile-display col-xl-7 col-md-5 cols-12 text-end pt-7 pr-6 pb-0"
                  cols="12"
                  align="end"
                >
                  <v-btn
                    class="my-1 mr-5 gray-border-buttons"
                    @click="exportData"
                  >
                    <svg
                      class="mr-2"
                      width="15"
                      height="16"
                      viewBox="0 0 15 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.2361 10.7639C14.0329 10.7639 13.838 10.8434 13.6937 10.985C13.5495 11.1267 13.4676 11.319 13.4659 11.5202V12.6764C13.4659 12.9367 13.3615 13.1862 13.1756 13.3703C12.9897 13.5543 12.7376 13.6577 12.4747 13.6577H2.52525C2.26238 13.6577 2.01027 13.5543 1.8244 13.3703C1.63852 13.1862 1.53409 12.9367 1.53409 12.6764V11.5202C1.51671 11.3307 1.42838 11.1544 1.28645 11.0261C1.14452 10.8978 0.959257 10.8266 0.767046 10.8266C0.574834 10.8266 0.389569 10.8978 0.247642 11.0261C0.105715 11.1544 0.0173841 11.3307 0 11.5202V12.6764C0 13.3395 0.266053 13.9753 0.739629 14.4442C1.21321 14.913 1.85551 15.1764 2.52525 15.1764H12.4747C13.1445 15.1764 13.7868 14.913 14.2604 14.4442C14.7339 13.9753 15 13.3395 15 12.6764V11.5202C15 11.3196 14.9195 11.1272 14.7763 10.9854C14.633 10.8436 14.4387 10.7639 14.2361 10.7639Z"
                        fill="#707070"
                      />
                      <path
                        d="M7.49971 0.176392C7.17488 0.176392 6.86336 0.304137 6.63368 0.531525C6.404 0.758913 6.27496 1.06732 6.27496 1.38889V7.05139C6.27496 7.09283 6.25833 7.13257 6.22873 7.16188C6.19913 7.19118 6.15899 7.20764 6.11713 7.20764H4.43784C4.32437 7.21533 4.2151 7.25322 4.12158 7.31731C4.02807 7.3814 3.95378 7.46931 3.90657 7.57175C3.85935 7.6742 3.84097 7.78736 3.85337 7.89929C3.86576 8.01122 3.90847 8.11776 3.97698 8.20764L7.03885 11.6951C7.0979 11.7576 7.16927 11.8074 7.24855 11.8414C7.32782 11.8754 7.4133 11.893 7.49971 11.893C7.58611 11.893 7.6716 11.8754 7.75087 11.8414C7.83014 11.8074 7.90151 11.7576 7.96057 11.6951L11.0224 8.20764C11.0909 8.11776 11.1337 8.01122 11.146 7.89929C11.1584 7.78736 11.1401 7.6742 11.0928 7.57175C11.0456 7.46931 10.9713 7.3814 10.8778 7.31731C10.7843 7.25322 10.675 7.21533 10.5616 7.20764H8.88228C8.84042 7.20764 8.80028 7.19118 8.77068 7.16188C8.74108 7.13257 8.72445 7.09283 8.72445 7.05139V1.38889C8.72445 1.06732 8.59542 0.758913 8.36573 0.531525C8.13605 0.304137 7.82453 0.176392 7.49971 0.176392V0.176392Z"
                        fill="#707070"
                      />
                    </svg>

                    Export
                  </v-btn>

                  <v-menu offset-y left bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" class="gray-border-buttons">
                        <svg
                          class="mr-2"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0 7.5H15"
                            stroke="#707070"
                            stroke-width="2"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M7.5 0V15"
                            stroke="#707070"
                            stroke-width="2"
                            stroke-linejoin="round"
                          />
                        </svg>

                        New
                      </v-btn>
                    </template>
                    <v-list class="dpc-menu-dropdown" style="margin-right: 0px">
                      <v-list-item>
                        <a class="a-tag underline-text" @click="goToAddNew()"
                          >Create an Invoice</a
                        >
                      </v-list-item>
                      <v-list-item>
                        <a
                          class="a-tag underline-text"
                          @click="uploadDialog = true"
                          >Upload an Invoice</a
                        >
                      </v-list-item>
                      <v-list-item>
                        <a
                          class="a-tag underline-text"
                          @click="requestDialog = true"
                          >Email or Fax Invoice</a
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
              <table id="tblData" style="display: none">
                <thead>
                  <tr>
                    <th class="text-left">CLIENT NAME</th>
                    <th class="text-left">INVOICE</th>
                    <th class="text-left">DATE</th>
                    <th class="text-left">AMOUNT</th>
                    <th class="text-left">STATUS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in allInvoices"
                    :key="index"
                    class="text-gray"
                  >
                    <td>
                      <a>{{ item.client }} </a>
                    </td>
                    <td>
                      {{ item.invoiceNo }}
                    </td>
                    <td>
                      {{ item.date | formatDate }}
                    </td>
                    <td>
                      {{ item.amount }}
                    </td>
                    <td>
                      {{
                        item.status
                          ? item.status.charAt(0).toUpperCase() +
                            item.status.slice(1)
                          : "/"
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <v-simple-table fixed-header height="70vh">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">CLIENT NAME</th>
                      <th class="text-left">INVOICE #</th>
                      <th class="text-left">DATE</th>
                      <th class="text-left">AMOUNT</th>
                      <th class="text-left">STATUS</th>
                    </tr>
                  </thead>
                  <tbody v-if="allInvoices.length > 0">
                    <tr
                      v-for="item in allInvoices"
                      :key="item.name"
                      class="text-gray"
                    >
                      <td v-if="item.client">
                        <a @click="singleItem(item)">{{ item.client }} </a>
                      </td>
                      <td v-else><a @click="singleItem(item)">/ </a></td>
                      <td>
                        {{ item.invoiceNo }}
                      </td>
                      <td>
                        {{ item.date | formatDate }}
                      </td>

                      <td>${{ item.amount }}</td>
                      <td>
                        {{
                          item.status
                            ? item.status.charAt(0).toUpperCase() +
                              item.status.slice(1)
                            : "/"
                        }}
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else-if="filterItems">
                    <v-col md="12" cols="12">
                      <v-alert
                        border="bottom"
                        colored-border
                        color="primary"
                        elevation="2"
                        class="text-gray"
                      >
                        Sorry! No results found
                      </v-alert>
                    </v-col>
                  </tbody>
                  <tbody v-else>
                    <v-col md="12">
                      <div class="col-md-12">
                        There are no invoices currently in your account.
                        <a @click="$router.push('/vendors/invoices/add-new/new')">
                          Click to add invoices.</a
                        >
                      </div>
                    </v-col>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-row>
        </div>
        <v-dialog v-model="filterModal" persistent max-width="350px">
          <v-card>
            <v-card-text>
              <v-container>
                <v-col
                  md="12"
                  cols="12"
                  class="justify-center align-center pl-0"
                >
                  <div class="row col-md-12 font">
                    <h3 class="ml-3">Filters</h3>
                  </div>
                </v-col>
                <div>
                  <v-text-field
                    :disabled="overlay"
                    class="input-control mr-5"
                    prepend-inner-icon="mdi-magnify"
                    @input="search"
                    light
                    dense
                    outlined
                    placeholder="Search Invoices"
                  ></v-text-field>
                </div>
                <br />
                <br />
                <div>
                  <v-btn
                    class="my-1 mr-5 gray-border-buttons"
                    @click="exportData"
                  >
                    <svg
                      class="mr-2"
                      width="15"
                      height="16"
                      viewBox="0 0 15 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.2361 10.7639C14.0329 10.7639 13.838 10.8434 13.6937 10.985C13.5495 11.1267 13.4676 11.319 13.4659 11.5202V12.6764C13.4659 12.9367 13.3615 13.1862 13.1756 13.3703C12.9897 13.5543 12.7376 13.6577 12.4747 13.6577H2.52525C2.26238 13.6577 2.01027 13.5543 1.8244 13.3703C1.63852 13.1862 1.53409 12.9367 1.53409 12.6764V11.5202C1.51671 11.3307 1.42838 11.1544 1.28645 11.0261C1.14452 10.8978 0.959257 10.8266 0.767046 10.8266C0.574834 10.8266 0.389569 10.8978 0.247642 11.0261C0.105715 11.1544 0.0173841 11.3307 0 11.5202V12.6764C0 13.3395 0.266053 13.9753 0.739629 14.4442C1.21321 14.913 1.85551 15.1764 2.52525 15.1764H12.4747C13.1445 15.1764 13.7868 14.913 14.2604 14.4442C14.7339 13.9753 15 13.3395 15 12.6764V11.5202C15 11.3196 14.9195 11.1272 14.7763 10.9854C14.633 10.8436 14.4387 10.7639 14.2361 10.7639Z"
                        fill="#707070"
                      />
                      <path
                        d="M7.49971 0.176392C7.17488 0.176392 6.86336 0.304137 6.63368 0.531525C6.404 0.758913 6.27496 1.06732 6.27496 1.38889V7.05139C6.27496 7.09283 6.25833 7.13257 6.22873 7.16188C6.19913 7.19118 6.15899 7.20764 6.11713 7.20764H4.43784C4.32437 7.21533 4.2151 7.25322 4.12158 7.31731C4.02807 7.3814 3.95378 7.46931 3.90657 7.57175C3.85935 7.6742 3.84097 7.78736 3.85337 7.89929C3.86576 8.01122 3.90847 8.11776 3.97698 8.20764L7.03885 11.6951C7.0979 11.7576 7.16927 11.8074 7.24855 11.8414C7.32782 11.8754 7.4133 11.893 7.49971 11.893C7.58611 11.893 7.6716 11.8754 7.75087 11.8414C7.83014 11.8074 7.90151 11.7576 7.96057 11.6951L11.0224 8.20764C11.0909 8.11776 11.1337 8.01122 11.146 7.89929C11.1584 7.78736 11.1401 7.6742 11.0928 7.57175C11.0456 7.46931 10.9713 7.3814 10.8778 7.31731C10.7843 7.25322 10.675 7.21533 10.5616 7.20764H8.88228C8.84042 7.20764 8.80028 7.19118 8.77068 7.16188C8.74108 7.13257 8.72445 7.09283 8.72445 7.05139V1.38889C8.72445 1.06732 8.59542 0.758913 8.36573 0.531525C8.13605 0.304137 7.82453 0.176392 7.49971 0.176392V0.176392Z"
                        fill="#707070"
                      />
                    </svg>

                    Export
                  </v-btn>
                </div>
                <br />
                <div>
                  <v-btn class="my-1 mr-5 gray-border-buttons"
                    @click="goToAddNew()">
                    Create an Invoice
                  </v-btn>
                  <br />
                </div>
                <div>
                  <v-btn  class="my-1 mr-5 gray-border-buttons"
                    @click="uploadDialog = true">
                    Upload an Invoice
                  </v-btn>
                </div>
                <div>
                  <v-btn class="my-1 mr-5 gray-border-buttons"
                    @click="requestDialog = true">
                    Email or Fax Invoice
                  </v-btn>
                </div>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <div class="col-md-12 mb-4 d-flex" align="end">
                <span
                  class="text-underline text-gray mr-10 pointer col-md-6"
                  @click="filterModal = false">
                  Reset</span>
                <span
                  class="text-underline blue--text pointer col-md-6"
                  @click="filterModal = false">
                  Apply Filter</span>
              </div>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-navigation-drawer
          class="userFilterPosition"
          width="350"
          v-model.trim="drawer"
          absolute
          temporary >
          <div class="filter-sideBar">
            <div>
              <div class="row no-padding col-md-12 justify-space-between">
                <h3 class="font pl-3 mt-1">Invoices Filters</h3>
                <h1 class="pointer mr-3" @click.stop="drawer = !drawer">x</h1>
              </div>
              <v-divider></v-divider>
              <div class="col-md-12">
                <div
                  class="row col-md-12 justify-space-between no-padding no-margin"
                >
                  <div
                    class="no-padding font filter-items-font font-weight-bold"
                  >
                    Selected Filter
                  </div>
                  <div
                    class="underline-text pointer text-gray filter-items-font"
                    @click="clearAll"
                  >
                    Clear All
                  </div>
                </div>
              </div>
              <div class="overflow-fitlers-buttons">
                <div class="row mb-1 col-md-12">
                  <v-btn
                    v-for="(item, index) in selectedArray.concat(
                      selectedTypeArray,
                      selectedAgingArray
                    )"
                    :key="index"
                    class="text-center mb-1 ml-2 mr-2 filter-btn"
                    small
                  >
                    <span v-html="item.text"></span>
                    <h5 class="ml-3" @click="removeFilter(item)">x</h5>
                  </v-btn>
                </div>
                <v-divider></v-divider>
                <v-expansion-panels
                  accordion
                  multiple
                  v-model.trim="panel"
                  class="no-shadow no-padding user-expansion-panels">
                  <v-expansion-panel class="no-shadow no-padding">
                    <v-expansion-panel-header
                      class="font-weight-black change-padding-filter filter-items-font">
                      Client</v-expansion-panel-header>
                    <v-expansion-panel-content
                      class="no-padding content-filter no-shadow mb-2">
                      <v-text-field
                        solo
                        class="filter-serach-input no-padding mb-4"
                        v-model.trim="type"
                        dense
                        :hide-details="true"
                        @input="searchType()"
                        placeholder="Search"
                        append-icon="fa fa-search"
                      ></v-text-field>
                      <v-checkbox
                        label="All"
                        color="primary"
                        v-if="showType.all"
                        :on-icon="'mdi-square'"
                        v-model.trim="filter.type.all"
                        class="no-padding radio-buttons-filters"
                        hide-details
                        @click="
                          pushtoTypeFilter(
                            { text: 'All', value: 'allTypes' },
                            filter.type.all
                          )
                        "
                      >
                      </v-checkbox>
                      <v-checkbox
                        v-for="(item, index) in clients"
                        :key="index"
                        :label="item"
                        color="primary"
                        v-if="showClient[item]"
                        v-model.trim="filter.clients[item]"
                        :on-icon="'mdi-square'"
                        class="no-padding radio-buttons-filters"
                        hide-details
                        @click="
                          pushtoClient({ text: item, value: item })
                        "
                      >
                        {{ item }}
                      </v-checkbox>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel class="no-padding no-shadow">
                    <v-expansion-panel-header
                      class="font-weight-black change-padding-filter filter-items-font"
                      >Status</v-expansion-panel-header
                    >
                    <v-expansion-panel-content
                      class="no-padding content-filter no-shadow"
                    >
                      <v-text-field
                        solo
                        class="filter-serach-input mb-4"
                        v-model.trim="status"
                        @input="searchStatus()"
                        dense
                        :hide-details="true"
                        placeholder="Search"
                        append-icon="fa fa-search"
                      ></v-text-field>
                      <v-checkbox
                        label="All"
                        color="primary"
                        v-if="showStatus.all"
                        v-model.trim="filter.status.all"
                        class="no-padding small-text radio-buttons-filters"
                        hide-details
                        :on-icon="'mdi-square'"
                        @click="
                          pushFilter(
                            { text: 'All', value: 'all' },
                            filter.status.all
                          )
                        "
                      >
                      </v-checkbox>
                      <v-checkbox
                      v-for="(item, index) in statuses"
                      :key="index"
                      :label="item"
                      color="primary"
                      v-if="showStatuses[item]"
                      v-model.trim="filter.statuses[item]"
                      :on-icon="'mdi-square'"
                      class="no-padding radio-buttons-filters"
                      hide-details
                      @click="
                        pushtoStatus({ text: item, value: item })
                      "
                    >
                      {{ item.charAt(0).toUpperCase() + item.slice(1) }}
                    </v-checkbox>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </div>
            <div>
              <v-divider></v-divider>
              <v-btn
                class="blue-buttons col-md-12 mt-3 mb-1"
                @click="filterInvoices"
                >View Result</v-btn>
            </div>
          </div>
          <v-dialog
            v-model.trim="requestDialog"
            width="450"
            class="success-modal"
          >
            <v-card>
              <v-card-text class="success-modal pa-3">
                <div align="end" class="col-12 pt-0 pr-0">
                  <div class="col-12 pa-0 text-end font-weight-bold" @click="requestDialog = false">
                    <v-icon role="button">mdi-close</v-icon>
                  </div>
                </div>
                <div class="text-center">
                  <svg
                    width="95"
                    height="100"
                    viewBox="0 0 126 112"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1017_28415)">
                      <path
                        d="M124.403 42.8311C124.358 42.8311 124.315 42.8174 124.278 42.7918L63.629 0.633045C63.4444 0.505346 63.2251 0.437101 63.0005 0.437483C62.776 0.437865 62.5569 0.506856 62.3727 0.635183L2.16072 42.7914C2.1131 42.8248 2.05416 42.8379 1.99686 42.8278C1.93956 42.8178 1.8886 42.7854 1.8552 42.7379C1.8218 42.6904 1.80868 42.6315 1.81873 42.5743C1.82879 42.5171 1.86119 42.4663 1.90882 42.4329L62.1209 0.27671C62.3787 0.0970783 62.6854 0.000517809 62.9998 2.07673e-06C63.3142 -0.000513655 63.6213 0.0950393 63.8796 0.273824L124.529 42.4325C124.567 42.4592 124.596 42.4973 124.611 42.5414C124.626 42.5855 124.626 42.6333 124.612 42.6778C124.598 42.7222 124.571 42.7611 124.533 42.7887C124.495 42.8162 124.45 42.8311 124.403 42.8311Z"
                        fill="#3F3D56"
                      />
                      <path
                        d="M5.60156 44.3256L63.0603 1.82104L120.955 47.35L66.0209 79.855L36.1963 73.0694L5.60156 44.3256Z"
                        fill="#E6E6E6"
                      />
                      <path
                        d="M38.7139 99.9318H10.3279C10.1536 99.9321 9.98095 99.898 9.81985 99.8316C9.65876 99.7651 9.51236 99.6677 9.38903 99.5447C9.2657 99.4218 9.16787 99.2758 9.10112 99.1151C9.03436 98.9544 9 98.7821 9 98.6081C9 98.4342 9.03436 98.2619 9.10112 98.1012C9.16787 97.9405 9.2657 97.7945 9.38903 97.6715C9.51236 97.5486 9.65876 97.4511 9.81985 97.3847C9.98095 97.3183 10.1536 97.2842 10.3279 97.2844H38.7139C38.8882 97.2842 39.0609 97.3183 39.2219 97.3847C39.383 97.4511 39.5294 97.5486 39.6528 97.6715C39.7761 97.7945 39.8739 97.9405 39.9407 98.1012C40.0074 98.2619 40.0418 98.4342 40.0418 98.6081C40.0418 98.7821 40.0074 98.9544 39.9407 99.1151C39.8739 99.2758 39.7761 99.4218 39.6528 99.5447C39.5294 99.6677 39.383 99.7651 39.2219 99.8316C39.0609 99.898 38.8882 99.9321 38.7139 99.9318Z"
                        fill="#16407A"
                      />
                      <path
                        d="M20.5122 94.2407H10.3279C10.1536 94.2409 9.98095 94.2068 9.81985 94.1404C9.65876 94.074 9.51236 93.9765 9.38903 93.8536C9.2657 93.7306 9.16787 93.5846 9.10112 93.4239C9.03436 93.2632 9 93.0909 9 92.917C9 92.743 9.03436 92.5707 9.10112 92.41C9.16787 92.2493 9.2657 92.1033 9.38903 91.9804C9.51236 91.8574 9.65876 91.76 9.81985 91.6935C9.98095 91.6271 10.1536 91.593 10.3279 91.5933H20.5122C20.6865 91.593 20.8591 91.6271 21.0202 91.6935C21.1813 91.76 21.3277 91.8574 21.451 91.9804C21.5743 92.1033 21.6722 92.2493 21.7389 92.41C21.8057 92.5707 21.84 92.743 21.84 92.917C21.84 93.0909 21.8057 93.2632 21.7389 93.4239C21.6722 93.5846 21.5743 93.7306 21.451 93.8536C21.3277 93.9765 21.1813 94.074 21.0202 94.1404C20.8591 94.2068 20.6865 94.2409 20.5122 94.2407Z"
                        fill="#16407A"
                      />
                      <path
                        d="M63.8685 67.9999C63.6528 68.0001 63.439 67.9583 63.2393 67.8768L27.8027 53.2006V7.26147C27.8032 6.85525 27.9651 6.46579 28.2529 6.17855C28.5407 5.8913 28.9308 5.72972 29.3378 5.72925H97.3203C97.7273 5.72972 98.1174 5.8913 98.4052 6.17855C98.693 6.46579 98.8549 6.85525 98.8554 7.26147V53.2327L98.7888 53.2611L64.5168 67.8697C64.3115 67.9557 64.0911 68 63.8685 67.9999Z"
                        fill="white"
                      />
                      <path
                        d="M63.8688 68.1093C63.6387 68.1095 63.4108 68.065 63.1978 67.9782L27.6934 53.2738V7.26154C27.6939 6.82629 27.8673 6.40902 28.1756 6.10125C28.484 5.79349 28.902 5.62037 29.3381 5.61987H97.3205C97.7566 5.62037 98.1747 5.79349 98.483 6.10125C98.7914 6.40902 98.9648 6.82629 98.9653 7.26154V53.305L64.5601 67.9703C64.3413 68.0622 64.1062 68.1095 63.8688 68.1093ZM28.1319 52.9813L63.3651 67.5735C63.6937 67.7066 64.0618 67.7044 64.3888 67.5674L98.5267 53.016V7.26154C98.5263 6.94237 98.3991 6.63637 98.173 6.41068C97.9469 6.18499 97.6403 6.05803 97.3206 6.05765H29.3381C29.0183 6.05803 28.7118 6.18499 28.4857 6.41068C28.2595 6.63637 28.1323 6.94237 28.132 7.26154L28.1319 52.9813Z"
                        fill="#3F3D56"
                      />
                      <path
                        d="M123.965 42.3933H123.921L98.7456 53.1233L64.3005 67.8041C64.1659 67.8607 64.0215 67.8901 63.8754 67.8909C63.7294 67.8916 63.5847 67.8636 63.4495 67.8085L27.9123 53.0926L2.1184 42.4108L2.07899 42.3933H2.03509C1.6281 42.3938 1.2379 42.5553 0.950114 42.8426C0.662327 43.1298 0.500452 43.5193 0.5 43.9255V110.468C0.500452 110.874 0.662327 111.263 0.950114 111.551C1.2379 111.838 1.6281 112 2.03509 112H123.965C124.372 112 124.762 111.838 125.05 111.551C125.338 111.263 125.5 110.874 125.5 110.468V43.9255C125.5 43.5193 125.338 43.1298 125.05 42.8426C124.762 42.5553 124.372 42.3938 123.965 42.3933ZM125.061 110.468C125.061 110.758 124.946 111.036 124.74 111.242C124.535 111.447 124.256 111.562 123.965 111.562H2.03509C1.74432 111.562 1.46549 111.447 1.25988 111.242C1.05427 111.036 0.938713 110.758 0.938596 110.468V43.9255C0.938994 43.6425 1.04898 43.3706 1.24557 43.1666C1.44215 42.9627 1.71011 42.8424 1.99343 42.8311L27.9123 53.5654L63.2808 68.2135C63.6636 68.3689 64.0926 68.3666 64.4737 68.2069L98.7456 53.5982L124.009 42.8311C124.292 42.8433 124.559 42.9638 124.755 43.1677C124.951 43.3715 125.061 43.643 125.061 43.9255V110.468Z"
                        fill="#3F3D56"
                      />
                      <path
                        d="M63.514 54.9733C62.2058 54.9757 60.9322 54.5535 59.8855 53.7703L59.8206 53.7216L46.1559 43.279C45.5231 42.795 44.9919 42.1913 44.5928 41.5025C44.1937 40.8136 43.9345 40.053 43.8298 39.2642C43.7252 38.4753 43.7773 37.6736 43.9831 36.9048C44.1889 36.136 44.5444 35.4152 45.0293 34.7835C45.5141 34.1519 46.1189 33.6217 46.8091 33.2234C47.4992 32.825 48.2612 32.5662 49.0516 32.4618C49.8419 32.3574 50.6451 32.4093 51.4154 32.6148C52.1856 32.8202 52.9077 33.175 53.5406 33.659L62.3915 40.4331L83.3078 13.2062C83.7929 12.5747 84.3978 12.0448 85.0881 11.6466C85.7784 11.2485 86.5405 10.99 87.3309 10.8859C88.1213 10.7817 88.9245 10.834 89.6946 11.0397C90.4648 11.2454 91.1868 11.6005 91.8194 12.0847L91.6894 12.261L91.8228 12.0873C93.099 13.0663 93.934 14.5106 94.1446 16.1033C94.3553 17.696 93.9243 19.3071 92.9464 20.5831L68.3439 52.6085C67.7749 53.3465 67.0432 53.9437 66.2056 54.3538C65.368 54.7639 64.4469 54.9759 63.514 54.9733Z"
                        fill="#16407A"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1017_28415">
                        <rect
                          width="125"
                          height="112"
                          fill="white"
                          transform="translate(0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div class="display">
                  <span class="text-center font-18 mt-2 mt-5">
                    Please send your invoices in PDF format to
                    <br />
                    <strong>invoices@apaly.net </strong>
                  </span>
                </div>
                <div class="text-center text--white mt-6">
                  <v-btn
                    color="#16407A"
                    class="submit-btn"
                    @click="requestDialog = false"
                  >
                    Okay
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model.trim="uploadDialog"
            width="700"
            class="success-modal">
            <v-card>
              <v-card-text class="success-modal pt-5">
                <div class="row col-md-12 font mt-8 pb-4 justify-start">
                  <h3 class="ml-3">Upload an Invoice</h3>
                </div>
                <div class="text-center">
                  <div class="row text-center justify-center upload-box">
                    <div
                      @click="addItem"
                      class="col-md-10 upload-dpc-provider-box pointer"
                    >
                      <input
                        :key="pdfUploadKey"
                        ref="fileInput"
                        type="file"
                        style="display: none"
                        @change="onChange"
                      />
                      <svg
                        width="100"
                        height="100"
                        viewBox="0 0 100 100"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M90.2135 56.7407C86.921 54.5225 83.1435 53.1493 79.208 52.7403C75.2726 52.3313 71.2979 52.8986 67.6281 54.3933C63.9583 55.8879 60.704 58.2648 58.147 61.3181C55.5901 64.3713 53.8075 68.009 52.9537 71.916C51.4417 76.8915 51.7658 82.2529 53.8659 87.005C55.9661 91.7571 59.6995 95.5771 64.3734 97.7561C66.9118 98.6879 69.5174 99.4207 72.1671 99.9481C72.3424 99.9983 72.5259 100.012 72.7065 99.9875C72.8871 99.9632 73.0608 99.9018 73.217 99.807C73.3732 99.7122 73.5086 99.586 73.6147 99.4363C73.7209 99.2866 73.7956 99.1164 73.8342 98.9364C74.4177 96.618 70.8751 97.3346 65.4987 94.9318C61.6722 92.939 58.6871 89.614 57.094 85.5701C55.5008 81.5262 55.4069 77.0361 56.8297 72.9277C57.619 69.7482 59.1452 66.8043 61.2821 64.3395C63.419 61.8746 66.1053 59.9597 69.1189 58.7528C72.1325 57.546 75.3868 57.082 78.6128 57.3991C81.8388 57.7163 84.9438 58.8055 87.6712 60.5767C91.3984 63.1517 94.1367 66.9448 95.4268 71.3196C96.7168 75.6945 96.4801 80.3849 94.7564 84.6042C92.5892 89.9577 87.046 96.5758 81.1695 96.8287C79.169 96.8287 78.9606 101.339 86.0458 98.2619C91.2017 95.6067 95.3368 91.2909 97.7989 85.9953C100.112 80.9907 100.62 75.325 99.2345 69.9813C97.8489 64.6375 94.6573 59.953 90.2135 56.7407V56.7407Z"
                          fill="#4072B7"
                        />
                        <path
                          d="M79.1678 70.3161C81.2871 72.781 82.902 75.6465 83.919 78.7468C83.919 79.1158 84.0639 79.4696 84.3219 79.7305C84.5798 79.9913 84.9296 80.1379 85.2944 80.1379C85.6592 80.1379 86.009 79.9913 86.2669 79.7305C86.5249 79.4696 86.6698 79.1158 86.6698 78.7468C86.3859 76.2974 85.725 73.9079 84.7109 71.665C84.2108 70.6112 83.7106 69.726 83.0021 68.2928C82.4951 67.1647 81.7563 66.1588 80.8349 65.342C78.5843 63.7823 75.2501 65.342 73.2912 67.1125C70.7302 69.9395 68.7974 73.2885 67.6231 76.9342C67.543 77.0833 67.4946 77.2475 67.4807 77.4165C67.4668 77.5855 67.4879 77.7556 67.5425 77.916C67.5972 78.0763 67.6842 78.2234 67.7981 78.3479C67.912 78.4723 68.0502 78.5715 68.2041 78.639C68.3579 78.7065 68.5239 78.7409 68.6916 78.74C68.8593 78.7391 69.0249 78.703 69.178 78.6338C69.3311 78.5647 69.4683 78.464 69.5809 78.3384C69.6935 78.2127 69.779 78.0647 69.832 77.9038C71.1592 75.4585 72.9097 73.274 75 71.4543C75 72.5081 75 73.857 75 74.1521C74.3604 77.4976 74.192 80.9179 74.4999 84.3111C74.4375 86.1337 74.5773 87.9577 74.9167 89.7489C74.9774 90.0675 75.1609 90.3487 75.4266 90.5305C75.6924 90.7123 76.0187 90.7799 76.3337 90.7184C76.6487 90.6569 76.9267 90.4714 77.1065 90.2026C77.2862 89.9338 77.3531 89.6038 77.2923 89.2852C77.4945 87.7059 77.8576 86.152 78.3759 84.6483C79.1199 81.2736 79.289 77.7952 78.876 74.3629C78.4593 67.4919 78.2925 69.0515 79.1678 70.3161Z"
                          fill="#4072B7"
                        />
                        <path
                          d="M20.1537 21.7534C22.1876 22.4505 24.2802 22.9584 26.4053 23.2709C30.5503 23.8942 34.7637 23.8942 38.9086 23.2709C39.2734 23.2709 39.6232 23.1243 39.8812 22.8635C40.1391 22.6026 40.284 22.2488 40.284 21.8798C40.284 21.5109 40.1391 21.1571 39.8812 20.8962C39.6232 20.6353 39.2734 20.4888 38.9086 20.4888C34.8425 19.3343 30.627 18.8085 26.4053 18.9291C24.138 18.6979 21.848 18.898 19.6535 19.5192C19.1951 19.8565 18.9867 21.0368 20.1537 21.7534Z"
                          fill="#4072B7"
                        />
                        <path
                          d="M18.8224 38.1526C18.7239 38.4172 18.7159 38.7074 18.7996 38.9771C18.8833 39.2467 19.0539 39.4803 19.2842 39.6404C19.5144 39.8005 19.7911 39.878 20.07 39.8605C20.3489 39.8429 20.614 39.7314 20.8229 39.5437C24.7935 39.4649 28.7642 39.6904 32.7011 40.2182C42.5787 40.4711 49.3721 39.1643 56.7074 38.785C57.0364 38.7273 57.3346 38.554 57.5495 38.2956C57.7645 38.0373 57.8823 37.7105 57.8823 37.3728C57.8823 37.0352 57.7645 36.7084 57.5495 36.45C57.3346 36.1917 57.0364 36.0184 56.7074 35.9607C47.2075 35.1589 37.6581 35.1589 28.1582 35.9607C26.4077 36.2557 20.1561 35.9185 18.8224 38.1526Z"
                          fill="#4072B7"
                        />
                        <path
                          d="M43.9089 54.7602C37.0909 53.4732 30.0964 53.4732 23.2784 54.7602C19.9442 55.3082 19.4857 55.266 19.1106 55.6876C18.9082 55.837 18.7549 56.0446 18.6706 56.2833C18.5862 56.522 18.5748 56.7808 18.6377 57.0262C18.7006 57.2716 18.8349 57.4922 19.0233 57.6594C19.2117 57.8266 19.4454 57.9327 19.6941 57.9638C20.2352 58.0036 20.7785 58.0036 21.3195 57.9638C28.8509 59.0779 36.5025 59.0779 44.0339 57.9638C44.2419 57.9555 44.4462 57.9059 44.6352 57.8177C44.8242 57.7295 44.9942 57.6046 45.1355 57.45C45.2767 57.2954 45.3865 57.1141 45.4585 56.9166C45.5305 56.7191 45.5633 56.5091 45.5551 56.2988C45.5469 56.0884 45.4978 55.8818 45.4106 55.6906C45.3235 55.4994 45.1999 55.3275 45.0471 55.1846C44.8942 55.0418 44.715 54.9307 44.5197 54.8579C44.3244 54.7851 44.1168 54.7519 43.9089 54.7602V54.7602Z"
                          fill="#4072B7"
                        />
                        <path
                          d="M41.2847 72.8444C29.5732 72.8444 16.8199 71.2847 5.10843 69.9358C11.1934 54.6341 10.5682 32.7985 12.4854 8.39167C12.4854 7.97014 13.3189 6.62123 13.694 6.53692C17.8618 5.56739 37.4503 2.82741 39.9927 2.70095C41.8441 2.55245 43.7069 2.738 45.4941 3.24895C47.0362 3.62833 49.9953 -0.123332 39.7843 0.0031284C37.492 0.0452819 15.5278 1.98434 12.0686 2.91172C8.60935 3.8391 8.526 6.19969 7.48406 15.5578C5.5252 43.6741 5.35849 50.9667 3.31629 60.9149C1.27408 70.8632 -0.684772 71.0739 0.232138 72.6336C0.388726 72.9446 0.619216 73.2114 0.902917 73.41C1.18662 73.6087 1.51465 73.733 1.85757 73.7717C14.1525 74.7413 28.9064 75.9216 41.2847 75.6686C41.6136 75.611 41.9118 75.4377 42.1268 75.1793C42.3417 74.921 42.4596 74.5942 42.4596 74.2565C42.4596 73.9189 42.3417 73.5921 42.1268 73.3337C41.9118 73.0754 41.6136 72.9021 41.2847 72.8444V72.8444Z"
                          fill="#4072B7"
                        />
                        <path
                          d="M61.7465 13.1549C63.2469 14.5881 65.9143 16.4007 66.6228 17.9604L66.206 44.2642C66.2591 44.5548 66.4112 44.8173 66.6358 45.0064C66.8604 45.1954 67.1433 45.2989 67.4355 45.2989C67.7277 45.2989 68.0106 45.1954 68.2352 45.0064C68.4598 44.8173 68.6119 44.5548 68.665 44.2642C68.665 41.9879 72.8328 20.1524 70.7906 15.8527C69.3318 12.9863 69.0401 13.9558 54.703 1.77345C54.4073 1.58391 54.0527 1.51177 53.7076 1.57094C53.3625 1.6301 53.0512 1.81637 52.8338 2.09386C52.6164 2.37135 52.5082 2.72041 52.5301 3.07376C52.5521 3.42712 52.7025 3.75977 52.9525 4.00759L61.7465 13.1549Z"
                          fill="#4072B7"
                        />
                        <path
                          d="M47.0348 21.3321C49.202 25.5474 56.704 23.777 61.4553 22.4702C69.7908 20.1939 65.623 16.0629 63.9559 18.2549C59.8192 19.6492 55.394 19.9253 51.1192 19.0558C50.7664 16.162 50.879 13.2296 51.4526 10.3722C51.7861 5.77745 51.8277 5.86176 51.0775 5.60884C49.4938 4.93439 49.077 7.4636 48.4518 9.82419C47.6183 12.9435 45.1176 17.4961 47.0348 21.3321Z"
                          fill="#4072B7"
                        />
                        <path
                          d="M73.4197 8.47638C75.5477 10.1898 77.4772 12.1413 79.1712 14.2936L76.7123 45.1499C76.7123 46.7939 77.8376 47.4684 78.7128 46.1194C79.9631 42.3678 85.0061 15.1366 82.2971 11.2585C78.8507 7.41849 74.8788 4.09688 70.5023 1.3946C70.367 1.25149 70.2031 1.13924 70.0215 1.06535C69.8399 0.991459 69.6447 0.95763 69.4492 0.966125C69.2536 0.974619 69.0621 1.02524 68.8874 1.11461C68.7127 1.20397 68.5589 1.33002 68.4363 1.48433C68.3136 1.63864 68.2249 1.81765 68.1762 2.00939C68.1274 2.20114 68.1197 2.40121 68.1535 2.59622C68.1873 2.79123 68.2619 2.9767 68.3723 3.14022C68.4826 3.30374 68.6262 3.44154 68.7935 3.54442L73.4197 8.47638Z"
                          fill="#F6A405"
                        />
                      </svg>

                      <h3 class="text-gray font">Drag & Drop</h3>
                      <h5 class="text-gray font">
                        Your files here or
                        <a>Browse</a>
                        to upload
                      </h5>
                      <h5 class="text-gray font">
                        <a>PDF File Only</a> <br />
                        <v-progress-circular
                        v-if="loader"
                        indeterminate
                        :size="50"
                        color="primary"
                        class="text-center"
                      ></v-progress-circular>
                        <div v-if="arrayLength">
                          <img
                            src="/success.png"
                            width="30"
                            height="30"
                            alt=""
                          />
                          <br />
                          {{ fileName }}
                        </div>
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 d-flex text--white mt-6">
                  <div class="col-md-10 text-end">
                    <a @click="cancelUpload()" class="text-underline font-bold">
                      Cancel</a>
                  </div>
                  <div class="col-md-2" v-if="loader==false">
                    <a @click="uploadPdf()" class="text-underline font-bold">
                      Upload</a>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-navigation-drawer>
        <removeDialog
          ref="dialog_delete"
          v-on:confirm="confirmRemove"
        ></removeDialog>
        <successModal ref="success_modal" v-on:confirm="closeSuccessModal">
        </successModal>
        <errorModal ref="error_modal" v-on:confirm="closeErrorModal">
        </errorModal>
      </v-sheet>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import RemoveDialog from "../../../TablesTemplate/RemoveDialog.vue";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../TablesTemplate/ErrorDialog.vue";

// import api from "../../../../services/api";
import api from "../../../../services/api.js";

export default {
  components: { RemoveDialog, successModal, errorModal },

  data() {
    return {
      loader: false,
      filterModal: false,
      arrayLength: false,
      uploadDialog: false,
      requestDialog: false,
      pdfUploadKey: "init",
      fileName: "",
      pdfFile: {},
      items: [],
      allInvoices: [],
      filterItems: false,
      overlay: false,
      display: true,
      filterArray: [],
      windowWidth: window.innerWidth,
      noResultMessage: false,
      drawer: null,
      showOverlay: null,
      overlay: false,
      showAging: {
        all: true,
        current: true,
        numDays: true,
        greaterThen: true,
      },
      showStatus: {
        all: true,
        pending: true,
        processing: true,
        paid: true,
      },
      showType: {
        all: true,
        eleven: true,
        boeing: true,
        catepillar: true,
      },
      clients: [],
      showClient: {},
      showStatuses:  {},
      userStatus: [
        {
          text: "ALL",
          value: "all",
        },

        {
          text: "Pending",
          value: "pending",
        },
        {
          text: "Processing",
          value: "processing",
        },
        {
          text: "Paid",
          value: "paid",
        },
      ],
      userAging: [
        {
          text: "ALL",
          value: "allAging",
        },
        {
          text: "Current",
          value: "current",
        },
        {
          text: "30 - 60 days",
          value: "numDays",
        },
        {
          text: "Grater than 60 daya",
          value: "greaterThen",
        },
      ],
      userType: [
        {
          text: "ALL",
          value: "all",
        },
        {
          text: "7-Eleven",
          value: "7-Eleven",
        },
        {
          text: "Boeing",
          value: "boeing",
        },
        {
          text: "Catepillar",
          value: "Catepillar",
        },
      ],
      object: {},
      filterItems: false,
      selectedArray: [],
      selectedTypeArray: [],
      getUsers: [],
      panel: [0, 1],
      filterUse: false,
      type: "",
      status: "",
      statusArray: [],
      typeArray: [],
      selectedAgingArray: [],
      itemToRemove: null,
      filter: {
        status: {
          all: false,
          pending: false,
          processing: false,
          paid: false,
        },
        type: {
          eleven: false,
          all: false,
          boeing: false,
          catepillar: false,
        },
        client: {
          eleven: false,
          allTypes: false,
          boeing: false,
        },
        aging: {
          all: false,
          current: false,
          greaterThen: false,
          numDays: false,
        },
        clients: {},
        statuses: {},
      },
      selectedClients: [],
      statuses: [],
      selectedStatuses: [],
    };
  },
  computed: {
    ...mapGetters(["showRoutePathForFilter", "showRoutePathForEmailOverlay"]),
    routeParams() {
      return this.$route.params.name;
    },
    singleEmp() {
      let emp = "";
      if (localStorage.getItem("employer")) {
        emp = JSON.parse(localStorage.getItem("employer"));
      }
      return emp;
    },
    arrayInFilters() {
      return this.allInvoices;
    },
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
  },
  watch: {
    $route(to, from) {
      this.getData();
    },
    showRoutePathForFilter: {
      handler(newValue, oldValue) {
        this.drawer = this.showRoutePathForFilter;
      },
      deep: true,
    },
    drawer: {
      handler(newValue, oldValue) {
        this.$store.commit("setNavItemFilter", this.drawer);
      },
      deep: true,
    },

    showRoutePathForEmailOverlay: {
      handler(newValue, oldValue) {
        this.drawer = false;
        this.$store.commit("setNavItemFilter", false);
        this.showOverlay = this.showRoutePathForEmailOverlay;
      },
      deep: true,
    },
    showOverlay: {
      handler(newValue, oldValue) {
        this.$store.commit("setOverlayItemFilter", this.showOverlay);
      },
      deep: true,
    },
  },
  methods: {
    goToAddNew() {
      this.$router.push({ name: `Create an Invoice`, params: { type: "new" } });
    },
    cancelUpload() {
      this.pdfUploadKey = Math.random();
      this.uploadDialog = false;
    },
    addItem() {
      this.$refs.fileInput.click();
    },
    async onChange(e) {
      let file = e.target.files[0];
      this.pdfFile = file;
      const formData = new FormData();
      this.loader = true;
      this.fileName = e.target.files[0].name;
      formData.append("invoice", file);
      await api()
        .post("/vendors/invoice/parse", formData)
        .then((res) => {
          if (res) {
            // this.$refs.success_modal.openPopUp("Invoice Successfully Uploaded!");
            this.object = res.data;
            this.loader = false;
            this.arrayLength = true;
          }
        })
        .catch((err) => {
          // this.$refs.error_modal.openPopUp(' There was a problem while parsing this invoice!');
          this.pdfUploadKey = Math.random();
          this.loader = false;
          this.uploadPdf();
        });
    },
    uploadPdf() {
      this.pdfUploadKey = Math.random();
      this.arrayLength = false;
      this.$router.push({
        name: `Create an Invoice`,
        params: { type: "pdf", invoiceObject: this.object, pdf: this.pdfFile },
      });
      this.uploadDialog = false;
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    exportData() {
      var elt = document.getElementById("tblData");
      var downloadLink;
      var dataType = "application/vnd.ms-excel";
      var tableSelect = elt;
      var tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

      // Specify file name
      let filename = "excel_data.xls";

      // Create download link element
      downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        var blob = new Blob(["\ufeff", tableHTML], {
          type: dataType,
        });
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = "data:" + dataType + ", " + tableHTML;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
    },
    filterInvoices() {
      api()
        .get("/vendors/invoices")
        .then((res) => {
          this.allInvoices = res.data;
          this.filterArray = res.data;
          let clientList = [];
          let statusList = [];
          for (let key in this.filter.clients) {
              if (this.filter.clients[key]) {
                clientList.push(key) ;
              }
            }
            if (clientList.length !== 0 ){
              this.allInvoices = this.allInvoices.filter(function (el) {
                   return clientList.includes(el.client)
              });
            }
            for (let key in this.filter.statuses) {
              if (this.filter.statuses[key]) {
                statusList.push(key) ;
              }
            }
            if (statusList.length !== 0 ){
              this.allInvoices = this.allInvoices.filter(function (el) {
                   return statusList.includes(el.status)
              });
            }
          if (!this.filter.status && !this.filter.type) {
            this.getData();
          } else {
            let params = "";
            for (let key in this.filter.status) {
              if (this.filter.status[key]) {
                params += `${key},`;
              }
            }
            if (params && !this.filter.status.all) {
              this.allInvoices = this.filterArray.filter(function (el) {
                return params.toLowerCase().includes(el.status.toLowerCase());
              });

              if (this.allInvoices.length < 1) {
                this.filterItems = true;
              }
            }
          }
          this.filterUse = true;
          this.drawer = false;
        });
    },
    getData() {
      api()
        .get("/vendors/invoices")
        .then((res) => {
          this.allInvoices = res.data;
          this.filterArray = res.data;
          this.allInvoices.forEach((element) => {
            this.clients.push(element.client);
            this.statuses.push(element.status);
            this.showStatuses[element.status] = true;
            this.filter.statuses[element.status] = false;
            this.showClient[element.client] = true;
            this.filter.clients[element.client] = false;
            const unique = (value, index, self) => {
              return self.indexOf(value) === index;
            };
            this.clients = this.clients.filter(unique);
            this.statuses = this.statuses.filter(unique);
          });
        });
    },
    pushtoClient(item,checked) {
      if (checked) {
        if (item.value == "ALL") {
          this.selectedClients = [];
          Object.keys(this.filter.clients).forEach(key => {
            this.filter.cients[key]=true;
          });
          this.selectedClients.push({ text: "All", value: "ALL" });

        } else {
          this.selectedClients.push(item);
        }
      } else {
        if (item.value == "ALL" || this.filter.clients['ALL']) {
          Object.keys(this.filter.clients).forEach(key => {
            this.filter.clients[key]=false;
          });
          this.selectedClients = [];
        } else {
          this.selectedClients.forEach((el, index) => {
            if (el.value == item.value) {
              this.selectedClients.splice(index, 1);
            }
          });
        }
      }
     
    },
    pushtoStatus(item,checked) {
      if (checked) {
        if (item.value == "ALL") {
          this.selectedStatuses = [];
          Object.keys(this.filter.statuses).forEach(key => {
            this.filter.statuses[key]=true;
          });
          this.selectedStatuses.push({ text: "All", value: "ALL" });

        } else {
          this.selectedStatuses.push(item);
        }
      } else {
        if (item.value == "ALL" || this.filter.statuses['ALL']) {
          Object.keys(this.filter.statuses).forEach(key => {
            this.filter.statuses[key]=false;
          });
          this.selectedStatuses = [];
        } else {
          this.selectedStatuses.forEach((el, index) => {
            if (el.value == item.value) {
              this.selectedStatuses.splice(index, 1);
            }
          });
        }
      }
     
    },
    confirmRemove() {
      this.allInvoices.splice(this.itemToRemove, 1);
      this.$refs.dialog_delete.closePopUp();
    },
    removeItem(item) {
      this.itemToRemove = item;
      this.$refs.dialog_delete.openPopUp(
        "Are you sure that you want to delete this Invoice"
      );
    },
    closeEmailOverlay() {
      this.$store.commit("setNavItemFilter", false);
      this.$store.commit("setOverlayItemFilter", false);
      this.showOverlay = false;
    },
    showEmailOverlay() {
      this.$store.commit("setNavItemFilter", false);
      this.$store.commit("setOverlayItemFilter", true);
      this.showOverlay = true;
    },
    showFilterSideBar() {
      this.drawer = !this.drawer;

      if (this.drawer) {
        this.$store.commit("setNavItemFilter", this.drawer);
      } else {
        this.$store.commit("setNavItemFilter", false);
      }
    },
    pushFilterAging(item, checked) {
      if (checked) {
        if (item.value == "allAging") {
          this.selectedAgingArray = [];
          this.filter.aging = {
            all: true,
            current: true,
            greaterThen: true,
            numDays: true,
          };
          this.selectedAgingArray.push({ text: "All", value: "allAging" });
        } else {
          this.selectedAgingArray.push(item);
        }
      } else {
        if (item.value == "allAging" || this.filter.aging.all) {
          this.filter.aging = {
            all: false,
            current: false,
            greaterThen: false,
            numDays: false,
          };
          this.selectedAgingArray = [];
        } else {
          this.selectedAgingArray.forEach((el, index) => {
            if (el.value == item.value) {
              this.selectedAgingArray.splice(index, 1);
            }
          });
        }
      }
    },

    pushtoTypeFilter(item, checked) {
      if (checked) {
        if (item.value == "allTypes") {
          this.selectedTypeArray = [];
          this.filter.type = {
            eleven: true,
            all: true,
            boeing: true,
            catepillar: true,
          };
          this.selectedTypeArray.push({ text: "All", value: "allTypes" });
        } else {
          this.selectedTypeArray.push(item);
        }
      } else {
        if (item.value == "allTypes" || this.filter.type.all) {
          this.filter.type = {
            eleven: false,
            all: false,
            boeing: false,
            catepillar: false,
          };
          this.selectedTypeArray = [];
        } else {
          this.selectedTypeArray.forEach((el, index) => {
            if (el.value == item.value) {
              this.selectedTypeArray.splice(index, 1);
            }
          });
        }
      }
    },
    pushFilter(item, checked) {
      if (checked) {
        if (item.value == "all") {
          this.selectedArray = [];
          this.filter.status = {
            all: true,
            pending: true,
            processing: true,
            paid: true,
          };
          this.selectedArray.push({ text: "All", value: "all" });
        } else {
          this.selectedArray.push(item);
        }
      } else {
        if (item.value == "all" || this.filter.status.all) {
          this.filter.status = {
            all: false,
            pending: false,
            processing: false,
            paid: false,
          };
          this.selectedArray = [];
        } else {
          this.selectedArray.forEach((el, index) => {
            if (el.value == item.value) {
              this.selectedArray.splice(index, 1);
            }
          });
        }
      }
    },

    searchStatus() {
      for (let key in this.showStatus) {
        if (key.toLowerCase().includes(this.status.toLowerCase())) {
          this.showStatus[key] = true;
        } else {
          this.showStatus[key] = false;
        }
      }
    },
    searchType() {
      for (let key in this.showType) {
        if (key.toLowerCase().includes(this.type.toLowerCase())) {
          this.showType[key] = true;
        } else {
          this.showType[key] = false;
        }
      }
    },
    filterUsers() {
      if (!this.filter.status && !this.filter.type) {
        this.getAllUsers("all");
      } else {
        this.getAllUsers(this.filter.status);
      }
      this.filterUse = true;
      this.drawer = false;
    },
    clearAll() {
      this.filter.status = {
        all: false,
        pending: false,
        processing: false,
        paid: false,
      };
      this.filter.client = {
        all: false,
        sevenEleven: false,
        boeing: false,
        catepillar: false,
      };
      this.filter.aging = {
        all: false,
        current: false,
        greaterThen: false,
        numDays: false,
      };

      this.selectedArray = [];
      this.selectedTypeArray = [];
      this.getAllUsers("all");
    },

    removeFilter(item) {
      if (item.value == "allTypes") {
        this.selectedTypeArray = [];
        this.filter.type = {
          all: false,
          user: false,
          admin: false,
        };
      } else if (this.filter.status[item.value]) {
        this.filter.status[item.value] = false;
        this.selectedArray.splice(this.selectedArray.indexOf(item), 1);
      }

      if (item.value == "all") {
        this.selectedArray = [];
        this.filter.status = {
          all: false,
          active: false,
          inactive: false,
          invited: false,
          join: false,
        };
      } else if (this.filter.type[item.value]) {
        this.filter.type[item.value] = false;
        this.selectedTypeArray.splice(this.selectedTypeArray.indexOf(item), 1);
      }
    },


    singleItem(item) {
      this.$store.commit("setSingleItem", item);
      this.$router.push(`/vendors/invoices/details/${item.id}`);
    },
    search(val) {
      this.allInvoices = this.filterArray.filter((el) => {
       if (
          el.client
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el.client
            .toLowerCase()
            .includes(val.toLowerCase());
        }  else if (
          el.invoiceNo &&
          el.invoiceNo.toString().toLowerCase().includes(val.toLowerCase())
        ) {
          return el.invoiceNo
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el.status &&
          el.status.toString().toLowerCase().includes(val.toLowerCase())
        ) {
          return el.status.toLowerCase().includes(val.toLowerCase());
        } else if (
          el.aging &&
          el.aging.toString().toLowerCase().includes(val.toLowerCase())
        ) {
          return el.aging.toLowerCase().includes(val.toLowerCase());
        } else if (
          el.amount &&
          el.amount.toString().toLowerCase().includes(val.toLowerCase())
        ) {
          return el.amount.toString().toLowerCase().includes(val.toLowerCase());
        }
      });
      if (this.allInvoices.length < 1) {
        this.filterItems = true;
      }
    },
  },
  created() {
    this.$store.commit("setNavItemFilter", false);
    this.$store.commit("setOverlayItemFilter", false);
    this.statusArray = this.userStatus;
    this.typeArray = this.userType;
    this.selectedArray = [];

    this.getData();
  },
};
</script>
<style scoped>
.submit-btn {
  color: white;
  width: 150px;
}
.mobile-only {
  display: none;
}
.display {
  display: grid;
}
@media screen and (max-width: 768px) {
  .mobile-display {
    display: none;
  }
  .mobile-only {
    width: 10px;
    display: flex;
  }
}
</style>
