<template>
    <div class="add-client-form">
        <v-row>
            <div class="col-md-12">
                 <h3 class="font">
                   Prior Authorization Request Determination
                 </h3>
            </div>
        </v-row>
        <v-row class="mt-4">
            <div class="col-md-12">
                <h4 class="font text-gray">Procedure Information</h4>
            </div>
        </v-row>
        <v-row>
            <div class="col-md-2">
                <label class="font text-gray" for="">Procedure Code Type <i class="fas fa-question-circle  yellow-icons"></i></label>
                <v-select solo></v-select>
            </div>
            <div class="col-md-2">
                <label class="font text-gray" for="">Procedure Code  <i class="fas fa-question-circle  yellow-icons"></i></label>
                <v-select solo></v-select>
            </div>
            <div class="col-md-5">
                <label class="font text-gray" for="">Procedure Description  <i class="fas fa-question-circle  yellow-icons"></i></label>
                <v-select solo></v-select>
            </div>
            <div class="col-md-3">
                <label class="font text-gray" for="">East Procedure Cost (USD) <i class="fas fa-question-circle  yellow-icons"></i></label>
                <v-text-field solo></v-text-field>
            </div>
        </v-row>
        <v-row justify="end">
            <v-btn class="blue-buttons mr-4">Cancel</v-btn>
            <v-btn class="blue-buttons" @click="dialog = true">Submit</v-btn>
        </v-row>
        <div class="text-center">
            <v-dialog
            v-model.trim="dialog"
            width="700"
            class="success-modal"
            >
            <v-card>
             <v-card-text class="success-modal" >
                <div>
                    <h1 class="text-underline font">Prior Authorization Request</h1>
                </div>
                <div class="font mt-5 mb-5">
                   <h2>
                       A prior authorization is not required for this procedure.
                    </h2> 
                </div>
                 <div class="text-center">
                        <svg width="210" height="210" viewBox="0 0 210 210" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M45 119.235L69.5 159.597C70.4293 161.209 71.6768 162.539 73.1385 163.478C74.6002 164.417 76.2339 164.937 77.9037 164.995C79.5735 165.052 81.231 164.647 82.7384 163.811C84.2458 162.975 85.5595 161.733 86.57 160.189L165 45" stroke="#0E8F32" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                            <circle cx="105" cy="105" r="102.5" stroke="#0E8F32" stroke-width="5"/>
                        </svg>
                </div>
                <h2  class=" font  mt-2 mb-5">
                        We will save this response on your Apaly Healt portal, un the Member's account, and you can also print a copy if you wish.
                </h2>
                <div class="row justify-space-between mt-2">
                    <v-btn class="blue-buttons" @click="dialog=false">Close</v-btn>
                    <v-btn class="blue-buttons" @click="dialog=false,dialog1=true">Print</v-btn>
                </div>
                </v-card-text>
            </v-card>
           </v-dialog>
             <v-dialog
            v-model.trim="dialog1"
            width="700"
            class="success-modal"
            >
            <v-card>
             <v-card-text class="success-modal" >
                <div>
                    <h1 class="text-underline font">Prior Authorization Request</h1>
                </div>
                <div class="font mt-5 mb-5">
                   <h2>
                       A prior authorization is required for this procedure.
                    </h2> 
                </div>
                 <div class="row text-center justify-center set-padding">
                       <v-btn class="blue-buttons" @click="$router.push(`/employer-advisor/employers/${routeParams}/prior-auth/new-request`)">Complete & Submit Prior Authorization Request</v-btn>
                </div>
                <h2  class=" font  mt-2 mb-5">
                      Click the button above to complete the prior authorization request for this procedure.
                </h2>
                <div class="row justify-space-between mt-2">
                    <v-btn class="blue-buttons" @click="dialog=false,dialog1=false">Close</v-btn>
                </div>
                </v-card-text>
            </v-card>
           </v-dialog>
        </div>
    </div>
</template>
<script>
export default{
    data(){
        return{
            dialog:false,
            dialog1:false,
        }
    },
     computed:{
           routeParams(){
            return this.$route.params.name
        }
    }
}
</script>
<style>
.set-padding{
    padding-top: 100px !important;
    padding-bottom: 100px !important;

}
</style>