<template>
  <v-row class="add-client-form">
    <div class="col-md-12">
      <successModal
        ref="success_modal"
        v-on:confirm="closeSuccessModal"
      ></successModal>
      <div class="row">
        <div class="col-md-12">
          <div class="col-md-12 row justify-space-between">
            <h3 class="form-title font">Invite Partner</h3>
          </div>
        </div>
      </div>
      <div class="form-row-height">
        <v-form v-model.trim="valid" ref="form" class="row">
          <div class="col-md-6 no-padding">
            <div class="col-md-12 pb-0">
              <label class="font text-gray" for="">First Name </label>
              <v-text-field
                required
                solo
                v-model.trim="firstName"
                :rules="requiredRules"
              ></v-text-field>
            </div>
            <div class="col-md-12 pt-0 pb-0">
              <label class="font text-gray" for="">Last Name</label>
              <v-text-field
                required
                v-model.trim="lastName"
                solo
                :rules="requiredRules"
              ></v-text-field>
            </div>
            <div class="col-md-12 pt-0 pb-0">
              <label class="font text-gray" for="">Email</label>
              <v-text-field
                required
                v-model.trim="email"
                :rules="emailRules"
                solo
              ></v-text-field>
            </div>
            <div class="col-md-12 pt-0 pb-0">
              <label class="font text-gray" for="">Phone</label>
              <v-text-field
                required
                v-model.trim="phone"
                :rules="phone.length > 0 ? phoneRules : []"
                solo
              ></v-text-field>
            </div>
          </div>
          <div class="col-md-6 no-padding">
            <div class="col-md-12 pb-0">
              <label class="font text-gray" for="">Company Name</label>
              <v-text-field
                required
                solo
                :rules="requiredRules"
                v-model.trim="companyName"
              ></v-text-field>
            </div>
            <div class="col-md-12 pt-0 pb-0">
              <label class="font text-gray" for="">Title</label>
              <v-text-field required v-model.trim="title" solo></v-text-field>
            </div>
            <v-row class="no-padding">
              <div class="col-md-6 pt-0 pb-0">
                <label class="font text-gray" for="">City</label>
                <v-text-field required v-model.trim="city" solo></v-text-field>
              </div>
              <div class="col-md-3 pt-0 pb-0">
                <label class="font text-gray" for="">State</label>
                <v-select
                  required
                  solo
                  :items="states"
                  item-text="name"
                  item-value="name"
                  v-model.trim="state"
                ></v-select>
              </div>
              <div class="col-md-3 zip-field-height pt-0 pb-0">
                <label class="font text-gray" for="">Zip</label>
                <v-text-field
                  required
                  :rules="zip.length > 0 ? zipRules : []"
                  solo
                  type="number"
                  v-model.trim="zip"
                ></v-text-field>
              </div>
            </v-row>
            <div class="col-md-12 pt-0 pb-0">
              <label class="font text-gray" for="">Type</label>
              <v-select
                solo
                :items="typeOptions"
                v-model.trim="type"
                :rules="requiredRules"
              >
              </v-select>
            </div>
          </div>
        </v-form>
      </div>
      <div class="col-md-12 d-flex align-end flex-column">
        <div>
          <v-btn
            class="blue-buttons mr-6 mb-2"
            @click="goBack"
            :disabled="loadData"
          >
            Cancel
          </v-btn>
          <v-btn
            @click="invite"
            tabindex="16"
            :disabled="loadData"
            class="blue-buttons mb-2"
          >
            Save & Invite
          </v-btn>
        </div>
      </div>
    </div>
    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
  </v-row>
</template>
<script>
import api from "../../../../services/api";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../TablesTemplate/ErrorDialog.vue";
export default {
  components: { successModal, errorModal },
  data() {
    return {
      show1: false,
      dialog: false,
      dialog1: false,
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      companyName: "",
      title: "",
      city: "",
      state: "",
      zip: "",
      type: "",
      states: [],
      loadData: false,
      valid: false,
      emailRules: [
        (v) => !!v || "This field is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
      zipRules: [
        (v) => (v && v.length == 5) || " Zip Code should be five digits long",
      ],
      typeOptions: [
        {
          text: "TPA",
          value: "Third Party Administrator (TPA)",
        },
        {
          text: "Broker Consultant",
          value: "brokerConsultant",
        },
        {
          text: "Analytics Consultant",
          value: "analyticsConsultant",
        },
      ],
    };
  },
  computed: {
    singlePartner() {
      return this.$store.getters.getSinglePartnerInEmployerAdvisor;
    },
    singleClient() {
      return this.$store.getters.getClientGetter;
    },
    routeParams() {
      return this.$route.params.name;
    },
  },
  created() {
    this.getStates();
  },
  methods: {
    getStates() {
      api()
        .get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },
    goBack() {
      this.$router.push("/employer/partners");
    },
    closeSuccessModal() {
      this.$router.push("/employer/partners");
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    invite() {
      this.$refs.form.validate();
      if (this.valid) {
        this.loadData = true;
        let data = {
          advisors: [
            {
              firstName: this.firstName,
              lastName: this.lastName,
              email: this.email,
              phone: this.phone,
              companyName: this.companyName,
              title: this.title,
              city: this.city,
              state: this.state,
              advisorType: this.type,
              zipCode: this.zip,
            },
          ],
        };
        let user = JSON.parse(localStorage.getItem("user"));
        let employerId = user.groupPortal[0].organization.employer;
        api()
          .post(`/employers/${employerId}/inviteAdvisors`, data)
          .catch((err) => {
            if (err) {
              this.$refs.error_modal.openPopUp(err.response.data.message);
              this.loadData = false;
            }
          })
          .then((res) => {
            if (res) {
              let text = "Email sent successfully";
              this.$refs.success_modal.openPopUp(text);
              this.loadData = false;

            }
          });
      }
    },
  },
};
</script>
