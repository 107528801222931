<template>
  <div class="top-class">
    <FullscreenLoader v-if="loader" />
    <!-- Desktop -->
    <v-layout row wrap class="whole-page hide-on-mobile">
      <v-flex xs12 sm12 md5 lg5 class="register-page-left">
        <div class="register-content-left">
          <div>
            <img class="apaly-logo" src="/svg/apalyBlue.svg" />
          </div>

          <div class="register-left-welcome">
            <span style="margin-bottom: -2vh; display: block"> Welcome</span>
            <span
              >to
              <span style="color: var(--secondary-color)">Apaly Health</span>
            </span>
          </div>

          <hr class="register-line-break" />

          <div class="register-left-info">
            <span class="info-line-spacing">
              <b>Apaly Health</b> is a B2B healthcare marketplace that
              <b>streamlines the business of healthcare</b> by connecting
              providers and point solution vendors to third-party
              administrators, brokers, self-funded employers and their members
            </span>
          </div>
        </div>
      </v-flex>
      <div class="divider"></div>
      <v-flex xs12 sm12 md6 lg6 class="register-page-right">
        <div class="register-content-right">
          <div class="register-form-container">
            <v-form class="col-12 info-form" ref="form" v-model.trim="valid">
              <div class="center-content mb-8">
                <img class="apaly-logo-2" src="/svg/apalyBlue.svg" />
                <span class="register-header-text">
                  Login to your account
                </span>
              </div>

              <v-row class="register-row">
                <v-col md="12" sm="12" class="pb-0" >
                  <label for="userEmail" class="register-label">E-mail</label>
                  <v-text-field
                    dense
                    placeholder="Enter company email"
                    type="email"
                    ref="emailField"
                    @input="checkFormValidation()"
                    @keydown.enter="submit()"
                    v-model.trim="email"
                    :rules="emailRules"
                    class="apaly_text login-text-field"
                  ></v-text-field>
                </v-col>
                
              </v-row>

              <v-row class="register-row" >
                <v-col md="12" sm="12">
                  <label for="loginPasswordField" class="register-label"
                    >Password</label
                  >
                  <v-text-field
                    id="loginPasswordField"
                    placeholder="Enter password"
                    name="accountPassword"
                    v-model.trim="password"
                    required
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    @click:append="show = !show"
                    @input="checkFormValidation()"
                    @keydown.enter="submit()"
                    :rules="passRules"
                    class="apaly_text login-text-field"
                  ></v-text-field>
                  
                </v-col>
              </v-row>
              
              <br>

              <div class="error-message">
                <span
                    style="color: #ff5252; font-size: 14px;"
                    v-if="error === 'User not inactive'">
                    Your account is inactive. <br>
                    Please contact support at <span style="color: var(--secondary-color)">support@apaly.com</span> if you have any questions
                </span>
                <span
                    style="color: #ff5252; font-size: 14px"
                    v-else-if="error">{{ error }}
                </span>
             </div>
             
              <v-row class="register-row ">
                <v-col md="12" sm="12" class="pb-0">
                  <v-btn
                    text
                    class="primary-button"
                    style="width: 100%"
                    @click="submit()"
                  >
                    Login
                  </v-btn>
                </v-col>
                <v-col md="12" sm="12" class="pb-0">
                  <v-btn
                    text
                    outlined
                    class="secondary-button"
                    style="width: 100%"
                    @click="$router.push('/change-pass')"
                  >
                    Forgot Password
                  </v-btn>
                </v-col>
              </v-row>

              <hr class="login-line-break" />

              <div class="center-content">
                <span class="login-footer-text">
                  Don’t have an account?
                  <b><a @click="$router.push('/register')">Register here</a></b>
                </span>
              </div>
            </v-form>
          </div>
        </div>
      </v-flex>
    </v-layout>

    <!-- MOBILE -->
    <div class="register-content-right hide-on-desktop">
      <div class="register-form-container">
        <v-form class="col-12 info-form" ref="form" v-model.trim="valid">
          <div class="center-content mb-8">
            <img class="apaly-logo-2" src="/svg/apalyBlue.svg" />
            <span class="register-header-text">
              Login to your account
            </span>
          </div>

          <v-row class="register-row">
            <v-col md="12" sm="12" class="pb-0" >
              <label for="userEmail" class="register-label">E-mail</label>
              <v-text-field
                dense
                placeholder="Enter company email"
                type="email"
                ref="emailField"
                @input="checkFormValidation()"
                @keydown.enter="submit()"
                v-model.trim="email"
                :rules="emailRules"
                class="apaly_text login-text-field"
              ></v-text-field>
            </v-col>
            
          </v-row>

          <v-row class="register-row" >
            <v-col md="12" sm="12">
              <label for="loginPasswordField" class="register-label"
                >Password</label
              >
              <v-text-field
                id="loginPasswordField"
                placeholder="Enter password"
                name="accountPassword"
                v-model.trim="password"
                required
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                @click:append="show = !show"
                @input="checkFormValidation()"
                @keydown.enter="submit()"
                :rules="passRules"
                class="apaly_text login-text-field"
              ></v-text-field>
              
            </v-col>
          </v-row>
          
          <br>

          <div class="error-message">
            <span
                style="color: #ff5252; font-size: 14px;"
                v-if="error === 'User not inactive'">
                Your account is inactive. <br>
                Please contact support at <span style="color: var(--secondary-color)">support@apaly.com</span> if you have any questions
            </span>
            <span
                style="color: #ff5252; font-size: 14px"
                v-else-if="error">{{ error }}
            </span>
          </div>
          
          <v-row class="register-row ">
            <v-col md="12" sm="12" class="pb-0">
              <v-btn
                text
                class="primary-button"
                style="width: 100%"
                @click="submit()"
              >
                Login
              </v-btn>
            </v-col>
            <v-col md="12" sm="12" class="pb-0">
              <v-btn
                text
                outlined
                class="secondary-button"
                style="width: 100%"
                @click="$router.push('/change-pass')"
              >
                Forgot Password
              </v-btn>
            </v-col>
          </v-row>

          <hr class="login-line-break" />

          <div class="center-content">
            <span class="login-footer-text">
              Don’t have an account?
              <b><a @click="$router.push('/register')">Register here</a></b>
            </span>
          </div>
        </v-form>
      </div>
    </div>

  </div>
</template>
<script>
import api from "../../services/api";
import Vue from "vue";
import { mapActions } from "vuex";
import FullscreenLoader from "../Common/FullscreenLoader.vue";
export default {
  components: { FullscreenLoader },
  data() {
    return {
      email: "",
      changePassEmail: "",
      password: "",
      valid: false,
      validPass: "",
      show: false,
      error: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
      passRules: [(v) => !!v || "Password is required"],
      dialog: false,
      dialog1: false,
      passsError: "",
      error: "",
      emailError: "",
      advisorDialog: false,
      loader: false,
      isFormValid: false,
      // rules: [v => v.length <= 5 || 'Max 5 characters'],
    };
  },
  created() {
    if (this.$route.query.email) {
        this.email = this.$route.query.email;
    }
  },
  methods: {
    ...mapActions(["loginUser"]),
    eventHandling(event) {
      if (event.keyCode == 13) {
        this.submit();
      }
    },
    updateEmailRules() {
        this.emailRules = [
            (v) => !!v || 'E-mail is required',
            (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            (v) => !this.emailError || this.emailError,
        ];
    },
    checkFormValidation() {
        this.emailError = "";
        this.isFormValid = this.$refs.form.validate() && this.password !== '';
    },
    lowerCaseEmail() {
      // this.email = this.email.toLowerCase()
    },
    submit() {
        if (!this.$refs.form.validate()) {
            this.error = "Input valid email and password"
        } else {
            this.loader = true;
            if (localStorage.getItem("user")) {
                localStorage.removeItem("user");
            }
            if (this.$cookies.get("token")) {
                this.$cookies.remove("token");
            }

            this.passsError = "";
            this.error = "";
            this.$refs.form.validate();
            if (this.valid) {
                let user = {
                    email: this.email.toLowerCase(),
                    password: this.password,
                };
                api()
                    .post("/auth/login", user)
                    .catch((err) => {
                        this.loader = false;
                        // this.error = "Something went wrong. Please try again later.";

                        if (err.response.data?.message === 'User not found') {
                            this.emailError = 'Email is not linked to any accounts' 
                            this.updateEmailRules();
                        } else {
                            this.error = err.response.data?.message
                                ? err.response.data.message
                                : "Something went wrong. Please try again later.";
                        }
                        return;
                    })
                    .then((res) => {
                        if (res && res.data && res.data) {
                            const userData = res.data;

                            this.$cookies.set("token", userData.accessToken);
                            localStorage.setItem("user", JSON.stringify(userData));
                            Vue.prototype.$role = userData.role;

                            // pehite5688@evilant.com
                            if (userData.superAdmin == true) {
                                this.$router.replace("/superadmin");
                            } else if (this.$route && this.$route.query.redirect) {
                                this.$router.replace(this.$route.query.redirect);
                            } else if (userData.type == "employerAdvisor") {
                                this.$router.replace("/employer-advisor/home");
                            } else if (userData.type == "providerAdvisor") {
                                this.$router.replace("/provider-advisor/home");
                            } else if (userData.type == "employer") {
                                this.$router.replace("/employer/home");
                            } else if (userData.type == "provider") {
                                if (userData.entity.subType == "DPC") {
                                    //pehite5688@evilant.com
                                    //dpcprovider@tdb.al - 29Apaly31#
                                    localStorage.setItem("type", "DPC");
                                    this.$router.replace("/dpc-providers/home");
                                } else {
                                    this.$router.replace("/provider/home");
                                }
                            } else if (userData.type == "member") {
                                this.$router.replace("/member/home");
                            } else if (userData.type == "vendor") {
                                //waxote5971@canyona.com - DANIELA22@
                                //  this.$router.replace("/solution-vendor/home");
                                this.$router.replace("/vendors/home");
                            } else if (userData.type == "tpa") {
                                //cojita8699@hostovz.com
                                this.$router.replace("/tpa/home");
                            }
                        } else {
                            this.error = res.data.message;
                        }
                    })
                    .finally(() => {
                        this.loader = false;
                    });
            }
        }
    },
    changePass() {
      this.passsError = "";
      this.error = "";
      this.changePassEmail = this.changePassEmail.toLowerCase();
      this.$refs.changePassForm.validate();

      if (this.validPass) {
        let email = {
          email: this.changePassEmail,
        };
        api()
          .get(
            `https://validate.consislab.net/check/v4/${this.changePassEmail}`
          )
          .catch((err) => {
            this.passsError = "Please Try Again";
          })
          .then((res) => {
            this.dialog = false;
            if (res.data.Response.result == "VALID") {
              api()
                .post(`/auth/forgotPassword`, email)
                .catch((err) => {
                  this.passsError = "Please Try Again";
                })
                .then((res) => {
                  if (res) {
                    this.dialog1 = true;
                  } else {
                    this.passsError = "Please Try Again";
                  }
                  this.$refs.changePassForm.reset();
                });
            }

            this.$refs.form.reset();
          });
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import url("../../assets/colors.css");

// Left screen styles
.whole-page {
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  font-family: "Open Sans";
}

.apaly-logo {
  width: 17.8vh;
}

.register-page-left {
  background-color: #ffffff;
  width: 100%;
  height: 100vh;
  padding: 1.5vw;
}

.register-content-left {
  margin: 8vh;
}

.register-left-welcome {
  margin-top: 3.8vh;
  font-size: 6.2vh;
  color: var(--primary-color);
  font-weight: bold;
}

.register-line-break {
  margin-top: 4vh;
  margin-bottom: 5.3vh;
  width: 5vw;
  border: 0;
  height: 0.6vh;
  background-color: var(--secondary-color);
  border-radius: 5px;
}

.register-left-info {
  font-size: 3vh;
  padding-right: 2vw;
  color: var(--primary-color);
}

.info-line-spacing {
  line-height: 5.8vh;
}

.register-form-logo-2 {
  width: 200px;
  position: absolute;
  left: 0;
}

.register-form-text {
  margin-top: 2vh;
  font-size: 6vh;
  color: var(--primary-color);
  display: inline-block;
  position: relative;
}

.divider {
  width: 1px;
  margin-left: -2vw;
  background-color: black;
  height: 90vh;
}

// Right side styles
.register-page-right {
  height: 100vh;
  padding-bottom: 2vh;
  margin-top: -8vh;
}

.register-content-right {
  width: 60vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.register-form-container {
  width: 30vw;
}

.apaly-logo-2 {
  width: 13vh;
  margin-bottom: 1vh;
}

.register-header-text {
  font-size: 2vw;
  font-weight: bold;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.register-form-title {
  font-size: 5vh;
  padding-left: 7vw;
  padding-right: 7vw;
  padding-top: 1vh;
  line-height: 6vh;
  margin-bottom: 2.8vh;
  display: block;
  font-weight: bold;
  text-align: center;
}

.register-form-title-2 {
  font-size: 5vh;
  padding-left: 3vh;
  padding-right: 3vh;
  line-height: 6vh;
  margin-bottom: 6vh;
  display: block;
  font-weight: bold;
}

.register-form-subtitle {
  font-size: 2.2vh;
  margin-bottom: 5vh;
  display: block;
  color: var(--inactive-color);
  text-align: center;
}

.register-label {
  font-size: 1.5vh;
  font-weight: bold;
}

.register-row {
    margin-bottom: 0.6vh;
}

.login-text-field {
  border-radius: 12px;
  border: 1px;
  margin-bottom: 10px;
}

.login-line-break {
  border: none;
  margin-top: 6vh;
  margin-bottom: 4vh;
  height: 1.2px;
  background-color: var(--gray-color-2) !important;
}

.login-footer-text {
  font-size: 1.7vh;
}

.apaly-color {
  color: #16407a;
}

.primary-button {
  font-weight: bold;
}

.secondary-button {
  font-weight: bold;
}

.error-message {
    margin-top: -2vh;
    margin-bottom: 2vh;
    line-height: 1.5vh;
    text-align: center;
}

@media only screen and (max-width: 1264px) {
  .mobile-font {
    font-size: 20px !important;
  }
}
// Desktop
@media only screen and (min-width: 964px) {
  .hide-on-desktop {
    display: none;
  }
}

// Mobile
@media only screen and (max-width: 963px) {
  .register-content-right {
    width: 100%;
  }
  .hide-on-mobile {
    display: none;
  }
  .register-form-container {
    width: 90% !important;
  }
  .register-header-text {
    font-size: 20px;
  }
}
</style>
