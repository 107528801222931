

const state ={
    singleContractInEmployer:null,
    contractTypeView:''
}
const getters = {
    getSingleContractInEmployer(state){
        return state.singleContractInEmployer
    },
    showRatesView(state){
        return state.contractTypeView
    }
}
const mutations = {
    setSingleContractinEmployer(state,contract){
        state.singleContractInEmployer = contract
    },
    singleContractView(state, type){
        state.contractTypeView = type
    }
}

export default{
    state,
    getters,
    mutations,
}