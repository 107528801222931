<template>
    <div class="container-fluid background-gray">
      <Header
        @expantSideBarOnHover="expantSideBarOnHover"
        @hideSideBar="hideSideBar"
      />
      <v-row class="background-gray">
        <div
          class="pb-0 vh-height sidebar-wrapper"
          v-if="showSideBar && !display"
        >
          <SideBar
            :mini="miniClass"
            @closeSideBar="closeSideBar"
            :windowWidth="display"
            :showSideBar="showSideBar"
          ></SideBar>
        </div>
        <div
           :class="
            showSideBar
              ? 'pb-0  vh-height sidebar-wrapper'
              : 'pb-0 vh-height sidebar-wrapper-mini'
          "
          v-else-if="display"
        >
          <SideBar
            :mini="miniClass"
            @closeSideBar="closeSideBar"
            :windowWidth="display"
            :showSideBar="showSideBar"
          ></SideBar>
        </div>
        <div
          v-if="mobileSideBar"
         :class="!showSideBar ? 'main-wrapper' : 'main-wrapper-mini'"
        >
          <div class="context no-padding-left">
            <div class="row no-padding">
              <RoutePath :path="path"></RoutePath>
            </div>
            <BlueNavbar :tab="tab"></BlueNavbar>
            <div
              :class="hideWhiteBack ? 'home-tables' : 'white-background tables'"
            >
              <Home
                v-if="route == '/superadmin' || route == '/superadmin/home'"
              ></Home>
              <Providers></Providers>
              <TPAs></TPAs>
              <Vendors></Vendors>
              <Settings></Settings>
               <Profile v-if="$route.path.includes('profile')" />
            </div>
          </div>
        </div>
      </v-row>
    </div>
  </template>
  <script>
  import Header from "../components/Sections/SuperAdmin//Header.vue";
  import SideBar from "../components/Sections/SuperAdmin/Layout/SideBar.vue";
  import RoutePath from "../components/TablesTemplate/RoutePath.vue";
  import BlueNavbar from "../components/Sections/SuperAdmin/Layout/BlueNavbar.vue";
  import Home from "../components/Sections/SuperAdmin/Home/Home.vue";
  
  import Providers from "../components/Sections/SuperAdmin/Providers/ProviderTemplate.vue";
  import TPAs from "../components/Sections/SuperAdmin/TPAs/TPATemplate.vue";
  import Vendors from "../components/Sections/SuperAdmin/Vendors/VendorTemplate.vue";

  import Settings from "../components/Sections/SuperAdmin/Settings/SettingsTemplate.vue";
  import Profile from '../components/TablesTemplate/MyProfile.vue'

  import api from '../services/api'
  
  export default {
    components: {
      Header,
      SideBar,
      RoutePath,
      BlueNavbar,
      Home,
      Providers, TPAs, Vendors,
      Settings,
      Profile
    },
    data() {
      return {
        sideBar: true,
        showSideBar: false,
        display: true,
        windowWidth: window.innerWidth,
        page: "",
        clientName: "",
        miniClass: false,
        clientItem: null,
        path: "",
        tab: "",
      };
    },
    created() {
      if (this.windowWidth < 959) {
        this.display = false;
      } else {
        this.display = true;
      }
      
    },
    computed: {
      hideWhiteBack() {
        let hide = false;
        if (this.route == "/provider" || this.route == "/provider/home") {
          hide = true;
        }
        return hide;
      },
      mobileSideBar() {
        let show;
        if (this.display) {
          show = true;
        } else if (!this.showSideBar && !this.display) {
          show = true;
        }
        return show;
      },
      route() {
        return this.$route.path;
      },
    },
    mounted() {
      this.$nextTick(() => {
        window.addEventListener("resize", this.onResize);
      });

      api()
        .get('/superadmin')
        .then(res => {
            if(res.data && res.data.email){
                localStorage.setItem('user', JSON.stringify(res.data))
            }
        })
    },
    beforeDestroy() {
      window.removeEventListener("resize", this.onResize);
    },
  
    methods: {
      getProvidersByClient(item) {
        if (item) {
          this.clientItem = item;
          this.clientName = item.name;
        }
      },
      closeSideBar() {
        if (!this.display) {
          this.showSideBar = false;
        } else {
          this.showSideBar = true;
        }
      },
      getClientName(name) {
        this.clientName = name;
      },
  
      onResize() {
        this.windowWidth = window.innerWidth;
        if (this.windowWidth < 959) {
          this.display = false;
        } else {
          this.display = true;
        }
      },
      hideSideBar() {
        this.showSideBar = !this.showSideBar;
      },
      expantSideBarOnHover(mini) {
        this.showSideBar = !this.showSideBar;
        this.miniClass = !this.miniClass;
      },
    },
  };
  </script>
  
  