<template>
  <div class="form-div">
    <div class="col-lg-8 col-xl-6 col-md-6">
      <h1 class="blue-text text-center">What best describes you?</h1>
      <v-card
        class="d-flex aling-center mt-4 mb-4 select-type-cards"
        v-for="(t, index) in types"
        :key="index"
      >
        <div class="col-xl-4 col-lg-4 col-md-4 text-center">
          <h3 class="blue-text mt-2">{{ t.type }}</h3>
        </div>
        <div class="col-md-8 col-lg-8 col-xl-8 text-center">
          <v-btn
            class="select-btn col-md-6 mt-1"
            @click="$router.push('/register/' + t.value)"
            style="height: 45px"
            text
          >
            Select
          </v-btn>
        </div>
      </v-card>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      types: [
        {
          type: "Employer",
          value: "employer",
        },
        {
          type: "Broker / Consultant",
          value: "employerAdvisor",
        },
        {
          type: "Third Party Admin",
          value: "thirdPartyAdmin",
        },
        {
          type: "DPC Provider",
          value: "provider",
        },
        {
          type: "Provider Partner",
          value: "providerAdvisor",
        },
             {
          type: "Plan Member",
          value: "member",
        },
        {
          type: "Vendor",
          value: "vendor",
        },
      ],
    };
  },
  created() {
    this.$store.commit(
      "sideBarTitleAndDesc",
      "“If you look at what you have in life, you’ll always have more...”"
    );
    this.$store.commit("sideBarDesc", "Oprah Winfrey");
  },
};
</script>
