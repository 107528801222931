<template>
    <v-row class="add-client-form no-padding">
        <successModal  ref="success_modal" v-on:confirm="sendMail"></successModal>
        <div class="col-md-12 ">
            <div class="row">
                <div class="col-md-12">
                    <h3 class="font">Add New Member</h3>
                </div>
            </div>
            <v-form class="row" ref="form" v-model.trim="valid">
                <div class="col-lg-6 col-md-6 col-12">
                    <label class="font text-gray">First Name </label>
                    <v-text-field
                            required
                            solo
                            :rules="requiredRules"
                            v-model.trim="firstName"
                    ></v-text-field>
                    <label class="font text-gray">Last Name</label>
                    <v-text-field
                            required
                            solo
                            :rules="requiredRules"
                            v-model.trim="lastName"
                    ></v-text-field>
                    <label class="font text-gray">Email</label>
                    <v-text-field
                            required
                            solo
                            :rules="emailRules"
                            v-model.trim="email"
                    ></v-text-field>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-6">
                            <label class="font text-gray">Sex</label>
                                <div :class="gender == 'male' ? 'col-md-6 blue-buttons  text-center  border-radius ' : 'col-md-6 gender-buttons  text-center  text-gray'" tabindex="4" style="height: 48px;padding-top: 0.7rem" @click="getGender('male')">Male</div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-6">
                            <div :class="gender == 'female' ? 'col-md-6 blue-buttons  text-center  border-radius ' : 'col-md-6 gender-buttons  text-center  text-gray'" tabindex="5" style="height: 48px;padding-top: 0.7rem;margin-top: 1.6rem" @click="getGender('female')">Female</div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-4">
                            <label class="font text-gray">Birth Month</label>
                            <v-text-field
                                    required
                                    solo
                                    :rules="requiredRules"
                                    v-model.trim="birthMonth"
                            ></v-text-field>
                        </div>
                        <div class="col-lg-4 col-md-4 col-4">
                            <label class="font text-gray">Day</label>
                            <v-text-field
                                    required
                                    solo
                                    type="number"
                                    :rules="requiredRules"
                                    v-model.trim="birthDay"
                            ></v-text-field>
                        </div>
                        <div class="col-lg-4 col-md-4 col-4">
                            <label class="font text-gray">Year</label>
                            <v-text-field
                                    required
                                    solo
                                    
                                     type="number"
                                    :rules="requiredRules"
                                    v-model.trim="birthYear"
                            ></v-text-field>
                        </div>
                    </div>
                    <label class="font text-gray" >Social Security Number</label>
                    <v-text-field
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            tabindex="9"
                            required
                            solo
                            :rules="requiredRules"
                            type="password"
                            v-model.trim="securityNumber"
                    ></v-text-field>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                    <label class="font text-gray">Member Type</label>
                    <v-select solo  :items="[{text:'Employee', value:'employee'},{text:'Dependent', value:'dependent'}]"  :rules="requiredRules" v-model.trim="memberType"></v-select>
                    <label class="font text-gray">Home Address 1</label>
                    <v-text-field
                            required
                            solo
                            :rules="requiredRules"
                            v-model.trim="address1"
                    ></v-text-field>
                    <label class="font text-gray">Home Address 2</label>
                    <v-text-field
                            required
                            solo
                            :rules="requiredRules"
                            v-model.trim="address2"
                    ></v-text-field>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-6">
                            <label class="font text-gray">City</label>
                            <v-text-field
                                    required
                                    solo
                                    :rules="requiredRules"
                                    v-model.trim="city"
                            ></v-text-field>
                        </div>
                        <div class="col-lg-3 col-md-3 col-3">
                            <label class="font text-gray">State</label>
                            <v-select
                                    required
                                        :rules="requiredRules"
                                        :items="states"
                                        tabindex="14"
                                        item-text="name"
                                        item-value='name'
                                        v-model.trim="state"
                                        solo></v-select>
                        </div>
                        <div class="col-lg-3 col-md-3 col-3">
                            <label class="font text-gray">Zip</label>
                            <v-text-field
                                    required
                                    solo
                                    :rules="requiredRules"
                                    v-model.trim="zip"
                            ></v-text-field>
                        </div>
                    </div>
               
                </div>
                <div  style="padding-top: 3rem;display: flex;justify-content: end;gap: 2rem;width: 100%">
                    <v-btn class="blue-buttons" @click="$router.push(`/employer/members`)">Cancel</v-btn>
                    <v-btn class="blue-buttons" @click="addMember">Add Member</v-btn>
                </div>
            </v-form>
        </div>
        <errorModal  ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
    </v-row>

</template>

<script>
    import api from "../../../../../services/api";
    import errorModal from '../../../../TablesTemplate/ErrorDialog.vue'
    import successModal from '../../../../TablesTemplate/SuccessDialog.vue'

    export default {
        name: 'AddMember',
        components:{
            successModal,
            errorModal
        },
        data(){
            return{
                show1:false,
                firstName:'',
                lastName:'',
                email:'',
                gender:'',
                birthMonth:'',
                birthDay:'',
                birthYear:'',
                memberType:'',
                address1:'',
                address2:'',
                city:'',
                zip:'',
                state:'',
                securityNumber:'',
                valid:false,
                requiredRules:[
                    v => !!v || 'This field is required',
                ],
                states:[],
                emailRules: [
                    v => !!v || 'This field is required',
                    v => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || 'E-mail must be valid',
                ],
            }
        },
        created(){

            this.getStates()
        },
        methods:{
              getStates(){
                api().get('/api/states').then((res)=>{
                    res.data.forEach((el)=>{
                        if(el.active){
                            this.states.push(el);
                        }
                    })
                })
                },
            sendMail(){
                this.$router.push(`/employer/members`)
            },
              closeErrorModal(){
                    this.$refs.error_modal.closePopUp();
            },
            addMember(){
                this.$refs.form.validate();
                if(this.valid){
                    let data = {
                        "firstName":this.firstName,
                        "lastName": this.lastName,
                        "email": this.email,
                        "zipCode": this.zip,
                        "sex": this.gender,
                        "birthdate": `${this.birthDay}/${this.birthMonth}/${this.birthYear}`,
                        "ssn": this.securityNumber,
                        "memberType": this.memberType,
                        "address1": this.address1,
                        "address2": this.address2,
                        "city": this.city,
                        "state": this.state
                    };

                    let user = JSON.parse(localStorage.getItem('user'));
                    let employerId = user.groupPortal[0].organization.employer
                    api().post(`/employers/${employerId}/members`, data).catch((err)=>{
                        if(err){
                            this.$refs.error_modal.openPopUp(err.response.data.message)
                        }
                    }).then((res)=>{
                        if(res){
                            this.$refs.success_modal.openPopUp('Member created and invited to Apaly Portal successfully')
                        }
                    })

                }
            },
            getGender(gender){
                this.gender = gender
            },
            showPass() {
                let x = document.querySelectorAll(".show_pass");

                if (x.type === "password") {
                    document.querySelector('.fa-eye').classList.add('fa-eye-slash');
                    document.querySelector('.fa-eye').classList.remove('fa-eye');
                    x.type = "text";
                } else {
                    document.querySelector('.fa-eye-slash').classList.add('fa-eye');
                    document.querySelector('.fa-eye-slash').classList.remove('fa-eye-slash');
                    x.type = "password";
                }
            }
        }
    }
</script>