<template>
  <div class="container-fluid background-gray">
    <Header
      @expantSideBarOnHover="expantSideBarOnHover"
      @hideSideBar="hideSideBar"
    />
    <v-row class="background-gray">
      <div
        class="pb-0 vh-height sidebar-wrapper"
        v-if="showSideBar && !display"
      >
        <SideBar
          :mini="miniClass"
          @closeSideBar="closeSideBar"
          :windowWidth="display"
          :showSideBar="showSideBar"
        ></SideBar>
      </div>
      <div
        :class="
          showSideBar
            ? 'pb-0  vh-height sidebar-wrapper'
            : 'pb-0 vh-height sidebar-wrapper-mini'
        "
        v-else-if="display"
      >
        <SideBar
          :mini="miniClass"
          @closeSideBar="closeSideBar"
          :windowWidth="display"
          :showSideBar="showSideBar"
        ></SideBar>
      </div>
      <div
        v-if="mobileSideBar"
        :class="!showSideBar ? 'main-wrapper' : 'main-wrapper-mini'"
      >
        <div class="context no-padding-left">
          <div class="row no-padding">
            <RoutePath :path="path"></RoutePath>
          </div>
          <BlueNavbar :tab="tab"></BlueNavbar>
         
          <div
            :class="hideWhiteBack ? 'home-tables' : 'white-background tables'"
          >
            <Home v-if="route == '/member' || route == '/member/home'"></Home>
            <Providers></Providers>
            <ServicesTemplate></ServicesTemplate>
            <VisitPassesTemplate style="padding: 1rem"></VisitPassesTemplate>
            <PriorAuthsTemplate></PriorAuthsTemplate>
            <ProfileTemplate></ProfileTemplate>
          </div>
        </div>
      </div>
    </v-row>

    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <MemberFooter class="d-lg-none d-block"></MemberFooter>
  </div>
</template>
<script>
import Header from "../components/Sections/Members/Header.vue";
import SideBar from "../components/Sections/Members/Layouts/MemberSideBar.vue";
import RoutePath from "../components/TablesTemplate/RoutePath.vue";
import BlueNavbar from "../components/Sections/Members/Layouts/BlueNavbar.vue";
import Home from "../components/Sections/Members/Home.vue";
import Providers from "../components/Sections/Members/Providers/ProvidersTemplate.vue";
import MemberFooter from "../components/Sections/Members/Footer/MemberFooter";
import ServicesTemplate from "../components/Sections/Members/Services/ServicesTemplate.vue";
import VisitPassesTemplate from "../components/Sections/Members/VisitPasses/VisitPassesTemplate.vue";
import PriorAuthsTemplate from "../components/Sections/Members/PriorAuts/PriorAuthsTemplate";
import ProfileTemplate from "../components/Sections/Members/MemberProfile/ProfileTemplate";
import successModal from "../components/TablesTemplate/SuccessDialog.vue";
import api from "../services/api";

export default {
  components: {
    successModal,
    Header,
    SideBar,
    RoutePath,
    BlueNavbar,
    Home,
    Providers,
    MemberFooter,
    ServicesTemplate,
    VisitPassesTemplate,
    PriorAuthsTemplate,
    ProfileTemplate,
  },
  data() {
    return {
      sideBar: true,
      showSideBar: false,
      display: true,
      windowWidth: window.innerWidth,
      page: "",
      clientName: "",
      miniClass: false,
      clientItem: null,
      path: "",
      tab: "",
    };
  },
  created() {
    if (this.windowWidth < 959) {
      this.display = false;
    } else {
      this.display = true;
    }
  },
  computed: {
     hideWhiteBack() {
      let hide = false;

      if (this.$route.path == "/member/home" || this.$route.path == "/member" || this.$route.path == "/member/profile") {
        hide = true;
      }
      return hide;
    },
    mobileSideBar() {
      let show;
      if (this.display) {
        show = true;
      } else if (!this.showSideBar && !this.display) {
        show = true;
      }
      return show;
    },
    route() {
      return this.$route.path;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  created() {},

  methods: {
   
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
      this.$router.replace("/login");
    },
    getProvidersByClient(item) {
      if (item) {
        this.clientItem = item;
        this.clientName = item.name;
      }
    },
    closeSideBar() {
      if (!this.display) {
        this.showSideBar = false;
      } else {
        this.showSideBar = true;
      }
    },
    getClientName(name) {
      this.clientName = name;
    },

    onResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 959) {
        this.display = false;
      } else {
        this.display = true;
      }
    },
    hideSideBar() {
      this.showSideBar = !this.showSideBar;
    },
    expantSideBarOnHover(mini) {
      this.showSideBar = !this.showSideBar;
      this.miniClass = !this.miniClass;
    },
  },
};
</script>
<style >
@media screen and (max-width: 992px) {
  .margin-bottom {
    margin-bottom: 2rem;
  }
}
</style>

