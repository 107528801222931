<template>
    <footer class="footer-ic d-flex justify-space-around">
            <div  @click="changePage('home')">
                <img src="/cards/member-home.png" class="img-icon img-fluid" alt="">
            </div>
            <div  @click="changePage('care')">
                <img src="/cards/member-care.png" class="img-icon img-fluid" alt="">
            </div>
            <div  @click="changePage('connect')"> 
                <img src="/cards/member-connect.png" class="img-icon img-fluid" alt="">
            </div>
            <div @click="changePage('passess')">
                <img src="/cards/member-passses.png" class="img-icon img-fluid" alt="">
            </div>
            <div @click="changePage('profile')">
                <img src="/cards/member-profile.png"  class="img-icon img-fluid"  alt="">
            </div>
    </footer>
</template>

<script>
    export default {
        name:'MemberFooter',
        methods:{
            changePage(page){
                if(page == 'home'){
                    this.$router.push('/member')
                }else if(page == 'care'){
                     this.$router.push('/member/providers')
                }
                else if(page == 'connect'){
                     this.$router.push('/member/services')
                }
                else if(page == 'passess'){
                     this.$router.push('/member/visit-passes')
                }
                else if(page == 'profile'){
                     this.$router.push('/member/profile')
                }
                else{
                    this.$store.commit('setMemberFooterIcons', page)
                } 
                
            }
        }
    }

</script>

<style scoped>
    footer{
        position: fixed;
        bottom: 0;
        width: 100%;
        background: #4072b7;
        z-index: 99999;
        padding-top: 0.5rem;
    }
    .footer-ic {
        align-items: center;
    }
    .img-icon{
        cursor: pointer;
    }
  
   
</style>