<template>
<div>
  <v-row class="mb-4 mt-3">
    <v-col class="col-md-7 col-lg-8 col-sm-5" cols="12">
        <h3>
           <span class="underline-text" v-if="route.includes('providers')"> Add Providers - Search Results</span>
           <span class="underline-text" v-else> Add Affilates - Search Results</span>

        </h3>
    </v-col>
    <v-col  class="col-md-5 col-lg-4 col-sm-7 row justify-md-end justify-sm-center buttons-left ml-2"  cols="12">
        <v-btn class="blue-buttons mr-10" @click="addProvider()" >
                Cancel
         </v-btn>
         <v-btn class="blue-buttons"  v-if="items.length > 0" @click="addProvider()">
                 Add Selected
         </v-btn>
    </v-col>
  </v-row>      
 <v-simple-table fixed-header  height="630px">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
           Provider Name
          </th>
          <th class="text-left">
           Type
          </th>
          <th class="text-left">
           Quality Rating
          </th>
          <th class="text-left">
           City
          </th>
          <th class="text-left">
          State
          </th>
          <th class="row no-padding">
               <v-checkbox></v-checkbox>
              <span class="mt-6">
                  Select All
              </span>  
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in items"
          :key="item.name"
        >
          <td><a  @click=" $router.push('/member/providers/result/npi')">{{ item.name }}</a> </td>
          <td>{{ item.type }}</td>
          <td>
             <v-rating
                  v-model.trim="item.rating"
                  color="yellow darken-3"
                  background-color="grey darken-1"
                  empty-icon="$ratingFull"
                  half-increments
                
              ></v-rating>
          </td>
          <td>{{ item.city }}</td>
          <td>{{ item.state }}</td>
          <td>
            <v-checkbox  @click="selected.push(item)"></v-checkbox>
          </td>
        
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</div>

    
</template>
<script>
  export default {
    data () {
      return {
        selected:[],
        items: [
          {
            name: 'Tampa General Hospital',
            npi: '123456789',
            type: 'Hospital',
            city:'Tampa',
            rating:4,
            state:'Florida',
            averageCharges: '280%',
            averageSavings:'35%',
            action:''
          },
          {
            name: 'USF Health',
            npi: '123456789',
           rating:2,
            type: 'Multi-Speciality',
            city:'Tampa',
            state:'Florida',
            averageCharges: '280%',
            averageSavings:'35%',
            action:''
          },
          {
            name: 'Florida Medical Clinic',
            npi: '123456789',
            rating:2.5,
            type: 'Multi-Speciality',
            city:'Tampa',
            state:'Florida',
            averageCharges: '280%',
            averageSavings:'35%',
            action:''
          },
          {
            name: 'Boyfront Health St Pete',
            npi: '123456789',
            type: 'Multi-Speciality',
            rating:3,
            city:'St.Petersburgh',
            state:'Florida',
            averageCharges: '280%',
            averageSavings:'35%',
            action:''
          },
          {
            name: 'Morton Plant Mease',
            npi: '123456789',
            rating:4,
            type: 'Hospital',
            city:'Tampa',
            state:'Florida',
            averageCharges: '280%',
            averageSavings:'35%',
            action:''
          },
          {
            name: 'St.Anthony\'s Hospital',
            npi: '123456789',
            rating:5,
            type: 'Hospital',
            city:'Tampa',
            state:'Florida',
            averageCharges: '280%',
            averageSavings:'35%',
            action:''
          },
          {
            name: 'Midway Clinic',
            npi: '123456789',
            rating:3,
            type: 'Hospital',
            city:'Tampa',
            state:'Florida',
            averageCharges: '280%',
            averageSavings:'35%',
            action:''
          },

        
        
        ],
      }
    },
   computed:{
        route(){
          return this.$route.path
          },
        getClient(){
            return this.$store.getters.getClientGetter
        }
    },
    methods:{
       addProvider(){
         this.$router.push('/member/providers')
       }
    }
  }
</script>
<style>
.buttons-left{
  justify-content: left;
  align-content: left !important;
  align-items: left !important;
}
</style>