<template>
  <v-app id="inspire"  >
    <v-main :class=" this.$route.path.includes('login') ||
     this.$route.path.includes('register') ||
     this.$route.path.includes('verify') || 
     this.$route.path.includes('change-pass')|| 
     this.$route.path.includes('set-pass')
     ? '' : 'background-gray'" style="padding-bottom:0">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>
<script>

export default{
  
 mounted() {
    window.onpopstate = event => {
      if ($cookies.get('token') !== null && this.$route.path == "/login") {
        this.$router.push("/login"); 
      }
    };
    let Script = document.createElement("script");
    Script.setAttribute("src", "https://desk.zoho.com/portal/api/web/inapp/318353000002054081?orgId=675143780");
    Script.setAttribute("nonce", "");
    Script.setAttribute("defer", "defer");

    document.head.appendChild(Script);
  }
  
}

</script>
<style lang="scss">

  #main-sidebar {
    box-shadow: 1px 0 20px rgba(0, 0, 0, .08);
    -webkit-box-shadow: 1px 0 20px rgba(0, 0, 0, .08);
    .v-navigation-drawer__border {
      display: none;
    }
    .v-list {
      padding: 8px 15px;
    }
    .v-list-item {
      min-height: 35px;
      &__icon--text,
      &__icon:first-child {
        justify-content: center;
        text-align: center;
        width: 20px;
      }

    }
    .v-list-item__icon i {
      width: 20px;
    }
    .icon-size .v-list-group__items i {
      width: 16px;
      font-size: 16px;
    }
    .profile-bg {
      &.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
        opacity: 1;
      }
      .v-avatar {
        padding: 15px 0;
      }
    }
    .theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
  }
</style>
<style>


</style>