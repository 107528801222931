<template>
  <div class="row justify-center mt-4">
    <div class="col-md-6 mt-4">
      <v-card class="mx-auto row-height" max-width="1200" tile>
        <BlueHeader :title="'DPC Organizations'" :back="false" :settings="false" :add="false"></BlueHeader>
        <div class="clearing-house-search">
          <v-text-field
              class="mr-5 filter-serach-input"
              @input="searchInput"
              solo
              append-icon="fa fa-search"
              placeholder="Search DPC Organizations"
          ></v-text-field>
        </div>
        <div class="mt-5">
          <v-list>
            <v-list-item-group color="primary" v-if="!filterItems">
              <v-list-item

                  id="clearing-house-list"
                  v-for="(item, i) in items"
                  :key="i"
                  @click="item.link? $router.push(item.link) : '' "
              >
                <v-list-item-content>
                  <v-list-item-title v-text="item.text" class="ml-5"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon color="warning"  v-text="item.icon" class="mr-3"></v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-item-group>
            <v-col md="12" cols="12" v-else>
              <v-alert
                  border="bottom"
                  colored-border
                  type="warning"
                  elevation="2"
                  class="text-gray"
              >
                Sorry! No results found
              </v-alert>
            </v-col>
          </v-list>
        </div>
      </v-card>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import Header from "../../DigitalVendorInvoices/Layout/HeaderPage.vue";
import api from "../../../../services/api";
// import tpas from "../../../../store/JSONS/tpa.json";
import BlueHeader from './BlueHeader.vue'
export default {
  data() {
    return {
      showDetails: false,
      dialog: false,
      singleItem: null,
      sortBy: "",
      type: "",
      solutions: [],
      favorites: [],
      filterItems: false,
      filteredArray: [],
      selectedItem: 1,
      items: [
        {
          text: "People One Health",
          icon: "mdi-arrow-right",
          link: "/dpc-organizations/people-health",
        },
        {
          text: "Eden Health",
          icon: "mdi-arrow-right",
          link: "",
        },
        {
          text: "Care ATC",
          icon: "mdi-arrow-right",
          link: "",
        },
        {
          text: "Everside",
          icon: "mdi-arrow-right",
          link: "",
        },
        {
          text: "First Primary Care",
          icon: "mdi-arrow-right",
          link: "",
        },
      ],
    };
  },
  components: {
    Header,
    BlueHeader,
  },
  computed: {
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
  },

  methods: {
    exportData(tableID, filename = "") {
      var elt = document.getElementById(tableID);
      var downloadLink;
      var dataType = "application/vnd.ms-excel";
      var tableSelect = elt;
      var tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

      // Specify file name
      filename = filename ? filename + ".xls" : "excel_data.xls";

      // Create download link element
      downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        var blob = new Blob(["\ufeff", tableHTML], {
          type: dataType,
        });
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = "data:" + dataType + ", " + tableHTML;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
    },
    getData() {
      api()
          .get("/tpas/invoices")
          .then((res) => {
            if (res) {
              // this.items = res.data;
              // this.filteredArray = res.data;
            }
          });
    },
    invite() {},
    add() {},
    exportFun() {
      this.exportData("tblData");
    },

    searchInput(val) {
      this.items = this.filteredArray.filter((el) => {
        if (el.text && el.text.toLowerCase().includes(val.toLowerCase())) {
          return el.text.toLowerCase().includes(val.toLowerCase());
        }
      });
      if (this.items.length < 1) {
        this.filterItems = true;
      } else {
        this.filterItems = false;
      }
    },

    searchMembers() {},
  },
  created() {

    this.filteredArray = this.items;
  },
};
</script>
<style>
.div-shadow {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
  rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.blue-navbar-clearing {
  background: #326ab3;
  display: flex;
  align-items: center;
}
.blue-navbar-clearing span{
  margin-top: 10px;
}
.clearing-house-search {
  position: absolute;
  margin: -23px;
  margin-left: 200px;
  width: 60%;
}
#clearing-house-list:hover{
  background:#fafafa!important;
  color: orange !important;

}
@media only screen and (max-width: 1500px) {
  .clearing-house-search {
    position: absolute;
    margin: -23px;
    margin-left: 75px;
    width: 80%;
  }
}
@media only screen and (max-width: 700px) {
  .clearing-house-search {
    position: absolute;
    margin: -23px;
    margin-left: 60px;
    width: 80%;
  }
}
</style>