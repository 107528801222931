<template>
  <div class="background-gray">
    <div class="mobile-background">
      <sideMenu></sideMenu>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import sideMenu from "@/components/Sections/DPCMember/Layout/sideMenu";
export default {
  components: {sideMenu},
  data() {
    return {};
  },
  created() {},
  computed: {},
  methods: {},
};
</script>

<style scoped src='./../assets/mobileStyle.css'></style>

<style scoped lang="scss">

.mobile-background {
  background-color: #16407A;
  max-width: 420px;
  max-height: 920px;
  margin: auto;
  height: 100vh; width: 100vw;
  overflow: hidden;
}

</style>
