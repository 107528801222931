<template>
    <div>
        <EmpSettings v-if="route=='/employer/settings'"></EmpSettings>
        <EmpSettingsAccount v-if="route=='/employer/settings/account'"></EmpSettingsAccount>
        <EmpSettingsUsers v-if="route=='/employer/settings/users'"></EmpSettingsUsers>
        <EmpSettingsAddUser v-if="route=='/employer/settings/users/add-new'"></EmpSettingsAddUser>
        <EmpSettingsInvite v-if="route=='/employer/settings/users/invite'"></EmpSettingsInvite>
        <EmpSettingDetail v-if="route=='/employer/settings/users/detail'"></EmpSettingDetail>

    </div>
</template>

<script>
    import EmpSettings from "./EmpSettings";
    import EmpSettingsAccount from "./Account/EmpSettingsAccount.vue";
    import EmpSettingsUsers from "./Users/EmpSettingsUsers.vue";
    import EmpSettingsAddUser from "./AddUser/EmpSettingsAddUser.vue";
    import EmpSettingsInvite from "./Invite/EmpSettingsInvite.vue";
    import EmpSettingDetail from "./Detail/EmpSettingDetail.vue";

    export default {
        name:'EmpContactTemplate',
        components: {
            EmpSettings,
            EmpSettingsAccount,
            EmpSettingsUsers,
            EmpSettingsAddUser,
            EmpSettingsInvite,
            EmpSettingDetail

        },
        computed:{
            route(){
                return this.$route.path
            }
        }
    }
</script>