<template>
 <v-row class="add-client-form no-padding">
   <successModal  ref="success_modal" v-on:confirm="closeSuccessModal"></successModal>
   <removeDialog  ref="dialog_delete" v-on:confirm="confirmRemove"></removeDialog>
   <errorModal  ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
   <div class="row mb-4">
        <div class="col-md-12">
            <h3 class="form-title font">Build a Bundle</h3>
        </div>
    </div>
    <v-tabs
      dark
      show-arrows
      style="background:#4072B7"
      v-model.trim="tab"
    >
    <v-tabs-slider style="background:orange"></v-tabs-slider>
     <v-tab
        style="background:#4072B7;"
        v-for="(item, i) in items"
        :key="i"
        class="col-md-4"
        :href="'#tab'+ i"
        >
       
       {{item.text}}
    </v-tab>
 </v-tabs>
   <div class="col-md-12"  v-if="itemtab == 'tab0'">
    <div class="col-md-12 padding">
    <form class="row">
      <div class="col-md-6 ">
        <div class="col-md-12">
          <label class="text-gray font" for="">Bundle Name</label>
          <v-text-field v-model.trim="bundle.bundleName" solo></v-text-field>
        </div>
        <div class="col-md-12">
          <label  class="text-gray font" for="">Bundle Category</label>
          <v-select v-model.trim="bundle.bundleCategory" @change="fillItems" :items="categories" solo></v-select>
        </div>
        <div class="col-md-12">
          <label  class="text-gray font" for="">Bundle Type</label>
          <v-select :items="bundelTypeItems" v-model.trim="bundle.bundleType" solo></v-select>
        </div>
        <div class="col-md-12">
          <label class="text-gray font" for="">Bundle Description</label>
          <v-text-field solo v-model.trim="bundle.bundleDescription"></v-text-field>
        </div>
        <div class="col-md-12">
          <label class="text-gray font" for="">Bundle Profile URL</label>
          <v-text-field solo v-model.trim="bundle.bundleProfileUrl"></v-text-field>
        </div>
        <div class="row no-padding">
         <div class="col-md-10">
          <label class="text-gray font" for="">Bundle Profile Image (Max Image Size 100 x 100 px)</label>
          <v-file-input
                @change="Preview_image"
                v-model.trim="bundle.profileImage"
                required
                solo
                truncate-length="15"
                ref="fileInput"
          ></v-file-input>
        </div>
        <div class="col-md-2">
          <v-btn class="blue-buttons mt-7"  @click="activateFileInput">Browse</v-btn>
        </div>
        </div>
        <div class="col-md-12 row no-padding">
          <v-checkbox></v-checkbox>
          <span class="font text-gray d-flex align-center">Make Available in Library. <i class="fas fa-question-circle  yellow-icons"></i></span>
        </div>
      </div>
       <div class="col-md-6 ">
        <div class="col-md-12">
          <label class="text-gray font" for="">Company Name <i class="fas fa-question-circle  yellow-icons"></i></label>
          <v-text-field solo v-model.trim="bundle.companyName"></v-text-field>
        </div>
        <div class="col-md-12">
          <label class="text-gray font" for="">First Name</label>
          <v-text-field solo v-model.trim="bundle.firstName"></v-text-field>
        </div>
        <div class="col-md-12">
          <label class="text-gray font" for="">Last Name</label>
          <v-text-field solo v-model.trim="bundle.lastName"></v-text-field>
        </div>
         <div class="col-md-12">
          <label class="text-gray font" for="">Title</label>
          <v-text-field solo v-model.trim="bundle.title"></v-text-field>
        </div>
        <div class="col-md-12">
          <label class="text-gray font" for="">Bundle Status</label>
          <v-select solo v-model.trim="bundle.status" :items="[{text:'Active', value:'active'},{text:'InActive', value:'inactive'}]"></v-select>
        </div>
         <div class="col-md-12 mt-2">
       
      </div>
      </div>
     
      </form>
    </div>
   </div>
    <div class="col-md-12" v-if="itemtab == 'tab1'">
    <v-row>
      <v-col class="col-md-8" md="4" cols="12">
          <h3>
            <span class="font"> </span>  Components
          </h3>
      </v-col>
      <v-col class="col-md-4" cols="12"   align="end">
        <v-btn  class="my-1 blue-buttons" @click="dialog = true">Add Components</v-btn>
    </v-col>
    </v-row>      
    <v-simple-table fixed-header>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left text-gray font">
             Component Name
            </th>
           
            <th class="text-left text-gray font">
             Category
            </th>
            <th class="text-left text-gray font">
              Item
            </th>
        
            <th class="text-left text-gray font">
              Description
            </th>
            <th class="text-left text-gray font">
              Rate Amount (USD)
            </th>
            <th class="text-left text-gray font">
              Editable
            </th>
            <th class="text-end text-gray font">
              Actions
            </th>
          </tr>
        </thead>
      
        <tbody v-if="componentsArray.length > 0">
        
          <tr v-for="(item,index) in componentsArray" :key="index">
            <td>
              {{item.companyName}}
            </td>
            
               <td>
              {{item.category}}
            </td>
              
               <td>
              {{item.item}}
            </td>
           <td>
              {{item.rateAmount}}
            </td>
             <td>
              {{item.description}} 
            </td>
            <td>
                <div class="d-flex mt-2">
                         NO
                         <v-switch
                         disabled
                          v-model.trim="item.editable"
                          solo
                          class="no-padding"
                        ></v-switch>
                         YES
              </div>
            </td>
             <td class="text-end">
                <svg @click="removeItem(item)" width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.5 19.5H26.5" stroke="#E40A0A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M32.3333 2H6.66667C4.08934 2 2 4.08934 2 6.66667V32.3333C2 34.9107 4.08934 37 6.66667 37H32.3333C34.9107 37 37 34.9107 37 32.3333V6.66667C37 4.08934 34.9107 2 32.3333 2Z" stroke="#E40A0A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

             </td>

          </tr>
      
        </tbody>
        <tbody v-else>
          <tr>
            <td>
              <a  @click="dialog=true">Add Components</a>
            </td>
          </tr>
      
        </tbody>
      </template>
    </v-simple-table>
    <div class="text-center">
        <v-dialog
            v-model.trim="dialog"
            width="600"
        >
            <v-card>
                <v-card-title class="text-h5 ">
                    Add Components to Bundle
                </v-card-title>
                <v-card-text>
                  <v-form>
                     <div>
                         <label for="">Name</label>
                         <v-text-field solo v-model.trim="components.companyName"></v-text-field>
                    </div>
                     <div>
                         <label for="">Category </label>
                         <v-select  :items="categories"  @change="fillItemsInModal" solo v-model.trim="components.category"></v-select>
                    </div>
                   
                    <div>
                         <label for="">Item</label>
                         <v-select :items="bundelTypeItems" solo v-model.trim="components.item"></v-select>
                    </div>
                
                    <div>
                         <label for="">Rate Amount (USD)</label>
                         <v-text-field type="number" solo v-model.trim="components.rateAmount"></v-text-field>
                    </div>
                    <div>
                         <label for="">Description</label>
                         <v-textarea solo v-model.trim="components.description"></v-textarea>
                    </div>
                    <div>
                      <label for="">Editable</label>
                      <div class="d-flex mt-2">
                         NO
                         <v-switch
                          v-model.trim="components.editable"
                          solo
                          class="no-padding"
                        ></v-switch>
                         YES
                      </div>
                    </div>
                  </v-form>
                </v-card-text>
                 <v-btn class="blue-buttons ml-5 mb-5" @click="dialog = false">Cancel</v-btn>
                    <v-btn class="blue-buttons ml-5 mb-5" @click="pushInComponents">Save & Add</v-btn>

                <v-divider></v-divider>
               
            </v-card>
        </v-dialog>
    </div>
  </div>
  <div class="col-md-12" v-if="itemtab == 'tab2'">
    <v-row>
      <v-col class="col-md-8" md="4" cols="12">
          <h3>
            <span class="font"> </span> Providers
          </h3>
      </v-col>
      <v-col class="col-md-4" cols="12"   align="end">
        <v-btn  class="my-1 blue-buttons" @click="addProvidersModal">Add Providers</v-btn>
    </v-col>
    </v-row>      
    <v-simple-table fixed-header>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left text-gray font">
              Provider Name  
            </th>
            <th class="text-left text-gray font">
              Type
            </th>
            <th class="text-left text-gray font">
             Quality Rating
            </th>
            <th class="text-left text-gray font">
              City
            </th>
            <th class="text-left text-gray font">
              State
            </th>
            <th class="text-center text-gray font">
                Actions 
            </th>
          </tr>
        </thead>
      
        <tbody v-if="providerList.length < 1">
          <tr>
            <td>
              <a  @click="dialog1=true">Add Providers </a>
            </td>
          </tr>
        </tbody>

         <tbody v-else>
              <tr
                v-for="(item,index) in providerList"
                :key="index"
                :id="'id' + index"
              >
              
                <td>
                   
                  <a v-if="item.companyName" class="font mt-6 mb-3" >
                    {{item.companyName}} 
                  </a>
                  <a v-else-if="item.providerName" class="font mt-6 mb-3" >
                    {{item.providerName}}
                  </a>
                </td>
                <td class="text-gray">{{ item.providerType }}</td>
                <td class="text-gray">
                  
                  <v-rating
                    v-model.trim="item.rating"
                    color="yellow darken-3"
                    background-color="grey darken-1"
                    empty-icon="$ratingFull"
                    half-increments
                  
                ></v-rating>
                </td>
                <td class="text-gray">{{ item.city }}</td>
                <td v-html="item.state ? item.state : '/'">{{item.state}}</td>
                <td  class="text-center">
                <svg @click="removeProvider(item)" class="pointer" width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.5 19.5H26.5" stroke="#E40A0A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M32.3333 2H6.66667C4.08934 2 2 4.08934 2 6.66667V32.3333C2 34.9107 4.08934 37 6.66667 37H32.3333C34.9107 37 37 34.9107 37 32.3333V6.66667C37 4.08934 34.9107 2 32.3333 2Z" stroke="#E40A0A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

                </td>
              </tr>
              
            </tbody>
      </template>
    </v-simple-table>
    <div class="text-center">
      <v-dialog
        v-model.trim="dialog1"
        width="1600"
        style="overflow-x:none"
      >
    <v-card>
      <v-card-text>
      <div class="font  text-center" >
       <div class="font ml-2">Search Providers</div>
        <v-row class="justify-center"  align="center">
          <div class="col-md-6">
               <v-text-field
                class="  "
                @input="searchProviders"
                placeholder="Search Providers">
              </v-text-field>
          </div>
         </v-row>
       </div>
        <v-simple-table fixed-header >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Provider Name
                </th>
                <th class="text-left">
                  Type
                  </th>
                <th class="text-left">
                  Quality Rating
                </th>
                <th class="text-left">
                  City 
                </th>
                 <th class="text-left">
                  State 
                </th>
                <th class="no-padding d-flex justify-end ">
                 <v-checkbox class="no-padding" @click="selectAllRows()"></v-checkbox>
                 <label for="" style="padding-top:5px">Select all</label>
               </th>
              </tr>
            </thead>
            <tbody  v-if="str.length > 0 ">
              <tr
                 v-for="(item,index) in searchProvider"
                :key="index"
                :id="'id' + index"
              >
                <td>
                  <a v-if="item.companyName" class="font mt-6 mb-3"  @click="singleProvider(item)">
                    {{item.companyName}} 
                  </a>
                  <a v-else-if="item.providerName" class="font mt-6 mb-3"  @click="singleProvider(item)">
                    {{item.providerName}}
                  </a>
                </td>
                <td class="text-gray">{{ item.type }}</td>
                <td class="text-gray">
                  
                  <v-rating
                    v-model.trim="item.rating"
                    color="yellow darken-3"
                    background-color="grey darken-1"
                    empty-icon="$ratingFull"
                    half-increments
                  
                ></v-rating>
                </td>
                <td class="text-gray">{{ item.city }}</td>
                <td>{{item.state}}</td>
                <td  class="text-center">
                  <v-checkbox  :class="'checkBox' + index" :disabled="selectAll"  :value="selectAll" @click="activeRow(index,item)"></v-checkbox>
                </td>
              </tr>
              
            </tbody>
    
             <tbody  v-else >
              <tr
                 v-for="(item,index) in providers"
                :key="index"
                :id="'id' + index"
              >
                <td>
               
                 <a v-if="item.companyName" class="font mt-6 mb-3" >
                    {{item.companyName}}
                  </a>
                  <a v-else-if="item.providerName" class="font mt-6 mb-3" >
                    {{item.providerName}}
                  </a>
                </td>
               
                <td class="text-gray">{{ item.providerType }}</td>
                <td class="text-gray">
                  
                  <v-rating
                    v-model.trim="item.rating"
                    color="yellow darken-3"
                    background-color="grey darken-1"
                    empty-icon="$ratingFull"
                    half-increments
                  
                ></v-rating>
                </td>
                
                <td class="text-gray">{{ item.city }}</td>
                <td v-html="item.state ? item.state : '/'">{{item.state}}</td>
                <td  class="text-center">
                  <v-checkbox  :class="'checkBox' + index" :disabled="selectAll" :value="selectAll"  @click="activeRow(index,item)"></v-checkbox>
                </td>
              </tr>
              
            </tbody>
            
          </template>
        </v-simple-table>
          <v-row class="text-end justify-end mt-10">
             <v-btn class="blue-buttons mr-3" @click="dialog1 = false">Cancel</v-btn>
             <v-btn class="blue-buttons" @click="addToProvidersRow">Add Selected</v-btn>
          </v-row>
       </v-card-text>

          <v-divider></v-divider>
        </v-card>
      </v-dialog>
    </div>
   </div>
    <div class="col-md-12" v-if="itemtab == 'tab3'">
     <v-row>
      <v-col class="col-md-4 mt-3" md="4" cols="12">
          <h3>
            <span class="font"> </span> Link Providers & Bundles
          </h3>
      </v-col>
      <v-col class="row">
        <span class="mt-8 mr-4 font text-blue">Select Bundle:</span>
        <v-select></v-select>
      </v-col>
      <v-col class="col-md-4" cols="12"   align="end">
        <v-btn  class="my-1 blue-buttons" >Select Provider First</v-btn>
    </v-col>
    </v-row>      
    <v-divider></v-divider>
    <v-row class="mt-5">
      <h3 class="col-md-6">Select Participating Providers to Link to Bundle</h3>
      <div class="col-md-3"> 
       <v-text-field   solo placeholder="Search Providers"></v-text-field>
      </div>
    </v-row>
      <v-simple-table fixed-header >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                Provider Name
                </th>
                <th class="text-left">
                Type
                </th>
                <th class="text-left">
                Speciality / Taxonomy
                </th>
                <th class="text-left">
                City
                </th>
                <th class="text-center">
                <v-checkbox></v-checkbox>
                Select All
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in providers"
                :key="item.name"
              >
                <td><a    @click="singleProvider(item)">{{ item.name }}</a> </td>
                <td class="text-gray">{{ item.type }}</td>
                <td class="text-gray">
                  <v-rating
                    v-model.trim="item.rating"
                    color="yellow darken-3"
                    background-color="grey darken-1"
                    empty-icon="$ratingFull"
                    half-increments 
                ></v-rating>
                </td>
                <td class="text-gray">{{ item.city }}</td>
                
                <td  class="text-center">
                  <v-checkbox></v-checkbox>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
     </div>
     <div class="col-md-12 d-flex align-end flex-column">
              <div>
                <v-btn class="blue-buttons mr-6" @click="goBack()">
                  Cancel
                </v-btn>
                <v-btn v-if="tab != 'tab2'" class="blue-buttons" @click="nextTab(itemtab)">
                   Next
                </v-btn>
                <v-btn v-else class="blue-buttons" @click="createBundle()">
                   Create Bundle
                </v-btn>
            </div>
        </div>
 </v-row>
 
</template>
<script>
import api from '../../../../services/api'
import successModal from '../../../TablesTemplate/SuccessDialog.vue'
import removeDialog from '../../../TablesTemplate/RemoveDialog.vue'
import errorModal from '../../../TablesTemplate/ErrorDialog.vue'


 export default{
   components:{
       successModal,
       removeDialog,
       errorModal
   },
   data(){
       return{
          items:[
            {
              text:'Bundle Information',
              value:1,
            },
            {
              text:'Components',
              value:1,
            },  
            {
              text:'Providers',
              value:1,
            }
          ],
          providerList:[],
          selectAll:false,
          componentsArray:[],
          tableItems:[],
          tab: '#tab0',
          href:'#tab-0',
          providers: [],
          dialog:false,
          dialog1:false,
          dialog2:false,
          check:false,
          bundle:{
            bundleName:'',
            bundleType:'',
            bundleCategory:'',
            bundleDescription:'',
            bundleProfileUrl:'',
            profileImage:[],
            companyName:'',
            firstName:'',
            lastName:'',
            title:'',
            status:'active',
            
          },
          str:'',
          selectedRows:[],
          selected:[],
          providers:[],
          searchProvider:[],
          itemById:{},
          index:0,
          components:{
            companyName:'',
            category:'',
            type:'',
            item:'',
            rateAmount:'',
            description:'',
            editable:false,
            
          },
          bundelTypeItems:[],
          categories:[
            {
              text:'Office Visit',
              value:'Office Visit'
            },
            {
              text:'Imaging',
              value:'Imaging'
            },
            {
              text:'Procedure',
              value:'Procedure'
            },
          ],
          officeTypes:[
            {
              text:'New Patient',
              value:'New Patient'
            },
            {
              text:'Existing Patient',
              value:'Existing Patient'
            },
            {
              text:'Urgent',
              value:'Urgent'
            },
          ],
          procedureTypes:[
            {
              text:'Cardiology',
              value:'Cardiology'
            },
            {
              text:'Neurological',
              value:'Neurological'
            },
            {
              text:'Musculoskeletal',
              value:'Musculoskeletal'
            },
            {
              text:'Gastroenterology',
              value:'Gastroenterology'
            },
            {
              text:'ENT',
              value:'ENT'
            },
            {
              text:'Ophthalmological',
              value:'Ophthalmological'
            },
            {
              text:'Genitourinary',
              value:'Genitourinary'
            },
            {
              text:'Podiatric',
              value:'Podiatric'
            },

          ],
        

          imagingTypes:[
             {
              text:'CT Scan',
              value:'CT Scan'
            },
            {
              text:'MRI',
              value:'MRI'
            },
            {
              text:'Ultrasound',
              value:'Ultrasound'
            },
            {
              text:'Xray',
              value:'Xray'
            },
            {
              text:'Other',
              value:'Other'
            },

          ],
         
       }
   },
   computed:{
       itemtab(){
         return this.tab
      },
       routeParams(){
         return this.$route.params.name
       },
       singleBundle(){
        
         return this.$store.getters.getSingleBundle
       }
   },
   created(){
       this.getAllProviders();
   },
    methods:{
      closeErrorModal(){
       this.$refs.error_modal.closePopUp();
      },
       fillItems(){
       
        if(this.bundle.bundleCategory == 'Office Visit'){
           this.bundelTypeItems = this.officeTypes
        }else if(this.bundle.bundleCategory == 'Imaging'){
            this.bundelTypeItems = this.imagingTypes
        }else if(this.bundle.bundleCategory == 'Procedure'){
           this.bundelTypeItems = this.procedureTypes
        }
      },
      fillItemsInModal(){   
        if(this.components.category == 'Office Visit'){
           this.bundelTypeItems = this.officeTypes
        }else if(this.components.category == 'Imaging'){
            this.bundelTypeItems = this.imagingTypes
        }else if(this.components.category == 'Procedure'){
           this.bundelTypeItems = this.procedureTypes
        }
      },
      searchProviders(value){
        this.str = value;
        api().get(`/advisors/providers/searchPA?=${value}`).then((res)=>{
        this.searchProvider = res.data.providers;
        }).catch((err)=>{
          console.log(err.response);
        })
      
      },
      closeSuccessModal(){
         this.$refs.success_modal.closePopUp();
         this.$router.push('/provider/bundles');

      },
     
      addProvidersModal(){
          this.dialog1 = true;
          let items = document.getElementsByClassName('activeItem');
          items.forEach((el)=>{
            el.classList.remove('activeItem')
          })
          
         
      },
      update(){
          let user = JSON.parse(localStorage.getItem('user'));
          let provider = user.entity._id;
          let data = {
            "information": {
              "firstName": this.bundle.firstName,
              "lastName": this.bundle.lastName,
              "title": this.bundle.title,
              "status": this.bundle.status,
              "name":this.bundle.bundleName,
              "type":this.bundle.bundleType,
              'description':this.bundle.bundleDescription,
              'companyName':this.bundle.companyName,
              'bundleProfileUrl':this.bundle.bundleProfileUrl

             },
          }
          api().put(`/providers/${provider}/bundles/${this.singleBundle._id}`, data).then((res)=>{
            this.$refs.success_modal.openPopUp('Bundle updated successfully')
            this.$store.commit('setSingleBundle', res.data.bundle);
          })
      },
      selectAllRows(){
           this.selectAll = !this.selectAll;
          if(this.str.length > 0){
            this.selectedRows = this.searchProvider
          }else{
             this.selectedRows = this.providers;
          }
       },
      activeRow(index,item){
        this.itemById = document.getElementById('id'+ index);
        this.itemById.classList.toggle('activeItem');
        this.index = index
        
       if( this.itemById.classList.contains('activeItem')){
          this.selectedRows.push(item);
       }else{
         let removeItem =  this.selectedRows.find(x => x._id === item._id);
         this.selectedRows.splice(this.selectedRows.indexOf(removeItem),1 );
       }
      
     },
      deleteBundle(){
        this.$refs.dialog_delete.openPopUp('Are you sure that you want to delete this Bundle?')
      },
      confirmRemove(){
          let user = JSON.parse(localStorage.getItem('user'));
          let provider = user.entity._id;
   
          api().delete(`/providers/${provider}/bundles/${this.singleBundle._id}`).then((res)=>{
              this.$router.push(`/provider/bundles`)
          })
      },
      goBack(){
        this.$router.push(`/provider/bundles`)
      },
      nextTab(tab){
        if(tab == 'tab0'){
          this.tab = 'tab1'
        }else if(tab == 'tab1'){
          this.tab = 'tab2'
        }
  
      },
      pushInComponents(){
        this.componentsArray.push(this.components);
        this.dialog = false
        this.components = {
            companyName:'',
            category:'',
            type:'',
            item:'',
            rateAmount:0,
            description:'',
            editable:false
        }
      },
      Preview_image(event) {
          this.url= URL.createObjectURL(this.companyLogo)
          this.imageEvent = event
      },
      activateFileInput(){
         this.$refs.fileInput.$refs.input.click()

      },
      removeItem(item){
          let removeItem = this.componentsArray.indexOf(item);
          this.componentsArray.splice(removeItem, 1)
      },
      removeProvider(item){
             let removeItem = this.providerList.indexOf(item);
             this.providerList.splice(removeItem, 1)
      },
      addToProvidersRow(){
         this.providerList = this.selectedRows;
         this.dialog1 = false;
         this.selectedRows = [];
         document.getElementsByClassName('checkBox' + this.index).checked = false
        
        
      },
      getAllProviders(){
           let user = JSON.parse(localStorage.getItem('user'));
          let provider = user.entity._id
            api().get(`/providers/${provider}/groupProviders`).then((res)=>{
             
              if(res){
                this.providers = res.data.groupProviders
                
              }
        })
     },
      createBundle(){
        if(this.bundle.bundleName){
         let ids = []
        this.providerList.forEach((el)=>{
              ids.push(el._id)
        })
        let data = {
             "information": {
              "name": this.bundle.bundleName,
              "type": this.bundle.bundleType,
              'category':this.bundle.bundleCategory,
              "description":this.bundle.description,
              "companyName": this.bundle.companyName,
              "firstName": this.bundle.firstName,
              "lastName": this.bundle.lastName,
              "title": this.bundle.title,
              "status": this.bundle.status,
          },
          "components": this.componentsArray,
          "providers": ids

        }
     
        let user = JSON.parse(localStorage.getItem('user'));
        let provider = user.entity._id;
      
        api().post(`/providers/${provider}/bundles`,data).then((res)=>{
           this.$refs.success_modal.openPopUp('Bundle created successfully')
        })

        }else{
         this.$refs.error_modal.openPopUp('Please enter at least Bunlde Name');
        }
       
      }
  
    }
 }
</script>
<style >
.theme--dark.v-tabs > .v-tabs-bar {
     background-color: transparent !important;
}
.v-tab{
    max-width: 900px;
}
</style>