<template>
 <v-row class="add-client-form no-padding" >
     <removeDialog  ref="dialog_delete" v-on:confirm="confirmRemove"></removeDialog>
     <successModal  ref="success_modal" v-on:confirm="updateModal"></successModal>
     <errorModal  ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>

  <v-form  v-model.trim="valid" ref="form" class="col-md-12 form-box ">
    <div class="row">
           
            <div class="col-md-6">
                <div class="row col-md-9" cols="12">
                    <v-select     @change="showDependent"  v-model.trim="dependent"   :items="dependents.length > 0 ? dependents : employee"  class="no-padding"   >
                      <template slot="selection" slot-scope="data"  >
                        {{ data.item.firstName }}  {{ data.item.lastName }}
                     </template>
                     <template slot="item" slot-scope="data" >
                        {{ data.item.firstName }}  {{ data.item.lastName }}
                     </template>
                    </v-select>
                    <span class="text-gray small-text mt-2">View Associated Members</span>
                </div>
            </div>
            
            <h3 class="form-title font col-md-3 row ml-1" cols="6">
                 <v-select
                    label="Status"
                    v-model.trim="status"
                    small
                    :rules="requiredRules"
                    :color="status == 'Active' ? 'green' : 'red'"
                    :item-color="status == 'Active' ? 'green' : 'red'" 
                    :items="[{text:'Active', value:'active'}, {text:'Inactive', value:'inactive'}]">
                </v-select>
            </h3>
            <div class=" col-md-3 text-xl-end text-lg-end text-sm-start" cols="6">
                <v-btn class="blue-buttons" v-if="!$route.path.includes('/members/dependents')" @click="addDependent" >Add Dependents</v-btn>
            </div>
       
    </div>
    <form class="row">
        <div class="col-md-6 no-padding">
            <div class="col-md-12">
               <label class="font text-gray" for="">First Name</label>
                <v-text-field
                tabindex="1"
                required
                v-model.trim="firstName"
                solo
                 :rules="requiredRules"
                ></v-text-field>
           </div>
            <div class="col-md-12">
              <label class="font text-gray" for="">Last Name</label>
                <v-text-field
                tabindex="2"
                required
                 :rules="requiredRules"
                solo
                v-model.trim="lastName"
                ></v-text-field>
           </div>
            <div class="col-md-12">
                <label class="font text-gray" for="">Email</label>
                <v-text-field
                 tabindex="3"
                required
                v-model.trim="email"
                 :rules="requiredRules"
                solo
                ></v-text-field>
            </div>
            <div class="col-md-12">
                <label class="font text-gray" for="">Sex</label>
                <div class="row col-md-12 justify-space-between">
                       <div  :class="gender == 'male' ? 'col-md-4 blue-buttons  text-center  border-radius ' : 'col-md-4 gender-buttons  text-center  text-gray'" @click="getGender('male')" tabindex="4">Male</div>
                    <div :class="gender == 'female' ? 'col-md-4 blue-buttons  text-center  border-radius ' : 'col-md-4 gender-buttons  text-center  text-gray'" @click="getGender('female')" tabindex="5">Female</div> 
                </div>
           </div>
           <div class="col-md-12 row">
            <div class="col-md-6">
                <label class="font text-gray" for="">Birth Month</label>
                <v-text-field
                required
                v-model.trim="birthMonth"
                :rules="requiredRules"
                tabindex="6"
                solo
                ></v-text-field>
            </div>
             <div class="col-md-3">
                <label class="font text-gray" for="">Day</label>
                <v-text-field
                 required
                 v-model.trim="birthDay"
                 type="number"
                 tabindex="7"
                 :rules="requiredRules"
                 solo
                ></v-text-field>
            </div>
             <div class="col-md-3">
                <label class="font text-gray" for="">Year</label>
                <v-text-field
                 required
                 tabindex="8"
                 type="number"
                 v-model.trim="birthYear"
                 :rules="requiredRules"
                 solo
                ></v-text-field>
            </div>
         </div>
            <div class="col-md-12">
                <label class="font text-gray" for="">Social Security Number </label>
                <v-text-field
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    tabindex="9"
                    :rules="requiredRules"
                    required
                    solo
                    v-model.trim="socialNumber"
                     :type="show1 ? 'text' : 'password'"
                    @click:append="show1 = !show1"
                ></v-text-field>
            </div>
        </div>
        <div class="col-md-6 no-padding" >
              <div class="col-md-12">
               <label class="font text-gray" for="">Member Type</label>
                <v-text-field
                tabindex="10"
                required
                solo
                 :rules="requiredRules"
                v-model.trim="memberType"
                ></v-text-field>
           </div>
            <div class="col-md-12">
               <label class="font text-gray" for="">Home Address 1</label>
                <v-text-field
                tabindex="10"
                required
                :rules="requiredRules"
                solo
                v-model.trim="homeAddres1"
                ></v-text-field>
           </div>
          <div class="col-md-12">
            <label class="font text-gray" for="">Address 2</label>
                <v-text-field
                tabindex="11"
                required
                solo
                v-model.trim="homeAddress2"
                ></v-text-field>
           </div>
          <div class="col-md-12 row">
            <div class="col-md-6">
                <label class="font text-gray" for="">City</label>
                <v-text-field
                required
                tabindex="12"
                 :rules="requiredRules"
                v-model.trim="city"
                solo
                ></v-text-field>
            </div>
             <div class="col-md-3">
                <label class="font text-gray" for="">Zip</label>
                <v-text-field
                required
                 :rules="requiredRules"
                tabindex="13"
                type="number"
                v-model.trim="zip"
                solo
                ></v-text-field>
            </div>
             <div class="col-md-3">
                <label class="font text-gray" for="">State</label>
                <v-select
                required
                :items="states"
                item-text="name"
                :rules="requiredRules"
                item-value='name'
                v-model.trim="state"
                tabindex="14"
                solo
                
                ></v-select>
            </div>
        </div>
        
         <div class="ma-lg-1 ma-xl-2" cols="12">
             <v-col cols="12">
                  <v-btn  cols="12" class="blue-buttons" @click="removeMember">Remove Member  </v-btn>
             </v-col>
           
         </div>
           <div class="col-md-12">
             <v-simple-table fixed-header  height="630px">
                <template v-slot:default>
                <thead>
                    <tr>
                    <th class="text-left">
                        Dependets
                    </th>
                    <th class="text-left">
                        Member ID
                    </th>
                    <th  class="text-left">
                        Relationship
                    </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in dependents" :key="item._id">
                    <td> 
                        <a  @click="getSingleDependent(item)">{{item.firstName}} {{item.lastName}}</a>
                    </td>
                    <td>{{item._id}}</td>
                    <td>{{item.memberType}}</td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>
          
            <v-divider></v-divider>
            <div class="d-flex  justify-space-between mt-2">
          
              <a @click="addDependent"> Add Dependents </a>
            </div>
        </div>
        
        </div>           
        <div class="col-md-12 text-xl-end text-lg-end text-sm-start">
                <v-btn cols="12"  tabindex="16" class="blue-buttons mr-6 mb-2" @click="$router.push(`/employer/members`)">
                   Cancel
                </v-btn>
                 <v-btn cols="12"  tabindex="16" class="blue-buttons mr-6 mb-2" @click="editMember()">
                  Save & Update
                </v-btn>
            
        </div>
      </form>
   </v-form>
       
 </v-row>
</template>
<script>
import api from '../../../../../services/api'
import successModal from '../../../../TablesTemplate/SuccessDialog.vue'
import removeDialog from '../../../../TablesTemplate/RemoveDialog.vue'
import errorModal from '../../../../TablesTemplate/ErrorDialog.vue'
 export default{
    components:{
        successModal,
        removeDialog,
        errorModal

     },
    data(){
        return{
            show1:false,
            dialog:false,
            dialog1:false,
            valid:false,
            firstName:'',
            lastName:'',
            email:'',
            sex:'',
            birthMonth: '',
            birthDay:'',
            birthYear:'',
            memberType:'',
            homeAddres1:'',
            homeAddress2:'',
            city:'',
            zip:'',
            state:'',
            socialNumber:'',
            gender:'',
            status:'',
            dependent:null,
            states:[],
            requiredRules: [
                v => !!v || 'This field is required',
            ],

        }
    },
    computed:{
        singleMember(){
             return this.$store.getters.getEmployerSingleMember;
        },
         singleClient(){
          return this.$store.getters.getClientGetter;
        },
        routeParams(){
         return this.$route.params.name
        },
        dependents(){
            let dep = []
            if(this.$store.getters.getEmployerSingleMemberDependent){
                dep = this.$store.getters.getEmployerSingleMemberDependent
            }
           
            return dep
        },
        employee(){
            let emp = [];
            if(this.$store.getters.getEmployerSingleMemberEmployee){
                emp = this.$store.getters.getEmployerSingleMemberEmployee
            }
            return emp
        }
    },
    created(){
        this.getStates()
        
        if(this.singleMember){
            if(this.singleMember.birthdate){
                let splited =  this.singleMember.birthdate.split("/");
                this.birthMonth = splited[0];
                this.birthDay =  splited[1];
                this.birthYear =  splited[2];

            }
            

            this.firstName = this.singleMember.firstName;
            this.lastName = this.singleMember.lastName;
            this.email = this.singleMember.email;
            this.sex = this.singleMember.sex;
           
            this.memberType = this.singleMember.memberType;
            this.homeAddres1 = this.singleMember.address1;
            this.homeAddress2 = this.singleMember.address1;
            this.city = this.singleMember.city;
            this.zip = this.singleMember.zipCode;
            this.state = this.singleMember.state;
            this.socialNumber = this.singleMember.ssn;
            this.gender = this.singleMember.sex
            this.status = this.singleMember.status

        }

    },
    methods:{
      getStates(){
        api().get('/api/states').then((res)=>{
            res.data.forEach((el)=>{
                if(el.active){
                    this.states.push(el);
                }
            })
        })
        },
        closeErrorModal(){
                this.$refs.error_modal.closePopUp();
         },
        getSingleDependent(item){
            
            let user = JSON.parse(localStorage.getItem('user'));
            let employer = user.groupPortal[0].organization.employer
            
             api().get(`/employers/${employer}/members/${this.singleMember._id}/dependents/${item._id}`).then((res)=>{
                
                this.$store.commit('setSingleEmployerMemberSingleDependent', res.data.dependent)
                this.$router.replace(`/employer/members/member-detail/dependent-detail`)
            })
         },
         getGender(gender){
             this.gender = gender
         },
        addDependent(){
               this.$router.replace(`/employer/members/member-detail/dependents`)
        },
        showDependent(){
            let user = JSON.parse(localStorage.getItem('user'));
            let employer = user.groupPortal[0].organization.employer
           
             api().get(`/employers/${employer}/members/${this.singleMember._id}/dependents/${this.dependent._id}`).then((res)=>{
                this.$store.commit('setSingleEmployerMemberSingleDependent', res.data.dependent)
                this.$router.replace(`/employer/members/member-detail/dependent-detail`)
            })
        },
      
        updateModal(){
           this.$refs.success_modal.closePopUp()
        },
        editMember(){
            this.$refs.form.validate();
           
            if(this.valid){
           
            let member = {
                'firstName' :  this.firstName,
                'lastName': this.lastName,
                'email': this.email,
                'sex': this.gender,
                "birthdate": `${this.birthDay}/${this.birthMonth}/${this.birthYear}`,
                'memberType': this.memberType,
                'address1': this.homeAddres1,
                'address2': this.homeAddress2,
                'city': this.city,
                'zip': this.zip,
                'state': this.state,
                'status':this.status,
               
            }
           
            let user = JSON.parse(localStorage.getItem('user'));
            let employer = user.groupPortal[0].organization.employer
           
            api().put(`/employers/${employer}/members/${this.singleMember._id}`, member).catch((err)=>{
               if(err){
                    this.$refs.error_modal.openPopUp(err.response.data.message);
               }
            }).then((res)=>{
               if(res){
                   this.$refs.success_modal.openPopUp('Member successfully updated');
                   this.$store.commit('setSingleEmployerMember', res.data.member)

               }
                
            })
            }
        },
        removeMember(){
            this.$refs.dialog_delete.openPopUp('Are you sure that you want to delete this member?');
        },
        confirmRemove(){
         let user = JSON.parse(localStorage.getItem('user'));
         let employer = user.groupPortal[0].organization.employer
       
            api().delete(`/employers/${employer}/members/${this.singleMember._id}`).then((res)=>{
                if(res){
                  
                    this.$router.replace(`/employer/members`);
                   
                }
            })
        },
        closePopUp(){
            this.dialog = false
        }
    }
 }
</script>

