<template>
   <div class="col-md-12 pl-0 pr-0">
    <v-simple-table fixed-header height="630px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">MEMBER ID</th>
            <th class="text-left">FIRST NAME</th>
            <th class="text-left">LAST NAME</th>
            <th class="text-left">GENDER</th>
            <th class="text-left">DATE OF BIRTH</th>
            <th class="text-left">RELATIONSHIP</th>
            <th class="text-left">SERVICE DATE</th>
            <th class="text-left">AMOUNT</th>
            <th class="text-left">CLAIM NUMBER</th>
            <th class="text-left">STATUS</th>
          </tr>
        </thead>
        <tbody class="mt-2">
          <tr
            v-for="(item, index) in members"
            :key="index"
          >
            <td>{{ item.memberId }}</td>
            <td>{{ item.firstName }}</td>
            <td>{{ item.lastName }}</td>
            <td v-if="item.gender == 'm'">Male</td>
            <td v-if="item.gender == 'f'">Female</td>
            <td>{{ item.dob.substring(0, 10) }}</td>
            <td>{{ item.subscriberRelation }}</td>
            <td>{{ item.serviceDate.substring(0, 10) }}</td>
            <td>{{ item.amount }} $</td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div> 
</template>
<script>
 import api from "../../../../../../services/api";
export default {
  data() {
    return {
        members: {
           memberId: "",
           firstName: "",
           lastName: "",
           gender: "",
           dob: "",
           subscriberRelation: "",
           serviceDate: "",
           amount: "",
        }
    };
  },
  created(){
    api().get(`/tpas/clients/${this.$route.params.clientId}/invoice/${this.$route.params.invoiceId}`)
        .then((res) => {
          if (res) {
            console.log("res",res.data)
            this.members = res.data.members;
            
          }
        }).catch((err) => {
          console.log(err.response.message)
    });
  },
  methods: {
 
  },
};
</script>