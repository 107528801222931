<template>
    <div>
        <EmpProviders v-if="route=='/employer/providers'"></EmpProviders>
        <EmpAddProvider v-if="route=='/employer/providers/add-provider'"></EmpAddProvider>
        <EmpProviderSearchResult v-if="route=='/employer/providers/add-provider/search-result'"></EmpProviderSearchResult>
        <EmpInviteProvider  v-if="route=='/employer/providers/invite'"></EmpInviteProvider>
        <EmpProviderDetail v-if="route=='/employer/providers/detail'"></EmpProviderDetail>
         <ZipCodes v-if="route=='/employer/providers/zip-codes'"></ZipCodes>
    </div>
</template>

<script>

    import EmpProviders from "./EmpProviders.vue";
    import EmpAddProvider from "./AddProvider/EmpAddProvider.vue";
    import EmpProviderSearchResult from "./AddProvider/SearchResult/EmpProviderSearchResult.vue";
    import EmpInviteProvider from "./InviteProviders/EmpInviteProvider.vue";
    import EmpProviderDetail from "./ProviderDetail/EmpProviderDetail.vue";
    import ZipCodes from './AddZipCodes.vue'


    export default {
        name:'EmpProvidersTemplate',
        components: {
            EmpProviders,
            EmpAddProvider,
            EmpProviderSearchResult,
            EmpInviteProvider,
            EmpProviderDetail,
            ZipCodes
        },
        computed:{
            route(){
                return this.$route.path
            }
        }
    }
</script>