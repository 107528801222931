<template>
  <div class="add-client-form">
    <div v-if="!isChildren">
      <v-row>
        <v-col class="col-md-6">
          <h3>Family Members</h3>
        </v-col>
        <v-col class="col-md-6 text-end">
          <!-- <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 10V22" stroke="#0F62F9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10 16H22" stroke="#0F62F9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M1 16C1 19.9782 2.58035 23.7936 5.3934 26.6066C8.20644 29.4196 12.0218 31 16 31C19.9782 31 23.7936 29.4196 26.6066 26.6066C29.4196 23.7936 31 19.9782 31 16C31 12.0218 29.4196 8.20644 26.6066 5.3934C23.7936 2.58035 19.9782 1 16 1C12.0218 1 8.20644 2.58035 5.3934 5.3934C2.58035 8.20644 1 12.0218 1 16V16Z" stroke="#0F62F9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg> -->

          <v-btn
            class="blue-buttons"
            @click="$router.push('/family-members/family/add-new')"
            >Add New Member</v-btn
          >
        </v-col>
      </v-row>
      <v-row >
        <div class="col-md-6" v-for="(item, index) in items" :key="index">
          <v-card
            class="pointer"
            @click="$router.push('/family-members/family/details')"
          >
            <v-card-title class="justify-content-between">
              <div class="col-md-6">
                {{ item.providerName }}
              </div>
              <div class="text-underline light-blue-text col-md-6 text-end">
                Select Provider
              </div>
            </v-card-title>
          </v-card>
        </div>
      </v-row>
    </div>
    <div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          providerName: "William Smith",
        },
        {
          providerName: "Beverly Smith",
        },
        {
          providerName: "William Smith Jr.",
        },
        {
          providerName: "Janice Smith",
        },
        {
          providerName: "John Smith",
        },
      ],
    };
  },
  computed: {
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
  },
};
</script>
