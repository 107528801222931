<template>
 <v-row class="add-client-form no-padding" >
    
     <successModal  ref="success_modal" v-on:confirm="confirmSuccess"></successModal>

  <div class="col-md-12 form-box ">
    <div class="row">
           
            <div class="col-md-6">
                <div class="row col-md-9" cols="12">
                    <v-select :items="dependents.length > 0 ? dependents : employee" class="no-padding"  ></v-select>
                    <span class="text-gray small-text mt-2">View Associated Members</span>
                </div>
            </div>
          
            <h3 class="form-title font col-md-3 row ml-1" cols="6">
                 <v-select
                    label="Status"
                    v-model.trim="status"
                    small
                    :color="status == 'Active' ? 'green' : 'red'"
                    :item-color="status == 'Active' ? 'green' : 'red'" 
                    :items="[{text:'Active', value:'active'}, {text:'Inactive', value:'inactive'}]">
                </v-select>
            </h3>
    
       
    </div>
    <v-form ref="form" v-model.trim="valid" class="row">
        <div class="col-md-6 no-padding">
            <div class="col-md-12">
               <label class="font text-gray" for="">First Name </label>
                <v-text-field
                tabindex="1"
                required
                :rules="requiredRules"
                v-model.trim="firstName"
                solo
                ></v-text-field>
           </div>
            <div class="col-md-12">
              <label class="font text-gray" for="">Last Name</label>
                <v-text-field
                tabindex="2"
                 :rules="requiredRules"
                required
                solo
                v-model.trim="lastName"
                ></v-text-field>
           </div>
            <div class="col-md-12">
                <label class="font text-gray" for="">Email </label>
                <v-text-field
                 tabindex="3"
                  :rules="emailRules"
                required
                v-model.trim="email"
                solo
                ></v-text-field>
            </div>
            <div class="col-md-12">
                <label class="font text-gray" for="">Sex</label>
                <div class="row col-md-12 justify-space-between">
                       <div  :class="gender == 'male' ? 'col-md-4 blue-buttons  text-center  border-radius ' : 'col-md-4 gender-buttons  text-center  text-gray'" @click="getGender('male')" tabindex="4">Male</div>
                    <div :class="gender == 'female' ? 'col-md-4 blue-buttons  text-center  border-radius ' : 'col-md-4 gender-buttons  text-center  text-gray'" @click="getGender('female')" tabindex="5">Female</div> 
                </div>
           </div>
           <div class="col-md-12 row">
            <div class="col-md-6">
                <label class="font text-gray" for="">Birth Month</label>
                <v-text-field
                required
                v-model.trim="birthMonth"
                tabindex="6"
                  :rules="requiredRules"
                solo
                ></v-text-field>
            </div>
             <div class="col-md-3">
                <label class="font text-gray" for="">Day</label>
                <v-text-field
                required
                :rules="requiredRules"
                 type="number"
                v-model.trim="birthDay"
                tabindex="7"
                solo
                ></v-text-field>
            </div>
             <div class="col-md-3">
                <label class="font text-gray" for="">Year</label>
                <v-text-field
                required
                tabindex="8"
                :rules="requiredRules"
                 type="number"
                v-model.trim="birthYear"
                solo
                ></v-text-field>
            </div>
         </div>
            <div class="col-md-12">
                <label class="font text-gray" for="">Social Security Number</label>
                <v-text-field
                tabindex="9"
                required
                :rules="requiredRules"
                solo
                v-model.trim="socialNumber"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                @click:append="show1 = !show1"
                ></v-text-field>
            </div>
        </div>
        <div class="col-md-6 no-padding" >
              <div class="col-md-12">
               <label class="font text-gray" for="">Member Type</label>
                      
                <v-select
                tabindex="10"
                v-model.trim="memberType"
                :rules="requiredRules"
                required
                solo
                :items="[{text:'Employee', value:'employee'},{text:'Dependent', value:'dependent'}]"
                ></v-select>
           </div>
            <div class="col-md-12">
               <label class="font text-gray" for="">Home Address 1</label>
                <v-text-field
                tabindex="10"
                required
                solo
                :rules="requiredRules"
                v-model.trim="homeAddres1"
                ></v-text-field>
           </div>
          <div class="col-md-12">
            <label class="font text-gray" for="">Address 2</label>
                <v-text-field
                tabindex="11"
                required
                solo
                :rules="requiredRules"
                v-model.trim="homeAddress2"
                ></v-text-field>
           </div>
          <div class="col-md-12 row">
            <div class="col-md-6">
                <label class="font text-gray" for="">City</label>
                <v-text-field
                required
                :rules="requiredRules"
                tabindex="12"
                v-model.trim="city"
                solo
                ></v-text-field>
            </div>
             <div class="col-md-3">
                <label class="font text-gray" for="">Zip</label>
                <v-text-field
                required
                tabindex="13"
                type="number"
                :rules="requiredRules"
                v-model.trim="zip"
                solo
                ></v-text-field>
            </div>
             <div class="col-md-3">
                <label class="font text-gray" for="">State</label>
                <v-text-field
                required
                :rules="requiredRules"
                v-model.trim="state"
                tabindex="14"
                solo
                
                ></v-text-field>
            </div>
        </div>
       
        </div>           
        <div class="col-md-12 text-xl-end text-lg-end text-sm-start">
                <v-btn cols="12"  tabindex="16" class="blue-buttons mr-6 mb-2" @click="$router.push(`/employer/members`)">
                   Cancel
                </v-btn>
                 <v-btn cols="12"  tabindex="16" class="blue-buttons mr-6 mb-2" @click="addDependent()">
                 Add Dependent
                </v-btn>
            
        </div>
      </v-form>
        <errorModal  ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
    </div>
    
       
 </v-row>
</template>
<script>
import api from '../../../../../services/api'
import successModal from '../../../../TablesTemplate/SuccessDialog.vue'
import removeDialog from '../../../../TablesTemplate/RemoveDialog.vue'
import errorModal from '../../../../TablesTemplate/ErrorDialog.vue'
 export default{
    components:{
        successModal,
        removeDialog,
        errorModal

     },
    data(){
        return{
            show1:false,
            dialog:false,
            dialog1:false,
            firstName:'',
            lastName:'',
            email:'',
            sex:'',
            birthMonth: '',
            birthDay:'',
            birthYear:'',
            memberType:'',
            homeAddres1:'',
            homeAddress2:'',
            city:'',
            zip:'',
            state:'',
            socialNumber:'',
            gender:'',
            status:'',
            requiredRules:[
                v => !!v || 'This field is required',
            ],
            emailRules: [
              v => !!v || 'This field is required',
              v => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || 'E-mail must be valid',
            ],
            valid:false

        }
    },
    computed:{
        singleMember(){
           return this.$store.getters.getEmployerSingleMember;
        },
        routeParams(){
         return this.$route.params.name
        },
        dependents(){
            let dep = []
           
            if(this.$store.getters.getEmployerSingleMemberDependent){
                dep = this.$store.getters.getEmployerSingleMemberDependent
            }
            
            return dep
        },
        employee(){
            let emp = [];
            if(this.$store.getters.getEmployerSingleMemberEmployee){
                emp = this.$store.getters.getEmployerSingleMemberEmployee
            }
            return emp
        }
    },
    created(){
        

    },
    methods:{
        closeErrorModal(){
            this.$refs.error_modal.closePopUp()
        },
        confirmSuccess(){
            this.$router.push(`/employer/members`)
        },
         getGender(gender){
             this.gender = gender
         },
         addDependent(){
            let user = JSON.parse(localStorage.getItem('user'));
            let employer = user.groupPortal[0].organization.employer
           
            this.$refs.form.validate();
            if(this.valid){
            let data = {
                "firstName":this.firstName,
                "lastName": this.lastName,
                "email": this.email,
                "zipCode": this.zip,
                "sex": this.gender,
                "birthdate": `${this.birthDay}/${this.birthMonth}/${this.birthYear}`,
                "ssn": this.securityNumber,
                "memberType": this.memberType,
                "address1": this.address,
                "address2": this.address2,
                "city": this.city,
                "state": this.state,
                'status':this.status
            };
           
              api().post(`/employers/${employer}/members/${this.singleMember._id}/dependents`, data)
              .catch((err)=>{
                     this.$refs.error_modal.openPopUp(err.response.data.message.message);
              })
              .then((res)=>{
                  if(res){
                     this.$refs.success_modal.openPopUp('Dependent successfully added');
                  }
               
                //this.$store.commit('setSingleEmployerMember', res.data.member)
            })
          } 
         },
        
    }
 }
</script>

