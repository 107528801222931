<template>
  <div class="">
    <div class="row border-blue-bottom mb-3">
      <div class="col-md-12 mb-4 mt-4 ml-4 font-weight-bold text-gray">
        Solution Detail
      </div>
    </div>
    <div v-if="item">
      <div class="row form-row-height pt-2 pb-2 light-blue-text">
        <div
          class="col-md-2 ml-4 font-weight-bold"
          style="border-right: 1px solid #eeeded"
        >
          General Information
        </div>
        <div class="col-md-8">
          <div class="row col-md-12 font-weight-bold">
            General Information
            <v-divider class="mt-3 ml-3"></v-divider>
          </div>
          <div class="row mt-10">
            <div class="col-md-6 ml-3">
              <div class="col-md-12">
                <span class="text-gray font-weight-bold">Solution Name:</span>
                <span class="text-gray font-weight-bold ml-4">{{
                 item.vendor ?  item.vendor.companyName : '/'
                }}</span>
              </div>
              <br>
              <div class="col-md-12">
                <span class="text-gray font-weight-bold"
                  >Solution Category:</span
                >
                <span class="text-gray font-weight-bold ml-4">{{
                  item.category
                }}</span>
              </div> <br>
              <div class="col-md-12">
                <span class="text-gray font-weight-bold">Solution URL:</span>
                <span class="text-gray font-weight-bold ml-4">{{
                  item.url
                }}</span>
              </div> <br>
              <div class="col-md-12">
                <span class="text-gray font-weight-bold"
                  >Solution Contact:</span
                >
                <span class="text-gray font-weight-bold ml-4">{{
                  item.contact
                }}</span>
              </div> <br>
              <div class="col-md-12">
                <span class="text-gray font-weight-bold">Solution Email:</span>
                <span class="text-gray font-weight-bold ml-4">{{
                  item.email
                }}</span>
              </div> <br>
              <div class="col-md-12">
                <span class="text-gray font-weight-bold"
                  >Solution Definition:</span
                >
                <span class="text-gray font-weight-bold ml-4">{{
                  item.definition
                }}</span>
              </div>
            </div>
            <div class="col-md-5" v-if="item.vendor">
              <div class="col-md-12">
                <span class="text-gray font-weight-bold">Vendor Name:</span>
                <span class="text-gray font-weight-bold ml-4">{{
                 item.vendor.companyName
                }}</span>
              </div> <br>
              <div class="col-md-12">
                <span class="text-gray font-weight-bold">Vendor Address:</span>
                <span class="text-gray font-weight-bold ml-4">{{
                  item.vendor.address1
                }}</span>
              </div> <br>
              <div class="col-md-12">
                <span class="text-gray font-weight-bold">Vendor Website:</span>
                <span class="text-gray font-weight-bold ml-4">{{
                  item.vendor.website
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="gray-top-border">
      <div class="col-md-12" align="end">
        <span
          class="light-blue-text text-underline mr-10 font-weight-bold pointer"
          @click="$router.push('/digital-invoicing/solutions')"
          >Cancel</span
        >
        <span
          class="light-blue-text text-underline mr-10 ml-10 font-weight-bold pointer"
          @click="$router.push('/digital-invoicing/solutions')"
          >Close</span
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      item: null,
    };
  },

  computed: {},
  created() {
    this.item = this.$store.state.Invoicing.singleSoulution;
  },
  methods: {},
};
</script>
