<template>
  <div class="page-background">
    <header-menu-gray :title="'Marketplace'"></header-menu-gray>
    <div class="mobile-content mobile-content-background">
      <div class="d-flex justify-space-between">
        <div @click="changeTab('favorites')" :class="tab == 'favorites' ? 'text-underline' : ''" style="text-underline-offset: 4px;" class="dark-blue-header">Favorites</div>
        <div @click="changeTab('all')" :class="tab == 'all' ? 'text-underline' : ''" style="text-underline-offset: 4px;" class="dark-blue-header">All Solutions</div>
      </div>
      <div>
        Slider
      </div>
      <div class="d-flex flex-column mt-4 mb-2" v-for="(solutionCards,title) in filteredSolutions" :key="title" v-if="solutionCards.length > 0">
        <div class="dark-blue-header mb-2 d-flex justify-space-between">
          <span>
            {{ title }}
          </span>
          <div>
            <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.66663 1.3335L6.29285 5.95972C6.68338 6.35025 7.31654 6.35025 7.70707 5.95972L12.3333 1.33349" stroke="#16407A" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
        <div class="d-flex justify-space-between align-center solution-card mb-3" v-for="(solutionCard,index) in solutionCards" :key="index">
          <div class="d-flex align-center">
            <div class="logo mr-2">
              <img :src="solutionCard.logo" alt="logo" style="width: 40px;">
            </div>
            <div class="d-flex flex-column">
              <div class="navy-title">{{ solutionCard.title }}</div>
              <div class="d-flex flex-row tags">
                <div class="tag mr-1" v-for="(tag,index) in solutionCard.tags" :key="'tag_'+index">{{ tag }}</div>
              </div>
            </div>
          </div>
          <div class="heart">
            <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="solutionCard.favorite" @click="toggleFavorite(false,solutionCard._id,title)">
              <path d="M12.0915 19.9488L12.0569 19.969C12.024 19.9881 11.9833 19.9877 11.9508 19.968V19.968C-4.71117 10.2583 1.00699 0.887911 7.19171 1.00101C7.38961 1.00101 7.58982 1.0202 7.78772 1.04371C9.90231 1.29186 11.4663 3.12623 11.8895 3.67316C11.9464 3.74679 12.0609 3.74679 12.1179 3.67318C12.5411 3.12642 14.1051 1.29345 16.2196 1.05426C22.5952 0.292925 29.235 9.93218 12.0915 19.9488Z" fill="#C24141" stroke="#C24141" stroke-width="2"/>
            </svg>
            <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-else @click="toggleFavorite(true,solutionCard._id,title)">
              <path d="M12.0915 19.9488L12.0569 19.969C12.024 19.9881 11.9833 19.9877 11.9508 19.968V19.968C-4.71117 10.2583 1.00699 0.887911 7.19171 1.00101C7.38961 1.00101 7.58982 1.0202 7.78772 1.04371C9.90231 1.29186 11.4663 3.12623 11.8895 3.67316C11.9464 3.74679 12.0609 3.74679 12.1179 3.67318C12.5411 3.12642 14.1051 1.29345 16.2196 1.05426C22.5952 0.292925 29.235 9.93218 12.0915 19.9488Z" stroke="#16407A" stroke-width="2"/>
            </svg>
          </div>
        </div>
        <span class="blue-title" v-if="solutionCards.length > 3">See all ></span>
      </div>
    </div>
  </div>
</template>

<script>
import headerMenuGray from "@/components/Sections/DPCMember/Layout/headerMenuGray";
export default {
  data() {
    return {
      tab:'all',
      solutions: {
        'Direct Primary Care':[
          {
            _id:'f4f5s4fs7afasf65jcc',
            title:'Solution Name',
            logo:'/logo.png',
            tags:['covered','app'],
            favorite:true
          }
        ],
        'Chronic Disease':[
          {
            _id:'f4f5s4fs7afq112asfc',
            title:'Solution Name',
            logo:'/logo.png',
            tags:['covered','app'],
            favorite:false
          },
          {
            _id:'f4f5s4fs7afa124ssfc',
            title:'Solution Name',
            logo:'/logo.png',
            tags:['covered','app'],
            favorite:true
          },
          {
            _id:'f4f5s4fs7afaff2ssfc',
            title:'Solution Name',
            logo:'/logo.png',
            tags:['uncovered','app'],
            favorite:false
          },
          {
            _id:'f4f5s42217afaff2ssfc',
            title:'Solution Name',
            logo:'/logo.png',
            tags:['uncovered','app'],
            favorite:false
          }
        ],
        'Wellness':[
          {
            _id:'f4f5s4fas2faff2ssfc',
            title:'Solution Name',
            logo:'/logo.png',
            tags:['covered','app'],
            favorite:false
          }
        ],
      },
      filteredSolutions: {},
    };
  },
  components:{
    headerMenuGray
  },
  methods: {
    toggleFavorite(type,id,title){
      this.solutions[title].find(i=>i._id == id).favorite = type
    },
    changeTab(tabType){
      this.tab = tabType
      if(this.tab == 'favorites'){
        this.filteredSolutions = {}
        Object.entries(this.solutions).map((data) => {
          this.filteredSolutions[data[0]] = data[1].filter((tab) => {
            return tab.favorite == true
          })
        })
      }else{
        this.filteredSolutions = this.solutions
      }
    }
  },
  created() {
    this.filteredSolutions = this.solutions
  },
};
</script>
<style scoped src='./../../../../assets/mobileStyle.css'></style>
<style scoped lang="scss">
.solution-card{
  border: 1px solid rgba(128, 128, 128, 0.15);
  padding: 4px 8px;
  box-shadow: 0 0 3px 2px rgba(128, 128, 128, 0.15);
}
.tag{
  background-color: #EBF1F9;
  padding: 4px;
  font-size: 12px;
  color: #16407A;
}

.mobile-content-background{
  background-color:white;
  min-height:100vh;
}
.mobile-content{
  padding:16px 20px 0 20px;
}
.page-background{
  background-color: #E8E8E8;
}

</style>