<template>
    <v-row class="padding add-client-form">
            <form class="row">
                    <v-col md="6" cols="12" class="justify-center align-center">
                        <div class="row col-md-12 font"> 
                            <h3 class="font">Upload Census</h3>
                        </div>
                        <div class="row text-center justify-center upload-box">
                            <div class="col-md-9 upload-provider-box pointer" @click="addItemExcel">
                                <input ref="fileInput" type="file" style="display: none" @change="uploadExcel">
                                <img src="/filee.png" class="apaly-pic" alt="">
                                <h3 class="text-gray font">Drag & Drop</h3>
                                <h5 class="text-gray font">
                                    Your files here or 
                                    <a >Browse</a> 
                                    to upload
                                 </h5>
                                <h5 class="text-gray font">
                                    <a >Excel or CSV files Only</a>
                                </h5>
                            </div>
                            <div class="btn-box col-md-10 ">
                                <div class="downoald-file-btn-box ">
                                    <h3>
                                        <a  class="underline-text">Download File Template</a>
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div class="row col-md-11 text-end justify-end" align="end">
                            <div class="">
                                <v-btn class="blue-buttons">Upload</v-btn>
                            </div>
                        </div>
                    </v-col>
                    <v-col md="6" cols="12" class="row y-scroll mt-15" >
                        <v-col md="6" cols="12">
                            <v-col md="12 text-gray">
                                <div>Match the Fields on Platform</div>
                                <div class="red--text">(* - required field)</div>
                            </v-col>
                            <v-col md="12 text-gray">
                                <v-text-field class="custom-placeholer-color" v-model.trim="firstName" solo placeholder="First Name *"></v-text-field>
                            </v-col>
                              <v-col md="12 text-gray">
                                <v-text-field class="custom-placeholer-color" v-model.trim="lastName" solo placeholder="Last Name *"></v-text-field>
                            </v-col>
                              <v-col md="12 text-gray">
                                <v-text-field class="custom-placeholer-color" v-model.trim="email" solo placeholder="Email *"></v-text-field>
                            </v-col>
                              <v-col md="12 text-gray">
                                <v-text-field class="custom-placeholer-color" v-model.trim="zip" solo placeholder="Zip Code *"></v-text-field>
                            </v-col>
                              <v-col md="12 text-gray">
                                <v-text-field  solo placeholder="Sex" v-model.trim="gender"></v-text-field>
                            </v-col>
                              <v-col md="12 text-gray">
                                <v-text-field solo placeholder="DOB" v-model.trim="dob"></v-text-field>
                            </v-col>
                              <v-col md="12 text-gray">
                                <v-text-field solo placeholder="Social Security Number" v-model.trim="securityNumber"></v-text-field>
                            </v-col>
                              <v-col md="12 text-gray">
                                <v-text-field solo placeholder="Address 1" v-model.trim="address1"></v-text-field>
                            </v-col>
                              <v-col md="12 text-gray">
                                <v-text-field solo placeholder="Address 2" v-model.trim="address2"></v-text-field>
                            </v-col>
                              <v-col md="12 text-gray">
                                <v-text-field solo placeholder="City" v-model.trim="city"></v-text-field>
                            </v-col>
                        </v-col>
                        <v-col md="6" cols="12">
                           <v-col md="12 text-gray">
                                <div>With Fields in Your File</div>
                                <div class="white--text">t</div>
                            </v-col>
                            <v-col md="12">
                                <v-select  class="custom-placeholer-color" solo placeholder="Select" ></v-select>
                            </v-col>
                             <v-col md="12">
                                <v-select  class="custom-placeholer-color" solo placeholder="Select" ></v-select>
                            </v-col>
                             <v-col md="12">
                                <v-select   solo placeholder="Email" ></v-select>
                            </v-col>
                             <v-col md="12">
                                <v-select   solo placeholder="Postal Code" ></v-select>
                            </v-col>
                             <v-col md="12">
                                <v-select   solo placeholder="Sex" ></v-select>
                            </v-col>
                             <v-col md="12">
                                <v-select   solo placeholder="Date of Birth" ></v-select>
                            </v-col>
                             <v-col md="12">
                                <v-select   solo placeholder="SSN" ></v-select>
                            </v-col>
                             <v-col md="12">
                                <v-select   solo placeholder="Address 1" ></v-select>
                            </v-col>
                             <v-col md="12">
                                <v-select   solo placeholder="Address 2" ></v-select>
                            </v-col>
                              <v-col md="12">
                                <v-select   class="custom-placeholer-color" solo placeholder="Select" ></v-select>
                            </v-col>
                        </v-col>
                    </v-col>
                
                    <div class="col-md-12 d-flex align-end flex-column mt-10">
                        <div>
                            <v-btn class="blue-buttons mr-6" @click=" $router.push(`/employer-advisor/employers/${routeParams}/members`)">
                              Cancel
                            </v-btn>
                            <v-btn class="blue-buttons mr-6" @click="submit">
                              Submit
                            </v-btn>
                        </div>
                    </div>
           </form>

 </v-row>
</template>
<script>
 import XLSX from 'xlsx'
export default{
    data(){
        return{
            excelError:false,
            arrayExcel:[],
            firstName:'',
            lastName:'',
            email:'',
            zip:'',
            gender:'',
            dob:'',
            securityNumber:'',
            address1:'',
            address2:'',
            city:'', 
        }
    },
    computed:{
        route(){
             return this.$route.path
        },
        getClient(){
            return this.$store.getters.getClientGetter
        },
        routeParams(){
            return this.$route.params.name
         }
    },
    methods:{
        addItemExcel() {
          this.$refs.fileInput.click()
         },
        uploadExcel(e){
         const files = e.target.files;
            if (!files.length) {
                return;
            } else if (!/\.(xls|xlsx|csv)$/.test(files[0].name.toLowerCase())) {
                return alert("The upload format is incorrect. Please upload xls, xlsx or csv format");
            }
            const fileReader = new FileReader();
            fileReader.onload = ev => {
                try {
                const data = ev.target.result;
                const workbook = XLSX.read(data, {
                    type: "binary"
                });
                const wsname = workbook.SheetNames[
                    0
                    ]; 
                const ws = XLSX.utils.sheet_to_json(workbook.Sheets[
                    wsname
                    ]); 
                const excellist = []; 
               
                for (var i = 0; i < ws.length; i++) {
                    excellist.push(ws[i].ids);
                    if(ws[i].ids){
                      this.excelError = false
                    }else{
                      this.excelError = true
                    }
                }

                this.arrayExcel =
                    excellist 
                } catch (e) {
                return alert("Read failure!");
                }
                 
            };
           fileReader.readAsBinaryString(files[0]);
        },
        submit(){
            let data = {
                'firstName' : '',
                'lastName' : '',
                'email' : '',
                'zip' : '',
                'gender':'',
                'dob':'',
                'securityNumber':'',
                'address1':'',
                'address2':'',
                'city':''
            }
            // api().post('', data).then((res)=>{
            //    console.log(res)
            //    this.$router.push(`/employer-advisor/employers/${this.routeParams}/members`)
            //})
            this.$router.push(`/employer-advisor/employers/${this.routeParams}/members`)
        }
    }
}
</script>
<style>
 
</style>