<template>
  <div :class="showOverlay ? ' postion-relative mt-2 ' : ''" class="row pa-3">
    <div  @click="$router.push('/vendors/solutions')" role="button" class=" pa-0 col-md-12 mb-0 ml-4 mt-4 font-weight-bold text-gray d-flex">
      <svg
      @click="$router.go(-1)"
        class="mr-2 mt-1"
        width="14"
        height="16"
        viewBox="0 0 14 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.6269 23.764L1.99634 12.9562C1.92725 12.8861 1.87267 12.803 1.83571 12.7118C1.79875 12.6205 1.78014 12.5229 1.78095 12.4245C1.78176 12.326 1.80197 12.2287 1.84042 12.1381C1.87888 12.0474 1.93482 11.9653 2.00505 11.8963L12.8118 1.26475"
          stroke="gray"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <p  @click="$router.go(-1)">Back to Solutions</p>
    </div>
      <!-- <div
        class="d-flex flex-column flex-md-row text-start pa-2"
        style="width: 100%"
      >
     
        <div class="d-flex mr-0">
          <div
            :class="{ active: tab == 'all_solutions' }"
            @click="changeTab('all_solutions')"
            class="content-header-menu-button mr-3 ml-0 ml-md-5 text-gray font-weight-bold font-14 cursor-pointer"
          >
            All Solutions
          </div>
          <div class="mr-3 text-gray">|</div>
          <div
            @click="changeTab('my_solutions')"
            class="content-header-menu-button text-gray font-weight-bold font-14 cursor-pointer"
            :class="{ active: tab == 'my_solutions' }"
          >
            My Solutions
          </div>
        </div>
      </div> -->
     
      <div
          class="d-flex flex-column flex-md-row align-center blue-underline mt-2"
          style="width: 100%"
        >
          <div class="mr-0">
            <div class="d-flex mr-1">
              <div class="pa-2">
                <img
                  src="/newapalylogoonlyblue.png"
                  width="95"
                  height="120"
                  class="img-mobile"
                />
              </div>
              <div class="d-flex flex-column pa-2 mt-5">
                <span class="font-36">Find your specialist</span>
                <span class="font-20"
                  >Get connected with them and start your high performance
                  healthcare</span
                >
              </div>
            </div>

            <div class="col-12 pt-0">
              <span
                class="col-12 col-lg-12 col-xl-12 col-md-12 d-md-flex pa-0 pt-0 mt-0"
              >
                <span class="pa-1 col-5">
                  <v-text-field
                    prepend-inner-icon="mdi-magnify"
                    v-model="searchQuery"
                    class="mr-3"
                    light
                    dense
                    outlined
                    hide-details
                    placeholder="Search by Name or Specialty"
                  ></v-text-field>
                </span>
                <span class="pa-1 d-flex"
                  ><v-text-field
                    prepend-inner-icon="mdi-map-marker"
                    v-model="zipOrState"
                    @keyup.enter="searchByZip"
                    class="mr-3"
                    light
                    dense
                    outlined
                    hide-details
                    placeholder="Search by Zip Code"
                  ></v-text-field>
                  <v-btn depressed color="primary" @click="searchByZip()">
                    <v-icon dark> mdi-magnify </v-icon>
                  </v-btn>
                </span>
              </span>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-lg-12 col-xl-12 col-8 pb-0 mb-0 d-flex">
          <span class="col-8 text-start">
            <v-sheet max-width="500">
              <v-slide-group show-arrows>
                <v-slide-item
                  v-for="(category, categoryIndex) in selectedCategories"
                  :key="'category-' + categoryIndex"
                >
                  <v-chip closable @click="removeChip(category, selectedCategories)" class="mr-1">
                    {{ category }}
                    <v-icon right> close </v-icon>
                  </v-chip>
                </v-slide-item>
                <v-slide-item
                v-for="(crendential, credentialIndex) in selectedCredentials"
                :key="'crendential-' + credentialIndex"
              >
                <v-chip closable @click="removeChip(crendential,selectedCredentials)" class="mr-1">
                  {{ crendential }}
                  <v-icon right> close </v-icon>
                </v-chip>
              </v-slide-item>
              <v-slide-item
              v-for="(gender, genderIndex) in selectedGenders"
              :key="'gender-' + genderIndex"
            >
              <v-chip closable @click="removeChip(gender,selectedGenders)" class="mr-1">
                {{ gender == 'F' ? 'Female' : 'Male' }}
                <v-icon right> close </v-icon>
              </v-chip>
            </v-slide-item>
            <v-slide-item
            v-for="(group, groupIndex) in selectedGroupAndOrganizations"
            :key="'group-' + groupIndex"
          >
            <v-chip closable @click="removeChip(group,selectedGroupAndOrganizations)" class="mr-1">
              {{ group }}
              <v-icon right> close </v-icon>
            </v-chip>
          </v-slide-item>
          <v-slide-item
            v-for="(speciality, specialityIndex) in selectedSpecialities"
            :key="'speciality-' + specialityIndex"
          >
            <v-chip closable @click="removeChip(speciality,selectedSpecialities)" class="mr-1">
              {{ speciality }}
              <v-icon right> close </v-icon>
            </v-chip>
          </v-slide-item>
              </v-slide-group>
            </v-sheet>
          </span>

          <span class="col-4 text-end">
            <v-btn
              @click.stop="drawer = !drawer"
              color="primary"
              elevation="3"
              outlined
            >
              <v-icon right dark class="mr-1 ml-0"> mdi-filter </v-icon>
              Filter solutions
            </v-btn>
          </span>
        </div>
        <v-navigation-drawer
          v-model.trim="drawer"
          width="350"
          absolute
          temporary
          right
          style="overflow: hidden"
        >
          <div class="scrollable-drawer">
            <div>
              <div class="drawer-header">
                <v-list-item class="apaly-bg">
                  <v-list-item-title class="d-flex justify-space-between">
                    <h4 style="color: white">Filter Solutions</h4>
                    <h3
                      role="button"
                      class="cursor-pointer"
                      @click.stop="drawer = !drawer"
                      style="color: white"
                    >
                      <v-icon right dark class="mr-1 ml-0"> mdi-close </v-icon>
                    </h3>
                  </v-list-item-title>
                </v-list-item>
              </div>
              <div
                class="drawer-content drawer-mobile-height"
                style="height: 75vh; overflow: scroll"
              >
                <v-expansion-panels
                  accordion
                  multiple
                  v-model.trim="panel"
                  class="no-shadow no-padding user-expansion-panels"
                >
                  <v-expansion-panel class="no-shadow no-padding">
                    <v-expansion-panel-header
                      class="font-18 font-weight-black change-padding-filter filter-items-font"
                      >Type</v-expansion-panel-header
                    >
                    <v-expansion-panel-content
                      class="no-padding content-filter"
                    >
                      <v-checkbox
                      class="pa-0 checkbox-label-size"
                        v-for="category in categories"
                        :key="category.value"
                        v-model="selectedCategories"
                        :label="category.label"
                        :value="category.value"
                      >
                      </v-checkbox>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel class="no-shadow no-padding">
                    <v-expansion-panel-header
                      class="font-18 font-weight-black change-padding-filter filter-items-font"
                      >Provider</v-expansion-panel-header
                    >
                    <v-expansion-panel-content
                      class="no-padding content-filter"
                    >
                      <h4 class="text-start mb-2">Credentials</h4>
                      <div class="pa-0 ma-0 mb-3 scrollable-creds">
                        <v-checkbox
                        class="pa-0 checkbox-label-size"
                          v-for="(credentials, i) in providerCredentials"
                          :key="i"
                          v-model="selectedCredentials"
                          :label="credentials"
                          :value="credentials"
                        >
                        </v-checkbox>
                      </div>
                      <v-divider horizontal> </v-divider>
                      <h4 class="text-start mb-2">Gender</h4>
                      <div class="d-flex justify-space-between pb-0 mb-0">
                        <p class="mb-0">Female</p>
                        <v-switch  lass="mb-0" v-model="selectedGenders" value="F"> </v-switch>
                      </div>
                      <div class="d-flex justify-space-between">
                        <p>Male</p>
                        <v-switch  v-model="selectedGenders" value="M"> </v-switch>
                      </div>
                      <h4 class="text-start mb-2">Specialty</h4>
                      <div class="pa-0 ma-0 mb-3 scrollable-creds">
                        <v-checkbox
                        class="pa-0 checkbox-label-size"
                          v-for="(speciality, ind) in specialities"
                          :key="ind"
                          v-model="selectedSpecialities"
                          :label="speciality"
                          :value="speciality"
                        >
                        </v-checkbox>
                      </div>
                      <v-divider horizontal> </v-divider>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel class="no-shadow no-padding">
                    <v-expansion-panel-header
                      class="font-18 font-weight-black change-padding-filter filter-items-font"
                      >Group or Organization</v-expansion-panel-header
                    >
                    <v-expansion-panel-content
                      class="no-padding content-filter"
                    >
                      <div class="pa-0 ma-0 mb-3 scrollable-creds">
                        <v-checkbox
                        class="pa-0 checkbox-label-size"
                          v-for="(grAndOrg, indx) in groupAndOrganizations"
                          :key="indx"
                          v-model="selectedGroupAndOrganizations"
                          :label="grAndOrg"
                          :value="grAndOrg"
                        >
                        </v-checkbox>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </div>
            <div class="drawer-footer">
              <v-list-item>
                <v-list-item-content class="justify-center">
                  <v-list-item-title class="d-flex justify-center">
                    <v-btn class="mr-5" @click="clearSearch()">
                      Clear All
                    </v-btn>
                    <v-btn color="primary" @click="searchResults()">
                      View results
                    </v-btn>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </div>
        </v-navigation-drawer>
   
    <v-card-title class="ma-2">
      <span class="font-weight-500 font-36" style="color: #333333">{{category}} </span>
    </v-card-title>
    <div class="row-height row col-12 ml-2">
      <div v-if="getCategoryItems(category) == false" class="col-12"> 
        <v-alert border="bottom" colored-border color="primary" elevation="2" class="col-5 text-gray mt-2 ml-2">
          No results found! <a @click="clearSearch()" class="text-underline">Reset</a>
        </v-alert>
    </div>
      <div v-else class="col-12 row ma-1" style="width: 100%">                 
        <v-card  v-for="(item,index) in getCategoryItems(category)" :key="index" outlined
        color="transparent" height="210px"  class="col-2 mb-5 mt-1 pa-0 box-hover" >
        <div class="display pl-1 pr-1 pb-1"  @click="goToDetails(item, item.entity)">
          <div
                        class="justify-start mb-1 mt-1 ml-0 text-start"
                        style="overflow: hidden"
                      >
                        <img
                          v-if="item.entity == 'solution'"
                          :src="
                            item.logo ? item.logo : '/tpa/virtualNetwork.svg'
                          "
                          class="pointer mt-3 mb-0"
                          height="65"
                        />
                        <img
                          v-if="item.entity == 'location'"
                          :src="
                            item.logo
                              ? item.logo
                              : '/tpa/clinicsAndOrganizations.svg'
                          "
                          class="pointer mt-3 mb-0"
                          height="65"
                        />
                        <img
                          v-if="item.entity == 'vendor'"
                          :src="
                          item.logo
                              ? item.logo
                              : '/tpa/pointSolutionVendor.svg'
                          "
                          class="pointer mt-3 mb-0"
                          height="65"
                        />
                        <img
                          v-if="item.entity == 'provider'"
                          :src="item.logo ? item.logo : '/tpa/clinicians.svg'"
                          class="pointer mt-3 mb-0"
                          height="65"
                        />
                      </div>
                      <div class="text-start mt-0 text-ellipsis">
                        <p class="font-14 font-weight-bold mb-1 mt-0">
                          {{ item.name }}
                        </p>
                      </div>
                      <div class="text-start text-ellipsis">
                        <p class="font-12 mb-1 text-gray">
                          {{ item.address }}, {{ item.city }}, {{ item.state }},
                          {{ item.zipCode }}
                        </p>
                      </div>
                      <div class="justify-start">
                        <v-chip
                          v-if="item.entity == 'solution'"
                          class="pa-2"
                          color="#AAC9F2"
                          text-color="#333333"
                          small
                        >
                          Virtual Network
                        </v-chip>
                        <v-chip
                          v-if="item.entity == 'vendor'"
                          class="pa-2"
                          color="#FFE2CC"
                          text-color="#333333"
                          small
                        >
                          Point Solution Vendor
                        </v-chip>
                        <v-chip
                          v-if="item.entity == 'location'"
                          class="pa-2"
                          color="#FFF4BF"
                          text-color="#333333"
                          small
                        >
                          Clinic & Organization
                        </v-chip>
                        <v-chip
                          v-if="item.entity == 'provider'"
                          class="pa-2"
                          color="#CDFFCC"
                          text-color="#333333"
                          small
                        >
                          Clinician
                        </v-chip>
                      </div>
        </div>
      </v-card> 
     </div>
    </div>
  <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <removeDialog
      ref="dialog_delete"
      v-on:confirm="confirmRemove"
    ></removeDialog>

    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
    <router-view></router-view>
  </div>
</template>

<script>
import api from "../../../../services/api";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../TablesTemplate/ErrorDialog.vue";
import removeDialog from "../../../TablesTemplate/RemoveDialog.vue";

export default {
  components: { successModal, errorModal, removeDialog },
  data() {
    return {
      marketplace: [],
      searchQuery: "",
      loader: false,
      model: null,
      items:[],
      zipOrState: '',
      filteredItems:[],
      showOverlay: false,
      category: "",
      panel: [0, 2],
      drawer: null,
      selectedCategories: [],
      selectedCredentials: [],
      selectedSpecialities: [],
      selectedGroupAndOrganizations: [],
      female: false,
      male: false,
      selectedGenders: [],
      genders: [],
      categories: [
        { label: "Virtual Networks", value: "virtualNetwork" },
        { label: " Point Solution Vendors", value: "vendors" },
        { label: " Clinics & Organizations", value: "clinics" },
        { label: "Clinicians ", value: "clinicians" },
      ],
      tab: "all_solutions",
      searchByCategory: [],
      providerCredentials: [],
      specialities: [],
      groupAndOrganizations: [],
      searchLabels: [],
      data: {},
    };
  },
  watch: {
  },
  computed: {
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
  },
  created() {
    this.getMarketplace();
  this.category = this.$route.params.category;
  this.getProviderCredentials();
    this.getSpecialities();
    this.getGroupAndOrganizations();
  },
  methods: {
    removeChip(chip,selectedArray) {
      const filterArray = selectedArray;
      const chipIndex = filterArray.indexOf(chip);
      if (chipIndex !== -1) {
        filterArray.splice(chipIndex, 1);
      }
      this.searchResults();
      this.drawer = false;
    },
    getProviderCredentials() {
      api()
        .get("/market/credentials/provider")
        .then((res) => {
          this.providerCredentials = res.data;
        });
    },
    getSpecialities() {
      api()
        .get("/market/specialities")
        .then((res) => {
          this.specialities = res.data;
        });
    },
    getGroupAndOrganizations() {
      api()
        .get("/market/organization/company/name")
        .then((res) => {
          this.groupAndOrganizations = res.data;
        });
    },
    getCategoryItems(category) {
      const categoryData = this.marketplace.find(
        (item) => item.category === category
      );
      if (categoryData) {
        if (this.searchQuery) {
          const query = this.searchQuery.toLowerCase();
          return categoryData.items.filter((item) => {
            const nameMatch = item.name.toLowerCase().includes(query);
            const addressMatch = item.address.toLowerCase().includes(query);
            const typeMatch = item.type.toLowerCase().includes(query);
            return nameMatch || addressMatch || typeMatch;
          });
        } else {
          return categoryData.items;
        }
      } else {
        return [];
      }
    },
    getMarketplace() {
      api()
        .get("/vendors/market")
        .then((res) => {
          this.marketplace = res.data;
        });
    },
    goToDetails(item, entity) {
      if (entity == "solution") {
        this.$router.push(`/vendors/solutions/virtual-network/${item.id}`);
      } else if (entity == "location") {
        this.$router.push({
          path: `/vendors/solutions/clinics-organization/${item.id}`,
          query: { location: item.locationId },
        });
      } else if (entity == "vendor") {
        this.$router.push(`/vendors/solutions/point-solution-vendor/${item.id}`);
      } else if (entity == "provider") {
        this.$router.push(
          `/vendors/solutions/clinician/${item.id}`
        );
      }
      this.showOverlay = false;
    },
    searchResults() {
      this.searchLoader = true;
      this.data = {
        search: {
          types: this.selectedCategories,
          organizations: this.selectedGroupAndOrganizations,
          specialities: this.selectedSpecialities,
          credentials: this.selectedCredentials,
          gender: this.selectedGenders,
        },
      };
      api()
        .post("/vendors/market/search", this.data)
        .catch((err) => {
          if (err) {
            this.$refs.error_modal.openPopUp(err);
          }
        })
        .then((res) => {
          this.marketplace = res.data;
          this.searchLoader = false;
          if (res.data.length < 1) {
            this.noResultsFromSearch = true;
          }
          else{
            this.noResultsFromSearch = false;
          }
          this.drawer = false;
        });
    },
    searchByZip() {
      this.searchLoader = true;
      const data = {
        search: {
          zipCode: this.zipOrState,
        },
      };
      api()
        .post("/vendors/market/search", data)
        .catch((err) => {
          if (err) {
            this.$refs.error_modal.openPopUp(err);
          }
        })
        .then((res) => {
          this.marketplace = res.data;
          this.searchLoader = false;
          if (this.marketplace.length < 1) {
            this.noResultsFromSearch = true;
          }
        });
    },
    clearSearch() {
      this.loader = false;
      this.selectedCategories = [];
      this.selectedCredentials = [];
      this.selectedGenders = [];
      this.selectedGroupAndOrganizations = [];
      this.selectedSpecialities = [];
      this.getMarketplace();
    },
    changeTab(tab) {
      this.tab = tab;
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
    },
    confirmRemove() {},
   
  },
};
</script>
<style scoped>
.active {
  color: #333333 !important;
  background-color: transparent !important;
  text-decoration: underline !important;
}
.active:hover {
  color: #333333 !important;
}
.text-ellipsis {
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.subtitle-link{
  color:#366CB3 !important
}
.box-hover:hover {
  box-shadow: 0px 4px 8px 0px rgba(51, 51, 51, 0.16);
}

</style>

