<template>
  <div class="container-fluid background-gray">
    <Header
      @expantSideBarOnHover="expantSideBarOnHover"
      @hideSideBar="hideSideBar"
    />
    <v-row class="background-gray">
      <div
        class="pb-0 vh-height sidebar-wrapper"
        v-if="showSideBar && !display"
      >
        <SideBar
          :mini="miniClass"
          @closeSideBar="closeSideBar"
          :windowWidth="display"
          :showSideBar="showSideBar"
        ></SideBar>
      </div>
      <div
        :class="
          showSideBar
            ? 'pb-0  vh-height sidebar-wrapper'
            : 'pb-0 vh-height sidebar-wrapper-mini'
        "
        v-else-if="display"
      >
        <SideBar
          :mini="miniClass"
          @closeSideBar="closeSideBar"
          :windowWidth="display"
          :showSideBar="showSideBar"
        ></SideBar>
      </div>
      <div
        v-if="mobileSideBar"
        :class="!showSideBar ? 'main-wrapper' : 'main-wrapper-mini'"
      >
        <div class="context no-padding-left">
          <div class="row no-padding">
            <RoutePath :path="path"></RoutePath>
          </div>
          <BlueNavbar :tab="tab"></BlueNavbar>
          <div
            :class="hideWhiteBack ? 'home-tables' : 'white-background tables'"
          >
            <Home
              class="mt-5"
              v-if="
                route == '/employer-advisor' ||
                route == '/employer-advisor/home'
              "
            ></Home>
            <Employers></Employers>
            <Partners></Partners>
            <Networks></Networks>
            <Utilziation
              v-if="$route.path.includes('utilization')"
            ></Utilziation>
            <Settings></Settings>
            <WizardForm
              v-if="route == '/employer-advisor/wizard-form'"
            ></WizardForm>
            <Profile v-if="$route.path.includes('profile')" />
          </div>
        </div>
      </div>
    </v-row>
  </div>
</template>
<script>
import Header from "../components/Sections/EmployerAdvisor/Header.vue";
import SideBar from "../components/Sections/EmployerAdvisor/Layout/AdvisorSideBar.vue";
import RoutePath from "../components/TablesTemplate/RoutePath.vue";
import BlueNavbar from "../components/Sections/EmployerAdvisor/Layout/BlueNavbar.vue";
import Home from "../components/Sections/EmployerAdvisor/Home.vue";
import Employers from "../components/Sections/EmployerAdvisor/Employers/EmployerTemplate.vue";
import Partners from "../components/Sections/EmployerAdvisor/Partners/PartnerTemplate.vue";
import Networks from "../components/Sections/EmployerAdvisor/Networks/NetworksTemplate.vue";
import Settings from "../components/Sections/EmployerAdvisor/Settings/SettingsTemplate.vue";
import Utilziation from "../components/Sections/EmployerAdvisor/Utilization/Utilization.vue";
import WizardForm from "../components/Sections/EmployerAdvisor/WizardForm";
import Profile from "../components/TablesTemplate/MyProfile.vue";

export default {
  components: {
    WizardForm,
    Header,
    SideBar,
    Utilziation,
    RoutePath,
    BlueNavbar,
    Home,
    Employers,
    Partners,
    Networks,
    Settings,
    Profile,
  },
  data() {
    return {
      sideBar: true,
      showSideBar: false,
      display: true,
      windowWidth: window.innerWidth,
      page: "",
      clientName: "",
      miniClass: false,
      clientItem: null,
      path: "",
      tab: "",
    };
  },
  created() {
    if (this.windowWidth < 959) {
      this.display = false;
    } else {
      this.display = true;
    }
  },
  computed: {
    hideWhiteBack() {
      let hide = false;
      let routeParamsName =
        this.$route.params && this.$route.params.name
          ? this.$route.params.name.replace(/ /g, "%20")
          : "";
      let routeParamsSpaceName =
        this.$route.params && this.$route.params.name
          ? this.$route.params.name
          : "";

      if (
        this.$route.path == "/employer-advisor" ||
        this.$route.path == "/employer-advisor/home" ||
        this.$route.path == "/employer-advisor/employers/" + routeParamsName ||
        this.$route.path ==
          "/employer-advisor/employers/" + routeParamsSpaceName
      ) {
        hide = true;
      }
      return hide;
    },

    mobileSideBar() {
      let show;
      if (this.display) {
        show = true;
      } else if (!this.showSideBar && !this.display) {
        show = true;
      }
      return show;
    },
    route() {
      return this.$route.path;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    getProvidersByClient(item) {
      if (item) {
        this.clientItem = item;
        this.clientName = item.name;
      }
    },
    closeSideBar() {
      if (!this.display) {
        this.showSideBar = false;
      } else {
        this.showSideBar = true;
      }
    },
    getClientName(name) {
      this.clientName = name;
    },

    onResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 959) {
        this.display = false;
      } else {
        this.display = true;
      }
    },
    hideSideBar() {
      this.showSideBar = !this.showSideBar;
    },
    expantSideBarOnHover(mini) {
      this.showSideBar = !this.showSideBar;
      this.miniClass = !this.miniClass;
    },
  },
};
</script>
<style>
.sidebar-wrapper {
  height: 100vh;
  position: fixed;
  width: 80px;
  transition: width 0.3s;
}
.sidebar-wrapper-mini {
  height: 100vh;
  position: fixed;
  width: 200px;
  transition: width 0.3s;
}
.sidebar-wrapper-mobile {
  height: 100%;
  z-index: 999999999;
}
.main-wrapper {
  width: 92%;
  margin-left: 8%;
  transition: margin-left 0.3s;
  transition: width 0.3s;
}
.main-wrapper-mini {
  width: 95%;
  margin-left: 5%;
  transition: margin-left 0.3s;
}

@media only screen and (max-width: 2390px) {
  .main-wrapper {
    width: 92%;
    margin-left: 10%;
  }
}
@media only screen and (max-width: 2210px) {
  .main-wrapper {
    width: 92%;
    margin-left: 11%;
  }
}
@media only screen and (max-width: 1930px) {
  .main-wrapper {
    width: 88%;
  }
  .main-wrapper-mini {
    width: 93%;
    margin-left: 8%;
    transition: margin-left 0.1s;
  }
}
@media only screen and (max-width: 1870px) {
  .main-wrapper {
    width: 88%;
    margin-left: 12%;
  }
}
@media only screen and (max-width: 1869px) {
  .main-wrapper {
    width: 87%;
    margin-left: 13%;
  }
}
@media only screen and (max-width: 1728px) {
  .main-wrapper {
    width: 87%;
    margin-left: 13%;
  }
}
@media only screen and (max-width: 1689px) {
  .main-wrapper {
    width: 87%;
    margin-left: 13%;
  }
}
@media only screen and (max-width: 1662px) {
  .main-wrapper {
    width: 86%;
    margin-left: 14%;
  }
}

@media only screen and (max-width: 1590px) {
  .main-wrapper {
    width: 86%;
    margin-left: 14%;
  }
}
@media only screen and (max-width: 1500px) {
  .main-wrapper {
    width: 85%;
    margin-left: 15%;
  }
}
@media only screen and (max-width: 1395px) {
  .main-wrapper {
    width: 84%;
    margin-left: 16%;
  }
}
@media only screen and (max-width: 1330px) {
  .main-wrapper {
    width: 83%;
    margin-left: 16%;
  }
}
@media only screen and (max-width: 1285px) {
  .main-wrapper {
    width: 83%;
    margin-left: 17%;
  }
}
@media only screen and (max-width: 1230px) {
  .main-wrapper {
    width: 82.5%;
    margin-left: 17%;
  }
}
@media only screen and (max-width: 1195px) {
  .main-wrapper {
    width: 81.2%;
    margin-left: 19%;
  }
}
@media only screen and (max-width: 1095px) {
  .main-wrapper {
    width: 80.4%;
    margin-left: 19%;
  }
}
@media only screen and (max-width: 1084px) {
  .main-wrapper {
    width: 80.3%;
    margin-left: 20%;
  }
}
@media only screen and (max-width: 1076px) {
  .main-wrapper {
    width: 80.1%;
    margin-left: 20%;
  }
  @media only screen and (max-width: 1054px) {
    .main-wrapper {
      width: 80%;
      margin-left: 20%;
    }
  }
  @media only screen and (max-width: 1038px) {
    .main-wrapper {
      width: 79%;
      margin-left: 23%;
    }
  }
  @media only screen and (max-width: 995px) {
    .main-wrapper {
      width: 78%;
      margin-left: 22%;
    }
  }
  @media only screen and (max-width: 959px) {
    .main-wrapper {
      width: 98%;
      margin-left: 25px;
    }
    .sidebar-wrapper {
      width: 100%;
      height: 100vh;
      background: white;
    }
  }
}
@media only screen and (max-width: 959px) {
  .main-wrapper {
    width: 98%;
    margin-left: 25px;
  }
}
@media only screen and (max-width: 950px) {
  .main-wrapper {
    width: 98%;
    margin-left: 10px;
  }
}
</style>