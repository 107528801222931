<template>
    <div>
       <PriorTable v-if="route == `/provider-advisor/providers/${routeParams}/prior-auth`"></PriorTable>
       <PriorRequest v-if="route == `/provider-advisor/providers/${routeParams}/prior-auth/new-request`"></PriorRequest>
       <PriorDetermination v-if="route == `/provider-advisor/providers/${routeParams}/prior-auth/determination`"></PriorDetermination>
       <Details v-if="route == `/provider-advisor/providers/${routeParams}/prior-auth/details`"></Details>
       <PriorAuth v-if="route == `/provider-advisor/providers/${routeParams}/prior-auth/auth`"></PriorAuth>
    </div>
</template>
<script>
import PriorTable from './PriorTable.vue'
import PriorRequest from './PriorRequest.vue'
import PriorDetermination from './PriorDetermination.vue'
import Details from './Details.vue'
import PriorAuth from './PriorAuthorization.vue'
export default{
    components:{
        PriorTable,
        PriorRequest,
        PriorDetermination,
        Details,
        PriorAuth
    },
    computed:{
       route(){
         return this.$route.path.replace(/%20/g,' ')
      },
      routeParams(){
          return this.$route.params.name
      }
    },
    data(){
        return{

        }
    },
    
}
</script>