<template>
<div>
    <div v-if="this.$route.path.includes('/member/providers/provider')" class="blue-toolbar row no-padding" style="margin-top:0px">
      <div  cols="2" :class="activeTab == 'people' ? ' col-lg-1 col-md-6 col-sm-6 font  white--text text-center orange-underline sm-buttons-member-width ml-2' : ' col-lg-1 col-md-6 col-sm-6 font  white--text text-center sm-buttons-member-width ml-2'"  align="center">
                <a class="white--text" @click="changeActiveTab('people')">
                    People 
                </a> 
         </div> 
          <div  cols="2" class="col-md-2" :class="activeTab == 'places' ? ' col-lg-1 col-md-6 col-sm-6 font  white--text text-center orange-underline sm-buttons-member-width' : ' col-lg-1 col-md-6 col-sm-6 font  white--text text-center sm-buttons-member-width'"  align="center">
                <a class="white--text" @click="changeActiveTab('places')">
                   Places
                </a> 
         </div> 
         <div  cols="2" :class="activeTab == 'procedures' ? ' col-lg-1 col-md-6 col-sm-6 font  white--text text-center orange-underline sm-buttons-member-width' : ' col-lg-1 col-md-6 col-sm-6 font  white--text text-center sm-buttons-member-width'"  align="center">
                <a class="white--text" @click="changeActiveTab('procedures')">
                    Procedures
                </a> 
         </div>
    </div>

    <div v-else-if="this.$route.path.includes('/member/providers') || this.$route.path.includes('/member/services')" class="blue-toolbar row no-padding" style="margin-top:0px">
        <div  cols="2" :class="tab == 'providers' ? ' col-lg-1 col-md-6 col-sm-6 font  white--text text-center orange-underline sm-buttons-member-width ml-2' : ' col-lg-1 col-md-6 col-sm-6 font  white--text text-center sm-buttons-member-width ml-2'"  align="center">
            <a class="white--text" @click="changeActiveTab('providers')">
                Providers
            </a>
        </div>
        <div  cols="2" class="col-md-2" :class="tab == 'services' ? ' col-lg-1 col-md-6 col-sm-6 font  white--text text-center orange-underline sm-buttons-member-width' : ' col-lg-1 col-md-6 col-sm-6 font  white--text text-center sm-buttons-member-width'"  align="center">
            <a class="white--text" @click="changeActiveTab('services')">
                Services
            </a>
        </div>

    </div>
   <div v-else-if="this.$route.path  == '/member' || this.$route.path  == '/member/home'" class="blue-toolbar row no-padding" style="margin-top:0px">
            <div    class="col-lg-3 col-md-6 col-sm-6 font  white--text text-left "  >
                <a class="white--text ml-4">
                   Welcome {{member.firstName}} {{member.lastName}}
                </a> 
         </div> 
    </div>
    <div v-else class="blue-toolbar  row " style="margin-top:0px; padding:1.05rem; margin-left:0px">
        {{routeParams}}
    </div>
</div>
</template>
<script>
import api from '../../../../services/api'
export default{
    data(){
        return {
            activeTab:'',
            activeTab1:'',
            member:''
        }
    },
    props:{
     clientInfo:{
        type:Boolean
      }
    },


    methods:{
        getMemberDetails(){
            let user = JSON.parse(localStorage.getItem('user'));
            let member = user.member
            
            api().get(`/members/${member}`).then((res)=>{
                this.member = res.data.member
               
            })
        },
       changeActiveTab(tab){
           if(tab == 'providers'){
               if(this.path != '/member/providers')
                   this.$router.push('/member/providers')
           }
           if(tab == 'services'){
               if(this.path != '/member/services') {
                   this.$router.push('/member/services')
               }
           }
          this.activeTab = tab
          this.activeTab1 = tab

      },
      onCreatedFunction(){

         if(this.path.includes('/member/providers')){
              this.activeTab = 'providers'
          }
          if(this.path.includes('/member/services')){
              this.activeTab = 'services'
          }
          

      }
    },
    computed:{
         routeParams(){  
            return this.$route.params.name
         },
         path(){
             return this.$route.path.replace(/%20/g, ' ')
         },
         tab(){
             if(this.path.includes('/member/providers')){
              this.activeTab = 'providers'
             }
            if(this.path.includes('/member/services')){
              this.activeTab = 'services'
            }
             return this.activeTab
         }
    },
    created(){
        this.onCreatedFunction();
        this.getMemberDetails()
    },
   
}
</script>
<style>
.no-margin{
  margin:0px !important;
}
@media screen and (max-width: 992px) {
  .sm-buttons-member-width {
    width: 30%;
  }
}
</style>