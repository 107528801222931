<template>
    <div class="add-client-form">
        <div>
            <h3 class="font">Prior Authorization Request</h3>
        </div>
       <v-row>
           <v-col md='12'>
              <h5 class="text-gray font underline-text">Member Information</h5>
           </v-col>
           <v-col md="10">
                <v-simple-table dense>
                   <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-left text-gray font">
                                First Name
                            </th>
                            <th class="text-left text-gray font">
                            Last Name
                            </th>
                            <th class="text-left text-gray font">
                                Sex
                            </th>
                            <th class="text-left text-gray font">
                                Status on Plan
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="font">
                                    <a >Valarie</a>
                                </td>
                                <td class="font">
                                    <a >Vaughn</a>
                                </td>
                                <td>
                                    Female
                                </td>
                                <td class="font">
                                    Active
                                </td>

                            
                            </tr>
                        </tbody>
                     </template>
                </v-simple-table>

           </v-col>
            <v-col md='12'>
              <h5 class="text-gray font underline-text">Provider Information</h5>
           </v-col>
            <v-col md="10">
                <v-simple-table dense>
                   <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left text-gray font">
                                Organization Name
                            </th>
                            <th class="text-left text-gray font">
                                Type
                            </th>
                            <th class="text-left text-gray font">
                                Quality Rating
                            </th>
                            <th class="text-left text-gray font">
                                City
                            </th>
                            <th class="text-left text-gray font">
                                Status
                            </th>
                        
                        </tr>
                     </thead>
                     <tbody>
                            <tr>
                                <td class="font">
                                    <a >Manatee Memorial Hospital</a>
                                </td>
                                <td class="font">
                                    Hospital
                                </td>
                                <td>
                                    <v-rating
                                        v-model.trim="rating"
                                        color="yellow darken-3"
                                        background-color="grey darken-1"
                                        empty-icon="$ratingFull"
                                        half-increments
                                    
                                    ></v-rating>
                                </td>
                                 <td class="font">
                                    Tampa
                                </td>
                                <td class="font green-text">
                                    Matched
                                </td>
                            </tr>
                        </tbody>
                     </template>
                 </v-simple-table>
           </v-col>
       </v-row>
        <v-row class="mt-4">
            <div class="col-md-12">
                <h4 class="font text-gray">Procedure Information</h4>
            </div>
        </v-row>
        <v-row>
            <div class="col-md-2">
                <label class="font text-gray" for="">Procedure Code Type <i class="fas fa-question-circle  yellow-icons"></i></label>
                <v-select solo></v-select>
            </div>
            <div class="col-md-2">
                <label class="font text-gray" for="">Procedure Code  <i class="fas fa-question-circle  yellow-icons"></i></label>
                <v-select solo></v-select>
            </div>
            <div class="col-md-5">
                <label class="font text-gray" for="">Procedure Description  <i class="fas fa-question-circle  yellow-icons"></i></label>
                <v-select solo></v-select>
            </div>
            <div class="col-md-3">
                <label class="font text-gray" for="">East Procedure Cost (USD) <i class="fas fa-question-circle  yellow-icons"></i></label>
                <v-text-field solo></v-text-field>
            </div>
        </v-row>
        <v-row justify="end">
            
            <v-btn class="blue-buttons mr-4" @click="$router.push(`/provider/prior-auth`)">Cancel</v-btn>
            <v-btn class="blue-buttons" @click="dialog = true">Submit</v-btn>
        </v-row>
        <div class="text-center">
            <v-dialog
            v-model.trim="dialog"
            width="700"
            class="success-modal"
            >
            <v-card>
             <v-card-text class="success-modal" >
                <div>
                    <h1 class="text-underline font">Prior Authorization Request</h1>
                </div>
                <div class="font mt-5 mb-5">
                   <h2>
                       A prior authorization is not required for this procedure.
                    </h2> 
                </div>
                 <div class="text-center">
                        <svg width="210" height="210" viewBox="0 0 210 210" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M45 119.235L69.5 159.597C70.4293 161.209 71.6768 162.539 73.1385 163.478C74.6002 164.417 76.2339 164.937 77.9037 164.995C79.5735 165.052 81.231 164.647 82.7384 163.811C84.2458 162.975 85.5595 161.733 86.57 160.189L165 45" stroke="#0E8F32" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                            <circle cx="105" cy="105" r="102.5" stroke="#0E8F32" stroke-width="5"/>
                        </svg>
                </div>
                <h2  class=" font  mt-2 mb-5">
                        We will save this response on your Apaly Healt portal, un the Member's account, and you can also print a copy if you wish.
                </h2>
                <div class="row justify-space-between mt-2">
                    <v-btn class="blue-buttons" @click="dialog=false">Close</v-btn>
                    <v-btn class="blue-buttons" @click="dialog=false,dialog1=true">Print</v-btn>
                </div>
                </v-card-text>
            </v-card>
           </v-dialog>
             <v-dialog
            v-model.trim="dialog1"
            width="700"
            class="success-modal"
            >
            <v-card>
             <v-card-text class="success-modal" >
                <div>
                    <h1 class="text-underline font">Prior Authorization Request</h1>
                </div>
                <div class="font mt-5 mb-5">
                   <h2>
                       A prior authorization is required for this procedure.
                    </h2> 
                </div>
                 <div class="row text-center justify-center set-padding">
                       <v-btn class="blue-buttons" @click="$router.push(`/provider/prior-auth/new-request`)">Complete & Submit Prior Authorization Request</v-btn>
                </div>
                <h2  class=" font  mt-2 mb-5">
                      Click the button above to complete the prior authorization request for this procedure.
                </h2>
                <div class="row justify-space-between mt-2">
                    <v-btn class="blue-buttons" @click="dialog=false,dialog1=false">Close</v-btn>
                </div>
                </v-card-text>
            </v-card>
           </v-dialog>
        </div>
       
    </div>
</template>
<script>
export default{
    data(){
        return{
            dialog:false,
            dialog1:false,
        }
    },
    computed:{
       routeParams(){
          let parsed = this.$route.params.name.replace(/ /g, '%20')
          return parsed
      }
    }
}
</script>
<style>
.set-padding{
    padding-top: 100px !important;
    padding-bottom: 100px !important;

}
</style>