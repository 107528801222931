

const state ={
    singleContractInEmployerAdvisor:null
}
const getters = {
    getSingleContractInEmployerAdvisor(state){
        return state.singleContractInEmployerAdvisor
    }
}
const mutations = {
    setSingleContractinEmployerAdvisor(state,contract){
        state.singleContractInEmployerAdvisor = contract
    }
}

export default{
    state,
    getters,
    mutations,
}