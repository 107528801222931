<template>
    <v-row class="padding">
        <div class="row col-md-12">
            <h3 class="font">
                <span>Upload Census</span>
            </h3>
        </div>
        <v-col md="6" cols="12" class="justify-center align-center ">
            <div class="row text-center justify-center upload-box" >
                <div class="col-md-12 upload-provider-box">
                    <img src="/filee.png" class="apaly-pic" alt="">
                    <h3 class="text-gray font">Drag & Drop</h3>
                    <h5 class="text-gray font">Your files here or <a >Browse</a> to upload</h5>
                    <h5 class="text-gray font"><a >Excel or CSV files Only</a></h5>
                </div>
                <div class=" col-md-12 no-padding mt-2 mb-4 ">
                    <div class="downoald-file-btn-box ">
                        <h3>
                            <a  class="underline-text">Download File Template</a>
                        </h3>
                    </div>
                </div>

            </div>
             <div class="row text-end justify-end">
                <v-btn class="blue-buttons">Upload</v-btn>
            </div>
           
        </v-col>
        <v-col cols="12" md="6 ">
        <div>
            <div class="row">
            <div class="col-md-6 col-12">
                <div style="text-align: center;margin-bottom: 0.5rem">
                    <label class="font text-gray" >Match the Fields on Platform</label><br>
                    <span style="color:red;">(* - required field)</span>
                </div>
            </div>
            <div class="col-md-6 col-12">
            <div style="text-align: center;margin-bottom: 2rem">
                <label class="font text-gray">With Fields in Your File</label>
            </div>
            </div>
            </div>
                <form class="row" style="overflow-y:scroll;max-height: 55vh;padding-left: 3rem">
                    <div class="col-md-6 col-12">
                    <v-text-field required solo placeholder="Zip Code *" class="zip-code"></v-text-field>
                    <v-text-field solo placeholder="First Name"></v-text-field>
                    <v-text-field solo placeholder="Last Name"></v-text-field>
                    <v-text-field solo placeholder="Email"></v-text-field>
                    <v-text-field solo placeholder="Sex"></v-text-field>
                    <v-text-field solo placeholder="DOB"></v-text-field>
                    <v-text-field solo placeholder="Social Security Number"></v-text-field>
                    <v-text-field solo placeholder="Address 1"></v-text-field>
                    <v-text-field solo placeholder="Address 2"></v-text-field>
                    <v-text-field solo placeholder="City"></v-text-field>
                </div>
                <div class="col-md-6 col-12">
                    <v-select solo placeholder="Zip Code"></v-select>
                    <v-select class="select" solo placeholder="Select"></v-select>
                    <v-select  solo placeholder="Last Name"></v-select>
                    <v-select  solo placeholder="Email"></v-select>
                    <v-select  solo placeholder="Sex"></v-select>
                    <v-select  solo placeholder="Date of Birth"></v-select>
                    <v-select  solo placeholder="SSN"></v-select>
                    <v-select  solo placeholder="Address 1"></v-select>
                    <v-select  solo placeholder="Address 2"></v-select>
                    <v-select class="select" solo placeholder="Select"></v-select>
                </div>
            </form>
            
        </div>
        
    </v-col>
    <div  class="row text-end justify-end">
        <div class="col-md-12">
           <v-btn  class="blue-buttons mr-2">Cancel</v-btn>
          <v-btn class="blue-buttons">Submit</v-btn>
        </div>
                
     </div>

    </v-row>
</template>

<script>
    export default {
        name:'EmployerUpload'
    }
</script>
<style>
    .zip-code ::placeholder{
        color:red !important;
    }
    .select ::placeholder{
        color:red !important;
    }
    .padding{
        padding:2rem ;
    }
    .border-right{
        border-right: 2px solid rgb(226, 221, 221);
    }
    .upload-box{
        padding-top: 3rem;
        align-items: center;
    }
    .upload-provider-box{
        border: 5px solid rgb(226, 221, 221);
        border-style: dotted;
        border-radius: 10px;
        padding: 4rem;
    }
    .downoald-file-btn-box{
        border: 5px solid gainsboro;
        padding: 1rem;
        border-radius: 10px;
    }
    .btn-box{
        padding: 1.5rem;
    }
    @media only screen and (max-width: 600px) {
        .padding {
            padding: 10px ;
        }
        .upload-box{
            padding: 0px;
        }
    }
</style>