<template>
  <v-row class="add-client-form no-padding">
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>

    <div class="row orange-underline-wizard mt-1" style="margin: -11px">
      <div class="col-md-12">
        <h3 class="form-title font ml-2 mb-2">Client Detail</h3>
      </div>
    </div>
    <div class="col-md-12">
      <v-form v-model.trim="valid" ref="form" class="row">
        <div class="col-md-6">
          <div class="col-md-12 pt-2 pb-0">
            <label class="font" for="">Company Name</label>
            <v-text-field
              required
              :rules="requiredRules"
              v-model.trim="client.companyName"
              solo
            ></v-text-field>
          </div>

          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">Tax ID</label>
            <v-text-field
              required
              v-model.trim="client.taxId"
              @keyup="showSlash($event)"
              solo
              placeholder="00-0000000"
              :rules="taxIdRules"
              counter="10"
              maxlength="10"
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">Number of Employees</label>
            <v-text-field
              required
              v-model.trim="client.employeesNo"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">Number of Plan Members</label>
            <v-text-field
              required
              v-model.trim="client.membersNo"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">HQ Address Line 1</label>
            <v-text-field
              required
              v-model.trim="client.address1"
              :rules="requiredRules"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">HQ Address Line 2</label>
            <v-text-field
              required
              v-model.trim="client.address2"
              solo
            ></v-text-field>
          </div>
          <v-row class="no-padding">
            <div class="col-md-6 pt-0 pb-0">
              <label class="font text-gray" for="">City</label>
              <v-text-field
                required
                v-model.trim="client.city"
                solo
                :rules="requiredRules"
              ></v-text-field>
            </div>
            <div class="col-md-3 pt-0 pb-0">
              <label class="font text-gray" for="">State</label>
              <v-select
                :items="states"
                item-text="name"
                item-value="name"
                required
                v-model.trim="client.state"
                solo
                :rules="requiredRules"
              ></v-select>
            </div>
            <div class="col-md-3 pt-0 pb-0">
              <label class="font text-gray" for="">Zip</label>
              <v-text-field
                required
                type="number"
                v-model.trim="client.zip"
                solo
                :rules="zipRules"
              ></v-text-field>
            </div>

            <div class="row col-md-12">
              <div class="col-md-8 font text-gray">
                <label for="">Client Relationship Status</label>
                <div class="d-flex mt-2">
                  InActive
                  <v-switch
                    v-model.trim="client.status"
                    solo
                    flat
                    class="no-padding ml-2 mr-2 vendors-switch"
                  ></v-switch>
                  Active
                </div>
              </div>
              <div class="col-md-4 mt-5 text-end">
                <a class="text-underline" @click="removeClient()"
                  >Remove Client</a
                >
              </div>
            </div>
          </v-row>
        </div>
        <div class="col-md-6">
          <div class="col-md-12 pt-1 pb-0">
            <label class="font text-gray" for=""
              >Primary Contact First Name
            </label>
            <v-text-field
              required
              v-model.trim="client.firstName"
              solo
              :rules="requiredRules"
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for=""
              >Primary Contact Last Name</label
            >
            <v-text-field
              required
              v-model.trim="client.lastName"
              solo
              :rules="requiredRules"
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">Primary Contact Title</label>
            <v-text-field
              required
              v-model.trim="client.title"
              solo
              :rules="requiredRules"
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">Primary Contact Email</label>
            <v-text-field
              required
              :rules="emailRules"
              v-model.trim="client.email"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">Primary Contact Phone</label>
            <v-text-field
              required
              v-model.trim="client.phone"
              solo
              :rules="phoneRules"
            ></v-text-field>
          </div>
          <div class="col-md-12">
            <a class="text-underline">View & Client Attestations</a>
          </div>
        </div>
      </v-form>
    </div>
    <removeDialog
      ref="dialog_delete"
      v-on:confirm="confirmRemove"
    ></removeDialog>
    <div class="col-md-12 no-padding">
      <div class="gray-underline-wizard row margin-l-11 mb-3">
        <div class="text-end justify-end col-md-12 pt-5 pb-5 mt-3">
          <span
            class="mr-10 text-underline text-gray pointer"
            @click="$router.go(-1)"
            >Cancel</span
          >
          <span
            class="mr-3 text-underline text-gray pointer pr-5"
            @click="update()"
            >Save
          </span>
        </div>
      </div>
    </div>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
import api from "../../../../services/api";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import RemoveDialog from "../../../TablesTemplate/RemoveDialog.vue";

export default {
  components: { successModal, RemoveDialog },
  data() {
    return {
      client: {
        companyName: "",
        taxId: "",
        logo: "",
        phone: "",
        title: "",
        firstName: "",
        lastName: "",
        email: "",
        address1: "",
        address2: "",
        city: "",
        state: { name: "Any", value: "any" },
        zip: "",
        displayName: "",
        membersNo: "",
        employeesNo: "",
        status: "",
      },

      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
      states: [],
      discountRate: "",
      valid: false,
      zipRules: [
        (v) => (v && v.length == 5) || " Zip Code should be five digits long",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
      emailRules: [
        (v) => !!v || "This field is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      taxIdRules: [
        (v) => !!v || "This field is required",
        (v) =>
          !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
          "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
      ],
    };
  },
  computed: {
    singleMember() {
      return this.$store.getters.getSingleMember;
    },

    ...mapGetters(["getSingleItemDPC"]),
  },
  created() {
    this.getInfo();
    this.getStates();
  },
  methods: {
    confirmRemove() {
      api()
        .delete(`/vendors/clients/${this.getSingleItemDPC._id}`)
        .then((res) => {
          if (res) {
            this.$parent.getData();
            this.$router.push("/vendors/clients");
          }
        });
    },
    generatePdf() {
      window.open("https://api.apaly.net/terms");
    },
    showSlash(event) {
      if (this.client.taxId.length == 2 && event.keyCode != 8) {
        this.client.taxId += "-";
      }
    },
    activateFileInput() {
      this.$refs.fileInput.$refs.input.click();
    },
    getStates() {
      api()
        .get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },
    removeClient() {
      this.$refs.dialog_delete.openPopUp(
        "Are you sure that you want to delete this Client"
      );
    },
    getInfo() {
      // api()
      //   .get(`/vendors/clients/${clientID}`)
      //   .then((res) => {
      //     if (res) {

      //     }
      //   });
      if (this.getSingleItemDPC) {
        this.client = {
          companyName: this.getSingleItemDPC.companyName
            ? this.getSingleItemDPC.companyName
            : "",
          taxId: this.getSingleItemDPC.taxId ? this.getSingleItemDPC.taxId : "",
          phone:
            this.getSingleItemDPC.primaryContact &&
            this.getSingleItemDPC.primaryContact.phone
              ? this.getSingleItemDPC.primaryContact.phone
              : "",
          title:
            this.getSingleItemDPC.primaryContact &&
            this.getSingleItemDPC.primaryContact.title
              ? this.getSingleItemDPC.primaryContact.title
              : "",
          firstName:
            this.getSingleItemDPC.primaryContact &&
            this.getSingleItemDPC.primaryContact.firstName
              ? this.getSingleItemDPC.primaryContact.firstName
              : "",
          lastName:
            this.getSingleItemDPC.primaryContact &&
            this.getSingleItemDPC.primaryContact.lastName
              ? this.getSingleItemDPC.primaryContact.lastName
              : "",
          email:
            this.getSingleItemDPC.primaryContact &&
            this.getSingleItemDPC.primaryContact.email
              ? this.getSingleItemDPC.primaryContact.email
              : "",
          address1: this.getSingleItemDPC.address1
            ? this.getSingleItemDPC.address1
            : "",
          address2: this.getSingleItemDPC.address2
            ? this.getSingleItemDPC.address2
            : "",
          city: this.getSingleItemDPC.city ? this.getSingleItemDPC.city : "",
          state: this.getSingleItemDPC.state ? this.getSingleItemDPC.state : "",
          zip: this.getSingleItemDPC.zipCode
            ? this.getSingleItemDPC.zipCode
            : "",
          displayName: this.getSingleItemDPC.displayName
            ? this.getSingleItemDPC.displayName
            : "",
          membersNo: this.getSingleItemDPC.noPlanMembers
            ? this.getSingleItemDPC.noPlanMembers
            : "",
          employeesNo: this.getSingleItemDPC.noEmployees
            ? this.getSingleItemDPC.noEmployees
            : "",
          status: this.getSingleItemDPC.status
            ? this.getSingleItemDPC.status
            : "",
        };
      }
    },
    closeSuccessModal() {
      this.$parent.getData();
      this.$refs.success_modal.closePopUp();
    },

    update() {
      this.$refs.form.validate();
      if (this.valid) {
        let data = {
          companyName: this.client.companyName,
          taxId: this.client.taxId,
          noEmployees: this.client.noEmployees,
          noPlanMembers: this.client.noPlanMembers,
          address1: this.client.address1,
          address2: this.client.address2,
          city: this.client.city,
          state: this.client.state,
          zipCode: this.client.zip,
          firstName: this.client.firstName,
          lastName: this.client.lastName,
          title: this.client.title,
          email: this.client.email,
          phone: this.client.phone,
          noEmployees: this.client.employeesNo,
          noPlanMembers: this.client.membersNo,
          tpas: this.client.tpas,
        };
   

        api()
          .put(`/vendors/clients/` + this.getSingleItemDPC._id, data)
          .then((res) => {
            if (res) {
              this.$refs.success_modal.openPopUp("Client updated successfully");
            }
          });
      }
    },
  },
};
</script>