import Members from '../views/Member.vue'
import Providers from '../components/Sections/Members/Providers/ProvidersTable.vue'
import InviteProviders from '../components/Sections/Members/Providers/InviteProvider.vue'
import AddProvider from '../components/Sections/Members/Providers/AddProviders.vue'
import Provider from '../components/Sections/Members/Providers/ProviderCrudForm.vue'
import ProvidersResult from '../components/Sections/Members/Providers/ResultTable.vue'
import Review from '../components/Sections/Members/Providers/Review.vue'

// Services
import AddService from "../components/Sections/Members/Services/AddService.vue";
import MemberServices from "../components/Sections/Members/Services/MemberServices.vue";
import ServiceDetail from "../components/Sections/Members/Services/ServiceDetail";

// Visit Passes
import VisitPasses from "../components/Sections/Members/VisitPasses/VisitPasses.vue";

//Prior Auth
import PriorAuth from "../components/Sections/Members/PriorAuts/PriorAuths.vue";

//Profile
import Profile from "../components/Sections/Members/MemberProfile/Profile";

function routeGuard(to, from, next) {
    let user = JSON.parse(localStorage.getItem('user'));

    if (user.type != 'member' || !$cookies.get('token')){
      next('/login');
    }else{
        next();
    }
      
}
const router = [
    
    {    path:'/member/home',
         name:'Member',
         component:Members,
         beforeEnter: routeGuard,

    },
    {
         path:'/member',
         name:'Member',
         component:Members,
         beforeEnter: routeGuard,
         children:[
             {
               path: 'profile',
               name: 'Profile',
               component: Profile
             },
             {
                path: 'care',
                name: 'Care',
                component: Profile
              },
             {
                 path: 'prior-auths',
                 name: 'Prior Auths',
                 component: PriorAuth
             },
             {
               path: 'visit-passes',
               name: 'Visit Passes',
               component: VisitPasses
             },
            {
                path:'providers',
                name:"Providers",
                component:Providers,
                children:[
                    {
                        path:'invite-provider',
                        name:"Invite Providers",
                        component:InviteProviders
                    },
                    {
                        path:'provider',
                        name:"Provider",
                        component:Provider,
                        children:[
                            {
                                path:'review',
                                name:"Provider Review",
                                component:Review

                            }
                        ]
                    },
                    {
                        path:'add-provider',
                        name:"Add New Provider",
                        component:AddProvider
                    },
                    {
                        path:'result',
                        name:"Search Result",
                        component:ProvidersResult

                    }
                ]
            },
             {
                 path:'services',
                 name:"Services",
                 component:MemberServices,
                 children: [
                     {
                         path:'add-services',
                         name:"Add New Service",
                         component:AddService
                     },
                     {
                         path:'service',
                         name:"Service",
                         component: ServiceDetail
                     }
                 ]
             }

         ]

    }
];

export default router