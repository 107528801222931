<template>
  <v-row class="add-client-form no-padding account">
    <successModal ref="success_modal" v-on:confirm="closeSuccessModal"></successModal>
    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"> </errorModal>
    <div class="col-12 col-md-12 pl-0 pr-0">
      <v-form v-model.trim="valid" ref="form" class="row">
        <div class="col-12 col-md-12 pb-0 pr-0 pl-0">
          <div class="col-12 col-md-12 d-md-flex pl-0 pr-0">
            <div class="col-12 col-md-3">
              <h3>Company Details</h3>
              <p>Enter some basic company information</p>
            </div>
            <div class="col-12 col-md-12">
              <div class="col-12 col-md-12 d-md-flex pt-0 pb-0">
                <div class="col-12 col-md-3 pt-0 pb-0">
                  <label class="font text-gray" for="">NPI</label>
                  <v-text-field
                    v-model.trim="account.NPI"
                    :rules="npiRules"
                    counter="10"
                    maxlength="10"
                    solo
                  ></v-text-field>
                </div>
                <div class="col-12 col-md-6 pt-0 pb-0">
                  <label class="font text-gray" for="">Company Name</label>
                  <v-text-field
                    required
                    :rules="requiredRules"
                    v-model.trim="account.companyName"
                    solo
                  ></v-text-field>
                </div>
              </div>
              <div class="col-12 col-md-12 d-md-flex pt-0 pb-0">
                <div class="col-md-6 pt-0 pb-0">
                  <label class="" for="">Website</label>
                  <v-text-field
                    :rules="websiteRules"
                    v-model.trim="account.website"
                    solo
                  ></v-text-field>
                </div>

                <div class="col-12 col-md-3 pt-0 pb-0">
                  <label class="font text-gray" for="">Tax ID</label>
                  <v-text-field
                    required
                    v-model.trim="account.taxId"
                    @keydown="showSlash($event)"
                    solo
                    placeholder="00-0000000"
                    :rules="taxIdRules"
                    counter="10"
                    maxlength="10"
                  ></v-text-field>
                </div>
              </div>
              <div class="col-12 col-md-12 d-md-flex pt-0 pb-0">
                <div class="col-12 col-md-6 pt-0 pb-0">
                  <label class="" for=""
                    >Description - This will be your description in the marketplace</label
                  >
                  <v-textarea
                    required
                    rows="3"
                    solo
                    v-model.trim="account.description"
                  ></v-textarea>
                </div>
                <div class="col-12 col-md-3 pt-0 pb-0">
                  <label class="font text-gray" for="">Category</label>
                  <v-text-field
                  required
                  disabled
                  v-model.trim="account.category"
                  solo
                ></v-text-field>
                  <!-- <v-select
                    :items="categories"
                    item-text="name"
                    item-value="_id"
                    v-model.trim="account.category"
                    solo
                  >
                  </v-select> -->
                </div>
              </div>
              <label class="ml-5" for="">Logo - Upload your logo (This will be displayed on your public profile in the member app)</label>
              <div class="col-12 col-md-12 d-md-flex pt-0 pb-0">
                <div>
                  <img
                    v-if="account.logo"
                    id="logo-img"
                    :src="account.logo"
                    style="width: 100px"
                  />
                </div>
                <div class="col-md-4 pt-2 pb-0">
                  <v-file-input
                    id="logo-img"
                    @change="updateLogo()"
                    v-model.trim="account.image"
                    required
                    solo
                    truncate-length="38"
                    ref="fileInput"
                  ></v-file-input>
                </div>
                <div class="col-md-1 pt-5 pb-0">
                  <a @click="activateFileInput()" class="text-underline">Browse</a>
                </div>
              </div>
            </div>
          </div>
          <v-divider horizontal class="mb-5"></v-divider>
          <div class="col-md-12 d-md-flex pr-0 pl-0">
            <div class="col-md-3">
              <h3>Address Details</h3>
              <p>Enter the address of your headquarters</p>
            </div>
            <div class="col-md-9">
              <div class="col-md-12 pt-0 pb-0">
                <label class="font text-gray" for="">HQ Address Line 1</label>
                <v-text-field
                  required
                  v-model.trim="account.address1"
                  :rules="requiredRules"
                  solo
                ></v-text-field>
              </div>
              <div class="col-md-12 pt-0 pb-0">
                <label class="font text-gray" for="">HQ Address Line 2</label>
                <v-text-field
                  required
                  v-model.trim="account.address2"
                  solo
                ></v-text-field>
              </div>
              <v-row class="no-padding">
                <div class="col-md-6 pt-0 pb-0">
                  <label class="font text-gray" for="">City</label>
                  <v-text-field
                    required
                    v-model.trim="account.city"
                    solo
                    :rules="requiredRules"
                  ></v-text-field>
                </div>
                <div class="col-md-3 pt-0 pb-0">
                  <label class="font text-gray" for="">State</label>
                  <v-select
                    :items="states"
                    item-text="name"
                    item-value="name"
                    required
                    v-model.trim="account.state"
                    solo
                    :rules="requiredRules"
                  ></v-select>
                </div>
                <div class="col-md-3 pt-0 pb-0">
                  <label class="font text-gray" for="">Zip</label>
                  <v-text-field
                    required
                    type="number"
                    v-model.trim="account.zipCode"
                    solo
                    :rules="zipRules"
                  ></v-text-field>
                </div>
              </v-row>
            </div>
          </div>
          <v-divider horizontal class="mb-5"></v-divider>
          <div class="col-md-12 d-md-flex pl-0 pr-0">
            <div class="col-md-3">
              <h3>Contact Details</h3>
              <p>Enter details of your primary contact person.</p>
            </div>
            <div class="col-md-9">
              <div class="col-md-12 d-md-flex pt-0 pb-0">
                <div class="col-md-6 pt-0 pb-0">
                  <label class="font text-gray pb-0" for="">First Name </label>
                  <v-text-field
                    required
                    v-model.trim="account.primaryContact.firstName"
                    solo
                    :rules="requiredRules"
                  ></v-text-field>
                </div>
                <div class="col-md-6 pt-0 pb-0">
                  <label class="font text-gray" for="">Primary Contact Last Name</label>
                  <v-text-field
                    required
                    v-model.trim="account.primaryContact.lastName"
                    solo
                    :rules="requiredRules"
                  ></v-text-field>
                </div>
              </div>
              <div class="col-md-12 d-md-flex pt-0 pb-0">
                <div class="col-md-6 pt-0 pb-0">
                  <label class="font text-gray" for="">Title</label>
                  <v-text-field
                    required
                    v-model.trim="account.primaryContact.title"
                    solo
                    :rules="requiredRules"
                  ></v-text-field>
                </div>
                <div class="col-md-6 pt-0 pb-0">
                  <label class="font text-gray" for=""> Email</label>
                  <v-text-field
                    required
                    :rules="emailRules"
                    v-model.trim="account.primaryContact.email"
                    solo
                  ></v-text-field>
                </div>
              </div>
              <div class="col-md-12 d-md-flex pt-0 pb-0">
                <div class="col-md-6 pt-0 pb-0">
                  <label class="font text-gray" for="">Phone</label>
                  <v-text-field
                    required
                    v-model.trim="account.primaryContact.phone"
                    solo
                    :rules="phoneRules"
                  ></v-text-field>
                </div>
                <!-- <div class="col-md-6 pt-0 pb-0">
                  <label class="font text-gray" for="">
                    Custom contact link 
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <img class="pl-1" style="height:20px; width:20px;" src="/svg/icon_info_circle_gray.svg" v-bind="attrs" v-on="on"/>
                        </template>
                        <div style="border-radius: 8px; width: 300px; padding: 10px;">
                          After selecting your organization, Apaly will utilize this URL or phone number provider to direct the member to your organization to finalize their membership activation (e.g scheduling system, landing page, CX phone number)
                        </div>
                    </v-tooltip>
                  </label>
                  <v-text-field
                    v-if="customContactLink == 'link'"
                    v-model.trim="account.postMemberEnrollment.redirect"
                    solo
                    :rules="websiteRules"
                  ></v-text-field>

                  <v-text-field
                    v-else-if="customContactLink == 'phone'"
                    v-model.trim="account.postMemberEnrollment.phone"
                    solo
                    :rules="phoneRules"
                  ></v-text-field>
                  <v-radio-group v-model="customContactLink" column>
                    <v-radio label="Custom URL" value="link"></v-radio>
                    <v-radio label="Phone Number" value="phone"></v-radio>
                  </v-radio-group>
                </div> -->
              </div>
            </div>
          </div>
          <v-divider horizontal class="mb-5"></v-divider>
          <div class="col-md-12 d-md-flex pl-0 pr-0">
            <div class="col-md-3">
              <h3>Bank Details</h3>
              <p>Enter details of your bank account.</p>
            </div>
            <div class="col-md-9">
              <div class="col-md-12 d-md-flex pt-0 pb-0">
                <div class="col-md-6 pt-0 pb-0">
                  <label class="font text-gray pb-0" for="">Bank Name </label>
                  <v-text-field solo v-model="account.bank.name"></v-text-field>
                </div>
              </div>
              <div class="col-md-12 d-md-flex pt-0 pb-0">
                <div class="col-md-6 pt-0 pb-0">
                  <label class="font text-gray" for="">Inbound Routing Number</label>
                  <v-text-field solo v-model="account.bank.roundNo"></v-text-field>
                </div>
                <div class="col-md-6 pt-0 pb-0">
                  <label class="font text-gray" for=""> Inbound Account Number</label>
                  <v-text-field solo v-model="account.bank.accountNo"></v-text-field>
                </div>
              </div>
            </div>
          </div>
          <v-divider horizontal class="mb-5"></v-divider>
        </div>
      </v-form>
    </div>
    <div class="row text-end justify-end" style="margin-left: -11px">
      <div class="col-md-8 d-flex mt-6 justify-end">
        <a @click="generatePdf()" large class="text-underline mt-3 mr-14">
          View & Print Apaly Terms and Conditions
        </a>
        <v-btn class="cancel mt-2 mr-5 col-md-4" @click="$router.push('/dpc-providers/home')"> Cancel </v-btn>
        <v-btn class="submit mt-2 col-md-4" color="#16407A" @click="update"> Save </v-btn>
      </div>
    </div>
  </v-row>
</template>
<script>
import api from "../../../../services/api";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../TablesTemplate/ErrorDialog.vue";

export default {
  components: { successModal, errorModal },
  data() {
    return {
      logo:null,
      customContactLink: "link",
      categories: [],
      satetes: [],
      states: [],
      account: {
        primaryContact:{
          
        },
        bank:{

        },
        postMemberEnrollment: {
          redirect: "",
          phone: ""
        }
      },
      valid: false,
      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
      zipRules: [(v) => (v && v.length == 5) || " Zip Code should be five digits long"],
      requiredRules: [(v) => !!v || "This field is required"],

      emailRules: [
        (v) => !!v || "This field is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],

      taxIdRules: [
        (v) => !!v || "This field is required",
        (v) =>
          !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
          "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
      ],
      npiRules: [
        (v) => !!v || "This field is required",
        (v) => (v && v.length == 10) || "NPI Number should be 10 digits long",
      ],
      // websiteRules: [
      //   (v) => !!v || "This field is required",
      //   (v) =>
      //   /^(https?:\/\/)?[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/.test(v) ||
      //     "Website must have a domain (.com, .net, etc). Please remove the www. or any extra spaces.",
      // ],
      websiteRules: [
        (v) => !!v || "This field is required",
        (v) =>
          /^(https?:\/\/)?(www\.)?[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/.test(v) ||
          "Website must be valid",
      ],
    };
  },
  created() {
    this.getStates();
    this.getInfo();
  },
  methods: {
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    generatePdf() {
      window.open("/pdfs/Platform Access Agreement 031823.pdf");
    },
    showSlash(event) {
      if (event.target.value.length == 2) {
        event.target.value += "-";
      }
    },
    activateFileInput() {
      this.$refs.fileInput.$refs.input.click();
    },
    getStates() {
      api()
        .get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },
    getInfo() {
      api()
        .get(`/dpcproviders/account`)
        .then((res) => {
          if (res) {
            this.account = res.data;
            console.log("getInfo() - ", res.data);
            this.account.category = 'Direct Primary Care';

            if (!this.account.settings.postMemberEnrollment) {
              this.account.postMemberEnrollment = {
                redirect: "",
                phone: ""
              }
            }
            else {
              this.account.postMemberEnrollment = {
                redirect: this.account.settings.postMemberEnrollment.redirect,
                phone: this.account.settings.postMemberEnrollment.phone
              }
              if(this.account.settings.postMemberEnrollment.redirect) {
                this.customContactLink = 'link';
              }
              else if (this.account.settings.postMemberEnrollment.phone) {
                this.customContactLink = 'phone';
              }
            }

          }
        })
        .finally(() => {
          
        });
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
      
      this.$router.push('/dpc-providers/home');
    },
    updateLogo() {
      let file =  this.$refs.fileInput.$refs.input.files[0]

      let formData = new FormData();
      formData.append("_method", "POST");
      formData.append("image", file);
      api()
        .post(`/dpcproviders/account/logo`, formData)
        .catch((err) => {
          if (err.response.status == 500) {
            this.$refs.error_modal.openPopUp(
              "There was an error, system admin is notified. Please try again later."
            );
          } else {
            this.$refs.error_modal.openPopUp(err.response.data);
          }
        })
        .then((res) => {
          if(res){
            this.account.logo = res.data
            this.$store.commit('setLogo',this.account.logo)
            //this.$router.go()
          }
        });
    },
    update() {
      this.$refs.form.validate();
      // if(this.customContactLink == 'link') {
      //   this.account.postMemberEnrollment.phone = "";
      // }
      // else if (this.customContactLink == 'phone') {
      //   this.account.postMemberEnrollment.redirect = "";
      // }

      console.log(this.account);

      // if (this.valid) {
        api()
          .put(`/dpcproviders/account`, this.account)
          .catch((err) => {
            if (err.response.status == 500) {
              this.$refs.error_modal.openPopUp(
                "There was an error, system admin is notified. Please try again later."
              );
            } else {
              this.$refs.error_modal.openPopUp(err.response.data);
            }
          })
          .then((res) => {
            if (res) {
              this.$refs.success_modal.openPopUp("Account updated successfully");
            }
          });
      // }
      // else {
      //   console.log("Not Saved");
      // }
    },


  },
};
</script>

<style scoped>
.submit {
  width: 130px;
  color: white;
}
.cancel {
  width: 130px;
}
#logo-img {
  max-width: 200px;
  height: 100px;
}
@media screen and (max-width: 768px) {
  #logo-img {
    height: 65px;
  }
  fileInput {
    margin-top: 40px;
  }
}
</style>
