<template>
  <div class="row justify-center mt-4">
    <div class="col-md-6 mt-4">
      <v-card class="mx-auto row-height" max-width="1200" tile>
        <BlueHeader :title="'Locations'"
                    :back="true"
                    :settings="false"
                    :add="true"
                    @goBack="goBack"
        >
        </BlueHeader>
        <div class="clearing-house-search">
          <v-text-field
              class="mr-5 filter-serach-input"
              @input="searchInput"
              solo
              append-icon="fa fa-search"
              placeholder="Search Locations"
          ></v-text-field>
        </div>

        <div class="mt-5">
          <v-list>
            <v-list-item-group color="primary" v-if="!filterItems">
              <v-list-item
                  id="clearing-house-list"
                  v-for="(item, i) in items"
                  :key="i"
                  @click="item.link? $router.push(item.link) : '' "
              >

                <v-list-item-content>
                  <v-list-item-title v-text="item.text" class="ml-5"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon color="warning"  v-text="item.icon"></v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-item-group>
            <v-col md="12" cols="12" v-else>
              <v-alert
                  border="bottom"
                  colored-border
                  type="warning"
                  elevation="2"
                  class="text-gray"
              >
                Sorry! No results found
              </v-alert>
            </v-col>
          </v-list>
        </div>

      </v-card>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import api from "@/services/api";
// import tpas from "../../../../store/JSONS/tpa.json";
import BlueHeader from '../../../../BlueHeader'
export default {
  props: [
    'addNew',
    'table'
  ],
  data() {
    return {
      showDetails: false,
      dialog: false,
      singleItem: null,
      sortBy: "",
      type: "",
      solutions: [],
      favorites: [],
      filterItems: false,
      filteredArray: [],
      selectedItem: 1,
      items: [
        {
          text: "Location1 Test",
          icon: "mdi-arrow-right",
          link: "",
        },
        {
          text: "Location2 Test",
          icon: "mdi-arrow-right",
          link: "",
        },
        {
          text: "Location3 Test",
          icon: "mdi-arrow-right",
          link: "",
        },
      ],
    };
  },
  components: {
    BlueHeader,

  },
  computed: {
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
  },

  methods: {
    goBack(){
      this.$router.push('/dpc-organizations/people-health/settings')
    },
    getData() {
      api()
          .get("/tpas/invoices")
          .then((res) => {
            if (res) {
              // this.items = res.data;
              // this.filteredArray = res.data;
            }
          });
    },
    invite() {},
    add() {},
    exportFun() {
      this.exportData("tblData");
    },

    searchInput(val) {
      this.items = this.filteredArray.filter((el) => {
        if (el.text && el.text.toLowerCase().includes(val.toLowerCase())) {
          return el.text.toLowerCase().includes(val.toLowerCase());
        }
      });
      if (this.items.length < 1) {
        this.filterItems = true;
      } else {
        this.filterItems = false;
      }
    },

    searchMembers() {},
  },
  created() {

    this.filteredArray = this.items;
  },
};
</script>
