<template>
    <div class="no-padding no-margin">
        <PriorAuths v-if="path == '/member/prior-auths'"></PriorAuths>
    </div>
</template>
<script>
    import PriorAuths from "./PriorAuths";
    export default {
        components:{
            PriorAuths,
        },
        data(){
            return{

            }
        },
        computed:{
            path(){
             
                return this.$route.path
            },
            routeName(){
                return this.$route.params.name
            }
        },

        created(){

        },
        methods:{

        }

    }
</script>
<style>
    .background-opacity{
        opacity: 0.46;
        background-color: rgb(33, 33, 33);
        border-color: rgb(33, 33, 33);
    }
</style>