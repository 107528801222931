<template>
    <div class="">
      <div class="row blue-underline mb-3">
        <div class="col-md-12 mb-4 ml-4 mt-4 font-weight-bold text-gray d-flex">
          <svg
            @click="$router.push('/tpa/solutions')"
            class="mr-2 mt-1"
            width="14"
            height="16"
            viewBox="0 0 14 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.6269 23.764L1.99634 12.9562C1.92725 12.8861 1.87267 12.803 1.83571 12.7118C1.79875 12.6205 1.78014 12.5229 1.78095 12.4245C1.78176 12.326 1.80197 12.2287 1.84042 12.1381C1.87888 12.0474 1.93482 11.9653 2.00505 11.8963L12.8118 1.26475"
              stroke="gray"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p @click="$router.push('/tpa/solutions')">Back to Solution </p>
        </div>
      </div>
      <div>
        <div class="row form-row-height pt-2 pb-2 light-blue-text">
          <div
            class="col-md-2 ml-4 font-weight-bold"
            style="border-right: 1px solid #eeeded"
          >
            <div class="text-center justify-center mb-1 mt-1">
              <img
                id="logo-img"
                :src="singleSolution.logo ? singleSolution.logo : '/newapalylogoblue.png'"
                class="apaly-pic pointer mt-3 mb-2"
              />
            </div>
            <div class="text-center justify-center mb-1 mt-5">
              <!-- <v-btn class="participate mt-2" 
              color="#16407A"
               rounded
               @click="participationDialog()">
                Participate
              </v-btn> -->
            </div>
          </div>
          <div class="col-md-8">
            <div class="row col-md-12 font-weight-bold">
              Solution Details
              <v-divider class="mt-3 ml-3"></v-divider>
              <div class="col-12 d-md-flex">
                <div class="col-lg-4 col-xl-4 col-12 text-gray">
                  General information about this solution
                </div>
                <div class="col-12">
                  <div class="col-md-12 d-flex pt-0 pb-0">
                    <span class="col-4 col-lg-3 col-xl-3 col-md-3 text-gray font-weight-bold"> Name:</span>
                    <span class="col-8 col-lg-9 col-xl-9 col-md-9 text-gray font-weight-bold"> {{singleSolution.name}} </span>
                  </div>
                  <div class="col-md-12 d-flex pt-0 pb-0">
                    <span class="col-4 col-lg-3 col-xl-3 col-md-3 text-gray font-weight-bold">
                      Category:</span
                    >
                    <span class="col-8 col-lg-9 col-xl-9 col-md-9 text-gray"> {{singleSolution.category}} </span>
                  </div>
                  <div class="col-md-12 d-flex pt-0 pb-0">
                      <span class="col-4 col-lg-3 col-xl-3 col-md-3 text-gray font-weight-bold"> Type:</span>
                      <span class="col-8 col-lg-9 col-xl-9 col-md-9 text-gray"> {{singleSolution.type}} </span>
                    </div>
                  <div class="col-md-12 d-flex pt-0 pb-0">
                    <span class="col-4 col-lg-3 col-xl-3 col-md-3 text-gray font-weight-bold">
                      Website:</span
                    >
                    <span class="col-8 col-lg-9 col-xl-9 col-md-9 text-gray">
                      <a :href="`${singleSolution.url}`">{{singleSolution.url}}</a>
                    </span>
                  </div>
                  <div class="col-md-12 d-flex pt-0 pb-0">
                    <span class="col-4 col-lg-3 col-xl-3 col-md-3 text-gray font-weight-bold">
                      Description:</span
                    >
                    <span class="col-8 col-lg-9 col-xl-9 col-md-9 text-gray">
                      {{singleSolution.description}}
                    </span>
                  </div>
                  <div class="col-md-12 d-flex pt-0 pb-0">
                      <span class="col-4 col-lg-3 col-xl-3 col-md-3 text-gray font-weight-bold">
                        Definition:</span
                      >
                      <span class="col-8 col-lg-9 col-xl-9 col-md-9 text-gray">
                        {{singleSolution.definition}}
                      </span>
                    </div>
                </div>
              </div>
            </div>
            <div class="row col-md-12 font-weight-bold">
              Solution Facilitator
              <v-divider class="mt-3 ml-3"></v-divider>
              <div class="col-12 d-md-flex">
                <div class="col-lg-4 col-xl-4 col-12 text-gray">
                  Basic information about this facilitator
                </div>
                <div class="col-12">
                  <div class="col-md-12 d-flex pt-0 pb-0">
                    <span class="col-4 col-lg-3 col-xl-3 col-md-3 text-gray font-weight-bold"> Name:</span>
                    <span class="col-8 col-lg-9 col-xl-9 col-md-9 text-gray font-weight-bold"> 
                      {{ singleSolution.facilitator ? singleSolution.facilitator.companyName : "/" }}
                    </span>
                  </div>
                  <div class="col-md-12 d-flex pt-0 pb-0">
                    <span class="col-4 col-lg-3 col-xl-3 col-md-3 text-gray font-weight-bold">
                      Address:</span
                    >
                    <span class="col-8 col-lg-9 col-xl-9 col-md-9 text-gray">
                      {{  singleSolution.facilitator ? singleSolution.facilitator.address1 : "/"}}
                      </span>
                  </div>
                  <div class="col-md-12 d-flex pt-0 pb-0">
                    <span class="col-4 col-lg-3 col-xl-3 col-md-3 text-gray font-weight-bold">
                      Website:</span
                    >
                    <span class="col-8 col-lg-9 col-xl-9 col-md-9 text-gray" v-if="singleSolution.facilitator">
                      <a :href="`${singleSolution.facilitator.website}`"> {{ singleSolution.facilitator ? singleSolution.facilitator.website : "/" }}</a>
                    </span>
                    <span v-else class="col-8 col-lg-9 col-xl-9 col-md-9 text-gray">
  
                    </span>
                  </div>
                  <div class="col-md-12 d-flex pt-0 pb-0">
                    <span class="col-4 col-lg-3 col-xl-3 col-md-3 text-gray font-weight-bold">
                      Contact:</span
                    >
                    <span class="col-8 col-lg-9 col-xl-9 col-md-9 text-gray"> 
                      {{ singleSolution.facilitator ? singleSolution.facilitator.primaryContact.firstName : "/" }}
                      {{ singleSolution.facilitator ? singleSolution.facilitator.primaryContact.lastName : "/"}} 
                    </span>
                  </div>
                  <div class="col-md-12 d-flex pt-0 pb-0">
                    <span class="col-4 col-lg-3 col-xl-3 col-md-3 text-gray font-weight-bold"> Email:</span>
                    <span class="col-8 col-lg-9 col-xl-9 col-md-9 text-gray">
                      <a>{{ singleSolution.facilitator ? singleSolution.facilitator.primaryContact.email : "/"}}</a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row col-md-12 font-weight-bold">
              Enrollment/Participation
              <v-divider class="mt-3 ml-3"></v-divider>
              <div class="col-12 d-md-flex">
                <div class="col-lg-4 col-xl-4 col-12 text-gray">
                  Enter details of your primary contact person
                </div>
                <div class="col-12">
                  <div class="col-md-12 d-flex pt-0 pb-0">
                    <span class="col-4 col-lg-3 col-xl-3 col-md-3 text-gray font-weight-bold">
                      Vendor Participation:</span
                    >
                    <span class="col-8 col-lg-9 col-xl-9 col-md-9 text-gray font-weight-bold">
                      {{singleSolution.vendorParticipation}}
                    </span>
                  </div>
                  <div class="col-md-12 d-flex pt-0 pb-0">
                    <span class="col-4 col-lg-3 col-xl-3 col-md-3 text-gray font-weight-bold">
                      Employer Participation:</span
                    >
                    <span class="col-8 col-lg-9 col-xl-9 col-md-9 text-gray">
                      {{singleSolution.participation}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row col-md-12 font-weight-bold">
              Rates & Fees
              <v-divider class="mt-3 ml-3"></v-divider>
              <div class="col-12 d-md-flex">
                <div class="col-lg-4 col-xl-4 col-12 text-gray">
                  Information about services and different fees
                </div>
                <div class="col-12">
                  <div class="col-md-12 d-flex pt-0 pb-0">
                    <a class="text-underline">Rates for Vendor Services </a>
                  </div>
                  <div class="col-md-12 d-flex pt-0 pb-0">
                    <span class="col-4 col-lg-3 col-xl-3 col-md-3 text-gray font-weight-bold">
                      Type:</span
                    >
                    <span class="col-8 col-lg-9 col-xl-9 col-md-9 text-gray font-weight-bold">
                      {{singleSolution.rate ? singleSolution.rate.type : ""}}
                    </span>
                  </div>
                  <div class="col-md-12 d-flex pt-0 pb-0">
                    <span class="col-4 col-lg-3 col-xl-3 col-md-3 text-gray font-weight-bold">
                      Structure:</span
                    >
                    <span class="col-8 col-lg-9 col-xl-9 col-md-9 text-gray font-weight-bold">
                      {{singleSolution.rate ? singleSolution.rate.structure : "/"}}
                    </span>
                  </div>
                  <div class="col-md-12 d-flex pt-0 pb-0">
                    <span class="col-4 col-lg-3 col-xl-3 col-md-3 text-gray font-weight-bold">
                      Frequency:</span>
                    <span class="col-8 col-lg-9 col-xl-9 col-md-9 text-gray">
                      {{singleSolution.rate ? singleSolution.rate.frequency : "/"}}
                    </span>
                  </div>
                  <div class="col-md-12 d-flex pt-0 pb-0">
                    <span class="col-4 col-lg-3 col-xl-3 col-md-3 text-gray font-weight-bold">
                      Amount:</span
                    >
                    <span class="col-8 col-lg-9 col-xl-9 col-md-9 text-gray">
                      <!-- Vendor / Provider Defined with Max Input for Employer -->
                    </span>
                  </div>
                  <br/>
                  <div class="col-md-12 d-flex pt-0 pb-0">
                    <a class="text-underline">Solution Facilitator Fee</a>
                  </div>
                  <div class="col-md-12 d-flex pt-0 pb-0">
                    <span class="col-4 col-lg-3 col-xl-3 col-md-3 text-gray font-weight-bold">
                     /  </span>
                    <span class="col-8 col-lg-9 col-xl-9 col-md-9 text-gray font-weight-bold">
                      Per Annual Subscription
                    </span>
                  </div>
                  
                  <br />
                  <div class="col-md-12 d-flex pt-0 pb-0">
                    <a class="text-underline">TPA Admin Fee </a>
                  </div>
                  <div class="col-md-12 d-flex pt-0 pb-0">
                    <span class="col-4 col-lg-3 col-xl-3 col-md-3 text-gray font-weight-bold">
                    / </span>
                    <span class="col-8 col-lg-9 col-xl-9 col-md-9 text-gray font-weight-bold">
                      Per Annual Subscription
                    </span>
                  </div>
                  <br />
                  <div class="col-md-12 d-flex pt-0 pb-0">
                    <a class="text-underline">Apaly Fee</a>
                  </div>
                  <div class="col-md-12 d-flex pt-0 pb-0">
                    <span class="col-4 col-lg-3 col-xl-3 col-md-3 text-gray font-weight-bold">
                      /</span>
                    <span class="col-8 col-lg-9 col-xl-9 col-md-9 text-gray font-weight-bold">
                      Per Transaction
                    </span>
                  </div>
                  <br />
                </div>
              </div>
            </div>
            <!-- <div class="row col-md-12 font-weight-bold">
                  Rates and Fees
                   <v-divider class="mt-3 ml-3"></v-divider>
                   <div class="col-12 d-flex">
                      <div class="col-4 text-gray"></div>
                      <div class="col-8 text-gray">Fee for service</div>
                    </div>
                 </div> -->
          </div>
        </div>
      </div>
      <v-dialog v-model.trim="participation" width="750" height="700" persistent>
          <v-card class="col-md-12" v-if="enrollment">
              <div class="col-md-12 text-end"> 
                  <h3 @click="closeDialog()">X</h3>
                </div>
            <v-card-title class="text-h5"> Enrollment </v-card-title>
            <div class="col-md-12 ml-3"> 
              What subscription rate would you like to apply for the solution?
            </div>
            <div class="col-md-12 d-flex pt-0 pb-0">
              <span class="col-3 text-gray font-weight-bold mt-2">
                  Rate Schedule </span
              >
              <span class="col-9 text-gray font-weight-bold">
                  <v-select
                  class=""
                  solo
                  placeholder="Select One of Your Rate Groups"
                  >
                  </v-select>
              </span>
            </div>
            <div class="col-md-12 ml-3"> 
              <v-simple-table fixed-header height="430px">
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th class="text-left">ITEM</th>
                      <th class="text-left">MONTHLY AMOUNT</th>
                      <th class="text-left">ANUAL AMOUNT </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>
                          DPC Base Rate:  
                      </td>
                      <td >  $80.00 USD </td>
                      <td >   $960.00 USD  </td>
                    </tr>
                    <tr>
                      <td>
                          Total Apaly Fees:    
                      </td>
                      <td >     $10.00 USD        </td>
                      <td >   $120.00 USD </td>
                    </tr>
                    <tr>
                      <td>
                      Total amount charged to the employer per member       
                      </td>
                      <td >       $90.00 USD           </td>
                      <td >     $1,080.00 USD  </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
            </div>
              <div class="col-md-12 text-center">
                <v-btn class="next"
                 @click="toContract()"
                 color="#16407A"
                  >Next</v-btn
                >
              </div>
            
          </v-card>
          <v-card class="col-md-12" v-if="contract">
              <div class="col-md-12 text-end"> 
                  <h3 @click="closeDialog()">X</h3>
                </div>
              <v-card-title class="text-h5"> Contract Agreement  </v-card-title>
              <div class="col-md-12 ml-3"> 
                  Explanatory text regarding contracts
              </div>
              
              <div class="col-md-12 ml-3"> 
                <div class="col-md-10"> 
                  <h3>1. Clause 1 </h3>
                </div>
                <div class="col-md-10"> 
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra condimentum eget purus in. Consectetur eget id morbi amet amet, in. Ipsum viverra pretium tellus neque. Ullamcorper suspendisse aenean leo pharetra in sit semper et. Amet quam placerat sem.
                </div>
              </div>
              <div class="col-md-12 ml-3"> 
                  <div class="col-md-10"> 
                    <h3>2. Clause 2 </h3>
                  </div>
                  <div class="col-md-10"> 
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra condimentum eget purus in. Consectetur eget id morbi amet amet, in. Ipsum viverra pretium tellus neque. Ullamcorper suspendisse aenean leo pharetra in sit semper et. Amet quam placerat sem.
  
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra condimentum eget purus in. Consectetur eget id morbi amet amet, in. Ipsum viverra pretium tellus neque. Ullamcorper suspendisse aenean leo pharetra in sit semper et. Amet quam placerat sem.                </div>
                </div>
                <div class="col-md-12 text-center mb-3">
                 <a class="text-underline">  Click here to download the contract </a>
                </div>
                <div class="col-md-12 text-center">
                  <v-btn class="next"
                   @click="toSuccess()"
                   color="#16407A"
                    >Enroll</v-btn
                  >
                </div>
              
            </v-card>
            <v-card class="col-md-8 txt-center" v-if="success">
              <div class="col-md-12 text-end"> 
                  <h3 @click="closeDialog()">X</h3>
                </div>
              <h2 class="font-weight-bold mt-10 mb-5 text-center"> Success! You have enrolled in this <br> solution.  </h2>
              <div class="col-md-12 ml-3  text-center"> 
                 <h3> Your approval is pending and you will be notified once <br> the solution facilitator has reviewed and approved your <br> participation </h3> 
              </div>
                <div class="col-md-12 text-center mt-5">
                  <v-btn class="okay"
                   @click="participation = false"
                   color="#16407A"
                    >Okay</v-btn
                  >
                </div>
              
            </v-card>
        </v-dialog>
      <div class="gray-top-border">
        <div class="col-md-12" align="end">
          <!-- <span
            class="light-blue-text text-underline mr-10 font-weight-bold pointer"
            @click="addSolution"
            >Update</span
          > -->
          <span
            class="light-blue-text text-underline mr-10 ml-10 font-weight-bold pointer"
            @click="$router.push('/tpa/solutions')"
            >Close</span
          >
        </div>
      </div>
      <successModal
        ref="success_modal"
        v-on:confirm="closeSuccessModal"
      ></successModal>
    </div>
  </template>
    
    <script>
  import successModal from "../../../TablesTemplate/SuccessDialog.vue";
  import api from "../../../../services/api";
  import { mapGetters } from "vuex";
  
  export default {
    components: { successModal },
    data() {
      return {
        logo: "",
        singleSolution: {},
          enrollment: true,
          contract: false,
          success: false,
          participation: false,
        item: {
          category: "DPC",
          url: "",
          contact: "",
          email: "",
          definition: "",
          description: "",
        },
        valid: false,
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
        ],
        requiredRules: [(v) => !!v || "This field is required"],
      };
    },
  
    computed: {
      ...mapGetters(["getSingleItem"]),
    },
    created() {
      this.getSolution();
      // console.log('s',this.getSingleItem)
      // if (this.getSingleItem) {
      //   this.singleSolution = this.getSingleItem;
      
      // }
    },
    methods: {
      closeSuccessModal() {
        this.$parent.getSolutions();
        this.$router.push("/tpa/solutions");
      },
      addSolution() {
        this.$refs.form.validate();
        if (this.valid) {
          api()
            .put("/tpa/solutions/" + this.item._id, this.item)
            .then((res) => {
              if (res) {
                this.$refs.success_modal.openPopUp("Updated successfully");
              }
            });
        }
      },
      getSolution() {
          api()
            .get("/tpas/solution/" + this.getSingleItem._id)
            .then((res) => {
              if (res) {
                this.singleSolution = res.data;
              }
            });
      },
      participationDialog(){
          this.participation = true;
      },
      toContract(){
          this.enrollment = false;
          this.contract = true;
      },
      toSuccess(){
          this.enrollment = false;
          this.contract = false;
          this.success = true;
      },
      closeDialog(){
          this.enrollment = true;
          this.contract = false;
          this.success = false;
          this.participation = false;
  
      },
  
    },
  };
  </script>
    <style scoped>
  .participate {
    width: 150px;
    color: white;
  }
  .next {
      width: 650px;
      color: white;
    }
    .okay {
      width: 250px;
      color: white;
    }
  </style>
    