<template>
  <div class="page-background">
    <main-header :title="'Settings'" :color="'white'" ></main-header>
    <div class="mobile-edit-form">
      <form >
        <div>
          <h3 class="from-headers">Change Email</h3>
          <v-text-field outlined label="Email" color="white" class="input-text" type="email"></v-text-field>
        </div>

        <div class="row mb-5" >
         <div class="col-6">
           <v-btn class="btn-settings" >Cancel</v-btn>
         </div>
          <div class="col-6">
            <v-btn color="white" class="btn-settings-white" @click="sendVerification">Send Verification</v-btn>
          </div>
        </div>

        <div>
          <h3 class="from-headers">Email Notifications</h3>
          <v-switch inset color="green" value="green" label="My plan information"
          ></v-switch>
          <v-switch color="green" value="green" inset label="Apaly"
          ></v-switch>
        </div>
      </form>

    </div>
    <div class="d-flex footer"  style="">
      <div class="col-6">
      </div>

    </div>
  </div>
</template>

<script>
import mainHeader from "@/components/Sections/DPCMember/Layout/mainHeader";
export default {
  data() {
    return {
      relationships: ["Spouse"],
      genders: ["male", "female"],
    };
  },
  components: {
    mainHeader,
  },
  methods: {
    sendVerification(){
      this.$router.push({name:'alertPage'})

    }
  },
  created() {},
};
</script>
<style scoped src='./../../../../assets/mobileStyle.css'></style>
<style scoped lang="scss">
.mobile-edit-form {
  background-color: #16407a;
  height: 100vh;
  padding: 20px;
  overflow-y: auto;
  padding-bottom: 150px !important;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;
}

.mobile-content {
  padding: 16px 24px 0 24px;
}

.page-background {

  background-color: #001e46;
}

.list-item-title {
  color: #0573f3;
  font-family: "Quicksand";
  font-weight: 700;
}

.list-item-title {
  color: #0573f3;
  font-family: "Quicksand";
  font-weight: 700;
}

.list-item-subtitle {
  font-family: "Quicksand";
  font-weight: 600;
}

.second-subtitle {
  color: #001e46;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.mobile-edit-form v-text-field {
  color: white;
}

.from-headers {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  padding-bottom: 15px;
  color: white;
}

.from-sub-headers {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.footer{
  background-color: #16407a;
  height:80px;
  position:absolute;
  bottom:0;
  width:100%;
  align-items: flex-start;
  border-top: 1px solid #C9C9C9
}
.footer-btn{
  border-radius: 23px;
  width: 134px;
}

.footer-btn-cancel{
  background-color:#16407a !important;
  border-radius: 23px;
  width: 134px;
  border: 1px solid white !important;
  color: white;
  float:right
}

.btn-settings{
  background-color:#16407a !important;
  border-radius: 23px;
  width: 155px;
  border: 1px solid white !important;
  color: white;
  float:right
}

.btn-settings-white{
  background-color:white !important;
  border-radius: 23px;
  width: 155px;
  border: 1px solid white !important;
  color: #16407a;
  float:right
}

.v-input__slot > label {
  color: white !important;
}

</style>
