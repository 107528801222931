<template>
  <div>
       <Home class="mt-5" v-if="path == '/employer-advisor/employers/' + routeParams"></Home>
       <EmployerTemplate></EmployerTemplate>
       <Member></Member>
       <PriorAuths></PriorAuths>
       <Providers></Providers>
       <Advisors></Advisors>
       <Bundles></Bundles>
       <!-- <Utilization v-if="path == `/employer-advisor/employers/${routeParams}/utilization`"></Utilization> -->
       <Networks></Networks>
       <Contracts></Contracts>
       <Settings></Settings>
   </div>
</template>

<script>
import api from '../../../../services/api'
import EmployerTemplate from '../Employers/Employers/EmployerTemplate.vue'
import Home from '../Employers/Home/Home.vue'
import Member from '../Employers/Members/MembersTemplate.vue'
import PriorAuths from '../Employers/PriorAuths/PriorTemplate.vue'
import Providers from '../Employers/Providers/ProvidersTemplate.vue'
import Advisors from '../Employers/Advisors/AdvisorTemplate.vue'
import Bundles from '../Employers/Bundles/BundlesTemplate.vue'
import Networks from '../Employers/Networks/NetworksTemplate.vue'
import Contracts from '../Employers/Contract/ContractTemplate.vue'
import Utilization from '../Utilization/Utilization.vue'
import Settings from '../Employers/Settings/SettingsTemplate.vue'
export default{
    components:{
        EmployerTemplate,
        Home,
        Member,
        Utilization,
        PriorAuths,
        Providers,
        Advisors,
        Bundles,
        Networks,
        Contracts,
        Settings
    },
    data(){
     return{
         errorTemp:false,
         companyName:''
     }
    },
     created(){
        
      if(this.$route.params.id){
            api().get(`/advisors/verifyEmployer/${this.$route.params.id}`).then((res)=>{
                if(res.status != 200){
                    this.errorTemp = true
                }else{
                    api().get(`/advisors/${res.data.advisorId}/employers/${res.data.employerId}`).then((res)=>{
                        if(res){
                            this.$router.push('/employer-advisor/employers/' + res.data.employer.companyName);
                            return;
                        }
                    }).catch((err)=>{
                    console.log(err.response);
                    })  
                }
            })
      }else if(this.$route.params.name){
        if(this.path.includes(`/employer-advisor/employers/${this.routeParams}/${this.path.split('/')[4]}`) && this.path != `/employer-advisor/employers/${this.routeParams}/${this.path.split('/')[4]}`){
             this.$router.push(`/employer-advisor/employers/${this.routeParams}/${this.path.split('/')[4]}`);
        }
      }    
    },
    computed:{
        path(){
             return this.$route.path.replace(/%20/g,' ')
        },
        routeParams(){
           let  route = ''
            if(localStorage.getItem('employer')){
               route = JSON.parse(localStorage.getItem('employer')).companyName
           }
           if(this.$route.params.name){
               route =  this.$route.params.name
           }
            return route
       }
    }
}

</script>