<template>
  <div class="">
    <div v-if="!isChildren && $route.meta.type == 'clients'" class="row-height">
      <!-- <Header
        :title="'Clients'"
        :searchInput="true"
        :exportButton="true"
        :placeholder="'Search Clients'"
        :newButton="true"
        @invite="invite"
        @add="add"
        @searchInput="searchInput"
        @export="exportFun"
      ></Header> -->
      <v-row class="mb-1 blue-underline">
        <v-col class="col-xl-2 col-md-2 cols-4 pt-2 pb-0" cols="4">
          <div class="col-md-12">
            <h3 class="form-title font col-md-12">Clients</h3>
          </div>
        </v-col>

        <!--                <v-col class="col-xl-2 col-md-5 cols-8" cols="8">-->
        <!--                  <div class="col-md-12 d-flex no-padding">-->
        <!--                    <v-text-field-->
        <!--                      class="input-control mr-5 pt-4"-->
        <!--                      prepend-inner-icon="mdi-magnify"-->
        <!--                      @input="search"-->
        <!--                      light-->
        <!--                      dense-->
        <!--                      outlined-->
        <!--                      placeholder="Search Clients"-->
        <!--                    ></v-text-field>-->
        <!--                  </div>-->
        <!--                </v-col>-->
        <v-col class="col-xl-2 col-md-5 cols-8 mobile-display" cols="8">
          <div class="col-md-12 d-flex no-padding">
            <v-text-field
              class="input-control mr-5 pt-4"
              prepend-inner-icon="mdi-magnify"
              @input="searchInput"
              light
              dense
              outlined
              placeholder="Search Clients"
            ></v-text-field>
          </div>
        </v-col>
        <v-col
          class="col-xl-8 col-md-5 cols-12 text-end pt-7 pr-6 pb-0 mobile-display"
          cols="12"
          align="end"
        >
          <v-menu offset-y>
            <template v-slot:activator="{}">
              <v-btn @click="exportFun" class="my-1 mr-5 gray-border-buttons">
                <svg
                  class="mr-2"
                  width="15"
                  height="16"
                  viewBox="0 0 15 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.2361 10.7639C14.0329 10.7639 13.838 10.8434 13.6937 10.985C13.5495 11.1267 13.4676 11.319 13.4659 11.5202V12.6764C13.4659 12.9367 13.3615 13.1862 13.1756 13.3703C12.9897 13.5543 12.7376 13.6577 12.4747 13.6577H2.52525C2.26238 13.6577 2.01027 13.5543 1.8244 13.3703C1.63852 13.1862 1.53409 12.9367 1.53409 12.6764V11.5202C1.51671 11.3307 1.42838 11.1544 1.28645 11.0261C1.14452 10.8978 0.959257 10.8266 0.767046 10.8266C0.574834 10.8266 0.389569 10.8978 0.247642 11.0261C0.105715 11.1544 0.0173841 11.3307 0 11.5202V12.6764C0 13.3395 0.266053 13.9753 0.739629 14.4442C1.21321 14.913 1.85551 15.1764 2.52525 15.1764H12.4747C13.1445 15.1764 13.7868 14.913 14.2604 14.4442C14.7339 13.9753 15 13.3395 15 12.6764V11.5202C15 11.3196 14.9195 11.1272 14.7763 10.9854C14.633 10.8436 14.4387 10.7639 14.2361 10.7639Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.49971 0.176392C7.17488 0.176392 6.86336 0.304137 6.63368 0.531525C6.404 0.758913 6.27496 1.06732 6.27496 1.38889V7.05139C6.27496 7.09283 6.25833 7.13257 6.22873 7.16188C6.19913 7.19118 6.15899 7.20764 6.11713 7.20764H4.43784C4.32437 7.21533 4.2151 7.25322 4.12158 7.31731C4.02807 7.3814 3.95378 7.46931 3.90657 7.57175C3.85935 7.6742 3.84097 7.78736 3.85337 7.89929C3.86576 8.01122 3.90847 8.11776 3.97698 8.20764L7.03885 11.6951C7.0979 11.7576 7.16927 11.8074 7.24855 11.8414C7.32782 11.8754 7.4133 11.893 7.49971 11.893C7.58611 11.893 7.6716 11.8754 7.75087 11.8414C7.83014 11.8074 7.90151 11.7576 7.96057 11.6951L11.0224 8.20764C11.0909 8.11776 11.1337 8.01122 11.146 7.89929C11.1584 7.78736 11.1401 7.6742 11.0928 7.57175C11.0456 7.46931 10.9713 7.3814 10.8778 7.31731C10.7843 7.25322 10.675 7.21533 10.5616 7.20764H8.88228C8.84042 7.20764 8.80028 7.19118 8.77068 7.16188C8.74108 7.13257 8.72445 7.09283 8.72445 7.05139V1.38889C8.72445 1.06732 8.59542 0.758913 8.36573 0.531525C8.13605 0.304137 7.82453 0.176392 7.49971 0.176392V0.176392Z"
                    fill="#707070"
                  />
                </svg>

                Export</v-btn
              >
              <v-btn
                @click="$router.push('/tpa/clients/add-new')"
                class="my-1 gray-border-buttons"
              >
                <svg
                  class="mr-2"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 7.5H15"
                    stroke="#707070"
                    stroke-width="2"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.5 0V15"
                    stroke="#707070"
                    stroke-width="2"
                    stroke-linejoin="round"
                  />
                </svg>

                New</v-btn
              >
            </template>
          </v-menu>
        </v-col>
        <v-col
          class="col-xl-10 col-md-10 cols-8 text-end"
          cols="8"
          max-width="350px"
          align="end"
        >
          <v-btn
            class="gray-border-buttons mobile-only ml-auto mr-5 mt-3"
            @click="filterModal = true"
          >
            <i class="fas fa-bars" style="cursor: pointer; color: gray"></i>
          </v-btn>
          <v-menu offset-y>
            <template v-slot:activator="{}">
              <v-dialog v-model="filterModal" persistent max-width="350px">
                <v-card>
                  <v-container>
                    <v-col
                      md="12"
                      cols="12"
                      class="justify-center align-center pl-0"
                    >
                      <div class="row col-md-12 font">
                        <h3 class="ml-3">Filters</h3>
                      </div>
                    </v-col>
                    <v-btn
                      @click="exportFun"
                      class="my-1 mr-5 gray-border-buttons menuButton"
                    >
                      <svg
                        class="mr-2"
                        width="15"
                        height="16"
                        viewBox="0 0 15 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.2361 10.7639C14.0329 10.7639 13.838 10.8434 13.6937 10.985C13.5495 11.1267 13.4676 11.319 13.4659 11.5202V12.6764C13.4659 12.9367 13.3615 13.1862 13.1756 13.3703C12.9897 13.5543 12.7376 13.6577 12.4747 13.6577H2.52525C2.26238 13.6577 2.01027 13.5543 1.8244 13.3703C1.63852 13.1862 1.53409 12.9367 1.53409 12.6764V11.5202C1.51671 11.3307 1.42838 11.1544 1.28645 11.0261C1.14452 10.8978 0.959257 10.8266 0.767046 10.8266C0.574834 10.8266 0.389569 10.8978 0.247642 11.0261C0.105715 11.1544 0.0173841 11.3307 0 11.5202V12.6764C0 13.3395 0.266053 13.9753 0.739629 14.4442C1.21321 14.913 1.85551 15.1764 2.52525 15.1764H12.4747C13.1445 15.1764 13.7868 14.913 14.2604 14.4442C14.7339 13.9753 15 13.3395 15 12.6764V11.5202C15 11.3196 14.9195 11.1272 14.7763 10.9854C14.633 10.8436 14.4387 10.7639 14.2361 10.7639Z"
                          fill="#707070"
                        />
                        <path
                          d="M7.49971 0.176392C7.17488 0.176392 6.86336 0.304137 6.63368 0.531525C6.404 0.758913 6.27496 1.06732 6.27496 1.38889V7.05139C6.27496 7.09283 6.25833 7.13257 6.22873 7.16188C6.19913 7.19118 6.15899 7.20764 6.11713 7.20764H4.43784C4.32437 7.21533 4.2151 7.25322 4.12158 7.31731C4.02807 7.3814 3.95378 7.46931 3.90657 7.57175C3.85935 7.6742 3.84097 7.78736 3.85337 7.89929C3.86576 8.01122 3.90847 8.11776 3.97698 8.20764L7.03885 11.6951C7.0979 11.7576 7.16927 11.8074 7.24855 11.8414C7.32782 11.8754 7.4133 11.893 7.49971 11.893C7.58611 11.893 7.6716 11.8754 7.75087 11.8414C7.83014 11.8074 7.90151 11.7576 7.96057 11.6951L11.0224 8.20764C11.0909 8.11776 11.1337 8.01122 11.146 7.89929C11.1584 7.78736 11.1401 7.6742 11.0928 7.57175C11.0456 7.46931 10.9713 7.3814 10.8778 7.31731C10.7843 7.25322 10.675 7.21533 10.5616 7.20764H8.88228C8.84042 7.20764 8.80028 7.19118 8.77068 7.16188C8.74108 7.13257 8.72445 7.09283 8.72445 7.05139V1.38889C8.72445 1.06732 8.59542 0.758913 8.36573 0.531525C8.13605 0.304137 7.82453 0.176392 7.49971 0.176392V0.176392Z"
                          fill="#707070"
                        />
                      </svg>

                      Export</v-btn
                    >
                    <v-btn
                      @click="$router.push('/tpa/clients/add-new')"
                      class="my-1 gray-border-buttons mr-2"
                    >
                      <svg
                        class="mr-2"
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0 7.5H15"
                          stroke="#707070"
                          stroke-width="2"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M7.5 0V15"
                          stroke="#707070"
                          stroke-width="2"
                          stroke-linejoin="round"
                        />
                      </svg>

                      New</v-btn
                    >
                    <v-text-field
                      class="input-control mr-5 pt-4"
                      prepend-inner-icon="mdi-magnify"
                      @input="searchInput"
                      light
                      dense
                      outlined
                      placeholder="Search Clients"
                    ></v-text-field>
                    <v-card-actions>
                      <div class="col-md-12 mb-4 d-flex" align="end">
                        <span
                          class="text-underline text-gray text-start mt-4 pointer col-md-6"
                          @click="filterModal = false"
                          >Reset</span
                        >
                        <span
                          class="text-underline blue--text pointer mt-4 col-md-6"
                          @click="filterModal = false"
                          >Apply Filter</span
                        >
                      </div>
                    </v-card-actions>
                  </v-container>
                </v-card>
              </v-dialog>
            </template>
          </v-menu>
        </v-col>
      </v-row>
      <div class="mt-3">
        <v-simple-table
          fixed-header
          height="70vh"
          class="no-padding tr-custom-height"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">CLIENT NAME</th>
                <th class="text-left"># OF PLANS</th>
                <th class="text-left"># OF SOLUTIONS</th>
                <th class="text-left">LAST MONTH SPEND</th>
              </tr>
            </thead>

            <tbody
              v-if="clients.length > 0 && !filterItems"
              class="text-gray tr-custom-height"
            >
              <tr
                class="text-gray"
                id="remove-hover"
                v-for="(item, index) in clients"
                :key="index"
              >
                <td>
                  <a
                    v-if="item && item.companyName"
                    class="text-underline"
                    @click="showClient(item)"
                  >
                    {{ item.companyName }}
                  </a>
                  <a v-else @click="showClient(item)">/</a>
                </td>
                <td>{{ item.noPlans }}</td>
                <td>{{ item.noSolutions }}</td>
                <td>${{ item.lastMonthSpent }}</td>
              </tr>
            </tbody>
            <tbody v-else-if="clients.length < 1 && filterItems">
              <v-col md="12" cols="12">
                <v-alert
                  border="bottom"
                  colored-border
                  type="warning"
                  elevation="2"
                  class="text-gray"
                >
                  Sorry! No results found
                </v-alert>
              </v-col>
            </tbody>
            <tbody v-else>
              <v-col md="12">
                There are no clients currently added to your account.
                <a @click="$router.push('/tpa/clients/add-new')"
                  >Click to add your clients</a
                >
              </v-col>
            </tbody>
          </template>
        </v-simple-table>
      </div>

      <table id="tblData" style="display: none">
        <thead>
          <tr>
            <th class="text-left">CLIENT NAME</th>
            <th class="text-left">OF PLANS</th>
            <th class="text-left">OF SOLUTIONS</th>
            <th class="text-left">LAST MONTH SPEND</th>
          </tr>
        </thead>

        <tbody
          v-if="clients.length > 0 && !filterItems"
          class="text-gray tr-custom-height"
        >
          <tr
            class="text-gray"
            id="remove-hover"
            v-for="(item, index) in clients"
            :key="index"
          >
            <td>
              <a
                v-if="item && item.companyName"
                class="text-underline"
                @click="showClient(item)"
              >
                {{ item.companyName }}
              </a>
              <a v-else @click="showClient(item)">/</a>
            </td>
            <td>{{ item.noPlans }}</td>
            <td>{{ item.noSolutions }}</td>
            <td>
              <a>Submit Charges</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import Header from "../Layout/HeaderPage.vue";
import api from "../../../../services/api";
export default {
  data() {
    return {
      filterModal: false,
      showDetails: false,
      tab: "solution",
      dialog: false,
      clients: [],
      filterArray: [],
      singleItem: null,
      typeItems: [{ text: "Categories", value: "Categories" }],
      sortItems: [{ text: "Newest", value: "Newest" }],
      sortBy: "",
      type: "",
      solutions: [],
      favorites: [],
      filterItems: false,
      load: false,
    };
  },
  components: {
    Header,
  },
  watch: {
    $route(to, from) {
      if (to.path == "/tpa/clients") this.getData();
    },
  },
  computed: {
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
  },
  created() {
    this.getData();
  },
  methods: {
    exportFun() {
      this.exportData("tblData");
    },
    exportData(tableID, filename = "") {
      let elt = document.getElementById(tableID);
      let downloadLink;
      let dataType = "application/vnd.ms-excel";
      let tableSelect = elt;
      let tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

      filename = filename ? filename + ".xls" : "excel_data.xls";
      downloadLink = document.createElement("a");
      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        let blob = new Blob(["\ufeff", tableHTML], {
          type: dataType,
        });
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        downloadLink.href = "data:" + dataType + ", " + tableHTML;
        downloadLink.download = filename;
        downloadLink.click();
      }
    },
    invite() {},
    add() {
      this.$router.push("/tpa/clients/add-new");
    },

    showClient(item) {
      api()
        .get("/tpas/clients/" + item.id)
        .then((res) => {
          this.singleItem = res.data;
          this.$store.commit("setClientName", res.data.companyName);
          this.$router.push(`/tpa/clients/${item.id}/details/`);
        });
    },
    getData() {
      this.load = true;
      api()
        .get(`/tpas/clients`)
        .then((res) => {
          if (res && res.data) {
            // this.clients = res.data;
            this.clients = res.data;
            this.filterArray = this.clients;
            this.load = false;
          }
        })
        .catch((err) => {
          this.load = false;
        });
    },
    searchInput(val) {
      this.clients = this.filterArray.filter((el) => {
        return el.companyName
          ?.toString()
          .toLowerCase()
          .includes(val.toLowerCase());
      });
      this.filterItems = this.clients.length < 1;
    },
  },
};
</script>
<style scoped>
.border-blue-bottom {
  border-bottom: 6px solid #4072b7;
  margin-left: -1px;
  margin-right: -2px;
  margin-top: -1px;
}

.tr-custom-height > .v-data-table__wrapper > table > tbody > tr > td {
  height: 75px;
}
.mobile-only {
  display: none;
}
.display {
  display: grid;
}
@media screen and (max-width: 768px) {
  .mobile-display {
    display: none;
  }
  .mobile-only {
    width: 10px;
    display: flex;
  }
}
</style>
