<template>
    <div class="">
      <div class="row-height">
        <div class="blue-underline">
            <div class="col-md-12">
              <h3 class="text-gray">DPC Invoice Details</h3>
            </div>
          </div>
        <div v-if="invoicesPart" class="row row-height mt-5 mb-5">
          <div class="col-12 col-md-6">
            <div  class="col-12 col-md-6"> 
                <h4 class="text-gray font ml-2 mb-2"> Invoice Information  </h4>
            </div>
            <div class="text-gray pl-2 mt-3" style="align-items: baseline;">
                <div class="row" style="align-items: baseline">
                    <div class="col-md-5 text-md-right">
                  <span class="text-gray font-weight-bold ml-4">SOLUTION:</span>
                </div>
                <div class="col-md-6 col-11">
                  <v-text-field class="input-control"  disabled  light dense outlined></v-text-field>
                </div>
              </div>
              <div class="row" style="align-items: baseline">
                <div class="col-md-5  text-md-right">
                    <span class="text-gray font-weight-bold ml-4">INVOICE NUMBER:</span>
                </div>
                <div class="col-md-6 col-11">
                  <v-text-field class="input-control" :value="invoice.invoiceNo" disabled  light dense outlined></v-text-field>
                </div>
              </div>
              <div class="row" style="align-items: baseline">
                <div class="col-md-5  text-md-right">
                    <span class="text-gray font-weight-bold ml-4">CLIENT:</span>
                </div>
                <div class="col-md-6 col-11">
                  <v-text-field class="input-control" :value="invoice.client" disabled light dense outlined></v-text-field>
                </div>
              </div>
              <div class="row" style="align-items: baseline">
                <div class="col-md-5  text-md-right">
                    <span class="text-gray font-weight-bold ml-4">INVOICE DATE:</span>
                </div>
                <div class="col-md-6 col-11">
                  <v-text-field class="input-control" :value="invoice.date" disabled light dense outlined></v-text-field>
                </div>
              </div>
              <div class="row" style="align-items: baseline">
                <div class="col-md-5  text-md-right">
                    <span class="text-gray font-weight-bold ml-4">DESCRIPTION:</span>
                </div>
                <div class="col-md-6 col-10">
                  <v-text-field class="input-control" :value="invoice.description" disabled light dense outlined></v-text-field>
                </div>
              </div>
              <div class="row" style="align-items: baseline">
                <div class="col-md-5  text-md-right">
                    <span class="text-gray font-weight-bold ml-4">TOTAL CHARGE (USD):</span>
                </div>
                <div class="col-md-6 col-11">
                  <v-text-field class="input-control" :value="invoice.total" disabled light dense outlined></v-text-field>
                </div>
              </div>
              <div class="row" style="align-items: baseline">
                <div class="col-md-5  text-md-right">
                    <span class="text-gray font-weight-bold ml-4">STATUS:</span>
                </div>
                <div class="col-md-6 col-11">
                  <v-text-field class="input-control" :value="invoice.status" disabled light dense outlined></v-text-field>
                </div>
              </div>
            </div>
  
          </div>
          <v-divider vertical></v-divider>
          <div class="col-12 col-md-6">
            <div  class="col-12 col-md-6"> 
                <h4 class="text-gray font ml-2 mb-2"> Member Information  </h4>
            </div>
            <div class="text-gray pl-2 mt-3" style="align-items: baseline;">
                <div class="row" style="align-items: baseline">
                    <div class="col-md-5  text-md-right">
                  <span class="text-gray font-weight-bold ml-4">MEMBER FIRST NAME:</span>
                </div> 
                <div class="col-md-6  col-11">
                  <v-text-field class="input-control" :value="member.firstName" disabled  light dense outlined></v-text-field>
                </div>
              </div>
              <div class="row" style="align-items: baseline">
                <div class="col-md-5  text-md-right">
                    <span class="text-gray font-weight-bold ml-4">MEMBER LAST NAME:</span>
                </div>
                <div class="col-md-6  col-11">
                  <v-text-field class="input-control" :value="member.lastName" disabled  light dense outlined></v-text-field>
                </div>
              </div>
              <div class="row" style="align-items: baseline">
                <div class="col-md-5  text-md-right">
                    <span class="text-gray font-weight-bold ml-4">MEMBER ID:</span>
                </div>
                <div class="col-md-6  col-11">
                  <v-text-field class="input-control" :value="member.memberId" disabled light dense outlined></v-text-field>
                </div>
              </div>
              <div class="row" style="align-items: baseline">
                <div class="col-md-5  text-md-right">
                    <span class="text-gray font-weight-bold ml-4">PLAN ID:</span>
                </div>
                <div class="col-md-6  col-11">
                  <v-text-field class="input-control" :value="member.planId" disabled light dense outlined></v-text-field>
                </div>
              </div>
              <div class="row" style="align-items: baseline">
                <div class="col-md-5  text-md-right">
                    <span class="text-gray font-weight-bold ml-4">CLAIM NUMBER</span>
                </div>
                <div class="col-md-6  col-11">
                  <v-text-field class="input-control" :value="invoice.claimNumber" disabled  light dense outlined></v-text-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import Header from "../../../Layout/HeaderPage.vue";
  import DpcMembers from '../Billing/DpcMembers.vue'
  import api from "../../../../../../services/api";
  export default {
    data() {
      return {
        showDetails: false,
        dialog: false,
        invoice: [],
        invoiceList: [],
        filterArray: [],
        singleItem: null,
        filterItems: false,
        load: false,
        invoicesPart: true,
        membersPart: false,
        member: {},
      };
    },
    components: {
      Header,
      DpcMembers,
    },
    methods: {
      
    },
    created() {
      api().get(`/tpas/clients/${this.$route.params.clientId}/invoice/${this.$route.params.invoiceId}`)
          .then((res) => {
            if (res && res.data) {
              this.invoice = res.data
             this.invoice.date = res.data.date.substring(0,10);
              this.invoice.status = res.data.status.charAt(0).toUpperCase() + res.data.status.slice(1);
              this.member = res.data.member;
            }
          }).catch((err) => {
            console.log(err.response.message)
      });
    },
  };
  </script>
  <style>
  .border-blue-bottom {
    border-bottom: 6px solid #4072b7;
    margin-left: -1px;
    margin-right: -2px;
    margin-top: -1px;
  }
  
  .tr-custom-height > .v-data-table__wrapper > table > tbody > tr > td {
    height: 75px;
  }
  </style>