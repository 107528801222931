<template>
  <div>
    <div
      v-if="!isChildren"
      class="row row-height"
      :class="showOverlay ? ' postion-relative' : ''"
    >
      <div v-if="showOverlay" class="wizard-absolute"></div>
      <v-sheet
        class="overflow-hidden col-md-12"
        style="position: relative; border-radius: 5px"
      >
        <div class="">
          <v-row align="center" justify="center">
            <div class="col-md-12 navigationWidth no-padding">
              <v-row class="orange-underline-wizard mb-1">
                <v-col class="col-xl-2 col-md-2 cols-4 pt-2 pb-0" cols="4">
                  <div class="col-md-12">
                    <h3 class="form-title font col-md-12">Members</h3>
                  </div>
                </v-col>
                <v-col class="col-xl-3 col-md-5 cols-8 pt-3 pb-0" cols="8">
                  <div class="col-md-12 d-flex no-padding pt-4">
                    <v-text-field
                      class="input-control mr-5"
                      prepend-inner-icon="mdi-magnify"
                      @input="searchUsers"
                      light
                      dense
                      outlined
                      placeholder="Search Members"
                    ></v-text-field>
                    <v-btn
                      class="text-gray filter-btn"
                      @click.stop="showFilterSideBar"
                    >
                      <svg
                        width="17"
                        class="mr-2"
                        height="14"
                        viewBox="0 0 17 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 1H16"
                          stroke="#424242"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M2.59766 5H14.403"
                          stroke="#424242"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M6.3125 13H10.6858"
                          stroke="#424242"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M4.19531 9H12.8046"
                          stroke="#424242"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      FILTERS
                    </v-btn>
                  </div>
                </v-col>
                <v-col
                  class="col-xl-7 col-md-5 cols-12 text-end pt-7 pr-6 pb-0"
                  cols="12"
                  align="end"
                >
                  <v-menu offset-y>
                    <template v-slot:activator="{}">
                      <v-btn
                        @click="exportData"
                        class="my-1 mr-5 gray-border-buttons"
                      >
                        <svg
                          class="mr-2"
                          width="15"
                          height="16"
                          viewBox="0 0 15 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.2361 10.7639C14.0329 10.7639 13.838 10.8434 13.6937 10.985C13.5495 11.1267 13.4676 11.319 13.4659 11.5202V12.6764C13.4659 12.9367 13.3615 13.1862 13.1756 13.3703C12.9897 13.5543 12.7376 13.6577 12.4747 13.6577H2.52525C2.26238 13.6577 2.01027 13.5543 1.8244 13.3703C1.63852 13.1862 1.53409 12.9367 1.53409 12.6764V11.5202C1.51671 11.3307 1.42838 11.1544 1.28645 11.0261C1.14452 10.8978 0.959257 10.8266 0.767046 10.8266C0.574834 10.8266 0.389569 10.8978 0.247642 11.0261C0.105715 11.1544 0.0173841 11.3307 0 11.5202V12.6764C0 13.3395 0.266053 13.9753 0.739629 14.4442C1.21321 14.913 1.85551 15.1764 2.52525 15.1764H12.4747C13.1445 15.1764 13.7868 14.913 14.2604 14.4442C14.7339 13.9753 15 13.3395 15 12.6764V11.5202C15 11.3196 14.9195 11.1272 14.7763 10.9854C14.633 10.8436 14.4387 10.7639 14.2361 10.7639Z"
                            fill="#707070"
                          />
                          <path
                            d="M7.49971 0.176392C7.17488 0.176392 6.86336 0.304137 6.63368 0.531525C6.404 0.758913 6.27496 1.06732 6.27496 1.38889V7.05139C6.27496 7.09283 6.25833 7.13257 6.22873 7.16188C6.19913 7.19118 6.15899 7.20764 6.11713 7.20764H4.43784C4.32437 7.21533 4.2151 7.25322 4.12158 7.31731C4.02807 7.3814 3.95378 7.46931 3.90657 7.57175C3.85935 7.6742 3.84097 7.78736 3.85337 7.89929C3.86576 8.01122 3.90847 8.11776 3.97698 8.20764L7.03885 11.6951C7.0979 11.7576 7.16927 11.8074 7.24855 11.8414C7.32782 11.8754 7.4133 11.893 7.49971 11.893C7.58611 11.893 7.6716 11.8754 7.75087 11.8414C7.83014 11.8074 7.90151 11.7576 7.96057 11.6951L11.0224 8.20764C11.0909 8.11776 11.1337 8.01122 11.146 7.89929C11.1584 7.78736 11.1401 7.6742 11.0928 7.57175C11.0456 7.46931 10.9713 7.3814 10.8778 7.31731C10.7843 7.25322 10.675 7.21533 10.5616 7.20764H8.88228C8.84042 7.20764 8.80028 7.19118 8.77068 7.16188C8.74108 7.13257 8.72445 7.09283 8.72445 7.05139V1.38889C8.72445 1.06732 8.59542 0.758913 8.36573 0.531525C8.13605 0.304137 7.82453 0.176392 7.49971 0.176392V0.176392Z"
                            fill="#707070"
                          />
                        </svg>

                        Export</v-btn
                      >
                      <v-btn
                        @click="$router.push('/vendors/members/add-new')"
                        class="my-1 gray-border-buttons"
                      >
                        <svg
                          class="mr-2"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0 7.5H15"
                            stroke="#707070"
                            stroke-width="2"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M7.5 0V15"
                            stroke="#707070"
                            stroke-width="2"
                            stroke-linejoin="round"
                          />
                        </svg>

                        New</v-btn
                      >
                    </template>
                  </v-menu>
                </v-col>
              </v-row>
              <table id="tblData" style="display: none">
                <thead>
                  <tr>
                    <th class="text-left">MEMBER NAME</th>
                    <th class="text-left">CLIENT NAME</th>
                    <th class="text-left">STATUS</th>
                    <th class="text-left">LAST VISIT</th>
                    <th class="text-left">NEXT VISIT</th>
                  </tr>
                </thead>
                <tbody id="remove-hover">
                  <tr
                    v-for="(item, index) in members"
                    :key="index"
                    class="text-gray"
                  >
                    <td>
                      <a v-if="item.firstName" @click="singleUser(item)"
                        >{{ item.firstName }}
                        {{ item.lastName }}
                      </a>
                      <a v-else @click="singleUser(item)">/</a>
                    </td>

                    <td v-html="item && item.client ? item.client : '/'"></td>

                    <td
                      v-if="item && item.status"
                      v-html="
                        item == 'inactive'
                          ? 'In Active'
                          : item
                          ? item.status.charAt(0).toUpperCase() +
                            item.status.slice(1)
                          : '/'
                      "
                    ></td>
                    <td v-else>/</td>

                    <td v-if="item.lastVisit">
                      {{
                        item.lastVisit.charAt(0).toUpperCase() +
                        item.lastVisit.slice(1)
                      }}
                    </td>
                    <td v-else>-</td>
                    <td v-if="item.nextVisit">
                      {{ computedDateFormattedMomentjs(item.nextVisit) }}
                    </td>
                    <td v-else>-</td>
                  </tr>
                </tbody>
              </table>

              <v-simple-table fixed-header height="630px">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">MEMBER NAME</th>
                      <th class="text-left">CLIENT NAME</th>
                      <th class="text-left">STATUS</th>
                      <th class="text-left">LAST VISIT</th>
                      <th class="text-left">NEXT VISIT</th>
                      <th class="text-center actions-th-padding">ACTIONS</th>
                    </tr>
                  </thead>
                  <tbody v-if="members.length > 0" id="remove-hover">
                    <tr
                      v-for="(item, index) in members"
                      :key="index"
                      class="text-gray"
                    >
                      <td>
                        <a v-if="item.firstName" @click="singleUser(item)"
                          >{{ item.firstName }}
                          {{ item.lastName }}
                        </a>
                        <a v-else @click="singleUser(item)">/</a>
                      </td>

                      <td v-html="item && item.client ? item.client : '/'"></td>

                      <td
                        v-if="item && item.status"
                        v-html="
                          item == 'inactive'
                            ? 'In Active'
                            : item
                            ? item.status.charAt(0).toUpperCase() +
                              item.status.slice(1)
                            : '/'
                        "
                      ></td>
                      <td v-else>/</td>

                      <td v-if="item.lastVisit">
                        {{
                          item.lastVisit.charAt(0).toUpperCase() +
                          item.lastVisit.slice(1)
                        }}
                      </td>
                      <td v-else>-</td>
                      <td v-if="item.nextVisit">
                        {{ computedDateFormattedMomentjs(item.nextVisit) }}
                      </td>
                      <td v-else>-</td>
                      <td class="text-center">
                        <v-menu
                          :ref="'menu_' + index"
                          :close-on-content-click="false"
                          :return-value.sync="date"
                          transition="scale-transition"
                          offset-y
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <svg
                              v-bind="attrs"
                              v-on="on"
                              class="pointer"
                              width="25"
                              height="25"
                              viewBox="0 0 25 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M23 3H2C1.44772 3 1 3.44772 1 4V23C1 23.5523 1.44772 24 2 24H23C23.5523 24 24 23.5523 24 23V4C24 3.44772 23.5523 3 23 3Z"
                                stroke="#007713"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M6 1V6"
                                stroke="#007713"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M19 1V6"
                                stroke="#007713"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M1 8H24"
                                stroke="#007713"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M17 15.5C17 15.3674 16.9473 15.2402 16.8536 15.1464C16.7598 15.0527 16.6326 15 16.5 15H14V12.5C14 12.3674 13.9473 12.2402 13.8536 12.1464C13.7598 12.0527 13.6326 12 13.5 12H11.5C11.3674 12 11.2402 12.0527 11.1464 12.1464C11.0527 12.2402 11 12.3674 11 12.5V15H8.5C8.36739 15 8.24021 15.0527 8.14645 15.1464C8.05268 15.2402 8 15.3674 8 15.5V17.5C8 17.6326 8.05268 17.7598 8.14645 17.8536C8.24021 17.9473 8.36739 18 8.5 18H11V20.5C11 20.6326 11.0527 20.7598 11.1464 20.8536C11.2402 20.9473 11.3674 21 11.5 21H13.5C13.6326 21 13.7598 20.9473 13.8536 20.8536C13.9473 20.7598 14 20.6326 14 20.5V18H16.5C16.6326 18 16.7598 17.9473 16.8536 17.8536C16.9473 17.7598 17 17.6326 17 17.5V15.5Z"
                                stroke="#007713"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </template>

                          <div
                            class="
                              col-md-12
                              d-flex
                              justify-space-between
                              white-background
                            "
                          >
                            <div>
                              <h3>Set Next Visit Date</h3>
                            </div>
                            <div
                              class="pointer"
                              @click="closeCalendar('menu_', index)"
                            >
                              x
                            </div>
                          </div>
                          <div class="orange-underline"></div>
                          <v-card class="white-background">
                            <v-date-picker
                              v-model.trim="item.nextVisit"
                              @change="changeNextDate(item)"
                              next-icon="fa-solid fa-caret-right"
                              prev-icon="fa-solid fa-caret-left"
                              no-title
                              scrollable
                            >
                              <v-spacer></v-spacer>
                              <v-btn text color="primary" @click="menu = false">
                                Cancel
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="$refs.menu.save(date)"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-card>
                        </v-menu>

                        <v-menu
                          :ref="'menuCheckIn_' + index"
                          :close-on-content-click="false"
                          :return-value.sync="date"
                          transition="scale-transition"
                          offset-y
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <svg
                              v-bind="attrs"
                              v-on="on"
                              class="mr-6 ml-6 pointer"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.85742 13.7143L10.3717 16.5257C10.4661 16.6035 10.5766 16.6592 10.6952 16.6888C10.8138 16.7185 10.9376 16.7213 11.0574 16.6972C11.1784 16.6745 11.2931 16.626 11.3937 16.555C11.4943 16.484 11.5784 16.3922 11.6403 16.2857L17.1431 6.85718"
                                stroke="#4072B7"
                                stroke-width="1.71429"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M12.0003 23.1429C18.1543 23.1429 23.1431 18.1541 23.1431 12C23.1431 5.846 18.1543 0.857178 12.0003 0.857178C5.84625 0.857178 0.857422 5.846 0.857422 12C0.857422 18.1541 5.84625 23.1429 12.0003 23.1429Z"
                                stroke="#4072B7"
                                stroke-width="1.71429"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </template>

                          <div
                            class="
                              col-md-12
                              d-flex
                              justify-space-between
                              white-background
                            "
                          >
                            <div>
                              <h3>Check In</h3>
                            </div>
                            <div
                              class="pointer"
                              @click="closeCalendar('menuCheckIn_', index)"
                            >
                              x
                            </div>
                          </div>
                          <div class="orange-underline"></div>
                          <div
                            class="col-md-12 white-background"
                            style="padding: 40px"
                          >
                            <v-btn large class="blue-buttons"
                              >Confirm Check In</v-btn
                            >
                          </div>
                        </v-menu>

                        <svg
                          @click="removeItem(item)"
                          width="25"
                          height="25"
                          class="pointer"
                          viewBox="0 0 25 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15.7774 8.50854L8.50879 15.7771"
                            stroke="#F51818"
                            stroke-width="1.71429"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8.50879 8.50854L15.7774 15.7771"
                            stroke="#F51818"
                            stroke-width="1.71429"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M18.1429 1H6.14286C3.30254 1 1 3.30254 1 6.14286V18.1429C1 20.9832 3.30254 23.2857 6.14286 23.2857H18.1429C20.9832 23.2857 23.2857 20.9832 23.2857 18.1429V6.14286C23.2857 3.30254 20.9832 1 18.1429 1Z"
                            stroke="#F51818"
                            stroke-width="1.71429"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else-if="filterItems">
                    <v-col md="12" cols="12">
                      <v-alert
                        border="bottom"
                        colored-border
                        type="warning"
                        elevation="2"
                        class="text-gray"
                      >
                        Sorry! No results found
                      </v-alert>
                    </v-col>
                  </tbody>
                  <tbody v-else>
                    <v-col md="12">
                      There are no members currently in your account.
                    </v-col>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-row>
        </div>
        <v-navigation-drawer
          class="userFilterPosition"
          width="350"
          v-model.trim="drawer"
          absolute
          temporary
        >
          <div class="filter-sideBar">
            <div>
              <div class="row no-padding col-md-12 justify-space-between">
                <h3 class="font pl-3 mt-1">Member Filters</h3>
                <h1 class="pointer mr-3" @click.stop="drawer = !drawer">x</h1>
              </div>
              <v-divider></v-divider>
              <div class="col-md-12">
                <div
                  class="
                    row
                    col-md-12
                    justify-space-between
                    no-padding no-margin
                  "
                >
                  <div
                    class="no-padding font filter-items-font font-weight-bold"
                  >
                    Selected Filter
                  </div>
                  <div
                    class="underline-text pointer text-gray filter-items-font"
                    @click="clearAll"
                  >
                    Clear All
                  </div>
                </div>
              </div>
              <div class="overflow-fitlers-buttons">
                <div class="row mb-1 col-md-12">
                  <v-btn
                    v-for="(item, index) in selectedArray.concat(
                      selectedTypeArray
                    )"
                    :key="index"
                    class="text-center mb-1 ml-2 mr-2 filter-btn"
                    small
                  >
                    <span v-html="item.text"></span>
                    <h5 class="ml-3" @click="removeFilter(item)">x</h5>
                  </v-btn>
                </div>
                <v-divider></v-divider>
                <v-expansion-panels
                  accordion
                  multiple
                  v-model.trim="panel"
                  class="no-shadow no-padding user-expansion-panels"
                >
                  <v-expansion-panel class="no-shadow no-padding mb-3">
                    <v-expansion-panel-header
                      class="
                        font-weight-black
                        change-padding-filter
                        filter-items-font
                      "
                      >Status</v-expansion-panel-header
                    >
                    <v-expansion-panel-content
                      class="no-padding content-filter"
                    >
                      <v-text-field
                        solo
                        class="filter-serach-input mb-4"
                        v-model.trim="status"
                        @input="searchStatus()"
                        dense
                        :hide-details="true"
                        placeholder="Search"
                        append-icon="fa fa-search"
                      ></v-text-field>
                      <v-checkbox
                        label="All"
                        color="primary"
                        v-if="showStatus.all"
                        v-model.trim="filter.status.all"
                        class="no-padding small-text radio-buttons-filters"
                        hide-details
                        :on-icon="'mdi-square'"
                        @click="
                          pushFilter(
                            { text: 'All', value: 'all' },
                            filter.status.all
                          )
                        "
                      >
                      </v-checkbox>
                      <v-checkbox
                        label="Pending"
                        color="primary"
                        v-if="showStatus['pending']"
                        v-model.trim="filter.status.pending"
                        class="no-padding radio-buttons-filters"
                        hide-details
                        :on-icon="'mdi-square'"
                        @click="
                          pushFilter(
                            { text: 'Pending', value: 'pending' },
                            filter.status.pending
                          )
                        "
                      >
                      </v-checkbox>
                      <v-checkbox
                        label="Active"
                        color="primary"
                        v-if="showStatus.active"
                        :on-icon="'mdi-square'"
                        v-model.trim="filter.status.active"
                        class="no-padding radio-buttons-filters"
                        hide-details
                        @click="
                          pushFilter(
                            { text: 'Active', value: 'active' },
                            filter.status.active
                          )
                        "
                      >
                      </v-checkbox>
                      <v-checkbox
                        label="In Active"
                        color="primary"
                        v-if="showStatus['inactive']"
                        v-model.trim="filter.status.inactive"
                        :on-icon="'mdi-square'"
                        class="no-padding radio-buttons-filters"
                        hide-details
                        @click="
                          pushFilter(
                            { text: 'In Active', value: 'inactive' },
                            filter.status.inactive
                          )
                        "
                      >
                      </v-checkbox>
                      <v-checkbox
                        label="Expired"
                        color="primary"
                        v-if="showStatus['expired']"
                        v-model.trim="filter.status.expired"
                        :on-icon="'mdi-square'"
                        class="no-padding radio-buttons-filters"
                        hide-details
                        @click="
                          pushFilter(
                            { text: 'Expired', value: 'expired' },
                            filter.status.expired
                          )
                        "
                      >
                      </v-checkbox>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel class="no-shadow no-padding">
                    <v-expansion-panel-header
                      class="
                        font-weight-black
                        change-padding-filter
                        filter-items-font
                        mb-0
                        pb-0
                      "
                      >Client</v-expansion-panel-header
                    >
                    <v-expansion-panel-content
                      class="no-padding content-filter"
                    >
                      <v-text-field
                        solo
                        class="filter-serach-input no-padding mb-4"
                        v-model.trim="type"
                        dense
                        :hide-details="true"
                        @input="searchType()"
                        placeholder="Search"
                        append-icon="fa fa-search"
                      ></v-text-field>
                      <v-checkbox
                        label="All"
                        color="primary"
                        v-if="showType.all"
                        :on-icon="'mdi-square'"
                        v-model.trim="filter.type.all"
                        class="no-padding radio-buttons-filters"
                        hide-details
                        @click="
                          pushtoTypeFilter(
                            { text: 'All', value: 'allTypes' },
                            filter.type.all
                          )
                        "
                      >
                      </v-checkbox>
                      <v-checkbox
                        label="7-Eleven"
                        color="primary"
                        v-if="showType.eleven"
                        v-model.trim="filter.type.eleven"
                        :on-icon="'mdi-square'"
                        class="no-padding radio-buttons-filters"
                        hide-details
                        @click="
                          pushtoTypeFilter(
                            { text: '7-Eleven', value: 'eleven' },
                            filter.type.eleven
                          )
                        "
                      >
                      </v-checkbox>
                      <v-checkbox
                        label="Boeing"
                        color="primary"
                        :on-icon="'mdi-square'"
                        v-if="showType['boeing']"
                        v-model.trim="filter.type.boeing"
                        class="no-padding radio-buttons-filters"
                        hide-details
                        @click="
                          pushtoTypeFilter(
                            { text: 'Boeing', value: 'boeing' },
                            filter.type.boeing
                          )
                        "
                      >
                      </v-checkbox>
                      <v-checkbox
                        label="Catepillar"
                        color="primary"
                        :on-icon="'mdi-square'"
                        v-if="showType['catepillar']"
                        v-model.trim="filter.type.catepillar"
                        class="no-padding radio-buttons-filters"
                        hide-details
                        @click="
                          pushtoTypeFilter(
                            { text: 'Catepillar', value: 'catepillar' },
                            filter.type.catepillar
                          )
                        "
                      >
                      </v-checkbox>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </div>
            <div>
              <v-divider></v-divider>
              <v-btn
                class="blue-buttons col-md-12 mt-3 mb-md-3 mb-xl-0"
                @click="filterUsers"
                >View Result</v-btn
              >
            </div>
          </div>
        </v-navigation-drawer>
      </v-sheet>
      <removeDialog
        ref="dialog_delete"
        v-on:confirm="confirmRemove"
      ></removeDialog>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import api from "../../../../services/api";
import { mapGetters } from "vuex";
import RemoveDialog from "../../../TablesTemplate/RemoveDialog.vue";
import EmployerSideBarVue from "../../Employer/Layout/EmployerSideBar.vue";
import moment from "moment";
export default {
  components: { RemoveDialog },
  data() {
    return {
      items: [],
      members: [],
      overlay: false,
      display: true,
      filterArray: [],
      windowWidth: window.innerWidth,
      noResultMessage: false,
      drawer: null,
      date: "",
      showStatus: {
        all: true,
        active: true,
        pending: true,
        expired: true,
        inactive: true,
      },
      showType: {
        all: true,
        eleven: true,
        boeing: true,
        catepillar: true,
      },
      menu: false,
      menuCheckIn: false,
      userStatus: [
        {
          text: "ALL",
          value: "all",
        },

        {
          text: "Pending",
          value: "pending",
        },
        {
          text: "Active",
          value: "active",
        },
        {
          text: "Expired",
          value: "expired",
        },
      ],
      userType: [
        {
          text: "ALL",
          value: "all",
        },
        {
          text: "7-Eleven",
          value: "7-Eleven",
        },
        {
          text: "Boeing",
          value: "boeing",
        },
        {
          text: "Catepillar",
          value: "Catepillar",
        },
      ],
      selectedArray: [],
      selectedTypeArray: [],
      getUsers: [],
      panel: [0, 1],
      filterUse: false,
      type: "",
      status: "",
      statusArray: [],
      typeArray: [],
      filter: {
        status: {
          all: false,
          active: false,
          inactive: false,
          invited: false,
          join: false,
        },
        type: {
          all: false,
          eleven: false,
          boeing: false,
          catepillar: false,
        },
      },
      wizardData: {
        steps: 1,
      },
      dialog: false,
      zIndex: 999,
      absolute: true,
      drawer: false,
      sickVisit: false,
      paymentDialog: false,
      proccessPaymentDialog: false,
      sendPaymentLinkDialog: false,
      checkNowDialog: false,
      date: "",
      showOverlay: false,
      filterItems: false,
      itemToRemove: null,
      clientId: "",
    };
  },
  computed: {
    ...mapGetters(["showRoutePathForFilter", "getTableArray"]),

    routeParams() {
      return this.$route.params.name;
    },
    singleEmp() {
      let emp = "";
      if (localStorage.getItem("employer")) {
        emp = JSON.parse(localStorage.getItem("employer"));
      }
      return emp;
    },
    arrayInFilters() {
      return this.allUsers;
    },
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
  },
  created() {
    this.getData("all");

    // this.members = this.getTableArray;
    this.statusArray = this.userStatus;
    this.typeArray = this.userType;
    this.selectedArray = [];

    this.getData();
  },
  watch: {
    showRoutePathForFilter: {
      handler(newValue, oldValue) {
        this.drawer = this.showRoutePathForFilter;
      },
      deep: true,
    },
    drawer: {
      handler(newValue, oldValue) {
        this.$store.commit("setNavItemFilter", this.drawer);
      },
      deep: true,
    },
  },
  methods: {
    exportData() {
      var elt = document.getElementById("tblData");
      var downloadLink;
      var dataType = "application/vnd.ms-excel";
      var tableSelect = elt;
      var tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

      // Specify file name
      let filename = "excel_data.xls";

      // Create download link element
      downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        var blob = new Blob(["\ufeff", tableHTML], {
          type: dataType,
        });
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = "data:" + dataType + ", " + tableHTML;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
    },
    computedDateFormattedMomentjs(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    getData() {
      api()
        .get("/vendors/members")
        .then((res) => {
          this.members = res.data;
          this.filterArray = res.data;
        });
    },
    concatArrays() {
      this.members = this.getTableArray;
    },

    closeCalendar(type, index) {
      let str = type + index;
      this.$refs[str][0].isActive = false;
    },
    showFilterSideBar() {
      this.drawer = !this.drawer;

      if (this.drawer) {
        this.$store.commit("setNavItemFilter", this.drawer);
      } else {
        this.$store.commit("setNavItemFilter", false);
      }
    },
    next(modal) {
      this.wizardData.steps++;
      this.paymentDialog = false;
      this.proccessPaymentDialog = false;
      this.sendPaymentLinkDialog = false;
      this.checkNowDialog = false;
      this.dialog = false;
      this.showOverlay = true;
      this[modal] = true;
    },
    closeModal() {
      this.wizardData.steps = 1;
      this.showOverlay = false;
      this.paymentDialog = false;
      this.proccessPaymentDialog = false;
      this.sendPaymentLinkDialog = false;
      this.checkNowDialog = false;
      this.dialog = false;
    },
    pushtoTypeFilter(item, checked) {
      if (checked) {
        if (item.value == "allTypes") {
          this.selectedTypeArray = [];
          this.filter.type = {
            all: true,
            eleven: true,
            boeing: true,
            catepillar: true,
          };
          this.selectedTypeArray.push({ text: "All", value: "allTypes" });
        } else {
          this.selectedTypeArray.push(item);
        }
      } else {
        if (item.value == "allTypes" || this.filter.type.all) {
          this.filter.type = {
            all: false,
            eleven: false,
            boeing: false,
            catepillar: false,
          };
          this.selectedTypeArray = [];
        } else {
          this.selectedTypeArray.forEach((el, index) => {
            if (el.value == item.value) {
              this.selectedTypeArray.splice(index, 1);
            }
          });
        }
      }
    },
    pushFilter(item, checked) {
      if (checked) {
        if (item.value == "all") {
          this.selectedArray = [];
          this.filter.status = {
            all: true,
            active: true,
            inactive: true,
            pending: true,
            expired: true,
          };
          this.selectedArray.push({ text: "All", value: "all" });
        } else {
          this.selectedArray.push(item);
        }
      } else {
        if (item.value == "all" || this.filter.status.all) {
          this.filter.status = {
            all: false,
            active: false,
            pending: false,
            expired: false,
            inactive: false,
          };
          this.selectedArray = [];
        } else {
          this.selectedArray.forEach((el, index) => {
            if (el.value == item.value) {
              this.selectedArray.splice(index, 1);
            }
          });
        }
      }
    },

    searchStatus() {
      for (let key in this.showStatus) {
        if (key.toLowerCase().includes(this.status.toLowerCase())) {
          this.showStatus[key] = true;
        } else {
          this.showStatus[key] = false;
        }
      }
    },
    searchType() {
      for (let key in this.showType) {
        if (key.toLowerCase().includes(this.type.toLowerCase())) {
          this.showType[key] = true;
        } else {
          this.showType[key] = false;
        }
      }
    },
    filterUsers() {
      api()
        .get("/vendors/members")
        .then((res) => {
          this.members = res.data;
          this.filterArray = res.data;

          if (!this.filter.status && !this.filter.type) {
            this.getData();
          } else {
            let params = "";
            for (let key in this.filter.status) {
              if (this.filter.status[key]) {
                params += `${key},`;
              }
            }
            if (params && !this.filter.status.all) {
              this.members = this.filterArray.filter(function (el) {
                return params.toLowerCase().includes(el.status.toLowerCase());
              });

              if (this.members.length < 1) {
                this.filterItems = true;
              }
            }

            this.filterUse = true;
            this.drawer = false;
          }
        });
    },
    clearAll() {
      this.filter.status = {
        all: false,
        active: false,
        pending: false,
        expired: false,
        inactive: false,
      };
      this.filter.type = {
        all: false,
        eleven: false,
        boeing: false,
        catepillar: false,
      };

      this.selectedArray = [];
      this.selectedTypeArray = [];
      this.getData("all");
    },

    removeFilter(item) {
      if (item.value == "allTypes") {
        this.selectedTypeArray = [];
        this.filter.type = {
          all: false,
          user: false,
          admin: false,
        };
      } else if (this.filter.status[item.value]) {
        this.filter.status[item.value] = false;
        this.selectedArray.splice(this.selectedArray.indexOf(item), 1);
      }

      if (item.value == "all") {
        this.selectedArray = [];
        this.filter.status = {
          all: false,
          active: false,
          pending: false,
          expired: false,
          inactive: false,
        };
      } else if (this.filter.type[item.value]) {
        this.filter.type[item.value] = false;
        this.selectedTypeArray.splice(this.selectedTypeArray.indexOf(item), 1);
      }
    },
    changeNextDate(item) {
      let data = {
        nextVisit: item.nextVisit,
      };

      api()
        .put("/vendors/members/" + item._id, data)
        .then((res) => {
          this.getData();
        });
    },
    singleUser(item) {
      api()
        .get("/vendors/members/" + item._id)
        .then((res) => {
          this.$store.commit("storeSingleItemDPC", res.data);
          this.$router.push(`/vendors/members/member`);
        });
    },
    confirmRemove() {
      this.members.splice(this.itemToRemove, 1);
      this.$refs.dialog_delete.closePopUp();
    },
    removeItem(item) {
      this.itemToRemove = item;
      this.$refs.dialog_delete.openPopUp(
        "Are you sure that you want to delete this Member"
      );
    },
    searchUsers(val) {
      this.members = this.filterArray.filter((el) => {
        if (el.member.firstName && el.member.lastName) {
          let fullName = el.member.firstName + " " + el.member.lastName;
          let reversefullName = el.member.lastName + " " + el.member.firstName;
          if (fullName.toString().toLowerCase().includes(val.toLowerCase())) {
            return el;
          } else if (
            reversefullName.toString().toLowerCase().includes(val.toLowerCase())
          ) {
            return el;
          }
        } else if (
          el.member &&
          el.member.firstName &&
          el.member.firstName.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.member.firstName.toLowerCase().includes(val.toLowerCase());
        } else if (
          el.member &&
          el.member.lastName.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.member.lastName.toLowerCase().includes(val.toLowerCase());
        } else if (
          el.member.client &&
          el.member.client.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.member.client.toLowerCase().includes(val.toLowerCase());
        }
      });
      if (this.members.length < 1) {
        this.filterItems = true;
      }
    },
  },
};
</script>
<style>
table th {
  font-weight: 700, bold;
  font-size: 14px !important ;
  line-height: 25px !important;
  letter-spacing: 0.2px !important;
  color: #9fa2b4 !important;
}
.actions-th-padding {
  padding-right: 85px !important;
}
#remove-hover tr:hover {
  background-color: transparent !important;
}
.gray-border-buttons {
  border: 1px solid gray;
  background: white !important;
  border-radius: 10px;
}
</style>
