const state ={
    contract : null,
}
const getters = {
    getContractGetter(state){
        return state.contract
      },
}
const mutations = {
    setSingleContract(state, contract){
        state.contract = contract
      },
}

export default{
    state,
    getters,
    mutations,
}