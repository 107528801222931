<template>
    <div class="no-padding no-margin">
        <AddUser v-if="path == '/provider-advisor/settings/users/add-new'"></AddUser>
        <InviteUser v-if="path == '/provider-advisor/settings/users/invite-user'"></InviteUser>
        <User v-if="path == '/provider-advisor/settings/users/user'"></User>
        <Users v-if="path == '/provider-advisor/settings/users'"></Users>
        <Account v-if="path == '/provider-advisor/settings/account'"></Account>
        <Settings v-if="path == '/provider-advisor/settings'"></Settings>
    </div>
</template>
<script>
import AddUser from './AddUser.vue'
import InviteUser from './InviteUser.vue'
import User from './UsersCrud.vue'
import Users from './UsersTable.vue'
import Account from './AccountForm.vue'
import Settings from './Settings.vue'
export default{
    components:{
        AddUser,
        InviteUser,
        User,
        Users,
        Account,
        Settings
    },
    computed:{
        path(){
            return this.$route.path
        }
      
    },
   
}
</script>