const state = {
  singlePackage: {
    _id: 0,
    name: null,
    description: null,
    status: "active",
    solutions: [],
  },
  tpaSingleSolution: {},
  clientSolutionAcceptance: {
    acceptedBy: "tpa",
    maxRate: "",
    tpaFee: "",
    firstName: "",
    lastName: "",
    email: "",
  },
  clientSolutions: [],
  clientName: "",
  accountLogo: "/newapalylogoblue.png",
  definitionOfService: "",
  vendorRate: "",
  tpaFee: "",
  vendorContract: "",
  noVendorContract: false,
  acceptByTpa: "",
  acceptByClient: {},
  solutionMaxRate: "",
  tpaAdminFee: "",
  solutionAcceptByTpa: "",
  solutionAcceptByClient: {},
  mainLogo: "",
};

const getters = {
  getMainLogo(state){
    return state.mainLogo;
  },
  getSinglePackage(state) {
    return state.singlePackage;
  },
  getTpaSingleSolution(state) {
    return state.tpaSingleSolution;
  },
  getClientSolutionAcceptance(state) {
    return state.clientSolutionAcceptance;
  },
  getDefinitionOfService(state) {
    return state.definitionOfService;
  },
  getVendorRate(state) {
    return state.vendorRate;
  },
  getTpaFee(state) {
    return state.tpaFee;
  },
  getVendorContract(state) {
    return state.vendorContract;
  },
  getNoVendorContract(state) {
    return state.noVendorContract;
  },
  getAcceptByTpa(state) {
    return state.acceptByTpa;
  },
  getAcceptByClient(state) {
    return state.acceptByClient;
  },
  getSolutionMaxRate(state) {
    return state.solutionMaxRate;
  },
  getTpaAdminFee(state) {
    return state.tpaAdminFee;
  },
  getSolutionAcceptByTpa(state) {
    return state.solutionAcceptByTpa;
  },
  getSolutionAcceptByClient(state) {
    return state.solutionAcceptByClient;
  },
};

const mutations = {
  updateLogo(state, newLogo) {
    state.accountLogo = newLogo;
  },
  setSinglePackage(state, data) {
    let tmpData = {
      _id: 0,
      name: null,
      description: null,
      status: "active",
      solutions: [],
    };
    if (data != null) {
      tmpData = data;
    }
    state.singlePackage = tmpData;
  },
  setTpaSingleSolution(state, data) {
    state.tpaSingleSolution = data;
  },
  setClientSolutionAcceptance(state, data) {
    state.clientSolutionAcceptance = data;
  },
  setClientSolutions(state, data) {
    state.clientSolutions = data;
  },
  setClientName(state, data) {
    state.clientName = data;
  },
  setDefinitionOfService(state, data) {
    state.definitionOfService = data;
  },
  setVendorRate(state, data) {
    state.vendorRate = data;
  },
  setTpaFee(state, data) {
    state.tpaFee = data;
  },
  setVendorContract(state, data) {
    state.vendorContract = data;
  },
  setNoVendorContract(state, data) {
    state.noVendorContract = data;
  },
  setAcceptByTpa(state, data) {
    state.acceptByTpa = data;
  },
  setAcceptByClient(state, data) {
    state.acceptByClient = data;
  },
  setSolutionMaxRate(state, data) {
    state.solutionMaxRate = data;
  },
  setTpaAdminFee(state, data) {
    state.tpaAdminFee = data;
  },
  setSolutionAcceptByTpa(state, data) {
    state.solutionAcceptByTpa = data;
  },
  setSolutionAcceptByClient(state, data) {
    state.solutionAcceptByClient = data;
  },
  setMainLogo(state,data){
     state.mainLogo = data;
  },
};
const actions = {
  setLogo({ commit }, newLogo) {
    commit("updateLogo", newLogo);
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
