<template>
  <div :class="windowWidth ? 'mainSideBarDiv' : 'mainSideBarMobile'">
    <v-navigation-drawer
      v-if="windowWidth"
      :width="!mini ? '800' : '69'"
      permanent
      class="white-sidebar"
    >
      <div>
        <v-divider></v-divider>
        <v-list-item
          two-line
          class="mt-3"
          :class="
            watchActiveItem == 'home' ? 'pointer active' : 'pointer inactive'
          "
          @click="activeItem('home')"
        >
          <v-list-item-icon
            class="list-item-icon no-padding"
            :class="!mini ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-3 ' : 'ml-4 mt-3'"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.7319 6.49L9.00356 0.659668L1.26809 6.49C1.10817 6.63755 1.01201 6.84157 1 7.05881V15.9622C1.00056 16.1464 1.07402 16.323 1.20432 16.4532C1.33462 16.5835 1.51119 16.657 1.69547 16.6575H6.33333V12.9268C6.33333 12.2197 6.61429 11.5415 7.11438 11.0415C7.61448 10.5414 8.29276 10.2605 9 10.2605C9.70724 10.2605 10.3855 10.5414 10.8856 11.0415C11.3857 11.5415 11.6667 12.2197 11.6667 12.9268V16.6597H16.3038C16.4882 16.6591 16.6648 16.5857 16.7952 16.4554C16.9256 16.3252 16.9993 16.1486 17 15.9643V7.05881C16.9877 6.84165 16.8916 6.63773 16.7319 6.49Z"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title
              class="
                d-flex
                align-center
                font-quicksand-medium-12
                text-gray-darker
              "
            >
              Home
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
      <div>
        <v-divider></v-divider>
        <v-list-item two-line v-if="!mini" class="mb-2">
          <span class="orange--text mt-1 ml-1" style="font-size: 9px">
            Powered by:
          </span>

          <v-list-item-content
            class="no-padding pointer justify-center mt-2 mb-2"
          >
            <v-list-item-title
              class="d-flex mb-3 align-center text-center text-gray-darker"
            >
              <img src="/apalyhealthlogo.png" alt="" class="mt-1 ml-1" />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line v-else class="mt-4">
          <v-list-item-content class="no-padding pointer">
            <v-list-item-title
              class="d-flex mb-3 align-center text-center text-gray-darker"
            >
              <img
                src="/apaly1.png"
                class="ml-1"
                width="95"
                height="45"
                alt=""
              />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-navigation-drawer>
    <!-- Mobile -->
    <v-navigation-drawer v-else permanent class="white-sidebar-onmobile mt-3">
      <div>
        <v-divider></v-divider>
        <v-list-item
          two-line
          class="mt-3"
          :class="
            watchActiveItem == 'home' ? 'pointer active' : 'pointer inactive'
          "
          @click="activeItem('home')"
        >
          <v-list-item-icon class="list-item-icon no-padding ml-4 ml-md-1 mt-3">
            <svg
              width="16"
              height="16"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.7319 6.49L9.00356 0.659668L1.26809 6.49C1.10817 6.63755 1.01201 6.84157 1 7.05881V15.9622C1.00056 16.1464 1.07402 16.323 1.20432 16.4532C1.33462 16.5835 1.51119 16.657 1.69547 16.6575H6.33333V12.9268C6.33333 12.2197 6.61429 11.5415 7.11438 11.0415C7.61448 10.5414 8.29276 10.2605 9 10.2605C9.70724 10.2605 10.3855 10.5414 10.8856 11.0415C11.3857 11.5415 11.6667 12.2197 11.6667 12.9268V16.6597H16.3038C16.4882 16.6591 16.6648 16.5857 16.7952 16.4554C16.9256 16.3252 16.9993 16.1486 17 15.9643V7.05881C16.9877 6.84165 16.8916 6.63773 16.7319 6.49Z"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title
              class="
                d-flex
                align-center
                font-quicksand-medium-12
                text-gray-darker
              "
            >
              Home
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script>
export default {
  name: "Tables",
  data() {
    return {
      miniVersion: true,
      item: "home",
      items: [
        {
          icon: "fas fa-home",
          title: "Home",
        },
        {
          icon: "fas fa-users",
          title: "Clients",
        },
        {
          icon: "fas fa-cog",
          title: "Settings",
        },
      ],
      route: this.$route.path,
    };
  },
  props: {
    windowWidth: {
      type: Boolean,
    },
    mini: {
      type: Boolean,
    },
  },

  computed: {
    routeParams() {
      return this.$route.params.name;
    },
    path() {
      return this.$route.path.replace(/%20/g, " ");
    },
    watchActiveItem() {
      this.item = "home";
      return this.item;
    },
  },
  created() {},
  methods: {
    activeItem(tab) {
      if (this.$route.path != `/employers-providers`) {
        this.$router.push(`/employers-providers`);
      }
      if (!this.windowWidth) {
        this.$emit("closeSideBar");
      }
    },
  },
};
</script>
<style lang="scss">
.active,
.inactive {
  min-height: 10px !important;
  max-height: 45px;
}
.active-settings {
  color: #109cf1 !important;
}
.icons-margin-top {
  margin-top: 20px;
}
.active-settings > .v-list-item__content > .v-list-item__title {
  color: #109cf1 !important;
}
.active-settings > .v-list-item > .v-list-item__icon > svg > path {
  fill: none;
  stroke: #109cf1 !important;
}
.theme--light.v-list-item:hover::before {
  opacity: 0 !important;
}
.inactive:hover {
  background: white !important;

  .v-list-item__content {
    .v-list-item__title {
      color: #109cf1 !important;
    }
  }
  .v-list-item__icon {
    svg {
      color: #109cf1 !important;
      path {
        color: #109cf1 !important;
        stroke: #109cf1 !important;
      }
    }
  }
}
</style>
