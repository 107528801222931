<template>
    <div class="add-client-form">
        <div class="row justify-space-between">
            <h3 class="font">Prior Authorization Request Details</h3>
            <h3 class="font">Prior Auhorization Status : <span class="red--text">DENIED</span></h3>
        </div>
     <v-row>
           <v-col md='12'>
              <h5 class="text-gray font underline-text">Member Information</h5>
           </v-col>
           <v-col md="10">
                <v-simple-table dense>
                   <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-left text-gray font">
                                First Name
                            </th>
                            <th class="text-left text-gray font">
                            Last Name
                            </th>
                            <th class="text-left text-gray font">
                                Sex
                            </th>
                            <th class="text-left text-gray font">
                                Status on Plan
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="font">
                                    <a >Valarie</a>
                                </td>
                                <td class="font">
                                    <a >Vaughn</a>
                                </td>
                                <td>
                                    Female
                                </td>
                                <td class="font">
                                    Active
                                </td>

                            
                            </tr>
                        </tbody>
                     </template>
                </v-simple-table>

           </v-col>
            <v-col md='12'>
              <h5 class="text-gray font underline-text">Provider Information</h5>
           </v-col>
            <v-col md="10">
                <v-simple-table dense>
                   <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left text-gray font">
                                Organization Name
                            </th>
                            <th class="text-left text-gray font">
                                Type
                            </th>
                            <th class="text-left text-gray font">
                                Quality Rating
                            </th>
                            <th class="text-left text-gray font">
                                City
                            </th>
                            <th class="text-left text-gray font">
                                Status
                            </th>
                        
                        </tr>
                     </thead>
                     <tbody>
                            <tr>
                                <td class="font">
                                    <a >Manatee Memorial Hospital</a>
                                </td>
                                <td class="font">
                                    Hospital
                                </td>
                                <td>
                                    <v-rating
                                        v-model.trim="rating"
                                        color="yellow darken-3"
                                        background-color="grey darken-1"
                                        empty-icon="$ratingFull"
                                        half-increments
                                    
                                    ></v-rating>
                                </td>
                                 <td class="font">
                                    Tampa
                                </td>
                                <td class="font green-text">
                                    Matched
                                </td>
                            </tr>
                        </tbody>
                     </template>
                 </v-simple-table>

           </v-col>
           <v-row class="mt-4">
            <div class="col-md-12">
                <h4 class="font text-gray">Procedure Information</h4>
            </div>
        </v-row>
        <v-row>
            <div class="col-md-2">
                <label class="font text-gray" for="">Procedure Code Type <i class="fas fa-question-circle  yellow-icons"></i></label>
                <v-select solo></v-select>
            </div>
            <div class="col-md-2">
                <label class="font text-gray" for="">Procedure Code  <i class="fas fa-question-circle  yellow-icons"></i></label>
                <v-select solo></v-select>
            </div>
            <div class="col-md-5">
                <label class="font text-gray" for="">Procedure Description  <i class="fas fa-question-circle  yellow-icons"></i></label>
                <v-select solo></v-select>
            </div>
            <div class="col-md-3">
                <label class="font text-gray" for="">East Procedure Cost (USD) <i class="fas fa-question-circle  yellow-icons"></i></label>
                <v-text-field solo></v-text-field>
            </div>
        </v-row>
             <v-col md="12" cols="12">
                <h5 class="text-gray font underline-text">Comments</h5>
             </v-col> 
             <v-col md="12">
                 <h5 class="font" cols="12">
                     This is a comment entered by the provider requesting the Prior Authorization.
                 </h5>
             </v-col>
           
            <v-col md='12'>
              <h5 class="text-gray font underline-text">Procedure Prerequisite Requirements</h5>
           </v-col>
            <v-col md="12">
                <v-simple-table dense>
                   <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left text-gray font">
                                Name
                            </th>
                            <th class="text-left text-gray font">
                                Category
                            </th>
                            <th class="text-left text-gray font">
                               Code
                            </th>
                            <th class="text-left text-gray font">
                                Description
                            </th>
                            <th class="text-left text-gray font ">
                               Prerequisite Met?
                            </th>
                        </tr>
                     </thead>
                     <tbody>
                            <tr>
                                <td class="font">
                                    MRI Knee
                                </td>
                                <td class="font">
                                    CPT
                                </td>
                                <td>
                                   G187
                                </td>
                                 <td class="font">
                                    CT Imaging of Knee
                                </td>
                                <td class="font ">
                                   <v-checkbox></v-checkbox> 
                                   <span>Prerequisit Requirement Met</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="font">
                                    14 Days of NSAIDs
                                </td>
                                <td class="font">
                                    Medication
                                </td>
                                <td>
                                   None
                                </td>
                                 <td class="font ">
                                  Pretreatment with anti-inflammatory
                                </td>
                                <td class="font">
                                   
                                     <v-checkbox></v-checkbox> 
                                     <span>Prerequisit Requirement Met</span>
                                   
                                </td>
                            </tr>
                        </tbody>
                     </template>
                 </v-simple-table>

           </v-col>
         
            
        </v-row>
        
        <v-row justify="end">
        
            <v-btn class="blue-buttons mr-4" @click="$router.push(`/provider-advisor/prior-auth`)">Cancel Request</v-btn>
            <v-btn class="blue-buttons mr-4" @click="$router.push(`/provider-advisor/prior-auth`)">Close</v-btn>
            <v-btn class="blue-buttons">Ubdate & ReSubmit</v-btn>
        </v-row>
     
       
    </div>
</template>
<script>
export default{
    data(){
        return{
            dialog:false,
            dialog1:false,
        }
    },
    computed:{
       routeParams(){
          let parsed = this.$route.params.name.replace(/ /g, '%20')
          return parsed
      }
    }
}
</script>
<style>
.set-padding{
    padding-top: 100px !important;
    padding-bottom: 100px !important;

}
</style>