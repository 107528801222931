
import api from '../../services/api'

    const state = {
        provider: null,
        providerName:'',
        members: {}
    }
    
    const getters = {
      getProviderGetter(state){
        return state.provider
      },
      getProviderName(){
        return state.providerName
      }
    }
    
    const mutations = {
        setSingleProvider(state, provider){
            state.provider = provider
        },
        setProviderName(state,name){
           state.providerName = name
        },
        setMembers(data) {
            state.members = data
        }
    }
    
    const actions = {
        getUsers(query) {
            api().get('/dpcproviders/members' + query)
            .then((res) => {
                if (res?.data) {
                    context.commit("setMembers", res.data);
                    console.log("setMembers: ", res.data);
                }
            })
            .catch((err) => {
                console.log("Error - setMembers:", err);
            });
        }
    }
    
    export default {
        state,
        getters,
        mutations,
        actions
    }
  