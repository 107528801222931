<template>
    <div>
        <EmpPriorAuth v-if="route=='/employer/prior-auth'"></EmpPriorAuth>
        <EmpPriorAuthDetail v-if="route=='/employer/prior-auth/detail'"></EmpPriorAuthDetail>
        <EmpPriorAuthRules v-if="route=='/employer/prior-auth/rules'"></EmpPriorAuthRules>
        <EmpPriorAuthAddRule v-if="route=='/employer/prior-auth/add-rule'"></EmpPriorAuthAddRule>
        <EmpPriorAuthRuleDetail  v-if="route=='/employer/prior-auth/rule-detail'"></EmpPriorAuthRuleDetail>
    </div>
</template>

<script>
    import EmpPriorAuth from "./EmpPriorAuth.vue";
    import EmpPriorAuthDetail from "./PriorAuthDetail/EmpPriorAuthDetail.vue";
    import EmpPriorAuthRules from "./PriorAuthRules/EmpPriorAuthRules.vue";
    import EmpPriorAuthAddRule from "./PriorAuthAddRule/EmpPriorAuthAddRule.vue";
    import EmpPriorAuthRuleDetail from "./PriorAuthRuleDetail/EmpPriorAuthRuleDetail";




    export default {
        name:'EmployerMembersTemplate',
        components: {
            EmpPriorAuth,
            EmpPriorAuthDetail,
            EmpPriorAuthRules,
            EmpPriorAuthAddRule,
            EmpPriorAuthRuleDetail

        },
        computed:{
            route(){
                return this.$route.path
            }
        }
    }
</script>