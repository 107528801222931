<template>
 <v-row class="add-client-form no-padding">
  <div class="col-md-12 form-box">
    <div class="row">
        <div class="col-lg-8 col-md-9 col-sm-12">
            <h3 class="form-title" >
                <span class="form-title font"> Invite Providers </span>
                
            </h3>
        </div>
          <!-- <div class="col-md-4 col-sm-12 text-end" align="end">
          <v-btn class="blue-buttons"  >Copy and Email Invite Link</v-btn>
        </div> -->
    </div>
    <form>
        <div class="row">
            <div class="col-md-4">
               <label class="font text-gray" for="">Email (required)</label>
                <v-text-field
                required
                solo
                ></v-text-field>
           </div>
            <div class="col-md-4">
               <label class="font text-gray" for="">First Name (optional)</label>
                <v-text-field
                required
                solo
                ></v-text-field>
           </div>
            <div class="col-md-4">
               <label class="font text-gray" for="">Last Name (optional)</label>
                <v-text-field
                required
                solo
                ></v-text-field>
           </div>
        </div>
          <v-divider></v-divider>
           <div class="row">
                <div class="col-md-4">
               <label class="font text-gray" for="">Email (required)</label>
                <v-text-field
                required
                solo
                ></v-text-field>
           </div>
            <div class="col-md-4">
               <label class="font text-gray" for="">First Name (optional)</label>
                <v-text-field
                required
                solo
                ></v-text-field>
           </div>
            <div class="col-md-4">
               <label class="font text-gray" for="">Last Name (optional)</label>
                <v-text-field
                required
                solo
                ></v-text-field>
           </div>
       </div>
        <v-divider></v-divider>
       <div class="row">
           <div class="col-md-4">
               <label class="font text-gray" for="">Email (required)</label>
                <v-text-field
                required
                solo
                ></v-text-field>
           </div>
            <div class="col-md-4">
               <label class="font text-gray" for="">First Name (optional)</label>
                <v-text-field
                required
                solo
                ></v-text-field>
           </div>
            <div class="col-md-4">
               <label class="font text-gray" for="">Last Name (optional)</label>
                <v-text-field
                required
                solo
                ></v-text-field>
           </div>
       </div>
       <v-divider></v-divider>
       <div class="row mt-2">
           <div class="col-md-12 row justify-space-between">
            <a href="" class="col-lg-10 col-md-10">Add Another Row</a>
            <v-btn class="blue-buttons  " v-if="$route.path.includes('providers')" @click="goToTable()">
               Invite Providers
            </v-btn>
             <v-btn class="blue-buttons  " v-else @click="goToTableAff()">
               Invite Affilates
            </v-btn>
           </div> 
       </div>
      </form>
    </div>
 </v-row>
</template>
<script>
 export default{
    computed:{
        singleMember(){
          return this.$store.getters.getMemberGetter;
        },
         singleClient(){
          return this.$store.getters.getClientGetter;
        },
        
    },
    methods:{
     goToTable(){
         this.$router.push('/member/providers')
    },
    goToTableAff(){
          this.$router.push('/member/providers')
      }
   }
 }
</script>