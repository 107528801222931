<template>
  <div class="">
    <div v-if="!isChildren" class="row-height">
      <Header
        :title="'Users'"
        :searchInput="true"
        :exportButton="true"
        :placeholder="'Search Users'"
        :newButton="true"
        @invite="invite"
        @add="add"
        @searchInput="searchInput"
      ></Header>
      <div class="mt-3">
        <v-simple-table fixed-header height="630px">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">First Name</th>
                <th class="text-left">Last Name</th>
                <th class="text-left">Email</th>
                <th class="text-left">Title</th>
                <th class="text-left">City</th>
                <th class="text-left">State</th>
              </tr>
            </thead>
            <tbody v-if="allUsers.length > 0">
              <tr v-for="item in allUsers" :key="item.name" class="text-gray">
                <td v-if="item.firstName">
                  <a @click="singleUser(item)">{{ item.firstName }} </a>
                </td>
                <td v-else><a @click="singleUser(item)">/ </a></td>
                <td v-if="item.lastName">
                  <a @click="singleUser(item)">{{ item.lastName }} </a>
                </td>
                <td v-else>/</td>
                <td v-html="item.email ? item.email : '/'"></td>
                <td v-if="item.title">
                  {{ item.title.charAt(0).toUpperCase() + item.title.slice(1) }}
                </td>
                <td v-else>/</td>
                <td v-html="item.city ? item.city : '/'"></td>
                <td v-html="item.state ? item.state : '/'"></td>
              </tr>
            </tbody>

            <tbody v-else>
              <v-col md="12">
                You Don't have Users Yet. Click Here To Add New User.
              </v-col>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import api from "../../../../../services/api";
import Header from "../../Layout/HeaderPage.vue";
import { mapGetters } from "vuex";

export default {
  components:{Header},
  data() {
    return {
      items: [],
      allUsers: [
        {
          firstName: "Bill",
          lastName: "Smith",
          email: "bill.smith@smartlight.cm",
          title: "Regional VP",
          city: "Detorit",
          state: "Michigan",
          status: "Active",
          role: "Admin",
        },
      ],
      overlay: false,
      display: true,
      filterArray: [],
      windowWidth: window.innerWidth,
      noResultMessage: false,
      drawer: null,
      showStatus: {
        all: true,
        active: true,
        "in active": true,
        invited: true,
        "join request": true,
      },
      showType: {
        all: true,
        "general user": true,
        admin: true,
      },
      userStatus: [
        {
          text: "ALL",
          value: "all",
        },

        {
          text: "Join Request",
          value: "join",
        },
        {
          text: "Active",
          value: "active",
        },
        {
          text: "In Active",
          value: "inactive",
        },
        {
          text: "Invited",
          value: "invited",
        },
      ],
      userType: [
        {
          text: "ALL",
          value: "all",
        },
        {
          text: "Admins",
          value: "admins",
        },
        {
          text: "General User",
          value: "users",
        },
      ],
      selectedArray: [],
      selectedTypeArray: [],
      getUsers: [],
      panel: [0, 1],
      filterUse: false,
      filterItems: false,
      type: "",
      status: "",
      statusArray: [],
      typeArray: [],
      filter: {
        status: {
          all: false,
          active: false,
          inactive: false,
          invited: false,
          join: false,
        },
        type: {
          all: false,
          user: false,
          admin: false,
        },
      },
    };
  },
  watch: {
    showRoutePathForFilter: {
      handler(newValue, oldValue) {
        this.drawer = this.showRoutePathForFilter;
      },
      deep: true,
    },
    drawer: {
      handler(newValue, oldValue) {
        this.$store.commit("setNavItemFilter", this.drawer);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["showRoutePathForFilter"]),
    routeParams() {
      return this.$route.params.name;
    },
    singleEmp() {
      let emp = "";
      if (localStorage.getItem("employer")) {
        emp = JSON.parse(localStorage.getItem("employer"));
      }
      return emp;
    },
    arrayInFilters() {
      return this.allUsers;
    },
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
  },
  created() {
    this.getAllUsers("all");
    this.statusArray = this.userStatus;
    this.typeArray = this.userType;
    this.selectedArray = [];
    this.filterArray = this.allUsers;
  },
  methods: {
    invite(){

    },
    add(){
      this.$router.push('/solution-vendor/users/add-new')
    },
    pushtoTypeFilter(item, checked) {
      if (checked) {
        if (item.value == "allTypes") {
          this.selectedTypeArray = [];
          this.filter.type = {
            all: true,
            user: true,
            admin: true,
          };
          this.selectedTypeArray.push({ text: "All", value: "allTypes" });
        } else {
          this.selectedTypeArray.push(item);
        }
      } else {
        if (item.value == "allTypes" || this.filter.type.all) {
          this.filter.type = {
            all: false,
            user: false,
            admin: false,
          };
          this.selectedTypeArray = [];
        } else {
          this.selectedTypeArray.forEach((el, index) => {
            if (el.value == item.value) {
              this.selectedTypeArray.splice(index, 1);
            }
          });
        }
      }
    },
    pushFilter(item, checked) {
      if (checked) {
        if (item.value == "all") {
          this.selectedArray = [];
          this.filter.status = {
            all: true,
            active: true,
            inactive: true,
            invited: true,
            join: true,
          };
          this.selectedArray.push({ text: "All", value: "all" });
        } else {
          this.selectedArray.push(item);
        }
      } else {
        if (item.value == "all" || this.filter.status.all) {
          this.filter.status = {
            all: false,
            active: false,
            inactive: false,
            invited: false,
            join: false,
          };
          this.selectedArray = [];
        } else {
          this.selectedArray.forEach((el, index) => {
            if (el.value == item.value) {
              this.selectedArray.splice(index, 1);
            }
          });
        }
      }
    },

    searchStatus() {
      for (let key in this.showStatus) {
        if (key.toLowerCase().includes(this.status.toLowerCase())) {
          this.showStatus[key] = true;
        } else {
          this.showStatus[key] = false;
        }
      }
    },
    searchType() {
      for (let key in this.showType) {
        if (key.toLowerCase().includes(this.type.toLowerCase())) {
          this.showType[key] = true;
        } else {
          this.showType[key] = false;
        }
      }
    },
    filterUsers() {
      if (!this.filter.status && !this.filter.type) {
        this.getAllUsers("all");
      } else {
        this.getAllUsers(this.filter.status);
      }
      this.filterUse = true;
      this.drawer = false;
    },
    clearAll() {
      this.filter.status = {
        all: false,
        active: false,
        inactive: false,
        invited: false,
        join: false,
      };
      this.filter.type = {
        all: false,
        user: false,
        admin: false,
      };

      this.selectedArray = [];
      this.selectedTypeArray = [];
      this.getAllUsers("all");
    },

    removeFilter(item) {
      if (item.value == "allTypes") {
        this.selectedTypeArray = [];
        this.filter.type = {
          all: false,
          user: false,
          admin: false,
        };
      } else if (this.filter.status[item.value]) {
        this.filter.status[item.value] = false;
        this.selectedArray.splice(this.selectedArray.indexOf(item), 1);
      }

      if (item.value == "all") {
        this.selectedArray = [];
        this.filter.status = {
          all: false,
          active: false,
          inactive: false,
          invited: false,
          join: false,
        };
      } else if (this.filter.type[item.value]) {
        this.filter.type[item.value] = false;
        this.selectedTypeArray.splice(this.selectedTypeArray.indexOf(item), 1);
      }
    },

    getAllUsers(type) {
      let params = "";
      if (type != "all") {
        for (let key in type) {
          if (type[key]) {
            params += `${key}=${type[key]}&`;
          }
        }
      }

      api()
        .get(`/vendors/users?${params}`)
        .then((res) => {
          this.allUsers = res.data;
          this.filterArray = res.data;
          this.getUsers = res.data;
          if (res.data.length < 1) {
            this.noResultMessage = true;
          } else {
            this.noResultMessage = false;
          }
        });
    },

    singleUser(item) {
      this.$store.commit("setSingleUserInEmployerAdvisorGlobal", item);
      this.$router.push(`/solution-vendor/users/details`);
      // if (!this.overlay) {
      //   let user = JSON.parse(localStorage.getItem("user"));
      //   let advisorId = user.groupPortal[0].organization.employerAdvisor;
      //   api()
      //     .get(`/advisors/${advisorId}/users/${item._id}`)
      //     .then((res) => {
      //       this.$store.commit(
      //         "setSingleUserInEmployerAdvisorGlobal",
      //         res.data.user
      //       );
      //       this.$router.push(`/employer-advisor/settings/users/user`);
      //     });
      // }
    },
    searchInput(val) {
      this.allUsers = this.filterArray.filter((el) => {
        if (el.firstName.toLowerCase().includes(val.toLowerCase())) {
          return el.firstName.toLowerCase().includes(val.toLowerCase());
        } else if (el.lastName.toLowerCase().includes(val.toLowerCase())) {
          return el.lastName.toLowerCase().includes(val.toLowerCase());
        }
      });
      if (this.allUsers.length < 1) {
        this.filterItems = true;
      }
    },
  },
};
</script>
<style></style>
