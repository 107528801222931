<template>
  <div>
    <Header :title="isClient ? 'General Information' : 'Add New Client'"></Header>
    <div class="col-md-12">
      <div class="col-md-12">
        <v-form v-model.trim="valid" ref="form" class="row">
          <div class="col-md-12 pb-0 pr-0 pl-0">
            <div class="col-md-12 d-md-flex ">
              <div class="col-md-3">
                <h3>Client's information</h3>
                <p>Enter some information for the client</p>
              </div>
              <div class="col-md-12 pl-0">
                <div class="col-md-12 d-md-flex pt-0 pb-0">
                  <div class="col-md-6  pt-0 pb-0">
                    <label class="font text-gray" for="">Company Name</label>
                    <v-text-field
                      v-model="client.companyName"
                      required
                      :rules="requiredRules"
                      solo
                    ></v-text-field>
                  </div>
                  <div class="col-md-3  pt-0 pb-0">
                    <label class="font text-gray" for="">Tax ID</label>
                    <v-text-field
                      v-model="client.taxId"
                      required
                      @keyup="showSlash($event)"
                      solo
                      placeholder="00-0000000"
                      :rules="taxIdRules"
                      counter="10"
                      maxlength="10"
                    ></v-text-field>
                  </div>
                </div>
                <!-- <div class="col-md-12 d-md-flex pt-0 pb-0">
                  <div class="col-md-6  pt-0 pb-0">
                    <label class="" for="">Website</label>
                    <v-text-field
                      v-model.trim="client.website"
                      :rules="websiteRules"
                      solo
                    ></v-text-field>
                  </div>
                </div> -->
              </div>
            </div>
     <v-divider horizontal class="mb-5"></v-divider>
     <div class="col-md-12 col-12 d-md-flex ">
      <div class="col-md-3">
        <h3>Address Details</h3>
        <p>Enter the address of your headquarters</p>
      </div>
      <div class="col-md-9">
          <div class="col-md-8 pt-0 pb-0">
              <label class="font text-gray" for="">Address Line 1</label>
              <v-text-field
                v-model="client.address1"
                :rules="requiredRules"
                required
                solo
              ></v-text-field>
            </div>
            <div class="col-md-8 pt-0 pb-0">
              <label class="font text-gray" for="">Address Line 2</label>
              <v-text-field 
              v-model="client.address2"
              required 
              solo>
            </v-text-field>
            </div>
            <v-row class="no-padding">
              <div class="col-md-3 pt-0 pb-0">
                <label class="font text-gray" for="">City</label>
                <v-text-field
                  v-model="client.city"
                  required
                  solo
                  :rules="requiredRules"
                ></v-text-field>
              </div>
              <div class="col-md-3 pt-0 pb-0">
                <label class="font text-gray" for="">State</label>
                <v-select
                  v-model="client.state"
                  :items="states"
                  item-text="name"
                  item-value="name"
                  required
                  solo
                  :rules="requiredRules"
                ></v-select>
              </div>
              <div class="col-md-2 pt-0 pb-0">
                <label class="font text-gray" for="">Zip Code</label>
                <v-text-field
                v-model="client.zipCode"
                  required
                  type="number"
                  solo
                  :rules="zipRules"
                ></v-text-field>
              </div>
            </v-row>
      </div>
    </div>
            <v-divider horizontal class="mb-5"></v-divider>
            <div class="col-md-12 d-md-flex pl-0 pr-0">
              <div class="col-md-3">
                <h3>Contact Details</h3>
                <p>Enter details of your primary contact person.</p>
              </div>
              <div class="col-md-9">
                <div class="col-md-12 d-md-flex pt-0 pb-0">
                  <div class="col-md-6 pt-0 pb-0">
                      <label class="font text-gray pb-0" for=""
                      > First Name
                    </label>
                    <v-text-field
                    v-model="client.primaryContact.firstName"
                      required
                      solo
                      :rules="requiredRules"
                    ></v-text-field>
                  </div>
                  <div class="col-md-6 pt-0 pb-0">
                      <label class="font text-gray" for=""> Last Name</label>
                    <v-text-field
                      v-model="client.primaryContact.lastName"
                      required
                      solo
                      :rules="requiredRules"
                    ></v-text-field>
                  </div>
                </div>
                <div class="col-md-12 d-md-flex pt-0 pb-0">
                  <div class="col-md-6 pt-0 pb-0">
                      <label class="font text-gray" for="">Title</label>
                      <v-text-field
                         v-model="client.primaryContact.title"
                         solo
                      ></v-text-field>
                  </div>
                  <div  class="col-md-6 pt-0 pb-0">
                      <label class="font text-gray" for=""> Email</label>
                      <v-text-field
                      v-model="client.primaryContact.email"
                        required
                        :rules="emailRules"
                        solo
                      ></v-text-field>
                  </div>
                </div>
                <div class="col-md-12 d-md-flex pt-0 pb-0">
                  <div class="col-md-6 pt-0 pb-0">
                      <label class="font text-gray" for="">Phone</label>
                      <v-text-field
                      @keyup="formatPhoneNumber"
                        v-model="client.primaryContact.phone"
                        solo
                      ></v-text-field>
                  </div>
                </div>
              </div>
            </div>
            <v-divider horizontal class="mb-5"></v-divider>
            <div class="col-md-12 d-md-flex pl-0 pr-0">
              <div class="col-md-3">
                <h3>Plan Details</h3>
                <p>Enter details of your plan.</p>
              </div>
              <div class="col-md-9 pl-0">
                <div class="col-md-12 d-md-flex pt-0 pb-0">
                  <div class="col-md-6 pt-0 pb-0">
                      <label class="font text-gray pb-0" for=""
                      >Group ID on Member's Insurance Cards 
                    </label>
                    <v-text-field
                      solo
                      :rules="requiredRules"
                      v-model="client.groupId"
                    ></v-text-field>
                  </div>
                  <div class="col-md-12 d-md-flex pt-0 pb-0">
                    <div class="col-md-6 pt-0 pb-0">
                        <label class="font text-gray" for="">Enter email domain here, and click Add </label>
                        <div class="row d-flex col-md-12">
                          <v-text-field
                            ref="emailValidation"
                            required
                            style="width: 50%;" 
                            @keyup="fillDomainRules"
                            :rules="newEmail ? domainRules : []"                           
                             v-model.trim="newEmail"
                            solo
                          ></v-text-field>
                          <v-btn large class="ml-3 mt-1 blue-buttons col-md-1" @click="pushToArray"
                            >Add</v-btn>
                        </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 d-md-flex pt-0 pb-0">
                  <div class="col-md-6 pt-0 pb-0">
                      <label class="font text-gray" for="">Members email domain </label>
                      <div class="col-md-9 pl-0"  v-for="(domain, index) in client.settings.domainWhiteList"
                      :key="index">
                        <v-text-field
                          solo
                          :value="domain"
                        ></v-text-field>
                      </div>
                      <div> 
                        <v-card
                        v-for="(email, index) in emails"
                        :key="index"
                        style="padding: 1rem"
                        class="mt-2 mb-2"
                      >
                        <span v-if="email.domain">{{ email.domain }}</span>
                        <span v-else>{{ email }}</span>
                        <span class="float-right">
                          <svg
                          @click="removeFromArray(email)"
                            class="pointer"
                            width="28"
                            height="26"
                            viewBox="0 0 28 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.35547 13C1.35547 16.1826 2.65524 19.2348 4.96884 21.4853C7.28244 23.7357 10.4204 25 13.6923 25C16.9642 25 20.1021 23.7357 22.4157 21.4853C24.7293 19.2348 26.0291 16.1826 26.0291 13C26.0291 9.8174 24.7293 6.76516 22.4157 4.51472C20.1021 2.26428 16.9642 1 13.6923 1C10.4204 1 7.28244 2.26428 4.96884 4.51472C2.65524 6.76516 1.35547 9.8174 1.35547 13V13Z"
                              stroke="#D00000"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M8.75781 17.7992L18.6273 8.19922"
                              stroke="#D00000"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M18.6273 17.7992L8.75781 8.19922"
                              stroke="#D00000"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                      </v-card>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <v-divider horizontal class="mb-5"></v-divider>
            <div class="col-md-12 d-md-flex pl-0 pr-0">
              <div class="col-md-3">
                <h3>Bank Details</h3>
                <p>Enter details of your bank account.</p>
              </div>
              <div class="col-md-9">
                <div class="col-md-12 d-md-flex pt-0 pb-0">
                  <div class="col-md-6 pt-0 pb-0">
                      <label class="font text-gray pb-0" for=""
                      >Bank Name
                    </label>
                    <v-text-field
                      solo
                      v-model="client.bank.name"
                    ></v-text-field>
                  </div>
                </div>
                <div class="col-md-12 d-md-flex pt-0 pb-0">
                  <div class="col-md-6 pt-0 pb-0">
                      <label class="font text-gray" for="">Inbound Routing Number</label>
                      <v-text-field
                        solo
                        v-model="client.bank.routeNo"
                      ></v-text-field>
                  </div>
                  <div class="col-md-6 pt-0 pb-0">
                      <label class="font text-gray" for=""> Inbound Account Number</label>
                      <v-text-field
                        solo
                        v-model="client.bank.accountNo"
                      ></v-text-field>
                  </div>
                </div>
              </div>
            </div> -->
            <v-divider horizontal class="mb-5"></v-divider>
          </div>
        </v-form>
      </div>
    </div>
    <div class="col-12">
      <div class="col-12 mb-4 d-md-flex justify-end mr-2" align="end">
        <!-- <span class="mt-3 col-6">
          <a @click="generatePdf()" large class="text-underline light-blue-text font-weight-bold mt-3">
         View & Print Apaly Terms and Conditions
           </a>
       </span> -->
        <span class="col-4 justify-end pointer  mr-2 font-18" >
           <v-btn @click="$router.push('/tpa/clients')" variant="plain" class="light-blue-text font-weight-bold">Cancel</v-btn>
          </span>
        <span  class=" col-1 pointer  font-weight-bold mr-8 font-18 ">
        <v-btn @click="submit()" color="#214A82" class="text--white">{{ isClient ? 'Update' : 'Add' }}</v-btn>  
        </span>
      </div>
      <successModal ref="success_modal" v-on:confirm="closeSuccessModal"></successModal>
      <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
    </div>
     <v-dialog
      v-model.trim="addedClientSuccess"
      width="800"
      height="auto"
      content-class=""
      persistent
    >
      <v-card>
        <v-card-text class="pt-5">
          <div class="text-center justify-center mt-3">
            <img height="150" src="/sucess.png" alt="" />
          </div>
          <div class="col-md-12  text-center pt-10">
          <h1>Client added successfully!</h1>
          <div class="d-flex justify-center text-center mt-8 pb-8">
            <v-btn outlined class="font-weight-bolder" @click="addHealthPlan()" ><strong>Add a health plan</strong> </v-btn>
          </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
 
</template>
<script>
import Header from "../Layout/HeaderPage.vue";
import api from "../../../../services/api";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../TablesTemplate/ErrorDialog.vue";
export default {
  components: { Header, successModal, errorModal },
  data() {
    return {
      taxIdRules: [
        (v) => !!v || "This field is required",
        (v) =>
          !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
          "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
      ],
      zipRules: [
        (v) => (v && v.length == 5) || " Zip Code should be five digits long",
      ],
      groupIdRules: [
        (v) => !!v || "This field is required",
        (v) => (v && v.length == 6) || "Group ID should contain 6 digits",
      ],
      states: [],
      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
      domainRules: [
        (v) => !!v || "This field is required",
        (v) =>
          /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/.test(v) ||
          "Domain must be valid",
      ],
      emailRules: [
        (v) => !!v || "This field is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      websiteRules: [
        (v) => !!v || "This field is required",
        (v) =>
        /^(https?:\/\/)?[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/.test(v) ||
          "Website must be valid",
      ],
      valid: false,
      isClient:false,
      newEmail: "",
      client: {
        companyName: "",
        taxId: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipCode: "",
        // website: "",
        // bank: {
        //   name: "",
        //   routeNo: "",
        //   accountNo: "",
        // },
        primaryContact: {
          firstName: "",
          lastName: "",
          title: "",
          email: "",
          phone: "",
        },
        groupId: "",
        status: "",
        settings: {
          domainWhiteList: [],
        }
      },
      emails: [],
      parsedEmails: "",
      domains: [],
      myAddArray: [],
      isEmailValid: true,
      idClient: "",
      addedClientSuccess: false,
    };
  },
  created() {
    this.getStates();
    if(this.$route.params.clientId){
      this.getClient();
    }
  },
  methods: {
    fillDomainRules() {
      const list = this.newEmail;
      console.log(list.includes(this.domainRules))
      this.domainRules = [
        (v) => !!v || "This field is required",
        (v) =>
          /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/.test(v) ||
          "Email domain is not valid!",
      ];
    },
    removeFromArray(email) {
      this.myAddArray.push(email);
      for (let i = 0; i < this.emails.length; i++) {
        if (this.emails[i] == email) {
          this.emails.splice(i, 1);
        }
      }
    },
    pushToArray() {
     if(this.$refs.emailValidation.validate() == true){
       if(this.newEmail != ''){
       this.emails.push(this.newEmail);
      this.myAddArray.push({ domain: this.newEmail });     
      this.newEmail = '';
     }
        
      }
      
    },
    formatPhoneNumber(){
      let phoneNumber = this.client.primaryContact.phone.replace(/\D/g, '');
      let formatted = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3');
      this.client.primaryContact.phone = formatted;
    },
    generatePdf(){
      window.open( `https://apalycdn.s3.us-west-1.amazonaws.com/public/uploads/admin/contracts/TermsAndConditions.pdf`, "_blank");
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    closeSuccessModal() {
      this.$parent.getData();
        this.$router.push(`/tpa/clients`);
    },
    addHealthPlan(){
      this.addedClientSuccess = false;
      this.$router.push(`/tpa/clients/${this.idClient}/plans`);
    },
    showSlash(event) {
      if (this.client.taxId.length == 2 && event.keyCode != 8) {
        this.client.taxId += "-";
      }
      if (this.client.taxId.length == 10) {
        api().get("auth/isValidTaxId?taxId=" + this.client.taxId)
          .catch((res) => {
            if (res) {
              this.taxIdRules = [res.response.data.message];
            }
          });
      } else {
        this.taxIdRules = [
          (v) => !!v || "This field is required",
          (v) =>
            !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
            "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
        ];
      }
    },
    getStates() {
      api().get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },
    submit() {
      this.client.settings.domainWhiteList =[...this.client.settings.domainWhiteList, ...this.emails] ;
      this.$refs.form.validate();
      if(this.client.settings.domainWhiteList.length < 1){
        this.$refs.error_modal.openPopUp('Emain domain field is required!');
      }
      else{
       if (this.valid) {
        this.client.status = (this.client.status) ? "active" : "inactive";
       
        let path = '/tpas/clients'
        let method = 'post'
        if(this.isClient){
          path += '/'+this.client.id
          method = 'put'
        }
        api()[method](path, this.client)
          .then((res) => {
            if (res) {
              
              if(method == 'put'){
              
                this.$refs.success_modal.openPopUp("Client updated successfully!");
              }else
              {
                this.idClient = res.data._id;
                this.addedClientSuccess= true;
              }
           
            }
          });
      } 
      }
      
    },
    removeClient(){
      api().delete(`/tpas/clients/${this.$route.params.clientId}`).then((res) => {
        this.$refs.success_modal.openPopUp("Client removed successfully!");
      })
    },
    getClient(){
       api().get(`/tpas/clients/${this.$route.params.clientId}`).then((res) => {
        if (res && res.data) {
          this.isClient = true
          this.client = {
            id: res.data.id,
            companyName: res.data.companyName,
            taxId: res.data.taxId,
            address1: res.data.address1,
            address2: res.data.address2,
            city: res.data.city,
            state: res.data.state,
            zipCode: res.data.zipCode,
            // website: res.data.website ? res.data.website : '',
            primaryContact: {
              firstName: res.data.primaryContact?.firstName,
              lastName: res.data.primaryContact?.lastName,
              title: res.data.primaryContact?.title,
              email: res.data.primaryContact?.email,
              phone: res.data.primaryContact?.phone,
            },
            // bank: {
            //   name: res.data.bank?.name,
            //   accountNo: res.data.bank?.accountNo,
            //   routeNo: res.data.bank?.routeNo,
            // },
            groupId: res.data.groupId,
            status: res.data.status,
            settings: {
              domainWhiteList: res.data.settings ? res.data.settings.domainWhiteList : "",
            }
          };
        }
      });
    },
  },
};
</script>
<style>
.text--white{
  color: white !important;
}
</style>