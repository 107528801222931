<template>
 <div>
     <CreateRates v-if="path == `/provider-advisor/providers/${routeParams}/rates/create-rate`" ></CreateRates>
     <Rates v-if="path == `/provider-advisor/providers/${routeParams}/rates`"></Rates>
 </div>  
</template>
<script>
import CreateRates from './CreateRate.vue'
import Rates from './Rates.vue'

export default{
    components:{
        CreateRates,
        Rates
    },
    computed:{
        path(){
              return this.$route.path.replace(/%20/g, ' ')
        },
        routeParams(){
            return this.$route.params.name
        }
    }
}
</script>