<template>
  <div class="row-height">
    <div>
      <div class="row border-blue-bottom">
        <div class="col-6">
          Solution Package Detail
        </div>
        <div class="col-6 text-right" v-if="singlePackage._id != 0">
          <a @click="deletePackage">
            Delete Solution Package
          </a>
        </div>
      </div>
      <div class="row ">
        <div class="col-xl-4 col-lg-3 col-md-6 col-sm-12 d-flex">
         <span class="mt-2 mr-1 ml-1 text-gray font-weight-bold">Name:</span> 
          <v-text-field v-model="singlePackage.name" class="input-control mr-5 col-md-6"  light dense outlined placeholder="Package name"></v-text-field>
        </div>
        <div class="col-xl-5 col-lg-7 col-md-6 col-sm-12 d-flex">
          <span class="mt-2 mr-1 text-gray font-weight-bold">Description:</span>
          <v-text-field v-model="singlePackage.description" class="input-control mr-5 col-md-6"  light dense outlined placeholder="Package description"></v-text-field>
        </div>
        <div class="col-xl-3 col-lg-2 col-md-6 col-sm-12 d-flex">
          <span class="mt-2 mr-1 text-gray font-weight-bold">Status:</span>
          <v-select v-model="singlePackage.status" :items="['active','inactive']" class="input-control mr-5 col-md-6"  light dense outlined placeholder="Search Solutions"></v-select>
        </div>
      </div>

      <div class="mt-6">
        <div class="row mt-4 mb-2">
          <v-divider horizontal></v-divider>
        </div>
        <div class="row">
          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 row">
            <div class="col-md-4 pt-5 text-center pointer font-weight-bold header-page-titles text-underline" @click="getSolutions" :class="tab == 'solutions' ? 'light-blue-text' : 'text-gray'">
              Find Solutions
            </div>
            <div class="mt-2 mb-2">
              <v-divider vertical class="dividers-margin"></v-divider>
            </div>
            <div class="col-md-3 pt-5 text-center pointer font-weight-bold header-page-titles" @click="getFavorites" :class="tab == 'favorites' ? 'light-blue-text' : 'text-gray'">
              Favorites
            </div>
          </div>
          <div v-if="['solutions','favorites'].includes(tab)" class="col-xl-2 col-lg-3 col-md-6 col-sm-12">
            <v-text-field @input="searchInput" class="input-control mr-5 col-md-6" prepend-inner-icon="mdi-magnify"  light dense outlined placeholder="Search Solutions"></v-text-field>
          </div>
          <div v-if="['solutions','favorites'].includes(tab)" class="col-xl-5 col-lg-4 col-md-12 col-sm-12 row justify-end" align="end">
            <v-col class="col-xl-6 col-lg-4 col-md-6 d-flex justify-end" align="end">
              <span class="mt-2 ml-4"> Type: </span>
              <div class="mt-1">
                <v-select style="width: 250px" :items="typeItems" solo dense v-model="typeBy" hide-details class="ml-3"
                          @change="filterItemsByType('type')">
                </v-select>
              </div>
            </v-col>
            <v-col class="col-xl-6 col-lg-4 col-md-6 d-flex" align="end">
              <span class="mt-2"> Sort By: </span>
              <div class="mt-1">
                <v-select style="width: 250px" :items="sortItems" solo dense v-model="sortBy" hide-details class="ml-3"
                          @change="filterItemsByType('sort')">
                </v-select>
              </div>
            </v-col>
          </div>
        </div>

        <div class="row mt-4 mb-2">
          <v-divider horizontal></v-divider>
        </div>

        <v-simple-table fixed-header height="60vh" class="no-padding tr-custom-height">
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">SOLUTION NAME</th>
              <th class="text-left">CATEGORY</th>
              <th class="text-left">DESCRIPTION</th>
              <th class="text-left">SELECT</th>
            </tr>
            </thead>
            <tbody v-if="items.length > 0 && !filterItems" class="text-gray tr-custom-height" :key="initTable">
            <tr v-for="(item, index) in items" :key="index+'_'+item.checked">
              <td @click="showDetails(item)">
                <a class="text-underline">{{ item.name }}</a>
              </td>
              <td v-html="item.category ? item.category : '/'"></td>
              <td v-html="item.description ? item.description : '/'"></td>
              <td>
                <div @click="fillArray(item, index)">
                  <v-checkbox v-model="item.checked" checked color="blue" large></v-checkbox>
                </div>
              </td>
            </tr>
            </tbody>
            <tbody v-else-if="items.length < 1 && filterItems">
            <v-col md="12" cols="12">
              <v-alert border="bottom" colored-border type="warning" elevation="2" class="text-gray">
                Sorry! No results found
              </v-alert>
            </v-col>
            </tbody>

            <tbody v-else>
            <v-col md="12" v-if="tab == 'favorites'">
              There are no favourite solutions currenlty.
            </v-col>
            <v-col md="12" v-else> There are no solutions currenlty. </v-col>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <successModal ref="success_modal" v-on:confirm="closeSuccessModal"></successModal>

      <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
      <div class="gray-top-border">
        <div class="col-md-12" align="end">
          <span class="light-blue-text text-underline mr-10 font-weight-bold pointer" @click="$router.push('/tpa/solutions')">
            Cancel
          </span>
          <span class="light-blue-text text-underline mr-10 ml-10 font-weight-bold pointer" @click="updatePackage()">
            <span v-if="this.singlePackage._id == 0">Store</span>
            <span v-else>Update</span>
          </span>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import api from "../../../../services/api";
import SolutionPackages from "./SolutionPackages";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../TablesTemplate/ErrorDialog.vue";

export default {
  components: { successModal, errorModal, SolutionPackages },
  data() {
    return {
      tab: "solution",
      initTable: 'init',
      dialog: false,
      items: [],
      singlePackage: {
        _id:0,
        name:null,
        description:null,
        status:'active',
        solutions:[],
      },
      typeItems: [
        { text: "All Categories" },
        { text: "DPC" },
        { text: "Others" },
      ],
      sortItems: [{ text: "Newest" }, { text: "Oldest" }],
      sortBy: "",
      typeBy: "",
      type: "",
      solutions: [],
      favorites: [],
      checked: [],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
      valid: false,
      filterArray: [],
      filterItems: false,
    };
  },
  watch:{
    favorites(newVal, oldVal) {
      const self = this
      this.items.forEach(function (i) {
        if(newVal.includes(i._id)){
          i.rating = 1
        }
      })
      this.filterArray = this.items;
      this.initTable = Math.random()
    },
    checked(newVal, oldVal) {
      const self = this
      this.items.forEach(function (i) {
        if(newVal.includes(i._id)){
          i.checked = true
        }
      })
      this.filterArray = this.items;
      this.initTable = Math.random()
    },
  },
  computed: {
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
  },
  methods: {
    deletePackage(){
      api().delete("/tpas/solutions/package/"+this.singlePackage._id)
         .then((res) => {
            if (res) {
              this.$refs.success_modal.openPopUp("Deleted Successfully");
              this.$router.push('/tpa/solutions')
            }
      }).catch((res) => {
        if (res) {
          this.$refs.error_modal.openPopUp(res.response.data);
        }
      });
    },
    updatePackage(){
      let type = 'post'
      let link = "/tpas/solutions/package"
      if(this.singlePackage._id != 0){
        link += '/'+this.singlePackage._id
        type = 'put'
      }else{
        delete this.singlePackage._id
      }
      api()[type](link,  this.singlePackage).then((res) => {
        this.$refs.success_modal.openPopUp("Updated Successfully");
      });
    },
    getFavorites(){
      this.tab = 'favorites'
      this.items = this.filterArray.filter(function (i) {
        return i.rating === 1
      })
    },
    getPackages(){
      this.tab = 'packages'
    },
    filterItemsByType(type) {
      if (type == "sort") {
        if (this.sortBy == "Oldest") {
          this.items = this.filterArray.sort(
            (a, b) =>
              Number(new Date(a.createdAt)) - Number(new Date(b.createdAt))
          );
        } else {
          this.items = this.filterArray.sort(
            (a, b) =>
              Number(new Date(b.createdAt)) - Number(new Date(a.createdAt))
          );
        }
      } else {
        if (this.typeBy != "All Categories") {
          this.items = this.filterArray.filter((el) => {
            return el.category == this.typeBy;
          });
        } else {
          this.items = this.filterArray;
        }
      }
    },
    closeErrorModal() {
      this.dialog = false;
      this.companyName = "";
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.$refs.success_modal.closePopUp();
      this.$refs.error_modal.closePopUp();
    },
    closeSuccessModal() {
      this.dialog = false;
      this.companyName = "";
      this.firstName = "";
      this.lastName = "";
      this.email = "";

      this.$refs.success_modal.closePopUp();
    },
    getSolutions() {
      this.tab = 'solutions'
      api().get("/tpas/solutions")
        .then((res) => {
          const self = this
          this.favorites = res.data.favorites;
          this.items = res.data.solutions;
          this.checked = this.singlePackage.solutions ?? [];
          this.items.forEach(function (i) {
            if(self.checked.includes(i._id)){
              i.checked = true
            }
          })
          this.filterArray = this.items;
        });
    },
    showDetails(item) {
      this.singleItem = item;
      this.$store.commit("setTpaSingleSolution", item);
      this.$router.push("/tpa/solutions/details");
    },
    fillArray(item, index) {
      this.items[index].checked = item.checked
      if(item.checked == true){
        this.checked.push(item._id)
      }else{
        this.checked.splice(this.checked.indexOf(item._id),1)
      }
      this.initTable = Math.random()
    },
    searchInput(val) {
      this.items = this.filterArray.filter((el) => {
        if (el.name && el.name.toString().toLowerCase().includes(val.toLowerCase())) {
          return el.name.toString().toLowerCase().includes(val.toLowerCase());
        } else if (el.category && el.category.toString().toLowerCase().includes(val.toLowerCase())) {
          return el.category.toString().toLowerCase().includes(val.toLowerCase());
        }else if (el.description && el.description.toString().toLowerCase().includes(val.toLowerCase())) {
          return el.description.toString().toLowerCase().includes(val.toLowerCase());
        }
      });
      this.filterItems  =  (this.items.length < 1);
    },
  },
  created() {
    this.singlePackage = this.$store.getters.getSinglePackage
    this.getSolutions();
  },
};
</script>
<style>
</style>