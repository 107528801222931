
import RegisterMembers from '../views/LoginRegister.vue'
import EmailDomain from '../views/EmailDomain.vue'
import ZipCodes from '../views/ZipCode.vue'
import NpiSearch from '../views/NpiSearchPage.vue'
import EmployersProviders from '../views/ProvidersEmployers.vue'
import WebSitePA from '../views/WebSiteProviderEmployer.vue'
import notFound from '../components/TablesTemplate/NotFound.vue'
import NoLoginRequired from '../views/NoLoginRequiredPage.vue'
import NewLogin from '../views/NewLogin.vue'
import NewRegister from '../views/NewRegister.vue'
const router = [
  {
    path:'/',
    redirect:'/login'
  },
  
  {
    path:'/checkdomain',
    name:'Check Email Domain',
    component:EmailDomain
  },
  // {
  //   path:'/login',
  //   name:'Log in',
  //   component:NewLogin
  // },
  
  {
    path:'/externals',
    name:'Externals',
    component:NoLoginRequired,
  
  },
  {
    path:'/search-members',
    name:'Search Members',
    component:WebSitePA
  },
  {
    path:'/search-employers',
    name:'Search Employers',
    component:WebSitePA
  },
  {
    path:'/npisearch',
    name:'Search Npi',
    component:NpiSearch
  },
  {
    path:'/providers/:providerID/employers/:employerID/addedProvider/:addedProviderID/acceptEmployerAccess',
    name:'Deny Access',
    component:RegisterMembers
  },
  {
    path:'/providers/:providerID/employers/:employerID/addedProvider/:addedProviderID/denyEmployerAccess',
    name:'Confirm Access',
    component:RegisterMembers
  },
  {
    path:'/solutions/approve/dpc/provider',
    name:'Approve Solution',
    component:RegisterMembers
  },
  {
    path:'/zipmilsearch',
    name:'Zip Codes',
    component:ZipCodes
  },
  {
    path:'/employers-providers',
    name:'Employers Providers',
    component:EmployersProviders
  },
  // {
  //   path:'/login',
  //   name:'Login',
  //   component:RegisterMembers
  // },
  {
    path:'/password',
    name:'Reset Password',
    component:RegisterMembers
  },
  {
    path:'/auth/verifyMembership/:id',
    name:'Login',
    component:RegisterMembers,
   // beforeEnter: routeGuard,

  },
  {
    path:'/advisors/:id/employers/:id/providers/:id/unmaskRequest/accept',
    name:'Unmask Request',
    component:RegisterMembers,
  },
  {
    path:'/advisors/:id/employers/:id/providers/:id/unmaskRequest/deny',
    name:'Unmask Request',
    component:RegisterMembers,
  },
  {
    path:'/auth/confirmOrganizationRequest/:id/accept',
    name:'Confirm Organization',
    component:RegisterMembers,
  },
  {
    path:'/auth/confirmOrganizationRequest/:id/deny',
    name:'Confirm Organization',
    component:RegisterMembers,
  },
  {
    path:'/members/:id/visitPassRequests/:id/approve',
    name:'Login',
    component:RegisterMembers,
   // beforeEnter: routeGuard,

  },
  {
    path:'/members/:id/visitPassRequests/:id/deny',
    name:'Login',
    component:RegisterMembers,
  },
  {
    path:'/auth/verify/:id',
    name:'Register New Member',
    component:RegisterMembers
  },
  {
    path:'/auth/verify',
    name:'Register New Member',
    component:RegisterMembers
  },
  // {
  //   path:'/register',
  //   name:'Register',
  //   component:NewRegister
  // },
  // {
  //   path:'/new-register',
  //   name:'Register',
  //   component:NewRegister
  // },
  {
    path:'/register/:name',
    name:'Register New Member',
    component:RegisterMembers
  },
  {
    path:'/register/:type/:name',
    name:'Register New Member',
    component:RegisterMembers
  },
  { path: '/404', component: notFound },  
  { path: '*', redirect: '/404' },  
]
export default router