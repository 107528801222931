<template>
    <div class="">
      <div class="row blue-underline  mb-3">
        <div class="col-md-12 mb-4 ml-4 mt-4 font-weight-bold text-gray d-flex">
            <svg @click="$router.push('/tpa/solutions')" class="mr-2 mt-1" width="14" height="16" viewBox="0 0 14 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.6269 23.764L1.99634 12.9562C1.92725 12.8861 1.87267 12.803 1.83571 12.7118C1.79875 12.6205 1.78014 12.5229 1.78095 12.4245C1.78176 12.326 1.80197 12.2287 1.84042 12.1381C1.87888 12.0474 1.93482 11.9653 2.00505 11.8963L12.8118 1.26475" stroke="gray" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
               <p @click="$router.push('/tpa/solutions')">Back to Solutions </p>   
        </div>
      </div>
      <div>
        <div class="row form-row-height pt-2 pb-2 light-blue-text">
          <div
            class="col-md-2 ml-4 font-weight-bold"
            style="border-right: 1px solid #eeeded"
          >
          <div class="text-center justify-center mb-1 mt-1">
            <img
            id="logo-img"
            :src="vendor.logo ? vendor.logo : '/newapalylogoblue.png'"
            class="apaly-pic pointer mt-3 mb-2"
          />
           </div>
          </div>
          <div class="col-md-8">
            <div class="row col-md-12 font-weight-bold">
             Vendor Details
              <v-divider class="mt-3 ml-3"></v-divider>
              <div class="col-12 d-md-flex">
              <div class="col-lg-4 col-xl-4 col-12 text-gray">
                General information about this vendor</div>
                <div class="col-12"> 
                    <div class="col-md-12 d-flex  pt-0 pb-0">
                    <span class="col-4 col-lg-3 col-xl-3 text-gray font-weight-bold">
                       Name:</span>
                    <span class="col-8 col-lg-9 col-xl-9 text-gray font-weight-bold">
                      {{vendor.companyName}}
                    </span>
                  </div>
                  <br />
                  <div class="col-md-12 d-flex  pt-0 pb-0">
                    <span class="col-4 col-lg-3 col-xl-3 text-gray font-weight-bold">
                       Type:</span>
                    <span class="col-8 col-lg-9 col-xl-9 text-gray">
                       {{vendor.type ? vendor.type : 'Vendor'}}
                    </span>
                  </div>
                  <br />
                  <div class="col-md-12 d-flex  pt-0 pb-0">
                    <span class="col-4 col-lg-3 col-xl-3 text-gray font-weight-bold">
                       Category:</span>
                    <span class="col-8 col-lg-9 col-xl-9 text-gray">
                      {{vendor.category ? vendor.category : '/'}}
                    </span>
                  </div>
                  <br /> 
                  <div class="col-md-12 d-flex  pt-0 pb-0">
                    <span class="col-4 col-lg-3 col-xl-3 text-gray font-weight-bold">
                        Website:</span>
                    <span class="col-8 col-lg-9 col-xl-9 text-gray">
                      <a :href="`${vendor.website}`">{{vendor.website}}</a>
                    </span>
                  </div>
                  <br />
                  <div class="col-md-12 d-flex  pt-0 pb-0">
                    <span class="col-4 col-lg-3 col-xl-3 text-gray font-weight-bold">
                       Description:</span>
                    <span class="col-8 col-lg-9 col-xl-9 text-gray">
                      {{vendor.description}}
                    </span>
                  </div>
                  <br /> 
                </div>
              </div>
            </div>
            <div class="row col-md-12 font-weight-bold">
                Vendor Information
                 <v-divider class="mt-3 ml-3"></v-divider>
                 <div class="col-12 d-md-flex">
                  <div class="col-lg-4 col-xl-4 col-12 text-gray">
                    Personal information of this contact</div>
                    <div class="col-12"> 
                        <div class="col-md-12 d-flex  pt-0 pb-0">
                        <span class="col-4 col-lg-3 col-xl-3 text-gray font-weight-bold">
                            Point of Contact :</span>
                        <span class="col-8 col-lg-9 col-xl-9 text-gray font-weight-bold">
                           {{vendor.primaryContact.firstName}} {{vendor.primaryContact.lastName}}
                        </span>
                      </div>
                      <br />
                      <div class="col-md-12 d-flex  pt-0 pb-0">
                        <span class="col-4 col-lg-3 col-xl-3 text-gray font-weight-bold">
                            Email:</span>
                        <span class="col-8 col-lg-9 col-xl-9 text-gray">
                           <a>{{vendor.primaryContact.email}}</a>
                        </span>
                      </div>
                      <br />
                      <div class="col-md-12 d-flex  pt-0 pb-0">
                        <span class="col-4 col-lg-3 col-xl-3 text-gray font-weight-bold">
                           Address:</span>
                        <span class="col-8 col-lg-9 col-xl-9 text-gray">
                            {{vendor.address1}}                        
                        </span>
                      </div>
                      <br /> 
                    </div>
                  </div>
               </div>
               <div class="row col-md-12 font-weight-bold">
                Rates & Fees
                 <v-divider class="mt-3 ml-3"></v-divider>
                 <div class="col-12 d-md-flex">
                  <div class="col-lg-4 col-xl-4 col-12 text-gray">
                     Information about rates and fees</div>
                    <div class="col-12"> 
                        <div class="col-md-12 d-flex  pt-0 pb-0">
                        <span class="col-4 col-lg-3 col-xl-3 text-gray font-weight-bold">
                            Vendor Rate :</span>
                        <span class="col-8 col-lg-9 col-xl-9 text-gray font-weight-bold">
                          
                        </span>
                      </div>
                      <br />
                      <div class="col-md-12 d-flex  pt-0 pb-0">
                        <span class="col-4 col-lg-3 col-xl-3 text-gray font-weight-bold">
                            Apaly Fee</span>
                        <span class="col-8 col-lg-9 col-xl-9 text-gray">
                         
                        </span>
                      </div>
                      <br />
                      <div class="col-md-12 d-flex  pt-0 pb-0">
                        <span class="col-4 col-lg-3 col-xl-3 text-gray font-weight-bold">
                           TPA Fee</span>
                        <span class="col-8 col-lg-9 col-xl-9 text-gray">
                                               
                        </span>
                      </div>
                      <br /> 
                    </div>
                  </div>
               </div>
               <!-- <div class="row col-md-12 font-weight-bold">
                Rates and Fees
                 <v-divider class="mt-3 ml-3"></v-divider>
                 <div class="col-12 d-flex">
                    <div class="col-4 text-gray"></div>
                    <div class="col-8 text-gray">Fee for service</div>
                  </div>
               </div> -->
          </div>
        </div>
      </div>
      <div class="gray-top-border">
        <div class="col-md-12" align="end">
          <!-- <span
            class="light-blue-text text-underline mr-10 font-weight-bold pointer"
            @click="addSolution"
            >Update</span
          > -->
          <span
            class="
              light-blue-text
              text-underline
              mr-10
              ml-10
              font-weight-bold
              pointer
            "
            @click="$router.push('/tpa/solutions')"
            >Close</span
          >
        </div>
      </div>
      <successModal
        ref="success_modal"
        v-on:confirm="closeSuccessModal"
      ></successModal>
    </div>
  </template>
  
  <script>
  import successModal from "../../../TablesTemplate/SuccessDialog.vue";
  import api from "../../../../services/api";
  import { mapGetters } from 'vuex';
  
  export default {
    components: { successModal },
    data() {
      return {
        vendor: {},
        logo: "",
        item: {},
        valid:false,
         emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
        ],
        requiredRules: [(v) => !!v || "This field is required"],
  
      };
    },
  
    computed: {
      ...mapGetters(['getSingleItem'])
    },
    created() {
      if(this.getSingleItem){
        this.item = this.getSingleItem
      }
      this.getVendor();
    },
    methods: {

      closeSuccessModal() {
        this.$parent.getSolutions();
        this.$router.push("/tpa/solutions");
      },
      getVendor() {
          api()
            .get(`/vendors/market/vendor/` + this.item.id)
            .then((res) => {
              if(res){
                this.vendor = res.data
              }
            });
        
      },
      addSolution() {
        this.$refs.form.validate();
        if (this.valid) {
          api()
            .put("/vendors/solutions/" + this.item._id, this.item)
            .then((res) => {
              if(res){
              this.$refs.success_modal.openPopUp('Updated successfully');
  
              }
            });
        }
      },
    },
  };
  </script>
  