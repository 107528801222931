<template>
  <div class="white-toolbar justify-space-between d-flex">
    <div
      v-if="
        $route.path == '/vendors/home' || $route.path == '/dpc-providers/home'
      "
    >
      <span class="text-gray font-quicksand-medium-14">Home</span>
    </div>

    <div v-else>
      <span v-for="(route, index) in $route.matched" :key="index">
        <span v-if="route && route.name">
          <router-link
            class="font-quicksand-medium-14"
            v-if="index != Object.keys($route.matched).length - 1"
            :to="
              route.name == 'Contract Type' &&
              $route.path.includes('/provider/contracts')
                ? '/provider/contracts/select-type'
                : route.name == 'Contract Type' &&
                  !$route.path.includes('/provider/contracts/')
                ? `/provider-advisor/providers/${providerName}/contracts/contract-type`
                : route.path
            "
          >
            <span v-if="route.name == 'Employer Home' && singleEmployer">
              Employer Home
            </span>
            <span v-else-if="route.name == 'Provider Home' && singleProvider">
              Provider Home
            </span>
            <span v-else>
              {{ route.name }}
            </span>
          </router-link>
          <span class="text-gray font-quicksand-medium-14" v-else>
            <span
              v-if="
                route.name == 'Employer' &&
                singleEmployer &&
                $route.path != '/employer-advisor/employers/employer'
              "
            >
              Employer Home
            </span>
            <span
              v-else-if="
                route.name == 'Provider Home' &&
                singleProvider &&
                $route.path == '/provider-advisor/providers/provider'
              "
            >
              Provider
            </span>
            <span v-else>
              {{ route.name }}
            </span>
          </span>
          <span
            class="text-gray font-quicksand-medium-14"
            v-if="index != Object.keys($route.matched).length - 1"
          >
            \
          </span>
        </span>
      </span>
    </div>
    <div id="changeProviderName" @click="changeProviderName">
      <span v-for="(route, index) in $route.matched" :key="index">
        <h3
          v-if="
            route &&
            route.name &&
            route.name.includes('Employer Home') &&
            $route.path != '/employer-advisor/employers/employer' &&
            $route.path != '/employer-advisor/employers' &&
            $route.path != '/employer-advisor/employers/add-new'
          "
          class="font-quicksand-medium-14 text-gray"
        >
          {{ changeProviderName() }}
        </h3>
        <h3
          v-else-if="
            route &&
            route.name &&
            route.name.includes('Provider Home') &&
            $route.path != '/provider-advisor/providers/provider' &&
            $route.path != '/provider-advisor/providers'
          "
          class="font-quicksand-medium-14 text-gray"
        >
          {{ changeProviderName() }}
        </h3>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },

  props: {
    clientName: {
      type: String,
    },
  },
  computed: {
    params() {
      let emp = "";

      return emp;
    },
    singleEmployer() {
      let emp = "";
      let name = "";
      if (localStorage.getItem("employer")) {
        emp = JSON.parse(localStorage.getItem("providerName"));
        name = emp;
      } else if (this.$route.params.name) {
        name = this.$route.params.name;
      }

      return name;
    },
    providerName() {
      return this.$route.path.split("/")[3];
    },
    singleProvider() {
      let pro = "";
      let name = "";
      if (localStorage.getItem("provider")) {
        pro = JSON.parse(localStorage.getItem("providerName"));
        name = pro;
      } else if (this.$route.params.name) {
        name = this.$route.params.name;
      }

      return name;
    },
  },
  created() {},
  methods: {
    goToSelectType() {
      this.$router.push("/provider/contracts");
    },
    changeEmpName() {
      let pro = "";
      let name = "";
      if (localStorage.getItem("provider")) {
        pro = JSON.parse(localStorage.getItem("providerName"));
        name = pro;
      } else if (this.$route.params.name) {
        name = this.$route.params.name;
      }

      return name;
    },
    changeProviderName() {
      let pro = "";
      let name = "";
      if (this.$route.path.includes("/employer-advisor")) {
        if (localStorage.getItem("providerName")) {
          pro = JSON.parse(localStorage.getItem("providerName"));
          name = pro;
        }
      } else if (this.$route.path.includes("/provider-advisor")) {
        if (localStorage.getItem("providerName")) {
          pro = JSON.parse(localStorage.getItem("providerName"));
          name = pro;
        }
      }
      return name;
    },
  },
};
</script>
<style>
.light-blue {
  color: #109cf1 !important;
}
</style>