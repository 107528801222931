<template>
  <div>
    <v-card class="col-md-12 border-orange-bottom-8">
      <div
        v-if="!$route.path.includes('details')"
        class="row col-md-12 pt-0 pb-0 font-weight-bold text-gray-darker"
      >
        <v-col class="col-xl-5 col-lg-3 aling-items-center" cols="6">
          <span
            :class="
              tab == 'all'
                ? 'cursor-pointer dark-blue'
                : 'cursor-pointer text-gray'
            "
            @click="changeTab('all')"
            >All Solutions</span
          >
          <v-divider vertical class="text-end ml-5 mr-5"></v-divider>
          <span
            :class="
              tab == 'my'
                ? 'cursor-pointer dark-blue'
                : 'cursor-pointer text-gray'
            "
            @click="changeTab('my')"
            >My Solutions</span
          >
        </v-col>

        <v-col class="col-xl-2 col-lg-2 aling-items-center" cols="12">
          <v-text-field
            class="mr-lg-5"
            prepend-inner-icon="mdi-magnify"
            light
            dense
            v-model="search"
            hide-details
            @input="searchSolution"
            solo
            placeholder="Search Solutions"
          ></v-text-field>
        </v-col>

        <v-col class="col-xl-2 col-lg-3 d-flex aling-items-center" cols="12">
          Type:
          <v-select
            :items="typeItems"
            solo
            dense
            v-model="type"
            hide-details
            class="ml-3"
          ></v-select>
        </v-col>

        <v-col class="col-xl-3 col-lg-4 d-flex aling-items-center" cols="12">
          Sort By:
          <div>
            <v-select
              :items="sortItems"
              solo
              dense
              v-model="sortBy"
              hide-details
              class="ml-3"
            ></v-select>
          </div>
        </v-col>
      </div>
      <div v-else>
        <v-col class="col-xl-2 col-lg-2 aling-items-center" cols="6">
          <span
            class="mt-1 cursor-pointer"
            @click="$router.push('/marketplace/home')"
          >
            <svg
              width="10"
              height="20"
              viewBox="0 0 14 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.6269 23.764L1.99634 12.9562C1.92725 12.8861 1.87267 12.803 1.83571 12.7118C1.79875 12.6205 1.78014 12.5229 1.78095 12.4245C1.78176 12.326 1.80197 12.2287 1.84042 12.1381C1.87888 12.0474 1.93482 11.9653 2.00505 11.8963L12.8118 1.26475"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <span
            class="size-18 ml-4 font-weight-bold cursor-pointer"
            @click="$router.push('/marketplace/home')"
            >Back to Solutions</span
          >
        </v-col>
      </div>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tab: "all",
      search: "",
      type: "Categories",
      sortBy: "Newest",
      typeItems: [{ text: "Categories", value: "Categories" }],
      sortItems: [{ text: "Newest", value: "Newest" }],
    };
  },
  methods: {
    changeTab(tab) {
      this.tab = tab;
      this.$store.commit("changeTabMarketPlace", tab);
    },
    searchSolution() {
      this.$store.commit("searchMarketplace", this.search);
    },
  },
};
</script>

<style>
.border-orange-bottom-8 {
  border-bottom: 8px solid orange !important;
}
.aling-items-center {
  display: flex;
  align-items: center;
}
.cursor-pointer {
  cursor: pointer;
}
.size-18 {
  font-size: 18px !important;
}
</style>
