const state ={
    advisor : null,
    advisorProvider : null,
    statistics:null,
    statisticsHorizontalMenu:null,
    allPartners:[],
    routeFromHomeCard:false,

}
const getters = {
    getAdvisorGetter(state){
        return state.advisor
      },
      getAdvisorProvider(state){
        return state.advisorProvider
      },
      getStatistcis: () =>  state.statistics,
      getStatistcisInHorizontalMenu: () => state.statisticsHorizontalMenu,
      getAllPartners:() => state.allPartners,
      checkIfIsFromHome:() => state.routeFromHomeCard

}
const mutations = {
    setSingleAdvisor(state, advisor){
        state.advisor = advisor
      },
      setSingleAdvisorProvider(state,provider){
          state.advisorProvider = provider
      },
      setStatisticsInfo(state,statistics){
          state.statistics = statistics
      },
      setStatisticsInfoHorizontalMenu(state,statisticsHorizontalMenu){
          state.statisticsHorizontalMenu = statisticsHorizontalMenu
      },
      setAllPartners(state,allPartners){
          state.allPartners = allPartners
      },
      routeFromHomeCard(state,param){
         state.routeFromHomeCard = param
      }
    }

export default{
    state,
    getters,
    mutations,
}