
const state ={
    searchResult:[],
    urlData:"",
    singleProviderInEmployerAdvisor:null,
    providerType:'',
    employerContractStatus:'',
    isFromExcel:false,
  
}
const getters = {
    getSearchProviders(state){
        return state.searchResult
    },
    getSearchUrl(state){
        return state.urlData
    },
    getSingleProviderInemployerAdvisor(state){
         return state.singleProviderInEmployerAdvisor
    },
    getProviderType(state){
        return state.providerType
    },
    getEmpContractStatus(state){
        return state.employerContractStatus
    },
    getExcelType(state){
        return state.isFromExcel
    }

}
const mutations = {
    setSearchResultProviders(state,data){
            
        state.searchResult = data
    },
    checkIsFromExcel(state, bool){
        state.isFromExcel = bool
    },
    setUrlData(state,url){
          state.urlData = url
    },
    setSingleProviderInEmployerAdvisor(state,provider){
      state.singleProviderInEmployerAdvisor = provider
    },
    setProviderType(state,type){
         state.providerType = type
    },
    setEmpContractStatus(state,status){
      state.employerContractStatus = status
    }
   
    
}

export default{
    state,
    getters,
    mutations,
}

