<template>
<div>
    <Partners v-if="route == '/provider-advisor/partners'"></Partners>
    <AddPartner v-if="route == '/provider-advisor/partners/add-new'"></AddPartner>
    <Result v-if="route == '/provider-advisor/partners/add-new/result'"></Result>
    <InvitePartner v-if="route == '/provider-advisor/partners/invite-partner'"></InvitePartner>
    <Partner v-if="route == '/provider-advisor/partners/partner'"></Partner>

</div>

</template>
<script>
import Partners from './PartnersTable.vue'
import AddPartner from './AddPartner.vue'
import InvitePartner from './InvitePartner.vue'
import Partner from './Partner.vue'
import Result from './Result.vue'

export default{
    components:{
        Partners,
        AddPartner,
        InvitePartner,
        Partner,
        Result
    },
    computed:{
        route(){
            return this.$route.path
        }
    }
}
</script>