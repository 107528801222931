<template>
  <div class="row-height">
    <div v-if="!isChildren">
      <div class="row  col-12 border-blue-bottom no-padding" style="height: 112px !important;">
        
        <div class="col-xl-4 col-lg-5 col-md-6 col-sm-12 mt-2 row">
          <div
            class="
              col-md-3
              pt-5
              text-center
              pointer
              font-weight-bold
              header-page-titles
            "
            @click="tab = 'solution'"
            :class="tab == 'solution' ? 'light-blue-text' : 'text-gray'"
          >
            Find Solutions
          </div>
          <div class="mt-2 mb-2">
            <v-divider vertical class="dividers-margin"></v-divider>
          </div>

          <div
            class="
              col-md-3
              pt-5
              text-center
              pointer
              font-weight-bold
              header-page-titles
            "
            @click="tab = 'favorites'"
            :class="tab == 'favorites' ? 'light-blue-text' : 'text-gray'"
          >
            Favorites
          </div>
          <div class="mt-2 mb-2">
            <v-divider vertical class="dividers-margin"></v-divider>
          </div>
          <div
            class="
              col-md-6
              pt-5
              text-xl-left text-lg-left text-md-center text-sm-center
              pointer
              font-weight-bold
              header-page-titles
            "
            @click="tab = 'package'"
            :class="tab == 'package' ? 'light-blue-text' : 'text-gray'"
          >
            <span class="pl-3"> Solution Packages</span>
          </div>
        </div>
        <div class="col-lg-7 d-flex">
        <div class="col-xl-2 col-lg-3 col-md-2 col-sm-2 mt-2">
          <v-text-field
            class="input-control mr-5 col-md-6"
            prepend-inner-icon="mdi-magnify"
            @input="searchInput"
            light
            dense
            outlined
            placeholder="Search Solutions"
          ></v-text-field>
        </div>
        <div
          class="col-xl-5 col-lg-6 col-md-12 col-sm-12 row justify-end"
          align="end"
        >
          <v-col
            class="col-xl-6 col-lg-6 col-md-6 d-flex justify-end col"
            align="end"
          >
            <span class=" ml-4 col-lg-2"> Type: </span>
            <div class=" col-lg-10">
              <v-select
                style="width: 250px"
                :items="typeItems"
                solo
                dense
                v-model="typeBy"
                hide-details
                class="ml-3 "
                @change="filterItemsByType('type')"
              ></v-select>
            </div>
          </v-col>
          <v-col class="col-xl-6 col-lg-6 col-md-6 d-flex justify-end col" align="end">
            <span class=" col-lg-2"> Sort By: </span>
            <div class="col-lg-10">
              <v-select
                style="width: 250px"
                :items="sortItems"
                solo
                dense
                v-model="sortBy"
                hide-details
                class="ml-3"
                @change="filterItemsByType('sort')"
              ></v-select>
            </div>
          </v-col>
        </div>
        <div class="col-xl-1 col-lg-1 col-md-12 col-sm-12 mt-2" align="end">
          <v-btn class="gray-border-buttons" @click="dialog = true">
            <svg
              class="mr-2"
              width="16"
              height="15"
              viewBox="0 0 16 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.741211 7.5H15.7774"
                stroke="#707070"
                stroke-width="2"
                stroke-linejoin="round"
              />
              <path
                d="M8.25977 0V15"
                stroke="#707070"
                stroke-width="2"
                stroke-linejoin="round"
              />
            </svg>

            Invite
          </v-btn>
        </div>
        </div>
      </div>



      <div class="mt-3">
        <v-simple-table
          fixed-header
          height="70vh"
          class="no-padding tr-custom-height"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">VENDOR NAME</th>
                <th class="text-left">CATEGORY</th>
                <th class="text-left">DESCRIPTION</th>
                <th class="text-left">FAVORITES</th>
              </tr>
            </thead>
            <tbody
              v-if="items.length > 0 && !filterItems"
              class="text-gray tr-custom-height"
            >
              <tr v-for="(item, index) in items" :key="index">
                <td v-if="item.vendor" @click="showSetails(item)">
                  <a>{{ item.vendor.companyName }}</a>
                </td>
                <td v-else><a>/</a></td>
                <td v-html="item.category ? item.category : '/'"></td>
                <td v-html="item.description ? item.description : '/'"></td>
                <td>
                  <div @click="fillArray(item, index)">
                    <v-rating
                      v-if="item.rating"
                      :value="1"
                      length="1"
                      background-color="orange lighten-3"
                      color="orange"
                      large
                    ></v-rating>
                    <v-rating
                      v-else
                      length="1"
                      background-color="orange lighten-3"
                      color="orange"
                      large
                    ></v-rating>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="items.length < 1 && filterItems">
              <v-col md="12" cols="12">
                <v-alert
                  border="bottom"
                  colored-border
                  type="warning"
                  elevation="2"
                  class="text-gray"
                >
                  Sorry! No results found
                </v-alert>
              </v-col>
            </tbody>

            <tbody v-else>
              <v-col md="12" v-if="tab == 'favorites'">
                There are no favourite solutions currenlty.
              </v-col>
              <v-col md="12" v-else> There are no solutions currenlty. </v-col>
            </tbody>
          </template>
        </v-simple-table>

        <v-dialog v-model="dialog" persistent max-width="800px">
          <v-card>
            <v-card-title>
              <span class="text-h5 text-underline col-md-12 font-weight-bolder"
                >Invite a Solution Vendor</span
              >
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form class="row" ref="form" v-model="valid">
                  <v-col cols="12" sm="12" md="12">
                    <label for="">Company Name</label>
                    <v-text-field
                      hide-details
                      :rules="requiredRules"
                      v-model="companyName"
                      solo
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <label for="">Contact First Name</label>
                    <v-text-field
                      hide-details
                      solo
                      :rules="requiredRules"
                      required
                      v-model="firstName"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <label for="">Contact Last Name</label>
                    <v-text-field
                      hide-details
                      :rules="requiredRules"
                      solo
                      required
                      v-model="lastName"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <label for="">Email</label>
                    <v-text-field
                      hide-details
                      solo
                      :rules="emailRules"
                      required
                      v-model="email"
                    ></v-text-field>
                  </v-col>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <div class="col-md-12" align="end">
                <span
                  class="text-underline text-gray mr-10 pointer"
                  @click="dialog = false"
                  >Cancel</span
                >
                <span
                  class="text-underline text-gray pointer"
                  @click="inviteSolutions"
                  >Invite</span
                >
              </div>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <successModal
        ref="success_modal"
        v-on:confirm="closeSuccessModal"
      ></successModal>

      <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import api from "../../../../services/api";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../TablesTemplate/ErrorDialog.vue";

export default {
  components: { successModal, errorModal },
  data() {
    return {
      showDetails: false,
      tab: "solution",
      dialog: false,
      items: [],
      singleItem: null,
      typeItems: [
        { text: "All Categories" },
        { text: "DPC" },
        { text: "Others" },
      ],
      sortItems: [{ text: "Newest" }, { text: "Oldest" }],
      sortBy: "",
      typeBy: "",

      type: "",
      solutions: [],
      favorites: [],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
      valid: false,
      companyName: "",
      firstName: "",
      lastName: "",
      email: "",
      filterArray: [],
      filterItems: false,
    };
  },
  watch: {
    tab(to, from) {
      this.getSolutions();
    },
  },
  computed: {
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
  },
  created() {
    this.getSolutions();
  },
  methods: {
    filterItemsByType(type) {
      if (type == "sort") {
        if (this.sortBy == "Oldest") {
          this.items = this.filterArray.sort(
            (a, b) =>
              Number(new Date(a.createdAt)) - Number(new Date(b.createdAt))
          );
        } else {
          this.items = this.filterArray.sort(
            (a, b) =>
              Number(new Date(b.createdAt)) - Number(new Date(a.createdAt))
          );
        }
      } else {
        if (this.typeBy != "All Categories") {
          this.items = this.filterArray.filter((el) => {
            return el.category == this.typeBy;
          });
        } else {
          this.items = this.filterArray;
        }
      }
    },
    closeErrorModal() {
      this.dialog = false;
      this.companyName = "";
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.$refs.success_modal.closePopUp();
      this.$refs.error_modal.closePopUp();
    },
    closeSuccessModal() {
      this.dialog = false;
      this.companyName = "";
      this.firstName = "";
      this.lastName = "";
      this.email = "";

      this.$refs.success_modal.closePopUp();
    },
    inviteSolutions() {
      this.$refs.form.validate();
      if (this.valid) {
        let data = {
          companyName: this.companyName,
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
        };
        api()
          .post("/tpas/solutions/vendor", data)
          .catch((res) => {
            if (res) {
              this.$refs.error_modal.openPopUp(res.response.data);
            }
          })
          .then((res) => {
            if (res) {
              this.$refs.success_modal.openPopUp("Invited Successfully");
            }
            //ratof41600@sopulit.com
          });
      }
    },
    getSolutions() {
      api()
        .get("/tpas/solutions")
        .then((res) => {
          this.items = [];
          this.filterArray = [];
          if (this.tab == "favorites") {
            res.data.solutions.forEach((el) => {
              res.data.favorites.forEach((response) => {
                if (el._id == response) {
                  el.rating = true;
                  this.items.push(el);
                  this.filterArray.push(el);
                }
              });
            });
          } else {
            res.data.solutions.forEach((el) => {
              res.data.favorites.forEach((response) => {
                if (el._id == response) {
                  el.rating = true;
                }
              });
            });
            this.items = res.data.solutions;
            this.filterArray = this.items;
          }
        });
    },
    showSetails(item) {
      this.singleItem = item;
      this.$store.commit("setSingleSolution", item);
      this.$router.push("/digital-invoicing/solutions/details");
    },
    fillArray(item, index) {
      let data = {
        solutions: [item._id],
      };
      item.rating = !item.rating;
      if (item.rating) {
        api()
          .put("/tpas/solutions/favorite", data)
          .then((res) => {
            if (!item.rating) {
              this.favorites.push(item);
            } else {
              this.favorites.splice(this.favorites.indexOf(item), 1);
            }
          });
      } else {
        api()
          .delete("/tpas/solutions/favorite/" + item._id)
          .then((res) => {
            if (!item.rating) {
              this.favorites.push(item);
            } else {
              this.favorites.splice(this.favorites.indexOf(item), 1);
            }
          });
      }
    },
    searchInput(val) {
      this.items = this.filterArray.filter((el) => {
        if (
          el.vendor &&
          el.vendor.companyName &&
          el.vendor.companyName
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el.vendor.companyName
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el.category &&
          el.category.toString().toLowerCase().includes(val.toLowerCase())
        ) {
          return el.category
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        }
      });
      if (this.items.length < 1) {
        this.filterItems = true;
      } else {
        this.filterItems = false;
      }
    },
  },
};
</script>
<style>
</style>