<template>
    <div>
    <div class="row blue-underline margin-l-11">
        <div class="col-md-12 d-flex justify-start text-start mt-3 p-0">
            <img
            id="logo-img"
            :src="'/newapalylogoblue.png'"
            class=" text-start pointer ml-3 mr-3"
            width="120"
          />
       


        </div>

      </div>
    <div class="form-div mt-100">
        
        <v-form class="col-md-12 col-xl-12 col-lg-12 text-center mt-10" ref="form">
            <div class="mt-10"> 
                <div class="col-md-12 mb-20 mt-5 justify-center d-flex" >
                    <div class="mt-3 col-7 justify-center">
                        <h1>
                     <br> 
                     <h3 class="login">
                        {{ message }} 
                     </h3>
                    
                   </h1>
                    </div>
                    
            
                </div>
                <div class="mt-5">
                  <h2>
                   <a @click="$router.push('/login')" class="text-underline mt-10">
                       Go to Login</a>
              </h2>
                </div>
               
               </div>
          </v-form>
    </div>
</div>
  </template>
  <script>
  import api from "../../services/api";
  import Vue from "vue";
  import { mapActions } from "vuex";

  export default {
    data() {
      return {
      message: "",
      
      };
    },
    created() {
  this.message=this.$route.query.message;

    },
    methods: {

   
    },
  };
  </script>
  <style scoped>


</style>
  