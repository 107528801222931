<template>
  <div class="no-padding row">
    <div class="col-md-12 no-padding">

        <successModal
        ref="success_modal"
        v-on:confirm="closeSuccessModal"
      ></successModal>
      
  <errorModal 
    ref="error_modal"
     v-on:confirm="closeErrorModal">
    </errorModal>
      <div class="col-md-12 ">
        <v-form
          ref="form"
          v-model.trim="valid"
          class="col-md-12 form-row-height"
        >
         
          <v-row class="mt-3">
            <div class="col-lg-12 col-xl-12 col-md-12 align-center no-padding">
              <h4 class="settings-text text-gray ml-3 mb-3 text-underline">
                New member subscription notifications
              </h4>
            </div>
            <div class="col-lg-12 col-xl-12 col-md-12 align-center no-padding">
              <label class="text-gray font ml-3" for=""
                >Enter email(s) where notifications should be sent (seperate
                with semi-colon):</label
              >
              <v-row justify="space-around">
                <v-col cols="12">
                  <v-text-field
                    class="ml-3"
                    style="width: 40%; float: left"
                    v-model.trim="email1"
                    :rules="email1 ? emailRules : []"
                    solo
                  >
                    <template v-slot:append>
                      <v-btn icon @click="pushToTags('1')"
                        ><v-icon>mdi-plus</v-icon></v-btn
                      >
                    </template>
                  </v-text-field>

                  <v-chip-group
                    mandatory
                    class="primary--text"
                    :class="tags.length > 4 ? 'col-md-11 mb-10 no-padding' : ''"
                  >
                    <v-chip
                      v-for="(tag, index) in tags"
                      :key="index"
                      class="primary--text v-chip--active ml-1"
                    >
                      {{ tag }}
                      <i
                        class="fa fa-times fa-2xs ml-1"
                        @click="removeFromTags(index, '1')"
                      ></i>
                    </v-chip>
                  </v-chip-group>
                </v-col>
              </v-row>
            </div>
          </v-row>
          <v-row class="mt-3">
            <div class="col-lg-12 col-xl-12 col-md-12 align-center no-padding">
              <h4 class="settings-text text-gray ml-3 mb-3 text-underline">
                Billing related notifications
              </h4>
            </div>
            <div class="col-lg-12 col-xl-12 col-md-12 align-center no-padding">
              <label class="text-gray font ml-3" for=""
                >Enter email(s) where notifications should be sent (seperate
                with semi-colon):</label
              >
              <v-row justify="space-around">
                <v-col cols="12">
                  <v-text-field
                    class="ml-3"
                    style="width: 40%; float: left"
                    v-model.trim="email2"
                    :rules="email2 ? emailRules : []"
                    solo
                  >
                    <template v-slot:append>
                      <v-btn icon @click="pushToTags('2')"
                        ><v-icon>mdi-plus</v-icon></v-btn
                      >
                    </template>
                  </v-text-field>

                  <v-chip-group
                    mandatory
                    class="primary--text"
                    :class="
                      tags2.length > 4 ? 'col-md-11 mb-10 no-padding' : ''
                    "
                  >
                    <v-chip
                      v-for="(tag, index) in tags2"
                      :key="index"
                      class="primary--text v-chip--active ml-1"
                    >
                      {{ tag }}
                      <i
                        class="fa fa-times fa-2xs ml-1"
                        @click="removeFromTags(index, '2')"
                      ></i>
                    </v-chip>
                  </v-chip-group>
                </v-col>
              </v-row>
            </div>
          </v-row>
          <v-row class="mt-3">
            <div class="col-lg-12 col-xl-12 col-md-12 align-center no-padding">
              <h4 class="settings-text text-gray ml-3 mb-3 text-underline">
                General notifications
              </h4>
            </div>
            <div class="col-lg-12 col-xl-12 col-md-12 align-center no-padding">
              <label class="text-gray font ml-3" for=""
                >Enter email(s) where notifications should be sent (seperate
                with semi-colon):</label
              >
              <v-row justify="space-around">
                <v-col cols="12">
                  <v-text-field
                    class="ml-3"
                    style="width: 40%; float: left"
                    v-model.trim="email3"
                    :rules="email3 ? emailRules : []"
                    solo
                  >
                    <template v-slot:append>
                      <v-btn icon @click="pushToTags('3')"
                        ><v-icon>mdi-plus</v-icon></v-btn
                      >
                    </template>
                  </v-text-field>

                  <v-chip-group
                    mandatory
                    class="primary--text"
                    :class="
                      tags3.length > 4 ? 'col-md-11 mb-10 no-padding' : ''
                    "
                  >
                    <v-chip
                      v-for="(tag, index) in tags3"
                      :key="index"
                      class="primary--text v-chip--active ml-1"
                    >
                      {{ tag }}
                      <i
                        class="fa fa-times fa-2xs ml-1"
                        @click="removeFromTags(index, '3')"
                      ></i>
                    </v-chip>
                  </v-chip-group>
                </v-col>
              </v-row>
            </div>
          </v-row>
          <!-- 
           <div class="row ">
             <h4 class="text-gray font text-gray text-underline">Rate Visibility</h4>
           </div>
           <v-row>
            <div class="d-flex  col-md-6 align-center no-padding">
              <v-checkbox v-model.trim="participate"></v-checkbox> <span class="font text-gray">We do not want to participate as a <a>Preferred Provider.</a></span>
            </div>
           </v-row>-->
        </v-form>
        <v-snackbar v-model.trim="snackbar" :timeout="1000" color="red">
          {{ snackbarText }}

          <template v-slot:action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </div>
      <div
        class="row text-end justify-end "
        style="margin-left: -11px"
      >
        <div class="col-md-12 mt-5 mb-5">
          <span
            class="text-underline col-md-12 pointer pr-10"
            style="color:#0572F3;"
            @click="submit"
            >Save</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../../../../../services/api";
import successModal from "../../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../../TablesTemplate/ErrorDialog.vue";
export default {
  components: {
    successModal, errorModal
  },
  data() {
    return {
      sendMail: false,
      sendMail1: "",
      participate: false,
      hideRates: false,
      clickToShow: false,
      requestToShow: false,
      sendNotification: false,
      emails: [],
      tags: [],
      tags2: [],
      tags3: [],
      snackbar: false,
      snackbarText: "",
      myDeleteArray: [],
      myAddArray: [],
      email: "",
      email1: "",
      email2: "",
      email3: "",
      email4: "",
      newEmail: "",
      valid: false,
      valid1: false,
      serviceType: false,
      domainRules: [
        (v) => !!v || "This field is required",
        (v) =>
          /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/.test(v) ||
          "Email domain is not valid!",
      ],
      emailRules: [
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
    };
  },
  created() {
    this.getSettings();
  },
  methods: {
    getSettings() {
      api()
        .get("/dpcproviders/settings")
        .then((res) => {

          this.serviceType = res.data.serviceType == "member" ? false : true;
          this.tags = res.data.subscribeNotify;
          this.tags2 = res.data.billNotify;
          this.tags3 = res.data.generalNotify;

        });
    },
    submit() {
      let data = {
     
        subscribeNotify: this.tags,
        billNotify: this.tags2,
        generalNotify: this.tags3,
      };

      api()
        .put(`/dpcproviders/settings`, data)
        .catch((err) => {
                  if (err.response.status == 500) {
                    this.$refs.error_modal.openPopUp(
                      "There was an error, system admin is notified. Please try again later."
                    );}
                   else{
                    this.$refs.error_modal.openPopUp(err.response.data)
                   }
                  
                })
        .then((res) => {
          if (res) {
            let text = "Settings updated successfully";
            this.$refs.success_modal.openPopUp(text);
          }
        });
    },
    pushToTags(row) {
      if (row == "1") {
        if (
          this.email1 != null &&
          /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email1)
        ) {
          let index = this.tags.findIndex((c) => c == this.email1);
          if (index == -1) {
            this.tags.push(this.email1);
            this.email1 = "";
          } else {
            this.snackbarText = "Email already exists";
            this.snackbar = true;
          }
        } else {
          this.snackbarText = "Email is not valid";
          this.snackbar = true;
        }
      } else if (row == "2") {
        if (
          this.email2 != null &&
          /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email2)
        ) {
          let index = this.tags2.findIndex((c) => c == this.email2);
          if (index == -1) {
            this.tags2.push(this.email2);
            this.email2 = "";
          } else {
            this.snackbarText = "Email already exists";
            this.snackbar = true;
          }
        } else {
          this.snackbarText = "Email is not valid";
          this.snackbar = true;
        }
      } else {
        if (
          this.email3 != null &&
          /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email3)
        ) {
          let index = this.tags3.findIndex((c) => c == this.email3);
          if (index == -1) {
            this.tags3.push(this.email3);
            this.email3 = "";
          } else {
            this.snackbarText = "Email already exists";
            this.snackbar = true;
          }
        } else {
          this.snackbarText = "Email is not valid";
          this.snackbar = true;
        }
      }
    },
    removeFromTags(index, row) {
      if (row == "1") {
        this.tags.splice(index, 1);
      } else if (row == "2") {
        this.tags2.splice(index, 1);
      } else {
        this.tags3.splice(index, 1);
      }
    },

    fillDomainRules() {
      this.domainRules = [
        (v) => !!v || "This field is required",
        (v) =>
          /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/.test(v) ||
          "Email domain is not valid!",
      ];
    },
    pushToArray() {
      this.$refs.form1.validate();

      let data = {
        domain: this.newEmail,
      };
      if (this.valid1) {
        api()
          .post("/auth/validateEmail", data)
          .catch((err) => {
            if (err) {
              this.domainRules = ["Only company email domains are allowed"];
            }
          })
          .then((res) => {
            if (res) {
              api()
                .get("/auth/isValidDomain?domain=" + this.newEmail)
                .catch((err) => {
                  if (err) {
                    this.domainRules = [err.response.data.message];
                    exist = true;
                  }
                })
                .then((res) => {
                  let regex =
                    /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/;
                  let check = regex.test(this.newEmail);
                  if (this.newEmail && this.valid1 && check) {
                    this.emails.push({ domain: this.newEmail });
                    this.myAddArray.push({ domain: this.newEmail });
                    this.$refs.success_modal.openPopUp(
                      "Domain added successfully"
                    );
                    this.$refs.form.resetValidation();
                    this.newEmail = "";
                  }
                });
            }
          });
      }
    },
    removeFromArray(email) {
      this.myDeleteArray.push(email);
      for (let i = 0; i < this.emails.length; i++) {
        if (this.emails[i] == email) {
          this.emails.splice(i, 1);
        }
      }
      this.$refs.success_modal.openPopUp("Domain was successfully deleted!");
    },
    showOrHideRates(text) {
      if (text == "hide") {
        this.hideRates = true;
        this.requestToShow = false;
      } else {
        this.hideRates = false;
        this.requestToShow = true;
      }
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
  },
};
</script>

<style>
</style>
