const state ={
    network : null,
}
const getters = {
    getNetworkGetter(state){
        return state.network
      },
}
const mutations = {
    setSingleNetwork(state, network){
       
        state.network = network
      },
}

export default{
    state,
    getters,
    mutations,
}