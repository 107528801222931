import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import vuetify from './plugins/vuetify'
import css from './index.scss'
import VueCookies from 'vue-cookies'
import VueGtag from 'vue-gtag'
import Rollbar from 'rollbar';
import moment from 'moment'
import { VueMaskDirective } from 'v-mask'
import * as VueGoogleMaps from "vue2-google-maps" // Import package

Vue.use(require('vue-moment'));

Vue.directive('mask', VueMaskDirective);

Vue.use(VueCookies)

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAOrUhXnTGLFFHe_5msFou6964vikUph0s",
    libraries: "places"
  }
});
Vue.use(VueGtag, {
  config: {
    id: "G-PS33H5R2CJ",
  }
});
Vue.prototype.$rollbar = new Rollbar({
  accessToken: 'bbeca4fdf4f146e6a307199e737eecbf',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    // Track your events to a specific version of code for better visibility into version health
    code_version: '1.0.0',
    // Add custom data to your events by adding custom key/value pairs like the one below
    custom_data: 'foo'
  }
});
Vue.config.errorHandler = (err, vm, info) => {
  vm.$rollbar.error(err);
  throw err; // rethrow
};
Vue.filter("formatDate", function(value){
  // value.split("T")[0]
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY')
  }
} );

Vue.config.productionTip = false;
if(localStorage.getItem('user')){
  let user = JSON.parse(localStorage.getItem('user'));
  Vue.prototype.$role = user.role;
}else{
  Vue.prototype.$role = "no-login"
}


new Vue({
  router,
  store,
  vuetify,
  css,
  VueCookies,
  render: h => h(App)
}).$mount('#app')
