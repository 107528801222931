<template>
  <v-row class="add-client-form no-padding">
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <div class="col-md-12 no-padding mt-4">
      <div class="orange-underline-wizard row" style="margin: -11px">
        <div class="col-md-12">
          <h3 class="form-title font ml-2 mb-2">New Client</h3>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <v-form v-model.trim="valid" ref="form" class="row">
        <div class="col-md-6">
          <div class="col-md-12 pt-2 pb-0">
            <label class="font" for="">Company Name</label>
            <v-text-field
              required
              :rules="requiredRules"
              v-model.trim="client.companyName"
              solo
            ></v-text-field>
          </div>

          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">Tax ID</label>
            <v-text-field
              required
              v-model.trim="client.taxId"
              @keyup="showSlash($event)"
              solo
              placeholder="00-0000000"
              :rules="taxIdRules"
              counter="10"
              maxlength="10"
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">Number of Employees</label>
            <v-text-field
              required
              v-model.trim="client.employeesNo"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">Number of Plan Members</label>
            <v-text-field
              required
              v-model.trim="client.membersNo"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">HQ Address Line 1</label>
            <v-text-field
              required
              v-model.trim="client.address1"
              :rules="requiredRules"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">HQ Address Line 2</label>
            <v-text-field
              required
              v-model.trim="client.address2"
              solo
            ></v-text-field>
          </div>
          <v-row class="no-padding">
            <div class="col-md-6 pt-0 pb-0">
              <label class="font text-gray" for="">City</label>
              <v-text-field
                required
                v-model.trim="client.city"
                solo
                :rules="requiredRules"
              ></v-text-field>
            </div>
            <div class="col-md-3 pt-0 pb-0">
              <label class="font text-gray" for="">State</label>
              <v-select
                :items="states"
                item-text="name"
                item-value="name"
                required
                v-model.trim="client.state"
                solo
                :rules="requiredRules"
              ></v-select>
            </div>
            <div class="col-md-3 pt-0 pb-0">
              <label class="font text-gray" for="">Zip</label>
              <v-text-field
                required
                type="number"
                v-model.trim="client.zip"
                solo
                :rules="zipRules"
              ></v-text-field>
            </div>
          </v-row>
        </div>
        <div class="col-md-6">
          <div class="col-md-12 pt-2 pb-0">
            <label class="font text-gray" for=""
              >Primary Contact First Name
            </label>
            <v-text-field
              required
              v-model.trim="client.firstName"
              solo
              :rules="requiredRules"
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for=""
              >Primary Contact Last Name</label
            >
            <v-text-field
              required
              v-model.trim="client.lastName"
              solo
              :rules="requiredRules"
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">Primary Contact Title</label>
            <v-text-field
              required
              v-model.trim="client.title"
              solo
              :rules="requiredRules"
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">Primary Contact Email</label>
            <v-text-field
              required
              :rules="emailRules"
              v-model.trim="client.email"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">Primary Contact Phone</label>
            <v-text-field
              required
              v-model.trim="client.phone"
              solo
              :rules="phoneRules"
            ></v-text-field>
          </div>
        </div>
      </v-form>
    </div>
    <div class="col-md-12 no-padding">
      <div class="gray-underline-wizard row margin-l-11">
        <div class="text-end justify-end col-md-12 mt-5 mb-5 pb-5 pr-5">
          <span
            class="mr-5 text-underline text-gray pointer pr-5"
            @click="$router.go(-1)"
            >Cancel</span
          >
          <span class="text-underline text-gray pointer pr-5" @click="update()"
            >Save & Invite</span
          >
        </div>
      </div>
    </div>
    <v-dialog v-model.trim="requestDialog" width="800" class="success-modal">
      <v-card>
        <v-card-text class="success-modal pt-5">
          <span align="end" class="pt-0 pr-0">
            <p class="font-weight-bold" @click="requestDialog = false">X</p>
          </span>
          <div class="display">
            <span
              class="text-center font-18 mt-2 font-weight-bold color-black mt-5"
            >
              Request sent! 
            </span>
            <br />
            <span class="text-center font-18 mt-2 text-gray mt-5">
              We've sent an email requesting the activation of your company <br> 
              to this client's account <br>
              Once approved, you will be able to submit invoices to this client
               
            </span>
          </div>
          <div class="text-center text--white mt-10">
            <v-btn
              color="#16407A"
              class="submit-btn"
              @click="$router.push('/vendors/clients')"
            >
              Okay
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
  </v-row>
</template>
<script>
import api from "../../../../services/api";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../TablesTemplate/ErrorDialog.vue";
export default {
  components: { successModal,errorModal },
  data() {
    return {
      requestDialog: false,
      client: {
        companyName: "",
        taxId: "",
        logo: "",
        phone: "",
        title: "",
        firstName: "",
        lastName: "",
        email: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        displayName: "",
        membersNo: "",
        employeesNo: "",
        tpas: [],
      },

      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
      states: [],
      discountRate: "",
      valid: false,
      zipRules: [
        (v) => (v && v.length == 5) || " Zip Code should be five digits long",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
      emailRules: [
        (v) => !!v || "This field is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      taxIdRules: [
        (v) => !!v || "This field is required",
        (v) =>
          !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
          "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
      ],
    };
  },
  computed: {
    singleMember() {
      return this.$store.getters.getSingleMember;
    },
  },
  created() {
    this.getStates();
  },
  methods: {
    closeErrorModal(){
      this.$refs.error_modal.closePopUp();
    },
    showSlash(event) {
      //  xedic69819@ishyp.com
      if (this.client.taxId.length == 2 && event.keyCode != 8) {
        this.client.taxId += "-";
      }
    },
    activateFileInput() {
      this.$refs.fileInput.$refs.input.click();
    },
    getStates() {
      api()
        .get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },

    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();

      this.$router.push("/vendors/clients");
    },

    update() {
      this.$refs.form.validate();
      if (this.valid) {
        let data = {
          companyName: this.client.companyName,
          taxId: this.client.taxId,
          noEmployees: this.client.noEmployees,
          noPlanMembers: this.client.noPlanMembers,
          address1: this.client.address1,
          address2: this.client.address2,
          city: this.client.city,
          state: this.client.state,
          zip: this.client.zip,
          firstName: this.client.firstName,
          lastName: this.client.lastName,
          title: this.client.title,
          email: this.client.email,
          phone: this.client.phone,
          status: this.client.status,
          tpas: this.client.tpas,
          noEmployees:this.client.employeesNo,
          noPlanMembers:this.client.membersNo
        };
        
        api()
          .post(`/vendors/clients`, data).catch((err) => {
          if (err) {
            this.$refs.error_modal.openPopUp(err.response.data);
          }
        })
          .then((res) => {
            if (res) {
              this.$parent.getData();
              this.$refs.success_modal.openPopUp("Client added successfully");
            }
          });
      }
    },
  },
};
</script>