<template>
    <v-row class="add-client-form no-padding">
        <div class="col-12 d-flex pt-14 pl-5 pr-5 justify-center">
            <div class="col-12">
                <!-- initial card -->
                <v-card v-if="!results" class="mx-auto" max-width="450">
                    <v-alert v-if="alert.value" :type="alert.type" border="top" shaped dismissible> {{ alert.message }} </v-alert>
                    <!-- container -->
                    <div class="pl-5 pr-5">
                        <div class="pt-10 text-center pb-3 font-weight-bold font-18">
                            <h2>Providers</h2>
                        </div>
                        <div class="pt-3 text-center pb-3 font-weight-bold font-14">
                            <h3>
                                Want to see how many members live in your area?
                            </h3>  
                        </div>
                        <!-- input -->
                        <div class="pt-3 pb-3">
                            <v-text-field
                                v-model="zipCode"
                                :rules="[zipCodeRule]"
                                class="centered-input custom-input-placeholder rounded-xl"
                                light
                                dense
                                outlined
                                placeholder="Enter your Zip Code"
                                @keypress.enter="search"
                            ></v-text-field>
                        </div>
                        <!-- submit -->
                        <div class="pb-10">
                            <v-btn
                                @click="search"
                                :loading="loading"
                                class="submit-button"
                                color="#16407A"
                                rounded
                                block
                                dark
                            > Submit</v-btn>
                        </div>
                    </div>
                </v-card>
                <!-- results card -->
                <v-card v-else class="mx-auto" max-width="450">
                    <!-- container -->
                    <div class="pl-5 pr-5">
                        <div class="pt-10 text-center pb-3 font-weight-bold font-18">
                            <h1>{{ memberCount }}</h1>
                        </div>
                        <div class="pt-3 text-center pb-3 font-weight-bold font-14">
                            <h3>
                                members live in your area
                            </h3>  
                        </div>
                        <!-- reset -->
                        <div class="pt-5 pb-10">
                            <v-btn @click="reset"
                                class="reset-button"
                                text
                                block
                                dark
                                x-large
                                color="primary"
                            >RESET</v-btn>
                        </div>
                    </div>
                </v-card>
            </div>
        </div>
    </v-row>
</template>

<script>
import api from "../../../services/api.js";
export default {
    data() {
        return {
            alert: {
                value: false,
                type: "error",
                message: "",
            },
            zipCode: null,
            results: false,
            memberCount: 0,
            loading: false,
            zipCodeRule: v => {
                if (!v) return false;
                if (!v.trim()) return false;
                if (!isNaN(parseFloat(v)) && v >= 1000 && v <= 99999) return true;
                return 'Please insert a valid zip code.';
            }
        }
    },
    methods: {
        search() {
            console.log(this.zipCodeRule(this.zipCode));

            if (this.zipCode !== null && this.zipCodeRule(this.zipCode) === true) {
                this.loading = true;
                api()
                    .get(`/employerplanmembers/nearby-members?zipCode=${this.zipCode}`)
                    .then((result) => {
                        if (result.data.totalCount) this.memberCount = result.data.totalCount;
                        this.results = true;
                    })
                    .catch((error) => { 
                        console.log(error);
                        this.triggerAlert('An error occurred while searching for the zip code.')
                    })
                    .finally(() => { this.loading = false; })
            }
        },

        reset() {
            this.results = false;
            this.zipCode = null;
            this.memberCount = 0;
        },

        triggerAlert(message, type) {
            this.alert.message = message;
            if (type) this.alert.type = type;
            this.alert.value = true;
            setTimeout(() => { this.alert.value = false; }, 5000);
        }
    }
}
</script>

<style scoped>
    .centered-input >>> input {
        text-align: center;
    }
    .custom-input-placeholder >>> input::placeholder {
        color: #1976D2 !important;
        opacity: 1;
        font-weight: bold;
    }
    .submit-button {
        font-weight: bold;
        font-size: 16px;
        min-height: 40px;
    }
    .reset-button {
        font-weight: bold;
        font-size: 20px;
        text-decoration-line: underline;
    }
</style>