const state ={
    singleUserEmpInEmployerAdvisor:null
}
const getters = {
    getSingleUserEmpInEmployerAdvisor(){
        return state.singleUserEmpInEmployerAdvisor
    }
}
const mutations = {
    setSingleUserInEmployerAdvisor(state,user){
        state.singleUserEmpInEmployerAdvisor = user
    },
    
}

export default{
    state,
    getters,
    mutations,
}