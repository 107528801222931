<template>
  <div class="add-client-form row-height no-padding">
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <div class="col-md-12">
      <h3 class="">
        <span class="font"> Settings </span>
      </h3>
      <div class="col-md-12 text-center justify-center" v-if="readyToShow">
        <v-progress-circular
          indeterminate
          :size="100"
          color="primary"
          class="text-center"
        ></v-progress-circular>
      </div>
      <v-form ref="form" v-model.trim="valid" v-else class="col-md-12">
        <v-row class="mt-3">
          <div class="col-lg-12 col-xl-5 col-md-12 align-center no-padding">
            <h4 class="settings-text text-underline">Notifications</h4>
            <div class="d-flex">
              <span class="settings-text text-gray mt-5"
                >Send all general platform notifications to the emails listed
                here:</span
              >
            </div>
          </div>
          <div class="col-lg-12 col-xl-7 col-md-12 col-md-12 no-padding">
            <label class="text-gray font ml-3" for=""
              >Enter email(s) where notifications should be sent:</label
            >
            <v-row justify="space-around">
              <v-col cols="12">
                <v-text-field
                  v-if="tags.length < 1 && email1.length < 1"
                  class="ml-3"
                  style="width: 40%; float: left"
                  v-model.trim="email1"
                  :rules="requiredRules"
                  solo
                >
                  <template v-slot:append>
                    <v-btn icon @click="pushToTags('1')"
                      ><v-icon>mdi-plus</v-icon></v-btn
                    >
                  </template>
                </v-text-field>
                <v-text-field
                  v-else
                  class="ml-3"
                  style="width: 40%; float: left"
                  v-model.trim="email1"
                  :rules="email1 ? emailRules : []"
                  solo
                >
                  <template v-slot:append>
                    <v-btn icon @click="pushToTags('1')"
                      ><v-icon>mdi-plus</v-icon></v-btn
                    >
                  </template>
                </v-text-field>

                <v-chip-group
                  mandatory
                  class="primary--text"
                  :class="tags.length > 4 ? 'col-md-11 mb-10 no-padding' : ''"
                >
                  <v-chip
                    v-for="(tag, index) in tags"
                    :key="index"
                    class="primary--text v-chip--active ml-1"
                  >
                    {{ tag }}
                    <i
                      class="fa fa-times fa-2xs ml-1"
                      @click="removeFromTags(index, '1')"
                    ></i>
                  </v-chip>
                </v-chip-group>
              </v-col>
            </v-row>
          </div>
        </v-row>
        <v-row class="mt-3">
          <div class="col-lg-12 col-xl-5 col-md-12 align-center no-padding">
            <h4 class="settings-text text-underline">Employer Access</h4>
            <div class="d-flex">
              <v-checkbox v-model.trim="sendMail"></v-checkbox>
              <span class="settings-text text-gray mt-5"
                >Send an email that enables us to approve or deny every employer
                relationship:</span
              >
            </div>
          </div>
          <div class="col-lg-12 col-xl-7 col-md-12 col-md-12 no-padding">
            <label class="text-gray font ml-3" for=""
              >Enter email(s) where notifications should be sent:</label
            >
            <v-row justify="space-around">
              <v-col cols="12">
                <v-text-field
                  class="ml-3"
                  style="width: 40%; float: left"
                  v-model.trim="email2"
                  :rules="email2 ? emailRules : []"
                  solo
                >
                  <template v-slot:append>
                    <v-btn icon @click="pushToTags('2')"
                      ><v-icon>mdi-plus</v-icon></v-btn
                    >
                  </template>
                </v-text-field>

                <v-chip-group
                  mandatory
                  class="primary--text"
                  style="height: 43px"
                >
                  <v-chip
                    v-for="(tag, index) in tags2"
                    :key="tag"
                    class="primary--text v-chip--active ml-2"
                  >
                    {{ tag }}
                    <i
                      class="fa fa-times fa-2xs ml-1"
                      @click="removeFromTags(index, '2')"
                    ></i>
                  </v-chip>
                </v-chip-group>
              </v-col>
            </v-row>
          </div>
        </v-row>

        <!-- 
           <div class="row ">
             <h4 class="text-gray font text-gray text-underline">Rate Visibility</h4>
           </div>
           <v-row>
            <div class="d-flex  col-md-6 align-center no-padding">
              <v-checkbox v-model.trim="participate"></v-checkbox> <span class="font text-gray">We do not want to participate as a <a>Preferred Provider.</a></span>
            </div>
           </v-row>-->
        <div class="row mt-4">
          <h4 class="text-gray settings-text text-underline">
            Rate Visibility
          </h4>
          <h4 class="text-gray settings-text col-md-12">Fee For Service</h4>
        </div>
        <div class="row">
          <div class="col-lg-12 col-xl-5 col-md-12 d-flex align-center">
            <v-checkbox
              v-model.trim="clickToShow"
              @click="
                () => {
                  if (clickToShow == false) {
                    requestToShow = false;
                    hideRates = false;
                  } else {
                    requestToShow = true;
                  }
                }
              "
            ></v-checkbox>
            <span class="settings-text"
              >Mask our Fee for Service Rates from employers, and use one of the
              two unmasking methods below:
            </span>
          </div>
          <div class="col-lg-12 col-xl-7 col-md-12 col-md-12 no-padding">
            <label class="text-gray font ml-3" for=""
              >Enter email(s) where notifications should be sent:</label
            >
            <v-row justify="space-around">
              <v-col cols="12">
                <v-text-field
                  class="ml-3"
                  style="width: 40%; float: left"
                  v-model.trim="email3"
                  :rules="email3 ? emailRules : []"
                  solo
                >
                  <template v-slot:append>
                    <v-btn icon @click="pushToTags('3')"
                      ><v-icon>mdi-plus</v-icon></v-btn
                    >
                  </template>
                </v-text-field>

                <v-chip-group
                  mandatory
                  class="primary--text"
                  style="height: 43px"
                >
                  <v-chip
                    v-for="(tag, index) in tags3"
                    :key="index"
                    class="primary--text v-chip--active ml-1"
                  >
                    {{ tag }}
                    <i
                      class="fa fa-times fa-2xs ml-1"
                      @click="removeFromTags(index, '3')"
                    ></i>
                  </v-chip>
                </v-chip-group>
              </v-col>
            </v-row>
          </div>
          <div class="row ml-6">
            <div class="col-md-12 d-flex align-center no-padding">
              <v-checkbox
                hide-details
                @click="showOrHideRates('request')"
                :disabled="!clickToShow"
                v-model.trim="requestToShow"
              ></v-checkbox>
              <label class="text-gray font mt-5" for=""
                >Require that employers click to show our rates, and then notify
                us when this occurs.</label
              >
            </div>
            <div class="col-md-12 d-flex align-center no-padding">
              <v-checkbox
                hide-details
                @click="showOrHideRates('hide')"
                :disabled="!clickToShow"
                v-model.trim="hideRates"
              ></v-checkbox>
              <label class="text-gray font mt-5" for=""
                >Require employers to request to show our rates. Send an email
                to allow us to approve or deny their request.</label
              >
            </div>
          </div>
        </div>
        <br /><br />
        <div class="row mt-10">
          <h4 class="text-gray settings-text text-underline mb-3">
            Email Domains Associated with this Account
          </h4>
        </div>
        <v-form
          ref="form1"
          v-model.trim="valid1"
          class="col-md-6 no-padding mt-5"
          v-if="$role != 'user'"
        >
          <label class="text-gray font" for=""
            >Enter email domain here, and click Add</label
          >
          <div class="row mt-5 col-md-11 no-padding">
            <v-text-field
              @keyup="fillDomainRules"
              :rules="newEmail ? domainRules : []"
              v-model.trim="newEmail"
              solo
            ></v-text-field>
            <v-btn large class="ml-3 blue-buttons" @click="pushToArray"
              >Add</v-btn
            >
          </div>
        </v-form>
        <div
          class="col-md-6 no-padding mb-10"
          :class="emails.length > 8 ? 'y-scroll' : ''"
          style="overflow-x: hidden"
        >
          <label for="" class="text-gray font">Email domain white list:</label>
          <div class="col-md-12">
            <v-card
              v-for="(email, index) in emails"
              :key="index"
              style="padding: 1rem"
              class="mt-2 mb-2"
            >
              <span v-if="email.domain">{{ email.domain }}</span>
              <span v-else>{{ email }}</span>
              <span class="float-right" v-if="index != 0">
                <svg
                  @click="removeFromArray(email)"
                  class="pointer"
                  width="28"
                  height="26"
                  viewBox="0 0 28 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.35547 13C1.35547 16.1826 2.65524 19.2348 4.96884 21.4853C7.28244 23.7357 10.4204 25 13.6923 25C16.9642 25 20.1021 23.7357 22.4157 21.4853C24.7293 19.2348 26.0291 16.1826 26.0291 13C26.0291 9.8174 24.7293 6.76516 22.4157 4.51472C20.1021 2.26428 16.9642 1 13.6923 1C10.4204 1 7.28244 2.26428 4.96884 4.51472C2.65524 6.76516 1.35547 9.8174 1.35547 13V13Z"
                    stroke="#D00000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.75781 17.7992L18.6273 8.19922"
                    stroke="#D00000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18.6273 17.7992L8.75781 8.19922"
                    stroke="#D00000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </v-card>
          </div>
        </div>
        <div class="row col-md-12 justify-end" v-if="$role != 'user'">
          <v-btn class="blue-buttons" @click="update">Update & Save</v-btn>
        </div>
      </v-form>
      <v-snackbar v-model.trim="snackbar" :timeout="1000" color="red">
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>
<script>
import api from "../../../../services/api";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
export default {
  components: {
    successModal,
  },
  data() {
    return {
      sendMail: false,
      sendMail1: "",
      participate: false,
      hideRates: false,
      clickToShow: false,
      requestToShow: false,
      sendNotification: false,
      emails: [],
      tags: [],
      tags2: [],
      tags3: [],
      readyToShow: false,
      snackbar: false,
      snackbarText: "",
      myDeleteArray: [],
      myAddArray: [],
      email: "",
      email1: "",
      email2: "",
      email3: "",
      email4: "",
      newEmail: "",
      valid: false,
      valid1: false,
      domainRules: [
        (v) => !!v || "This field is required",
        (v) =>
          /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/.test(v) ||
          "Email domain is not valid!",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
      emailRules: [
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
    };
  },
  created() {
    this.getSettings();
  },
  methods: {
    pushToTags(row) {
      if (row == "1") {
        if (
          this.email1 != null &&
          /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email1)
        ) {
          let index = this.tags.findIndex((c) => c == this.email1);
          if (index == -1) {
            this.tags.push(this.email1);
            this.email1 = "";
          } else {
            this.snackbarText = "Email already exists";
            this.snackbar = true;
          }
        } else {
          this.snackbarText = "Email is not valid";
          this.snackbar = true;
        }
      } else if (row == "2") {
        if (
          this.email2 != null &&
          /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email2)
        ) {
          let index = this.tags2.findIndex((c) => c == this.email2);
          if (index == -1) {
            this.tags2.push(this.email2);
            this.email2 = "";
          } else {
            this.snackbarText = "Email already exists";
            this.snackbar = true;
          }
        } else {
          this.snackbarText = "Email is not valid";
          this.snackbar = true;
        }
      } else {
        if (
          this.email3 != null &&
          /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email3)
        ) {
          let index = this.tags3.findIndex((c) => c == this.email3);
          if (index == -1) {
            this.tags3.push(this.email3);
            this.email3 = "";
          } else {
            this.snackbarText = "Email already exists";
            this.snackbar = true;
          }
        } else {
          this.snackbarText = "Email is not valid";
          this.snackbar = true;
        }
      }
    },
    removeFromTags(index, row) {
      if (row == "1") {
        this.tags.splice(index, 1);
      } else if (row == "2") {
        this.tags2.splice(index, 1);
      } else {
        this.tags3.splice(index, 1);
      }
    },

    fillDomainRules() {
      this.domainRules = [
        (v) => !!v || "This field is required",
        (v) =>
          /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/.test(v) ||
          "Email domain is not valid!",
      ];
    },
    pushToArray() {
      this.$refs.form1.validate();

      let data = {
        domain: this.newEmail,
      };
      if (this.valid1) {
        api()
          .post("/auth/validateEmail", data)
          .catch((err) => {
            if (err) {
              this.domainRules = ["Only company email domains are allowed"];
            }
          })
          .then((res) => {
            if (res) {
              api()
                .get("/auth/isValidDomain?domain=" + this.newEmail)
                .catch((err) => {
                  if (err) {
                    this.domainRules = [err.response.data.message];
                    exist = true;
                  }
                })
                .then((res) => {
                  let regex =
                    /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/;
                  let check = regex.test(this.newEmail);
                  if (this.newEmail && this.valid1 && check) {
                    this.emails.push({ domain: this.newEmail });
                    this.myAddArray.push({ domain: this.newEmail });
                    this.$refs.success_modal.openPopUp(
                      "Domain added successfully"
                    );
                    this.$refs.form.resetValidation();
                    this.newEmail = "";
                  }
                });
            }
          });
      }
    },
    removeFromArray(email) {
      this.myDeleteArray.push(email);
      for (let i = 0; i < this.emails.length; i++) {
        if (this.emails[i] == email) {
          this.emails.splice(i, 1);
        }
      }
      this.$refs.success_modal.openPopUp("Domain was successfully deleted!");
    },
    showOrHideRates(text) {
      if (text == "hide") {
        this.hideRates = true;
        this.requestToShow = false;
      } else {
        this.hideRates = false;
        this.requestToShow = true;
      }
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
    },
    update() {
      let user = JSON.parse(localStorage.getItem("user"));
      let provider = user.entity._id;
      let self = this;
      this.$refs.form.validate();
      if (this.valid) {
        let tagEmails = "";
        let tagEmails2 = "";
        let tagEmails3 = "";
        this.tags.forEach(function (item) {
          if (item != "") tagEmails += item + ";";
        });
        this.tags2.forEach(function (item) {
          if (item != "") tagEmails2 += item + ";";
        });
        this.tags3.forEach(function (item) {
          if (item != "") tagEmails3 += item + ";";
        });

        let newAddArr = [];
        let newDeletArr = [];
        if (this.myAddArray.length > 0) {
          this.myAddArray.forEach((el) => {
            if (el.domain) {
              newAddArr.push(el.domain);
            } else {
              newAddArr.push(el);
            }
          });
        }
        if (this.myDeleteArray.length > 0) {
          this.myDeleteArray.forEach((el) => {
            if (el.domain) {
              newDeletArr.push(el.domain);
            } else {
              newDeletArr.push(el);
            }
          });
        }

        let data = {
          employerAccess: this.sendMail,
          employerAccessEmails: tagEmails2,
          notificationEmails: tagEmails,
          // preferredProviderParticipation: this.participate,
          ratesVisibility: {
            employersClickShowRates: this.clickToShow,
            employersRequireAccess: this.requestToShow,
            hideFromEmployers: this.hideRates,
          },
          priorAuths: {
            emails: tagEmails3,
          },
          addedDomains: newAddArr,
          deletedDomains: newDeletArr,
        };

        api()
          .put(`/providers/${provider}/settings`, data)
          .then((res) => {
            if (res) {
              this.$refs.success_modal.openPopUp(
                "Settings updated successfully"
              );
            }
          });
      }
    },
    getSettings() {
      this.readyToShow = true;
      let user = JSON.parse(localStorage.getItem("user"));
      let provider = user.entity._id;
      let self = this;

      api()
        .get(`/providers/${provider}/settings`)
        .then((res) => {
          this.sendMail = res.data.settings.employerAccess
            ? res.data.settings.employerAccess
            : false;
          this.participate = res.data.settings.preferredProviderParticipation
            ? res.data.settings.preferredProviderParticipation
            : false;
          this.hideRates = res.data.settings.ratesVisibility.hideFromEmployers
            ? res.data.settings.ratesVisibility.hideFromEmployers
            : false;
          this.clickToShow = res.data.settings.ratesVisibility
            .employersClickShowRates
            ? res.data.settings.ratesVisibility.employersClickShowRates
            : false;
          this.requestToShow = res.data.settings.ratesVisibility
            .employersRequireAccess
            ? res.data.settings.ratesVisibility.employersRequireAccess
            : false;
          this.tags = res.data.settings.priorAuths.emails;

          let serviceTags =
            res.data.settings.priorAuths.emails?.split(";") ?? [];
          let notificationTags =
            res.data.settings.notificationEmails?.split(";") ?? [];
          let employerTags =
            res.data.settings.employerAccessEmails?.split(";") ?? [];
          self.tags = [];
          notificationTags.forEach(function (item) {
            if (item != "") self.tags.push(item);
          });
          employerTags.forEach(function (item) {
            if (item != "") self.tags2.push(item);
          });
          serviceTags.forEach(function (item) {
            if (item != "") self.tags3.push(item);
          });
          this.emails = res.data.settings.domainWhiteList;
          if (localStorage.getItem("user")) {
            let mail = JSON.parse(localStorage.getItem("user")).email;
            let parsedMail = mail.split("@")[1];

            if (!res.data.settings.domainWhiteList.includes(parsedMail)) {
              this.emails.unshift(parsedMail);
              this.$store.commit("setEmailWhiteList", this.emails);
            }
          }
          this.readyToShow = false;
        });
    },
  },
};
</script>

