<template>
  <div class="no-padding row row-height">
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>

    <div class="col-md-12">
      <div class="col-md-12 pt-0 pl-0">
        <v-row>
          <v-col class="col-xl-1 col-lg-3 col-md-3" cols="12">
            <h3>
              <span class="font">Providers </span>
            </h3>
          </v-col>
          <!-- <v-col class="col-lg-3 col-md-4"  cols="12">
          <v-btn class="blue-buttons" :disabled="overlay" @click="overlay = !overlay">Group NPI Filters</v-btn>
        </v-col> -->
          <v-col class="col-xl-2 col-lg-3 col-md-3" cols="12">
            <v-text-field
              class="input-control"
              prepend-inner-icon="mdi-magnify"
              @input="searchProviders"
              light
              dense
              v-if="filterArray.length > 0"
              outlined
              placeholder="Search Providers"
            >
            </v-text-field>
          </v-col>
          <v-col
            class="col-xl-9 col-lg-6 col-md-6 text-end"
            cols="12"
            align="end"
          >
            <v-menu offset-y>
              <template v-if="$role != 'user'" v-slot:activator="{ on }">
                <v-btn v-on="on" class="my-1 blue-buttons" :disabled="overlay">
                  Add / Invite Providers
                </v-btn>
              </template>
              <template v-else v-slot:activator="{ on }">
                <v-btn v-on="on" class="my-1 blue-buttons" :disabled="overlay">
                  Search Providers
                </v-btn>
              </template>
              <v-list>
                <v-list-item style="disply: block">
                  <a
                    class="a-tag underline-text"
                    @click="$router.push(`/employer/providers/zip-codes`)"
                    >By Member Zip Code</a
                  >
                </v-list-item>
                <v-list-item style="disply: block">
                  <a
                    class="a-tag underline-text"
                    @click="$router.push(`/employer/providers/add-provider`)"
                    >Search & Add
                  </a>
                </v-list-item>
                <v-list-item>
                  <a
                    class="a-tag underline-text"
                    @click="$router.push(`/employer/providers/add-provider`)"
                    >Upload a NPI List</a
                  >
                </v-list-item>
                <v-list-item v-if="$role != 'user'">
                  <a
                    class="a-tag underline-text"
                    @click="$router.push(`/employer/providers/invite`)"
                    >Invite Providers</a
                  >
                </v-list-item>
                <!-- <v-list-item>
                    <a class="a-tag underline-text" >Copy Invite Link</a>
                </v-list-item> -->
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </div>

      <v-row v-if="loader" class="justify-center col-md-12">
        <v-progress-circular
          :size="100"
          class="text-center"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-row>
      <v-row
        class="col-md-12"
        v-else-if="allProviders.length < 1 && !filterMessage"
      >
        <v-col md="12" class="font-bold mt-4">
          <div v-if="$role != 'user'">
            To create a targeted provider list,
            <a @click="$router.push(`/employer/providers/zip-codes`)">
              Add your plan member home zip codes</a
            >
          </div>
          <div v-else>No Results</div>
        </v-col>
      </v-row>

      <v-simple-table v-else fixed-header height="630px" class="mt-4">
        <template v-slot:default>
          <thead :class="overlay ? 'disabled-table' : ''">
            <tr>
              <th class="text-left">Provider Name</th>
              <th class="text-left">Type</th>
              <th class="text-left">City</th>
              <th class="text-left">Status</th>
              <th class="text-left">Rate</th>

              <th
                class="no-padding text-end justify-end"
                v-if="$role != 'user'"
              >
                <div class="col-md-12 justify-end d-flex text-end">
                  <v-checkbox
                    v-model="allRows"
                    class="no-padding mt-3 stay-blue"
                    @click="selectAllRows1()"
                  ></v-checkbox>
                  <label for="" style="padding-top: 11px">Select all </label>
                </div>
              </th>
              <!-- <th class="text-center">Actions</th> -->
            </tr>
          </thead>
          <tbody v-if="allProviders.length > 0">
            <tr
              v-for="(item, index) in allProviders"
              :key="index"
              :id="'id' + index"
            >
              <td v-if="item.displayName">
                <a @click="singleProvider(item)">{{ item.displayName }}</a>
              </td>
              <td v-else-if="item.companyName">
                <a @click="singleProvider(item)">{{ item.companyName }}</a>
              </td>
              <td v-else-if="item.providerName">
                <a @click="singleProvider(item)">{{ item.providerName }}</a>
              </td>
              <td v-else><a @click="singleProvider(item)">/</a></td>

              <td class="text-gray">Type {{ item.type }}</td>

              <td class="text-gray" v-html="item.city ? item.city : '/'"></td>
              <td
                :class="
                  item.status == 'contracted' ? 'green--text' : 'gray--text'
                "
                v-html="
                  item.status == 'contracted'
                    ? 'Contracted '
                    : item.status == 'pending'
                    ? 'Pending'
                    : '/'
                "
              ></td>
              <td
                :class="
                  item.rate == 'notMatched'
                    ? 'red--text'
                    : item.rate == 'matched'
                    ? 'green--text'
                    : 'gray--text'
                "
                v-html="
                  item.rate == 'matched'
                    ? 'Matched'
                    : item.rate == 'notMatched'
                    ? 'Not Matched'
                    : item.rate == 'NA'
                    ? 'NA'
                    : '/'
                "
              ></td>

              <td
                v-if="$role != 'user'"
                class="text-center mt-3 row no-padding justify-end"
                style="padding-right: 60px"
              >
                <v-checkbox
                  class="stay-blue no-padding stay-blue"
                  v-model="item.checkbox"
                  @click="activeRow1(index, item)"
                ></v-checkbox>
                <svg
                  class="pointer"
                  @click="singleProvider(item)"
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.9955 19.5396L6.87109 20.1296L7.45998 16.004L15.8611 7.6029C16.3299 7.13406 16.9658 6.87067 17.6289 6.87067C18.2919 6.87067 18.9278 7.13406 19.3966 7.6029C19.8655 8.07175 20.1289 8.70764 20.1289 9.37068C20.1289 10.0337 19.8655 10.6696 19.3966 11.1385L10.9955 19.5396Z"
                    stroke="#4072B7"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M1 13.5C1 16.8152 2.31696 19.9946 4.66117 22.3388C7.00537 24.683 10.1848 26 13.5 26C16.8152 26 19.9946 24.683 22.3388 22.3388C24.683 19.9946 26 16.8152 26 13.5C26 10.1848 24.683 7.00537 22.3388 4.66117C19.9946 2.31696 16.8152 1 13.5 1C10.1848 1 7.00537 2.31696 4.66117 4.66117C2.31696 7.00537 1 10.1848 1 13.5V13.5Z"
                    stroke="#4072B7"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </td>
            </tr>
          </tbody>
          <div v-if="allProviders.length < 1 && emptySearch">
            <v-col md="12" cols="12">
              <v-alert
                border="bottom"
                colored-border
                type="warning"
                elevation="2"
                class="text-gray"
              >
                Sorry! No results found
              </v-alert>
            </v-col>
          </div>
        </template>
      </v-simple-table>

      <v-row
        v-if="allProviders.length > 0 && !loader && $role != 'user'"
        class="col-md-12 pr-0 justify-end mt-6 mb-2"
      >
        <v-btn class="blue-buttons" @click="removeSelected()"
          >Remove Selected</v-btn
        >
      </v-row>
      <v-row class="justify-end mt-3" v-if="!noDataMessage && showSaveButton">
        <v-btn
          class="blue-buttons"
          @click="
            $store.getters.getZipSearchResult.length > 0
              ? addProviders('npidata')
              : addProviders('provider')
          "
          >Save & Apply</v-btn
        >
      </v-row>
    </div>

    <v-dialog v-model.trim="dialog" max-width="600px" persistent>
      <v-card>
        <v-col>
          <h3>Remove Provider</h3>
        </v-col>

        <v-col>
          <span v-if="selectedRows.length == 1"
            >Are you sure you want to delete this Provider?
          </span>
          <span v-else
            >Are you sure you want to delete selected Providers?</span
          >
        </v-col>

        <v-col>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              class="blue-buttons float-class"
              @click="removeListOfProvider"
              >Confirm</v-btn
            >
            <v-btn class="button-style float-class" @click="closePopUp"
              >Close</v-btn
            >
          </v-card-actions>
        </v-col>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import api from "../../../../services/api";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
export default {
  components: { successModal },
  data() {
    return {
      ratingValue: 2,
      items: [],
      loader: true,
      filterItems: false,
      filterMessage: false,
      manualOverrideProviders: [
        {
          text: "Manual Override",
          value: true,
        },
      ],
      selectAll: false,
      prefferedProviderItems: [
        {
          text: "Preferred Provider",
          value: true,
        },
      ],
      filter: {
        type: "",
        speciality: "",
        rating: 0,
        distance: "",
        status: "",
        preferredProvider: false,
        manualOverride: false,
      },
      showSaveButton: false,
      filterMatchingStatus: [
        {
          text: "Matched",
          value: "matched",
        },
        {
          text: "Not Matched",
          value: "notMatched",
        },
      ],
      noDataMessage: "",
      selectedArray: [],
      panel: [0, 1, 2, 3, 4, 5],
      providerType: [
        {
          text: "Hospital",
          value: "hospital",
        },
        {
          text: "Doctor Office",
          value: "doctorOffice",
        },
        {
          text: "Imagining",
          value: "imaging",
        },
        {
          text: "Laboratory",
          value: "laboratory",
        },
        {
          text: "Other",
          value: "other",
        },
      ],

      allProviders: [],
      display: true,
      overlay: false,
      windowWidth: window.innerWidth,
      dialog: false,
      filterArray: [],
      itemToRemove: null,
      allRows: false,
      selectAll: false,
      selectedRows: [],
      loader: true,
      checkIfSelect: false,
      checkIfClicked: false,
    };
  },

  created() {
    if (this.windowWidth < 600) {
      this.display = false;
    } else {
      this.display = true;
    }

    this.overlay = false;
    this.getAllProviders();
  },
  computed: {
    path() {
      return this.$route.path;
    },
    getClient() {
      return this.$store.getters.getClientGetter;
    },
    routeParams() {
      return this.$route.params.name;
    },

    overlayClass() {
      let str = "";
      if (this.display) {
        str = "col-md-12";
        if (document.getElementById("hideOnMobile")) {
          document.getElementById("hideOnMobile").style.display =
            "none !important";
        }
      } else if (this.overlay) {
        str = "col-md-9 disabled-table";
      } else if (!this.display) {
        str = "col-md-12";
      } else {
        str = "col-md-12";
      }
      return str;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    selectAllRows() {
      this.selectedRows = [];
      this.selectAll = !this.selectAll;

      if (!this.selectAll) {
        this.selectedRows = [];
      } else {
        this.selectedRows = [];
        let tmp = JSON.stringify(this.allProviders);
        this.selectedRows = JSON.parse(tmp);
      }
    },
    selectAllRows1() {
      this.selectedRows = [];
      if (!this.checkIfSelect) {
        this.selectedRows = [];
        this.checkIfClicked = false;
        this.allProviders.forEach((el) => {
          el.checkbox = false;
        });
      }
      if (this.checkIfSelect) {
        this.allProviders = [];
        this.loader = true;
        this.allProviders = this.items;
        let tmp = JSON.stringify(this.allProviders);
        this.selectedRows = JSON.parse(tmp);
        this.checkIfSelect = false;

        setTimeout(() => {
          this.allProviders.forEach((el) => {
            el.checkbox = true;
          });
          this.loader = false;
        }, 0);
        //  this.$forceUpdate();
      } else if (this.selectAll && !this.checkIfSelect) {
        this.selectAll = false;
      } else {
        this.selectAll = !this.selectAll;
        if (!this.selectAll) {
          this.selectedRows = [];
          this.checkIfClicked = false;
          this.allProviders.forEach((el) => {
            el.checkbox = false;
          });
        } else {
          this.allProviders.forEach((el) => {
            el.checkbox = true;
          });
          this.selectedRows = [];
          let tmp = JSON.stringify(this.allProviders);
          this.selectedRows = JSON.parse(tmp);
        }
      }
    },
    activeRow1(index, item) {
      let itemById = document.getElementById("id" + index);
      if (this.allRows) {
        this.checkIfSelect = true;
        let removeItem = this.selectedRows.find((x) => x._id === item._id);
        this.selectedRows.splice(this.selectedRows.indexOf(removeItem), 1);
        itemById.classList.remove("activeItem");
        this.allRows = false;
        this.checkIfClicked = true;
      } else {
        itemById.classList.toggle("activeItem");
        if (this.checkIfClicked) {
          itemById.classList.remove("activeItem");
        }

        if (item.checkbox) {
          this.selectedRows.push(item);
        } else {
          let removeItem = this.selectedRows.find((x) => x._id === item._id);
          this.selectedRows.splice(this.selectedRows.indexOf(removeItem), 1);
        }
      }
      // this.allRows = true;
      // this.allProviders.forEach((el) => {
      //   if (!el.checkbox) {
      //     this.allRows = false;
      //   }
      // });
      // if (this.allRows) {
      //   this.checkIfSelect = false;
      // }
    },
    activeRow(index, item) {
      this.index = index;
      this.itemById = document.getElementById("id" + index);
      this.itemById.classList.toggle("activeItem");
      if (this.allRows) {
        let removeItem = this.selectedRows.find((x) => x._id === item._id);
        this.selectedRows.splice(this.selectedRows.indexOf(removeItem), 1);
      } else {
        if (this.itemById.classList.contains("activeItem")) {
          this.selectedRows.push(item._id);
        } else {
          let removeItem = this.selectedRows.find((x) => x._id === item._id);
          this.selectedRows.splice(this.selectedRows.indexOf(removeItem), 1);
        }
      }
    },
    removeItem(item) {
      this.dialog = true;
      this.itemToRemove = item;
    },
    removeSelected() {
      if (this.selectedRows.length > 0) {
        this.dialog = true;
      } else if (this.selectedRows.length < 1) {
        let text = "You must select at least one Provider";
        this.$refs.error_modal.openPopUp(text);
        return;
      }
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
      this.getAllProviders();
      this.overlay = false;
      this.showSaveButton = false;
    },
    clearAll() {
      this.filter.type = "";
      this.filter.distance = "";
      this.filter.rating = 0;
      this.filter.status = "";
      this.filter.preferredProvider = false;
      this.filter.manualOverride = false;
    },
    removeFilter(type) {
      if (type == "type") {
        this.filter.type = "";
      } else if (type == "distance") {
        this.filter.distance = "";
      } else if (type == "rating") {
        this.filter.rating = 0;
      } else if (type == "status") {
        this.filter.status = "";
      } else if (type == "preferred") {
        this.filter.preferredProvider = false;
      } else if (type == "manual") {
        this.filter.manualOverride = false;
      }
    },

    addProviders(search) {
      let data = {
        search: search,
        ids: this.selectedRows,
      };

      let user = JSON.parse(localStorage.getItem("user"));
      let empId = user.groupPortal[0].organization.employer;

      api()
        .post(`/employers/${empId}/providers`, data)
        .then((res) => {
          let text = "Providers added successfully to this Employer!";
          this.$refs.success_modal.openPopUp(text);
          let array = [];
          this.$store.commit("setZipSearchResult", array);
        });
    },
    filterProviders() {
      let array = [];
      this.filterItems = true;
      this.$store.commit("setZipSearchResult", array);
      let urlData = ``;
      urlData += this.filter.type
        ? `providerType=${this.filter.type.value}&`
        : "";
      urlData += this.filter.distance
        ? `distance=${this.filter.distance}&`
        : "";
      urlData += this.filter.rating
        ? `qualityRating=${this.filter.rating}&`
        : "";
      urlData += this.filter.status
        ? `status=${this.filter.status.value}&`
        : "";
      urlData += this.filter.preferredProvider
        ? `preferred=${this.filter.preferredProvider.value}&`
        : "";
      urlData += this.filter.manualOverride
        ? `manualOverride=${this.filter.manualOverride.value}&`
        : "";

      api()
        .get(`/advisors/providers/search?${urlData}`)
        .then((res) => {
          if (res) {
            if (res.data.providers.length < 1) {
              this.noDataMessage = "No Result Found. Try Again";
              this.allProviders = [];
              this.filterArray = [];
              this.showSaveButton = false;
            } else {
              this.allProviders = res.data.providers;
              this.filterArray = res.data.providers;
              this.showSaveButton = true;
            }

            this.overlay = false;
          }
        });
    },
    getAllProviders() {
      let user = JSON.parse(localStorage.getItem("user"));
      let empId = user.groupPortal[0].organization.employer;
      this.filterItems = false;

      api()
        .get(`/employers/${empId}/providers`)
        .then((res) => {
          if (res) {
            this.filterItems = false;
            this.allProviders = res.data.providers;
            this.items = res.data.providers;
            this.filterArray = res.data.providers;
            this.allRows = false;
            this.loader = false;
          }
        });
    },
    searchProviders(val) {
      this.allProviders = this.filterArray.filter((el) => {
        if (
          el.displayName &&
          el.displayName.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.displayName.toLowerCase().includes(val.toLowerCase());
        } else if (
          el.companyName &&
          el.companyName.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.companyName.toLowerCase().includes(val.toLowerCase());
        } else if (
          el.providerName &&
          el.providerName.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.providerName.toLowerCase().includes(val.toLowerCase());
        } else if (
          el["Provider First Name"] &&
          el["Provider First Name"].toLowerCase().includes(val.toLowerCase())
        ) {
          return el["Provider First Name"]
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el["Provider Last Name (Legal Name)"] &&
          el["Provider Last Name (Legal Name)"]
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el["Provider Last Name (Legal Name)"]
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el.city &&
          el.city.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.city.toLowerCase().includes(val.toLowerCase());
        } else if (
          el.status &&
          el.status.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.status.toLowerCase().includes(val.toLowerCase());
        } else if (
          el.rate &&
          el.rate.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.rate.toLowerCase().includes(val.toLowerCase());
        }
      });
      if (this.allProviders.length < 1) {
        this.filterMessage = true;
      }
    },

    singleProvider(item) {
      if (!this.overlay && !this.showSaveButton) {
        let user = JSON.parse(localStorage.getItem("user"));
        let empId = user.groupPortal[0].organization.employer;

        api()
          .get(`/employers/${empId}/providers/${item._id}`)
          .then((res) => {
            if (res) {
              this.$store.commit(
                "setSingleProviderInEmployer",
                res.data.provider
              );
              this.$router.push(`/employer/providers/detail`);
            }
          });
      }
    },
    removeListOfProvider() {
      let user = JSON.parse(localStorage.getItem("user"));
      let empId = user.groupPortal[0].organization.employer;

      if (this.selectedRows.length < 1) {
        let text = "You must select at least one Provider";
        this.$refs.error_modal.openPopUp(text);
        return;
      }
      let arr = [];

      this.selectedRows.forEach((el) => {
        if (el._id) {
          arr.push(el._id);
        } else {
          arr.push(el);
        }
      });

      let data = {
        ids: arr,
      };

      api()
        .put(`/employers/${empId}/providersDelete`, data)
        .then((res) => {
          this.loader = true;
          if (res) {
            api()
              .get(`/employers/${empId}/providers`)
              .then((res) => {
                if (res) {
                  this.allProviders = res.data.providers;
                  this.filterArray = res.data.providers;
                  this.items = res.data.providers;
                  this.selectedRows = [];
                  this.dialog = false;
                  this.loader = false;
                  this.allRows = false;
                  this.checkIfClicked = false;
                  this.selectAll = false;
                }
              });
          }
        });
    },
    confirmRemove() {
      let user = JSON.parse(localStorage.getItem("user"));
      let empId = user.groupPortal[0].organization.employer;
      api()
        .delete(`/employers/${empId}/providers/${this.itemToRemove._id}`)
        .then((res) => {
          if (res) {
            api()
              .get(`/employers/${empId}/providers`)
              .then((res) => {
                if (res) {
                  this.allProviders = res.data.providers;
                  this.filterArray = res.data.providers;
                  this.dialog = false;
                }
              });
          }
        });
    },
    closePopUp() {
      this.dialog = false;
    },
    openFilterMenu() {
      this.overlay = !this.overlay;
      this.$store.commit("showFilterMenu", this.overlay);
    },
    changeColor(status) {
      let myClass = "";
      if (status == "Matched") {
        myClass = "red--text";
      } else if (status == "Not matched") {
        myClass = "green--text";
      } else {
        myClass = "gray--text";
      }
      return myClass;
    },
    selectAllProviders() {
      this.selectAll = !this.selectAll;
    },
    removeSelected() {
      if (this.selectedRows.length > 0) {
        this.dialog = true;
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 600) {
        this.display = false;
      } else {
        this.display = true;
      }
    },
  },
};
</script>
<style>
</style>