<template>
  <div class="page-background-alert">
    <img class="apaly-logo" src="/apaly.png" alt="">

          <div  class="row alert-text" v-if="type == 'add'" >
           Success!
            <br>
            You’ve added
            <br>
            a family member.
          </div>

          <div  class="row alert-text" v-else>
           Success!
            <br>
            You’ve updated
            <br>
            the insurance information.
          </div>

<!--    <div  class="row alert-text" style="width: 199px">-->
<!--      <span style="font-size: 30px;">Check your email</span>-->
<!--      <br>-->
<!--      There should be-->
<!--      <br>-->
<!--      a verification link.-->
<!--    </div>-->
  </div>
</template>
<script>
export default {
  data() {
    return {
      type:this.$route.params.type,

    };
  },
  components: {
  },
  methods: {},
  created() {},
};
</script>
<style scoped src='./../../../../assets/mobileStyle.css'></style>

<style scoped lang="scss">

.page-background-alert {
  height: 100vh;
  background-color:  #16407A;
}

.apaly-logo{
  position: absolute;
  width: 162.17px;
  height: 65px;
  left: 115px;
  top: 186px;
}

.alert-text{
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;
  color: white;
  display: block;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 8px;
text-align: center;
  position: absolute;
  width: 184px;
  height: 80px;
  left: 105px;
  top: 386px;
}

</style>