<template>
  <v-row class="add-client-form no-padding">
    <div class="col-md-12 form-box">
      <div class="row" v-if="$role != 'user'">
        <div class="col-md-12">
          <div class="col-md-12 row justify-space-between">
            <h3 class="form-title font">Add New Partner</h3>
          </div>
        </div>
      </div>
      <div class="row" v-if="$role == 'user'">
        <div class="col-md-12">
          <div class="col-md-12 row justify-space-between">
            <h3 class="form-title font">Search Partner</h3>
          </div>
        </div>
      </div>
      <div class="font mt-5" v-if="$role != 'user'">
        <h3 class="col-md-12">Search and Add a Partner</h3>
      </div>
      <div class="form-row-height">
        <v-form class="row" v-model="valid" ref="form">
          <div class="col-md-6">
            <div class="col-md-12 pb-0">
              <label class="font text-gray" for="">Partner Type</label>
              <v-select
                :items="typeOptions"
                v-model.trim="partnerType"
                required
                solo
              ></v-select>
            </div>
            <div class="col-md-12 pb-0 pt-0">
              <label class="font text-gray" for=""
                >Partner Organization Name</label
              >
              <v-text-field
                required
                solo
                v-model.trim="organization"
              ></v-text-field>
            </div>

            <v-row class="no-padding">
              <div class="col-md-6 pb-0 pt-0">
                <label class="font text-gray" for="">City</label>
                <v-text-field required solo v-model.trim="city"></v-text-field>
              </div>
              <div class="col-md-3 pb-0 pt-0">
                <label class="font text-gray" for="">State</label>
                <v-select
                  required
                  item-text="name"
                  item-value="name"
                  solo
                  :items="states"
                  v-model.trim="state"
                ></v-select>
              </div>
              <div class="col-md-3 pb-0 pt-0">
                <label class="font text-gray" for="">Zip</label>
                <v-text-field
                  required
                  solo
                  @keydown="checkPlus($event)"
                  :rules="zip.length > 0 ? zipRules : []"
                  type="number"
                  v-model.trim="zip"
                ></v-text-field>
              </div>
            </v-row>
          </div>
          <div class="col-md-6">
            <div class="col-md-12 pb-0">
              <label class="font text-gray" for=""
                >Individual Partner First Name</label
              >
              <v-text-field
                required
                v-model.trim="firstName"
                solo
              ></v-text-field>
            </div>
            <div class="col-md-12 pb-0 pt-0">
              <label class="font text-gray" for="">Last Name</label>
              <v-text-field
                required
                solo
                v-model.trim="lastName"
              ></v-text-field>
            </div>
            <div class="col-md-12 d-flex align-end flex-column">
              <div>
                <v-btn
                  tabindex="16"
                  class="blue-buttons mb-2"
                  @click="searchPartners"
                >
                  Search
                </v-btn>
              </div>
            </div>
          </div>
        </v-form>
      </div>
    </div>
  </v-row>
</template>
<script>
import api from "../../../../services/api";
export default {
  data() {
    return {
      show1: false,
      dialog: false,
      dialog1: false,
      valid: false,
      partnerType: "",
      city: "",
      state: "",
      zip: "",
      firstName: "",
      lastName: "",
      states: [],
      organization: "",
      zipRules: [
        (v) => (v && v.length == 5) || " Zip Code should be five digits long",
      ],
      typeOptions: [
        {
          text: "Third Party Administrator (TPA)",
          value: "TPA",
        },
        {
          text: "Broker Consultant",
          value: "brokerConsultant",
        },
        {
          text: "Analytics Consultant",
          value: "analyticsConsultant",
        },
      ],
    };
  },
  computed: {
    singleMember() {
      return this.$store.getters.getMemberGetter;
    },
    singleClient() {
      return this.$store.getters.getClientGetter;
    },
    routeParams() {
      return this.$route.params.name;
    },
  },
  created() {
    this.getStates();
  },
  methods: {
    checkPlus(e) {
      if (e.keyCode == 187 || e.keyCode == 69 || e.keyCode == 189) {
        e.preventDefault();
      }
    },
    getStates() {
      api()
        .get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },
    searchPartners() {
      //  if( this.partnerType == '' && this.city == '' && this.state == '' && this.zip == '' && this.firstName == '' && this.lastName == '' ){

      //  }
      this.$refs.form.validate();
      if (this.valid) {
        let urlData = "";
        urlData += this.partnerType ? `advisorType=${this.partnerType}&` : "";
        urlData += this.city ? `city=${this.city}&` : "";
        urlData += this.state ? `state=${this.state}&` : "";
        urlData += this.zip ? `zipCode =${this.zip}&` : "";
        urlData += this.firstName ? `firstName=${this.firstName}&` : "";
        urlData += this.lastName ? `lastName=${this.lastName}&` : "";
        urlData += this.organization ? `companyName=${this.organization}&` : "";
        let user = JSON.parse(localStorage.getItem("user"));
        let advisorId = user.groupPortal[0].organization.providerAdvisor;
        api()
          .get(`/advisors/${advisorId}/searchPA?` + urlData)
          .then((res) => {
            if (res) {
              this.$router.replace(`/provider-advisor/partners/add-new/result`);
              this.$store.commit(
                "setPartnersSearchResultInEmployerAdvisor",
                res.data.advisors
              );
            }
          });
      }
    },
  },
};
</script>
