<template>
    <div class="no-padding no-margin">
        <AdvisorsTable v-if="path == `/employer-advisor/employers/${routeParams}/advisors`"></AdvisorsTable>
        <AddAdvisor v-if="path == `/employer-advisor/employers/${routeParams}/advisors/add-new`"></AddAdvisor>
        <InviteAdvisor v-if="path == `/employer-advisor/employers/${routeParams}/advisors/invite-advisor`"></InviteAdvisor>
        <Advisor v-if="path == `/employer-advisor/employers/${routeParams}/advisors/advisor`"></Advisor>
        <Result v-if="path == `/employer-advisor/employers/${routeParams}/advisors/result`"></Result>

    </div>
    
</template>
<script>
import AddAdvisor from './AddAdvisor.vue'
import InviteAdvisor from './InviteAdvisor.vue'
import Advisor from './Advisor.vue'
import AdvisorsTable from './AdvisorsTable.vue'
import Result from './AdvisorSearchResult.vue'

export default {
    components:{
    AdvisorsTable,
    AddAdvisor,
    InviteAdvisor,
    Advisor,
    Result
    },
    data(){
        return{
        }
    },

    computed:{
        path(){
            return this.$route.path
        },
        routeParams(){
            return this.$route.params.name
       }
    }
   
}
</script>