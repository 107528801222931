<template>
  <div class="page-background">
    <main-header :title="'Login'" :color="'white'" ></main-header>
    <div class="page-background-alert">
      <div class="d-flex flex-column align-center justify-space-between pr-5 pl-5 " >
        <div style="margin-top: 100px;margin-bottom: 100px !important;">
          <img class="apaly-logo" src="/apaly.png" alt="">
        </div>
        <div class="d-flex flex-column align-center" style="width: 100%" >
          <form style="width: 100%">
            <div>
              <h3 class="form-title">Login</h3>
              <v-text-field outlined label="Email (login)" type="email" color="white" class="input-text"></v-text-field>
              <v-text-field outlined label="Password" color="white" class="input-text" :type="show ? 'text' : 'password'"
                            @click:append="show = !show" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              ></v-text-field>
            </div>
          </form>
        </div>
      </div>
      <div class="above-footer-text" >
        Don't have an account? <span style="color:white"  @click="$router.push({name:'MemberRegister'})">Register ></span>
      </div>
    </div>
    <div class="d-flex footer"  style="">
      <div class="col-6">
        <v-btn class="footer-btn-cancel" >Cancel</v-btn>
      </div>
      <div class="col-6">
        <v-btn class="footer-btn" >Login</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import mainHeader from "@/components/Sections/DPCMember/Layout/mainHeader";

export default {
  data() {
    return {
      show:false
    };
  },
  components: {
    mainHeader,
  },
  methods: {},
  created() {},
};
</script>
<style scoped src='./../../../../assets/mobileStyle.css'></style>
<style scoped lang="scss">


.page-background {

  background-color: #001e46;
}

.page-background-alert {
  height: 92vh;
  background-color:  #16407A;
}

.apaly-logo{
  width: 162.17px;
  height: 65px;
  left: 115px;
  top: 186px;
;
}

.form-title{
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  text-align: center;
  margin-bottom: 10px;
  color: white;
}

.alert-text{
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;
  color: white;
  display: block;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 8px;
  text-align: center;
  position: absolute;
  width: 184px;
  height: 80px;
  left: 105px;
  top: 386px;
}

.footer{
  height:80px;
  position:absolute;
  bottom:0;
  width:100%;
  align-items: flex-start;
  border-top: 1px solid #C9C9C9
}

.footer-btn-cancel{
  background-color:#16407a !important;
  border-radius: 23px;
  width: 100%;
  border: 1px solid white !important;
  color: white;
  float:right
}
.footer-btn{
  background-color: white !important;
  border-radius: 23px;
  width: 100%;
  color: #16407A ;
}
.entry-title{
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
}

.entry-description{
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.above-footer-text{
  margin-top: 170px;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  margin-left: 17px;
  color: #C9C9C9;

}
</style>