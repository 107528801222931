<template>
  <div>
    <div v-if="path.includes(`/tpa/clients`) && $route.params.clientId != null" class="blue-toolbar row no-padding" style="margin-top: 0px">
      <div cols="1"
        :class="activeTab == 'details'
            ? ' col-1 col-lg-1 col-md-2 col-sm-2 font ml-1  white--text text-center orange-underline sm-buttons-width'
            : 'col-1 col-lg-1 col-md-2 col-sm-2 font  ml-1 white--text text-center sm-buttons-width'" align="center">
        <a class="white--text" @click="changeActiveTab('details')"> General </a>
      </div>
      <div cols="1"
        :class="activeTab == 'members'
            ? ' col-1 col-lg-1 col-md-2 col-sm-2 font  white--text text-center orange-underline sm-buttons-width'
            : 'col-1 col-lg-1 col-md-2 col-sm-2 font  white--text text-center sm-buttons-width'" align="center">
        <a class="white--text" @click="changeActiveTab('members')"> Members </a>
      </div>
      <div cols="1"
        :class="activeTab == 'plans'
            ? 'col-1  col-lg-1 col-md-2 col-sm-2 font  white--text text-center orange-underline sm-buttons-width'
            : 'col-1 col-lg-1 col-md-2 col-sm-2 font  white--text text-center sm-buttons-width'" align="center">
        <a class="white--text" @click="changeActiveTab('plans')"> Plans </a>
      </div>

      <div cols="1" @click="changeActiveTab('solutions')"
        :class="activeTab == 'solutions'
            ? 'col-1 col-lg-1 col-md-2 col-sm-2 font  white--text text-center orange-underline sm-buttons-width'
            : 'col-1 col-lg-1 col-md-2 col-sm-2 font  white--text text-center sm-buttons-width'" align="center">
        <a class="white--text"> Solutions </a>
      </div>
      <div @click="changeActiveTab('invoices')" cols="1"
        :class="activeTab == 'invoices'
            ? 'col-1 col-lg-1 col-md-2 col-sm-2 font  white--text text-center orange-underline sm-buttons-width'
            : 'col-1 col-lg-1 col-md-2 col-sm-2 font  white--text text-center sm-buttons-width'" align="center">
        <a class="white--text"> Invoices </a>
      </div>
    </div>
    <div v-else class="blue-toolbar row" style="margin-top: 0px; padding: 1.05rem; margin-left: 0px">
      {{ routeParams }}
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeTab: "",
      activeTab1: "",
    };
  },
  props: {
    clientInfo: {
      type: Boolean,
    },
  },
  watch: {
    $route(to, from) {
      this.onCreatedFunction();
    },
  },

  methods: {
    changeActiveTab(tab) {
      console.log(this.$route.params)
      this.activeTab = tab;
      if (this.path != "/tpa/clients/" + this.$route.params.clientId + "/" + tab) {
        this.$router.push(
          "/tpa/clients/" + this.$route.params.clientId + "/" + tab
        );
      }
    },
    onCreatedFunction() {
      this.activeTab = this.path.split("/")[4];
    },
  },
  computed: {
    routeParams() {
      return this.$route.params.name;
    },
    path() {
      return this.$route.path.replace(/%20/g, " ");
    },
  },
  created() {
    this.onCreatedFunction();
  },
};
</script>
<style scoped>
.no-margin {
  margin: 0px !important;
}
</style>