<template>
  <v-app-bar app clipped-left clipped-right>
    <v-toolbar-title
      class="no-padding dark-blue"
      align="center"
      style="
        overflow: initial;
        width: 173px;
        display: flex;
        align-items: center;
      "
    >
      <img
        @click="getLogoImg"
        id="logo-img"
        width="35"
        height="40"
        :src="logo ? logo : '/apple.png'"
        class="pointer mr-3 ml-3"
      />
      <div style="font-size: 18px" class="mr-2">Marketplace</div>
        <v-divider vertical></v-divider>
      <div style="font-size: 13px" class="ml-3 pointer" @click="$router.push('/employer-client')">Employer Client</div>
    </v-toolbar-title>

    <v-spacer />
    <div class="user-settings">
      <div class="font text-right font-quicksand-medium-12" v-if="user">
        {{ user.firstName }} {{ user.lastName }}
      </div>
      <div class="font-quicksand-medium-11" v-if="user">{{ user.email }}</div>
    </div>

    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn dark icon v-on="on" class="mr-1">
          <v-avatar size="40">
            <img
              style="object-fit: cover"
              id="profile-img"
              :src="
                profileImg
                  ? profileImg
                  : 'https://www.pngkit.com/png/full/128-1280585_user-icon-fa-fa-user-circle.png'
              "
              alt="John"
            />
          </v-avatar>
        </v-btn>
      </template>
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <img
              style="object-fit: cover"
              :src="
                profileImg
                  ? profileImg
                  : 'https://www.pngkit.com/png/full/128-1280585_user-icon-fa-fa-user-circle.png'
              "
              alt="John"
            />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="font" v-if="user"
              >{{ user.firstName }} {{ user.lastName }}
            </v-list-item-title>
            <!-- //<v-list-item-subtitle class="font" v-if="user">{{user.email}}</v-list-item-subtitle> -->
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>

        <v-list-item
          v-for="(item, i) in userprofile"
          :key="i"
          color="primary"
          :to="item.path"
        >
          <v-list-item-title class="pointer" @click="logout(item)">{{
            item.title
          }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
<script>
import api from "../../../../services/api";
export default {
  name: "Header",
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showLogo: true,
    showSearch: false,
    fav: true,
    accountSettings: false,
    logo: "",
    profileImg: "",
    displayName: "",
    userprofile: [
      {
        title: "Logout",
      },
    ],
  }),
  computed: {
    user() {
      let user = {
        firstName: "Bill",
        lastName: "Smith",
        email: "brian.mcnilty@usi.com",
      };
      if (localStorage.getItem("user")) {
        user = JSON.parse(localStorage.getItem("user"));
      }
      return user;
    },
  },
  created() {
    // this.getLogoImg();
    // this.getDisplayName()
  },
  methods: {
    getDisplayName() {
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.employerAdvisor;
      api()
        .get(`/advisors/${advisorId}`)
        .then((res) => {
          this.displayName = res.data.advisor.displayName
            ? res.data.advisor.displayName
            : res.data.advisor.companyName;
        });
    },
    changeProfileImg() {
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.employerAdvisor;
      api()
        .get(`/advisors/${advisorId}`)
        .then((res) => {
          if (res.data.advisor && res.data.advisor.primaryContact) {
            if (res.data.advisor.primaryContact.profile) {
              this.profileImg = res.data.advisor.primaryContact.profile;
            } else {
              this.profileImg = null;
            }
          } else {
            this.profileImg = null;
          }
        });
    },
    getLogoImg() {
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.employerAdvisor;
      api()
        .get(`/advisors/${advisorId}`)
        .then((res) => {
          if (res.data.advisor.logo) {
            this.logo = res.data.advisor.logo;
          } else {
            this.logo = null;
          }
          if (
            res.data.advisor.primaryContact &&
            res.data.advisor.primaryContact.profile
          ) {
            this.profileImg = res.data.advisor.primaryContact.profile;
          } else {
            this.profileImg;
          }
        });
    },
    hideSideBar() {
      this.$emit("expantSideBarOnHover");
    },
    showhideLogo: function () {
      this.showLogo = !this.showLogo;
    },
    searchbox: function () {
      this.showSearch = !this.showSearch;
    },
    logout(item) {
      if (item.title == "Logout") {
        localStorage.clear();
        this.$cookies.remove("token");
        this.$router.replace("/login");
      }
    },
  },
};
</script>
<style>
.dark-blue {
  color: #326ab3;
}
</style>



