<template>
  <div class="container-fluid background-gray">
    <v-app-bar app clipped-left clipped-right class="no-padding">
      <v-toolbar-title
        class="col-lg-1 col-md-1 col-sm-2 col-sm-1 no-padding"
        align="center"
      >
        <img id="logo-img" src="/apaly1.png" class="apaly-pic pointer" />
      </v-toolbar-title>
      <v-divider class="header-divider col-md-1" vertical></v-divider>
      <v-spacer />
      <div class="user-settings">
        <div
          class="font text-right text-bold pointer"
          @click="$router.push('/login')"
        >
          Login
        </div>
        <div class="tiny-font"></div>
      </div>
      <v-menu
        bottom
        left
        offset-y
        origin="top right"
        transition="scale-transition"
      >
        <template v-slot:activator="{}">
          <v-btn dark icon class="mr-1">
            <v-avatar size="40">
              <img
                style="object-fit: cover"
                src="https://www.pngkit.com/png/full/128-1280585_user-icon-fa-fa-user-circle.png"
                alt="John"
              />
            </v-avatar>
          </v-btn>
        </template>
        <v-list>
          <v-divider></v-divider>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-row
      class="background-gray text-center justify-center"
      style="justify-content: center"
    >
      <div class=" col-md-9 text-center">
        <div class="add-client-form row">
          <div class="col-md-6">
            <v-card class="mb-5">
              <v-form ref="form" v-model.trim="valid">
                <div class="col-md-12 orange-underline-wizard">
                  <h3>Enter A Zip Code And Find All The Rest In The Radius</h3>
                </div>
                <div class="col-md-12">
                  <v-text-field :rules="zip.length > 0 ? zipRules  : []" solo v-model.trim="zip"></v-text-field>
                </div>
                <div class="col-md-12 text-end  pt-0 justify-end">
                  <v-progress-circular
                    class="text-center"
                    indeterminate
                    color="primary"
                    v-if="loader"
                  ></v-progress-circular>
                  <v-btn
                    class="blue-buttons"
                    :disabled="loader"
                    @click="submit(1)"
                    >Check</v-btn
                  >
                </div>
                <div>
                  <v-simple-table fixed-header height="815px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Place Name</th>
                          <th class="text-left">Country</th>
                          <th class="text-left">State</th>
                          <th class="text-left">Zip Code</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in zipCodes" :key="index">
                          <td class="text-left">{{ item.PlaceName }}</td>
                          <td class="text-left">{{ item.Country }}</td>
                          <td class="text-left">{{ item.State }}</td>
                          <td class="text-left">{{ item.Zip }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </v-form>
            </v-card>
          </div>
          <div class="col-md-6">
            <v-card class="col-md-12 mb-5 no-padding">
              <h3 class="orange-underline-wizard col-md-12 no -padding">Provider Matching Zip Codes</h3>
              <div class="mt-10">
                <v-simple-table
                  fixed-header
                  :height="showButton ? '865px' : '925px'"
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Provider Name</th>
                        <th class="text-left">NPI Type</th>
                        <th class="text-left">Speciality</th>
                        <th class="text-left">City</th>
                        <th class="text-left">State</th>
                      </tr>
                    </thead>
                    <tbody v-if="providers.length > 0">
                      <tr v-for="(item, index) in providers" :key="index">
                        <td
                          class="text-gray text-left"
                          v-if="
                            item[
                              'Provider Organization Name (Legal Business Name)'
                            ]
                          "
                        >
                          {{
                            item[
                              "Provider Organization Name (Legal Business Name)"
                            ]
                          }}
                        </td>
                        <td
                          class="text-gray text-left"
                          v-else-if="
                            item['Provider Last Name (Legal Name)'] ||
                            item['Provider First Name']
                          "
                        >
                          {{ item["Provider First Name"] }}
                          {{ item["Provider Last Name (Legal Name)"] }}
                        </td>
                        <td class="text-gray text-left">
                         Type:  {{ item["Entity Type Code"] }}
                        </td>
                        <td class="text-left">
                          {{ item["Healthcare Provider Taxonomy Code_1"] }}
                        </td>
                        <td
                          class="text-gray text-left"
                          v-html="
                            item[
                              'Provider Business Practice Location Address City Name'
                            ]
                              ? item[
                                  'Provider Business Practice Location Address City Name'
                                ]
                              : '/'
                          "
                        ></td>
                        <td
                          class="text-gray text-left"
                          v-html="
                            item['Provider Business Mailing Address State Name']
                              ? item[
                                  'Provider Business Mailing Address State Name'
                                ]
                              : '/'
                          "
                        ></td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr class="text-left ">
                        <span class="ml-3">No Results Found.</span>
                        
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <div v-if="showButton" class="col-md-12 text-end">
                  <v-btn
                    class="blue-buttons"
                    @click="submit(2)"
                    :disabled="loadData"
                    v-if="showButton"
                    >Load More Data</v-btn
                  >
                </div>
              </div>
            </v-card>
          </div>
        </div>
      </div>
    </v-row>
    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
  </div>
</template>
<script>
import api from "../services/api";
import errorModal from "../components/TablesTemplate/ErrorDialog.vue";

export default {
  components: { errorModal },
  data() {
    return {
      valid: false,
      zip: "",
      zipCodes: [],
      loadData: false,
      providers: [],
      loader: false,
      showButton: false,
      page: 1,
      zipListObject: null,
      requiredRules: [(v) => !!v || "Zip is required"],
       zipRules: [
        (v) => (v && v.length == 5) || " Zip Code should be five digits long",
      ],
    };
  },
  methods: {
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    submit(type) {
      this.$refs.form.validate();
      if (this.valid && this.zip.length > 0) {
        if (type == 2) {
          this.loadData = true;
          this.page++;
          api()
            .post(
              `/locations/npi?page=${this.page}&limit=100`,
              this.zipListObject
            )
            .then((res) => {
              if (res) {
                res.data.items.forEach((el) => {
                  this.providers.push(el);
                });
                this.loader = false;
                this.loadData = false;

                if (res.data.items.length % 100 == 0) {
                  this.showButton = true;
                } else if (res.data.items.length < 100) {
                  this.showButton = false;
                } else {
                  this.showButton = true;
                }
              }
            });
        } else {
          let arr = [];
          this.loader = true;
          api()
            .get("/locations/nearLocations?zipCode=" + this.zip)
            .catch((err) => {
              this.loader = false;
              if (err) {
                this.$refs.error_modal.openPopUp(err.response.data.error);
              } else {
                this.$refs.error_modal.openPopUp(
                  "Something went wrong, please try again"
                );
              }
            })
            .then((res) => {
              this.zipCodes = res.data;
              let array = [];
              this.zipCodes.forEach((el) => {
                array.push(Number(el.Zip));
              });
              let data = {
                zipCodes: array,
              };
              this.zipListObject = data;
              if (res) {
                api()
                  .post(`/locations/npi`, data)
                  .then((res) => {
                    if (res) {
                      this.providers = res.data.items;
                      this.loader = false;

                      if (res.data.items.length % 100 == 0) {
                        this.showButton = true;
                      } else if (res.data.items.length < 100) {
                        this.showButton = false;
                      } else {
                        this.showButton = true;
                      }
                    }
                  });
              }
            });
        }
      }
    },
  },
};
</script>
