<template>
  <v-row class="add-client-form no-padding row-height">
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12">
          <h3 class="form-title font">Account Settings</h3>
        </div>
      </div>
       <div class="col-md-12 text-center justify-center" v-if="readyToShow">
        <v-progress-circular
          indeterminate
          :size="100"
          color="primary"
          class="text-center"
        ></v-progress-circular>
      </div>
      <v-form v-model.trim="valid" ref="form" class="row" v-else>
        <div class="col-md-6">
          <div class="col-md-12 pb-0">
            <label class="font text-gray" for="">Company Legal Name</label>
            <v-text-field
              required
              :rules="requiredRules"
              v-model.trim="companyName"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Company Display Name</label>
            <v-text-field
              required
              :rules="requiredRules"
              v-model.trim="displayName"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Tax ID</label>
            <v-text-field
              required
              v-model.trim="taxId"
              @keyup="showSlash($event)"
              solo
              placeholder="00-0000000"
              :rules="taxIdRules"
              counter="10"
              maxlength="10"
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Website</label>
            <v-text-field required v-model.trim="website" solo></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for=""
              >Company Logo (Image Size - Width = 100px Height = 50px)</label
            >
            <div class="row">
              <div class="col-xl-10 col-md-9 col-xs-12 col-sm-12" cols="12">
                <v-file-input
                  @change="Preview_image"
                  v-model.trim="dataComponents.image"
                  required
                  solo
                  truncate-length="15"
                  ref="fileInput"
                ></v-file-input>
              </div>
              <div
                class="col-xl-2 col-md-3 col-xs-12 col-sm-12"
                v-if="$role != 'user'"
                cols="12"
              >
                <v-btn
                  class="blue-buttons col-sm-12"
                  @click="activateFileInput"
                  style="padding: 23px"
                >
                  Browse
                </v-btn>
              </div>
            </div>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for=""
              >Profile Picture (Image Size - Width = 100px Height = 50px)</label
            >
            <div class="row">
              <div class="col-xl-10 col-md-9 col-xs-12 col-sm-12" cols="12">
                <v-file-input
                  @change="Preview_profileImage"
                  v-model.trim="dataComponents.profileImage"
                  required
                  solo
                  truncate-length="15"
                  ref="profileInput"
                ></v-file-input>
              </div>
              <div
                class="col-xl-2 col-md-3 col-xs-12 col-sm-12"
                v-if="$role != 'user'"
                cols="12"
              >
                <v-btn
                  class="blue-buttons col-sm-12"
                  @click="activateProfileInput"
                  style="padding: 23px"
                >
                  Browse
                </v-btn>
              </div>
            </div>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">HQ Address Line 1</label>
            <v-text-field required v-model.trim="address1" solo></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">HQ Address Line 2</label>
            <v-text-field required v-model.trim="address2" solo></v-text-field>
          </div>
          <v-row class="no-padding">
            <div class="col-md-6 pb-0 pt-0">
              <label class="font text-gray" for="">City</label>
              <v-text-field required v-model.trim="city" solo></v-text-field>
            </div>
            <div class="col-md-3 pb-0 pt-0">
              <label class="font text-gray" for="">State</label>
              <v-select
                :items="states"
                item-text="name"
                item-value="name"
                required
                v-model.trim="state"
                solo
              ></v-select>
            </div>
            <div class="col-md-3 pb-0 pt-0">
              <label class="font text-gray" for="">Zip</label>
              <v-text-field
                required
                type="number"
                v-model.trim="zip"
                solo
                :rules="zip.length > 0 ? zipRules : []"
              ></v-text-field>
            </div>
          </v-row>
        </div>
        <div class="col-md-6">
          <div class="col-md-12 pb-0">
            <label class="font text-gray" for=""
              >Primary Contact First Name
            </label>
            <v-text-field required v-model.trim="firstName" solo></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for=""
              >Primary Contact Last Name</label
            >
            <v-text-field required v-model.trim="lastName" solo></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Primary Contact Title</label>
            <v-text-field required v-model.trim="title" solo></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Primary Contact Email</label>
            <v-text-field
              required
              :rules="email.length > 0 ? emailRules : []"
              v-model.trim="email"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12">
            <label class="font text-gray" for="">Primary Contact Phone</label>
            <v-text-field
              required
              v-model.trim="phone"
              solo
              :rules="phone.length > 0 ? phoneRules : []"
            ></v-text-field>
          </div>
          <div class="col-md-12 mt-3">
            <v-btn
              @click="generatePdf"
              large
              class="blue-buttons blue-buttons-font-size"
              >Access the Apaly Health Terms of Use Agreement</v-btn
            >
          </div>
        </div>
        <div
          class="col-md-12 d-flex align-end flex-column"
          v-if="$role != 'user'"
        >
          <v-btn class="blue-buttons" @click="update"> Update & Save </v-btn>
        </div>
      </v-form>
    </div>
  </v-row>
</template>
<script>
import api from "../../../../services/api";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";

export default {
  components: { successModal },
  data() {
    return {
      companyName: "",
      taxId: "",
      website: "",
      logo: "",
      phone: "",
      title: "",
      firstName: "",
      lastName: "",
      email: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      website: "",
      displayName: "",
      companyLogo: {
        name: "",
      },
      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
      states: [],
      discountRate: "",
      valid: false,
      zipRules: [
        (v) => (v && v.length == 5) || " Zip Code should be five digits long",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
      imageEvent: null,
      emailRules: [
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      logoChange: false,
      profileChange: false,
      imageProfleEvent: null,
      readyToShow:false,
      dataComponents: {
        image: {
          name: "",
        },
        profileImage: {
          name: "",
        },
      },
      taxIdRules: [
        (v) => !!v || "This field is required",
        (v) =>
          !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
          "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
      ],
    };
  },
  computed: {
    singleEmp() {
      let provider = "";
      if (localStorage.getItem("employer")) {
        provider = JSON.parse(localStorage.getItem("employer"));
      }

      return provider;
    },
  },
  created() {
    this.getStates();
    this.getInfo();
  },
  methods: {
    generatePdf() {
      window.open("https://api.apaly.net/terms");
    },
    showSlash(event) {
      if (this.taxId.length == 2 && event.keyCode != 8) {
        this.taxId += "-";
      }
      if (this.taxId.length == 10) {
        api()
          .get("auth/isValidTaxId?taxId=" + this.taxId)
          .catch((res) => {
            if (res) {
              this.taxIdRules = [res.response.data.message];
            }
          });
      } else {
        this.taxIdRules = [
          (v) => !!v || "This field is required",
          (v) =>
            !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
            "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
        ];
      }
    },
    activateFileInput() {
      this.$refs.fileInput.$refs.input.click();
    },
    getStates() {
      api()
        .get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },
    getInfo() {
      this.readyToShow = true;

      let user = JSON.parse(localStorage.getItem("user"));
      let provider = user.entity._id;

      api()
        .get(`/providers/${provider}`)
        .then((res) => {
          if (res) {
            this.companyName = res.data.provider.providerName
              ? res.data.provider.providerName
              : res.data.provider.companyName
              ? res.data.provider.companyName
              : "";
            this.displayName = res.data.provider.displayName
              ? res.data.provider.displayName
              : "";
            this.taxId = res.data.provider.taxId ? res.data.provider.taxId : "";
            this.address1 = res.data.provider.address1
              ? res.data.provider.address1
              : "";
            this.address2 = res.data.provider.address2
              ? res.data.provider.address2
              : "";
            this.website = res.data.provider.website
              ? res.data.provider.website
              : "";
            this.city = res.data.provider.city ? res.data.provider.city : "";
            this.state = res.data.provider.state ? res.data.provider.state : "";
            this.zip = res.data.provider.zipCode
              ? res.data.provider.zipCode
              : "";
            this.firstName = res.data.provider.primaryContact.firstName
              ? res.data.provider.primaryContact.firstName
              : "";
            this.lastName = res.data.provider.primaryContact.lastName
              ? res.data.provider.primaryContact.lastName
              : "";
            this.title = res.data.provider.primaryContact.title
              ? res.data.provider.primaryContact.title
              : "";
            this.phone = res.data.provider.primaryContact.phone
              ? res.data.provider.primaryContact.phone
              : "";
            this.email = res.data.provider.primaryContact.email
              ? res.data.provider.primaryContact.email
              : "";
            this.dataComponents.image.name = res.data.provider.logo
              ? res.data.provider.logo
              : "";
            this.dataComponents.profileImage.name = res.data.provider
              .primaryContact.profile
              ? res.data.provider.primaryContact.profile
              : "";
          }
          this.readyToShow = false;
        });
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
    },
    updateLogoOrProfile() {
      let user = JSON.parse(localStorage.getItem("user"));
      let provider = user.entity._id;

      if (this.imageProfleEvent) {
        let formData = new FormData();
        formData.append("_method", "PUT");
        formData.append("image", this.imageProfleEvent);
        api()
          .put(`/providers/${provider}/profile`, formData)
          .then((res) => {
            document.getElementById("profile-img").click();
          });
      } else {
        api()
          .delete(`/providers/${provider}/profile`)
          .then((res) => {
            document.getElementById("profile-img").click();
          });
      }
      if (this.imageEvent) {
        let formData = new FormData();
        formData.append("_method", "PUT");
        formData.append("image", this.imageEvent);
        api()
          .put(`/providers/${provider}/logo`, formData)
          .then((res) => {
            document.getElementById("logo-img").click();
          });
      } else {
        api()
          .delete(`/providers/${provider}/logo`)
          .then((res) => {
            document.getElementById("profile-img").click();
          });
      }
    },
    update() {
      this.$refs.form.validate();
      if (this.valid) {
        let data = {
          companyName: this.companyName,
          providerName: this.companyName,
          displayName: this.displayName,
          address1: this.address1,
          address2: this.address2,
          website: this.website,
          city: this.city,
          state: this.state,
          zipCode: this.zip,
          taxId: this.taxId,
          firstName: this.firstName,
          lastName: this.lastName,
          title: this.title,
          phone: this.phone,
          email: this.email,
        };

        let user = JSON.parse(localStorage.getItem("user"));
        let provider = user.entity._id;
        let formData = new FormData();
        formData.append("_method", "PUT");
        formData.append("image", this.imageEvent);

        api()
          .put(`/providers/${provider}`, data)
          .then((res) => {
            if (res) {
              if (this.profileChange) {
                if (this.imageProfleEvent) {
                  let formData = new FormData();
                  formData.append("_method", "PUT");
                  formData.append("image", this.imageProfleEvent);
                  api()
                    .put(`/providers/${provider}/profile`, formData)
                    .then((res) => {
                      document.getElementById("profile-img").click();
                    });
                } else {
                  api()
                    .delete(`/providers/${provider}/profile`)
                    .then((res) => {
                      document.getElementById("profile-img").click();
                    });
                }
              }
              if (this.logoChange) {
                if (this.imageEvent) {
                  let formData = new FormData();
                  formData.append("_method", "PUT");
                  formData.append("image", this.imageEvent);
                  api()
                    .put(`/providers/${provider}/logo`, formData)
                    .then((res) => {
                      document.getElementById("logo-img").click();
                    });
                } else {
                  api()
                    .delete(`/providers/${provider}/logo`)
                    .then((res) => {
                      document.getElementById("logo-img").click();
                    });
                }
              }

              this.$refs.success_modal.openPopUp(
                "Account updated successfully"
              );
              this.logoChange = false;
              this.profileChange = false;
              document.getElementById("changeProDisplayName").click();
            }
          });
      }
    },
    activateProfileInput() {
      this.$refs.profileInput.$refs.input.click();
    },
    Preview_image(event) {
      this.logoChange = true;
      this.imageEvent = event;
    },
    Preview_profileImage(event) {
      this.profileChange = true;
      this.imageProfleEvent = event;
    },
  },
};
</script>