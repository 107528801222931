<template>
  <div class="white-toolbar justify-space-between d-flex">
    <div
      v-if="
        $route.path == '/solution-vendor' ||
        $route.path == '/solution-vendor/home'
      "
    >
      <span class="text-gray font-quicksand-medium-14">Home</span>
    </div>

    <div v-else-if="showRoutePathForFilter">
      <div v-if="showRoutePathForFilter == 'TPA'">
        <router-link
          class="font-quicksand-medium-14"
          to="/solution-vendor/home"
        >
          <span @click="$store.commit('setNavItemFilter', false)"> Home </span>
        </router-link>
        \
        <router-link
          class="font-quicksand-medium-14"
          to="/solution-vendor/clients"
        >
          <span @click="$store.commit('setNavItemFilter', false)">
            Clients
          </span>
        </router-link>
        \
        <router-link
          class="font-quicksand-medium-14"
          to="/solution-vendor/clients/add-client"
        >
          <span @click="$store.commit('setNavItemFilter', 'new')">
            New Client
          </span>
        </router-link>
        \
        <span
          class="text-gray font-quicksand-medium-14"
          @click="$store.commit('setNavItemFilter', 'TPA')"
        >
          Add TPAs
        </span>
      </div>
      <div v-if="showRoutePathForFilter == 'invite'">
        <router-link
          class="font-quicksand-medium-14"
          to="/solution-vendor/home"
        >
          <span @click="$store.commit('setNavItemFilter', false)">
            Home
          </span> </router-link
        >\

        <router-link
          class="font-quicksand-medium-14"
          to="/solution-vendor/clients"
        >
          <span @click="$store.commit('setNavItemFilter', false)">
            Clients
          </span>
        </router-link>
        \
        <router-link
          class="font-quicksand-medium-14"
          to="/solution-vendor/clients/add-client"
        >
          <span @click="$store.commit('setNavItemFilter', false)">
            New Client
          </span>
        </router-link>
        \
        <span
          class="font-quicksand-medium-14 text-gray"
          @click="$store.commit('setNavItemFilter', 'invite')"
        >
          Invite a TPA
        </span>
      </div>
      <div v-if="showRoutePathForFilter == 'new'">
        <router-link
          class="font-quicksand-medium-14"
          to="/solution-vendor/home"
        >
          <span @click="$store.commit('setNavItemFilter', false)"> Home </span>
        </router-link>

        \
        <router-link
          class="font-quicksand-medium-14"
          to="/solution-vendor/clients"
        >
          <span @click="$store.commit('setNavItemFilter', false)">
            Clients
          </span>
        </router-link>
        \
        <span class="text-gray text-gray font-quicksand-medium-14">
          New Client
        </span>
      </div>
    </div>

    <div v-else>
      <span v-for="(route, index) in $route.matched" :key="index">
        <span v-if="route && route.name">
          <router-link
            class="font-quicksand-medium-14"
            v-if="index != Object.keys($route.matched).length - 1"
            :to="route.path"
          >
            <span>
              {{ route.name }}
            </span>
          </router-link>
          <span class="text-gray font-quicksand-medium-14" v-else>
            <span>
              {{ route.name }}
            </span>
          </span>
          <span
            class="text-gray font-quicksand-medium-14"
            v-if="index != Object.keys($route.matched).length - 1"
          >
            \
          </span>
        </span>
      </span>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },

  props: {
    clientName: {
      type: String,
    },
  },
  computed: {
    ...mapGetters(["showRoutePathForFilter"]),
    params() {
      let emp = "";

      return emp;
    },
    singleEmployer() {
      let emp = "";
      let name = "";
      if (localStorage.getItem("employer")) {
        emp = JSON.parse(localStorage.getItem("providerName"));
        name = emp;
      } else if (this.$route.params.name) {
        name = this.$route.params.name;
      }

      return name;
    },
    singleProvider() {
      let pro = "";
      let name = "";
      if (localStorage.getItem("provider")) {
        pro = JSON.parse(localStorage.getItem("providerName"));
        name = pro;
      } else if (this.$route.params.name) {
        name = this.$route.params.name;
      }

      return name;
    },
  },
  created() {},
};
</script>
<style>
.light-blue {
  color: #109cf1 !important;
}
</style>