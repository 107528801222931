<template>
  <div class="">
    <div v-if="!isChildren" class="row-height">
      <Header
        :title="'Invoices'"
        :searchInput="true"
        :exportButton="true"
        :addButton="true"
        @invite="invite"
        @add="add"
        @export="exportData"
        @searchInput="searchInputFunction"
        :placeholder="'Search Invoices'"
        :sort="false"
        :type="false"
      ></Header>
      <table style="display: none" id="tblData">
        <thead>
          <tr>
            <th class="text-left">CLIENT NAME</th>
            <th class="text-left">INVOICE</th>
            <th class="text-left">DATE</th>
            <th class="text-left">AMOUNT</th>
            <th class="text-left">STATUS</th>
          </tr>
        </thead>

        <tbody class="text-gray tr-custom-height">
          <tr v-for="(item, index) in items" :key="index">
            <td>
              <a
                v-if="item.client && item.client.primaryContact"
                @click="showClient(item)"
                >{{ item.client.primaryContact.firstName }}
                {{ item.client.primaryContact.lastName }}</a
              >
              <a v-else @click="showClient(item)">/</a>
            </td>

            <td>
              <span v-if="item.invoiceNo">{{ item.invoiceNo }}</span>
              <span v-else>/</span>
            </td>

            <td v-if="item.date">
              {{ item.date | formatDate }}
            </td>
            <td v-html="item.amount ? item.amount : '/'"></td>
            <td>
              {{
                item.status
                  ? item.status.charAt(0).toUpperCase() + item.status.slice(1)
                  : "/"
              }}
            </td>
          </tr>
        </tbody>
      </table>

      <div class="mt-3">
        <v-simple-table
          fixed-header
          height="70vh"
          class="no-padding tr-custom-height"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">CLIENT NAME</th>
                <th class="text-left">INVOICE #</th>
                <th class="text-left">DATE</th>
                <th class="text-left">AMOUNT</th>
                <th class="text-left">STATUS</th>
              </tr>
            </thead>

            <tbody
              v-if="items.length > 0 && !filterItems"
              class="text-gray tr-custom-height"
            >
              <tr v-for="(item, index) in items" :key="index">
                <td>
                  <a
                    v-if="item.client && item.client.primaryContact"
                    @click="showClient(item)"
                    >{{ item.client.primaryContact.firstName }}
                    {{ item.client.primaryContact.lastName }}</a
                  >
                  <a v-else @click="showClient(item)">/</a>
                </td>

                <td>
                  <span v-if="item.invoiceNo">{{ item.invoiceNo }}</span>
                  <span v-else>/</span>
                </td>

                <td v-if="item.date">
                  {{ item.date | formatDate }}
                </td>
                <td v-html="item.amount ? item.amount : '/'"></td>
                <td>
                  {{
                    item.status
                      ? item.status.charAt(0).toUpperCase() +
                        item.status.slice(1)
                      : "/"
                  }}
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="items.length < 1 && filterItems">
              <v-col md="12" cols="12">
                <v-alert
                  border="bottom"
                  colored-border
                  type="warning"
                  elevation="2"
                  class="text-gray"
                >
                  Sorry! No results found
                </v-alert>
              </v-col>
            </tbody>

            <tbody v-else>
              <v-col md="12">
                There are no invoices that have been received for any of your
                clients.
              </v-col>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import Header from "../../DigitalVendorInvoices/Layout/HeaderPage.vue";
import api from "../../../../services/api";

export default {
  data() {
    return {
      showDetails: false,
      tab: "solution",
      dialog: false,
      items: [],

      singleItem: null,
      typeItems: [{ text: "Categories", value: "Categories" }],
      sortItems: [{ text: "Newest", value: "Newest" }],
      sortBy: "",
      type: "",
      solutions: [],
      favorites: [],
      filterItems: false,
    };
  },
  components: {
    Header,
  },
  computed: {
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
  },
  created() {
    this.getData();
  },
  methods: {
    exportData() {
      var elt = document.getElementById("tblData");
      var downloadLink;
      var dataType = "application/vnd.ms-excel";
      var tableSelect = elt;
      var tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

      // Specify file name
      let filename = "excel_data.xls";

      // Create download link element
      downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        var blob = new Blob(["\ufeff", tableHTML], {
          type: dataType,
        });
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = "data:" + dataType + ", " + tableHTML;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
    },
    invite() {},
    getData() {
      api()
        .get("/vendors/invoices")
        .then((res) => {
          this.items = res.data;
          this.filterArray = res.data;
        });
    },
    add() {
      this.$router.push("/solution-vendor/invoices/new");
    },

    showClient(item) {
      this.singleItem = item;
      this.$store.commit("setSingleSolution", item);

      // this.$router.push("/solution-vendor/invoices/details/" + item.id);
    },

    searchInputFunction(val) {
      this.items = this.filterArray.filter((el) => {
        if (
          el.client &&
          el.client.primaryContact &&
          el.client.primaryContact.firstName &&
          el.client.primaryContact.lastName
        ) {
          let fullName =
            el.client.primaryContact.firstName +
            " " +
            el.client.primaryContact.lastName;
          let reversefullName =
            el.client.primaryContact.lastName +
            " " +
            el.client.primaryContact.firstName;
          if (fullName.toString().toLowerCase().includes(val.toLowerCase())) {
            return el;
          } else if (
            reversefullName.toString().toLowerCase().includes(val.toLowerCase())
          ) {
            return el;
          }
        }
      });
      if (this.items.length < 1) {
        this.filterItems = true;
      } else {
        this.filterItems = false;
      }
    },
  },
};
</script>
<style>
</style>