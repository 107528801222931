<template>
  <div class="no-padding row-height">
    <removeDialog
      ref="dialog_delete"
      v-on:confirm="confirmRemove"
    ></removeDialog>
    <div class="col-md-12">
      <v-row>
        <v-col class="col-xl-1 col-lg-4 col-md-4" md="4" cols="12">
          <h3>
            <span class="font"> Partners </span>
          </h3>
        </v-col>
        <v-col class="col-xl-2 col-lg-4 col-md-4" md="4" cols="12">
          <v-text-field
            class="input-control"
            @input="searchPartners"
            v-if="filteredArray.length > 0"
            prepend-inner-icon="mdi-magnify"
            light
            dense
            outlined
            placeholder="Search Partner"
          >
          </v-text-field>
        </v-col>
        <v-col
          class="col-xl-9 col-lg-4 col-md-4 text-end"
          cols="12"
          align="end"
        >
          <v-menu offset-y v-if="$role != 'user'">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" class="my-1 blue-buttons">
                Add / Invite Partners</v-btn
              >
            </template>
            <v-list>
              <v-list-item style="disply: block">
                <a
                  class="a-tag underline-text"
                  @click="$router.push(`/employer/partners/add-new`)"
                  >Add Partner</a
                >
              </v-list-item>
              <v-list-item>
                <a
                  class="a-tag underline-text"
                  @click="$router.push(`/employer/partners/invite-partner`)"
                  >Invite Partner</a
                >
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
            v-else
            class="my-1 blue-buttons"
            @click="$router.push(`/employer/partners/add-new`)"
          >
            Search Partners</v-btn
          >
        </v-col>
      </v-row>
    </div>

    <div class="col-md-12 text-center justify-center" v-if="readyToShow">
      <v-progress-circular
        indeterminate
        :size="100"
        color="primary"
        class="text-center"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-row class="col-md-12" v-if="allPartners.length < 1 && !filterItems">
        <v-col md="12">
          You have not yet added any partners to your account.
          <a
            v-if="$role != 'user'"
            @click="$router.push(`/employer/partners/add-new`)"
          >
            Click here to add partners.</a
          >
        </v-col>
      </v-row>
      <v-simple-table v-else fixed-header height="630px">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Partner Name</th>
              <th class="text-left">Type</th>
              <th class="text-left">Primary Contact</th>
              <th class="text-left">State</th>
              <th class="text-end" v-if="$role != 'user'">Actions</th>
            </tr>
          </thead>
          <tbody v-if="allPartners.length > 0">
            <tr v-for="item in allPartners" :key="item.name" class="text-gray">
              <td>
                <a @click="singlePartner(item)">{{ item.companyName }}</a>
              </td>
              <td
                v-html="
                  item.advisorType == 'brokerConsultant'
                    ? 'Broker Consultant'
                    : item.advisorType == 'analyticsConsultant'
                    ? 'Analytics Consultant'
                    : item.advisorType
                "
              >
                {{ item.advisorType }}
              </td>
              <td v-if="item.primaryContact">
                {{ item.primaryContact.firstName }}
                {{ item.primaryContact.lastName }}
              </td>
              <td v-else>/</td>
              <td v-html="item.state ? item.state : '/'"></td>
              <td class="text-end" v-if="$role != 'user'">
                <svg
                  class="ml-2 mt-2 pointer"
                  @click="removeItem(item)"
                  width="28"
                  height="26"
                  viewBox="0 0 28 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.35547 13C1.35547 16.1826 2.65524 19.2348 4.96884 21.4853C7.28244 23.7357 10.4204 25 13.6923 25C16.9642 25 20.1021 23.7357 22.4157 21.4853C24.7293 19.2348 26.0291 16.1826 26.0291 13C26.0291 9.8174 24.7293 6.76516 22.4157 4.51472C20.1021 2.26428 16.9642 1 13.6923 1C10.4204 1 7.28244 2.26428 4.96884 4.51472C2.65524 6.76516 1.35547 9.8174 1.35547 13V13Z"
                    stroke="#D00000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.75781 17.8002L18.6273 8.2002"
                    stroke="#D00000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18.6273 17.8002L8.75781 8.2002"
                    stroke="#D00000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </td>
            </tr>
          </tbody>
          <div v-if="allPartners.length < 1 && filterItems">
            <v-col md="12" cols="12">
              <v-alert
                border="bottom"
                colored-border
                type="warning"
                elevation="2"
                class="text-gray"
              >
                Sorry! No results found
              </v-alert>
            </v-col>
          </div>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>
<script>
import api from "../../../../services/api";
import removeDialog from "../../../TablesTemplate/RemoveDialog.vue";
export default {
  components: { removeDialog },
  data() {
    return {
      items: [],
      allPartners: [],
      filteredArray: [],
      itemToRemove: null,
      filterItems: false,
      readyToShow:false,
    };
  },
  computed: {
    getClient() {
      return this.$store.getters.getClientGetter;
    },
    routeParams() {
      return this.$route.params.name;
    },
  },
  created() {
    this.getAllPartners();
  },
  methods: {
    getAllPartners() {
      this.readyToShow = true;
      this.allPartners = [];
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.employer;
      api()
        .get(`/employers/${advisorId}/advisors`)
        .then((res) => {
          if (res) {
            this.allPartners = res.data.advisors;
            this.filteredArray = res.data.advisors;
            this.$store.commit("setAllPartners", res.data.advisors);
            this.readyToShow = false;
          }
        });
    },
    singlePartner(item) {
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.employer;

      api()
        .get(`/employers/${advisorId}/advisors/${item._id}`)
        .then((res) => {
          if (res) {
            this.$store.commit(
              "setSinglePartnerInEmployerAdvisor",
              res.data.advisor
            );
            this.$store.commit("singlePartnerDeleteButton", true);
            this.$router.push(`/employer/partners/partner`);
          }
        });
    },
    searchPartners(val) {
      this.allPartners = this.filteredArray.filter((el) => {
        if (el.companyName.toLowerCase().includes(val.toLowerCase())) {
          return el.companyName.toLowerCase().includes(val.toLowerCase());
        } else if (
          el.companyName &&
          el.primaryContact &&
          el.primaryContact.firstName &&
          el.primaryContact.firstName.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.primaryContact.firstName
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el.primaryContact &&
          el.primaryContact.lastName &&
          el.primaryContact.lastName.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.primaryContact.lastName
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el.advisorType &&
          el.advisorType.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.advisorType.toLowerCase().includes(val.toLowerCase());
        }
      });
      if (this.allPartners.length < 1) {
        this.filterItems = true;
      }
    },
    confirmRemove() {
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.employer;
      let employer = JSON.parse(localStorage.getItem("employer"));

      api()
        .delete(`/employers/${advisorId}/advisors/${this.itemToRemove._id}`)
        .then((res) => {
          if (res) {
            api()
              .get(`/employers/${advisorId}/advisors`)
              .then((res) => {
                if (res) {
                  this.allPartners = res.data.advisors;
                  this.filteredArray = res.data.advisors;

                  this.$store.commit("setAllPartners", res.data.advisors);
                  this.$refs.dialog_delete.closePopUp();
                }
              });
          }
        });
    },
    removeItem(item) {
      let text = "Are you sure you want to delete this Partner?";
      this.$refs.dialog_delete.openPopUp(text);
      this.itemToRemove = item;
    },
  },
};
</script>
<style>
.green-text {
  color: #0fa64b !important;
}
</style>