<template>
  <div class="text-center">
    <v-dialog
      persistent
      v-if="!dialog1"
      v-model.trim="dialog"
      width="800"
      class="success-modal"
    >
      <v-card>
        <v-card-text class="success-modal">
          <div class="text-center">
            <img src="/error.png" width="200" height="200" alt="" />
          </div>
          <h2 class="text-center font mt-2">
            <h3 v-html="title"></h3>
            <h6 class="text-underline light-blue-text mt-3" @click="showList">
              Show Providers that exists
            </h6>
          </h2>
          <h2 class="text-center mt-10 pointer" @click="$emit('confirm')">
            Back
          </h2>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model.trim="dialog1"
      persistent
      width="500"
      class="success-modal"
    >
      <v-card>
        <div class="col-md-12 text-center">
          <h2 class="red--text">Provider(s)</h2>
          <br />
          <svg
            width="100"
            height="100"
            viewBox="0 0 20 20"
            fill="none"
            class="text-center"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.2002 11.8C15.6217 12.3923 16.836 13.392 17.6902 14.6733C18.5444 15.9546 19.0002 17.4601 19.0002 19"
              stroke="#707070"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M3.3999 18.9999C3.40282 17.953 3.61687 16.9175 4.02925 15.9553C4.44162 14.9931 5.04385 14.124 5.7999 13.3999"
              stroke="#707070"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M14.2002 10.6V12.4C14.2002 13.1956 13.8841 13.9587 13.3215 14.5213C12.7589 15.0839 11.9958 15.4 11.2002 15.4C10.4045 15.4 9.64148 15.0839 9.07887 14.5213C8.51627 13.9587 8.2002 13.1956 8.2002 12.4V10.6"
              stroke="#707070"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M2.6 9.80009C3.48366 9.80009 4.2 9.08375 4.2 8.2001C4.2 7.31644 3.48366 6.6001 2.6 6.6001C1.71634 6.6001 1 7.31644 1 8.2001C1 9.08375 1.71634 9.80009 2.6 9.80009Z"
              stroke="#707070"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M4.19238 8.33594C4.66051 8.50144 5.06578 8.80807 5.35234 9.21355C5.6389 9.61903 5.79264 10.1034 5.79238 10.5999V17.1999C5.79238 17.437 5.8392 17.6717 5.93016 17.8906C6.02112 18.1095 6.15442 18.3083 6.32242 18.4756C6.49041 18.6428 6.68979 18.7752 6.9091 18.8652C7.12841 18.9552 7.36333 19.001 7.60038 18.9999H9.40038C9.87777 18.9999 10.3356 18.8103 10.6732 18.4727C11.0107 18.1352 11.2004 17.6773 11.2004 17.1999V15.3999"
              stroke="#707070"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M11.2 9.39999C13.5196 9.39999 15.4 7.51959 15.4 5.2C15.4 2.8804 13.5196 1 11.2 1C8.8804 1 7 2.8804 7 5.2C7 7.51959 8.8804 9.39999 11.2 9.39999Z"
              stroke="#707070"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M7.6001 3.06396C8.24781 3.73694 9.02422 4.27283 9.88316 4.63977C10.7421 5.00671 11.6661 5.19722 12.6001 5.19996C13.5494 5.20262 14.489 5.00925 15.3601 4.63196"
              stroke="#707070"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
          <h3 v-for="(item, index) in items" :key="index"> <span v-if="!$route.path.includes('dpc-providers')">NPI: </span> {{ item }}</h3>
        </div>
        <h2
          class="text-center pointer"
          @click="
            dialog = false;
            dialog1 = false;
          "
        >
          Done
        </h2>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      dialog1: false,
      title: "",
      text: "",
      items: [],
    };
  },

  methods: {
    showList() {
      this.dialog = false;
      this.dialog1 = true;
    },
    closePopUp() {
      this.dialog = false;
    },
    openPopUp(title, items) {
      this.title = title;
      this.items = items;
      this.dialog = true;
      this.dialog1 = false;
    },
  },
};
</script>

<style>
.bold-mail {
  color: #1976d2;
}
</style>