<template>
<div class="no-padding row-height">
  <removeDialog  ref="dialog_delete" v-on:confirm="confirmRemove"></removeDialog>
  <div class="col-md-12">
     <v-row>
        <v-col class="col-md-6" md="6" cols="12">
            <h3>
              <span class="font"> Contracts</span>
            </h3>
        </v-col> 
       
        <v-col class="col-md-6 text-end" v-if="allContracts.length < 1" cols="12"   align="end">
        <v-menu offset-y v-if="$role != 'user'">
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" class="my-1 blue-buttons"> Add Contract</v-btn>
            </template>
            <v-list>
                <v-list-item style="disply:block">
                    <a class="a-tag underline-text" @click="$router.push(`/employer/contract/add-new`)">Add Contract</a>
                </v-list-item>
                <v-list-item>
                    <a class="a-tag underline-text"  @click="$router.push(`/employer/contract/request`)">Request New Form of Contract</a>
                </v-list-item>
            </v-list>
        </v-menu>
      </v-col>
   </v-row> 
  </div>
  <v-row v-if="allContracts.length < 1">
      <v-col md="12" class="font-bold ml-2">
        You have not yet Contracted on the Platform 
          <a v-if="$role != 'user'"  @click="$router.push(`/employer/contract/add-new`)">- Click Here to Add Your Rates and Contract</a>
      </v-col>
  </v-row>
   <v-simple-table v-else fixed-header  height="630px">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
           Final Contract
          </th>
          <th class="text-left">
           Contract Date
          </th>
          <th class="text-left">
           Outpatient Rate
          </th>
          <th class="text-left">
            Inpatient Rate
          </th>
          <th class="text-left" v-if="$role != 'user'">Actions</th>

        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in allContracts"
          :key="item.name"
        >
          <td v-if="item.name"><a  @click="singleContract(item,'form')">{{ item.name }}</a> </td>
          <td v-else><a  @click="singleContract(item)">/</a> </td>
          <td v-html="item.effectiveDate ? parsedDate(item.effectiveDate) : '/'"></td>
          <td v-if="item.employerRates">{{ item.employerRates.inpatient }}%</td>
          <td v-else>/</td>
          <td v-if="item.employerRates"> {{ item.employerRates.outpatient }}% </td>
          <td v-else>/</td>
           <td class="mt-2" v-if="$role != 'user'">
              <a @click="singleContract(item,'rates')" >Increase Rates</a> <br>
              <a  @click="removeItem(item)" >Cancel Contract</a>
             
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
    <errorModal  ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
  
</div>
</template>
<script>
import api from '../../../../services/api'
import removeDialog from '../../../TablesTemplate/RemoveDialog.vue'
import errorModal from '../../../TablesTemplate/ErrorDialog.vue'
  export default {
    components:{removeDialog,errorModal},
    data () {
      return {
        items: [],
        allContracts:[]
      }
    },
    created(){
        this.getAllContracts()
        
    },
    computed:{

      getClient(){
          return this.$store.getters.getClientGetter
       },
       routeParams(){
          return this.$route.params.name
       },
       filterContracts(){
         return this.items
       }
    },
    methods:{
     parsedDate(date){
         let newDate = new Date(date);
         return newDate.toDateString();
        //  return `${newDate.getMonth()}/${newDate.getDate()}/${newDate.getFullYear()}  `
      },
      closeErrorModal(){
         this.$refs.error_modal.closePopUp();
      },
      removeItem(){
          let text = "Are you sure you want to cancel the Contract?"
          this.$refs.dialog_delete.openPopUp(text);
      },
      confirmRemove(){
          this.$refs.dialog_delete.closePopUp()
           let user = JSON.parse(localStorage.getItem('user'));
           let employerId = user.groupPortal[0].organization.employer
         
           api().delete(`/employers/${employerId}/contract`).catch((err)=>{
             if(err){
               this.$refs.dialog_delete.closePopUp();
               this.$refs.error_modal.openPopUp(err.response.data.message);
             }
           }).then((res)=>{
      
             if(res && res.status == 200){
            
                api().get(`/employers/${employerId}/contract`).catch((err)=>{
                if(err){
                  this.allContracts = [];
                }
                }).then((res)=>{
                if(res){
                  this.allContracts.push(res.data.contract);
                   this.$refs.dialog_delete.closePopUp()
                   this.$refs.error_modal.closePopUp()
                }else{
                  this.allContracts = [];
                   this.$refs.dialog_delete.closePopUp();
                   this.$refs.error_modal.closePopUp()
                }
               })
             }
           });
      },
      getAllContracts(){
           let user = JSON.parse(localStorage.getItem('user'));
           let employerId = user.groupPortal[0].organization.employer
          
           api().get(`/employers/${employerId}/contract`).catch((err)=>{
             if(err){
               this.allContracts = [];
             }
           }).then((res)=>{
                if(res){
                  this.allContracts.push(res.data.contract);
                }
           })
      },
      singleContract(item, type){
          let user = JSON.parse(localStorage.getItem('user'));
          let employerId = user.groupPortal[0].organization.employer
          
           api().get(`/employers/${employerId}/contract`).then((res)=>{
                if(res){
                   this.$store.commit('setSingleContractinEmployer', res.data.contract);
                    this.$router.push(`/employer/contract/details`);
                    this.$store.commit('singleContractView',type)
                }
           })
       
      },
      searchContracts(val){
        this.allContracts = this.filterContracts.filter((el)=>{
          if(el.name.toLowerCase().includes(val.toLowerCase())){
            return el.name.toLowerCase().includes(val.toLowerCase())
          }
        })
      }
    }
  }
</script>
<style>
.green-text{
  color: #0FA64B !important;
}
</style>