<template>
    <div class="no-padding no-margin">
        <ProvidersTable v-if="path == '/member/providers'"></ProvidersTable>
        <ResultTable v-if="path == '/member/providers/result'"></ResultTable>
        <AddProviders v-if="path == '/member/providers/add-provider'"></AddProviders>
        <InviteProvider v-if="path == '/member/providers/invite-provider'"></InviteProvider>
        <ProviderCrudForm v-if="path == '/member/providers/provider'"></ProviderCrudForm>
        <Review v-if="path == '/member/providers/provider/review'"></Review>
      
    </div>
</template>
<script>
import AddProviders from './AddProviders.vue'
import InviteProvider from './InviteProvider.vue'
import ProviderCrudForm from './ProviderCrudForm.vue'
import ResultTable from './ResultTable.vue'
import ProvidersTable from './ProvidersTable.vue'
import Review from './Review.vue'



export default {
    components:{
    ResultTable,
    AddProviders,
    InviteProvider,
    ProviderCrudForm,
    ProvidersTable,
    Review,


    },
    data(){
        return{
            
        }
    },
    computed:{
        path(){
            return this.$route.path
        },
        routeName(){
            return this.$route.params.name
        }
    },
   
    created(){
      
    },
    methods:{
     
    }
   
}
</script>
<style>
.background-opacity{
    opacity: 0.46;
    background-color: rgb(33, 33, 33);
    border-color: rgb(33, 33, 33);
}
</style>