<template>
    <div class="col-md-12">
        <div class="row align-center">
            <div class="col-lg-3 col-md-3">
                <h3>Visit Passes</h3>
            </div>
            <div class="col-lg-4 col-md-4">
                <v-select
                        v-if="user"
                        placeholder="User"
                        required
                        :items="[{text:user.firstName + ' ' + user.lastName, value:user.firstName + ' ' + user.lastName}]"
                ></v-select>
            </div>
       
        </div>
        <div class="row btn-row ">
            <div cols="2">
                <v-btn text x-large 
                       :style="checkIfTabIsActive('pending')" outlined style="width: 6.5rem; border-radius: 0px !important"
                       @click="changeActiveTab('pending')" class="btn-group-pac text-gray">
                    Pending
                </v-btn>
            </div>
            <div  cols="2" >
                <v-btn   text x-large 
                       :style="checkIfTabIsActive('active')" outlined style="width: 6.5rem; border-radius: 0px !important"
                       @click="changeActiveTab('active')" class="btn-group-pac text-gray">
                    Active
                </v-btn>
            </div>
            <div  cols="2" >
                <v-btn  text x-large 
                       :style="checkIfTabIsActive('complete')" outlined style="width: 6.5rem; border-radius: 0px !important"
                       @click="changeActiveTab('complete')" class="btn-group-pac text-gray">
                    Completed
                </v-btn>
            </div>
             <div  cols="2" >
                <v-btn  text x-large 
                       :style="checkIfTabIsActive('denied')" outlined style="width:6.5rem; border-radius: 0px !important"
                       @click="changeActiveTab('denied')" class="btn-group-pac text-gray">
                    Denied
                </v-btn>
            </div>
            
        </div>
        <v-row class="pt-10 pb-10" v-if="visitPasses.length < 1">
            <a @click="$router.push('/member/services')">You don't have Visit Passes with this Status.</a>

        </v-row>
        <div class="row" style="flex-direction: column">
            <v-card class="mt-5" v-for="item,key in visitPasses" :key="key" style="padding:1.5rem">
                <div class="pt-2" v-if="activeTab === 'pending'">
                    <h4 class="visit-header">Visit Pass - <span style="color: red;">PENDING </span></h4>
                </div>
                <div  class="pt-2" v-if="activeTab === 'active'">
                    <h4 class="visit-header">Visit Pass - <span style="color: green">ACTIVE</span></h4>
                </div>
                <div  class="pt-2" v-if="activeTab === 'complete'">
                    <h4 class="visit-header">Visit Pass - <span style="color: grey">COMPLETE</span></h4>
                </div>
                   <div  class="pt-2" v-if="activeTab === 'denied'">
                    <h4 class="visit-header">Visit Pass - <span  style="color: red;">DENIED</span></h4>
                </div>
                <hr class="mt-3">
                <div class="row visit-pass-content mt-3 ml-3">
                <div class="col-lg-4 col-md-4">
                <div>
                    <p>Group #: <b>{{item._id}}</b></p>
                    <p>Payer ID: <b>{{item._id}}</b></p>
                </div>
                <div v-if="item.member">
                    <p>Member: <b>{{item.member.firstName}} {{item.member.lastName}}</b></p>
                </div>
                <div v-if="item.member">
                    <p>Member ID: <b>{{item.member._id}}</b></p>
                </div>
                </div>
                <hr class="horizontal-line">
                <div class="col-lg-4 col-md-4 ">
                   <div>
                        <div>
                        <p>Provider: <a href="">{{item.provider}}</a></p>
                    </div>
                    <div>
                        <p>Prior Auth #: <b>{{item.priorAuthNumber}}</b></p>
                    </div>
                    <div>
                        <p>Service Type: <b>{{item.serviceType}}</b></p>
                    </div>
                    <div>
                        <p>Service: <b>{{item.serviceName}}</b></p>
                    </div>
                   </div>
                </div>
                <hr class="horizontal-line">
                <div class="col-lg-4 col-md-4">
                <div>
                    <p>Member's Capay: <b> ${{item.memberCopay}}</b></p>
                </div>
                <div>
                    <p>Memeber's Deductible: <b>${{item.memberDeductible}}</b></p>
                </div>
                </div>
                </div>
                <hr class="mt-3"  v-if="activeTab !== 'complete'">
                <div class="important-msg" v-if="activeTab !== 'complete'">
                    <div class="mt-5">
                    <h4 class="text-underline" style="color:red">
                        IMPORTANT MESSAGE
                    </h4>
                </div>
                <div>
                <div class="mt-4">
                    <h4 style="color:red">
                        DO NOT PRESENT YOUR INSURANCE CARD TO THE PROVIDER
                    </h4>
                </div>
                <div class="mt-4">
                    <h4 style="color:red">
                        ONLY PRESENT YOUR VISIT PASS
                    </h4>
                </div>
                </div>
                </div>
                <div class="mt-4">
                    <v-btn class="blue-buttons check-btn" v-if="activeTab === 'pending'">Check Prior Auth Status</v-btn>
                    <v-btn class="blue-buttons check-btn" v-if="activeTab === 'active'" @click="changePendingToComplete(item)">My Visit Was Completed</v-btn>
                     <v-btn class="blue-buttons check-btn" v-if="activeTab === 'complete'" @click="denyVisitPass(item)">Deny Visit Pass Request</v-btn>
                    <v-btn class="blue-buttons check-btn" v-if="activeTab === 'denied'" @click="resubmitVisitPass(item)">Resubmit Visit Pass Request</v-btn>
                </div>
            </v-card>
        </div>
     <successModal  ref="success_modal" v-on:confirm="closeSuccessModal"></successModal>

    </div>
</template>

<script>
import api from '../../../../services/api'
import successModal from '../../../TablesTemplate/SuccessDialog.vue'
    export default {
        components:{successModal},
        data() {
            return {
                 activeTab: '',
                 visitPasses:[
                    {   
                        _id:'154-653-142-01',
                        priorAuthNumber:'689-584-254-554',
                        provider:'James Smithson MD',
                        serviceType:'Office Visit',
                        serviceName:'Office Visit - New Patient',
                        memberCopay:'0',
                        memberDeductible:'0',
                        member:{
                             firstName:'Bill',
                             lastName:'Smith',
                             _id:'154-653-142-01',
                            

                        },

                       

                        
                    }
                ],
                user:{}
            }
        },
        methods: {
           closeSuccessModal(){
              let user = JSON.parse(localStorage.getItem('user'));
                 let member = user.member;
              api().get(`/members/${member}/visitPassRequests?status=pending`).then((res)=>{
                    this.visitPasses = res.data.visitPassRequests
                     this.$refs.success_modal.closePopUp();
                    this.activeTab = 'pending'
                   
            });
              
           },
            checkIfTabIsActive(tab) {
                return tab === this.activeTab ? "border-bottom: 8px solid orange;cursor: pointer; background: #fff !important;" : "cursor:pointer;background: #fff ";
            },
            resubmitVisitPass(item){
           
                 let user = JSON.parse(localStorage.getItem('user'));
                 let member = user.member;
                 
                 let data = {
                "member": item.member._id,
                "serviceType": item.serviceType,
                "serviceName": item.serviceName ?item.serviceName : '',
                "serviceDetails":item.service.fullName,
                // 'organization':this.singleItem.provider.companyName ? this.singleItem.provider.companyName : this.singleItem.provider.affiliateProvider ? this.singleItem.provider.affiliateProvider : 'No Organization',
                "provider":   item.provider ?  item.provider : '',
                "provider1":  item.provider1._id,
                // "provider2":  this.singleItem.provider._id,
               "location":   item.location ? item.location : 'no location',
                "specialty": item.specialty,
                "service":  item.service._id,
              }
           
                api().put(`/members/${member}/visitPassRequests/${item._id}/resubmit`, data).then((res)=>{
                    if(res){
                        this.$refs.success_modal.openPopUp('Visit resubmited successfully!');
                    }
                       
                })

            },
            denyVisitPass(item){
            
                let user = JSON.parse(localStorage.getItem('user'));
                let member = user.member;
                
                api().get(`/members/${member}/visitPassRequests/${item._id}/deny`).then((res)=>{
                   if(res){
                        this.$refs.success_modal.openPopUp('Visit Passs Denied!');
                    }
                })

            },
           
            changePendingToComplete(item){
              
             let user = JSON.parse(localStorage.getItem('user'));
             let member = user.member;
                let data = {
                "member": item.member._id,
                "serviceType": item.serviceType,
                "serviceDetails":item.service.fullName,
                "serviceName": item.serviceName ?item.serviceName : '',
                // 'organization':this.singleItem.provider.companyName ? this.singleItem.provider.companyName : this.singleItem.provider.affiliateProvider ? this.singleItem.provider.affiliateProvider : 'No Organization',
                "provider":   item.provider ?  item.provider : '',
                "provider1":  item.provider1._id,
                // "provider2":  this.singleItem.provider._id,
                "location":   item.location ? item.location : 'no location',
                "specialty": item.specialty,
                "service":  item.service._id,
              }
           
                api().post(`/members/${member}/visitPassRequests/${item._id}/complete`, data).then((res)=>{
                  if(res){
                        this.$refs.success_modal.openPopUp('My Visit Was Completed!');
                    }
                      
                })
            },
            changeActiveTab(tab){
                tab === this.activeTab ? this.activeTab = null : this.activeTab = tab;
                let user = JSON.parse(localStorage.getItem('user'));
                let member = user.member;
                if(tab == 'active'){
                   api().get(`/members/${member}/visitPassRequests?status=active`).then((res)=>{
                    this.visitPasses = res.data.visitPassRequests
                    this.activeTab = 'active'
                   
                 });
                }else if(tab == 'pending'){
                  
                   api().get(`/members/${member}/visitPassRequests?status=pending`).then((res)=>{
                    this.visitPasses = res.data.visitPassRequests
                    this.activeTab = 'pending'
                   });
                }else if(tab == 'complete'){
                    
                     api().get(`/members/${member}/visitPassRequests?status=complete`).then((res)=>{
                        this.visitPasses = res.data.visitPassRequests
                        this.activeTab = 'complete'
                   });
                }else if(tab == 'denied'){
                    
                   api().get(`/members/${member}/visitPassRequests?status=denied`).then((res)=>{
                    this.visitPasses = res.data.visitPassRequests
                    this.activeTab = 'denied'
                 });

                }
            }
        },
        created() {
            this.activeTab = 'pending'
            this.user = {
                firstName:'Will',
                lastName:'Smith'
            }
        //    let user = JSON.parse(localStorage.getItem('user'));
        //    let member = user.member;
        //     api().get(`/members/${member}/visitPassRequests?status=pending`).then((res)=>{
        //             this.visitPasses = res.data.visitPassRequests
        //             this.activeTab = 'pending'
        //             this.user = JSON.parse(localStorage.getItem('user'));
        //     });
            
        }
    }
</script>
<style>

</style>

