<template>
    <div class="form-div ">
        <v-card class="col-lg-8  col-xl-6 col-md-6 blue-text">
        <v-form class="col-md-12 flex-column"  ref="form" v-model.trim="valid">
            <h2 class="row mb-10  text-center blue-text justify-center">Platform Checks Email Domain</h2>
            <div class="col-md-12 justify-center">
              <div class="d-flex justify-space-between mt-10 mb-10">
                <h4 class=" blue-text">Portal Exists that Lists that Domain</h4>
                 <v-btn depressed class="select-btn mt-2 ml-3 col-md-4" @click="submit(true)">
                    Next
                </v-btn>
            </div>
             <div class="d-flex justify-space-between">
                <h4  class=" blue-text">Portal Does Not Exist that List that Domain</h4>
                 <v-btn depressed class="select-btn mt-2 ml-3 col-md-4" @click="submit(false)">
                    Next
                </v-btn>
            </div>

            </div>
         
        </v-form>
         </v-card>
    </div>
</template>
<script>
import {mapActions} from 'vuex'
import api from '../../../services/api'
export default{
    data(){
        return{
            errorDiv : true,
            show1:false,
            show2:false,
            retypePassword:'',
            password:'',
            passRules: [
                    v => !!v || 'Password is required',
                    v => v.length > 7 || 'Password must be at least 8 character',
                    v => v.length &&  /[^A-Za-z0-9]/.test(v) || 'Password should contain at least one special character (example @,#,$,etc.)',
                    v => v.length &&   /[A-Z]{1}/.test(v) || 'Password should contain at least one uppercase letter',
            ],
            confirmPassRules: [
                 v => !!v || 'ReType Password is required',
            ],
            valid:false,
            id:'',
            type:''
        }
    },
    created(){
         this.$store.commit(
            "sideBarTitleAndDesc",
            "“Love the life you live. Live the life you love.”"
          );
          this.$store.commit("sideBarDesc", "Bob Marley");

    },
    computed:{ 
       

    },
    methods:{
        submit(exists){
            let email = {
                'email' :  JSON.parse(localStorage.getItem('email'))
            }
            api().post('/auth/findOrganization/basic', email).then((res)=>{
                
                if(res.data.organization){
                    this.$store.commit('setCompanyName',res.data.organization.name)
                }
                if(res.data.found){
                    this.$router.push('/register/organization-found');
                }else{ 
                     this.$router.push('/register/organization-not-found');
                }
                

            }).catch((err)=>{
                if(err.response && err.response.data && err.response.data.accountId){
                        localStorage.setItem('accountId',  JSON.stringify(err.response.data.accountId))
                        this.$store.commit('setAccountId', err.response.data.accountId) 
                }
                    this.$router.push('/register/organization-not-found');
            })
            
        }
    }
  
}
</script>
<style>
.check{
  width: 33px;
  height: 35px;
  padding: 0px;
}
</style>