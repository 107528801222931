<template>
    <div>
        <Welcome v-if="$route.path == '/register'"/>   
        <SelectType  v-if="$route.path == '/register/type'"/>
        <CreateAccount v-else-if="$route.path == `/register/${$route.params.type}/create-account`"/>  
        <SetPassword  v-else-if="$route.path.includes('/auth/verify/') || showCreatePassword"/>
        <OrgExist v-else-if="$route.path.includes('/register/organization-found')"/>  
        <OrgNotExist v-else-if="$route.path.includes('/register/organization-not-found')"/>  
        <CreateOrg v-else-if="$route.path.includes('/register/create-organization')"/>
        <EmailDomain v-else-if="$route.path.includes('/register/domain-list')"/>
        <ConfirmInfo v-else-if="$route.path.includes('/register/confirm-org')"/>
        <ContactEmp v-else-if="$route.path.includes('/register/contact')"></ContactEmp>
        <Reasons  v-else-if="$route.path.includes('/register/reasons') || $route.path.includes('/register/why-not')"  />
        <SingleType v-else-if="$route.params.name"/>



        
    </div>
</template>
<script>
import Welcome from './RegisterComponents/Welcome.vue'
import SingleType from './RegisterComponents/SingleType.vue'
import SelectType from './RegisterComponents/SelectType.vue'
import CreateAccount from './RegisterComponents/CreateAccount.vue'
import SetPassword from './RegisterComponents/CreatePassword.vue'
import CheckDomain from './RegisterComponents/CheckEmailDomain.vue'
import OrgExist from './RegisterComponents/OrganizationExists/OrgFound.vue'
import OrgNotExist from './RegisterComponents/OrganizationNotExists/OrgNotFound.vue'
import CreateOrg from './RegisterComponents/OrganizationNotExists/CreateNewOrg.vue'
import EmailDomain from './RegisterComponents/OrganizationNotExists/CreateEmailDomain.vue'
import ConfirmInfo from './RegisterComponents/OrganizationNotExists/ConfrimInformation.vue'
import Reasons from './RegisterComponents/OrganizationExists/DontSendRequest.vue'
import ContactEmp from './RegisterComponents/OrganizationNotExists/ContactEmp.vue'
    export default{
         components:{
                Welcome,
                SingleType,
                SelectType,
                CreateAccount,
                SetPassword,
                CheckDomain,
                OrgExist,
                OrgNotExist,
                CreateOrg,
                EmailDomain,
                ConfirmInfo,
                Reasons,
                ContactEmp
         },
         computed:{
           showCreatePassword() {
                let bool = false;
                if (
                    this.$route.query &&
                    typeof this.$route.query.memberLink != "undefined" &&
                    this.$route.query.memberLink == null
                ) {
                    bool = true;
                } else if (
                    this.$route.query &&
                    typeof this.$route.query.employerLink != "undefined" &&
                    this.$route.query.employerLink == null
                ) {
                    bool = true;
                } else if (
                    this.$route.query &&
                    typeof this.$route.query.advisorLink != "undefined" &&
                    this.$route.query.advisorLink == null
                ) {
                    bool = true;
                } else if (
                    this.$route.query &&
                    typeof this.$route.query.providerLink != "undefined" &&
                    this.$route.query.providerLink == null
                ) {
                    
                    bool = true;
                } else {
                    bool = false;
                }

                return bool;
                },
         },
         created(){
         
         }
    }
</script>
