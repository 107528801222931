<template>
  <div class="no-padding no-margin">
      <Table v-if="route==`/provider-advisor/providers/${routeParams}/employers`"></Table>
      <AddNewForm v-if="route==`/provider-advisor/providers/${routeParams}/employers/add-new`"></AddNewForm> 
      <SingleForm v-if="route==`/provider-advisor/providers/${routeParams}/employers/employer`"></SingleForm>
      <InviteForm  v-if="route==`/provider-advisor/providers/${routeParams}/employers/invite-employer`"></InviteForm>
  </div>
    
</template>
<script>
import Table from './EmployersTable.vue'
import SingleForm from './SingleEmployer.vue'
import AddNewForm from './AddEmployer.vue'
import InviteForm from './InviteEmployers.vue'
// getPath

export default{
  data(){
    return{
        
    }
  },
  created(){
  },
  computed:{
     route(){
         return this.$route.path.replace(/%20/g, ' ')
      },
      routeParams(){
        return this.$route.params.name
      }
  },
  methods:{

  },
  components:{
        SingleForm,
        AddNewForm,
        Table,
        InviteForm
 
  }
}

</script>
