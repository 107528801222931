
<template>
  <div class="no-padding row-height">
    <div class="col-md-12">
      <v-row>
        <v-col class="col-xl-1 col-lg-4 col-md-4 font" md="4" cols="12">
          <h3>
            <span class="font"> Partners</span>
          </h3>
        </v-col>
        <v-col class="col-xl-2 col-lg-4 col-md-4" md="4" cols="12">
          <v-text-field
            v-if="filteredArray.length > 0"
            class="input-control"
            @input="searchAdvisor"
            prepend-inner-icon="mdi-magnify"
            light
            dense
            outlined
            placeholder="Search Partners"
          >
          </v-text-field>
        </v-col>
        <v-col
          class="col-xl-9 col-lg-4 col-md-4 text-end"
          cols="12"
          align="end"
        >
          <v-btn
            v-if="$role == 'user'"
            @click="$router.push(`/provider/advisors/add-new`)"
            class="my-1 blue-buttons"
          >
            Search Partners</v-btn
          >

          <v-menu v-else offset-y>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" class="my-1 blue-buttons">
                Add / Invite Partners</v-btn
              >
            </template>
            <v-list>
              <v-list-item>
                <a
                  class="a-tag underline-text"
                  @click="$router.push(`/provider/advisors/add-new`)"
                  >Add Partner</a
                >
              </v-list-item>
              <v-list-item>
                <a
                  class="a-tag underline-text"
                  @click="$router.push(`/provider/advisors/invite-advisor`)"
                  >Invite Partner</a
                >
              </v-list-item>
              <!-- <v-list-item>
                <a class="a-tag underline-text" >Copy Invite Link</a>
            </v-list-item> -->
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </div>
    <div class="col-md-12 text-center justify-center" v-if="readyToShow">
      <v-progress-circular
        indeterminate
        :size="100"
        color="primary"
        class="text-center"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-row class="col-md-12" v-if="allAdvisors.length < 1 && !filterItems">
        <v-col md="12">
          You have not yet added any Partners to your account
          <a
            v-if="$role != 'user'"
            @click="$router.push(`/provider/advisors/add-new`)"
            >- Click Here to Add Partners</a
          >
        </v-col>
      </v-row>

      <v-simple-table v-else fixed-header height="630px">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Partner Name</th>
              <th class="text-left">Type</th>
              <th class="text-left">Primary Contact</th>
              <th class="text-left">State</th>
              <th class="text-center" v-if="$role != 'user'">Actions</th>
            </tr>
          </thead>
          <tbody v-if="allAdvisors.length > 0">
            <tr
              v-for="(item, index) in allAdvisors"
              :key="index"
              class="text-gray"
            >
              <td>
                <a @click="singleAdvisor(item)">{{ item.companyName }}</a>
              </td>

              <td
                v-if="item.advisorType"
                v-html="
                  item.advisorType == 'brokerConsultant'
                    ? 'Broker Consultant'
                    : item.advisorType == 'analyticsConsultant'
                    ? 'Analytics Consultant'
                    : item.advisorType
                "
              ></td>
              <td v-else>/</td>
              <td v-if="item.primaryContact">
                {{ item.primaryContact.firstName }}
                {{ item.primaryContact.lastName }}
              </td>
              <td v-else>/</td>
              <td v-html="item.state ? item.state : '/'"></td>
              <td v-if="$role != 'user'" class="text-center">
                <svg
                  class="ml-2 pointer mt-2"
                  @click="removeItem(item)"
                  width="28"
                  height="26"
                  viewBox="0 0 28 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.35547 13C1.35547 16.1826 2.65524 19.2348 4.96884 21.4853C7.28244 23.7357 10.4204 25 13.6923 25C16.9642 25 20.1021 23.7357 22.4157 21.4853C24.7293 19.2348 26.0291 16.1826 26.0291 13C26.0291 9.8174 24.7293 6.76516 22.4157 4.51472C20.1021 2.26428 16.9642 1 13.6923 1C10.4204 1 7.28244 2.26428 4.96884 4.51472C2.65524 6.76516 1.35547 9.8174 1.35547 13V13Z"
                    stroke="#D00000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.75781 17.8002L18.6273 8.2002"
                    stroke="#D00000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18.6273 17.8002L8.75781 8.2002"
                    stroke="#D00000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </td>
            </tr>
          </tbody>
          <div v-if="allAdvisors.length < 1 && filterItems">
            <v-col md="12" cols="12">
              <v-alert
                border="bottom"
                colored-border
                type="warning"
                elevation="2"
                class="text-gray"
              >
                Sorry! No results found
              </v-alert>
            </v-col>
          </div>
        </template>
      </v-simple-table>
      <v-dialog v-model.trim="dialog" max-width="600px" persistent>
        <v-card>
          <v-col>
            <h3>Remove Partner</h3>
          </v-col>

          <v-col>
            <span>Are you sure you want to delete this Partner?</span>
          </v-col>

          <v-col>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="blue-buttons float-class" @click="confirmRemove"
                >Confirm</v-btn
              >
              <v-btn class="button-style float-class" @click="closePopUp"
                >Close</v-btn
              >
            </v-card-actions>
          </v-col>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import api from "../../../../services/api";
export default {
  data() {
    return {
      items: [],
      filteredArray: [],
      dialog: false,
      allAdvisors: [],
      singleItem: null,
      filterItems: false,
      readyToShow:false,
    };
  },
  computed: {
    getClient() {
      return this.$store.getters.getClientGetter;
    },
    routeParams() {
      return this.$route.params.name;
    },
    filterArray() {
      return this.items;
    },
  },
  created() {
    this.getAllAdvisors();
  },
  methods: {
    removeItem(item) {
      this.dialog = true;
      this.singleItem = item;
    },
    getAllAdvisors() {
      this.readyToShow = true;
      let user = JSON.parse(localStorage.getItem("user"));
      let provider = user.entity._id;

      api()
        .get(`/providers/${provider}/advisors`)
        .then((res) => {
          if (res) {
            this.$store.commit("setAllPartners", res.data.advisors);
            this.filteredArray = res.data.advisors;
            this.allAdvisors = res.data.advisors;
            this.readyToShow = false;
          }
        });
    },
    searchAdvisor(val) {
      this.allAdvisors = this.filteredArray.filter((el) => {
        if (el.companyName.toLowerCase().includes(val.toLowerCase())) {
          return el.companyName.toLowerCase().includes(val.toLowerCase());
        }
      });
      if (this.allAdvisors.length < 1) {
        this.filterItems = true;
      }
    },
    singleAdvisor(item) {
      let user = JSON.parse(localStorage.getItem("user"));
      let provider = user.entity._id;

      api()
        .get(`/providers/${provider}/advisors/${item._id}`)
        .then((res) => {
          if (res) {
            this.$store.commit(
              "setSingleAdvisorinEmployerAdvisor",
              res.data.advisor
            );
            this.$store.commit("singlePartnerDeleteButton", true);
            this.$router.push(`/provider/advisors/advisor`);
          }
        });
    },
    closePopUp() {
      this.dialog = false;
    },
    confirmRemove() {
      let user = JSON.parse(localStorage.getItem("user"));
      let provider = user.entity._id;

      api()
        .delete(`/providers/${provider}/advisors/${this.singleItem._id}`)
        .then((res) => {
          if (res) {
            api()
              .get(`/providers/${provider}/advisors`)
              .then((res) => {
                if (res) {
                  this.allAdvisors = res.data.advisors;
                  this.filteredArray = res.data.advisors;
                  this.$store.commit("setAllPartners", res.data.advisors);
                  this.dialog = false;
                }
              });
          }
        });
    },
  },
};
</script>
<style>
.green-text {
  color: #0fa64b !important;
}
</style>