
const state ={
   
    searchAdvsiorResult:[],
    singleAdvisorinEmployerAdvisor:null
}
const getters = {
    getSearchAdvisorsResultInEmployerAdvisor(state){
         return state.searchAdvsiorResult
    },
    getSingleAdvisorinEmployerAdvisor(state){
        return state.singleAdvisorinEmployerAdvisor
    }
}
const mutations = {
   
    setAdvisorSearchResult(state,advisors){
        state.searchAdvsiorResult = advisors
    },
    setSingleAdvisorinEmployerAdvisor(state,advisor){
        state.singleAdvisorinEmployerAdvisor = advisor
    }
    
}

export default{
    state,
    getters,
    mutations,
}

