<template>
 <v-row class="add-client-form no-padding" >
  <div class="col-md-12 form-box ">
    <div class="row">
        <div class="col-md-12">
           <div class="col-md-12 row justify-space-between ">
            <h3 class="form-title font " v-if="singleMember">
               {{singleMember.name}}
            </h3>
            <h3 class="form-title font ">
                Status : <span class="green-text">Acitve</span>
            </h3>
         </div>
        </div>
    </div>
    <form class="row">
        <div class="col-md-6 no-padding">
            <div class="col-md-12">
               <label class="font text-gray" for="">First Name</label>
                <v-text-field
                tabindex="1"
                required
                v-model.trim="fisrstName"
                solo
                ></v-text-field>
           </div>
            <div class="col-md-12">
              <label class="font text-gray" for="">Last Name</label>
                <v-text-field
                tabindex="2"
                required
                v-model.trim="lastName"
                solo
                ></v-text-field>
           </div>
            <div class="col-md-12">
                <label class="font text-gray" for="">Email</label>
                <v-text-field
                 tabindex="3"
                required
                v-model.trim="email"
                solo
                ></v-text-field>
            </div>
              <div class="col-md-12">
                <label class="font text-gray" for="">Sex</label>
                <div class="row col-md-12 justify-space-between">
                       <div  :class="gender == 'male' ? 'col-md-4 blue-buttons  text-center  border-radius ' : 'col-md-4 gender-buttons  text-center  text-gray'" @click="getGender('male')" tabindex="4">Male</div>
                       <div :class="gender == 'female' ? 'col-md-4 blue-buttons  text-center  border-radius ' : 'col-md-4 gender-buttons  text-center  text-gray'" @click="getGender('female')" tabindex="5">Female</div> 
                </div>
           </div>
            <div class="col-md-12">
                <label class="font text-gray" for="">Sex</label>
                <div class="row col-md-12 justify-space-between">
                    <div class="col-md-4 gender-buttons  text-center  text-gray" tabindex="4">Male</div>
                    <div class="col-md-4 gender-buttons text-center text-end text-gray" tabindex="5">Female</div> 
                </div>
           </div>
           <div class="col-md-12 row">
            <div class="col-md-6">
                <label class="font text-gray" for="">Birth Month</label>
                <v-text-field
                required
                tabindex="6"
                solo
                v-model.trim="month"
                ></v-text-field>
            </div>
             <div class="col-md-3">
                <label class="font text-gray" for="">Day</label>
                <v-text-field
                required
                tabindex="7"
                solo
                v-model.trim="day"
                ></v-text-field>
            </div>
             <div class="col-md-3">
                <label class="font text-gray" for="">Year</label>
                <v-text-field
                required
                tabindex="8"
                solo
                v-model.trim="year"
                ></v-text-field>
            </div>
         </div>
            <div class="col-md-12">
                <label class="font text-gray" for="">Social Security Number</label>
                <v-text-field
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                tabindex="9"
                required
                solo
                v-model.trim="socialNumber"
                :type="show1 ? 'text' : 'password'"
                @click:append="show1 = !show1"
                ></v-text-field>
            </div>
        </div>
        <div class="col-md-6 no-padding" >
              <div class="col-md-12">
               <label class="font text-gray" for="">Member Type</label>
                <v-text-field
                tabindex="10"
                required
                v-model.trim="memberType"
                solo
                ></v-text-field>
           </div>
            <div class="col-md-12">
               <label class="font text-gray" for="">Home Address 1</label>
                <v-text-field
                tabindex="10"
                required
                solo
                 v-model.trim="address1"
                ></v-text-field>
           </div>
          <div class="col-md-12">
            <label class="font text-gray" for="">Address 2</label>
                <v-text-field
                tabindex="11"
                required
                solo
                 v-model.trim="address2"
                ></v-text-field>
           </div>
          <div class="col-md-12 row">
            <div class="col-md-6">
                <label class="font text-gray" for="">City</label>
                <v-text-field
                required
                tabindex="12"
                v-model.trim="city"
                solo
                ></v-text-field>
            </div>
             <div class="col-md-3">
                <label class="font text-gray" for="">Zip</label>
                <v-text-field
                required
                tabindex="13"
                solo
                type="number"
                v-model.trim="zip"
                ></v-text-field>
            </div>
             <div class="col-md-3">
                <label class="font text-gray" for="">State</label>
                <v-select
                required
                v-model.trim="state"
                tabindex="14"
                solo
                :options="{text:'Any', value:null}"
                ></v-select>
            </div>
        </div>
        <div class="col-md-12">
            <div class="d-flex  justify-space-between text-gray">
                <div class="col-md-4"> Prior Authorizations </div>
                 <div class="text-gray col-md-4" >
                   Date
                </div>
                <div class="text-gray col-md-4">
                   Status
                </div>
                
            </div>
            <v-divider></v-divider>
            <div class="d-flex  justify-space-between">
                <div class="col-md-4">
                    <a  tabindex="15" >Knee Arthoscope</a>
                </div>
                <div class="text-gray col-md-4" >
                    10/20/2021
                </div>
                <div class="text-gray col-md-4">
                   Completed
                </div>
            </div>
             <div class="d-flex  justify-space-between">
                <div class="col-md-4">
                    <a  tabindex="15" >Knee Arthoscope</a>
                </div>
                <div class="text-gray col-md-4" >
                    10/20/2021
                </div>
                <div class="text-gray col-md-4">
                   Completed
                </div>
            </div>
            <div class="mt-2">
               <a  tabindex="15"  @click="$router.push(`/provider/members`)"> New Prior Auth Request </a> 
            </div>
        </div>
        </div>           
        <div class="col-md-12 d-flex align-end flex-column">
            <div>
                <v-btn  tabindex="16" class="blue-buttons mr-6 mb-2" @click="$router.push(`/provider/members`)">
                 Cancel
                </v-btn>
            </div>
        </div>
      </form>
    </div>
 </v-row>
</template>
<script>
 export default{
    data(){
        return{
            show1:false,
            dialog:false,
            dialog1:false,
        }
    },
    computed:{
        singleMember(){
          return this.$store.getters.getSingleMember;
        },
         singleClient(){
          return this.$store.getters.getClientGetter;
        },
        routeParams(){
         return this.$route.params.name
        }
    },
    methods:{
         getGender(gender){
             this.gender = gender
         },
    }
 }
</script>
