import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyC9s_LP4UsdhCyGgelIFs_SviTHFZd710g",
  authDomain: "tdb-tpa.firebaseapp.com",
  projectId: "tdb-tpa",
  storageBucket: "tdb-tpa.appspot.com",
  messagingSenderId: "381579629334",
  appId: "1:381579629334:web:c0a553273434426449b637"
  };
  
  export default firebase.initializeApp(firebaseConfig);