var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      _vm.path.includes(`/digital-invoicing/clients`) && _vm.$route.params.id != null
    )?_c('div',{staticClass:"blue-toolbar row no-padding",staticStyle:{"margin-top":"0px"}},[_c('div',{class:_vm.activeTab == 'details'
          ? ' col-lg-1 col-md-2 col-sm-2 font ml-1  white--text text-center orange-underline sm-buttons-width'
          : ' col-lg-1 col-md-2 col-sm-2 font  ml-1 white--text text-center sm-buttons-width',attrs:{"cols":"2","align":"center"}},[_c('a',{staticClass:"white--text",on:{"click":function($event){return _vm.changeActiveTab('details')}}},[_vm._v(" General ")])]),_c('div',{class:_vm.activeTab == 'members'
          ? ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center orange-underline sm-buttons-width'
          : ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center sm-buttons-width',attrs:{"cols":"2","align":"center"}},[_c('a',{staticClass:"white--text"},[_vm._v(" Members ")])]),_c('div',{class:_vm.activeTab == 'plans'
          ? ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center orange-underline sm-buttons-width'
          : ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center sm-buttons-width',attrs:{"cols":"2","align":"center"}},[_c('a',{staticClass:"white--text",on:{"click":function($event){return _vm.changeActiveTab('plans')}}},[_vm._v(" Plans ")])]),_c('div',{class:_vm.activeTab == 'solutions'
          ? ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center orange-underline sm-buttons-width'
          : ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center sm-buttons-width',attrs:{"cols":"2","align":"center"},on:{"click":function($event){return _vm.changeActiveTab('solutions')}}},[_c('a',{staticClass:"white--text"},[_vm._v(" Solutions ")])]),_c('div',{class:_vm.activeTab == 'advisors'
          ? ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center orange-underline sm-buttons-width'
          : ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center sm-buttons-width',attrs:{"cols":"2","align":"center"}},[_c('a',{staticClass:"white--text"},[_vm._v(" Advisors ")])]),_c('div',{class:_vm.activeTab == 'tpa'
          ? ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center orange-underline sm-buttons-width'
          : ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center sm-buttons-width',attrs:{"cols":"2","align":"center"}},[_c('a',{staticClass:"white--text"},[_vm._v(" TPAs ")])]),_c('div',{class:_vm.activeTab == 'billing'
          ? ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center orange-underline sm-buttons-width'
          : ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center sm-buttons-width',attrs:{"cols":"2","align":"center"},on:{"click":function($event){return _vm.changeActiveTab('billing')}}},[_c('a',{staticClass:"white--text"},[_vm._v(" Billing ")])]),_c('div',{class:_vm.activeTab == 'settings'
          ? ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center orange-underline sm-buttons-width'
          : ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center sm-buttons-width',attrs:{"cols":"2","align":"center"}},[_c('a',{staticClass:"white--text",on:{"click":function($event){return _vm.changeActiveTab('settings')}}},[_vm._v(" Settings ")])])]):_c('div',{staticClass:"blue-toolbar row",staticStyle:{"margin-top":"0px","padding":"1.05rem","margin-left":"0px"}},[_vm._v(" "+_vm._s(_vm.routeParams)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }