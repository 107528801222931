<template>
  <v-row class="add-client-form">
    <successModal ref="success_modal" v-on:confirm="sendMail"></successModal>
    <errorDialog ref="error_modal" v-on:confirm="closeErrorModal"></errorDialog>
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12 pb-0">
          <h3 class="form-title font col-md-12 pb-0">New User</h3>
        </div>
      </div>
      <v-form v-model.trim="valid" ref="form" class="row form-row-height">
        <div class="col-md-6">
          <div class="col-md-12 pb-0">
            <label class="font text-gray" for="">First Name</label>
            <v-text-field
              required
              v-model.trim="firstName"
              solo
              :rules="requiredRules"
              tabindex="1"
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">Last Name</label>
            <v-text-field
              required
              tabindex="2"
              :rules="requiredRules"
              v-model.trim="lastName"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">Email</label>
            <v-text-field
              required
              :rules="emailRules"
              v-model.trim="email"
              solo
              tabindex="3"
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">Title</label>
            <v-text-field
              required
              tabindex="4"
              :rules="requiredRules"
              v-model.trim="title"
              solo
            ></v-text-field>
          </div>
        </div>
        <div class="col-md-6">
          <div class="col-md-12 pb-0">
            <label class="font text-gray" for="">Role </label>
            <v-select
              required
              tabindex="5"
              solo
              :rules="requiredRules"
              :items="[
                { text: 'Admin', value: 'admin' },
                { text: 'User', value: 'user' },
              ]"
              v-model.trim="role"
            ></v-select>
          </div>

          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">Office Name</label>
            <v-text-field
              required
              tabindex="6"
              v-model.trim="officeName"
              :rules="requiredRules"
              solo
            ></v-text-field>
          </div>

          <div class="col-md-12 pt-0 pb-0">
            <label class="font text-gray" for="">Address</label>
            <v-text-field
              required
              tabindex="7"
              :rules="requiredRules"
              v-model.trim="address"
              solo
            ></v-text-field>
          </div>

          <div class="col-md-12 row">
            <div class="col-md-6 pt-0 pb-0">
              <label class="font text-gray" for="">City</label>
              <v-text-field
                required
                :rules="requiredRules"
                v-model.trim="city"
                solo
                tabindex="8"
              ></v-text-field>
            </div>
            <div class="col-md-3 pt-0 pb-0">
              <label class="font text-gray" for="">State</label>
              <v-select
                required
                :rules="requiredRules"
                :items="states"
                v-model.trim="state"
                solo
                tabindex="9"
                item-text="name"
                item-value="name"
              ></v-select>
            </div>
            <div class="col-md-3 pt-0 pb-0">
              <label class="font text-gray" for="">Zip</label>
              <v-text-field
                required
                type="number"
                v-model.trim="zip"
                :rules="zipRules"
                solo
                tabindex="10"
              ></v-text-field>
            </div>
          </div>
        </div>
      </v-form>
      <div class="row mt-3 mb-3">
        <div class="col-md-12 row text-end justify-end">
          <v-btn
            class="blue-buttons mr-5"
            @click="$router.push(`/employer-client/account/users`)"
          >
            Cancel
          </v-btn>
          <v-btn class="blue-buttons mr-2 ml-2" @click="save()">
            Add & Invite User
          </v-btn>
        </div>
      </div>
    </div>
  </v-row>
</template>
<script>
import successModal from "../../../../TablesTemplate/SuccessDialog.vue";
import errorDialog from "../../../../TablesTemplate/ErrorDialog.vue";
import api from "../../../../../services/api";
export default {
  components: { successModal, errorDialog },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      title: "",
      role: "",
      officeName: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
      zipRules: [
        (v) => (v && v.length == 5) || " Zip Code should be five digits long",
      ],
      states: [],
      valid: false,
    };
  },
  computed: {
    routeParams() {
      return this.$route.params.name;
    },
  },
  created() {
    //   this.getStates()
  },
  methods: {
    addUser() {
      this.$router.push(`/employer-advisor/settings/users`);
    },
    getStates() {
      api()
        .get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },
    sendMail() {
      this.$refs.success_modal.closePopUp();
      this.$router.push(`/employer-advisor/settings/users`);
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    save() {
      this.$refs.form.validate();
      let text = "Email sucessfully sent";
      this.$refs.success_modal.openPopUp(text);
      //   if(this.valid){
      //     let data = {
      //       "firstName": this.firstName,
      //       "lastName": this.lastName,
      //       "email": this.email,
      //       "title": this.title,
      //       "role": this.role,
      //       "officeName": this.officeName,
      //       "address1": this.address,
      //       "city": this.city,
      //       "state": this.state,
      //       "zipCode": this.zip,

      //     }

      //     let user = JSON.parse(localStorage.getItem('user'));
      //     let advisorId = user.groupPortal[0].organization.employerAdvisor
      //     let employer = JSON.parse(localStorage.getItem('employer'))
      //     api().post(`/advisors/${advisorId}/users`, data)
      //     .catch((err)=>{
      //          let text = err.response.data.message
      //          this.$refs.error_modal.openPopUp(text);
      //     }).then((res)=>{

      //       if(res)
      //       {
      //           let text = 'Email sucessfully sent'
      //           this.$refs.success_modal.openPopUp(text);
      //       }
      //     })
      //   }
    },
  },
};
</script>
