<template>
  <div class="form-div">
    <!-- <v-card class="videos-cart col-lg-8 col-xl-6 col-md-6">
      <h2 class="blue-text text-center">APALY - DIGITAL DIRECT HEALTHCARE</h2>
      <video width="640" height="360" controls autoplay muted ref="myVideo" class="video-media">
        <source src="https://vcontent.apaly.net/1-main.mp4" type="video/mp4" />
        <source src="https://vcontent.apaly.net/1-main.mp4" type="video/webm" />
        Your browser does not support the video tag.
      </video>
      <v-card-actions class="col-md-12 justify-center" align="center">
        <v-btn x-large class="select-btn col-md-7 mt-5" text @click="next()">
          Create an Account
        </v-btn>
      </v-card-actions>
    </v-card> -->
  </div>
</template>
<script>
export default {
  mounted() {
    // let video = document.getElementById('myVideo');
    // if(video){
    //    video.play()
    // }
    //  document.body.addEventListener("mousemove", function () {
    //   video.play()
    //  })
  },
  created() {
    this.$store.commit(
      "sideBarTitleAndDesc",
      "“The way to get started is to quit talking and begin doing.” "
    );
    this.$store.commit("sideBarDesc", "Walt Disney");
  },
  methods: {
    next() {
      // let video = document.getElementById('myVideo');
      let video = this.$refs.myVideo;
      video.pause();
      video.currentTime = 0;
      this.$router.push("/register/type");
    },
  },
};
</script>


