<template>
  <div>
    <div
      v-if="!isChildren"
      class="row row-height"
      :class="showOverlay ? ' postion-relative' : ''"
    >
      <div v-if="showOverlay" class="wizard-absolute"></div>

      <v-sheet
        v-if="!load"
        class="overflow-hidden col-md-12"
        style="position: relative; border-radius: 5px"
      >
        <div class="">
          <v-row align="center" justify="center">
            <div class="col-md-12 navigationWidth no-padding">
              <v-row class="mb-1 blue-underline">
                <v-col class="col-xl-2 col-md-2 cols-4 pt-2 pb-0" cols="4">
                  <div class="col-md-12">
                    <h3 class="form-title font col-md-12">Vendors</h3>
                  </div>
                </v-col>

                <v-col class="col-xl-2 col-md-5 cols-8" cols="8">
                  <div class="col-md-12 d-flex no-padding">
                    <v-text-field
                      class="input-control mr-5 pt-4"
                      prepend-inner-icon="mdi-magnify"
                      @input="search"
                      light
                      dense
                      outlined
                      placeholder="Search Vendors"
                    ></v-text-field>
                  </div>
                </v-col>
               
              </v-row>
              <table id="tblData" style="display: none">
                <thead>
                  <tr>
                    <th class="text-left">CLIENT NAME</th>
                    <th class="text-left">OF MEMBERS</th>
                    <th class="text-left">PMNT STATUS</th>
                    <th class="text-left">LAST PMNT</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="text-gray"
                    id="remove-hover"
                    v-for="(item, index) in clients"
                    :key="index"
                  >
                    <td>
                      <a
                        v-if="item && item.primaryContact"
                        class="text-underline"
                        @click="singleItem(item)"
                        >{{ item.primaryContact.firstName }}
                        {{ item.primaryContact.lastName }}</a
                      >
                      <a v-else @click="singleItem(item)">/</a>
                    </td>
                    <td>
                      {{ item.noPlanMembers ? item.noPlanMembers : "/" }}
                    </td>
                    <td>Paid</td>
                    <td>
                      {{ item.updatedAt | formatDate }}
                    </td>
                  </tr>
                </tbody>
              </table>

              
              <v-simple-table fixed-header height="630px">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">CLIENT NAME</th>
                      <th class="text-left"># OF MEMBERS</th>
                      <th class="text-left">PMNT STATUS</th>
                      <th class="text-left">LAST PMNT</th>
                    </tr>
                  </thead>
                  <tbody v-if="clients.length > 0">
                    <tr
                      class="text-gray"
                      id="remove-hover"
                      v-for="(item, index) in clients"
                      :key="index"
                    >
                      <td>
                        <a
                          v-if="item && item.primaryContact"
                          class="text-underline"
                          @click="singleItem(item)"
                          >{{ item.primaryContact.firstName }}
                          {{ item.primaryContact.lastName }}</a
                        >
                        <a v-else @click="singleItem(item)">/</a>
                      </td>
                      <td>
                        {{ item.noPlanMembers ? item.noPlanMembers : "/" }}
                      </td>
                      <td>Paid</td>
                      <td>
                        {{ item.updatedAt | formatDate }}
                      </td>
                    </tr>
                  </tbody>

                  <tbody v-else-if="filterItems">
                    <v-col md="12" cols="12">
                      <v-alert
                        border="bottom"
                        colored-border
                        type="warning"
                        elevation="2"
                        class="text-gray"
                      >
                        Sorry! No results found
                      </v-alert>
                    </v-col>
                  </tbody>
                  <tbody v-else>
                    <v-col md="12">
                      <span>
                        There are no vendors currently in your account.</span
                      >
                    </v-col>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-row>
        </div>
      </v-sheet>
      <div v-else>
        <div class="col-md-12">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import api from "../../../../services/api";
export default {
  data() {
    return {
      items: [],
      showOverlay: false,
      clients: [],
      filterItems: false,
      filterArray: [],
      load: false,
    };
  },
  computed: {
    routeParams() {
      return this.$route.params.name;
    },
    singleEmp() {
      let emp = "";
      if (localStorage.getItem("employer")) {
        emp = JSON.parse(localStorage.getItem("employer"));
      }
      return emp;
    },
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
  },
  created() {
    // this.getData();
  },
  methods: {
    exportData() {
      var elt = document.getElementById("tblData");
      var downloadLink;
      var dataType = "application/vnd.ms-excel";
      var tableSelect = elt;
      var tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

      // Specify file name
      let filename = "excel_data.xls";

      // Create download link element
      downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        var blob = new Blob(["\ufeff", tableHTML], {
          type: dataType,
        });
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = "data:" + dataType + ", " + tableHTML;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
    },
    singleItem(item) {
      this.$store.commit("storeSingleItemDPC", item);
      this.$router.push("/vendors/clients/client");
    },
    getData() {
      this.load = true;
      api()
        .get(`/vendors/clients`)
        .catch((err) => {
          this.load = false;
        })
        .then((res) => {
          if (res && res.data) {
            this.clients = res.data;

            this.filterArray = this.clients;
            this.load = false;
          }
        });
    },
    search(val) {
      this.clients = this.filterArray.filter((el) => {
        if (
          el.primaryContact &&
          el.primaryContact.firstName &&
          el.primaryContact.firstName.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.primaryContact.firstName
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el.pmntStatus &&
          el.pmntStatus.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.pmntStatus.toLowerCase().includes(val.toLowerCase());
        } else if (
          el.memberNumber &&
          el.memberNumber.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.memberNumber.toLowerCase().includes(val.toLowerCase());
        } else if (
          el.lastPmnt &&
          el.lastPmnt.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.lastPmnt.toLowerCase().includes(val.toLowerCase());
        }
      });
      if (this.clients.length < 1) {
        this.filterItems = true;
      }
    },
  },
};
</script>
<style>

</style>
