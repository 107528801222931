<template>
  <div class="form-div">
    <FullscreenLoader v-if="loader" />
    <v-card class="col-lg-8 col-xl-6 col-md-6">
      <v-alert dense v-if="errorDiv" outlined type="error" class="mt-5">
        <!-- Verify Token is not correct please try again -->

        This link has expired, please request another e-mail confirmation link to be sent. 
      </v-alert>

      <v-form class="col-md-12" v-else-if="!errorDiv && !loader" ref="form" v-model.trim="valid">
        <h2 class="text-center blue-text mb-10">Set your password.</h2>
        <v-row>
          <v-col md="12" sm="12" cols="12">
            <label for="" class="tiny-font">Create a password</label>
            <v-text-field
              solo
              :rules="passRules"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              v-model.trim="password"
              :type="show1 ? 'text' : 'password'"
              required
              tabindex="1"
              @click:append="show1 = !show1"
            ></v-text-field>
          </v-col>
          <v-col md="12" sm="12" cols="12">
            <label for="" class="tiny-font">ReType Password </label>
            <v-text-field
              solo
              tabindex="2"
              :rules="[
                this.password === this.retypePassword || 'Password must match',
              ]"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show2 ? 'text' : 'password'"
              v-model.trim="retypePassword"
              required
              @click:append="show2 = !show2"
            ></v-text-field>
          </v-col>
        </v-row>
        <div class="mt-4">
          <h4 class="text-gray text-underline">Password Requirments:</h4>
          <ul>
            <li class="text-gray">At least 8 characters</li>
            <li class="text-gray">Contain at least one uppercase letter</li>
            <li class="text-gray">
              Contain at least one special character (example @,#,$,etc.)
            </li>
          </ul>
        </div>
        <v-col class="d-flex justify-end" align="end">
          <v-btn depressed class="select-btn mt-2 ml-3" @click="submit">
            Next
          </v-btn>
        </v-col>
      </v-form>
    </v-card>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import api from "../../../services/api";
import FullscreenLoader from "../../Common/FullscreenLoader.vue";

export default {
  components: { FullscreenLoader },
  data() {
    return {
      errorDiv: true,
      show1: false,
      show2: false,
      retypePassword: "",
      password: "",
      newToken: '',
      passRules: [
        (v) => !!v || "Password is required",
        (v) => v.length > 7 || "Password must be at least 8 character",
        (v) =>
          (v.length && /[^A-Za-z0-9]/.test(v)) ||
          "Password should contain at least one special character (example @,#,$,etc.)",
        (v) =>
          (v.length && /[A-Z]{1}/.test(v)) ||
          "Password should contain at least one uppercase letter",
        (v) =>
          (v.length && /[a-z]{1}/.test(v)) ||
          "Password should contain at least one lowercase letter",
      ],
      confirmPassRules: [(v) => !!v || "ReType Password is required"],
      valid: false,
      loader: false,
      id: "",
      type: "",
    };
  },
  computed: {},
  created() {
    this.loader = true;
    api()
      .get("/auth/verify/" + this.$route.query.token)
      .then((res) => {
        if (res.status != 200) {
          this.errorDiv = true;
          this.$store.commit("sideBarTitleAndDesc", "");
          this.$store.commit("sideBarDesc", "");
        } else {
          this.newToken = res.data.newToken;
          this.errorDiv = false;
          this.$store.commit(
            "sideBarTitleAndDesc",
            "“It is during our darkest moments that we must focus to see the light.”"
          );
          this.$store.commit("sideBarDesc", "Aristotle");
          if (res.data.type) {
            localStorage.setItem("type", JSON.stringify(res.data.type));
            this.$store.commit("userType", res.data.type);
            this.type = res.data.type;
          }
        }
        this.$store.commit("setUserVerify", this.errorDiv);
        
        if (this.$route.query && this.$route.query.onboardLink) {
          api()
            .get("/auth/verify/" + this.$route.params.id + "?onboardLink=1")
            .then((res) => {
              if (res) {
                localStorage.setItem(
                  "firstTime",
                  JSON.stringify(res.data.onboardAcceptedFirstTime)
                );
              }
            });
        }

        this.loader = false;
      }).catch(err => {
        console.log('Verify Error:', err);
        this.loader = false;
      });

    if (this.errorDiv) {
      this.$store.commit("sideBarTitleAndDesc", "");
    }
  },

  methods: {
    ...mapActions(["registerUser"]),
    submit() {
      let user = {
        password: this.password,
      };
      this.$refs.form.validate();
      if (this.valid) {
        api()
          .post("/auth/setPassword/" + this.newToken, user)
          .then((res) => {
            if (res.status == 200) {
              this.error = "";
              localStorage.setItem("email", JSON.stringify(res.data.email));
              let email = {
                email: res.data.email,
              };

              if (
                this.$route.query &&
                typeof this.$route.query.onboardLink != "undefined" &&
                this.$route.query.onboardLink == 1
              ) {
                this.$router.push("/login");
                return;
              }
            //   if(res.data.orgExist == true){
            //     this.$router.push("/login");
            //     return;
            //   } else {
            //   api()
            //     .post("/auth/findOrganization/basic", email)
            //     .then((res) => {
            //       if (res.data.organization) {
            //         this.$store.commit(
            //           "setCompanyName",
            //           res.data.organization.name
            //         );
            //       }
            //       if (res.data.found) {
            //         this.$router.push("/register/organization-found");
            //       } else {
            //         this.$router.push("/register/organization-not-found");
            //       }
            //     })
            //     .catch((err) => {
            //       if (
            //         err.response &&
            //         err.response.data &&
            //         err.response.data.accountId
            //       ) {
            //         localStorage.setItem(
            //           "accountId",
            //           JSON.stringify(err.response.data.accountId)
            //         );
            //         this.$store.commit(
            //           "setAccountId",
            //           err.response.data.accountId
            //         );
            //         localStorage.setItem(
            //           "accountInfo",
            //           JSON.stringify( err.response.data.account)
            //         );
                   
            //       }
            //       this.$router.push("/register/organization-not-found");
            //     });
            //  }
          
                this.$router.push("/login");
                return;
            }
          });
      }
    },
  },
};
</script>
<style>
.check {
  width: 33px;
  height: 35px;
  padding: 0px;
}
</style>