<template>
  <div>
    <div class="col-md-12 text-center justify-center" v-if="readyToShow">
      <v-progress-circular
        indeterminate
        :size="100"
        color="primary"
        class="text-center"
      ></v-progress-circular>
    </div>
    <div v-else class="row">
      <div
        v-for="item in items"
        :key="item.title"
        class="
          text-center
          justify-center
          align-center
          col-xl-3 col-lg-3 col-md-4
        "
      >
        <v-card
          class="centered-card"
          style="height: 13rem; cursor: pointer"
          @click="item.url && $role != 'user' ? $router.push(item.url) : ''"
        >
          <img :src="item.img" height="60" width="70" class="img-media" />
          <h3 class="text-center justify-center blue-text font">
            {{ item.title }}
          </h3>
          <v-card-text>
            <v-row
              align="center"
              class="mx-lg-4 mx-xl-4 mx-md-0 justify-center"
            >
              <h5
                class="text-center font-quicksand-medium-18 mt-4 text-gray"
                style="font-weight: normal; line-height: 16px"
              >
                {{ item.desc }}
              </h5>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
      <div
        class="
          col-xl-6 col-lg-6 col-md-8
          text-center
          justify-center
          align-center
        "
      >
        <v-card
          style="height: 13rem; cursor: pointer"
          class="space-around-content"
        >
          <v-row align="center" class="mx-0 justify-center">
            <h4 class="text-center text-underline font-quicksand-medium-18">
              Easy Setup
            </h4>
          </v-row>
          <v-row class="text-center justify-center mt-3">
            <img
              src="/provider-advisor.png"
              id="setup-img-emp"
              width="640"
              height="100"
              alt=""
              style="object-fit: containt"
            />
          </v-row>
        </v-card>
      </div>
      <div
        v-for="item in precentageCards"
        :key="item.title"
        class="
          col-xl-3 col-lg-3 col-md-6
          text-center
          justify-center
          aling-center
        "
      >
        <v-card
          style="height: 13rem; cursor: pointer"
          class="space-around-content"
        >
          <div align="center" class="mx-0 justify-center">
            <h4
              class="text-center text-underline font-quicksand-medium-18"
              :class="item.title != 'Providers' ? 'mt-1' : ''"
              style="height: 1rem"
            >
              {{ item.title }}
            </h4>
          </div>

          <v-card-title
            class="text-center justify-center text-gray font percentage-num"
          >
            <div
              v-if="item.title == 'Patient / Member Encounters'"
              class="row justify-space-around"
            >
              <h3 class="blue-text no-padding">
                {{ statistics.noEmployers }} <br />
                This month
              </h3>
              <h3 class="blue-text no-padding">
                {{ statistics.noPlanMembers }} <br />
                YTD
              </h3>
            </div>
            <div
              v-else-if="
                item.title == 'Current Contract Rates' && statistics.rates
              "
              class="row justify-space-around blue-text"
            >
              <h3 class="blue-text">
                {{ statistics.rates.inpatient }} % <br />
                Inpatient
              </h3>
              <h3 class="blue-text">
                {{ statistics.rates.outpatient }} % <br />
                Outpatient
              </h3>
            </div>
            <div v-else-if="item.title == 'Providers'" class="blue-text">
              <h1 v-html="statistics.providers ? statistics.providers : 0"></h1>
            </div>
            <div
              v-else-if="item.title == 'Projected Savings'"
              class="blue-text"
            >
              <h1>{{ statistics.projectedSavings }} %</h1>
            </div>
            <div
              v-else-if="item.title == 'Members within Coverage Area'"
              class="blue-text"
            >
              <h1>{{ statistics.providerCoverage }}</h1>
            </div>
            <div v-else-if="item.title == 'Contract Renewal'" class="blue-text">
              <h1>{{ statistics.contractRenewals }}</h1>
            </div>
            <div
              v-else-if="item.title == 'Employers Interested in Contracting'"
              class="blue-text"
            >
              <h1
                v-html="
                  statistics.noEmployersInterested
                    ? statistics.noEmployersInterested
                    : '0'
                "
              >
                {{ statistics.noEmployersInterested }}
              </h1>
            </div>
          </v-card-title>
          <div align="center" class="mx-10 justify-center">
            <h5
              class="blue-text text-center font-quicksand-medium-18 pb-3"
              style="line-height: 20px"
              v-if="item.desc"
            >
              {{ item.desc }}
            </h5>
          </div>
        </v-card>
      </div>
    </div>

    <!-- Modal -->
  </div>
</template>
<script>
import api from "../../../services/api";
export default {
  data() {
    return {
      readyToShow: false,
      items: [
        {
          url: "/provider-advisor/providers/add-manually",
          img: "/cards/woman.png",
          title: "Add a Provider",
          desc: "Add Providers to your portal to help them capture direct self-funded employer marketshare.",
        },
        //  {
        //      url:'/provider-advisor/bundles',
        //      img:'/cards/money.png',
        //      title:'Build Bundles',
        //      desc:'Create bundle templates and assign them to your provider clients / partners.'
        //  },
        //  {
        //      url:'/provider-advisor/networks',
        //      img:'/cards/doctor.png',
        //      title:'Build VNets',
        //      desc:'Create high value Vnets of providers to deliver quality services to employers.'
        //  },
        //  {
        //      img:'/cards/hospital.png',
        //      title:'Market Services',
        //      desc:'Offer Bundles and Networks on the Employer / Member Marketplace.'
        //  },
        //  {
        //      img:'/cards/contract.png',
        //      title:'Invite Employers',
        //      desc:'Invite employers to participate and generate business for your provider clients / partners.'
        //  },
        //  {
        //      img:'/cards/letter.png',
        //      title:'Invite Providers',
        //      desc:'Invite providers to directly contract through Apaly.'
        //  },
        {
          url: "/provider-advisor/settings/users/invite-user",
          img: "/cards/desktop.png",
          title: "Invite Colleagues",
          desc: "Invite other consultants to participate on your Apaly Health portal.",
        },
      ],
      precentageCards: [
        //  {
        //      title: 'Patient / Member Encounters',
        //      percentage:'74',
        //      percentage1:'1,450',
        //      desc:'number of patient encounters that have been delivered by your providers for Apaly Health employer participants',
        //      clients:true
        //  },
        {
          title: "Employers Interested in Contracting",
          percentage: "28%",
          desc: "self-funded employers have added your providers to their provider list, and are requesting to contract",
        },
        //  {
        //      title: 'Members within Coverage Area',
        //      percentage:'94%',
        //      desc:'number of self-funded employer plan members residing within a 30 mile radius of your facilities'
        //  },
        {
          title: "Providers",
          percentage: "48,000",
          desc: "total number of providers that are currently entered into your Apaly Health account",
        },
        //  {
        //      title: 'Current Contract Rates',
        //      percentage:'150%',
        //      percentage1:'180%',
        //      desc:'of Medicare benchmark rates',
        //      inpatient:true
        //  },
        //  {
        //      title: 'Contract Renewal',
        //      percentage:'5',
        //      desc:'contract renewal date'
        //  },
      ],
      dialogm1: "",
      dialog: false,
      dialog1: false,
      allEmployers: "",
      statistics: {},
      allProviders: [],
      searchedProviders: [],
      str: "",
    };
  },
  methods: {
    singleProvider(item) {
      if (!this.overlay && !this.showSaveButton) {
        let user = JSON.parse(localStorage.getItem("user"));
        let advisorId = user.groupPortal[0].organization.providerAdvisor;
        let employer = JSON.parse(localStorage.getItem("employer"));
        api()
          .get(`/advisors/${advisorId}/providers/${item._id}`)
          .then((res) => {
            if (res) {
              this.$store.commit(
                "setSingleProviderInEmployerAdvisor",
                res.data.provider
              );
              this.$router.push({
                path: "/provider-advisor/providers/" + item.companyName,
                params: { name: item.companyName },
              });
            }
          });
      }
    },
    singleCompany(item) {
      localStorage.setItem("provider", JSON.stringify(item));
      if (item.providerName) {
        this.$router.push({
          path: "/provider-advisor/providers/" + item.providerName,
          params: { name: item.providerName },
        });
      } else {
        this.$router.push({
          path: "/provider-advisor/providers/" + item.companyName,
          params: { name: item.companyName },
        });
      }
    },
    clientsTable() {
      this.dialog = !this.dialog;
      this.dialog1 = !this.dialog1;
      this.$emit("showClient");
    },
    getEmployersStatistics() {
      this.readyToShow = true;
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.providerAdvisor;
      api()
        .get(`/advisors/${advisorId}/PA/statistics`)
        .then((res) => {
          this.statistics = res.data.statistics;
          this.readyToShow = false;
        })
        .catch((err) => {
          console.log(err.response);
          this.readyToShow = true;
        });
    },
    getAllProviders() {
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.providerAdvisor;

      api()
        .get(`/advisors/${advisorId}/providers`)
        .then((res) => {
          if (res) {
            this.allProviders = res.data.providers;
          }
        });
    },
    // ttt siviki1718@qqhow.com
    searchEmp(value) {
      this.str = value;
      let newStr = "";

      if (Number(value)) {
        newStr = "NPI=";
      } else {
        newStr = "name=";
      }

      api()
        .get(`/advisors/providers/searchPA?${newStr}${value}`)
        .then((res) => {
          this.searchedProviders = res.data.providers;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
  },
  created() {
    this.getEmployersStatistics();

    //   this.$store.commit('setBreadcrumbs','home')
  },
};
</script>
<style>
