<template>
<div class="no-padding">
   <v-row>
     <v-overlay :value="$store.getters.getFilterMenu">
              <v-btn
                color="orange lighten-2"
                @click="$store.commit('showFilterMenu', false)"
              >
                Hide Overlay
              </v-btn>
            
          </v-overlay>
    <v-col class="col-md-4" md="4" cols="12">
        <h3 class="font">Employers</h3>
    </v-col>
    <v-col class="col-md-4" md="4" cols="12">
        <v-text-field class="input-control" prepend-inner-icon="mdi-magnify"  light dense outlined placeholder="Search Employers">
        </v-text-field>
    </v-col>
    <v-col class="col-md-4 text-end" cols="12"   align="end">
    <v-menu offset-y>
        <template v-slot:activator="{ on }">
            <v-btn v-on="on" class="my-1 blue-buttons"> Add / Invite Employer </v-btn>
        </template>
        <v-list>
            <v-list-item>
                <a class="a-tag underline-text"  @click="$router.push(`/employer-advisor/employers/${routeParams}/employers/add-new`)">Add Employer</a>
            </v-list-item>
             <v-list-item>
                <a class="a-tag underline-text"  @click="$router.push(`/employer-advisor/employers/${routeParams}/employers/invite-employer`)">Invite Employers</a>
            </v-list-item>
            <!-- <v-list-item>
                <a class="a-tag underline-text" >Copy Invite Link</a>
            </v-list-item> -->
        </v-list>
    </v-menu>
   </v-col>
  </v-row> 
    <v-simple-table fixed-header  height="630px"
     class="no-padding">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
           Company Name 
          </th> 
          <th class="text-left">
           Contract Term
          </th>
          <th class="text-left">
           # of Employees
          </th>
          <th class="text-left">
           # of Members
          </th>
          <th class="text-left">
           Providers
          </th>
          <th class="text-left">
            Est.Avg. Charges <i class="fas fa-question-circle  yellow-icons"></i>
          </th>
          <th class="text-left">
            Est.Avg. Savings <i class="fas fa-question-circle  yellow-icons"></i>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in items"
          :key="item.name"
        >
          <td><a  @click="addEmp(item)">{{ item.name }}</a> </td>
          <td :class="item.color && item.color == 'red' ? 'red--text' : '' ">{{ item.term }}</td>
          <td>{{ item.numOfEmployees }}</td>
          <td>{{ item.numOfMemebers }}</td>
          <td class="pointer">
          <img src="/table-hospital.png" width="30" height="30"    alt=""></td>
          <td class="pointer" @click="goToProviders(item,'form')">
              <span v-if="item.averageCharges == 'pig'">
                  <img src="/pig.png" width="30" height="30"    alt="">
              </span>
              <span v-else>
                {{ item.averageCharges }}
              </span>
          </td>
          <td class="pointer" >
              <span v-if="item.averageSavings == 'pig'">
                  <img src="/pig.png" width="30" height="30"    alt="">
              </span>
              <span v-else>
               {{ item.averageSavings }}
              </span>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</div>

    
</template>
<script>
  export default {
    data () {
      return {
        items: [
          {
            name: 'Catholic Charities - St Pete',
            term: '10/30/2020',
            numOfEmployees: '500',
            numOfMemebers:'650',
            providers:'',
            averageCharges: 'pig',
            averageSavings:'pig',
            color:'red',
          },
          {
            name: 'Amco Transmission',
            term: '10/30/2020',
            numOfEmployees: '500',
            numOfMemebers:'650',
            providers:'',
            averageCharges: '210%',
            averageSavings:'32%',
            color:'red',
          },
          {
            name: 'Bob\'s Towing',
             term: '10/30/2020',
            numOfEmployees: '500',
            numOfMemebers:'650',
            providers:'',
            averageCharges: '280%',
            averageSavings:'40%',
            color:'red',
          },
          {
            name: 'Hynes Auto Group',
             term: '01/01/2023',
            numOfEmployees: '500',
            numOfMemebers:'650',
            providers:'',
            averageCharges: '280%',
            averageSavings:'40%'
          },
          {
            name: 'Trinity Bakery',
            term: '01/01/2023',
            numOfEmployees: '500',
            numOfMemebers:'650',
            providers:'',
            averageCharges: '213%',
            averageSavings:'pig'
          },
          {
            name: 'Tampa Bay Ligtning',
            term: '01/01/2023',
            numOfEmployees: '500',
            numOfMemebers:'650',
            providers:'',
            averageCharges: '280%',
            averageSavings:'40%'
          },
          {
            name: 'Tampa Bay Rays',
            term: '01/01/2023',
            numOfEmployees: '500',
            numOfMemebers:'650',
            providers:'',
            averageCharges: '280%',
            averageSavings:'40%'
          },
        
        ],
      }
    },
    methods:{
      addEmp(){
        this.$router.push(`/employer-advisor/employers/${this.routeParams}/employers/employer`);
      },
    },
    computed:{
      routeParams(){
        return this.$route.params.name
      }
    }
  }
</script>
<style>

</style>