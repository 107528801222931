<template>
  <div class="no-padding no-margin">
      <Table v-if="route==`/superadmin/vendors`"></Table>
      <AddNewForm v-if="route==`/superadmin/vendors/add-new`"></AddNewForm> 
      <SingleForm v-if="route==`/superadmin/vendors/vendor`"></SingleForm>
      <InviteForm  v-if="route==`/superadmin/vendors/invite-vendor`"></InviteForm>
  </div>
    
</template>
<script>
import Table from './VendorsTable.vue'
import SingleForm from './SingleVendor.vue'
import AddNewForm from './AddVendor.vue'
import InviteForm from './InviteVendors.vue'
// getPath

export default{
  data(){
    return{
        
    }
  },
  created(){
  },
  computed:{
     route(){
        return this.$route.path;
      },
      routeParams(){
        let parsed =''
        if(this.$route.params.name){
          parsed = this.$route.params.name.replace(/ /g, '%20')
        }
        return parsed
      }
  },
  methods:{

  },
  components:{
        SingleForm,
        AddNewForm,
        Table,
        InviteForm
 
  }
}

</script>
