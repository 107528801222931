<template>
  <div>
    <div
      v-if="
        path.includes(`/digital-invoicing/clients`) && $route.params.id != null
      "
      class="blue-toolbar row no-padding"
      style="margin-top: 0px"
    >
      <div
        cols="2"
        :class="
          activeTab == 'details'
            ? ' col-lg-1 col-md-2 col-sm-2 font ml-1  white--text text-center orange-underline sm-buttons-width'
            : ' col-lg-1 col-md-2 col-sm-2 font  ml-1 white--text text-center sm-buttons-width'
        "
        align="center"
      >
        <a class="white--text" @click="changeActiveTab('details')"> General </a>
      </div>
      <div
        cols="2"
        :class="
          activeTab == 'members'
            ? ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center orange-underline sm-buttons-width'
            : ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center sm-buttons-width'
        "
        align="center"
      >
        <a class="white--text"> Members </a>
      </div>

      <div
        cols="2"
        :class="
          activeTab == 'plans'
            ? ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center orange-underline sm-buttons-width'
            : ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center sm-buttons-width'
        "
        align="center"
      >
        <a class="white--text" @click="changeActiveTab('plans')"> Plans </a>
      </div>

      <div
        cols="2"
        @click="changeActiveTab('solutions')"
        :class="
          activeTab == 'solutions'
            ? ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center orange-underline sm-buttons-width'
            : ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center sm-buttons-width'
        "
        align="center"
      >
        <a class="white--text"> Solutions </a>
      </div>
      <div
        cols="2"
        :class="
          activeTab == 'advisors'
            ? ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center orange-underline sm-buttons-width'
            : ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center sm-buttons-width'
        "
        align="center"
      >
        <a class="white--text"> Advisors </a>
      </div>
      <div
        cols="2"
        :class="
          activeTab == 'tpa'
            ? ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center orange-underline sm-buttons-width'
            : ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center sm-buttons-width'
        "
        align="center"
      >
        <a class="white--text"> TPAs </a>
      </div>
      <div
        @click="changeActiveTab('billing')"
        cols="2"
        :class="
          activeTab == 'billing'
            ? ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center orange-underline sm-buttons-width'
            : ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center sm-buttons-width'
        "
        align="center"
      >
        <a class="white--text"> Billing </a>
      </div>
      <div
        cols="2"
        :class="
          activeTab == 'settings'
            ? ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center orange-underline sm-buttons-width'
            : ' col-lg-1 col-md-2 col-sm-2 font  white--text text-center sm-buttons-width'
        "
        align="center"
      >
        <a class="white--text" @click="changeActiveTab('settings')">
          Settings
        </a>
      </div>
    </div>
    <div
      v-else
      class="blue-toolbar row"
      style="margin-top: 0px; padding: 1.05rem; margin-left: 0px"
    >
      {{ routeParams }}
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeTab: "",
      activeTab1: "",
    };
  },
  props: {
    clientInfo: {
      type: Boolean,
    },
  },
  watch: {
    $route(to, from) {
      this.onCreatedFunction();
    },
  },

  methods: {
    changeActiveTab(tab) {
      this.activeTab = tab;

      if (
        this.path !=
        "/digital-invoicing/clients/" + tab + "/" + this.$route.params.id
      ) {
        this.$router.push(
          "/digital-invoicing/clients/" + tab + "/" + this.$route.params.id
        );
      }
      // if (tab == "home") {
      //   if (this.path != "/employer-advisor/employers/" + this.routeParams)
      //     this.$router.push("/employer-advisor/employers/" + this.routeParams);
      // } else if (tab == "provider-settings") {
      //   if (
      //     this.path !=
      //     `/employer-advisor/employers/${this.routeParams}/settings`
      //   )
      //     this.$router.push(
      //       `/employer-advisor/employers/${this.routeParams}/settings`
      //     );
      // } else {
      //   if (
      //     this.path != `/employer-advisor/employers/${this.routeParams}/${tab}`
      //   )
      //     this.$router.push(
      //       `/employer-advisor/employers/${this.routeParams}/${tab}`
      //     );
      // }
    },
    onCreatedFunction() {
      this.activeTab = this.path.split("/")[3];
      // if (this.path.includes(`/digital-invoicing/clients/details`)) {
      //   this.activeTab = "details";
      // }
      // if (this.path.includes(`/digital-invoicing/clients/details`)) {
      //   this.activeTab = "details";
      // }
    },
  },
  computed: {
    routeParams() {
      return this.$route.params.name;
    },
    path() {
      return this.$route.path.replace(/%20/g, " ");
    },
  },
  created() {
    this.onCreatedFunction();
  },
};
</script>
<style scoped>
.no-margin {
  margin: 0px !important;
}
</style>