<template>
   <v-dialog  v-model.trim="dialog" max-width="600px" persistent>
       <v-card>
            <v-col>
                <h3>{{title}}</h3>
            </v-col>
      
       
            <v-col>
                <span>{{text}}</span>
            </v-col>
       
            <v-col>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="blue-buttons float-class" @click="$emit('confirm')">Confirm</v-btn>
                    <v-btn class="button-style float-class" @click="closePopUp">Close</v-btn>
                </v-card-actions>
            </v-col>
        </v-card>
    </v-dialog>

</template>

<script>
    export default {
        data() {
            return {
                dialog: false,
                title: '',
                text: '',
            }
        },
        methods: {
            closePopUp() {
                this.dialog = false
            },
            openPopUp(title, text) {
                this.title = title
                this.text = text
                this.dialog = true
            }
        }
    }
</script>

<style>
</style>