var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"white-toolbar justify-space-between d-flex"},[(
      _vm.$route.path == '/vendors/home' || _vm.$route.path == '/dpc-providers/home'
    )?_c('div',[_c('span',{staticClass:"text-gray font-quicksand-medium-14"},[_vm._v("Home")])]):_c('div',_vm._l((_vm.$route.matched),function(route,index){return _c('span',{key:index},[(route && route.name)?_c('span',[(index != Object.keys(_vm.$route.matched).length - 1)?_c('router-link',{staticClass:"font-quicksand-medium-14",attrs:{"to":route.name == 'Contract Type' &&
            _vm.$route.path.includes('/provider/contracts')
              ? '/provider/contracts/select-type'
              : route.name == 'Contract Type' &&
                !_vm.$route.path.includes('/provider/contracts/')
              ? `/provider-advisor/providers/${_vm.providerName}/contracts/contract-type`
              : route.path}},[(route.name == 'Employer Home' && _vm.singleEmployer)?_c('span',[_vm._v(" Employer Home ")]):(route.name == 'Provider Home' && _vm.singleProvider)?_c('span',[_vm._v(" Provider Home ")]):_c('span',[_vm._v(" "+_vm._s(route.name)+" ")])]):_c('span',{staticClass:"text-gray font-quicksand-medium-14"},[(
              route.name == 'Employer' &&
              _vm.singleEmployer &&
              _vm.$route.path != '/employer-advisor/employers/employer'
            )?_c('span',[_vm._v(" Employer Home ")]):(
              route.name == 'Provider Home' &&
              _vm.singleProvider &&
              _vm.$route.path == '/provider-advisor/providers/provider'
            )?_c('span',[_vm._v(" Provider ")]):_c('span',[_vm._v(" "+_vm._s(route.name)+" ")])]),(index != Object.keys(_vm.$route.matched).length - 1)?_c('span',{staticClass:"text-gray font-quicksand-medium-14"},[_vm._v(" \\ ")]):_vm._e()],1):_vm._e()])}),0),_c('div',{attrs:{"id":"changeProviderName"},on:{"click":_vm.changeProviderName}},_vm._l((_vm.$route.matched),function(route,index){return _c('span',{key:index},[(
          route &&
          route.name &&
          route.name.includes('Employer Home') &&
          _vm.$route.path != '/employer-advisor/employers/employer' &&
          _vm.$route.path != '/employer-advisor/employers' &&
          _vm.$route.path != '/employer-advisor/employers/add-new'
        )?_c('h3',{staticClass:"font-quicksand-medium-14 text-gray"},[_vm._v(" "+_vm._s(_vm.changeProviderName())+" ")]):(
          route &&
          route.name &&
          route.name.includes('Provider Home') &&
          _vm.$route.path != '/provider-advisor/providers/provider' &&
          _vm.$route.path != '/provider-advisor/providers'
        )?_c('h3',{staticClass:"font-quicksand-medium-14 text-gray"},[_vm._v(" "+_vm._s(_vm.changeProviderName())+" ")]):_vm._e()])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }