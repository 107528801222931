<template>
 <v-row class="add-client-form no-padding" >
     <removeDialog  ref="dialog_delete" v-on:confirm="confirmRemove"></removeDialog>
     <successModal  ref="success_modal" v-on:confirm="updateModal"></successModal>

  <div class="col-md-12 form-box ">
    <div class="row">
           
            <div class="col-md-6">
                <div class="row col-md-9" cols="12" v-if="employee" >
                    <v-select :items="employee" class="no-padding" item-text="firstName" >
                        <template slot="selection" slot-scope="data">
                            {{ data.item.firstName }}  {{ data.item.lastName }}
                        </template>
                        <template slot="item" slot-scope="data">
                            {{ data.item.firstName }}  {{ data.item.lastName }}
                        </template>
                    </v-select>
                    <span class="text-gray small-text mt-2">View Associated Members</span>
                </div>
            </div>
            
            <h3 class="form-title font col-md-3 row ml-1" cols="6">
                 <v-select
                    label="Status"
                    v-model.trim="status"
                    small
                    :color="status == 'Active' ? 'green' : 'red'"
                    :item-color="status == 'Active' ? 'green' : 'red'" 
                    :items="[{text:'Active', value:'active'}, {text:'Inactive', value:'inactive'}]">
                </v-select>
            </h3>
          
       
    </div>
    <form class="row">
        <div class="col-md-6 no-padding">
            <div class="col-md-12">
               <label class="font text-gray" for="">First Name</label>
                <v-text-field
                tabindex="1"
                required
                v-model.trim="firstName"
                solo
                ></v-text-field>
           </div>
            <div class="col-md-12">
              <label class="font text-gray" for="">Last Name</label>
                <v-text-field
                tabindex="2"
                required
                solo
                v-model.trim="lastName"
                ></v-text-field>
           </div>
            <div class="col-md-12">
                <label class="font text-gray" for="">Email</label>
                <v-text-field
                 tabindex="3"
                required
                v-model.trim="email"
                solo
                ></v-text-field>
            </div>
            <div class="col-md-12">
                <label class="font text-gray" for="">Sex</label>
                <div class="row col-md-12 justify-space-between">
                       <div  :class="gender == 'male' ? 'col-md-4 blue-buttons  text-center  border-radius ' : 'col-md-4 gender-buttons  text-center  text-gray'" @click="getGender('male')" tabindex="4">Male</div>
                    <div :class="gender == 'female' ? 'col-md-4 blue-buttons  text-center  border-radius ' : 'col-md-4 gender-buttons  text-center  text-gray'" @click="getGender('female')" tabindex="5">Female</div> 
                </div>
           </div>
           <div class="col-md-12 row">
            <div class="col-md-6">
                <label class="font text-gray" for="">Birth Month</label>
                <v-text-field
                required
                v-model.trim="birthMonth"
                tabindex="6"
                solo
                ></v-text-field>
            </div>
             <div class="col-md-3">
                <label class="font text-gray" for="">Day</label>
                <v-text-field
                required
                type="number"
                v-model.trim="birthDay"
                tabindex="7"
                solo
                ></v-text-field>
            </div>
             <div class="col-md-3">
                <label class="font text-gray" for="">Year</label>
                <v-text-field
                required
                type="number"
                tabindex="8"
                v-model.trim="birthYear"
                solo
                ></v-text-field>
            </div>
         </div>
            <div class="col-md-12">
                <label class="font text-gray" for="">Social Security Number</label>
                <v-text-field
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                tabindex="9"
                required
                solo
                v-model.trim="socialNumber"
                :type="show1 ? 'text' : 'password'"
                @click:append="show1 = !show1"
                ></v-text-field>
            </div>
        </div>
        <div class="col-md-6 no-padding" >
              <div class="col-md-12">
               <label class="font text-gray" for="">Member Type</label>
                <v-text-field
                tabindex="10"
                required
                solo
                v-model.trim="memberType"
                ></v-text-field>
           </div>
            <div class="col-md-12">
               <label class="font text-gray" for="">Home Address 1</label>
                <v-text-field
                tabindex="10"
                required
                solo
                v-model.trim="homeAddres1"
                ></v-text-field>
           </div>
          <div class="col-md-12">
            <label class="font text-gray" for="">Address 2</label>
                <v-text-field
                tabindex="11"
                required
                solo
                v-model.trim="homeAddress2"
                ></v-text-field>
           </div>
          <div class="col-md-12 row">
            <div class="col-md-6">
                <label class="font text-gray" for="">City</label>
                <v-text-field
                required
                tabindex="12"
                v-model.trim="city"
                solo
                ></v-text-field>
            </div>
             <div class="col-md-3">
                <label class="font text-gray" for="">Zip</label>
                <v-text-field
                required
                tabindex="13"
                type="number"
                v-model.trim="zip"
                solo
                ></v-text-field>
            </div>
             <div class="col-md-3">
                <label class="font text-gray" for="">State</label>
                <v-text-field
                required
                v-model.trim="state"
                tabindex="14"
                solo
                
                ></v-text-field>
            </div>
        </div>
        
         <div class="ma-lg-1 ma-xl-2" cols="12">
             <v-col cols="12">
                  <v-btn  cols="12" class="blue-buttons" @click="removeMember">Remove Dependent  </v-btn>
             </v-col>

         </div>
     
        
        </div>           
        <div class="col-md-12 text-xl-end text-lg-end text-sm-start">
                <v-btn cols="12"  tabindex="16" class="blue-buttons mr-6 mb-2" @click="$router.push(`/employer-advisor/employers/${routeParams}/members`)">
                   Cancel
                </v-btn>
                 <v-btn cols="12"  tabindex="16" class="blue-buttons mr-6 mb-2" @click="editMember()">
                  Save & Update
                </v-btn>
            
        </div>
      </form>
    </div>
       
 </v-row>
</template>
<script>
import api from '../../../../../services/api'
import successModal from '../../../../TablesTemplate/SuccessDialog.vue'
import removeDialog from '../../../../TablesTemplate/RemoveDialog.vue'
 export default{
    components:{
        successModal,
        removeDialog

     },
    data(){
        return{
            show1:false,
            dialog:false,
            dialog1:false,
            firstName:'',
            lastName:'',
            email:'',
            sex:'',
            birthMonth: '',
            birthDay:'',
            birthYear:'',
            memberType:'',
            homeAddres1:'',
            homeAddress2:'',
            city:'',
            zip:'',
            state:'',
            socialNumber:'',
            gender:'',
            status:'active'

        }
    },
    computed:{
        singleMember(){
        
           return this.$store.getters.getEmployerSingleMember;
        },
         singleClient(){
          return this.$store.getters.getClientGetter;
        },
        routeParams(){
         return this.$route.params.name
        },
        dependent(){
            let dep = {}
            if(this.$store.getters.getEmployerSingleMemberSingleDependent){
                dep = this.$store.getters.getEmployerSingleMemberSingleDependent
            }
            
           
            return dep
        },
        employee(){
            let emp = [];
            if(this.$store.getters.getEmployerSingleMemberEmployee){
                emp.push(this.$store.getters.getEmployerSingleMemberEmployee)
            }
         
            return emp
        }
    },
    created(){
       
        if(this.dependent){
            let splited =  this.dependent.birthdate.split("/");
            this.birthMonth = splited[0];
            this.birthDay =  splited[1];
            this.birthYear =  splited[2];

            this.firstName = this.dependent.firstName;
            this.lastName = this.dependent.lastName;
            this.email = this.dependent.email;
            this.sex = this.dependent.sex;
           
            this.memberType = this.dependent.memberType;
            this.homeAddres1 = this.dependent.address1;
            this.homeAddress2 = this.dependent.address1;
            this.city = this.dependent.city;
            this.zip = this.dependent.zipCode;
            this.state = this.dependent.state;
            this.socialNumber = this.dependent.ssn;
            this.gender = this.dependent.sex
            this.status = this.dependent.status

        }

        this.singleDemp()



    },
    methods:{
       getGender(gender){
             this.gender = gender
         },
        updateModal(){
           this.$refs.success_modal.closePopUp()
        },
        editMember(){
            let member = {
                'firstName' :  this.firstName,
                'lastName': this.lastName,
                'email': this.email,
                'sex': this.gender,
                'birthMonth':  this.birthMonth,
                'birthDay': this.birthDay,
                'birthYear': this.birthYear,
                'memberType': this.memberType,
                'address1': this.homeAddres1,
                'address2': this.homeAddress2,
                'city': this.city,
                'zip': this.zip,
                'state': this.state,
                'status':this.status,
                'ssn':this.socialNumber
               
            }
            let user = JSON.parse(localStorage.getItem('user'));
            let advisorId = user.groupPortal[0].organization.employerAdvisor
            let employer = JSON.parse(localStorage.getItem('employer'))
            api().put(`/advisors/${advisorId}/employers/${employer._id}/members/${this.singleMember._id}/dependents/${this.dependent._id}`, member).then((res)=>{
              
                this.$refs.success_modal.openPopUp('Dependent successfully updated');
                this.$store.commit('setSingleEmployerMemberDependent', res.data.associatedDependents);
            })
            
        },
        removeMember(){
            this.$refs.dialog_delete.openPopUp('Are you sure that you want to delete this dependent?');
        },
        confirmRemove(){
         let user = JSON.parse(localStorage.getItem('user'));
         let advisorId = user.groupPortal[0].organization.employerAdvisor
         let employer = JSON.parse(localStorage.getItem('employer'))
            api().delete(`/advisors/${advisorId}/employers/${employer._id}/members/${this.singleMember._id}/dependents/${this.dependent._id}`).then((res)=>{
                if(res){
                    this.$router.push(`/employer-advisor/employers/${this.routeParams}/members`);
                   
                }
            })
        },
        closePopUp(){
            this.dialog = false
        },
         singleDemp(){
           let user = JSON.parse(localStorage.getItem('user'));
           let advisorId = user.groupPortal[0].organization.employerAdvisor
           let employer = JSON.parse(localStorage.getItem('employer'))
         
           api().get(`/advisors/${advisorId}/employers/${employer._id}/members/${this.dependent._id}/associated`).then((res)=>{
             if(res){
                this.$store.commit('setSingleEmployerMemberDependent', res.data.associatedDependents);
                this.$store.commit('setSingleEmployerMemberEmployee', res.data.associatedEmployee);
            
             }
           });

      },
    }
 }
</script>

