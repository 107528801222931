<template>
    <div class="row-height pa-0">
        <!-- Fullscreen Loader -->
        <FullscreenLoader v-if="waitingForResponse" />

        <div v-if="!waitingForResponse">
            <div class="row col-12 d-flex justify-space-between mb-1">
                <div class="col-md-8 col-4 mb-0 ml-4 pb-0 font-weight-500 font-14 d-flex">
                    <div @click="$router.go(-1)" role="button" class="mt-2 display_flex display_flex_mid">
                        <img src="/svg/backArrow.svg" width="16" />
                        <p class="ma-0 ml-2">Back</p>
                    </div>
                </div>
                <div class="pb-0 mb-0 mt-2">
                    <span v-if="solution.favorite" class="d-flex pt-2">
                        <p class="mb-0 pb-0">Remove from favorites</p> <img @click="favorite()" src="/svg/filled-heart.svg"
                            height="22" width="25" />
                    </span>
                    <span v-else class="d-flex pt-2">
                        <p class="mb-0 pb-0"> Add to favorites</p> <img @click="favorite()" height="22" width="25"
                            src="/svg/empty-heart.svg" />
                    </span>
                </div>
            </div>
            <div class="orange-header">
            </div>
            <div class="col-12 d-flex">

                <div v-if="solution.logo" class="text-start justify-start mb-1 mt-0 card-logo"
                    :style="`background-image:url(${solution.logo})`"></div>
                <div v-else class="text-start justify-start mb-1 mt-0 card-logo"
                    :style="`background-image:url('/tpa/virtualNetwork.svg')`"></div>
                <div class="col-12">
                    <div class="font-20 pb-0 mb-0"> {{ solution.name }}</div>
                    <div>
                        <v-chip class="pa-2" color="#F2F2F2" text-color="#333333" small>
                            <img src="/svg/vNet.svg" class="mr-1" />
                            Virtual Network
                        </v-chip>
                    </div>
                    <div class="d-flex pt-1">
                        <span class="d-flex mr-4"> <img class="mr-2 mt-1" height="16" width="16"
                                src="/svg/advancedPrimaryCare.svg" />
                            <!-- <p class="ma-0 font-14"> {{ solution.category }}</p>  -->
                            <p class="ma-0 font-14"> Advanced Primary Care </p> 
                        </span>
                        <!-- Remove for now -->
                        <!-- <span class="d-flex"> <img class="mr-2 mt-1" height="16" width="16" src="/svg/activeMembers.svg" />
                            <p class="ma-0 font-14"> 0 active members</p>
                        </span> -->
                    </div>
                </div>
            </div>
            <div class="col-12 row-height">
                <v-tabs color="black" left>
                    <v-tab class="mr-1" :class="{ 'no-border': isActiveTab('Info') }" @click="setActiveTab('Info')"
                        :style="isActiveTab('Rates and Fees') ? 'border-right:1px solid #cccccc; padding-right: 10px;' : ''">
                        Info
                    </v-tab>
                    <v-tab class="mr-1" :class="{ 'no-border': isActiveTab('Rates and Fees') }"
                        @click="setActiveTab('Rates and Fees')" style="border-left:1px solid #cccccc; padding-left: 10px;"
                        :style="isActiveTab('Participating Organization') ? 'border-right:1px solid #cccccc; padding-right: 10px;' : ''">
                        <!-- Rates and Fees -->
                        Rates
                    </v-tab>
                    <!-- <v-tab class="mr-1" :class="{ 'no-border': isActiveTab('Participating Organization') }"
                        @click="setActiveTab('Participating Organization')"
                        style="border-left:1px solid #cccccc; padding-left: 10px;"
                        :style="isActiveTab('Participating Employers') ? 'border-right:1px solid #cccccc; padding-right: 10px;' : ''">
                        Participating Organizations
                    </v-tab>
                    <v-tab :class="{ 'no-border': isActiveTab('Participating Employers') }"
                        @click="setActiveTab('Participating Employers')"
                        style="border-left:1px solid #cccccc; padding-left: 10px;">
                        Participating Employers
                    </v-tab> -->
                    <!-- TAB INFO -->
                    <v-tab-item>
                        <v-divider horizontal class="mt-2"></v-divider>
                        <v-container fluid>
                            <v-row>
                                <v-col cols="6">
                                    <v-col cols="12" class="font-weight-600 pb-0">
                                        Description:
                                    </v-col>
                                    <v-col
                                        v-if="solution && solution.details && solution.details.description && solution.details.description.length > 200 && descLength"
                                        cols="12" class="pt-0">

                                        {{ solution.details?.description.substring(0, 5) }}...
                                        <br>
                                        <small class="desc_display_btn" @click="descLength = false">
                                            show more <v-icon small color="primary">mdi-chevron-down</v-icon>
                                        </small>
                                    </v-col>
                                    <v-col
                                        v-else-if="solution && solution.details && solution.details.description && solution.details.description.length > 200 && !descLength"
                                        cols="12" class="pt-0">

                                        {{ solution.details?.description }}
                                        <br>
                                        <small class="desc_display_btn" @click="descLength = true">
                                            show less <v-icon small color="primary">mdi-chevron-up</v-icon>
                                        </small>
                                    </v-col>
                                    <v-col v-else-if="!descLength" cols="12" class="pt-0">
                                        {{ solution.details ? solution.details.description : '' }}
                                    </v-col>
                                    <!-- Hide for now -->
                                    <!-- <v-col cols="12" class="font-weight-600 pb-0">
                                        Definition of Services:
                                    </v-col>
                                    <v-col cols="12" class="pt-0">
                                        {{ solution.details && solution.details.definition ? solution.details.definition :
                                            '/' }}
                                    </v-col> -->
                                </v-col>
                                <v-col cols="6">
                                    <v-col cols="12" class=" font-24 font-weight-600 pb-0">
                                        Solution Facilitator:
                                    </v-col>
                                    <v-col cols="12" class="pt-0 expansion-link">
                                        <a @click="openUrl()"> Visit Website
                                            <svg class="ml-1 " width="15" height="15" viewBox="0 0 16 16" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.68866 10.9703C5.57151 10.8532 5.57151 10.6632 5.68866 10.546L13.7674 2.46728L13.7497 2.44961L11.5008 2.45816C11.3351 2.45816 11.2008 2.32383 11.2008 2.15816V1.90818C11.2008 1.74248 11.3351 1.60818 11.5008 1.60818L14.9008 1.59961C15.0665 1.59961 15.2008 1.73391 15.2008 1.89961L15.1922 5.29961C15.1922 5.46528 15.0579 5.59961 14.8922 5.59961H14.6422C14.4766 5.59961 14.3422 5.46528 14.3422 5.29961L14.3508 3.05066L14.3331 3.03298L6.25436 11.1117C6.13721 11.2289 5.94726 11.2289 5.83008 11.1117L5.68866 10.9703ZM11.4887 7.07948L11.2887 7.27948C11.2324 7.33574 11.2008 7.41205 11.2008 7.49161V13.1996C11.2008 13.4205 11.0217 13.5996 10.8008 13.5996H2.00078C1.77988 13.5996 1.60078 13.4205 1.60078 13.1996V4.39961C1.60078 4.17868 1.77988 3.99961 2.00078 3.99961H10.5002C10.5798 3.99961 10.6561 3.96801 10.7123 3.91173L10.9123 3.71173C11.1013 3.52273 10.9675 3.19961 10.7002 3.19961H2.00078C1.33803 3.19961 0.800781 3.73686 0.800781 4.39961V13.1996C0.800781 13.8624 1.33803 14.3996 2.00078 14.3996H10.8008C11.4635 14.3996 12.0008 13.8624 12.0008 13.1996V7.29163C12.0008 7.02436 11.6776 6.89051 11.4887 7.07948Z"
                                                    fill="#0069F3" />
                                            </svg>
                                        </a>
                                    </v-col>
                                    <v-col cols="12" class="font-weight-600 pb-0">
                                        Name:
                                    </v-col>
                                    <v-col cols="12" class="pt-0">
                                        {{ solution.facilitator ? solution.facilitator.companyName : '' }}
                                    </v-col>
                                    <!-- <v-col cols="12" class="font-weight-600 pb-0">
                                        Phone Number:
                                    </v-col>
                                    <v-col cols="12" class="pt-0">
                                        {{ solution.facilitator.primaryContact ? solution.facilitator.primaryContact.phone :
                                            '-' }}
                                    </v-col> -->
                                    <v-col cols="12" class="font-weight-600 pb-0">
                                        Point of Contact:
                                    </v-col>
                                    <v-col cols="12" class="pt-0">
                                        {{ solution.facilitator.primaryContact ?
                                            solution.facilitator.primaryContact.firstName : '' }} {{
                                       solution.facilitator.primaryContact ? solution.facilitator.primaryContact.lastName :
                                       '' }}
                                    </v-col>
                                    <v-col cols="12" class="font-weight-600 pb-0">
                                        Email:
                                    </v-col>
                                    <v-col cols="12" class="pt-0">
                                        {{ solution.details.contact ? solution.details.contact.email : '' }}
                                    </v-col>
                                    <!-- <v-col cols="12" class="font-weight-600 pb-0">
                                        Address:
                                    </v-col>
                                    <v-col cols="12" class="pt-0 pb-16">
                                        {{ solution.facilitator.address1 ? solution.facilitator.address1 : '' }}
                                    </v-col> -->
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                    <!-- TAB RATES AND FEES -->
                    <v-tab-item>
                        <v-divider horizontal class="mt-2"></v-divider>
                        <v-row>
                            <v-col cols="6">
                                <v-col cols="12" class="font-weight-600 font-20 pb-0" >
                                    <!-- Rates for Vendor Services -->
                                    Provider Rate
                                </v-col>
                                <v-col cols="12" class="font-weight-600 pb-0" style="font-weight:bold !important;">
                                    <!-- Subscription:  -->
                                    <!-- Rate Structure: -->
                                    Structure:
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    {{ solution.rate ? solution.rate.structure : '' }}
                                </v-col>
                                <v-col cols="12" class="font-weight-700 pb-0" style="font-weight:bold !important;">
                                    <!-- Payment Frequency: -->
                                    Frequency:
                                </v-col>
                                <v-col cols="12" class="pt-0" style="text-transform: capitalize;">
                                    <!-- {{ solution.rate && solution.rate.frequency == 'PMPM' ? 'Per Member Per Month' : '/' }} -->
                                    <!-- {{ solution.rate }} -->
                                    {{ solution.rate && solution?.rate?.frequency ? solution?.rate?.frequency : '/' }}
                                </v-col>
                                <v-col cols="12" class="font-weight-700 pb-0" style="font-weight:bold !important;">
                                    <!-- Rate: -->
                                    Rate Description:
                                </v-col>
                                <v-col cols="12" class="pt-0 text-danger" v-if="solution?.rate?.structure && solution?.rate?.structure === 'PMPM'">
                                    <!-- Defined by the provider -->
                                    Per Member Per Month
                                </v-col>
                                <v-col cols="12" class="pt-0 text-danger" v-else-if="solution?.rate?.structure && solution?.rate?.structure === 'PEMPM'">
                                    <!-- Defined by the provider -->
                                    Per Engaged Member Per Month
                                </v-col>
                                <v-col cols="12" class="pt-0 text-danger" v-else>
                                    <!-- Defined by the provider -->
                                    {{  solution?.rate?.structure }}
                                </v-col>
                                <v-col cols="12" class="font-weight-700 pb-0" style="font-weight:bold !important;">
                                    {{ solution.contracted && solution.participation && solution.participation.status == 'Active' ? 'Rate Selected:':'Rate:' }}
                                    <!-- Rate Selected: if participating -->
                                    <!-- Rate: if NOT p -->
                                </v-col>
                                <v-col cols="12" class="pt-0" style="text-transform: capitalize;">
                                        <!-- {{ solution.rate }} -->
                                        <span style="text-transform: capitalize;">
                                            {{solution.rate?.type}}
                                        </span>  
                                        <!-- TODO: review update here! Is only temporary to serve a minor fix -->
                                        <!-- TEMPORARY - TO BE CHANGED ------------------------------------   -->
                                        
                                        - Net Rate =<strong> ${{ solution.rate?.amount }}</strong> 
                                        <!-- - <strong> ${{ solution.rate?.amount }}</strong> {{  solution.rate.structure }} -->
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <div class="rates_notes">
                                        NOTE: Your contracted rate for this solution will vary, based on the specific fees associated with each employer.  Regardless of what the total contracted rate is, you will receive the net amount that is reflected in your rate group.
                                    </div>
                                </v-col>
                                <!-- <v-col v-if="solution.contracted" cols="12" class="pt-0 text-danger">
                                    
                                </v-col>
                                <v-col v-else cols="12" class="pt-0 text-danger">
                                    <span>
                                        Open - Defined by the provider 
                                    </span>
                                </v-col> -->
                                <!-- <v-col cols="12" class="font-weight-600 font-20 pb-0 mt-2">
                                    Solution Fees
                                </v-col>
                                <v-col cols="12" class="font-weight-600 pb-0" style="font-weight:bold !important;">
                                    Solution Facilitator Fee:
                                </v-col>
                                <v-col cols="12" class="pt-0" v-if="solution.fees && solution.fees[0]">
                                    <strong>${{ solution.fees[0].amount }}</strong> dollars per transaction
                                </v-col>
                                <v-col v-else> - </v-col>
                                <v-col cols="12" class="font-weight-600 pb-0" style="font-weight:bold !important;">
                                    TPA Fee:
                                </v-col>
                                <v-col cols="12" class="pt-0" :class="!solution.contracted ? 'pb-16' : ''"
                                    v-if="solution.fees && solution.fees[2] && solution.fees[2].amount != 0">
                                    Determined by the TPA on an employer basis. TPA fee may range from 0 to
                                    {{ solution.fees[2].amount }}% per subscription per member per month (PMPM)
                                </v-col>
                                <v-col v-else cols="12" class="pt-0" :class="!solution.contracted ? 'pb-16' : ''"> Not applicable </v-col>
                                <v-col v-if="solution.contracted" cols="12" class="font-weight-600 pb-0" style="font-weight:bold !important;">
                                    Total net PMPM (after fees):
                                </v-col>
                                <v-col cols="12" class="pt-0 pb-16"
                                    v-if="solution.contracted && solution.rate">
                                   HSA Plan members: ${{solution.rate.totalNetHSA.toFixed(2)}} <br>
                                   Non HSA Plan members: ${{solution.rate.totalNetNonHSA.toFixed(2)}}
                                </v-col> -->
                            </v-col>
                            <v-col cols="6">
                                <v-col cols="12" class=" font-20 font-weight-600 pb-0">
                                    Enrollment/Participation
                                </v-col>
                                <v-col cols="12" class="font-weight-600 pb-0" style="font-weight:bold !important;">
                                    Provider participation:
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    Requires Approval from Solution Facilitator
                                </v-col>
                                <v-col cols="12" class="font-weight-600 pb-0" style="font-weight:bold !important;">
                                    Employer participation:
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    Requires Approval from Solution Facilitator
                                </v-col>
                                <v-col cols="12" class="font-weight-600 pb-0" style="font-weight:bold !important;">
                                    Contract PDF:
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <a class="" @click="downloadFile()">
                                        {{ solution.contracted ? "Download Contract" : "View Contract" }}
                                        <img src="/svg/newDownload.svg" />
                                    </a>
                                </v-col>
                                <div v-if="solution.contracted">
                                    <v-col cols="12" class=" font-20 font-weight-600 pb-0">
                                        Participation Details
                                    </v-col>
                                    <v-col cols="12" class="font-weight-600 pb-0">
                                        Status:
                                    </v-col>
                                    <v-col cols="12" class="pt-0">
                                        <span v-if="solution.participation.status == 'Active'">
                                            <v-icon color="#04D900" size="x-small">mdi-circle</v-icon>
                                            Active
                                        </span>
                                        <span v-else-if="solution.participation.status == 'Pending Approval'">
                                            <v-icon color="#E6E6E6" size="x-small">mdi-circle</v-icon>
                                            Pending Approval
                                        </span>
                                        <span v-else>
                                            <v-icon color="#D90101" size="x-small">mdi-circle</v-icon>
                                            Inactive
                                        </span>
                                    </v-col>
                                </div>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <!-- TAB PARTICIPATING ORGANIZATIONS -->
                    <v-tab-item>
                        <v-divider horizontal class="mt-2"></v-divider>

                        <v-row class="pa-3">
                            <v-col cols="12" class="font-weight-600 pb-0 ml-2">
                                Total
                            </v-col>
                            <v-col cols="12" class="pt-0 ml-2">
                                15 Organizations <br>
                                2,073 Locations
                            </v-col>
                        </v-row>
                        <div class="col-12">
                            <v-data-table :headers="orgHeaders" :items="organizations" :hide-default-header="false"
                                :mobile-breakpoint="0" class="elevation-1 pa-2">
                                <template v-slot:body="{ items }">
                                    <tr v-for="item in items" :key="item._id" class="pa-1">
                                        <td class="pa-1 text-gray pl-3 font-14">
                                        </td>
                                        <td class="pa-1 pl-3 text-gray font-14">
                                        </td>
                                    </tr>
                                    <!-- STATIC DATA FOR NOW -->
                                    <tr class="pa-1">
                                        <td class="pa-1 pl-3 font-14">
                                            <span class="d-flex"><img height="40" src="/tpa/clinicsAndOrganizations.svg" />
                                                <strong class="mt-2">People One</strong> </span>
                                        </td>
                                        <td class="pa-1 pl-3  font-14">
                                            Advanced Primary Care
                                        </td>
                                        <td class="pa-1 pl-3  font-14">
                                            1023
                                        </td>
                                    </tr>
                                    <tr class="pa-1">
                                        <td class="pa-1 pl-3 font-14">
                                            <span class="d-flex"><img height="40" src="/tpa/clinicsAndOrganizations.svg" />
                                                <strong class="mt-2"> Heidi M. Larson</strong> </span>
                                        </td>
                                        <td class="pa-1 pl-3  font-14">
                                            Advanced Primary Care
                                        </td>
                                        <td class="pa-1 pl-3  font-14">
                                            60
                                        </td>
                                    </tr>
                                    <tr class="pa-1">
                                        <td class="pa-1  pl-3 font-14">
                                            <span class="d-flex"><img height="40" src="/tpa/clinicsAndOrganizations.svg" />
                                                <strong class="mt-2"> Organization</strong> </span>
                                        </td>
                                        <td class="pa-1 pl-3  font-14">
                                            Advanced Primary Care
                                        </td>
                                        <td class="pa-1 pl-3  font-14">
                                            23
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </div>
                    </v-tab-item>
                    <!-- TAB PARTICIPATING EMPLOYERS / -->
                    <v-tab-item>
                        <v-divider horizontal class="mt-2"></v-divider>

                        <v-row class="pa-3">
                            <v-col cols="12" class="font-weight-600 pb-0 ml-2">
                                Total
                            </v-col>
                            <v-col cols="12" class="pt-0 ml-2">
                                15 Employers <br>
                                5,000 Employees
                            </v-col>
                            <div class="col-12">
                                <v-data-table :headers="emplHeaders" :items="employers" :hide-default-header="false"
                                    :mobile-breakpoint="0" class="elevation-1 pa-2">
                                    <template v-slot:body="{ items }">
                                        <tr v-for="item in items" :key="item._id" class="pa-1">
                                            <td class="pa-1 text-gray pl-3 font-14">
                                            </td>
                                            <td class="pa-1 pl-3 text-gray font-14">
                                            </td>
                                        </tr>
                                        <!-- STATIC FOR NOW -->
                                        <tr class="pa-1">
                                            <td class="pa-1 pl-3 font-14">
                                                <span class="d-flex"><img height="40"
                                                        src="/tpa/clinicsAndOrganizations.svg" />
                                                    <strong class="mt-2">People One</strong> </span>
                                            </td>
                                            <td class="pa-1 pl-3  font-14">
                                                1023
                                            </td>
                                        </tr>
                                        <tr class="pa-1">
                                            <td class="pa-1 pl-3 font-14">
                                                <span class="d-flex"><img height="40"
                                                        src="/tpa/clinicsAndOrganizations.svg" />
                                                    <strong class="mt-2"> Heidi M. Larson</strong> </span>
                                            </td>
                                            <td class="pa-1 pl-3  font-14">
                                                60
                                            </td>
                                        </tr>
                                        <tr class="pa-1">
                                            <td class="pa-1  pl-3 font-14">
                                                <span class="d-flex"><img height="40"
                                                        src="/tpa/clinicsAndOrganizations.svg" />
                                                    <strong class="mt-2"> Organization</strong> </span>
                                            </td>
                                            <td class="pa-1 pl-3  font-14">
                                                23
                                            </td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </div>
                        </v-row>
                    </v-tab-item>
                </v-tabs>

            </div>
            <div class="btn-footer">
                <v-btn v-if="solution.contracted && solution.participation?.status == 'Active' || 
                solution.contracted && solution.participation?.status == 'active' ||
                solution.contracted && solution.participation?.status?.includes(`active`)"
                    class="radius-8 text--white mr-md-16" height="40" width="320" color="#D90101" @click="leaveSolution()">
                    Stop Participating
                </v-btn>
                <v-btn
                    v-else-if="solution.contracted && solution.participation?.status == 'Pending Approval' || 
                    solution.contracted && solution.participation?.status?.includes(`pending`) || 
                    solution.contracted && solution.participation?.status?.includes(`Pending`)"
                    disabled class="radius-8 font-14" height="40" width="320" color="#CCCCCC"
                    @click="participationDialog()">
                    Pending Approval
                </v-btn>
                <v-btn v-else class="radius-8 font-14 text--white" height="40" width="320" color="#1E7BF4"
                    @click="participationDialog()">
                    Participate
                </v-btn>
            </div>
            <v-dialog hide-overlay v-model.trim="errorModal" height="320" width="400" style="margin-top: 30vh"
                content-class="modal-member-check">
                <v-card>
                    <v-card-text>
                        <div class="col-md-12 pt-10 text-center" @click="errorModal = false">
                            <div class="red-error-modal">
                                <img src="/svg/error-modal-icon.svg" />
                                <div class="error-modal-red-text">Please select rate first</div>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model.trim="participation" width="1000" persistent>
                <v-card class="col-md-12 pa-0">
                    <div class="col-md-12 text-end pb-0">
                        <h3 @click="closeDialog()" role="button"><v-icon>mdi-close</v-icon></h3>
                    </div>
                    <v-card-title class="text-h5 pt-0 pb-0"> Enrollment </v-card-title>
                    <v-stepper v-model="step">
                        <v-stepper-header class="col-7 ma-auto pt-0 pb-0">
                            <v-stepper-step :complete="step > 1" step="1">
                            </v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step :complete="step > 2" step="2">
                            </v-stepper-step>
                            <!-- <v-divider></v-divider> -->
                            <!-- <v-stepper-step :complete="step > 3" step="3">
                            </v-stepper-step> -->
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1" class="pa-md-2 pa-0">
                                <v-card class="mb-12">
                                    <div class="col-12 ml-3 pb-0">
                                        <!-- <div class="font-weight-600 font-14"> Choose your subscription rate</div> -->
                                        <!-- <div class="font-weight-400 font-12"> Maximum rate schedule permited: $150</div> -->
                                        <div class="font-weight-600 font-14"> Select your net PMPM Rate</div>

                                    </div>
                                    <div class="col-12 mt-0 pt-0 d-md-flex">
                                        <div class="col-12 col-md-6 col-lg-6 col-xl-6 mt-0 pt-0">
                                            <div class="col-12 pl-0 pb-0 mb-0">
                                                <v-select class="pb-0 radius-8" solo dense return-object hide-details
                                                    required v-model="solutionRate" :items="rates" item-text="group"
                                                    item-value="_id" placeholder="Select your net PMPM Rate"
                                                    @change="selected()" :disabled="solution.rate?.type === 'fix'">
                                                    <template #item="{ item }">
                                                        <span class="d-flex col-12 pa-0">
                                                            <p class="col-6">{{ item.group }} </p>
                                                            <p class=" col-4 text-end ml-10 font-weight-bold">${{
                                                                item.baseRate }}</p>
                                                        </span>
                                                    </template>
                                                </v-select>
                                            </div>
                                            <div class="pt-2" >
                                                <p class="font-12 text-gray-05 pt-0" v-if="solution.rate?.type !== 'fix'">*You may adjust or create a new rate
                                                    under
                                                    the
                                                    <a class="text-underline"
                                                        @click="$router.push('/dpc-providers/rates')">Rate Tab</a>
                                                </p>
                                            </div>
                                            <div class="rates_notes">
                                                NOTE: Your contracted rate for this solution will vary, based on the specific fees associated with each employer.  Regardless of what the total contracted rate is, you will receive the net amount that is reflected in your rate group.
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-6 col-xl-6">


                                        </div>
                                    </div>
                                </v-card>
                                <div class="col-12" align="end">
                                    <v-btn color="#0069F3" text class="mr-3" width="150px" @click="closeDialog()">
                                        Back
                                    </v-btn>
                                    <!-- <v-btn :disabled="!solutionRate" width="150px" color="#0069F3" class="radius-8 text--white" @click="step2()"> -->
                                    <v-btn :disabled="!solutionRate" width="150px" color="#0069F3" class="radius-8 text--white"  @click="step = 2">
                                        Next
                                    </v-btn>
                                </div>
                            </v-stepper-content>
                            <!-- <v-stepper-content step="2" class="pa-md-2 pa-0">
                                <v-card class="">
                                    <div class="col-12 col-md-6 ml-3 pb-0">
                                        <div class="font-weight-600 font-14"> Choose your subscription rate</div>
                                        <div class="font-weight-400 font-12"> Maximum rate schedule permited: $150</div>

                                    </div>

                                    <div class="col-12 mt-0 pt-0 d-md-flex">
                                        <div class="col-12 col-md-6 col-lg-6 col-xl-6 mt-0 pt-0">
                                            <div class="col-12 pl-0 pb-0 mb-0">
                                                <v-select class="pb-0 radius-8" solo dense return-object hide-details
                                                    requried v-model="solutionRate" :items="rates" item-text="group"
                                                    item-value="_id" placeholder="Select one of your rate groups*"
                                                    @change="selected()">
                                                    <template #item="{ item }">
                                                        <span class="d-flex col-12 pa-0">
                                                            <p class="col-6">{{ item.group }} </p>
                                                            <p class=" col-4 text-end ml-10 font-weight-bold">${{
                                                                item.baseRate }}</p>
                                                        </span>
                                                    </template>
                                                </v-select>
                                            </div>
                                            <div class="pt-2">
                                                <p class="text-gray-05 font-12 pt-0">*You may adjust or create a new rate
                                                    under
                                                    the
                                                    <a class="text-underline"
                                                        @click="$router.push('/dpc-providers/rates')">Rate Tab</a>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-6 col-xl-6">

                                            <div v-if="solutionRate && loadRate">
                                                <div class="col-6 text-center">
                                                    <v-progress-circular indeterminate :size="50" color="primary"
                                                        class="text-center mt-5 mb-5"></v-progress-circular>
                                                </div>


                                            </div>
                                            <div height="350px" class=" mb-5 mt-0 rate-shadow "
                                                style="margin-top: -35px !important;" v-else-if="solutionRate && !loadRate">
                                                <div class=" pl-1 pr-1 pb-4 display">
                                                    <span class="mt-2 font-weight-bold ml-3">
                                                        Monthly Amount
                                                    </span>
                                                    <div class="col-12 d-flex pa-0">
                                                        <div class="col-8 pb-0 text-start text-gray">
                                                            DPC Base Rate
                                                        </div>
                                                        <div class="col-4 pb-0 text-end pl-0"
                                                            v-if="rateInformation.monthly">
                                                            ${{ rateInformation.monthly.baseRate.toFixed(2) }}</div>
                                                    </div>
                                                    <div class="col-12 d-flex pa-0">
                                                        <div class="col-8 pb-0 text-start text-gray">
                                                            Solution Facilitator Fee
                                                        </div>
                                                        <div class="col-4 pb-0 text-end pl-0"
                                                            v-if="rateInformation.monthly">
                                                            ${{ rateInformation.monthly.solutionFee.toFixed(2) }}</div>
                                                    </div>
                                                    <div class="col-12 d-flex pa-0">
                                                        <div class="col-9 pb-0 text-start text-gray">
                                                            Apaly Fee
                                                        </div>
                                                    </div>
                                                    <div class="col-12 d-flex pa-2 pb-0 pt-0 pr-0">
                                                        <div class="col-8 pb-0 text-start text-gray">
                                                            HSA Plan Members
                                                        </div>
                                                        <div class="col-4 pb-0  text-end pl-0"
                                                            v-if="rateInformation.monthly">
                                                            ${{ rateInformation.monthly.apalyHSA.toFixed(2) }}</div>
                                                    </div>
                                                    <div class="col-12 d-flex pa-2 pt-0 pr-0">
                                                        <div class="col-8 pb-0  text-start text-gray">
                                                            Non HSA Plan Members
                                                        </div>
                                                        <div class="col-4 pb-0 text-end pl-0"
                                                            v-if="rateInformation.monthly">
                                                            ${{ rateInformation.monthly.apalyNonHSA.toFixed(2) }}</div>
                                                    </div>
                                                    <v-divider horizontal></v-divider>
                                                    <div class="col-12 d-flex pl-0 pr-0">
                                                        <div class="col-9 text-start pb-0 pt-0 text-gray">
                                                            Total net PMPM received after fees
                                                        </div>
                                                    </div>
                                                    <div class="col-12 d-flex pl-0 pr-0 pa-0">
                                                        <div class="col-8 text-start pb-0 pt-0 text-gray">
                                                            HSA Plan Members
                                                        </div>
                                                        <div class="col-4 pb-0 pt-0 text-end font-weight-bold pl-0"
                                                            v-if="rateInformation.monthly"> ${{
                                                                rateInformation.monthly.netHSA.toFixed(2) }}*</div>
                                                    </div>
                                                    <div class="col-12 d-flex pl-0 pr-0 pa-0">
                                                        <div class="col-8 text-start pb-0 pt-0 text-gray">
                                                            Non HSA Plan Members
                                                        </div>
                                                        <div class="col-4 pb-0 pt-0 text-end font-weight-bold pl-0"
                                                            v-if="rateInformation.monthly"> ${{
                                                                rateInformation.monthly.netNonHSA.toFixed(2) }}*</div>
                                                    </div>
                                                    <div class="hide_for_now" style="display: none">
                                                        <span class="mt-2 font-weight-bold ml-3">
                                                            Annual Amount
                                                        </span>
                                                        <div class="col-12 d-flex pa-0">
                                                            <div class="col-8 pb-0 text-start text-gray">
                                                                DPC Base Rate
                                                            </div>
                                                            <div class="col-4 pb-0 text-end font-weight-bold pl-0"
                                                                v-if="rateInformation.yearly"> ${{
                                                                    rateInformation.yearly.baseRate.toFixed(2) }}</div>
                                                        </div>
                                                        <div class="col-12 d-flex pa-0">
                                                            <div class="col-8 pb-0 text-start text-gray">
                                                                Solution Facilitator Fee
                                                            </div>
                                                            <div class="col-4 pb-0 text-end font-weight-bold pl-0"
                                                                v-if="rateInformation.yearly"> ${{
                                                                    rateInformation.yearly.solutionFee.toFixed(2) }}</div>
                                                        </div>
                                                        <div class="col-12 d-flex pa-0">
                                                            <div class="col-9 pb-0 text-start text-gray">
                                                                Apaly Fee
                                                            </div>
                                                        </div>
                                                        <div class="col-12 d-flex pa-2 pb-0 pt-0 pr-0">
                                                            <div class="col-8 pb-0 text-start text-gray">
                                                                HSA Plan Members
                                                            </div>
                                                            <div class="col-4 pb-0 pl-0  text-end "
                                                                v-if="rateInformation.yearly">
                                                                ${{ rateInformation.yearly.apalyHSA.toFixed(2) }}</div>
                                                        </div>
                                                        <div class="col-12 d-flex pa-2 pt-0 pr-0">
                                                            <div class="col-8 pb-0  text-start text-gray">
                                                                Non HSA Plan Members
                                                            </div>
                                                            <div class="col-4 pb-0 text-end pl-0" v-if="rateInformation.yearly">
                                                                ${{ rateInformation.yearly.apalyNonHSA.toFixed(2) }}</div>
                                                        </div>

                                                        <v-divider horizontal></v-divider>
                                                        <div class="col-12 d-flex pl-0 pr-0">
                                                            <div class="col-9 text-start pb-0 pt-0 text-gray">
                                                                Total net PMPM received after fees
                                                            </div>
                                                        </div>
                                                        <div class="col-12 d-flex pl-0 pr-0 pa-0">
                                                            <div class="col-8 text-start pb-0 pt-0 text-gray">
                                                                HSA Plan Members
                                                            </div>
                                                            <div class="col-4 pb-0 pt-0 text-end font-weight-bold pl-0"
                                                                v-if="rateInformation.yearly"> ${{
                                                                    rateInformation.yearly.netHSA.toFixed(2) }}</div>
                                                        </div>
                                                        <div class="col-12 d-flex pl-0 pr-0 pa-0">
                                                            <div class="col-8 text-start pb-0 pt-0 text-gray">
                                                                Non HSA Plan Members
                                                            </div>
                                                            <div class="col-4 pb-0 pt-0 text-end font-weight-bold pl-0"
                                                                v-if="rateInformation.yearly"> ${{
                                                                    rateInformation.yearly.netNonHSA.toFixed(2) }}</div>
                                                        </div>
                                                     </div>
                                                </div>
                                                <div class="col-12 d-flex pl-0 pr-0 pa-0 pb-3 pt-3">
                                                    <div class="col-12 pb-16 text-start pb-0 pt-0 ">
                                                        *TPA fees to up to {{ rateInformation.maxTPAfee }}% may apply
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </v-card>
                                <div class="col-12" align="end">
                                    <v-btn color="#0069F3" text class="mr-3" width="150px" @click="step = 1">
                                        Back
                                    </v-btn>
                                    <v-btn :disabled="!solutionRate" width="150px" color="#0069F3"
                                        class="radius-8 text--white" @click="step = 3">
                                        Next
                                    </v-btn>
                                </div>
                            </v-stepper-content> -->
                            <v-stepper-content step="2">
                                <v-card class="mb-12 " height="60vh">
                                    <h4 class="font-weight-bold">
                                        Solution Facilitator Contract Agreement
                                    </h4>
                                    <div class="" v-if="solution.contract">
                                        <iframe :src="pdfUrl" :style="{
                                            height: $vuetify.breakpoint.xsOnly ? '300px' : '50vh',
                                            width: $vuetify.breakpoint.xsOnly ? '100%' : '100%',
                                        }"></iframe>
                                    </div>
                                </v-card>
                                <div class="d-md-flex justify-space-between col-12">
                                    <div class="col-md-6 col-12 pa-0">
                                        <v-checkbox v-model="agreeWithTerms" label="I agree with the terms">
                                        </v-checkbox>
                                    </div>
                                    <div class="col-md-6 col-12 pa-0 d-flex justify-end ml-md-auto">
                                        <v-btn color="#0069F3" text class="mr-3" width="150px" @click="step = 1">
                                            Back
                                        </v-btn>
                                        <v-btn :disabled="!agreeWithTerms" color="#0069F3" class="radius-8 text--white"
                                            width="150px" @click="accept()">
                                            Participate
                                        </v-btn>
                                    </div>
                                </div>
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                </v-card>
            </v-dialog>
            <div class="">
                <div class="col-md-12" align="end">
                    <v-dialog v-model.trim="agreeParticipate" width="500" class="success-modal">
                        <v-card>
                            <v-card-text class="pt-5">
                                <div class="display mt-3">
                                    <span class="text-start mt-2  success-text mt-5 mb-3">
                                        <span class="font-24 font-weight-400"> Success! You have enrolled in this
                                            solution.</span>
                                    </span>
                                    <span class="text-start font-16 font-weight-400 mt-3 mb-3 modal-content">
                                        Your approval is pending and you will be notified once the solution facilitator has
                                        reviewed and approved your participation
                                    </span>
                                    <span class="text-center font-weight-bold success-text mt-5 mb-2">
                                        <v-btn color="#0069F3" class="radius-8 text--white" width="170px" @click="gotIt()">
                                            Got It!
                                        </v-btn>
                                    </span>

                                </div>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model.trim="stopParticipating" width="450">
                        <v-card>
                            <div align="end" class="pt-2 pr-2"> <v-icon
                                    @click="stopParticipating = false">mdi-close</v-icon> </div>
                            <v-card-text class="pt-0 pb-2">
                                <div class="display mt-3">
                                    <span class="text-start mb-3">
                                        <div class="font-24 font-weight-400" style="color: #990101;"> Are you sure you want
                                            to stop participating in this solution?</div>
                                    </span>
                                    <span class="text-start font-14 mt-2  mb-3">
                                        Stop participating in a solution may have consequences on existing invoices and plan
                                        member services. Please email <strong>support@apaly.com </strong> to stop
                                        participating in this solution.
                                    </span>
                                    <span class="text-center font-weight-bold mt-5 mb-2">
                                        <v-btn class="mr-5 radius-8" color="#D90101" outlined
                                            @click="stopParticipating = false">
                                            Got It
                                        </v-btn>
                                        <v-btn color="#0069F3" class="text--white radius-8"
                                            @click="stopParticipating = false">
                                            Keep Participating
                                        </v-btn>
                                    </span>

                                </div>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model.trim="displayContract" width="900">
                        <v-card>
                            <div align="end" class="pt-2 pr-2"> <v-icon @click="displayContract = false">mdi-close</v-icon>
                            </div>
                            <v-card-text class="pt-0 pb-2">
                                <iframe :src="pdfContentUrl" style="width: 100%; height: 90vh;"></iframe>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                </div>
            </div>
        </div>

        <v-dialog v-model.trim="viewContract" width="900">
            <v-card>
                <div align="end" class="pt-2 pr-2"> <v-icon @click="viewContract = false">mdi-close</v-icon>
                </div>
                <v-card-text class="pt-0 pb-2">
                    <iframe :src="contractLink" style="width: 100%; height: 90vh;"></iframe>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- <v-overlay v-if="waitingForResponse" v-model="waitingForResponse" contained class="align-center justify-center">
            <v-progress-circular indeterminate :size="100" color="#326ab3" class="text-center mt-10 mb-5">
                <img src="/newapalylogoonlyblue.png" height="70" class="img-mobile" />
            </v-progress-circular>
        </v-overlay> -->
        <successModal ref="success_modal" v-on:confirm="closeSuccessModal"></successModal>
        <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
    </div>
</template>
    
<script>
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../TablesTemplate/ErrorDialog.vue";
import FullscreenLoader from "../../../Common/FullscreenLoader.vue";
import api from "../../../../services/api";
import { saveAs } from 'file-saver';
import { mapGetters } from "vuex";
export default {
    components: { 
        successModal,
        errorModal,
        FullscreenLoader
    },
    data() {
        return {
            viewContract: false,
            displayContract: false,
            pdfContentUrl: '',
            tab: 1,
            loadRate: false,
            stopParticipating: false,
            waitingForResponse: false,
            panel: [0],
            disablePointer: true,
            contractLink: "",
            checkTerms: false,
            agreeWithTerms: false,
            pdfUrl: "",
            agreeParticipate: false,
            rateInformation: null,
            errorText: false,
            solutionRate: "",
            solution: {},
            step: 1,
            enrollment: true,
            contract: false,
            success: false,
            participation: false,
            errorModal: false,
            rates: [],
            rate: null,
            valid: false,
            orgHeaders: [
                { text: 'Organization ', value: 'organization', align: 'start', sortable: true },
                { text: 'Specialty', value: 'specialty', align: 'start', sortable: true },
                { text: 'Number of locations ', value: 'locations', align: 'start', sortable: true },
                // { text: '', value: '', align: 'end', sortable: true },
            ],
            emplHeaders: [
                { text: 'Employer ', value: 'employer', align: 'start', sortable: true },
                { text: 'Number of employers ', value: 'employers', align: 'start', sortable: true },
            ],
            organizations: [],
            employers: [],
            descLength: false,
            activeTab: 'Info',
        };
    },
    async created() {
        await this.getRates();
        this.getSolution();
    },
    methods: {
        isActiveTab(tab) {
            return this.activeTab === tab;
        },
        setActiveTab(tab) {
            this.activeTab = tab;
        },
        formatAmount(value) {
            return `$${value % 1 !== 0 ? value.toFixed(2) : value + '.00'}`;
        },
        downloadFile() {
            if(this.solution.contracted) {
                // IF private contract

                this.waitingForResponse = true;
                let headers = { 'x-access-token': this.$cookies.get("token") };
                api()
                    .get(this.contractLink, {
                        responseType: 'blob',
                        'headers': headers,
                    })
                    .then((res) => {
                        if (res) {
                            this.waitingForResponse = false;
                            const blob = new Blob([res.data], { type: 'application/pdf' });
                            const blobUrl = URL.createObjectURL(blob);
                            this.displayContract = true;
                            this.pdfContentUrl = blobUrl;
                            const link = document.createElement('a');
                            link.style.display = 'none';
                            link.href = blobUrl;
                            link.download = 'contract.pdf';
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        }
                    })
                    .finally(() => {
                        this.waitingForResponse = false;
                    });
            }
            else {
                // If public contract
                // this.contractLink
                this.viewContract = true;

            }

        },
        openUrl() {
            window.open(`${this.solution.details.website}`);
        },
        openWebsite() {

            window.open(`${this.solution.website}`);
        },
        gotIt() {
            this.agreeParticipate = false;
            this.$router.push("/dpc-providers/solutions");
        },
        accept() {
            if (this.agreeWithTerms == true) {
                let data = {
                    rate: this.solutionRate.id,
                    contracted: true,
                };
                api()
                    .post(`/dpcproviders/solutions/${this.solution.id}`, data)
                    .catch((err) => {
                        if (err) {
                            this.participation = false;
                            this.step = 1;
                            this.rateInformation = {};
                            this.agreeWithTerms = false;

                            if (this.solution.rate && this.solution.rate.type !== 'fix') {
                                this.solutionRate = "";
                            }

                            this.$refs.error_modal.openPopUp(err.response?.data || err.response);
                        }
                    })
                    .then((res) => {
                        if (res) {
                            this.participation = false;
                            this.agreeParticipate = true;
                            this.step = 1;
                            this.rateInformation = {};
                            this.agreeWithTerms = false;

                            if (this.solution.rate && this.solution.rate.type !== 'fix') {
                                this.solutionRate = "";
                            }
                        }
                    });

            } else {
                this.checkTerms = true;
            }
        },
        goToDetails(item, entity) {
            if (entity == "solution") {
                this.$router.push(`/dpc-providers/solutions/details/${item.id}`);
            } else if (entity == "location") {
                this.$router.push({
                    path: `/dpc-providers/solutions/provider/details/${item.id}`,
                    query: { location: item.locationId },
                });
            } else if (entity == "vendor") {
                this.$router.push(`/dpc-providers/solutions/vendor/details/${item.id}`);
            } else if (entity == "provider") {
                this.$router.push(
                    `/dpc-providers/solutions/clinician/details/${item.id}`
                );
            }
        },
        closeErrorModal() {
            this.$refs.error_modal.closePopUp();
        },
        closeSuccessModal() {
            this.$refs.success_modal.closePopUp();
            // window.location.reload();
        },
        getSolution() {
            this.waitingForResponse = true;
            api()
                .get("/dpcproviders/market/solution/" + this.$route.params.id,)
                .then((res) => {
                    if (res) {
                        this.solution = res.data;
                        // console.log("this.solution: ", this.solution);
                        this.pdfUrl = res.data.contract;
                        this.contractLink = res.data.contract;
                        this.waitingForResponse = false;
                        // if (res.data.details && res.data.details.description && res.data.details.description.length > 200) {
                        //     this.descLength = true;
                        // }
                        // else {
                        //     this.descLength = false
                        // }

                        if (this.solution.rate && this.solution.rate.type === 'fix') {
                            this.rates = [
                                {
                                    _id: this.solution.rate.id,
                                    id: this.solution.rate.id,
                                    group: this.solution.rate.group,
                                    baseRate: this.solution.rate.amount 
                                }
                            ]
                            this.solutionRate = this.rates[0]
                        }
                    }
                })
                .finally(() => {
                    this.waitingForResponse = false;
                });;
        },
        async getRates() {
            await api()
                .get("/dpcproviders/rates")
                .then((res) => {
                    if (res) {
                        this.rates = res.data;
                    }
                });
        },
        leaveSolution(item) {
            this.stopParticipating = true;
        },
        participateSolution(item) {
            this.participation = true;
        },
        selected() {
            this.loadRate = true;
            // this.step = 2;
            this.errorText = false;
            let data = {
                solution: this.solution.id,
                rate: this.solutionRate.baseRate,
            }
            api()
                .post("/dpcproviders/rate/calculate", data)
                .then((res) => {
                    if (res) {
                        this.rateInformation = res.data;
                        this.loadRate = false;
                    }
                })
                .finally(() => {
                    this.loadRate = false;
                });
        },
        participationDialog() {
            this.participation = true;
        },
        closeDialog() {
            this.errorText = false;
            this.solutionRate = "";
            this.participation = false;
            this.step = 1;
        },
        step2() {
            if (this.solutionRate == "") {
                this.errorText = true;
            } else {
                this.step = 2;
            }
        },
        participate() {
            if (this.rate == null) {
                this.errorModal = true;
            }
            let data = {
                solutionId: this.$route.params.id,
                rate: this.rate._id,
                contracted: true,
            };
            api()
                .post("/dpcproviders/solutions", data)
                .then((res) => {
                    if (res) {
                        this.$router.push(`dpc-providers/solutions`);
                    }
                });
        },
        favorite() {
            let data = {
                entity: 'solution',
                solution: this.$route.params.id,
                favorite: !this.solution.favorite,
            };
            api()
                .post("/dpcproviders/market/favorite", data)
                .then((res) => {
                    if (res) {
                        this.solution.favorite = !this.solution.favorite;
                    }
                });
        },

    },
};
</script>
<style scoped>
.no-border {
    border-left: none !important;
}
.rates_notes {
    font-size: 14px;
    font-weight: 600;
    color: #808080;
}
.btn-footer {
    border-top: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    height: auto;
    position: fixed;
    bottom: 0;
    z-index: 100;
    background-color: white;
    width: 100% !important;
    padding-right: 15vw;
}

/deep/ .v-tab {
    text-transform: none !important;
    color: #333333 !important;
    font-weight: 600 !important;
}

/deep/ .v-tabs-slider-wrapper {
    height: 2px !important;
}

.v-tabs-slider {
    height: 40% !important;
}

.card-logo {
    min-width: 124px;
    max-width: 124px;
    min-height: 124px;
    max-height: 124px;
    overflow: hidden;
    background-size: contain;
    background-position: center;
    border-radius: 100px;
    background-repeat: no-repeat;
    border: 0.2px solid #CCCCCC;
    background-position: center;
    background-color: white;
    margin-top: -40px !important;
}

.orange-header {
    background-color: #f26621;
    height: 56px;
}

.success-text {
    color: #014D00;
}

.text--white {
    color: white !important;
}

.participate {
    width: 150px;
    color: white;
}

.v-stepper__header {
    box-shadow: none !important;
}

.v-sheet .theme--light {
    box-shadow: none !important;
}

.rate-shadow {
    box-shadow: 0px 1px 12px 3px rgb(166, 161, 161);
}

.frame-style {
    border: none !important;
}

.pdf {
    width: 100%;
    height: 600px;
}

.text-ellipsis {
    height: 45px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.desc_display_btn {
    font-size: 14px;
    color: #366CB3;
    cursor: pointer;
    padding-top: 0px;
}
</style>
    