<template>
  <div class="row row-height">
    <v-sheet class="overflow-hidden col-md-12 border-r-8">
      <div class="">
        <v-row align="center" justify="center">
          <div
            :class="
              overlay
                ? 'hideOnMoblie background-opacity col-md-9'
                : 'col-md-12 navigationWidth no-padding form-row-height '
            "
          >
            <v-row class="blue-underline mb-1">
              <v-col class="col-xl-6 col-md-6 cols-6 pt-2 pb-0" cols="6">
                <div class="col-md-12">
                  <h3 class="form-title font col-md-12 font-18">Contract Detail </h3>
                </div>
              </v-col>
              <v-col
                class="col-xl-6 pr-6 pt-6 col-md-6 cols-6 text-end"
                cols="6"
                align="end"
              >
                <v-btn class="my-1 gray-border-buttons">
                  <svg
                    class="mr-2"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 7.5H15"
                      stroke="#707070"
                      stroke-width="2"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.5 0V15"
                      stroke="#707070"
                      stroke-width="2"
                      stroke-linejoin="round"
                    />
                  </svg>

                  New</v-btn
                >
              </v-col>
            </v-row>

            <v-simple-table
              fixed-headerh
              height="330px"
              class="col-md-12 no-padding"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">CONTRACT NAME</th>
                    <th class="text-left">CLIENT(S)</th>
                    <th class="text-left">EFFECTIVE DATE</th>
                    <th class="text-left">RENEWAL DATE</th>
                    <th class="text-left">RATE GROUP</th>
                    <th class="text-center">ACTIONS</th>
                  </tr>
                </thead>
                <tbody class="text-gray">
                  <tr v-for="(item, index) in allEmployers" :key="index">
                    <td v-if="item.name">
                      <a>{{ item.name }}</a>
                    </td>
                    <td v-else><a>/</a></td>
                    <td v-html="item.clients ? item.clients : '/'"></td>
                    <td
                      v-html="item.rateStructure ? item.rateStructure : '/'"
                    ></td>
                    <td v-html="item.rateAmount ? item.rateAmount : '/'"></td>
                    <td v-html="item.rateType ? item.rateType : '/'"></td>
                    <td
                      class="
                        text-center
                        mt-3
                        row
                        no-padding
                        justify-end
                        text-gray
                        d-flex
                      "
                      style="padding-right: 110px"
                    >
                      <v-checkbox class="no-padding stay-blue"></v-checkbox>
                      Accept & Contract
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div class="row">
              <div class="row justify-end mr-10">
                <div class="col-md-3 jumbotron mb-10 font">
                  Note: When you contracted, you agreed to committ to the
                  contracted rates for 12 months. You can lower your rates, but
                  not increase them.
                </div>
              </div>
            </div>
          </div>
        </v-row>
        <v-row class="col-md-12">
          <v-col>
            <v-btn class="blue-buttons" @click="dialog1 = true">Accept & Contract</v-btn>
          </v-col>
          <b-col class="col-md-12 text-gray">
            By checking the box(es) and clicking "ACCEPT & CONTRACT," you represent that:
             (1) you have read and agree with the contract(s) listed above, and (2) you have
              the right, authority and capacity to enter into this agreement and commit to the
             contract rate(s) that you have entered, on your own behalf and on behalf of any
              organization(s) for which the agreement(s) applies.
          </b-col>
          
        </v-row>
      </div>
    </v-sheet>

    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
    <v-dialog v-model.trim="dialog1" width="600">
      <v-card>
        <v-card-title class="text-h5"> CONGRATULATIONS...! </v-card-title>
        <v-card-text>
          You have just contracted on the Apaly Health Platform. <br />
          You will be receiving a welcome package with educational materials
          that are designed to help your plan members become familiar with the
          Apaly Health solution.<br />
          In addition, we will be reaching out to gather some information about
          your TPA, so we can coordinate our provider medical claims process
          with them.
        </v-card-text>
        <v-card-actions>
          <div class="col-md-12 text-end">
            <v-btn
              class="blue-buttons text-end"
              @click="$router.push(`/dpc-providers/account/contract`)"
              >Close</v-btn
            >
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <contractPdf
      ref="contract_pdf"
      :name="customName"
      :inpatient="inpatient"
      :outpatient="outpatient"
    ></contractPdf>
  </div>
</template>
<script>
import api from "../../../../../services/api";
import successModal from "../../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../../TablesTemplate/ErrorDialog.vue";
import ContractPdf from "../../../../TablesTemplate/ContractPDF.vue";
export default {
  components: {
    successModal,
    errorModal,
    ContractPdf,
  },
  data() {
    return {
      display: true,
      dialog1: false,
      windowWidth: window.innerWidth,
      inpatient: "",
      outpatient: "",
      priceMatch: "",
      contractName: "",
      checkboxError: "",
      checkbox: "",
      outpatientPriceMatch: "",
      inpatientPriceMatch: "",
      singleConDialog: false,
      customName: "",
      tab: null,
      href: "#tab-0",
      serviceAgreement: "",
      select: {
        text: "Fee for Service - Provider Services Agreement",
        value: "providerService",
      },
      allEmployers: [
        {
          name: "Digital Direct Healthcare - Provider Services Agreement",
          clients: "Multiple",
          rateStructure: "05/02/2022",
          rateAmount: "05/02/2022",
          rateType: "General Rate Group",
        },
      ],
      items: [
        {
          text: "Form and Custom Name",
          value: 0,
        },
        {
          text: "Rates",
          value: 1,
        },
        {
          text: "Acceptance",
          value: 2,
        },
      ],
    };
  },
  computed: {
    getClient() {
      return this.$store.getters.getClientGetter;
    },
    routeParams() {
      return this.$route.params.name;
    },
    itemtab() {
      return this.tab;
    },
  },
  created() {
    if (this.windowWidth < 900) {
      this.display = false;
    } else {
      this.display = true;
    }
    //   this.getOutpatientPriceMatch();
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    viewContract(name) {
      this.singleConDialog = true;
      this.$refs.contract_pdf.openPopUp();
    },
    clearContract() {
      this.customName = "";
      this.inpatient = "";
      this.outpatient = "";
      this.tab = "tab0";
    },
    getOutpatientPriceMatch() {
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.employerAdvisor;
      let employer = JSON.parse(localStorage.getItem("employer"));
      api()
        .get(
          `/advisors/${advisorId}/employers/${
            employer._id
          }/providersPriceMatch?inpatient=${Number(
            this.inpatient
          )}&outpatient=${Number(this.outpatient)}`
        )
        .then((res) => {
          this.outpatientPriceMatch = res.data.priceMatch.outpatient;
          this.inpatientPriceMatch = res.data.priceMatch.inpatient;
        });
    },
    getInpatientPriceMatch() {
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.employerAdvisor;
      let employer = JSON.parse(localStorage.getItem("employer"));
      api()
        .get(
          `/advisors/${advisorId}/employers/${
            employer._id
          }/providersPriceMatch?inpatient=${Number(
            this.inpatient
          )}&outpatient=${Number(this.outpatient)}`
        )
        .then((res) => {
          this.inpatientPriceMatch = res.data.priceMatch.inpatient;
        });
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
    },

    backTab(tab) {
      if (tab == "tab1") {
        this.tab = "tab0";
      } else if (tab == "tab2") {
        this.tab = "tab1";
      }
    },
    nextTab(tab) {
      if (tab == "tab0") {
        this.tab = "tab1";
      } else if (tab == "tab1") {
        this.tab = "tab2";
      }
    },
    createModal() {
      this.$router.push(`/dpc-providers/account/contract`);
    },
    addContract() {
      this.dialog1 = true;
      // if (this.customName && this.outpatient) {
      //   if (this.checkbox) {
      //     let user = JSON.parse(localStorage.getItem("user"));
      //     let advisorId = user.groupPortal[0].organization.employerAdvisor;
      //     let employer = JSON.parse(localStorage.getItem("employer"));
      //     let data = {
      //       form: this.serviceAgreement,
      //       name: this.customName,
      //       employerRates: {
      //         inpatient: this.inpatient,
      //         outpatient: this.outpatient,
      //       },
      //     };
      //     api()
      //       .post(
      //         `/advisors/${advisorId}/employers/${employer._id}/contract`,
      //         data
      //       )
      //       .then((res) => {
      //         if (res) {
      //           this.dialog1 = true;
      //         }
      //       });
      //   } else {
      //     this.checkboxError = "Please check the box first ";
      //   }
      // } else {
      //   this.$refs.error_modal.openPopUp(
      //     "Contract Name and Contract Rates Are Required"
      //   );
      // }
    },
    singleContract(item) {
      this.$store.commit("setSingleContract", item);
      this.$router.push("/contracts");
    },
    onResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 900) {
        this.display = false;
      } else {
        this.display = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.v-select__selection--comma {
  color: #1976d2;
}
</style>






 