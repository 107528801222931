<template>
  <div class="white-toolbar justify-space-between d-flex">
    <div v-if="$route.path == '/tpa' || $route.path == '/tpa/home'">
      <span class="text-gray font-quicksand-medium-14">Home</span>
    </div>
    <div v-else-if="showRoutePathForFilter">
      <router-link class="font-quicksand-medium-14" to="tpa/home">
        <span   @click="$store.commit('setNavItemFilter', false)"> Home </span>
      </router-link>
      <a v-if="$route.path.includes('members')" class="font-quicksand-medium-14" @click="$store.commit('setNavItemFilter', false)">
        \ <span class="text-underline"> Members </span>
      </a>
      <span v-if="$route.path.includes('members')" class="text-gray font-quicksand-medium-14">\ Member Filters</span>
      <a v-if="$route.path.includes('invoices')" class="font-quicksand-medium-14" @click="$store.commit('setNavItemFilter', false)">
        \ <span class="text-underline"> Billing </span>
      </a>
      <span v-if="$route.path.includes('invoices')" class="text-gray font-quicksand-medium-14">\ Invoice Filter</span>
      <a v-if="$route.path.includes('locations')" class="font-quicksand-medium-14" @click="$store.commit('setNavItemFilter', false)">
        \ <span class="text-underline"> Locations </span>
      </a>
      <span v-if="$route.path.includes('locations')" class="text-gray font-quicksand-medium-14">\ Locations Filter</span>

      <a v-if="$route.path.includes('/account/users')" class="font-quicksand-medium-14" @click="$router.push('/tpa/account')">
        \ <span class="text-underline"  @click="$store.commit('setNavItemFilter', false)"> Account </span>
      </a>
      <a v-if="$route.path.includes('/account/users')" class="font-quicksand-medium-14" @click="$store.commit('setNavItemFilter', false)">
        \ <span class="text-underline"> Users </span>
      </a>
      <span v-if="$route.path.includes('/account/users')" class="text-gray font-quicksand-medium-14">\ Users Filter</span>

      <a v-if="$route.path.includes('/account/providers')" class="font-quicksand-medium-14" @click="$router.push('/tpa/account')">
        \ <span class="text-underline"  @click="$store.commit('setNavItemFilter', false)"> Account </span>
      </a>
      <a v-if="$route.path.includes('/account/providers')" class="font-quicksand-medium-14" @click="$store.commit('setNavItemFilter', false)">
        \ <span class="text-underline"> Providers </span>
      </a>
      <span v-if="$route.path.includes('/account/providers')" class="text-gray font-quicksand-medium-14">\ Providers Filter</span>
    </div>
    <div v-else-if="
        $route.path == '/tpa/members' ||
        $route.path == '/tpa/locations' ||
        $route.path == '/tpa/solutions' ||
        $route.path == '/tpa/account'">
      <router-link class="font-quicksand-medium-14" to="/tpa/home">
        <span> Home </span>
      </router-link>
      <span v-if="$route.path == '/tpa/members'" class="text-gray font-quicksand-medium-14">\ Members</span>
      <span v-if="$route.path == '/tpa/locations'" class="text-gray font-quicksand-medium-14">\ Locations</span>
      <span v-if="$route.path == '/tpa/account'" class="text-gray font-quicksand-medium-14">\ Account</span>
      <span v-if="$route.path == '/tpa/solutions'" class="text-gray font-quicksand-medium-14">\ Solutions</span>
    </div>
    <div v-else>
      <span v-for="(route, index) in $route.matched" :key="index">
        <span v-if="route && route.name">
          <router-link class="font-quicksand-medium-14" v-if="index != Object.keys($route.matched).length - 1" :to="route.path">
            <span>
              {{ route.name }}
            </span>
          </router-link>
          <span class="text-gray font-quicksand-medium-14" v-else>
            <span>
              {{ route.name }}
            </span>
          </span>
          <span class="text-gray font-quicksand-medium-14" v-if="index != Object.keys($route.matched).length - 1">
            \
          </span>
        </span>
      </span>
    </div>
    <div v-if="$route.path.includes('/tpa/clients/details') 
              || $route.path.includes('/tpa/clients/members')
              || $route.path.includes('/tpa/clients/plans')
              || $route.path.includes('/tpa/clients/solutions')
              || $route.path.includes('/tpa/clients/invoices')"
    class="text-gray font-weight-bold mr-3"
    >
    {{getClientName}}
  </div>

  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      
    };
  },

  props: {
    clientName: {
      type: String,
    },
  },
  computed: {
    ...mapGetters(["showRoutePathForFilter"]),
    params() {
      let emp = "";

      return emp;
    },
    getClientName(){
     return  this.$store.state.Tpa.clientName;
    },
    singleEmployer() {
      let emp = "";
      let name = "";
      if (localStorage.getItem("employer")) {
        emp = JSON.parse(localStorage.getItem("providerName"));
        name = emp;
      } else if (this.$route.params.name) {
        name = this.$route.params.name;
      }

      return name;
    },
    singleProvider() {
      let pro = "";
      let name = "";
      if (localStorage.getItem("provider")) {
        pro = JSON.parse(localStorage.getItem("providerName"));
        name = pro;
      } else if (this.$route.params.name) {
        name = this.$route.params.name;
      }

      return name;
    },
  },
  created() {

  },
};
</script>
<style>
.light-blue {
  color: #109cf1 !important;
}
</style>