<template>
  <div class="page-background">
    <main-header :title="'Login'" :color="'white'" ></main-header>
    <div class="page-background-alert">
      <div class="d-flex flex-column align-center justify-space-between pr-5 pl-5 " >
        <div style="margin-top: 100px;margin-bottom: 80px !important;">
          <img class="apaly-logo" src="/apaly.png" alt="">
        </div>
        <div class="d-flex flex-column align-center" style="width: 100%" >

          <form style="width: 100%">
            <div>
              <h3 class="form-title">Change Password</h3>
              <span style="color: white">Requirements:</span>
              <ul class="list-class mb-4"  >
                <li>At least 8 characters</li>
                <li>At least 1 capital letter</li>
                <li>At least 1 lowercase letter</li>
                <li> At least 1 special character - ! @ # $ % etc</li>
              </ul>
<!--              <v-list >-->
<!--                <v-list-item-->
<!--                    v-for="item in items"-->
<!--                    :title="item"-->
<!--                ></v-list-item>-->
<!--              </v-list>-->
              <v-text-field outlined label="New password" color="white" class="input-text" :type="show1 ? 'text' : 'password'"
                            @click:append="show1 = !show1" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              ></v-text-field>              <v-text-field outlined label="Password" color="white" class="input-text" :type="show2 ? 'text' : 'password'"
                            @click:append="show2 = !show2" :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              ></v-text-field>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="d-flex footer"  style="">
      <div class="col-6">
        <v-btn class="footer-btn-cancel" >Cancel</v-btn>
      </div>
      <div class="col-6">
        <v-btn class="footer-btn" >Change Password</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import mainHeader from "@/components/Sections/DPCMember/Layout/mainHeader";

export default {
  data() {
    return {
      show1:false,
      show2:false,


    };
  },
  components: {
    mainHeader,
  },
  methods: {},
  created() {},
};
</script>
<style scoped src='./../../../../assets/mobileStyle.css'></style>
<style scoped lang="scss">


.page-background {

  background-color: #001e46;
}

.page-background-alert {
  height: 100vh;
  background-color:  #16407A;
}

.apaly-logo{
  width: 162.17px;
  height: 65px;
  left: 115px;
  top: 186px;
;
}

.form-title{
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  text-align: center;
  margin-bottom: 10px;
  color: white;
}

.alert-text{
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;
  color: white;
  display: block;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 8px;
  text-align: center;
  position: absolute;
  width: 184px;
  height: 80px;
  left: 105px;
  top: 386px;
}

.footer{
  height:80px;
  position:absolute;
  bottom:0;
  width:100%;
  align-items: flex-start;
  border-top: 1px solid #C9C9C9
}

.footer-btn-cancel{
  background-color:#16407a !important;
  border-radius: 23px;
  width: 100%;
  border: 1px solid white !important;
  color: white;
  float:right
}
.footer-btn{
  background-color: white !important;
  border-radius: 23px;
  width: 100%;
  color: #16407A ;
}
.entry-title{
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
}

.entry-description{
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.above-footer-text{
  margin-top: 170px;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  margin-left: 17px;
  color: #C9C9C9;
}

.list-class{
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color:white;
}
</style>