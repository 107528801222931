<template>
  <div class="text-center">
    <v-dialog
      persistent
      v-model.trim="dialog"
      width="460"
      class="success-modal"
    >
      <v-card>
        <v-card-text class="success-modal">
          <div class="text-center">
            <img src="/error.png" width="70" height="70" alt="" />
          </div>
          <h2 class="text-center font mt-2">
            <h3 v-html="title"></h3>
          </h2>
          <h2
            class="text-center text-underline mt-10 pointer"
            @click="$emit('confirm')"
          >
            Back
          </h2>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      title: "",
      text: "",
    };
  },

  methods: {
    closePopUp() {
      this.dialog = false;
    },
    openPopUp(title, text) {
      this.title = title;
      this.text = text;
      this.dialog = true;
    },
  },
};
</script>

<style>
.bold-mail {
  color: #1976d2;
}
</style>
