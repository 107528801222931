<template>
  <div class="form-div">
    <v-card class="col-lg-8 col-xl-6 col-md-6 blue-text">
      <h2 class="text-center blue-text">
        Let’s create an email domain white list.
      </h2>
      <div class="text-gray mt-5">
        This step enables anyone who signs up using one of these email domains,
        to easily link to your Apaly Health group account.
      </div>
      <v-form v-model.trim="valid" ref="form">
        <div class="row mt-5">
          <div class="col-md-8">
            <label for="" class="text-gray"
              >Enter email domain here, and click Add</label
            >
            <v-text-field
              @keyup="fillDomainRules"
              :rules="email ? domainRules : []"
              solo
              v-model.trim="email"
            ></v-text-field>
          </div>
          <div class="col-md-4">
            <label for=""></label>
            <v-btn
              large
              class="col-md-11 select-btn ml-2 mt-6"
              @click="pushToArray"
              >Add</v-btn
            >
          </div>
        </div>
      </v-form>
      <div class="mt-5">
        <div
          class="col-md-12 no-padding mb-10"
          :class="emails.length > 8 ? 'y-scroll' : ''"
          style="overflow-x: hidden"
        >
          <label for="" class="text-gray">Email domain white list:</label>
          <div class="col-md-12">
            <v-card
              v-for="(email, index) in emails"
              :key="index"
              style="padding: 1rem"
              class="mt-2 mb-2"
            >
              <span v-if="email.domain">{{ email.domain }}</span>
              <span v-else>{{ email }}</span>
              <span class="float-right" v-if="index != 0">
                <svg
                  class="pointer"
                  @click="removeFromArray(email)"
                  width="28"
                  height="26"
                  viewBox="0 0 28 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.35547 13C1.35547 16.1826 2.65524 19.2348 4.96884 21.4853C7.28244 23.7357 10.4204 25 13.6923 25C16.9642 25 20.1021 23.7357 22.4157 21.4853C24.7293 19.2348 26.0291 16.1826 26.0291 13C26.0291 9.8174 24.7293 6.76516 22.4157 4.51472C20.1021 2.26428 16.9642 1 13.6923 1C10.4204 1 7.28244 2.26428 4.96884 4.51472C2.65524 6.76516 1.35547 9.8174 1.35547 13V13Z"
                    stroke="#D00000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.75781 17.7992L18.6273 8.19922"
                    stroke="#D00000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18.6273 17.7992L8.75781 8.19922"
                    stroke="#D00000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </v-card>
          </div>
        </div>
      </div>
      <div class="row mt-3 mb-3">
        <div class="col-md-12 row justify-space-between">
          <v-btn
            class="select-btn col-md-3 ml-3"
            @click="$router.push('/register/create-organization')"
            >Back</v-btn
          >
          <v-btn class="select-btn col-md-3" @click="next">Next</v-btn>
        </div>
      </div>
    </v-card>
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
  </div>
</template>
<script>
import api from "../../../../services/api";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../TablesTemplate/ErrorDialog.vue";

export default {
  components: { successModal, errorModal },
  data() {
    return {
      email: "",
      emails: [],
      newEmail: "",
      domains: [],
      array: [],
      page: 1,
      total: 0,
      emailsForDelete: [],
      items: 100,
      myDeleteArray: [],
      myAddArray: [],
      valid: false,
      domainRules: [
        (v) => !!v || "This field is required",
        (v) =>
          /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/.test(v) ||
          "Domain must be valid",
      ],
    };
  },
  created() {
    //  this.getEmailDomains();
    this.$store.commit(
      "sideBarTitleAndDesc",
      "“Love the life you live. Live the life you love.”"
    );
    this.$store.commit("sideBarDesc", "Bob Marley");

    if (this.$store.getters.getEmailWhiteList) {
      this.emails = this.$store.getters.getEmailWhiteList;
    }
    if (localStorage.getItem("email")) {
      if (!this.emails[0]) {
        let mail = JSON.parse(localStorage.getItem("email"));
        this.emails.unshift(mail.split("@")[1]);
      }
    }
  },
  computed: {},
  methods: {
    next() {
      this.$store.commit("setEmailWhiteList", this.emails);
      this.$store.commit("setAddDomainArray", this.myAddArray);
      this.$store.commit("setDeleteDomainArray", this.myDeleteArray);
      this.$router.push("/register/confirm-org");
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    getEmailDomains() {
      api()
        .get("/vdomains/distinct?page=" + this.page + "&limit=100")
        .catch((err) => {
          if (err) {
            this.$refs.error_modal.openPopUp(err.response.data.message);
          }
        })
        .then((res) => {
          this.domains = res.data.domains;
          this.emails = res.data.domains;

          this.items = res.data.totalRecordsInPage;
          this.total = Math.round(res.data.total / 250);
        });
    },
    fillDomainRules() {
      this.domainRules = [
        (v) => !!v || "This field is required",
        (v) =>
          /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/.test(v) ||
          "Email domain is not valid!",
      ];
    },
    pushToArray() {
      this.$refs.form.validate();
      let data = {
        domain: this.email,
      };
      if (this.valid) {
        api()
          .post("/auth/validateEmail", data)
          .catch((err) => {
            if (err) {
              this.domainRules = ["Only company email domains are allowed"];
            }
          })
          .then((res) => {
            if (res) {
              api()
                .get("/auth/isValidDomain?domain=" + this.email)
                .catch((err) => {
                  if (err) {
                    this.domainRules = [err.response.data.message];
                    exist = true;
                  }
                })
                .then((res) => {
                  if (res) {
                    this.$refs.form.validate();
                    let regex =
                      /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/;
                    let check = regex.test(this.email);
                    if (this.email && this.valid && check) {
                      this.emails.push({ domain: this.email });
                      this.myAddArray.push({ domain: this.email });
                      this.$refs.success_modal.openPopUp(
                        "Domain added successfully"
                      );
                      this.$refs.form.resetValidation();
                      this.email = "";
                    }
                  }
                });
            }
          });
      }
    },

    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
    },
    removeFromArray(email) {
      this.myDeleteArray.push(email);
      for (let i = 0; i < this.emails.length; i++) {
        if (this.emails[i] == email) {
          this.emails.splice(i, 1);
        }
      }
      this.$refs.success_modal.openPopUp("Domain was successfully deleted!");
    },
  },
};
</script>




