<template>
  <div class="">
    <div v-if="!isChildren" class="row-height">
      <Header
        :title="'Invoices'"
        :searchInput="true"
        :exportButton="true"
        @searchInput="searchInput"
        :placeholder="'Search Invoice'"
      ></Header>
      <div class="mt-3">
        <v-simple-table fixed-header height="70vh" class="no-padding tr-custom-height">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">INVOICE #</th>
                <th class="text-left">CLAIM ID</th>
                <th class="text-left">SOLUTION</th>
                <th class="text-left">CLIENT</th>
                <th class="text-left">AMOUNT</th>
                <th class="text-left">DATE</th>
                <th class="text-left">STATUS</th>
              </tr>
            </thead>
            <tbody v-if="!filterItems && items.length > 0" class="text-gray tr-custom-height">
              <tr v-for="(item, index) in items" :key="index">
                <td v-if="item.type && item.type == 'vendor'" @click="showVendor(item)">
                  <a>{{ item.invoiceNo }}</a>
                </td>
                <td v-else-if="item.type && item.type == 'DPC'" @click="showDpc(item)"><a>{{ item.invoiceNo }}</a></td>
                <td v-else>/</td>
                <td></td>
                <td></td>
                <td> {{ item.client }} </td>
                <td> {{ item.amount }} $ </td>
                <td>
                  {{ item.date | moment('MM/DD/YYYY') }}
                </td>
                <td> {{ item.status.charAt(0).toUpperCase() + item.status.slice(1) }} </td>
              </tr>
              
            </tbody>

            <tbody v-else>
              <v-col md="12">
                <v-alert border="bottom" colored-border type="primary" elevation="2" class="text-gray">
                There are no invoices that have been received for this client.
                </v-alert>
              </v-col>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>

import api from "../../../../../../services/api";

import Header from "../../../Layout/HeaderPage.vue";
export default {
  data() {
    return {
      showDetails: false,
      tab: "solution",
      dialog: false,
      items: [],
      filterItems: false,
      singleItem: null,
      typeItems: [{ text: "Categories", value: "Categories" }],
      sortItems: [{ text: "Newest", value: "Newest" }],
      sortBy: "",
      type: "",
      solutions: [],
      favorites: [],
      filterArray: [],
    };
  },
  components: {
    Header,
  },
  computed: {
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      api()
        .get("/tpas/clients/" + this.$route.params.clientId + '/invoices')
        .then((res) => {
          if (res) {
            this.items = res.data;
            this.items.type = res.data.type;
            this.filterArray = this.items;
          }
        });
    },
    invite() {},
    add() {
      // this.$router.push("/tpa/clients/billing/" + item.id + "/");
    },

    showVendor(item) {
      this.$router.push(`/tpa/clients/${this.$route.params.clientId}/invoices/vendor-invoice/${item.id}`);
    },
  showDpc(item){
    this.$router.push(`/tpa/clients/${this.$route.params.clientId}/invoices/dpc-invoice/${item.id}`);
    },
  
    searchInput(val) {
      this.items = this.filterArray.filter((el) => {
        return el.entity?.toString().toLowerCase().includes(val.toLowerCase()) ||
            el.invoiceNo?.toString().toLowerCase().includes(val.toLowerCase());
      });
      this.filterItems = (this.items.length < 1)
    },
  },
};
</script>
<style>
</style>