import VendorsTemplate from '../views/Vendors.vue'
import RouterView from '../components/Sections/Vendors/RouterView.vue'
import Home from '../components/Sections/Vendors/Home.vue'
import Vendors from '../components/Sections/Vendors/Vendors/Vendors.vue'
import Providers from '../components/Sections/Vendors/Providers/Providers.vue'
import Invoices from '../components/Sections/Vendors/Invoices/Invoices.vue'
import AddInvoice from '../components/Sections/Vendors/Invoices/AddNew.vue'

import Solutions from '../components/Sections/Vendors/Solutions/NewSolutions.vue'
import AddSolution from '../components/Sections/Vendors/Solutions/AddNew.vue'
import NewSolutionDetails from '../components/Sections/Vendors/Solutions/SolutionsDetails.vue'
import NewVendorDetails from '../components/Sections/Vendors/Solutions/PointSolutionVendorDetails.vue'
import ClinicianDetails from '../components/Sections/Vendors/Solutions/ClinicianDetails.vue'
import ClinicAndOrganizationDetails from '../components/Sections/Vendors/Solutions/ClinicAndOrganizationDetails.vue'
import AllSolutions from '../components/Sections/Vendors/Solutions/AllSolutions.vue'


import UploadInvoice from '../components/Sections/Vendors/Invoices/UploadInvoice.vue'
import Payment from '../components/Sections/Vendors/Invoices/PaymentOptions.vue'
import InvoiceDetails from '../components/Sections/Vendors/Invoices/NewDetails.vue'
import Account from '../components/Sections/Vendors/Account/AccountLayout.vue'
import Contract from '../components/Sections/Vendors/Account/Contract/Contract.vue'
import AddNew from '../components/Sections/Vendors/Account/Contract/AddNew.vue'
import Bank from '../components/Sections/Vendors/Account/BankInfo/BankInfo.vue'
import Users from '../components/Sections/Vendors/Account/Users/Users.vue'
import AddUser from '../components/Sections/Vendors/Account/Users/AddUser.vue'
import SingleUser from '../components/Sections/Vendors/Account/Users/SingleUser.vue'
import Settings from '../components/Sections/Vendors/Account/Settings/Settings.vue'
import Members from '../components/Sections/Vendors/Members/Members.vue'
import AddMember from '../components/Sections/Vendors/Members/AddNew.vue'
import Member from '../components/Sections/Vendors/Members/Member.vue'
import Clients from '../components/Sections/Vendors/Clients/Clients.vue'
import AddClient from '../components/Sections/Vendors/Clients/AddNew.vue'
import NewClient from '../components/Sections/Vendors/Clients/NewClient.vue'
import Client from '../components/Sections/Vendors/Clients/Client.vue'
import NewClientDetails from '../components/Sections/Vendors/Clients/ClientDetails.vue'


function routeGuard(to, from, next) {
    let user = JSON.parse(localStorage.getItem('user'));
    if(!user){
        next('/login');
    }
    else if ( user.type != 'vendor' || !$cookies.get('token')){
      next('/login');
    }else{
        next();
    }
      
}

const router = [
    {
        path:'/vendors',
        name:'',
        beforeEnter: routeGuard,
        redirect: () => {
            return { path: '/vendors/home'} 
        },
     },
     
  
    {
        component:RouterView,
        path:'/vendors',
        name:'',
        beforeEnter: routeGuard,
        redirect: () => {
            return { path: '/vendors'} 
        },
        children:[
            {
                path:'/vendors',
                name:'Home',
                component:VendorsTemplate,
                beforeEnter: routeGuard,
                children:[
                    {
                        path:'home',
                        name:'Home',
                        component:Home,
                    },
                    {
                        path:'vendors',
                        name:'Vendors',
                        component:Vendors,
                    },
                    {
                        path:'providers',
                        name:'Providers',
                        component:Providers,
                    },
                    {
                        path:'invoices',
                        component:RouterView,
                        redirect: () => {
                            return { path: '/vendors/invoices'} 
                        },
                        children:[
                         {
                               path:'/vendors/invoices',
                               name:'Invoices',
                               component:Invoices,
                               children:[
                                {
                                    path:'details/:id',
                                    name:'Invoice Detail',
                                    component:InvoiceDetails,
                                    meta:{
                                        isChildren:true
                                    }
                                },
                                {
                                    path:'add-new/:type',
                                    name:'Create an Invoice',
                                    component:AddInvoice,
                                    meta:{
                                        isChildren:true
                                    }
                                },
                                {
                                    path:'upload',
                                    name:'Upload an Invoice',
                                    component:UploadInvoice,
                                    meta:{
                                        isChildren:true
                                    }
                                },
                                {
                                    path:'payment',
                                    name:'Payment Options',
                                    component:Payment,
                                    meta:{
                                        isChildren:true
                                    }
                                },
                            ]
                         }
                        ]
                    }, 
                    {
                        path:'solutions',
                        component:RouterView,
                        redirect: () => {
                            return { path: '/vendors/solutions'} 
                        },
                        children:[
                         {
                               path:'/vendors/solutions',
                               name:'Solutions',
                               component:Solutions,
                               children:[    
                                {
                                    path:'add-new',
                                    name:'Create Solution',
                                    component:AddSolution,
                                    meta:{
                                        isChildren:true
                                    }
                                },
                                {
                                    path:'virtual-network/:id',
                                    name:'Solution Details',
                                    component:NewSolutionDetails,
                                    meta:{
                                        isChildren:true
                                    }
                                },
                                {
                                    path:'point-solution-vendor/:id',
                                    name:'Vendor Details',
                                    component:NewVendorDetails,
                                    meta:{
                                        isChildren:true
                                    }
                                },
                                {
                                    path:'clinics-organization/:id',
                                    name:'Clinic And Organization Details',
                                    component:ClinicAndOrganizationDetails,
                                    meta:{
                                        isChildren:true
                                    }
                                },
                                {
                                    path:'clinician/:id',
                                    name:'Clinician Details',
                                    component:ClinicianDetails,
                                    meta:{
                                        isChildren:true
                                    }
                                },
                                {
                                    path: "all/:category",
                                    name: "All Soluttions",
                                    component: AllSolutions,
                                    meta: {
                                      isChildren: true,
                                    },
                                  },
                      
                            ]
                         }
                        ]
                    }, 
                    
                    {
                        path:'members',
                        component:RouterView,
                        redirect: () => {
                            return { path: '/vendors/members'} 
                        },
                        children:[
                         {
                            path:'/vendors/members',
                            name:'Members',
                            component:Members,
                            children:[
                                {
                                    path:'add-new',
                                    name:'New Member',
                                    component:AddMember,
                                    meta:{
                                        isChildren:true
                                    }
                                },
                                {
                                    path:'member',
                                    name:'Member Detail',
                                    component:Member,
                                    meta:{
                                        isChildren:true
                                    }
                                },

                            ]
                         }
                        ]
                    }, 
                    {
                        path:'clients',
                        component:RouterView,
                        redirect: () => {
                            return { path: '/vendors/clients'} 
                        },
                        children:[
                         {
                            path:'/vendors/clients',
                            name:'Clients',
                            component:Clients,
                            children:[
                                {
                                    path:'add-new',
                                    name:'New Client',
                                    component:NewClient,
                                    meta:{
                                        isChildren:true
                                    }
                                },
                                {
                                    path:'client/:id',
                                    name:'Client Details',
                                    component:NewClientDetails,
                                    meta:{
                                        isChildren:true
                                    }
                                },

                            ]
                         }
                        ]
                    },   
                    
                    {
                        path:'account',
                        name:'Account',
                        component:RouterView,
                        redirect: () => {
                            return { path: '/vendors/account'} 
                        },
                        children:[
                            {
                                path:'/vendors/account',
                                name:'Account',
                                component:Account,
                            },
                            {
                                path:'bank',
                                name:'Bank Info',
                                component:Bank,
                            },
                            {
                                path:'/vendors/account/contract',
                                name:'',
                                component:RouterView,
                                redirect: () => {
                                    return { path: '/vendors/account/contract'} 
                                },
                                children:[
                                    {
                                        path:'/vendors/account/contract',
                                        name:'Contracts',
                                        component:Contract,
                                        children:[
                                            {
                                                path:'add-new',
                                                name:'Add New',
                                                component:AddNew,
                                                meta:{
                                                    isChildren:true
                                                }
                                            },
                                          
                                        ]
                                    },
                                ]
                            },
                            {
                                path:'/vendors/account/users',
                                name:'',
                                component:RouterView,
                                redirect: () => {
                                    return { path: '/vendors/account/users'} 
                                },
                                children:[
                                    {
                                        path:'/vendors/account/users',
                                        name:'Users',
                                        component:Users,
                                        children:[
                                            {
                                                path:'add-new',
                                                name:'User New',
                                                component:AddUser,
                                                meta:{
                                                    isChildren:true
                                                }
                                            },
                                            {
                                                path:'user',
                                                name:'User Detail',
                                                component:SingleUser,
                                                meta:{
                                                    isChildren:true
                                                }
                                            },
                                        ]
                                    },
                                ]
                            },
                            {
                                path:'settings',
                                name:'Settings',
                                component:Settings,
                            },
                        ]
                    },
                   
                ]
            }
        ]
    },

]

export default router;