<template>
    <v-row class="padding">
         <successModal  ref="success_modal" v-on:confirm="closeSuccessModal"></successModal>
        <div class=" col-md-12">
           <h3  class="font">
             Invite Advisor
          </h3>
         
        </div>
        <v-form class="row"  ref="form" v-model.trim="valid" >
           <div class="col-md-6 ">
                <div class="col-md-12">
                    <label class="font text-gray" for="">First Name</label>
                    <v-text-field
                        required
                        v-model.trim="firstName"
                        :rules="requiredRules"
                        solo
                        ></v-text-field>
                </div>
                <div class="col-md-12">
                    <label class="font text-gray" for="">Last Name</label>
                        <v-text-field
                        required
                        v-model.trim="lastName"
                        :rules="requiredRules"
                        solo
                        ></v-text-field>
                </div>
                 <div class="col-md-12">
                    <label class="font text-gray" for="">Email</label>
                        <v-text-field
                        required
                        v-model.trim="email"
                        solo
                        :rules="emailRules"
                        ></v-text-field>
                </div>
                 <div class="col-md-12">
                    <label class="font text-gray" for="">Phone</label>
                        <v-text-field
                        required
                        solo
                        v-model.trim="phone"
                        :rules="phoneRules"
                        ></v-text-field>
                </div>
         
           </div>
            <div class="col-md-6  ">
                <div class="col-md-12">
                    <label class="font text-gray" for="">Company Name</label>
                    <v-text-field
                        required
                        solo
                        v-model.trim="companyName"
                        :rules="requiredRules"
                        ></v-text-field>
                </div>
                <div class="col-md-12">
                    <label class="font text-gray" for="">Title</label>
                        <v-text-field
                        required
                        solo
                        :rules="requiredRules"
                        v-model.trim="title"
                        ></v-text-field>
                </div>
                     <div class="row no-padding">
                <div class="col-md-6">
                   <label class="font text-gray" for="">City</label>
                        <v-text-field
                        required
                        solo
                        :rules="requiredRules"
                        v-model.trim="city"
                        ></v-text-field>
                </div>
                <div class="col-md-3">
                    <label class="font text-gray" for="">State</label>
                    <v-select
                            class="text-gray"
                            :items="states"
                            label=""
                            item-text="name" 
                            item-value="name"
                            solo
                            v-model.trim="state"
                            :rules="requiredRules"
                     ></v-select>
                </div>
                <div class="col-md-3">
                    <label class="font text-gray" for="">Zip </label>
                        <v-text-field
                        required
                        v-model.trim="zip"
                        :rules="zipRules"
                        type="number" 
                        ></v-text-field>
                </div>
               </div>
           </div>
            <div class="col-md-12 d-flex align-end flex-column">
                <div>
                    <v-btn class="blue-buttons mr-6" @click="inviteAdvisor">
                        Invite Advisor
                    </v-btn>
                 </div>
            </div>
        </v-form>
        <errorModal  ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
 </v-row>
</template>
<script>

import successModal from '../../../../TablesTemplate/SuccessDialog.vue'
import errorModal from '../../../../TablesTemplate/ErrorDialog.vue'
import api from '../../../../../services/api'
export default{
    components:{successModal,errorModal},
    data(){
       return{
           firstName:'',
           lastName:'',
           email:'',
           phone:'',
           companyName:'',
           title:'',
           city:'',
           state:'',
           zip:'',
           states:[],
           zipRules:[
              v => ( v && v.length == 5 ) || " Zip Code should be five digits long",
           ],
           requiredRules: [
                v => !!v || 'This field is required',
           ],
             emailRules: [
                v => !!v || 'This field is required',
                v => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || 'E-mail must be valid',
            ],
           numberRules: [
                v => Number.isInteger(v) || 'The value must be an integer number',
                v => !!v || 'This field is required',
                
            ],
           phoneRules:[
                (v) => /^[0-9\ \+\-\/]+$/.test(v) || "Phone number must be in a valid format (XXX XXX XXXX)",
            ],
            
           valid:false,
           errorPhoneUpdate:false,
       }
    },
    computed:{
        getClient(){
            return this.$store.getters.getClientGetter
        },
        routeParams(){
           return this.$route.params.name
        },
    
    },
    created(){
        this.getStates()
    },
    methods:{
      getStates(){
          api().get('/api/states').then((res)=>{
              res.data.forEach((el)=>{
                  if(el.active){
                      this.states.push(el);
                  }
              })
          })
        },
    closeErrorModal(){
        this.$refs.error_modal.closePopUp();
    },
      closeSuccessModal(){
          this.$router.push(`/employer/advisors`)

      },
      inviteAdvisor(){
           let data = {
               advisors:[
                  {
                       'firstName' : this.firstName,
                        'lastName' :this.lastName,
                        'email' :this.email,
                        'phone' :this.phone,
                        'companyName' :this.companyName,
                        'title' :this.title,
                        'city' :this.city,
                        'state' :this.state,
                        'zip' :this.zip,
                  }
               ]
           }
         
           this.$refs.form.validate()
           if(this.valid){
            let user = JSON.parse(localStorage.getItem('user'));
            let employerId = user.groupPortal[0].organization.employer
        
           api().post(`/employers/${employerId}/inviteAdvisors`,data).catch((err)=>{
               
              if(err){
                this.$refs.error_modal.openPopUp(err.response.data.message)
              }
          }).then((res)=>{
              if(res){
                  let text = 'Email sent sucessfully'
                  this.$refs.success_modal.openPopUp(text);
              }
               
           })
            //
           }
      },
       closeErrorModal(){
         this.$refs.error_modal.closePopUp();
      },
       preventNumericPhoneUpdate($event) {
            let regex = /[A-Za-z]/;
            let regexSpecialChar = /[$&+,:;=?@#|'<>.^*()%!-/]/;
            let key = $event.keyCode;
            if (key === 45 || key === 43 || regex.test($event.key) || regexSpecialChar.test($event.key)) {
                this.errorPhoneUpdate = true;
                $event.preventDefault();
            }else{
               
                this.errorPhoneUpdate = false;
            }
       },

    }
}
</script>
<style >

</style>
 

