<template>
  <div class="">
    
    <v-sheet
      v-if="!isChildren"
      class="col-md-12 pa-0"
      style="border-radius: 8px"
    >
      <div class="align-center pb-3 pr-0 pl-0">
        <div
          class="d-flex flex-column flex-md-row text-start pa-2"
          style="width: 100%"
        >
          <div class="d-flex mr-0">
            <div
              :class="{ active: tab == 'all_solutions' }"
              @click="changeTab('all_solutions')"
              class="content-header-menu-button mr-3 ml-0 ml-md-5 text-gray font-weight-bold font-14 cursor-pointer"
            >
              All Solutions
            </div>
            <div class="mr-3 text-gray">|</div>
            <div
              @click="changeTab('my_solutions')"
              class="content-header-menu-button text-gray font-weight-bold font-14 cursor-pointer"
              :class="{ active: tab == 'my_solutions' }"
            >
              My Solutions
            </div>
          </div>
        </div>
      </div>
      <!-- ALL Solutions -->
      <div v-if="tab == 'all_solutions'">
        <div
          class="d-flex flex-column flex-md-row align-center blue-underline mt-2"
          style="width: 100%"
        >
          <div v-if="tab == 'all_solutions'" class="mr-0">
            <div class="d-flex mr-1">
              <div class="pa-2">
                <img
                  src="/newapalylogoonlyblue.png"
                  width="95"
                  height="120"
                  class="img-mobile"
                />
              </div>
              <div class="d-flex flex-column pa-2 mt-5">
                <span class="font-36">Find your specialist</span>
                <span class="font-20"
                  >Get connected with them and start your high performance
                  healthcare</span
                >
              </div>
            </div>

            <div class="col-12 pt-0">
              <span
                class="col-12 col-lg-12 col-xl-12 col-md-12 d-md-flex pa-0 pt-0 mt-0"
              >
                <span class="pa-1 col-5">
                  <v-text-field
                    prepend-inner-icon="mdi-magnify"
                    v-model="searchQuery"
                    class="mr-3"
                    light
                    dense
                    outlined
                    hide-details
                    placeholder="Search by Name or Specialty"
                  ></v-text-field>
                </span>
                <span class="pa-1 d-flex"
                  ><v-text-field
                    prepend-inner-icon="mdi-map-marker"
                    v-model="zipOrState"
                    @keyup.enter="searchByZip"
                    class="mr-3"
                    light
                    dense
                    outlined
                    hide-details
                    placeholder="Search by Zip Code"
                  ></v-text-field>
                  <v-btn depressed color="#0069F3" class="text-white" @click="searchByZip()">
                    <v-icon color="white" > mdi-magnify </v-icon>
                  </v-btn>
                </span>
              </span>
            </div>
          </div>
        </div>
      
        <div class="col-md-12 col-lg-12 col-xl-12 col-8 pb-0 mb-0 d-flex">
          <span class="col-8 text-start">
            <v-sheet max-width="500">
              <v-slide-group show-arrows>
                <v-slide-item
                  v-for="(category, categoryIndex) in selectedCategories"
                  :key="'category-' + categoryIndex"
                >
                  <v-chip closable @click="removeChip(category, selectedCategories)" class="mr-1">
                    {{ category }}
                    <v-icon right> close </v-icon>
                  </v-chip>
                </v-slide-item>
                <v-slide-item
                v-for="(crendential, credentialIndex) in selectedCredentials"
                :key="'crendential-' + credentialIndex"
              >
                <v-chip closable @click="removeChip(crendential,selectedCredentials)" class="mr-1">
                  {{ crendential }}
                  <v-icon right> close </v-icon>
                </v-chip>
              </v-slide-item>
              <v-slide-item
              v-for="(gender, genderIndex) in selectedGenders"
              :key="'gender-' + genderIndex"
            >
              <v-chip closable @click="removeChip(gender,selectedGenders)" class="mr-1">
                {{ gender == 'F' ? 'Female' : 'Male' }}
                <v-icon right> close </v-icon>
              </v-chip>
            </v-slide-item>
            <v-slide-item
            v-for="(group, groupIndex) in selectedGroupAndOrganizations"
            :key="'group-' + groupIndex"
          >
            <v-chip closable @click="removeChip(group,selectedGroupAndOrganizations)" class="mr-1">
              {{ group }}
              <v-icon right> close </v-icon>
            </v-chip>
          </v-slide-item>
          <v-slide-item
            v-for="(speciality, specialityIndex) in selectedSpecialities"
            :key="'speciality-' + specialityIndex"
          >
            <v-chip closable @click="removeChip(speciality,selectedSpecialities)" class="mr-1">
              {{ speciality }}
              <v-icon right> close </v-icon>
            </v-chip>
          </v-slide-item>
              </v-slide-group>
            </v-sheet>
          </span>

          <span class="col-4 text-end">
            <v-btn
              @click.stop="drawer = !drawer"
              color="primary"
              elevation="3"
              outlined
            >
              <v-icon right dark class="mr-1 ml-0"> mdi-filter </v-icon>
              Filter solutions
            </v-btn>
          </span>
        </div>
        <v-navigation-drawer
       class="mobile-drawer"
        :value="drawer"
        temporary
        touchless
        :right="!$vuetify.rtl"
        width="350"
        app
      >
        <v-card height="100%">
          <div class="drawer-header d-flex align-center mb-4 mt-0">
            <span class="font-20">Filter solutions</span>
            <v-btn icon small @click="drawer = !drawer">
              <v-icon right color="white" class="mr-1 ml-0"> mdi-close </v-icon>
            </v-btn>
          </div>
          <v-card-text class="pt-5 content-height" style="overflow:scroll">
            <v-expansion-panels
            accordion
            multiple
            v-model.trim="panel"
            class="no-shadow no-padding user-expansion-panels"
          >
            <v-expansion-panel class="no-shadow no-padding">
              <v-expansion-panel-header
                class="font-18 font-weight-black change-padding-filter filter-items-font"
                >Type</v-expansion-panel-header
              >
              <v-expansion-panel-content class="no-padding content-filter">
                <v-checkbox
                class="checkbox-label-size pa-0"
                hide-details
                  v-for="category in categories"
                  :key="category.value"
                  v-model="selectedCategories"
                  :label="category.label"
                  :value="category.value"
                >
                </v-checkbox>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="no-shadow no-padding">
              <v-expansion-panel-header
                class="font-18 font-weight-black change-padding-filter filter-items-font"
                >Provider</v-expansion-panel-header
              >
              <v-expansion-panel-content class="no-padding content-filter">
                <h4 class="text-start mb-2">Credentials</h4>
                <div class="pa-0 ma-0 mb-3 scrollable-creds">
                  <v-checkbox
                  class="checkbox-label-size pa-0"
                  hide-details
                    v-for="(credentials, i) in providerCredentials"
                    :key="i"
                    v-model="selectedCredentials"
                    :label="credentials"
                    :value="credentials"
                  >
                  </v-checkbox>
                </div>
                <v-divider horizontal> </v-divider>
                <h4 class="text-start mb-2">Gender</h4>
                <div class="d-flex justify-space-between pb-0 mb-0">
                  <p class="mb-0">Female</p>
                  <v-switch  lass="mb-0" v-model="selectedGenders" value="F"> </v-switch>
                </div>
                <div class="d-flex justify-space-between">
                  <p>Male</p>
                  <v-switch  v-model="selectedGenders" value="M"> </v-switch>
                </div>
                <h4 class="text-start mb-2">Specialty</h4>
                <div class="pa-0 ma-0 mb-3 scrollable-creds">
                  <v-checkbox
                  class="checkbox-label-size pa-0"
                  hide-details
                    v-for="(speciality, ind) in specialities"
                    :key="ind"
                    v-model="selectedSpecialities"
                    :label="speciality"
                    :value="speciality"
                  >
                  </v-checkbox>
                </div>
                <v-divider horizontal> </v-divider>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="no-shadow no-padding">
              <v-expansion-panel-header
                class="font-18 font-weight-black change-padding-filter filter-items-font"
                >Group or Organization</v-expansion-panel-header
              >
              <v-expansion-panel-content class="no-padding content-filter">
                <div class="pa-0 ma-0 mb-3 scrollable-creds">
                  <v-checkbox
                  class="checkbox-label-size pa-0"
                  hide-details
                    v-for="(grAndOrg, indx) in groupAndOrganizations"
                    :key="indx"
                    v-model="selectedGroupAndOrganizations"
                    :label="grAndOrg"
                    :value="grAndOrg"
                  >
                  </v-checkbox>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          </v-card-text>
          <v-list-item>
          
            <v-list-item-content class="justify-center">
              <v-list-item-title class="d-flex justify-center">
                <v-btn class="mr-5" @click="clearSearch()"> Clear All </v-btn>
                <v-btn color="primary" @click="searchResults()"> View results </v-btn>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-navigation-drawer>
        <v-dialog v-model="searchLoader" width="200" height="200">
          <v-card height="200" class="justify-center text-center">
            <v-progress-circular
              indeterminate
              :size="30"
              color="primary"
              class="text-center mt-12 ml-3 mb-5"
            ></v-progress-circular>
            <br />
            <h4>Searching...</h4>
          </v-card>
        </v-dialog>
        <div class="row-height mt-0">
          <v-progress-circular
            v-if="loader"
            indeterminate
            :size="50"
            color="primary"
            class="text-center mt-5"
          ></v-progress-circular>
          <div v-if="!noResultsFromSearch">
            <div
              v-for="(item, categoryI) in filteredCategories"
              :key="categoryI"
              class="mt-0"
            >
              <v-card-title
                class="font-24 font-weight-400 mb-0 mt-0 pt-0 pb-0"
              >
                {{ item.category }}
                <span class="pl-3 subtitle-link">
                  <small>
                    <router-link class="font-14 font-weight-600"
                      :to="{
                        path: `/vendors/solutions/all/${item.category}`,
                      }"
                    >
                      View all ({{ item.items.length }})
                    </router-link>
                  </small>
                </span>
              </v-card-title>
              <v-slide-group
                class="pa-4 pt-0 pb-0"
                active-class="success"
                show-arrows
              >
                <v-slide-item
                  v-for="(card, itemIndex) in item.items"
                  :key="itemIndex"
                >
                  <v-card
                    outlined
                    color="transparent"
                    height="210px"
                    width="180px"
                    class="mr-0 mb-2 mt-2 cursor-pointer box-hover"
                  >
                    <div
                      class="pl-1 pr-1 pb-0"
                      @click="goToDetails(card, card.entity)"
                    >
                      <div
                        class="justify-start mb-1 mt-1 ml-0 text-start"
                        style="overflow: hidden"
                      >
                        <img
                          v-if="card.entity == 'solution'"
                          :src="
                            card.logo ? card.logo : '/tpa/virtualNetwork.svg'
                          "
                          class="pointer mt-3 mb-0"
                          height="65"
                        />
                        <img
                          v-if="card.entity == 'location'"
                          :src="
                            card.logo
                              ? card.logo
                              : '/tpa/clinicsAndOrganizations.svg'
                          "
                          class="pointer mt-3 mb-0"
                          height="65"
                        />
                        <img
                          v-if="card.entity == 'vendor'"
                          :src="
                            card.logo
                              ? card.logo
                              : '/tpa/pointSolutionVendor.svg'
                          "
                          class="pointer mt-3 mb-0"
                          height="65"
                        />
                        <img
                          v-if="card.entity == 'provider'"
                          :src="card.logo ? card.logo : '/tpa/clinicians.svg'"
                          class="pointer mt-3 mb-0"
                          height="65"
                        />
                      </div>
                      <div class="text-start mt-0 text-ellipsis">
                        <p class="font-14 font-weight-600 mb-1 mt-0" style="color: #333333">
                          {{ card.name }}
                        </p>
                      </div>
                      <div class="text-start text-ellipsis">
                        <p class="font-12 font-weight-300 mb-1" style="color: #333333">
                          {{ card.address }}, {{ card.city }}, {{ card.state }},
                          {{ card.zipCode }}
                        </p>
                      </div>
                      <div class="justify-start">
                        <v-chip
                          v-if="card.entity == 'solution'"
                          class="pa-2"
                          color="#AAC9F2"
                          text-color="#333333"
                          small
                        >
                          Virtual Network
                        </v-chip>
                        <v-chip
                          v-if="card.entity == 'vendor'"
                          class="pa-2"
                          color="#FFE2CC"
                          text-color="#333333"
                          small
                        >
                          Point Solution Vendor
                        </v-chip>
                        <v-chip
                          v-if="card.entity == 'location'"
                          class="pa-2"
                          color="#FFF4BF"
                          text-color="#333333"
                          small
                        >
                          Clinic & Organization
                        </v-chip>
                        <v-chip
                          v-if="card.entity == 'provider'"
                          class="pa-2"
                          color="#CDFFCC"
                          text-color="#333333"
                          small
                        >
                          Clinician
                        </v-chip>
                      </div>
                    </div>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
              <v-divider horizontal class="mb-2"></v-divider>
            </div>
          </div>
          <div v-else>
            <v-alert
              border="bottom"
              colored-border
              color="primary"
              elevation="2"
              class="col-2 text-gray mt-2 ml-2"
            >
              No results found! <a @click="clearSearch()" class="text-underline">Reset</a>
            </v-alert>
          </div>
        </div>
      </div>

      <div v-else class="row-height">
        <div class="mt-0 mb-1">
          <div class="pl-4 pb-2 font-24">My Solutions</div>
          <div v-if="filteredContracted.length >= 1">
            <div v-for="(item, index) in filteredContracted" :key="index">
              <!-- <v-card-title class="text-gray font-weight-bold mb-0 mt-0 pt-0 pb-0">
                {{ item.category }}
              </v-card-title> -->
              <v-slide-group
                class="pa-4 pt-0 pb-0"
                active-class="success"
                show-arrows
              >
                <v-slide-item
                  v-for="(card, index1) in contractedItems(item.items)"
                  :key="index1"
                >
                  <v-card
                    outlined
                    color="transparent"
                    height="210px"
                    width="180px"
                    class="mr-0 mb-0 mt-0 cursor-pointer box-hover"
                  >
                    <div
                      class="pl-1 pr-1 pb-0"
                      @click="goToDetails(card, card.entity)"
                    >
                      <div class="text-start justify-start mb-1 mt-1 ml-0">
                        <img
                          v-if="card.entity == 'solution'"
                          :src="
                            card.logo ? card.logo : '/tpa/virtualNetwork.svg'
                          "
                          class="pointer mt-3 mb-0"
                          :width="card.logo ? '170' : 'fit-content'"
                          height="65"
                        />
                        <img
                          v-if="card.entity == 'location'"
                          :src="
                            card.logo
                              ? card.logo
                              : '/tpa/clinicsAndOrganizations.svg'
                          "
                          class="pointer mt-3 mb-0"
                          :width="card.logo ? '170' : 'fit-content'"
                          height="65"
                        />
                        <img
                          v-if="card.entity == 'vendor'"
                          :src="
                            card.logo
                              ? card.logo
                              : '/tpa/pointSolutionVendor.svg'
                          "
                          class="pointer mt-3 mb-0"
                          :width="card.logo ? '170' : 'fit-content'"
                          height="65"
                        />
                        <img
                          v-if="card.entity == 'provider'"
                          :src="card.logo ? card.logo : '/tpa/clinicians.svg'"
                          class="pointer mt-3 mb-0"
                          width="fit-content"
                          height="65"
                        />
                      </div>
                      <span class="text-start mt-0 text-ellipsis">
                        <p class="font-14 font-weight-500 mb-1 mt-0">
                          {{ card.name }}
                        </p>
                      </span>
                      <span class="text-start text-ellipsis">
                        <p class="font-12 mb-1 font-weight-400">
                          {{ card.address }}
                        </p>
                      </span>
                      <div class="justify-start">
                        <v-chip
                          v-if="card.entity == 'solution'"
                          class="pa-2"
                          color="#AAC9F2"
                          text-color="#333333"
                          small
                        >
                          Virtual Network
                        </v-chip>
                        <v-chip
                          v-if="card.entity == 'vendor'"
                          class="pa-2"
                          color="#FFE2CC"
                          text-color="#333333"
                          small
                        >
                          Point Solution Vendor
                        </v-chip>
                        <v-chip
                          v-if="card.entity == 'location'"
                          class="pa-2"
                          color="#FFF4BF"
                          text-color="#333333"
                          small
                        >
                          Clinic & Organization
                        </v-chip>
                        <v-chip
                          v-if="card.entity == 'provider'"
                          class="pa-2"
                          color="#CDFFCC"
                          text-color="#333333"
                          small
                        >
                          Clinician
                        </v-chip>
                      </div>
                    </div>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </div>
          </div>
          <div v-else  class="col-12">
            <v-alert
              border="bottom"
              colored-border
              color="primary"
              elevation="2"
              class="col-md-4 col-10 text-gray mt-2 ml-2"
            >
              You are not paricipating in any solution!
            </v-alert>
          </div>
        </div>
        <v-divider horizontal> </v-divider>
      
        <div >
          <div class="pa-4 mt-0 mb-1 d-flex">
            <div class="font-24">
              My Favorites <img src="/svg/filled-heart.svg" height="18px" />
            </div>
          </div>
          <div v-if="filteredFavorites.length < 1" class="col-12">
            <v-alert
              border="bottom"
              colored-border
              color="primary"
              elevation="2"
              class="col-md-4 col-10 text-gray mt-2 ml-2"
            >
              There are no solutions marked as favorites!
            </v-alert>
          </div>
          <div v-else>   
             <div v-for="(item, index) in filteredFavorites" :key="index">
            <v-card-title
              class="text-gray font-weight-bold mb-0 mt-0 pt-0 pb-0"
            >
              {{ item.category }}
            </v-card-title>
            <v-slide-group
              class="pa-4 pt-0 pb-0"
              active-class="success"
              show-arrows
            >
              <v-slide-item
                v-for="(card, index1) in favoriteItems(item.items)"
                :key="index1"
              >
                <v-card
                  outlined
                  color="transparent"
                  height="215px"
                  width="180px"
                  class="mr-0 mb-0 mt-0 cursor-pointer box-hover"
                >
                  <div
                    class="pl-1 pr-1 pb-0"
                    @click="goToDetails(card, card.entity)"
                  >
                    <div class="text-start justify-start mb-1 mt-1 ml-0">
                      <img
                        v-if="card.entity == 'solution'"
                        :src="card.logo ? card.logo : '/tpa/virtualNetwork.svg'"
                        class="pointer mt-3 mb-0"
                        :width="card.logo ? '170' : 'fit-content'"
                        height="65"
                      />
                      <img
                        v-if="card.entity == 'location'"
                        :src="
                          card.logo
                            ? card.logo
                            : '/tpa/clinicsAndOrganizations.svg'
                        "
                        class="pointer mt-3 mb-0"
                        :width="card.logo ? '170' : 'fit-content'"
                        height="65"
                      />
                      <img
                        v-if="card.entity == 'vendor'"
                        :src="
                          card.logo ? card.logo : '/tpa/pointSolutionVendor.svg'
                        "
                        class="pointer mt-3 mb-0"
                        :width="card.logo ? '170' : 'fit-content'"
                        height="65"
                      />
                      <img
                        v-if="card.entity == 'provider'"
                        :src="card.logo ? card.logo : '/tpa/clinicians.svg'"
                        class="pointer mt-3 mb-0"
                        width="fit-content"
                        height="65"
                      />
                    </div>
                    <span class="text-start mt-0 text-ellipsis">
                      <p class="font-14 font-weight-500 mb-1 mt-0">
                        {{ card.name }}
                      </p>
                    </span>
                    <span class="text-start text-ellipsis">
                      <p class="font-12 font-weight-400 mb-1 text-gray">
                        {{ card.address }}
                      </p>
                    </span>
                    <div class="justify-start">
                      <v-chip
                        v-if="card.entity == 'solution'"
                        class="pa-2"
                        color="#AAC9F2"
                        text-color="#333333"
                        small
                      >
                        Virtual Network
                      </v-chip>
                      <v-chip
                        v-if="card.entity == 'vendor'"
                        class="pa-2"
                        color="#FFE2CC"
                        text-color="#333333"
                        small
                      >
                        Point Solution Vendor
                      </v-chip>
                      <v-chip
                        v-if="card.entity == 'location'"
                        class="pa-2"
                        color="#FFF4BF"
                        text-color="#333333"
                        small
                      >
                        Clinic & Organization
                      </v-chip>
                      <v-chip
                        v-if="card.entity == 'provider'"
                        class="pa-2"
                        color="#CDFFCC"
                        text-color="#333333"
                        small
                      >
                        Clinician
                      </v-chip>
                    </div>
                  </div>
                </v-card>
              </v-slide-item>
            </v-slide-group>
            <v-divider horizontal class="mb-2"></v-divider>
          </div></div>
       
        </div>
      </div>
    </v-sheet>

    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <removeDialog
      ref="dialog_delete"
      v-on:confirm="confirmRemove"
    ></removeDialog>
    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
    <router-view></router-view>
  </div>
</template>

<script>
import api from "../../../../services/api";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../TablesTemplate/ErrorDialog.vue";
import removeDialog from "../../../TablesTemplate/RemoveDialog.vue";

export default {
  components: { successModal, errorModal, removeDialog },
  data() {
    return {
      mini: false,
      panel: [0, 2],
      drawer: false,
      loader: true,
      searchQuery: "",
      zipOrState: "",
      marketplace: [],
      selectedCategories: [],
      selectedCredentials: [],
      selectedSpecialities: [],
      selectedGroupAndOrganizations: [],
      selectedGenders: [],
      categories: [
        { label: "Virtual Networks", value: "virtualNetwork" },
        { label: " Point Solution Vendors", value: "vendors" },
        { label: " Clinics & Organizations", value: "clinics" },
        { label: "Clinicians ", value: "clinicians" },
      ],
      searchByCategory: [],
      providerCredentials: [],
      specialities: [],
      groupAndOrganizations: [],
      searchLoader: false,
      noResultsFromSearch: false,
      tab: "all_solutions",
      sortItems: [{ text: "Newest" }, { text: "Oldest" }],
      sortBy: "",
      requiredRules: [(v) => !!v || "This field is required"],
      valid: false,
      showOverlay: false,
      search: {},
      data: {},
    };
  },
  watch: {},
  computed: {
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
    favoriteItems() {
      return (items) => {
        if (this.tab === "my_solutions") {
          return items.filter((item) => item.favorite === true);
        }
        return items;
      };
    },
    contractedItems() {
      return (items) => {
        if (this.tab === "my_solutions") {
          return items.filter((item) => item.contracted === true);
        }
        return items;
      };
    },
    filteredFavorites() {
      if (this.tab === "my_solutions") {
        return this.marketplace.filter((category) =>
          category.items.find((item) => item.favorite === true)
        );
      }
      return this.marketplace;
    },
    filteredContracted() {
      if (this.tab === "my_solutions") {
        return this.marketplace.filter((category) =>
          category.items.find((item) => item.contracted === true)
        );
      }
      return this.marketplace;
    },
    filteredSearch() {
      return (items) => {
        const query = this.searchQuery.toLowerCase().trim();
        if (query === "") {
          return items;
        }
        return items.filter(
          (item) =>
            item.name.toLowerCase().includes(query) ||
            item.speciality?.toLowerCase().includes(query)
        );
      };
    },
    filteredCategories() {
      const query = this.searchQuery.toLowerCase().trim();
      if (query === "") {
        return this.marketplace;
      }
      return this.marketplace.reduce((filtered, category) => {
        const filteredItems = category.items.filter(
          (item) =>
            item.name.toLowerCase().includes(query) ||
            category.category.toLowerCase().includes(query)
        );
        if (
          category.category.toLowerCase().includes(query) ||
          (filteredItems.length > 0 &&
            !category.category.toLowerCase().includes(query))
        ) {
          filtered.push({ category: category.category, items: filteredItems });
        }

        return filtered;
      }, []);
    },
  },
  created() {
    this.getMarketplace();
    this.getProviderCredentials();
    this.getSpecialities();
    this.getGroupAndOrganizations();
  },
  beforeRouteUpdate(to, from, next) {
    this.getMarketplace();
    next();
  },
  methods: {
    removeChip(chip,selectedArray) {
      const filterArray = selectedArray;
      const chipIndex = filterArray.indexOf(chip);
      if (chipIndex !== -1) {
        filterArray.splice(chipIndex, 1);
      }
      this.searchResults();
      this.drawer = false;
    },
    getProviderCredentials() {
      api()
        .get("/market/credentials/provider")
        .then((res) => {
          this.providerCredentials = res.data;
        });
    },
    getSpecialities() {
      api()
        .get("/market/specialities")
        .then((res) => {
          this.specialities = res.data;
        });
    },
    getGroupAndOrganizations() {
      api()
        .get("/market/organization/company/name")
        .then((res) => {
          this.groupAndOrganizations = res.data;
        });
    },
    selectGender(){
      if(this.male){
        this.selectedGenders.push('M');
      }else{
        let index = this.selectedGenders.indexOf('M');
        this.selectedGenders.splice(index,1);
      }
      if(this.female){
        this.selectedGenders.push('F');
      }else{
        let index = this.selectedGenders.indexOf('F');
        this.selectedGenders.splice(index,1);
      }
    },
    searchResults() {
      this.searchLoader = true;
     
      this.data = {
        search: {
          types: this.selectedCategories,
          organizations: this.selectedGroupAndOrganizations,
          specialities: this.selectedSpecialities,
          credentials: this.selectedCredentials,
          gender: this.selectedGenders,
        },
      };
    
      api()
        .post("/vendors/market/search", this.data)
        .catch((err) => {
          if (err) {
            this.$refs.error_modal.openPopUp(err);
          }
        })
        .then((res) => {
          this.marketplace = res.data;
          this.searchLoader = false;
          if (res.data.length < 1) {
            this.noResultsFromSearch = true;
          }
          else{
            this.noResultsFromSearch = false;
          }
          this.drawer = false;
        });
    },
    searchByZip() {
      this.searchLoader = true;
      const data = {
        search: {
          zipCode: this.zipOrState,
        },
      };
      api()
        .post("/vendors/market/search", data)
        .catch((err) => {
          if (err) {
            this.$refs.error_modal.openPopUp(err);
          }
        })
        .then((res) => {
          this.marketplace = res.data;
          this.searchLoader = false;
          if (res.data.length < 1) {
            this.noResultsFromSearch = true;
          }
          else{
            this.noResultsFromSearch = false;
          }
        });
    },
    clearSearch() {
      this.loader = false;
      this.selectedCategories = [];
      this.selectedCredentials = [];
      this.selectedGenders = [];
      this.selectedGroupAndOrganizations = [];
      this.selectedSpecialities = [];
      this.getMarketplace();
    },
    changeTab(tab) {
      this.tab = tab;
    },
    getMarketplace() {
      this.noResultsFromSearch = false;
      this.zipOrState = '';
      api()
        .get("/vendors/market")
        .then((res) => {
          this.marketplace = res.data;
          this.loader = false;
        });
    },
    closeErrorModal() {
      this.searchLoader = false;
      this.getMarketplace();
      this.$refs.error_modal.closePopUp();
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
    },
    confirmRemove() {},
    getMySolutions() {},
    goToDetails(item, entity) {
      if (entity == "solution") {
        this.$router.push(`/vendors/solutions/virtual-network/${item.id}`);
      } else if (entity == "location") {
        this.$router.push({
          path: `/vendors/solutions/clinics-organization/${item.id}`,
          query: { location: item.locationId },
        });
      } else if (entity == "vendor") {
        this.$router.push(`/vendors/solutions/point-solution-vendor/${item.id}`);
      } else if (entity == "provider") {
        this.$router.push(
          `/vendors/solutions/clinician/${item.id}`
        );
      }
      this.showOverlay = false;
    },
  },
};
</script>
<style scoped lang="scss">
.content-height{
  height:70vh;
}
.mobile-drawer{
  top: 60px !important;
  }
.subtitle-link {
  color: #366CB3 !important;
}
.active {
  color: #333333 !important;
  background-color: transparent !important;
  text-decoration: underline !important;
}
.active:hover {
  color: #333333 !important;
}
.display-grid {
  display: grid !important;
}
.apaly-bg {
  background-color: #194680;
}
.drawer-header{
  background-color: #194680;
  padding: 15px;
  color: white;
  display: flex;
  justify-content: space-between;
  span{
    font-weight: normal;
    text-align: center;
   width: 100%;
   font-size:20px;
  }
}
.scrollable-drawer {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
}

.scrollable-creds {
  overflow-y: scroll;
  height: 20vh;
}
::v-deep .checkbox-label-size .v-label{
  font-size: 14px;
}

.text-ellipsis {
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.box-hover:hover {
  box-shadow: 0px 4px 8px 0px rgba(51, 51, 51, 0.16);
}
@media only screen and (max-width: 600px) {
  .font-36 {
    font-size: 24px;
  }
  .font-20 {
    font-size: 14px;
  }
  .img-mobile {
    width: 75px;
    height: 100px;
  }
  .drawer-mobile-height{
    height: 70vh !important;
  }
  .mobile-drawer{
  z-index:9 !important;
  top: 0px !important;
  }
  .content-height{
    height:70vh !important;
  }
}
</style>
