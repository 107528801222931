<template>
  <div class="">
    <div class="row border-blue-bottom mb-3">
      <div class="col-md-2 mb-4 mt-4 ml-4 font-weight-bold text-gray">
        Activate Solution
      </div>
      <div v-show="solution.acceptedDate" class="col-md mt-4 mb-4 text-gray">
        Activated on
        {{ new Date(solution.acceptedDate) | moment("MM/DD/YYYY") }}
      </div>
    </div>
    <div v-if="solution">
      <div class="row form-row-height pt-2 pb-2">
        <div class="col-md-2" style="border-right: 1px solid #eeeded">
          <div class="row scroll">
            <div class="col-12 text-gray">
              <span
                :class="
                  tab == 'general'
                    ? 'ml-4 font-weight-bold cursor-pointer light-blue-text'
                    : 'ml-4 font-weight-bold cursor-pointer'
                "
                >General Information</span
              >
            </div>
            <div class="col-12 mt-6 text-gray">
              <span
                :class="
                  tab == 'solution'
                    ? 'ml-4 font-weight-bold cursor-pointer light-blue-text'
                    : 'ml-4 font-weight-bold cursor-pointer'
                "
                >Solution Facilitator</span
              >
            </div>
            <div class="col-12 mt-6 text-gray">
              <span
                :class="
                  tab == 'rates'
                    ? 'ml-4 font-weight-bold cursor-pointer light-blue-text'
                    : 'ml-4 font-weight-bold cursor-pointer'
                "
                >Rates & Fees
              </span>
            </div>
            <div class="col-12 mt-6 text-gray">
              <span
                :class="
                  tab == 'contracts'
                    ? 'ml-4 font-weight-bold cursor-pointer light-blue-text'
                    : 'ml-4 font-weight-bold cursor-pointer'
                "
                >Contracts</span
              >
            </div>
            <div class="col-12 mt-6 text-gray">
              <span
                :class="
                  tab == 'acceptance'
                    ? 'ml-4 font-weight-bold cursor-pointer light-blue-text'
                    : 'ml-4 font-weight-bold cursor-pointer'
                "
                >Acceptance</span
              >
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <GeneralInformation
            :item="solution"
            v-if="solution && tab == 'general'"
          ></GeneralInformation>
          <SolutionFacilitator
            :item="solution"
            v-if="solution && tab == 'solution'"
          ></SolutionFacilitator>
          <Rates
            :item="solution"
            @getRate="getRate"
            @getFee="getFee"
            v-if="solution && tab == 'rates'"
          >
          </Rates>
          <Contracts :item="solution" v-if="solution && tab == 'contracts'">
          </Contracts>
          <Acceptance
            :item="solution"
            @getContract="getContract"
            @getClient="getClient"
            v-if="solution && tab == 'acceptance'"
          >
          </Acceptance>
        </div>
      </div>
    </div>
    <div class="gray-top-border">
      <div class="col-md-12 f-lex" align="end">
        <span
          class="light-blue-text text-underline mr-10 font-weight-bold pointer"
          @click="backTab(tab)"
        >
          Back
        </span>
        <span
          class="light-blue-text text-underline mr-10 font-weight-bold pointer"
          v-if="tab != 'acceptance'"
          @click="nextTab(tab)"
        >
          Next
        </span>
        <span
          class="light-blue-text text-underline mr-10 font-weight-bold pointer"
          v-else
          @click="submit()"
        >
          Submit
        </span>
      </div>
    </div>
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
  </div>
</template>
<script>
import api from "@/services/api";
import { mapState } from "vuex";
import successModal from "../../../../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../../../../TablesTemplate/ErrorDialog.vue";
import GeneralInformation from "@/components/Sections/Tpa/Clients/HorizontalMenu/Solutions/SolutionFlow/Details/GeneralInformation";
import SolutionFacilitator from "@/components/Sections/Tpa/Clients/HorizontalMenu/Solutions/SolutionFlow/Details/SolutionFacilitator";
import Rates from "@/components/Sections/Tpa/Clients/HorizontalMenu/Solutions/SolutionFlow/Details/Rates";
import Contracts from "@/components/Sections/Tpa/Clients/HorizontalMenu/Solutions/SolutionFlow/Details/Contracts";
import Acceptance from "@/components/Sections/Tpa/Clients/HorizontalMenu/Solutions/SolutionFlow/Details/Acceptance";
export default {
  data() {
    return {
      plans: [],
      dataToSend: {},
      receivedRate: "",
      receivedFee: "",
      acceptedByTpa: "",
      clientData: {},
      solution: {},
      tab: "general",
      dialog: false,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
      valid: false,
      companyName: "",
      firstName: "",
      lastName: "",
      email: "",
    };
  },
  components: {
    GeneralInformation,
    SolutionFacilitator,
    Rates,
    Contracts,
    Acceptance,
    successModal,
    errorModal,
  },
  computed: {
    ...mapState({
      clientSolutions: (state) => state.Tpa.clientSolutions,
      solutionAcceptance: (state) => state.Tpa.clientSolutionAcceptance,
    }),
  },
  watch: {},
  created() {
    this.$store.commit("setSolutionMaxRate", "");
      this.$store.commit("setSolutionAcceptByClient", "");
      this.$store.commit("setSolutionAcceptByTpa", "");
      this.$store.commit("setTpaAdminFee", "");
    this.plans = this.$store.getters.getTpaSingleSolution.plans;
    api()
      .get("/tpas/solution/" + this.$route.params.solutionId)
      .then((res) => {
        this.solution = res.data;
   
      });
  },
  methods: {
    getRate(data) {
      this.receivedRate = data;
    },
    getFee(data) {
      this.receivedFee = data;
    },
    getContract(data) {
      this.acceptedByTpa = data;
    },
    getClient(data) {
      this.clientData = data;
    },
    inviteSolutions() {
      this.$refs.form.validate();
      if (this.valid) {
        this.dialog = false;
      }
    },
    nextTab(tab) {
      if (tab == "general") {
        this.tab = "solution";
      } else if (tab == "solution") {
        this.tab = "rates";
      } else if (tab == "rates") {
        if (!this.receivedRate || !this.receivedFee) {
          this.$refs.error_modal.openPopUp(
            "Please fill the required fileds before you proceed to the next"
          );
        } else {
          this.tab = "contracts";
        }
      } else if (tab == "contracts") {
        this.tab = "acceptance";
      }
    },
    submit() {
         if(this.acceptedByTpa || Object.keys(this.clientData).length!= 0){
          if (this.acceptedByTpa == true) {
        this.dataToSend = {
          plans: this.plans,
          maxRate: this.receivedRate,
          tpaFee: this.receivedFee,
          acceptedBy: "tpa",
        };
      } else {
        this.dataToSend = {
          plans: this.plans,
          maxRate: this.receivedRate,
          tpaFee: this.receivedFee,
          acceptedBy: "employer",
          firstName: this.clientData.firstName,
          lastName: this.clientData.lastName,
          email: this.clientData.email,
        };
      }
      api()
        .post(
          `/tpas/clients/${this.$route.params.clientId}/solutions/${this.$route.params.solutionId}/contract`,
          this.dataToSend
        )
        .catch((err) => {
          this.$refs.error_modal.openPopUp(err.response.data);
        })
        .then((res) => {
          if (res) {
            this.$refs.success_modal.openPopUp(res.data);
          }
        });
         }
         else{
        this.$refs.error_modal.openPopUp('Please complete all required fields!');
      }
     
    },
    backTab(tab) {
      if (tab == "solution") {
        this.tab = "general";
      } else if (tab == "rates") {
        this.tab = "solution";
      } else if (tab == "contracts") {
        this.tab = "rates";
      } else if (tab == "acceptance") {
        this.tab = "contracts";
      } else if (tab == "general") {
        this.$store.commit("setSolutionMaxRate", "");
        this.$store.commit("setSolutionAcceptByClient", "");
        this.$store.commit("setSolutionAcceptByTpa", "");
        this.$store.commit("setTpaAdminFee", "");
        this.$router.go(-1);
      }
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
      this.$store.commit("setSolutionMaxRate", "");
      this.$store.commit("setSolutionAcceptByClient", "");
      this.$store.commit("setSolutionAcceptByTpa", "");
      this.$store.commit("setTpaAdminFee", "");
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
.scroll {
  scroll-behavior: smooth;
}
</style>