<template>
  <div class="">
    <div v-if="!isChildren && $route.meta.type == 'members'" class="row-height">
      <Header
        :title="'Client Members'"
        :searchInput="true"
        :exportButton="true"
        :placeholder="'Search Members'"
        :newButton="true"
        @export="exportData"
        @add="add"
        @searchInput="searchInput"
      ></Header>
      <div class="mt-3">
        <v-simple-table fixed-header id="tblData" height="70vh" class="no-padding tr-custom-height">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">MEMBER ID</th>
                <th class="text-left">PLAN NAME</th>
                <th class="text-left">EMAIL</th>
                <th class="text-left">RELATIONSHIP TO SUBSCRIBER</th>
                <th class="text-left">ZIP CODE</th>
                <th class="text-left">STATUS</th>
              </tr>
            </thead>
            <tbody v-if="items.length > 0 && !filterItems" class="text-gray tr-custom-height">
              <tr v-for="(item, index) in items" :key="index">
                <td>
                  <router-link :to="'/tpa/clients/'+$route.params.clientId+'/members/'+item.id +'/details'">
                    {{item.memberId}}
                  </router-link>
                </td>
                <td v-if="item.plan">
                  {{ item.plan.name }}
                </td>
                <td v-else>
                  /
                </td>
                <td>
                  {{ item.email }}
                </td>
                <td>
                  {{ item.subscriberRelation }}
                </td>
                <td>
                  {{ item.zipCode }}
                </td>
                <td>
                  {{ item.status.charAt(0).toUpperCase() + item.status.slice(1) }}
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="items.length < 1 && filterItems">
              <v-col md="12" cols="12">
                <v-alert border="bottom" colored-border type="warning" elevation="2" class="text-gray">
                  Sorry! No results found
                </v-alert>
              </v-col>
            </tbody>
            <tbody v-else>
              <v-col md="12">
                There are no members currently added to this account.
                <a @click="add"> Click to add Member IDs, Emails, and Zip Codes to your client’s account</a>
              </v-col>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import Header from "../../../Layout/HeaderPage.vue";
import api from "../../../../../../services/api";
import successModal from "../../../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../../../TablesTemplate/ErrorDialog.vue";
export default {
  components: { errorModal, successModal, Header },
  data() {
    return {
      showDetails: false,
      tab: "solution",
      dialog: false,
      items: [],

      singleItem: null,
      typeItems: [{ text: "Categories", value: "Categories" }],
      sortItems: [{ text: "Newest", value: "Newest" }],
      sortBy: "",
      type: "",
      solutions: [],
      favorites: [],
      filterArray: [],
      filterItems: false,
    };
  },
  computed: {
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
  },
  created() {
    api().get(`/tpas/clients/${this.$route.params.clientId}/members`)
        .then((res) => {
          if (res) {
            this.items = res.data;
            this.filterArray = this.items;
          }
        });
  },
  methods: {
    exportData() {
      let elt = document.getElementById("tblData");
      let downloadLink;
      let dataType = "application/vnd.ms-excel";
      let tableSelect = elt;
      let tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

      let filename = "excel_data.xls";
      downloadLink = document.createElement("a");
      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        let blob = new Blob(["\ufeff", tableHTML], {
          type: dataType,
        });
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        downloadLink.href = "data:" + dataType + ", " + tableHTML;
        downloadLink.download = filename;
        downloadLink.click();
      }
    },
    add() {
      this.$router.push("/tpa/clients/" + this.$route.params.clientId + "/members/add-new");
    },
    searchInput(val) {
      this.items = this.filterArray.filter((el) => {
        return el.email.toString().toLowerCase().includes(val.toLowerCase());
               el._id.toString().toLowerCase().includes(val.toLowerCase());
      });
      this.filterItems = (this.items.length < 1)
    },
  },
};
</script>
<style>
.border-blue-bottom {
  border-bottom: 6px solid #4072b7;
  margin-left: -1px;
  margin-right: -2px;
  margin-top: -1px;
}

.tr-custom-height > .v-data-table__wrapper > table > tbody > tr > td {
  height: 75px;
}
</style>