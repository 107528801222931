<template>
  <div class="">
    <div class="row border-blue-bottom mb-3">
      <div class="col-md-12 mb-4 mt-4 ml-4 font-weight-bold text-gray">
        Solution Approval
      </div>
    </div>
    <successModal ref="success_modal" v-on:confirm="closeSuccessModal"></successModal>
    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
  </div>
</template>
<script>
import api from "@/services/api";
import successModal from "../../../../../../components/TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../../../../components/TablesTemplate/ErrorDialog.vue";
export default {
  data() {
    return {};
  },
  components: {
    successModal,
    errorModal,
  },
  methods: {
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
      this.$router.replace("/tpa");
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
      this.$router.replace("/tpa");
    },
  },
  created() {
    api().get(`/tpas/approve/solutions?token=${this.$route.query.token}`)
        .catch((err) => {
          if (err) {
            this.$refs.error_modal.openPopUp(err.response.data);
          }
        })
        .then((res) => {
          if (res) {
            this.$refs.success_modal.openPopUp(
                "Solution is activated"
            );
          }
        });
  },
};
</script>
