import EmployerClientTemplate from "../views/DPCProviders.vue";
import RouterView from "../components/Sections/EmployerClient/RouterView.vue";
import Home from "../components/Sections/DPCProviders/Home.vue";
import WizardForm from "../components/Sections/DPCProviders/Wizard/ConfirmInformation.vue";
import Members from "../components/Sections/DPCProviders/Members/Members.vue";
import AddNewMember from "../components/Sections/DPCProviders/Members/AddNew.vue";
import RegisterNewMember from "../components/Sections/DPCProviders/Members/RegisterNewMember.vue";

import MemberDetails from "../components/Sections/DPCProviders/Members/Member.vue";
import VisitReporting from "../components/Sections/DPCProviders/Members/VisitReporting.vue";
import NewTemplate from "../components/Sections/DPCProviders/Members/NewTemplate.vue";
import EditTemplate from "../components/Sections/DPCProviders/Members/EditTemplate.vue";

import Locations from "../components/Sections/DPCProviders/Locations/Locations.vue";
import Vendors from "../components/Sections/DPCProviders/Vendors/Vendors.vue";

import AddLocation from "../components/Sections/DPCProviders/Locations/AddNew.vue";
import LocationDetails from "../components/Sections/DPCProviders/Locations/Location.vue";
import Invoices from "../components/Sections/DPCProviders/Invoices/Invoices.vue";
import InvoiceDetail from "../components/Sections/DPCProviders/Invoices/Details.vue";
import InvoicePayment from "../components/Sections/DPCProviders/Invoices/Payment.vue";
import Account from "../components/Sections/DPCProviders/Account/Account.vue";
import LayoutAccount from "../components/Sections/DPCProviders/Account/LayoutAccount.vue";

import Contract from "../components/Sections/DPCProviders/Account/Contract/Contract.vue";
import ContractDetails from "../components/Sections/DPCProviders/Account/Contract/Details.vue";
import ContractAccept from "../components/Sections/DPCProviders/Account/Contract/ContractsAccept.vue";

import AddNew from "../components/Sections/DPCProviders/Account/Contract/AddNew.vue";
import Bank from "../components/Sections/DPCProviders/Account/BankInfo/BankInfo.vue";
import Users from "../components/Sections/DPCProviders/Account/Users/Users.vue";
import AddUser from "../components/Sections/DPCProviders/Account/Users/AddUser.vue";
import SingleUser from "../components/Sections/DPCProviders/Account/Users/SingleUser.vue";
import Settings from "../components/Sections/DPCProviders/Account/Settings/Settings.vue";

import Solutions from "../components/Sections/DPCProviders/Solutions/NewMarketplace.vue";
import SolutionDetails from "../components/Sections/DPCProviders/Solutions/NewVNetDetails.vue";
import ProviderDetails from "../components/Sections/DPCProviders/Solutions/NewClinicDetails.vue";
import ClinicianDetails from "../components/Sections/DPCProviders/Solutions/ClinicianDetails.vue";
import VendorDetails from "../components/Sections/DPCProviders/Solutions/VendorDetails.vue";
import AllSolutions from "../components/Sections/DPCProviders/Solutions/AllSolutions.vue";

import Providers from "../components/Sections/DPCProviders/Account/Providers/Providers.vue";
import AddProviders from "../components/Sections/DPCProviders/Account/Providers/AddNew.vue";
import SearchResult from "../components/Sections/DPCProviders/Account/Providers/SearchResult.vue";
import Provider from "../components/Sections/DPCProviders/Account/Providers/Provider.vue";
import Clients from "../components/Sections/DPCProviders/Account/Clients/Clients.vue";
import Client from "../components/Sections/DPCProviders/Account/Clients/Client.vue";
import AddNewClient from "../components/Sections/DPCProviders/Account/Clients/AddNew.vue";
import Rates from "../components/Sections/DPCProviders/Account/Rates/Rates.vue";
import Rate from "../components/Sections/DPCProviders/Account/Rates/Details.vue";
import AddNewRate from "../components/Sections/DPCProviders/Account/Rates/AddNew.vue";
import VueCookies from "vue-cookies";
import api from "@/services/api";
function routeGuard(to, from, next) {
  let type = localStorage.getItem("type");

  if (to.query.token) {
    // If there's a token in the URL, set it in cookies
    VueCookies.set("token", to.query.token);
    localStorage.setItem("type", "DPC");

    // Fetch user data using the token
    api()
      .get(`/dpcproviders/accountandentity`)
      .then((res) => {
        if (res.data) {
            localStorage.setItem("user", JSON.stringify(res.data));
          next();
        } else {
          next("/login");
        }
      })
      .catch((error) => {
        console.error("Error fetching account data:", error);

        next(`/login?redirect=${to.path}`);
      });
  } else if (!type || type !== "DPC" || !$cookies.get("token")) {
    // If there's no token in cookies or URL, redirect to login

    next(`/login${to.fullPath ? `?redirect=${to.fullPath}` : ""}`);
  } else {
    // If there's a token in cookies, proceed with the route
    next();
  }
}
const router = [
  {
    path: "/dpc-providers",
    name: "Home",
    beforeEnter: routeGuard,
    redirect: () => {
      return { path: "/dpc-providers/home" };
    },
  },
  {
    component: RouterView,
    path: "/dpc-providers",
    beforeEnter: routeGuard,
    redirect: () => {
      return { path: "/dpc-providers" };
    },
    children: [
      {
        path: "/dpc-providers",
        name: "Home",
        component: EmployerClientTemplate,
        children: [
          {
            path: "home",
            name: "Home",
            component: RouterView,
            redirect: () => {
              return { path: "/dpc-providers/home" };
            },
            children: [
              {
                path: "/dpc-providers/home",
                name: "Home",
                component: Home,
                children: [
                  {
                    path: "wizard",
                    name: "New Member Registration Wizard",
                    component: WizardForm,
                    meta: {
                      isChildren: true,
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "solutions",
            name: "",
            component: RouterView,
            redirect: () => {
              return { path: "/dpc-providers/solutions" };
            },
            children: [
              {
                path: "/dpc-providers/solutions",
                name: "Marketplace",
                component: Solutions,
                children: [],
              },
              {
                path: "/dpc-providers/solutions/all/:category",
                name: "All Soluttions",
                component: AllSolutions,
              },
              {
                path: "/dpc-providers/solutions/details/:id",
                name: "Solution Detail",
                component: SolutionDetails,
                meta: {
                  isChildren: true,
                },
              },
              {
                path: "/dpc-providers/solutions/provider/details/:id",
                name: "Provider Detail",
                component: ProviderDetails,
                meta: {
                  isChildren: true,
                },
                props: (route) => ({
                  location: route.query.location || "",
                }),
              },
              {
                path: "/dpc-providers/solutions/clinician/details/:id",
                name: "Clinician Detail",
                component: ClinicianDetails,
                meta: {
                  isChildren: true,
                },
              },
              {
                path: "/dpc-providers/solutions/vendor/details/:id",
                name: "Vendor Detail",
                component: VendorDetails,
                meta: {
                  isChildren: true,
                },
              },
            ],
          },

          {
            path: "members",
            name: "Members",
            component: RouterView,
            redirect: () => {
              return { path: "/dpc-providers/members" };
            },
            children: [
              {
                path: "/dpc-providers/members",
                name: "Members",
                component: Members,
              },
              {
                path: "add-new",
                name: "Add Member",
                component: AddNewMember,
              },
              {
                path: "register-new",
                name: "Register New Member",
                component: RegisterNewMember,
              },
              {
                path: "member/:id",
                name: "Member Detail",
                component: MemberDetails,
                beforeEnter: routeGuard.bind(this),
              },
              {
                path: "visit-reporting/:id",
                name: "Visit Reporting",
                component: VisitReporting,
              },
              {
                path: "new-template",
                name: "New Template",
                component: NewTemplate,
              },
              {
                path: "edit-template/:id",
                name: "Edit Template",
                component: EditTemplate,
              },
            ],
          },
          {
            path: "vendors",
            name: "",
            component: RouterView,
            redirect: () => {
              return { path: "/dpc-providers/vendors" };
            },
            children: [
              {
                path: "/dpc-providers/vendors",
                name: "Vendors",
                component: Vendors,
              },
              // {
              //     path:'add-new',
              //     name:'Add Member',
              //     component:AddNewMember,
              // },
              // {
              //     path:'member',
              //     name:'Member Detail',
              //     component:MemberDetails,
              // }
            ],
          },
          {
            path: "locations",
            name: "Locations",
            component: RouterView,
            redirect: () => {
              return { path: "/dpc-providers/locations" };
            },
            children: [
              {
                path: "/dpc-providers/locations",
                name: "Locations",
                component: Locations,
              },
              {
                path: "add-new",
                name: "Add New Location",
                component: LocationDetails,
              },
              {
                path: "upload",
                name: "Upload a List",
                component: AddLocation,
              },

              {
                path: "location/:id",
                name: "Location Detail",
                component: LocationDetails,
              },
            ],
          },
          {
            path: "invoices",
            name: "",
            component: RouterView,
            redirect: () => {
              return { path: "/dpc-providers/invoices" };
            },
            children: [
              {
                path: "/dpc-providers/invoices",
                name: "Invoices",
                component: Invoices,
                children: [
                  {
                    path: "details/:id",
                    name: "Invoice Detail",
                    component: InvoiceDetail,
                    meta: {
                      isChildren: true,
                    },
                    children: [
                      {
                        path: "payment",
                        name: "Payment Options",
                        component: InvoicePayment,
                        meta: {
                          isChildren: true,
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },

          {
            path: "account",
            name: "Account",
            component: RouterView,
            redirect: () => {
              return { path: "/dpc-providers/account" };
            },
            children: [
              {
                path: "/dpc-providers/account",
                name: "Account",
                component: LayoutAccount,
              },
            ],
          },

          {
            path: "/dpc-providers/providers",
            name: "",
            component: RouterView,
            redirect: () => {
              return { path: "/dpc-providers/providers" };
            },
            children: [
              {
                path: "/dpc-providers/providers",
                name: "Providers",
                component: Providers,
                children: [
                  {
                    path: "add-new",
                    name: "Add Providers",
                    component: AddProviders,
                    meta: {
                      isChildren: true,
                    },
                  },
                  {
                    path: "provider/:id",
                    name: "Provider Detail",
                    component: Provider,
                    meta: {
                      isChildren: true,
                    },
                  },
                  {
                    path: "search-result",
                    name: "Search Result",
                    component: SearchResult,
                    meta: {
                      isChildren: true,
                    },
                  },
                ],
              },
            ],
          },

          {
            path: "/dpc-providers/rates",
            name: "",
            component: RouterView,
            redirect: () => {
              return { path: "/dpc-providers/rates" };
            },
            children: [
              {
                path: "/dpc-providers/rates",
                name: "Rate Groups",
                component: Rates,
                children: [
                  {
                    path: "add-new",
                    name: "New Rate",
                    component: AddNewRate,
                    meta: {
                      isChildren: true,
                    },
                  },
                  {
                    path: "rate/:id",
                    name: "Rate Group Detail",
                    component: Rate,
                    meta: {
                      isChildren: true,
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "/dpc-providers/clients",
            name: "",
            component: RouterView,
            redirect: () => {
              return { path: "/dpc-providers/clients" };
            },
            children: [
              {
                path: "/dpc-providers/clients",
                name: "Clients",
                component: Clients,
                children: [
                  {
                    path: "add-new",
                    name: "New Client",
                    component: AddNewClient,
                    meta: {
                      isChildren: true,
                    },
                  },
                  {
                    path: "client/:id",
                    name: "Client  Detail",
                    component: Client,
                    meta: {
                      isChildren: true,
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "/dpc-providers/contract",
            name: "",
            component: RouterView,
            redirect: () => {
              return { path: "/dpc-providers/contract" };
            },
            children: [
              {
                path: "/dpc-providers/contract",
                name: "Contracts",
                component: Contract,
                children: [
                  {
                    path: "add-new",
                    name: "Add New",
                    component: AddNew,
                    meta: {
                      isChildren: true,
                    },
                  },
                  {
                    path: "details",
                    name: "Contract Details",
                    component: ContractDetails,
                    meta: {
                      isChildren: true,
                    },
                  },
                  {
                    path: "accept",
                    name: "Contract(s) Accept",
                    component: ContractAccept,
                    meta: {
                      isChildren: true,
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "/dpc-providers/users",
            name: "",
            component: RouterView,
            redirect: () => {
              return { path: "/dpc-providers/users" };
            },
            children: [
              {
                path: "/dpc-providers/users",
                name: "Users",
                component: Users,
                children: [
                  {
                    path: "add-new",
                    name: "Add User",
                    component: AddUser,
                    meta: {
                      isChildren: true,
                    },
                  },
                  {
                    path: "user/:id",
                    name: "User Detail",
                    component: SingleUser,
                    meta: {
                      isChildren: true,
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

export default router;
