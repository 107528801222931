<template>
  <v-row class="add-client-form row no-padding">
    <successModal ref="success_modal" v-on:confirm="sendMail"></successModal>
    <removeDialog
      ref="dialog_delete"
      v-on:confirm="confirmRemove"
    ></removeDialog>

    <div class="col-md-12 form-row-60-height">
      <div class="row">
        <div class="col-md-12">
          <h3 class="form-title font col-md-12">User Detail</h3>
        </div>
      </div>
      <v-form v-model.trim="valid" ref="form" class="row col-md-12">
        <div class="col-md-6 pb-0">
          <label class="font text-gray" for="">First Name</label>
          <v-text-field
            required
            v-model.trim="firstName"
            solo
            :rules="requiredRules"
            tabindex="1"
          ></v-text-field>
        </div>

        <div class="col-md-6 pb-0">
          <label class="font text-gray" for="">Last Name</label>
          <v-text-field
            required
            tabindex="2"
            :rules="requiredRules"
            v-model.trim="lastName"
            solo
          ></v-text-field>
        </div>
        <div class="col-md-6 pb-0 pt-0">
          <label class="font text-gray" for="">Role </label>
          <v-select
            solo
            disabled
            :items="[
              { text: 'Admin', value: 'admin' },
              { text: 'User', value: 'user' },
            ]"
            v-model.trim="role"
          ></v-select>
        </div>
        <div class="col-md-6 pb-0 pt-0">
          <label class="font text-gray" for="">Email</label>
          <v-text-field
            required
            :rules="emailRules"
            v-model.trim="email"
            solo
            disabled
            tabindex="3"
          ></v-text-field>
        </div>
        <div class="col-md-6 pb-0 pt-0">
          <label class="font text-gray" for="">Address</label>
          <v-text-field
            required
            tabindex="7"
            :rules="requiredRules"
            v-model.trim="address"
            solo
          ></v-text-field>
        </div>
        <div class="col-md-6 pb-0 pt-0">
          <label class="font text-gray" for="">Title</label>
          <v-text-field
            required
            tabindex="4"
            :rules="requiredRules"
            v-model.trim="title"
            solo
          ></v-text-field>
        </div>
        <div class="col-md-6 row no-padding">
          <div class="col-md-6 pb-0 pt-0">
            <label class="font text-gray" for="">City</label>
            <v-text-field
              required
              :rules="requiredRules"
              v-model.trim="city"
              solo
              tabindex="8"
            ></v-text-field>
          </div>
          <div class="col-md-3 pb-0 pt-0">
            <label class="font text-gray" for="">State</label>

            <v-select
              required
              :rules="requiredRules"
              :items="states"
              v-model.trim="state"
              solo
              tabindex="9"
              item-text="name"
              item-value="name"
            ></v-select>
          </div>
          <div class="col-md-3 pb-0 pt-0">
            <label class="font text-gray" for="">Zip</label>
            <v-text-field
              required
              type="number"
              v-model.trim="zip"
              :rules="zipRules"
              solo
              tabindex="10"
            ></v-text-field>
          </div> 
          <div
            class="row col-md-12  pb-0"
            v-if="!deactivateDelete && $role != 'user'"
          >
            <div class="col-md-8 font text-gray pt-0">
              <label for="" class="ml-15 small-text"> User Status</label>
              <div class="d-flex mt-2">
                InActive
                <v-switch
                  solo
                  v-model="status"
                  class="no-padding ml-2 mr-2 vendors-switch"
                ></v-switch>
                Active
              </div>
            </div>
            <div
              class="col-md-4 mt-5 text-end"
              v-if="!deactivateDelete && $role != 'user'"
            >
              <a class="text-underline" @click="deleteUser()">Remove User</a>
            </div>
          </div>
        </div>
      </v-form>
    </div>
    <div class="text-end justify-end gray-underline-wizard row no-padding">
      <div class="col-md-12 mt-5 mb-5">
        <span
          class="text-underline text-gray mr-10 col-md-12 pointer"
          @click="goBack"
          >Cancel</span
        >
        <span
          class="text-underline text-gray mr-10 col-md-12 pointer"
          @click="updateUser"
          >Save
        </span>
      </div>
    </div>
  </v-row>
</template>
<script>
import successModal from "../../../../TablesTemplate/SuccessDialog.vue";
import removeDialog from "../../../../TablesTemplate/RemoveDialog.vue";
import api from "../../../../../services/api";
export default {
  components: { successModal, removeDialog },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      title: "",
      role: "",
      officeName: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      status: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
      zipRules: [
        (v) => !!v || "This field is required",
        (v) => (v && v.length == 5) || " Zip Code should be five digits long",
      ],
      states: [],
      valid: false,
      deactivateDelete: false,
      removUserStatus: false,
    };
  },
  computed: {
    routeParams() {
      return this.$route.params.name;
    },
    singleUser() {
      return this.$store.getters.getSingleUserEmpInEmployerAdvisorGlobal;
    },
  },
  created() {
    if (this.singleUser) {
      this.firstName = this.singleUser.firstName;
      this.lastName = this.singleUser.lastName;
      this.email = this.singleUser.email;
      this.title = this.singleUser.title ? this.singleUser.title : "";
      this.role =
        this.singleUser.groupPortal &&
        this.singleUser.groupPortal[0] &&
        this.singleUser.groupPortal[0].role &&
        this.singleUser.groupPortal[0].role.name
          ? this.singleUser.groupPortal[0].role.name
          : "";

      this.officeName = this.singleUser.officeName
        ? this.singleUser.officeName
        : "";
      this.address = this.singleUser.address1 ? this.singleUser.address1 : "";
      this.city = this.singleUser.city ? this.singleUser.city : "";
      this.state = this.singleUser.state ? this.singleUser.state : "";
      this.zip = this.singleUser.zipCode ? this.singleUser.zipCode : "";
      this.status =
        this.singleUser.status && this.singleUser.status == "active"
          ? true
          : false;
      if (localStorage.getItem("user")) {
        let user = JSON.parse(localStorage.getItem("user"));
        if (user.email == this.email) {
          this.deactivateDelete = true;
        }
      }
      if (this.singleUser.status == "invited") {
        this.removUserStatus = true;
      }
    }
    this.getStates();
  },
  methods: {
    deleteUser() {
      this.$refs.dialog_delete.openPopUp(
        "Are you sure that you want to delete this user?"
      );
    },

    confirmRemove() {
        this.$parent.getAllUsers()
      api()
        .delete(`/vendors/users/${this.singleUser._id}`)
        .then((res) => {
          if (res) {
          
            this.$router.push(`/solution-vendor/users`);
          }
        });
    },
    goBack() {
      this.$router.push(`/solution-vendor/users`);
    },
    getStates() {
      api()
        .get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },
    sendMail() {
      this.$refs.success_modal.closePopUp();
      this.$parent.getAllUsers("all");
      this.$router.push(`/solution-vendor/users`);
    },
    updateUser() {
      this.$refs.form.validate();
      if (this.valid) {
        let data = {
          firstName: this.firstName,
          lastName: this.lastName,
          title: this.title,
          officeName: this.officeName,
          address1: this.address,
          address: this.address,
          city: this.city,
          state: this.state,
          zipCode: this.zip,
          status: this.status ? "active" : "inactive",
        };

        api()
          .put(`/vendors/users/` + this.singleUser._id, data)
          .then((res) => {
            if (res) {
               this.$parent.getAllUsers()
              let text = "User sucessfully updated";
              this.$refs.success_modal.openPopUp(text);
            }
          });
      }
    },
  },
};
</script>
