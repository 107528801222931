<template>
  <div class="row justify-center mt-4">
    <div class="col-md-6 mt-4">
      <v-card class="mx-auto" max-width="1200" tile>
        <BlueHeader></BlueHeader>
        <div class="mt-3">
          <div class="row border-blue-bottom">
            <div class="row" :class="tableClients ? 'col-md-4' : 'col-md-6'">
              <v-icon
                large
                v-text="'mdi-menu-left'"
                class="pointer"
                @click="
                  tableClients
                    ? $router.push('/clearing-house')
                    : ((addNew = false),
                      (clientDetails = false),
                      (tableClients = true),
                      getData())
                "
              ></v-icon>

              <h3 class="mb-4 mt-5 ml-3 font-weight-bold warning--text">
                <span v-if="tableClients"> Health Plans </span>
                <span v-else-if="addNew">Add New Health Plan</span>
                <span v-else-if="clientDetails">Health Plan Details</span>
              </h3>
            </div>
            <div class="col-md-4" v-if="tableClients">
              <v-text-field
                class="input-control mr-5"
                prepend-inner-icon="mdi-magnify"
                @input="searchInput"
                light
                dense
                outlined
                placeholder="Search Health Plans"
              ></v-text-field>
            </div>

            <div :class="tableClients ? 'col-md-4' : 'col-md-6'" align="end">
              <div class="pointer" v-if="clientDetails">
                <svg
                  @click="dialog = true"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.25 7.51196H27.75"
                    stroke="#326AB3"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21.75 28.5H8.25C7.45435 28.5 6.69129 28.1839 6.12868 27.6213C5.56607 27.0587 5.25 26.2956 5.25 25.5V7.5H24.75V25.5C24.75 26.2956 24.4339 27.0587 23.8713 27.6213C23.3087 28.1839 22.5456 28.5 21.75 28.5Z"
                    stroke="#326AB3"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.62402 7.5V6.813C9.62402 5.4039 10.1838 4.05252 11.1802 3.05614C12.1765 2.05976 13.5279 1.5 14.937 1.5C16.3461 1.5 17.6975 2.05976 18.6939 3.05614C19.6903 4.05252 20.25 5.4039 20.25 6.813V7.5"
                    stroke="#326AB3"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.967 13.5V22.5"
                    stroke="#326AB3"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18.0449 13.5V22.5"
                    stroke="#326AB3"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <v-btn
                v-if="tableClients"
                class="my-1 gray-border-buttons ml-6"
                @click="
                  (addNew = true),
                    (tableClients = false),
                    (clientDetails = false),
                    (selectedEmployers = []),
                    (planname = ''),
                    (planid = ''),
                    (plantype = ''),
                    (tpas = []),
                    (selectedEmployers = [])
                "
              >
                <svg
                  class="mr-2"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 7.5H15"
                    stroke="#707070"
                    stroke-width="2"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.5 0V15"
                    stroke="#707070"
                    stroke-width="2"
                    stroke-linejoin="round"
                  />
                </svg>

                New
              </v-btn>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <v-simple-table
            v-if="tableClients"
            fixed-header
            height="70vh"
            class="no-padding tr-custom-height"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">PLAN ID</th>
                  <th class="text-left">PLAN NAME</th>
                  <th class="text-left">PLAN TYPE</th>
                </tr>
              </thead>

              <tbody
                v-if="items.length > 0 && !filterItems"
                class="text-gray tr-custom-height"
              >
                <tr
                  class="text-gray"
                  id="remove-hover"
                  v-for="(item, index) in items"
                  :key="index"
                >
                  <td>
                    <a
                      v-if="item && item.planid"
                      class="text-underline"
                      @click="showClient(item)"
                      >{{ item.planid }}
                    </a>
                    <a v-else @click="showClient(item)">/</a>
                  </td>
                  <td>{{ item.planname }}</td>
                  <td>{{ item.plantype }}</td>
                </tr>
              </tbody>
              <tbody v-else-if="items.length < 1 && filterItems">
                <v-col md="12" cols="12">
                  <v-alert
                    border="bottom"
                    colored-border
                    type="warning"
                    elevation="2"
                    class="text-gray"
                  >
                    Sorry! No results found
                  </v-alert>
                </v-col>
              </tbody>

              <tbody v-else>
                <v-col md="12" v-if="!loader">
                  There are no health plans currently.
                </v-col>
                <v-row v-else class="justify-center col-md-12 mt-5">
                  <v-progress-circular
                    :size="100"
                    class="text-center"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-row>
              </tbody>
            </template>
          </v-simple-table>
          <div v-else-if="addNew" class="row justify-center mt-5">
            <div class="col-md-8">
              <v-text-field
                outlined
                label="Plan Name"
                v-model="planname"
                hide-details=""
              ></v-text-field>
            </div>
            <div class="col-md-8">
              <v-text-field
                outlined
                hide-details=""
                type="number"
                label="Plan Id"
                v-model="planid"
              ></v-text-field>
            </div>
            <div class="col-md-8">
              <div class="row col-md-12">
                Plan Type:

                <span class="ml-4 mr-1">HSA</span>

                <div
                  class="text-end"
                  :class="
                    plantype == 'HSA' ? 'fill-blue-checkbox' : 'blue-checkbox'
                  "
                  @click="plantype = 'HSA'"
                ></div>
                <span class="ml-2 mr-1"> Non HSA</span>
                <div
                  class="text-end"
                  :class="
                    plantype == 'Non-HSA'
                      ? 'fill-blue-checkbox'
                      : 'blue-checkbox'
                  "
                  @click="plantype = 'Non-HSA'"
                ></div>
              </div>
            </div>
            <div class="col-md-8">
              <v-select
                outlined
                hide-details=""
                label="TPA / Carrier Admin"
                :items="allTpas"
                item-text="tpaname"
                item-value="_id"
                v-model="tpas"
                @change="fillEmps"
              >
                <template v-slot:prepend-item>
                  <div class="fixed-list-item">
                    <v-list-item>
                      <v-list-item-content class="p-2">
                        <v-text-field
                          dense
                          placeholder="Search"
                          @input="searchTpas"
                        ></v-text-field>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </div>
                </template>
              </v-select>
            </div>
            <div class="col-md-8">
              <v-select
                outlined
                :items="employers"
                item-text="employername"
                item-value="_id"
                multiple
                hide-details=""
                label="Employer Client"
                v-model="selectedEmployers"
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.employername }} - {{ data.item.groupid }}
                </template>
              </v-select>
            </div>
            <div class="col-md-8 mb-10">
              <v-btn class="blue-buttons" @click="addNewTPA" block
                >Add New</v-btn
              >
            </div>
          </div>
          <div v-else-if="clientDetails" class="row justify-center mt-5">
            <div class="col-md-8">
              <v-text-field
                outlined
                label="Plan Name"
                v-model="planname"
                hide-details=""
              ></v-text-field>
            </div>
            <div class="col-md-8">
              <v-text-field
                outlined
                type="number"
                hide-details=""
                label="Plan Id"
                v-model="planid"
              ></v-text-field>
            </div>
            <div class="col-md-8">
              <div class="row col-md-12">
                Plan Type:

                <span class="ml-4 mr-1">HSA</span>

                <div
                  class="text-end"
                  :class="
                    plantype == 'HSA' ? 'fill-blue-checkbox' : 'blue-checkbox'
                  "
                  @click="plantype = 'HSA'"
                ></div>
                <span class="ml-2 mr-1"> Non HSA</span>
                <div
                  class="text-end"
                  :class="
                    plantype == 'Non-HSA'
                      ? 'fill-blue-checkbox'
                      : 'blue-checkbox'
                  "
                  @click="plantype = 'Non-HSA'"
                ></div>
              </div>
            </div>
            <div class="col-md-8">
              <v-select
                outlined
                hide-details=""
                label="TPA / Carrier Admin"
                :items="allTpas"
                item-text="tpaname"
                item-value="_id"
                v-model="tpas"
                @change="fillEmps"
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.tpaname }} - {{ data.item.payerid }}
                </template>
                <template slot="item" slot-scope="data">
                  {{ data.item.tpaname }}
                </template>
                <template v-slot:prepend-item>
                  <div class="fixed-list-item">
                    <v-list-item>
                      <v-list-item-content class="p-2">
                        <v-text-field
                          dense
                          placeholder="Search"
                          @input="searchTpas"
                        ></v-text-field>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </div>
                </template>
              </v-select>
            </div>
            <div class="col-md-8">
              <v-select
                outlined
                :items="employers"
                item-text="employername"
                item-value="_id"
                hide-details=""
                multiple
                :disabled="changeTpa"
                label="Employer Client"
                v-model="selectedEmployers"
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.employername }} - {{ data.item.groupid }}
                </template>
              </v-select>
            </div>
            <div class="col-md-8 mb-10">
              <v-btn class="blue-buttons" block @click="updateItem"
                >Update</v-btn
              >
            </div>
          </div>
        </div>
      </v-card>

      <v-dialog v-model="dialog" width="500">
        <v-card>
          <v-card-title class="">
            Are you sure you want to delete?
          </v-card-title>

          <v-card-text class="mt-5 mb-5">
            <v-btn class="blue-buttons" block @click="removeItem">
              YES - DELETE</v-btn
            >
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <a class="text-underline mt-3 mb-3" @click="dialog = false">
              Cancel
            </a>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <successModal
        ref="success_modal"
        v-on:confirm="closeSuccessModal"
      ></successModal>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import Header from "../../../DigitalVendorInvoices/Layout/HeaderPage.vue";
import api from "../../../../../services/api";
import BlueHeader from "../BlueHeader.vue";
import successModal from "../../../../TablesTemplate/SuccessDialog.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      showDetails: false,
      dialog: false,
      items: [],
      singleItem: null,
      sortBy: "",
      type: "",
      solutions: [],
      favorites: [],
      filterItems: false,
      filteredArray: [],
      selectedItem: 1,
      tableClients: true,
      addNew: false,
      clientDetails: false,
      dialog: false,
      tpaName: "",
      payerId: "",
      carrierAdmin: "",
      plantype: "",
      planname: "",
      planid: "",
      employerClient: "",
      employers: [],
      filterArray: [],
      selectedEmployers: [],
      filterEmp: [],
      loader: true,
      allTpas: [],
      filterTpas: [],
      tpas: null,
      allEmployers: [],
      changeTpa: true,
    };
  },
  components: {
    Header,
    BlueHeader,
    successModal,
  },
  computed: {
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
    ...mapGetters(["getClearingHouseDetails"]),
  },
  created() {
    this.getData();
    this.getEmployers();
    this.getTpas();
  },
  methods: {
    fillEmps(item) {
      let sel = null;
      this.selectedEmployers = [];
      this.changeTpa = true;
      this.employers = [];
      this.allTpas.forEach((el) => {
        if (el._id == item) {
          sel = el;
        }
      });
      if (sel && sel.employers.length > 0) {
        this.allEmployers.forEach((el) => {
          sel.employers.forEach((el1) => {
            if (el._id == el1) {
              this.employers.push(el);
            }
          });
        });
      } else {
        this.employers = [];
      }
      this.changeTpa = false;
    },
    searchTpas(val) {
      this.allTpas = this.filterTpas.filter((el) => {
        if (
          el["tpaname"] &&
          el["tpaname"].toString().toLowerCase().includes(val.toLowerCase())
        ) {
          return el["tpaname"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        }
      });
    },
    getTpas(item) {
      api()
        .get("/clearinghouse/tpas")
        .then((res) => {
          this.allTpas = res.data;
          this.filterTpas = res.data;
          if (this.getClearingHouseDetails) {
            this.showClient(this.getClearingHouseDetails);
            this.$store.commit("setClientDetails", null);
          }
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    },
    searchEmployers(val) {
      this.employers = this.filterEmp.filter((el) => {
        if (
          el["employername"] &&
          el["employername"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el["employername"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        }
      });
    },
    getData() {
      this.loader = true;
      api()
        .get("/clearinghouse/plans")
        .then((res) => {
          if (res) {
            this.items = res.data;
            this.filterArray = res.data;
            this.loader = false;
          }
        });
    },
    removeItem() {
      api()
        .delete("/clearinghouse/plans/" + this.tpaId)
        .then((res) => {
          if (res) {
            this.dialog = false;
            this.$refs.success_modal.openPopUp(
              "Health Plan removed successfully"
            );
          }
        });
    },
    updateItem() {
      api()
        .put("/clearinghouse/plans/" + this.tpaId, {
          planname: this.planname,
          planid: this.planid,
          plantype: this.plantype,
          employer: this.selectedEmployers,
          tpa: this.tpas,
        })
        .then((res) => {
          if (res) {
            this.$refs.success_modal.openPopUp(
              "Health Plan updated successfully"
            );
          }
        });
    },
    closeSuccessModal() {
      this.getData();
      this.$refs.success_modal.closePopUp();
      this.clientDetails = false;
      this.addNew = false;
      this.showDetails = false;
      this.tableClients = true;
      this.clientDetails = false;
    },
    addNewTPA() {
      if (this.planname && this.planid) {
        api()
          .post("/clearinghouse/plans", {
            planname: this.planname,
            planid: this.planid,
            plantype: this.plantype,
            employer: this.selectedEmployers,
            tpa: this.tpas,
          })
          .then((res) => {
            if (res) {
              this.$refs.success_modal.openPopUp(
                "Health Plan added successfully"
              );
            }
          });
      }
    },
    closeSuccessModal() {
      this.getData();
      this.$refs.success_modal.closePopUp();
      this.addNew = false;
      this.showDetails = false;
      this.clientDetails = false;
      this.tableClients = true;
    },
    getEmployers() {
      api()
        .get("/clearinghouse/employers")
        .then((res) => {
          if (res) {
            this.employers = res.data;
            this.filterEmp = res.data;
            this.allEmployers = res.data;
          }
        });
    },
    showClient(item) {
      this.tpaId = "";
      this.planid = "";
      this.plantype = "";
      this.planname = "";
      this.changeTpa = true;
      this.tpas = [];
      this.selectedEmployers = [];
      this.getTpas();

      this.addNew = false;
      this.tableClients = false;
      this.clientDetails = true;

      this.tpaId = item._id;
      this.planid = item.planid;
      this.plantype = item.plantype;
      this.planname = item.planname;

      if (item.employer) {
        this.employers.forEach((el) => {
          item.employer.forEach((el1) => {
            if (el._id == el1) {
              this.selectedEmployers.push(el);
            }
          });
        });
      }

      if (item.tpa) {
        this.allTpas.forEach((el) => {
          if (el._id == item.tpa) {
            this.tpas = el;
          }
        });
      }
    },

    invite() {},
    add() {},
    exportFun() {
      this.exportData("tblData");
    },

    searchMembers() {},

    searchInput(val) {
      this.items = this.filterArray.filter((el) => {
        if (
          el["planid"] &&
          el["planid"].toString().toLowerCase().includes(val.toLowerCase())
        ) {
          return el["planid"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el["planname"] &&
          el["planname"].toString().toLowerCase().includes(val.toLowerCase())
        ) {
          return el["planname"]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        }
      });
      if (this.items.length < 1) {
        this.filterItems = true;
      } else {
        this.filterItems = false;
      }
    },
  },
};
</script>
<style>
.div-shadow {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
</style>