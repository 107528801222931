<template>
  <div>
    <div class="row blue-underline margin-l-11">
      <div class="col-md-12 d-flex mt-3 p-0">
        <img
          id="logo-img"
          :src="'/newapalylogoblue.png'"
          class="apaly-pic pointer ml-3 mr-3"
        />
        <h3 class="col-7 form-title title-color font ml-2 mt-2 font-18"></h3>
      </div>
    </div>
    <div class="form-div mt-100">
      <v-form
        class="col-md-12 col-xl-12 col-lg-12 text-center mt-10"
        ref="form"
      >
        <div class="mt-10">
          <div class="col-md-12 mb-20 mt-10">
            <div class="mt-10">
              <h1>
                <br />
                <h2 class="login">Please wait!</h2>
              </h1>
            </div>
            <div class="mt-5">
              <h2>
                <v-progress-circular
                  v-if="loader"
                  indeterminate
                  :size="50"
                  color="primary"
                  class="text-center"
                ></v-progress-circular>
              </h2>
            </div>
          </div>
        </div>
      </v-form>
    </div>
  </div>
</template>
  <script>
import api from "../../services/api";
import Vue from "vue";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      message: "",
      loader: true,
    };
  },
  created() {
    console.log(this.$route.query);
    if (this.$route.query.token) {
      this.$cookies.set("token", this.$route.query.token);
      api()
        .post(`auth/login/token`, { token: this.$route.query.token })
        .catch((err) => {
          if (err) {
            console.log(err.response);
          }
        })
        .then((res) => {
          if (res) {
            res.data.type = "tpa";
            this.$cookies.set("token", res.data.accessToken);
            localStorage.setItem("user", JSON.stringify(res.data));
            this.loader = false;

            // this.$router.push(`/tpa/clients/${this.$route.params.clientId}/solutions`)
            if (this.$route.query && this.$route.query.action == "activate") {
              this.$router.push({
                path: `/tpa/clients/${this.$route.params.clientId}/solutions`,
                query: {
                  action: this.$route.query.action,
                  filterBy: this.$route.query.filterBy,
                  vendorId: this.$route.query.vendor,
                },
              });
            } else {
              this.$router.push(
                `/tpa/clients/${this.$route.params.clientId}/solutions`
              );
            }
          }
        });
    }
  },
  methods: {},
};
</script>
  <style scoped>
</style>
  