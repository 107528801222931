<template>
    <div class="page-background">
      <main-header :title="'Jessie Smith'" :color="'#001E46'" :icon="'edit'" @editFromHeader="editMember"></main-header>
      <div class="mobile-content-background" style="overflow-y: auto" overflow-auto>
          <v-list>
            <v-list-item-group color="primary" >
              <v-list-item  >
                <v-list-item-content style="    padding-bottom: 80px;
">

                    <v-card style="background:#EBF1F9;margin-bottom: 15px;">
                        <v-card-title style="padding-bottom:0">
                            Family Member’s Information
                        </v-card-title>    
                        <div class="row"> 
                            <div class="col-6">
                                <v-card-subtitle style="padding-bottom:0">
                                    First Name
                                    <br>
                                   <span class="second-subtitle" >Jessie</span> 
                                </v-card-subtitle> 
                            </div>
                            <div class="col-6">
                                <v-card-subtitle style="padding-bottom:0">
                                    Last Name
                                    <br>
                                    <span class="second-subtitle" >Smith</span> 
                                </v-card-subtitle> 
                            </div>
                        </div>
                        <div class="row" style="margin-top:0"> 
                            <div class="col-6" >
                                <v-card-subtitle >
                                    Date of Birth
                                    <br>
                                    <span class="second-subtitle" >01/01/2020</span> 
                                </v-card-subtitle> 
                            </div>
                            <div class="col-6"  >
                                <v-card-subtitle >
                                    Gender
                                    <br>
                                    <span class="second-subtitle" >Female</span> 

                                </v-card-subtitle> 
                            </div>
                        </div>                      
                    </v-card>

                    <v-card style="background:#EBF1F9;margin-bottom: 15px;">
                        <v-card-title style="padding-bottom:0">
                            Contact     
                        </v-card-title>    
                        <div class="row"> 
                            <div class="col-12">
                                <v-card-subtitle style="padding-bottom:0">
                                    Email                                  
                                    <br>
                                    <span class="second-subtitle" >jessiesmith@gmail.com</span> 
                                </v-card-subtitle> 
                            </div>
                        </div>
                        <div class="row" style="margin-top:0"> 
                            <div class="col-6" >
                                <v-card-subtitle >
                                    Phone                                  
                                    <br>
                                    <span class="second-subtitle" >000-000-0000 </span> 
                                    </v-card-subtitle> 
                            </div>
                            <div class="col-6"  >
                                <v-card-subtitle >
                                    Home Zip Code                                  
                                    <br>
                                    <span class="second-subtitle" >00000</span> 
                                </v-card-subtitle> 
                            </div>
                        </div>
                    </v-card>

                    <v-card style="background:#EBF1F9;margin-bottom: 15px;">
                        <v-card-title style="padding-bottom:0">
                            Provider     
                        </v-card-title>    
                        <div class="row"> 
                            <div class="col-12">
                                <v-card-subtitle style="padding-bottom:0">
                                    Provider                                  
                                    <br>
                                    <span class="second-subtitle" >Everside Health</span> 
                                </v-card-subtitle> 
                            </div>
                        </div>
                        <div class="row" style="margin-top:0"> 
                            <div class="col-12" >
                                <v-card-subtitle >
                                    Location                                  
                                    <br>
                                    <span class="second-subtitle" >Main Street West</span> 
                                </v-card-subtitle> 
                            </div>
                        </div>
                    </v-card>

                  
                    <v-card style="background:#EBF1F9;margin-bottom: 15px;">
                        <v-card-title style="padding-bottom:0">
                            Insurance Information
                        </v-card-title>    
                        <div class="row"> 
                            <div class="col-6">
                                <v-card-subtitle style="padding-bottom:0">
                                    Member ID
                                    <br>
                                   <span class="second-subtitle" >000-000000</span> 
                                </v-card-subtitle> 
                            </div>
                            <div class="col-6">
                                <v-card-subtitle style="padding-bottom:0">
                                    Group ID
                                    <br>
                                    <span class="second-subtitle" >000-000000</span> 
                                </v-card-subtitle> 
                            </div>
                        </div>
                        <div class="row" style="margin-top:0"> 
                            <div class="col-6" >
                                <v-card-subtitle >
                                    Payer ID
                                    <br>
                                    <span class="second-subtitle" >000-000000</span> 
                                </v-card-subtitle> 
                            </div>
                            <div class="col-6"  >
                                <v-card-subtitle >
                                    Employer
                                    <br>
                                    <span class="second-subtitle" >[Employer Name]</span> 

                                </v-card-subtitle> 
                            </div>
                        </div>                      
                    </v-card>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </div>
</template>

<script>
import mainHeader from "@/components/Sections/DPCMember/Layout/mainHeader";
export default {
  data() {
    return {
      member:{},
      id:this.$route.params.id,
    };
  },
  components:{
    mainHeader
  },
  methods: {
    editMember(){
      this.$router.push(`/dpc-member/account/family-members/edit/${this.id}`)
    }
  },
  created() {
    console.log(this.$route.params.id)
  },
};
</script>
<style scoped src='./../../../../../assets/mobileStyle.css'></style>
<style scoped lang="scss">
  .mobile-content-background{
    background-color:white;
    height:100vh;
  }
  .mobile-content{
    padding:16px 24px 0 24px;
  }
  .page-background{
    background-color: #E8E8E8
;
  }
  .list-item-title{
    color:#0573F3;
    font-family: 'Quicksand';
    font-weight: 700;
  }

  .list-item-title{
    color:#0573F3;
    font-family: 'Quicksand';
    font-weight: 700;
  }

  .list-item-subtitle{
    font-family: 'Quicksand';
    font-weight: 600;
  }

  .second-subtitle{
    color:#001E46;font-family: 'Quicksand';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
  }
</style>