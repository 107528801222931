<template>
    <div class="no-padding no-margin">
        
        <ProvidersTable v-if="path == '/provider-advisor/providers'"></ProvidersTable>
        <ResultTable v-else-if="path == '/provider-advisor/providers/result'"></ResultTable>
        <AddProviders v-else-if="path == '/provider-advisor/providers/add-provider'"></AddProviders>
        <InviteProvider v-else-if="path == '/provider-advisor/providers/invite-provider'"></InviteProvider>
        <ProviderCrudForm v-else-if="path == '/provider-advisor/providers/provider'"></ProviderCrudForm>
        <AddManually v-else-if="path == '/provider-advisor/providers/add-manually'"></AddManually>
        <SingleNpi v-else-if="path == '/provider-advisor/providers/result/npi'"></SingleNpi>
        <Tabs v-else></Tabs>
      
    </div>
</template>
<script>
import AddProviders from './AddProviders.vue'
import InviteProvider from './InviteProvider.vue'
import ProviderCrudForm from './ProviderCrudForm.vue'
import ResultTable from './ResultTable.vue'
import ProvidersTable from './ProvidersTable.vue'
import SingleNpi from './SingleNpi.vue'
import AddManually from './AddManually.vue'
import Tabs from './Tabs.vue'



export default {
    components:{
    ResultTable,
    AddProviders,
    InviteProvider,
    ProviderCrudForm,
    ProvidersTable,
    SingleNpi,
    Tabs,
    AddManually


    },
    data(){
        return{
            
        }
    },
    computed:{
        path(){
            return this.$route.path
        },
        routeName(){
            return this.$route.params.name
        }
    },
   
    created(){
      
    },
    methods:{
     
    }
   
}
</script>
<style>
.background-opacity{
    opacity: 0.46;
    background-color: rgb(33, 33, 33);
    border-color: rgb(33, 33, 33);
}
</style>