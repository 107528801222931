<template>
  <div class="white-toolbar justify-space-between d-flex">
    <div v-if="$route.path == '/vendors/account'">
      <router-link class="font-quicksand-medium-14" to="/vendors/home">
        Home
      </router-link>
      <span class="text-gray font-quicksand-medium-14"
        >\
        {{
          $route.path.split("/")[2].charAt(0).toUpperCase() +
          $route.path.split("/")[2].slice(1)
        }}</span
      >
    </div>
    <div v-else-if="showRoutePathForFilter">
      <router-link
        class="font-quicksand-medium-14"
        @click="$store.commit('setNavItemFilter', false)"
        to="/vendors/home"
      >
        Home
      </router-link>
      <a
        v-if="$route.path.includes('vendors/invoices')"
        class="font-quicksand-medium-14"
        @click="$store.commit('setNavItemFilter', false)"
      >
        \ <span class="text-underline"> Invoices </span>
      </a>

      <span
        v-if="$route.path.includes('vendors/invoices')"
        class="text-gray font-quicksand-medium-14"
        >\ Invoice Filters</span
      >

      <a
        v-if="$route.path.includes('vendors/members')"
        class="font-quicksand-medium-14"
        @click="$store.commit('setNavItemFilter', false)"
      >
        \ <span class="text-underline"> Members </span>
      </a>

      <span
        v-if="$route.path.includes('vendors/members')"
        class="text-gray font-quicksand-medium-14"
        >\ Members Filters</span
      >

      <router-link
        v-if="$route.path.includes('vendors/account')"
        class="font-quicksand-medium-14"
        to="/vendors/account"
      >
        \ <span> Account </span>
      </router-link>
      <a
        v-if="$route.path.includes('vendors/account/contract')"
        class="font-quicksand-medium-14"
        @click="$store.commit('setNavItemFilter', false)"
      >
        \ <span class="text-underline"> Contracts </span>
      </a>
      <span
        v-if="$route.path.includes('vendors/account/contract')"
        class="text-gray font-quicksand-medium-14"
        >\ Contracts Filters</span
      >

      <a
        v-if="$route.path.includes('vendors/account/users')"
        class="font-quicksand-medium-14"
        @click="$store.commit('setNavItemFilter', false)"
      >
        \ <span class="text-underline"> Users </span>
      </a>
      <span
        v-if="$route.path.includes('vendors/account/users')"
        class="text-gray font-quicksand-medium-14"
        >\ Users Filters</span
      >
    </div>
    <div v-else-if="showRoutePathForEmailOverlay">
      <router-link class="font-quicksand-medium-14" to="/vendors/home">
        <span
          @click="
            $store.commit('setOverlayItemFilter', false);
            store.commit('setNavItemFilter', false);
          "
        >
          Home</span
        >
      </router-link>
      <a
        class="font-quicksand-medium-14"
        @click="$store.commit('setOverlayItemFilter', false)"
      >
        \ <span class="text-underline"> Invoices </span>
      </a>

      <span class="text-gray font-quicksand-medium-14"
        >\ Email or Fax an Invoice</span
      >
    </div>

    <div v-else-if="$route.path == '/vendors/home'">
      <span class="text-gray font-quicksand-medium-14">Home</span>
    </div>
    <div v-else>
      <span v-for="(route, index) in $route.matched" :key="index">
        <span v-if="route && route.name">
          <router-link
            class="font-quicksand-medium-14"
            v-if="index != Object.keys($route.matched).length - 1"
            :to="route.path"
          >
            <span>
              {{ route.name }}
            </span>
          </router-link>
          <span class="text-gray font-quicksand-medium-14" v-else>
            <span>
              {{ route.name }}
            </span>
          </span>
          <span
            class="text-gray font-quicksand-medium-14"
            v-if="index != Object.keys($route.matched).length - 1"
          >
            \
          </span>
        </span>
      </span>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },

  props: {
    clientName: {
      type: String,
    },
  },
  computed: {
    ...mapGetters(["showRoutePathForFilter", "showRoutePathForEmailOverlay"]),
    params() {
      let emp = "";

      return emp;
    },
    singleEmployer() {
      let emp = "";
      let name = "";
      if (localStorage.getItem("employer")) {
        emp = JSON.parse(localStorage.getItem("providerName"));
        name = emp;
      } else if (this.$route.params.name) {
        name = this.$route.params.name;
      }

      return name;
    },
    singleProvider() {
      let pro = "";
      let name = "";
      if (localStorage.getItem("provider")) {
        pro = JSON.parse(localStorage.getItem("providerName"));
        name = pro;
      } else if (this.$route.params.name) {
        name = this.$route.params.name;
      }

      return name;
    },
  },
  created() {},
};
</script>
<style>
.light-blue {
  color: #109cf1 !important;
}
</style>