<template>
    <div class="no-padding no-margin">
        <Members v-if="route == `/employer-advisor/employers/${routeParams}/members`"></Members>
        <Member v-if="route == `/employer-advisor/employers/${routeParams}/members/member`"></Member>
        <AddMembers v-if="route == `/employer-advisor/employers/${routeParams}/members/add-new`"></AddMembers>
        <InviteMembers v-if="route == `/employer-advisor/employers/${routeParams}/members/invite-members`"></InviteMembers>
        <Upload v-if="route == `/employer-advisor/employers/${routeParams}/members/upload`"></Upload>
        <AddDependent v-if=" route == `/employer-advisor/employers/${routeParams}/members/dependents`"></AddDependent>
        <SingleDependent v-if=" route == `/employer-advisor/employers/${routeParams}/members/dependents/dependent`"></SingleDependent>
        <AddZipCodes v-if=" route == `/employer-advisor/employers/${routeParams}/members/add-zip-codes`" />
        <Result v-if="route == `/employer-advisor/employers/${routeParams}/members/result`"></Result>
    </div>
</template>
<script>
import Members from './Members.vue'
import Member from './Member.vue'
import Search from './AdvancedSearch.vue'
import AddMembers from './AddMember.vue'
import InviteMembers from './InviteMembers.vue'
import Upload from './UploadCensus.vue'
import AddDependent from './AddDependent.vue'
import SingleDependent from './SingleDependent.vue'
import AddZipCodes from './AddZipCodes.vue'
import Result from './ResultTable.vue'

export default{
    components:{
        Members,
        Member,
        Search,
        AddMembers,
        InviteMembers,
        Upload,
        AddDependent,
        SingleDependent,
        AddZipCodes,
        Result
       
    },
    
    computed:{
      route(){
           return this.$route.path.replace(/%20/g,' ')
      },
      routeParams(){
         return this.$route.params.name
      }
      
    },
    data(){
        return{

        }
    },

}
</script>