<template>
  <div>
    <Header :title="'Health Plan Detail'"></Header>
    <div class="col-md-12">
      <div class="col-md-12">
        <v-form v-model="valid" ref="form" class="row gray-border-form form-row-height">
          <div class="col-md-6">
            <div class="col-md-10">
              <div class="col-md-12 pb-0">
                <label for="">Plan Name</label>
                <v-text-field :rules="requiredRules" solo v-model="plan.name"></v-text-field>
              </div>
              <div class="col-md-12 pt-0 pb-0">
                <label for="">Plan ID</label>
                <v-text-field :rules="requiredRules" solo v-model="plan.planId"></v-text-field>
              </div>
              <div class="col-md-12 pt-0 pb-0">
                <label for="">Plan Type</label>
                <v-select solo :rules="requiredRules" v-model="plan.type" :items="typeItems"></v-select>
              </div>
            </div>
          </div>
       
          <div class="col-md-6">
            <div class="col-md-10">
              <div class="row col-md-12 no-padding">
                <div class="col-md-10 font">
                  <label for="" class="text-gray">Health Plan Status</label>
                  <div class="d-flex mt-2 font-weight-bold">
                    Inactive 
                    <v-switch @change="planDeactivation()"
                     v-model="plan.status" 
                     false-value="inactive"
                      true-value="active" 
                      solo 
                      class="no-padding ml-2 mr-2 vendors-switch">
                    </v-switch>
                    Active
                  </div>
                </div>

                <!-- <div class="mt-5 text-end">
                  <br /><br /><br />
                  <span @click="removeItem" class="text-underline light-blue-text font-weight-bold font-18 col-md-12 pointer">
                    Remove Health Plan
                  </span>
                </div> -->
              </div>
            </div>
          </div>
        </v-form>
      </div>
    </div>
    <div class="col-md-12">
      <div class="col-md-12 mb-4" align="end">
        <span class="light-blue-text text-underline pointer font-weight-bold mr-10 font-18" @click="goBack">
          Cancel
        </span>
        <span class=" light-blue-text text-underline pointer font-weight-bold mr-5 font-18 pointer" @click="update">Save / Update</span>
      </div>
    </div>
    <v-dialog v-model.trim="pendingMember" width="600" class="success-modal">
      <v-card>
        <v-card-text class="success-modal pt-5">
          <div class="display mt-3">
              <span class="text-center font-18 mt-2 font-weight-bold color-black mt-5">
            You cannot deactivate/remove this plan. You have a member with pending status
          </span>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model.trim="activeInactive" width="600" class="success-modal">
      <v-card>
        <v-card-text class="success-modal pt-5">
          <div class="display mt-3 text-center mb-3">
              <span class="text-center font-18 mt-2 font-weight-bold color-black mt-5">
               Are you sure you want to change plan status?
          </span>
        </div>
          <div class="text-center col-12">
          <span class="col-6 font-18 mt-2 font-weight-bold color-black mt-5">
            <v-btn @click="yesBtn" color="#16407A"
            class="yes-btn">Yes</v-btn>
          </span>
          <span class="col-6 font-18 mt-2 font-weight-bold color-black mt-5">
            <v-btn @click="noBtn">No</v-btn>
          </span>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model.trim="deactivationModal" width="500" class="">
      <v-card class="pa-0">
        <v-card-text class=" justify-center pt-5">
          <h2 class="font-weight-bolder justify-center d-flex color-black mt-5 mb-2">Plan Deactivation Warning  </h2>
          <div class="col-12 display text-center justify-center d-flex mb-3">
              <span class="text-center font-18 mt-2 color-black mt-3 col-10">
                Deactivating a plan may have consequences on existing invoices and plan member services.
                 Please email <strong >support@apaly.com </strong> to deactivate this plan
          </span>
        </div>
          <div class="text-center col-12">
            <v-btn @click="noBtn()" color="#16407A"
            class="okay-btn">Okay</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <removeDialog ref="dialog_delete" v-on:confirm="confirmRemove"></removeDialog>
    <successModal ref="success_modal" v-on:confirm="closeSuccessModal"></successModal>
    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
  </div>
</template>
<script>
import Header from "../../../Layout/HeaderPage.vue";
import api from "../../../../../../services/api";
import successModal from "../../../../../TablesTemplate/SuccessDialog.vue";
import removeDialog from "../../../../../TablesTemplate/RemoveDialog.vue";
import errorModal from "../../../../../TablesTemplate/ErrorDialog.vue";

export default {
  components: { Header, successModal, removeDialog, errorModal },
  data() {
    return {
      valid: false,
      clients: [],
      pending: 0,
      activeInactive: false,
      deactivationModal: false,
      pendingMember: false,
      taxIdRules: [
        (v) => !!v || "This field is required",
        (v) =>
          !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
          "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
      ],
      zipRules: [
        (v) => (v && v.length == 5) || " Zip Code should be five digits long",
      ],
      states: [],
      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
      requiredRules: [(v) => !!v || "This field is required"],

      emailRules: [
        (v) => !!v || "This field is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      typeItems: ["HSA", "Non-HSA"],
      plan: {
        name: "",
        planId: "",
        type: "",
        groupId: "",
        admin: "",
        status: "",
      },
    };
  },
  computed: {
    singleItem() {
      return this.$store.getters.getSingleItem;
    },
    getSingleClient(){
      return this.$store.getters.getSingleClient
    }
  },
  created() {
    this.getStates();
    this.getClient();
    this.plan = this.singleItem;
    let user = JSON.parse(localStorage.getItem("user"));
    if(user && user.groupPortal &&  user.groupPortal[0].organization){
    this.plan.admin = user.companyName;

    }
    if(this.getSingleClient){
      this.plan.groupId = this.getSingleClient.groupId;

    }
  },
  methods: {
    getClient(){
      api().get(`/tpas/clients/${this.$route.params.clientId}/members`)
        .then((res) => {
          if (res) {
            this.clients = res.data;
          }
        });
    },
    goBack() {
      this.closeSuccessModal();
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
      this.$router.push(`/tpa/clients/${this.$route.params.clientId}/plans`);
      
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
      
    },
    update() {
      this.$refs.form.validate();
      if (this.valid) {
      api().put(`/tpas/clients/${this.$route.params.clientId}/plans/${this.singleItem._id}`, this.plan)
        .then((res) => {
          if (res) {
            if(this.plan.status == 'inactive')
            {
              this.$refs.success_modal.openPopUp("Plan was Deactivated");

            }
            else{
               this.$refs.success_modal.openPopUp("Successfully updated");
            }
           
          }
        });
      }
    },
    confirmRemove() {
      api().delete(`/tpas/clients/${this.$route.params.clientId}/plans/${this.singleItem._id}`)
      .catch(err => {
            this.$refs.error_modal.openPopUp(err.response.data)
        
      })
        .then((res) => {
          if (res) {
            this.$refs.success_modal.openPopUp("Successfully removed");
          }
        });
    },
    removeItem() {
      this.$refs.dialog_delete.openPopUp(
        "Are you sure that you want to remove this Health Plan?"
      );
    },
    ActiveInactive(){
      this.activeInactive = true;

    },
    planDeactivation(){
      this.deactivationModal = true;
      
    },
    yesBtn(){
      this.activeInactive = false;
    },
    noBtn(){
      this.deactivationModal = false;
      if( this.plan.status == 'active'){
        this.plan.status = 'inactive'
      }
     else if ( this.plan.status == 'inactive')
     {
      this.plan.status = 'active';
     }
    
      this.activeInactive = false;
    },
    getStates() {
      api().get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },
  },
};
</script>
<style scoped>
.yes-btn{
  color: white !important;
}
.okay-btn{
  width: 180px;
  color: white !important;
}
.color-black{
  color: black;
}
.gray-border-form {
  border-right: 2px solid rgba(179, 177, 177, 0.5);
  border-left: 2px solid rgba(179, 177, 177, 0.5);
  border-bottom: 2px solid rgba(179, 177, 177, 0.5);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
</style>