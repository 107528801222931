<template>
  <div class="no-padding row row-height">
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <div
      :class="
        overlay
          ? 'hideOnMoblie background-opacity col-md-9'
          : 'col-md-12 navigationWidth '
      "
    >
      <div class="">
        <v-row>
          <v-col class="col-xl-1 col-lg-2 col-md-2" cols="12">
            <h3>
              <span class="font">Providers</span>
            </h3>
          </v-col>
          <v-col class="col-xl-2 col-lg-3 col-md-6 mb-10" cols="12">
            <v-text-field
              v-if="filterArray.length > 0"
              class="input-control"
              prepend-inner-icon="mdi-magnify"
              @input="searchProviders"
              light
              dense
              outlined
              placeholder="Search Providers"
            >
            </v-text-field>
          </v-col>
          <v-col
            v-if="$role != 'user'"
            class="col-xl-9 col-lg-7 col-md-12 text-end"
            cols="12"
            align="end"
          >
            <v-btn
              class="my-1 blue-buttons"
              @click="$router.push(`/provider-advisor/providers/add-manually`)"
            >
              Add Providers
            </v-btn>
            <!-- <v-menu offset-y>
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" class="my-1 blue-buttons" :disabled="overlay" > Add Providers </v-btn>
            </template>
            <v-list>
                <v-list-item style="disply:block">
                    <a class="a-tag underline-text" @click="$router.push(`/provider-advisor/providers/add-manually`)">Add Manually</a>
                </v-list-item>
                <v-list-item>
                    <a class="a-tag underline-text"   @click="$router.push(`/provider-advisor/providers/invite-provider`)">Invite Providers</a>
                </v-list-item>
                <v-list-item>
                    <a class="a-tag underline-text"   @click="$router.push(`/provider-advisor/providers/add-provider`)">Upload a List of NPIs</a>
                </v-list-item>
                
                <v-list-item>
                    <a class="a-tag underline-text" >Copy Invite Link</a>
                </v-list-item> 
            </v-list>
         

        </v-menu>  -->
          </v-col>
        </v-row>
      </div>
      <div class="col-md-12 text-center justify-center" v-if="readyToShow">
        <v-progress-circular
          indeterminate
          :size="100"
          color="primary"
          class="text-center"
        ></v-progress-circular>
      </div>
      <div v-else>
        <v-row class="col-md-12" v-if="allProviders.length < 1 && !filterItems">
          <v-col md="12" v-if="$role == 'user'">
            <a @click="$router.push(`/provider-advisor/providers/add-manually`)"
              >You Don't have Providers.</a
            >
          </v-col>
          <v-col md="12" v-else>
            <a @click="$router.push(`/provider-advisor/providers/add-manually`)"
              >You Don't have Providers Yet. Click Here To Add Provider.</a
            >
          </v-col>
        </v-row>
        <v-simple-table v-else fixed-header height="630px">
          <template v-slot:default>
            <thead :class="overlay ? 'disabled-table' : ''">
              <tr>
                <th class="text-left">Provider Name</th>
                <th class="text-left">NPI Type</th>
                <th class="text-left">Speciality / Taxonomy</th>
                <th class="text-left">City</th>
                <th class="text-left">State</th>
                <th class="text-end" v-if="$role != 'user'">Actions</th>
              </tr>
            </thead>
            <tbody v-if="allProviders.length > 0">
              <tr
                v-for="(item, index) in allProviders"
                :key="index"
                :id="'id' + index"
              >
                <td v-if="item.displayName">
                  <a @click="singleProvider(item)">{{ item.displayName }} </a>
                </td>
                <td v-else><a @click="singleProvider(item)">/</a></td>
                <td class="text-gray">Type {{ item.type }}</td>
                <td
                  class="text-gray"
                  v-html="
                    item.taxonomy
                      ? item.taxonomy
                      : item.specialty
                      ? item.specialty
                      : '/'
                  "
                ></td>
                <td class="text-gray" v-html="item.city ? item.city : '/'"></td>
                <td
                  class="text-gray"
                  v-html="item.state ? item.state : '/'"
                ></td>
                <td v-if="$role != 'user'" class="text-end">
                  <svg
                    @click="singleProvider(item)"
                    class="pointer"
                    width="27"
                    height="27"
                    viewBox="0 0 27 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.9955 19.5396L6.87109 20.1296L7.45998 16.004L15.8611 7.6029C16.3299 7.13406 16.9658 6.87067 17.6289 6.87067C18.2919 6.87067 18.9278 7.13406 19.3966 7.6029C19.8655 8.07175 20.1289 8.70764 20.1289 9.37068C20.1289 10.0337 19.8655 10.6696 19.3966 11.1385L10.9955 19.5396Z"
                      stroke="#4072B7"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M1 13.5C1 16.8152 2.31696 19.9946 4.66117 22.3388C7.00537 24.683 10.1848 26 13.5 26C16.8152 26 19.9946 24.683 22.3388 22.3388C24.683 19.9946 26 16.8152 26 13.5C26 10.1848 24.683 7.00537 22.3388 4.66117C19.9946 2.31696 16.8152 1 13.5 1C10.1848 1 7.00537 2.31696 4.66117 4.66117C2.31696 7.00537 1 10.1848 1 13.5V13.5Z"
                      stroke="#4072B7"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <svg
                    class="ml-2 pointer mt-2"
                    @click="removeItem(item)"
                    width="28"
                    height="26"
                    viewBox="0 0 28 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.35547 13C1.35547 16.1826 2.65524 19.2348 4.96884 21.4853C7.28244 23.7357 10.4204 25 13.6923 25C16.9642 25 20.1021 23.7357 22.4157 21.4853C24.7293 19.2348 26.0291 16.1826 26.0291 13C26.0291 9.8174 24.7293 6.76516 22.4157 4.51472C20.1021 2.26428 16.9642 1 13.6923 1C10.4204 1 7.28244 2.26428 4.96884 4.51472C2.65524 6.76516 1.35547 9.8174 1.35547 13V13Z"
                      stroke="#D00000"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.75781 17.8002L18.6273 8.2002"
                      stroke="#D00000"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M18.6273 17.8002L8.75781 8.2002"
                      stroke="#D00000"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </td>
                <!-- <td v-else>
                <v-checkbox solo @click="activeRow(index, item)"></v-checkbox>
              </td> -->
              </tr>
            </tbody>
            <div v-if="allProviders.length < 1 && filterItems">
              <v-col md="12" cols="12">
                <v-alert
                  border="bottom"
                  colored-border
                  type="warning"
                  elevation="2"
                  class="text-gray"
                >
                  Sorry! No results found
                </v-alert>
              </v-col>
            </div>
          </template>
        </v-simple-table>

        <v-row class="justify-end mt-3" v-if="!noDataMessage && showSaveButton">
          <v-btn class="blue-buttons" @click="addProviders()"
            >Save & Apply</v-btn
          >
        </v-row>
      </div>
    </div>

    <v-dialog v-model.trim="dialog" max-width="600px" persistent>
      <v-card>
        <v-col>
          <h3>Remove Provider</h3>
        </v-col>

        <v-col>
          <span>Are you sure you want to delete this Provider?</span>
        </v-col>

        <v-col>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="blue-buttons float-class" @click="confirmRemove"
              >Confirm</v-btn
            >
            <v-btn class="button-style float-class" @click="closePopUp"
              >Close</v-btn
            >
          </v-card-actions>
        </v-col>
      </v-card>
    </v-dialog>
    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
  </div>
</template>
<script>
import api from "../../../../services/api";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../TablesTemplate/ErrorDialog.vue";
export default {
  components: { successModal, errorModal },
  data() {
    return {
      ratingValue: 2,
      items: [],
      manualOverrideProviders: [
        {
          text: "Manual Override",
          value: true,
        },
      ],
      prefferedProviderItems: [
        {
          text: "Preferred Provider",
          value: true,
        },
      ],
      readyToShow:false,
      filter: {
        type: "",
        speciality: "",
        rating: 0,
        distance: "",
        status: "",
        preferredProvider: false,
        manualOverride: false,
      },
      showSaveButton: false,
      filterMatchingStatus: [
        {
          text: "Matched",
          value: "matched",
        },
        {
          text: "Not Matched",
          value: "notMatched",
        },
      ],
      noDataMessage: "",
      selectedArray: [],
      filteredItems: false,
      panel: [0, 1, 2, 3, 4, 5],
      providerType: [
        {
          text: "Hospital",
          value: "hospital",
        },
        {
          text: "Doctor Office",
          value: "doctorOffice",
        },
        {
          text: "Imagining",
          value: "imaging",
        },
        {
          text: "Laboratory",
          value: "laboratory",
        },
        {
          text: "Other",
          value: "other",
        },
      ],
      selectedRows: [],
      allProviders: [],
      display: true,
      overlay: false,
      windowWidth: window.innerWidth,
      dialog: false,
      filterArray: [],
      itemToRemove: null,
      itemById: {},
      index: 0,
      filterItems: false,
    };
  },

  computed: {
    path() {
      return this.$route.path;
    },
    getClient() {
      return this.$store.getters.getClientGetter;
    },
    routeParams() {
      return this.$route.params.name;
    },

    overlayClass() {
      let str = "";
      if (this.display) {
        str = "col-md-12";
        if (document.getElementById("hideOnMobile")) {
          document.getElementById("hideOnMobile").style.display =
            "none !important";
        }
      } else if (this.overlay) {
        str = "col-md-9 disabled-table";
      } else if (!this.display) {
        str = "col-md-12";
      } else {
        str = "col-md-12";
      }
      return str;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  created() {
    if (this.windowWidth < 600) {
      this.display = false;
    } else {
      this.display = true;
    }
    this.getAllProviders();
  },

  methods: {
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    removeItem(item) {
      this.dialog = true;
      this.itemToRemove = item;
    },
    closeSuccessModal() {
      this.itemById = document.getElementById("id" + this.index);
      this.itemById.classList.remove("activeItem");
      this.$refs.success_modal.closePopUp();
      this.getAllProviders();
      this.overlay = false;
      this.showSaveButton = false;
    },
    clearAll() {
      this.filter.type = "";
      this.filter.distance = "";
      this.filter.rating = 0;
      this.filter.status = "";
      this.filter.preferredProvider = false;
      this.filter.manualOverride = false;
    },
    removeFilter(type) {
      if (type == "type") {
        this.filter.type = "";
      } else if (type == "distance") {
        this.filter.distance = "";
      } else if (type == "rating") {
        this.filter.rating = 0;
      } else if (type == "status") {
        this.filter.status = "";
      } else if (type == "preferred") {
        this.filter.preferredProvider = false;
      } else if (type == "manual") {
        this.filter.manualOverride = false;
      }
    },
    activeRow(index, item) {
      this.index = index;
      this.itemById = document.getElementById("id" + index);
      this.itemById.classList.toggle("activeItem");

      if (this.itemById.classList.contains("activeItem")) {
        this.selectedRows.push(item._id);
      } else {
        let removeItem = this.selectedRows.find((x) => x === item._id);
        this.selectedRows.splice(this.selectedRows.indexOf(removeItem), 1);
      }
    },
    addProviders() {
      let data = {
        search: "provider",
        ids: this.selectedRows,
      };

      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.providerAdvisor;

      api()
        .post(`/advisors/${advisorId}/providers`, data)
        .then((res) => {
          let text = "Providers added successfully to this Employer!";
          this.$refs.success_modal.openPopUp(text);
        });
    },

    filterProviders() {
      this.filteredItems = false;
      let urlData = ``;
      urlData += this.filter.type
        ? `providerType=${this.filter.type.value}&`
        : "";
      urlData += this.filter.distance
        ? `distance=${this.filter.distance}&`
        : "";
      urlData += this.filter.rating
        ? `qualityRating=${this.filter.rating}&`
        : "";
      urlData += this.filter.status
        ? `status=${this.filter.status.value}&`
        : "";
      urlData += this.filter.preferredProvider
        ? `preferred=${this.filter.preferredProvider.value}&`
        : "";
      urlData += this.filter.manualOverride
        ? `manualOverride=${this.filter.manualOverride.value}&`
        : "";

      api()
        .get(`/advisors/providers/search?${urlData}`)
        .then((res) => {
          if (res) {
            if (res.data.providers.length < 1) {
              this.noDataMessage = "No Result Found. Try Again";
              this.allProviders = [];
              this.showSaveButton = false;
              this.filterArray = [];
            } else {
              this.allProviders = res.data.providers;
              this.filterArray = res.data.providers;
              this.showSaveButton = true;
            }

            this.overlay = false;
          }
        });
    },
    getAllProviders() {
      this.readyToShow = true;
      this.filteredItems = true;
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.providerAdvisor;
      let employer = JSON.parse(localStorage.getItem("employer"));
      api()
        .get(`/advisors/${advisorId}/providers`)
        .then((res) => {
          if (res) {
            this.allProviders = res.data.providers;
            this.filterArray = res.data.providers;
            this.readyToShow = false;
          }
        });
    },
    searchProviders(val) {
      this.allProviders = this.filterArray.filter((el) => {
        if (
          el.displayName &&
          el.displayName.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.displayName.toLowerCase().includes(val.toLowerCase());
        } else if (
          el.city &&
          el.city.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.city.toLowerCase().includes(val.toLowerCase());
        } else if (
          el.state &&
          el.state.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.state.toLowerCase().includes(val.toLowerCase());
        } else if (
          el.taxonomy &&
          el.taxonomy.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.taxonomy.toLowerCase().includes(val.toLowerCase());
        }
      });
      if (this.allProviders.length < 1) {
        this.filterItems = true;
      }
    },
    singleProviderHorizontalMenu(item) {
      localStorage.setItem("provider", JSON.stringify(item));
      if (item.providerName) {
        this.$router.push({
          path: "/provider-advisor/providers/" + item.providerName,
          params: { name: item.providerName },
        });
      } else {
        this.$router.push({
          path: "/provider-advisor/providers/" + item.companyName,
          params: { name: item.companyName },
        });
      }
    },
    singleProvider(item) {
      if (!this.overlay && !this.showSaveButton) {
        let user = JSON.parse(localStorage.getItem("user"));
        let advisorId = user.groupPortal[0].organization.providerAdvisor;
        let employer = JSON.parse(localStorage.getItem("employer"));
        api()
          .get(`/advisors/${advisorId}/providers/${item._id}`)
          .then((res) => {
            if (res) {
              this.$store.commit(
                "setSingleProviderInEmployerAdvisor",
                res.data.provider
              );
              this.$router.push(`/provider-advisor/providers/provider`);
            }
          });
      }
    },
    confirmRemove() {
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.providerAdvisor;
      let employer = JSON.parse(localStorage.getItem("employer"));
      api()
        .delete(`/advisors/${advisorId}/providers/${this.itemToRemove._id}`)
        .catch((err) => {
          if (err) {
            this.$refs.error_modal.openPopUp(err.response.data.message);
            this.dialog = false;
          }
        })
        .then((res) => {
          if (res) {
            api()
              .get(`/advisors/${advisorId}/providers`)
              .then((res) => {
                if (res) {
                  this.allProviders = res.data.providers;
                  this.filterArray = res.data.providers;
                  this.dialog = false;
                }
              });
          }
        });
    },
    closePopUp() {
      this.dialog = false;
    },
    openFilterMenu() {
      this.overlay = !this.overlay;
      this.$store.commit("showFilterMenu", this.overlay);
    },
    changeColor(status) {
      let myClass = "";
      if (status == "Matched") {
        myClass = "red--text";
      } else if (status == "Not matched") {
        myClass = "green--text";
      } else {
        myClass = "gray--text";
      }
      return myClass;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 600) {
        this.display = false;
      } else {
        this.display = true;
      }
    },
  },
};
</script>
<style>
</style>