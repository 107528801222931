<template>
  <div class="container-fluid background-gray">
    <Header
      @expantSideBarOnHover="expantSideBarOnHover"
      @hideSideBar="hideSideBar"
    />
    <v-row class="background-gray">
      <div
        class="pb-0 vh-height sidebar-wrapper"
        v-if="showSideBar && !display"
      >
        <EmployerSideBar
          :mini="miniClass"
          @closeSideBar="closeSideBar"
          :windowWidth="display"
          :showSideBar="showSideBar"
        ></EmployerSideBar>
      </div>
      <div
        :class="
          showSideBar
            ? 'pb-0  vh-height sidebar-wrapper'
            : 'pb-0 vh-height sidebar-wrapper-mini'
        "
        v-else-if="display"
      >
        <EmployerSideBar
          :mini="miniClass"
          @closeSideBar="closeSideBar"
          :windowWidth="display"
          :showSideBar="showSideBar"
        ></EmployerSideBar>
      </div>
      <div
        v-if="mobileSideBar"
        :class="!showSideBar ? 'main-wrapper' : 'main-wrapper-mini'"
      >
        <div class="context no-padding-left">
          <div class="row no-padding">
            <RoutePath :path="path"></RoutePath>
          </div>
          <BlueNavbar :tab="tab"></BlueNavbar>
          <div
            :class="hideWhiteBack ? 'home-tables' : 'white-background tables'"
          >
            <Home
              v-if="route == '/employer' || route == '/employer/home'"
            ></Home>
            <EmployerMembersTemplate
              v-if="route.includes('/employer/members')"
            ></EmployerMembersTemplate>
            <EmpPriorAuthTemplate
              v-if="route.includes('/employer/prior-auth')"
            ></EmpPriorAuthTemplate>
            <EmpProvidersTemplate
              v-if="route.includes('/employer/providers')"
            ></EmpProvidersTemplate>
            <EmpAdvisorTemplate
              v-if="route.includes('/employer/advisors')"
            ></EmpAdvisorTemplate>
            <EmpContractTemplate
              v-if="route.includes('/employer/contract')"
            ></EmpContractTemplate>
            <EmpSettingTemplate
              v-if="route.includes('/employer/settings')"
            ></EmpSettingTemplate>
            <EmpBundles v-if="route.includes('/employer/bundles')"></EmpBundles>
            <Networks v-if="route.includes('/employer/networks')"></Networks>
            <Utilization
              v-if="route.includes('/employer/utilization')"
            ></Utilization>
            <Partners v-if="route.includes('/employer/partners')"></Partners>
            <WizzardForm v-if="route.includes('/employer/wizard-form')" />
            <NewWizard v-if="route.includes('/employer/new-wizard-form')" />
            <Profile v-if="$route.path.includes('profile')" />
          </div>
        </div>
      </div>
    </v-row>
  </div>
</template>

<script>
import Header from "../components/Sections/Employer/Header.vue";
import EmployerSideBar from "../components/Sections/Employer/Layout/EmployerSideBar.vue";
import RoutePath from "../components/TablesTemplate/RoutePath.vue";
import BlueNavbar from "../components/Sections/Provider/Layout/BlueNavbar.vue";
import Home from "../components/Sections/Employer/Home/Home.vue";
import EmployerMembersTemplate from "../components/Sections/Employer/Members/EmployerMembersTemplate.vue";
import EmpPriorAuthTemplate from "../components/Sections/Employer/PriorAuth/EmpPriorAuthTemplate.vue";
import EmpProvidersTemplate from "../components/Sections/Employer/Providers/EmpProvidersTemplate.vue";
import EmpAdvisorTemplate from "../components/Sections/Employer/Advisors/EmpAdvisorTemplate.vue";
import EmpContractTemplate from "../components/Sections/Employer/Contract/EmpContractTemplate.vue";
import EmpSettingTemplate from "../components/Sections/Employer/Settings/EmpSettingTemplate.vue";
import EmpBundles from "../components/Sections/Employer/Bundles/BundlesTemplate.vue";
import Utilization from "../components/Sections/Employer/Utilization/Utilization.vue";
import Networks from "../components/Sections/Employer/Networks/Networks.vue";
import Partners from "../components/Sections/Employer/Partners/PartnerTemplate.vue";
import WizzardForm from "../components/Sections/Employer/WizzardFormEmployer.vue";
import NewWizard from "../components/Sections/Employer/WizardWithoutInfo.vue";
import Profile from "../components/TablesTemplate/MyProfile.vue";

export default {
  name: "Employer",
  components: {
    Header,
    EmployerSideBar,
    RoutePath,
    BlueNavbar,
    EmployerMembersTemplate,
    EmpPriorAuthTemplate,
    EmpProvidersTemplate,
    EmpAdvisorTemplate,
    EmpContractTemplate,
    EmpSettingTemplate,
    Home,
    EmpBundles,
    Utilization,
    Networks,
    Partners,
    WizzardForm,
    NewWizard,
    Profile,
  },
  data() {
    return {
      sideBar: true,
      showSideBar: false,
      display: true,
      windowWidth: window.innerWidth,
      page: "",
      clientName: "",
      miniClass: false,
      clientItem: null,
      path: "",
      tab: "",
    };
  },
  created() {
    if (this.windowWidth < 959) {
      this.display = false;
    } else {
      this.display = true;
    }

    if (this.route.includes("members/member-detail")) {
      this.$router.replace("/employer/members");
    } else if (
      this.route.includes("members/providers/detail") ||
      this.route.includes("/employer/providers/add-provider/search-result")
    ) {
      this.$router.replace("/employer/providers");
    } else if (
      this.route.includes("/employer/advisors/detail") ||
      this.route.includes("/employer/advisors/add-new/result")
    ) {
      this.$router.replace("/employer/advisors");
    } else if (this.route.includes("/employer/contract/details")) {
      this.$router.replace("/employer/contract");
    } else if (this.route.includes("/employer/settings/users/detail")) {
      this.$router.replace("/employer/settings/users");
    }
  },
  computed: {
    hideWhiteBack() {
      let hide = false;
      if (this.route == "/employer" || this.route == "/employer/home") {
        hide = true;
      }
      return hide;
    },
    mobileSideBar() {
      let show;
      if (this.display) {
        show = true;
      } else if (!this.showSideBar && !this.display) {
        show = true;
      }
      return show;
    },
    route() {
      return this.$route.path;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    getProvidersByClient(item) {
      if (item) {
        this.clientItem = item;
        this.clientName = item.name;
      }
    },
    closeSideBar() {
      if (!this.display) {
        this.showSideBar = false;
      } else {
        this.showSideBar = true;
      }
    },
    getClientName(name) {
      this.clientName = name;
    },

    onResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 959) {
        this.display = false;
      } else {
        this.display = true;
      }
    },
    hideSideBar() {
      this.showSideBar = !this.showSideBar;
    },
    expantSideBarOnHover(mini) {
      this.showSideBar = !this.showSideBar;
      this.miniClass = !this.miniClass;
    },
  },
};
</script>
