<template>
  <div>
       <Home  v-if="path == '/provider-advisor/providers/' + routeParams"></Home>
       <Member></Member>
       <PriorAuths></PriorAuths>
       <Providers></Providers>
       <Advisors></Advisors>
       <Bundles></Bundles>
       <Networks></Networks>
       <Contracts></Contracts>
       <Settings></Settings>
       <Employers></Employers>
       <Rates></Rates>
   </div>
</template>

<script>

import ProviderTemplate from '../Providers/Providers/ProvidersTemplate.vue'

import Home from '../Providers/Home/Home.vue'
import Member from '../Providers/Members/MembersTemplate.vue'
import PriorAuths from '../Providers/PriorAuths/PriorTemplate.vue'
import Providers from '../Providers/Providers/ProvidersTemplate.vue'
import Advisors from '../Providers/Advisors/AdvisorTemplate.vue'
import Bundles from '../Providers/Bundles/BundlesTemplate.vue'
import Networks from '../Providers/Networks/NetworksTemplate.vue'
import Contracts from '../Providers/Contract/ContractTemplate.vue'
import Settings from '../Providers/Settings/SettingsTemplate.vue'
import Employers from '../Providers/Employers/EmployerTemplate.vue'
import Rates from '../Providers/Rates/RatesTemplate.vue'
export default{
    components:{
        ProviderTemplate,
        Home,
        Member,
        PriorAuths,
        Providers,
        Advisors,
        Bundles,
        Networks,
        Contracts,
        Settings,
        Employers,
        Rates
    },
    created(){
        if(this.path.includes(`/provider-advisor/providers/${this.routeParams}/${this.path.split('/')[4]}`) && this.path != `/provider-advisor/providers/${this.routeParams}/${this.path.split('/')[4]}`){
             this.$router.push(`/provider-advisor/providers/${this.routeParams}/${this.path.split('/')[4]}`)
        }
    },
    computed:{
        path(){
             return this.$route.path.replace(/%20/g,' ')
        },
        routeParams(){
            return this.$route.params.name
       }
    }
}

</script>