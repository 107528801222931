<template>
  <div class="">
    <div class="row col-md-12 border-blue-bottom mb-3 justify-space-between">
      <div class="mb-4 mt-4 ml-4 font-weight-bold text-gray">
        Invoice Detail
      </div>
      <div>
        <v-btn class="my-1 gray-border-buttons ml-6 mt-3">
          <svg
            class="mr-2"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.7705 10.7637C14.5668 10.7637 14.3714 10.8431 14.2268 10.9848C14.0822 11.1264 14.0001 11.3188 13.9984 11.5199V12.6762C13.9984 12.9364 13.8938 13.186 13.7074 13.37C13.5211 13.554 13.2684 13.6574 13.0049 13.6574H3.03135C2.76784 13.6574 2.51513 13.554 2.3288 13.37C2.14247 13.186 2.0378 12.9364 2.0378 12.6762V11.5199C2.02037 11.3304 1.93183 11.1542 1.78956 11.0259C1.64729 10.8975 1.46157 10.8264 1.2689 10.8264C1.07622 10.8264 0.89051 10.8975 0.74824 11.0259C0.605971 11.1542 0.517426 11.3304 0.5 11.5199V12.6762C0.5 13.3392 0.766695 13.9751 1.24142 14.4439C1.71614 14.9128 2.36 15.1762 3.03135 15.1762H13.0049C13.6762 15.1762 14.3201 14.9128 14.7948 14.4439C15.2695 13.9751 15.5362 13.3392 15.5362 12.6762V11.5199C15.5362 11.3194 15.4556 11.127 15.312 10.9852C15.1684 10.8433 14.9736 10.7637 14.7705 10.7637Z"
              fill="#707070"
            />
            <path
              d="M8.01829 0.17627C7.69268 0.17627 7.38041 0.304015 7.15017 0.531403C6.91993 0.75879 6.79058 1.06719 6.79058 1.38877V7.05127C6.79058 7.09271 6.77392 7.13245 6.74425 7.16176C6.71458 7.19106 6.67433 7.20752 6.63237 7.20752H4.94902C4.83528 7.2152 4.72575 7.25309 4.632 7.31719C4.53826 7.38128 4.46379 7.46919 4.41647 7.57163C4.36914 7.67407 4.35072 7.78724 4.36314 7.89917C4.37557 8.0111 4.41838 8.11764 4.48705 8.20752L7.55632 11.695C7.61551 11.7575 7.68706 11.8072 7.76652 11.8413C7.84599 11.8753 7.93168 11.8929 8.01829 11.8929C8.1049 11.8929 8.19059 11.8753 8.27006 11.8413C8.34952 11.8072 8.42107 11.7575 8.48026 11.695L11.5495 8.20752C11.6182 8.11764 11.661 8.0111 11.6734 7.89917C11.6859 7.78724 11.6674 7.67407 11.6201 7.57163C11.5728 7.46919 11.4983 7.38128 11.4046 7.31719C11.3108 7.25309 11.2013 7.2152 11.0876 7.20752H9.4042C9.36225 7.20752 9.322 7.19106 9.29233 7.16176C9.26266 7.13245 9.246 7.09271 9.246 7.05127V1.38877C9.246 1.06719 9.11665 0.75879 8.88641 0.531403C8.65617 0.304015 8.3439 0.17627 8.01829 0.17627V0.17627Z"
              fill="#707070"
            />
          </svg>

          Export
        </v-btn>
      </div>
    </div>
    <div class="col-md-12">
      <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 row justify-center">
          <span class="mr-10 text-gray mt-4 font-weight-bold col-md-6">
            Enter or verify invoice information below</span
          >

          <br />
          <div
            class="col-xl-7 col-lg-12 col-md-12 d-xl-flex d-lg-flex d-md-inlinepb-0"
          >
            <label for="" class="col-md-5 text-right">INVOICE NUMBER:</label>
            <v-text-field solo></v-text-field>
            <span class="text-underline light-blue-text ml-3 mt-3"
              >Autogenerate</span
            >
          </div>
          <div
            class="col-xl-7 col-lg-10 col-md-12 d-xl-flex d-lg-flex d-md-inline pt-0 pb-0"
          >
            <label for="" class="col-md-5 text-right">BILLING PERIOD:</label>
            <v-menu
              ref="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  solo
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                :max="
                  new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                    .toISOString()
                    .substr(0, 10)
                "
                min="1950-01-01"
              ></v-date-picker>
            </v-menu>
            <svg
              class="mt-3 ml-2"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1_11277)">
                <path
                  d="M21.75 3.75H2.25C1.42157 3.75 0.75 4.42157 0.75 5.25V21.75C0.75 22.5784 1.42157 23.25 2.25 23.25H21.75C22.5784 23.25 23.25 22.5784 23.25 21.75V5.25C23.25 4.42157 22.5784 3.75 21.75 3.75Z"
                  stroke="#707070"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M0.75 9.75H23.25"
                  stroke="#707070"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.75 6V0.75"
                  stroke="#707070"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M17.25 6V0.75"
                  stroke="#707070"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5.625 14.25C5.41789 14.25 5.25 14.0821 5.25 13.875C5.25 13.6679 5.41789 13.5 5.625 13.5"
                  stroke="#707070"
                  stroke-width="1.5"
                />
                <path
                  d="M5.625 14.25C5.83211 14.25 6 14.0821 6 13.875C6 13.6679 5.83211 13.5 5.625 13.5"
                  stroke="#707070"
                  stroke-width="1.5"
                />
                <path
                  d="M5.625 19.5C5.41789 19.5 5.25 19.3321 5.25 19.125C5.25 18.9179 5.41789 18.75 5.625 18.75"
                  stroke="#707070"
                  stroke-width="1.5"
                />
                <path
                  d="M5.625 19.5C5.83211 19.5 6 19.3321 6 19.125C6 18.9179 5.83211 18.75 5.625 18.75"
                  stroke="#707070"
                  stroke-width="1.5"
                />
                <path
                  d="M12 14.25C11.7929 14.25 11.625 14.0821 11.625 13.875C11.625 13.6679 11.7929 13.5 12 13.5"
                  stroke="#707070"
                  stroke-width="1.5"
                />
                <path
                  d="M12 14.25C12.2071 14.25 12.375 14.0821 12.375 13.875C12.375 13.6679 12.2071 13.5 12 13.5"
                  stroke="#707070"
                  stroke-width="1.5"
                />
                <path
                  d="M12 19.5C11.7929 19.5 11.625 19.3321 11.625 19.125C11.625 18.9179 11.7929 18.75 12 18.75"
                  stroke="#707070"
                  stroke-width="1.5"
                />
                <path
                  d="M12 19.5C12.2071 19.5 12.375 19.3321 12.375 19.125C12.375 18.9179 12.2071 18.75 12 18.75"
                  stroke="#707070"
                  stroke-width="1.5"
                />
                <path
                  d="M18.375 14.25C18.1679 14.25 18 14.0821 18 13.875C18 13.6679 18.1679 13.5 18.375 13.5"
                  stroke="#707070"
                  stroke-width="1.5"
                />
                <path
                  d="M18.375 14.25C18.5821 14.25 18.75 14.0821 18.75 13.875C18.75 13.6679 18.5821 13.5 18.375 13.5"
                  stroke="#707070"
                  stroke-width="1.5"
                />
                <path
                  d="M18.375 19.5C18.1679 19.5 18 19.3321 18 19.125C18 18.9179 18.1679 18.75 18.375 18.75"
                  stroke="#707070"
                  stroke-width="1.5"
                />
                <path
                  d="M18.375 19.5C18.5821 19.5 18.75 19.3321 18.75 19.125C18.75 18.9179 18.5821 18.75 18.375 18.75"
                  stroke="#707070"
                  stroke-width="1.5"
                />
              </g>
              <defs>
                <clipPath id="clip0_1_11277">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div
            class="col-xl-7 col-lg-10 col-md-12 d-xl-flex d-lg-flex d-md-inline pt-0 pb-0"
          >
            <label for="" class="col-md-5 text-right">CLIENT:</label>
            <v-select solo></v-select>
          </div>
          <div
            class="col-xl-7 col-lg-10 col-md-12 d-xl-flex d-lg-flex d-md-inline pt-0 pb-0"
          >
            <label for="" class="col-md-5 text-right">PLAN:</label>
            <v-select solo></v-select>
          </div>
          <div
            class="col-xl-7 col-lg-10 col-md-12 d-xl-flex d-lg-flex d-md-inline pt-0 pb-0"
          >
            <label for="" class="col-md-5 text-right"
              >TOTAL CHARGE (USD):</label
            >
            <v-text-field solo></v-text-field>
          </div>
        </div>
        <v-divider vertical style="margin-top: 80px"></v-divider>
        <div class="col-6">
          <div class="text-gray col-md-12 row">
            <div class="col-md-3">
              <span
                class="pointer font-weight-bold"
                :class="tab == 'invoice' ? 'light-blue-text' : 'text-gray'"
                @click="tab = 'invoice'"
                >Invoice</span
              >
              <span class="mr-5 ml-5">|</span>
              <span
                class="pointer font-weight-bold"
                :class="tab == 'members' ? 'light-blue-text' : 'text-gray'"
                @click="tab = 'members'"
                >Members</span
              >
            </div>

            <span
              v-if="tab == 'invoice'"
              class="col-xl-4 col-lg-8 col-md-9 pt-2"
            >
              <v-text-field
                class="input-control ml-10 mb-5"
                prepend-inner-icon="mdi-magnify"
                light
                dense
                outlined
                placeholder="Search Member List Below"
              ></v-text-field>
            </span>
          </div>

          <div class="col-md-12">
            <div
              v-if="tab == 'members'"
              class="col-md-12 original-image-div text-gray"
            >
              <div class="col-md-12 first">
                <h3>Show Original Image of Invoice</h3>
              </div>
              <div class="col-md-12 second">
                <h4 class="text-underline pointer mr-2 mb-3">Download</h4>
              </div>
            </div>
            <div v-else>
              <v-divider></v-divider>
              <v-simple-table
                fixed-header
                height="690px"
                style="overflow-y: scroll"
                class="col-md-12 no-padding"
              >
                <template v-slot:default>
                  <thead>
                    <tr class="">
                      <th class="text-left">MEMBER ID</th>
                      <th class="text-left">SERVICE DATE</th>
                      <th class="text-left">AMOUNT (USD)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="text-gray">
                      <td>12345</td>
                      <td>05/21/2022</td>
                      <td>$60.00</td>
                    </tr>
                    <tr class="text-gray">
                      <td>12345</td>
                      <td>05/21/2022</td>
                      <td>$60.00</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="gray-top-border">
      <div class="col-md-12" align="end">
        <span
          class="light-blue-text text-underline mr-10 font-weight-bold pointer"
          @click="$router.push('/tpa/solutions')"
          >Close</span
        >
      </div>
    </div>
  </div>
</template>
<script>
import Header from "../../../Layout/HeaderPage.vue";
export default {
  data() {
    return {
      item: null,
      tab: "invoice",
    };
  },
  components: { Header },
  computed: {},
  created() {
    this.item = this.$store.state.Invoicing.singleSoulution;
  },
  methods: {},
};
</script>
<style>
.original-image-div {
  border: 1px solid rgb(219, 217, 217);
  min-height: 50vh;
}
.original-image-div .first {
  display: flex;
  min-height: 44vh;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.original-image-div .second {
  align-items: end;
  justify-content: end;
  text-align: end;
}
</style>
