<template>
  <div class="">
    <div v-if="!isChildren && $route.meta.type == 'clients'" class="row-height">
      <Header
        :title="'Clients'"
        :searchInput="true"
        :exportButton="true"
        :placeholder="'Search Clients'"
        :addButton="true"
        @invite="invite"
        @add="add"
        @searchInput="searchInput"
        @export="exportFun"
      ></Header>
      <div class="mt-3">
        <v-simple-table
          fixed-header
          height="70vh"
          class="no-padding tr-custom-height"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">CLIENT NAME</th>
                <th class="text-left"># OF PLANS</th>
                <th class="text-left"># OF SOLUTIONS</th>
                <th class="text-left">LAST MONTH SPEND</th>
              </tr>
            </thead>

            <tbody
              v-if="clients.length > 0 && !filterItems"
              class="text-gray tr-custom-height"
            >
              <tr
                class="text-gray"
                id="remove-hover"
                v-for="(item, index) in clients"
                :key="index"
              >
                <td>
                  <a
                    v-if="item && item.companyName"
                    class="text-underline"
                    @click="showClient(item)"
                    >{{ item.companyName }}
                  </a>
                  <a v-else @click="showClient(item)">/</a>
                </td>
                <td>{{ item.noPlans }}</td>
                <td>{{ item.noSolutions }}</td>
                <td>
                  <a>Submit Charges</a>
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="clients.length < 1 && filterItems">
              <v-col md="12" cols="12">
                <v-alert
                  border="bottom"
                  colored-border
                  type="warning"
                  elevation="2"
                  class="text-gray"
                >
                  Sorry! No results found
                </v-alert>
              </v-col>
            </tbody>

            <tbody v-else>
              <v-col md="12">
                There are no clients currently added to your account.
                <a @click="$router.push('/digital-invoicing/clients/add-new')">
                  Click to add your clients</a
                >
              </v-col>
            </tbody>
          </template>
        </v-simple-table>
      </div>

      <table id="tblData" style="display: none">
        <thead>
          <tr>
            <th class="text-left">CLIENT NAME</th>
            <th class="text-left">OF PLANS</th>
            <th class="text-left">OF SOLUTIONS</th>
            <th class="text-left">LAST MONTH SPEND</th>
          </tr>
        </thead>

        <tbody
          v-if="clients.length > 0 && !filterItems"
          class="text-gray tr-custom-height"
        >
          <tr
            class="text-gray"
            id="remove-hover"
            v-for="(item, index) in clients"
            :key="index"
          >
            <td>
              <a
                v-if="item && item.companyName"
                class="text-underline"
                @click="showClient(item)"
                >{{ item.companyName }}
              </a>
              <a v-else @click="showClient(item)">/</a>
            </td>
            <td>{{ item.noPlans }}</td>
            <td>{{ item.noSolutions }}</td>
            <td>
              <a   class="text-underline">Submit Charges</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import Header from "../Layout/HeaderPage.vue";
import api from "../../../../services/api";
export default {
  data() {
    return {
      showDetails: false,
      tab: "solution",
      dialog: false,
      clients: [],
      filterArray: [],
      singleItem: null,
      typeItems: [{ text: "Categories", value: "Categories" }],
      sortItems: [{ text: "Newest", value: "Newest" }],
      sortBy: "",
      type: "",
      solutions: [],
      favorites: [],
      filterItems: false,
      load: false,
    };
  },
  components: {
    Header,
  },
  computed: {
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
  },
  created() {
    this.getData();
  },
  methods: {
    exportFun() {
      this.exportData("tblData");
    },
    exportData(tableID, filename = "") {
      var elt = document.getElementById(tableID);
      var downloadLink;
      var dataType = "application/vnd.ms-excel";
      var tableSelect = elt;
      var tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

      // Specify file name
      filename = filename ? filename + ".xls" : "excel_data.xls";

      // Create download link element
      downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        var blob = new Blob(["\ufeff", tableHTML], {
          type: dataType,
        });
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = "data:" + dataType + ", " + tableHTML;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
    },
    invite() {},
    add() {
      this.$router.push("/digital-invoicing/clients/add-new");
    },

    showClient(item) {
      api()
        .get("/tpas/clients/" + item.id)
        .then((res) => {
          this.singleItem = res.data;

          this.$store.commit("setSingleClient", res.data);

          this.$router.push("/digital-invoicing/clients/details/" + item.id);
        });
    },
    getData() {
      this.load = true;
      api()
        .get(`/tpas/clients`)
        .catch((err) => {
          this.load = false;
        })
        .then((res) => {
          if (res && res.data) {
            this.clients = res.data;
            this.filterArray = res.data;
            this.load = false;
          }
        });
    },
    searchInput(val) {
      this.clients = this.filterArray.filter((el) => {
        if (
        el && el.companyName &&  el.companyName.toString().toLowerCase().includes(val.toLowerCase())
        ) {
          return el.companyName
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase());
        }
      });
      if (this.clients.length < 1) {
        this.filterItems = true;
      } else {
        this.filterItems = false;
      }
    },

    searchMembers() {},
  },
};
</script>
<style>
.border-blue-bottom {
  border-bottom: 6px solid #4072b7;
  margin-left: -1px;
  margin-right: -2px;
  margin-top: -1px;
}

.tr-custom-height > .v-data-table__wrapper > table > tbody > tr > td {
  height: 75px;
}
</style>