<template>
  <v-row class="add-client-form no-padding">
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <div class="col-md-12 form-box">
      <div class="row">
        <div class="col-md-12">
          <h3 class="font">Add New Employer</h3>
        </div>
      </div>
      <v-form ref="form" v-model.trim="valid" class="row">
        <div class="col-md-6">
          <div class="col-md-12 pb-0 ">
            <label class="font text-gray" for="">Company Legal Name </label>
            <v-text-field
              required
              solo
              v-model.trim="companyName"
              :rules="requiredRules"
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Company Display Name </label>
            <v-text-field
              required
              solo
              v-model.trim="displayName"
              :rules="requiredRules"
            ></v-text-field>
          </div>
          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for="">Tax ID </label>
            <v-text-field
              required
              @keyup="showSlash($event)"
              v-model.trim="taxId"
              solo
              placeholder="00-0000000"
              :rules="taxIdRules"
              counter="10"
              maxlength="10"
            ></v-text-field>
          </div>

          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for=""
              >Approximate Number of Employees
            </label>
            <v-text-field
              required
              type="number"
              v-model.trim="noEmployees"
              solo
            ></v-text-field>
          </div>

          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for=""
              >Approximate Number of Plan Members</label
            >
            <v-text-field
              required
              v-model.trim="noPlanMembers"
              solo
              type="number"
            ></v-text-field>
          </div>
        </div>
        <div class="col-md-6">
          <div class="col-md-12 pb-0 ">
            <label class="font text-gray" for="">Current Network Vendor</label>
            <v-text-field
              required
              solo
              v-model.trim="networkVendor"
            ></v-text-field>
          </div>

          <div class="col-md-12 pb-0 pt-0">
            <label class="font text-gray" for=""
              >Current Network Discount Rate (i.e Percent Discount off Billed
              Charges)</label
            >
            <v-text-field
              type="number"
              required
              v-model.trim="networkDiscountRate"
              solo
            ></v-text-field>
          </div>
        </div>

        <div class="col-md-12 d-flex align-end flex-column">
          <div>
            <!-- <v-btn class="blue-buttons mr-6" @click="checkIfIsFromHome ? $router.push('/employer-advisor/home') : $router.push('/employer-advisor/employers')">
                  Cancel
                </v-btn> -->

            <v-btn class="blue-buttons mr-6" :disabled="loadData" @click="$router.go(-1)">
              Cancel
            </v-btn>

            <v-btn class="blue-buttons" :disabled="loadData" @click="submit"> Add Employer </v-btn>
          </div>
        </div>
      </v-form>
    </div>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
import api from "../../../../services/api";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
export default {
  components: {
    successModal,
  },
  data() {
    return {
      companyName: "",
      displayName: "",
      taxId: "",
      noEmployees: Number,
      noPlanMembers: Number,
      networkVendor: "",
      networkDiscountRate: Number,
      displayName: "",
      valid: false,
      requiredRules: [(v) => !!v || "This field is required"],
      taxIdRules: [
        (v) => !!v || "This field is required",
        (v) =>
          !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
          "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
      ],
      advisorId: 1,
      loadData:false,
    };
  },
  created() {},
  computed: {
    ...mapGetters(["checkIfIsFromHome"]),
  },
  methods: {
   showSlash(event){
      if (this.taxId.length == 2 && event.keyCode != 8) {
        this.taxId += "-";
      }
      if (this.taxId.length == 10) {
        api()
          .get("auth/isValidTaxId?taxId=" + this.taxId)
          .catch((res) => {
            if (res) {
              this.taxIdRules = [res.response.data.message];
            }
          });
      } else {
        this.taxIdRules = [
          (v) => !!v || "This field is required",
          (v) =>
            !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
            "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
        ];
      }
    },
    closeSuccessModal() {
      this.$router.push("/employer-advisor/employers");
    },
    submit() {
      this.$refs.form.validate();

      if (this.$refs.form.validate()) {
        this.loadData = true;
        let user = {
          companyName: this.companyName,
          displayName: this.displayName,
          taxId: this.taxId,
          noEmployees: Number(this.noEmployees),
          noPlanMembers: Number(this.noPlanMembers),
          networkVendor: this.networkVendor,
          networkDiscountRate: Number(this.networkDiscountRate),
        };
        let userFromStore = JSON.parse(localStorage.getItem("user"));
        let advisorId =
          userFromStore.groupPortal[0].organization.employerAdvisor;
        api()
          .post(`/advisors/${advisorId}/employers`, user)
          .then((res) => {
            if (res) {
              let text = "Employer added successfully";
              this.$refs.success_modal.openPopUp(text);
              this.loadData = false;
            }
          });
      }
    },
  },
};
</script>
<style>
.add-client-form {
  padding: 1.5rem;
}

.yellow-icons {
  color: rgb(224, 171, 36);
}
.blue-buttons {
  background: #4072b7 !important;
  color: white !important;
}
</style>