<template>
  <v-app-bar app clipped-left clipped-right>
    <v-toolbar-title
      class="no-padding"
      align="left"
      style="overflow: initial; width: 173px"
    >
      <img
        id="profile-img"
        :src="logo ? logo : '/apaly1.png'"
        class="apaly-pic pointer"
        @click="changeProfileImg"
      />
    </v-toolbar-title>
    <v-divider class="header-divider-hide" vertical></v-divider>
    <div
      class="
        col-xl-2 col-lg-2 col-md-2 col-sm-6
        header-hamb-icon
        mt-md-3
        no-padding
        row
      "
    >
      <!-- <i
        class="fas fa-bars top-2px pt-1"
        style="cursor: pointer; color: gray"
        @click="hideSideBar"
      ></i> -->
      <h2
        class="font-quicksand-semibold text-gray mb-4 ml-2 group-name"
        style="font-size: 16px"
        v-html="'Digital Direct Healthcare'"
      ></h2>
    </div>

    <v-spacer />
    <div class="user-settings">
      <div class="font text-right font-quicksand-medium-12">
        <v-menu offset-y bottom left>
          <template v-slot:activator="{ on, attrs }">
            <svg
              v-bind="attrs"
              v-on="on"
              class="mr-4 pointer"
              width="28"
              height="30"
              viewBox="0 0 28 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.0183 4.76923L10.9325 2.37846C11.0867 1.97368 11.3579 1.6255 11.7107 1.37953C12.0635 1.13357 12.4814 1.00128 12.9096 1H14.6529C15.0811 1.00128 15.499 1.13357 15.8518 1.37953C16.2046 1.6255 16.4758 1.97368 16.63 2.37846L17.5442 4.76923L20.648 6.57846L23.1567 6.19077C23.5744 6.13333 23.9995 6.20299 24.3781 6.39091C24.7566 6.57883 25.0715 6.87652 25.2826 7.24615L26.133 8.75385C26.3509 9.12937 26.4513 9.563 26.4209 9.99752C26.3906 10.432 26.2309 10.847 25.9629 11.1877L24.411 13.1908V16.8092L26.0054 18.8123C26.2734 19.153 26.4331 19.568 26.4634 20.0025C26.4938 20.437 26.3934 20.8706 26.1755 21.2462L25.3251 22.7538C25.114 23.1235 24.7991 23.4212 24.4206 23.6091C24.042 23.797 23.6169 23.8667 23.1992 23.8092L20.6906 23.4215L17.5867 25.2308L16.6725 27.6215C16.5184 28.0263 16.2471 28.3745 15.8943 28.6205C15.5415 28.8664 15.1237 28.9987 14.6954 29H12.9096C12.4814 28.9987 12.0635 28.8664 11.7107 28.6205C11.3579 28.3745 11.0867 28.0263 10.9325 27.6215L10.0183 25.2308L6.91445 23.4215L4.40584 23.8092C3.98813 23.8667 3.56299 23.797 3.18444 23.6091C2.8059 23.4212 2.49105 23.1235 2.27989 22.7538L1.42952 21.2462C1.21161 20.8706 1.11121 20.437 1.14158 20.0025C1.17194 19.568 1.33164 19.153 1.59959 18.8123L3.15153 16.8092V13.1908L1.55707 11.1877C1.28912 10.847 1.12942 10.432 1.09906 9.99752C1.0687 9.563 1.16909 9.12937 1.387 8.75385L2.23737 7.24615C2.44853 6.87652 2.76338 6.57883 3.14193 6.39091C3.52047 6.20299 3.94561 6.13333 4.36332 6.19077L6.87193 6.57846L10.0183 4.76923ZM9.52936 15C9.52936 15.852 9.77873 16.6848 10.2459 17.3932C10.7131 18.1016 11.3772 18.6538 12.1541 18.9798C12.9311 19.3058 13.786 19.3911 14.6108 19.2249C15.4355 19.0587 16.1932 18.6484 16.7878 18.046C17.3824 17.4436 17.7874 16.676 17.9514 15.8404C18.1155 15.0048 18.0313 14.1386 17.7095 13.3515C17.3877 12.5644 16.8427 11.8916 16.1435 11.4183C15.4443 10.945 14.6222 10.6923 13.7813 10.6923C12.6536 10.6923 11.5721 11.1462 10.7747 11.954C9.97733 12.7619 9.52936 13.8575 9.52936 15V15Z"
                stroke="#707070"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </template>
          <v-list class="dpc-menu-dropdown" style="margin-right: 0px">
            <v-list-item class="" v-for="(item, index) in items" :key="index">
              <v-list-item-title
                class="pointer ml-5 mr-10 mt-1 mb-1"
                @click="$route.path != item.link ? $router.push(item.link) : ''"
                >{{ item.text }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu offset-y bottom left>
          <template v-slot:activator="{ on, attrs }">
            <svg
              v-bind="attrs"
              v-on="on"
              width="30"
              class="mr-2"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="15" cy="15" r="15" fill="#5F8BFC" />
              <path
                d="M15.4909 18.12C15.4909 19.28 14.9109 20.12 13.6909 20.12C12.4709 20.12 11.9109 19.28 11.8909 18.12H9.59094C9.59094 20.64 11.2909 22.14 13.6909 22.14C16.0909 22.14 17.7909 20.64 17.7909 18.12V8.1H15.4909V18.12Z"
                fill="white"
              />
            </svg>
          </template>
          <v-list class="dpc-menu-dropdown" style="margin-right: 0px">
            <v-list-item
              class=""
              v-for="(item, index) in userItems"
              :key="index"
            >
              <v-list-item-title
                class="pointer ml-6 mr-6"
                @click="goToRoute(item.link)"
                >{{ item.text }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </v-app-bar>
</template>
    
<script>
import api from "../../../../services/api";
export default {
  name: "Header",
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showLogo: true,
    showSearch: false,
    fav: true,
    accountSettings: false,
    logo: "",
    profileImg: "",

    userprofile: [
      {
        title: "Logout",
      },
    ],
    items: [
      {
        text: "Account",
        link: "/solution-vendor/account",
      },
      {
        text: "Users",
        link: "/solution-vendor/users",
      },
      {
        text: "Settings",
        link: "/solution-vendor/settings",
      },
    ],
    userItems: [
      {
        text: "Manage Account",
        link: "/solution-vendor/profile",
      },
      {
        text: "Sign Out",
        link: "/login",
      },
    ],
    userprofile: [
      {
        title: "Logout",
      },
    ],
  }),
  computed: {
    user() {
      let user = {
        firstName: "Bill",
        lastName: "Smith",
        email: "brian.mcnilty@usi.com",
      };
      if (localStorage.getItem("user")) {
        user = JSON.parse(localStorage.getItem("user"));
      }
      return user;
    },
  },
  created() {
    this.getLogoImg();
  },
  methods: {
    goToRoute(link) {
      if (link.includes("login")) {
        this.logout();
      } else {
        this.$route.path != link ? this.$router.push(link) : "";
      }
    },
    changeProfileImg() {
      api()
        .get(`/vendors`)
        .then((res) => {
          if (res.data && res.data.primaryContact) {
            this.profileImg = res.data.primaryContact.profile;
          } else {
            this.profileImg = null;
          }
          if (res.data) {
            this.logo = res.data.logo;
          } else {
            this.logo = null;
          }
        });
    },
    getLogoImg() {
      api()
        .get(`/vendors`)
        .then((res) => {
          if (res && res.data) {
            this.logo = res.data.logo;
            this.profileImg =
              res.data &&
              res.data.primaryContact &&
              res.data.primaryContact.profile;
          } else {
            this.logo = null;
            this.profileImg = null;
          }
        });
    },
    hideSideBar() {
      this.$emit("expantSideBarOnHover");
    },
    showhideLogo: function () {
      this.showLogo = !this.showLogo;
    },
    searchbox: function () {
      this.showSearch = !this.showSearch;
    },
    logout(item) {
      
        localStorage.clear();
        this.$cookies.remove("token");
        this.$router.replace("/login");
      
    },
  },
};
</script>
