<template>
<div>
  <v-row class="mb-4 mt-3">
    <v-col class="col-md-7 col-lg-8 col-sm-5" cols="12">
        <h3 class="font">
           <span class="underline-text" v-if="getClient">{{getClient.name}}:</span> Add Contracts - Search Results
        </h3>
    </v-col>
    <v-col  class="col-md-5 col-lg-4 col-sm-7 row justify-md-end justify-sm-center buttons-left ml-2"  cols="12">
        <v-btn class="blue-buttons mr-10" @click="addContract()" >
                Cancel
         </v-btn>
         <v-btn class="blue-buttons"  @click="addContract()">
                 Add Selected
         </v-btn>
    </v-col>
  </v-row>      
 <v-simple-table fixed-header>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
           Contract Name
          </th>
          <th class="text-left green-text">
           Type
          </th>
          <th class="text-left green-text">
           City
          </th>
          <th class="text-left green-text">
          State
          </th>
          <th class="row">
               <v-checkbox></v-checkbox>
              <span class="mt-6">
                  All
              </span>  
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in items"
          :key="item.name"
        >
          <td><a >{{ item.name }}</a> </td>
          <td>{{ item.type }}</td>
          <td>{{ item.city }}</td>
          <td>{{ item.state }}</td>
          <td>
            <v-checkbox  @click="selected.push(item)"></v-checkbox>
          </td>
        
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</div>

    
</template>
<script>
  export default {
    data () {
      return {
        selected:[],
       items: [
          {
            name: 'Tampa Bay Contract',
            npi: '123456789',
            type: 'Hospital',
            city:'Tampa',
            state:'Florida',
            averageCharges: '280%',
            averageSavings:'35%',
            action:''
          },
          {
            name: 'Southeast US Gold Contract',
            npi: '123456789',
            type: 'Multi-Speciality',
            city:'Tampa',
            state:'Florida',
            averageCharges: '280%',
            averageSavings:'35%',
            action:''
          },
        ],
      }
    },
   computed:{
        getClient(){
            return this.$store.getters.getClientGetter
        }
    },
    methods:{
       addContract(){
         this.$emit('showTable')
       }
    }
  }
</script>
