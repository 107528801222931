<template>
  <div class="pa-3">
    <div class="row blue-underline mb-3">
      <div  @click="$router.push('/tpa/solutions')" role="button" class="col-md-12 mb-0 ml-4 mt-2 pb-0 font-weight-500 font-14 d-flex">
        <svg
        @click="$router.go(-1)"
          class="mr-2 mt-1"
          width="14"
          height="16"
          viewBox="0 0 14 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.6269 23.764L1.99634 12.9562C1.92725 12.8861 1.87267 12.803 1.83571 12.7118C1.79875 12.6205 1.78014 12.5229 1.78095 12.4245C1.78176 12.326 1.80197 12.2287 1.84042 12.1381C1.87888 12.0474 1.93482 11.9653 2.00505 11.8963L12.8118 1.26475"
            stroke="gray"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <p  @click="$router.go(-1)">Back</p>
      </div>
    </div>
    <div>
      <div class="row form-row-height d-flex pt-2 pb-2 light-blue-text">
        <div  class="col-md-4 ml-auto d-flex font-weight-bold">
          <div class=" mb-1 mt-1">
              <v-card
              outlined color="transparent"
              height="fit-content"
              width="270px"
              class=" mb-5 mt-2"
              :class="{ 'no-pointer': disablePointer }"
            >
              <div class="display pl-1 pr-1 pb-4">
                
                <div class="text-start justify-start d-flex mb-1 mt-0">
                  <img
                    :src="vendor.logo ? vendor.logo : '/tpa/pointSolutionVendor.svg'"
                    class=" mb-0"
                    height="60"
                  />
                </div>
                <span class="text-start mt-2 mb-0 text-ellipsis">
                  <p class="font-20 font-weight-500 mb-0">
                    {{ vendor.companyName }}
                  </p>
                </span>
                <span class="text-start mb-0 text-ellipsis">
                  <p class="font-16 font-weight-400 text-gray">
                    {{ vendor.category }}
                      </p>
                </span>
                <div class="justify-start d-flex mb-0">
                  <v-chip
                  class=""
                  color="#FFE2CC"
                  text-color="#333333"
                >
                Non-Provider Vendor
                </v-chip>
               
                </div>
              </div>
            </v-card>
          </div>
          <div align="start" class="mb-0"> 
            <img v-if="vendor.favorite" @click="favorite()" src="/svg/filled-heart.svg"/>
            <img v-else  @click="favorite()" src="/svg/empty-heart.svg"/>
          </div>
        </div>
        <div class="col-md-7 col-11 ml-2 mr-auto">
          <v-expansion-panels  multiple class="col-5" v-model.trim="panel">
              <v-expansion-panel class="mb-3" >
              <v-expansion-panel-header>
                 <div class="col-12 expansion-header pt-0 pb-0"> 
                    Details    
                 </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="col-12 pl-0 pt-0">
                  <div class="col-12 expansion-title mt-0 pt-0 pb-0"> 
                    Description: 
                  </div>
                  <div class="col-12 expansion-content"> 
                    {{ vendor.details.description }} 
                  </div>
                  <div class="col-12 mt-0 expansion-link"> 
                    <a :href="`${vendor.details.website}`"> Visit Website
                       <svg  class="ml-1 pt-1" width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.68866 10.9703C5.57151 10.8532 5.57151 10.6632 5.68866 10.546L13.7674 2.46728L13.7497 2.44961L11.5008 2.45816C11.3351 2.45816 11.2008 2.32383 11.2008 2.15816V1.90818C11.2008 1.74248 11.3351 1.60818 11.5008 1.60818L14.9008 1.59961C15.0665 1.59961 15.2008 1.73391 15.2008 1.89961L15.1922 5.29961C15.1922 5.46528 15.0579 5.59961 14.8922 5.59961H14.6422C14.4766 5.59961 14.3422 5.46528 14.3422 5.29961L14.3508 3.05066L14.3331 3.03298L6.25436 11.1117C6.13721 11.2289 5.94726 11.2289 5.83008 11.1117L5.68866 10.9703ZM11.4887 7.07948L11.2887 7.27948C11.2324 7.33574 11.2008 7.41205 11.2008 7.49161V13.1996C11.2008 13.4205 11.0217 13.5996 10.8008 13.5996H2.00078C1.77988 13.5996 1.60078 13.4205 1.60078 13.1996V4.39961C1.60078 4.17868 1.77988 3.99961 2.00078 3.99961H10.5002C10.5798 3.99961 10.6561 3.96801 10.7123 3.91173L10.9123 3.71173C11.1013 3.52273 10.9675 3.19961 10.7002 3.19961H2.00078C1.33803 3.19961 0.800781 3.73686 0.800781 4.39961V13.1996C0.800781 13.8624 1.33803 14.3996 2.00078 14.3996H10.8008C11.4635 14.3996 12.0008 13.8624 12.0008 13.1996V7.29163C12.0008 7.02436 11.6776 6.89051 11.4887 7.07948Z" fill="#0069F3"/>
                      </svg>
                       </a>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel class="mb-3" >
                  <v-expansion-panel-header>
                    <div class="col-12 expansion-header pt-0 pb-0"> 
                      Contact Information
                   </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pl-0 pt-0">
                      <div class="col-12 expansion-title mt-0 pt-0 pb-0"> 
                        Point Of Contact: 
                      </div>
                      <div class="col-12 expansion-content mt-0" > 
                        {{ vendor.contact?.firstName }} {{ vendor.contact?.lastName }}
                      </div>   
                      <div class="col-12 expansion-title mt-0 pb-0"> 
                        Email: 
                      </div>
                      <div class="col-12 expansion-content mt-0"> 
                        {{ vendor.contact?.email }}
                      </div>    
                      <div class="col-12 expansion-title mt-0 pb-0"> 
                          Address: 
                        </div>
                        <div class="col-12 expansion-content mt-0"> 
                          {{ vendor.contact?.address1 }}
                      </div> 
                      </v-expansion-panel-content>
                  </v-expansion-panel>
            </v-expansion-panels>
        </div>
      </div>
    </div>
    <div class="">
      <div class="col-md-12" align="end">
        <!-- <span
          class="light-blue-text text-underline mr-10 ml-10 font-weight-bold pointer"
          @click="$router.go(-1)"
          >Close</span> -->
      </div>
    </div>
    <successModal ref="success_modal" v-on:confirm="closeSuccessModal"></successModal>
  </div>
</template>

<script>
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import api from "../../../../services/api";
import { mapGetters } from "vuex";

export default {
  components: { successModal },
  data() {
    return {
      disablePointer: true,
      vendor: null,
      valid: false,
      panel: [0],
    };
  },
  created() {
    this.getVendor();
  },
  methods: {
    closeSuccessModal() {
      this.$parent.getSolutions();
      this.$router.push("/tpa/solutions");
    },
    getVendor() {
      api()
        .get("/tpas/market/vendor/" + this.$route.params.solutionId)
        .then((res) => {
          if (res) {
            this.vendor = res.data;
            this.vendor.logo = res.data.logo ? res.data.logo : '';
          }
        });
    },
    favorite() {
      let data = {
        entity: 'vendor',
        vendor: this.$route.params.solutionId,
        favorite: !this.vendor.favorite,
      };
      api()
        .post("/tpas/market/favorite", data)
        .then((res) => {
          if (res) {
            this.vendor.favorite = !this.vendor.favorite;
          }
        });
    },
  },
};
</script>
<style scoped>
.participate {
  width: 150px;
  color: white;
}
.next {
  width: 650px;
  color: white;
}
.okay {
  width: 250px;
  color: white;
}
.no-pointer {
  cursor: default;
}
.text-ellipsis{
  height: 58px;
  overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
}
</style>
