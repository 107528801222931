<template>
  <div>
    <div class="">
      <div :class="showOverlay ? ' postion-relative' : ''">
        <div
          v-if="showOverlay"
          class="wizard-absolute"
          style="border-radius: 7px"
       
        >
          <div class="col-md-12">
            <div class="white-background" style="border-radius: 7px">
              <Header
                :title="'TPAs'"
                :searchInput="true"
                :inviteButton="true"
                :placeholder="'Search TPAs'"
                :newButton="false"
                @invite="invite"
                @add="add"
                @searchInput="searchInput"
              ></Header>

              <v-simple-table
                fixed-header
                height="870"
                class="no-padding tr-custom-height mt-3"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">TPA NAME</th>
                      <th class="text-center">SELECT</th>
                    </tr>
                  </thead>

                  <tbody
                    v-if="tpas.length > 0"
                    class="text-gray tr-custom-height"
                  >
                    <tr v-for="(item, index) in tpas" :key="index">
                      <td v-if="item.companyName">
                        <a>{{ item.companyName }}</a>
                      </td>
                      <td v-else><a>/</a></td>

                      <td
                        class="
                          text-right
                          row
                          justify-center
                          no-padding
                          text-center
                          align-center
                        "
                      >
                        <div
                          class="text-end"
                          :id="'selected_item' + index"
                          :class="
                            item.select ? 'fill-blue-checkbox' : 'blue-checkbox'
                          "
                          @click="fillArray(item, index)"
                        ></div>
                      </td>
                    </tr>
                  </tbody>

                  <tbody v-else>
                    <v-col md="12">
                      There are no clients currently added to your account.
                      <a> Click to add your clients</a>
                    </v-col>
                  </tbody>
                </template>
              </v-simple-table>
              <div class="gray-top-border">
                <div class="col-md-12" align="end">
                  <span
                    class="
                      text-gray text-underline
                      mr-10
                      font-weight-bold
                      pointer
                    "
                    @click="closeTpaDialog"
                    >Cancel</span
                  >
                  <span
                    class="
                      text-gray text-underline
                      mr-10
                      font-weight-bold
                      pointer
                    "
                    @click="addSelected"
                    >Add Selected</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="">
          <Header :title="'New Client'"></Header>
          <div class="col-md-12">
            <v-form v-model="valid" ref="form" class="col-md-12">
              <div class="row gray-border-form">
                <div class="col-xl-4 col-lg-4 col-md-6">
                  <div class="col-md-12">
                    <h4 class="text-gray col-md-12">Company Information</h4>
                  </div>
                  <div class="col-md-10">
                    <div class="col-md-12">
                      <label for="">Company Name</label>
                      <v-text-field
                        :rules="requiredRules"
                        v-model="client.companyName"
                        solo
                      ></v-text-field>
                    </div>
                    <div class="col-md-12">
                      <label for="">Tax ID / EIN</label>
                      <v-text-field
                        required
                        @keyup="showSlash($event)"
                        v-model.trim="client.taxId"
                        solo
                        :rules="taxIdRules"
                        counter="10"
                        maxlength="10"
                      ></v-text-field>
                    </div>
                    <div class="col-md-12">
                      <label for="">Status</label>
                      <v-text-field
                        :rules="requiredRules"
                        solo
                        v-model="client.status"
                      ></v-text-field>
                    </div>
                    <div class="col-md-12">
                      <label for="">Contact First Name </label>
                      <v-text-field
                        :rules="requiredRules"
                        solo
                        v-model="client.firstName"
                      ></v-text-field>
                    </div>
                    <div class="col-md-12">
                      <label for="">Contact Last Name </label>
                      <v-text-field
                        :rules="requiredRules"
                        solo
                        v-model="client.lastName"
                      ></v-text-field>
                    </div>
                    <div class="col-md-12">
                      <label for="">Email</label>
                      <v-text-field
                        :rules="emailRules"
                        solo
                        v-model="client.email"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6">
                  <div class="col-md-10">
                    <div
                      class="row row justify-space-between col-md-12 pr-0 mr-0"
                    >
                      <h4 class="text-gray text-underline pl-3 col-md-6">
                        TPA / Carrier Information
                      </h4>
                      <h4
                        class="
                          light-blue-text
                          text-underline
                          col-md-6
                          text-end
                          pr-0
                        "
                        @click="addTpa"
                      >
                        Search for and add a TPA
                      </h4>
                    </div>
                  </div>
                  <div class="mt-5 col-md-10">
                    <v-simple-table
                      fixed-header
                      height="700"
                      style="overflow-y: auto"
                      class="no-padding tr-custom-height"
                    >
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">TPA NAME</th>
                            <th class="text-right">ACTION</th>
                          </tr>
                        </thead>
                        <tbody
                          v-if="tpas.length > 0"
                          class="text-gray tr-custom-height"
                        >
                          <tr v-for="(item, index) in client.tpas" :key="index">
                            <td v-if="item.companyName">
                              {{ item.companyName }}
                            </td>
                            <td v-else><a>/</a></td>
                            <td class="text-right">
                              <a
                                class="text-underline pointer"
                                @click="removeTpa(item)"
                                >Remove</a
                              >
                            </td>
                          </tr>
                        </tbody>

                        <tbody v-else>
                          <v-col md="12"> No results </v-col>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <br /><br /><br />
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-12">
                  <div class="col-md-12">
                    <h4 class="text-gray text-underline col-md-12 pl-0">
                      Actions and Functions
                    </h4>
                  </div>
                  <br /><br />
                  <div class="">
                    <div class="row col-md-12">
                      <div class="col-md-12">
                        <h3 class="text-gray text-underline">Submit Charges</h3>
                      </div>
                      <br /><br /><br />
                      <div class="col-md-12">
                        <h3 class="text-gray text-underline">
                          View Prior Invoices
                        </h3>
                      </div>
                      <br /><br /><br />
                      <div class="col-md-12">
                        <div class="col-md-10 font text-gray">
                          <label for="">Client Relationship Status</label>
                          <div class="d-flex mt-2 font-weight-bold">
                            InActive
                            <v-switch
                              solo
                              class="no-padding ml-2 mr-2 vendors-switch"
                            ></v-switch>
                            Active
                          </div>
                        </div>
                      </div>
                      <br /><br /><br />
                      <!-- <div class="mt-5 col-md-12">
                        <h3
                          class="
                            text-underline
                            col-md-12
                            text-gray
                            font-weight-bold font-18
                          "
                        >
                          Remove Client
                        </h3>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </v-form>
          </div>
          <div class="col-md-12">
            <div class="col-md-12 mb-4" align="end">
              <span
                class="
                  text-gray text-underline
                  pointer
                  font-weight-bold font-18
                "
                style="margin-right: 100px"
                @click="closeAddNewPage"
                >Cancel
              </span>
              <span
                @click="update"
                class="
                  text-gray text-underline
                  pointer
                  font-weight-bold
                  mr-5
                  font-18
                "
                >Save</span
              >
            </div>
          </div>
        </div>
        <router-view></router-view>
      </div>

      <v-dialog v-model="inviteDialog" persistent max-width="800px">
        <v-card>
          <v-card-title>
            <span class="text-h5 text-underline col-md-12 font-weight-bolder"
              >Invite a TPA</span
            >
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="tpaForm" v-model="tpaValid">
                <v-col cols="12" sm="12" md="12">
                  <label for="">Company Name</label>
                  <v-text-field
                    solo
                    :rules="requiredRules"
                    v-model="tpa.companyName"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <label for="">Contact First Name</label>
                  <v-text-field
                    v-model="tpa.firstName"
                    :rules="requiredRules"
                    solo
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <label for="">Contact Last Name</label>
                  <v-text-field
                    v-model="tpa.lastName"
                    :rules="requiredRules"
                    solo
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <label for="">Email</label>
                  <v-text-field
                    v-model="tpa.email"
                    :rules="emailRules"
                    solo
                    required
                  ></v-text-field>
                </v-col>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <div class="col-md-12" align="end">
              <span
                class="text-underline text-gray mr-10 pointer"
                @click="closeInviteDialog"
                >Cancel
              </span>
              <span
                class="text-underline ml-10 text-gray pointer"
                @click="inviteTPA"
                >Save</span
              >
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
  </div>
</template>
<script>
import Header from "../../DigitalVendorInvoices/Layout/HeaderPage.vue";
import api from "../../../../services/api";
import errorModal from "../../../TablesTemplate/ErrorDialog.vue";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";

export default {
  components: { Header, errorModal, successModal },
  data() {
    return {
      taxIdRules: [
        (v) => !!v || "This field is required",
        (v) =>
          !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
          "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
      ],
      zipRules: [
        (v) => (v && v.length == 5) || " Zip Code should be five digits long",
      ],
      states: [],
      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
      requiredRules: [(v) => !!v || "This field is required"],

      emailRules: [
        (v) => !!v || "This field is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      state: "",
      zip: "",
      city: "",
      firstName: "",
      phone: "",
      
      title: "",
      email: "",
      inviteDialog: false,
      dialog: false,
      showOverlay: false,
      selected: [],
      valid: false,
      client: {
        companyName: "",
        taxId: "",
        status: "",
        firstName: "",
        lastName: "",
        email: "",
        tpas: [],
      },
      tpaValid: false,

      tpa: {
        companyName: "",
        firstName: "",
        lastName: "",
        email: "",
      },
      tpas: [],
    };
  },
  created() {
    // this.getStates();
    this.$store.commit("setNavItemFilter", "new");
    this.getTpas();
  },
  methods: {
    closeSuccessModal() {
      this.$router.push("/solution-vendor/clients");
    },
    showSlash(event) {
      if (this.client.taxId.length == 2 && event.keyCode != 8) {
        this.client.taxId += "-";
      }
      if (this.client.taxId.length == 10) {
        api()
          .get("auth/isValidTaxId?taxId=" + this.taxId)
          .catch((res) => {
            if (res) {
              this.taxIdRules = [res.response.data.message];
            }
          });
      } else {
        this.taxIdRules = [
          (v) => !!v || "This field is required",
          (v) =>
            !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
            "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
        ];
      }
    },
    inviteTPA() {
      this.$refs.tpaForm.validate();
      if (this.tpaValid) {
        api()
          .post(`/vendors/tpas`, this.tpa)
          .catch((err) => {
            if (err) {
              this.$refs.error_modal.openPopUp(err.response.data);
            }
            return;
          })
          .then((res) => {
            if (res && res.data) {
              this.tpas.push(res.data);
              this.$store.commit("setNavItemFilter", "TPA");
              this.inviteDialog = false;
            }
          });
      }
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    getTpas() {
      api()
        .get(`/vendors/tpas`)
        .catch((err) => {
          if (err) {
            this.$refs.error_modal.openPopUp(err.response.data.message);
          }
        })
        .then((res) => {
          this.tpas = res.data;
          this.tpas.forEach((el) => {
            el.select = false;
          });
        });
    },
    closeAddNewPage() {
      this.$store.commit("setNavItemFilter", false);
      this.$router.push("/solution-vendor/clients");
    },
    closeInviteDialog() {
      this.$store.commit("setNavItemFilter", "TPA");
      this.inviteDialog = false;
    },
    closeTpaDialog() {
      this.$store.commit("setNavItemFilter", "new");
      this.showOverlay = false;
    },
    addSelected() {
      let arr = JSON.stringify(this.selected);
      this.client.tpas = JSON.parse(arr);
      this.$store.commit("setNavItemFilter", "new");
      this.showOverlay = false;
    },
    addTpa() {
      this.$store.commit("setNavItemFilter", "TPA");
      this.showOverlay = true;
    },
    removeTpa(item) {
      let ind1 = this.client.tpas.findIndex((i) => i._id === item._id);
      this.client.tpas.splice(ind1, 1);

      let ind = this.selected.findIndex((i) => i._id === item._id);
      this.selected.splice(ind, 1);

      let ind3 = this.tpas.findIndex((i) => i._id === item._id);
      this.tpas[ind3].select = false;
    },
    fillArray(item, index) {
      let doc = document.getElementById("selected_item" + index);
      item.select = !item.select;

      if (item.select) {
        this.selected.push(item);
        doc.className = "fill-blue-checkbox";
      } else {
        this.selected.splice(this.selected.indexOf(item), 1);
        doc.className = "blue-checkbox";
      }
    },

    update() {
      this.$refs.form.validate();
      if (this.valid) {
        let data = {
          companyName: this.client.companyName,
          taxId: this.client.taxId,
          firstName: this.client.firstName,
          lastName:this.client.lastName,
          email: this.client.email,
          status: this.client.status,
          tpas: this.client.tpas,
        };

        api()
          .post(`/vendors/clients`, data)
          .catch((err) => {
            if (err) {
              this.$refs.error_modal.openPopUp(err.response.data);
            }
            return;
          })
          .then((res) => {
            if (res) {
              this.$parent.getData();
              this.$refs.success_modal.openPopUp("Client added successfully");
            }
          });
      }
    },

    searchInput() {},
    invite() {
      this.$store.commit("setNavItemFilter", "invite");
      this.inviteDialog = true;
    },
    add() {
      this.dialog = true;
      this.showOverlay = true;
    },
    getStates() {
      api()
        .get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },
  },
};
</script>
<style>
</style>