<template>
  <div class="col-md-12 text-center justify-center" v-if="readyToShow">
    <v-progress-circular
      indeterminate
      :size="100"
      color="primary"
      class="text-center"
    ></v-progress-circular>
  </div>
  <div class="row mb-10 mt-2" v-else>
    <div
    class="col-xl-9 col-lg-12 col-md-7 col-sm-12 d-flex justify-center row"
  >
    <v-col
      class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 pr-lg-0"
      cols="12"
    >
      <v-card height="280px" width="280px" class="mr-2 elevation-20"  >
        <div class="display pl-1 pr-1">
          <div class="text-center justify-center mb-1 mt-1">
            <svg
            class="mt-8"
              width="46"
              height="46"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.2002 11.8C15.6217 12.3923 16.836 13.392 17.6902 14.6733C18.5444 15.9546 19.0002 17.4601 19.0002 19"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.3999 18.9999C3.40282 17.953 3.61687 16.9175 4.02925 15.9553C4.44162 14.9931 5.04385 14.124 5.7999 13.3999"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14.2002 10.6V12.4C14.2002 13.1956 13.8841 13.9587 13.3215 14.5213C12.7589 15.0839 11.9958 15.4 11.2002 15.4C10.4045 15.4 9.64148 15.0839 9.07887 14.5213C8.51627 13.9587 8.2002 13.1956 8.2002 12.4V10.6"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M2.6 9.80009C3.48366 9.80009 4.2 9.08375 4.2 8.2001C4.2 7.31644 3.48366 6.6001 2.6 6.6001C1.71634 6.6001 1 7.31644 1 8.2001C1 9.08375 1.71634 9.80009 2.6 9.80009Z"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M4.19238 8.33594C4.66051 8.50144 5.06578 8.80807 5.35234 9.21355C5.6389 9.61903 5.79264 10.1034 5.79238 10.5999V17.1999C5.79238 17.437 5.8392 17.6717 5.93016 17.8906C6.02112 18.1095 6.15442 18.3083 6.32242 18.4756C6.49041 18.6428 6.68979 18.7752 6.9091 18.8652C7.12841 18.9552 7.36333 19.001 7.60038 18.9999H9.40038C9.87777 18.9999 10.3356 18.8103 10.6732 18.4727C11.0107 18.1352 11.2004 17.6773 11.2004 17.1999V15.3999"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.2 9.39999C13.5196 9.39999 15.4 7.51959 15.4 5.2C15.4 2.8804 13.5196 1 11.2 1C8.8804 1 7 2.8804 7 5.2C7 7.51959 8.8804 9.39999 11.2 9.39999Z"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.6001 3.06396C8.24781 3.73694 9.02422 4.27283 9.88316 4.63977C10.7421 5.00671 11.6661 5.19722 12.6001 5.19996C13.5494 5.20262 14.489 5.00925 15.3601 4.63196"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <span class="text-center">
            <p class="font-weight-bold">
             Providers
            </p>
          </span>
          <span class="text-center">
            <p>{{providers.length}}</p>
          </span>
          <div class="justify-center d-flex mt-2">
            <v-btn 
            class="details mt-2" 
            color="#16407A"
            rounded
            @click="$router.push('/superadmin/providers')"
            > Go to providers </v-btn>
          </div>
        </div>
      </v-card>
    </v-col>
    <v-col
    class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 pr-lg-0"
    cols="12"
  >
    <v-card height="280px" width="280px" class="mr-2 elevation-20">
      <div class="display pl-1 pr-1">
        <div class="text-center justify-center mb-1 mt-1">
          <svg
            class="mt-8"
              width="46"
              height="46"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.1707 1H3.39468C2.75957 1 2.15047 1.25286 1.70139 1.70294C1.2523 2.15303 1 2.76348 1 3.4V17.8C1 18.1183 1.12615 18.4235 1.35069 18.6485C1.57524 18.8736 1.87979 19 2.19734 19H3.3388"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.39453 4.7998H9.18167"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.39453 8H7.27391"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12.3748 8.2V2.8C12.3748 2.32261 12.564 1.86477 12.9008 1.52721C13.2376 1.18964 13.6944 1 14.1708 1V1C14.6471 1 15.1039 1.18964 15.4407 1.52721C15.7776 1.86477 15.9668 2.32261 15.9668 2.8V4.8H12.3748"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.763 16.0879L13.9395 16.8559"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14.3222 12.5121L12.5182 13.2721C12.3771 13.3333 12.225 13.3649 12.0712 13.3649C11.9175 13.3649 11.7653 13.3333 11.6242 13.2721C11.416 13.1775 11.2386 13.0258 11.1126 12.8346C10.9867 12.6433 10.9172 12.4203 10.9122 12.1912C10.9072 11.9621 10.9668 11.7362 11.0844 11.5396C11.2019 11.343 11.3724 11.1837 11.5763 11.0801L13.3803 10.1761C13.6027 10.0619 13.8488 10.0016 14.0987 10.0001C14.2874 9.99817 14.4745 10.0335 14.6495 10.1041L18.3373 11.6001"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6.94678 16.8884H8.21596L10.6505 18.7444C10.7076 18.8091 10.7768 18.862 10.8543 18.8999C10.9317 18.9378 11.0159 18.9599 11.1019 18.9651C11.1879 18.9704 11.2742 18.9585 11.3556 18.9302C11.437 18.9019 11.5121 18.8578 11.5765 18.8004L14.9769 15.9844C15.1126 15.8745 15.1997 15.7155 15.2191 15.5417C15.2385 15.368 15.1888 15.1936 15.0807 15.0564L13.1729 12.9844"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.3691 11.2085L11.1696 11.0405C10.9188 10.8753 10.6234 10.7915 10.3234 10.8005C10.1409 10.8005 9.95982 10.833 9.78863 10.8965L6.93896 12.0805"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.78955 17.4877H6.02902C6.14856 17.4942 6.26819 17.4769 6.38108 17.437C6.49396 17.397 6.59787 17.3352 6.68685 17.2549C6.77583 17.1746 6.84813 17.0776 6.89962 16.9692C6.95111 16.8609 6.98076 16.7435 6.98689 16.6237V12.3437C6.97248 12.1031 6.86364 11.878 6.68416 11.7176C6.50467 11.5572 6.26914 11.4746 6.02902 11.4877H5.78955"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.9602 17.4877H18.7606C18.6411 17.4942 18.5214 17.4769 18.4085 17.437C18.2957 17.397 18.1918 17.3352 18.1028 17.2549C18.0138 17.1746 17.9415 17.0776 17.89 16.9692C17.8385 16.8609 17.8089 16.7435 17.8027 16.6237V12.3437C17.8171 12.1031 17.926 11.878 18.1055 11.7176C18.285 11.5572 18.5205 11.4746 18.7606 11.4877H19.0001"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
        </div>
        <span class="text-center">
          <p class="font-weight-bold">
           TPAs
          </p>
        </span>
        <span class="text-center">
          <p> {{ tpa.length }} </p>
        </span>
        <div class="justify-center d-flex mt-2">
          <v-btn
           class="details mt-2"
           color="#16407A"
            rounded
          @click="$router.push('/superadmin/tpas')"
          > Go to TPAs </v-btn>
        </div>
      </div>
    </v-card>
  </v-col>
  <v-col
  class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 pr-lg-0"
  cols="12"
>
  <v-card height="280px" width="280px" class="mr-2 elevation-20" >
    <div class="display pl-1 pr-1">
      <div class="text-center justify-center mb-1 mt-1">
        <svg
            class="mt-8"
              width="46"
              height="46"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.6322 12.7686L12.2002 14.2685"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.34375 12.7824H5.30375L8.11175 18.1551C8.21361 18.3458 8.33692 18.4996 8.47436 18.6073C8.61179 18.7151 8.76054 18.7746 8.91175 18.7824C10.5465 16.7768 12.0823 14.5466 13.5037 12.1142C13.5917 11.6915 13.4238 11.2688 13.2718 10.9143L11.2637 6.49609"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.86439 3.0732L8.58439 2.66411C8.29678 2.31524 7.95338 2.12489 7.60039 2.11866C7.38661 2.11788 7.17475 2.18733 6.97639 2.3232L3.40039 4.75046"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1 2.41846H2.6C2.80154 2.41736 2.99589 2.54596 3.14421 2.77855C3.29253 3.01114 3.38388 3.33058 3.4 3.673V12.4275C3.38198 12.7675 3.2898 13.0836 3.14168 13.3133C2.99356 13.5431 2.80027 13.6698 2.6 13.6684H1"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.9996 13.6684H17.3996C17.1993 13.6698 17.0061 13.5431 16.8579 13.3133C16.7098 13.0836 16.6176 12.7675 16.5996 12.4275V3.673C16.6157 3.33058 16.7071 3.01114 16.8554 2.77855C17.0037 2.54596 17.1981 2.41736 17.3996 2.41846H18.9996"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12.2001 5.96411L9.44014 7.51864C9.13574 7.70007 8.80248 7.68026 8.50623 7.46312C8.20998 7.24598 7.97229 6.84732 7.84014 6.34592C7.69777 5.82356 7.67992 5.22738 7.79035 4.68302C7.90078 4.13866 8.13088 3.68863 8.43214 3.42775L11.1201 1.12322C11.3827 0.895505 11.6735 0.778592 11.9681 0.782313C12.1862 0.784815 12.4025 0.849337 12.6081 0.973221L16.6081 3.45503"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
      </div>
      <span class="text-center">
        <p class="font-weight-bold">
         Vendors
        </p>
      </span>
      <span class="text-center">
        <p>{{vendors.length}}</p>
      </span>
      <div class="justify-center d-flex mt-2">
        <v-btn 
        class="details mt-2" 
        color="#16407A"
        rounded
        @click="$router.push('/superadmin/vendors')"
        > Go to vendors </v-btn>
      </div>
    </div>
  </v-card>
</v-col>
    
  </div>
  </div>
</template>
<script>
import api from "../.../../../../../services/api";
export default {
  data() {
    return {
      providers: [],
      providersNo: "",
      vendors: [],
      tpa: [],
      statistics: {},
      dialogm1: "",
      dialog: false,
      dialog1: false,
      readyToShow: false,
    };
  },
  methods: {
    getProviders() {
      let type = {type: "provider"}
      api()
        .post(`/superadmin/accounts`, type)
        .then((res) => {
          this.providers = res.data;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    getTpa() {
      let type = {type: "tpa"}
      api()
        .post(`/superadmin/accounts`, type)
        .then((res) => {
          this.tpa = res.data;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    getVendors() {
      let type = {type: "vendor"}
      api()
        .post(`/superadmin/accounts`, type)
        .then((res) => {
          this.vendors = res.data;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    
  },
  created() {
    this.getProviders();
    this.getTpa();
    this.getVendors();
  },
};
</script>

<style scoped>
.v-stepper--alt-labels .v-stepper__step {
  flex-basis: 0px !important;
}
.details{
  color: white;
}
.v-application .v-card {
  box-shadow: 0 0 30px #4072B7 !important;
  -webkit-box-shadow: 0 0 30px #4072B7 !important;
  }
</style>

