<template>
  <div>
        <h3 class="col-md-12">Contracts</h3>
        <div  class="center-align">
           <div class=" mb-10  col-md-8  col-lg-6 col-xl-4 mt-15" cols="">
            <h3 class="blue-text text-center">What best Describes you?</h3>
            <v-card class="col-12 d-flex mt-5">
              <div class="col-md-8 blue-text mt-1 font text-center">
                  Hospital or Health System
              </div>
              <div class="col-md-4 text-center" >
                <v-btn class="select-btn" @click="$router.push({path:`/provider-advisor/providers/${routeParams}/contracts/hospital-type/add-new`, params:{type:'hospital-type'}})">Select</v-btn>
              </div>
            </v-card>
            <v-card class="col-12 d-flex mt-5">
              <div class="col-md-8 blue-text mt-1 font text-center">
                  Other Healthcare Provider 
              </div>
              <div class="col-md-4 text-center"  >
                <v-btn class="select-btn" @click="$router.push({path:`/provider-advisor/providers/${routeParams}/contracts/other-type/add-new`, params:{type:'other-type'}})">Select</v-btn>
              </div>
            </v-card>
         </div>
        </div>
  </div> 
</template>
<script>
export default{
  data(){
    return{

    }
  },
  computed:{
     routeParams(){
           return this.$route.params.name
       },
  }
}
</script>
<style >
.center-align{
    align-items: center;
    display: flex;
    flex-direction: column;
    height:70vh !important;
}
</style>