<template>
  <div class="container-fluid">
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <v-row class="main-row">
      <v-col lg="12" md="12" sm="12">
        <!-- <div class="end-div">
            <h3 v-if="this.$route.path == '/login' || this.$route.path == '/new-login'" class="tiny-font text-gray mobile-font">
              Don’t have an Apaly Account?
              <a @click="$router.push('/register')">Sign up</a>
            </h3>
            <h3 v-if="this.$route.path == '/register' || this.$route.path == '/new-register'" class="tiny-font mobile-font">
              Are you a member ? 
              <a @click="$router.push('/login')">Access Here</a>
            </h3>
            <h3 v-else class="tiny-font">
              
            </h3>
          </div> -->

        <div
          v-if="
            this.$route.path == '/login' || this.$route.path == '/new-login'
          "
        >
          <New></New>
        </div>
        <div v-if="this.$route.path == '/confirm'">
          <Invited></Invited>
        </div>
        <div v-else-if="this.$route.path == '/register'">
          <NewRegister></NewRegister>
        </div>

        <div v-else-if="this.$route.path == '/new-register'">
          <NewRegister></NewRegister>
        </div>
        <div v-else-if="this.$route.path == '/auth/simple/verify/account'">
          <Verify></Verify>
        </div>
        <div
          v-else-if="
            this.$route.query &&
            typeof this.$route.query.resetPassword != 'undefined' &&
            this.$route.query.resetPassword == null
          "
        >
          <ResetPass></ResetPass>
        </div>
        <div v-else class="stepper">
          <RegisterComp></RegisterComp>
        </div>
      </v-col>
    </v-row>
    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
  </div>
</template>

<script>
import api from "../services/api";
import successModal from "../components/TablesTemplate/SuccessDialog.vue";
import errorModal from "../components/TablesTemplate/ErrorDialog.vue";
import Invited from "../components/LoginRegister/InviteUser.vue";
import BlueSideBar from "../components/LoginRegister/BlueSideBar.vue";
import Login from "../components/LoginRegister/Login.vue";
import New from "../components/LoginRegister/NewLoginPage.vue";
import NewRegister from "../components/LoginRegister/NewRegister.vue";
import ResetPass from "../components/LoginRegister/ResetPassword.vue";
import RegisterComp from "../components/LoginRegister/Register.vue";
import Verify from "../components/LoginRegister/VerifyEmail.vue";
export default {
  components: {
    Login,
    New,
    Verify,
    NewRegister,
    BlueSideBar,
    RegisterComp,
    ResetPass,
    successModal,
    errorModal,
    Invited,
  },
  data() {
    return {
      login: true,
      valid: false,
      loginOrRegister: false,
      firstname: "",
      lastname: "",
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length <= 10 || "Name must be less than 10 characters",
      ],
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      tab: "firstTab",
      desc: "",
      title: "",
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
      this.$router.replace("/login");
    },
    changeTab(tab) {
      this.tab = tab;
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
      this.$router.replace("/login");
    },
  },
  created() {
    console.log("here", this.$route.query.action);
  },
};
</script>
<style scoped>
@media only screen and (max-width: 1264px) {
  .mobile-font {
    font-size: 16px !important;
  }
}
</style>
