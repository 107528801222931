<template>
  <div class="container-fluid vh-height externals">
    <v-row
      class="text-center justify-center background-gray"
      style="justify-content: center"
    >
      <div class="col-xl-4 col-lg-8 mt-6 white-background">
        <div>
          <img src="/apaly1.png" alt="" />
        </div>
        <div>
          <v-divider class="mb-4 mt-4"></v-divider>

          <h1 class="dark-blue">Welcome to Apaly Health</h1>
          <h2 class="text-gray">- Externals -</h2>
        </div>
        <div class="row col-md-12 text-center justify-center">
          <div class="col-md-4">
            <v-btn
              large
              color="primary"
              outlined
              @click="$router.push('/npisearch')"
              class="col-md-11"
              >Search NPI</v-btn
            >
          </div>
          <div class="col-md-4">
            <v-btn
              outlined
              large
              @click="$router.push('/zipmilsearch')"
              light
              color="success"
              class="col-md-11"
              >Zip Code Search
            </v-btn>
          </div>
          <div class="col-md-4">
            <v-btn
              outlined
              color="warning"
              large
              class="col-md-11 white--text"
              @click="$router.push('/checkdomain')"
              >Check Email Domain</v-btn
            >
          </div>
        </div>
        <div class="dark-blue">
          <h3>DPC Forms</h3>
        </div>
        <div class="row col-md-12 text-center justify-center">
          <div class="col-xl-4 col-lg-4">
            <v-btn
              outlined
              large
              @click="$router.push('/employer-client/home')"
              color="warning"
              class="col-md-11"
              >Employer Client</v-btn
            >
          </div>
          <div class="col-md-4">
            <v-btn
              large
              @click="$router.push('/vendors/home')"
              color="primary"
              outlined
              class="col-md-11 white--text"
              >Vendors
            </v-btn>
          </div>
          <div class="col-md-4">
            <v-btn
              outlined
              @click="$router.push('/dpc-providers/home')"
              large
              color="success"
              class="col-md-11 white--text"
              >DPC Providers</v-btn
            >
          </div>
          <div class="col-md-12 pt-0 pb-0"></div>
          <div class="col-md-4">
            <v-btn
              @click="$router.push('/family-members')"
              large
              outlined
              color="success"
              class="col-md-11 white--text"
              >Member App
            </v-btn>
          </div>
          <div class="col-md-4">
            <v-btn
              @click="goToMarketPlace()"
              large
              outlined
              color="primary"
              class="col-md-11 white--text"
              >Marketplace
            </v-btn>
          </div>
        </div>
        <br />
        <v-divider></v-divider>
        <div class="dark-blue mt-3 mb-3">
          <h3>Web Site Provider-Employer</h3>
          Have employers requested to connect with you? <br />
          Are there plan members in your market?
        </div>
        <div class="row col-md-12 text-center justify-center pb-10">
          <div class="col-md-4">
            <v-btn
              large
              color="info"
              @click="$router.push('/search-members')"
              class="col-md-11 white--text"
              >Members</v-btn
            >
          </div>
          <div class="col-md-4">
            <v-btn
              large
              color="warning"
              @click="$router.push('/search-employers')"
              light
              class="col-md-11"
              >Employers
            </v-btn>
          </div>
        </div>
        <div class="mt-3 tet-gray small-text">
          Sign in to your account.
          <a href="" class="dark-blue" @click="$router.push('/login')"
            >Sign in
          </a>
        </div>
        <div
          @click="dialog = true"
          class="mb-3 tet-gray small-text text-underline"
        >
          <a> Remove Account?</a>
        </div>
      </div>
    </v-row>

    <v-dialog persistent v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h6 lighten-2 dark-blue">
          <span v-if="!removeDialog">Welcome</span>
          <span v-else>Remove account</span>
        </v-card-title>

        <v-card-text v-if="!removeDialog">
          <v-form ref="form" v-model="valid">
            <v-text-field
              @input="hideErrors"
              v-model="pass"
              :rules="pass.length > 0 ? passRules : []"
              outlined
              label="Enter Password"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show2 ? 'text' : 'password'"
              required
              hide-details=""
              @click:append="show2 = !show2"
            ></v-text-field>
            <span class="red--text">{{ error }}</span>
          </v-form>
        </v-card-text>
        <v-card-text v-else>
          <v-form ref="form" v-model="valid">
            <v-text-field
              v-model="email"
              :rules="email.length > 0 ? emailRules : []"
              outlined
              label="Enter Email"
            ></v-text-field>
          </v-form>
          <v-alert
            v-if="dialogMessage"
            dense
            :type="dialogMessage.includes('found') ? 'error' : 'success'"
            >{{ dialogMessage }}</v-alert
          >
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" v-if="!removeDialog" text @click="submit">
            Submit
          </v-btn>
          <v-btn color="primary" v-else text @click="removeAccount">
            Confirm
          </v-btn>
          <v-btn color="primary" text @click="closeDialog"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import api from "../services/api";
export default {
  data() {
    return {
      dialog: false,
      removeDialog: false,
      pass: "",
      valid: false,
      email: "",
      show1: false,
      snackbar: false,
      dialogMessage: "",
      show2: false,
      error: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],

      passRules: [],
    };
  },
  methods: {
    goToMarketPlace() {
      this.$store.commit("changeTabMarketPlace", "all");
      this.$router.push("/marketplace/home");
    },
    hideErrors() {
      if(this.pass.length < 1){
        this.error = ""
      }
    },
    submit() {
      this.error = "";
      if (this.pass == "admin987") {
        this.pass = "";
        this.removeDialog = true;
        this.error = "";
      } else {
        if (this.pass.length < 1) {
          this.error = "Password is required";
        } else {
          this.error = "Wrong Password!";
        }
      }
    },
    closeDialog() {
      this.dialog = false;
      this.removeDialog = false;
      this.email = "";
      this.pass = "";
      this.dialogMessage = '';
    },
    removeAccount() {
      if (this.email) {
        api()
          .post("/auth/cleanup", { email: this.email })
          .then((res) => {
            if (res) {
              this.email = "";
              this.dialogMessage = res.data.message;
            }
          })
          .catch((err) => {
            if (err) {
              this.dialogMessage = err.response.data.message;
            }
          });
        // this.removeDialog =  false;
      }
    },
  },
};
</script>

<style>
.externals {
  align-items: center;
  display: flex;
}
</style>