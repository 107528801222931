<template>
  <div>
    <Header :title="'Add New Health Plan'"></Header>
    <div class="col-md-12">
      <div class="col-md-12">
        <v-form
          v-model="valid"
          ref="form"
          class="row gray-border-form form-row-height"
        >
          <div class="col-md-6">
            <div class="col-md-10">
              <div class="col-md-12 pb-0">
                <label for="">Plan Name</label>
                <v-text-field solo v-model="plan.name"></v-text-field>
              </div>
              <div class="col-md-12 pt-0 pb-0">
                <label for="">Plan ID</label>
                <v-text-field solo v-model="plan.planId"></v-text-field>
              </div>
              <div class="col-md-12 pt-0 pb-0">
                <label for="">Plan Type</label>
                <v-select
                  solo
                  v-model="plan.type"
                  :items="typeItems"
                ></v-select>
              </div>
              <div class="col-md-12 pt-0 pb-0">
                <label for="">Group ID </label>
                <v-text-field
                  disabled
                  solo
                  v-model="plan.groupId"
                ></v-text-field>
              </div>
              <div class="col-md-12 pt-0 pb-0">
                <label for="">Admin/TPA</label>
                <v-text-field disabled v-model="plan.admin" solo></v-text-field>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="col-md-10">
              <div class="row col-md-12 no-padding">
                <div class="col-md-10 font">
                  <label for="" class="text-gray">Health Plan Status</label>
                  <div class="d-flex mt-2 font-weight-bold">
                    InActive
                    <v-switch
                      v-model="plan.status"
                      solo
                      class="no-padding ml-2 mr-2 vendors-switch"
                    ></v-switch>
                    Active
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-form>
      </div>
    </div>
    <div class="col-md-12">
      <div class="col-md-12 mb-4" align="end">
        <span
          class="
            light-blue-text
            text-underline
            pointer
            font-weight-bold
            mr-10
            font-18
          "
          @click="goBack"
          >Cancel</span
        >
        <span
          class="
            light-blue-text
            text-underline
            pointer
            font-weight-bold
            mr-5
            font-18
            pointer
          "
          @click="submit"
          >Submit</span
        >
      </div>
    </div>
    <removeDialog
      ref="dialog_delete"
      v-on:confirm="confirmRemove"
    ></removeDialog>
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
  </div>
</template>
<script>
import Header from "../../../Layout/HeaderPage.vue";
import api from "../../../../../../services/api";
import successModal from "../../../../../TablesTemplate/SuccessDialog.vue";
import removeDialog from "../../../../../TablesTemplate/RemoveDialog.vue";
import errorModal from "../../../../../TablesTemplate/ErrorDialog.vue";

export default {
  components: { Header, successModal, removeDialog, errorModal },
  data() {
    return {
      taxIdRules: [
        (v) => !!v || "This field is required",
        (v) =>
          !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
          "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
      ],
      zipRules: [
        (v) => (v && v.length == 5) || " Zip Code should be five digits long",
      ],
      states: [],
      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
      typeItems: ["HSA", "non-HSA"],
      emailRules: [
        (v) => !!v || "This field is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      valid: false,
      plan: {
        name: "",
        planId: "",
        type: "",
        groupId: "",
        admin: "",
        status: "",
      },
    };
  },
  computed: {
    singleItem() {
      return this.$store.getters.getSingleItem;
    },
    getSingleClient() {
      return this.$store.getters.getSingleClient;
    },
  },
  created() {
    this.getStates();

    let user = JSON.parse(localStorage.getItem("user"));
    if (user && user.groupPortal && user.groupPortal[0].organization) {
      this.plan.admin = user.companyName;
    }
    if (this.getSingleClient) {
      this.plan.groupId = this.getSingleClient.groupId;
    }
  },
  methods: {
    goBack() {
      this.closeSuccessModal();
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
      this.$router.push(
        "/digital-invoicing/clients/plans/" + this.$route.params.id
      );
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    submit() {
      this.$refs.form.validate();
      if (this.valid) {
        if (this.plan.status) {
          this.plan.status = "active";
        } else {
          this.plan.status = "inactive";
        }
        api()
          .post(`/tpas/clients/${this.$route.params.id}/plans`, this.plan)
          .then((res) => {
            if (res) {
              this.$refs.success_modal.openPopUp("Successfully added");
            }
          });
      }
    },
    confirmRemove() {
      api()
        .delete(
          `/tpas/clients/${this.$route.params.id}/plans/${this.singleItem._id}`
        )
        .then((res) => {
          if (res) {
            this.$router.push(
              "/digital-invoicing/clients/plans/" + this.$route.params.id
            );
          }
        });
    },
    removeItem() {
      this.$refs.dialog_delete.openPopUp(
        "Are you sure that you want to remove this Health Plan?"
      );
    },
    getStates() {
      api()
        .get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },
  },
};
</script>
<style>
.gray-border-form {
  border-right: 2px solid rgba(179, 177, 177, 0.5);
  border-left: 2px solid rgba(179, 177, 177, 0.5);
  border-bottom: 2px solid rgba(179, 177, 177, 0.5);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
</style>