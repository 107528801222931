<template>
  <div class="">
    <div class="row orange-underline-wizard  mb-3">
      <div class="col-md-12 mb-4 ml-4 mt-4 font-weight-bold text-gray">
        Add New Solution
      </div>
    </div>
    <div>
      <div class="row form-row-height pt-2 pb-2 light-blue-text">
        <div
          class="col-md-2 ml-4 font-weight-bold"
          style="border-right: 1px solid #eeeded"
        >
          General Information
        </div>
        <div class="col-md-8">
          <div class="row col-md-12 font-weight-bold">
            General Information
            <v-divider class="mt-3 ml-3"></v-divider>
          </div>
          <v-form ref="form" v-model="valid" class="row mt-10">
            <div class="col-md-6 ml-3">
              <div class="col-md-12 pb-0">
                <span class="text-gray font-weight-bold"
                  >Solution Category:</span
                >
                <v-text-field solo v-model="item.category" disabled :rules="requiredRules"> </v-text-field>
              </div>
              <br />
              <div class="col-md-12 pb-0 pt-0">
                <span class="text-gray font-weight-bold"
                  >Solution Description:</span
                >

                <v-text-field solo v-model="item.description" :rules="requiredRules"> </v-text-field>
              </div>
              <div class="col-md-12 pb-0 pt-0">
                <span class="text-gray font-weight-bold">Solution URL:</span>
                <v-text-field solo v-model="item.url" :rules="requiredRules"> </v-text-field>
              </div>
              <br />
              <div class="col-md-12 pb-0 pt-0">
                <span class="text-gray font-weight-bold"
                  >Solution Contact:</span
                >
                <v-text-field solo v-model="item.contact" :rules="requiredRules"> </v-text-field>
              </div>
              <br />
              <div class="col-md-12 pb-0 pt-0">
                <span class="text-gray font-weight-bold">Solution Email:</span>
                <v-text-field solo v-model="item.email" :rules="emailRules"> </v-text-field>
              </div>
              <br />
              <div class="col-md-12 pb-0 pt-0">
                <span class="text-gray font-weight-bold"
                  >Solution Definition:</span
                >
                <v-text-field solo v-model="item.definition" :rules="requiredRules"> </v-text-field>
              </div>
            </div>
          </v-form>
        </div>
      </div>
    </div>
    <div class="gray-top-border">
      <div class="col-md-12" align="end">
        <span
          class="light-blue-text text-underline mr-10 font-weight-bold pointer"
          @click="addSolution"
          >Submit</span
        >
        <span
          class="
            light-blue-text
            text-underline
            mr-10
            ml-10
            font-weight-bold
            pointer
          "
          @click="$router.push('/digital-invoicing/solutions')"
          >Close</span
        >
      </div>
    </div>
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
  </div>
</template>

<script>
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import api from "../../../../services/api";

export default {
  components: { successModal },
  data() {
    return {
      item: {
        category: "DPC",
        url: "",
        contact: "",
        email: "",
        definition: "",
        description: "",
        
      },
      valid:false,
       emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      requiredRules: [(v) => !!v || "This field is required"],

    };
  },

  computed: {},
  created() {},
  methods: {
    closeSuccessModal() {
      this.$parent.getSolutions();
      this.$router.push("/vendors/solutions");
    },
    addSolution() {
      this.$refs.form.validate();
      if (this.valid) {
        api()
          .post("/vendors/solutions", this.item)
          .then((res) => {
            if(res){
            this.$refs.success_modal.openPopUp('Added successfully');

            }
          });
      }
    },
  },
};
</script>
