<template>
  <div class="">
    <div class="row border-blue-bottom mb-3">
      <div class="col-md-12 mb-4 mt-4 ml-4 font-weight-bold text-gray">
        Activate Vendor
      </div>
    </div>
    <div v-if="solution">
      <div class="row form-row-height pt-2 pb-2">
        <div class="col-md-2" style="border-right: 1px solid #eeeded">
          <div class="row">
            <div class="col-12 text-gray">
              <span
                :class="
                  tab == 'general'
                    ? 'ml-4 font-weight-bold light-blue-text'
                    : 'ml-4 font-weight-bold'
                "
                >General Information</span
              >
            </div>
            <div class="col-12 mt-6 text-gray">
              <span
                :class="
                  tab == 'rates'
                    ? 'ml-4 font-weight-bold light-blue-text'
                    : 'ml-4 font-weight-bold'
                "
                >Services & Fees</span
              >
            </div>
            <div class="col-12 mt-6 text-gray">
              <span
                :class="
                  tab == 'contracts'
                    ? 'ml-4 font-weight-bold light-blue-text'
                    : 'ml-4 font-weight-bold '
                "
                >Contract(s)</span
              >
            </div>
            <div class="col-12 mt-6 text-gray">
              <span
                :class="
                  tab == 'acceptance'
                    ? 'ml-4 font-weight-bold  light-blue-text'
                    : 'ml-4 font-weight-bold'
                "
                >Acceptance</span
              >
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <GeneralInformation
            :item="solution"
            v-if="solution && tab == 'general'"
          ></GeneralInformation>
          <Rates
            :item="solution"
            @getDefService="getDefService"
            @getVendorRate="getVendorRate"
            @getTpaFee="getTpaFee"
            v-if="solution && tab == 'rates'"
          >
          </Rates>
          <Contracts
            @getContract="getContract"
            @base64file="base64file"
            :item="solution"
            v-if="solution && tab == 'contracts'"
          >
          </Contracts>
          <Acceptance
            :item="solution"
            @getAcceptedByTpa="getAcceptedByTpa"
            @getClient="getClient"
            v-if="solution && tab == 'acceptance'"
          ></Acceptance>
        </div>
      </div>
    </div>
    <div class="gray-top-border">
      <div class="col-md-12 f-lex" align="end">
        <span
          class="light-blue-text text-underline mr-10 font-weight-bold pointer"
          @click="backTab(tab)"
        >
          Back
        </span>
        <span
          class="light-blue-text text-underline mr-10 font-weight-bold pointer"
          v-if="tab != 'acceptance'"
          @click="nextTab(tab)"
        >
          Next
        </span>
        <span
          class="light-blue-text text-underline mr-10 font-weight-bold pointer"
          v-else
          @click="submit()"
        >
          Submit
        </span>
      </div>
    </div>
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
  </div>
</template>
<script>
import successModal from "../../../../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../../../../TablesTemplate/ErrorDialog.vue";
import GeneralInformation from "@/components/Sections/Tpa/Clients/HorizontalMenu/Solutions/VendorFlow/Details/GeneralInformation";
import Rates from "@/components/Sections/Tpa/Clients/HorizontalMenu/Solutions/VendorFlow/Details/Rates";
import Contracts from "@/components/Sections/Tpa/Clients/HorizontalMenu/Solutions/VendorFlow/Details/Contracts";
import SolutionFacilitator from "@/components/Sections/Tpa/Clients/HorizontalMenu/Solutions/VendorFlow/Details/SolutionFacilitator";
import Acceptance from "@/components/Sections/Tpa/Clients/HorizontalMenu/Solutions/VendorFlow/Details/Acceptance";
import api from "@/services/api";
export default {
  components: {
    SolutionFacilitator,
    Contracts,
    Rates,
    GeneralInformation,
    Acceptance,
    errorModal,
    successModal,
  },
  data() {
    return {
      solution: null,
      tab: "general",
      serviceDefinition: "",
      serviceRate: "",
      tpaFee: "",
      acceptedBy: "",
      customContract: "",
      clientData: {},
      noContractBox: "",
      dataToSend: {},
    };
  },

  computed: {},
  created() {
    this.$store.commit("setAcceptByClient", {});
        this.$store.commit("setVendorContract", "");
        this.$store.commit("setNoVendorContract", "");
        this.$store.commit("setDefinitionOfService", "");
        this.$store.commit("setVendorRate", "");
        this.$store.commit("setTpaFee", "");
    let item = this.$store.getters.getTpaSingleSolution;
    api()
      .get("/tpas/market/vendor/" + this.$route.params.vendorId)
      .then((res) => {
        if (res) {
          this.solution = res.data;
          this.solution.plans = item.plan;
        }
      });
  },
  methods: {
    getDefService(data) {
      this.serviceDefinition = data;
    },
    getVendorRate(data) {
      this.serviceRate = data;
    },
    getTpaFee(data) {
      this.tpaFee = data;
    },
    getClient(data) {
      this.clientData = data;
    },
    getContract(data) {
      this.noContractBox = data;
    },
    getAcceptedByTpa(data) {
      this.acceptedBy = data;
    },
    base64file(data) {
      this.customContract = data;
    },
    submit() {
      if(this.acceptedBy || Object.keys(this.clientData).length!= 0){
        const commonData = {
        plans: this.solution.plans,
        serviceDefinition: this.serviceDefinition,
        serviceRate: this.serviceRate,
        tpaFee: this.tpaFee ? this.tpaFee : "0",
      };
      if (this.acceptedBy === true) {
        this.dataToSend = {
          ...commonData,
          acceptedBy: "tpa",
          customContract: this.customContract || undefined,
        };
      } else {
        this.dataToSend = {
          ...commonData,
          acceptedBy: "employer",
          firstName: this.clientData.firstName,
          lastName: this.clientData.lastName,
          email: this.clientData.email,
          customContract: this.customContract || undefined,
        };
      }
      api()
        .post(
          `/tpas/client/${this.$route.params.clientId}/vendor/${this.$route.params.vendorId}`,
          this.dataToSend
        )
        .catch((err) => {
          if(err){
             this.$store.commit("setAcceptByClient", {});
          this.$store.commit("setVendorContract", "");
          this.$store.commit("setNoVendorContract", "");
          this.$store.commit("setDefinitionOfService", "");
          this.$store.commit("setVendorRate", "");
          this.$store.commit("setTpaFee", "");
          this.$refs.error_modal.openPopUp(err.response.data);
          }
         
        })
        .then((res) => {
          if (res) {
            this.$refs.success_modal.openPopUp(res.data);
          }
        });   
      }
      else{
        this.$refs.error_modal.openPopUp('Please complete all required fields!');
      }
   
    },
    nextTab(tab) {
      if (tab == "general") {
        this.tab = "rates";
      } else if (tab == "rates") {
        if (!this.serviceDefinition || !this.serviceRate) {
          this.$refs.error_modal.openPopUp(
            "Please fill the required fileds before you proceed to the next"
          );
        } else {
          this.tab = "contracts";
        }
      } else if (tab == "contracts") {
        this.tab = "acceptance";
      }
    },
    backTab(tab) {
      if (tab == "rates") {
        this.tab = "general";
      } else if (tab == "contracts") {
        this.tab = "rates";
      } else if (tab == "acceptance") {
        this.tab = "contracts";
      } else if (tab == "general") {
        this.$store.commit("setAcceptByClient", {});
        this.$store.commit("setVendorContract", "");
        this.$store.commit("setNoVendorContract", "");
        this.$store.commit("setDefinitionOfService", "");
        this.$store.commit("setVendorRate", "");
        this.$store.commit("setTpaFee", "");
        this.$router.go(-1);
      }
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
      this.$router.go(-1);
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
      this.$store.commit("setAcceptByClient", {});
      this.$store.commit("setVendorContract", "");
      this.$store.commit("setNoVendorContract", "");
      this.$store.commit("setDefinitionOfService", "");
      this.$store.commit("setVendorRate", "");
      this.$store.commit("setTpaFee", "");
      this.$router.go(-1);
    },
  },
};
</script>
