<template>
  <div class="row-height">
    <router-view></router-view>
    <v-sheet class="overflow-hidden col-md-12" v-if="!isChildren" style="position: relative">
      <div class="container-fluid">
        <v-row align="center" justify="center">
          <div
            :class="
              overlay
                ? 'hideOnMoblie background-opacity col-md-9'
                : 'col-md-12 navigationWidth '
            "
          >
            <v-row>
              <v-col class="col-xl-1 col-md-2 cols-4" cols="4">
                <div class="col-md-12">
                  <h3 class="form-title font">Find Care</h3>
                </div>
              </v-col>
              <v-col class="col-xl-4 col-md-6 cols-8" cols="8">
                <div class="col-md-8 d-flex no-padding">
                  <v-select
                    :items="items"
                    item-text="name"
                    class="input-control mr-5"
                    v-model.trim="modalItem"
                    prepend-inner-icon="mdi-magnify"
                    @change="openModal()"
                    placeholder="Select Provider"
                  ></v-select>
                  <v-btn
                    :disabled="overlay"
                    class="text-gray filter-btn"
                    @click.stop="drawer = !drawer"
                  >
                    <svg
                      width="17"
                      class="mr-2"
                      height="14"
                      viewBox="0 0 17 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1H16"
                        stroke="#424242"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M2.59766 5H14.403"
                        stroke="#424242"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6.3125 13H10.6858"
                        stroke="#424242"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M4.19531 9H12.8046"
                        stroke="#424242"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Filters
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <div v-for="(item, index) in items" :key="index" class="col-md-12">
              <v-card class="col-md-12 d-flex justify-content-between pointer" @click="$router.push('/family-members/care/details')">
                <div class="col-md-11">
                  <v-card-title class="pointer pb-0 mb-0">
                    <span class="light-blue-text text-underline mr-4">{{
                      item.name
                    }}</span>
                  </v-card-title>

                  <v-card-text>
                    <div>
                      {{ item.address }}
                    </div>
                    <div class="light-blue-text mt-1">
                      {{ item.number }}
                    </div>
                  </v-card-text>
                </div>
                <div class="col-md-1" style="height: 30px">
                  <v-checkbox
                    size="40"
                    v-model.trim="item.selected"
                    large
                    class="ml-7 radio-buttons-filters shrink "
                  ></v-checkbox>
                  <label
                    class="col-md-4 text-center mr-3"
                    v-html="item.selected ? 'Selected' : 'Select Provider'"
                  ></label>
                </div>
              </v-card>
            </div>
          </div>
        </v-row>
      </div>
      <v-navigation-drawer
        class="userFilterPosition"
        width="350"
        v-model.trim="drawer"
        absolute
        temporary
      >
        <div class="filter-sideBar">
          <div>
            <div class="row no-padding col-md-12 justify-space-between">
              <h3 class="font pl-3 mt-1">Provider Filters</h3>
              <h1 class="pointer mr-3" @click.stop="drawer = !drawer">x</h1>
            </div>
            <v-divider></v-divider>
            <div class="col-md-12">
              <div
                class="row col-md-12 justify-space-between no-padding no-margin"
              >
                <div class="no-padding font filter-items-font font-weight-bold">
                  Selected Filter
                </div>
                <div
                  class="underline-text pointer text-gray filter-items-font"
                  @click="clearAll"
                >
                  Clear All
                </div>
              </div>
            </div>
            <div class="overflow-fitlers-buttons">
              <div class="row mb-1 col-md-12">
                <v-btn
                  v-for="(item, index) in selectedArray.concat(
                    selectedTypeArray
                  )"
                  :key="index"
                  class="text-center mb-1 ml-2 mr-2 filter-btn"
                  small
                >
                  <span v-html="item.text"></span>
                  <h5 class="ml-3" @click="removeFilter(item)">x</h5>
                </v-btn>
              </div>
              <v-divider></v-divider>
              <v-expansion-panels
                accordion
                multiple
                v-model.trim="panel"
                class="no-shadow no-padding user-expansion-panels"
              >
                <v-expansion-panel class="no-shadow no-padding">
                  <v-expansion-panel-header
                    class="
                      font-weight-black
                      change-padding-filter
                      filter-items-font
                    "
                    >Status</v-expansion-panel-header
                  >
                  <v-expansion-panel-content class="no-padding content-filter">
                    <v-text-field
                      solo
                      class="filter-serach-input mb-4"
                      v-model.trim="status"
                      @input="searchStatus()"
                      dense
                      :hide-details="true"
                      placeholder="Search"
                      append-icon="fa fa-search"
                    ></v-text-field>
                    <v-checkbox
                      label="All"
                      color="primary"
                      v-if="showStatus.all"
                      v-model.trim="filter.status.all"
                      class="no-padding small-text radio-buttons-filters"
                      hide-details
                      :on-icon="'mdi-square'"
                      @click="
                        pushFilter(
                          { text: 'All', value: 'all' },
                          filter.status.all
                        )
                      "
                    >
                    </v-checkbox>
                    <v-checkbox
                      label="Join Request"
                      color="primary"
                      v-if="showStatus['join request']"
                      v-model.trim="filter.status.join"
                      class="no-padding radio-buttons-filters"
                      hide-details
                      :on-icon="'mdi-square'"
                      @click="
                        pushFilter(
                          { text: 'Join Request', value: 'join' },
                          filter.status.join
                        )
                      "
                    >
                    </v-checkbox>
                    <v-checkbox
                      label="Active"
                      color="primary"
                      v-if="showStatus.active"
                      :on-icon="'mdi-square'"
                      v-model.trim="filter.status.active"
                      class="no-padding radio-buttons-filters"
                      hide-details
                      @click="
                        pushFilter(
                          { text: 'Active', value: 'active' },
                          filter.status.active
                        )
                      "
                    >
                    </v-checkbox>
                    <v-checkbox
                      label="In Active"
                      color="primary"
                      v-if="showStatus['in active']"
                      v-model.trim="filter.status.inactive"
                      :on-icon="'mdi-square'"
                      class="no-padding radio-buttons-filters"
                      hide-details
                      @click="
                        pushFilter(
                          { text: 'In Active', value: 'inactive' },
                          filter.status.inactive
                        )
                      "
                    >
                    </v-checkbox>
                    <v-checkbox
                      label="Invited"
                      color="primary"
                      v-if="showStatus.invited"
                      :on-icon="'mdi-square'"
                      v-model.trim="filter.status.invited"
                      class="no-padding radio-buttons-filters mb-2"
                      hide-details
                      @click="
                        pushFilter(
                          { text: 'Invited', value: 'invited' },
                          filter.status.invited
                        )
                      "
                    >
                    </v-checkbox>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel class="no-shadow no-padding">
                  <v-expansion-panel-header
                    class="
                      font-weight-black
                      change-padding-filter
                      filter-items-font
                      mb-0
                      pb-0
                    "
                    >Type</v-expansion-panel-header
                  >
                  <v-expansion-panel-content class="no-padding content-filter">
                    <v-text-field
                      solo
                      class="filter-serach-input no-padding mb-4"
                      v-model.trim="type"
                      dense
                      :hide-details="true"
                      @input="searchType()"
                      placeholder="Search"
                      append-icon="fa fa-search"
                    ></v-text-field>
                    <v-checkbox
                      label="All"
                      color="primary"
                      v-if="showType.all"
                      :on-icon="'mdi-square'"
                      v-model.trim="filter.type.all"
                      class="no-padding radio-buttons-filters"
                      hide-details
                      @click="
                        pushtoTypeFilter(
                          { text: 'All', value: 'allTypes' },
                          filter.type.all
                        )
                      "
                    >
                    </v-checkbox>
                    <v-checkbox
                      label="Admins"
                      color="primary"
                      v-if="showType.admin"
                      v-model.trim="filter.type.admin"
                      :on-icon="'mdi-square'"
                      class="no-padding radio-buttons-filters"
                      hide-details
                      @click="
                        pushtoTypeFilter(
                          { text: 'Admins', value: 'admin' },
                          filter.type.admin
                        )
                      "
                    >
                    </v-checkbox>
                    <v-checkbox
                      label="General User"
                      color="primary"
                      :on-icon="'mdi-square'"
                      v-if="showType['general user']"
                      v-model.trim="filter.type.user"
                      class="no-padding radio-buttons-filters"
                      hide-details
                      @click="
                        pushtoTypeFilter(
                          { text: 'General User', value: 'user' },
                          filter.type.user
                        )
                      "
                    >
                    </v-checkbox>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
          <div>
            <v-divider></v-divider>
            <v-btn class="blue-buttons col-md-12 mt-3" @click="filterUsers"
              >View Result</v-btn
            >
          </div>
        </div>
      </v-navigation-drawer>
    </v-sheet>
    <v-dialog v-model.trim="dialog" width="400" class="success-modal">
      <v-card class="col-md-12">
        <v-card-title v-if="modalItem" class="text-gray">
          <h3>{{ modalItem }}</h3>
        </v-card-title>
        <v-divider></v-divider>
        <h3 class="col-md-12 text-gray ml-2">
          Select this provider for other family members:
        </h3>
        <v-card-text class="ml-2">
          <div v-for="(item,index) in items" :key="index" class="row">
            <v-checkbox></v-checkbox>
            <label class="mt-5" for="">
              <h3>
                {{ item.name }}
              </h3>
            </label>
          </div>
        </v-card-text>
        <div
          class="
            row
            light-blue-text
            pointer
            text-underline text-left
            justify-end
          "
        >
          <div class="text-end justify-end col-md-12" @click="dialog = false">Cancel</div>
        </div>
      </v-card>
    </v-dialog>
   
  </div>
</template>
<script>
export default {
  data() {
    return {
      items: [
        {
          name: "Eden Health",
          address: "1313 Mockingbird Lane Tampa, Florida 33602",
          number: "813-555-1212",
          selected: true,
        },
        {
          name: "Everside Health",
          address: "1313 Mockingbird Lane Tampa, Florida 33602",
          number: "813-555-1212",
          selected: true,
        },
        {
          name: "Care ATC",
          address: "1313 Mockingbird Lane Tampa, Florida 33602",
          number: "813-555-1212",
          selected: false,
        },
        {
          name: "Marathon Health",
          address: "1313 Mockingbird Lane Tampa, Florida 33602",
          number: "813-555-1212",
          selected: false,
        },
      ],
      drawer: null,
      showStatus: {
        all: true,
        active: true,
        "in active": true,
        invited: true,
        "join request": true,
      },
      showType: {
        all: true,
        "general user": true,
        admin: true,
      },
      modalItem: {
        name: "",
      },
      type: "",
      status: "",
      dialog: false,
      panel: [0, 1],
      userStatus: [
        {
          text: "ALL",
          value: "all",
        },

        {
          text: "Join Request",
          value: "join",
        },
        {
          text: "Active",
          value: "active",
        },
        {
          text: "In Active",
          value: "inactive",
        },
        {
          text: "Invited",
          value: "invited",
        },
      ],
      userType: [
        {
          text: "ALL",
          value: "all",
        },
        {
          text: "Admins",
          value: "admins",
        },
        {
          text: "General User",
          value: "users",
        },
      ],
      overlay: false,
      selectedArray: [],
      selectedTypeArray: [],
      filter: {
        status: {
          all: false,
          active: false,
          inactive: false,
          invited: false,
          join: false,
        },
        type: {
          all: false,
          user: false,
          admin: false,
        },
      },
    };
  },
  computed: {
    filteredArray() {
      return this.response;
    },
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
  },
  methods: {
    pushtoTypeFilter(item, checked) {
      if (checked) {
        if (item.value == "allTypes") {
          this.selectedTypeArray = [];
          this.filter.type = {
            all: true,
            user: true,
            admin: true,
          };
          this.selectedTypeArray.push({ text: "All", value: "allTypes" });
        } else {
          this.selectedTypeArray.push(item);
        }
      } else {
        if (item.value == "allTypes" || this.filter.type.all) {
          this.filter.type = {
            all: false,
            user: false,
            admin: false,
          };
          this.selectedTypeArray = [];
        } else {
          this.selectedTypeArray.forEach((el, index) => {
            if (el.value == item.value) {
              this.selectedTypeArray.splice(index, 1);
            }
          });
        }
      }
    },
    pushFilter(item, checked) {
      if (checked) {
        if (item.value == "all") {
          this.selectedArray = [];
          this.filter.status = {
            all: true,
            active: true,
            inactive: true,
            invited: true,
            join: true,
          };
          this.selectedArray.push({ text: "All", value: "all" });
        } else {
          this.selectedArray.push(item);
        }
      } else {
        if (item.value == "all" || this.filter.status.all) {
          this.filter.status = {
            all: false,
            active: false,
            inactive: false,
            invited: false,
            join: false,
          };
          this.selectedArray = [];
        } else {
          this.selectedArray.forEach((el, index) => {
            if (el.value == item.value) {
              this.selectedArray.splice(index, 1);
            }
          });
        }
      }
    },
    removeFilter(item) {
      if (item.value == "allTypes") {
        this.selectedTypeArray = [];
        this.filter.type = {
          all: false,
          user: false,
          admin: false,
        };
      } else if (this.filter.status[item.value]) {
        this.filter.status[item.value] = false;
        this.selectedArray.splice(this.selectedArray.indexOf(item), 1);
      }

      if (item.value == "all") {
        this.selectedArray = [];
        this.filter.status = {
          all: false,
          active: false,
          inactive: false,
          invited: false,
          join: false,
        };
      } else if (this.filter.type[item.value]) {
        this.filter.type[item.value] = false;
        this.selectedTypeArray.splice(this.selectedTypeArray.indexOf(item), 1);
      }
    },
    searchStatus() {
      for (let key in this.showStatus) {
        if (key.toLowerCase().includes(this.status.toLowerCase())) {
          this.showStatus[key] = true;
        } else {
          this.showStatus[key] = false;
        }
      }
    },
    searchType() {
      for (let key in this.showType) {
        if (key.toLowerCase().includes(this.type.toLowerCase())) {
          this.showType[key] = true;
        } else {
          this.showType[key] = false;
        }
      }
    },
    filterUsers() {
      if (!this.filter.status && !this.filter.type) {
        this.getAllInvoices("all");
      } else {
        this.getAllInvoices(this.filter.status);
      }
      this.filterUse = true;
      this.drawer = false;
    },
    openModal() {
      this.dialog = true;
    },
    clearAll() {
      this.filter.status = {
        all: false,
        active: false,
        inactive: false,
        invited: false,
        join: false,
      };
      this.filter.type = {
        all: false,
        user: false,
        admin: false,
      };

      this.selectedArray = [];
      this.selectedTypeArray = [];
      this.getAllInvoices("all");
    },
    searchEmp(val) {
      this.allEmployers = this.filteredArray.filter((el) => {
        if (el.companyName.toLowerCase().includes(val.toLowerCase())) {
          return el.companyName.toLowerCase().includes(val.toLowerCase());
        }
      });
    },
    singleEmp(item) {
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.employerAdvisor;

      api()
        .get(`/advisors/${advisorId}/employers/${item._id}`)
        .then((res) => {
          if (res) {
            api()
              .get(`/advisors/${advisorId}/employers/${item._id}/statistics`)
              .then((res) => {
                this.$store.commit(
                  "setStatisticsInfoHorizontalMenu",
                  res.data.statistics
                );
              });
            this.$store.commit("setSingleEmployer", res.data);
            localStorage.setItem("employer", JSON.stringify(item));
            this.$router.push("/employer-advisor/employers/employer");
            // this.$router.push({path:'/employer-advisor/employers/'+ item.companyName, params:{name:item.companyName}})
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    searchInvoices() {},
    getAllInvoices() {},
  },
};
</script>
