<template>
  <v-row class="padding">
    <div class="col-md-12" v-if="$role != 'user'">
      <h3 class="font">Add New Partner</h3>
      <div class="font mt-5">
        <h3 class="">Search and Add a Partner</h3>
      </div>
    </div>
    <div class="col-md-12" v-else>
      <h3 class="font">Search Partners</h3>
    </div>

    <form class="row form-row-height">
      <div class="col-md-6">
        <div class="col-md-12">
          <label class="font text-gray" for="">Partner Type</label>
          <v-select
            required
            solo
            :items="typeOptions"
            v-model.trim="advisorType"
          ></v-select>
        </div>
        <div class="col-md-12 pb-0 pt-0">
          <label class="font text-gray" for="">Partner Organization Name</label>
          <v-text-field
            required
            solo
            v-model.trim="advisorOrganizationName"
          ></v-text-field>
        </div>
        <div class="row no-padding">
          <div class="col-md-6 pb-0 pt-0">
            <label class="font text-gray" for="">City</label>
            <v-text-field
              required
              v-model.trim="advisorCity"
              solo
            ></v-text-field>
          </div>
          <div class="col-md-3 pb-0 pt-0">
            <label class="font text-gray" for="">State</label>
            <v-select
              class="text-gray"
              :items="states"
              label=""
              solo
              item-text="name"
              item-value="name"
              v-model.trim="advisorState"
            ></v-select>
          </div>
          <div class="col-md-3 pb-0 pt-0">
            <label class="font text-gray" for="">Zip</label>
            <v-text-field
              required
              type="number"
              v-model.trim="advisorZip"
              solo
            ></v-text-field>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="col-md-12">
          <label class="font text-gray" for=""
            >Individual Partner First Name</label
          >
          <v-text-field
            required
            v-model.trim="advisorFirstName"
            solo
          ></v-text-field>
        </div>
        <div class="col-md-12  pt-0">
          <label class="font text-gray" for="">Last Name</label>
          <v-text-field
            required
            solo
            v-model.trim="advisorLastName"
          ></v-text-field>
        </div>
        <div class="col-md-12 d-flex align-end flex-column">
          <div>
            <v-btn class="blue-buttons mr-6" @click="searchAdvisors">
              Search
            </v-btn>
          </div>
        </div>
      </div>
    </form>
  </v-row>
</template>
<script>
import api from "../../../../../services/api";
export default {
  data() {
    return {
      advisorType: "",
      advisorOrganizationName: "",
      advisorCity: "",
      advisorState: "",
      advisorZip: "",
      advisorFirstName: "",
      advisorLastName: "",
      states: [],
      typeOptions: [
        {
          text: "Third Party Administrator (TPA)",
          value: "TPA",
        },
        {
          text: "Broker Consultant",
          value: "brokerConsultant",
        },
        {
          text: "Analytics Consultant",
          value: "analyticsConsultant",
        },
      ],
    };
  },
  computed: {
    getClient() {
      return this.$store.getters.getClientGetter;
    },
    routeParams() {
      return this.$route.params.name;
    },
  },
  created() {
    this.getStates();
  },
  methods: {
    getStates() {
      api()
        .get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },
    searchAdvisors() {
      let urlData = "";
      urlData += this.advisorType ? `advisorType=${this.advisorType}&` : "";
      urlData += this.advisorOrganizationName
        ? `companyName=${this.advisorOrganizationName}&`
        : "";
      urlData += this.city ? `city=${this.city}&` : "";
      urlData += this.state ? `state=${this.state}&` : "";
      urlData += this.zip ? `zipCode =${this.zip}&` : "";
      urlData += this.firstName ? `firstName=${this.firstName}&` : "";
      urlData += this.lastName ? `lastName=${this.lastName}&` : "";
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.employerAdvisor;
      api()
        .get(`/advisors/${advisorId}/search?` + urlData)
        .then((res) => {
          if (res) {
            this.$router.push(
              `/employer-advisor/employers/${this.routeParams}/advisors/result`
            );
            this.$store.commit("setAdvisorSearchResult", res.data.advisors);
          }
        });
    },
  },
};
</script>
 