<template>
  <div>
    <v-card class="col-md-12 white-background" v-if="getSingleSolution">
      <div class="row row-height pt-2 pb-2">
        <div class="col-md-2" style="border-right: 1px solid #eeeded">
          <img :src="getSingleSolution.img" alt="" />
        </div>

        <div class="col-md-9  font-weight-bold">
          <div class="col-md-12 mt-3 ml-3">{{ getSingleSolution.desc1 }}</div>
          <div class="col-md-12 ml-3">{{ getSingleSolution.desc2 }}</div>
        </div>
      
        <div class="col-md-1 text-end">
          <v-rating
            v-if="getSingleSolution.rating"
            :value="1"
            length="1"
            background-color="orange lighten-3"
            color="orange"
            large
          ></v-rating>
          <v-rating
            v-else
            length="1"
            background-color="orange lighten-3"
            color="orange"
            large
          ></v-rating>
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getSingleSolution"]),
  },
  created() {},
  methods: {},
};
</script>
