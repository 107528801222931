<template>
  <div>
    <div class="row col-md-12 font-weight-bold light-blue-text">
      Contracts
      <v-divider class="mt-3 ml-3"></v-divider>
    </div>
    <div class="row col-md-12 mt-10">
      <div class="row col-md-12">
        <div class="col-12">
          <a href="#" class="font-weight-bold ml-4">
            Agreement between Employer and Vendor / Provider
          </a>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-md-12 row ml-8">
              <span class="text-gray font-weight-bold col" v-if="!employerProviderContract">
                This solution operates without a contract requirement between the Employer and the Vendor / Provider
              </span>
              <span class="text-gray font-weight-bold col" v-else>
                This solution operates with a contract requirement between the Employer and the Vendor / Provider.
                <a :href="employerProviderContract.pdf" target="_blank">Click</a> to view the contract.
              </span>
              <div v-if="item.contracted" class="col-md-4 text-right text-gray">
                Acknowledged on {{ new Date(item.acceptedDate) | moment("MM/DD/YYYY") }}
              </div>
            </div>
          </div>
        </div>
      </div>
      

      <div class="row col-md-12 mt-10">
        <div class="col-12">
          <a href="#" class="font-weight-bold ml-4">
            Agreement between Employer and Solution Facilitator
          </a>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-md-12 row ml-8">
              <span class="text-gray font-weight-bold col" v-if="!employerFacilitatorContract">
                This solution operates without a contract requirement between the Employer and the Solution Facilitator
              </span>
              <span class="text-gray font-weight-bold col" v-else>
                This solution operates with a contract requirement between the Employer and the Solution Facilitator. 
                <a :href="acceptedEmpFacContract" target="_blank">Click</a> to view the contract.
              </span>
              <div v-if="item.contracted" class="col-md-4 text-right text-gray">
                Acknowledged on {{ new Date(item.acceptedDate) | moment("MM/DD/YYYY") }}
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>

export default {
  name: "Contracts",
  props:['item'],
  computed: {
    employerContracts(){
      return this.item.contracts.filter(item => item.parties.includes('employer'))
    },
    employerProviderContract(){
      return this.employerContracts.find(item => item.parties.includes('provider/vendor'))
    },
    employerFacilitatorContract(){
      return this.employerContracts.find(item => item.parties.includes('facilitator'))
    },
    acceptedEmpFacContract(){
      return this.item.contracted ? 
        `${process.env.VUE_APP_API}/tpas/clients/solution/contract/${this.item.acceptedContracts[0]}` : this.employerFacilitatorContract.pdf
    }
  }
}
</script>

<style scoped>

</style>