<template>
  <div class="row row-height2">
    <FullscreenLoader v-if="loader" />

    <v-sheet v-else
     
      class="overflow-hidden col-md-12 border-r-8"
      style="position: relative"
    >
      <div class="">
        <v-row align="center" justify="center">
          <div
            :class="
              overlay
                ? 'hideOnMoblie background-opacity col-md-9'
                : 'col-md-12 navigationWidth no-padding'
            "
          >
            <v-row class="blue-underline mb-1">
              <v-col class="col-xl-2 col-md-2 cols-4 pt-2 pb-0" cols="4">
                <div class="col-md-12">
                  <h3 class="form-title font col-md-12 font-18">Locations</h3>
                </div>
              </v-col>

              <v-col class="col-xl-3 col-md-6 cols-8" cols="8">
                <div class="col-md-12 d-flex no-padding pt-4">
                  <v-text-field
                    :disabled="overlay"
                    v-model="searchQuery"
                    class="input-control mr-5"
                    prepend-inner-icon="mdi-magnify"
                    @input="filterLocations"
                    light
                    dense
                    outlined
                    placeholder="Search Locations"
                  ></v-text-field>
                  <v-btn
                    :disabled="overlay"
                    class="text-gray filter-btn"
                    @click.stop="showFilterSideBar"
                  >
                    <svg
                      width="17"
                      class="mr-2"
                      height="14"
                      viewBox="0 0 17 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1H16"
                        stroke="#424242"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M2.59766 5H14.403"
                        stroke="#424242"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6.3125 13H10.6858"
                        stroke="#424242"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M4.19531 9H12.8046"
                        stroke="#424242"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Filters
                  </v-btn>
                </div>
              </v-col>

              <v-col
                class="col-xl-7 col-md-4 pr-6 pt-6 cols-12 text-end"
                cols="12"
                align="end"
              >
                <v-btn
                  @click="exportData('tblData')"
                  class="my-1 mr-4 gray-border-buttons"
                >
                  <svg
                    class="mr-2"
                    width="15"
                    height="16"
                    viewBox="0 0 15 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.2361 10.7639C14.0329 10.7639 13.838 10.8434 13.6937 10.985C13.5495 11.1267 13.4676 11.319 13.4659 11.5202V12.6764C13.4659 12.9367 13.3615 13.1862 13.1756 13.3703C12.9897 13.5543 12.7376 13.6577 12.4747 13.6577H2.52525C2.26238 13.6577 2.01027 13.5543 1.8244 13.3703C1.63852 13.1862 1.53409 12.9367 1.53409 12.6764V11.5202C1.51671 11.3307 1.42838 11.1544 1.28645 11.0261C1.14452 10.8978 0.959257 10.8266 0.767046 10.8266C0.574834 10.8266 0.389569 10.8978 0.247642 11.0261C0.105715 11.1544 0.0173841 11.3307 0 11.5202V12.6764C0 13.3395 0.266053 13.9753 0.739629 14.4442C1.21321 14.913 1.85551 15.1764 2.52525 15.1764H12.4747C13.1445 15.1764 13.7868 14.913 14.2604 14.4442C14.7339 13.9753 15 13.3395 15 12.6764V11.5202C15 11.3196 14.9195 11.1272 14.7763 10.9854C14.633 10.8436 14.4387 10.7639 14.2361 10.7639Z"
                      fill="#707070"
                    />
                    <path
                      d="M7.49971 0.176392C7.17488 0.176392 6.86336 0.304137 6.63368 0.531525C6.404 0.758913 6.27496 1.06732 6.27496 1.38889V7.05139C6.27496 7.09283 6.25833 7.13257 6.22873 7.16188C6.19913 7.19118 6.15899 7.20764 6.11713 7.20764H4.43784C4.32437 7.21533 4.2151 7.25322 4.12158 7.31731C4.02807 7.3814 3.95378 7.46931 3.90657 7.57175C3.85935 7.6742 3.84097 7.78736 3.85337 7.89929C3.86576 8.01122 3.90847 8.11776 3.97698 8.20764L7.03885 11.6951C7.0979 11.7576 7.16927 11.8074 7.24855 11.8414C7.32782 11.8754 7.4133 11.893 7.49971 11.893C7.58611 11.893 7.6716 11.8754 7.75087 11.8414C7.83014 11.8074 7.90151 11.7576 7.96057 11.6951L11.0224 8.20764C11.0909 8.11776 11.1337 8.01122 11.146 7.89929C11.1584 7.78736 11.1401 7.6742 11.0928 7.57175C11.0456 7.46931 10.9713 7.3814 10.8778 7.31731C10.7843 7.25322 10.675 7.21533 10.5616 7.20764H8.88228C8.84042 7.20764 8.80028 7.19118 8.77068 7.16188C8.74108 7.13257 8.72445 7.09283 8.72445 7.05139V1.38889C8.72445 1.06732 8.59542 0.758913 8.36573 0.531525C8.13605 0.304137 7.82453 0.176392 7.49971 0.176392V0.176392Z"
                      fill="#707070"
                    />
                  </svg>

                  Export</v-btn
                >
                <v-menu offset-y left bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="gray-border-buttons">
                      <svg
                        class="mr-2"
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0 7.5H15"
                          stroke="#707070"
                          stroke-width="2"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M7.5 0V15"
                          stroke="#707070"
                          stroke-width="2"
                          stroke-linejoin="round"
                        />
                      </svg>

                      New
                    </v-btn>
                  </template>
                  <v-list class="dpc-menu-dropdown" style="margin-right: 0px">
                    <v-list-item>
                      <a
                        class="a-tag underline-text"
                        @click="
                          $router.push('/dpc-providers/locations/add-new'),$store.commit('setSingleMember', null)
                        "
                        >Add a Location</a
                      >
                    </v-list-item>

                    <v-list-item>
                      <a
                        class="a-tag underline-text"
                        @click="
                          $router.push('/dpc-providers/locations/upload'),$store.commit('setSingleMember', null)
                        "
                        >Upload a List</a
                      >
                    </v-list-item>
                    <v-list-item>
                      <a
                        class="a-tag underline-text"
                        >Upload List of NPIs</a
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>

            <!-- <div v-if="loader">
              <v-progress-linear
              class="text-center justify-center mt-0 "
              color="primary"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
            </div> -->
            <v-row class="col-md-12" v-if="locations.length < 1">
              <v-col md="12">
                No locations to show
              </v-col>
            </v-row>
              <v-data-table v-else
                    :headers="tableHeaders" 
                    :items="locations"
                    :search="searchValue"
                    :hide-default-header="false"
                    :mobile-breakpoint="0"
                    class="elevation-1 pa-2"
                    :hide-default-footer="true"
                    :items-per-page="itemsPerPage"
                    :page="page"
                    :footer-props="{
                        'items-per-page-options': itemsPerPageOptions,
                    }"
                    :loading="tableLoading"
                  >
                    <template v-slot:body="{ items }" >
                      <tr v-for="item in items" :key="item.id" class="pa-1">
                       <td  class="pa-1 font-14">
                                <a @click="singleItem(item)"
                                  >{{ item.name }} 
                                </a>
                              </td>
                              <td class="pa-1 text-gray font-14">
                                {{ item.address1 ? item.address1 : '/' }}
                              </td>
                              <td class="pa-1 text-gray font-14">
                                {{ item.city ? item.city : '/' }}
                              </td>
                              <td class="pa-1 text-gray font-14">
                                {{returnLocationState(item.state)}}
                              </td>
                              <td class="pa-1 text-gray font-14">
                              {{ item.zip ? item.zip : '/' }}
                              </td>
        
                              <td class="text-center pa-1 text-gray font-14" v-if=" item.setup.complete == true">
                                <v-tooltip left>
                                  <template v-slot:activator="{ on, attrs }">
                                    <svg
                                      v-bind="attrs"
                                      v-on="on"
                                      width="26"
                                      height="26"
                                      viewBox="0 0 26 26"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M8 14.8571L11.5143 17.6686C11.6087 17.7463 11.7192 17.802 11.8378 17.8317C11.9564 17.8613 12.0801 17.8642 12.2 17.84C12.321 17.8173 12.4357 17.7688 12.5363 17.6978C12.6368 17.6268 12.721 17.535 12.7829 17.4286L18.2857 8"
                                        stroke="#09951F"
                                        stroke-width="3"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M13.1429 24.2857C19.2969 24.2857 24.2857 19.2969 24.2857 13.1429C24.2857 6.98883 19.2969 2 13.1429 2C6.98883 2 2 6.98883 2 13.1429C2 19.2969 6.98883 24.2857 13.1429 24.2857Z"
                                        stroke="#09951F"
                                        stroke-width="3"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  </template>
                                  <span>Location setup is completed</span>
                                </v-tooltip>
          
                              </td>
                              <td class="text-center pa-1 text-gray font-14" v-else>
                                <v-tooltip left>
                                  <template v-slot:activator="{ on, attrs }">
                                    <svg
                                      v-bind="attrs"
                                      v-on="on"
                                      width="26"
                                      height="26"
                                      viewBox="0 0 26 26"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M13.1367 9.7041V14.847"
                                        stroke="#DF0202"
                                        stroke-width="3"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M13.1384 20.8469C13.6118 20.8469 13.9955 20.4631 13.9955 19.9897C13.9955 19.5163 13.6118 19.1326 13.1384 19.1326C12.665 19.1326 12.2812 19.5163 12.2812 19.9897C12.2812 20.4631 12.665 20.8469 13.1384 20.8469Z"
                                        stroke="#DF0202"
                                        stroke-width="3"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M14.6634 2.93264C14.5195 2.65184 14.301 2.41618 14.0318 2.25163C13.7626 2.08707 13.4532 2 13.1377 2C12.8222 2 12.5128 2.08707 12.2436 2.25163C11.9744 2.41618 11.7558 2.65184 11.612 2.93264L2.18338 21.7898C2.05188 22.0507 1.98927 22.341 2.0015 22.633C2.01373 22.9249 2.1004 23.2089 2.25326 23.458C2.40613 23.707 2.62013 23.9129 2.87493 24.056C3.12973 24.199 3.41687 24.2746 3.7091 24.2755H22.5662C22.8585 24.2746 23.1456 24.199 23.4004 24.056C23.6552 23.9129 23.8692 23.707 24.0221 23.458C24.1749 23.2089 24.2616 22.9249 24.2738 22.633C24.2861 22.341 24.2235 22.0507 24.092 21.7898L14.6634 2.93264Z"
                                        stroke="#DF0202"
                                        stroke-width="3"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  </template>
                                  <span>Location setup is not completed</span>
                                </v-tooltip>
                              </td>
                      </tr>
                    </template>
                    <template v-slot:footer>
                        <v-divider class="mt-5 mb-3"></v-divider>
                        <v-row justify="end" align="center" no-gutters>
                            <v-col cols="auto" class="d-flex align-center mr-7">
                                <span class="rows-per-page">Rows per page:</span>
                            </v-col>

                            <v-col cols="auto" class="d-flex align-center mr-5">
                                <v-select
                                    class="pagination-items-per-page"
                                    v-model="itemsPerPage"
                                    :items="itemsPerPageOptions"
                                    hide-details
                                    @change="updateItemsPerPage"
                                ></v-select>
                            </v-col>

                            <v-col cols="auto" class="d-flex align-center mr-2">
                                <span class="page-number">{{ pageStart }} - {{ pageEnd }} of {{ totalLocations }}</span>
                            </v-col>

                            <v-col cols="auto mr-13">
                                <v-pagination
                                    density="compact"
                                    v-model="page"
                                    @input="updatePage"
                                    class="ml-4"
                                    :length="totalPages"
                                    :total-visible="5"
                                >
                                </v-pagination>
                            </v-col>
                        </v-row>
                    </template>
                </v-data-table>
        

            <table  id="tblData" style="display:none">
              <thead>
                 <tr>
                    <th class="text-left">LOCATION NAME</th>
                    <th class="text-left">ADDRESS</th>
                    <th class="text-left">CITY</th>
                    <th class="text-left">STATE</th>
                    <th class="text-left">ZIP</th>
                 
                  </tr>
              </thead>
              <tbody>
              <tbody v-if="locations.length > 0">
                  <tr v-for="(item, index) in filteredArray" :key="index">
                    <td v-if="item.name">
                      <a style="text-decoration: underline;" @click="singleItem(item)">{{ item.name }}</a>
                    </td>
                    <td @click="singleItem(item)" v-else><a>/</a></td>
                    <td v-html="item.address1 ? item.address1 : '/'"></td>
                    <td v-html="item.city ? item.city : '/'"></td>
                    <td v-html="item.state ? returnLocationState(item.state) : '/'"></td>
                    <td
                      v-html="
                        item.zip ? item.zip : item.zipCode ? item.zipCode : '/'
                      "
                    ></td>
                
                  </tr>
                </tbody>
              </tbody>
            </table>
          </div>
        </v-row>
      </div>
      <v-navigation-drawer
        class="userFilterPosition"
        width="350"
        v-model.trim="drawer"
        absolute
        temporary
      >
        <div class="filter-sideBar">
          <div>
            <div class="row no-padding col-md-12 justify-space-between">
              <h3 class="font pl-3 mt-1">Locations Filters</h3>
              <h1 class="pointer mr-3" @click.stop="drawer = !drawer">x</h1>
            </div>
            <v-divider></v-divider>
            <div class="col-md-12">
              <div
                class="row col-md-12 justify-space-between no-padding no-margin"
              >
                <div class="no-padding font filter-items-font font-weight-bold">
                  Selected Filter
                </div>
                <div
                  class="underline-text pointer text-gray filter-items-font"
                  @click="clearAll"
                >
                  Clear All
                </div>
              </div>
            </div>
            <div class="overflow-fitlers-buttons">
              <div class="row mb-1 col-md-12">
                <v-btn
                  v-for="(item, index) in selectedArray.concat(
                    selectedTypeArray,
                    selectedLocations,
                  )"
                  :key="index"
                  class="text-center mb-1 ml-2 mr-2 filter-btn"
                  small
                >
                  <span v-html="item.text"></span>
                  <h5 class="ml-3" @click="removeFilter(item)">x</h5>
                </v-btn>
              </div>
              <v-divider></v-divider>
              <v-expansion-panels
                accordion
                multiple
                v-model.trim="panel"
                class="no-shadow no-padding user-expansion-panels"
              >
                <v-expansion-panel class="no-shadow no-padding">
                  <v-expansion-panel-header
                    class="
                      font-weight-black
                      change-padding-filter
                      filter-items-font
                    "
                    >City</v-expansion-panel-header
                  >
                  <v-expansion-panel-content class="no-padding content-filter">
                    <v-text-field
                      solo
                      class="filter-serach-input mb-4"
                      v-model.trim="city"
                      @input="searchCity()"
                      dense
                      :hide-details="true"
                      placeholder="Search"
                      append-icon="fa fa-search"
                    ></v-text-field>
                    <v-checkbox
                      label="All"
                      color="primary"
                      v-if="showLocation['ALL']"
                      v-model.trim="filter.locations['ALL']"
                      :on-icon="'mdi-square'"
                      class="no-padding radio-buttons-filters"
                      hide-details
                      @click="
                      pushtoLocationFilter(
                        { text: 'ALL', value:'ALL'},
                        filter.locations['ALL']
                      )
                      "
                    >
                    </v-checkbox>
                
                    <v-checkbox v-for="(item,index) in cities" :key="index"
                      :label=item
                      color="primary"
                      v-if="showLocation[item]"
                      v-model.trim="filter.locations[item?.toLowerCase()]"
                      :on-icon="'mdi-square'"
                      class="no-padding radio-buttons-filters"
                      hide-details
                      @click="
                        pushtoLocationFilter(
                          { text: item, value:item?.toLowerCase() },
                          filter.locations[item?.toLowerCase()]
                        )
                      "
                    >
                    {{ item }}
                    </v-checkbox>

                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel class="no-shadow no-padding mt-3">
                  <v-expansion-panel-header
                    class="
                      font-weight-black
                      change-padding-filter
                      filter-items-font
                      mb-0
                      pb-0
                    "
                    >Accepting New Patients</v-expansion-panel-header
                  >
                  <v-expansion-panel-content class="no-padding content-filter mb-2">
                    <v-checkbox
                      label="All"
                      color="primary"
                      v-if="showType.all"
                      :on-icon="'mdi-square'"
                      v-model.trim="filter.type.all"
                      class="no-padding radio-buttons-filters"
                      hide-details
                      @click="
                        pushtoTypeFilter(
                          { text: 'All', value: 'allTypes' },
                          filter.type.all
                        )
                      "
                    >
                    </v-checkbox>
                    <v-checkbox
                      label="Yes"
                      color="primary"
                      v-if="showType.yes"
                      v-model.trim="filter.type.yes"
                      :on-icon="'mdi-square'"
                      class="no-padding radio-buttons-filters"
                      hide-details
                      @click="
                        pushtoTypeFilter(
                          { text: 'Yes', value: true },
                          filter.type.yes
                        )
                      "
                    >
                    </v-checkbox>
                    <v-checkbox
                      label="No"
                      color="primary"
                      :on-icon="'mdi-square'"
                      v-if="showType['no']"
                      v-model.trim="filter.type.no"
                      class="no-padding radio-buttons-filters"
                      hide-details
                      @click="
                        pushtoTypeFilter(
                          { text: 'No', value: false },
                          filter.type.no
                        )
                      "
                    >
                    </v-checkbox>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
          <div>
            <v-divider></v-divider>
            <v-btn class="blue-buttons col-md-12 mt-3"
             @click="filterLocations()"
              >View Result</v-btn
            >
          </div>
        </div>
      </v-navigation-drawer>
    </v-sheet>
    <router-view></router-view>
  </div>
</template>

<script>
import api from "../../../../services/api";
import { mapGetters } from "vuex";
import FullscreenLoader from "../../../Common/FullscreenLoader.vue";

export default {
  components: {
    FullscreenLoader
  },
  data() {
    return {
      searchValue: '',
      pagination: {
      rowsPerPage: 30
    },
      tableHeaders: [
        {text: 'LOCATION NAME ', value:'locationName', align: 'start', sortable: true},
        {text: 'ADDRESS', value:'address', align: 'start'},
        {text: 'CITY', value:'city', align: 'start'},
        {text: 'STATE', value:'state', align: 'start'},
        {text: 'ZIP', value:'zip', align: 'start'},
        {text: 'ACTIONS', align: 'center'},
      ],
      selectedLocations: [],
      filteredArray:[],
      allCities: [],
      items: [],
      completed: false,
      locations: [],
      selectedLocationArray: [],
      response: [],
      resultsReady: true,
      drawer: null,
      showType: {
        all: true,
        yes: true,
        no: true,
      },
      type: "",
      status: "",
      panel: [0, 1],
      filterItems: false,
      filterCity: [],
      showLocation:{},
      cities: [],
      overlay: false,
      city: '',
      selectedArray: [],
      selectedTypeArray: [],
      filter: {
        type: {
          all: false,
          yes: false,
          no: false,
        },
        locations:{},
      },
      states: [],
      loader: false,

      totalLocations: 0,
      tableLoading: false,
      page: 1,
      itemsPerPage: 10,
      itemsPerPageOptions: [5, 10, 20, 50],
      searchQuery: "",
      debounceTimer: null
    };
  },
  created() {
    this.$store.commit("setSingleMember", null);
    this.fetchLocationWithPagination(true);
    this.getStates();
  },

  computed: {
    pageStart() {
      return (this.page - 1) * this.itemsPerPage + 1;
    },
    pageEnd() {
      return Math.min(this.page * this.itemsPerPage, this.totalLocations);
    },
    totalPages() {
      return Math.ceil(this.totalLocations / this.itemsPerPage);
    },
    filteredLocations() {
        return this.locations.filter(item => {
        let search = this.searchValue.toLowerCase()
        let match = false
        if(item.name)match = match || item.name.toLowerCase().includes(search)
        if(item.address1)match = match || item.address1.toLowerCase().includes(search)
        if(item.city)match = match || item.city.toLowerCase().includes(search)
        if(item.zip)match = match || item.zip.toLowerCase().includes(search)
        if(item.state)match = match || item.state.toLowerCase().includes(search)
        return match
        })
    },
    ...mapGetters(["showRoutePathForFilter"]),
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
  },
  watch: {
    showRoutePathForFilter: {
      handler(newValue, oldValue) {
        this.drawer = this.showRoutePathForFilter;
      },
      deep: true,
    },
    drawer: {
      handler(newValue, oldValue) {
        this.$store.commit("setNavItemFilter", this.drawer);
      },
      deep: true,
    },
  },
  methods: {
    updateItemsPerPage(newVal) {
        this.itemsPerPage = newVal;
        this.page = 1;

        this.fetchLocationWithPagination();
    },
    updatePage(newVal) {
        this.page = newVal;
        this.fetchLocationWithPagination();
    },
    filterLocations() {
        this.page = 1;
        this.page = 1;

        // Clear the previous timeout if the user is still typing
        clearTimeout(this.debounceTimer);

        // Set a new timeout to call the search after a delay
        this.debounceTimer = setTimeout(() => {
            this.fetchLocationWithPagination();
        }, 500); 
    },
    returnLocationState(state){
      let name = state
     this.states.forEach((item) => {
      if(item.value == state){
        name = item.name
      } 
     });
     return name
    },   
    getStates() {
      api()
        .get("/api/states")
        .then((res) => {
          this.states = res.data;
        });
    },
     exportData(tableID, filename = "") {
      var elt = document.getElementById(tableID);
      var downloadLink;
      var dataType = "application/vnd.ms-excel";
      var tableSelect = elt;
      var tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

      // Specify file name
      filename = filename ? filename + ".xls" : "excel_data.xls";

      // Create download link element
      downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        var blob = new Blob(["\ufeff", tableHTML], {
          type: dataType,
        });
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = "data:" + dataType + ", " + tableHTML;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
    },
    getItems() {
      this.loader = true
      api()
        .get("/dpcproviders/locations?pagination=true")
        .then((res) => {
            if(res){
                this.loader = false
                this.locations = res.data.locations;
                this.totalLocations = res.data.totalLocations
                this.filteredArray = this.locations;
                this.populateLocations();
            }
        })
        .finally(() => {
            this.loader = false
        });
    },
    populateLocations(){
      this.locations.forEach(element => {
        this.cities.push(element.city);
      this.showLocation[element.city] = true;
      this.filter.locations[element.city?.toLowerCase()] = false;
    });

    this.filter.locations['ALL'] = false;
    this.showLocation['ALL'] = true;

    const unique = (value, index, self) => {
  return self.indexOf(value) === index
}
this.cities = this.cities.filter(unique)

    },
    showFilterSideBar() {
      this.drawer = !this.drawer;
      if (this.drawer) {
        this.$store.commit("setNavItemFilter", this.drawer);
      } else {
        this.$store.commit("setNavItemFilter", false);
      }
    },
    searchCity() {
      for (let key in this.showLocation) {
        if (key?.toLowerCase().includes(this.locations?.toLowerCase())) {
          this.showLocation[key] = true;
        } else {
          this.showLocation[key] = false;
        }
      }
    },
    pushtoLocationFilter(item,checked) {
      if (checked) {
        if (item.value == "ALL") {
          this.selectedLocations = [];
          Object.keys(this.filter.locations).forEach(key => {
            this.filter.locations[key]=true;
          });
          this.selectedLocations.push({ text: "All", value: "ALL" });

        } else {
          this.selectedLocations.push(item);
        }
      } else {
        if (item.value == "ALL" || this.filter.locations['ALL']) {
          Object.keys(this.filter.locations).forEach(key => {
            this.filter.locations[key]=false;
          });
          this.selectedLocations = [];
        } else {
          this.selectedLocations.forEach((el, index) => {
            if (el.value == item.value) {
              this.selectedLocations.splice(index, 1);
            }
          });
        }
      }
     
    },
    pushtoTypeFilter(item, checked) {
      if (checked) {
        if (item.value == "allTypes") {
          this.selectedTypeArray = [];
          this.filter.type = {
            all: true,
            yes: true,
            no: true,
          };
          this.selectedTypeArray.push({ text: "All", value: "allTypes" });
        } else {
          this.selectedTypeArray.push(item);
        }
      } else {
        if (item.value == "allTypes" || this.filter.type.all) {
          this.filter.type = {
            all: false,
            yes: false,
            no: false,
          };
          this.selectedTypeArray = [];
        } else {
          this.selectedTypeArray.forEach((el, index) => {
            if (el.value == item.value) {
              this.selectedTypeArray.splice(index, 1);
            }
          });
        }
      }
    },
    removeFilter(item) {
      if (item.value == "ALL") {
        Object.keys(this.filter.locations).forEach(key => {
            this.filter.locations[key]=false;
          });
        this.selectedLocations = [];
       
      } else if (this.filter.locations[item.value]) {
        this.filter.locations[item.value] = false;
        this.selectedLocations.splice(this.selectedLocations.indexOf(item), 1);
      } 

      if (item.value == "allTypes") {
        this.selectedTypeArray = [];
        this.filter.type = {
          all: false,
          yes: false,
          no: false,
        };
      } else if (this.filter.status[item.value]) {
        this.filter.status[item.value] = false;
        this.selectedArray.splice(this.selectedArray.indexOf(item), 1);
      }

      if (item.value == "all") {
        this.selectedArray = [];
      } else if (this.filter.type[item.value]) {
        this.filter.type[item.value] = false;
        this.selectedTypeArray.splice(this.selectedTypeArray.indexOf(item), 1);
      }
    },
    searchStatus() {
      for (let key in this.showStatus) {
        if (key.toLowerCase().includes(this.status.toLowerCase())) {
          this.showStatus[key] = true;
        } else {
          this.showStatus[key] = false;
        }
      }
    },
    searchType() {
      for (let key in this.showType) {
        if (key.toLowerCase().includes(this.type.toLowerCase())) {
          this.showType[key] = true;
        } else {
          this.showType[key] = false;
        }
      }
    },
  
    fetchLocationWithPagination(useLoader) {
        this.tableLoading = true;
        if (useLoader === true) {
            this.loader = true
        }

        let cityFilter = [];
        let typeFilter = [];

        Object.keys(this.filter.locations).forEach(key => {
            const enabled = this.filter.locations[key];

            if (enabled) {
                cityFilter.push(key)
            }
        });

        Object.keys(this.filter.type).forEach(key => {
            const enabled = this.filter.type[key];

            if (enabled) {
                typeFilter.push(key)
            }
        });

        const queryParams = new URLSearchParams();

        queryParams.append('pagination', 'true');
        queryParams.append('limit', this.itemsPerPage);
        queryParams.append('page', this.page);

        if (this.searchQuery && this.searchQuery !== '') {
            const searchQuery = this.searchQuery.trim().toLowerCase();
            queryParams.append('search', searchQuery);
        }

        // Add city filter if present
        if (cityFilter.length > 0) {
            queryParams.append('city', cityFilter.join(','));
        }

        // Add new patient filter if present
        if (typeFilter.length > 0) {
            queryParams.append('type', typeFilter.join(','));
        }

        api()
            .get("/dpcproviders/locations?" + queryParams.toString())
            .then((res) => {
                if(res){
                    this.loader = false
                    this.locations = res.data.locations;
                    this.totalLocations = res.data.totalLocations
                    this.filteredArray = this.locations;
                    this.populateLocations();
                }
                
                if (this.locations.length < 1) {
                    this.filterItems = true;
                } else {
                    this.filterItems = false;
                }

                this.drawer = false;
                this.tableLoading = false;
            });
    },
    clearAll() {
      this.filter.status = {
        all: false,
        austin: false,
        dallas: false,
        manford: false,
      };
      this.filter.type = {
        all: false,
        yes: false,
        no: false,
      };

      this.selectedArray = [];
      this.selectedTypeArray = [];
      this.selectedLocations = [];
      this.getItems();
    },
    search(val) {
      this.locations = this.filteredArray.filter((el) => {
        if (el.name && el.name.toLowerCase().includes(val.toLowerCase())) {
          return el.name.toLowerCase().includes(val.toLowerCase());
        } else if (
          el.address1 &&
          el.address1.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.address1.toLowerCase().includes(val.toLowerCase());
        } else if (
          el.city &&
          el.city.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.city.toLowerCase().includes(val.toLowerCase());
        } else if (
          el.state &&
          el.state.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.state.toLowerCase().includes(val.toLowerCase());
        } else if (
          el.zip &&
          el.zip.toString().toLowerCase().includes(val.toLowerCase())
        ) {
          return el.zip.toString().toLowerCase().includes(val.toLowerCase());
        }
      });
      if (this.locations.length < 1) {
        this.filterItems = true;
      }
    },
    singleItem(item) {
      // this.$store.commit("setSingleMember", item);
      this.$router.push("/dpc-providers/locations/location/"+item.id);
    },

  },
};
</script>
<style></style>
