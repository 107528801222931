const state ={
    affilate : null,
}
const getters = {
    getSingleAffilate(){
        return state.affilate
      }
}
const mutations = {
    setSingleAffilate(state,aff){
        state.affilate = aff
      }
}

export default{
    state,
    getters,
    mutations,
}