<template>
<div class="col-md-12 row-height font-bold">
  <v-row class="">
    <v-col class="col-md-8" md="4" cols="12">
      <h3>
         <span class="font"> VNets</span>
      </h3>
    </v-col>
  </v-row>    
  <div class="col-md-12">
    <div class="mt-7 text-underline"> COMING SOON</div> 
    <div class="mt-3"> 
        VNets (short for Virtual Networks), will enable you to group providers together into a VNet, and then offer your VNet to your own clients, and/or any employer on the platform.
        You’ll be able to create multiple VNets, give them unique names, link to a VNet website of your choice, and even charge a VNet access fee if you wish. 
    </div>
  </div>  
  
</div>


    
</template>
<script>

  export default {
    data () {
      return {
        items: [
          {
            name: 'Tampa General Hospital',
            contact: 'James Reilly',
            type: 'Multi Speciality',
            providers:'123',
            rating:3,
            action:''
          },
          {
            name: 'National Ortha HVN',
            contact: 'James Reilly',
            type: 'Single Speciality',
            providers:'223',
            rating:5,
            action:''
          },
          {
            name: 'National Cardiac Network',
            contact: 'Jeff Hayes',
            type: 'Single Speciality',
            providers:'212',
            rating:3,
            action:''
          },
          {
            name: 'Florida HVN',
            contact: 'Scot Pruzan',
            type: 'Multi Speciality',
            providers:'88',
            rating:4,
            action:''
          },
          {
            name: 'Northeast HVN',
            contact: 'Beth Schultz',
            type: 'Multi Speciality',
            providers:'195',
            rating:5,
            action:''
          },

     
        ],
      }
    },
    computed:{
      getClient(){
          return this.$store.getters.getClientGetter
       },
        routeParams(){
            return this.$route.params.name
        }
    },
    methods:{
      singleProvider(item){
        this.$store.commit('setSingleNetwork', item);
        this.$emit('singleNetworkTemp');
      }
    }
  }
</script>