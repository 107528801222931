<template>
    <div>
        <Bundles v-if="path == `/provider-advisor/providers/${routeParams}/bundles`"></Bundles>        
        <Builder v-if="path == `/provider-advisor/providers/${routeParams}/bundles/builder`"></Builder>
        <SingleBundle  v-if="path == `/provider-advisor/providers/${routeParams}/bundles/bundle`"></SingleBundle>
    </div>
</template>
<script>
import Bundles from './Bundles.vue'
import Builder from './Builder.vue'
import SingleBundle from './SingleBundle.vue'

export default {
    components:{
        Bundles,
        Builder,
        SingleBundle
    },
    computed:{
       path(){
            return this.$route.path.replace(/%20/g,' ')
        },
        routeParams(){
         return this.$route.params.name
        }
    }
}
</script>