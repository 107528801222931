var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app-bar',{attrs:{"app":"","clipped-left":"","clipped-right":""}},[_c('v-toolbar-title',{staticClass:"no-padding",staticStyle:{"overflow":"initial","width":"173px"},attrs:{"align":"center"}},[_c('img',{staticClass:"apaly-pic pointer",attrs:{"id":"logo-img","src":_vm.logo ? _vm.logo : '/apaly1.png'}})]),_c('v-divider',{staticClass:"header-divider-hide",attrs:{"vertical":""}}),_c('div',{staticClass:"col-xl-4 col-lg-3 col-md-2 col-sm-6 header-hamb-icon mt-md-3 no-padding row"},[_c('i',{staticClass:"fas fa-bars top-2px pt-1",staticStyle:{"cursor":"pointer","color":"gray"},on:{"click":_vm.hideSideBar}}),_c('h2',{staticClass:"text-gray font-quicksand-semibold mb-4 ml-2 group-name",staticStyle:{"font-size":"18px"},domProps:{"innerHTML":_vm._s(
        _vm.user &&
        _vm.user.groupPortal &&
        _vm.user.groupPortal[0] &&
        _vm.user.companyName
          ? _vm.user.companyName
          : 'Employer Client'
      )}})]),_c('v-spacer'),_c('div',{staticClass:"user-settings"},[(_vm.user)?_c('div',{staticClass:"font text-right font-quicksand-medium-12"},[_vm._v(" "+_vm._s(_vm.user.firstName)+" "+_vm._s(_vm.user.lastName)+" ")]):_vm._e(),(_vm.user)?_c('div',{staticClass:"font-quicksand-medium-11"},[_vm._v(_vm._s(_vm.user.email))]):_vm._e()]),_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"dark":"","icon":""}},on),[_c('v-avatar',{attrs:{"size":"40"}},[_c('img',{staticStyle:{"object-fit":"cover"},attrs:{"id":"profile-img","src":_vm.profileImg
                ? _vm.profileImg
                : 'https://www.pngkit.com/png/full/128-1280585_user-icon-fa-fa-user-circle.png',"alt":"John"}})])],1)]}}])},[_c('v-list',[_c('v-list-item',[_c('v-list-item-avatar',[_c('img',{staticStyle:{"object-fit":"cover"},attrs:{"src":_vm.profileImg
                ? _vm.profileImg
                : 'https://www.pngkit.com/png/full/128-1280585_user-icon-fa-fa-user-circle.png',"alt":"John"}})]),_c('v-list-item-content',[(_vm.user)?_c('v-list-item-title',{staticClass:"font"},[_vm._v(_vm._s(_vm.user.firstName)+" "+_vm._s(_vm.user.lastName)+" ")]):_vm._e()],1)],1),_c('v-divider'),_vm._l((_vm.userprofile),function(item,i){return _c('v-list-item',{key:i,attrs:{"color":"primary","to":item.path}},[_c('v-list-item-title',{staticClass:"pointer",on:{"click":function($event){return _vm.logout(item)}}},[_vm._v(_vm._s(item.title))])],1)})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }