<template>
  <div class="row row-height">
    <v-sheet
      v-if="!isChildren"
      class="overflow-hidden col-md-12"
      style="position: relative; border-radius: 10px"
    >
      <div>
        <v-row align="center" justify="center">
          <div
            :class="
              overlay
                ? 'hideOnMoblie background-opacity col-md-9'
                : 'col-md-12 navigationWidth no-padding '
            "
          >
            <v-row class="orange-underline-wizard mb-1">
              <v-col class="col-xl-2 col-md-2 cols-4 pt-2 pb-0" cols="4">
                <div class="col-md-12">
                  <h3 class="form-title font col-md-12 font-18">DPC Providers</h3>
                </div>
              </v-col>
              <v-col class="col-xl-3 col-md-5 cols-8" cols="8">
                <div
                  class="col-md-12 d-flex mt-4 no-padding"
                  style="width: 80%"
                >
                  <v-text-field
                    :disabled="overlay"
                    class="input-control mr-5"
                    prepend-inner-icon="mdi-magnify"
                    @input="searchProviders"
                    light
                    dense
                    outlined
                    placeholder="Search DPC Providers"
                  ></v-text-field>
                  <v-btn
                    :disabled="overlay"
                    class="text-gray filter-btn"
                    @click.stop="showFilterSideBar"
                  >
                    <svg
                      width="17"
                      class="mr-2"
                      height="14"
                      viewBox="0 0 17 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1H16"
                        stroke="#424242"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M2.59766 5H14.403"
                        stroke="#424242"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6.3125 13H10.6858"
                        stroke="#424242"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M4.19531 9H12.8046"
                        stroke="#424242"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Filters
                  </v-btn>
                </div>
              </v-col>
              <!-- <v-col
                class="col-md-7 col-xl-7 text-end pr-4 pt-6"
                cols="12"
                align="end"
              >
                <v-btn
                  class="my-1 mr-4 gray-border-buttons"
                  @click="exportData('tblData')"
                >
                  <svg
                    class="mr-2"
                    width="15"
                    height="16"
                    viewBox="0 0 15 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.2361 10.7639C14.0329 10.7639 13.838 10.8434 13.6937 10.985C13.5495 11.1267 13.4676 11.319 13.4659 11.5202V12.6764C13.4659 12.9367 13.3615 13.1862 13.1756 13.3703C12.9897 13.5543 12.7376 13.6577 12.4747 13.6577H2.52525C2.26238 13.6577 2.01027 13.5543 1.8244 13.3703C1.63852 13.1862 1.53409 12.9367 1.53409 12.6764V11.5202C1.51671 11.3307 1.42838 11.1544 1.28645 11.0261C1.14452 10.8978 0.959257 10.8266 0.767046 10.8266C0.574834 10.8266 0.389569 10.8978 0.247642 11.0261C0.105715 11.1544 0.0173841 11.3307 0 11.5202V12.6764C0 13.3395 0.266053 13.9753 0.739629 14.4442C1.21321 14.913 1.85551 15.1764 2.52525 15.1764H12.4747C13.1445 15.1764 13.7868 14.913 14.2604 14.4442C14.7339 13.9753 15 13.3395 15 12.6764V11.5202C15 11.3196 14.9195 11.1272 14.7763 10.9854C14.633 10.8436 14.4387 10.7639 14.2361 10.7639Z"
                      fill="#707070"
                    />
                    <path
                      d="M7.49971 0.176392C7.17488 0.176392 6.86336 0.304137 6.63368 0.531525C6.404 0.758913 6.27496 1.06732 6.27496 1.38889V7.05139C6.27496 7.09283 6.25833 7.13257 6.22873 7.16188C6.19913 7.19118 6.15899 7.20764 6.11713 7.20764H4.43784C4.32437 7.21533 4.2151 7.25322 4.12158 7.31731C4.02807 7.3814 3.95378 7.46931 3.90657 7.57175C3.85935 7.6742 3.84097 7.78736 3.85337 7.89929C3.86576 8.01122 3.90847 8.11776 3.97698 8.20764L7.03885 11.6951C7.0979 11.7576 7.16927 11.8074 7.24855 11.8414C7.32782 11.8754 7.4133 11.893 7.49971 11.893C7.58611 11.893 7.6716 11.8754 7.75087 11.8414C7.83014 11.8074 7.90151 11.7576 7.96057 11.6951L11.0224 8.20764C11.0909 8.11776 11.1337 8.01122 11.146 7.89929C11.1584 7.78736 11.1401 7.6742 11.0928 7.57175C11.0456 7.46931 10.9713 7.3814 10.8778 7.31731C10.7843 7.25322 10.675 7.21533 10.5616 7.20764H8.88228C8.84042 7.20764 8.80028 7.19118 8.77068 7.16188C8.74108 7.13257 8.72445 7.09283 8.72445 7.05139V1.38889C8.72445 1.06732 8.59542 0.758913 8.36573 0.531525C8.13605 0.304137 7.82453 0.176392 7.49971 0.176392V0.176392Z"
                      fill="#707070"
                    />
                  </svg>

                  Export</v-btn
                >
                <v-btn
                  class="my-1 mr-2 gray-border-buttons"
                  @click="
                    $router.push('/dpc-providers/account/providers/add-new')
                  "
                >
                  <svg
                    class="mr-2"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 7.5H15"
                      stroke="#707070"
                      stroke-width="2"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.5 0V15"
                      stroke="#707070"
                      stroke-width="2"
                      stroke-linejoin="round"
                    />
                  </svg>

                  New</v-btn
                >
              </v-col> -->
            </v-row>
            <v-row v-if="loader" class="justify-center col-md-12">
              <v-progress-circular
                :size="100"
                class="text-center"
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-row>

            <v-simple-table fixed-header height="630px">
              <template v-slot:default>
                <thead :class="overlay ? 'disabled-table' : ''">
                  <tr>
                    <th>Name</th>
                    <th>Gender</th>
                    <th>Specialty</th>
                    <th>Location(s)</th>
                    <th class="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody v-if="allProviders.length > 0">
                  <tr
                    v-for="(item, index) in allProviders"
                    :key="index"
                    :id="'id' + index"
                  >
                    <td v-if="item.companyName">
                      <a @click="singleProvider(item)">{{
                        item.companyName
                      }}</a>
                    </td>
                    <td v-else-if="item.providerName">
                      <a @click="singleProvider(item)">{{
                        item.providerName
                      }}</a>
                    </td>
                    <td v-else><a @click="singleProvider(item)">/</a></td>
                    <td class="text-gray">
                      <span
                        v-html="
                          item.sex
                            ? item.sex.charAt(0).toUpperCase() +
                              item.sex.slice(1)
                            : '/'
                        "
                      ></span>
                    </td>
                    <td v-if="item.taxonomy" class="text-gray">
                      {{ item.taxonomy }}
                    </td>
                    <td v-else-if="item.specialty" class="text-gray">
                      {{ item.specialty }}
                    </td>
                    <td v-else>/</td>
                    <td class="text-gray">
                      {{ item.locations ? item.locations.length : 0 }}
                    </td>

                    <td v-if="!selectAll" class="text-center">
                      <svg
                        class="pointer mt-2"
                        @click="singleProvider(item)"
                        width="27"
                        height="27"
                        viewBox="0 0 27 27"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.9955 19.5396L6.87109 20.1296L7.45998 16.004L15.8611 7.6029C16.3299 7.13406 16.9658 6.87067 17.6289 6.87067C18.2919 6.87067 18.9278 7.13406 19.3966 7.6029C19.8655 8.07175 20.1289 8.70764 20.1289 9.37068C20.1289 10.0337 19.8655 10.6696 19.3966 11.1385L10.9955 19.5396Z"
                          stroke="#4072B7"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M1 13.5C1 16.8152 2.31696 19.9946 4.66117 22.3388C7.00537 24.683 10.1848 26 13.5 26C16.8152 26 19.9946 24.683 22.3388 22.3388C24.683 19.9946 26 16.8152 26 13.5C26 10.1848 24.683 7.00537 22.3388 4.66117C19.9946 2.31696 16.8152 1 13.5 1C10.1848 1 7.00537 2.31696 4.66117 4.66117C2.31696 7.00537 1 10.1848 1 13.5V13.5Z"
                          stroke="#4072B7"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <svg
                        class="ml-2 mt-2 pointer"
                        @click="removeItem(item)"
                        width="28"
                        height="26"
                        viewBox="0 0 28 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.35547 13C1.35547 16.1826 2.65524 19.2348 4.96884 21.4853C7.28244 23.7357 10.4204 25 13.6923 25C16.9642 25 20.1021 23.7357 22.4157 21.4853C24.7293 19.2348 26.0291 16.1826 26.0291 13C26.0291 9.8174 24.7293 6.76516 22.4157 4.51472C20.1021 2.26428 16.9642 1 13.6923 1C10.4204 1 7.28244 2.26428 4.96884 4.51472C2.65524 6.76516 1.35547 9.8174 1.35547 13V13Z"
                          stroke="#D00000"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M8.75781 17.8002L18.6273 8.2002"
                          stroke="#D00000"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M18.6273 17.8002L8.75781 8.2002"
                          stroke="#D00000"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </td>
                    <td
                      v-else
                      class="text-left mt-3 row no-padding justify-center"
                      style="padding-right: 30px"
                    >
                      <v-checkbox
                        class="no-padding ml-6"
                        :value="selectAll"
                        @click="activeRow(index, item)"
                      ></v-checkbox>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else-if="filterItems">
                  <v-col md="12" cols="12">
                    <v-alert
                      border="bottom"
                      colored-border
                      type="warning"
                      elevation="2"
                      class="text-gray"
                    >
                      Sorry! No results found
                    </v-alert>
                  </v-col>
                </tbody>
                <tbody v-else>
                  <v-col md="12">
                    Currently, you do not have any providers in your account.
                  </v-col>
                </tbody>
              </template>
            </v-simple-table>
            <v-row class="justify-end mt-3 mb-2" v-if="selectAll">
              <v-btn class="blue-buttons" @click="removeSelected()"
                >Remove Selected</v-btn
              >
            </v-row>
            <v-row
              :class="!overlay ? 'justify-end mt-3' : 'disabled-table'"
              v-if="!noDataMessage && showSaveButton"
            >
              <v-btn class="blue-buttons" @click="addProviders()"
                >Save & Apply</v-btn
              >
            </v-row>

            <table id="tblData" style="display: none">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Gender</th>
                  <th>Specialty</th>
                  <th>Location(s)</th>
                </tr>
              </thead>
              <tbody v-if="allProviders.length > 0">
                <tr
                  v-for="(item, index) in allProviders"
                  :key="index"
                  :id="'id' + index"
                >
                  <td v-if="item.companyName">
                    <a @click="singleProvider(item)">{{ item.companyName }}</a>
                  </td>
                  <td v-else-if="item.providerName">
                    <a @click="singleProvider(item)">{{ item.providerName }}</a>
                  </td>
                  <td v-else><a @click="singleProvider(item)">/</a></td>
                  <td class="text-gray">
                    <span
                      v-html="
                        item.sex
                          ? item.sex.charAt(0).toUpperCase() + item.sex.slice(1)
                          : '/'
                      "
                    ></span>
                  </td>
                  <td v-if="item.taxonomy" class="text-gray">
                    {{ item.taxonomy }}
                  </td>
                  <td v-else-if="item.specialty" class="text-gray">
                    {{ item.specialty }}
                  </td>
                  <td v-else>/</td>
                  <td
                    class="text-gray"
                    v-html="item.city ? item.city : '/'"
                  ></td>
                </tr>
              </tbody>
            </table>
          </div>
        </v-row>
      </div>

      <v-navigation-drawer
        class="userFilterPosition"
        width="350"
        v-model.trim="drawer"
        absolute
        temporary
      >
        <div class="filter-sideBar">
          <div>
            <div class="row no-padding col-md-12 justify-space-between">
              <h3 class="font pl-3 mt-1">Providers Filters</h3>
              <h1 class="pointer mr-3" @click.stop="drawer = !drawer">x</h1>
            </div>
            <v-divider></v-divider>
            <div class="col-md-12">
              <div
                class="row col-md-12 justify-space-between no-padding no-margin"
              >
                <div class="no-padding font filter-items-font font-weight-bold">
                  Selected Filter
                </div>
                <div
                  class="underline-text pointer text-gray filter-items-font"
                  @click="clearAll"
                >
                  Clear All
                </div>
              </div>
            </div>
            <div class="overflow-fitlers-buttons">
              <div class="row mb-1 col-md-12">
                <v-btn
                  v-for="(item, index) in selectedArray.concat(
                    selectedTypeArray
                  )"
                  :key="index"
                  class="text-center mb-1 ml-2 mr-2 filter-btn"
                  small
                >
                  <span v-html="item.text"></span>
                  <h5 class="ml-3" @click="removeFilter(item)">x</h5>
                </v-btn>
              </div>
              <v-divider></v-divider>
              <v-expansion-panels
                accordion
                multiple
                v-model.trim="panel"
                class="no-shadow no-padding user-expansion-panels"
              >
                <v-expansion-panel class="no-shadow no-padding">
                  <v-expansion-panel-header
                    class="
                      font-weight-black
                      change-padding-filter
                      filter-items-font
                    "
                    >Status</v-expansion-panel-header
                  >
                  <v-expansion-panel-content class="no-padding content-filter">
                    <v-text-field
                      solo
                      class="filter-serach-input mb-4"
                      v-model.trim="status"
                      @input="searchStatus()"
                      dense
                      :hide-details="true"
                      placeholder="Search"
                      append-icon="fa fa-search"
                    ></v-text-field>
                    <v-checkbox
                      label="All"
                      color="primary"
                      v-if="showStatus.all"
                      v-model.trim="filter.status.all"
                      class="no-padding small-text radio-buttons-filters"
                      hide-details
                      :on-icon="'mdi-square'"
                      @click="
                        pushFilter(
                          { text: 'All', value: 'all' },
                          filter.status.all
                        )
                      "
                    >
                    </v-checkbox>
                    <v-checkbox
                      label="Join Request"
                      color="primary"
                      v-if="showStatus['join request']"
                      v-model.trim="filter.status.join"
                      class="no-padding radio-buttons-filters"
                      hide-details
                      :on-icon="'mdi-square'"
                      @click="
                        pushFilter(
                          { text: 'Join Request', value: 'join' },
                          filter.status.join
                        )
                      "
                    >
                    </v-checkbox>
                    <v-checkbox
                      label="Active"
                      color="primary"
                      v-if="showStatus.active"
                      :on-icon="'mdi-square'"
                      v-model.trim="filter.status.active"
                      class="no-padding radio-buttons-filters"
                      hide-details
                      @click="
                        pushFilter(
                          { text: 'Active', value: 'active' },
                          filter.status.active
                        )
                      "
                    >
                    </v-checkbox>
                    <v-checkbox
                      label="Inactive"
                      color="primary"
                      v-if="showStatus['in active']"
                      v-model.trim="filter.status.inactive"
                      :on-icon="'mdi-square'"
                      class="no-padding radio-buttons-filters"
                      hide-details
                      @click="
                        pushFilter(
                          { text: 'In Active', value: 'inactive' },
                          filter.status.inactive
                        )
                      "
                    >
                    </v-checkbox>
                    <v-checkbox
                      label="Invited"
                      color="primary"
                      v-if="showStatus.invited"
                      :on-icon="'mdi-square'"
                      v-model.trim="filter.status.invited"
                      class="no-padding radio-buttons-filters mb-2"
                      hide-details
                      @click="
                        pushFilter(
                          { text: 'Invited', value: 'invited' },
                          filter.status.invited
                        )
                      "
                    >
                    </v-checkbox>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel class="no-shadow no-padding">
                  <v-expansion-panel-header
                    class="
                      font-weight-black
                      change-padding-filter
                      filter-items-font
                      mb-0
                      pb-0
                    "
                    >Type</v-expansion-panel-header
                  >
                  <v-expansion-panel-content class="no-padding content-filter">
                    <v-text-field
                      solo
                      class="filter-serach-input no-padding mb-4"
                      v-model.trim="type"
                      dense
                      :hide-details="true"
                      @input="searchType()"
                      placeholder="Search"
                      append-icon="fa fa-search"
                    ></v-text-field>
                    <v-checkbox
                      label="All"
                      color="primary"
                      v-if="showType.all"
                      :on-icon="'mdi-square'"
                      v-model.trim="filter.type.all"
                      class="no-padding radio-buttons-filters"
                      hide-details
                      @click="
                        pushtoTypeFilter(
                          { text: 'All', value: 'allTypes' },
                          filter.type.all
                        )
                      "
                    >
                    </v-checkbox>
                    <v-checkbox
                      label="Admins"
                      color="primary"
                      v-if="showType.admin"
                      v-model.trim="filter.type.admin"
                      :on-icon="'mdi-square'"
                      class="no-padding radio-buttons-filters"
                      hide-details
                      @click="
                        pushtoTypeFilter(
                          { text: 'Admins', value: 'admin' },
                          filter.type.admin
                        )
                      "
                    >
                    </v-checkbox>
                    <v-checkbox
                      label="General User"
                      color="primary"
                      :on-icon="'mdi-square'"
                      v-if="showType['general user']"
                      v-model.trim="filter.type.user"
                      class="no-padding radio-buttons-filters"
                      hide-details
                      @click="
                        pushtoTypeFilter(
                          { text: 'General User', value: 'user' },
                          filter.type.user
                        )
                      "
                    >
                    </v-checkbox>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
          <div>
            <v-divider></v-divider>
            <v-btn
              class="blue-buttons col-md-12 mt-3 mb-md-3 mb-xl-0"
              @click="filterUsers"
              >View Result</v-btn
            >
          </div>
        </div>
      </v-navigation-drawer>
    </v-sheet>
    <v-dialog v-model.trim="dialog" max-width="600px" persistent>
      <v-card>
        <v-col>
          <h3>Remove Provider</h3>
        </v-col>

        <v-col>
          <span>Are you sure you want to delete this Provider? </span>
        </v-col>

        <v-col>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="blue-buttons float-class" @click="confirmRemove"
              >Confirm</v-btn
            >

            <v-btn class="button-style float-class" @click="closePopUp"
              >Close</v-btn
            >
          </v-card-actions>
        </v-col>
      </v-card>
    </v-dialog>
    <router-view></router-view>
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
  </div>
</template>
<script>
import api from "../../../../services/api";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  components: { successModal },
  data() {
    return {
      ratingValue: 2,
      items: [],
      manualOverrideProviders: [
        {
          text: "Manual Override",
          value: true,
        },
      ],
      filterItems: false,
      prefferedProviderItems: [
        {
          text: "Preferred Provider",
          value: true,
        },
      ],
      filter: {
        type: "",
        speciality: "",
        rating: 0,
        distance: "",
        status: "",
        preferredProvider: false,
        manualOverride: false,
      },
      showSaveButton: false,
      filterMatchingStatus: [
        {
          text: "Matched",
          value: "matched",
        },
        {
          text: "Not Matched",
          value: "notMatched",
        },
      ],
      noDataMessage: "",
      selectedArray: [],
      panel: [0, 1, 2, 3, 4, 5],
      providerType: [
        {
          text: "Hospital",
          value: "hospital",
        },
        {
          text: "Doctor Office",
          value: "doctorOffice",
        },
        {
          text: "Imagining",
          value: "imaging",
        },
        {
          text: "Laboratory",
          value: "laboratory",
        },
        {
          text: "Other",
          value: "other",
        },
      ],

      allProviders: [
    
      ],
      display: true,
      overlay: false,
      windowWidth: window.innerWidth,
      dialog: false,
      filterArray: [],
      itemToRemove: null,
      itemById: {},
      index: 0,
      selectAll: false,
      selectedRows: [],
      loader: false,
      drawer: null,
      showStatus: {
        all: true,
        active: true,
        "in active": true,
        invited: true,
        "join request": true,
      },
      showType: {
        all: true,
        "general user": true,
        admin: true,
      },
      userStatus: [
        {
          text: "ALL",
          value: "all",
        },

        {
          text: "Join Request",
          value: "join",
        },
        {
          text: "Active",
          value: "active",
        },
        {
          text: "In Active",
          value: "inactive",
        },
        {
          text: "Invited",
          value: "invited",
        },
      ],
      userType: [
        {
          text: "ALL",
          value: "all",
        },
        {
          text: "Admins",
          value: "admins",
        },
        {
          text: "General User",
          value: "users",
        },
      ],
      selectedArray: [],
      rows: [1],
      selectedTypeArray: [],
      getUsers: [],
      panel: [0, 1],
      filterUse: false,
      type: "",
      status: "",
      statusArray: [],

      typeArray: [],
      filter: {
        status: {
          all: false,
          active: false,
          inactive: false,
          invited: false,
          join: false,
        },
        type: {
          all: false,
          user: false,
          admin: false,
        },
      },
    };
  },

  created() {
    if (this.windowWidth < 600) {
      this.display = false;
    } else {
      this.display = true;
    }
    this.overlay = false;
    // this.getAllProviders();
    this.filterArray = this.allProviders;
  },
  computed: {
    ...mapGetters(["showRoutePathForFilter", "getLocationId"]),
    path() {
      return this.$route.path;
    },
    getClient() {
      return this.$store.getters.getClientGetter;
    },
    routeParams() {
      return this.$route.params.name;
    },

    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }

      return bool;
    },
    overlayClass() {
      let str = "";
      if (this.display) {
        str = "col-md-12";
        if (document.getElementById("hideOnMobile")) {
          document.getElementById("hideOnMobile").style.display =
            "none !important";
        }
      } else if (this.overlay) {
        str = "col-md-9 disabled-table";
      } else if (!this.display) {
        str = "col-md-12";
      } else {
        str = "col-md-12";
      }
      return str;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  watch: {
    showRoutePathForFilter: {
      handler(newValue, oldValue) {
        this.drawer = this.showRoutePathForFilter;
      },
      deep: true,
    },
    drawer: {
      handler(newValue, oldValue) {
        this.$store.commit("setNavItemFilter", this.drawer);
      },
      deep: true,
    },

    getLocationId: {
      handler(newValue, oldValue) {
        this.getAllProviders();
      },

      deep: true,
    },
  },

  methods: {
    filterUsers() {
      api()
        .get("/vendors/providers")
        .then((res) => {
          this.allProviders = res.data;
          this.filterArray = res.data;

          if (!this.filter.status.all) {
            let params = "";
            let type = "";
            for (let key in this.filter.status) {
              if (this.filter.status[key]) {
                params += `${key},`;
              }
            }

            for (let key in this.filter.type) {
              if (this.filter.type[key]) {
                type += `${key},`;
              }
            }
            if ((params && !this.filter.status.all) || type) {
              this.allProviders = this.filterArray.filter(function (el) {
                return params.toLowerCase().includes(el.status.toLowerCase());
              });
            }
          }

          if (this.allProviders.length < 1) {
            this.filterItems = true;
          } else {
            this.filterItems = false;
          }

          this.drawer = false;
        });
    },
    exportData(tableID, filename = "") {
      var elt = document.getElementById(tableID);
      var downloadLink;
      var dataType = "application/vnd.ms-excel";
      var tableSelect = elt;
      var tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

      // Specify file name
      filename = filename ? filename + ".xls" : "excel_data.xls";

      // Create download link element
      downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        var blob = new Blob(["\ufeff", tableHTML], {
          type: dataType,
        });
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = "data:" + dataType + ", " + tableHTML;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
    },
    showFilterSideBar() {
      this.drawer = !this.drawer;
      if (this.drawer) {
        this.$store.commit("setNavItemFilter", this.drawer);
      } else {
        this.$store.commit("setNavItemFilter", false);
      }
    },
    pushtoTypeFilter(item, checked) {
      if (checked) {
        if (item.value == "allTypes") {
          this.selectedTypeArray = [];
          this.filter.type = {
            all: true,
            user: true,
            admin: true,
          };
          this.selectedTypeArray.push({ text: "All", value: "allTypes" });
        } else {
          this.selectedTypeArray.push(item);
        }
      } else {
        if (item.value == "allTypes" || this.filter.type.all) {
          this.filter.type = {
            all: false,
            user: false,
            admin: false,
          };
          this.selectedTypeArray = [];
        } else {
          this.selectedTypeArray.forEach((el, index) => {
            if (el.value == item.value) {
              this.selectedTypeArray.splice(index, 1);
            }
          });
        }
      }
    },
    pushFilter(item, checked) {
      if (checked) {
        if (item.value == "all") {
          this.selectedArray = [];
          this.filter.status = {
            all: true,
            active: true,
            inactive: true,
            invited: true,
            join: true,
          };
          this.selectedArray.push({ text: "All", value: "all" });
        } else {
          this.selectedArray.push(item);
        }
      } else {
        if (item.value == "all" || this.filter.status.all) {
          this.filter.status = {
            all: false,
            active: false,
            inactive: false,
            invited: false,
            join: false,
          };
          this.selectedArray = [];
        } else {
          this.selectedArray.forEach((el, index) => {
            if (el.value == item.value) {
              this.selectedArray.splice(index, 1);
            }
          });
        }
      }
    },

    searchStatus() {
      for (let key in this.showStatus) {
        if (key.toLowerCase().includes(this.status.toLowerCase())) {
          this.showStatus[key] = true;
        } else {
          this.showStatus[key] = false;
        }
      }
    },
    searchType() {
      for (let key in this.showType) {
        if (key.toLowerCase().includes(this.type.toLowerCase())) {
          this.showType[key] = true;
        } else {
          this.showType[key] = false;
        }
      }
    },

    clearAll() {
      this.filter.status = {
        all: false,
        active: false,
        inactive: false,
        invited: false,
        join: false,
      };
      this.filter.type = {
        all: false,
        user: false,
        admin: false,
      };

      this.selectedArray = [];
      this.selectedTypeArray = [];
      this.getAllUsers("all");
    },

    removeFilter(item) {
      if (item.value == "allTypes") {
        this.selectedTypeArray = [];
        this.filter.type = {
          all: false,
          user: false,
          admin: false,
        };
      } else if (this.filter.status[item.value]) {
        this.filter.status[item.value] = false;
        this.selectedArray.splice(this.selectedArray.indexOf(item), 1);
      }

      if (item.value == "all") {
        this.selectedArray = [];
        this.filter.status = {
          all: false,
          active: false,
          inactive: false,
          invited: false,
          join: false,
        };
      } else if (this.filter.type[item.value]) {
        this.filter.type[item.value] = false;
        this.selectedTypeArray.splice(this.selectedTypeArray.indexOf(item), 1);
      }
    },
    removeItem(item) {
      this.dialog = true;
      this.itemToRemove = item;
    },
    selectAllRows() {
      this.selectedRows = [];
      this.selectAll = !this.selectAll;
      this.allProviders.forEach((el) => {
        this.selectedRows.push(el._id);
      });
      if (!this.selectAll) {
        this.selectedRows = [];
      }
    },
    selectAllProviders() {
      this.selectAll = !this.selectAll;
    },

    removeSelected() {
      this.dialog = true;
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
      this.getAllProviders();
      this.overlay = false;
      this.showSaveButton = false;
    },
    clearAll() {
      this.filter.type = "";
      this.filter.distance = "";
      this.filter.rating = 0;
      this.filter.status = "";
      this.filter.preferredProvider = false;
      this.filter.manualOverride = false;
    },
    removeFilter(type) {
      if (type == "type") {
        this.filter.type = "";
      } else if (type == "distance") {
        this.filter.distance = "";
      } else if (type == "rating") {
        this.filter.rating = 0;
      } else if (type == "status") {
        this.filter.status = "";
      } else if (type == "preferred") {
        this.filter.preferredProvider = false;
      } else if (type == "manual") {
        this.filter.manualOverride = false;
      }
    },
    activeRow(index, item) {
      this.index = index;
      this.itemById = document.getElementById("id" + index);
      this.itemById.classList.toggle("activeItem");

      if (this.itemById.classList.contains("activeItem")) {
        this.selectedRows.push(item._id);
      } else {
        let removeItem = this.selectedRows.find((x) => x === item._id);
        this.selectedRows.splice(this.selectedRows.indexOf(removeItem), 1);
      }
    },

    addProviders() {
      this.itemById.classList.remove("activeItem");
      let data = {
        search: "provider",
        ids: this.selectedRows,
      };
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.employerAdvisor;
      let employer = JSON.parse(localStorage.getItem("employer"));
      api()
        .post(
          `/advisors/${advisorId}/employers/${employer._id}/providers`,
          data
        )
        .then((res) => {
          let text = "Providers added successfully to this Employer!";
          this.$refs.success_modal.openPopUp(text);
        });
    },
    filterProviders() {
      let urlData = ``;
      urlData += this.filter.type
        ? `providerType=${this.filter.type.value}&`
        : "";
      urlData += this.filter.distance
        ? `distance=${this.filter.distance}&`
        : "";
      urlData += this.filter.rating
        ? `qualityRating=${this.filter.rating}&`
        : "";
      urlData += this.filter.status
        ? `status=${this.filter.status.value}&`
        : "";
      urlData += this.filter.preferredProvider
        ? `preferred=${this.filter.preferredProvider.value}&`
        : "";
      urlData += this.filter.manualOverride
        ? `manualOverride=${this.filter.manualOverride.value}&`
        : "";

      api()
        .get(`/advisors/providers/search?${urlData}`)
        .then((res) => {
          if (res) {
            if (res.data.providers.length < 1) {
              this.noDataMessage = "No Result Found. Try Again";
              this.allProviders = [];
              this.showSaveButton = false;
            } else {
              this.allProviders = res.data.providers;
              this.showSaveButton = true;
            }

            this.overlay = false;
          }
        });
    },
    getAllProviders() {
      api()
        .get(`/vendors/providers`)
        .then((res) => {
          if (res) {
            this.filterItems = false;
            this.allProviders = res.data;
            this.filterArray = res.data;
            this.loader = false;

            if (this.getLocationId) {
              this.allProviders = this.allProviders.filter((item) =>
                item.locations.includes(this.getLocationId)
              );
            }
          }
        });
    },
    searchProviders(val) {
      this.allProviders = this.filterArray.filter((el) => {
        if (
          el.companyName &&
          el.companyName.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.companyName.toLowerCase().includes(val.toLowerCase());
        } else if (
          el.providerName &&
          el.providerName.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.providerName.toLowerCase().includes(val.toLowerCase());
        } else if (
          el["Provider First Name"] &&
          el["Provider First Name"].toLowerCase().includes(val.toLowerCase())
        ) {
          return el["Provider First Name"]
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el["Provider Last Name (Legal Name)"] &&
          el["Provider Last Name (Legal Name)"]
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el["Provider Last Name (Legal Name)"]
            .toLowerCase()
            .includes(val.toLowerCase());
        }
      });

      if (this.allProviders.length < 1) {
        this.filterItems = true;
      }
    },

    singleProvider(item) {
      this.$store.commit("setSingleProviderInEmployerAdvisor", item);
      this.$router.push("/dpc-providers/account/providers/provider");
    },
    removeListOfProvider() {
      let user = JSON.parse(localStorage.getItem("user"));
      let advisorId = user.groupPortal[0].organization.employerAdvisor;
      let employer = JSON.parse(localStorage.getItem("employer"));
      let array = [];
      let data = {
        ids: this.selectedRows,
      };

      api()
        .put(
          `/advisors/${advisorId}/employers/${employer._id}/providersDelete`,
          data
        )
        .then((res) => {
          if (res) {
            api()
              .get(`/advisors/${advisorId}/employers/${employer._id}/providers`)
              .then((res) => {
                if (res) {
                  this.allProviders = res.data.providers;
                  this.filterArray = res.data.providers;
                  this.dialog = false;
                  this.selectAll = false;
                }
              });
          }
        });
    },
    confirmRemove() {
      this.dialog = false;
      api()
        .delete(`/vendors/providers/${this.itemToRemove._id}`)
        .then((res) => {
          if (res) {
            this.getAllProviders();
          }
        });
    },
    closePopUp() {
      this.dialog = false;
    },
    openFilterMenu() {
      this.overlay = !this.overlay;
      this.$store.commit("showFilterMenu", this.overlay);
    },
    changeColor(status) {
      let myClass = "";
      if (status == "Matched") {
        myClass = "red--text";
      } else if (status == "Not matched") {
        myClass = "green--text";
      } else {
        myClass = "gray--text";
      }
      return myClass;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 600) {
        this.display = false;
      } else {
        this.display = true;
      }
    },
  },
};
</script>
<style>
.noActiveItem {
  background-color: white !important;
}
</style>
