<template>
  <div>
    <Header :title="'Add Member IDs and Zip Codes'"></Header>
    <div class="row mt-2">
      <div class="col-12 col-md-6">
        <v-form v-model="valid" ref="form" class="row  form-row-height">
            <div class="row justify-center align-center" style="margin: 10px" >
              <v-col cols="12" class="text-gray font-weight-bold">
                Add an Individual Member’s Info
              </v-col>
              <div class="col-12 d-md-flex">   
                <v-col md="4" cols="12" >
                <label for="">Member ID</label>
                <v-text-field solo v-model="tmpMember.memberId" :rules="memberIdRules"></v-text-field>
              </v-col>
              <v-col md="4" cols="12">
                <label for="">Email</label>
                <v-text-field solo v-model="tmpMember.email" :rules="emailRules"></v-text-field>
              </v-col>
              <v-col md="4" cols="12">
                <label for="">Home Zip Code</label>
                <v-text-field solo v-model="tmpMember.zipCode" :rules="zipRules"></v-text-field>
              </v-col>
            </div>
            <div class="col-12 d-md-flex">
               <v-col md="5" cols="12">
                <label for="">Relationship to Subscriber</label>
                <v-select solo v-model="tmpMember.subscriberRelation" :items="typeItems" :rules="requiredRules"></v-select>
              </v-col>
              <v-col md="4" cols="12">
                <label for="">State</label>
                <v-select solo v-model="tmpMember.state" :items="states"></v-select>
              </v-col>
              <v-col md="3" cols="12">
                <label for="">City</label>
                <v-text-field solo v-model="tmpMember.city"></v-text-field>
              </v-col>
              
            </div>
             
              <v-col cols="10" class="text-right">
                <a @click="addItemToList" class="text-underline light-blue-text">Add to List</a>
              </v-col>
              <v-col md="12" cols="10" class="justify-center align-center">
                <div class="row col-md-12 font text-gray">
                  <h4 >Upload a List of Member’s Information</h4>
                </div>
                <div class="row text-center justify-center upload-box">
                  <div class="col-md-9 upload-provider-box pointer" @click="addItemExcel">
                    <input  ref="fileInput" type="file" style="display: none" @change="uploadExcel"/>
                    <img src="/filee.png" class="apaly-pic" alt="" />
                    <h3 class="text-gray font">Drag & Drop</h3>
                    <h5 class="text-gray font">
                      Your files here or
                      <a>Browse</a>
                      to upload
                    </h5>
                    <div class="text-gray font d-flex text-center justify-center">
                      <span class="mt-1">
                         <a>Excel or CSV files Only</a>
                      </span>
                      <span>
                        <img v-if="arrayLength" src="/success.png" width="20" height="25" alt="" class="ml-2 pt-1"/>
                      </span>
                     
                      
                    </div>
                  </div>
                  <div class="btn-box col-md-10">
                    <div class="downoald-file-btn-box">
                      <h3>
                        <a class="underline-text" @click="downloadExcel">Download File Template</a>
                      </h3>
                    </div>
                  </div>
                </div>
              </v-col>

            </div>
        </v-form>
      </div>
      <v-divider vertical></v-divider>
      <div class="col-12 col-md-6">
        <div class="mt-3">
          <h4 class="text-gray font-weight-bold">Member ID and Zip Code List</h4>
          <v-simple-table fixed-header id="tblData" height="70vh" class="no-padding tr-custom-height">
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">MEMBER ID</th>
                <th class="text-left">EMAIL</th>
                <th class="text-left">CITY</th>
                <th class="text-left">STATE</th>
                <th class="text-left">ZIP CODE</th>
                <th class="text-left">RELATIONSHIP TO SUBSCRIBER</th>
                <th class="text-left"></th>
              </tr>
              </thead>
              <tbody v-if="items.length > 0" class="text-gray tr-custom-height">
              <tr v-for="(item, index) in items" :key="index">
                <td>{{item.memberId}}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.city }}</td>
                <td>{{ item.state }}</td>
                <td>{{ item.zipCode }}</td>
                <td>{{ item.subscriberRelation }}</td>
                <td>
                  <svg @click="removeItemFromList(item,index)" class="pointer" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 13.7206C1 17.0943 2.35661 20.3298 4.77138 22.7154C7.18615 25.101 10.4613 26.4412 13.8763 26.4412C17.2913 26.4412 20.5664 25.101 22.9812 22.7154C25.396 20.3298 26.7526 17.0943 26.7526 13.7206C26.7526 10.3469 25.396 7.11134 22.9812 4.72577C20.5664 2.3402 17.2913 1 13.8763 1C10.4613 1 7.18615 2.3402 4.77138 4.72577C2.35661 7.11134 1 10.3469 1 13.7206V13.7206Z" stroke="#D00000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8.72656 18.8088L19.0276 8.63232" stroke="#D00000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M19.0276 18.8088L8.72656 8.63232" stroke="#D00000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="col-md-12 mb-4" align="end">
        <span class="light-blue-text text-underline pointer font-weight-bold mr-10 font-18" @click="goBack">Cancel</span>
        <span class="light-blue-text text-underline pointer font-weight-bold mr-5 font-18 pointer" @click="submit">Submit</span>
      </div>
    </div>
    <successModal ref="success_modal" v-on:confirm="closeSuccessModal"></successModal>
    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>
  </div>
</template>
<script>
import Header from "../../../Layout/HeaderPage.vue";
import api from "../../../../../../services/api";
import successModal from "../../../../../TablesTemplate/SuccessDialog.vue";
import removeDialog from "../../../../../TablesTemplate/RemoveDialog.vue";
import errorModal from "../../../../../TablesTemplate/ErrorDialog.vue";
import XLSX from "xlsx";

export default {
  components: { Header, successModal, removeDialog, errorModal },
  data() {
    return {
      taxIdRules: [
        (v) => !!v || "This field is required",
        (v) =>
          !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
          "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
      ],
      zipRules: [
        (v) => !!v || "This field is required",
        (v) => (v && v.length == 5) || " Zip Code should be five digits long",
      ],
      memberIdRules: [
        (v) => !!v || "This field is required",
        (v) => (v && v.length == 9) || "Member ID should contain 9 digits",
      ],
      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
      typeItems: ["Self","Spouse","Dependent"],
      emailRules: [
        (v) => !!v || "This field is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      valid: false,
      tmpMember: {
        memberId: "",
        email: "",
        zipCode: "",
        subscriberRelation: "",
        state: "",
        city: "",
      },
      states: [],
      arrayLength: false,
      memberError: false,
      results: false,
      excelKey: 'init',
      items: [],
      jsondata: [
        {
          memberId: "",
          email: "",
          zipCode: "",
          subscriberRelation: "",
          city: "",
          state: "",
        },
      ],
    };
  },
  computed: {
    singleItem() {
      return this.$store.getters.getSingleItem;
    },
    getSingleClient() {
      return this.$store.getters.getSingleClient;
    },
  },
  created() {
    this.getStates();
  },
  methods: {
    getStates() {
      api().get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el.name);
            }
          });
        });
    },
    addItemToList(){
      this.$refs.form.validate();
      if(this.valid){
        let tmpMember = JSON.stringify(this.tmpMember)
        tmpMember = JSON.parse(tmpMember)
        this.items.push(tmpMember)
        this.tmpMember = {
          memberId: "",
          email: "",
          zipCode: "",
          subscriberRelation: "",
          city: "",
          state: "",
        }
        this.valid = false
      }
    },
    removeItemFromList(item,index){
      if(item.id){
        api().delete(`/tpas/clients/${this.$route.params.clientId}/members/${item.id}`)
          .then((res) => {
            if (res) {
              this.items.splice(index,1)
            }
          });
      }else{
        this.items.splice(index,1)
      }

    },
    addItemExcel() {
      this.excelKey = Math.random()
      this.$refs.fileInput.click();
    },
    uploadExcel(e) {
      this.excelError = false;
      const files = e.target.files;
      if (!files.length) {
        return;
      } else if (!/\.(xls|xlsx|csv)$/.test(files[0].name.toLowerCase())) {
        return alert(
            "The upload format is incorrect. Please upload xls, xlsx or csv format"
        );
      }
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const workbook = XLSX.read(data, {
            type: "binary",
          });
          const wsname = workbook.SheetNames[0];
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]);
          for (var i = 0; i < ws.length; i++) {
            if (ws[i].memberId) {
              this.excelError = false;
              let tmpObj = {
                memberId: ws[i].memberId,
                email: ws[i].email ?? "",
                zipCode: ws[i].zipCode ?? "",
                subscriberRelation: ws[i].subscriberRelation ?? "",
                city: ws[i].city ?? "",
                state: ws[i].state ?? "",
              }
              let data =  {
                memberId: ws[i].memberId,
                email: ws[i].email ?? "",
                zipCode: ws[i].zipCode ?? "",
                zipCode: ws[i].zipCode ?? "",
                subscriberRelation: ws[i].subscriberRelation ?  ws[i].subscriberRelation.charAt(0).toUpperCase() + ws[i].subscriberRelation.slice(1) : "",
                city: ws[i].city ? ws[i].city.charAt(0).toUpperCase() + ws[i].city.slice(1) : "",
                state: ws[i].state ? ws[i].state.charAt(0).toUpperCase() + ws[i].state.slice(1) : "",
              }
              if(data.memberId.toString().length < 9)
              {
                this.memberError = true;
               
              }
              else{
                   this.items.push(data);
              console.log('tmp', data)
              this.arrayLength = true;
              }
           
            } else {
              this.excelError = true;
              this.arrayLength = false;
            }
          }
          if (this.items.length < 1) {
            this.arrayLength = false;
            this.items = [];
            if(this.memberError)
            {
              this.$refs.error_modal.openPopUp(
                "Member ID should be 9 digits long"
            );
            }
            else{
              this.$refs.error_modal.openPopUp(
                "Something Went Wrong, Please Download The File Template First"
            );
            }
            
          }
        } catch (e) {
          console.log(e)
          return alert("Read failure! Please Download the File Template.");
        }
      };

      fileReader.readAsBinaryString(files[0]);
    },
    downloadExcel() {
      const data = XLSX.utils.json_to_sheet(this.jsondata);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "clientMembers.xlsx");
    },
    goBack() {
      this.closeSuccessModal();
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
      this.$router.push(`/tpa/clients/${this.$route.params.clientId}/members` );
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    submit() {
      api().post(`/tpas/clients/${this.$route.params.clientId}/members`, this.items)
        .then((res) => {
          if (res) {
            this.$refs.success_modal.openPopUp("Successfully added");
          }else{
            this.$refs.error_modal.openPopUp(res.data.message);
          }
        }).catch(err => {
          console.log(err.response)
          if(err.response.data.message){
        this.$refs.error_modal.openPopUp(err.response.data.message)
          }
          else{
            this.$refs.error_modal.openPopUp(err.response.data)
          }
      });
    },
  },
};
</script>
<style>
.gray-border-form {
  border-right: 2px solid rgba(179, 177, 177, 0.5);
}
</style>