<template>
  <div class="form-div">
    <v-card class="videos-cart col-lg-8 col-xl-6 col-md-6">
      <h2 class="blue-text text-center">Now we’re talking.</h2>

      <!-- ?autoplay=1&mute=1 At the end of the url ,  allow=autoplay width="640" height="360"-->

      <!-- Employer Video -->
      <div v-if="$route.params.name == 'employer'">
        <video
          width="640"
          height="360"
          ref="singleVideo"
          class="video-media"
          controls
          :autoplay="true"
          muted
        >
          <source
            src="https://vcontent.apaly.net/2-employer.mp4"
            type="video/mp4"
          />
          <source
            src="https://vcontent.apaly.net/2-employer.mp4"
            type="video/webm"
          />
          Your browser does not support the video tag.
        </video>
      </div>
      <!-- Broker Video -->
      <div v-if="$route.params.name == 'employerAdvisor'">
        <video
          width="640"
          height="360"
          ref="singleVideo"
          class="video-media"
          controls
          :autoplay="true"
          muted
        >
          <source
            src="https://vcontent.apaly.net/3-broker.mp4"
            type="video/mp4"
          />
          <source
            src="https://vcontent.apaly.net/3-broker.mp4"
            type="video/webm"
          />
          Your browser does not support the video tag.
        </video>
      </div>
      <!-- TPA Video -->
      <div v-if="$route.params.name == 'thirdPartyAdmin'">
        <video
          width="640"
          height="360"
          ref="singleVideo"
          class="video-media"
          controls
          :autoplay="true"
          muted
        >
          <source src="https://vcontent.apaly.net/4-tpa.mp4" type="video/mp4" />
          <source
            src="https://vcontent.apaly.net/4-tpa.mp4"
            type="video/webm"
          />
          Your browser does not support the video tag.
        </video>
      </div>
      <!-- Member -->
         <div v-if="$route.params.name == 'member'">
        <video
          width="640"
          height="360"
          ref="singleVideo"
          class="video-media"
          controls
          :autoplay="true"
          muted
        >
   
          />
          Your browser does not support the video tag.
        </video>
      </div>
      <!-- Provider Video -->
      <div v-if="$route.params.name == 'provider'">
        <video
          width="640"
          height="360"
          ref="singleVideo"
          class="video-media"
          controls
          :autoplay="true"
          muted
        >
          <source
            src="https://vcontent.apaly.net/5-provider.mp4"
            type="video/mp4"
          />
          <source
            src="https://vcontent.apaly.net/5-provider.mp4"
            type="video/webm"
          />
          Your browser does not support the video tag.
        </video>
      </div>
      <!-- Provider Partner Video -->
      <div v-if="$route.params.name == 'providerAdvisor'">
        <video
          width="640"
          height="360"
          ref="singleVideo"
          class="video-media"
          controls
          :autoplay="true"
          muted
        >
          <source
            src="https://vcontent.apaly.net/6-providerpartner.mp4"
            type="video/mp4"
          />
          <source
            src="https://vcontent.apaly.net/6-providerpartner.mp4"
            type="video/webm"
          />
          Your browser does not support the video tag.
        </video>
      </div>
      <div v-if="$route.params.name == 'vendor'">
        <video
          width="640"
          height="360"
          ref="singleVideo"
          class="video-media"
          controls
          :autoplay="true"
          muted
        ></video>
      </div>
      

      <v-card-actions class="col-md-12 justify-center" align="center">
        <v-btn x-large class="select-btn col-md-10 mt-5" text @click="next()">
          Let's Get Started
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
export default {
  created() {
    this.$store.commit(
      "sideBarTitleAndDesc",
      "“Life is what happens when you’re busy making other plans.”  "
    );
    this.$store.commit("sideBarDesc", "John Lennon");
  },
  methods: {
    next() {
      let video = this.$refs.singleVideo;
      video.pause();
      video.currentTime = 0;
      this.$router.push(`/register/${this.$route.params.name}/create-account`);
    },
  },
};
</script>
