<template>
<div>

   <div class="row ">
      <div v-for="item in items" :key="item.title" class=" mx-2 my-2 text-center justify-center aling-center client-home-cards " @click="clientsTable">
        <v-card style="height:100%; cursor:pointer">
          <img
            :src="item.img"
            height="65"
            width="75"
            style="object-fit:contain"
            class="img-media">
         <h3 class="text-center justify-center blue-text font ">{{item.title}} </h3>
          <v-card-text>
            <v-row
              align="center"
              class="mx-0 justify-center"
            >
              <h5 class="grey--text  text-center font-quicksand-medium-16">
                {{item.desc}}
              </h5>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <div class="row">
      
      <div class="col-md-6 text-center justify-center aling-center">
        <v-card>
            <v-row
              align="center"
              class="mx-0 justify-center mt-2"
            >
              <h5 class="  text-center text-underline mt-2">
                Easy Setup 
              </h5>
            </v-row>
            <v-stepper alt-labels class=" mt-3">
                <v-stepper-header>
                  <v-stepper-step
                    step="1"
                    complete
                    class="small-text"
                  >
                    Add a Client
                  </v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step
                    step="2"
                    complete
                     class="small-text"
                  >
                    Upload Client Census
                  </v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step
                    step="3"
                    class="small-text "
                  >
                   Filter Providers 
                  </v-stepper-step>
                  <v-divider></v-divider>
                </v-stepper-header>
            </v-stepper>
            <v-row
              align="center"
              class="mx-0 justify-center mt-2"
            >
              <h5 class="red--text mt-3 text-center font" >
               Instantly view your client's estimated savings and provider coverage.
              </h5>
            </v-row>
        </v-card>
      </div>

      <div v-for="item in precentageCards" :key="item.title" class="col-md-3 text-center justify-center aling-center"  >
        <v-card style="height:10rem;cursor:pointer" @click="clientsTable">
            <v-row
              align="center"
              class="mx-0 justify-center mt-2"
            >
              <h5 class="  text-center text-underline mt-2">
                {{item.title}}
              </h5>
            </v-row>
            <v-card-title class="text-center justify-center text-gray font percentage-num mt-4">
            <div v-if="item.clients" class="row justify-space-around mt-1">
              <h4 class=" blue-text">
                 {{item.percentage}} <br>
                 Clients
              </h4>
              <h4 class=" blue-text">
                 {{item.percentage1}} <br>
                  Members
              </h4>
            </div>
            <div v-else-if="item.inpatient" class="row justify-space-around mt-1 blue-text">
              <h4 class=" blue-text">
                 {{item.percentage}} <br>
                 Outpatient
              </h4>
              <h4 class=" blue-text">
                 {{item.percentage1}} <br>
                  Inpatient
              </h4>
            </div>
             <div v-else class=" blue-text">
               {{item.percentage}}
            </div>
          </v-card-title>
            <v-row
            style="height:200px"
              align="center"
              class="mx-0 justify-center mt-2"
            >
              <h5 class="grey--text ms-4 text-center " v-if="item.desc" >
                {{item.desc}}
              </h5>
            </v-row>
        </v-card>
    </div>
    </div>
<!-- Modal -->
  <v-row justify="center">
    <v-dialog
      v-model.trim="dialog"
      scrollable
      max-width="600px"
      class="select-client-modal"
    >
   
    <v-card class="ml-6">
      <div class="font mb-2 mt-2 justify-center text-center">
       <div class="font justify-center ">Search For Providers</div>
        <div class="col-md-12  justify-center">
          <v-text-field
          class=" justify-center col-md-5"
          placeholder="Search by Company, Name or Tax ID">
          </v-text-field>
         </div>
       </div>
        <h4 class="font ml-3">Select Provider</h4>
        <v-card-text style="height: 300px;">
           <v-divider  class="mt-4 mb-4"></v-divider>
           
           <a class="font mt-3 mb-3" @click="$router.push('/provider-advisor/providers/Tampa%20General%20Hospital')">Tampa General Hospital</a>
           <v-divider class="mt-4 mb-4"></v-divider>
            <a class="font"  @click="$router.push('/provider-advisor/providers/Manatee%20Memorial')">Manatee Memorial</a>
           <v-divider class="mt-4 mb-4"></v-divider>
           <a class="font"  @click="$router.push('/provider-advisor/providers/Sarasota%20Memorial')">Sarasota Memorial</a>
           <v-divider class="mt-4 mb-4"></v-divider>
           <a class="font"  @click="$router.push('/provider-advisor/providers/Mount%20Sinai%20Medical%20Center')">Mount Sinai Medical Center</a>
           <v-divider></v-divider>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog1 = false"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog1 = false"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

 
  </v-row>
  
 
</div>
</template>
<script>
 export default{
   data(){
     return{
       items:[
         {
             img:'/cards/woman.png',
             title:'Add a Client',
             desc:'First Create a client profile, and then be able to view savings and coverage.'
         },
         {
             img:'/cards/money.png',
             title:'View Savings',
             desc:'Determine the average savings for your client throught Apaly.'
         },
         {
             img:'/cards/doctor.png',
             title:'View Providers',
             desc:'View provider coverage based on plan member location.'
         },         
         {
             img:'/cards/hospital.png',
             title:'Define Network',
             desc:'Create a custom provider network for your client based on many factors.'
         },        
         {
             img:'/cards/contract.png',
             title:'Contract',
             desc:'Facilitate direct contracts between your client and providers.'
         },         
         {
             img:'/cards/letter.png',
             title:'Invite Providers',
             desc:'Invite providers to directly contract throught Apaly.'
         },         
         {
             img:'/cards/desktop.png',
             title:'Invite Colleagues',
             desc:'Invite other consultants to participate.'
         },
       ],
       precentageCards:[
           {
             title: 'Patient / Member Encounterss',
             percentage:'150',
             percentage1:'180',
             desc1:'number of patient encounters that have been delivered by your providers for Apaly Health employes participants',
             inpatient:true
         },
         {
             title: 'Employers Interested in Contracting',
             percentage:'500',
             desc:'number of self-funded employers who have added you to theit provider list, and are requesting to contract'
         },
         {
             title: 'Members within Coverage Area',
             percentage:'15,000',
             desc:'number of self-funded employer plan members residing within a 3 0mile radius of your facilities'
         },
         {
             title: 'Providers',
             percentage:'450',
             desc:'total number of providers that are currently entered your Apaly Health accounbt'
         },
         {
             title: 'Current Contract Rates',
             percentage:'150%',
             percentage1:'180%',
             desc1:'of Medicare benchmark rates',
             inpatient:true
         },
         {
             title: 'Contract Renewals',
             percentage:'10/30/2021',
             desc:'contract renewal date',
            
         },

       ],
       dialogm1: '',
       dialog: false,
       dialog1:false,
    
     }
   },
   methods:{
     clientsTable(){
       this.dialog = !this.dialog
       this.dialog1 = !this.dialog1
       this.$emit('showClient')
     }
   },
   created(){
       this.$store.commit('setBreadcrumbs','home')
   }
 }
</script>

