<template>
  <div class="col-12 col-md-12 mt-2">
    <div class="row">
        <div class="col-12 col-md-12">
            <h3>Dependents</h3>
        </div>
        <div class="col-12">
            <v-simple-table fixed-header width="100" >
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-left">Name </th>
                    <th class="text-left">Last Name</th>
                    <th class="text-left">Member ID</th>
                    <th class="text-left">Relationship to subscriber</th>
                  </tr>
                  </thead>
                  <tbody
                      v-if="memberData.dependents.length > 0"
                      id="remove-hover"
                  >
                  <tr
                      v-for="(item, index) in memberData.dependents"
                      :key="index"
                      class="text-gray cursor-pointer"
                      @click="openDependent(item)"
                  >
                    <td><span  :class="{ 'underline-text': item.attributed }"  @click="openDependent(item)">{{item.firstName}}</span></td>
                    <td><span  :class="{ 'underline-text': item.attributed }"  @click="openDependent(item)"></span>{{item.lastName}}</span></td>
                    <td><span  :class="{ 'underline-text': item.attributed }"  @click="openDependent(item)"></span>{{item.memberId}}</span></td>
                    <td>{{item.subscriberRelation}}</td>


                  </tr>
                  </tbody>
                  <tbody v-else-if=" memberData.dependents.length < 1">
                    <tr>
                        <td colspan="4">
                            <v-alert
                            border="bottom"
                            colored-border
                            type="warning"
                            elevation="2"
                            class="text-gray"
                        >
                          Sorry! No results found
                        </v-alert>
                        </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
        </div>

    </div>
  </div>
</template>
<script>
import api from "../../../../services/api";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import removeDialog from "../../../TablesTemplate/RemoveDialog.vue";
import errorModal from "../../../TablesTemplate/ErrorDialog.vue";

export default {
  components: { successModal, removeDialog, errorModal },
  props:{
    memberData:{
        required:true,
        type:Object
    }
  },
  data() {
    return {
      subscription: {},
      invoice: {},
      transactions: [],
      
    };
  },
  computed: {
    singleMember() {
      return this.$store.getters.getSingleProviderMember;
    },
  },
  created() {
    this.member = this.singleMember;
  },
  methods: {
    openDependent(item){
      if(item.attributed == true){
        this.$store.commit("setSingleMemberProvider", item);
        window.location = `/dpc-providers/members/member/`+item._id
      }
      //this.$router.push(`/dpc-providers/members/member/`+item._id);
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
      this.$router.push("/dpc-providers/members/");
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
  },
};
</script>
